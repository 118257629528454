import React, { useState } from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";

import { spacing } from "@mui/system";
import { green } from "@mui/material/colors";

import {
  Box as MuiBox,
  Chip,
  Drawer as MuiDrawer,
  ListItemButton,
} from "@mui/material";

// import Logo from "/static/img/brands/ticon_white.png";
// import { ReactComponent as Logo } from "../../vendor/logo.svg";
import Footer from "./SidebarFooter";
import SidebarNav from "./SidebarNav";
import { Logo } from "../../Svgs";
// import Logo from "..//static/img/brands/ticon.png";
// import Logo from "../../../public/static/img/brands/ticon.png";
const Box = styled(MuiBox)(spacing);
const Drawer = styled(MuiDrawer)`
  border-right: 0;
  z-index: 0;

  > div {
    border-right: 0;
    height: calc(100vh - 64px);
    top: 64px;
    z-index: 100;
  }
`;

const Brand = styled(ListItemButton)`
  font-size: ${(props) => props.theme.typography.h5.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  color: ${(props) => props.theme.sidebar.header.color};
  background-color: ${(props) => props.theme.sidebar.header.background};
  font-family: ${(props) => props.theme.typography.fontFamily};
  min-height: 56px;
  padding-left: ${(props) => props.theme.spacing(6)};
  padding-right: ${(props) => props.theme.spacing(6)};
  justify-content: center;
  cursor: pointer;
  flex-grow: 0;

  ${(props) => props.theme.breakpoints.up("sm")} {
    min-height: 64px;
  }

  &:hover {
    background-color: ${(props) => props.theme.sidebar.header.background};
  }
`;

const BrandIcon = styled(Logo)`
  margin-right: ${(props) => props.theme.spacing(2)};
  color: ${(props) => props.theme.sidebar.header.brand.color};
  fill: ${(props) => props.theme.sidebar.header.brand.color};
  width: 32px;
  height: 32px;
`;

const BrandChip = styled(Chip)`
  background-color: ${green[700]};
  border-radius: 5px;
  color: ${(props) => props.theme.palette.common.white};
  font-size: 55%;
  height: 18px;
  margin-left: 2px;
  margin-top: -16px;
  padding: 3px 0;

  span {
    padding-left: ${(props) => props.theme.spacing(1.375)};
    padding-right: ${(props) => props.theme.spacing(1.375)};
  }
`;

const Sidebar = ({ items, showFooter = true, open, onClose, ...rest }) => {
  // console.log(res);
  return (
    <Drawer
      variant="permanent"
      {...rest}
      onClose={onClose}
      open={open}
      style={{ zIndex: 100 }}
      ModalProps={{ sx: { zIndex: 1 } }}
    >
      {/* <Brand
        component={NavLink}
        to="/"
        style={{ justifyContent: "center", padding: 0, justifySelf: "center" }}
      > */}
      {/* <Logo>
          <img src={"/static/img/brands/ticon.png"} />
          <Logo />
        </Logo> */}
      {/* <img
          src={"/static/img/brands/RetailerConnect-white.png"}
          style={{ width: "90px", height: "90px" }}
        /> */}
      {/* <Box ml={1}><BrandChip label="PRO" /></Box> */}
      {/* </Brand> */}
      <SidebarNav
        style={{ top: "64px", height: "calc(100vh - 64px)" }}
        items={items}
        onClose={onClose}
      />
      {/* {!!showFooter && <Footer />} */}
    </Drawer>
  );
};

export default Sidebar;
