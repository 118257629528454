import { CloudOff, Storage } from "@material-ui/icons";
import { CloudCircle } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardContent,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { dialogStyle } from "../../helper";
import { fetchCustomer } from "../crm/View";

const pricingJSON = {
  Users: {
    User: { premium: 1, pro: 1 },
    Devices: { premium: 9, pro: 3 },
  },
  Product: {
    "Products Limit": { premium: 25000, pro: 15000 },
    "Images Per Product": { premium: 6, pro: 6 },
    "Video Per Product": { premium: 1, pro: 1 },
    "Quick Edit": { premium: true, pro: true },
    "Bulk Edit": { premium: true, pro: true },
    "Browser, App & Bulk Upload": { premium: true, pro: true },
    "Product Customization": { premium: true, pro: true },
    "Product Categorization": { premium: true, pro: true },
    "Product Filters": { premium: true, pro: true },
    "Product Cloning": { premium: true, pro: true },
    "Product offers & Discounts": { premium: true, pro: true },
    "Matching Products, Up-sell & Cross-sell": { premium: true, pro: true },
    "Auto SEO Keywords, Title & Description Mapping": {
      premium: true,
      pro: true,
    },
    "ERP Integration, Auto Fetch & Auto Update": { premium: true, pro: true },
    "Size Masters & guide for Ring, Bracelets etc": {
      premium: true,
      pro: true,
    },
    "Share & Publish products on 10+ Channels at a Click": {
      premium: true,
      pro: true,
    },
  },
  Pricing: {
    "Master & Custom Level Pricing": { premium: true, pro: true },
    "Masters Management": { premium: true, pro: true },
    "Separate Calculation for Gold, Diamond, Gemstone, Making": {
      premium: true,
      pro: true,
    },
    "Multiple level pricing management & calculation dynamically": {
      premium: true,
      pro: true,
    },
    "Diamond – Natural & Lab Grown": { premium: true, pro: true },
    "Making – On wastage, Per gram, Per piece": { premium: true, pro: true },
    "Auto Product Pricing Calculation": { premium: true, pro: true },
    "Automatic & Manual Gold rate Update": { premium: true, pro: true },
    "Auto pricing update for every product": { premium: true, pro: true },
    "Auto pricing update on Every connected channels": {
      premium: true,
      pro: true,
    },
    "Real-time Pricing Update": { premium: true, pro: true },
    "Currency - 1": { premium: true, pro: true },
    "Auto Tax Calculation": { premium: true, pro: true },
    "Auto discount calculation": { premium: true, pro: true },
    "Extra charges management": { premium: true, pro: true },
  },
  Catalog: {
    "No of Catalogs": { premium: 3500, pro: 2500 },
    "Products Per Catalog": { premium: 500, pro: 500 },
    "Catalog Duration": { premium: "72 hrs", pro: "72 hrs" },
    "Enable/Disable Pricing": { premium: true, pro: true },
    "Enable/Disable OTP": { premium: true, pro: true },
    "Clone Catalogs": { premium: true, pro: true },
    "Share Catalog Link on any channel": { premium: true, pro: true },
    "Multiple Images view for each product": { premium: true, pro: true },
    "Get selection, requests from customers": { premium: true, pro: true },
    "Track & Analyze customer journey & engagement on Catalog": {
      premium: true,
      pro: true,
    },
  },
  Website: {
    "An ecommerce shopping site": { premium: true, pro: true },
    "Customize home page design/theme ": { premium: true, pro: true },
    "Mobile View Optimized": { premium: true, pro: true },
    "SEO Optimized": { premium: true, pro: true },
    "Multi-level Filters": { premium: true, pro: true },
    "Image Download disabled": { premium: true, pro: true },
    "Real-time Pricing": { premium: true, pro: true },
    "Customize Product Pricing on Diamond & Metal Quality": {
      premium: true,
      pro: true,
    },
    "Product Breakup": { premium: true, pro: true },
    "Product Offers, Discounts": { premium: true, pro: true },
    "Custom Pop-ups & Newsletters": { premium: true, pro: true },
    "Share Products easily": { premium: true, pro: true },
    "Enquire on Whatsapp ": { premium: true, pro: true },
    "Chat with visitors": { premium: true, pro: true },
    "Customer Profile, Account management & Ledger": {
      premium: true,
      pro: true,
    },
    "Payment Integration": { premium: true, pro: true },
    "GDPR Compliant": { premium: true, pro: true },
    "Analytics Integration": { premium: true, pro: true },
  },
  "In-Store App": {
    "Tablet App to engage customers in-store": { premium: true, pro: false },
    "Login & capture customers when they visit your stores": {
      premium: true,
      pro: false,
    },
    "Manage customer details from app": { premium: true, pro: false },
    "Showcase all your products for selection in the app": {
      premium: true,
      pro: false,
    },
    "Enable your sales team to personalize experience": {
      premium: true,
      pro: false,
    },
    "Track, Understand & Analyze your customer requirements": {
      premium: true,
      pro: false,
    },
    "Track & Analyze your Sales Team Performance": {
      premium: true,
      pro: false,
    },
    "Integrated with CRM": { premium: true, pro: false },
    "Check customer wise details visited in-store and products they viewed": {
      premium: true,
      pro: false,
    },
    "Showroom visits analytics": { premium: true, pro: false },
  },

  Apps: {
    "iOS App for Customers": { premium: true, pro: true },
    "Android App for Customer": { premium: true, pro: true },
    "Admin App for Teams": { premium: true, pro: true },
    "App Submission to App Store/Play Store": { premium: true, pro: true },
    "Manage Updates, Bugs on App Store": { premium: true, pro: true },
    "Upgrade Apps as per product updates": { premium: true, pro: true },
  },
  Chat: {
    Chats: { premium: 100000, pro: 100000 },
    "One Dashboard for all your Communication": { premium: true, pro: true },
    "Whatsapp, Facebook, Instagram Integration": { premium: true, pro: true },
    "Convert Chats to Contact in CRM": { premium: true, pro: true },
    "Map chats to existing customers in CRM": { premium: true, pro: true },
    "Assign Chats to a Team Member": { premium: true, pro: true },
    "Tag and manage chats": { premium: true, pro: true },
    "Share images and emojis": { premium: true, pro: true },
    "Share products from within chats": { premium: true, pro: true },
    "Send Template messages": { premium: true, pro: true },
    "Filter Chats": { premium: true, pro: true },
  },
  Whatsapp: {
    "Official Whatsapp API Account": { premium: true, pro: true },
    "Enquire on Whatsapp": { premium: true, pro: true },
    "Whatsapp Chat on website": { premium: true, pro: true },
    "Whatsapp Bot": { premium: true, pro: true },
    "Whatsapp Catalog Sync with Products": { premium: true, pro: true },
    "Whatsapp Template Message Management & Submission": {
      premium: true,
      pro: true,
    },
    "Whatsapp Manager Integration": { premium: true, pro: true },
    "Whatsapp Chat Leads to Auto CRM Contact": { premium: true, pro: true },
    "Auto Whatsapp Notifications on Birthdays, Anniversaries, Payments, Orders, Digital Gold, Ledgers":
      { premium: true, pro: true },
    "Whatsapp Bulk Promotion & Campaigns": { premium: true, pro: true },
    "Whatsapp Abandoned Cart & Wishlist": { premium: true, pro: true },
    "Whatsapp Customer Notes & Reminders": { premium: true, pro: true },
  },
  Marketplaces: {
    "API Calls": { premium: 100000, pro: 50000 },
    "Facebook Shop – 500 Products": { premium: true, pro: true },
    "Instagram Shop – 500 Products": { premium: true, pro: true },
    "Google Shop – 500 Products": { premium: true, pro: true },
    "Amazon Marketplace – 100 Products": { premium: true, pro: true },
    "Ebay Marketplace – 100 Products": { premium: true, pro: true },
    "Real-time Product & Pricing Update on all channels": {
      premium: true,
      pro: true,
    },
  },
  "Marketing & Promotions": {
    "Email Notifications": { premium: true, pro: true },
    "Push Notification": { premium: true, pro: true },
    "Whatsapp Notifications": { premium: true, pro: true },
    "Chat Notifications": { premium: true, pro: true },
    "Dynamic Discount offers": { premium: true, pro: true },
    "Dynamic Coupon Code Management": { premium: true, pro: true },
    "Pop-ups & Notification": { premium: true, pro: true },
    "Automate SEO Content ": { premium: true, pro: true },
    "Social Media Updates & Notification": { premium: true, pro: true },
    "Social Media Comments & Reply": { premium: true, pro: true },
    "Whatsapp Bulk Messaging & Promotions": { premium: true, pro: true },
    "App Notifications": { premium: true, pro: true },
    "Gold Cashbacks": { premium: true, pro: true },
  },
  "Social Sharing": {
    "Posts Per Day (Each Channel)": { premium: 5, pro: 5 },
    "Facebook, Instagram, Google Integration": { premium: true, pro: true },
    "Pinterest, Linkedin – Coming in Feb 23": { premium: true, pro: true },
    "Reply & Manage Comments ": { premium: true, pro: true },
    "Likes to Contacts – Coming in Feb 23": { premium: true, pro: true },
    "Schedule posts": { premium: true, pro: true },
  },
  "Digital Gold": {
    "Unlimited Plans (Amount, Weight & Both)": { premium: true, pro: true },
    "Unlimited Transactions": { premium: true, pro: true },
    "Customer Management": { premium: true, pro: true },
    "Multiple Rewards at Multiple levels": { premium: true, pro: true },
    "Assign Rewards, Cashbacks, Gifts & Penalty": { premium: true, pro: true },
    "Customer Limit for each Plan to add exclusivity": {
      premium: true,
      pro: true,
    },
    "Enable/Disable Advance Payments": { premium: true, pro: true },
    "Payment Reminders & Notifications": { premium: true, pro: true },
    "Monthly Ledger Update Notification, email & download": {
      premium: true,
      pro: true,
    },
    "Missed Payment Alerts": { premium: true, pro: true },
    "Account Closure & Settlements": { premium: true, pro: true },
    "Manage from Website, Apps & Backend": { premium: true, pro: true },
    "Collect online & Offline Payments": { premium: true, pro: true },
    "Multiple Reports, tracking & Analytics": { premium: true, pro: true },
    "Assign customers to Team Members": { premium: true, pro: true },
    "Real-time ledger view for customer on website & Apps": {
      premium: true,
      pro: true,
    },
  },
  Integrations: {
    Google: { premium: true, pro: true },
    "Google Business": { premium: true, pro: true },
    "Tag Manager": { premium: true, pro: true },
    "Google Sheets": { premium: true, pro: true },
    "Google Merchant Center": { premium: true, pro: true },
    Analytics: { premium: true, pro: true },
    Firebase: { premium: true, pro: true },
    "Google Site Verification": { premium: true, pro: true },
    Meta: { premium: true, pro: true },
    Facebook: { premium: true, pro: true },
    Instagram: { premium: true, pro: true },
    Whatsapp: { premium: true, pro: true },
    "Commerce (FB Shop, Insta Shop, Whatsapp Catalog)": {
      premium: true,
      pro: true,
    },
    Onesignal: { premium: true, pro: true },
    Linkedin: { premium: true, pro: true },
    "Payment Gateways": { premium: true, pro: true },
    PayU: { premium: true, pro: true },
    CCAvenue: { premium: true, pro: true },
    Razorpay: { premium: true, pro: true },
    EaseBuzz: { premium: true, pro: true },
    Cashfree: { premium: true, pro: true },
    "Virtual TryOn": { premium: true, pro: true },
    JewelTryStudio: { premium: true, pro: true },
    Camweara: { premium: true, pro: true },
    ERPs: { premium: true, pro: true },
    Ornate: { premium: true, pro: true },
    Marketplaces: { premium: true, pro: true },
    "Amazon – Launching soon": { premium: true, pro: true },
    "Ebay– Launching soon": { premium: true, pro: true },
    "ONDC– Launching soon": { premium: true, pro: true },
    Logistics: { premium: true, pro: true },
    "Blue Dart": { premium: true, pro: true },
    BVC: { premium: true, pro: true },
    Automation: { premium: true, pro: true },
    "Zapier – Launching soon": { premium: true, pro: true },
  },
  Analytics: {
    "One Dashboard for Analytics – Launching in March ‘23": {
      premium: true,
      pro: true,
    },
    "Website Analytics": { premium: true, pro: true },
    "Apps Analytics": { premium: true, pro: true },
    "In-Store Analytics": { premium: true, pro: true },
    "Catalog Analytics": { premium: true, pro: true },
    "CRM Analytics": { premium: true, pro: true },
    "Digital Gold Analytics": { premium: true, pro: true },
    "Order Analytics": { premium: true, pro: true },
    "Chats Analytics": { premium: true, pro: true },
    "Whatsapp Analytics": { premium: true, pro: true },
    "Messenger Analytics": { premium: true, pro: true },
    "Facebook Page Analytics": { premium: true, pro: true },
    "Facebook Shop Analytics": { premium: true, pro: true },
    "Google Business Analytics": { premium: true, pro: true },
    "Google Shop Analytics": { premium: true, pro: true },
    "Instagram Page Analytics": { premium: true, pro: true },
    "Amazon Analytics": { premium: true, pro: true },
    "Ebay Analytics": { premium: true, pro: true },
  },
  Security: {
    "Secure Sockets Layer (SSL)": { premium: true, pro: true },
    "Secured Cloud Server": { premium: true, pro: true },
    "Captcha Secure": { premium: true, pro: true },
    Firewalls: { premium: true, pro: true },
    "IP Lockouts": { premium: true, pro: true },
    Masking: { premium: true, pro: true },
    "Secured against DDoS, SQLs, XSS, Brute Force Attacks": {
      premium: true,
      pro: true,
    },
    "Daily Backups": { premium: true, pro: true },
  },
  Support: {
    "Response Time (Working Hours)": { premium: "24 Hrs", pro: "24 hrs" },
    "Onboarding Videos": { premium: "Free", pro: "Free" },
    "Support Video & Documentation": { premium: "Free", pro: "Free" },
    "Ticket & Email Support": { premium: "Free", pro: "Free" },
  },
  "Add-Ons": {
    Users: { premium: "900/user/m", pro: "900/user/m" },
    "Products (500)": { premium: 4500, pro: 4500 },
    "Catalogs (1000)": { premium: 3500, pro: 3500 },
    "CRM (1000)": { premium: 4500, pro: 4500 },
    "API Calls (500k)": { premium: 5000, pro: 5000 },
    "Chats (500k)": { premium: 6000, pro: 6000 },
    Currency: { premium: "9000 each", pro: "9000 each" },
    "In-person Support (3 Hrs)*": { premium: 18000, pro: 18000 },
  },
};

const pricing = {
  yearly: { premium: 432000, pro: 180000 },
  monthly: { premium: 45000, pro: 22500 },
};

function PricingTable() {
  const theme = useTheme();
  let [selected, setSelected] = useState("monthly");
  const [selectedPlanType, setSelectedPlanType] = useState("Public");
  const [selectedPlan, setSelectedPlan] = useState("");
  const [currency, setCurrency] = useState("₹");
  const [textColor, setTextColor] = useState({
    premium: "#012687",
    // pro: theme?.palette?.primary1,
    pro: "#f6b11c",
  });
  const [customer, setCustomer] = useState({});
  const [customerLoading, setCustomerLoading] = useState(false);
  const customer_id = useSelector((state) => state.user.id);
  useEffect(() => {
    if (!customer_id) return;
    setupCustomer(customer_id);
  }, [customer_id]);

  const setupCustomer = async (id) => {
    let customer = await fetchCustomer(id, setCustomerLoading);
    if (customer) setCustomer(customer);
  };

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = (v) => {
    setOpen(true);
    setSelectedPlan(v);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onPlanSelct = () => {
    let data = {
      first_name: customer?.first_name || customer?.billing?.first_name,
      last_name: customer?.last_name || customer?.billing?.last_name,
      email: customer?.email || customer?.billing?.email,
      phone: customer?.phone || customer?.billing?.phone,
    };
    let plan = "";
    if (selected == "monthly") if (selectedPlan === "pro") plan = "TJORMPRO";
    if (selected == "yearly") if (selectedPlan === "pro") plan = "TJORYPRO";
    if (selected == "monthly") if (selectedPlan === "premium") plan = "TJORM";
    if (selected == "yearly") if (selectedPlan === "premium") plan = "TJORY ";
    data = Object.fromEntries(
      Object.entries(data).filter(([_, v]) => Boolean(v))
    );
    console.log(JSON.stringify(data, null, 2), plan);
    window.open(
      `https://subscriptions.zoho.in/subscribe/df42113c47f9b9ebbff79be9258182ac5074be6814bacb080823bcb88ecfdc2b/${plan}?${new URLSearchParams(
        data
      ).toString()}`,
      "_self"
    );
    // window.open(
    //   `https://subscriptions.zoho.in/subscribe/fd79bbcc627a8a8ecb24adb313fff7761525b7cd06fce3e40823bcb88ecfdc2b/${plan}?${new URLSearchParams(
    //     data
    //   ).toString()}`
    //   // "_blank"
    // );
  };

  return (
    <Box sx={{ textAlign: "center" }}>
      <Box>
        <img src="/static/img/brands/jwero_logo.png" height={"60px"} />
      </Box>
      <ButtonGroup sx={{ mt: 3 }}>
        <Button
          variant={selected == "monthly" ? "contained" : "outlined"}
          onClick={() => setSelected("monthly")}
        >
          Monthly
        </Button>
        <Button
          variant={selected == "yearly" ? "contained" : "outlined"}
          onClick={() => setSelected("yearly")}
        >
          Yearly
        </Button>
      </ButtonGroup>
      <Card sx={{ maxWidth: { xs: "100%", md: "70%" }, margin: "10px auto" }}>
        <CardContent>
          <Grid container spacing={0}>
            <Grid item xs={4}>
              <Typography variant="h4" sx={{ py: 3 }}>
                Select your plan
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Box
                sx={{
                  textAlign: "center",
                  backgroundColor: textColor.pro,
                  py: 3,
                }}
              >
                <Typography variant="h4" sx={{ color: "#fff" }}>
                  Pro
                </Typography>
              </Box>
              <Typography variant="h2" sx={{ color: textColor.pro }} mt={6}>
                {currency}{" "}
                {selected === "yearly"
                  ? pricing?.[selected]?.pro / 12
                  : pricing?.[selected]?.pro}
                /mo
              </Typography>
              <Typography variant="h6" sx={{ color: textColor.pro }}>
                Paid {selected}
              </Typography>
              <Chip
                sx={{ mt: 5, color: textColor.pro }}
                // color={textColor.pro}
                label="Get started"
                variant="outlined"
                onClick={() => handleClickOpen("pro")}
              />
            </Grid>
            <Grid item xs={4}>
              <Box
                sx={{
                  textAlign: "center",
                  backgroundColor: textColor.premium,
                  py: 3,
                }}
              >
                <Typography variant="h4" sx={{ color: "#fff" }}>
                  Premium
                </Typography>
              </Box>
              <Typography variant="h2" sx={{ color: textColor.premium }} mt={6}>
                {currency}{" "}
                {selected === "yearly"
                  ? pricing?.[selected]?.premium / 12
                  : pricing?.[selected]?.premium}
                /mo
              </Typography>
              <Typography variant="h6" sx={{ color: textColor.premium }}>
                Paid {selected}
              </Typography>
              <Chip
                sx={{ mt: 5, color: textColor.premium }}
                // color={textColor.pro}
                label="Get started"
                variant="outlined"
                onClick={() => handleClickOpen("premium")}
              />
            </Grid>
          </Grid>

          {Object.keys(pricingJSON).map((i) => {
            let features = pricingJSON[i];

            return (
              <Box>
                <Typography
                  sx={{ fontSize: "16px", textAlign: "left", mt: 4, mb: 2 }}
                >
                  {i}
                </Typography>
                {Object.keys(features).map((feature) => {
                  return (
                    <Grid container sx={{ margin: "8px 0px" }}>
                      <Grid item xs={4}>
                        <Typography
                          sx={{
                            color: "text.secondary",
                            textAlign: "left",
                            ml: 2,
                          }}
                        >
                          {feature}
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography sx={{ fontWeight: "600" }}>
                          {features?.[feature]?.pro === true
                            ? "✓"
                            : features?.[feature]?.pro}
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography sx={{ fontWeight: "600" }}>
                          {features?.[feature]?.premium === true
                            ? "✓"
                            : features?.[feature]?.premium}
                        </Typography>
                      </Grid>
                    </Grid>
                  );
                })}
                <Divider sx={{ mt: 2 }} />
              </Box>
            );
          })}
        </CardContent>
      </Card>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{ ...dialogStyle }}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="alert-dialog-title">
          Select your cloud space preferences
        </DialogTitle>
        <DialogContent>
          <Stack direction="row" spacing={5} my={2} justifyContent="center">
            <Box
              sx={{
                border:
                  selectedPlanType === "Public"
                    ? "1px solid #000"
                    : "1px solid #e8e8e8",
                borderRadius: "6px",
                p: 3,
                cursor: "pointer",
              }}
              onClick={() => setSelectedPlanType("Public")}
            >
              <CloudCircle
                style={{ height: "80px", width: "80px", padding: "10px" }}
              />
              <Typography sx={{ textAlign: "center", mt: 2 }}>
                Public
              </Typography>
              <Typography
                sx={{
                  textAlign: "center",
                  fontSize: "11px",
                  color: "text.secondary",
                  mt: 0.5,
                }}
              >
                Free
              </Typography>
            </Box>
            <Box
              sx={{
                border:
                  selectedPlanType === "Private"
                    ? "1px solid #000"
                    : "1px solid #e8e8e8",
                borderRadius: "6px",
                p: 3,
                cursor: "pointer",
              }}
              // onClick={() => setSelectedPlanType("Private")}
            >
              <CloudOff
                style={{ height: "80px", width: "80px", padding: "10px" }}
              />
              <Typography sx={{ textAlign: "center", mt: 2 }}>
                Private
              </Typography>

              <Typography
                sx={{
                  textAlign: "center",
                  fontSize: "11px",
                  color: "text.secondary",
                  mt: 0.5,
                }}
              >
                (Coming soon)
              </Typography>
            </Box>
            <Box
              sx={{
                border:
                  selectedPlanType === "Self Hosted"
                    ? "1px solid #000"
                    : "1px solid #e8e8e8",
                borderRadius: "6px",
                p: 3,
                cursor: "pointer",
              }}
              // onClick={() => setSelectedPlanType("Self Hosted")}
            >
              <Storage
                style={{ height: "80px", width: "80px", padding: "10px" }}
              />
              <Typography sx={{ textAlign: "center", mt: 2 }}>
                Self Hosted
              </Typography>
              <Typography
                sx={{
                  textAlign: "center",
                  fontSize: "11px",
                  color: "text.secondary",
                  mt: 0.5,
                }}
              >
                (Coming soon)
              </Typography>
            </Box>
          </Stack>
          <Stack
            direction="row"
            alignItems={"center"}
            justifyContent="center"
            spacing={2}
            mt={4}
            mb={2}
          >
            <Box sx={{ textAlign: "center" }}>
              <Typography sx={{ fontSize: "16px", fontWeight: "600" }}>
                {currency} {pricing?.[selected]?.[selectedPlan]}
              </Typography>
              {/* <Typography sx={{ fontSize: "10px", color: "text.secondary" }}>
               
              </Typography> */}
            </Box>
            <Typography sx={{ fontSize: "16px", fontWeight: "600" }}>
              + Free = {currency} {pricing?.[selected]?.[selectedPlan]}
            </Typography>
            <Typography sx={{ fontSize: "16px", fontWeight: "600", ml: 1 }}>
              ({selected} {selectedPlan} plan)
            </Typography>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
          <LoadingButton
            loading={customerLoading}
            onClick={onPlanSelct}
            autoFocus
            variant="contained"
          >
            Proceed to pay {currency} {pricing?.[selected]?.[selectedPlan]}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default PricingTable;
