import {
  ArrowBackIos,
  ArrowForward,
  ArrowForwardIos,
  CalendarToday,
  HelpOutline,
  Inventory2Outlined,
  LocationOn,
  Store,
  Storefront,
  Visibility,
} from "@mui/icons-material";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Grid,
  IconButton,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { sidebarExtraWidth } from "../../../App";
import {
  formatDate,
  getRandomRangeNumbers,
  isArrayWithValues,
  isObjWithValues,
  primaryLabel,
  reduceLargeText,
  secondaryLabel,
  sectionLabel,
} from "../../../helper";
import { drawerWidth } from "../../../layouts/Dashboard";
import { fetchRecentCustomers } from "../../../redux/actions/crmActions";
import {
  InstagramSvg,
  Messenger,
  UserCatalogsSvg,
  Whatsapp,
} from "../../../Svgs";
import { NoTasks } from "../../chat/ChatHelper";
import { SwiperSlide, Swiper } from "swiper/react";
import { Controller, Navigation } from "swiper";
import "swiper/css"; // core Swiper

function MostViewedCatalogProducts({}) {
  //   const recentCustomers =
  //     useSelector((state) => state.customers.recentCustomers) || [];
  const [recentCustomers, setRecentCustomers] = useState([]);
  const fetchingRecentCustomers = useSelector(
    (state) => state.customers.fetchingRecentCustomers
  );
  const theme = useTheme();
  const isIpad = useMediaQuery(theme.breakpoints.down("md"));

  const dashboardDetails = useSelector(
    (state) => state.settings.dashboardDetails
  );
  const fetchingDashboardDetails = useSelector(
    (state) => state.settings.fetchingDashboardDetails
  );
  const top_products = useSelector((state) => state.visitor.top_products);

  const dispatch = useDispatch();
  useEffect(() => {
    if (isArrayWithValues(top_products)) setRecentCustomers(top_products);
  }, [top_products]);
  return (
    <Card sx={{ borderRadius: "15px" }}>
      <CardContent>
        <Stack direction="row" alignItems={"center"} spacing={2}>
          <Typography sx={{ ...sectionLabel }}>Most viewed products</Typography>
          <Tooltip title={"Products which are highest viewed by contacts"}>
            <HelpOutline
              fontSize="small"
              style={{ opacity: "0.5", height: "16px", width: "16px" }}
              color="text.secondary"
            />
          </Tooltip>
        </Stack>
        {!fetchingRecentCustomers && !isArrayWithValues(recentCustomers) && (
          <NoTasks
            sx={{ my: 25 }}
            text={
              <Stack justifyContent={"center"} alignItems="center" rowGap={2}>
                No products at this moment
              </Stack>
            }
            icon={
              <Inventory2Outlined
                style={{
                  height: "70px",
                  width: "70px",
                  stroke: "#ffffff",
                  strokeWidth: 1.3,
                }}
                color="primary"
              />
            }
          />
        )}
        {/* <Typography sx={{ ...secondaryLabel, mt: 1 }}>
          You have 7 new customers
        </Typography> */}
        {fetchingRecentCustomers ? (
          <>
            {new Array(5).fill("").map((str, index) => (
              <LoadingRecentCustomer key={index} />
            ))}
          </>
        ) : null}
        {!fetchingRecentCustomers && (
          <>
            <Stack
              direction="row"
              overflow={"scroll"}
              className="scrollbar-hidden"
              //   spacing={3}
              sx={{
                maxWidth: !isIpad
                  ? `calc(100vw - ${sidebarExtraWidth + 300}px)`
                  : "100%",
              }}
            >
              {recentCustomers?.slice(0, 5)?.map((customer) => (
                <CustomerCard customer={customer} />
              ))}
            </Stack>
            <Stack
              direction="row"
              overflow={"scroll"}
              className="scrollbar-hidden"
              //   spacing={3}
              sx={{
                maxWidth: !isIpad
                  ? `calc(100vw - ${sidebarExtraWidth + 300}px)`
                  : "100%",
              }}
            >
              {recentCustomers?.slice(5, 10)?.map((customer) => (
                <CustomerCard customer={customer} />
              ))}
            </Stack>
          </>
        )}
        {!fetchingRecentCustomers && false && (
          <>
            <Stack
              direction="row"
              alignItems={"center"}
              sx={{
                maxWidth: !isIpad
                  ? `calc(100vw - ${sidebarExtraWidth + 300}px)`
                  : "100%",
              }}
            >
              {/* <IconButton>
                <ArrowBackIos />
              </IconButton> */}
              <Swiper
                spaceBetween={30}
                slidesPerView={5}
                onSlideChange={() => console.log("slide change")}
                onSwiper={(swiper) => console.log(swiper)}
                modules={[Navigation]}
                navigation={true}
                style={{
                  "--swiper-navigation-color": "#808080",
                  "--swiper-pagination-color": "#808080",
                  position: "relative",

                  maxWidth: !isIpad
                    ? `calc(100vw - ${sidebarExtraWidth + 300}px)`
                    : "100%",
                }}
                pagination={{
                  type: "fraction",
                }}
                breakpoints={{
                  // when window width is >= 320px
                  320: {
                    slidesPerView: 1,
                    spaceBetween: 24,
                  },
                  // when window width is >= 480px
                  480: {
                    slidesPerView: 2,
                    spaceBetween: 24,
                  },
                  // when window width is >= 640px
                  640: {
                    slidesPerView: 2,
                    spaceBetween: 24,
                  },
                  1024: {
                    slidesPerView: 4,
                    spaceBetween: 32,
                    slidesPerGroup: 1,
                  },
                  1336: {
                    slidesPerView: 5,
                    spaceBetween: 32,
                  },
                }}
              >
                {recentCustomers.map((customer) => (
                  <SwiperSlide>
                    <CustomerCard customer={customer} />
                  </SwiperSlide>
                ))}
              </Swiper>
              {/* <IconButton>
                <ArrowForwardIos />
              </IconButton> */}
            </Stack>
          </>
        )}
      </CardContent>
    </Card>
  );
}

export default MostViewedCatalogProducts;

const CustomerCard = ({ customer }) => {
  const [customerInfo, setCustomerInfo] = useState(customer);
  let fullName = `${customer?.first_name || ""} ${customer?.last_name || ""}`;
  const navigate = useNavigate();
  useEffect(() => {
    let { meta_data } = customer;
    let customerObj = { ...customer };

    const metaKeys = [
      "whatsapp",
      "profile_url",
      "whatsapp_id",
      "facebook_id",
      "instagram_id",
      "wc_last_active",
    ];
    if (isArrayWithValues(meta_data)) {
      for (let metaObj of meta_data) {
        if (metaKeys.includes(metaObj.key) && metaObj.value)
          customerObj[metaObj.key] = metaObj.value;
      }
    }
    setCustomerInfo(customerObj);
    // setCustomerInfoObj(customer)
  }, []);

  return (
    <Stack
      direction={"row"}
      sx={{
        mt: 5,
        mb: 5,
        mx: 3,
        minWidth: "140px",
        textAlign: "center",
        // alignItems: "center",
      }}
      // sx={{ textAlign: "center", justifyContent: "center" }}
      gap={"8px"}
    >
      <Box
        sx={{
          textAlign: "center",
          alignItems: "center",
          justifyContent: "center",
          cursor: "pointer",
        }}
        onClick={() => navigate(`/products/edit/${customer?.id}`)}
      >
        <Avatar
          src={customer?.image}
          //   sx={{ height: "150px", width: "150px" }}
          sx={{ width: 100, height: 100, margin: "auto", marginTop: 0 }}
        />
        <Typography sx={{ ...primaryLabel, mt: 2 }}>
          {reduceLargeText(`${customer.name || ""}`, 12)}
        </Typography>
        <Typography sx={{ ...secondaryLabel, mt: 1 }}>
          {reduceLargeText(`${customer.sku || ""}`, 12)}
        </Typography>
        <Stack direction="row" alignItems={"center"} justifyContent="center">
          <Visibility style={{ fontSize: "13px", mr: 1 }} />
          <Typography sx={{ ...secondaryLabel, ml: 1 }}>
            {customer.views}
          </Typography>
        </Stack>
      </Box>
      {/* <Stack sx={{ width: "-webkit-fill-available" }}>
          <Stack direction="row" alignItems={"center"}>
            <Typography fontSize={"16px"} fontWeight={"500"}>
              {fullName}
            </Typography>
            <Box sx={{ ml: 2 }}>
              {customerInfo?.facebook_id ? (
                <Messenger height={17} width={17} />
              ) : customerInfo?.instagram_id ? (
                <InstagramSvg height={17} width={17} />
              ) : customerInfo?.whatsapp_id ? (
                <Whatsapp height={17} width={17} />
              ) : (
                <Storefront style={{ height: "17px", width: "17px" }} />
              )}
            </Box>
          </Stack>
          <Stack
            direction={"row"}
            alignItems="center"
            justifyContent={"space-between"}
            sx={{ width: "80%" }}
          >
            <Typography sx={{ fontSize: "13px", color: "text.secondary" }}>
              Last active :{" "}
              {customerInfo?.wc_last_active
                ? formatDate(
                    new Date(Number(customerInfo?.wc_last_active) * 1000),
                    "dd:mm:yyyy hh:mm"
                  )
                : ""}
            </Typography>
          </Stack>
        </Stack>
        <IconButton onClick={() => navigate(`/crm/${customerInfo?.id}`)}>
          <ArrowForward />
        </IconButton> */}
    </Stack>
  );
};

const LoadingRecentCustomer = () => {
  return (
    <Stack
      direction={"row"}
      sx={{ mt: 5, mb: 5 }}
      // sx={{ textAlign: "center", justifyContent: "center" }}
      gap={"8px"}
    >
      <Skeleton variant="circular" width={40} height={40} />

      <Stack sx={{ width: "-webkit-fill-available" }}>
        <Skeleton variant="text" sx={{ fontSize: "1rem", width: "180px" }} />

        <Stack
          direction={"row"}
          alignItems="center"
          justifyContent={"space-between"}
          sx={{ width: "80%" }}
        >
          <Skeleton variant="text" sx={{ fontSize: "1rem", width: "100px" }} />
          <Skeleton variant="circular" width={25} height={25} />
        </Stack>
      </Stack>
    </Stack>
  );
};
