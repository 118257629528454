import { Card, CardContent, Stack, Typography } from "@mui/material";

const AnalyticsCard = ({ title, value, suffix, sx }) => {
    return (
        <Card
            sx={{
                ...sx,
                border: "0.5px solid #e8e8e8",
                height: "100%",
                backgroundColor: "#ecedf1",
            }}
        >
            <CardContent sx={{
                padding: "7px",
                paddingBottom: "7px"
                // paddingBottom: "3px",
            }}>
                <Typography variant="h5" fontSize="12px" fontWeight={"500"}>
                    {title}
                </Typography>
                <Stack mt={2} alignItems="flex-end" direction={"row"} flexWrap="wrap">
                    <Typography variant="h5" fontSize="22px" fontWeight={"500"}>
                        {value}
                    </Typography>
                    <Typography
                        ml={1}
                        variant="body2"
                        fontSize="16px"
                        color="text.secondary"
                    >
                        {suffix}
                    </Typography>
                </Stack>
            </CardContent>
        </Card >
    );
};

export default AnalyticsCard;