import { Add } from "@material-ui/icons";
import {
  DesktopDateTimePicker,
  LoadingButton,
  LocalizationProvider,
} from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import {
  Autocomplete,
  Box,
  Button,
  ButtonGroup,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import Switch from "rc-switch";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  dialogStyle,
  fetchCustomers,
  formatDate,
  formatServerValue,
  getRandomString,
  getToken,
  getWebsite,
  isArrayWithValues,
  isObjWithValues,
} from "../../helper";
import {
  addUserEvent,
  addUserTask,
  deleteUserTask,
  getUsersWithTasks,
  updateUserTask,
} from "../../redux/actions/chatActions";
import CallTable from "./CallTable";
import {
  primaryLabel,
  PrimaryLabel,
  secondaryLabel,
  sectionLabel,
  userActivityStatus,
  userActivityTitle,
} from "./ChatHelper";
import ReminderTable from "./ReminderTable";
import TaskTable from "./TaskTable";

function UserTasks({ customer, user_from = "chat" }) {
  const [formOpen, setFormOpen] = useState(false);
  const [editForm, setEditForm] = useState(false);
  const openForm = () => setFormOpen(true);
  const closeForm = () => setFormOpen(false);
  const dispatch = useDispatch();
  const users_with_tasks_fetched = useSelector(
    (state) => state.chats.users_with_tasks_fetched
  );
  const [deleteFormOpen, setDeleteFormOpen] = useState(false);

  useEffect(() => {
    if (!users_with_tasks_fetched) dispatch(getUsersWithTasks());
  }, []);
  const openDeleteForm = (edit) => {
    setEditForm(edit);
    setDeleteFormOpen(true);
  };
  const closeDeleteForm = () => setDeleteFormOpen(false);

  const openUpdateForm = (edit) => {
    setEditForm(edit);
    setFormOpen(true);
  };

  return (
    <Box sx={{ p: 3, overflowY: "scroll", height: "calc(100vh - 226px)" }}>
      <Stack
        direction="row"
        justifyContent={"space-between"}
        alignItems="center"
      >
        <Typography sx={{ ...primaryLabel }}>
          Recent activities of User
        </Typography>
        <Button startIcon={<Add />} onClick={openForm} variant="outlined">
          Create task
        </Button>
      </Stack>

      <TaskTable
        customer={customer}
        user_from={user_from}
        openUpdateForm={openUpdateForm}
        openDeleteForm={openDeleteForm}
      />
      <ReminderTable
        customer={customer}
        user_from={user_from}
        openUpdateForm={openUpdateForm}
        openDeleteForm={openDeleteForm}
      />
      <CallTable
        customer={customer}
        user_from={user_from}
        openUpdateForm={openUpdateForm}
        openDeleteForm={openDeleteForm}
      />

      <Dialog
        open={formOpen}
        keepMounted
        onClose={closeForm}
        aria-describedby="alert-dialog-slide-description"
        maxWidth="sm"
        fullWidth
        sx={{ ...dialogStyle }}
      >
        <CreateUserTask
          customer={customer}
          user_from={user_from}
          updateForm={editForm}
          closeForm={closeForm}
        />
      </Dialog>
      <Dialog
        open={deleteFormOpen}
        keepMounted
        onClose={closeDeleteForm}
        aria-describedby="alert-dialog-slide-description"
        maxWidth="sm"
        fullWidth
        sx={{ ...dialogStyle }}
      >
        <DeleteUserTask closeForm={closeDeleteForm} deleteForm={editForm} />
      </Dialog>
    </Box>
  );
}

export default UserTasks;

export const DeleteUserTask = ({ deleteForm, customer, closeForm }) => {
  const [deleting, setDeleting] = useState(false);
  const dispatch = useDispatch();
  const onDelete = () => {
    dispatch(deleteUserTask(deleteForm, setDeleting, () => closeForm()));
  };
  return (
    <>
      <DialogTitle>
        Delete{" "}
        <strong>
          {formatServerValue(deleteForm?.title)} to{" "}
          {deleteForm?.userDetails?.name}
        </strong>
        ?
      </DialogTitle>
      <DialogContent>
        <DialogContentText>This action cannot be undone.</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeForm}>Cancel</Button>
        <LoadingButton
          loading={deleting}
          color="error"
          onClick={() => onDelete(deleteForm)}
        >
          Delete
        </LoadingButton>
      </DialogActions>
    </>
  );
};

const defaultForm = {
  remind_label: "minutes",
  remind_value: 10,
  date: new Date(),
  owner: "admin",
  type: "task",
  title: "send_catalog",
  status: "in_progress",
};
export const CreateUserTask = forwardRef(
  (
    { customer: customerProp, closeForm, updateForm, user_from = "chat" },
    ref
  ) => {
    const [customer, setCustomer] = useState({});
    const allReduxConversations =
      useSelector((state) => state.chats.conversations) || [];
    const [form, setForm] = useState(defaultForm);
    const [loading, setLoading] = useState(false);
    let [isUpdate, setIsUpdate] = useState(false);
    const [allCustomers, setAllCustomers] = useState([]);
    const editForm = (payload) =>
      setForm((state) => ({ ...state, ...payload }));
    const [selectedCustomer, setSelectedCustomer] = useState("");
    const [error, setError] = useState(false);

    useEffect(() => {
      if (isArrayWithValues(allReduxConversations)) {
        setAllCustomers(
          allReduxConversations
            .map((i) => ({
              label: `${i?.first_name?.trim() || ""} ${
                i?.last_name?.trim() || ""
              } ${i?.name?.trim() || ""}`.trim(),
              value: i.id,
            }))
            .filter((i) => Boolean(i.label) && Boolean(i.value))
        );
      }
    }, [allReduxConversations]);

    useEffect(() => {
      setIsUpdate(isObjWithValues(updateForm));
      if (isObjWithValues(updateForm)) {
        let { date, date_created } = updateForm;
        let obj = {
          ...updateForm,
          date: new Date(date),
          date_created: new Date(date_created),
        };
        editForm(obj);
      } else editForm(defaultForm);
    }, [updateForm]);
    useEffect(() => {
      if (isObjWithValues(customerProp)) setCustomer(customerProp);
    }, [customerProp]);

    const theme = useTheme();
    const dispatch = useDispatch();
    let backgroundColor = theme?.palette?.primary?.main;

    const teamMembers = useSelector((state) => state.settings.teamMembers);
    const usersWithTasks = useSelector((state) => state.chats.users_with_tasks);
    const [customerSearching, setCustomerSearching] = useState(false);
    const [customerSearched, setSearchedCustomer] = useState([]);
    const [customerSearch, setCustomerSearch] = useState("");
    let customerSearchTimeout;

    const [members, setMembers] = useState([]);

    useEffect(() => {
      let members = [];
      if (isArrayWithValues(teamMembers)) {
        members = members.concat(
          teamMembers.map((i) => ({
            label: `${i.first_name || ""} ${i.last_name || ""}`,
            value: i.id,
          }))
        );
      }
      members.unshift({
        label: "Admin",
        value: "admin",
      });
      setMembers(members);
    }, [teamMembers]);

    const onUpdateUserTask = () => {
      let obj = { ...form };
      if (!obj.show_remind) {
        delete obj.remind_label;
        delete obj.remind_value;
      }
      obj.is_admin = obj.owner == "admin";
      obj.date = new Date(obj.date).getTime();
      obj.date_modified = new Date().getTime();
      obj.user_from = user_from;
      obj.date_created = new Date(obj.date_created).getTime();
      delete obj.userDetails;
      delete obj.teamMemberInfo;
      dispatch(updateUserTask(obj, setLoading, () => closeForm()));
    };
    const onAddUserTask = () => {
      setLoading(true);
      let obj = { ...form };
      let _customer = isObjWithValues(customer) ? customer : "";
      if (!_customer) {
        let temp = allReduxConversations?.find(
          (i) => i.id == selectedCustomer?.value
        );
        if (temp) _customer = temp;
      }
      if (!obj.show_remind) {
        delete obj.remind_label;
        delete obj.remind_value;
      }
      obj.is_admin = obj.owner == "admin";
      obj.date = new Date(obj.date).getTime();
      obj.user_id = _customer?.id;
      obj.date_created = new Date().getTime();
      obj.user_from = user_from;
      obj.id = getRandomString(14);
      if (!obj.user_id && isObjWithValues(selectedCustomer)) {
        obj.user_id = selectedCustomer?.value;
      }
      console.log(obj);
      if (!usersWithTasks?.[obj.user_id]) {
        dispatch(
          addUserEvent({
            id: obj?.user_id,
            time: new Date().getTime(),
            platform: _customer?.platform,
          })
        );
      }
      dispatch(addUserTask(obj, setLoading, () => closeForm()));
    };

    const onCustomerSearch = async (value) => {
      setCustomerSearch(value);
      if (value.length >= 3)
        customerSearchTimeout = setTimeout(() => {
          fetchCustomers(
            value,
            (arr) => {
              setSearchedCustomer(
                arr?.map((i) => ({
                  label: `${i.first_name || ""} ${i.last_name}`.trim(),
                  value: i.id,
                }))
              );
            },
            setCustomerSearching
          );
        }, 400);
    };

    return (
      <DialogContent>
        <Stack
          direction={"row"}
          alignItems="center"
          justifyContent={"space-between"}
          flexWrap="wrap"
          mb={2}
        >
          <Stack
            direction={"row"}
            alignItems="center"
            sx={{ my: 2 }}
            spacing={2}
          >
            <Typography sx={{ ...primaryLabel }}>
              {isUpdate ? "Update activity" : "Add new activity"}
            </Typography>
          </Stack>
          <Stack
            direction="row"
            alignItems={"center"}
            justifyContent={"flex-end"}
            spacing={1}
            pr={1}
            flex={1}
          >
            <ButtonGroup size="small" sx={{ mt: 1 }}>
              <Button
                onClick={() => editForm({ type: "task" })}
                variant={form.type === "task" ? "contained" : "outlined"}
              >
                Task
              </Button>
              <Button
                onClick={() => editForm({ type: "reminder" })}
                variant={form.type === "reminder" ? "contained" : "outlined"}
              >
                Reminder
              </Button>
              <Button
                onClick={() => editForm({ type: "call" })}
                variant={form.type === "call" ? "contained" : "outlined"}
              >
                Call
              </Button>
            </ButtonGroup>
          </Stack>
        </Stack>
        {/* <Stack
          direction="row"
          justifyContent={"space-between"}
          alignItems="center"
          mb={2}
        >
          <Typography sx={{ ...primaryLabel }}>
            {isUpdate ? "Update activity" : "Add new activity"}
          </Typography>
          <ButtonGroup size="small" sx={{ mt: 1 }}>
            <Button
              onClick={() => editForm({ type: "task" })}
              variant={form.type === "task" ? "contained" : "outlined"}
            >
              Task
            </Button>
            <Button
              onClick={() => editForm({ type: "reminder" })}
              variant={form.type === "reminder" ? "contained" : "outlined"}
            >
              Reminder
            </Button>
            <Button
              onClick={() => editForm({ type: "call" })}
              variant={form.type === "call" ? "contained" : "outlined"}
            >
              Call
            </Button>
          </ButtonGroup>
        </Stack> */}
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Typography sx={{ ...secondaryLabel }}>Task Assign To</Typography>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={form.owner}
              onChange={(e) => editForm({ owner: e.target.value })}
              fullWidth
              size="small"
              sx={{ mt: 1 }}
            >
              {members.map((i) => (
                <MenuItem value={i.value}> {i.label}</MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography sx={{ ...secondaryLabel }}>Title</Typography>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={form.title}
              onChange={(e) => editForm({ title: e.target.value })}
              fullWidth
              size="small"
              sx={{ mt: 1 }}
            >
              {userActivityTitle.map((i) => (
                <MenuItem value={i.value}> {i.label}</MenuItem>
              ))}
            </Select>
          </Grid>
          {!isObjWithValues(customer) && !isObjWithValues(updateForm) && (
            <Grid item xs={12}>
              <Typography sx={{ ...secondaryLabel }}>Customer</Typography>
              {user_from === "chat" ? (
                <Autocomplete
                  value={selectedCustomer}
                  onChange={(a, e) => setSelectedCustomer(e)}
                  disablePortal
                  id="combo-box-demo"
                  options={allCustomers}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Search customer"
                      fullWidth
                    />
                  )}
                  fullWidth
                  size="small"
                />
              ) : (
                <Autocomplete
                  value={selectedCustomer}
                  onChange={(a, e) => setSelectedCustomer(e)}
                  // disablePortal
                  id="combo-box-demo"
                  options={customerSearched}
                  loading={customerSearching}
                  openOnFocus
                  inputValue={customerSearch}
                  onInputChange={(event, value) => {
                    onCustomerSearch(value);
                  }}
                  // multiple
                  // getOptionLabel={(option) => option.label || ""}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      // value={customerSearch}
                      // onChange={(e) => onCustomerSearch(e.target.value)}
                      placeholder="Search customer"
                      fullWidth
                    />
                  )}
                  fullWidth
                  size="small"
                />
              )}
              {/* <FormControl fullWidth>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedCustomer}
                  onChange={(e) => setSelectedCustomer(e.target.value)}
                  size="small"
                  MenuProps={{ sx: { maxHeight: "300px" } }}
                >
                  {allReduxConversations?.map((i) => {
                    return (
                      <MenuItem value={i.id}>{`${i?.first_name || ""} ${
                        i?.last_name || ""
                      } ${i?.name || ""}`}</MenuItem>
                    );
                  })}
                </Select>
              </FormControl> */}
            </Grid>
          )}
          <Grid item xs={12}>
            <Typography sx={{ ...secondaryLabel }}>Description</Typography>
            <TextField
              fullWidth
              multiline
              rows={2}
              value={form.description}
              onChange={(e) => editForm({ description: e.target.value })}
              sx={{ mt: 2 }}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography sx={{ ...secondaryLabel, mb: 2 }}>Date/time</Typography>
            <Box>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDateTimePicker
                  // label="Birthday"

                  // name="birthdate"
                  disablePast
                  renderInput={(params) => (
                    <TextField
                      fullWidth
                      label="Enter Date"
                      size="small"
                      {...params}
                    />
                  )}
                  fullWidth
                  onChange={(newValue) => {
                    editForm({ date: formatDate(newValue) });
                  }}
                  value={form.date ? form.date : null}
                />
              </LocalizationProvider>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography sx={{ ...secondaryLabel }}>
                Remind me before
              </Typography>
              <Switch
                onChange={(e) => {
                  editForm({ show_remind: e });
                }}
                checked={form.show_remind}
                style={{
                  border: `1px solid ${
                    form.show_remind ? backgroundColor : "#e8e8e8"
                  }`,
                  backgroundColor: form.show_remind
                    ? backgroundColor
                    : "#e8e8e8",
                }}
              />
            </Stack>
          </Grid>
          {form.show_remind ? (
            <>
              <Grid item xs={6} md={4}>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={form.remind_value}
                  onChange={(e) => editForm({ remind_value: e.target.value })}
                  fullWidth
                  size="small"
                  sx={{ mt: 1 }}
                >
                  {[5, 10, 30].map((i) => (
                    <MenuItem value={i}> {i}</MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={6} md={4}>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={form.remind_label}
                  onChange={(e) => editForm({ remind_label: e.target.value })}
                  fullWidth
                  size="small"
                  sx={{ mt: 1 }}
                >
                  {["minutes", "hours"].map((i) => (
                    <MenuItem value={i}> {i}</MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={6} md={4}>
                <ButtonGroup size="small" sx={{ mt: 1 }} fullWidth>
                  <Button
                    onClick={() => editForm({ repeat_remind: true })}
                    variant={form.repeat_remind ? "contained" : "outlined"}
                  >
                    Repeat
                  </Button>
                  <Button
                    onClick={() => editForm({ repeat_remind: false })}
                    variant={!form.repeat_remind ? "contained" : "outlined"}
                  >
                    Not repeat
                  </Button>
                </ButtonGroup>
              </Grid>
            </>
          ) : null}
          <Grid item xs={12} md={6}>
            <Typography sx={{ ...secondaryLabel }}>Status</Typography>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={form.status}
              onChange={(e) => editForm({ status: e.target.value })}
              fullWidth
              size="small"
              sx={{ mt: 1 }}
            >
              {userActivityStatus.map((i) => (
                <MenuItem value={i.value}> {i.label}</MenuItem>
              ))}
            </Select>
          </Grid>
        </Grid>
        <Stack
          direction="row"
          justifyContent={"flex-end"}
          alignItems="center"
          spacing={3}
        >
          <Button onClick={closeForm}>Cancel</Button>
          <LoadingButton
            loading={loading}
            variant="contained"
            onClick={isUpdate ? onUpdateUserTask : onAddUserTask}
          >
            {isUpdate ? "Update" : "Add"} activity
          </LoadingButton>
        </Stack>
      </DialogContent>
    );
  }
);
