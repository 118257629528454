import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import {
  isObjWithValues,
  primaryLabel,
  primaryLabelLarge,
} from "../../../helper";
import { useSelector } from "react-redux";

const CategorySummary = ({ quotation }) => {
  const [rows, setRows] = useState([]);
  //   const [categorySummary, setCategorySummary] = useState({
  //     metal: 0,
  //     diamond: 0,
  //     gemstone: 0,
  //     labour: 0,
  //     total: 0,
  //     avgPricePerProduct: 0,
  //   });
  const default_currency = useSelector(
    (state) => state.settings.productSettings
  )?.default_currency;

  useEffect(() => {
    let { categorySummary } = quotation;
    if (isObjWithValues(categorySummary)) {
      let rows = [];
      for (let category in categorySummary) {
        rows.push({
          ...categorySummary[category],
          category,
        });
      }
      setRows(rows);
    } else setRows([]);
    // if (isObjWithValues(categorySummary)) setCategorySummary(categorySummary);
  }, [quotation.categorySummary]);

  return (
    <Card sx={{ mt: 4, mb: 6 }}>
      <CardContent>
        <Typography sx={{ ...primaryLabelLarge }}>Category Summary</Typography>
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ pb: 1 }}>Category</TableCell>
                <TableCell sx={{ pb: 1 }} align="right">
                  Metal Wt (Gross)
                </TableCell>
                <TableCell sx={{ pb: 1 }} align="right">
                  Qty
                </TableCell>
                <TableCell sx={{ pb: 1 }} align="right">
                  DW
                </TableCell>
                <TableCell sx={{ pb: 1 }} align="right">
                  DP
                </TableCell>
                {/* <TableCell sx={{ pb: 1 }} align="right">
                  GW
                </TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody sx={{ rowGap: "4px" }}>
              {rows.map((row) => (
                <>
                  <TableRow
                    key={row.category}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      //   border: row.metal?.includes("Labour") ? 1 : 0,
                      //   borderTop:
                      //     row.metal?.includes("Total") ||
                      //     row.metal?.includes("Labour")
                      //       ? 1
                      //       : 0,
                      //   borderRadius: "6px",
                      //   borderColor: "#e8e8e8",
                      //   "& td": { border: 0, py: 2 },
                      //   "& th": { border: 0 },
                      //   py: 1,
                      //   my: 1,
                      ...primaryLabel,
                    }}
                  >
                    <TableCell
                      sx={{
                        ...(row.metal?.includes("Total") ? primaryLabel : {}),
                      }}
                      scope="row"
                    >
                      {row.label}
                    </TableCell>
                    <TableCell
                      sx={{
                        ...(row.metal?.includes("Total") ? primaryLabel : {}),
                      }}
                      align="right"
                    >
                      {row.metal_gross_weight}
                    </TableCell>
                    <TableCell
                      sx={{
                        ...(row.metal?.includes("Total") ? primaryLabel : {}),
                      }}
                      align="right"
                    >
                      {row.quantity}
                    </TableCell>
                    <TableCell
                      sx={{
                        ...(row.metal?.includes("Total") ? primaryLabel : {}),
                      }}
                      align="right"
                    >
                      {row?.diamond_weight || "0"}
                    </TableCell>
                    <TableCell
                      sx={{
                        ...(row.metal?.includes("Total") ? primaryLabel : {}),
                      }}
                      align="right"
                    >
                      {row?.diamond_pieces || "0"}
                    </TableCell>
                  </TableRow>
                </>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
};

export default CategorySummary;
