import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
} from "@mui/material";
import React from "react";
import { isArrayWithValues } from "../../../helper";
import { FormLabel } from "../../settings/Email";
import displayState from "../../../validateProduct";

let filtersData = {
  status: [
    { label: "Follow up", value: "follow" },
    { label: "Done", value: "done" },
    { label: "spam", value: "spam" },
  ],
};
const ChatsFilters = ({ filterObj, setFilterObj, reduxTeamMembers = [] }) => {
  const onChangeFilter = (state, value) => {
    let obj = { ...filterObj };
    if (filterObj?.[state]?.includes(value)) {
      obj[state] = (obj[state] || [])?.filter((key) => key !== value);
    } else {
      obj[state] = [...(obj[state] || []), value];
    }
    setFilterObj(obj);
  };

  return (
    <>
      <FormLabel sx={{ mt: 2 }}>Customer Status</FormLabel>
      <FormGroup>
        <FormControlLabel
          // key={item.name}
          control={
            <Checkbox
              size="small"
              sx={{ padding: "5px", paddingLeft: "10px" }}
              // name={item.name}

              checked={filterObj?.status?.includes("done")}
              onChange={() => onChangeFilter("status", "done")}
            />
          }
          label={<Typography>Done </Typography>}
        />
        <FormControlLabel
          control={
            <Checkbox
              size="small"
              sx={{ padding: "5px", paddingLeft: "10px" }}
              checked={filterObj?.status?.includes("spam")}
              onChange={() => onChangeFilter("status", "spam")}
            />
          }
          label={<Typography>Spam </Typography>}
        />
        <FormControlLabel
          control={
            <Checkbox
              size="small"
              sx={{ padding: "5px", paddingLeft: "10px" }}
              checked={filterObj?.active_chats}
              onChange={() =>
                setFilterObj((pre) => ({
                  ...pre,
                  active_chats: !pre?.active_chats,
                }))
              }
            />
          }
          label={<Typography>Active Chats (with in 24 hours) </Typography>}
        />
      </FormGroup>
      {isArrayWithValues(
        (reduxTeamMembers || [])?.filter((obj) =>
          isArrayWithValues(obj?.assignedChatCustomers)
        ) || []
      ) && (
          <>
            <FormLabel sx={{ mt: 2 }}>Team members</FormLabel>

            {reduxTeamMembers?.map((obj) => {
              if (!isArrayWithValues(obj?.assignedChatCustomers)) return <></>;
              return (
                <>
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        sx={{ padding: "5px", paddingLeft: "10px" }}
                        checked={filterObj?.teamMember?.includes(
                          `${obj?.first_name || ""} ${obj?.last_name || ""}`
                        )}
                        onChange={() =>
                          onChangeFilter(
                            "teamMember",
                            `${obj?.first_name || ""} ${obj?.last_name || ""}`
                          )
                        }
                      />
                    }
                    label={
                      <Typography>{`${obj?.first_name || ""} ${obj?.last_name || ""
                        } (${obj?.assignedChatCustomers?.length})`}</Typography>
                    }
                  />
                </>
              );
            })}
          </>
        )}
      <FormLabel sx={{ mt: 2 }}>Customer Type</FormLabel>
      <FormControlLabel
        control={
          <Checkbox
            size="small"
            sx={{ padding: "5px", paddingLeft: "10px" }}
            checked={filterObj?.contact_type?.includes("customer")}
            onChange={() => onChangeFilter("contact_type", `customer`)}
          />
        }
        label={<Typography>Customer</Typography>}
      />
      <FormControlLabel
        control={
          <Checkbox
            size="small"
            sx={{ padding: "5px", paddingLeft: "10px" }}
            checked={filterObj?.contact_type?.includes("lead")}
            onChange={() => onChangeFilter("contact_type", `lead`)}
          />
        }
        label={<Typography>Lead</Typography>}
      />

      {displayState({ key: "chatsCrmFilters" }) && <>
        <FormLabel sx={{ mt: 2 }}>Filter by</FormLabel>
        <FormGroup>

          <FormControlLabel
            control={
              <Checkbox
                size="small"
                sx={{ padding: "5px", paddingLeft: "10px" }}
                checked={filterObj?.inCrm || false}
                onChange={(event) => setFilterObj(pre => ({ ...pre, inCrm: event.target.checked }))}
              />
            }
            label={<Typography>Added in CRM</Typography>}
          />
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                sx={{ padding: "5px", paddingLeft: "10px" }}
                checked={filterObj?.inNotCrm || false}
                onChange={(event) => setFilterObj(pre => ({ ...pre, inNotCrm: event.target.checked }))}
              />
            }
            label={<Typography>Not in CRM</Typography>}
          />
        </FormGroup>
      </>}
    </>
  );
};

export default ChatsFilters;
