import { Popover, Box, Typography } from "@mui/material";
import { optionPopoverStyles } from "../styles/optionPopoverStyles";

const styles = optionPopoverStyles;

const FormatOptions = ({
  setData,
  optionsAnchorEl,
  setOptionsAnchorEl,
}) => {
  const open = Boolean(optionsAnchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleClosePopover = () => {
    setOptionsAnchorEl(null);
  };

  const handleOptionClick = (formatType) => {
    setData((prev) => ({
      ...prev,
      time_format: formatType
    }));
    handleClosePopover();
  };

  const options = [
    { label: "Seconds", value: "seconds" },
    { label: "Minutes", value: "minutes" },
    { label: "Hours", value: "hours" },
    { label: "Days", value: "days" },
  ];

  return (
    <Popover
      sx={{ marginTop: "4px" }}
      id={id}
      open={open}
      anchorEl={optionsAnchorEl}
      onClose={handleClosePopover}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
    >
      <Box sx={styles.outerBox}>
        {options && options.map((format, index) => (
          <Typography key={index} variant="span" sx={styles.title} onClick={() => handleOptionClick(format?.value)}>
            {format?.label}
          </Typography>
        ))}
      </Box>
    </Popover>
  );
};

export default FormatOptions;
