import {
  Button,
  Card,
  CardContent,
  Divider,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect } from "react";
import {
  BaseEdge,
  EdgeLabelRenderer,
  Handle,
  Position,
  getBezierPath,
} from "reactflow";
import { CampaignIconSvg } from "../../../Svgs";
import CustomHandle from "./CustomHandle";
import { Add, Delete } from "@material-ui/icons";

import { FlowStateContext } from "..";

import { campaignChannelIcon } from "../../customers/components/CampaignHistory";
import { formatServerValue, unformatServerValue } from "../../../helper";
import { HourglassBottomRounded } from "@mui/icons-material";

const NodeContainer = ({
  children,
  hideStartNode,
  hideDelete,
  cardContentSx = {},
  sx = {},
  heading,
  id,
  selected,
  icon,
  description,
  parent,
  // hasError,
}) => {
  let { setDeleteOpen, onEditForm, campaignState, isReport, hasError } =
    useContext(FlowStateContext);

  // useEffect(() => {
  //     onEditForm({
  //         payload: {
  //             hasError: true,
  //         },
  //         key: id,
  //     });

  //     return () => { };
  // }, []);

  hasError = hasError?.[id];

  return (
    <Card
      sx={{
        borderRadius: "16px",
        width: "250px",
        minHeight: "150px",
        boxShadow: selected
          ? hasError
            ? `rgb(212 28 28 / 25%) 0px 14px 28px, rgb(171 46 46 / 22%) 0px 10px 10px`
            : ` rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;`
          : `0 2px 4px 0 rgba(0, 0, 0, 0.2)`,
        ...sx,
        border: 2,
        borderColor: hasError ? "#ff0000" : "#012687",
      }}
      // className="light_shadow_card"
    >
      {" "}
      {hideStartNode ? null : <Handle type="target" position={Position.Top} />}
      {heading || id ? (
        <>
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
            sx={{ padding: "12px 20px" }}
          >
            <Typography
              variant="h6"
              sx={{ display: "flex", alignItems: "center", gap: 2 }}
              noWrap
            >
              {icon} {heading || formatServerValue(id?.split("_")?.[0])}
            </Typography>
            {selected ? (
              hideDelete ? null : (
                <IconButton
                  size="small"
                  sx={{ padding: 0 }}
                  onClick={() => setDeleteOpen && setDeleteOpen(id)}
                >
                  <Delete fontSize="small" />{" "}
                </IconButton>
              )
            ) : null}
          </Stack>
          <Divider />
        </>
      ) : null}
      <CardContent sx={{ ...cardContentSx }}>
        {isReport
          ? null
          : description && (
              <Stack direction={"row"} alignItems={"center"} gap={1}>
                <Typography variant="subtitle2">Description :</Typography>{" "}
                <Typography variant="subtitle2" fontWeight={300}>
                  {description}
                </Typography>
              </Stack>
            )}
        {children}
      </CardContent>
    </Card>
  );
};

export const InitialNode = ({ isConnectable, ...otherProps }) => {
  const { campaignState, flowObject, analytics } = useContext(FlowStateContext);

  return (
    <NodeContainer
      // cardContentSx={{ padding: 0 }}
      parent={otherProps}
      sx={{}}
      hideStartNode={true}
      id={"campaign_info"}
      heading={flowObject?.name}
      // description={campaignState?.campaign_info?.description}
      hideDelete={true}
      icon={<CampaignIconSvg />}
      // hasError={!isArrayWithValues(campaignState?.campaign_info?.segment_id)}
    >
      <CustomHandle
        showChannel={true}
        parent={otherProps}
        id={otherProps?.id}
        subTitle={analytics?.audience}
        label={"Start"}
        type="source"
      />

      {/* <Handle type="source" position={Position.Bottom} id="a" style={{ left: 30 }} /> */}
    </NodeContainer>
  );
};
export const WhatsappNode = (props) => {
  const { campaignState, isReport, historyObject } =
    useContext(FlowStateContext);

  let { data, selected } = props;

  let { setNodes } = data || {};

  let actions = [
    { label: `Sent`, style: { left: 40 }, xPos: -200 },
    // { label: "Delivered", style: { left: 40 }, xPos: -200 },
    { label: "Read", style: { left: 120 }, xPos: 100 },
    { label: "Failed", style: { left: 210 }, xPos: 400 },
  ];
  console.log(historyObject);
  return (
    <NodeContainer
      id={props?.id}
      parent={props}
      //   cardContentSx={{ padding: 0 }}
      heading={"WhatsApp"}
      description={campaignState?.[props?.id]?.description}
      selected={selected}
      icon={campaignChannelIcon?.["whatsapp"]}
      hasError={!campaignState?.[props?.id]?.template}
    >
      {actions?.map((action) => (
        <CustomHandle
          key={action?.label}
          parent={props}
          {...action}
          subTitle={
            historyObject?.[props?.id]?.[unformatServerValue(action?.label)]
              ?.length
          }
          setNodes={setNodes}
          id={`${action?.label?.replace(" ", "_")}${props.id}`}
          showChannel={true}
        />
      ))}
      {/* </Stack> */}

      {/* <Handle type="source" position={Position.Bottom} id="a" style={{ left: 30 }} /> */}
    </NodeContainer>
  );
};
export const SmsNode = (props) => {
  const { campaignState, isReport } = useContext(FlowStateContext);
  let { selected } = props;

  let actions = [
    { label: "Delivered", style: { left: 40 }, xPos: -200 },
    // { label: "Read", style: { left: 120 }, xPos: 100 },
    { label: "Failed", style: { left: 210 }, xPos: 400 },
  ];
  return (
    <NodeContainer
      // heading={props?.id}
      id={props?.id}
      parent={props}
      heading={"SMS"}
      description={campaignState?.[props?.id]?.description}
      //   cardContentSx={{ padding: 0 }}
      hasError={!campaignState?.[props?.id]?.template_id}
      selected={selected}
      icon={campaignChannelIcon?.["sms"]}
    >
      {/* <Stack
                direction={"row"}
                alignContent={"center"}
                gap={2}
                sx={{ padding: "20px" }}
            >
                <SmsOutlined /> <Typography variant="h6">SMS</Typography>
            </Stack>
            <Divider /> */}

      {actions?.map((action, index) => (
        <CustomHandle
          key={action?.label}
          parent={props}
          {...action}
          // setNodes={setNodes}
          id={`${action?.label?.replace(" ", "_")}${props.id}`}
          showChannel={true}
        />
      ))}
      {/* <Handle type="source" position={Position.Bottom} id="a" style={{ left: 30 }} /> */}
    </NodeContainer>
  );
};
export const EmailNode = (props) => {
  const { campaignState, isReport } = useContext(FlowStateContext);
  let { selected } = props;
  let actions = [
    { label: "Opened", style: { left: 40 }, xPos: -200 },
    { label: "Reopened", style: { left: 120 }, xPos: 100 },
    { label: "Failed", style: { left: 210 }, xPos: 400 },
  ];
  return (
    <NodeContainer
      id={props?.id}
      parent={props}
      //   cardContentSx={{ padding: 0 }}
      heading={"Email"}
      description={campaignState?.[props?.id]?.description}
      // heading={props?.id}
      hasError={!campaignState?.[props?.id]?.template}
      selected={selected}
      icon={campaignChannelIcon?.["email"]}
    >
      {/* <CustomHandle showChannel={true} parent={props} /> */}
      {actions?.map((action, index) => (
        <CustomHandle
          parent={props}
          {...action}
          // setNodes={setNodes}
          id={`${action?.label?.replace(" ", "_")}${props.id}`}
          showChannel={true}
        />
      ))}
      {/* <Handle type="source" position={Position.Bottom} id="a" style={{ left: 30 }} /> */}
    </NodeContainer>
  );
};
export const PushNotificationNode = (props) => {
  const { campaignState, isReport } = useContext(FlowStateContext);
  let { selected } = props;
  let actions = [
    { label: "Displayed", style: { left: 40 }, xPos: -200 },
    { label: "Clicked", style: { left: 120 }, xPos: 100 },
    { label: "Dismissed", style: { left: 210 }, xPos: 400 },
  ];
  return (
    <NodeContainer
      id={props?.id}
      parent={props}
      //   cardContentSx={{ padding: 0 }}
      hasError={!campaignState?.[props?.id]?.payload}
      heading={"Push Notification"}
      description={campaignState?.[props?.id]?.description}
      selected={selected}
      icon={campaignChannelIcon?.["push_notification"]}
    >
      {actions?.map((action, index) => (
        <CustomHandle
          parent={props}
          {...action}
          // setNodes={setNodes}
          id={`${action?.label?.replace(" ", "_")}${props.id}`}
          showChannel={true}
        />
      ))}
      {/* <Handle type="source" position={Position.Bottom} id="a" style={{ left: 30 }} /> */}
    </NodeContainer>
  );
};

// export const CustomEdge = ({ id, source, target, type, animated }) => {
//     const handleButtonClick = () => {
//         // Handle button click logic here
//         console.log('Button clicked on edge:', id);
//     };

//     return (
//         <g>
//             <path
//                 className={`react-flow__edge-path${animated ? ' react-flow__edge-path--animated' : ''}`}
//                 d={getEdgePath(source, target)}
//             />
//             <circle cx={getEdgeCenterX(source, target)} cy={getEdgeCenterY(source, target)} r={5} fill="white" />
//             <text>
//                 <textPath href={`#${id}`} startOffset="50%" textAnchor="middle">
//                     <button onClick={handleButtonClick}>Button</button>
//                 </textPath>
//             </text>
//         </g>
//     );
// };
