import { Box, Typography } from "@mui/material";
import React from "react";

const instagramBenefits = [
  "✅ Upload or Schedule your posts instantly",
  "✅ Share products on Instagram with just a click",
  "✅ Get notified and reply to comments in no time",
  "✅ Receive your Instagram messages right here and reply to customers right away",
];
const whatsappBenefits = [
  "✅ Interact directly with clients",
  "✅ Advertise new products and discounts",
  "✅ Generally make themselves more approachable for business inquiries",
];
const eCommerceBenifits = [
  "✅ Visual merchandising for your customers",
  "✅ Connect products to consumers",
  "✅ Superfast Transactions with customers on the mobile phone",
];
const MetaOverview = () => {
  return (
    <Box sx={{ p: 3, mb: 3 }}>
      <Typography variant="subtitle1" sx={{ fontSize: "16px", mb: 4 }}>
        Meta’s Facebook enables businesses to communicate with their customers
        through posts and stories 🗣
      </Typography>
      <Typography variant="subtitle1" sx={{ fontSize: "16px", mb: 3 }}>
        It’s a must-have for jewellers to promote their business online not
        limited to their friends and family but to anyone in any part of the
        world 🌎
      </Typography>
      <Typography variant="subtitle1" sx={{ fontSize: "16px" }}>
        By optimising the audience and targeting tools by meta business centre,
        jewellers can make sure that your jeweller business’s reach is amplified
        🦾
      </Typography>
      <Typography variant="h5" sx={{ mb: 4, mt: 4 }}>
        Benefits ✨
      </Typography>
      <Typography variant="h6" sx={{ mb: 3 }}>
        #1 Facebook Page
      </Typography>
      <Typography variant="subtitle1" sx={{ fontSize: "16px" }}>
        Enables your jewellery business to communicate with your customers
        through posts, messaging, etc. At the same time, it enhances your brand
        recognition with quick and easy access to a larger audience.
      </Typography>
      <Typography variant="h6" sx={{ mb: 3, mt: 4 }}>
        #2 Instagram Business Page
      </Typography>
      <Typography variant="subtitle1" sx={{ fontSize: "16px" }}>
        Do more with Instagram integration⚡ Instantly Connect your Instagram
        and automate your Instagram Management from one place.
      </Typography>
      <Typography variant="h6" sx={{ mb: 3, mt: 4 }}>
        Benefits of Integrating Instagram✨
      </Typography>
      <ListItem arr={instagramBenefits} />
      <Typography variant="h6" sx={{ mb: 3, mt: 4 }}>
        #3 Whatsapp
      </Typography>
      <Typography variant="subtitle1" sx={{ fontSize: "16px" }}>
        An average WhatsApp user spends 38minutes per day on the app. Whatsapp
        is used by almost everyone as soon as their day starts.
      </Typography>
      <Typography variant="subtitle1" sx={{ fontSize: "16px" }}>
        Jewellery businesses can leverage the power of WhatsApp to stay
        connected with their customers 24/7.
      </Typography>
      <Typography variant="h6" sx={{ mb: 3, mt: 4 }}>
        Businesses can use the integration to✨
      </Typography>
      <ListItem arr={whatsappBenefits} />
      <Typography variant="h6" sx={{ mb: 3, mt: 4 }}>
        #4 E-commerce
      </Typography>
      <Typography variant="subtitle1" sx={{ fontSize: "16px" }}>
        According to Facebook Survey, at least 1 in 3 users use the marketplace
        to purchase and sell goods. Now that people are actively buying
        jewellery digitally, you may promote product discovery and sales there.
      </Typography>
      <ListItem arr={eCommerceBenifits} />
    </Box>
  );
};

export default MetaOverview;

export const ListItem = ({ arr, bulletList }) => {
  return (
    <ul style={{ listStyle: bulletList ? "initial" : "none", margin: "10px 0px", padding: bulletList ? "0px 25px" : "auto" }}>
      {arr.map((str, index) => (
        <li key={index} style={{ fontSize: "14px", margin: "8px 0px" }}>{str}</li>
      ))}
    </ul>
  );
};
