import React, { useEffect, useState } from "react";
import { CircleNotifications, Lock, QuizSharp } from "@mui/icons-material";
import { DateTimePicker, LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Card,
  Checkbox,
  Collapse,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  InputAdornment,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import axios from "axios";
import { ShopifySvg } from "../../../../Svgs";
import {
  apiCallFromSocket,
  Base64,
  getAxiosError,
  getStoreId,
  getToken,
  getWebsite,
  isObjWithValues,
  nodeWebsite,
  TabPanel,
} from "../../../../helper";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ShopifyOverview from "./ShopifyOverview";
import ShopifyIntegration from "./ShopifyIntegration";
import { EDIT_SETTINGS } from "../../../../redux/actions/settingActions";
import Mapping from "./components/Mapping";
import Settings from "./components/Settings";
import Activity from "./Activity";
import { setGlobalToast } from "../../../../redux/actions/helperActions";
import Import from "./components/Import";

function Shopify() {
  const [selectedTab, setSelectedTab] = useState("overview");
  const store_id = useSelector((state) => state.user.store_id);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const reduxShopifyIntegration = useSelector(
    (state) => state.settings.shopifyIntegration
  );

  // Get OAuth success params
  useEffect(() => {
    let params_string = location.search;
    const all_params = new URLSearchParams(params_string);
    const status = new URLSearchParams(params_string).get("success");
    let state = new URLSearchParams(params_string).get("state");

    // if (!state || !status) return;

    if (state?.includes("shopify") && state?.includes(getStoreId())) {
      try {
        const paramsAsJson = {};
        for (const [key, value] of all_params) {
          paramsAsJson[key] = value;
        }

        setSelectedTab("integration");
        exchangeCodeForAccessToken(paramsAsJson, state);
      } catch (err) {
        console.debug(err);
      }
    }
  }, []);

  const exchangeCodeForAccessToken = async (authParams, state) => {
    let website = getWebsite();
    let token = getToken();

    try {
      if (website && token) {
        let integrationData = authParams;
        dispatch({
          type: EDIT_SETTINGS,
          payload: {
            integratingShopify: true,
          },
        });
        if (isObjWithValues(authParams)) {
          const tokenResponse = await getAccessTokenFromCode(authParams);
          if (!isObjWithValues(tokenResponse)) return;
          if (tokenResponse.hasOwnProperty("access_token")) {
            integrationData = { ...integrationData, ...tokenResponse };
          }
        }

        let shopInfo = await apiCallFromSocket({
          url: `https://${integrationData?.shop}/admin/api/2023-04/shop.json`,
          method: "GET",
          headers: {
            "X-Shopify-Access-Token": integrationData?.access_token,
          },
        });
        console.log(shopInfo, "<<< shop info");

        let postRes = await axios({
          url: `${website}/wp-json/store/v1/settings/shopify_integration`,
          method: "POST",
          headers: {
            Authorization: `Basic ${token}`,
          },

          data: {
            setting: "shopify_integration",
            data: {
              integrated: true,
              ...integrationData,
              shopInfo: shopInfo?.data?.data?.shop,
            },
          },
        });

        if (postRes?.data?.type === "success") {
          dispatch({
            type: EDIT_SETTINGS,
            payload: {
              shopifyIntegration: postRes?.data?.data,
            },
          });
          batchCreateWebhooks(integrationData);
          dispatch(
            setGlobalToast({
              show: true,
              message: "Shopify integrated successfully!",
              severity: "success",
            })
          );
        }
      }
    } catch (err) {
      console.log(err);
    }
    dispatch({
      type: EDIT_SETTINGS,
      payload: {
        integratingShopify: false,
      },
    });
    navigate("/integrations");
  };

  const getAccessTokenFromCode = async ({ shop, code }) => {
    let client_id = "10596520b77ff26498d53de6b3781e58";
    let client_secret = "5e3e9d9e3167d0e450b0e75e15da0d46";

    try {
      const response = await apiCallFromSocket({
        url: `https://${shop}/admin/oauth/access_token`,
        method: "POST",
        params: {
          client_id,
          client_secret,
          code,
        },
      });
      console.log("shopify token exchanged");
      return response?.data?.data;
    } catch (error) {
      console.log(getAxiosError(error));
      return false;
    }
  };

  const batchCreateWebhooks = async (integrationData) => {
    let website = getWebsite();
    let token = getToken();

    if (true) {
      const { shop, access_token } = integrationData || reduxShopifyIntegration;
      if (website && token) {
        try {
          const response = await apiCallFromSocket({
            method: "POST",
            url: `https://${shop}/admin/api/2023-01/bulk_operations.json`,
            headers: {
              "Content-Type": "application/json",
              "X-Shopify-Access-Token": access_token,
            },
            data: {
              bulk_operation: {
                description: "Create multiple webhooks",
                operation_type: "bulk",
                params: {
                  send_email: false,
                  data: {
                    requests: [
                      {
                        method: "POST",
                        path: "admin/api/2023-01/webhooks.json",
                        body: {
                          webhook: {
                            topic: "customers/create",
                            address: `${nodeWebsite}/shopify/${store_id}`,
                            format: "json",
                          },
                        },
                      },
                      {
                        method: "POST",
                        path: "admin/api/2023-01/webhooks.json",
                        body: {
                          webhook: {
                            topic: "customers/create",
                            address: `${nodeWebsite}/shopify/${store_id}`,
                            format: "json",
                          },
                        },
                      },
                    ],
                  },
                },
              },
            },
          });
          // const response = await apiCallFromSocket({
          //   method: "POST",
          //   url: `https://${shop}/admin/api/2023-01/webhooks.json`,
          //   headers: {
          //     "Content-Type": "application/json",
          //     "X-Shopify-Access-Token": access_token,
          //   },
          // //   data: {
          //     webhook: {
          //       topic: "customers/create",
          //       // address: `${website}/wc-api/ecommerce`,
          //       address: `${nodeWebsite}/shopify/${store_id}`,
          //       format: "json",
          //     },
          //     webhook: [
          //       // Customers
          //       {
          //         topic: "customers/create",
          //         // address: `${website}/wc-api/ecommerce`,
          //         address: `${nodeWebsite}/shopify/${store_id}`,
          //         format: "json",
          //       },
          //       {
          //         topic: "customers/delete",
          //         address: `${nodeWebsite}/shopify/${store_id}`,
          //         format: "json",
          //       },
          //       // {
          //       //   topic: "customers/disable",
          //       //   address: `${website}/wc-api/ecommerce`,
          //       //   format: "json",
          //       // },
          //       // {
          //       //   topic: "customers/enable",
          //       //   address: `${website}/wc-api/ecommerce`,
          //       //   format: "json",
          //       // },
          //       {
          //         topic: "customers/update",
          //         address: `${nodeWebsite}/shopify/${store_id}`,
          //         format: "json",
          //       },
          //       // Orders
          //       {
          //         topic: "orders/cancelled",
          //         address: `${nodeWebsite}/shopify/${store_id}`,
          //         format: "json",
          //       },
          //       {
          //         topic: "orders/create",
          //         address: `${nodeWebsite}/shopify/${store_id}`,
          //         format: "json",
          //       },
          //       {
          //         topic: "orders/delete",
          //         address: `${nodeWebsite}/shopify/${store_id}`,
          //         format: "json",
          //       },
          //       {
          //         topic: "orders/edited",
          //         address: `${nodeWebsite}/shopify/${store_id}`,
          //         format: "json",
          //       },
          //       {
          //         topic: "orders/fulfilled",
          //         address: `${nodeWebsite}/shopify/${store_id}`,
          //         format: "json",
          //       },
          //       {
          //         topic: "orders/paid",
          //         address: `${nodeWebsite}/shopify/${store_id}`,
          //         format: "json",
          //       },
          //       {
          //         topic: "orders/partially_fulfilled",
          //         address: `${nodeWebsite}/shopify/${store_id}`,
          //         format: "json",
          //       },
          //       {
          //         topic: "orders/updated",
          //         address: `${nodeWebsite}/shopify/${store_id}`,
          //         format: "json",
          //       },
          //       // Carts/Checkout
          //       {
          //         topic: "carts/create",
          //         address: `${nodeWebsite}/shopify/${store_id}`,
          //         format: "json",
          //       },
          //       {
          //         topic: "carts/update",
          //         address: `${nodeWebsite}/shopify/${store_id}`,
          //         format: "json",
          //       },
          //       // {
          //       //   topic: "checkout/create",
          //       //   address: `${website}/wc-api/ecommerce`,
          //       //   format: "json",
          //       // },
          //       // {
          //       //   topic: "checkout/delete",
          //       //   address: `${website}/wc-api/ecommerce`,
          //       //   format: "json",
          //       // },
          //       // {
          //       //   topic: "checkout/update",
          //       //   address: `${website}/wc-api/ecommerce`,
          //       //   format: "json",
          //       // },
          //     ],
          //   },
          // });

          console.log("shopifyWebHooks", response.data);
        } catch (err) {
          console.log("CreateWebhooks", err);
        }
      }
    }
  };

  return (
    <Box sx={{ height: "100%", paddingRight: "15px" }}>
      <Stack
        sx={{
          position: "sticky",
          top: "0",
          backgroundColor: "#fff",
          zIndex: 13,
          margin: 0,
          padding: "12px",
        }}
      >
        <Stack
          direction="row"
          justifyContent={"space-between"}
          alignItems="center"
        >
          <Typography
            variant="h3"
            sx={{ display: "flex", alignItems: "center", gap: "10px" }}
          >
            {" "}
            <ShopifySvg height={25} width={25} /> Shopify
          </Typography>
        </Stack>
        <Tabs
          onChange={(e, newValue) => setSelectedTab(newValue)}
          value={selectedTab}
          sx={{ borderBottom: 1, borderColor: "divider" }}
        >
          <Tab label="Overview" value="overview" />
          <Tab label="Integration" value="integration" />
          {reduxShopifyIntegration?.integrated
            ? [
                <Tab label="Mapping" value="mapping" />,
                <Tab label="Import" value="import" />,
                <Tab label="Activity" value="activity" />,
                <Tab label="Settings" value="settings" />,
              ]
            : null}
        </Tabs>
      </Stack>
      <TabPanel value={selectedTab} index={"overview"}>
        <ShopifyOverview />
      </TabPanel>
      <TabPanel value={selectedTab} index={"integration"}>
        <ShopifyIntegration />
      </TabPanel>
      {reduxShopifyIntegration?.integrated ? (
        <>
          <TabPanel value={selectedTab} index={"mapping"}>
            <Mapping />
          </TabPanel>
          <TabPanel value={selectedTab} index={"import"} sx={{ p: 3, pt: 0 }}>
            <Import />
          </TabPanel>
          <TabPanel value={selectedTab} index={"activity"} sx={{ p: 3, pt: 0 }}>
            <Activity />
          </TabPanel>
          <TabPanel value={selectedTab} index={"settings"} sx={{ p: 3, pt: 0 }}>
            <Settings />
          </TabPanel>
        </>
      ) : null}
    </Box>
  );
}

export default Shopify;
