import { East, HelpOutline } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Skeleton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AnalyticsChip } from ".";
import {
  formatServerValue,
  getRelativeTimeDifference,
  isArrayWithValues,
  isObjWithValues,
  reduceLargeText,
  secondaryLabel,
  sectionLabel,
} from "../../../helper";
import { getConversations } from "../../../redux/actions/chatActions";
import {
  ChatIllustration,
  InstagramSvg,
  Messenger,
  NewMessageIcon,
  Whatsapp,
} from "../../../Svgs";
import { NoTasks } from "../../chat/ChatHelper";
import PopperTooltip from "../../components/PopperTooltip";

const RecentChats = ({ conversationsFetched }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const isIpad = useMediaQuery(theme.breakpoints.down("md"));
  // const allConversations =
  //   useSelector((state) => state.chats.conversations) || [];
  const [allConversations, setAllConversations] = useState([]);
  const conversationExtra = useSelector(
    (state) => state.chats.conversationExtra
  );
  const fetchingConversations = useSelector(
    (state) => state.chats.fetchingConversations
  );

  const dashboardDetails = useSelector(
    (state) => state.settings.dashboardDetails
  );
  const fetchingDashboardDetails = useSelector(
    (state) => state.settings.fetchingDashboardDetails
  );

  useEffect(() => {
    if (!isObjWithValues(dashboardDetails)) return;
    let { conversations } = dashboardDetails;
    if (isArrayWithValues(conversations?.recent))
      setAllConversations(conversations?.recent?.slice(0, 5));
  }, [dashboardDetails]);

  // useEffect(() => {
  //   if (!isArrayWithValues(allConversations)) dispatch(getConversations());
  // }, []);

  return (
    <Card sx={{ borderRadius: "15px", height: "100%" }}>
      <CardContent>
        <Stack direction="row" alignItems={"center"} spacing={2}>
          <Typography sx={{ ...sectionLabel }}>Recent Chats</Typography>

          <PopperTooltip
            points={[
              "Latest messages (irrespective of channel) will display on top",
            ]}
          >
            <HelpOutline
              fontSize="small"
              style={{ opacity: "0.5", height: "16px", width: "16px" }}
              color="text.secondary"
            />
          </PopperTooltip>
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent={"space-between"}
          my={2}
        >
          <Typography sx={{ ...secondaryLabel }}>
            You have {conversationExtra?.totalUnseen || 0} new messages.
          </Typography>
          <Stack
            direction={"row"}
            alignItems="center"
            sx={{ cursor: "pointer" }}
            onClick={() => navigate("/chats")}
          >
            <Typography sx={{ ...secondaryLabel, color: "primary" }}>
              See all chats
            </Typography>
            <East
              style={{ height: "15px", width: "15px", marginLeft: "5px" }}
            />
          </Stack>
        </Stack>
        {!fetchingDashboardDetails && !isArrayWithValues(allConversations) ? (
          <Box sx={{ margin: "auto", height: "100%" }}>
            <NoTasks
              text={
                <Box sx={{ textAlign: "center" }}>
                  <Typography>You have no new chats.</Typography>
                  <Typography sx={{ ...secondaryLabel, fontSize: "12px" }}>
                    Let's start your first chat.
                  </Typography>
                  <Button
                    variant="outlined"
                    sx={{ mt: 3 }}
                    size="small"
                    // startIcon={<Add />}
                    onClick={() => navigate("/chats")}
                  >
                    Go to chats
                  </Button>
                </Box>
              }
              icon={
                <NewMessageIcon style={{ height: "70px", width: "70px" }} />
              }
            />
          </Box>
        ) : // <Box sx={{ py: 4, mt: 2 }}>
        //   <Stack
        //     direction="row"
        //     justifyContent={"center"}
        //     sx={{ margin: "14px auto", alignItems: "center" }}
        //   >
        //     <ChatIllustration height={200} width={200} />
        //   </Stack>
        //   <Typography
        //     sx={{
        //       fontSize: "14px",
        //       color: "text.secondary",
        //       mt: 3,
        //       textAlign: "center",
        //     }}
        //   >
        //     No Recent Chats found
        //   </Typography>
        // </Box>
        null}
        {isArrayWithValues(allConversations) &&
          !fetchingDashboardDetails &&
          allConversations.slice(0, 5).map((customer, index) => {
            let {
              first_name,
              last_name,
              id,
              platform,
              name,
              profile_pic,
              conversation,
              // username,
              status,
            } = customer;
            let fullName = `${first_name || ""} ${last_name || ""} ${
              name || ""
            } ${customer?.username || ""}`;
            let lastMessageObj = conversation[conversation.length - 1];

            let lastMessageTime = customer?.last_message
              ? getRelativeTimeDifference(new Date(customer?.last_message))
              : {};

            let lastMessage = "";
            if (platform === "whatsapp") {
              lastMessage = lastMessageObj?.message?.text?.body || "";
            }
            if (platform !== "whatsapp")
              lastMessage = lastMessageObj?.message?.text || "";
            let messageUnread = conversation.filter(
              (m) => m?.is_read === 0
            ).length;
            return (
              <Stack
                onClick={() => navigate(`/chats`)}
                direction={"row"}
                alignItems={"center"}
                sx={{
                  borderBottom: index === 4 ? 0 : 1,
                  borderColor: "divider",
                  padding: "10px",
                  gap: "8px",
                  cursor: "pointer",
                }}
              >
                <Avatar height={60} width={60} src={profile_pic} />
                <Stack sx={{ width: "-webkit-fill-available" }}>
                  <Stack
                    direction={"row"}
                    justifyContent="space-between"
                    alignItems={"center"}
                    // flexWrap="wrap"
                  >
                    <Stack direction={"row"} gap={"10px"} alignItems="center">
                      <Typography fontWeight={"500"} fontSize="16px">
                        {reduceLargeText(fullName, 20)}
                      </Typography>
                      {platform === "facebook" ? (
                        <Messenger height={18} width={18} />
                      ) : platform === "instagram" ? (
                        <InstagramSvg height={18} width={18} />
                      ) : (
                        platform === "whatsapp" && (
                          <Whatsapp height={18} width={18} />
                        )
                      )}
                      {customer?.company_name && !isIpad && (
                        <Typography>{customer?.company_name}</Typography>
                      )}
                    </Stack>
                    <Typography sx={{ whiteSpace: "nowrap" }}>
                      {isObjWithValues(lastMessageTime)
                        ? `${lastMessageTime.value || ""} ${
                            lastMessageTime.label || ""
                          } ago`
                        : ""}
                    </Typography>
                  </Stack>
                  <Stack
                    direction={"row"}
                    justifyContent="space-between"
                    alignItems={"center"}
                  >
                    <Stack direction={"row"} alignItems={"center"} gap={1.5}>
                      <Typography fontWeight={"500"} color="text.secondary">
                        {lastMessage.length > 35
                          ? `${lastMessage.slice(0, 35)}...`
                          : lastMessage}
                      </Typography>
                      {customer?.contact_type && (
                        <Chip
                          size="small"
                          label={formatServerValue(customer?.contact_type)}
                        />
                      )}
                    </Stack>
                    {messageUnread ? (
                      <AnalyticsChip
                        sx={{ fontWeight: "500" }}
                        label={messageUnread > 9 ? "+9" : messageUnread}
                        type={"success"}
                        hideIcon={true}
                      />
                    ) : null}
                  </Stack>
                </Stack>
              </Stack>
            );
          })}
        {fetchingDashboardDetails ? (
          <>
            {new Array(5).fill("").map((i, index) => (
              <Stack
                direction={"row"}
                alignItems={"center"}
                sx={{
                  borderBottom: index === 4 ? 0 : 1,
                  borderColor: "divider",
                  padding: "10px",
                  gap: "8px",
                }}
              >
                <Skeleton variant="circular" width={40} height={40} />

                <Stack sx={{ width: "-webkit-fill-available" }}>
                  <Stack
                    direction={"row"}
                    justifyContent="space-between"
                    alignItems={"center"}
                  >
                    <Stack direction={"row"} gap={"10px"} alignItems="center">
                      <Skeleton
                        variant="text"
                        sx={{ fontSize: "1rem", width: "80px" }}
                      />
                      <Skeleton variant="circular" width={18} height={18} />
                    </Stack>

                    <Skeleton
                      variant="text"
                      sx={{ fontSize: "1rem", width: "50px" }}
                    />
                  </Stack>
                  <Stack
                    direction={"row"}
                    justifyContent="space-between"
                    alignItems={"center"}
                  >
                    <Skeleton
                      variant="text"
                      sx={{ fontSize: "1rem", width: "50px" }}
                    />
                    <Skeleton
                      variant="text"
                      sx={{ fontSize: "1rem", width: "25px" }}
                    />
                  </Stack>
                </Stack>
              </Stack>
            ))}
          </>
        ) : null}
      </CardContent>
    </Card>
  );
};

export default RecentChats;
