/* eslint-disable react-hooks/exhaustive-deps */

import {
  Box,
  Card,
  CardContent,
  Dialog,
  Grid,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { DashboardTotalCard } from ".";

import {
  dashboardCardSx,
  dialogStyle,
  hasOnlyProduct,
  isArrayWithValues,
  isObjWithValues,
  jweroColorPalette,
  sectionLabel,
  validateNumber,
} from "../../../helper";

import { InstagramSvg, Messenger, Whatsapp } from "../../../Svgs";
import { ChannelTotalGraph } from "../../chat";
import { CreateUserTask } from "../../chat/UserTasks";
import VisitorsOverview from "../../visitors/VisitorsOverview";

import {
  ContactByLoaction,
  ContactScoreCard,
  CrmLeads,
} from "./crmComponents/hepler";
import GoogleAnalytics from "./GoogleAnalytics";

import MostRequestCatalogGraph from "./MostRequestCatalogGraph";
import MostViewedCatalogProducts from "./MostViewedCatalogProducts";
import NotificationsDashboard from "./NotificationsDashboard";
import ProductAnalyticsBar from "./ProductAnalyticsBar";

import RecentCampaign from "./RecentCampaign";
import RecentCatalogs from "./RecentCatalogs";
import RecentChats from "./RecentChats";
import RecentCustomers from "./RecentCustomers";

import RecentOrders from "./RecentOrders";
import RecentQuotation from "./RecentQuotation";

import UpcomingActivities from "./UpcomingActivities";
import RecentCampaignList from "./RecentCampaignList";
import displayState from "../../../validateProduct";

function RetailerDashboard({
  userTotalData,
  taxonomies,
  allCatalogs,
  fetchedAllCatalogs,
  quotations,
  dashboardDetails,
}) {
  const [formOpen, setFormOpen] = useState(false);
  const [editForm] = useState(false);
  const [channelTotal, setChannelTotal] = useState([]);
  const [hasAllProducts, setHasAllProducts] = useState(false);
  const [cardBarGraph, setCardBarGraph] = useState({
    contacts: [],
    products: [],
    orders: [],
    catalogs: [],
  });
  const user_products = useSelector(
    (state) => state.user?.store_plan?.user_products
  );
  const user_type = useSelector((state) => state.user.user_type);

  useEffect(() => {
    setHasAllProducts(!isArrayWithValues(user_products));
  }, [user_products]);

  let _months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  useEffect(() => {
    if (!isObjWithValues(dashboardDetails)) return;
    let obj = {
      contacts: [],
      products: [],
      orders: [],
      catalogs: [],
    };
    console.log(dashboardDetails, ">dashboardDetails");
    let monthIndex = new Date().getMonth();
    // let monthIndex = 3;
    let months = _months.slice(0, monthIndex + 1);
    months = [..._months.slice(monthIndex + 1, 12), ...months];

    let { contacts, products, orders, catalogs } = dashboardDetails;
    if (contacts?.timeseries)
      for (let month of months)
        obj.contacts.push(contacts?.timeseries?.[month] || 0);
    if (products?.timeseries)
      for (let month of months)
        obj.products.push(products?.timeseries?.[month] || 0);
    if (orders?.timeseries)
      for (let month of months)
        obj.orders.push(orders?.timeseries?.[month] || 0);
    if (catalogs?.timeseries)
      for (let month of months)
        obj.catalogs.push(catalogs?.timeseries?.[month] || 0);

    setCardBarGraph(obj);
  }, [dashboardDetails]);
  useEffect(() => {
    if (!isObjWithValues(dashboardDetails)) return;
    let { conversations } = dashboardDetails;
    if (isObjWithValues(conversations)) {
      let { whatsapp, facebook, instagram } = conversations;
      setChannelTotal([
        { name: "whatsapp", value: whatsapp },
        { name: "facebook", value: facebook },
        { name: "instagram", value: instagram },
      ]);
    }
  }, [dashboardDetails]);

  const closeForm = () => setFormOpen(false);

  const hasProduct = (product) => user_products?.includes(product);
  return (
    <Box sx={{}}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={6}>
          {hasProduct("catalog") ? (
            <TopCardsCatalogs
              cardBarGraph={cardBarGraph}
              dashboardDetails={dashboardDetails}
            />
          ) : hasProduct("chats") ? (
            <TopCardsChats
              cardBarGraph={cardBarGraph}
              dashboardDetails={dashboardDetails}
            />
          ) : hasProduct("whatsapp") ? (
            <TopCardsWhatsapp
              cardBarGraph={cardBarGraph}
              dashboardDetails={dashboardDetails}
            />
          ) : hasProduct("ornate") ? (
            <TopCardsOrante
              cardBarGraph={cardBarGraph}
              dashboardDetails={dashboardDetails}
            />
          ) : hasProduct("crm") ? (
            <TopCardsCRM
              cardBarGraph={cardBarGraph}
              dashboardDetails={dashboardDetails}
            />
          ) : user_type === "supplier" ? (
            <TopCardsSupplier
              cardBarGraph={cardBarGraph}
              dashboardDetails={dashboardDetails}
            />
          ) : user_type === "retailer" ? (
            <TopCards
              cardBarGraph={cardBarGraph}
              dashboardDetails={dashboardDetails}
            />
          ) : null}
        </Grid>
        {hasAllProducts ||
        hasProduct("catalog") ||
        hasProduct("e-commerce") ||
        hasProduct("ornate") ? (
          <Grid item xs={12} sm={12} md={6}>
            <VisitorsOverview />
          </Grid>
        ) : null}

        {hasProduct("chats") ||
        hasProduct("crm") ||
        hasProduct("whatsapp") ||
        hasProduct("e-commerce") ? (
          <Grid item xs={12} sm={12} md={6}>
            <UpcomingActivities />
          </Grid>
        ) : null}
        {hasProduct("chats") ||
        hasProduct("catalog") ||
        hasProduct("crm") ||
        hasProduct("whatsapp") ||
        hasProduct("e-commerce") ||
        hasProduct("ornate") ? (
          <>
            <Grid item xs={12} md={6}>
              <NotificationsDashboard />
            </Grid>
            {hasProduct("chats") ||
              (hasProduct("whatsapp") && (
                // || hasProduct("crm")
                <Grid item xs={12} md={6}>
                  <RecentChats />
                </Grid>
              ))}
            {hasProduct("crm") && (
              <Grid item xs={12} md={6}>
                <ContactTypeCRMGraph dashboardDetails={dashboardDetails} />
              </Grid>
            )}

            {hasProduct("catalog") && (
              <Grid item xs={12} md={6}>
                <MostRequestCatalogGraph />
              </Grid>
            )}
            {hasProduct("ornate") && (
              <Grid item xs={12} md={6}>
                <ProductAnalyticsBar taxonomies={taxonomies} />
                {/* <ProductDistribution taxonomies={taxonomies} /> */}
              </Grid>
            )}
          </>
        ) : null}
        {hasProduct("chats") ? (
          <>
            <Grid item xs={12} md={6}>
              <ChannelTotalGraph channelTotal={channelTotal} />
              {/* <ProductDistribution taxonomies={taxonomies} /> */}
            </Grid>
            {hasProduct("chats") && hasProduct("crm") ? (
              <Grid item xs={12} md={6}>
                <RecentCustomers />
              </Grid>
            ) : null}
          </>
        ) : null}
        {/* <RecentUserTasks openUpdateForm={updateTaskPress} /> */}
      </Grid>
      {hasAllProducts ? (
        <Box sx={{ mt: 5 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <NotificationsDashboard />
            </Grid>
            <Grid item xs={12} md={6}>
              <UpcomingActivities />
              {/* <RecentUserTasks openUpdateForm={updateTaskPress} /> */}
            </Grid>
          </Grid>
        </Box>
      ) : null}
      {hasProduct("e-commerce") ? (
        <>
          {/* <Box sx={{ mt: 5 }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <NotificationsDashboard length={8} />
              </Grid>
              <Grid item xs={12} md={6}>
                <ProductAnalyticsBar taxonomies={taxonomies} />
              </Grid>
            </Grid>
          </Box> */}

          <Box sx={{ mt: 5 }}>
            <RecentOrders />
          </Box>
          <Box sx={{ mt: 5 }}>
            <GoogleAnalytics />
          </Box>
        </>
      ) : null}
      {hasProduct("crm") ? (
        <Box sx={{ mt: 5 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <RecentCampaign />
              {/* <RecentVisits /> */}
            </Grid>
            {/* <Grid item xs={12} md={4}>
              <ContactScoreCard />
            </Grid> */}
            <Grid item xs={12} md={7}>
              <CrmLeads />
            </Grid>
            <Grid item xs={12} md={5}>
              <ContactByLoaction />
            </Grid>
          </Grid>
        </Box>
      ) : null}

      {hasAllProducts ? (
        <Box sx={{ mt: 5 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <ProductAnalyticsBar taxonomies={taxonomies} />
              {/* <ProductDistribution taxonomies={taxonomies} /> */}
            </Grid>
            <Grid item xs={12} md={6}>
              <RecentCustomers />
            </Grid>
          </Grid>
        </Box>
      ) : null}

      {/* {hasProduct("catalog") ? (
        <Box sx={{ mt: 5 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={7}>
              <MostViewed />
            </Grid>
            <Grid item xs={12} md={5}>
            </Grid>
          </Grid>
        </Box>
      ) : null} */}
      {/* <Box sx={{ mt: 5 }}>
        <RecentQuotation quotations={quotations} />
      </Box> */}
      {hasProduct("ornate") ? (
        // || hasProduct("crm")
        <Box sx={{ mt: 5 }}>
          <RecentOrders />
        </Box>
      ) : null}
      {/* {hasProduct("catalog") ? (
      ) : null} */}
      {
        // !hasOnlyProduct("chats", user_products) &&
        displayState({
          key: "dashboardproductAndScore",
          user_products,
        }) && (
          <Box sx={{ mt: 5 }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={7} sx={{}}>
                <MostViewedCatalogProducts />
              </Grid>
              <Grid item xs={12} md={5}>
                {/* <RecentCustomers /> */}
                <ContactScoreCard />

                {/* <RecentCatalogs /> */}
              </Grid>
            </Grid>
          </Box>
        )
      }
      {hasAllProducts && (
        <>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Box sx={{ mt: 5, height: "94%" }}>
                <RecentCatalogs
                  catalogs={allCatalogs}
                  fetchedAllCatalogs={fetchedAllCatalogs}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box sx={{ mt: 5, height: "94%" }}>
                <RecentOrders />
              </Box>
            </Grid>
          </Grid>
          {user_type === "supplier" && (
            <Box sx={{ mt: 5 }}>
              <RecentQuotation quotations={quotations} />
            </Box>
          )}
          <Box sx={{ mt: 5 }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <RecentChats />
              </Grid>
              <Grid item xs={12} md={6}>
                <ChannelTotalGraph channelTotal={channelTotal} />
                {/* <AllConversationsByDate /> */}
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ mt: 5 }}>
            <RecentCampaignList />
          </Box>
          <Box sx={{ mt: 5 }}>
            <GoogleAnalytics />
          </Box>
        </>
      )}

      <Dialog
        open={formOpen}
        keepMounted
        onClose={closeForm}
        aria-describedby="alert-dialog-slide-description"
        maxWidth="sm"
        fullWidth
        sx={{ ...dialogStyle }}
      >
        <CreateUserTask closeForm={closeForm} updateForm={editForm} />
      </Dialog>
    </Box>
  );
}

export default RetailerDashboard;

const TopCards = ({ dashboardDetails, cardBarGraph }) => {
  const navigate = useNavigate();
  return (
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <DashboardTotalCard
          title="Contacts"
          value={dashboardDetails?.contacts?.total || 0}
          values={[
            {
              title: "Customer",
              value: dashboardDetails?.contacts?.customers || 0,
              // prefix: currencySymbols[currency],
            },
            {
              title: "Lead",
              value: dashboardDetails?.contacts?.leads || 0,

              // suffix: "g",
            },
          ]}
          onClick={() => navigate("/crm")}
          graphData={cardBarGraph.contacts}
        />
      </Grid>
      <Grid item xs={6}>
        <DashboardTotalCard
          title="Products"
          value={dashboardDetails?.products?.total || 0}
          values={[
            {
              title: "Ready Stock",
              value: dashboardDetails?.products?.instock || 0,
            },
            {
              title: "Make to order",
              value: dashboardDetails?.products?.onbackorder || 0,
            },
            {
              title: "Sold out",
              value: dashboardDetails?.products?.outofstock || 0,
            },
          ]}
          onClick={() => navigate("/products")}
          graphData={cardBarGraph.products}
        />
      </Grid>
      <Grid item xs={6}>
        <DashboardTotalCard
          title="Catalogs"
          value={dashboardDetails?.catalogs?.total || 0}
          values={[
            {
              title: "Public",
              value: dashboardDetails?.catalogs?.public,
            },
            {
              title: "Private",
              value: dashboardDetails?.catalogs?.private,
            },
          ]}
          onClick={() => navigate("/catalogs")}
          graphData={cardBarGraph.catalogs}
        />
      </Grid>
      <Grid item xs={6}>
        <DashboardTotalCard
          title="Orders"
          value={dashboardDetails?.orders?.total || 0}
          values={[
            {
              title: "Avg. quanity",
              value:
                Math.round(
                  validateNumber(dashboardDetails?.orders?.average_quantity)
                ) || 0,
            },
            {
              title: "Avg. price",
              value:
                Math.round(
                  validateNumber(dashboardDetails?.orders?.average_price)
                ) || 0,
            },
          ]}
          onClick={() => navigate("/orders")}
          graphData={cardBarGraph.orders}
        />
      </Grid>
    </Grid>
  );
};
const TopCardsOrante = ({ dashboardDetails, cardBarGraph }) => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <DashboardTotalCard
          title="Contact"
          value={dashboardDetails?.contacts?.total || 0}
          values={[
            {
              title: "Customer",
              value: dashboardDetails?.contacts?.customers || 0,
              // prefix: currencySymbols[currency],
            },
            {
              title: "Lead",
              value: dashboardDetails?.contacts?.leads || 0,

              // suffix: "g",
            },
          ]}
          // onClick={() => navigate("/crm")}
          graphData={cardBarGraph.contacts}
        />
      </Grid>
      <Grid item xs={6}>
        <DashboardTotalCard
          title="Products"
          value={dashboardDetails?.products?.total || 0}
          values={[
            {
              title: "Ready Stock",
              value: dashboardDetails?.products?.instock || 0,
            },
            {
              title: "Make to order",
              value: dashboardDetails?.products?.onbackorder || 0,
            },
            {
              title: "Sold out",
              value: dashboardDetails?.products?.outofstock || 0,
            },
          ]}
          // onClick={() => navigate("/products")}
          graphData={cardBarGraph.products}
        />
      </Grid>
      <Grid item xs={6}>
        <DashboardTotalCard
          title="Catalogs"
          value={dashboardDetails?.catalogs?.total || 0}
          values={[
            {
              title: "Public",
              value: dashboardDetails?.catalogs?.public,
            },
            {
              title: "Private",
              value: dashboardDetails?.catalogs?.private,
            },
          ]}
          // onClick={() => navigate("/catalogs")}
          graphData={cardBarGraph.catalogs}
        />
      </Grid>
      <Grid item xs={6}>
        <DashboardTotalCard
          title="Orders"
          value={dashboardDetails?.orders?.total || 0}
          values={[
            {
              title: "Avg. quanity",
              value:
                Math.round(
                  validateNumber(dashboardDetails?.orders?.average_quantity)
                ) || 0,
            },
            {
              title: "Avg. price",
              value:
                Math.round(
                  validateNumber(dashboardDetails?.orders?.average_price)
                ) || 0,
            },
          ]}
          // onClick={() => navigate("/orders")}
          graphData={cardBarGraph.orders}
        />
      </Grid>
    </Grid>
  );
};
const TopCardsCRM = ({ dashboardDetails, cardBarGraph }) => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <DashboardTotalCard
          title="Contact"
          value={dashboardDetails?.contacts?.total || 0}
          values={[
            {
              title: "Customer",
              value: dashboardDetails?.contacts?.customers || 0,
              // prefix: currencySymbols[currency],
            },
            {
              title: "Lead",
              value: dashboardDetails?.contacts?.leads || 0,

              // suffix: "g",
            },
          ]}
          // onClick={() => navigate("/crm")}
          graphData={cardBarGraph.contacts}
        />
      </Grid>
      <Grid item xs={6}>
        <DashboardTotalCard
          title="Campaigns"
          value={dashboardDetails?.campaigns?.total || 0}
          values={[
            {
              title: "Email sent",
              value: dashboardDetails?.campaigns?.sent_to || 0,
            },
            {
              title: <Typography color={"error"}>Delivered</Typography>,
              value: dashboardDetails?.campaigns?.delivered || 0,
            },
            {
              title: <Typography color={"error"}>Delivered rate</Typography>,
              value: dashboardDetails?.campaigns?.delivered_rate || 0,
            },
          ]}
          // onClick={() => navigate("/products")}
          graphData={cardBarGraph.campaigns}
        />
      </Grid>
      <Grid item xs={6}>
        <DashboardTotalCard
          title="Activities"
          value={dashboardDetails?.new_customer?.total || 0}
          values={[
            {
              title: "Task",
              value: 0,
            },
            {
              title: "Reminder",
              value: 0,
            },
            {
              title: "Calls",
              value: 0,
            },
          ]}
          // onClick={() => navigate("/new_customer")}
          graphData={cardBarGraph.new_customer}
        />
      </Grid>
      <Grid item xs={6}>
        <DashboardTotalCard
          title="Team member"
          value={dashboardDetails?.leads?.total || 0}
          values={[
            {
              title: "Name",
              value: "",
            },
            {
              title: "Customers",
              value: "0",
            },
            {
              title: "Lead",
              value: "0",
            },
          ]}
          // onClick={() => navigate("/orders")}
          graphData={cardBarGraph.orders}
        />
      </Grid>
    </Grid>
  );
};

const TopCardsSupplier = ({
  userTotalData,
  dashboardDetails,
  cardBarGraph,
}) => {
  const navigate = useNavigate();
  return (
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <DashboardTotalCard
          title="Retailers"
          value={dashboardDetails?.contacts?.total || 0}
          values={[
            {
              title: "Customer",
              value: dashboardDetails?.contacts?.customers || 0,
              // prefix: currencySymbols[currency],
            },
            {
              title: "Lead",
              value: dashboardDetails?.contacts?.leads || 0,

              // suffix: "g",
            },
          ]}
          onClick={() => navigate("/crm")}
          graphData={cardBarGraph.contacts}
        />
      </Grid>
      <Grid item xs={6}>
        <DashboardTotalCard
          title="Products"
          value={dashboardDetails?.products?.total || 0}
          values={[
            {
              title: "Ready Stock",
              value: dashboardDetails?.products?.instock || 0,
            },
            {
              title: "Make to order",
              value: dashboardDetails?.products?.onbackorder || 0,
            },
            {
              title: "Sold out",
              value: dashboardDetails?.products?.outofstock || 0,
            },
          ]}
          onClick={() => navigate("/products")}
          graphData={cardBarGraph.products}
        />
      </Grid>
      <Grid item xs={6}>
        <DashboardTotalCard
          title="Catalogs"
          value={dashboardDetails?.catalogs?.total || 0}
          values={[
            {
              title: "Public",
              value: dashboardDetails?.catalogs?.public || 0,
            },
            {
              title: "Private",
              value: dashboardDetails?.catalogs?.private || 0,
            },
          ]}
          onClick={() => navigate("/catalogs")}
          graphData={cardBarGraph.catalogs}
        />
      </Grid>
      <Grid item xs={6}>
        <DashboardTotalCard
          title="Quotations"
          value={dashboardDetails?.quotations?.total || 0}
          values={[
            {
              title: "Catalogs converted into quotations",
              value: 0,
              suffix: "(0%)",
            },
          ]}
          onClick={() => navigate("/quotations")}
          graphData={cardBarGraph.quotations}
        />
      </Grid>
    </Grid>
  );
};
const TopCardsChats = ({ userTotalData, dashboardDetails, cardBarGraph }) => {
  const navigate = useNavigate();
  return (
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <DashboardTotalCard
          title="Channel"
          points={["Channels connected"]}
          value={
            isObjWithValues(dashboardDetails?.conversations?.channels)
              ? Object.keys(dashboardDetails?.conversations?.channels).length
              : 0
          }
          values={[
            {
              title: (
                <Box>
                  <Whatsapp />
                </Box>
              ),
              value: "Whatsapp",
              key: "whatsapp",
              valueStyle: { fontSize: "11px" },
              // prefix: currencySymbols[currency],
            },
            {
              title: (
                <Box>
                  <Messenger />
                </Box>
              ),
              value: "Messenger",
              key: "facebook",
              valueStyle: { fontSize: "11px" },
              // suffix: "g",
            },
            {
              title: (
                <Box>
                  <InstagramSvg />
                </Box>
              ),
              value: "Instagram",
              key: "instagram",
              valueStyle: { fontSize: "11px" },
              // suffix: "g",
            },
          ].filter(
            (i) =>
              isObjWithValues(dashboardDetails?.conversations?.channels) &&
              Object.keys(dashboardDetails?.conversations?.channels).includes(
                i.key?.toLowerCase()
              )
          )}
          onClick={() => navigate("/chats")}
          // graphData={cardBarGraph.quotations}
        />
      </Grid>

      <Grid item xs={6}>
        <DashboardTotalCard
          title="Contacts"
          value={dashboardDetails?.conversations?.total || 0}
          // values={[
          //   {
          //     title: "Customers",
          //     value: 0,
          //   },
          //   {
          //     title: "Leads",
          //     value: 0,
          //   },
          // ]}
          onClick={() => navigate("/chats")}
          // graphData={cardBarGraph.catalogs}
        />
      </Grid>
      <Grid item xs={6}>
        <DashboardTotalCard
          title="Activities"
          value={dashboardDetails?.conversations?.activities || 0}
          points={["Count of Activities Created"]}
          // values={[
          //   {
          //     title: "Tasks",
          //     value: 0,
          //     // prefix: currencySymbols[currency],
          //   },
          //   {
          //     title: "Reminders",
          //     value: 0,

          //     // suffix: "g",
          //   },
          //   {
          //     title: "Calls",
          //     value: 0,

          //     // suffix: "g",
          //   },
          // ]}
          onClick={() => navigate("/chats")}
          // graphData={cardBarGraph.contacts}
        />
      </Grid>
      <Grid item xs={6}>
        <DashboardTotalCard
          title="Chats"
          value={dashboardDetails?.conversations?.total || 0}
          values={[
            {
              title: (
                <Box>
                  <Whatsapp />
                </Box>
              ),
              value: dashboardDetails?.conversations?.channels?.whatsapp || 0,
              key: "whatsapp",
              // prefix: currencySymbols[currency],
            },
            {
              title: (
                <Box>
                  <Messenger />
                </Box>
              ),
              value: dashboardDetails?.conversations?.channels?.facebook || 0,
              key: "facebook",

              // suffix: "g",
            },
            {
              title: (
                <Box>
                  <InstagramSvg />
                </Box>
              ),
              value: dashboardDetails?.conversations?.channels?.instagram || 0,
              key: "instagram",

              // suffix: "g",
            },
          ].filter((i) =>
            Boolean(dashboardDetails?.conversations?.channels?.[i.key])
          )}
          onClick={() => navigate("/chats")}
          // graphData={cardBarGraph.products}
        />
      </Grid>
    </Grid>
  );
};
const TopCardsWhatsapp = ({
  userTotalData,
  dashboardDetails,
  cardBarGraph,
}) => {
  const navigate = useNavigate();
  return (
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <DashboardTotalCard
          title="Channel"
          points={["Channels connected"]}
          value={
            isObjWithValues(dashboardDetails?.conversations?.channels)
              ? Object.keys(dashboardDetails?.conversations?.channels).length
              : 0
          }
          values={[
            {
              title: (
                <Box>
                  <Whatsapp />
                </Box>
              ),
              value: "Whatsapp",
              key: "whatsapp",
              valueStyle: { fontSize: "11px" },
              // prefix: currencySymbols[currency],
            },
            {
              title: (
                <Box>
                  <Messenger />
                </Box>
              ),
              value: "Messenger",
              key: "facebook",
              valueStyle: { fontSize: "11px" },
              // suffix: "g",
            },
            {
              title: (
                <Box>
                  <InstagramSvg />
                </Box>
              ),
              value: "Instagram",
              key: "instagram",
              valueStyle: { fontSize: "11px" },
              // suffix: "g",
            },
          ].filter(
            (i) =>
              isObjWithValues(dashboardDetails?.conversations?.channels) &&
              Object.keys(dashboardDetails?.conversations?.channels).includes(
                i.key?.toLowerCase()
              )
          )}
          onClick={() => navigate("/chats")}
          // graphData={cardBarGraph.quotations}
        />
      </Grid>

      <Grid item xs={6}>
        <DashboardTotalCard
          title="Contacts"
          value={dashboardDetails?.conversations?.total || 0}
          // values={[
          //   {
          //     title: "Customers",
          //     value: 0,
          //   },
          //   {
          //     title: "Leads",
          //     value: 0,
          //   },
          // ]}
          onClick={() => navigate("/chats")}
          // graphData={cardBarGraph.catalogs}
        />
      </Grid>
      <Grid item xs={6}>
        <DashboardTotalCard
          title="Activities"
          value={dashboardDetails?.conversations?.activities || 0}
          points={["Count of Activities Created"]}
          // values={[
          //   {
          //     title: "Tasks",
          //     value: 0,
          //     // prefix: currencySymbols[currency],
          //   },
          //   {
          //     title: "Reminders",
          //     value: 0,

          //     // suffix: "g",
          //   },
          //   {
          //     title: "Calls",
          //     value: 0,

          //     // suffix: "g",
          //   },
          // ]}
          onClick={() => navigate("/chats")}
          // graphData={cardBarGraph.contacts}
        />
      </Grid>
      <Grid item xs={6}>
        <DashboardTotalCard
          title="Chats"
          value={dashboardDetails?.conversations?.total || 0}
          values={[
            {
              title: (
                <Box>
                  <Whatsapp />
                </Box>
              ),
              value: dashboardDetails?.conversations?.channels?.whatsapp || 0,
              key: "whatsapp",
              // prefix: currencySymbols[currency],
            },
            {
              title: (
                <Box>
                  <Messenger />
                </Box>
              ),
              value: dashboardDetails?.conversations?.channels?.facebook || 0,
              key: "facebook",

              // suffix: "g",
            },
            {
              title: (
                <Box>
                  <InstagramSvg />
                </Box>
              ),
              value: dashboardDetails?.conversations?.channels?.instagram || 0,
              key: "instagram",

              // suffix: "g",
            },
          ].filter((i) =>
            Boolean(dashboardDetails?.conversations?.channels?.[i.key])
          )}
          onClick={() => navigate("/chats")}
          // graphData={cardBarGraph.products}
        />
      </Grid>
    </Grid>
  );
};
const TopCardsCatalogs = ({
  userTotalData,
  dashboardDetails,
  cardBarGraph,
}) => {
  const catalogExtra = useSelector((state) => state.product.catalogExtra);
  const catalogTopGraphs = useSelector(
    (state) => state.product.catalogTopGraphs
  );

  const navigate = useNavigate();
  return (
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <DashboardTotalCard
          title="Catalog created"
          value={dashboardDetails?.catalogs?.total}
          values={[
            {
              title: "Public Catalogs",
              value: dashboardDetails?.catalogs?.public,
              // prefix: currencySymbols[currency],
              info: ["Share catalog link via Whatsapp, Email etc."],
            },
            {
              title: "Private Catalogs",
              value: dashboardDetails?.catalogs?.private,
              info: ["Send a curated catalog to one or more specific contacts"],

              // suffix: "g",
            },
          ]}
          onClick={() => navigate("/catalogs#sent")}
          points={["Total nos of catalogs created, Public and Private"]}
          graphData={catalogTopGraphs?.sent}
        />
      </Grid>
      <Grid item xs={6}>
        <DashboardTotalCard
          title="Sent to contacts"
          value={catalogExtra?.totalSentToCustomers || 0}
          values={[
            {
              title: "Customers",
              value: catalogExtra?.totalSentToCustomers || 0,
              // prefix: currencySymbols[currency],
            },
            {
              title: "Leads",
              value: 0,

              // suffix: "g",
            },
          ]}
          onClick={() => navigate("/catalogs#sent")}
          graphData={catalogTopGraphs?.sent}
          // graphData={cardBarGraph.products}
          points={[
            "Total nos of Contacts (Customers & Leads) whom Catalogs are sent.",
          ]}
        />
      </Grid>
      <Grid item xs={6}>
        <DashboardTotalCard
          title="Response received"
          value={catalogExtra?.totalResponse}
          values={[
            {
              title: "Catalog requests",
              value: catalogExtra?.totalRequests,
            },
            {
              title: "Unique products requested",
              value: catalogExtra?.totalUniqueProductsRequested,
              info: [
                "Unique Product requested - a product selected by multiple contacts, will be counted as 1",
              ],
            },
          ]}
          onClick={() => navigate("/catalogs#requests")}
          graphData={catalogTopGraphs?.request}
          points={[
            "Total nos of responses you received against the Catalogs you sent",
          ]}
        />
      </Grid>
      <Grid item xs={6}>
        <DashboardTotalCard
          title="Products Sent"
          value={catalogExtra?.totalProducts}
          hideNavigate
          points={[
            "Total Nos of product you shared via catalog. A product  shared on multiple catalogs will be counted as 1",
          ]}
          values={[
            {
              title: "No response products",
              value:
                validateNumber(catalogExtra?.totalProducts) -
                  validateNumber(catalogExtra?.totalUniqueProductsRequested) >
                0
                  ? validateNumber(catalogExtra?.totalProducts) -
                    validateNumber(catalogExtra?.totalUniqueProductsRequested)
                  : 0,
              // prefix: currencySymbols[currency],
              info: [
                "No response products - Non performing products, which never bought or selected by contacts.",
              ],
            },
            {
              title: "Avg products per catalog",
              value:
                validateNumber(
                  Number(catalogExtra?.avgProductsPerCatalog).toFixed(2)
                ) || 0,
              // suffix: "g",
            },
          ]}
          onClick={() => navigate("/catalogs")}
          graphData={catalogTopGraphs?.productsSent}
        />
      </Grid>
    </Grid>
  );
};

/////CRM UI//////////
/////////////////////

const ContactTypeCRMGraph = ({ dashboardDetails }) => {
  const [series, setSeries] = useState([]);
  const options = {
    chart: {
      type: "donut",
    },
    colors: jweroColorPalette?.reverse(),
    labels: ["Customers", "Leads", "Undefined"],
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };

  useEffect(() => {
    let { contacts } = dashboardDetails || {};
    let { customers, leads, total } = contacts || {};
    setSeries([
      validateNumber(customers),
      validateNumber(leads),
      validateNumber(total) -
        (validateNumber(customers) + validateNumber(leads)),
    ]);

    return () => {};
  }, [dashboardDetails]);

  return (
    <>
      <Card sx={{ ...dashboardCardSx }}>
        <CardContent>
          <Typography sx={{ ...sectionLabel }}>Contact types</Typography>

          <ReactApexChart
            options={options}
            series={series}
            type="donut"
            width={"70%"}
            style={{ margin: "auto" }}
            margin={"auto"}
          />
        </CardContent>
      </Card>
    </>
  );
};
