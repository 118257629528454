import { Box, Stack, Tab, Tabs, Typography } from "@mui/material";
import React from "react";
import { useState } from "react";
import { TabPanel } from "../../../../helper";
import CCAvenueForm from "./CCAvenueForm";
import Overview from "./Overview";

const CCAvenue = () => {
  const [selectedTab, setSelectedTab] = useState("overview");
  return (
    <Box sx={{ height: "100%", paddingRight: "15px" }}>
      <Stack
        sx={{
          position: "sticky",
          top: "0",
          backgroundColor: "#fff",
          zIndex: 13,
          margin: 0,
          padding: "12px",
        }}
      >
        <Stack
          direction="row"
          justifyContent={"space-between"}
          alignItems="center"
        >
          <Typography
            variant="h3"
            sx={{ display: "flex", alignItems: "center", gap: "10px" }}
          >
            <img
              src="/static/img/brands/ccavenue.png"
              width="25px"
              height="25px"
            />
            CCAvenue
          </Typography>
          {/* {fetchingMetaIntegration && <Stack direction={"row"} alignItems="center" sx={{ gap: "10px" }}>
                        <CircularProgress size={"15px"} /> <Typography>Fetching Integrations...</Typography>
                    </Stack>} */}
        </Stack>
        {/* <Divider sx={{ mt: 4 }} /> */}
        <Tabs
          onChange={(e, newValue) => setSelectedTab(newValue)}
          value={selectedTab}
          sx={{ borderBottom: 1, borderColor: "divider" }}
        >
          <Tab label="Overview" value="overview" />
          {/* <Tab label="How to do?" value="how_to_do" />
          <Tab label="Details" value="details" /> */}
          <Tab label="Integration" value="integration" />
          {/* <Tab label="Settings" value="settings" /> */}
        </Tabs>
      </Stack>
      <TabPanel value={selectedTab} index={"overview"}>
        <Typography><Overview /></Typography>
      </TabPanel>
      <TabPanel value={selectedTab} index={"how_to_do"}>
        <Typography>How to do</Typography>
      </TabPanel>
      <TabPanel value={selectedTab} index={"details"}>
        <Typography>Details</Typography>
      </TabPanel>
      <TabPanel value={selectedTab} index={"integration"}>
        <CCAvenueForm />
      </TabPanel>
    </Box>
  );
};

export default CCAvenue;
