import { East, LocationOnOutlined } from "@mui/icons-material";
import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getRandomAvatar } from ".";
import { sidebarExtraWidth } from "../../App";
import {
  formatDate,
  getInitials,
  getRandomInt,
  getRandomString,
  getStoreName,
  globalCardStyle,
  isArrayWithValues,
  primaryLabel,
  reduceLargeText,
  secondaryLabel,
  sectionLabel,
} from "../../helper";
import { catalogCardStyle } from "../catalogs/CatalogHelper";
import { DashboardTotalCard } from "../dashboards/Default";
import ConnectedSuppliersAvatars from "./components/ConnectedSuppliersAvatars";
import ProductAvailabliltyPie from "./components/ProductAvailabliltyPie";
import ProductsInDBgraph from "./components/ProductsInDBgraph";
import ProductsPulledFrom from "./components/ProductsPulledFrom";
import ProductsPulledGraph from "./components/ProductsPulledGraph";
import ProductsPulledPie from "./components/ProductsPulledPie";
import RecentActivity from "./components/RecentActivity";
import RecentProductActivity from "./components/RecentProductActivity";

const DesignBankDashboard = ({ setSelectedTab }) => {
  const theme = useTheme();
  const isIpad = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Box padding={"20px"}>
      <AnalyticsCard setSelectedTab={setSelectedTab} />
      <Box
        sx={{
          maxWidth: !isIpad ? `calc(100vw - ${sidebarExtraWidth}px)` : "100%",
        }}
      >
        <Grid
          container
          my={4}
          spacing={5}
          style={{
            maxWidth: !isIpad ? `calc(100vw - ${sidebarExtraWidth}px)` : "100%",
          }}
        >
          <Grid item xs={12} md={5}>
            <NotificationsCard />
          </Grid>
          <Grid item xs={12} md={7} sx={{}}>
            <NewSupplierSection />
          </Grid>
          {/* <Grid item xs={6} md={6} lg={6}></Grid> */}
        </Grid>
      </Box>
      <Box>
        <Grid container spacing={4}>
          <Grid item xs={12} md={7.7}>
            <Typography mb={3} variant="h6">
              Products in design bank
            </Typography>
            <ProductInDesginBank />
            <StockAvailibilityStatus />
          </Grid>
          <Grid item xs={12} md={4.3}>
            <RecentActivity />
          </Grid>
        </Grid>
      </Box>
      <Grid container spacing={5} sx={{ mt: 4 }}>
        <Grid item xs={12} md={6}>
          <ProductsInDBgraph />
        </Grid>
        <Grid item xs={12} md={6}>
          <ProductsPulledGraph />
        </Grid>
      </Grid>

      <Grid container spacing={5} sx={{ mt: 4 }}>
        <Grid item xs={12} md={6}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <ProductAvailabliltyPie />
            </Grid>

            <Grid item xs={12} md={6}>
              <ProductsPulledPie />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          <ConnectedSuppliersAvatars />
        </Grid>
      </Grid>
      <Grid container spacing={5} sx={{ mt: 4 }}>
        <Grid item xs={12} md={6}>
          <RecentProductActivity />
        </Grid>
        <Grid item xs={12} md={6}>
          <ProductsPulledFrom />
        </Grid>
      </Grid>
    </Box>
  );
};

export default DesignBankDashboard;

const profiles = [
  {
    url: "https://media.istockphoto.com/id/1332653761/photo/shot-of-a-young-man-exercising-in-nature.jpg?b=1&s=170667a&w=0&k=20&c=nMCw3SWHEjwKtS4DU6z8IiNzfy4agNTU0K4L93rsfYA=",
  },
  {
    url: "https://static.toiimg.com/photo/93724657/93724657.jpg?v=3",
  },
  {
    url: "https://img.freepik.com/free-photo/close-up-smiley-man-taking-selfie_23-2149155156.jpg?w=2000",
  },
  {
    url: "https://assets.vogue.in/photos/604cd4ac6a9323b082cc4e0f/1:1/w_686,h_686,c_limit/selfies.jpg",
  },
];

const AnalyticsCard = ({ setSelectedTab }) => {
  const [profiles, setProfiles] = useState([]);
  const allSuppliers = useSelector((state) => state.supplier.allSuppliers);
  const designBankActivities = useSelector(
    (state) => state.supplier.designBankActivities
  );

  const designbankProductsAnalytics = useSelector(
    (state) => state.supplier.designbankProductsAnalytics
  );
  useEffect(() => {
    let profiles = [];
    if (!isArrayWithValues(allSuppliers)) return;
    for (let supplier of allSuppliers) {
      if (supplier?.details?.store_logo)
        profiles.push({ url: supplier?.details?.store_logo });
    }
    setProfiles(profiles);
  }, [allSuppliers]);
  // useEffect(() => {
  let pull_product = Object.values(designBankActivities || {})
    .filter((obj) => obj?.pulled)
    ?.map((obj) => obj?.pulled)
    .flat();
  // }, [designBankActivities])

  const navigate = useNavigate();
  return (
    <Grid container spacing={2}>
      <Grid item xs={6} sm={4} md={3}>
        <DashboardTotalCard
          title="Suppliers connected"
          value={
            allSuppliers?.filter((obj) => obj?.status === "connected")?.length
          }
          hideGraph
          values={[]}
          onClick={() => navigate("/design-bank/suppliers")}
          // graphData={[cardBarGraph.contacts]}
          profiles={profiles}
        />
      </Grid>
      <Grid item xs={6} sm={4} md={3}>
        <DashboardTotalCard
          title="Total products"
          onClick={() => setSelectedTab("design_bank")}
          value={designbankProductsAnalytics?.total || 0}
          hideGraph
          values={[
            {
              title: "Ready stock",
              value: designbankProductsAnalytics?.readyStock || 0,
            },
            {
              title: "Make to order",
              value: designbankProductsAnalytics?.makeToOrder || 0,
            },
          ]}
          // onClick={() => navigate("/crm")}
          // graphData={cardBarGraph.contacts}
        />
      </Grid>
      <Grid item xs={6} sm={4} md={3}>
        <DashboardTotalCard
          title="Stock available"
          value={designbankProductsAnalytics?.readyStock || 0}
          hideGraph
          values={[
            {
              title: "Diamond",
              value:
                designbankProductsAnalytics?.stockAvailable?.diamondProducts ||
                0,
            },
            {
              title: "Gold",
              value:
                designbankProductsAnalytics?.stockAvailable?.goldProducts || 0,
            },
          ]}
          // onClick={() => navigate("/crm")}
          // graphData={cardBarGraph.contacts}
        />
      </Grid>
      <Grid item xs={6} sm={4} md={3}>
        <DashboardTotalCard
          title="Products pulled"
          value={pull_product?.length}
          graphData={[4, 5, 7, 3, 6, 23, 6, 10, 43, 23]}
          // onClick={() => navigate("/crm")}
          // graphData={cardBarGraph.contacts}
        />
      </Grid>
    </Grid>
  );
};

const NotificationsCard = () => {
  const notifications = useSelector((state) => state.settings.notifications);

  const random = [
    "220 new products added by mayfair.",
    "Ratnalaya Jewellers gave access to 140 products",
    "You are now connected with Ratnalaya Jewellers",
  ];
  const navigate = useNavigate();
  return (
    <Card sx={{ height: "100%", ...globalCardStyle }}>
      <CardContent>
        <Typography variant="h6">Welcome {getStoreName()}</Typography>
        <Stack
          direction={"row"}
          alignItems="center"
          justifyContent={"space-between"}
          my={3}
        >
          <Typography sx={{ ...secondaryLabel, fontWeight: "400" }}>
            You have
          </Typography>{" "}
          <Button
            size="small"
            endIcon={<East />}
            onClick={() => navigate("/design_bank/notifications")}
          >
            Check notification history
          </Button>
        </Stack>

        <Stack rowGap={4}>
          {/* {notifications?.slice(0, 5)?.map((obj) => {
            return (
              <Stack
                direction={"row"}
                alignItems="center"
                justifyContent={"space-between"}
              >
                <Stack direction={"row"} alignItems="center" gap={"15px"}>
                  <Avatar src={getRandomAvatar()} />{" "}
                  <Typography>{obj.message}</Typography>
                </Stack>
                <Typography sx={{ ...secondaryLabel, textAlign: "right" }}>
                  {formatDate(new Date(), "hh:mm am/pm")}
                </Typography>
              </Stack>
            );
          })} */}
          {new Array(4).fill("").map((obj) => {
            return (
              <Stack
                direction={"row"}
                alignItems="center"
                justifyContent={"space-between"}
              >
                <Stack direction={"row"} alignItems="center" gap={"15px"}>
                  <Avatar src={getRandomAvatar()} />{" "}
                  <Typography>
                    {random[getRandomInt(random.length - 1)]}
                  </Typography>
                </Stack>
                <Typography sx={{ ...secondaryLabel }}>
                  {formatDate(new Date(), "hh:mm am/pm")}
                </Typography>
              </Stack>
            );
          })}
        </Stack>
      </CardContent>
    </Card>
  );
};

const NewSupplierSection = () => {
  const theme = useTheme();
  const isIpad = useMediaQuery(theme.breakpoints.down("md"));
  const lgUp = useMediaQuery(theme.breakpoints.down("lg"));
  const allSuppliers = useSelector((state) => state.supplier.allSuppliers);
  const gettingAllBusinesses = useSelector(
    (state) => state.supplier.gettingAllBusinesses
  );
  const navigate = useNavigate();
  return (
    <Card
      sx={{
        ...globalCardStyle,
      }}
    >
      <CardContent>
        <Stack
          direction={"row"}
          alignItems="center"
          justifyContent={"space-between"}
        >
          <Stack>
            <Typography variant="h6">
              {allSuppliers?.length} new Suppliers
            </Typography>
            <Typography sx={{ ...secondaryLabel }}>
              You may be intersted in
            </Typography>
          </Stack>
          <Button onClick={() => navigate("/design-bank/suppliers")}>
            View all
          </Button>
        </Stack>
        <Stack
          direction="row"
          overflow={"scroll"}
          className="scrollbar-hidden"
          //   spacing={3}
          sx={{
            maxWidth: !isIpad
              ? `calc(100vw - ${sidebarExtraWidth + 200}px)`
              : "100%",
          }}
        >
          {gettingAllBusinesses
            ? new Array(7).fill("")?.map((str) => <LoadingSupplierInfoCard />)
            : null}
          {allSuppliers?.map((customer) => (
            <div
              onClick={() =>
                navigate(`/design-bank/new_suppliers/${customer?.id}`)
              }
            >
              <SupplierInfoCard customer={customer} />
            </div>
          ))}
        </Stack>
      </CardContent>
    </Card>
  );
};

const ProductInDesginBank = () => {
  const designbankProductsAnalytics = useSelector(
    (state) => state.supplier.designbankProductsAnalytics
  );

  return (
    <>
      <Grid container spacing={3} mb={4}>
        <Grid item xs={6} md={3}>
          <QuanityCard
            value={designbankProductsAnalytics?.goldJewellery || 0}
            label={"Gold Jewellery"}
            bgColor={"#012687"}
            imgSrc={"https://cdn-icons-png.flaticon.com/512/4259/4259798.png"}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <QuanityCard
            value={designbankProductsAnalytics?.diamondJewelley || 0}
            label={"Diamond Jewellery"}
            bgColor={"#0012b9"}
            imgSrc={"https://cdn-icons-png.flaticon.com/512/4507/4507707.png"}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <QuanityCard
            value={designbankProductsAnalytics?.platinumJwewlley || 0}
            label={"Platinum Jewellery"}
            bgColor={"#616ada"}
            imgSrc={"https://cdn-icons-png.flaticon.com/512/4017/4017423.png"}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <QuanityCard
            value={designbankProductsAnalytics?.silverJewellery || 0}
            label={"Silver Jewellery"}
            bgColor={"#f6b11c"}
            imgSrc={"https://cdn-icons-png.flaticon.com/512/1176/1176337.png"}
          />
        </Grid>
      </Grid>
    </>
  );
};

export const LoadingSupplierInfoCard = () => {
  return (
    <Stack
      component={Card}
      direction={"row"}
      sx={{
        mt: 5,
        mb: 5,
        mx: 3,
        minWidth: "145px",
        textAlign: "center",
        justifyContent: "center",
        maxWidth: "170px",
        // ...globalCardStyle
        // alignItems: "center",
      }}
      // sx={{ textAlign: "center", justifyContent: "center" }}
      // gap={"8px"}
    >
      <CardContent>
        <Box
          sx={{
            textAlign: "center",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Skeleton variant="circular" width={90} height={90} />

          <Typography sx={{ ...primaryLabel, mt: 2 }}>
            <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
          </Typography>
          {/* <Typography sx={{ ...secondaryLabel, mt: 1 }}>
          {reduceLargeText(`${customer.sku || ""}`, 12)}
        </Typography> */}

          <Typography
            sx={{
              ...primaryLabel,
              mt: 2,
              color: "info.main",
              textAlign: "center",
              fontSize: "12px",
            }}
          >
            {/* {reduceLargeText(`${customer.name || ""}`, 12)} */}
            <Skeleton variant="text" sx={{ fontSize: "1rem", width: "60%" }} />
          </Typography>

          <Skeleton variant="text" sx={{ fontSize: "1rem", mt: 2 }} />
        </Box>
      </CardContent>
    </Stack>
  );
};
export const SupplierInfoCard = ({ customer = {} }) => {
  const naviagte = useNavigate();
  return (
    <Stack
      component={Card}
      direction={"row"}
      sx={{
        mt: 5,
        mb: 5,
        mx: 3,
        minWidth: "145px",
        textAlign: "center",
        justifyContent: "center",
        maxWidth: "170px",
        // ...globalCardStyle
        // alignItems: "center",
      }}
      // sx={{ textAlign: "center", justifyContent: "center" }}
      // gap={"8px"}
    >
      <CardContent>
        <Box
          sx={{
            textAlign: "center",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {/* {customer?.details?.store_logo ? (
            <img
              src={customer?.details?.store_logo || ""}
              style={{
                width: 90,
                height: 90,
                margin: "14px auto",
                marginTop: "8px",
                objectFit: "contain",
              }}
            />
          ) : (
            <Box
              sx={{
                width: 90,
                height: 90,
                // margin: "auto",
                marginTop: "8px",
                // justifyContent: "center",
                // alignItems: "center",
              }}
            >
              <Typography
                align="center"
                sx={{
                  fontSize: "18px",
                  margin: "auto",
                  verticalAlign: "center",
                }}
              >
                BR
              </Typography>
            </Box>
          )} */}
          {customer?.details?.store_logo ? (
            <Avatar
              src={customer?.details?.store_logo || ""}
              alt={customer?.name || ""}
              style={{
                width: 90,
                height: 90,
                margin: "auto",
                // marginTop: "8px",
                objectFit: "contain",
              }}
            >
              {getInitials(`${customer?.name || ""}`)}
            </Avatar>
          ) : (
            <Box
              display="flex"
              style={{
                width: 90,
                height: 90,
                backgroundColor: "#465065",
                borderRadius: "50%",
              }}
              alignItems="center"
              justifyContent="center"
            >
              <Typography
                align="center"
                sx={{
                  ...sectionLabel,
                  color: "#fff",
                  fontSize: "26px",
                  fontWeight: "400",
                }}
              >
                {getInitials(`${customer?.name || ""}`)}
              </Typography>
            </Box>
          )}
          <Tooltip title={customer?.name}>
            <Typography sx={{ ...primaryLabel, mt: 2 }}>
              {reduceLargeText(`${customer?.name || ""}`, 9)}
            </Typography>
          </Tooltip>
          {/* <Typography sx={{ ...secondaryLabel, mt: 1 }}>
          {reduceLargeText(`${customer.sku || ""}`, 12)}
        </Typography> */}
          <Stack
            direction="row"
            alignItems={"center"}
            justifyContent="center"
            sx={{ mt: 2 }}
          >
            <LocationOnOutlined
              style={{ fontSize: "13px", mr: 1, color: "info.main" }}
            />
            <Typography sx={{ ...secondaryLabel, ml: 1 }}>
              {reduceLargeText(`${customer?.address?.city || ""}`, 8)}
            </Typography>
          </Stack>
          <Typography
            sx={{
              ...primaryLabel,
              mt: 2,
              color: "info.main",
              textAlign: "center",
              fontSize: "12px",
            }}
          >
            {/* {reduceLargeText(`${customer.name || ""}`, 12)} */}
            Gold Jewellery
          </Typography>
          <Stack direction="row" alignItems={"center"} justifyContent="center">
            <Typography sx={{ ...secondaryLabel, ml: 1 }}>
              {customer?.inviteOn
                ? formatDate(
                    new Date(customer?.inviteOn),
                    "dd:mm:yyyy hh:mm am/pm"
                  )
                : ""}
            </Typography>
          </Stack>
          <Button
            variant="outlined"
            size="small"
            sx={{ mt: 2 }}
            onClick={() =>
              customer.status === "connected"
                ? naviagte(`/design-bank/suppliers/${customer?.id}`)
                : naviagte(`/design-bank/new_suppliers/${customer?.id}`)
            }
          >
            View Profile
          </Button>
        </Box>
        {/* <Stack sx={{ width: "-webkit-fill-available" }}>
            <Stack direction="row" alignItems={"center"}>
              <Typography fontSize={"16px"} fontWeight={"500"}>
                {fullName}
              </Typography>
              <Box sx={{ ml: 2 }}>
                {customerInfo?.facebook_id ? (
                  <Messenger height={17} width={17} />
                ) : customerInfo?.instagram_id ? (
                  <InstagramSvg height={17} width={17} />
                ) : customerInfo?.whatsapp_id ? (
                  <Whatsapp height={17} width={17} />
                ) : (
                  <Storefront style={{ height: "17px", width: "17px" }} />
                )}
              </Box>
            </Stack>
            <Stack
              direction={"row"}
              alignItems="center"
              justifyContent={"space-between"}
              sx={{ width: "80%" }}
            >
              <Typography sx={{ fontSize: "13px", color: "text.secondary" }}>
                Last active :{" "}
                {customerInfo?.wc_last_active
                  ? formatDate(
                      new Date(Number(customerInfo?.wc_last_active) * 1000),
                      "dd:mm:yyyy hh:mm"
                    )
                  : ""}
              </Typography>
            </Stack>
          </Stack>
          <IconButton onClick={() => navigate(`/crm/${customerInfo?.id}`)}>
            <ArrowForward />
          </IconButton> */}
      </CardContent>
    </Stack>
  );
};

const QuanityCard = ({ label, value, imgSrc, bgColor }) => {
  return (
    <Card sx={{ backgroundColor: bgColor, ...globalCardStyle, height: "100%" }}>
      <CardContent
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Stack direction={"row"} justifyContent="space-between">
          <Typography variant="h4" color={"#fff"}>
            {value}
          </Typography>
          <img src={imgSrc} height={"50px"} />
        </Stack>
        <Typography variant="h6" fontWeight={"300"} color={"#fff"}>
          {label}
        </Typography>
      </CardContent>
    </Card>
  );
};

const random = [
  "1 new supplier connect request received",
  "203 products has been added by ratnalaya jewellers",
  "AR-243 is now Out of Stock",
  "AR-243 is now Out of Stock",
];

const randomSuppliers = ["Mayfair jewellers", "Ratnalaya jwellers", "Go gems"];
const StockAvailibilityStatus = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const StockRow = () => {
    return (
      <Stack direction={"row"} justifyContent="space-between">
        <Stack direction={"row"} gap={"10px"}>
          <Avatar src={getRandomAvatar()} sx={{ width: 50, height: 50 }} />
          <Stack rowGap={"4px"}>
            <Typography variant="subtitle2">
              {random[getRandomInt(random.length - 1)]}
            </Typography>
            <Typography sx={{ ...secondaryLabel }}>
              {randomSuppliers[getRandomInt(randomSuppliers.length - 1)]}
            </Typography>
          </Stack>
        </Stack>
        {!isMobile && (
          <Stack direction={"row"} gap={"25px"}>
            <Typography sx={{ ...secondaryLabel }}>
              {formatDate(new Date(), "dd/mm/yyyy")}
            </Typography>
            <Typography sx={{ ...secondaryLabel }}>
              {formatDate(new Date(), "hh:mm am/pm")}
            </Typography>
          </Stack>
        )}
      </Stack>
    );
  };
  return (
    <Card mt={3} sx={{ ...globalCardStyle }}>
      <CardContent>
        <Stack
          direction={"row"}
          alignItems="center"
          justifyContent={"space-between"}
          mb={4}
        >
          <Typography variant="h6">Stock availibility status</Typography>
          <Button endIcon={<East />}>Notification history</Button>
        </Stack>

        <Stack rowGap={4}>
          {new Array(8).fill("").map((obj) => {
            return <StockRow />;
          })}
        </Stack>
      </CardContent>
    </Card>
  );
};
