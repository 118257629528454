import {
  Avatar,
  Box,
  Card,
  CardContent,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import {
  formatMessageTime,
  getAxiosError,
  getRandomId,
  getTimeInSeconds,
  getToken,
  getWebsite,
  isArrayWithValues,
  secondaryLabelSmall,
} from "../../../helper";
import { QuestionAnswerOutlined, Send } from "@mui/icons-material";
import axios from "axios";
import { useSelector } from "react-redux";

function QuotationComments({ quotation = {}, setQuotation }) {
  let [chats, setChats] = useState([]);

  const isAdmin = useSelector((state) => state.user.isAdmin);
  const user_id = useSelector((state) => state.user.id);
  const user = useSelector((state) => state.user);

  const messageInputRef = useRef();
  useEffect(() => {
    let { comments } = quotation;
    if (isArrayWithValues(comments)) setChats(comments);
  }, [quotation]);

  const onMessageSend = async ({ message }) => {
    let commentObj = {
      from: isAdmin
        ? "Admin"
        : `${user?.first_name || ""} ${user?.last_name || ""}`,
      is_sender: true,
      time: getTimeInSeconds(),
      message,
      id: getRandomId(),
    };
    let { comments = [] } = quotation;

    let allComments = isArrayWithValues(comments)
      ? [...comments, commentObj]
      : [commentObj];
    setQuotation((state) => ({ ...state, comments: allComments }));
    messageInputRef.current?.clearMessage();
    let res = await addProductComment({ quotation, allComments });
    console.log(res, "<<<<< comments add res");
  };

  return (
    <Card sx={{ mt: 3 }}>
      <CardContent>
        <Box sx={{ maxHeight: "300px", height: "300px", overflow: "scroll" }}>
          {isArrayWithValues(chats) ? (
            chats.map((obj) => {
              return (
                <ChatBubble
                  isOpposite={obj?.from === "supplier"}
                  payload={obj}
                />
              );
            })
          ) : (
            <Stack
              sx={{
                justifyContent: "center",
                alignItems: "center",
                flex: 1,
                height: "100%",
              }}
            >
              <QuestionAnswerOutlined
                style={{ color: "#e8e8e8", height: "100px", width: "100px" }}
              />
              <Typography
                sx={{ mt: 1, ...secondaryLabelSmall, color: "#b9b9b9" }}
              >
                Ask queries about quotation
              </Typography>
            </Stack>
          )}
        </Box>
        <MessageInput ref={messageInputRef} onMessageSend={onMessageSend} />
      </CardContent>
    </Card>
  );
}

const textFieldStyle = {
  "& fieldset": {
    borderRadius: "16px",
    overflow: "hidden",
  },
  borderRadius: "16px",
  backgroundColor: "#f7f8fe",
};

const MessageInput = forwardRef(({ onMessageSend }, ref) => {
  const [message, setMessage] = useState("");
  useEffect(() => {}, []);

  useImperativeHandle(ref, () => {
    return {
      clearMessage() {
        setMessage("");
      },
    };
  });

  return (
    <Box sx={{ mt: 2 }}>
      {/* <TextField fullWidth /> */}
      <TextField
        onKeyDown={(e) => {
          if (e.key === "Enter" && e.ctrlKey && message?.trim())
            onMessageSend({ message });
        }}
        sx={{ ...textFieldStyle }}
        variant="outlined"
        fullWidth
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        // size="small"
        placeholder="Send message"
        InputProps={{
          endAdornment: (
            <InputAdornment>
              <IconButton onClick={() => onMessageSend({ message })}>
                <Send />
              </IconButton>
            </InputAdornment>
          ),
        }}
        // value={form.last_name}
        // onChange={(e) => editForm({ last_name: e.target.value })}
      />
    </Box>
  );
});

const ChatBubble = ({ payload = {} }) => {
  let { from, message, time, is_sender } = payload;
  let isOpposite = is_sender;
  return (
    <>
      <Typography
        sx={{
          ...secondaryLabelSmall,
          mb: 1,
          mt: 2,
          mx: 2,
          textAlign: isOpposite ? "left" : "right",
        }}
      >
        {isOpposite ? from : ""}
      </Typography>
      <Stack
        direction="row"
        alignItems={"flex-end"}
        justifyContent={isOpposite ? "flex-start" : "flex-end"}
        mt={1}
        spacing={2}
      >
        {!isOpposite && (
          <Typography sx={{ ...secondaryLabelSmall, mb: 1 }}>
            {formatMessageTime(new Date(Number(time) * 1000))}
          </Typography>
        )}
        {isOpposite && (
          <Avatar style={{ height: "24px", width: "24px" }} sx={{ mb: 1 }} />
        )}
        <Box
          sx={{
            backgroundColor: isOpposite ? "#ebebff" : "#ededed",
            py: 3,
            px: 3,
            borderRadius: "14px",
            borderBottomLeftRadius: isOpposite ? 0 : "14px",
            borderBottomRightRadius: !isOpposite ? 0 : "14px",
            maxWidth: "70%",
          }}
        >
          <Typography>{message}</Typography>
        </Box>
        {isOpposite && (
          <Typography sx={{ ...secondaryLabelSmall, mb: 1 }}>
            {formatMessageTime(new Date(Number(time) * 1000))}
          </Typography>
        )}
      </Stack>
    </>
  );
};

export default QuotationComments;

const addProductComment = async ({ quotation, allComments }) => {
  try {
    let obj = {
      type: "edit",
      id: quotation.id,
      data: {
        comments: allComments,
      },
    };
    let website = await getWebsite();
    let token = await getToken();
    let res = await axios({
      url: `${website}/wp-json/store/v1/quotations`,
      method: "POST",
      headers: {
        Authorization: `Basic ${token}`,
      },
      data: obj,
    });
    if (res.data.success) {
      return true;
    }
    return false;
  } catch (e) {
    console.log(getAxiosError(e));
    return false;
  }
};
