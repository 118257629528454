import { Typography } from "@mui/material";
import { Box, styled } from "@mui/material";
import {
  BellCircle,
  CallCircle,
  CheckCircle,
  Facebook,
  InstagramSvg,
  Messenger,
  MetaSvg,
  Whatsapp,
} from "../../Svgs";

export const userActivityTitle = [
  { label: "Send a catalog", value: "send_catalog" },
  { label: "Send metal rate", value: "send_metal_rate" },
  { label: "Make delivery", value: "make_delivery" },
  { label: "Book a call", value: "book_voice_call" },
  { label: "Book a video call", value: "book_video_call" },
];
export const userActivityStatus = [
  { label: "Deferred", value: "deferred" },
  { label: "In progress", value: "in_progress" },
  { label: "Completed", value: "complete" },
  { label: "Waiting for input", value: "waiting_for_input" },
];

export const chatgGlobalColors = [
  "#023E8A",
  "#0077B6",
  "#0096C7",
  "#00B4D8",
  "#48CAE4",
];
export const chatgGlobalColorsLight = [
  "#48CAE4",
  "#90E0EF",
  "#ADE8F4",
  "#CAF0F8",
];

export const taskIcons = {
  task: <CheckCircle />,
  reminder: <BellCircle />,
  call: <CallCircle />,
};

export const platformIcons = {
  whatsapp: <Whatsapp />,
  facebook: <Messenger />,
  instagram: <InstagramSvg />,
};

export const platformColors = {
  whatsapp: "#25D366",
  facebook: "#4267B2",
  instagram:
    "linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%)",
};

export const primaryLabel = {
  fontSize: "14px",
  fontWeight: "500",
};
export const secondaryLabel = {
  fontSize: "13px",
  color: "text.secondary",
};

export const sectionLabel = {
  fontSize: "20px",
  fontWeight: "800",
  padding: "10px 0px 12px 10px",
};

export const newContactHeadCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Contact Name",
  },
  // {
  //   id: "calories",
  //   numeric: true,
  //   disablePadding: false,
  //   label: "Location",
  // },
  {
    id: "fat",
    numeric: true,
    disablePadding: false,
    label: "Date",
  },
  {
    id: "carbs",
    numeric: true,
    disablePadding: false,
    label: "Channels",
  },
  {
    id: "protein",
    numeric: true,
    disablePadding: false,
    label: "Message",
  },
  // {
  //   id: "action",
  //   numeric: true,
  //   disablePadding: false,
  //   label: "Action",
  // },
];
export const userTasksHeadCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Contact Name",
  },
  {
    id: "calories",
    numeric: false,
    disablePadding: false,
    label: "Task Title",
  },
  {
    id: "fat",
    numeric: true,
    disablePadding: false,
    label: "Date",
  },
  {
    id: "carbs",
    numeric: true,
    disablePadding: false,
    label: "Description",
  },
  {
    id: "protein",
    numeric: true,
    disablePadding: false,
    label: "User",
  },
  {
    id: "Schedule date",
    numeric: true,
    disablePadding: false,
    label: "Schedule date",
  },
  {
    id: "Status",
    numeric: true,
    disablePadding: false,
    label: "Status",
  },
  {
    id: "Actions",
    numeric: true,
    disablePadding: false,
    label: "Actions",
  },
];
export const userTasksHeadCellsWithoutCustomer = [
  {
    id: "Title",
    numeric: false,
    disablePadding: false,
    label: "Task Title",
  },
  {
    id: "fat",
    numeric: true,
    disablePadding: false,
    label: "Date",
  },
  {
    id: "carbs",
    numeric: true,
    disablePadding: false,
    label: "Description",
  },
  {
    id: "protein",
    numeric: true,
    disablePadding: false,
    label: "User",
  },
  {
    id: "Schedule date",
    numeric: true,
    disablePadding: false,
    label: "Schedule date",
  },
  {
    id: "Status",
    numeric: true,
    disablePadding: false,
    label: "Status",
  },
  {
    id: "Actions",
    numeric: true,
    disablePadding: false,
    label: "Actions",
  },
];

export const totalConversationsOptions = {
  chart: {
    id: "area-datetime",
    type: "area",
    height: 350,
    zoom: {
      autoScaleYaxis: true,
    },
    toolbar: {
      show: true,
      offsetX: 0,
      offsetY: 0,
      tools: {
        reset: true,
      },
    },
  },
  colors: ["#25D366", "#4267B2", "#E1306C"],
  // colors: catalogGlobalColors,
  dataLabels: {
    enabled: false,
  },
  markers: {
    size: 0,
    style: "hollow",
  },
  yaxis: [
    {
      labels: {
        formatter: function (val) {
          return val?.toFixed(0);
        },
      },
    },
  ],
  xaxis: {
    type: "datetime",
    // min: new Date("01 Mar 2012").getTime(),
    tickAmount: 6,
  },
  tooltip: {
    x: {
      format: "dd MMM yyyy",
    },
  },
  fill: {
    type: "gradient",
    gradient: {
      shadeIntensity: 1,
      opacityFrom: 0.7,
      opacityTo: 0.9,
      stops: [0, 100],
    },
  },
};

export const NoTasks = ({ icon, text, sx = {} }) => {
  return (
    <Box
      sx={{
        my: 18,
        height: "100%",
        justifyContent: "center",
        display: "flex",
        ...sx,
      }}
    >
      <Box sx={{ gap: "14px" }}>
        <Box
          sx={{
            margin: "auto",
            alignSelf: "center",
            justifyContent: "center",
            display: "flex",
            textAlign: "center",
          }}
        >
          {icon}
        </Box>
        <Typography sx={{ fontSize: "15px", color: "text.secondary", mt: 4 }}>
          {text}
        </Typography>
      </Box>
    </Box>
  );
};
