import axios from "axios";
import {
  apiCallFromSocket,
  createObjFromMapping,
  getAxiosError,
  isArrayWithValues,
  sleep,
} from "../../../../helper";
import { jemisysMapping } from "../../../../mappingArrays";
import { jemisysSilverInventory_API } from "./Integration";

export const getJemisysProducts = async ({
  code,
  inventory_ip_port,
  APIKey,
}) => {
  try {
    let { data: res } = await apiCallFromSocket(
      {
        url: jemisysSilverInventory_API(inventory_ip_port, code),
        headers: {
          APIKey: APIKey,
          "Content-Type": "application/json",
        },
        method: "POST",
      },
      "https://websockets.tanika.tech"
    );
    console.log(res, "<<<<<<alsdjflsfj");
    return res?.data?.inventories;
  } catch (error) {}
};

export const getJweroProductFromJemisys = ({
  products,
  setId,
  make_live = false,
}) => {
  //   let outsideKey = [];
  let batchArray = products?.map((obj) => {
    let jweroProduct = createObjFromMapping({
      mapping: jemisysMapping,
      payload: obj,
    });
    jweroProduct.stock_status = obj?.availability ? "instock" : "outofstock";
    jweroProduct.stock_quantity = obj?.availability || null;
    jweroProduct.status = make_live ? "publish" : "draft";
    if (setId) jweroProduct.id = obj.id;
    // jweroProduct.password = "password"
    jweroProduct.meta_data = [
      ...(jweroProduct.meta_data || []),
      {
        key: "jemisys_product",
        value: {
          id: obj?.productCode,
        },
      },
      {
        key: "wastage_from",
        value: "weight",
      },
      {
        key: "making_from",
        value: "customize",
      },
      {
        key: "colostone_from",
        value: "customize",
      },
      {
        key: "diamond_from",
        value: "customize",
      },
      {
        key: "labourType",
        value: "individual",
      },
      {
        key: "metal_types",
        value: ["silver"],
      },
      {
        key: "silver_gross",
        value: obj.grams || 0,
      },
      // {
      //   key: "gold_gross",
      //   value: 0,
      // },
      {
        key: "silver_net",
        value: obj.grams || 0,
      },
      // {
      //     key: "labourType",
      //     value: "master",
      // },
      // {
      //     key: "labour_from",
      //     value: "gross",
      // },
    ];
    // if(obj?.)

    return jweroProduct;
  });

  return batchArray || [];
};

export const _getJemisysProductsFromTanika = async (website, token) => {
  try {
    let array = [];
    let page = 1;
    let per_page = 500;
    for (let i of Array(100)) {
      let { data } = await axios({
        url: `${website}/wp-json/wc/v3/products`,
        headers: { Authorization: `Basic ${token}` },
        // headers: { apikey: `${token}` },
        params: {
          per_page,
          page,
          "custom_filter[0][key]": "jemisys_product",
          "custom_filter[0][value]": "",
          "custom_filter[0][compare]": "LIKE",
          // "custom_filter[0][type]": "NUMERIC",
        },
      });
      // if (isArrayWithValues(data)) {
      array = array.concat(data);
      if (data.length < per_page) break;
      await sleep(page < 10 ? page * 1000 : 3000);
      // }
      page++;
    }
    return array;
  } catch (error) {
    console.log(getAxiosError(error), "fetching orante products from tanika");
  }
};
