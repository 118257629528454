import { SET_HELPER_DATA } from "../actions/helperActions";

const initialState = {
  globalToast: { show: false },
  globalSnackbar: { show: false },
  globalTutorial: { show: false, url: "" },
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_HELPER_DATA:
      return { ...state, ...payload };
    default:
      return state;
  }
};
export default reducer;
