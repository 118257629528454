import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Typography, IconButton, Tooltip, Fade } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import * as actions from "../../../../../../redux/actions/flowsActions";
import { getStoreName } from "../../../../../../helper";
// import * as actions from "../../../redux_setup/actions/actionsTypes";
// import CloseChatbotAlert from "./CloseChatbotAlert";
// import { backendApiCall } from "../utils/backendApiCall";
// import { updateChatUserDetails } from "../utils/user";
// import { createActivity } from "../utils/activities";

// {"brand_name":"Tiara By TJ","favicon_url":"https://tiarabytj.com/wp-content/uploads/sites/27/2020/01/cropped-2_tiara-logo.png","brand_color":""}

const HeaderTop = ({ handleClose }) => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const brandDetails = useSelector((state) => state.chatbot?.brandDetails);
  const chabotMinimized = useSelector((state) => state.flow?.isMinimized);
  const userDetails = useSelector((state) => state.chatbot?.userDetails);
  const brandName = getStoreName() ? `Welcome to ${getStoreName()}` : "Welcome";

  // const user_platform_id = useSelector(
  //   (state) => state.chatbot?.user_platform_id
  // );

  // const handleCloseChat = async (e) => {
  //   await updateChatUserDetails({
  //     id: userDetails?.id,
  //     payload: { meta_data: { agent_can_respond: false } },
  //   });
  //   await createActivity({
  //     payload: {
  //       type: "live_agent",
  //       created_by: { type: "bot" },
  //       sub_type: "disconnected",
  //       reference_type: "contact",
  //       reference_id: userDetails?.id + "",
  //       reference: {
  //         first_name: userDetails?.first_name,
  //         last_name: userDetails?.last_name,
  //       },
  //       type_id: `${userDetails?.assigned_team_member?.[0]?.id || ""}`,
  //     },
  //   });
  //   setAnchorEl(null);
  //   const value = "You have closed the chat";
  //   const commentMessage = {
  //     id: Date.now(),
  //     text: value,
  //     type: "rating",
  //   };

  //   setTimeout(() => {
  //     dispatch({ type: actions.CHATBOT_ADD_MESSAGES, payload: commentMessage });
  //   }, [700]);
  //   // dispatch({
  //   //   type: actions.EDIT_ELEMENTS_IN_CHATBOT,
  //   //   payload: { isChatOpen: false },
  //   // });
  //   // dispatch({
  //   //   type: actions.EDIT_ELEMENTS_IN_CHATBOT,
  //   //   payload: { messages: [] },
  //   // });

  // };

  // const handleMinimize = () => {
  //   dispatch({ type: actions.RESET_TEST_BOT_MESSAGES });
  //   dispatch({
  //     type: actions.EDIT_ELEMENT_IN_REDUX,
  //     payload: {
  //       isMinimized: true,
  //     },
  //   });
  //   setAnchorEl(null);
  // };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        padding: "10px",
        borderBottom: "1.5px solid rgb(234, 238, 243)",
      }}
    >
      <Typography
        variant="span"
        sx={{
          fontWeight: 600,
          fontFamily: "Inter",
          fontSize: "13px",
        }}
      >
        {brandName}
      </Typography>
      <Box sx={{ position: "absolute", right: "25px" }}>
        {/* <Tooltip
          title="Minimize"
          arrow
          TransitionComponent={Fade}
          enterDelay={1000}
          enterNextDelay={1000}
          TransitionProps={{ timeout: 1000 }}
          PopperProps={{
            style: {
              zIndex: 99999,
            },
          }}
          componentsProps={{
            tooltip: {
              sx: {
                color: "#fff",
                fontSize: "12px",
                padding: "5px",
              },
            },
          }}
        >
          <HorizontalRuleIcon
            onClick={handleMinimize}
            sx={{
              position: "relative",
              top: "3px",
              padding: "2px",
              marginRight: "8px",
              "&:hover": {
                cursor: "pointer",
                backgroundColor: "#e8e8e8",
                borderRadius: "35%",
              },
            }}
          />
        </Tooltip> */}
        <Tooltip
          title="Close"
          arrow
          TransitionComponent={Fade}
          enterDelay={1000}
          enterNextDelay={1000}
          TransitionProps={{ timeout: 1000 }}
          PopperProps={{
            style: {
              zIndex: 99999,
            },
          }}
          componentsProps={{
            tooltip: {
              sx: {
                color: "#fff",
                fontSize: "12px",
                padding: "5px",
              },
            },
          }}
        >
          <CloseIcon
            onClick={handleClose}
            sx={{
              padding: "2px",
              "&:hover": {
                cursor: "pointer",
                backgroundColor: "#e8e8e8",
                borderRadius: "35%",
              },
            }}
          />
        </Tooltip>
        {/* <CloseChatbotAlert
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
          // handleCloseChat={handleCloseChat}
          // handleMinimize={handleMinimize}
        /> */}
      </Box>
    </Box>
  );
};

export default HeaderTop;
