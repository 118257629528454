import {
  AddCircleOutlined,
  AddCircleOutlineOutlined,
  ArrowBackIosNew,
  History,
  Person,
  Refresh,
  Update,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  formatDate,
  getRelativeTimeDifference,
  isArrayWithValues,
  isObjWithValues,
} from "../../helper";
import { fetchAllSettings } from "../../redux/actions/settingActions";
import { Whatsapp } from "../../Svgs";

function SpreadsheetHistory() {
  const [history, setHistory] = useState([]);
  const navigate = useNavigate();
  const reduxHistory = useSelector(
    (state) => state.settings.spreadsheetHistory
  );
  const fetchingSyncHistory = useSelector(
    (state) => state.settings.fetchingAllSettings
  );
  const dispatch = useDispatch();
  useEffect(() => {
    // if (!Array.isArray(reduxHistory)) dispatch(getSpreadsheetHistory());
  }, []);
  useEffect(() => {
    if (isArrayWithValues(reduxHistory)) setHistory([...reduxHistory]);
  }, [reduxHistory]);
  return (
    <>
      <Button
        variant="text"
        startIcon={<ArrowBackIosNew />}
        onClick={() => navigate("/products")}
      >
        All Products
      </Button>
      <Card sx={{mt: 3}} >
        <CardContent>
          <Stack
            direction="row"
            alignItems={"center"}
            justifyContent="space-between"
          >
            <CardHeader title="Sync History" />
            <IconButton
              onClick={() => dispatch(fetchAllSettings())}
              disabled={fetchingSyncHistory}
            >
              <Refresh style={{ height: "28px", width: "28px" }} />
            </IconButton>
          </Stack>
          {history.map((obj) => {
            let timeObj = getRelativeTimeDifference(
              Number(obj.time * 1000),
              Number(new Date().getTime())
            );
            let { updated, added, exported, time } = obj;
            return (
              <Card>
                <CardContent>
                  <Stack direction="row" alignItems={"center"} width="100%">
                    <History style={{ height: "30px", width: "30px" }} />
                    <Stack flex={"100%"} ml={2}>
                      <Stack
                        direction="row"
                        alignItems={"center"}
                        justifyContent="space-between"
                        ml={2}
                      >
                        <Typography variant="h6">
                          {formatDate(
                            new Date(Number(obj.time) * 1000),
                            "dd:mm:yyyy hh:mm am/pm"
                          )}
                        </Typography>
                        {/* {isObjWithValues(timeObj) && (
                        <Typography variant="caption">
                          {timeObj.value} {timeObj.label}
                        </Typography>
                      )} */}
                      </Stack>
                      <Stack direction="row" spacing={3} mt={2}>
                        <Chip
                          size="small"
                          icon={
                            <AddCircleOutlineOutlined
                              style={{ color: "#fff" }}
                            />
                          }
                          label={`  Added: ${added || 0}`}
                          // color="success"
                          sx={{ backgroundColor: "#537a5a", color: "#fff" }}
                        />
                        <Chip
                          size="small"
                          icon={<Update />}
                          label={`Updated: ${updated || 0}`}
                          color="warning"
                          sx={{ backgroundColor: "#ad703e", color: "#fff" }}
                        />
                        <Chip
                          size="small"
                          icon={<Update />}
                          label={`Exported: ${exported}`}
                          color="info"
                          sx={{ backgroundColor: "#3f7f99", color: "#fff" }}
                        />
                      </Stack>

                      {/* <Typography
                      variant="body2"
                      dangerouslySetInnerHTML={{
                        __html: obj.message,
                      }}
                      sx={{ margin: 0, padding: 0 }}
                    ></Typography> */}
                    </Stack>
                  </Stack>
                </CardContent>
              </Card>
            );
          })}
        </CardContent>
      </Card>
    </>
  );
}

export default SpreadsheetHistory;
