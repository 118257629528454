import { IconButton } from "@mui/material";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import PropTypes from "prop-types";

const DeleteAndSwap = ({ setBotData, index }) => {
  const handleDelete = (index) => {
    setBotData((prevDropFields) => {
      const updatedFields = [...prevDropFields];
      updatedFields.splice(index, 1);
      return updatedFields;
    });
  };

  const handleSwap = (index) => {
    console.log("clicked", index);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flex-start",
        marginTop: "20px",
        marginLeft: "10px",
      }}
    >
      <IconButton
        sx={{
          zIndex: 200,
          marginBottom: "6px",
          height: "30px",
          width: "30px",
          backgroundColor: "white",
          color: "#008aff",
          "&:hover": { backgroundColor: "#008aff", color: "#fff" },
        }}
        onClick={() => handleSwap(index)}
      >
        <SwapVertIcon
          sx={{
            fontSize: "20px",
            borderRadius: "50%",
          }}
        />
      </IconButton>
      <IconButton
        sx={{
          marginBottom: "6px",
          height: "30px",
          width: "30px",
          backgroundColor: "white",
          color: "#E63946",
          "&:hover": { backgroundColor: "#E63946", color: "#fff" },
        }}
        onClick={() => handleDelete(index)}
      >
        <DeleteOutlineOutlinedIcon
          sx={{
            fontSize: "20px",
            borderRadius: "50%",
          }}
        />
      </IconButton>
    </div>
  );
};

DeleteAndSwap.propTypes = {
  index: PropTypes.number.isRequired,
  setBotData: PropTypes.func.isRequired,
};

export default DeleteAndSwap;
