import { ArrowForward, Close } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useTour } from "@reactour/tour";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  dialogStyle,
  getUserProduct,
  primaryLabel,
  primaryLabelLarge,
  secondaryLabel,
} from "../../helper";
import { Confetti } from "../../Svgs";

function GetStartedDialog({ onStartTour }) {
  const { setIsOpen } = useTour();
  const [open, setOpen] = React.useState(false);
  //   let [username, setUsername] =useState("")
  const theme = useTheme()
  const isIpad = useMediaQuery(theme.breakpoints.down("md"));
  let username = useSelector((state) => state.user.username);
  let tour_history = useSelector((state) => state.user?.tour_history);
  useEffect(() => {
    // setTimeout(() => {
    console.log("in");
    if (isIpad) return
    for (const product of getUserProduct() || []) {
      if (!tour_history?.[`${product}_tour`]) {
        setOpen(true);
        break;
        // return
      }
    }
    // }, 5000);
  }, [tour_history, username]);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{ ...dialogStyle }}
      maxWidth="sm"
      fullWidth
    >
      <DialogContent sx={{ textAlign: "center" }}>
        <Stack
          direction="row"
          alignItems={"center"}
          justifyContent="space-between"
        >
          <Stack direction="row" alignItems={"center"} spacing={2}>
            <Typography sx={{ ...primaryLabelLarge }}>Get started</Typography>
            <img
              src="/static/img/brands/jwero_icon.png"
              style={{ height: "20px", width: "20px" }}
            />
          </Stack>
          {false && <IconButton size="small" onClick={handleClose}>
            <Close />
          </IconButton>}
        </Stack>
        <Stack
          sx={{
            mt: 3,
            mb: 2,
          }}
          direction={"row"}
          alignItems="center"
          justifyContent={"center"}
          spacing={2}
        >
          <Typography
            sx={{
              ...secondaryLabel,
              color: "primary.main",
              fontSize: "15px",
            }}
          >
            Welcome {username}
          </Typography>
          <Confetti />
        </Stack>
        <Typography
          sx={{
            ...primaryLabel,
            fontSize: "22px",
          }}
        >
          Ready to get started
        </Typography>
        <Typography
          sx={{
            ...secondaryLabel,
            margin: "auto",
            mt: 3,
            maxWidth: 400,
            textAlign: "center",
          }}
        >
          Take a tour to get comfortable with your dashboard and see how can you
          use Jwero for you business
        </Typography>
        <Box sx={{ mt: 5 }}>
          <Button
            variant="contained"
            size="large"
            endIcon={<ArrowForward />}
            onClick={() => {
              handleClose();
              setIsOpen(true);
            }}
            sx={{ borderRadius: "18px" }}
          >
            Start Tour
          </Button>
        </Box>
        <Box sx={{ mt: 2 }}>
          <Button>Never show again</Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default GetStartedDialog;
