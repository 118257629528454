import { isArrayWithValues, isObjWithValues, validateNumber } from "../helper";

export const getTotalMetalGross = (product) => {
  if (!isObjWithValues(product)) return 0;
  let { meta_data } = product;
  if (!isArrayWithValues(meta_data)) return 0;
  // let obj = { gold_gross: 0, silver_gross: 0, platinium_gross: 0 };
  const metaKeys = ["gold_gross", "silver_gross", "platinium_gross"];
  let total = 0;
  meta_data?.forEach((i) => {
    if (metaKeys.includes(i.key)) {
      total += validateNumber(i.value);
    }
  });
  return total;
};
export const getTotalMetalNet = (product) => {
  if (!isObjWithValues(product)) return 0;
  let { meta_data } = product;
  if (!isArrayWithValues(meta_data)) return 0;
  // let obj = { gold_net: 0, silver_net: 0, platinium_net: 0 };
  const metaKeys = ["gold_net", "silver_net", "platinium_net"];
  let total = 0;
  meta_data?.forEach((i) => {
    if (metaKeys.includes(i.key)) {
      total += validateNumber(i.value);
    }
  });
  return total;
};
export const getTotalDiamondWeight = (product) => {
  if (!isObjWithValues(product)) return 0;
  let { meta_data } = product;
  if (!isArrayWithValues(meta_data)) return 0;
  let diamond = meta_data.find((i) => i.key === "diamond");
  if (!diamond) return 0;
  diamond = diamond.value;
  if (!diamond || !isObjWithValues(diamond)) return 0;

  let total = 0;
  Object.values(diamond).forEach((i) => {
    total += validateNumber(i.diamond_weight);
  });
  return total;
};
export const getTotalDiamondPieces = (product) => {
  if (!isObjWithValues(product)) return 0;
  let { meta_data } = product;
  if (!isArrayWithValues(meta_data)) return 0;
  let diamond = meta_data.find((i) => i.key === "diamond");
  if (!diamond) return 0;
  diamond = diamond.value;
  if (!diamond || !isObjWithValues(diamond)) return 0;

  let total = 0;
  Object.values(diamond)?.forEach((i) => {
    total += validateNumber(i.diamond_pieces);
  });
  return total;
};
export const getTotalGemstoneWeight = (product) => {
  if (!isObjWithValues(product)) return 0;
  let { meta_data } = product;
  if (!isArrayWithValues(meta_data)) return 0;
  let colorstone = meta_data.find((i) => i.key === "colorstone_details");
  if (!colorstone) return 0;
  colorstone = colorstone.value;
  if (!colorstone || !isObjWithValues(colorstone)) return 0;

  let total = 0;
  Object.values(colorstone).forEach((i) => {
    total += validateNumber(i.colorstone_weight);
  });
  return total;
};
export const getTotalGemstonePieces = (product) => {
  if (!isObjWithValues(product)) return 0;
  let { meta_data } = product;
  if (!isArrayWithValues(meta_data)) return 0;
  let colorstone = meta_data.find((i) => i.key === "colorstone_details");
  if (!colorstone) return 0;
  colorstone = colorstone.value;
  if (!colorstone || !isObjWithValues(colorstone)) return 0;

  let total = 0;
  Object.values(colorstone).forEach((i) => {
    total += validateNumber(i.colorstone_pieces);
  });
  return total;
};
