export const changeIconStyles = {
  popoverContainer: {
    width: "400px",
    height: "300px",
    padding: "0px 15px 15px 20px",
    fontFamily: "Inter",
  },
  title: {
    fontFamily: "Inter",
    marginTop: "15px",
    marginBottom: "15px",
    fontSize: "16px",
    fontWeight: 600,
  },
  iconsContainter: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  iconBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "10px",
    marginBottom: "10px",
    height: "75px",
    width: "75px",
    borderRadius: "50%",
    backgroundColor: "#dcdcdc",
    marginRight: "10px",
  },
};
