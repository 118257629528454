import React from "react";
import ReactApexChart from "react-apexcharts";

function RedialBarMeter({ series = [] }) {
  return (
    <ReactApexChart
      options={options}
      series={series}
      type="radialBar"
      width={"100px"}
    />
  );
}

export default RedialBarMeter;

const options = {
  chart: {
    type: "radialBar",
    // offsetY: -20,
    sparkline: {
      enabled: true,
    },
  },
  colors: ["#012687"],
  plotOptions: {
    radialBar: {
      startAngle: -90,
      endAngle: 90,
      track: {
        background: "#e7e7e7",
        strokeWidth: "80%",
        margin: 5, // margin is in pixels
        dropShadow: {
          enabled: true,
          top: 2,
          left: 0,
          color: "#999",
          opacity: 1,
          blur: 2,
        },
      },
      dataLabels: {
        name: {
          show: false,
        },
        value: {
          offsetY: -2,
          fontSize: "12px",
        },
      },
    },
  },
  grid: {
    padding: {
      top: -8.5,
    },
  },
  // fill: {
  //   type: "gradient",
  //   gradient: {
  //     shade: "light",
  //     shadeIntensity: 0.4,
  //     inverseColors: false,
  //     opacityFrom: 1,
  //     opacityTo: 1,
  //     stops: [0, 50, 53, 91],
  //     gradientToColors: ["#012687"],
  //   },
  // },
  stroke: {
    lineCap: "round",
  },
  // labels: ["Average Results"],
};
