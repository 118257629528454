import { ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";
import { campaignChannels } from "../SideBar";
import { useReactFlow } from "reactflow";
import { getRandomId, getRandomString, validateNumber } from "../../../helper";

export const ChannelNames = ({
  anchorEl,
  open,
  action,
  handleClose,
  parent,
  xPos,
  id,
}) => {
  // const { setNodes } = useStoreActions(actions => actions);
  const { setNodes, setEdges } = useReactFlow();

  return (
    <Menu
      id="basic-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      MenuListProps={{
        "aria-labelledby": "basic-button",
      }}
    >
      {campaignChannels?.map((channel) => {
        return (
          <MenuItem
            key={channel?.type}
            onClick={() => {
              handleClose && handleClose();

              setNodes &&
                setNodes((pre) => {
                  let preLen = getRandomString(4);
                  // validateNumber(
                  //     pre?.filter((n) => n.id?.includes(channel.value))?.length
                  // ) + 1;

                  setEdges((pre) => {
                    return [
                      ...pre,
                      {
                        id: `reactflow__edge-${parent?.id || ""}${id || ""}${
                          channel.label
                        }_${preLen}`,
                        source: parent?.id,
                        sourceHandle: id,
                        target: `${channel?.type}_${preLen}`,
                        channel: channel?.type,
                        action: action,
                        type: "buttonedge",
                        // `${channel.value}_${preLen}`
                      },
                    ];
                  });

                  pre = [
                    ...pre,
                    {
                      id: `${channel?.type}_${preLen}`,
                      type: channel.value,
                      icon: channel?.icon,
                      heading: channel?.label,
                      channel: channel?.type,
                      position: {
                        x: validateNumber(xPos) + validateNumber(parent?.xPos),
                        y: validateNumber(parent?.yPos) + 300,
                      },
                    },
                  ];
                  // pre.concat([])

                  return pre;
                });
            }}
          >
            <ListItemIcon>
              {channel?.icon}
              {/* <WhatsappSvg fontSize="small" /> */}
            </ListItemIcon>
            <ListItemText>{channel?.label}</ListItemText>
          </MenuItem>
        );
      })}

      {/* <MenuItem onClick={handleClose}>
            <ListItemIcon>
                <SmsOutlined fontSize="small" />
            </ListItemIcon>
            <ListItemText>SMS</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleClose}>
            <ListItemIcon>
                <EmailOutlined fontSize="small" />
            </ListItemIcon>
            <ListItemText>Email</ListItemText>
        </MenuItem> */}
    </Menu>
  );
};
