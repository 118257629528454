export const removeEdgeAndReturnSource = ({edges, id}) => {
    let sourceId = null;
  
    const updatedEdges = edges.filter((edge) => {
      if (edge.target === id) {
        sourceId = edge.source;
        return false;
      }
      return true;
    });
  
    return { sourceId, updatedEdges };
  }