import React, { useEffect } from "react";
import styled from "styled-components/macro";
import { CircularProgress } from "@mui/material";

const Root = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  min-height: 100%;
`;

function Loader(props) {
  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);
  return (
    <Root {...props}>
      <CircularProgress color="primary" disableShrink />
    </Root>
  );
}

export default Loader;
