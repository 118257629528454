import { Done, DoneAllOutlined, ErrorOutline } from '@mui/icons-material'
import { Box, IconButton, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux'
import { EmptyState, StackRow, formatDate, formatMessageTime, formatServerValue, isArrayWithValues, isObjWithValues, validateNumber } from '../../../helper'

import { getCategoryNameColor } from './CampaignHistory'
import { EmailEmptyState, OpenMailIcon, ReopenMailIcon } from '../../../Svgs'

const Emails = ({ customerInfo }) => {
    const crmEmailHistory = useSelector(state => state.chats.crmEmailHistory)
    let historyArray = crmEmailHistory?.[customerInfo?.id] || []

    return (

        <Box>
            {!isArrayWithValues(historyArray) && <EmptyState text={"No Email History"} icon={<EmailEmptyState height={"200px"} />} />}
            {isArrayWithValues(historyArray) &&
                <TableContainer >
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Template Name</TableCell>
                                <TableCell>Campaign Name</TableCell>
                                <TableCell >Category</TableCell>
                                <TableCell >Sent on</TableCell>
                                <TableCell >Status</TableCell>
                                {/* <TableCell >Protein&nbsp;(g)</TableCell> */}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {historyArray.sort((a, b) => validateNumber(b?.time) - validateNumber(a?.time)).map((row) => (
                                <TableRow
                                    key={row?.campaign_info?.history_obj_id}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        <Typography variant='subtitle2'>

                                            {row?.campaign_info?.template_name || "N/A"}
                                        </Typography>
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        <Typography variant='subtitle2'>

                                            {row?.campaign_info?.name || "N/A"}
                                        </Typography>

                                    </TableCell>
                                    <TableCell >
                                        <Typography
                                            variant="h6"
                                            sx={{
                                                fontSize: "13px",
                                                whiteSpace: "nowrap",
                                                color: getCategoryNameColor(row?.campaign_info?.category || ""),
                                            }}
                                        >
                                            {formatServerValue(row?.campaign_info?.category || "N/A")}
                                            {/* {row.campaign_info?.template_name} */}
                                        </Typography>
                                    </TableCell>
                                    <TableCell >
                                        <Typography variant='subtitle2'>

                                            {formatDate(new Date(row?.time), "relative_date")}
                                        </Typography>
                                        <Typography>
                                            {formatDate(new Date(row?.time), "hh:mm am/pm")}
                                        </Typography>

                                    </TableCell>
                                    <TableCell >

                                        {getEmailStatus(row)}
                                        {/* <CustomChip type={row?.sent === "email_not_found" ? "failed" : row?.sent} status={formatServerValue(row?.sent)} /> */}

                                        {/* {formatServerValue(row?.sent)} */}
                                    </TableCell>
                                    {/* <TableCell >{row.protein}</TableCell> */}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>


            }
        </Box>
    )
}

export default Emails


const getEmailStatus = (obj) => {
    console.log(obj?.open, obj?.reopened, obj);
    if (isObjWithValues(obj?.opened) && isObjWithValues(obj?.reopened)) {
        return <StackRow>
            {/* <Tooltip title={formatMessageTime(new Date(obj?.opened?.time * 1000))}>
                <IconButton>
                    <OpenMailIcon />
                </IconButton>
            </Tooltip> */}
            <Tooltip title={`Re-Opend on ${formatMessageTime(new Date(obj?.reopened?.time * 1000))}`}>
                <IconButton>
                    <ReopenMailIcon />
                </IconButton>
            </Tooltip>

        </StackRow>

    }
    else if (isObjWithValues(obj?.opened)) {
        return <Tooltip title={`Re-Opend on ${formatMessageTime(new Date(obj?.opened?.time * 1000))}`}>
            <IconButton>
                <OpenMailIcon />
            </IconButton>
        </Tooltip>
    } else if (obj?.sent === "success") {
        return <Tooltip title={"Delivered"}>
            <IconButton>
                <Done />
            </IconButton>
        </Tooltip>

    } else if (obj?.sent === "email_not_found" || obj?.sent === "failed") {
        return <Tooltip title={formatServerValue(obj?.sent)}>
            <IconButton>
                <ErrorOutline color="error" />
            </IconButton>
        </Tooltip>
    }


}