import React from "react";
import { EmailCampaignList } from "../../customers/components/CampaignHistory";
import { Card, Paper, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { isObjWithValues } from "../../../helper";
import { sectionLabel } from "../../chat/ChatHelper";

const RecentCampaignList = () => {
    const dashboardDetails = useSelector(
        (state) => state.settings.dashboardDetails
    );
    const fetchingDashboardDetails = useSelector(
        (state) => state.settings.fetchingDashboardDetails
    );

    return (
        <Card
            mb={6}
            sx={{ padding: "0 15px", borderRadius: "15px", py: 3, height: "100%" }}
        >
            <Paper sx={{ height: "100%" }}>
                <Typography sx={{ ...sectionLabel }}>Recent Campaigns</Typography>
                <EmailCampaignList
                    fetched={isObjWithValues(dashboardDetails)}
                    fetching={fetchingDashboardDetails}
                    rows={Object.values(dashboardDetails?.campaign || {})}
                    hideAction={true}
                />
            </Paper>
        </Card>
    );
};

export default RecentCampaignList;
