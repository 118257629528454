import { Box, MenuItem, Stack, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Label } from "../../../customers/components/Info";
import { LoadingButton } from "@mui/lab";
import { isObjWithValues, settingsEndpoint } from "../../../../helper";
import { useDispatch, useSelector } from "react-redux";
import { EDIT_SETTINGS } from "../../../../redux/actions/settingActions";
import { setGlobalToast } from "../../../../redux/actions/helperActions";
let dropDownOptions = [
    { label: "DigitalGold unique id", value: "digigold_uid" },
    { label: "Customer Number", value: "phone" },
    { label: "Customer email", value: "email" },
];
const Settings = () => {
    const dispatch = useDispatch();
    const [saving, setSaving] = useState(false);
    const [formState, setFormState] = useState({});
    const paysharpIntegration = useSelector(
        (state) => state.settings.paysharpIntegration
    );

    useEffect(() => {
        console.log(paysharpIntegration);
        if (isObjWithValues(paysharpIntegration?.settings))
            setFormState(paysharpIntegration?.settings || {});
    }, [paysharpIntegration]);

    const onSave = async () => {
        setSaving(true);
        let res = await settingsEndpoint({
            endpoint: "paysharp_integration",
            method: "PUT",
            data: {
                settings: formState,
            },
        });
        if (res) {
            dispatch({
                type: EDIT_SETTINGS,
                payload: {
                    paysharpIntegration: res,
                },
            });
            dispatch(
                setGlobalToast({
                    show: true,
                    message: "Settings updated successfully",
                    severity: "success",
                })
            );
        }
        setSaving(false);
    };
    return (
        <Box p={"12px 26px"}>
            <Stack sx={{ width: "55%" }} gap={5}>
                <Stack direction={"row"} justifyContent={"space-between"}>
                    <Typography variant="h6">Settings</Typography>
                    <LoadingButton
                        onClick={onSave}
                        loading={saving}
                        variant="contained"
                        color="primary"
                    >
                        Save
                    </LoadingButton>
                </Stack>
                <Stack>
                    <Label>Customer Id</Label>
                    <TextField
                        select
                        size="small"
                        value={formState?.unique_customer_key || ""}
                        onChange={(e) => {
                            setFormState({
                                ...formState,
                                unique_customer_key: e.target.value,
                            });
                        }}
                    >
                        {dropDownOptions?.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option?.label}
                            </MenuItem>
                        ))}
                    </TextField>
                </Stack>
            </Stack>
            <Stack>
                <Typography sx={{ mt: 4 }}>
                    Before proceeding, make sure you have configured the webhook link and
                    API key. If you haven't done so, please refer to the instructions
                    provided in the Paysharp Integrations Tab for guidance on how to
                    configure them in Jwero.
                </Typography>
                <Typography sx={{ mt: 4, fontStyle: "italic" }} variant="h6">
                    What's Customer ID ?
                </Typography>

                <Typography sx={{ mt: 4 }}>
                    The Customer ID is a unique identifier assigned to each customer for
                    every plan they enroll in. The jeweler has the freedom to choose the
                    type of customer ID they want their customers to use when signing up
                    for their brand in the BBPS App for gold scheme payments. Each
                    customer will have a distinct ID, ensuring uniqueness across plans.
                </Typography>

                <Typography sx={{ mt: 4, fontStyle: "italic" }} variant="h6">
                    Selection Process:
                </Typography>

                <Typography sx={{ mt: 4 }}>
                    The Selected option from the dropdown will be visible in the BBPS at
                    the time of registration for the customer.
                </Typography>

                <ul style={{ marginLeft: "24px", marginTop: "16px" }}>
                    {[
                        "Digital Unique ID",
                        "Customer Contact Number",
                        "Customer Email",
                    ]?.map((str) => (
                        <li key={str}>
                            <Typography variant="subtitle2">{str}</Typography>
                        </li>
                    ))}
                </ul>
                <Typography sx={{ mt: 4, fontStyle: "italic" }}>
                    Example: If a Jeweller has set the Customer Number as Customer ID for Paysharp
                    then End customers will have to link their account using the customer number shared to the jeweller.
                </Typography>
                <img alt="info" src="/static/img/content/paysharpSetting.jpg" style={{ marginTop: 20, width: "50%" }} />

                <Typography variant="h6" sx={{ mt: 4, textDecoration: "underline" }}> DIGITAL UNIQUE ID :</Typography>
                <Typography variant="subtitle2" sx={{ mt: 4 }}>
                    If jewellers offer multiple plans and wish to enable customers to make payments for multiple schemes concurrently, they should choose the Digital Unique ID option. This means that each customer will have a customer ID, but if the same customer enrols in multiple gold saving scheme plans, the jeweller will assign a distinct unique ID for each plan. This ensures that the customer can be differentiated based on the specific plan they are enrolled in.

                </Typography>
                <Typography variant="h6" sx={{ mt: 4, textDecoration: "underline" }}>CUSTOMER CONTACT NUMBER/EMAIL :</Typography>
                <Typography variant="subtitle2" sx={{ mt: 4 }}>
                    If Jewellers wants the customers to register/Sign in using Mobile or Email, then based on this customer's contact number/email (one of the options) must be selected in the dropdown.
                </Typography>
                <Typography variant="subtitle2" sx={{ mt: 4 }}>
                    Once the jeweller confirms the selected ID type with Paysharp, customers will be able to register in the BBPS app using the specified ID type chosen by the jeweller. The chosen ID type will also be visible in the BBPS app.
                </Typography>
            </Stack>
        </Box>
    );
};

export default Settings;
