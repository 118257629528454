import { Download, Search, Sync } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  LinearProgress,
  linearProgressClasses,
  Paper,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import axios from "axios";
import { visuallyHidden } from "@mui/utils";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getToken,
  getWebsite,
  apiCallFromSocket,
  getFullName,
  createObjFromMapping,
  getShopifyData,
  batchCreateCustomer,
  getCustomerWidthFilter,
  isArrayWithValues,
  formatDate,
  validateNumber,
  sleep,
  getRandomString,
  fetchIPInfo,
  isObjWithValues,
  settingsEndpoint,
  secondaryLabel,
  getCRMList,
  defaultShopifyCustomerMapping,
  websocketApiCall,
} from "../../../../../helper";
import { SET_CRM_DATA } from "../../../../../redux/actions/crmActions";
import { filterSubmitHandler } from "../../../../customers/components/EmailTemplate";
import {
  editSettings,
  EDIT_SETTINGS,
} from "../../../../../redux/actions/settingActions";
import { setGlobalToast } from "../../../../../redux/actions/helperActions";
import { getIp } from "../../../../../App";
import { UAParser } from "ua-parser-js";
import { ShopifyImport } from "../../../../../Svgs";
const headCells = [
  {
    id: "sr",
    numeric: false,
    // disablePadding: true,
    label: "Sr.",
  },
  {
    id: "label",
    numeric: false,
    // disablePadding: true,
    label: "Name",
  },
  {
    id: "product",
    numeric: false,
    // disablePadding: true,
    label: "Email",
  },
  {
    id: "stock",
    numeric: true,
    // disablePadding: false,
    label: "City",
  },
  // {
  //     id: "amount",
  //     numeric: true,
  //     disablePadding: false,
  //     label: "Amount",
  // },
  {
    id: "last_sync",
    numeric: true,
    // disablePadding: false,
    label: "Imported",
  },
  //   {
  //     id: "carbs",
  //     numeric: true,
  //     disablePadding: false,
  //     label: "Carbs (g)",
  //   },
  //   {
  //     id: "protein",
  //     numeric: true,
  //     disablePadding: false,
  //     label: "Protein (g)",
  //   },
];
const AllCustomer = () => {
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [selected, setSelected] = useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [previousImported, setPreviousImported] = useState([]);
  const [gettingCustomer, setGettigCustomer] = useState(false);
  const [importing, setImporting] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [allCustomer, setAllCustomer] = useState([]);
  const [nextLink, setNextLink] = useState({});
  // const [loading, setLoading] = useState(false);
  const [importingState, setImportingState] = useState({});
  const [fetchingCustomers, setFetchingCustomers] = useState(false);
  const reduxShopifyIntegration =
    useSelector((state) => state.settings.shopifyIntegration) || {};
  const shopifyCustomers = useSelector(
    (state) => state.customers?.shopifyCustomers
  );
  const shopifyMapping = useSelector((state) => state.settings?.shopifyMapping);
  const previousImportedShopify = useSelector(
    (state) => state.settings.previousImportedShopify
  );

  // useEffect(() => {
  //   console.log(searchText, "<<<<<<");
  // }, [searchText]);

  const addHistory = async ({ selected, resArray, shop, type }) => {
    let historyObj = {
      id: getRandomString(8),
      start: Math.floor(Date.now() / 1000),
      ip: await getIp(),
      ua: new UAParser().getUA(),
      import_info: {},
      // ip:
      // imported: 0,
      // products: [],
    };
    let location = await fetchIPInfo(historyObj.ip);
    if (isObjWithValues(location)) {
      let { city, region, postal, country } = location;
      historyObj.location = {
        city,
        state: region,
        postcode: postal,
        country,
      };
    }
    let import_info = {};

    for (let index = 0; index < selected?.length; index++) {
      const shopifyCustomer = selected?.[index];

      let jeroCustomer = resArray?.find(
        (obj) => obj?.shopify_customer?.shopify_id == shopifyCustomer?.id
      );

      if (jeroCustomer) {
        import_info.success = [
          ...(import_info.success || []),
          { shopify_id: shopifyCustomer?.id },
        ];
      }
      try {
        if (isObjWithValues(resArray?.[index]?.error)) {
          import_info[resArray?.[index]?.error?.code?.replaceAll("-", "_")] = [
            ...(import_info[
              resArray?.[index]?.error?.code?.replaceAll("-", "_")
            ] || []),
            { shopify_id: shopifyCustomer?.id },
          ];
        }
      } catch (error) {
        console.log(error);
      }
    }

    historyObj.import_info = import_info;

    let res = await settingsEndpoint({
      endpoint: `shopify_sync_history`,
      method: "PUT",

      data: {
        [historyObj?.id]: {
          ...historyObj,
          type,
        },
      },
    });
    dispatch(
      editSettings({
        payload: {
          shopifyHistory: res,
        },
      })
    );
    console.log(res);
  };

  const theme = useTheme();
  const dispatch = useDispatch();
  const isDarkTheme = theme.palette && theme.palette.mode === "dark";

  const getShopifyCustomers = async ({
    shop,
    access_token,
    nextLink = {},
    clearCustomers = false,
  }) => {
    let reqOptions = { shop, access_token };
    setFetchingCustomers(true);
    if (nextLink) {
      reqOptions.link = nextLink?.link
        ?.replaceAll("<", "")
        ?.replaceAll(">", "");
    }
    let customers = [];
    let customerRes = await getShopifyData(reqOptions);
    if (customerRes) {
      if (!clearCustomers)
        customers = [
          ...allCustomer,
          ...(customerRes?.data?.data?.customers || []),
        ].filter((v, i, a) => a.findIndex((v2) => v2.id === v.id) === i);
      else
        customers = [...(customerRes?.data?.data?.customers || [])].filter(
          (v, i, a) => a.findIndex((v2) => v2.id === v.id) === i
        );
      setNextLink({
        link: customerRes?.data?.headers?.link,
      });
      return customers;
      // dispatch({
      //   type: SET_CRM_DATA,
      //   payload: {
      //     shopifyCustomers: customers,
      //   },
      // });
    }
    setFetchingCustomers(false);
  };

  const getShopifyCustomerCount = async ({ shop, access_token }) => {
    let website = getWebsite();
    let token = getToken();
    if (website && token) {
      try {
        let countRes = await apiCallFromSocket({
          url: `https://${shop}/admin/api/2023-01/customers/count.json`,
          method: `GET`,
          headers: {
            "X-Shopify-Access-Token": access_token,
          },
        });
        if (countRes?.data?.success) {
          console.log(countRes?.data?.data?.count);

          let count = Math.ceil(validateNumber(500) / 250);
          // let count = Math.ceil(
          //   validateNumber(countRes?.data?.data?.count) / 250
          // );
          let arrayLenght = new Array(count)
            ?.fill("")
            ?.map((s, index) => ({ index: index + 1 }));
          let customerArr = [];
          for await (const index of arrayLenght) {
            let reqOptions = { shop, access_token };
            if (index?.link) {
              reqOptions.link = index?.link
                ?.replaceAll("<", "")
                ?.replaceAll(">", "");
            }
            let customerRes = await getShopifyData(reqOptions);
            if (index.index < count)
              arrayLenght[index.index] = {
                index: index.index + 1,
                link: customerRes?.data?.headers?.link,
              };
            if (customerRes?.data?.success) {
              customerArr = [
                ...customerArr,
                ...(customerRes?.data?.data?.customers || []),
              ];
            }
          }
          console.log(customerArr?.length);
          return customerArr;

          dispatch({
            type: SET_CRM_DATA,
            payload: {
              shopifyCustomers: customerArr,
            },
          });
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (Array.isArray(allCustomer) && allCustomer.length > 0) {
      let currentPage = newPage + 1;
      if (allCustomer.length / rowsPerPage === currentPage) {
        getShopifyCustomers({ nextLink, ...reduxShopifyIntegration });
      }
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const startImport = async () => {
    setImporting(true);
    let arr = [];

    for (const customerObj of selected || []) {
      let jweroCustomer = createObjFromMapping({
        mapping: shopifyMapping?.customers?.mapping,
        payload: customerObj,
      });

      jweroCustomer = {
        ...jweroCustomer,
        password: "password",
        meta_data: [
          ...(jweroCustomer?.meta_data || []),
          {
            key: "shopify_customer",
            value: {
              shopify_id: customerObj?.id,
            },
          },
        ],
      };
      arr.push(jweroCustomer);
    }
    console.log(arr);
    let createRes = await batchCreateCustomer(arr);
    // if (setPreviousImported)
    console.log(createRes);

    let customerArr = createRes?.data?.create?.map((obj) => {
      for (const metaObj of obj?.meta_data || []) {
        obj[metaObj?.key] = metaObj?.value;
      }
      return obj;
    });
    // console.log(arr);
    dispatch(
      setGlobalToast({
        show: true,
        severity: "success",
        message: "Imported successfully!",
      })
    );
    dispatch({
      type: EDIT_SETTINGS,
      payload: {
        previousImportedShopify: [
          ...(previousImportedShopify || []),
          ...customerArr,
        ],
      },
    });
    addHistory({
      selected,
      resArray: customerArr,
      ...(reduxShopifyIntegration || {}),
      type: "customer",
    });
    setSelected([]);
    setImporting(false);
  };
  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0
      ? Math.max(0, (1 + page) * rowsPerPage - shopifyCustomers?.length)
      : 0;
  const handleClick = (event, obj) => {
    // if (importedSku.includes(obj.label)) return;
    const selectedIndex = selected.findIndex((i) => i.id == obj.id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, obj);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };
  // useEffect(() => {
  //   setPreviousImported(previousImportedShopify || []);
  // }, [previousImportedShopify]);

  // useEffect(async () => {
  //   if (!isArrayWithValues(shopifyCustomers)) {
  //     setGettigCustomer(true);
  //     await getShopifyCustomers(reduxShopifyIntegration);
  //     // await getShopifyCsutomerCount(reduxShopifyIntegration);
  //     setGettigCustomer(false);
  //   }
  // }, []);

  const onRefresh = async () => {
    setGettigCustomer(true);
    await getShopifyCustomers({
      ...reduxShopifyIntegration,
      clearCustomers: true,
    });
    // await getShopifyCsutomerCount(reduxShopifyIntegration);
    setGettigCustomer(false);
  };
  // useEffect(async () => {
  //   if (!isArrayWithValues(previousImportedShopify)) {
  //     let shopifyCustomer = await getCustomerWidthFilter({
  //       params: {
  //         custom_filter: [
  //           {
  //             key: `shopify_customer`,
  //             value: "id",
  //             compare: "LIKE",
  //           },
  //         ],
  //         _feilds: "id,meta_data,date_created",
  //       },
  //     });
  //     let arr = shopifyCustomer?.data?.map((obj) => {
  //       for (const metaObj of obj?.meta_data || []) {
  //         obj[metaObj?.key] = metaObj?.value;
  //       }
  //       return obj;
  //     });
  //     console.log(arr, shopifyCustomer);
  //     dispatch({
  //       type: EDIT_SETTINGS,
  //       payload: {
  //         previousImportedShopify: arr,
  //       },
  //     });
  //   }
  // }, []);

  const isSelected = (obj) => selected.findIndex((i) => i.id == obj.id) !== -1;
  useEffect(() => {
    setAllCustomer(shopifyCustomers || []);
  }, [shopifyCustomers]);

  useMemo(async () => {
    await sleep(300);

    if (searchText) {
      setPage(0);
      setAllCustomer(
        shopifyCustomers?.filter((obj) =>
          Object.values(obj || {})
            ?.join("")
            ?.toLocaleLowerCase()
            ?.includes(searchText?.toLocaleLowerCase())
        )
      );
    } else {
      setAllCustomer(shopifyCustomers || []);
    }
  }, [searchText]);

  const onImporting = async () => {
    try {
      let token = getToken();
      let website = getWebsite();
      let historyObj = {
        id: getRandomString(8),
        start: Math.floor(Date.now() / 1000),
        ip: await getIp(),
        ua: new UAParser().getUA(),
        import_info: {},
        // ip:
        // imported: 0,
        // products: [],
      };
      let location = await fetchIPInfo(historyObj.ip);
      if (isObjWithValues(location)) {
        let { city, region, postal, country } = location;
        historyObj.location = {
          city,
          state: region,
          postcode: postal,
          country,
        };
      }

      setImportingState((pre) => ({ ...pre, loading: true }));
      const res = await websocketApiCall({
        method: "POST",
        endpoint: "shopify/sync/customers",
        headers: {
          token: token,
        },
        data: {
          website: website,
          history: {
            ...historyObj,
            type: "customer",
          },
        },
      });

      if (res?.success) {
        dispatch(
          setGlobalToast({
            show: true,
            message: res?.data?.message,
            severity: "success",
          })
        );
      }

      console.log(res);
    } catch (error) {
      dispatch(
        setGlobalToast({
          show: true,
          message: "Request failed, please try again",
          severity: "success",
        })
      );
    } finally {
      setImportingState((pre) => ({ ...pre, loading: false }));
    }
  };

  // const onImporting = async () => {
  //   setImportingState((pre) => ({ ...pre, loading: true }));
  //   //geting all Shopify customer
  //   let allShopifyCustomer = await getShopifyCustomerCount(
  //     reduxShopifyIntegration
  //   );

  //   //get customer witch already impotred in jewriCrm
  //   let crmCustomer = await getCRMList({
  //     fields: "shopify_customer",
  //   });
  //   // making array of ids with in crm
  //   let previousImportedIds = Object.values(crmCustomer || {})?.map(
  //     (obj) => obj?.shopify_customer?.shopify_id
  //   );

  //   //filtering customer which have to create
  //   let haveToImport = allShopifyCustomer?.filter(
  //     (obj) => !previousImportedIds?.includes(obj?.id)
  //   );

  //   // batch creating
  //   let countArray = new Array(Math.ceil(haveToImport?.length / 100))
  //     ?.fill("")
  //     ?.map((str, index) => index);
  //   let arr = [];
  //   for await (const index of countArray) {
  //     let batchArray = haveToImport
  //       ?.slice(index * 100, index * 100 + 100)
  //       ?.map((obj) => {
  //         let jweroCustomer = createObjFromMapping({
  //           mapping: [
  //             ...(shopifyMapping?.customers?.mapping || []),
  //             ...defaultShopifyCustomerMapping,
  //           ],
  //           payload: obj,
  //         });
  //         jweroCustomer.password = "password";
  //         jweroCustomer.meta_data = [
  //           ...(obj.meta_data || []),
  //           {
  //             key: "customer_channel",
  //             value: {
  //               id: obj?.id,
  //               name: "shopify",
  //             },
  //           },
  //         ];

  //         return jweroCustomer;
  //       });
  //     let createRes = await batchCreateCustomer(batchArray);
  //     let customerArr =
  //       createRes?.data?.create?.map((obj) => {
  //         for (const metaObj of obj?.meta_data || []) {
  //           obj[metaObj?.key] = metaObj?.value;
  //         }
  //         return obj;
  //       }) || [];
  //     arr = [...arr, customerArr || []];

  //     console.log(batchArray);
  //   }
  //   addHistory({
  //     selected: haveToImport,
  //     resArray: arr,
  //     ...(reduxShopifyIntegration || {}),
  //     type: "customer",
  //   });
  //   setImportingState((pre) => ({ ...pre, loading: false }));
  //   dispatch(
  //     setGlobalToast({
  //       show: true,
  //       message: "Customers synced from Shopify Successfully",
  //       severity: "success",
  //     })
  //   );
  //   // let createRes = await batchCreateCustomer(arr);
  //   // if (setPreviousImported)
  // };

  return (
    <Box sx={{ px: 2, mt: 3 }}>
      <Stack alignItems={"center"} justifyContent={"center"} rowGap={3} gap={3}>
        <ShopifyImport height={300} width={300} />
        <Typography
          variant="h6"
          color={"text.secondary"}
          sx={{ width: "40%", textAlign: "center", lineHeight: 1.6 }}
        >
          Import your customer from your Shopify store to Jwero CRM
        </Typography>
        <LoadingButton
          loading={importingState?.loading}
          size="large"
          sx={{ mt: 3 }}
          variant="contained"
          onClick={() => {
            onImporting();
          }}
        >
          Sync Customer
        </LoadingButton>
      </Stack>
      {/* <ImportingCustomer loading={importingState?.loading} />
      <Stack direction="row" spacing={3} my={3} justifyContent="flex-end">
        <Button
          variant="outlined"
          onClick={() => {
            onImporting();
          }}
        >
          Import all customers
        </Button>
      </Stack>
      <Stack direction="row" spacing={3}>
        <AnalyticsCard title="Customers In Shopify" value="344" />
        <AnalyticsCard title="Customers Imported" value="4" />
        <AnalyticsCard title="Customers In Jwero" value="532" />
      </Stack> */}
    </Box>
  );

  return (
    <Box>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          selected={selected}
          fetchingWooCommerceCustomer={gettingCustomer}
          startImport={startImport}
          importing={importing}
          onRefresh={onRefresh}
          searchText={searchText}
          setSearchText={setSearchText}
        />
        <TableContainer>
          <Table aria-labelledby="tableTitle" size={dense ? "small" : "medium"}>
            <EnhancedTableHead
              numSelected={selected.length}

              // order={order}
              // orderBy={orderBy}
              // onSelectAllClick={handleSelectAllClick}
              // onRequestSort={handleRequestSort}
              // rowCount={
              //     rows.filter((i) => !importedSku.includes(i.label)).length
              // }
            />
            <TableBody>
              {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                   rows.slice().sort(getComparator(order, orderBy)) */}
              {
                // stableSort(rows, getComparator(order, orderBy))
                //

                allCustomer
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  ?.map((row, index) => {
                    const isItemSelected = isSelected(row);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    let crmCustomer = previousImported?.find(
                      (o) => o.shopify_customer?.shopify_id == row.id
                    );
                    return (
                      <TableRow
                        hover
                        onClick={(event) =>
                          !crmCustomer && handleClick(event, row)
                        }
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.id}
                        selected={isItemSelected}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            checked={isItemSelected}
                            inputProps={{
                              "aria-labelledby": labelId,
                            }}
                            size="small"
                            disabled={crmCustomer}
                          />
                        </TableCell>
                        <TableCell
                          padding="checkbox"
                          align="left"
                          sx={{ fontWeight: "600" }}
                        >
                          {page * rowsPerPage + index + 1}
                        </TableCell>
                        <TableCell scope="row">{getFullName(row)}</TableCell>
                        <TableCell
                          // component="th"
                          id={labelId}
                          scope="row"
                          // padding="none"
                        >
                          {row?.email}
                          <Stack
                            direction="row"
                            alignItems={"center"}
                            //   justifyContent="space-between"
                          ></Stack>
                        </TableCell>
                        <TableCell align="right">
                          {row?.billing?.city}
                        </TableCell>

                        <TableCell align="right" sx={{ fontSize: "12px" }}>
                          {formatDate(
                            new Date(crmCustomer?.date_created),
                            "dd:mm:yyyy hh:mm"
                          )}
                          {/* {getSyncDate(row.label)} */}
                        </TableCell>
                        {/* <TableCell align="right">{row.carbs}</TableCell>
                      <TableCell align="right">{row.protein}</TableCell> */}
                      </TableRow>
                    );
                  })
              }
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Stack
          direction={"row"}
          justifyContent="flex-start"
          alignItems={"center"}
          gap="10px"
        >
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={shopifyCustomers?.length || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          {fetchingCustomers && (
            <>
              <CircularProgress style={{ height: "16px", width: "16px" }} />
              <Typography sx={{ ...secondaryLabel, whiteSpace: "nowrap" }}>
                Fetching customers...
              </Typography>
            </>
          )}
        </Stack>
      </Paper>
    </Box>
  );
};

export default AllCustomer;

export const AnalyticsCard = ({ title, value, suffix }) => {
  return (
    <Card
      sx={{
        border: "0.5px solid #e8e8e8",
        height: "100%",
        backgroundColor: "#f8f8f8",
      }}
    >
      <CardContent sx={{ "&:last-child": { paddingBottom: "16px" } }}>
        <Typography variant="h5" fontSize="12px" fontWeight={"500"}>
          {title}
        </Typography>
        <Stack mt={2} alignItems="flex-end" direction={"row"} flexWrap="wrap">
          <Typography variant="h5" fontSize="22px" fontWeight={"500"}>
            {value}
          </Typography>
          <Typography
            ml={1}
            variant="body2"
            fontSize="16px"
            color="text.secondary"
          >
            {suffix}
          </Typography>
        </Stack>
      </CardContent>
    </Card>
  );
};

const EnhancedTableToolbar = (props) => {
  const {
    numSelected,
    startImport,
    searchText,
    setSearchText,
    fetchingWooCommerceCustomer,
    importing,
    onRefresh,
  } = props;
  const [showHistory, setShowHistory] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [syncing, setSyncing] = useState(false);

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        // ...(numSelected > 0 && {
        //     bgcolor: (theme) =>
        //         alpha(
        //             theme.palette.primary.main,
        //             theme.palette.action.activatedOpacity
        //         ),
        // }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ fontSize: "12px", color: "text.secondary", flex: "1 1 100%" }}
        >
          {/* Last Sync:{" "} */}
          {/* {ornateIntegration.last_sync_time
                        ? formatDate(
                            new Date(ornateIntegration.last_sync_time),
                            "dd:mm:yyyy hh:mm am/pm"
                        )
                        : null} */}
        </Typography>
        // <Typography
        //   sx={{ flex: "1 1 100%" }}
        //   variant="h6"
        //   id="tableTitle"
        //   component="div"
        // ></Typography>
      )}

      <Stack direction="row" alignItems={"center"}>
        {/* <Button
          size="small"
          sx={{ whiteSpace: "nowrap" }}
          onClick={() => setShowHistory(true)}
        >
          Sync History
        </Button> */}
        <TextField
          variant="standard"
          // fullWidth
          placeholder="Search Customer"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          size="small"
          sx={{ mr: 2, width: "200px" }}
          InputProps={{
            startAdornment: (
              <InputAdornment>
                <Search sx={{ mr: 1, mb: 1 }} />
              </InputAdornment>
            ),
          }}
        />
        <LoadingButton
          size="small"
          sx={{ whiteSpace: "nowrap" }}
          loading={fetchingWooCommerceCustomer}
          onClick={() => onRefresh()}
        >
          Refresh
        </LoadingButton>

        <IconButton
          className={syncing ? "infinite_rotate" : ""}
          // onClick={onSyncPress}
          disabled={fetchingWooCommerceCustomer}
        >
          <Sync />
        </IconButton>
        {numSelected > 0 ? (
          <LoadingButton
            loading={importing}
            size="small"
            variant="contained"
            startIcon={<Download sx={{ height: "18px", width: "18px" }} />}
            onClick={startImport}
          >
            Import
          </LoadingButton>
        ) : (
          <Tooltip title="Filter list">
            <IconButton
            // onClick={() =>
            // setFilterForm((state) => ({ ...state, show: !state.show }))
            // }
            >
              {/* <FilterListIcon /> */}
            </IconButton>
          </Tooltip>
        )}
      </Stack>

      {/* <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem>
          <Avatar /> Profile
        </MenuItem>
        <MenuItem>
          <Avatar /> My account
        </MenuItem>
      </Menu> */}
      {/* <Dialog
                open={showHistory}
                onClose={closeHistory}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle variant="h5" id="alert-dialog-title">
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems={"center"}
                    >
                        Ornate History
                        <IconButton onClick={closeHistory}>
                            <Close />
                        </IconButton>
                    </Stack>
                </DialogTitle>
                <DialogContent>
                    <OrnateActivity />
                </DialogContent> */}
      {/* <DialogActions>
          <Button onClick={closeHistory}>Disagree</Button>
          <Button onClick={closeHistory} autoFocus>
            Agree
          </Button>
        </DialogActions> */}
      {/* </Dialog> */}
    </Toolbar>
  );
};

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
            size="small"
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export const ImportingCustomer = ({
  loading,
  percentage,
  total,
  totalDone,
  heading,
  illustration,
}) => {
  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 6,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === "light" ? "#00e676" : "#00e676",
    },
  }));
  return (
    <Dialog
      open={loading}
      // onClose={close}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="xs"
      fullWidth
    >
      <DialogTitle id="alert-dialog-title">{heading}</DialogTitle>
      <DialogContent>
        <Stack
          direction={"row"}
          justifyContent="center"
          sx={{ margin: "10px auto", alignSelf: "center" }}
        >
          {/* <Whatsapp
          width="130px"
          height="130px"
        // className="animated_background_color"
        /> */}
          {illustration}
        </Stack>
        <Box sx={{ mt: 6 }}>
          <BorderLinearProgress variant="determinate" value={percentage} />
          <Typography
            sx={{
              fontSize: "14px",
              textAlign: "right",
              mr: 2,
              mt: 2,
              fontWeight: "600",
            }}
          >
            {totalDone} / {total}
          </Typography>
        </Box>
        {/* <DialogContentText id="alert-dialog-description">
            Let Google help apps determine location. This means sending
            anonymous location data to Google, even when no apps are running.
          </DialogContentText> */}
      </DialogContent>
    </Dialog>
  );
};
