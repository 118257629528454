import { Box, Typography } from '@mui/material'
import React from 'react'

const Overview = () => {
    return (
        <Box sx={{ p: 3, mb: 3 }}>
            <Typography variant="subtitle1" sx={{ fontSize: "16px", mb: 4 }}>
                Camweara allows your customer to try on jewellery virtually within a second without waiting for days.
            </Typography>
            <Typography variant="subtitle1" sx={{ fontSize: "16px", mb: 4 }}>
                It gives your customers the flexibility to check out your products virtually in just a matter of minutes. This enables your customers to check out each and every aspect of the product before making a purchase.

            </Typography>
            <Typography variant="subtitle1" sx={{ fontSize: "16px", mb: 4 }}>
                Giving your consumers the chance to try on your beautiful jewellery virtually will not only boost their trust in your business but also help you establish a long-lasting relationship with them.
            </Typography>

        </Box>
    )
}

export default Overview