import { Box, Tab, Tabs } from "@mui/material";
import React, { useEffect, useMemo } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { isArrayWithValues, TabPanel } from "../../helper";
import { getDropdownData } from "../../redux/actions/persistActions";
import { getMoreVisitors } from "../../redux/actions/visitorActions";
import AllVisitors from "./AllVisitors";
import LiveVisistors from "./LiveVisistors";

const NewVisitors = () => {
  const [selectedTab, setSelectedTab] = useState("live_visitors");
  const allCountries = useSelector((state) => state.persist.countries);
  const dispatch = useDispatch()
  const params = useParams()
  useMemo(() => {
    if (!isArrayWithValues(allCountries)) dispatch(getDropdownData());
  }, []);
  useEffect(() => {
    if (params?.id) {
      setSelectedTab("all_visitors")
    }
  }, [params])


  return (
    <Box>
      <Tabs
        value={selectedTab}
        onChange={(e, newValue) => {
          setSelectedTab(newValue)

        }}
      >
        <Tab label={"Live visitors"} value="live_visitors" />
        <Tab label={"All visitors"} value="all_visitors" />
      </Tabs>
      <TabPanel value={selectedTab} index={"live_visitors"}>
        <LiveVisistors />
      </TabPanel>
      <TabPanel value={selectedTab} index={"all_visitors"}>
        <AllVisitors />
      </TabPanel>
    </Box>
  );
};

export default NewVisitors;
