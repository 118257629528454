import { Box, Button, Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/system";
import axios from "axios";
import Switch from "rc-switch";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  apiCallFromSocket,
  Base64,
  getAxiosError,
  getToken,
  getWebsite,
  isArrayWithValues,
  isObjWithValues,
  nodeWebsite,
  settingsEndpoint,
  sleep,
  StackRow,
  TabPanel,
} from "../../../../../helper";
import { setGlobalToast } from "../../../../../redux/actions/helperActions";
import { editSettings } from "../../../../../redux/actions/settingActions";
const tabs = [
  // {
  //     label: "Overview",
  //     value: "overview",
  // },
  // {
  //     label: "Mapping",
  //     value: "mapping",
  // },
  {
    label: "Sync",
    value: "Sync",
  },
  // { label: "Settings", value: "settings" },
  // { label: "Activity", value: "history" },
];
const Settings = () => {
  const [selectedTab, setSelectedTab] = useState("Sync");
  const [settings, setSettings] = useState({});
  const shopifySettingsFetched = useSelector(
    (state) => state.settings.shopifySettingsFetched
  );

  const [showProductsSettings, setShowProductSettings] = useState(true);
  const [showOrdersSettings, setShowOrdersSettings] = useState(true);
  const user_products = useSelector(
    (state) => state.user?.store_plan?.user_products
  );
  const dispatch = useDispatch();

  React.useEffect(() => {
    let onlyCRM = user_products?.length === 1 && user_products.includes("crm");
    setShowProductSettings(!onlyCRM);
    setShowOrdersSettings(!onlyCRM);
  }, [user_products]);

  const fetchShopifySettings = async () => {
    dispatch(
      editSettings({
        fetchingWooCommerceShopSettings: true,
      })
    );
    let settings = await settingsEndpoint({
      endpoint: "woocommerce_shop_settings",
    });
    if (settings) {
      setSettings(settings);
    }

    dispatch(
      editSettings({
        shopifySettingsFetched: true,
        fetchingWooCommerceShopSettings: false,
      })
    );
  };

  useEffect(() => {
    if (!shopifySettingsFetched) fetchShopifySettings();
  }, []);
  return (
    <Box>
      <Stack
        direction="row"
        sx={{
          backgroundColor: "Background.paper",
          padding: "10px",
          borderBottomColor: "#e8e8e8",
          borderBottomWidth: 0.2,
          borderBottomStyle: "solid",
          overflow: "scroll",
        }}
        spacing={4}
        className="scrollbar-hidden"
      >
        {tabs.map((i) => {
          return (
            <Button
              variant={"text"}
              // variant={selectedTab !== i.value ? "outlined" : "contained"}
              sx={{
                backgroundColor:
                  selectedTab === i.value ? "#e6e6e6" : "transparent",
                color: "#000",
                // color: selectedTab === i.value ? "primary.main" : "#000",
                "&:hover": {
                  backgroundColor:
                    selectedTab === i.value ? "#e6e6e6" : "transparent",
                },
              }}
              onClick={() => setSelectedTab(i.value)}
            >
              {i.label}
            </Button>
          );
        })}
      </Stack>
      <TabPanel index={selectedTab} value={"Sync"}>
        <Sync
          settings={settings}
          showOrdersSettings={showOrdersSettings}
          showProductsSettings={showProductsSettings}
        />
      </TabPanel>
    </Box>
  );
};

export default Settings;

const webhooks = {
  customer_created_updated: ["customer.created", "customer.updated"],
  customer_deleted: ["customer.deleted"],
  product_created_updated: ["product.created", "product.updated"],
  product_deleted: ["product.deleted"],
  order_created_updated: ["order.created", "order.updated"],
  order_deleted: ["order.deleted"],
};

const createWooCommerceShopWebhook = async ({
  website,
  token,
  topic,
  store_id,
  params,
}) => {
  try {
    const response = await apiCallFromSocket({
      url: `${website}/wp-json/wc/v3/webhooks`,
      method: "POST",
      headers: {
        Authorization: `Basic ${token}`,
      },
      data: {
        name: topic,
        topic,
        delivery_url: `${nodeWebsite}/woocommerce/${store_id}`,
      },
      params,
    });
    return response;
  } catch (error) {
    return getAxiosError(error);
  }
};
const batchWooCommerceShopWebhook = async ({
  website,
  token,
  topic,
  store_id,
}) => {
  try {
    const response = await apiCallFromSocket({
      url: `${website}/wp-json/wc/v3/webhooks`,
      method: "POST",
      headers: {
        Authorization: `Basic ${token}`,
      },
      data: {
        webhook: {
          topic,
          // address: `${website}/wc-api/ecommerce`,
          address: `${nodeWebsite}/woocommerce/${store_id}`,
          format: "json",
        },
      },
    });
    return response;
  } catch (error) {
    return getAxiosError(error);
  }
};
const getWooCommerceShopWebhooks = async ({ website, token, params }) => {
  try {
    const response = await axios({
      method: "GET",
      url: `${website}/wp-json/wc/v3/webhooks?hello`,
      headers: {
        Authorization: `Basic ${token}`,
      },
      params,
    });
    return response;
  } catch (error) {
    return getAxiosError(error);
  }
};
const deleteShopifyWebhook = async ({ id, website, token, params }) => {
  try {
    const response = await apiCallFromSocket({
      method: "DELETE",
      url: `${website}/wp-json/wc/v3/webhooks/${id}`,
      headers: {
        Authorization: `Basic ${token}`,
      },
      params: { ...params, force: true },
    });
    return response;
  } catch (error) {
    return getAxiosError(error);
  }
};

const Sync = ({ settings, showProductsSettings, showOrdersSettings }) => {
  const [form, setForm] = useState({});
  const [loading, setLoading] = useState("");

  const reduxWoocommerceIntegration = useSelector(
    (state) => state.settings.wooCommerceIntegration
  );
  const reduxWoocommerceShopSettings = useSelector(
    (state) => state.settings.woocommerceShopSettings
  );
  const fetchingWooCommerceShopSync = useSelector(
    (state) => state.settings.fetchingWooCommerceShopSync
  );
  const fetchedWooCommerceShopSync = useSelector(
    (state) => state.settings.fetchedWooCommerceShopSync
  );
  const fetchingWooCommerceShopSettings = useSelector(
    (state) => state.settings.fetchingWooCommerceShopSettings
  );

  useEffect(() => {
    if (
      (isObjWithValues(reduxWoocommerceIntegration) &&
        reduxWoocommerceIntegration.integrated &&
        !fetchedWooCommerceShopSync) ||
      true
    )
      setupWebhooks();
  }, [reduxWoocommerceIntegration]);

  const setupWebhooks = async () => {
    let obj = {};
    dispatch(
      editSettings({
        fetchingWooCommerceShopSync: true,
      })
    );
    let { consumer_key, consumer_secret, website } =
      reduxWoocommerceIntegration || {};
    let getWebhooksRes = await getWooCommerceShopWebhooks({
      website,
      // params: { key: consumer_key, secret: consumer_secret },
      token: `${Base64.btoa(`${consumer_key}:${consumer_secret}`)}`,
    });
    console.log(getWebhooksRes?.data, "<<<<asdfasldkfj");

    if (isArrayWithValues(getWebhooksRes?.data)) {
      let allWebhooks = getWebhooksRes?.data;
      for (let webhookTopic in webhooks) {
        console.log(
          webhooks[webhookTopic],
          webhooks[webhookTopic].every((topic) =>
            allWebhooks?.find((i) => i.topic === topic)
          ),
          allWebhooks?.filter((i) => webhooks[webhookTopic].includes(i.topic))
        );
        obj[webhookTopic] = {
          enabled: webhooks[webhookTopic].every((topic) =>
            allWebhooks?.find((i) => i.topic === topic)
          ),
          webhooks: allWebhooks?.filter((i) =>
            webhooks[webhookTopic].includes(i.topic)
          ),
        };
      }
      // obj.customers = {
      //   enabled: webhooks.customers.every((topic) =>
      //     allWebhooks?.find((i) => i.topic === topic)
      //   ),
      //   webhooks: allWebhooks?.filter((i) =>
      //     webhooks.customers.includes(i.topic)
      //   ),
      // };
      // obj.orders = {
      //   enabled: webhooks.orders.every((topic) =>
      //     allWebhooks?.find((i) => i.topic === topic)
      //   ),
      //   webhooks: allWebhooks?.filter((i) => webhooks.orders.includes(i.topic)),
      // };
    }
    let settings = await settingsEndpoint({
      method: "PUT",
      data: {
        sync: obj,
      },
      endpoint: "woocommerce_shop_settings",
    });

    if (settings) {
      dispatch(
        editSettings({
          woocommerceShopSettings: settings,
          fetchedWooCommerceShopSync: true,
        })
      );
    }
    dispatch(
      editSettings({
        fetchingWooCommerceShopSync: false,
      })
    );
  };

  useEffect(() => {
    if (isObjWithValues(settings) && settings.sync) setForm(settings.sync);
  }, [settings]);

  useEffect(() => {
    if (isObjWithValues(reduxWoocommerceShopSettings))
      setForm(reduxWoocommerceShopSettings?.sync);
  }, [reduxWoocommerceShopSettings]);
  const store_id = useSelector((state) => state.user.store_id);
  const dispatch = useDispatch();

  const editForm = (label, value, update = true) => {
    setForm((state) => ({
      ...state,
      [label]: { ...state[label], enabled: value },
    }));
    if (update) {
      if (value) subscribeCustomer(label, value);
      else unsubscribeCustomer(label, value);
    }
  };

  const subscribeCustomer = async (label) => {
    let events = webhooks[label];
    let webhooksRes = [];
    setLoading(label);
    console.log(events, "asdfj");
    try {
      let allWebhooks = [];

      let { consumer_key, consumer_secret, website } =
        reduxWoocommerceIntegration || {};
      let getWebhooksRes = await getWooCommerceShopWebhooks({
        website,
        token: `${Base64.btoa(`${consumer_key}:${consumer_secret}`)}`,
        // params: { key: consumer_key, secret: consumer_secret },
      });
      if (getWebhooksRes?.data) {
        allWebhooks = getWebhooksRes?.data?.data;
      } else if (!getWebhooksRes?.data && getWebhooksRes?.code) {
        setLoading("");
        setForm(reduxWoocommerceShopSettings?.sync);
        return dispatch(
          setGlobalToast({
            show: true,
            message: "Enabling sync failed",
            severity: "error",
          })
        );
      }
      for await (let event of events) {
        let res = await createWooCommerceShopWebhook({
          store_id,
          website,
          // params: { key: consumer_key, secret: consumer_secret },
          token: `${Base64.btoa(`${consumer_key}:${consumer_secret}`)}`,
          topic: event,
        });
        let { data, success } = res?.data || {};

        if (success) {
          if (data.webhook) webhooksRes.push(data.webhook);
        }
        if (
          !success &&
          data?.errors?.address?.[0]?.includes("topic has already been ")
        ) {
          let webhook = allWebhooks?.find((i) => i.topic === event);
          webhooksRes.push(webhook);
        }
        if (
          !success &&
          !data?.errors?.address?.[0]?.includes("topic has already been ")
        ) {
          await unsubscribeCustomer(label, webhooks);
          return dispatch(
            setGlobalToast({
              show: true,
              message: "Enabling sync failed",
              severity: "error",
            })
          );
        }
      }
    } catch (error) {
      console.log(error, "<<<<");
    }
    setLoading("");
    let settings = await settingsEndpoint({
      method: "PUT",
      data: {
        sync: {
          ...form,
          [label]: {
            ...form[label],
            enabled: isArrayWithValues(webhooksRes),
            webhooks: webhooksRes,
          },
        },
      },
      endpoint: "woocommerce_shop_settings",
    });

    if (settings) {
      dispatch(
        editSettings({
          woocommerceShopSettings: settings,
        })
      );
    }
  };
  const unsubscribeCustomer = async (label, _webhooks) => {
    let webhooks = _webhooks || form[label]?.webhooks;
    let { consumer_key, consumer_secret, website } =
      reduxWoocommerceIntegration || {};
    setLoading(label);
    if (!isArrayWithValues(webhooks)) {
      let getWebhooksRes = await getWooCommerceShopWebhooks({
        website,
        token: `${Base64.btoa(`${consumer_key}:${consumer_secret}`)}`,
        // params: { key: consumer_key, secret: consumer_secret },
      });
      if (getWebhooksRes?.data) {
        webhooks = getWebhooksRes?.data?.data;
      }
    }
    try {
      if (isArrayWithValues(webhooks)) {
        for await (let webhook of webhooks) {
          let res = await deleteShopifyWebhook({
            id: webhook.id,
            website,
            // params: { key: consumer_key, secret: consumer_secret },
            token: `${Base64.btoa(`${consumer_key}:${consumer_secret}`)}`,
          });
          console.log(res);
        }
      }
    } catch (error) {
      console.log(error, "<<<<");
    }
    setLoading("");

    let settings = await settingsEndpoint({
      method: "PUT",
      data: { sync: { ...form, [label]: { enabled: false, webhooks: [] } } },
      endpoint: "woocommerce_shop_settings",
    });

    if (settings) {
      dispatch(
        editSettings({
          woocommerceShopSettings: settings,
        })
      );
    }
  };
  return (
    <Box>
      <SwitchRow
        title={"Customer Create or Update"}
        subtitle="Sync when customer gets created, updated"
        loading={loading}
        forceLoading={
          fetchingWooCommerceShopSync || fetchingWooCommerceShopSettings
        }
        editForm={editForm}
        form={form}
        value={"customer_created_updated"}
      />
      <SwitchRow
        title={"Customer delete"}
        subtitle="Sync when customer gets deleted"
        loading={loading}
        forceLoading={
          fetchingWooCommerceShopSync || fetchingWooCommerceShopSettings
        }
        editForm={editForm}
        form={form}
        value={"customer_deleted"}
      />
      {showProductsSettings && (
        <>
          <SwitchRow
            title={"Product Create or Update"}
            subtitle="Sync when product gets created, updated"
            loading={loading}
            forceLoading={
              fetchingWooCommerceShopSync || fetchingWooCommerceShopSettings
            }
            editForm={editForm}
            form={form}
            value={"product_created_updated"}
          />

          <SwitchRow
            title={"Product Deleted"}
            subtitle="Sync when product gets deleted"
            loading={loading}
            forceLoading={
              fetchingWooCommerceShopSync || fetchingWooCommerceShopSettings
            }
            editForm={editForm}
            form={form}
            value={"product_deleted"}
          />
        </>
      )}
      {showOrdersSettings && (
        <>
          <SwitchRow
            title={"Order Create or Update"}
            subtitle="Sync when order gets created, updated"
            loading={loading}
            forceLoading={
              fetchingWooCommerceShopSync || fetchingWooCommerceShopSettings
            }
            editForm={editForm}
            form={form}
            value={"order_created_updated"}
          />
          <SwitchRow
            title={"Order deleted"}
            loading={loading}
            forceLoading={
              fetchingWooCommerceShopSync || fetchingWooCommerceShopSettings
            }
            editForm={editForm}
            form={form}
            value={"order_deleted"}
            subtitle="Sync customer order when it deleted"
          />
        </>
      )}
    </Box>
  );
};

const SwitchRow = ({
  form = {},
  title,
  subtitle,
  editForm,
  value,
  loading,
  forceLoading,
}) => {
  const theme = useTheme();
  let backgroundColor = theme.palette.primary && theme.palette.primary.main;
  return (
    <Box sx={{ px: 4 }}>
      <Stack
        direction={"row"}
        alignItems="center"
        justifyContent="space-between"
        sx={{ mt: 5 }}
      >
        <Typography variant="h5" fontSize="15px">
          {title}
        </Typography>
        <Switch
          disabled={loading === value || forceLoading}
          onChange={(e) => {
            editForm(value, e);
          }}
          checked={form[value]?.enabled}
          style={{
            border: `1px solid ${
              form[value]?.enabled ? backgroundColor : "#e8e8e8"
            }`,
            backgroundColor: form[value]?.enabled ? backgroundColor : "#e8e8e8",
          }}
        />
      </Stack>
      <Typography
        variant="body2"
        sx={{ fontSize: "12px", color: "text.secondary", mt: 1 }}
      >
        {subtitle}
      </Typography>
    </Box>
  );
};
