import React, { useEffect, useState } from "react";
import { CircleNotifications, Lock, QuizSharp } from "@mui/icons-material";
import { DateTimePicker, LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Card,
  Checkbox,
  Collapse,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  InputAdornment,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import axios from "axios";
import { EbaySvg } from "../../../../Svgs";
import EbayOverview from "./EbayOverview";
import EbayIntegration from "./EbayIntegration";
import EbayAnalytics from "./EbayAnalytics";
import {
  Base64,
  getAxiosError,
  getStoreId,
  getToken,
  getWebsite,
} from "../../../../helper";
import { useLocation, useNavigate } from "react-router-dom";
import { EDIT_SETTINGS } from "../../../../redux/actions/settingActions";
import { useDispatch, useSelector } from "react-redux";
import qs from "qs";
import ebayCreds, { getQueryString } from "./constants";
import EbayMapping from "./mapping";
import { setGlobalToast } from "../../../../redux/actions/helperActions";
import Settings from "./settings";

function Ebay() {
  const location = useLocation();
  const dispatch = useDispatch();
  const [selectedTab, setSelectedTab] = useState("overview");

  const reduxEbayIntegration = useSelector(
    (state) => state.settings.ebayIntegration
  );
  // Get OAuth success params
  useEffect(() => {
    let params_string = location.search;
    let code = new URLSearchParams(params_string).get("code");
    let app = new URLSearchParams(params_string).get("state");

    if (!code || !app) return;

    if (app?.includes("ebay")) {
      try {
        if (app.split("_")?.[1] == getStoreId()) {
          exchangeCodeForAccessToken(code, app);
          setSelectedTab("integration");
        }
      } catch (err) {
        console.log("error ebay-authcode", err);
      }
    }
  }, []);

  // Exchange AuthCode for AccessToken
  const exchangeCodeForAccessToken = async (authCode, app) => {
    let website = getWebsite();
    let token = getToken();

    const data = {
      "payload[grant_type]": "authorization_code",
      "payload[code]": authCode,
      "payload[redirect_uri]": ebayCreds.redirectURI,
    };

    try {
      dispatch({
        type: EDIT_SETTINGS,
        payload: {
          fetchingTokenForEbay: true,
        },
      });
      let updateRes = await axios({
        url: `${website}/wp-json/store/v1/channel/ebay?endpoint=/identity/v1/oauth2/token`,
        method: "POST",
        headers: { "content-type": "application/x-www-form-urlencoded" },
        data: qs.stringify(data),
      });

      console.log(updateRes.data);

      if (updateRes.status === 200 && !updateRes.data?.error) {
        if (updateRes.data?.access_token) updateEbayTokens(updateRes.data);
      } else {
        dispatch(
          setGlobalToast({
            show: true,
            message:
              updateRes.data?.error_description ||
              "Couln't integrate ebay. Please try again",
            severity: "error",
          })
        );
        dispatch({
          type: EDIT_SETTINGS,
          payload: {
            fetchingTokenForEbay: false,
          },
        });
      }
    } catch (err) {
      console.log("Ebay exchange code", getAxiosError(err));

      dispatch({
        type: EDIT_SETTINGS,
        payload: {
          fetchingTokenForEbay: false,
        },
      });
    }
  };

  const updateEbayTokens = async (ebayTokens) => {
    let website = getWebsite();
    let token = getToken();

    if (website && token) {
      try {
        let updateRes = await axios({
          url: `${website}/wp-json/store/v1/settings/ebay_integration`,
          method: "POST",
          headers: {
            Authorization: `Basic ${token}`,
          },
          data: {
            setting: "ebay_integration",
            data: { ...ebayTokens, integrated: true },
          },
        });

        if (updateRes.data.success) {
          dispatch({
            type: EDIT_SETTINGS,
            payload: {
              ebayIntegration: updateRes.data.data,
            },
          });
          dispatch(
            setGlobalToast({
              show: true,
              message: "Your eBay integration has been successfully completed",
              severity: "success",
            })
          );
        }
      } catch (err) {
        console.log("err", err);
      }

      dispatch({
        type: EDIT_SETTINGS,
        payload: {
          fetchingTokenForEbay: false,
        },
      });
    }
  };

  return (
    <Box sx={{ height: "100%", paddingRight: "15px" }}>
      <Stack
        sx={{
          position: "sticky",
          top: "0",
          backgroundColor: "#fff",
          zIndex: 13,
          margin: 0,
          padding: "12px",
        }}
      >
        <Stack
          direction="row"
          justifyContent={"space-between"}
          alignItems="center"
        >
          <Typography
            variant="h3"
            sx={{ display: "flex", alignItems: "center", gap: "10px" }}
          >
            {" "}
            <EbaySvg height={25} width={25} /> Ebay
          </Typography>
        </Stack>
        <Tabs
          onChange={(e, newValue) => setSelectedTab(newValue)}
          value={selectedTab}
          sx={{ borderBottom: 1, borderColor: "divider" }}
        >
          <Tab label="Overview" value="overview" />
          <Tab label="Integration" value="integration" />
          <Tab label="Mapping" value="mapping" />
          <Tab label="Settings" value="settings" />
        </Tabs>
      </Stack>
      <TabPanel value={selectedTab} index={"overview"}>
        <EbayOverview />
      </TabPanel>
      <TabPanel value={selectedTab} index={"integration"}>
        <EbayIntegration />
      </TabPanel>
      {reduxEbayIntegration?.integrated && (
        <>
          <TabPanel value={selectedTab} index={"mapping"}>
            <EbayMapping />
          </TabPanel>
          <TabPanel value={selectedTab} index={"analytics"}>
            <EbayAnalytics />
          </TabPanel>
          <TabPanel value={selectedTab} index={"settings"}>
            <Settings />
          </TabPanel>
        </>
      )}
    </Box>
  );
}

export default Ebay;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}
