import { ChevronRightRounded } from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  FormLabel,
  Grid,
  Stack,
  TextField,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import { useState } from "react";
import {
  getAxiosError,
  getRandomString,
  getStoreName,
  getToken,
  getWebsite,
  isArrayWithValues,
  isObjWithValues,
  mergeConversation,
  validateNumber,
} from "../../../helper";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { EDIT_CHATS } from "./../../../redux/actions/chatActions";
import { forwardRef } from "react";
import { useImperativeHandle } from "react";
import { setGlobalToast } from "../../../redux/actions/helperActions";
import { useMemo } from "react";
import MergeView from "./MergeView";
import { useNavigate } from "react-router-dom";

// function mergeConversation(customer) {
//   // let customer = {
//   //   id: "nrvflijnm",
//   //   name: "merge",
//   //   platform: "whatsapp",
//   //   ids: ["919998060495", "919967283656", "919167707645"],
//   //   conversation: [],
//   // };

//   let conversationArray = [];
//   for (const id of customer?.ids) {
//     let customer = allReduxConversations?.find((obj) => obj.id === id);
//     if (customer && isArrayWithValues(customer?.conversation)) {
//       conversationArray = [
//         ...conversationArray,
//         ...customer?.conversation?.map((obj) => ({
//           ...obj,
//           from: `${customer?.first_name || ""} ${customer?.last_name || ""} ${
//             customer?.name || ""
//           }`,
//           to: getWebsite(),
//         })),
//       ];
//     }
//   }
//   customer.conversation = conversationArray.sort(
//     (a, b) => validateNumber(b.time) - validateNumber(a.time)
//   );
//   return customer;
// }
const MergeCreate = forwardRef(
  ({ selectedCustomers, setLoading, clearBulk, showExisting, setShowExisting, setCustomerView, setListTab }, ref) => {
    const theme = useTheme();
    const isIpad = useMediaQuery(theme.breakpoints.down("md"));
    const allReduxConversations = useSelector(
      (state) => state.chats.conversations
    );
    const dispatch = useDispatch();
    const [mergeState, setMergeState] = React.useState({});
    const [showError, setShowError] = useState({});

    const [preMerge, setPreMerge] = useState([])
    const navigate = useNavigate()
    useMemo(() => {
      setShowError({});
    }, [selectedCustomers]);
    useImperativeHandle(ref, () => ({
      onCreateMerge() {
        onCreateMerge();
      },
    }));

    const onCreateMerge = async () => {
      let time = Date.now();
      let id = `${time}_${getRandomString(5)}`;
      let length = selectedCustomers?.length
      let data = {
        [id]: {
          id,
          name: mergeState?.name,
          platform: selectedCustomers?.[0]?.platform,
          ids: selectedCustomers?.map((obj) => obj?.id),
        },
      }
      if (showExisting && isObjWithValues(preMerge)) {
        console.log(preMerge);
        id = preMerge?.id
        data = {
          [preMerge?.id]: {
            id: preMerge?.id,
            name: preMerge?.name,
            platform: preMerge?.platform,
            ids: [...new Set([...preMerge?.ids, ...selectedCustomers.map((obj) => obj?.id)])]
          }
        }
        selectedCustomers.push({ platform: preMerge?.platform })
      }
      else {
        data = {
          [id]: {
            id,
            name: mergeState?.name,
            platform: selectedCustomers?.[0]?.platform,
            ids: selectedCustomers?.map((obj) => obj?.id),
          },
        }
      }

      if (new Set(selectedCustomers?.map((obj) => obj.platform)).size !== 1) {
        setShowError({
          platform: true,
        });
        return;
      }
      // console.log("hi", Date.now());
      let website = getWebsite();
      let token = getToken();

      // console.log(id);

      console.log({ ...data });

      // return
      setLoading(true);
      try {
        const res = await axios({
          url: `${website}/wp-json/store/v1/settings/chats_groupings`,
          headers: {
            Authorization: `Basic ${token}`,
          },
          method: "PUT",
          data: {
            setting: "chats_groupings",
            data: {
              ...data
            },
          },
        });
        if (res?.data?.success) {


          dispatch({
            type: EDIT_CHATS,
            payload: {
              chatsGroupings: res?.data?.data,
            },
          });
          dispatch(
            setGlobalToast({
              show: true,
              message: `${length || ""} added in  ${mergeState?.name || ""
                } for Merge view`,
              severity: "success",
            })
          );
          setMergeState({});
          console.log(res?.data?.data?.[id], mergeConversation(res?.data?.data?.[id], allReduxConversations));
          setCustomerView(mergeConversation(res?.data?.data?.[id], allReduxConversations))
          navigate(`/chats/${id}`)
          setListTab("merged")
        }
        clearBulk();
        console.log(res);
      } catch (error) {
        console.log(getAxiosError(error));
      }
      setLoading(false);
    };
    return (
      <Box p={4}>
        {showError?.platform && (
          <Alert sx={{ my: 2 }} variant="outlined" severity="error">
            Selcted Chats are from diffrent platform.
          </Alert>
        )}

        {!showExisting && (
          <>
            <Stack
              direction={"row"}
              alignItems="center"
              justifyContent={"space-between"}
            >
              <Typography color={"text.secondary"} variant="h6">
                More view settings
              </Typography>
              <Button
                endIcon={<ChevronRightRounded />}
                onClick={() => setShowExisting(true)}
              >
                Add to existing{" "}
              </Button>
            </Stack>
            <Grid container mt={3}>
              <Grid item xs={6}>
                <FormLabel>Merge title</FormLabel>
                <TextField
                  size="small"
                  placeholder="eg. sales team"
                  fullWidth
                  value={mergeState.name || ""}
                  onChange={(e) =>
                    setMergeState((pre) => ({ ...pre, name: e.target.value }))
                  }
                />
              </Grid>
            </Grid>
          </>
        )}

        {showExisting && (
          <MergeView
            allReduxConversations={allReduxConversations}
            isIpad={isIpad}
            action={true}
            setShowExisting={setShowExisting}
            setPreMerge={setPreMerge}
            preMerge={preMerge}
          />
        )}
        {/* <Button onClick={() => onCreateMerge()}>create</Button> */}
      </Box>
    );
  }
);

export default MergeCreate;
