import {
    ErrorOutlineOutlined,
    ExpandMoreOutlined,
    Language,
} from "@material-ui/icons";
import {
    Avatar,
    Chip,
    Grid,
    TableBody,
    TableCell,
    Typography,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Paper,
    Slide,
    Stack,
    Table,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    useTheme,
    Skeleton,
} from "@mui/material";
import React, { useState } from "react";
import { memo } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import {
    dialogStyle,
    fetchProducts,
    getProductPrice,
    goldName,
    isArrayWithValues,
    isProductIncomplete,
    validateNumber,
} from "../../../helper";
import { currencySymbols } from "../../pricing/Gold";
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const ChatProducts = ({ open, productsId, onClose }) => {

    const theme = useTheme();
    const isDarkTheme = theme.palette && theme.palette.mode === "dark";
    const allTaxes = useSelector((state) => state.settings.allTaxes) || []
    const currency = useSelector(
        (state) => state.settings?.productSettings?.default_currency
    );
    const globalPopupStyle = useSelector((state) => state.helper.globalPopupStyle);
    const masterPricing = useSelector((state) => state.settings.masterPricing);
    const productSettings = useSelector(
        (state) => state.settings.productSettings
    );
    const allDiamondGroups = useSelector(
        (state) => state.settings.allDiamondGroups
    );
    const [productsList, setProductsList] = useState([]);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        if (isArrayWithValues(productsId)) {
            fetchProducts({ include: productsId?.join(","), per_page: "30" }, setProduct, setLoading);
        }
    }, [productsId]);
    const hanldeClose = () => {
        onClose()
        setProductsList([])
    }
    const setProduct = (arr) => {
        let productsArr = [];
        for (let product of arr) {
            if (isArrayWithValues(product?.meta_data)) {
                for (const metaObj of product?.meta_data) {
                    product[metaObj?.key] = metaObj?.value;
                }
            }
            productsArr.push(product);
        }
        setProductsList(productsArr);
    };
    console.log(productsList, productsId);

    return (
        <Box>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                // onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
                fullWidth
                maxWidth={"md"}
                onClose={hanldeClose}
                sx={{ ...dialogStyle }}
                {...globalPopupStyle}
            >
                <DialogTitle>Products</DialogTitle>
                <DialogContent>

                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Product name</TableCell>
                                    <TableCell>Stock</TableCell>
                                    <TableCell >Price</TableCell>
                                    <TableCell >Purity</TableCell>
                                    <TableCell >Weight</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {loading &&
                                    new Array(7).fill("").map((str) => (
                                        <TableRow>
                                            <TableCell>
                                                <Stack direction={"row"} alignItems="center" gap={"10px"}>
                                                    <Skeleton variant="circular" width={40} height={40} />
                                                    <Stack>
                                                        <Skeleton
                                                            variant="text"
                                                            sx={{ fontSize: "1rem", width: "80px" }}
                                                        />
                                                        <Skeleton
                                                            variant="text"
                                                            sx={{ fontSize: "1rem", with: "50px" }}
                                                        />
                                                    </Stack>
                                                </Stack>
                                            </TableCell>
                                            <TableCell><Skeleton
                                                variant="text"
                                                sx={{ fontSize: "1rem", with: "45%" }}
                                            /></TableCell>
                                            <TableCell >
                                                <Skeleton
                                                    variant="text"
                                                    sx={{ fontSize: "1rem", with: "45%" }}
                                                />
                                            </TableCell>
                                            <TableCell align="right">
                                                <Skeleton
                                                    variant="text"
                                                    sx={{ fontSize: "1rem", with: "45%" }}
                                                />
                                            </TableCell>
                                            <TableCell align="right">
                                                <Skeleton
                                                    variant="text"
                                                    sx={{ fontSize: "1rem", with: "45%" }}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                {productsList.map((row) => {
                                    let {
                                        id,
                                        date_created,
                                        images,
                                        price,
                                        sale_price,
                                        meta_data,
                                        permalink,
                                    } = row;
                                    let imageUrl =
                                        Array.isArray(row.images) && images[0] && images[0].src;

                                    return (
                                        <TableRow>
                                            <TableCell>
                                                <Stack
                                                    alignItems={"center"}
                                                    direction={"row"}
                                                    sx={{ width: "100%" }}
                                                >
                                                    <Avatar
                                                        alt={row.name}
                                                        src={
                                                            imageUrl ||
                                                            "/static/img/woocommerce_placeholder.png"
                                                        }
                                                        sx={{ width: 50, height: 50, cursor: "pointer" }}
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            // onEditPress(row);
                                                        }}

                                                    // srcSet={`${small} 100w, ${medium} 68w, ${large} 1280w, ${xlarge} 3200w`}
                                                    />
                                                    <Stack>
                                                        <Typography>{row?.name}</Typography>
                                                        <Typography
                                                            variant="body1"
                                                            // component={Button}
                                                            disableRipple
                                                            disableFocusRipple
                                                            disableElevation
                                                            // onClick={(e) => onEditPress(row)}
                                                            sx={{
                                                                justifyContent: "flex-start",
                                                                backgroundColor: "transparent",
                                                                fontSize: "12px",
                                                                fontWeight: "500",
                                                                color: "text.secondary",
                                                            }}
                                                            style={{
                                                                padding: "0px",
                                                                cursor: "pointer",
                                                                mt: 0.4,
                                                            }}
                                                            fontStyle={{ textAlign: "left" }}
                                                        >
                                                            SKU: {row.sku}
                                                        </Typography>
                                                    </Stack>
                                                </Stack>
                                            </TableCell>
                                            <TableCell >
                                                {row.stock_status === "instock" && (
                                                    <Chip
                                                        size="small"
                                                        mr={1}
                                                        mb={1}
                                                        label="In stock"
                                                        // paid={+true}
                                                        color="success"
                                                        sx={{
                                                            border: isDarkTheme ? "0.7px solid" : "0px",
                                                            backgroundColor: isDarkTheme
                                                                ? "transparent"
                                                                : "#E6F8E0",
                                                        }}
                                                        variant={"outlined"}
                                                    />
                                                )}
                                                {row.stock_status === "onbackorder" && (
                                                    <Chip
                                                        size="small"
                                                        mr={1}
                                                        mb={1}
                                                        label="Make to Order"
                                                        sx={{
                                                            border: isDarkTheme ? "0.7px solid" : "0px",
                                                            backgroundColor: isDarkTheme
                                                                ? "transparent"
                                                                : "#ededed",
                                                        }}
                                                        variant={"outlined"}
                                                    />
                                                )}
                                                {row.stock_status === "outofstock" && (
                                                    <Chip
                                                        size="small"
                                                        mr={1}
                                                        mb={1}
                                                        label="Out of Stock"
                                                        color="warning"
                                                        sx={{
                                                            border: isDarkTheme ? "0.7px solid" : "0px",
                                                            backgroundColor: isDarkTheme
                                                                ? "transparent"
                                                                : "#f7e0d2",
                                                        }}
                                                        variant="outlined"
                                                    />
                                                )}
                                            </TableCell>
                                            <TableCell>
                                                <Typography> {currencySymbols[currency]}{getProductPrice(row,
                                                    masterPricing,
                                                    productSettings,
                                                    allDiamondGroups,
                                                    Math.max(...allTaxes.map((o) => validateNumber(o.rate)))
                                                )?.price}</Typography>
                                            </TableCell>
                                            <TableCell>

                                                <Typography>{goldName[row?.gold_kt]}</Typography>
                                                {/* <Typography> {row?.gold_kt ? `(${row?.gold_kt})` : null}</Typography> */}
                                            </TableCell>
                                            <TableCell>
                                                <Stack>
                                                    <Typography>{row?.gold_gross ? `Gold: ${row?.gold_gross}` : null}</Typography>
                                                    <Typography>{row?.total_diamond_weight ? `Diamond: ${row?.total_diamond_weight}` : null}</Typography>
                                                </Stack>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={hanldeClose}
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default memo(ChatProducts);

const productRow = (row) => {
    let { images, permalink } = row;
    let imageUrl = Array.isArray(row.images) && images[0] && images[0].src;

    return (
        <></>
        // <TableRow
        //     hover
        //     // role="checkbox"

        //     key={`${row.id}`}
        // >
        //     <TableCell
        //         component="th"
        //         // id={labelId}
        //         sx={{ width: "100%", px: 1 }}
        //         scope="row"
        //     >
        //         {/* <Customer> */}
        //         <Avatar
        //             alt={row.name}
        //             src={imageUrl || "/static/img/woocommerce_placeholder.png"}
        //             sx={{ width: 50, height: 50, cursor: "pointer" }}
        //         //   onClick={(e) => {
        //         //     e.preventDefault();
        //         //     onEditPress(row);
        //         //   }}

        //         // srcSet={`${small} 100w, ${medium} 68w, ${large} 1280w, ${xlarge} 3200w`}
        //         />
        //         <Box ml={3} sx={{ flex: 1 }}>
        //             <Stack
        //                 container
        //                 spacing={2}
        //                 alignItems="center"
        //                 justifyContent="space-between"
        //                 direction="row"
        //             >
        //                 <Box>
        //                     <Typography
        //                         variant="body1"
        //                         // component={Button}
        //                         disableRipple
        //                         disableFocusRipple
        //                         disableElevation
        //                         // onClick={(e) => onEditPress(row)}
        //                         sx={{
        //                             justifyContent: "flex-start",
        //                             backgroundColor: "transparent",
        //                             fontWeight: "500",
        //                         }}
        //                         style={{ padding: "0px", cursor: "pointer" }}
        //                         fontStyle={{ textAlign: "left" }}
        //                     >
        //                         {row.name}
        //                     </Typography>
        //                     {row.sku && (
        //                         <Typography
        //                             variant="body1"
        //                             // component={Button}
        //                             disableRipple
        //                             disableFocusRipple
        //                             disableElevation
        //                             //   onClick={(e) => onEditPress(row)}
        //                             sx={{
        //                                 justifyContent: "flex-start",
        //                                 backgroundColor: "transparent",
        //                                 fontSize: "12px",
        //                                 fontWeight: "500",
        //                                 color: "text.secondary",
        //                             }}
        //                             style={{
        //                                 padding: "0px",
        //                                 cursor: "pointer",
        //                                 mt: 0.4,
        //                             }}
        //                             fontStyle={{ textAlign: "left" }}
        //                         >
        //                             SKU: {row.sku}
        //                         </Typography>
        //                     )}
        //                     <Stack mt={0} direction="row" alignItems={"center"}>
        //                         {/* <Stack direction="row" alignItems={"center"}>
        //                 <Favorite
        //                   style={{
        //                     height: "13px",
        //                     width: "13px",
        //                     color: "#c9839a",
        //                   }}
        //                 />
        //                 <Typography variant="h6" fontSize="12px" ml={1}>
        //                   {object.likes || 0}
        //                 </Typography>
        //               </Stack> */}
        //                         {isArrayWithValues(isProductIncomplete4(row)) && (
        //                             <Stack
        //                                 // ml={3}
        //                                 component={Button}
        //                                 direction="row"
        //                                 alignItems={"center"}
        //                                 sx={{ cursor: "pointer", pl: 0 }}
        //                             // onClick={incompleteClick}
        //                             // onMouseEnter={incompleteClick}
        //                             // onMouseLeave={incompleteClose}
        //                             >
        //                                 <ErrorOutlineOutlined
        //                                     style={{
        //                                         height: "13px",
        //                                         width: "13px",
        //                                         // color: "#c9839a",
        //                                     }}
        //                                     color="error"
        //                                 />
        //                                 <Typography variant="h6" fontSize="12px" sx={{ ml: 1 }}>
        //                                     Incomplete
        //                                 </Typography>
        //                                 <ExpandMoreOutlined
        //                                     style={{
        //                                         height: "13px",
        //                                         width: "13px",
        //                                         // color: "#c9839a",
        //                                     }}
        //                                     color="error"
        //                                 />
        //                             </Stack>
        //                         )}
        //                         {/* <Popover
        //                           id={incompleteId}
        //                           open={open}
        //                           anchorEl={anchorEl}
        //                           onClose={incompleteClose}
        //                           anchorOrigin={{
        //                             vertical: "bottom",
        //                             horizontal: "left",
        //                           }}
        //                           sx={{
        //                             boxShadow: 2,
        //                             // boxShadow: "1px 1px 5px 0px rgba(0,0,0,0.75)",
        //                             border: "0.4px solid rgba(0,0,0,0.4)",
        //                           }}
        //                         >
        //                           <Box
        //                             sx={{
        //                               p: 3,
        //                               pl: 0,
        //                             }}
        //                           >
        //                             {incompleteReasons.map((i) => {
        //                               return (
        //                                 <Stack
        //                                   direction="row"
        //                                   alignItems="center"
        //                                   mt={1.2}
        //                                 >
        //                                   <Box
        //                                     sx={{
        //                                       height: "7px",
        //                                       width: "7px",
        //                                       borderRadius: "50%",
        //                                       mr: 1.4,
        //                                       // color: "primary",
        //                                     }}
        //                                     backgroundColor="text.secondary"
        //                                   />
        //                                   <Typography
        //                                     fontWeight="500"
        //                                     fontSize="13px"
        //                                   >
        //                                     {i}
        //                                   </Typography>
        //                                 </Stack>
        //                               );
        //                             })}
        //                           </Box>
        //                         </Popover> */}
        //                     </Stack>
        //                     <Stack
        //                         direction="row"
        //                         alignItems={"center"}
        //                         spacing={3}
        //                         sx={{ mt: 1 }}
        //                     >
        //                         {row.status === "publish" && (
        //                             <Box
        //                             // direction={"row"}
        //                             // justifyContent="flex-start"
        //                             // sx={{ flex: 0 }}
        //                             >
        //                                 <Tooltip title="View on Store" arrow>
        //                                     <div
        //                                         onClick={() => window.open(permalink, "_blank")}
        //                                         style={{ cursor: "pointer" }}
        //                                     >
        //                                         <Language
        //                                             style={{
        //                                                 height: "20px",
        //                                                 width: "20px",
        //                                                 fill: "#6a6a6a",
        //                                             }}
        //                                         />
        //                                     </div>
        //                                 </Tooltip>
        //                             </Box>
        //                         )}

        //                         {/* {object?.pull_source && (
        //                             <Box
        //                             // direction={"row"}
        //                             // justifyContent="flex-start"
        //                             // sx={{ flex: 0 }}
        //                             >
        //                                 <Tooltip title={supplier?.name || ""} arrow>
        //                                     <Inventory2Outlined fontSize="small" />
        //                                 </Tooltip>
        //                             </Box>
        //                         )} */}
        //                     </Stack>
        //                 </Box>

        //                 {/* <Grid item >
        //           </Grid> */}
        //                 {/* {isIpad && (
        //                     <IconButton
        //                         aria-label="options"
        //                         size="small"
        //                         onClick={(e) => {
        //                             e.stopPropagation();
        //                             setShowActions(e.currentTarget);
        //                         }}
        //                         sx={{ py: 0 }}
        //                     >
        //                         <MoreVert />
        //                     </IconButton>
        //                     // <Grid item>
        //                     // </Grid>
        //                 )} */}
        //             </Stack>
        //             {isIpad && (
        //                 <>
        //                     <Grid container spacing={2} sx={{ alignItems: "center" }}>
        //                         {metalPurity && (
        //                             <Grid item>
        //                                 <Typography variant="caption">
        //                                     Purity:{" "}
        //                                     <span style={{ fontWeight: "bold" }}>
        //                                         {metalPurity}
        //                                     </span>
        //                                 </Typography>
        //                             </Grid>
        //                         )}
        //                         {/* <Grid item xs /> */}
        //                         <Grid item>
        //                             {row.stock_status === "instock" && (
        //                                 <Typography
        //                                     variant="caption"
        //                                     color="success.main"
        //                                     fontWeight={"bold"}
        //                                 >
        //                                     In stock
        //                                 </Typography>
        //                             )}
        //                             {row.stock_status === "onbackorder" && (
        //                                 <Typography
        //                                     variant="caption"
        //                                     color="warning.main"
        //                                     fontWeight={"bold"}
        //                                 >
        //                                     Make to order
        //                                 </Typography>
        //                             )}
        //                             {row.stock_status === "outofstock" && (
        //                                 <Typography
        //                                     variant="caption"
        //                                     color="error.main"
        //                                     fontWeight={"bold"}
        //                                 >
        //                                     Out of stock
        //                                 </Typography>
        //                             )}
        //                         </Grid>
        //                     </Grid>

        //                     {/* <Stack direction={"row"}> */}
        //                     <Grid container spacing={2}>
        //                         {metalWeight ? (
        //                             <Grid item>
        //                                 <Typography variant="caption">
        //                                     Metal WT:{" "}
        //                                     <span style={{ fontWeight: "bold" }}>
        //                                         {metalWeight}
        //                                     </span>
        //                                 </Typography>
        //                             </Grid>
        //                         ) : null}
        //                         {diamondWeight ? (
        //                             <Grid item>
        //                                 <Typography variant="caption">
        //                                     Diamond WT:{" "}
        //                                     <span style={{ fontWeight: "bold" }}>
        //                                         {diamondWeight}
        //                                     </span>
        //                                 </Typography>
        //                             </Grid>
        //                         ) : null}
        //                     </Grid>
        //                 </>
        //             )}
        //             {/* </Stack> */}
        //             {/* <span
        //           style={{
        //             flexWrap: "wrap",
        //             width: "100%",
        //             whiteSpace: "nowrap",
        //             overflow: "hidden",
        //             textOverflow: "ellipsis",
        //             margin: "0px",
        //             cursor: "pointer",
        //           }}
        //           onClick={() => onEditPress(row)}
        //         >
        //           {row.sku ? row.name : "NO-SKU"}
        //         </span> */}
        //         </Box>
        //         {/* </Customer> */}
        //     </TableCell>
        //     {!isIpad ? (
        //         <>
        //             <TableCell align="center">
        //                 <Typography sx={{ fontWeight: "600", whiteSpace: "nowrap" }}>
        //                     {currencySymbols[props.currency] || ""} {row?.priceBreakup?.price}
        //                 </Typography>
        //                 {row?.priceBreakup?.totalDiscount ? (
        //                     <Typography
        //                         sx={{
        //                             whiteSpace: "nowrap",
        //                             textDecoration: "line-through",
        //                             fontSize: "12px",
        //                             color: "text.secondary",
        //                         }}
        //                     >
        //                         {currencySymbols[props.currency] || ""}{" "}
        //                         {row?.priceBreakup?.totalPrice}
        //                     </Typography>
        //                 ) : null}
        //             </TableCell>
        //             <TableCell align="center">
        //                 {row.stock_status === "instock" && (
        //                     <Chip
        //                         size="small"
        //                         mr={1}
        //                         mb={1}
        //                         label="In stock"
        //                         // paid={+true}
        //                         color="success"
        //                         sx={{
        //                             border: isDarkTheme ? "0.7px solid" : "0px",
        //                             backgroundColor: isDarkTheme ? "transparent" : "#E6F8E0",
        //                         }}
        //                         variant={"outlined"}
        //                     />
        //                 )}
        //                 {row.stock_status === "onbackorder" && (
        //                     <Chip
        //                         size="small"
        //                         mr={1}
        //                         mb={1}
        //                         label="Make to Order"
        //                         sx={{
        //                             border: isDarkTheme ? "0.7px solid" : "0px",
        //                             backgroundColor: isDarkTheme ? "transparent" : "#ededed",
        //                         }}
        //                         variant={"outlined"}
        //                     />
        //                 )}
        //                 {row.stock_status === "outofstock" && (
        //                     <Chip
        //                         size="small"
        //                         mr={1}
        //                         mb={1}
        //                         label="Out of Stock"
        //                         color="warning"
        //                         sx={{
        //                             border: isDarkTheme ? "0.7px solid" : "0px",
        //                             backgroundColor: isDarkTheme ? "transparent" : "#f7e0d2",
        //                         }}
        //                         variant="outlined"
        //                     />
        //                 )}
        //             </TableCell>

        //             <TableCell sx={{ alignSelf: "center" }}>
        //                 {metalPurity && metalPurity}
        //             </TableCell>
        //             <TableCell sx={{ alignSelf: "center" }}>
        //                 {metalWeight ? (
        //                     <span style={{ display: "flex", margin: "0px" }}>
        //                         <MetalWeightText>Metal: </MetalWeightText>
        //                         <MetalWeightText style={{ marginLeft: "4px" }}>
        //                             {metalWeight}
        //                         </MetalWeightText>
        //                     </span>
        //                 ) : null}
        //                 {diamondWeight ? (
        //                     <span style={{ display: "flex", margin: "0px" }}>
        //                         <MetalWeightText>Diamond: </MetalWeightText>
        //                         <MetalWeightText style={{ marginLeft: "4px" }}>
        //                             {diamondWeight}
        //                         </MetalWeightText>
        //                     </span>
        //                 ) : null}
        //                 {colorstoneWeight ? (
        //                     <span style={{ display: "flex", margin: "0px" }}>
        //                         <MetalWeightText>Gemstone: </MetalWeightText>
        //                         <MetalWeightText style={{ marginLeft: "4px" }}>
        //                             {colorstoneWeight}
        //                         </MetalWeightText>
        //                     </span>
        //                 ) : null}
        //             </TableCell>
        //             <TableCell align="center">
        //                 {/* <IconButton
        //                 aria-label="delete"
        //                 size="large"
        //                 onClick={(e) => {
        //                     e.stopPropagation();
        //                     setShowActions(e.currentTarget);
        //                 }}
        //             >
        //                 <MoreVert />
        //             </IconButton> */}
        //             </TableCell>
        //         </>
        //     ) : null}
        // </TableRow>
    );
};
