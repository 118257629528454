import {
  Box,
  CircularProgress,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { WebchatSvg } from "../../../../Svgs";
import { useSelector } from "react-redux";
import { TabPanel } from "../../../../helper";
import Integration from "./Integration";

function Webchat() {
  const [selectedTab, setSelectedTab] = useState("integration");
  const fetchingAllSettings = useSelector(
    (state) => state.settings.fetchingAllSettings
  );
  return (
    <Box>
      <Stack
        sx={{
          position: "sticky",
          backgroundColor: "#fff",
          zIndex: 13,
          margin: 0,
          padding: "12px",
          top: "0",
        }}
      >
        <Stack
          direction="row"
          justifyContent={"space-between"}
          alignItems="center"
        >
          <Typography
            variant="h3"
            sx={{ display: "flex", alignItems: "center", gap: "10px" }}
          >
            <WebchatSvg /> Webchat
          </Typography>

          {fetchingAllSettings && (
            <Stack direction={"row"} alignItems="center" sx={{ gap: "10px" }}>
              <CircularProgress size={"15px"} />{" "}
              <Typography>Fetching Integrations...</Typography>
            </Stack>
          )}
        </Stack>
        {/* <Divider sx={{ mt: 4 }} /> */}
        <Tabs
          onChange={(e, newValue) => setSelectedTab(newValue)}
          value={selectedTab}
          sx={{ borderBottom: 1, borderColor: "divider" }}
        >
          <Tab label="Overview" value="overview" />
          {/* <Tab label="How to do?" value="how_to_do" /> */}
          {/* <Tab label="Details" value="details" /> */}
          <Tab label="Integration" value="integration" />
          {/* {onlyChats ||
              !isArrayWithValues(
                integratedApp?.filter((i) =>
                  [
                    "google_business",
                    "tag_manager",
                    "google_shopping",
                    "google_analytics",
                    "firebase",
                    "google_sheets",
                  ].includes(i)
                )
              ) ? null : (
              <Tab label="Settings" value="settings" />
            )} */}
        </Tabs>

        <TabPanel value={selectedTab} index={"integration"}>
          <Integration />
        </TabPanel>
      </Stack>
    </Box>
  );
}

export default Webchat;
