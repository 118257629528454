import * as React from "react";
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Facebook from "./facebook";
import { IntegrationIllustration, SettingsIllustration } from "../../Svgs";
import { UnderConstructionComponent } from "../design_bank/DesignBank";
import Instagram from "./instagram";
import { setHubData } from "../../redux/actions/hubActions";
import { useDispatch, useSelector } from "react-redux";
import {
  getAxiosError,
  getIntegrationObj,
  isArrayWithValues,
  isObjWithValues,
  secondaryLabel,
} from "../../helper";
import axios from "axios";
import OnesignalHub from "./onesingnal/Index";

export default function Hub() {
  const [facebookAccessToken, setFacebookAccessToken] = useState("");
  const [value, setValue] = React.useState("");
  const [tabs, setTabs] = useState([]);
  const [selectedPageAccessToken, setSelectedPageAccessToken] = useState("");
  const [onesignalIntegrated, setOnesignalIntegrated] = useState(false);
  const [facebookUserInfo, setFacebookUserInfo] = useState({});
  const [isEmpty, setIsEmpty] = useState(false);
  const facebookIntegration = useSelector(
    (state) => state.settings.facebookIntegration
  );
  const facebookInfo = useSelector((state) => state.settings.facebookInfo);
  const facebookPages = useSelector((state) => state.hub.facebookPages);
  const reduxOnesignalIntegration = useSelector(
    (state) => state.settings.onesignalIntegration
  );
  const reduxMetaIntegration = useSelector(
    (state) => state.settings.metaIntegration
  );
  const allSettingsFetched = useSelector(
    (state) => state.settings.allSettingsFetched
  );

  useEffect(() => {
    let newTabs = [...tabs];
    let atLeastOneIntegration = false;
    if (
      isObjWithValues(getIntegrationObj(reduxMetaIntegration, "instagram")?.obj)
    ) {
      newTabs.push("Instagram");
      setTabs((pre) => [...new Set(["Instagram", ...pre])]);
      atLeastOneIntegration = true;
    }
    if (
      isObjWithValues(getIntegrationObj(reduxMetaIntegration, "facebook")?.obj)
    ) {
      newTabs.push("Facebook");
      setTabs((pre) => [...new Set(["Facebook", ...pre])]);
      atLeastOneIntegration = true;
    }

    if (isObjWithValues(reduxOnesignalIntegration?.selectedAccount)) {
      setTabs((pre) => [...new Set([...pre, "Onesignal"])]);
      setOnesignalIntegrated(true);
      // let newTabs = [...tabs]
      atLeastOneIntegration = true;
    }
    if (!atLeastOneIntegration && allSettingsFetched) setIsEmpty(true);
  }, [reduxMetaIntegration, reduxOnesignalIntegration, allSettingsFetched]);

  useEffect(() => {
    let newTabs = new Set(tabs);
    console.log([...newTabs]);
    if (isArrayWithValues(tabs)) setValue(tabs[0]);
  }, [tabs]);

  useEffect(() => {
    if (isArrayWithValues(facebookPages)) {
      if (facebookIntegration && facebookIntegration.page) {
        let { page } = facebookIntegration;
        setSelectedPageAccessToken(page.access_token);
      }
    }
  }, [facebookPages, facebookIntegration]);

  const dispatch = useDispatch();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // useEffect(() => {
  //   if (Array.isArray(facebookPages)) setPages(facebookPages);
  // }, []);

  useEffect(() => {
    if (facebookInfo) setFacebookUserInfo(facebookInfo);
  }, [facebookInfo]);

  useEffect(() => {
    if (facebookIntegration && facebookIntegration.access_token) {
      setFacebookAccessToken(facebookIntegration.access_token);
      if (!isObjWithValues(facebookInfo))
        getFacebookUserInfo(facebookIntegration.access_token);
    }
    // else setNotIntegrated(true);
  }, [facebookIntegration]);

  const getFacebookUserInfo = async (facebookAccessToken) => {
    let info = await fetchFacebookInfo(facebookAccessToken);
    dispatch(setHubData({ facebookInfo: info }));
  };

  if (isEmpty) {
    return (
      <Box sx={{ position: "relative", height: "100%" }}>
        <Box
          sx={{
            textAlign: "center",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <IntegrationIllustration />
          <Typography sx={{ ...secondaryLabel, fontSize: "18px", mt: 3 }}>
            No Social Connected Yet
          </Typography>
        </Box>
      </Box>
    );
  }

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          {tabs.map((tab, index) => (
            <Tab label={tab} {...a11yProps(index)} value={tab} />
          ))}

          {/* {getIntegrationObj(reduxMetaIntegration, "facebook")?.obj
            ?.facebook_pages_info ? (
            <Tab label="Facebook" {...a11yProps(1)} value={"Facebook"} />
          ) : null}
          {isObjWithValues(
            getIntegrationObj(reduxMetaIntegration, "instagram")?.obj
          ) ? (
            <Tab label="Instagram" {...a11yProps(1)} value={"Instagram"} />
          ) : null} */}
          {/* <Tab label="Google" {...a11yProps(2)} />
          <Tab label="Linkedin" {...a11yProps(2)} /> */}
          {/* {onesignalIntegrated && (
            <Tab label="Onesignal" value={"Onesignal"} {...a11yProps(0)} />
          )} */}
        </Tabs>
      </Box>
      {/* <UnderConstructionComponent /> */}
      <TabPanel value={value} index={"Facebook"}>
        <Facebook />
      </TabPanel>
      <TabPanel value={value} index={"Instagram"}>
        <Instagram
          facebookUserInfo={facebookUserInfo}
          selectedPageAccessToken={selectedPageAccessToken}
        />
      </TabPanel>
      <TabPanel value={value} index={2}>
        Comming soon!
      </TabPanel>
      <TabPanel value={value} index={3}>
        Comming soon!
      </TabPanel>
      <TabPanel value={value} index={"Onesignal"}>
        <OnesignalHub />
      </TabPanel>
    </Box>
  );
}

const fetchFacebookInfo = async (accessToken) => {
  try {
    let res = await axios({
      url: `https://graph.facebook.com/v8.0/me?fields=id,name,email,picture&access_token=${accessToken}`,
    });
    return res.data;
  } catch (error) {
    console.log(getAxiosError(error));
  }
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{}}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
