import { Box, Stack, Tab, Tabs, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { TabPanel } from '../../../../helper'
import Integration from './Integration'
import Settings from './Settings'

const Jemisys = () => {
    const [selectedTab, setSelectedTab] = useState("integration")
    const jemisysIntegration = useSelector(state => state.settings.jemisysIntegration)
    return (
        <Box sx={{ height: "100%", paddingRight: "15px" }}>
            <Stack
                sx={{
                    position: "sticky",
                    top: "0",
                    backgroundColor: "#fff",
                    zIndex: 13,
                    margin: 0,
                    padding: "12px",
                }}
            >
                <Stack
                    direction="row"
                    justifyContent={"space-between"}
                    alignItems="center"
                >
                    <Typography
                        variant="h3"
                        sx={{ display: "flex", alignItems: "center", gap: "10px" }}
                    >
                        {" "}
                        <img
                            src="/static/img/brands/jemisysLogo.png"
                            width="auto"
                            height="24px"
                        // sx={{}}
                        />{" "}
                        JEMiSys
                    </Typography>
                    {/* {fetchingMetaIntegration && <Stack direction={"row"} alignItems="center" sx={{ gap: "10px" }}>
                        <CircularProgress size={"15px"} /> <Typography>Fetching Integrations...</Typography>
                    </Stack>} */}
                </Stack>
                {/* <Divider sx={{ mt: 4 }} /> */}
                <Tabs
                    onChange={(e, newValue) => setSelectedTab(newValue)}
                    value={selectedTab}
                    sx={{ borderBottom: 1, borderColor: "divider" }}
                >
                    {/* <Tab label="Overview" value="overview" /> */}
                    {/* <Tab label="How to do?" value="how_to_do" />
                    <Tab label="Details" value="details" /> */}
                    <Tab label="Integration" value="integration" />
                    {/* <Tab label="Products" value="settings" /> */}
                    {jemisysIntegration?.integrated && [<Tab label="Settings" value="settings" />]}
                </Tabs>
            </Stack>
            <TabPanel value={selectedTab} index={"overview"}>
                {/* <Typography><Overview /></Typography> */}
            </TabPanel>
            <TabPanel value={selectedTab} index={"how_to_do"}>
                <Typography>How to do</Typography>
            </TabPanel>
            <TabPanel value={selectedTab} index={"details"}>
                <Typography>Details</Typography>
            </TabPanel>
            <TabPanel value={selectedTab} index={"integration"}>
                <Integration />
            </TabPanel>
            <TabPanel value={selectedTab} index={"settings"}>
                <Settings />
            </TabPanel>
        </Box>
    )
}

export default Jemisys