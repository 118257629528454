import { Box, Stack, Tooltip, Typography } from "@mui/material";
import React from "react";
import RedialBarMeter from "../../components/RedialBarMeter";
import {
  formatNumber,
  primaryLabel,
  secondaryLabel,
  secondaryLabelSmall,
  validateNumber,
} from "../../../helper";

function OpportunityLeadScore({ score, topScore, leadScoreUI = "1" }) {
  return (
    <Stack direction="row" alignItems="center" spacing={2}>
      <Tooltip title={""}>
        <div>
          <RedialBarMeter
            series={[
              Math.round((validateNumber(score) / topScore) * 100).toFixed(0) ==
              100
                ? 99
                : Math.round((validateNumber(score) / topScore) * 100).toFixed(
                    0
                  ),
            ]}
          />
          <Typography
            sx={{
              ...secondaryLabelSmall,
              textAlign: "center",
              fontSize: "10px",
              marginTop: "1px",
            }}
          >
            Opportunity
          </Typography>
        </div>
      </Tooltip>
      {leadScoreUI === "1" ? (
        <Box sx={{ textAlign: "center" }}>
          <Typography
            sx={{
              ...primaryLabel,
              fontSize: `${score}`?.length > 5 ? "10px" : "12px",
            }}
          >
            {validateNumber(score)}
          </Typography>
          <Typography sx={{ ...secondaryLabelSmall }}>Lead Score</Typography>
        </Box>
      ) : (
        (leadScoreUI = "2" ? (
          <Box sx={{}}>
            <Typography
              sx={{
                ...secondaryLabel,
                fontSize: `${score}`?.length > 5 ? "10px" : "12px",
              }}
            >
              Lead Score
            </Typography>
            <Typography variant="h6" fontWeight={400}>
              {validateNumber(score)}
            </Typography>
          </Box>
        ) : null)
      )}
      {/* <Typography sx={{ ...secondaryLabelSmall }}>Lead Score</Typography>
        <Typography sx={{ ...primaryLabel }}>
          {formatNumber(validateNumber(score))}
        </Typography> */}
    </Stack>
  );
}

export default OpportunityLeadScore;
