import { Laptop, PhoneIphone } from "@material-ui/icons";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  deleteDashboardSession,
  dialogStyle,
  formatDate,
  getRelativeTimeDifference,
  getUAInfo,
  isObjWithValues,
  primaryLabelSmall,
  secondaryLabel,
  secondaryLabelSmall,
  terminateDashboardSession,
} from "../../helper";
import useAuth from "../../hooks/useAuth";
import {
  AccessDenied,
  Android,
  Apple,
  UpgradeIllustration,
  Windows,
} from "../../Svgs";
import { primaryLabel } from "../chat/ChatHelper";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DeviceLoginExceed = forwardRef((props, ref) => {
  const [expiryOn, setExpiryOn] = useState("");
  const [open, setOpen] = React.useState(false);
  const [isExpired, setIsExpired] = useState(true);
  const dashboardSessions = useSelector(
    (state) => state.user.dashboard_sessions
  );
  const [showTerminate, setShowTerminate] = useState(false);
  const [terminating, setTerminating] = useState(false);
  const [sessions, setSessions] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { signOut } = useAuth();
  const user_id = useSelector((state) => state.user.id);

  useImperativeHandle(ref, () => {
    return {
      open() {
        setOpen(true);
      },
    };
  });

  useEffect(() => {
    if (isObjWithValues(dashboardSessions)) {
      setupSessions(dashboardSessions);
    }
  }, [dashboardSessions]);

  const setupSessions = (dashboardSessions) => {
    let array = [];
    for (let obj of Object.values(dashboardSessions)) {
      let { ua } = obj;
      let uaInfo = getUAInfo(ua);

      let info = { uaInfo, ...obj, oldObj: obj };
      if (info.user_id == user_id && !info.terminated) array.push(info);
    }
    setSessions(array);
  };

  const handleClose = () => {
    if (!isExpired) setOpen(false);

    dispatch({
      type: "SET_USER",
      payload: {
        expiryWarningShowed: true,
      },
    });
  };

  const logout = () => {
    setOpen(false);
    signOut();
  };

  const closeTerminate = () => {
    setShowTerminate(false);
  };

  const onTerminate = async () => {
    setTerminating(true);
    let data = await terminateDashboardSession({
      ...showTerminate,
      terminated: true,
    });
    if (data?.success) setupSessions(data.data);
    setTerminating(false);
    setShowTerminate();
    setIsExpired(false);
  };
  return (
    <Box>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        fullWidth
        maxWidth="sm"
        sx={{ ...dialogStyle }}
      >
        <DialogContent>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <AccessDenied height="120px" width="120px" />
          </Box>

          <Typography sx={{ ...primaryLabel, textAlign: "center", mt: 3 }}>
            Your device login limit is reached.
          </Typography>

          <TableContainer>
            <Table sx={{}} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Location</TableCell>
                  <TableCell align="right">Device</TableCell>
                  <TableCell align="right">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sessions.map((row) => (
                  <TableRow
                    key={row.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <Typography sx={{ ...primaryLabelSmall }}>
                        {row?.location?.city ? `${row?.location?.city},` : ""}
                        {row?.location?.region
                          ? ` ${row?.location?.region}, `
                          : ""}
                        {row?.location?.country || ""}
                      </Typography>
                      <Typography sx={{ ...secondaryLabelSmall, mt: 1 }}>
                        {formatDate(
                          new Date(row?.time),
                          "dd:mm:yyyy hh:mm am/pm"
                        )}
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Stack
                        direction="row"
                        justifyContent={"flex-end"}
                        spacing={2}
                      >
                        {row?.uaInfo?.os_name === "Mac OS" ? (
                          <Apple style={{ color: "#666666" }} />
                        ) : row?.uaInfo?.os_name === "Windows" ? (
                          <Windows style={{ color: "#666666" }} />
                        ) : row?.uaInfo?.os_name === "Android" ? (
                          <Android style={{ color: "#666666" }} />
                        ) : null}
                        {row?.uaInfo?.os_name === "Mac OS" ||
                        row?.uaInfo?.os_name === "Windows" ? (
                          <Laptop width="20px" height="20px" />
                        ) : (
                          <PhoneIphone />
                        )}
                      </Stack>
                    </TableCell>
                    <TableCell align="right">
                      <Button
                        color="error"
                        onClick={() => setShowTerminate(row.oldObj)}
                      >
                        Terminate
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Dialog
            open={Boolean(showTerminate)}
            onClose={closeTerminate}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
            sx={{ ...dialogStyle }}
            maxWidth="xs"
          >
            <DialogTitle id="alert-dialog-title">
              {"Terminate session?"}
            </DialogTitle>
            {/* <DialogContent></DialogContent> */}
            <DialogActions>
              <Button onClick={closeTerminate}>Cancel</Button>
              <LoadingButton
                loading={terminating}
                onClick={onTerminate}
                color="error"
                autoFocus
              >
                Terminate
              </LoadingButton>
            </DialogActions>
          </Dialog>
        </DialogContent>
        <DialogActions>
          {!isExpired && (
            <Button variant="contained" onClick={handleClose}>
              Done
            </Button>
          )}
          {isExpired && (
            <Button variant="contained" color="error" onClick={logout}>
              Logout
            </Button>
          )}
          {/* <Button
            color="primary"
            onClick={() => {
              // handleClose();
              setOpen(false);
              dispatch({
                type: "SET_USER",
                payload: {
                  expiryWarningShowed: true,
                },
              });
              navigate("/pricing");
            }}
            variant="contained"
          >
            Upgrade
          </Button> */}
        </DialogActions>
      </Dialog>
    </Box>
  );
});

export default DeviceLoginExceed;
