import { Box, Stack, Tab, Tabs, Typography } from '@mui/material'
import React, { useState } from 'react'
import { TabPanel, isOurWebsite } from '../../../../helper';
import Integration from './Integration';
import { SmsOutlined } from '@mui/icons-material';
// import Templates from './Templates';
// import Activities from './Activites';
import { ListItem } from '../meta/MetaOverview';
import { Msg91Svg } from '../../../../Svgs';
// import { Link } from 'react-router-dom';

const Msg91 = () => {
    const [selectedTab, setSelectedTab] = useState("overview");
    return (
        <Box sx={{ height: "100%", paddingRight: "15px" }}>
            <Stack
                sx={{
                    position: "sticky",
                    top: "0",
                    backgroundColor: "#fff",
                    zIndex: 13,
                    margin: 0,
                    padding: "12px",
                }}
            >
                <Stack
                    direction="row"
                    justifyContent={"space-between"}
                    alignItems="center"
                >
                    <Typography
                        variant="h3"
                        sx={{ display: "flex", alignItems: "center", gap: "10px" }}
                    >
                        <Msg91Svg height={"40px"} width={"40px"} />
                        {" "}
                        MSG91
                    </Typography>
                </Stack>
                <Tabs
                    onChange={(e, newValue) => setSelectedTab(newValue)}
                    value={selectedTab}
                    sx={{ borderBottom: 1, borderColor: "divider" }}
                >
                    <Tab label="Overview" value="overview" />
                    <Tab label="How to Use" value="how_to_use" />
                    <Tab label="Integration" value="integration" />
                    {/* <Tab label="Templates" value="template" />
                        <Tab label="Activities" value="activities" /> */}

                </Tabs>
            </Stack>
            <TabPanel value={selectedTab} index={"overview"}>
                <Overview />
            </TabPanel>
            <TabPanel value={selectedTab} index={"how_to_use"}>
                <HowToUse />
            </TabPanel>
            <TabPanel value={selectedTab} index={"integration"}>
                <Integration />
            </TabPanel>
            <TabPanel value={selectedTab} index={"template"}>
                {/* <Templates /> */}
            </TabPanel>
            <TabPanel value={selectedTab} index={"activities"}>
                {/* <Activities /> */}
            </TabPanel>

        </Box>
    )
}

export default Msg91

const featureArray = [
    "✅ Send and Receive messages",
    "✅ Send Personalised Transactional, Promotional and OTP messages",
    "✅ Send message to an individual contact from Jwero CRM",
    "✅ Send SMS campaign from Campaign module",
    "✅ Get Sent, delivery & Failed count"
]

const Overview = () => {
    return <Box p={5}>
        <Typography variant="subtitle1" sx={{ fontSize: "16px" }}>
            MSG 91 is a leading communication solution provider including SMS, Email, voice etc.<br />  Jwero has integrated its SMS solution for you.
        </Typography>
        <Box sx={{ mt: 4 }}>
            <Typography variant="h5">With SMS Integration, you will be able to :</Typography>
            <ListItem arr={featureArray} />
            <Typography variant="h5" sx={{ mt: 6 }}>
                Pre-requisite{" "}Before using the SMS service for your business, you need to :
            </Typography>
            <ListItem
                arr={[
                    "✅ Register your business entity for DLT",
                    "✅ Apply for Header",
                    "✅ Apply for Consent message",
                    "✅ Apply for message Template message"
                ]}
            />
            {/* Jweo - Exotel Advantage
        Direct Dialing from CRM
        Call Log in respective CRM account
        Team member based call assign */}
        </Box>
    </Box>
}


const HowToUse = () => {
    return <Box p={5}>
        {/* <div style={{ }}> */}
        <Typography variant="h5" >
            Register your Business Entity for DLT Compliance
        </Typography>
        <Typography variant="subtitle2" sx={{ mt: 2 }}>
            To comply with India's new regulations for SMS messaging, it is now mandatory to register your business entity on a DLT (Distributed Ledger Technology) platform. This process involves creating a unique sender ID and registering your organization with the DLT provider.
        </Typography>
        <Typography variant="subtitle2" sx={{ mt: 2 }}>
            We recommend using the Jio DLT platform as it is one of the approved providers by the Telecom Regulatory Authority of India (TRAI). To register your business entity on Jio DLT, please follow these steps:
        </Typography>
        <ListItem
            arr={[
                "✅ Login to the Jio DLT portal using your credentials.",
                `✅ Click on the "Entity Registration" tab.`,
                "✅ Enter the required information about your organization, including your GSTIN number and PAN card details.",
                "✅ Upload the necessary documents to verify your organization's identity.",
                "✅ Once your registration is approved, you can create your unique sender ID and register your SMS templates."
            ]}
        />

        {/* <ol>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
        </ol> */}
        <Typography variant="body1" gutterBottom>
            Please note that DLT registration is mandatory for both promotional and transactional SMS messages, and failure to comply may result in your SMS messages being blocked.
        </Typography>
        <a href="https://msg91.com/help/entity-registration-on-dlt-platform#:~:text=Login%20to%20the%20Jio%20DLT,and%20Promotional%20for%20Promotional%20SMS." target='_black'>Entity Registration on DLT Platform</a>

        {/* </div> */}
    </Box>
}
