import React, { useImperativeHandle, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { Box } from "@mui/material";
import { LoadingButton } from "@mui/lab";

const CustomDialog = React.forwardRef((props, ref) => {
  const [open, setOpen] = useState(false);
  const {
    onClose,
    title,
    content,
    children,
    submitButtonProps,
    ...otherProps
  } = props;

  useImperativeHandle(ref, () => {
    return {
      open: function () {
        setOpen(true);
      },
      close: () => {
        setOpen(false);
      },
    };
  });

  const onClosePress = () => {
    setOpen(false);
    onClose && onClose();
  };

  return (
    <Box>
      <Dialog
        open={open}
        onClose={onClosePress}
        fullWidth
        maxWidth="md"
        {...otherProps}
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          {/* <DialogContentText>{content}</DialogContentText> */}
          {children}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClosePress} color="primary">
            Close
          </Button>
          <LoadingButton
            variant="contained"
            color="primary"
            {...submitButtonProps}
          >
            Submit
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </Box>
  );
});

export default CustomDialog;
