import { CircleNotifications, Lock } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Card,
  Collapse,
  Divider,
  Grid,
  InputAdornment,
  MenuItem,
  Select,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import { useState } from "react";
import axios from "axios";
import {
  formatDate,
  getToken,
  getWebsite,
  isArrayWithValues,
  isObjWithValues,
  settingsEndpoint,
  TabPanel,
} from "../../../../helper";
import { setGlobalToast } from "../../../../redux/actions/helperActions";
import { useDispatch, useSelector } from "react-redux";
import { EDIT_SETTINGS } from "../../../../redux/actions/settingActions";
import { useEffect } from "react";
import Switch from "rc-switch";
import { PhoneSvg } from "../../../../Svgs";
import { currencySymbols } from "../../../pricing/Gold";

const Onesignal = () => {
  const [selectedTab, setSelectedTab] = useState("overview");
  const [keyInput, setKeyInput] = useState("");
  const [accounts, setAccounts] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState("");
  const [integratedtime, setIntegratedTime] = useState("");
  const [loading, setLoading] = useState(false);
  const [updating, setUpdating] = useState(false);

  const [onesignalState, setOnesignalState] = useState({});
  const [isIntegreated, setIsIntegreated] = useState(false);
  const globalToast = useSelector((state) => state.helper.globalToast);
  const reduxOnesignalIntegration = useSelector(
    (state) => state.settings.onesignalIntegration
  );
  const theme = useTheme();
  // const isLgUp = useMediaQuery(theme.breakpoints.up("lg"));
  // const location = useLocation();
  // let { hash } = location;
  // const isIpad = useMediaQuery(theme.breakpoints.down("md"));
  const isPhone = useMediaQuery(theme.breakpoints.down("sm"));

  const dispatch = useDispatch();
  useEffect(() => {
    setIntegratedTime(reduxOnesignalIntegration?.time || "");
    setOnesignalState(reduxOnesignalIntegration?.selectedAccount || {});
    setIsIntegreated(
      reduxOnesignalIntegration?.selectedAccount?.id &&
      reduxOnesignalIntegration?.selectedAccount?.basic_auth_key
    );
    // if (isObjWithValues(reduxOnesignalIntegration)) {
    //   if (isArrayWithValues(reduxOnesignalIntegration?.accounts)) {
    //     setAccounts(reduxOnesignalIntegration?.accounts || []);
    //   } else {
    //     setAccounts([]);
    //   }
    //   if (reduxOnesignalIntegration?.selectedAccount) {
    //     setSelectedAccount(
    //       reduxOnesignalIntegration?.selectedAccount?.id || ""
    //     );
    //   } else {
    //     setSelectedAccount("");
    //   }
    //   if (reduxOnesignalIntegration?.key) {
    //     setKeyInput(reduxOnesignalIntegration?.key);
    //     setIsIntegreated(true);
    //   } else {
    //     setKeyInput("");
    //     setIsIntegreated(false);
    //   }
    //   if (reduxOnesignalIntegration?.time) {
    //     setIntegratedTime(reduxOnesignalIntegration?.time);
    //   } else {
    //     setIntegratedTime("");
    //   }
    // } else {
    //   setIsIntegreated(false);
    // }
  }, [reduxOnesignalIntegration]);

  // const onVerify = async () => {
  //   let website = getWebsite();
  //   let token = getToken();
  //   if (!website && !token) return false;
  //   setLoading(true);
  //   try {
  //     const accountsRes = await axios({
  //       url: "https://onesignal.com/api/v1/apps",
  //       headers: {
  //         Authorization: `Basic ${keyInput}`,
  //       },
  //     });

  //     if (isArrayWithValues(accountsRes?.data)) {
  //       console.log(accountsRes?.data);
  //       let time = Date.now();
  //       let postAccountRes = await axios({
  //         url: `${website}/wp-json/store/v1/settings/onesignal_integration`,
  //         method: "PUT",
  //         headers: {
  //           Authorization: `Basic ${token}`,
  //         },
  //         data: {
  //           setting: "onesignal_integration",
  //           data: {
  //             accounts: accountsRes?.data,
  //             key: keyInput,
  //             time,
  //           },
  //         },
  //       });
  //       if (postAccountRes.data.success) {
  //         dispatch(
  //           setGlobalToast({
  //             ...globalToast,
  //             show: true,
  //             message: "onesignal account integarted succesfully",
  //             severity: "success",
  //           })
  //         );
  //         dispatch({
  //           type: EDIT_SETTINGS,
  //           payload: {
  //             onesignalIntegration: postAccountRes.data.data,
  //           },
  //         });
  //       }
  //     } else {
  //       dispatch(
  //         setGlobalToast({
  //           ...globalToast,
  //           show: true,
  //           message: "Intregration Failed! Please try again.",
  //           severity: "error",
  //         })
  //       );
  //     }
  //   } catch (error) {
  //     dispatch(
  //       setGlobalToast({
  //         ...globalToast,
  //         show: true,
  //         message: "Intregration Failed! Please try again.",
  //         severity: "error",
  //       })
  //     );
  //   }
  //   setLoading(false);
  // };

  const onEditFrom = (payload) => {
    setOnesignalState((pre) => ({ ...pre, ...payload }));
  };
  const onVerify = async () => {
    let website = getWebsite();
    let token = getToken();
    if (!website && !token) return false;
    setLoading(true);
    try {
      const accountsRes = await axios({
        url: "https://onesignal.com/api/v1/players",
        params: {
          limit: 1,
          app_id: onesignalState?.id,
        },
        headers: {
          Authorization: `Basic ${onesignalState?.basic_auth_key}`,
        },
      });

      console.log(accountsRes?.data);
      if (!isArrayWithValues(accountsRes?.data?.errors)) {
        let time = Date.now();

        let postAccountRes = await settingsEndpoint({
          endpoint: "onesignal_integration",
          method: "PUT",
          data: {
            selectedAccount: onesignalState,
            time,
          },
        });
        // let postAccountRes = await axios({
        //   url: `${website}/wp-json/store/v1/settings/onesignal_integration`,
        //   method: "PUT",
        //   headers: {
        //     Authorization: `Basic ${token}`,
        //   },
        //   data: {
        //     setting: "onesignal_integration",
        //     data: {

        //       accounts: accountsRes?.data,
        //       key: keyInput,
        //       time,
        //     },
        //   },
        // });
        if (postAccountRes) {
          dispatch(
            setGlobalToast({
              ...globalToast,
              show: true,
              message: "onesignal account integarted succesfully",
              severity: "success",
            })
          );
          dispatch({
            type: EDIT_SETTINGS,
            payload: {
              onesignalIntegration: postAccountRes,
            },
          });
        }
      } else {
        dispatch(
          setGlobalToast({
            ...globalToast,
            show: true,
            message: "Intregration Failed! Please try again.",
            severity: "error",
          })
        );
      }
    } catch (error) {
      dispatch(
        setGlobalToast({
          ...globalToast,
          show: true,
          message: "Intregration Failed! Please try again.",
          severity: "error",
        })
      );
    }
    setLoading(false);
  };

  const updateAccount = async () => {
    let website = getWebsite();
    let token = getToken();
    if (!website && !token) return false;
    if (!selectedAccount) return;
    let obj = accounts.find((o) => o.id === selectedAccount);
    setUpdating(true);
    let postAccountRes = await axios({
      url: `${website}/wp-json/store/v1/settings/onesignal_integration`,
      method: "PUT",
      headers: {
        Authorization: `Basic ${token}`,
      },
      data: {
        setting: "onesignal_integration",
        data: {
          selectedAccount: obj,
        },
      },
    });
    if (postAccountRes.data.success) {
      dispatch(
        setGlobalToast({
          ...globalToast,
          show: true,
          message: "onesignal Account selected succesfully",
          severity: "success",
        })
      );
      dispatch({
        type: EDIT_SETTINGS,
        payload: {
          onesignalIntegration: postAccountRes.data.data,
        },
      });
    } else {
      dispatch(
        setGlobalToast({
          ...globalToast,
          show: true,
          message: "Invaild auth key!",
          severity: "error",
        })
      );
    }
    setUpdating(false);
  };
  const onRevoke = async () => {
    let website = getWebsite();
    let token = getToken();
    if (!website && !token) return false;
    setLoading(true);
    let postAccountRes = await axios({
      url: `${website}/wp-json/store/v1/settings/onesignal_integration`,
      method: "PUT",
      headers: {
        Authorization: `Basic ${token}`,
      },
      data: {
        setting: "onesignal_integration",
        // id: "selectedAccount",
        data: {
          selectedAccount: {},
          time: "",
          accounts: [],
          key: "",
        },
      },
    });

    if (postAccountRes?.data?.success) {
      dispatch(
        setGlobalToast({
          ...globalToast,
          show: true,
          message: "Revoke succusfully",
          severity: "success",
        })
      );
      dispatch({
        type: EDIT_SETTINGS,
        payload: {
          onesignalIntegration: postAccountRes?.data?.data,
        },
      });
      // setOnesignalState({})
    } else {
      dispatch(
        setGlobalToast({
          ...globalToast,
          show: true,
          message: "Revoke Failed",
          severity: "error",
        })
      );
    }
    setLoading(false);
  };
  return (
    <Box sx={{ height: "100%", paddingRight: "15px" }}>
      <Stack
        sx={{
          position: "sticky",
          top: "0",
          backgroundColor: "#fff",
          zIndex: 13,
          margin: 0,
          padding: "12px",
        }}
      >
        <Stack
          direction="row"
          justifyContent={"space-between"}
          alignItems="center"
        >
          <Typography
            variant="h3"
            sx={{ display: "flex", alignItems: "center", gap: "10px" }}
          >
            {" "}
            <img
              src="/static/img/brands/onesignal-circle.png"
              width="20px"
              height="20px"
            />{" "}
            Onesignal
          </Typography>
          {/* {fetchingMetaIntegration && <Stack direction={"row"} alignItems="center" sx={{ gap: "10px" }}>
                        <CircularProgress size={"15px"} /> <Typography>Fetching Integrations...</Typography>
                    </Stack>} */}
        </Stack>
        {/* <Divider sx={{ mt: 4 }} /> */}
        <Tabs
          onChange={(e, newValue) => setSelectedTab(newValue)}
          value={selectedTab}
          sx={{ borderBottom: 1, borderColor: "divider" }}
        >
          <Tab label="Overview" value="overview" />
          {/* <Tab label="How to do?" value="how_to_do" />
                    <Tab label="Details" value="details" /> */}
          <Tab label="Integration" value="integration" />
          <Tab label="Settings" value="settings" />
        </Tabs>
      </Stack>
      <TabPanel value={selectedTab} index={"overview"}>
        <Box sx={{ padding: "16px" }}>
          <Typography variant="subtitle1" sx={{ fontSize: "15px" }}>
            Onesignal is a tool that allows you to create push and in-app
            notifications for your jewelry business to improve your customer
            engagement and retention.
          </Typography>
          <Typography variant="subtitle1" sx={{ fontSize: "15px", mt: 2 }}>
            Uses include:
          </Typography>
          <Typography variant="subtitle1" sx={{ fontSize: "15px", mt: 2 }}>
            1. Push alerts can assist you in gaining additional clientele.{" "}
            <br />
            2. They encourage your audience to interact with your company on a
            regular basis even if they are not aware of it.
          </Typography>
          <Typography variant="subtitle2" sx={{ fontSize: "15px", mt: 2 }}>
            Benefits
          </Typography>
          <Stack>
            <Typography variant="subtitle2" sx={{ fontSize: "15px", mt: 2 }}>
              1. Re-engage and keep users
            </Typography>
            <Typography variant="subtitle1" sx={{ fontSize: "15px", mt: 2 }}>
              The dormant users can become active by receiving push alerts.
              Individuals who choose to get mobile notifications have retention
              rates that are generally substantially higher. You have a
              significantly better chance of keeping people if your mobile
              alerts can demonstrate direct personal value.
            </Typography>
          </Stack>
          <Stack>
            <Typography variant="subtitle2" sx={{ fontSize: "15px", mt: 2 }}>
              2. High conversion rates
            </Typography>
            <Typography variant="subtitle1" sx={{ fontSize: "15px", mt: 2 }}>
              The easiest strategy to raise conversion rates is to send out
              timely marketing messages that might alert customers or evoke a
              sense of urgency.
            </Typography>
          </Stack>
          <Stack>
            <Typography variant="subtitle2" sx={{ fontSize: "15px", mt: 2 }}>
              3. Choose the proper audience to target
            </Typography>
            <Typography variant="subtitle1" sx={{ fontSize: "15px", mt: 2 }}>
              Mobile applications may request a user's consent before accessing
              their location. Based on the user's time zone, a mobile app can
              deliver them deals that are relevant to their location to
              customise location-specific push alerts.
            </Typography>
          </Stack>
        </Box>
      </TabPanel>
      <TabPanel value={selectedTab} index={"how_to_do"}>
        <Typography>How to do</Typography>
      </TabPanel>
      <TabPanel value={selectedTab} index={"details"}>
        <Typography>Details</Typography>
      </TabPanel>
      <TabPanel value={selectedTab} index={"integration"}>
        {false && (
          <Box sx={{ padding: "12px 18px" }}>
            <Stack direction={"row"} alignItems={"center"} sx={{ gap: "20px" }}>
              <Typography
                variant="h6"
              // fontSize={"13px"}
              // mt={3}
              >
                Onesignal Auth Key
              </Typography>
            </Stack>
            <Stack
              direction={"row"}
              alignItems="center"
              sx={{ gap: "20px", mt: 2 }}
            >
              <TextField
                value={keyInput}
                onChange={(e) => setKeyInput(e.target.value)}
                // variant="outlined"
                size="small"
                fullWidth
                sx={{ maxWidth: isPhone ? "100%" : "450px" }}
                disabled={isIntegreated}
                InputProps={
                  isIntegreated
                    ? {
                      endAdornment: (
                        <InputAdornment position="start">
                          <Lock />
                        </InputAdornment>
                      ),
                    }
                    : null
                }
              />

              {!isIntegreated ? (
                <LoadingButton
                  size="small"
                  loading={loading}
                  disabled={!keyInput}
                  variant="contained"
                  onClick={onVerify}
                >
                  Integrate
                </LoadingButton>
              ) : null}
              {isIntegreated ? (
                <LoadingButton
                  size="small"
                  color="error"
                  loading={loading}
                  variant="contained"
                  onClick={onRevoke}
                >
                  Revoke
                </LoadingButton>
              ) : null}
            </Stack>
            {integratedtime ? (
              <Typography sx={{ mt: 2 }}>
                {" "}
                Integrated on{" "}
                {formatDate(new Date(integratedtime), "relative_date")}
              </Typography>
            ) : null}

            {isIntegreated ? (
              <>
                {" "}
                <Divider sx={{ mt: 4, mb: 4 }} />
                <Typography variant="h6">Select Account</Typography>
                <Stack
                  direction={"row"}
                  sx={{ gap: "25px", mt: 2 }}
                  alignItems={"center"}
                >
                  <Select
                    size="small"
                    value={selectedAccount}
                    fullWidth
                    sx={{ maxWidth: isPhone ? "100%" : "250px" }}
                    onChange={(e) => setSelectedAccount(e.target.value)}
                  >
                    {accounts.map((obj) => (
                      <MenuItem value={obj?.id}>{obj?.name}</MenuItem>
                    ))}
                  </Select>
                  <LoadingButton
                    disabled={!selectedAccount}
                    variant="contained"
                    size="small"
                    loading={updating}
                    onClick={updateAccount}
                  >
                    Update
                  </LoadingButton>
                </Stack>
              </>
            ) : null}
          </Box>
        )}
        <Box sx={{ padding: "12px 18px" }}>
          <Stack gap={4} sx={{ maxWidth: "450px" }}>
            <Stack>
              <Stack
                direction={"row"}
                alignItems={"center"}
                sx={{ gap: "20px" }}
              >
                <Typography
                  variant="h6"
                // fontSize={"13px"}
                // mt={3}
                >
                  App ID
                </Typography>
              </Stack>
              <Stack
                direction={"row"}
                alignItems="center"
                sx={{ gap: "20px", mt: 2 }}
              >
                <TextField
                  value={onesignalState?.id || ""}
                  onChange={(e) => onEditFrom({ id: e.target.value })}
                  // variant="outlined"
                  size="small"
                  fullWidth
                  sx={{ maxWidth: isPhone ? "100%" : "450px" }}
                  disabled={isIntegreated}
                  InputProps={
                    isIntegreated
                      ? {
                        endAdornment: (
                          <InputAdornment position="start">
                            <Lock />
                          </InputAdornment>
                        ),
                      }
                      : null
                  }
                />
              </Stack>
            </Stack>
            <Stack>
              <Stack
                direction={"row"}
                alignItems={"center"}
                sx={{ gap: "20px" }}
              >
                <Typography
                  variant="h6"
                // fontSize={"13px"}
                // mt={3}
                >
                  API Key
                </Typography>
              </Stack>
              <Stack
                direction={"row"}
                alignItems="center"
                sx={{ gap: "20px", mt: 2 }}
              >
                <TextField
                  value={onesignalState?.basic_auth_key || ""}
                  onChange={(e) =>
                    onEditFrom({ basic_auth_key: e.target.value })
                  }
                  // variant="outlined"
                  size="small"
                  fullWidth
                  sx={{ maxWidth: isPhone ? "100%" : "450px" }}
                  disabled={isIntegreated}
                  InputProps={
                    isIntegreated
                      ? {
                        endAdornment: (
                          <InputAdornment position="start">
                            <Lock />
                          </InputAdornment>
                        ),
                      }
                      : null
                  }
                />
              </Stack>
            </Stack>
            <Stack direction={"row"} sx={{ justifyContent: "space-between" }}>
              {/* {integratedtime ? ( */}
              <Typography
              // sx={{ mt: 2 }}
              >
                {integratedtime && (
                  <>
                    Integrated on{" "}
                    {integratedtime &&
                      formatDate(new Date(integratedtime), "relative_date")}
                  </>
                )}
              </Typography>
              {/* ) : null} */}
              {!isIntegreated ? (
                <LoadingButton
                  size="small"
                  loading={loading}
                  disabled={
                    !(onesignalState?.basic_auth_key && onesignalState?.id)
                  }
                  variant="contained"
                  onClick={onVerify}
                >
                  Integrate
                </LoadingButton>
              ) : null}
              {isIntegreated ? (
                <LoadingButton
                  size="small"
                  color="error"
                  loading={loading}
                  variant="contained"
                  onClick={onRevoke}
                >
                  Revoke
                </LoadingButton>
              ) : null}
            </Stack>
          </Stack>
        </Box>
      </TabPanel>
      <TabPanel value={selectedTab} index={"settings"}>
        <OnesignalSettings />
      </TabPanel>
    </Box>
  );
};

export default Onesignal;

const OnesignalSettings = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  let backgroundColor = theme.palette.primary && theme.palette.primary.main;
  const reduxPricing = useSelector((state) => state.settings.masterPricing);
  const isIpad = useMediaQuery(theme.breakpoints.down("md"));
  const reduxDefaultSetting =
    useSelector((state) => state.settings.onesignalIntegration) || {};
  const default_currency = useSelector(
    (state) => state.settings.productSettings
  )?.default_currency;
  console.log();
  console.log(reduxPricing, reduxDefaultSetting);
  const [updating, setUpdating] = useState(false);
  const [notificationContent, setNotificationConetnt] = useState("");
  const [checkMetals, setCheckMetals] = useState({
    gold: [],
    silver: [],
    platinum: [],
  });
  const [settingData, setSettingData] = useState({
    daily_rates: false,
    title: "",
    gold: {
      enabled: false,
      purities: [],
    },
    silver: {
      enabled: false,
      purities: [],
    },
    platinum: {
      enabled: false,
      purities: [],
    },
  });
  useEffect(() => {
    if (isObjWithValues(reduxDefaultSetting))
      setSettingData((pre) => ({ ...pre, ...reduxDefaultSetting }));
  }, [reduxDefaultSetting]);

  useEffect(() => {
    if (
      isObjWithValues(reduxPricing?.gold_pricing) &&
      isObjWithValues(reduxPricing?.silver_pricing) &&
      isObjWithValues(reduxPricing?.platinum_pricing)
    ) {
      let goldPurities =
        reduxPricing?.gold_pricing[default_currency]?.enabled_purities || {};
      let silverPurities =
        reduxPricing?.silver_pricing[default_currency]?.enabled_purities || {};
      let platinumPurities =
        reduxPricing?.platinum_pricing[default_currency]?.enabled_purities ||
        {};
      let enabledGoldPurities = [];
      let enabledSilverPurities = [];
      let enabledPlatinumPurities = [];
      for (const key in goldPurities) {
        if (goldPurities[key]) {
          enabledGoldPurities.push(key);
        }
      }
      for (const key in silverPurities) {
        if (silverPurities[key]) {
          enabledSilverPurities.push(key);
        }
      }
      for (const key in platinumPurities) {
        if (platinumPurities[key]) {
          enabledPlatinumPurities.push(key);
        }
      }
      setCheckMetals({
        gold: enabledGoldPurities,
        silver: enabledSilverPurities,
        platinum: enabledPlatinumPurities,
      });
    }
  }, [reduxPricing, default_currency]);
  const onSettingChange = (payload) => {
    setSettingData((pre) => ({ ...pre, ...payload }));
  };

  // const masterPricing = useSelector(state => state.settings.masterPricing)
  console.log(reduxPricing);
  const onSwitchChange = (e, metal) => {
    let obj = { ...settingData[metal] };
    onSettingChange({ [metal]: { ...obj, enabled: e } });
  };
  const onCheckPurities = (purity, metal) => {
    let obj = { ...settingData[metal] };
    if (obj?.purities.includes(purity))
      obj.purities = obj?.purities.filter((key) => key != purity);
    else obj?.purities.push(purity);
    onSettingChange({ [metal]: { ...obj } });
  };
  const getStr = (onesignalIntegration) => {
    const getPurityRate = (priceObj, purities) => {
      if (!isObjWithValues(priceObj)) return;
      let arr = [];
      for (const key in priceObj) {
        if (purities.includes(key)) {
          arr.push({ key: key, rate: priceObj[key]?.rate });
        }
      }
      return arr;
    };
    const getStr = (arr, label) => {
      let str = "";
      for (const obj of arr) {
        str =
          str +
          `${label} (${obj?.key})  ${obj?.rate}${currencySymbols?.[default_currency]}\n`;
      }
      return str;
    };
    let goldObj = reduxPricing?.gold_pricing[default_currency];
    let silverObj = reduxPricing?.silver_pricing[default_currency];
    let platinumObj = reduxPricing?.platinum_pricing[default_currency];
    console.log(reduxPricing?.gold_pricing, goldObj, default_currency);
    let goldPriceArr = getPurityRate(
      goldObj?.[reduxPricing?.gold_pricing?.type] || [],
      onesignalIntegration?.gold?.purities
    );

    let silverPriceArr = getPurityRate(
      silverObj?.[reduxPricing?.silver_pricing?.type] || [],
      onesignalIntegration?.silver?.purities
    );
    let platinumPriceArr = getPurityRate(
      platinumObj?.[reduxPricing?.platinum_pricing?.type] || [],
      onesignalIntegration?.platinum?.purities
    );
    // let goldPriceArr = []
    // let silverPriceArr = []
    // let platinumPriceArr = []

    console.log(silverPriceArr);

    let finalStr = (
      <>
        {onesignalIntegration?.gold?.enabled ? (
          <>
            Gold
            <br /> {getStr(goldPriceArr, "Gold")}
          </>
        ) : (
          ""
        )}{" "}
        <br />
        {onesignalIntegration?.silver?.enabled ? (
          <>
            {" "}
            Silver <br />
            {getStr(silverPriceArr, "Silver")}
          </>
        ) : (
          ""
        )}
        <br />
        {onesignalIntegration?.platinum?.enabled ? (
          <>
            Platinum <br />
            {getStr(platinumPriceArr, "Platinum")}
          </>
        ) : (
          ""
        )}
        <br />
      </>
    );
    return finalStr;
  };
  useEffect(() => {
    setNotificationConetnt(getStr(settingData));
    console.log(getStr(settingData));
  }, [settingData, checkMetals]);

  const updateAccount = async () => {
    let website = getWebsite();
    let token = getToken();
    if (!website && !token) return false;
    setUpdating(true);
    let postAccountRes = await axios({
      url: `${website}/wp-json/store/v1/settings/onesignal_integration`,
      method: "PUT",
      headers: {
        Authorization: `Basic ${token}`,
      },
      data: {
        setting: "onesignal_integration",
        data: {
          ...settingData,
        },
      },
    });
    if (postAccountRes.data.success) {
      dispatch(
        setGlobalToast({
          show: true,
          message: "onesignal settings updated succesfully",
          severity: "success",
        })
      );
      dispatch({
        type: EDIT_SETTINGS,
        payload: {
          onesignalIntegration: postAccountRes.data.data,
        },
      });
    } else {
      dispatch(
        setGlobalToast({
          show: true,
          message: "Invaild auth key!",
          severity: "error",
        })
      );
    }
    setUpdating(false);
  };
  return (
    <Box sx={{ padding: "16px" }}>
      <Stack direction={"row"} justifyContent="flex-end">
        <LoadingButton
          variant="contained"
          loading={updating}
          onClick={updateAccount}
        >
          Save Changes
        </LoadingButton>
      </Stack>

      <Grid container spacing={5} sx={{ mt: 3 }}>
        <Grid item lg={6} xs={12} md={12} sm={12}>
          <Stack direction={"row"} alignItems="center" sx={{ mb: 2 }}>
            <Typography variant="h6">Daily gold rate notification</Typography>
            <Switch
              onChange={(e) => onSettingChange({ daily_rates: e })}
              checked={settingData?.daily_rates}
              style={
                settingData?.daily_rates
                  ? {
                    marginLeft: "auto",
                    border: `1px solid ${backgroundColor}`,
                    backgroundColor,
                  }
                  : {
                    marginLeft: "auto",
                  }
              }
            />
          </Stack>

          <Stack sx={{ mt: 2 }}>
            <Typography variant="h6">Title</Typography>
            <TextField
              size="small"
              placeholder="Title"
              onChange={(e) => onSettingChange({ title: e.target.value })}
              value={settingData?.title}
            />
          </Stack>
          <Stack sx={{ mt: 5, mb: 5 }}>
            <Stack
              direction={"row"}
              // justifyContent="start"
              sx={{ gap: "20px", mb: 2 }}
            >
              <Typography variant="h6">Gold</Typography>
              <Switch
                onChange={(e) => onSwitchChange(e, "gold")}
                checked={settingData?.gold?.enabled}
                style={
                  settingData?.gold?.enabled
                    ? {
                      marginLeft: "auto",
                      border: `1px solid ${backgroundColor}`,
                      backgroundColor,
                    }
                    : {
                      marginLeft: "auto",
                    }
                }
              />
            </Stack>
            <Collapse in={settingData?.gold?.enabled}>
              <Stack direction={"row"} sx={{ gap: "15px" }} flexWrap={"wrap"}>
                {checkMetals?.gold.map((key) => (
                  <Button
                    size="small"
                    onClick={() => onCheckPurities(key, "gold")}
                    variant={
                      settingData?.gold?.purities.includes(key)
                        ? "contained"
                        : "outlined"
                    }
                  >
                    {key}
                  </Button>
                ))}
              </Stack>
            </Collapse>
          </Stack>

          <Stack sx={{ mt: 5, mb: 5 }}>
            <Stack
              direction={"row"}
              // justifyContent="start"
              sx={{ gap: "20px", mb: 2 }}
            >
              <Typography variant="h6">Silver</Typography>
              <Switch
                onChange={(e) => onSwitchChange(e, "silver")}
                checked={settingData?.silver?.enabled}
                style={
                  settingData?.silver?.enabled
                    ? {
                      marginLeft: "auto",
                      border: `1px solid ${backgroundColor}`,
                      backgroundColor,
                    }
                    : {
                      marginLeft: "auto",
                    }
                }
              />
            </Stack>
            <Collapse in={settingData?.silver?.enabled}>
              <Stack direction={"row"} sx={{ gap: "15px" }} flexWrap={"wrap"}>
                {checkMetals?.silver.map((key) => (
                  <Button
                    size="small"
                    onClick={() => onCheckPurities(key, "silver")}
                    variant={
                      settingData?.silver?.purities.includes(key)
                        ? "contained"
                        : "outlined"
                    }
                  >
                    {key}
                  </Button>
                ))}
              </Stack>
              {/* <Stack>
                <FormGroup>
                  {checkMetals?.silver.map((key) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={() => onCheckPurities(key, "silver")}
                          checked={settingData?.silver?.purities.includes(key)}
                        />
                      }
                      label={key}
                    />
                  ))}
                </FormGroup>
              </Stack> */}
            </Collapse>

            <Stack sx={{ mt: 5, mb: 5 }}>
              <Stack
                direction={"row"}
                // justifyContent="start"
                sx={{ gap: "20px", mb: 2 }}
              >
                <Typography variant="h6">Platinium</Typography>
                <Switch
                  onChange={(e) => onSwitchChange(e, "platinum")}
                  checked={settingData?.platinum?.enabled}
                  style={
                    settingData?.platinum?.enabled
                      ? {
                        marginLeft: "auto",
                        border: `1px solid ${backgroundColor}`,
                        backgroundColor,
                      }
                      : {
                        marginLeft: "auto",
                      }
                  }
                />
              </Stack>
              <Collapse in={settingData?.platinum?.enabled}>
                <Stack direction={"row"} sx={{ gap: "15px" }} flexWrap={"wrap"}>
                  {checkMetals?.platinum.map((key) => (
                    <Button
                      size="small"
                      onClick={() => onCheckPurities(key, "platinum")}
                      variant={
                        settingData?.platinum?.purities.includes(key)
                          ? "contained"
                          : "outlined"
                      }
                    >
                      {key}
                    </Button>
                  ))}
                </Stack>
                {/* <Stack>
                  <FormGroup>
                    {checkMetals?.platinum.map((key) => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={() => onCheckPurities(key, "platinum")}
                            checked={settingData?.platinum?.purities.includes(
                              key
                            )}
                          />
                        }
                        label={key}
                      />
                    ))}
                  </FormGroup>
                </Stack> */}
              </Collapse>
            </Stack>
          </Stack>
        </Grid>
        <Grid
          item
          xs={6}
          sx={isIpad ? { display: "none" } : { position: "relative" }}
        >
          <PhoneSvg height="50vh"></PhoneSvg>
          <Card
            sx={{
              position: "absolute",
              top: "60%",
              width: "70%",
              left: "231px",
              transform: "translateX(-50%)",
              padding: "10px",
              boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
              borderRadius: "18px",
              overflow: "hidden",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Stack direction={"row"} justifyContent="space-between">
              <Stack direction={"row"} alignItems="center" sx={{ gap: "4px" }}>
                <CircleNotifications />
                <Typography variant="subtitle2">
                  {settingData?.title}
                </Typography>
              </Stack>
              <Typography>now</Typography>
            </Stack>
            <Stack>
              {/* <Typography>{showNotificationAnalytics?.global_image}</Typography> */}
              {/* <Typography>{notificationContent}</Typography> */}
              {notificationContent}
            </Stack>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};
