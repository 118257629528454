export const SET_MAIN_SOCKET = "SET_MAIN_SOCKET";

export const setMainSocket = (socket) => {
  return (dispatch) => {
    dispatch({
      type: SET_MAIN_SOCKET,
      payload: socket,
    });
  };
};
