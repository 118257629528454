import { useState, useRef } from "react";
import { TextField, InputAdornment, IconButton, Box } from "@mui/material";
import ButtonPopover from "../../components/ButtonPopover";
import PropTypes from "prop-types";
import DeleteAndSwap from "../../components/DeleteAndSwap";
import AddIcon from "@mui/icons-material/Add";
import DataObjectIcon from "@mui/icons-material/DataObject";
import InsertEmoticonIcon from "@mui/icons-material/InsertEmoticon";
import TextFieldAttributes from "../helper/TextFieldAttributes";
import { getTimeInMilliSeconds } from "jwero-javascript-utils";
import EmojiPopover from "../../chatbot/helper/EmojiPopover";
import { buttonFieldStyles } from "../styles/buttonFieldStyles";

const styles = buttonFieldStyles;

const BotButtonField = ({ setBotData, data, index, botData }) => {
  const inputRef = useRef();
  const [anchorEl, setAnchorEl] = useState(null);
  const [attributeAnchorEl, setAttributeAnchorEl] = useState(null);

  const handleAttributePopover = (e) => {
    setAttributeAnchorEl(e.currentTarget);
  };

  const handleEmojiPicker = (e) => {
    setAnchorEl(inputRef.current);
  };

  const handleAttributeSelect = (value) => {
    const cursor = inputRef.current.querySelector("textarea").selectionStart;
    const newValue =
      data.description.slice(0, cursor) +
      ` {{${value}}} ` +
      data.description.slice(cursor);
    setBotData((currentField) => {
      return currentField.map((field) => {
        if (field.id === data.id) {
          return { ...field, description: newValue };
        }
        return field;
      });
    });
    setAttributeAnchorEl(null);
  };

  const handleEmojiSelect = (emoji) => {
    const emojiText = emoji.native;
    const cursor = inputRef.current.querySelector("textarea").selectionStart;
    const newValue =
      data.description.slice(0, cursor) +
      emojiText +
      data.description.slice(cursor);

    setBotData((currentField) => {
      return currentField.map((field) => {
        if (field.id === data.id) {
          return { ...field, description: newValue };
        }
        return field;
      });
    });
    setAnchorEl(false);
  };

  const handleButtonTitle = (e, data) => {
    const description = e.target.value;
    const id = data.id;

    setBotData((buttonField) => {
      return buttonField.map((field) => {
        if (field.id === id) {
          return { ...field, description: description };
        }
        return field;
      });
    });
  };

  const handleAddButtonClick = (id, dataType) => {
    const newButton = {
      id: getTimeInMilliSeconds(),
      title: "Button",
      type: "send_message",
    };
    setBotData((currentField) => {
      return currentField.map((field) => {
        if (field.id === id && field.type == dataType) {
          return { ...field, buttons: [...field.buttons, newButton] };
        }
        return field;
      });
    });
  };

  return (
    <div key={data.id} style={styles.mainContainer}>
      <DeleteAndSwap setBotData={setBotData} index={index} botData={botData} />
      <div style={styles.innerContainer}>
        <Box sx={styles.textFieldContainer}>
          <TextField
            ref={inputRef}
            multiline
            sx={styles.textField}
            inputProps={{
              maxLength: 256,
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    sx={styles.iconButton}
                    onClick={handleAttributePopover}
                  >
                    <DataObjectIcon sx={styles.icon} />
                  </IconButton>
                  <TextFieldAttributes
                    attributeAnchorEl={attributeAnchorEl}
                    setAttributeAnchorEl={setAttributeAnchorEl}
                    handleAttributeSelect={handleAttributeSelect}
                    data={data}
                  />

                  <IconButton
                    sx={styles.iconButton}
                    onClick={handleEmojiPicker}
                  >
                    <InsertEmoticonIcon sx={styles.icon} />
                  </IconButton>
                  <EmojiPopover
                    anchorEl={anchorEl}
                    setAnchorEl={setAnchorEl}
                    handleEmojiSelect={handleEmojiSelect}
                    data={data}
                  />
                </InputAdornment>
              ),
            }}
            id="outlined-multiline-static"
            variant="outlined"
            value={data.description}
            onChange={(e) => handleButtonTitle(e, data)}
            placeholder="Enter your message..."
          />

          {data.buttons.map((button) => (
            <Box
              key={button.id}
              style={{
                display: "flex",
                flexDirection: "column",
              }}
              sx={{ borderTop: 1, color: "rgb(220, 220, 220)" }}
            >
              <ButtonPopover
                fieldId={data?.id}
                key={button?.id}
                button={button}
                buttonId={button?.id}
                fieldType={data?.type}
                title={button?.title}
                botData={botData}
                setBotData={setBotData}
              />
            </Box>
          ))}
        </Box>

        {data?.buttons.length < 5 && (
          <div style={styles.lineBox}>
            {Array.from({ length: 5 }, (_, index) => (
              <div key={index} style={styles.line} />
            ))}

            <IconButton
              sx={{
                height: "25px",
                width: "25px",
                color: "#008aff",
                backgroundColor: "#fff",
                "&:hover": { backgroundColor: "#008aff", color: "#fff" },
              }}
              onClick={() => handleAddButtonClick(data.id, data.type)}
            >
              <AddIcon
                sx={{
                  fontSize: "19px",
                  borderRadius: "50%",
                  fontWeight: 600,
                }}
              />
            </IconButton>
          </div>
        )}
      </div>
    </div>
  );
};

BotButtonField.propTypes = {
  data: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  botData: PropTypes.array.isRequired,
  setBotData: PropTypes.func.isRequired,
};

export default BotButtonField;
