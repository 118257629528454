import axios from "axios";
import {
  backendApiEndpoints,
  backendDoamin,
  formatDate,
  getAxiosError,
  getChannelData,
  getIsBackendMigrated,
  getRandomString,
  getToken,
  getWebsite,
  isArrayWithValues,
  isDev,
  isObjWithValues,
} from "../../helper";
import { currencySymbols } from "../../pages/pricing/Gold";
import { setGlobalToast } from "./helperActions";
import { EDIT_SETTINGS } from "./settingActions";
export const SET_HUB_DATA = "SET_HUB_DATA";

export const setHubData = (payload) => {
  return {
    type: "SET_HUB_DATA",
    payload,
  };
};

export const getOnesignalNotifications = (appId) => {
  let website = getWebsite();
  let token = getToken();
  if (!website || !token) return false;
  return async (dispatch) => {
    dispatch({
      type: SET_HUB_DATA,
      payload: { onesignalNotifications: [], fetchingNotifications: true },
    });
    let notificationsRes = await axios({
      url: `${website}/wp-json/store/v1/channel/onesignal`,
      headers: {
        Authorization: `Basic ${token}`,
      },
      params: {
        endpoint: `/notifications?app_id=${appId}`,
      },
    });
    if (isArrayWithValues(notificationsRes?.data?.notifications)) {
      dispatch({
        type: SET_HUB_DATA,
        payload: {
          onesignalNotifications: notificationsRes?.data?.notifications,
          fetchingNotifications: false,
        },
      });
    } else {
      dispatch({
        type: SET_HUB_DATA,
        payload: { fetchingNotifications: false },
      });
    }
  };
};
export const deleteFacebookPost = (post) => {
  let website = getWebsite();
  let token = getToken();
  if (!website || !token) return false;
  return async (dispatch, getState) => {
    let state = getState();
    let facebookposts = state.hub.facebookPosts || [];
    let allPosts = facebookposts.filter((obj) => obj?.id !== post?.id);
    dispatch({
      type: SET_HUB_DATA,
      payload: { facebookPosts: allPosts },
    });
    const res = await getChannelData("facebook", `/${post?.id}`, "DELETE");

    if (res.success) {
      dispatch(
        setGlobalToast({
          show: true,
          message: "Post Deleted ",
          severity: "success",
        })
      );
    }
  };
};

export const sendNewRatesPushNotification = () => {
  return async (dispatch, getState) => {
    console.log("sending ");
    let state = getState();
    let onesignalIntegration = state.settings.onesignalIntegration;
    let masterPricing = state.settings.masterPricing;
    const default_currency =
      state.settings.productSettings?.default_currency || "";
    if (!onesignalIntegration?.daily_rates) return;
    // let goldPriceStr =

    const getPurityRate = (priceObj, purities) => {
      if (!isObjWithValues(priceObj)) return;
      let arr = [];
      for (const key in priceObj) {
        if (purities.includes(key)) {
          arr.push({ key: key, rate: priceObj[key]?.rate });
        }
      }
      return arr;
    };
    const getStr = (arr, label) => {
      let str = "";
      for (const obj of arr) {
        str =
          str +
          `${label} (${obj?.key})  ${currencySymbols?.[default_currency]} ${obj?.rate}\n`;
      }
      return str;
    };
    let goldObj = masterPricing?.gold_pricing[default_currency];
    let silverObj = masterPricing?.silver_pricing[default_currency];
    let platinumObj = masterPricing?.platinum_pricing[default_currency];
    let goldPriceArr = getPurityRate(
      goldObj?.[masterPricing?.gold_pricing?.type],
      onesignalIntegration?.gold?.purities
    );
    let silverPriceArr = getPurityRate(
      silverObj?.[masterPricing?.silver_pricing?.type],
      onesignalIntegration?.silver?.purities
    );
    let platinumPriceArr = getPurityRate(
      platinumObj?.[masterPricing?.platinum_pricing?.type],
      onesignalIntegration?.platinum?.purities
    );

    let finalStr = `${
      onesignalIntegration?.gold?.enabled
        ? `Gold\n${getStr(goldPriceArr, "Gold")}`
        : ""
    }\n${
      onesignalIntegration?.silver?.enabled
        ? `Silver\n${getStr(silverPriceArr, "Silver")}`
        : ""
    }\n${
      onesignalIntegration?.platinum?.enabled
        ? `Platinum\n${getStr(platinumPriceArr, "Platinum")}`
        : ""
    }\n`;

    // console.log(finalStr);
    if (
      !onesignalIntegration?.selectedAccount?.id ||
      !onesignalIntegration?.selectedAccount?.basic_auth_key
    )
      return;
    let data = {
      app_id: onesignalIntegration?.selectedAccount?.id,
      name: `Price Update Notification Date ${formatDate(
        new Date(),
        "relative_date"
      )}`,
      contents: {
        en: finalStr,
      },
      headings: {
        en: onesignalIntegration.title,
      },
      included_segments: ["All"],
      // url: formData.launchUrl,
    };
    try {
      const res = await axios({
        url: "https://onesignal.com/api/v1/notifications",
        headers: {
          Authorization: `Basic ${onesignalIntegration?.selectedAccount?.basic_auth_key}`,
        },
        data: data,
        method: "POST",
      });
      if (res?.data?.id) {
        dispatch(
          setGlobalToast({
            show: "true",
            message: "Sent new gold rates successfully",
            severity: "success",
          })
        );
      }
      console.log(res.data);
    } catch (error) {
      console.log(getAxiosError(error));
    }
  };
};

export const addUserActivity = (eventDetails) => {
  return async (dispatch, getState) => {
    let website = getWebsite();
    let token = getToken();
    if (!website || !token) return false;
    if (isDev()) return;
    const id = `${Date.now()}_${getRandomString(8)}`;
    let state = getState();
    let admin = state.user.isAdmin;
    let user_email;
    let user_id = state?.user?.id;
    let access_id;
    let user_name;
    // let customer_id;
    // console.log(state.user);
    if (!admin) {
      user_email = state.user?.email;
      user_name = `${state.user?.first_name} ${state.user?.last_name}`;
      access_id = state.user?.id;
      // customer_id = state.user?.id;
    }

    // let userActivities = state.settings.userActivities || [];
    try {
      let res = await axios({
        url: `${website}/wp-json/store/v1/settings/user_activities`,
        headers: {
          Authorization: `Basic ${token}`,
        },
        method: "PUT",
        data: {
          type: "user_activities",
          data: {
            [id]: {
              is_admin: admin,
              user_info: !admin
                ? {
                    // email: "",
                    // name: "",
                    // access_id: ""
                    email: user_email,
                    name: user_name,
                    access_id,
                    id: user_id,
                  }
                : undefined,
              ...eventDetails,
            },
          },
        },
      });
      console.log({
        user_id,
        user_email,
        user_name,
        access_id,
        ...eventDetails,
      });
      console.log(res);
      if (res.data?.data) {
        console.log("activity added");
        // let dataObj = {};
        // dispatch({
        //   type: EDIT_SETTINGS,
        //   payload: {
        //     userActivities: [...userActivities, {...res.data?.data}],
        //   },
        // });
      }
    } catch (error) {
      console.log(getAxiosError(error));
    }
  };
};

export const deprecated_getUserActivity = () => {
  let website = getWebsite();
  let token = getToken();
  if (!website || !token) return false;
  return async (dispatch) => {
    dispatch({
      type: EDIT_SETTINGS,
      payload: {
        userFetchingActivities: true,
      },
    });
    try {
      let res = await axios({
        url: `${website}/wp-json/store/v1/settings/user_activities`,
        headers: {
          Authorization: `Basic ${token}`,
        },
      });
      console.log(res);
      if (res.data.type === "success") {
        let arr = [];
        let resObj = { ...res.data?.data };
        for (const key in resObj) {
          arr.push({ ...resObj[key], id: key, time: key.split("_")[0] });
        }
        dispatch({
          type: EDIT_SETTINGS,
          payload: {
            userActivities: arr.reverse(),
            userFetchingActivities: false,
          },
        });
      }
    } catch (error) {
      console.log(getAxiosError(error));
    }
  };
};
export const getUserActivity = () => {
  if (!getIsBackendMigrated("chats") || true)
    return deprecated_getUserActivity();
  let token = getToken();
  if (!token) return false;
  return async (dispatch) => {
    dispatch({
      type: EDIT_SETTINGS,
      payload: {
        userFetchingActivities: true,
      },
    });
    try {
      let res = await axios({
        url: `${backendDoamin}/${backendApiEndpoints.settings}/user_activities`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log(res);
      if (res.data.type === "success") {
        let arr = [];
        let resObj = { ...res.data?.data };
        for (const key in resObj) {
          arr.push({ ...resObj[key], id: key, time: key.split("_")[0] });
        }
        dispatch({
          type: EDIT_SETTINGS,
          payload: {
            userActivities: arr.reverse(),
            userFetchingActivities: false,
          },
        });
      }
    } catch (error) {
      console.log(getAxiosError(error));
    }
  };
};
