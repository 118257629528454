/* eslint-disable react-hooks/exhaustive-deps */
import {
  AccessTimeOutlined,
  ArrowBack,
  Close,
  InputOutlined,
  LaptopMac,
  LocationOnOutlined,
  Note,
  PhoneIphone,
  Share,
} from "@mui/icons-material";
import {
  Avatar,
  Box,
  Breadcrumbs,
  Card,
  CardContent,
  Dialog,
  FormControl,
  Grid,
  IconButton,
  Link,
  List,
  MenuItem,
  Select,
  Slide,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/system";
import React, { createRef, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import {
  dialogStyle,
  formatDate,
  getRelativeTimeDifference,
  isArrayWithValues,
  isObjWithValues,
  primaryLabel,
  validateNumber,
} from "../../helper";
import { UAParser } from "ua-parser-js";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  timelineItemClasses,
  TimelineOppositeContent,
  TimelineSeparator,
} from "@mui/lab";
import "react-vertical-timeline-component/style.min.css";
import "../../App.css";
import { isValidDate } from "../../utils/dateFunctions";
import { getDropdownData } from "../../redux/actions/persistActions";
import { getFlagImage } from "../dashboards/Default/GoogleAnalytics";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const mainEvents = ["design_bank", "catalog", "quotation", "website"];

const defaultDetailView = {
  type: "",
  retailer_name: "",
  product_name: "",
  ip: "",
  from: "",
  location: "",
  locationFetched: false,
};
// const defaultDetailView = {
//   online: true,
//   id: "1656877313_yxvSMW",
//   time: 1656877313,
//   type: "design_bank_product",
//   user_name: "Tiara By TJ",
//   user_id: "1234",
//   ip: "49.32.253.145",
//   ua: "Mozilla/5.0 (Linux; Android 11; POCO M2 Pro Build/RKQ1.200826.002; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/102.0.5005.125 Mobile Safari/537.36",
//   from: "app",
// };

const VisitorsDetails = ({ fromDashboard }) => {
  const theme = useTheme();
  const [userDetailsOpen, setUserDetailsOpen] = useState(false);
  const [visitors, setVisitors] = useState({
    0: [],
    1: [],
    5: [],
    10: [],
  });
  const [allOnlineVisitors, setAllOnlineVisitors] = useState([]);
  const [allVisitors, setAllVisitors] = useState([]);
  const reduxAllOnlineVisitors = useSelector(
    (state) => state.visitor.allOnlineVisitors
  );
  const reduxAllVisitors = useSelector((state) => state.visitor.allVisitors);
  const [intervalStarted, setIntervalStarted] = useState(false);
  const [selectedTime, setSelectedTime] = useState("all");
  const allVisitorsRef = useRef([]);
  const [userDetailView, setUserDetailView] = useState(defaultDetailView);

  const allCountries = useSelector((state) => state.persist.countries);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!isArrayWithValues(allCountries)) dispatch(getDropdownData());
  }, []);
  let visitorsDataInterval;
  // useEffect(() => {
  //   if (!isArrayWithValues(allCountries)) dispatch(getDropdownData());
  // }, []);

  useEffect(() => {
    return clearInterval(visitorsDataInterval);
  }, []);

  useEffect(async () => {
    let { events } = userDetailView;
    if (isArrayWithValues(events)) {
      let _country, _city, _region;
      for (let event of events) {
        if (isObjWithValues(event.location)) {
          let { city, region, country } = event.location;
          _city = city;
          _region = region;
          _country = country;
          break;
        }
      }
      setUserDetailView((state) => ({
        ...state,
        locationFetched: true,
        location: `${_city ? _city + ", " : ""}${
          _region ? _region + ", " : ""
        }${_country || ""}`,
        country: _country,
      }));
    }
    // else
    //   setUserDetailView((state) => ({
    //     ...state,
    //     location: ``,
    //     locationFetched: true,
    //   }));
  }, [JSON.stringify(userDetailView)]);

  // useEffect(() => {
  // }, [reduxAllOnlineVisitors]);
  useEffect(() => {
    let obj = setUpVisitorsData({
      ...reduxAllVisitors,
      ...reduxAllOnlineVisitors,
    });
    setAllVisitors(
      setUpVisitorsData(
        {
          ...reduxAllVisitors,
          ...reduxAllOnlineVisitors,
        },
        true
      )
    );
    setAllOnlineVisitors(obj);
  }, [reduxAllVisitors, reduxAllOnlineVisitors]);

  const setUpVisitorsData = (visitors, skipTime) => {
    let obj = { ...visitors };
    let date = new Date();
    let todayDate = date.toISOString().substring(0, 10);
    date = date.setDate(date.getDate() - 1);
    date = new Date(date);
    let yesterdayDate = date.toISOString().substring(0, 10);
    let visitorsArray = [];
    for (let id in obj) {
      let events = obj[id];

      // for (let i = events.length - 1; i >= 0; i--) {
      for (let i = 0; i < events.length; i++) {
        let { time, online, user_id, type, user_name } = events[i];
        let eventTime = new Date(time * 1000);

        eventTime = isValidDate(eventTime)
          ? eventTime.toISOString().substring(0, 10)
          : "";
        // console.log(
        //   id,
        //   eventTime === todayDate || eventTime === yesterdayDate,
        //   mainEvents.includes(type),
        //   online || online === "true"
        // );
        if (
          ((eventTime === todayDate || eventTime === yesterdayDate) &&
            mainEvents.includes(type)) ||
          skipTime
        ) {
          if (online || online === "true" || skipTime) {
            visitorsArray.push({
              time,
              user_id,
              user_name,
              events,
            });
            break;
          } else break;
        }
      }
    }
    return visitorsArray;
  };

  useEffect(() => {
    setVisitorsData(allOnlineVisitors);
    allVisitorsRef.current = [...allOnlineVisitors];
    if (isArrayWithValues(allOnlineVisitors)) {
      if (!intervalStarted) {
        setIntervalStarted(true);
        visitorsDataInterval = setInterval(() => {
          // console.log("in interval", allOnlineVisitors);
          setVisitorsData(allVisitorsRef.current);
        }, 60000);
      }
    }
    if (!isArrayWithValues(allOnlineVisitors) || allOnlineVisitors.length === 0)
      clearInterval(visitorsDataInterval);
  }, [allOnlineVisitors]);

  const setVisitorsData = (allOnlineVisitors) => {
    if (allOnlineVisitors.length === 0) {
      setVisitors({
        0: [],
        1: [],
        5: [],
        10: [],
      });
    } else {
      let currentVisitors = {
        0: [],
        1: [],
        5: [],
        10: [],
      };

      for (let i = 0; i < allOnlineVisitors.length; i++) {
        let visitor = allOnlineVisitors[i];
        let currentTime = parseInt(Date.now() / 1000);
        let minutesDiff = (currentTime - visitor.time) / 60;
        // console.log(minutesDiff, "MINUTES DIFFERENCE");
        if (minutesDiff < 1) currentVisitors[0].push(visitor);
        else if (minutesDiff < 5) currentVisitors[1].push(visitor);
        else if (minutesDiff < 10) currentVisitors[5].push(visitor);
        else if (minutesDiff >= 10) currentVisitors[10].push(visitor);
      }
      if (
        currentVisitors[0].length === 0 &&
        currentVisitors[1].length === 0 &&
        currentVisitors[5].length === 0
      )
        clearInterval(visitorsDataInterval);

      setVisitors(currentVisitors);
    }
  };

  const onUserDetailOpen = (user) => {
    setUserDetailView(user);
    setUserDetailsOpen(true);
  };

  const onUserDetailClose = () => {
    setUserDetailView((state) => ({ ...defaultDetailView }));
    setUserDetailsOpen(false);
  };

  const isIpad = useMediaQuery(theme.breakpoints.down("md"));
  const globalPopupStyle =
    useSelector((state) => state.helper.globalPopupStyle) || {};

  return (
    <Box sx={{}}>
      <Typography variant="h3" gutterBottom display="inline">
        Visitors Info
      </Typography>
      {!fromDashboard && (
        <Breadcrumbs aria-label="Breadcrumb" mt={2}>
          <Link component={NavLink} to="/">
            Dashboard
          </Link>
          {/* <Link component={NavLink} to="/">
              Products
            </Link> */}
          <Typography>Visitors Info</Typography>
        </Breadcrumbs>
      )}
      {isIpad && (
        <FormControl size="small" sx={{ mt: 3, ml: 2 }}>
          {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            defaultValue={10}
            value={selectedTime}
            // label="Age"
            onChange={(e) => setSelectedTime(e.target.value)}
          >
            <MenuItem value={"all"} defaultChecked>
              All Visitors
            </MenuItem>
            <MenuItem value={0} defaultChecked>
              More than 0 min
            </MenuItem>
            <MenuItem value={1}>More than 1 min</MenuItem>
            <MenuItem value={5}>More than 5 min</MenuItem>
            <MenuItem value={10}>More than 10 min</MenuItem>
          </Select>
        </FormControl>
      )}
      {/* <Stack direction={"row"} justifyContent={"flex-end"}>
      </Stack> */}
      {isIpad && (
        <List
          sx={{
            overflowY: "scroll",
            maxHeight: "65vh",
            scrollBehavior: "smooth",
          }}
          className="scrollbar-hidden"
        >
          {(selectedTime === "all" ? allVisitors : visitors[selectedTime])?.map(
            (i) => {
              return (
                <CustomerCard
                  key={i.user_id}
                  user={{
                    first_name:
                      i.user_name?.trim() ||
                      `Visitor ${i?.user_id?.replace("random_", "")}`,
                    last_name: "",
                    from: i.from,
                    events: i.events,
                  }}
                  onUserDetailOpen={() => onUserDetailOpen(i)}
                  allCountries={allCountries}
                />
              );
            }
          )}
          {/* <CustomerCard
            user={{ first_name: "Akshay", last_name: "Pagare" }}
            onUserDetailOpen={onUserDetailOpen}
          />
          <CustomerCard user={{ first_name: "Akshay", last_name: "Pagare" }} />
          <CustomerCard user={{ first_name: "Akshay", last_name: "Pagare" }} />
          <CustomerCard user={{ first_name: "Akshay", last_name: "Pagare" }} />
          <CustomerCard
            user={{ first_name: "Akshay", last_name: "Pagare" }}
          />{" "} */}
        </List>
      )}
      {!isIpad && (
        <Box
          mt={4}
          sx={{
            overflow: "scroll",
            overflowX: "scroll",
            display: "flex",
            whiteSpace: "normal",
            scrollBehavior: "smooth",
          }}
          // className="track-horizontal"
          className="scrollbar-hidden"
        >
          {" "}
          <Card
            sx={{
              height: "70vh",
              minWidth: "300px",
              mx: 2,
              borderRadius: "8px",
              // overflowY: "scroll",
            }}
          >
            <CardContent sx={{ p: 0 }}>
              <CustomerListCard
                title="All Visitors"
                visitors={allVisitors.length}
              />
              <Box
                sx={{
                  position: "relative",
                  maxHeight: "70vh",
                  overflowY: "auto",
                  paddingBottom: "70px",
                }}
                className="scrollbar-hidden"
              >
                {allVisitors.map((i) => {
                  return (
                    <CustomerCard
                      key={i.user_id}
                      user={{
                        first_name:
                          i.user_name?.trim() ||
                          `Visitor ${i?.user_id?.replace("random_", "")}`,
                        last_name: "",
                        from: i.from,
                        events: i.events,
                      }}
                      onUserDetailOpen={() => onUserDetailOpen(i)}
                      allCountries={allCountries}
                    />
                  );
                })}
              </Box>
            </CardContent>
          </Card>
          <Card
            sx={{
              height: "70vh",
              minWidth: "300px",
              mx: 2,
              borderRadius: "8px",
            }}
          >
            <CardContent sx={{ p: 0 }}>
              <CustomerListCard
                title="Greater than 10 min"
                visitors={visitors[10].length}
              />
              <Box
                sx={{
                  position: "relative",
                  maxHeight: "70vh",
                  overflowY: "auto",
                  paddingBottom: "70px",
                }}
                className="scrollbar-hidden"
              >
                {visitors[10].map((i) => {
                  return (
                    <CustomerCard
                      key={i.user_id}
                      user={{
                        first_name:
                          i.user_name?.trim() ||
                          `Visitor ${i?.user_id?.replace("random_", "")}`,
                        last_name: "",
                        from: i.from,
                        events: i.events,
                      }}
                      onUserDetailOpen={() => onUserDetailOpen(i)}
                      allCountries={allCountries}
                    />
                  );
                })}
              </Box>
            </CardContent>
          </Card>
          <Card
            sx={{
              height: "70vh",
              minWidth: "300px",
              mx: 2,
              borderRadius: "8px",
            }}
          >
            <CardContent sx={{ p: 0 }}>
              <CustomerListCard
                title="Greater than 5 min"
                visitors={visitors[5].length}
              />
              <Box
                sx={{
                  position: "relative",
                  maxHeight: "70vh",
                  overflowY: "auto",
                  paddingBottom: "70px",
                }}
                className="scrollbar-hidden"
              >
                {visitors[5].map((i) => {
                  return (
                    <CustomerCard
                      key={i.user_id}
                      user={{
                        first_name:
                          i.user_name?.trim() ||
                          `Visitor ${i?.user_id?.replace("random_", "")}`,
                        last_name: "",
                        from: i.from,
                        events: i.events,
                      }}
                      onUserDetailOpen={() => onUserDetailOpen(i)}
                      allCountries={allCountries}
                    />
                  );
                })}
              </Box>
            </CardContent>
          </Card>
          <Card
            sx={{
              height: "70vh",
              minWidth: "300px",
              mx: 2,
              borderRadius: "8px",
            }}
          >
            <CardContent sx={{ p: 0 }}>
              <CustomerListCard
                title="Greater than 1 min"
                visitors={visitors[1].length}
              />
              <Box
                sx={{
                  position: "relative",
                  maxHeight: "70vh",
                  overflowY: "auto",
                  paddingBottom: "70px",
                }}
                className="scrollbar-hidden"
              >
                {visitors[1].map((i) => {
                  return (
                    <CustomerCard
                      key={i.user_id}
                      user={{
                        first_name:
                          i.user_name?.trim() ||
                          `Visitor ${i?.user_id?.replace("random_", "")}`,
                        last_name: "",
                        from: i.from,
                        events: i.events,
                      }}
                      onUserDetailOpen={() => onUserDetailOpen(i)}
                      allCountries={allCountries}
                    />
                  );
                })}
              </Box>
            </CardContent>
          </Card>
          <Card
            sx={{
              height: "70vh",
              minWidth: "300px",
              mx: 2,
              borderRadius: "8px",
            }}
          >
            <CardContent
              sx={{
                p: 0,
                // overflow: "scroll",
                // overflowY: "scroll",
                // whiteSpace: "normal",
              }}
            >
              <CustomerListCard
                title="Greater than 0 min"
                visitors={visitors[0].length}
              />
              {visitors[0].map((i) => {
                return (
                  <CustomerCard
                    key={i.user_id}
                    user={{
                      first_name:
                        i.user_name?.trim() ||
                        `Visitor ${i?.user_id?.replace("random_", "")}`,
                      last_name: "",
                      from: i.from,
                      events: i.events,
                    }}
                    onUserDetailOpen={() => onUserDetailOpen(i)}
                    allCountries={allCountries}
                  />
                );
              })}
            </CardContent>
          </Card>
          <Box
            sx={{
              height: "70vh",
              minWidth: "300px",
              mx: 2,
              borderRadius: "8px",
            }}
          />
        </Box>
      )}
      {isIpad ? (
        <Dialog
          fullScreen
          open={userDetailsOpen}
          onClose={onUserDetailClose}
          TransitionComponent={Transition}
          sx={dialogStyle}
          {...globalPopupStyle}
        >
          <UserDetailsDialog
            userDetailView={userDetailView}
            onUserDetailClose={onUserDetailClose}
          />
        </Dialog>
      ) : (
        <Dialog
          open={userDetailsOpen}
          onClose={onUserDetailClose}
          TransitionComponent={Transition}
          // keepMounted
          aria-describedby="alert-dialog-slide-description"
          maxWidth="md"
          fullWidth
          PaperProps={{ sx: { overflow: "visible", ...dialogStyle } }}
          {...globalPopupStyle}
        >
          <UserDetailsDialog
            onUserDetailClose={onUserDetailClose}
            userDetailView={userDetailView}
          />
        </Dialog>
      )}
    </Box>
  );
};

export default VisitorsDetails;

// const iconStyle = {
//   background: "rgba(0,0,0,0.4)",
//   height: "28px",
//   width: "28px",
//   padding: "5px",
//   color: "#fff",
//   marginTop: "6px",
//   marginLeft: "6px",
//   textAlign: "center",
// };

export const UserDetailsDialog = ({ onUserDetailClose, userDetailView }) => {
  let [lastEvent, setLastEvent] = useState({});
  const [onlineTime, setOnlineTime] = useState({});
  const [onlineTimeInterval, setOnlineTimeInveral] = useState(false);
  let intervalRef = createRef();
  const theme = useTheme();
  const isIpad = useMediaQuery(theme.breakpoints.down("md"));
  const [userCountry, setUserCountry] = useState("");
  const allCountries = useSelector((state) => state.persist.countries);

  // const dispatch = useDispatch();

  useEffect(() => {
    let { country } = userDetailView;
    if (isArrayWithValues(allCountries)) {
      let countryObj = allCountries.find((i) => i.code === country);
      if (countryObj)
        setUserCountry(countryObj?.label?.replaceAll(" ", "-")?.toLowerCase());
    }
  }, [allCountries, userDetailView]);

  useEffect(() => {
    return () => clearInterval(intervalRef.current);
  }, [userDetailView.time]);
  useEffect(() => {
    clearInterval(intervalRef.current);
  }, [userDetailView.time]);

  useEffect(() => {
    let { events } = userDetailView;
    clearInterval(intervalRef.current);
    if (isArrayWithValues(events)) {
      setLastEvent(events[0]);
      for (let i = 0; i < events.length; i++) {
        let { type } = events[i];
        if (mainEvents.includes(type)) {
          let { time } = events[i];
          let timeObj = getRelativeTimeDifference(
            new Date(validateNumber(time) * 1000),
            new Date().getTime()
          );
          if (timeObj) {
            setOnlineTime(timeObj);
            clearInterval(intervalRef.current);
            if (timeObj.label.includes("second")) {
              setOnlineTimeInveral(1000);
            } else setOnlineTimeInveral(60000);
          } else {
            setOnlineTime({});
            setOnlineTimeInveral(false);
          }
          break;
        }
      }
    }
    return () => clearInterval(intervalRef.current);
  }, [userDetailView]);

  useEffect(() => {
    if (onlineTimeInterval) {
      if (intervalRef.current) clearInterval(intervalRef.current);
      intervalRef.current = setInterval(() => {
        let { events } = userDetailView;
        let timeFlag;

        if (isArrayWithValues(events)) {
          for (let i = 0; i < events.length; i++) {
            let { type, online, time } = events[i];
            if (mainEvents.includes(type) && online) {
              timeFlag = time;
              break;
            }
          }
        }
        if (!timeFlag) return;

        let timeObj = getRelativeTimeDifference(
          new Date(timeFlag * 1000),
          new Date().getTime()
        );
        if (timeObj) {
          setOnlineTime(timeObj);
          if (timeObj.label.includes("second") && onlineTimeInterval !== 1000)
            setOnlineTimeInveral(1000);
          else if (
            !timeObj.label.includes("second") &&
            onlineTimeInterval !== 60000
          )
            setOnlineTimeInveral(60000);
        } else {
          setOnlineTime({});
          setOnlineTimeInveral(false);
          clearInterval(intervalRef.current);
        }
      }, onlineTimeInterval);
    }
  }, [onlineTimeInterval]);

  let { type, from, ua } = lastEvent;
  let { events } = userDetailView;
  let userAgent = ua ? new UAParser(ua).getResult() : false;
  userAgent = userAgent || {};
  let { browser, os } = userAgent;
  let isFromLaptop =
    os && os.name && (os.name === "Mac OS" || os.name === "Windows");
  if (isIpad)
    return (
      <Box sx={{ px: 2 }}>
        <Stack direction="row" alignItems={"center"} py={3} px={3}>
          <IconButton onClick={onUserDetailClose}>
            <ArrowBack />
          </IconButton>
          <Typography variant="h5" ml={2}>
            Spent more than 10 min
          </Typography>
        </Stack>
        <Stack direction="row" alignItems={"center"} mt={3} px={3}>
          <Avatar sx={{ width: 65, height: 65 }} />
          <Box sx={{ ml: 2, flex: 1 }}>
            <Typography variant="body1" fontWeight={"bold"} fontSize="15px">
              {`${
                (userDetailView && userDetailView.user_name?.trim()) ||
                `Visitor ${userDetailView.user_id?.replace("random_", "")}`
              }`}
            </Typography>
            {/* <Stack direction={"row"} alignItems="center">
            <Typography variant="h6" fontSize="12px">
              {userDetailView.location}
            </Typography>
          </Stack> */}
            <Stack
              direction={"row"}
              alignItems="center"
              // justifyContent={"space-between"}
            >
              <Typography variant="h6" fontSize="12px">
                {from === "app" ? "Retailer App" : "Website"}
              </Typography>

              <IconButton size="small" sx={{ ml: 2 }}>
                <Share sx={{ widht: "14px", height: "14px" }} />
              </IconButton>
            </Stack>
          </Box>
        </Stack>
        <Stack direction={"row"} mt={5} justifyContent="space-around">
          {/* <Grid container spacing={4} px={3}>
                <Grid item xs={6}>
                  <ReactApexChart
                    options={opportunityRadialBarOptions}
                    series={[0]}
                    type="radialBar"
                    height={isMobile ? 180 : 240}
                    // minWidth={"0%"}
                  />
                </Grid>
                <Grid item xs={6}>
                  <ReactApexChart
                    options={leadsRadialBarOptions}
                    series={[0]}
                    type="radialBar"
                    height={isMobile ? 180 : 240}
                  />
                </Grid>
              </Grid> */}
        </Stack>
        <Card
          sx={{
            boxShadow: "0px 0px 2px 0px rgba(0,0,0,0.35)",
            my: 3,
          }}
        >
          <CardContent sx={{ "&:last-child": { paddingBottom: "16px" } }}>
            <Typography mb={2} variant="h6">
              Timeline
            </Typography>
            <Box sx={{ maxHeight: "300px", overflowY: "scroll" }}>
              <UserTimeline events={events} />
            </Box>
          </CardContent>
        </Card>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <UserDetailInfoCardMobile
              label="Location"
              value={userDetailView.location}
              icon={<LocationOnOutlined />}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <UserDetailInfoCardMobile
              label="Device"
              value={
                !browser && !os
                  ? "Unavailable"
                  : `${browser?.name ? `${browser.name},` : ""} ${
                      os?.name ? os.name : ""
                    }`.trim() || ""
              }
              icon={from === "app" ? <PhoneIphone /> : <LaptopMac />}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <UserDetailInfoCardMobile
              label="Time"
              value={
                isObjWithValues(onlineTime)
                  ? `${onlineTime.value} ${onlineTime.label}`
                  : ""
              }
              icon={<AccessTimeOutlined />}
            />
          </Grid>
        </Grid>
        {/* <Stack direction={"row"} my={5} mb={8} justifyContent="center">
          <Button
            variant="contained"
            startIcon={<ForumOutlined />}
            fullWidth
            sx={{ maxWidth: "300px" }}
            onClick={() => navigate(`customers/${user_id}#chats`)}
          >
            Start a Chat
          </Button>
        </Stack> */}
      </Box>
    );
  return (
    <React.Fragment>
      <IconButton
        sx={{
          position: "absolute",
          // transform: "translateY(-40px)",
          // transitionDelay: "4s",
          top: "-50px",
          right: "10px",
          backgroundColor: "background.paper",
          "&:hover": {
            backgroundColor: "background.paper",
          },
        }}
        size="small"
        onClick={onUserDetailClose}
      >
        <Close />
      </IconButton>
      <Card>
        <CardContent>
          <Grid container spacing={2} alignItems="center" pb={2}>
            <Grid item xs={12} sm={6}>
              <Stack direction="row" alignItems={"center"}>
                <Avatar sx={{ width: 45, height: 45 }} />
                <Box sx={{ ml: 2, flex: 1 }}>
                  <Stack direction="row" alignItems={"center"}>
                    <Typography
                      variant="body1"
                      fontWeight={"bold"}
                      fontSize="15px"
                    >{`${
                      (userDetailView && userDetailView.user_name?.trim()) ||
                      `Visitor ${userDetailView.user_id?.replace(
                        "random_",
                        ""
                      )}`
                    }`}</Typography>
                    {userCountry
                      ? getFlagImage(
                          `https://www.countryflags.com/wp-content/uploads/${userCountry}-flag-png-large.png`,
                          {
                            style: {
                              width: "18px",
                              height: "18px",
                              borderRadius: "15px",
                              marginLeft: "6px",
                              marginBottom: "0px",
                              objectFit: "cover",
                            },
                          }
                        )
                      : ""}
                  </Stack>
                  <Stack direction={"row"} alignItems="center">
                    <Typography variant="h6" fontSize="12px">
                      {from === "app" ? "Retailer App" : "Website"}
                    </Typography>
                  </Stack>
                </Box>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6}>
              {/* <Typography sx={{ ...primaryLabel, textAlign: "center" }}>
                Customer journey
              </Typography> */}
              {/* <Button
                variant="contained"
                sx={{ px: 6 }}
                onClick={() => navigate(`/crm/${user_id}#chats`)}
                fullWidth
              >
                Start Chat
              </Button> */}
            </Grid>
          </Grid>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={6} my={5} px={5}>
              <Grid container spacing={5}>
                <Grid item xs={12} sm={6} md={6}>
                  <UserDetailInfoCard
                    Icon={LocationOnOutlined}
                    label={userDetailView.location || "Unavailable"}
                    // label={ip || ""}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <UserDetailInfoCard
                    Icon={AccessTimeOutlined}
                    label={
                      isObjWithValues(onlineTime)
                        ? `${onlineTime.value} ${onlineTime.label}`
                        : "N/A"
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <UserDetailInfoCard
                    Icon={InputOutlined}
                    label={getLabelFromType(type)}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <UserDetailInfoCard
                    Icon={!isFromLaptop ? PhoneIphone : LaptopMac}
                    label={
                      !browser && !os
                        ? "Unavailable"
                        : `${browser?.name ? `${browser.name},` : ""} ${
                            os?.name ? os.name : ""
                          }`.trim() || "Unavailable"
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              sx={{ maxHeight: "250px", overflowY: "scroll", pt: 0 }}
              className="scrollbar-hidden"
            >
              <Typography sx={{ ...primaryLabel, textAlign: "center" }}>
                Customer journey
              </Typography>
              <UserTimeline events={events} />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </React.Fragment>
  );
};

export const UserTimeline = ({ events, alignedLeft }) => {
  const theme = useTheme();
  const isIpad = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Timeline
      position="right"
      sx={
        alignedLeft
          ? {
              [`& .${timelineItemClasses.root}:before`]: {
                flex: 0,
                padding: 0,
              },
            }
          : {}
      }
    >
      {isArrayWithValues(events) &&
        events
          ?.sort((a, b) => validateNumber(b?.time) - validateNumber(a?.time))
          .map((i, index) => {
            let { type, time, event_name, end_time } = i;
            let date = formatDate(new Date(Number(time) * 1000), "dd mm yyyy");
            let eventTime = formatDate(
              new Date(Number(time) * 1000),
              "hh:mm am/pm"
            );
            let label = getLabelFromType(type);
            let isLast = events.length - 1 === index;
            // label =
            //   catalog_name && !product_name
            //     ? `${label} (${catalog_name})`
            //     : label;

            let timeSpentObj = getRelativeTimeDifference(
              new Date(validateNumber(time) * 1000),
              new Date(validateNumber(end_time) * 1000)
            );
            if (alignedLeft && !date) return <></>;
            return (
              <TimelineItem>
                <TimelineOppositeContent
                  color="text.secondary"
                  whiteSpace={"nowrap"}
                  sx={
                    alignedLeft
                      ? {
                          flex: !date ? 0.2 : 0,
                          // minWidth: date ? "" : "180px"
                        }
                      : {}
                  }
                >
                  {date}
                  {isIpad ? <br /> : null} {eventTime}
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot color={i.online ? "success" : "grey"} />
                  {!isLast && <TimelineConnector />}
                </TimelineSeparator>
                <TimelineContent>
                  <Stack>
                    <Stack
                      // direction={"row"}
                      // alignItems="flex-end"
                      whiteSpace={"nowrap"}
                      overflow="hidden"
                      textOverflow={"ellipsis"}
                    >
                      <Typography>{label}</Typography>
                      {/* <Typography fontSize={"11px"} ml={1}>
                  {catalog_name || quotation_name}
                </Typography> */}
                    </Stack>
                    {event_name && (
                      <Typography
                        fontSize={"10px"}
                        color="text.secondary"
                        fontStyle={"italic"}
                      >
                        {type?.includes("product") ? "product" : "Name"}:{" "}
                        {event_name}{" "}
                      </Typography>
                    )}
                    {timeSpentObj?.label &&
                      timeSpentObj?.label !== "years" &&
                      timeSpentObj?.label !== "hours" &&
                      timeSpentObj.value > 0 && (
                        <Typography fontSize={"10px"} fontStyle={"italic"}>
                          Time spent: {timeSpentObj.value} {timeSpentObj.label}
                        </Typography>
                      )}
                  </Stack>
                </TimelineContent>
              </TimelineItem>
            );
          })}
    </Timeline>
  );
};

const CustomerListCard = ({ title, visitors }) => {
  let [visitorColor, setVisitorColor] = useState();

  useEffect(() => {
    if (visitors === 0) setVisitorColor("error.main");
    if (visitors > 0 && visitors < 8) setVisitorColor("info.main");
    if (visitors > 8) setVisitorColor("success.main");
  }, [visitors]);

  return (
    <Card>
      <CardContent sx={{ p: 0, "&:last-child": { paddingBottom: "0px" } }}>
        <Box
          sx={{ backgroundColor: "rgba(217, 217, 217, 0.25)", py: 5, px: 3 }}
        >
          <Stack
            direction="row"
            alignItems={"center"}
            justifyContent="space-between"
          >
            <Typography variant="body2" fontWeight={"bold"} fontSize={"13px"}>
              {title}
            </Typography>
            <Typography variant="body2" color={visitorColor} fontSize={"13px"}>
              {visitors} Visitors
            </Typography>
          </Stack>
        </Box>
      </CardContent>
    </Card>
  );
};

const CustomerCard = ({ user, onUserDetailOpen, allCountries }) => {
  let { first_name, last_name, from } = user;
  const [country, setCountry] = useState("");
  useEffect(() => {
    if (!country && isArrayWithValues(user.events)) {
      let { events } = user;
      for (let event of events) {
        if (isObjWithValues(event.location)) {
          let { country } = event.location;

          if (isArrayWithValues(allCountries)) {
            let countryObj = allCountries.find((i) => i.code === country);
            if (countryObj)
              setCountry(
                countryObj?.label?.replaceAll(" ", "-")?.toLowerCase()
              );
          }
          break;
        }
      }
    }
  }, [user, allCountries]);
  return (
    <Card
      sx={{ my: 2, cursor: "pointer" }}
      onClick={() => onUserDetailOpen && onUserDetailOpen()}
    >
      <CardContent sx={{ "&:last-child": { paddingBottom: "16px" } }}>
        <Stack direction="row" alignItems={"center"}>
          <Avatar sx={{ width: 38, height: 38 }} />
          <Box sx={{ ml: 2, flex: 1 }}>
            <Stack direction="row" alignItems={"center"}>
              <Typography
                variant="body1"
                fontWeight={"bold"}
                fontSize="15px"
              >{`${first_name} ${last_name}`}</Typography>
              {country
                ? getFlagImage(
                    `https://www.countryflags.com/wp-content/uploads/${country}-flag-png-large.png`,
                    {
                      style: {
                        width: "18px",
                        height: "18px",
                        borderRadius: "15px",
                        marginLeft: "6px",
                        marginBottom: "0px",
                        objectFit: "cover",
                      },
                    }
                  )
                : ""}
            </Stack>
            <Stack
              direction={"row"}
              alignItems="center"
              justifyContent={"space-between"}
            >
              <Stack direction={"row"} sx={{ flex: 1 }} alignItems="center">
                <Note
                  sx={{ height: "14px", width: "14px", mr: 1 }}
                  color="disabled"
                />
                <Typography
                  variant="body2"
                  fontSize={"12px"}
                  alignItems={"center"}
                >
                  {from === "app" ? "Retailer App" : "Website"}
                </Typography>
              </Stack>
              {/* <IconButton size="small" sx={{}}>
                <Share sx={{ widht: "14px", height: "14px" }} />
              </IconButton> */}
            </Stack>
          </Box>
        </Stack>
      </CardContent>
    </Card>
  );
};

const UserDetailInfoCardMobile = ({ label, value, icon }) => {
  return (
    <Card sx={{ boxShadow: "0px 0px 2px 0px rgba(0,0,0,0.35)" }}>
      <CardContent sx={{ "&:last-child": { paddingBottom: "16px" } }}>
        <Typography variant="h5">{label}</Typography>
        <Stack
          direction="row"
          alignItems={"center"}
          justifyContent="space-between"
        >
          <Typography variant="h6">{value}</Typography>
          <IconButton>{icon && icon}</IconButton>
        </Stack>
      </CardContent>
    </Card>
  );
};

const UserDetailInfoCard = ({ Icon, label }) => {
  return (
    <Card sx={{ backgroundColor: "background.default", borderRadius: "8px" }}>
      <CardContent sx={{ "&:last-child": { paddingBottom: "16px" } }}>
        <Stack justifyContent={"center"} alignItems="center">
          <Icon style={{ height: "40px", width: "40px" }} />
          <Typography mt={2} varinat="h6">
            {label}
          </Typography>
        </Stack>
      </CardContent>
    </Card>
  );
};

const opportunityRadialBarOptions = {
  chart: {
    type: "radialBar",
    toolbar: {
      show: false,
    },
    width: "100%",
  },
  plotOptions: {
    radialBar: {
      startAngle: -135,
      endAngle: 225,
      hollow: {
        margin: 0,
        size: "70%",
        background: "#fff",
        image: undefined,
        imageOffsetX: 0,
        imageOffsetY: 0,
        position: "front",
        dropShadow: {
          enabled: true,
          top: 3,
          left: 0,
          blur: 4,
          opacity: 0.24,
        },
      },
      track: {
        background: "#CCFDB5",
        strokeWidth: "67%",
        margin: 0, // margin is in pixels
        dropShadow: {
          enabled: true,
          top: -3,
          left: 0,
          blur: 4,
          opacity: 0.35,
        },
      },

      dataLabels: {
        show: true,
        name: {
          offsetY: -10,
          show: true,
          color: "#888",
          fontSize: "14px",
        },
        value: {
          formatter: function (val) {
            return parseInt(val);
          },
          color: "#111",
          fontSize: "28px",
          show: true,
        },
      },
    },
  },
  fill: {
    type: "solid",
    colors: ["#21AC28"],
    gradient: {
      shade: "dark",
      type: "horizontal",
      shadeIntensity: 0.5,
      gradientToColors: ["#21AC28"],
      inverseColors: true,
      opacityFrom: 1,
      opacityTo: 1,
      stops: [0, 100],
    },
  },
  stroke: {
    lineCap: "round",
  },
  labels: ["Opportunity"],
};
const leadsRadialBarOptions = {
  chart: {
    type: "radialBar",
    toolbar: {
      show: false,
    },
    width: "100%",
  },
  plotOptions: {
    radialBar: {
      startAngle: -135,
      endAngle: 225,
      hollow: {
        margin: 0,
        size: "70%",
        background: "#fff",
        image: undefined,
        imageOffsetX: 0,
        imageOffsetY: 0,
        position: "front",
        dropShadow: {
          enabled: true,
          top: 3,
          left: 0,
          blur: 4,
          opacity: 0.24,
        },
      },
      track: {
        background: "#FFE5E5",
        strokeWidth: "67%",
        margin: 0, // margin is in pixels
        dropShadow: {
          enabled: true,
          top: -3,
          left: 0,
          blur: 4,
          opacity: 0.35,
        },
      },

      dataLabels: {
        show: true,
        name: {
          offsetY: -10,
          show: true,
          color: "#888",
          fontSize: "14px",
        },
        value: {
          formatter: function (val) {
            return parseInt(val);
          },
          color: "#111",
          fontSize: "28px",
          show: true,
        },
      },
    },
  },
  fill: {
    type: "solid",
    colors: ["#D93D3D"],
    gradient: {
      shade: "dark",
      type: "horizontal",
      shadeIntensity: 0.5,
      gradientToColors: ["#21AC28"],
      inverseColors: true,
      opacityFrom: 1,
      opacityTo: 1,
      stops: [0, 100],
    },
  },
  stroke: {
    lineCap: "round",
  },
  labels: ["Lead score"],
};

export const getLabelFromType = (type) =>
  type === "design_bank_product" || type === "design_bank"
    ? "Design Bank"
    : type === "catalog_product" || type === "catalog"
    ? "Catalog"
    : type === "website_product" || type === "website"
    ? "Website"
    : type === "sales_app_product" || type === "sales_app"
    ? "Sales App"
    : type === "quotation_product" || type === "quotation"
    ? "Quotation"
    : "";
