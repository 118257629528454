import { Box, Stack, Typography } from "@mui/material";
import React from "react";

const GoogleOverview = () => {
  return (
    <Box sx={{ p: 3, mb: 3 }}>
      <Typography variant="subtitle1" sx={{ fontSize: "16px", mb: 4 }}>
        Google integration enables you to open the door of your business to
        multiple features such as Google Analytics, Tag Manager, Merchant, etc.{" "}
      </Typography>
      <Typography variant="subtitle1" sx={{ fontSize: "16px", mb: 3 }}>
        Google offers a variety of tools that you can use to expand your
        jewellery company. Tools like Analytics, Tag Manager, Merchant, etc.,
        will help you significantly expand your business 🚀
      </Typography>
      <Typography variant="subtitle1" sx={{ fontSize: "16px" }}>
        By integrating Google with your e-commerce, you can utilise these
        integrations to locate and enhance your user base.
      </Typography>
    </Box>
  );
};

export default GoogleOverview;
