import React, { useEffect, useState } from "react";
import { memo } from "react";
import { ChatListRow } from "../../chat/ChatsSocial";
import { isArrayWithValues, isObjWithValues } from "../../../helper";
import { getChatsUsers } from "../apiFunctions";
import { StackRow } from "../../components/helper";
import { CircularProgress, Stack } from "@mui/material";

const ChatsCustomer = memo(({ selectedCustomer, params = {} }) => {
  const [user, setUser] = useState([]);
  const [loading, setLoading] = useState(true);
  let [pagination, setPagination] = useState({ page: 1, per_page: 100 });
  useEffect(() => {
    if (isArrayWithValues(selectedCustomer)) {
      setUser(selectedCustomer);
    }
    return () => {};
  }, [selectedCustomer]);

  useEffect(() => {
    let getChats = async () => {
      setLoading(true);
      let res = await getChatsUsers({
        params: {
          ...params,
          ...pagination,
        },
      });
      if (res?.success) {
        setUser((pre) => [...pre, ...(res?.data || [])]);
      }
      setLoading(false);
    };

    if (isObjWithValues(params)) getChats();
    return () => {
      setLoading(false);
      setUser([]);
    };
  }, [params, pagination]);

  return (
    <>
      {user?.map((obj) => (
        <ChatListRow customer={obj} />
      ))}
      {loading ? (
        <Stack
          direction={"row"}
          alignItems={"center"}
          gap={5}
          justifyContent={"center"}
        >
          <CircularProgress size={20} /> Fetching...
        </Stack>
      ) : null}
    </>
  );
});

export default ChatsCustomer;
