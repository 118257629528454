import axios from "axios";
import {
  apikey,
  backendApiCall,
  backendApiEndpoints,
  getAxiosError,
  liveLink,
} from "../../helper";

export const SET_HELPER_DATA = "SET_HELPER_DATA";

export const setHelperData = (payload) => ({ type: SET_HELPER_DATA, payload });

export const setGlobalToast = (payload) => ({
  type: SET_HELPER_DATA,
  payload: { globalToast: payload },
});
export const setGlobalSnackbar = (payload) => ({
  type: SET_HELPER_DATA,
  payload: { globalSnackbar: payload },
});

export const setGlobalTutorial = (payload) => ({
  type: SET_HELPER_DATA,
  payload: { globalTutorial: payload },
});
export const setGlobalPopup = (payload) => ({
  type: SET_HELPER_DATA,
  payload: { globalPopupStyle: payload },
});
export const predefinedTemplateHideCategory = [
  "Abandoned cart",
  "Order",
  "Thank you for order",
  "Delivery Confirmation",
  "Delay in delivery",
  "Payment received",
  "Forgot password",
];
export const getPredefinedEmailTemplate = () => {
  return async (dispatch) => {
    dispatch({
      type: SET_HELPER_DATA,
      payload: { predefinedEmailTemplateFetching: true },
    });
    try {
      let res = await axios({
        url: `${liveLink}/wp-json/store/v1/settings/predefined_email_templates`,
        headers: { apikey },
      });
      if (res?.data?.type === "success") {
        let templateObj = res?.data?.data;
        for (const tempkey of Object.keys(res?.data?.data || {})) {
          if (
            predefinedTemplateHideCategory?.includes(
              templateObj?.[tempkey]?.collection
            )
          )
            delete templateObj[tempkey];
        }
        dispatch({
          type: SET_HELPER_DATA,
          payload: {
            predefinedEmailTemplate: templateObj,
            predefinedEmailTemplateFetching: false,
          },
        });
      }
      console.log(res);
    } catch (error) {
      console.log(getAxiosError(error));
    }
    dispatch({
      type: SET_HELPER_DATA,
      payload: { predefinedEmailTemplateFetching: false },
    });
  };
};

// export const getPricingChnage = (metal) => {
//   return (dispatch, getState) => {
//     let state = getState()
//     let pricing = state.settings.masterPricing
//     let oldPricingObj = [`${metal}_pricing`]
//     // let defaultPurity = oldPricingObj[default_currency]?.default;
//     let defaultPurityAmount = oldPricingObj[default_currency]?.[oldPricingObj.type]

//     let obj = {
//       "gold": {
//         "995": {
//           "old": 234,
//           "new": 677
//         }
//       }
//     }
//   }
// }

//////////////////////////////
//////////////////////////////
//////////////////////////////

export const getMedia = ({ params, clear }) => {
  return async (dispatch, getState) => {
    dispatch({
      type: SET_HELPER_DATA,
      payload: {
        fetchingMedia: true,
      },
    });
    let state = getState();
    let media = state?.helper?.media || [];
    try {
      let response = await backendApiCall({
        endpoint: `${backendApiEndpoints?.media}`,
        method: "GET",
        params,
        extractDataFromResponse: true,
      });
      if (response) {
        // console.log(params);
        if (params?.page <= 1) {
        } else if (clear) {
        } else response = [...media, ...(response || [])];
        dispatch({
          type: SET_HELPER_DATA,
          payload: {
            media: response,
            mediaparams: params,
            fetchingMedia: false,
            noMoreMedia:
              params?.page <= 1 && (response || [])?.length <= 100
                ? true
                : false,
          },
        });
      }
    } catch (error) {
      console.log(getAxiosError(error));
    } finally {
      dispatch({
        type: SET_HELPER_DATA,
        payload: {
          fetchingMedia: false,
        },
      });
    }

    // console.log(response);
  };
};
