import { Visibility, VisibilityOff } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  FormHelperText,
  FormLabel,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getToken,
  getWebsite,
  isDev,
  isObjWithValues,
} from "../../../../helper";
import axios from "axios";
import { setGlobalToast } from "../../../../redux/actions/helperActions";
import { EDIT_SETTINGS } from "../../../../redux/actions/settingActions";
import { useTheme } from "styled-components";
import Switch from "rc-switch";

function Integration() {
  const theme = useTheme();
  const dispatch = useDispatch();
  let backgroundColor = theme.palette.primary && theme.palette.primary.main;

  const [formData, setFormData] = useState({
    enabled: "no",
    description: "",
    client_id: "",
    client_key: "",
    client_secret: "",
    client_data_encryption_key: "",
    gateway_module: "",
  });

  const [visibility, setVisibility] = useState({
    client_id: false,
    client_key: false,
    client_secret: false,
    client_data_encryption_key: false,
  });
  const [loading, setLoading] = useState(false);

  const reduxLoyltyRewardzIntegration = useSelector(
    (state) => state.settings.loyltyRewardzIntegration
  );
  const globalToast = useSelector((state) => state.helper.globalToast);
  const isPhone = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    if (isObjWithValues(reduxLoyltyRewardzIntegration))
      setFormData({ ...reduxLoyltyRewardzIntegration });
  }, [reduxLoyltyRewardzIntegration]);

  const onFormEdit = (payload) => {
    setFormData((pre) => ({ ...pre, ...payload }));
  };

  const onUpdateForm = async () => {
    let website = getWebsite();
    let token = getToken();
    if (!website && !token) return false;
    setLoading(true);
    let res = await axios({
      url: `${website}/wp-json/store/v1/settings/loylty_rewardz_integration`,
      method: "PUT",
      headers: {
        Authorization: `Basic ${token}`,
      },
      data: {
        setting: "loylty_rewardz_integration",
        data: formData,
      },
    });
    if (res.data.success) {
      dispatch(
        setGlobalToast({
          ...globalToast,
          show: true,
          message: "Loylty Rewardz setting saved successfully",
          severity: "success",
        })
      );
      dispatch({
        type: EDIT_SETTINGS,
        payload: {
          loyltyRewardzIntegration: res.data.data,
        },
      });
    } else {
      dispatch(
        setGlobalToast({
          ...globalToast,
          show: true,
          message: "Intregration Failed! Please try again.",
          severity: "error",
        })
      );
    }
    setLoading(false);
  };

  return (
    <Box sx={{ paddingLeft: "12px", paddingBottom: "25px" }}>
      <Stack
        direction={"row"}
        alignItems="center"
        sx={{ gap: "14px", mb: 2 }}
        justifyContent="space-between"
      >
        <Stack direction={"row"} alignItems="center" sx={{ gap: "14px" }}>
          <FormLabel variant="h6">Enable /Disabled</FormLabel>
          <Switch
            onChange={(e) => onFormEdit({ enabled: e ? "yes" : "no" })}
            checked={formData.enabled === "yes"}
            style={
              formData.enabled === "yes"
                ? {
                    border: `1px solid ${backgroundColor}`,
                    backgroundColor,
                  }
                : {}
            }
          />
        </Stack>
        <LoadingButton
          loading={loading}
          variant={"contained"}
          onClick={onUpdateForm}
          sx={{ mr: 4 }}
        >
          Update
        </LoadingButton>
      </Stack>
      <Stack spacing={7} sx={{ width: isPhone ? "100%" : "70%" }}>
        {/* <Stack>
          <FormLabel variant="h6">Title</FormLabel>
          <TextField
            value={formData.title}
            onChange={(e) => onFormEdit({ title: e.target.value })}
            size="small"
            placeholder="Title"
            helperText=""
          />
          <FormHelperText>This controls the title which the user sees during checkout</FormHelperText>
        </Stack> */}
        <Stack>
          <FormLabel variant="h6">Description</FormLabel>
          <TextField
            value={formData.description}
            onChange={(e) => onFormEdit({ description: e.target.value })}
            size="small"
            placeholder="Description"
            helperText=""
            multiline
            rows={3}
          />
          <FormHelperText>
            This controls the description which the user sees during checkout
          </FormHelperText>
        </Stack>
        <Stack>
          <FormLabel variant="h6">CLIENT ID</FormLabel>
          <TextField
            type={visibility.client_id ? "text" : "password"}
            value={formData.client_id}
            onChange={(e) => onFormEdit({ client_id: e.target.value })}
            size="small"
            placeholder="CLIENT ID"
            helperText=""
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    // aria-label="toggle password visibility"
                    onClick={() =>
                      setVisibility((pre) => ({
                        ...pre,
                        client_id: !visibility.client_id,
                      }))
                    }
                    // onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {visibility.client_id ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {/* <FormHelperText>CLIENT ID</FormHelperText> */}
        </Stack>
        <Stack>
          <FormLabel variant="h6">CLIENT KEY</FormLabel>
          <TextField
            type={visibility.client_key ? "text" : "password"}
            value={formData.client_key}
            onChange={(e) => onFormEdit({ client_key: e.target.value })}
            size="small"
            placeholder="CLIENT KEY"
            helperText=""
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    // aria-label="toggle password visibility"
                    onClick={() =>
                      setVisibility((pre) => ({
                        ...pre,
                        client_key: !visibility.client_key,
                      }))
                    }
                    // onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {visibility.client_key ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {/* <FormHelperText>CLIENT KEY</FormHelperText> */}
        </Stack>
        <Stack>
          <FormLabel variant="h6">CLIENT SECRET</FormLabel>
          <TextField
            type={visibility.client_secret ? "text" : "password"}
            value={formData.client_secret}
            onChange={(e) => onFormEdit({ client_secret: e.target.value })}
            size="small"
            placeholder="CLIENT SECRET"
            helperText=""
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    // aria-label="toggle password visibility"
                    onClick={() =>
                      setVisibility((pre) => ({
                        ...pre,
                        client_secret: !visibility.client_secret,
                      }))
                    }
                    // onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {visibility.client_secret ? (
                      <VisibilityOff />
                    ) : (
                      <Visibility />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {/* <FormHelperText>CLIENT SECRET</FormHelperText> */}
        </Stack>
        <Stack>
          <FormLabel variant="h6">CLIENT DATA ENCRYPTION KEY</FormLabel>
          <TextField
            type={visibility.client_data_encryption_key ? "text" : "password"}
            value={formData.client_data_encryption_key}
            onChange={(e) =>
              onFormEdit({ client_data_encryption_key: e.target.value })
            }
            size="small"
            placeholder="CLIENT DATA ENCRYPTION KEY"
            // helperText=""
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    // aria-label="toggle password visibility"
                    onClick={() =>
                      setVisibility((pre) => ({
                        ...pre,
                        client_data_encryption_key:
                          !visibility.client_data_encryption_key,
                      }))
                    }
                    // onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {visibility.client_data_encryption_key ? (
                      <VisibilityOff />
                    ) : (
                      <Visibility />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {/* <FormHelperText>ElitePay CLIENT DATA ENCRYPTION KEY</FormHelperText> */}
        </Stack>
        {isDev() && (
          <Stack>
            <FormLabel variant="h6">Gateway Mode</FormLabel>
            <Select
              size="small"
              value={formData.gateway_module}
              onChange={(e) => onFormEdit({ gateway_module: e.target.value })}
            >
              <MenuItem value="sandbox">Sandbox</MenuItem>
              <MenuItem value="production">Production</MenuItem>
            </Select>
            <FormHelperText>Mode of gateway subscription.</FormHelperText>
          </Stack>
        )}
      </Stack>
    </Box>
  );
}

export default Integration;
