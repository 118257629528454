import {
  Avatar,
  Card,
  CardContent,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListSubheader,
  MenuItem,
  Paper,
  Select,
  Skeleton,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Tabs,
  Typography,
  useTheme,
} from "@mui/material";
import ReactApexChart from "react-apexcharts";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CircularProgressWithLabel,
  dashboardCardSx,
  EmptyState,
  getCustomers,
  getFlagFromCountryCode,
  isArrayWithValues,
  isObjWithValues,
  jweroColorPalette,
  secondaryLabel,
  sectionLabel,
  validateNumber,
} from "../../../../helper";
import { LocationOnOutlined } from "@mui/icons-material";
import CustomChip from "../../../components/CustomChip";
import { useNavigate } from "react-router-dom";
import { VisitorRow } from "../../../newVisitors/components/helper";

export const ContactScoreCard = () => {
  const [selectedTab, setSelectedTab] = useState("all");
  const [customers, setCustomers] = useState([]);
  const [topScore, setTopScore] = useState(0);
  const [loading, setLoading] = useState(false);
  const visitorLeadScores = useSelector(
    (state) => state.visitor.visitorLeadScores
  );
  const navigate = useNavigate();

  useEffect(() => {
    if (isObjWithValues(visitorLeadScores)) {
      let { customer = {}, lead = {} } = visitorLeadScores;
      let customers = [];
      customer = [...(Object.keys(customer) || [])]
        ?.map((i) => ({
          ...customer?.[i],
          id: i,
          type: "customer",
        }))
        ?.sort((a, b) => b?.score - a?.score)
        ?.slice(0, 10);
      lead = [...(Object.keys(lead) || [])]
        ?.map((i) => ({
          ...lead?.[i],
          id: i,
          type: "lead",
        }))
        ?.sort((a, b) => b?.score - a?.score)
        ?.slice(0, 10);
      let allCustomers = Object.values(customer).concat(Object.values(lead));
      if (selectedTab === "all") customers = allCustomers;
      else if (selectedTab === "customer") customers = Object.values(customer);
      else if (selectedTab === "lead") customers = Object.values(lead);
      setCustomers(customers.sort((a, b) => b.score - a.score));
      setTopScore(Math.max(...allCustomers?.map((o) => o.score)));
    } else setCustomers([]);
  }, [visitorLeadScores, selectedTab]);
  useEffect(async () => {
    // setLoading(true);
    // let resCustomer = await getCustomers({ per_page: 20 });
    // setLoading(false);
    // setCustomers(resCustomer?.data || []);
  }, []);

  return (
    <Card sx={{ ...dashboardCardSx }}>
      <CardContent>
        <Typography sx={{ ...sectionLabel }}>Top Contacts by Score</Typography>
        <Tabs
          value={selectedTab}
          onChange={(e, newValue) => {
            setSelectedTab(newValue);
          }}
        >
          <Tab value={"all"} label={"All"} wrapped />
          <Tab value={"customer"} label={"Customer"} wrapped />
          <Tab value={"lead"} label={"Lead"} wrapped />
        </Tabs>

        {loading ? (
          new Array(5).fill(1).map((i, index) => {
            return (
              <React.Fragment key={index}>
                <Stack
                  direction="row"
                  alignItems="center"
                  sx={{ width: "100%" }}
                // sx={{ padding: "5px 10px" }}
                >
                  <Skeleton variant="circular" width={50} height={40} />
                  <Box sx={{ ml: 2, flexGrow: 1 }}>
                    <Skeleton variant="text" width={"90%"} height={25} />
                    <Skeleton
                      variant="text"
                      width={"75%"}
                      height={20}
                      sx={{ mt: 0.5 }}
                    />
                  </Box>
                  <Divider />
                </Stack>
              </React.Fragment>
            );
          })
        ) : isArrayWithValues(customers) ? (
          <>
            <List
              sx={{
                width: "100%",
                height: 475,
                overflow: "auto",
                bgcolor: "background.paper",
              }}
              subheader={
                <ListSubheader
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    borderBottom: "divider",
                  }}
                >
                  <div>Contact Name</div>
                  <div>Score</div>
                </ListSubheader>
              }
            >
              {customers?.map((obj) => {
                return (
                  <VisitorRow
                    visitorObj={obj}
                    key={obj?.id}
                    topScore={topScore}
                    onClick={() => {
                      // obj.type === "customer" && navigate(`/crm/${obj.id}`);
                      navigate(`/visitors_info/details/${obj.type === "customer" ? obj?.id : `_${obj?.id?.split("_")?.[1]}`}`)
                    }}
                  />
                );
                return (
                  <>
                    <ListItem
                      sx={{
                        cursor: obj.type === "customer" ? "pointer" : "default",
                      }}
                      onClick={() => {
                        obj.type === "customer" && navigate(`/crm/${obj.id}`);
                      }}
                    >
                      {/* <Divider /> */}
                      <ListItemIcon>
                        <Avatar
                          alt={`${obj.first_name}  ${obj.last_name}`}
                          sx={{
                            marginRight: 2,
                            height: "38px",
                            width: "38px",
                            // bgcolor: "#b6bed4",
                            fontSize: "14px",
                            background: "#b6bed4",
                            background:
                              //  isPaid  ? "linear-gradient(90deg,rgb(237 217 71) 0%,rgb(186 144 54) 100%)":
                              "linear-gradient(90deg, rgb(168 190 245) 0%, rgb(89 97 132) 100%)",
                          }}
                        >
                          {`${obj.first_name
                            ? obj.first_name[0].toUpperCase()
                            : ""
                            }${obj.last_name ? obj.last_name[0].toUpperCase() : ""
                            }`}
                        </Avatar>
                      </ListItemIcon>

                      {/* <Stack> */}

                      <Stack
                        direction={"row"}
                        alignItems={"center"}
                        justifyContent={"space-between"}
                        sx={{ width: "100%" }}
                      >
                        <Stack sx={{ flexGrow: 1 }}>
                          <Stack direction="row" spacing={2}>
                            <Typography
                              sx={{ fontWeight: "18px", fontWeight: "550" }}
                            >
                              {`${obj.first_name || ""}  ${obj.last_name || ""
                                }`.trim() || obj.username}
                            </Typography>
                            {obj.country
                              ? getFlagFromCountryCode(obj.country)
                              : null}
                          </Stack>
                          <Typography
                            sx={{
                              ...secondaryLabel,
                              alignItems: "center",
                              display: "flex",
                              gap: "5px",
                            }}
                          >
                            <CustomChip
                              status={obj?.type}
                              size="small"
                              type={
                                obj?.type === "lead"
                                  ? "completed"
                                  : "processing"
                              }
                            />
                            <LocationOnOutlined
                              fontSize="small"
                              color={"primary"}
                              style={{
                                opacity: "0.7",
                                width: "16px",
                                height: "16px",
                                // marginRight: "2px",
                              }}
                            />
                            {obj?.city || ""}
                          </Typography>
                        </Stack>
                        <CircularProgressWithLabel
                          value={(validateNumber(obj?.score) / topScore) * 100}
                          label={obj?.score}
                          thickness={5}
                        />
                      </Stack>
                      {/* </Stack> */}
                    </ListItem>
                    <Divider />
                  </>
                );
              })}
            </List>
          </>
        ) : (
          <EmptyState />
        )}
      </CardContent>
    </Card>
  );
};

export const ContactBy = () => {
  return (
    <>
      <Card>
        <CardContent>
          <Typography sx={{ ...sectionLabel }}>Contact by </Typography>
        </CardContent>
      </Card>
    </>
  );
};

export const RecentVisits = () => {
  const allOnlineVisitors = useSelector(
    (state) => state.visitor.allOnlineVisitors
  );
  console.log(allOnlineVisitors);
  return (
    <Box sx={{ mt: 3 }}>
      <Typography sx={{ ...sectionLabel }}>Recent visits</Typography>
      {Array.from(new Array(4).fill(1)).map((i) => (
        <VisitCard />
      ))}
    </Box>
  );
};

const VisitCard = ({ obj = {} }) => {
  return (
    <Card sx={{ dashboardCardSx, height: "auto" }}>
      <CardContent>
        <Stack direction={"row"} alignItems={"center"} gap={2}>
          <Avatar
            alt={`${obj.first_name}  ${obj.last_name}`}
            sx={{
              marginRight: 2,
              height: "38px",
              width: "38px",
              // bgcolor: "#b6bed4",
              fontSize: "14px",
              background: "#b6bed4",
              background:
                // isPaid
                // ? "linear-gradient(90deg,rgb(237 217 71) 0%,rgb(186 144 54) 100%)"  :
                "linear-gradient(90deg, rgb(168 190 245) 0%, rgb(89 97 132) 100%)",
            }}
          >
            {`${obj.first_name ? obj.first_name[0].toUpperCase() : ""}${obj.last_name ? obj.last_name[0].toUpperCase() : ""
              }`}
          </Avatar>

          <Stack></Stack>
        </Stack>
      </CardContent>
    </Card>
  );
};

export const ContactByLoaction = () => {
  const [series, setSeries] = useState([]);
  const options = {
    chart: {
      type: "pie",
    },
    colors: jweroColorPalette?.reverse(),
    labels: ["Mumbai", "Delhi", "Chennai", "pune"],
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };

  useEffect(() => {
    setSeries([44, 55, 41]);

    return () => { };
  }, []);
  return (
    <>
      <Card sx={{ ...dashboardCardSx }}>
        <CardContent>
          <Typography sx={{ ...sectionLabel }}>Conatct by Location</Typography>
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Tabs>
              <Tab value={"all"} label={"All"} />
              <Tab value={"customers"} label={"Customers"} />
              <Tab value={"Leads"} label={"Leads"} />
            </Tabs>
            <Select
              size="small"
              sx={{ minWidth: "106px" }}
              defaultValue={"city"}
            // value={age}
            // label="Age"
            // onChange={handleChange}
            >
              <MenuItem value={"city"}>By city</MenuItem>
              <MenuItem value={"country"}>By country</MenuItem>
              {/* <MenuItem value={30}>Thirty</MenuItem> */}
            </Select>
          </Stack>
          <ReactApexChart
            options={options}
            series={series}
            type="pie"
            width={"90%"}
          />
        </CardContent>
      </Card>
    </>
  );
};

export const CrmLeads = () => {
  const filtersTab = [
    { label: "All", value: "all" },
    { label: "New", value: "new" },
    { label: "Untouched", value: "untouched" },
    { label: "Inactive", value: "inactive" },
  ];

  const [selectedTab, setSelectedTab] = useState("all");
  return (
    <Card sx={{ ...dashboardCardSx }}>
      <CardContent>
        <Stack
          direction={"row"}
          alignContent={"center"}
          justifyContent={"space-between"}
          sx={{ width: "100%" }}
        >
          <Typography sx={{ ...sectionLabel }}>Leads</Typography>
        </Stack>
        <Tabs
          value={selectedTab}
          onChange={(e, newValue) => {
            setSelectedTab(newValue);
          }}
        >
          {filtersTab?.map((o) => (
            <Tab label={o.label} value={o?.value} />
          ))}
        </Tabs>
        <LeadList fetched={true} rows={[]} fetching={false} />
      </CardContent>
    </Card>
  );
};

const LeadList = ({ rows, fetched, fetching }) => {
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [page, setPage] = React.useState(0);
  const dispatch = useDispatch();
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      {fetching
        ? new Array(4).fill(1).map((str) => (
          <ListItem style={{}}>
            <Card
              sx={{
                padding: 3,
                width: "100%",
                display: "flex",
                alignItems: "center",
                gap: "20px",
                pr: { xs: 0, sm: 3 },
              }}
            >
              <Skeleton
                variant="circular"
                sx={{ height: "60px", width: "70px" }}
              />
              <Box sx={{ width: "100%" }}>
                <Skeleton
                  variant="text"
                  sx={{ fontSize: "1.3rem", width: "100%" }}
                />
                <Skeleton
                  variant="text"
                  sx={{ fontSize: "1rem", width: "80%" }}
                />
              </Box>
            </Card>
          </ListItem>
        ))
        : null}
      {fetched && !fetching && !isArrayWithValues(rows) ? (
        // <EmptyState icon={<CampaignSvg />} text="No campaigns found" />
        <EmptyState
          // icon={< />}
          text="No Leads found"
        />
      ) : null}
      {fetched && !fetching && isArrayWithValues(rows) && (
        <TableContainer component={Paper}>
          <Table sx={{}} aria-label="custom pagination table">
            <TableHead>
              <TableRow>
                {/* <TableCell>Sr.</TableCell> */}
                <TableCell sx={{ whiteSpace: "nowrap" }}>
                  Contact name
                </TableCell>
                <TableCell>Source</TableCell>
                <TableCell>Connected since </TableCell>
                <TableCell>Last activity</TableCell>
                <TableCell>Last touchpoint</TableCell>
                <TableCell>Score</TableCell>
                {/* <TableCell>Action</TableCell> */}
                {/* <TableCell>Location</TableCell> */}
                {/* <TableCell>IP</TableCell> */}
                {/* <TableCell align="right">Fat&nbsp;(g)</TableCell>
          <TableCell align="right">Protein&nbsp;(g)</TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {(rowsPerPage > 0
                ? rows.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
                : rows
              ).map((row, index) => {
                return <Row row={row} index={index} />;
              })}

              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
            {rows?.length > 5 || true ? (
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[5, { label: "All", value: -1 }]}
                    // colSpan={3}
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: {
                        "aria-label": "rows per page",
                      },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  // ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            ) : null}
          </Table>
        </TableContainer>
      )}
    </>
  );
};
const Row = ({ row, index }) => {
  // let [totalSent, setTotalSent] = useState(0);
  const theme = useTheme();
  const isDarkTheme = theme.palette && theme.palette.mode === "dark";
  // const [anchorEl, setAnchorEl] = React.useState(null);

  // const handleClick = (event) => {
  //     setAnchorEl(event.currentTarget);
  // };

  // const handleClose = () => {
  //     setAnchorEl(null);
  // };
  // const getCategoryName = (category) => {
  //     switch (category) {
  //         case "marketing":
  //             return "#4FD53A"
  //             break;
  //         case "promotional":
  //             return "#D2099A"
  //             break;
  //         case "tansactional":
  //             return "#2E5CC0"
  //             break;

  //         default:
  //             break;
  //     }

  // }

  // useEffect(() => {
  //     let { sent_info, total_customers, id } = row;
  //     if (!isObjWithValues(sent_info)) return setTotalSent(0);
  //     let total = validateNumber(total_customers),
  //         success = 0;
  //     Object.keys(sent_info).map((i) => {
  //         // total += sent_info[i]?.length || 0;
  //         if (i === "success") success = sent_info[i]?.length;
  //     });
  //     setTotalSent(validateNumber(Math.round((success / total) * 100)));
  //     console.log(
  //         total_customers,
  //         success,
  //         total,
  //         id,
  //         Math.round((success / total) * 100),
  //         success / total
  //     );
  // }, [row]);
  console.log(row);
  return (
    <TableRow key={row?.campaign_info?.id}>
      {/* <TableCell padding="checkbox" align="left">
        <Typography sx={{ ml: 3, fontWeight: "600" }}>{index + 1}</Typography>
      </TableCell> */}

      <TableCell component="th" scope="row">
        {/* <Typography
                    variant="h6"
                    sx={{ fontSize: "13px", whiteSpace: "nowrap" }}
                >
                    {row?.campaign_info?.name}
                   
                </Typography> */}
      </TableCell>

      <TableCell>
        {/* <CustomChip
                    label={row?.campaign_info?.draft ? "Draft" : row?.end_time ? "Completed" : "In Process"}
                    type={row?.campaign_info?.draft ? "Draft" : row?.end_time ? "completed" : "pending"}
                    //   color={row?.end_time ? "success" : "warning"}
                    size="small"
                    isDarkTheme={isDarkTheme}
                /> */}
      </TableCell>
      <TableCell component="th" scope="row">
        {/* <Typography
                    variant="h6"
                    sx={{ fontSize: "13px", whiteSpace: "nowrap", color: getCategoryName(row?.campaign_info?.category || "") }}
                >
                    {formatServerValue(row?.campaign_info?.category || "")}
                    
                </Typography> */}
      </TableCell>
      <TableCell component="th" scope="row">
        {/* <Typography
                    variant="h6"
                    sx={{ fontSize: "13px", whiteSpace: "nowrap" }}
                >
                    {row.start_time
                        ? formatDate(new Date(row.start_time), "dd:mm:yyyy")
                        : ""}
                </Typography>
                <Typography

                    sx={{ fontSize: "13px", whiteSpace: "nowrap" }}
                >
                    {row.start_time
                        ? formatDate(new Date(row.start_time), "hh:mm am/pm")
                        : ""}
                </Typography> */}
      </TableCell>
      <TableCell>
        {/* <Typography
                    variant="h6"
                    sx={{ fontSize: "13px", whiteSpace: "nowrap" }}
                >
                    {customerSegments?.[row?.campaign_info?.segment_id]?.filterForm?.segment_name}
                   
                </Typography>
                <Typography

                    sx={{ fontSize: "13px", whiteSpace: "nowrap" }}
                >
                    {row?.total_customers ? `${row?.total_customers} contacts` : "-"}
                
                </Typography> */}
      </TableCell>
      <TableCell style={{ minWidth: "70px" }}>
        {/* {row?.sent_info ? <>

                    <Typography variant="h6"
                        sx={{ fontSize: "13px", whiteSpace: "nowrap" }}>{row?.sent_info?.success?.length} Contacts</Typography>
                    <Stack direction={"row"} alignItems={"center"} width={"100%"} gap={1}>
                        <Typography

                            sx={{ fontSize: "13px", whiteSpace: "nowrap" }}
                        >
                            {totalSent}%
                        </Typography>
                        <LinearProgress sx={{ flexGrow: 1, borderRadius: "5px" }} variant="determinate" value={Number(totalSent)} color={"success"} />
                    </Stack>
                </> : "-"} */}
      </TableCell>
      {/* <TableCell>
                <IconButton onClick={(e) => {
                    handleClick(e)
                }}>
                    <MoreVert />
                </IconButton>
            </TableCell> */}
      {/* <TableCell>
        <Typography
          // variant="h6"
          fontSize={"11px"}
          sx={{ mt: 0.5 }}
        >
          {isObjWithValues(row.location)
            ? `${row.location.city || ""} ${row.location.state || row.location.region || ""
            } ${row.location.country}`
            : ""}
        </Typography>
      </TableCell> */}
      {/* <CampaignOption anchorEl={anchorEl} handleClose={handleClose} row={row} /> */}
    </TableRow>
  );
};
