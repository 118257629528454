import { Card, CardContent, Typography } from '@mui/material';
import { useCallback } from 'react';
import { Handle, Position } from 'reactflow';
import { WhatsappSvg } from '../../../Svgs';
import CustomHandle from './CustomHandle';

const handleStyle = { left: 10 };

function FlowCard({ data, component }) {
    const onChange = useCallback((evt) => {
        console.log(evt.target.value);
    }, []);

    return (
        <Card>
            <CardContent>

                <Handle type="target" position={Position.Top} />
                {component}
                {/* <Typography sx={{ display: "flex", alignItems: "center", gap: 2 }}> <WhatsappSvg /> WhatsApp</Typography> */}

                {/* <div>
                <label htmlFor="text">Text:</label>
                <input id="text" name="text" onChange={onChange} className="nodrag" />
            </div> */}
                <Handle type="source" position={Position.Bottom} id="a" />
                <Handle type="source" position={Position.Bottom} id="b" style={handleStyle} />
            </CardContent>
        </Card>
    );
}

export default FlowCard;