import { Popover, Box } from "@mui/material";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";

const EmojiPopover = ({ anchorEl, setAnchorEl, handleEmojiSelect }) => {
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      style={{
        zIndex: 99999,
      }}
    >
      <Box
      // sx={{ maxHeight: "300px", maxWidth: "280px" }}
      >
        <Picker
          data={data}
          onEmojiSelect={handleEmojiSelect}
          previewPosition="none"
          height="100px"
          style={{
            maxHeight: "200px",
            width: "280px",
          }}
        />
      </Box>
    </Popover>
  );
};

export default EmojiPopover;
