import { format } from "date-fns";

export const formatDate = (date, dateFormat) => {
  if (isValidDate(date)) {
    format(date, dateFormat);
  }
  return false;
};
export function isValidDate(d) {
  return d instanceof Date && !isNaN(d);
}

function isValidMySQLDate(dateStr) {
  const regex = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/;
  return regex.test(dateStr);
}
const mysqlDateToJS = (date) => {
  let newDate = "";
  if (typeof date === "string") {
    if (isValidMySQLDate(date)) {
      let jsDate = date + "Z";
      newDate = new Date(jsDate);
    } else if (isValidDate(date)) {
      newDate = new Date(date);
    }
  }
  return newDate;
};

export { mysqlDateToJS };
