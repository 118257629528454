import { LoadingButton } from "@mui/lab";
import { Box, Button, Stack, Typography } from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UAParser } from "ua-parser-js";
import { getIp } from "../../../../App";
import {
  getToken,
  getWebsite,
  TabPanel,
  getWooCommerceCustomersHelper,
  defaultWoocommerceCustomerMapping,
  batchCreateCustomer,
  createObjFromMapping,
  getCRMList,
  getRandomString,
  fetchIPInfo,
  isObjWithValues,
  settingsEndpoint,
  secondaryLabel,
} from "../../../../helper";
import { editSettings } from "../../../../redux/actions/settingActions";
import { WooCommerceImport } from "../../../../Svgs";
import {
  AnalyticsCard,
  ImportingCustomer,
} from "../shopify/components/AllCustomer";
const tabs = [
  // {
  //     label: "Overview",
  //     value: "overview",
  // },
  // {
  //     label: "Mapping",
  //     value: "mapping",
  // },
  {
    label: "Customers",
    value: "customers",
  },
  // { label: "Settings", value: "settings" },
  // { label: "Activity", value: "history" },
];
const Import = () => {
  const [selectedTab, setSelectedTab] = useState("customers");
  return <CustomerImport />;
  return (
    <Box>
      <Stack
        direction="row"
        sx={{
          backgroundColor: "Background.paper",
          padding: "10px",
          borderBottomColor: "#e8e8e8",
          borderBottomWidth: 0.2,
          borderBottomStyle: "solid",
          overflow: "scroll",
        }}
        spacing={4}
        className="scrollbar-hidden"
      >
        {tabs.map((i) => {
          return (
            <Button
              variant={"text"}
              // variant={selectedTab !== i.value ? "outlined" : "contained"}
              sx={{
                backgroundColor:
                  selectedTab === i.value ? "#e6e6e6" : "transparent",
                color: "#000",
                // color: selectedTab === i.value ? "primary.main" : "#000",
                "&:hover": {
                  backgroundColor:
                    selectedTab === i.value ? "#e6e6e6" : "transparent",
                },
              }}
              onClick={() => setSelectedTab(i.value)}
            >
              {i.label}
            </Button>
          );
        })}
      </Stack>
      <TabPanel index={selectedTab} value={"customers"}>
        <CustomerImport />
      </TabPanel>
    </Box>
  );
};

export default Import;

const CustomerImport = () => {
  const [importingState, setImportingState] = useState({});
  const wooCommerceMapping = useSelector(
    (state) => state.settings.woocommerceMapping
  );

  const wooCommerceIntegration =
    useSelector((state) => state.settings.wooCommerceIntegration) || {};

  const dispatch = useDispatch();

  const getAllCustomers = async ({
    wooCommerceIntegration,
    setSyncProgress,
  }) => {
    let fetchBatchLength = 100;
    // let batchLength = parseInt(total / fetchBatchLength) + 1;
    let getProductsTotalProgress = 30;
    // let addToProgress = Math.round(getProductsTotalProgress / batchLength);

    let page = 1;
    let allCustomers = [];
    for await (let i of Array(5000)) {
      // for await (let i of Array(1)) {
      let customers = await getWooCommerceCustomersHelper({
        ...wooCommerceIntegration,
        params: { per_page: fetchBatchLength, page },
      });
      allCustomers = allCustomers.concat(customers?.data);
      // setSyncProgress((state) =>
      //     state + addToProgress >= getProductsTotalProgress
      //         ? getProductsTotalProgress
      //         : state + addToProgress
      // );
      page += 1;
      console.log(customers);
      if (customers?.data?.length < fetchBatchLength) break;
    }
    return allCustomers;
  };

  const addHistory = async ({ selected, resArray, website, type }) => {
    let historyObj = {
      id: getRandomString(8),
      start: Math.floor(Date.now() / 1000),
      ip: await getIp(),
      ua: new UAParser().getUA(),
      import_info: {},
      // ip:
      // imported: 0,
      // products: [],
    };
    let location = await fetchIPInfo(historyObj.ip);
    if (isObjWithValues(location)) {
      let { city, region, postal, country } = location;
      historyObj.location = {
        city,
        state: region,
        postcode: postal,
        country,
      };
    }
    let import_info = {};

    for (let index = 0; index < selected?.length; index++) {
      const woocommerceCustomer = selected?.[index];

      let jeroCustomer = resArray?.find(
        (obj) =>
          obj?.woocommerce_customer?.woocommerce_id == woocommerceCustomer?.id
      );

      if (jeroCustomer) {
        import_info.success = [
          ...(import_info.success || []),
          { woocommerce_id: woocommerceCustomer?.id },
        ];
      }
      try {
        if (isObjWithValues(resArray?.[index]?.error)) {
          import_info[resArray?.[index]?.error?.code?.replaceAll("-", "_")] = [
            ...(import_info[
              resArray?.[index]?.error?.code?.replaceAll("-", "_")
            ] || []),
            { woocommerce_id: woocommerceCustomer?.id },
          ];
        }
      } catch (error) {
        console.log(error);
      }
    }

    historyObj.import_info = import_info;

    let res = await settingsEndpoint({
      endpoint: `woocommerce_history_${website?.replaceAll(".", "_")}`,
      method: "PUT",

      data: {
        [historyObj?.id]: {
          ...historyObj,
          type,
        },
      },
    });
    dispatch(
      editSettings({
        payload: {
          woocommerceHistory: res,
        },
      })
    );
    console.log(res);
  };

  const onImporting = async () => {
    setImportingState((pre) => ({ ...pre, loading: true }));
    //getting all Woocom customer
    let allCustomers = await getAllCustomers({
      wooCommerceIntegration,
    });
    //get customer witch already impotred in jewriCrm
    let crmCustomer = await getCRMList({
      fields: "woocommerce_customer",
    });
    // making array of ids with in crm
    let previousImportedIds = Object.values(crmCustomer || {})?.map(
      (obj) => obj?.woocommerce_customer?.woocommerce_id
    );

    //filtering customer which have to create
    let haveToImport = allCustomers?.filter(
      (obj) => !previousImportedIds?.includes(obj?.id)
    );

    // batch creating
    // let countArray = new Array(1)?.fill("")?.map((str, index) => index)
    let countArray = new Array(Math.ceil(haveToImport?.length / 100))
      ?.fill("")
      ?.map((str, index) => index);
    let arr = [];
    console.log([
      ...(wooCommerceMapping?.customers?.mapping || []),
      ...defaultWoocommerceCustomerMapping,
    ]);
    for await (const index of countArray) {
      let batchArray = haveToImport
        ?.slice(index * 100, index * 100 + 100)
        ?.map((obj) => {
          let jweroCustomer = createObjFromMapping({
            mapping: [
              ...(wooCommerceMapping?.customers?.mapping || []),
              ...defaultWoocommerceCustomerMapping,
            ],
            payload: obj,
          });
          jweroCustomer.password = "password";
          jweroCustomer.meta_data = [
            ...(jweroCustomer.meta_data || []),
            {
              key: "woocommerce_customer",
              value: {
                woocommerce_id: obj?.id,
              },
            },
          ];

          return jweroCustomer;
        });
      console.log(batchArray);
      // let createRes = await batchCreateCustomer(batchArray);
      // let customerArr = createRes?.data?.create?.map((obj) => {
      //     for (const metaObj of obj?.meta_data || []) {
      //         obj[metaObj?.key] = metaObj?.value;
      //     }
      //     return obj;
      // }) || [];
      // arr = [...arr, ...(customerArr || [])]
    }
    // addHistory({
    //     selected: haveToImport,
    //     resArray: arr,
    //     ...(wooCommerceIntegration || {}),
    //     type: "customer",
    // });
    setImportingState((pre) => ({ ...pre, loading: false }));
    console.log(allCustomers);
  };

  return (
    <>
      <Box sx={{ px: 2, mt: 3 }}>
        <Stack
          alignItems={"center"}
          justifyContent={"center"}
          rowGap={3}
          gap={3}
        >
          <WooCommerceImport height={300} width={300} />
          <Typography
            variant="h6"
            color={"text.secondary"}
            sx={{ width: "40%", textAlign: "center", lineHeight: 1.6 }}
          >
            Import your customer from your Woocommerce store to Jwero CRM
          </Typography>
          <LoadingButton
            loading={importingState?.loading}
            size="large"
            variant="contained"
            sx={{ mt: 3 }}
            onClick={() => {
              onImporting();
            }}
          >
            Sync Customer
          </LoadingButton>
        </Stack>
        {/* <ImportingCustomer loading={importingState?.loading} />
            <Stack direction="row" spacing={3} my={3} justifyContent="flex-end">
                <Button
                    variant="outlined"
                    onClick={() => {
                        onImporting();
                    }}
                >
                    Import all customers
                </Button>
            </Stack>
            <Stack direction="row" spacing={3}>
                <AnalyticsCard title="Customers In Shopify" value="344" />
                <AnalyticsCard title="Customers Imported" value="4" />
                <AnalyticsCard title="Customers In Jwero" value="532" />
            </Stack> */}
      </Box>
    </>
  );
};
