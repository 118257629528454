import { useEffect, useState } from "react";
import { Typography, Popover, Box, Button } from "@mui/material";
import { askUntilStyles } from "../styles/askUntilStyles";

const styles = askUntilStyles;

const AskUntil = ({ value, questionId, questions, setQuestions }) => {
  const [popoverAnchorEl, setPopoverAnchorEl] = useState(null);
  const isPopoverOpen = Boolean(popoverAnchorEl);

  const handlePopoverOpen = (e) => {
    setPopoverAnchorEl(e.currentTarget);
  };

  const handleButtonClicked = (e, value) => {
    setQuestions((prevQuestions) => {
      return prevQuestions.map((question) => {
        if (question.id === questionId) {
          const updatedQuestion = { ...question };
          updatedQuestion.askUntil = value;
          return updatedQuestion;
        }
        return question;
      });
    });
    // setValue(e.target.innerText)
    handlePopoverClose();
  };

  const handlePopoverClose = () => {
    setPopoverAnchorEl(null);
  };

  const valueLabels = {
    "-1": "Until asked",
    1: "Once",
    2: "2 times",
    3: "3 times",
    4: "4 times",
    5: "5 times",
  };

  return (
    <div>
      <Typography sx={styles.text}>
        Ask
        <span onClick={handlePopoverOpen} style={styles.span}>
          {valueLabels?.[value?.toString()]}
        </span>
        &nbsp;if the user reply is invalid
      </Typography>

      <Popover
        open={isPopoverOpen}
        anchorEl={popoverAnchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
      >
        <Box sx={styles.outerBox}>
          <Button
            variant="standard"
            sx={styles.button}
            onClick={(e) => handleButtonClicked(e, -1)}
          >
            Until filled
          </Button>
          <Button
            variant="standard"
            sx={styles.button}
            onClick={(e) => handleButtonClicked(e, 1)}
          >
            Once
          </Button>
          <Button
            variant="standard"
            sx={styles.button}
            onClick={(e) => handleButtonClicked(e, 2)}
          >
            2 times
          </Button>
          <Button
            variant="standard"
            sx={styles.button}
            onClick={(e) => handleButtonClicked(e, 3)}
          >
            3 times
          </Button>
          <Button
            variant="standard"
            sx={styles.button}
            onClick={(e) => handleButtonClicked(e, 4)}
          >
            4 times
          </Button>
          <Button
            variant="standard"
            sx={styles.button}
            onClick={(e) => handleButtonClicked(e, 5)}
          >
            5 times
          </Button>
        </Box>
      </Popover>
    </div>
  );
};

export default AskUntil;
