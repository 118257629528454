import { Box, Card, Divider, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
    arrayMove,
    getId,
    isArrayWithValues,
    isObjWithValues,
    secondaryLabel,
    settingsEndpoint,
} from "../../../../helper";
import { useDispatch, useSelector } from "react-redux";
import CustomSwitch from "../../../components/CustomeSwitch";
import { DragIndicator } from "@mui/icons-material";
import SortableList, { SortableItem } from "react-easy-sort";
import { LoadingButton } from "@mui/lab";
import { EDIT_SETTINGS } from "../../../../redux/actions/settingActions";

import SmallSwitch from "../../../components/SmallSwitch";
import { setGlobalToast } from "../../../../redux/actions/helperActions";
import { ListItem } from "../meta/MetaOverview";

const teamMemberContent = [
    "1) Drag and arrange the names of your team members in the order of priority for receiving incoming calls.",
    "2)The first team member on the list will be contacted first. If they don't answer, the call will be forwarded to the second team member.",
    `3) If the second team member also fails to answer or declines the call, the call will be forwarded to the third team member.`,
    `4) If the third team member also fails to answer or decline the call, a pre-recorded message will be played to the caller indicating that all team members are currently busy, and the call will be disconnected.`,
    `5) For the second incoming call, the first team member on the list will be contacted again.`,
];

const Settings = () => {
    const reduxTeamMembers = useSelector((state) => state.settings.teamMembers);
    const admin = useSelector((state) => state.user?.userAdminData);
    const isAdmin = useSelector((state) => state.user.isAdmin);

    // userAdminData

    const exotelIntegration = useSelector(
        (state) => state.settings.exotelIntegration
    );
    // const [teamMembers, setTeamMember] = useState([]);
    const dispatch = useDispatch();
    const [settingState, setSettingState] = useState({});

    const editSettngs = (payload) => {
        setSettingState((pre) => ({ ...pre, ...payload }));
    };

    const [loading, setLoading] = useState(false);
    useEffect(() => {
        if (isArrayWithValues(reduxTeamMembers)) {
            console.log("update setting");
            setSettingState({
                ...(exotelIntegration?.settings || {}),
                phones:
                    exotelIntegration?.settings?.phones?.map((o) => {
                        let member =
                            reduxTeamMembers?.find((t) => t?.id == o.id) ||
                            [admin]?.find((a) => a?.id == o.id);

                        if (member) {
                            o.first_name = member?.first_name;
                            o.last_name = member?.last_name;
                        }
                        return o;
                    }) ||
                    reduxTeamMembers
                        ?.map((o) => {
                            let { id, phone, dialcode, first_name, last_name } = o;
                            return {
                                id,
                                phone,
                                dialcode,
                                first_name,
                                last_name,
                                enabled: false,
                            };
                        })
                        ?.concat(
                            isObjWithValues(admin)
                                ? [
                                    {
                                        first_name: admin?.first_name,
                                        last_name: admin?.last_name,
                                        id: admin?.id,
                                        phone: admin?.mobile_no,
                                        dialcode: admin?.dialcode_mobile,
                                    },
                                ]
                                : []
                        ),
            });
        }
    }, [reduxTeamMembers, exotelIntegration, admin]);
    const onSortEnd = (oldIndex, newIndex) => {
        console.log("sort");
        let newArr = arrayMove(settingState?.phones, oldIndex, newIndex);

        editSettngs({ phones: isAdmin ? newArr : settingState?.phones });
    };
    const onSwitchChange = (e, o) => {
        let phones = settingState?.phones || [];
        editSettngs({
            phones: phones?.map((obj) => {
                if (o?.id == obj?.id) obj.enabled = e;
                return obj;
            }),
        });
    };

    const onSave = async () => {
        setLoading(true);

        let settings = {
            ...(settingState || {}),
            phones: { ...(settingState || {}) }?.phones?.map((o) => {
                // delete o.first_name;
                // delete o.last_name;
                let { id, phone, dialcode, enabled } = o;
                return {
                    id,
                    phone,
                    dialcode,
                    // first_name,
                    // last_name,
                    enabled,
                };
            }),
        };
        console.log(settingState?.phones);
        let res = await settingsEndpoint({
            endpoint: "exotel_integration",
            method: "PUT",
            data: {
                settings,
            },
        });
        console.log(settingState?.phones);
        if (res) {
            await dispatch({
                type: EDIT_SETTINGS,
                payload: {
                    exotelIntegration: res,
                },
            });
            dispatch(
                setGlobalToast({
                    show: true,
                    message: "Exotel Settings has been saved successfully",
                    severity: "success",
                })
            );
        }
        setLoading(false);
    };

    return (
        <Box sx={{ px: 3 }}>
            <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"flex-end"}
            >
                <LoadingButton
                    loading={loading}
                    onClick={() => {
                        onSave();
                    }}
                    variant="contained"
                >
                    Save
                </LoadingButton>
            </Stack>
            <Stack
                direction={"row"}
                alignItems="flex-start"
                justifyContent="space-between"
                sx={{ mt: 5 }}
            >
                <Stack gap={2} sx={{ width: "60%" }}>
                    <Typography variant="h5" fontSize="15px">
                        Manage Team Member
                    </Typography>

                    <Typography variant="subtitle1" sx={{ ...secondaryLabel }}>
                        {/* Set priority by dragging Team Member name who will receive the incoming call first. */}
                        You can arrange the priority of incoming calls for your team members
                    </Typography>
                    <ul style={{ padding: "0 18px", listStyle: "none" }}>
                        {teamMemberContent?.map((str) => (
                            <li key={str} style={{ marginTop: "4px" }} >{str}</li>
                        ))}
                    </ul>
                </Stack>
                <Box sx={{ width: "40%" }}>
                    <SortableList
                        onSortEnd={onSortEnd}
                        className="list"
                        draggedItemClassName="dragged"
                        style={{ display: "flex", flexDirection: "column", gap: "8px" }}
                    >
                        {settingState?.phones?.map((o, index) => (
                            <SortableItem key={o.id}>
                                <div className="item">
                                    <TeamMemberRow
                                        data={o}
                                        key={o.id}
                                        index={index}
                                        onSwitchChange={onSwitchChange}
                                        settingState={settingState}
                                        isAdmin={isAdmin}
                                        teamMembers={reduxTeamMembers}
                                    />
                                </div>
                            </SortableItem>
                        ))}
                    </SortableList>
                </Box>
            </Stack>
            <Divider sx={{ my: 3 }} />
            {isAdmin ? (
                <>
                    <Stack
                        direction={"row"}
                        alignItems="flex-start"
                        justifyContent="space-between"
                        sx={{ mt: 5, gap: 3 }}
                    >
                        <Stack sx={{ width: "60%" }}>
                            <Typography variant="h5" fontSize="15px">
                                Self Call Management
                            </Typography>
                            <Typography variant="subtitle1" sx={{ ...secondaryLabel }}>
                                1)  Allow Team Members to Set their Availability Status. If Enabled
                                then Team Members will be able to manage their status from their
                                dashboard. If disabled, Only Jeweller can manage/set the
                                Availablity of Team Member.
                            </Typography>
                            <Typography variant="subtitle1" sx={{ ...secondaryLabel }}>
                                2)  This feature can be useful when team members are on leave or
                                unavailable for any reason.
                            </Typography>
                            {/* <Typography variant="h6" >
                             
                                Allow Team Members to Set their Availability Status. If Enabled then Team Members will be able to manage their status from their dashboard. If disabled, Only Jeweller can manage/set the Availablity of Team Member.
                            </Typography>
                            <Typography variant="subtitle1" >
                                This feature can be useful when team members are on leave or unavailable for any reason.
                            </Typography> */}
                        </Stack>
                        {/* <Box sx={{ width: "30%" }}> */}
                        <CustomSwitch
                            checked={settingState?.self_enable}
                            onChange={(e) =>
                                editSettngs({
                                    self_enable: e,
                                })
                            }
                        />

                        {/* </Box> */}
                    </Stack>
                    <Divider sx={{ my: 3 }} />
                    <Stack
                        direction={"row"}
                        alignItems="flex-start"
                        justifyContent="space-between"
                        sx={{ mt: 5, }}
                    >
                        <Stack sx={{ width: "60%" }}>
                            <Typography variant="h5" fontSize="15px">
                                Assign CRM Contacts to their Team members
                            </Typography>
                            <Typography variant="subtitle1" sx={{ ...secondaryLabel }}>
                                Allow Team members to receive their assigned CRM contact's call
                                first. If not accepted, then the call will be transferred to
                                another team as per priority set.
                            </Typography>
                        </Stack>
                        {/* <Box sx={{ width: "30%" }}> */}
                        <CustomSwitch
                            checked={settingState?.team_member_priority}
                            onChange={(e) =>
                                editSettngs({
                                    team_member_priority: e,
                                })
                            }
                        />

                        {/* </Box> */}
                    </Stack>
                </>
            ) : null}
        </Box>
    );
};

export default Settings;

const TeamMemberRow = ({
    data,
    index,
    onSwitchChange,
    settingState,
    isAdmin,
    teamMembers,
}) => {
    console.log(getId());
    let userId = getId() || "";

    if (!isAdmin && !userId?.toString()?.includes(data?.id)) return <></>;
    // if (!isAdmin && !teamMembers?.map(o => o.id)?.includes(data?.id)) return <></>

    return (
        <Card
            sx={{
                p: 2,
                boxShadow:
                    "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
            }}
        >
            {/* <CardContent> */}
            <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
            >
                <Stack direction={"row"} alignItems={"center"} gap={2}>
                    <DragIndicator style={{ cursor: "pointer" }} />
                    {/* <Typography variant="subtitle2">{index + 1})</Typography> */}
                    <Typography>
                        {data?.first_name}
                        {data?.lastt_name}
                    </Typography>
                </Stack>
                <Stack direction={"row"} alignItems={"center"} gap={2}>
                    <SmallSwitch
                        disabled={!isAdmin && !settingState?.self_enable}
                        // disabled={disabled}
                        onChange={(e) => onSwitchChange(e, data)}
                        checked={data?.enabled}
                    />
                </Stack>
            </Stack>
            {/* </CardContent> */}
        </Card>
    );
};
