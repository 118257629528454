import { Box, Card, CardContent, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useSelector } from "react-redux";
import {
  formatServerValue,
  globalCardStyle,
  globalColors,
  isObjWithValues,
  sortObjectByValues,
  sumupElementsAfterIndex,
} from "../../../helper";

function ProductsPulledPie() {
  const [info, setInfo] = useState({
    series: [],
    labels: [],
  });
  const designbankProductsAnalytics = useSelector(
    (state) => state.supplier.designbankProductsAnalytics
  );
  const allSuppliers = useSelector((state) => state.supplier.allSuppliers);

  useEffect(() => {
    if (!isObjWithValues(designbankProductsAnalytics)) return;
    let { totalProductsPulledByCollection } = designbankProductsAnalytics;
    if (!isObjWithValues(totalProductsPulledByCollection)) return;
    let sortedObj = sortObjectByValues(totalProductsPulledByCollection, true);
    let max = 4;
    let series = Object.values(sortedObj);
    let labels = Object.keys(sortedObj).slice(0, max);
    if (series.length >= max) {
      series = sumupElementsAfterIndex(series, max);
      labels = labels.concat(["Other"]);
    }
    setInfo({
      series: series,
      labels: labels.map((i) => formatServerValue(i)),
    });
  }, [designbankProductsAnalytics, allSuppliers]);

  return (
    <Card sx={{ ...globalCardStyle }}>
      <CardContent>
        <Typography variant="h6" sx={{ mb: 3 }}>
          Total products pulled
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <ReactApexChart
            options={{ ...options, labels: info.labels }}
            series={info.series}
            type="pie"
            height={320}
            width={380}
          />
        </Box>
      </CardContent>
    </Card>
  );
}

export default ProductsPulledPie;

const options = {
  chart: {
    width: 380,
    type: "pie",
  },
  colors: globalColors,
  legend: {
    position: "bottom",
  },
  // labels: ["Mayfair", "Ratnalaya Jewellers", "Go gems"],
  responsive: [
    {
      breakpoint: 480,
      options: {
        chart: {
          width: 200,
        },
        legend: {
          position: "bottom",
        },
      },
    },
  ],
};
