import React, { useContext } from "react";
import {
    EmailCampaignSvg,
    PushNotificationSvg,
    SMSSvg,
    WhatsappSvg,
} from "../../Svgs";

import {
    Box,
    Divider,
    FormHelperText,
    IconButton,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import { EmptyState, isObjWithValues } from "../../helper";
import {
    CampaignInfo,
    DelayInfo,
    EmailInfo,
    PushNotificationInfo,
    SMSInfo,
    WhatsappInfo,
} from "./components/CampaignSideBar";
import { Label } from "../customers/components/Info";
import { FlowStateContext } from ".";
import { Close, HourglassBottomRounded } from "@mui/icons-material";
import { campaignChannelIcon } from "../customers/components/CampaignHistory";

export const campaignChannels = [
    {
        label: "WhatsApp",
        icon: <WhatsappSvg />,
        value: "WhatsappNode",
        type: "whatsapp",
    },
    { label: "SMS", icon: <SMSSvg />, value: "SmsNode", type: "sms" },
    {
        label: "Email",
        icon: <EmailCampaignSvg />,
        value: "EmailNode",
        type: "email",
    },
    {
        label: "Push Notification",
        icon: <PushNotificationSvg />,
        value: "PushNotificationNode",
        type: "push_notification",
    },
];
const SideBar = ({ selectedNode, selectedEdge }) => {
    const { campaignState, setSelectedNode, onEditForm, setSelectedEdge } =
        useContext(FlowStateContext);
    let componentsObject = {
        "Campaign Info": <CampaignInfo nodeData={selectedNode} />,
        WhatsApp: <WhatsappInfo nodeData={selectedNode} />,
        SMS: <SMSInfo />,
        "Push Notification": <PushNotificationInfo />,
        Email: <EmailInfo />,
    };

    return (
        <>
            {isObjWithValues(selectedEdge) ? (
                <>
                    <Stack
                        direction={"row"}
                        alignItems={"center"}
                        justifyContent={"space-between"}
                        sx={{ padding: "20px 12px" }}
                    >
                        <Typography
                            variant="h6"
                            sx={{ display: "flex", alignItems: "center", gap: 2 }}
                        >
                            {/* {campaignChannelIcon?.[]} */}
                            <HourglassBottomRounded />
                            Delay
                            {/* {selectedNode?.icon ||
                                campaignChannelIcon?.[selectedNode?.channel]}
                            {selectedNode?.heading} */}
                        </Typography>
                        <IconButton onClick={() => setSelectedEdge(false)}>
                            <Close />
                        </IconButton>
                    </Stack>
                    <Divider />
                    <DelayInfo />
                </>
            ) : null}
            {isObjWithValues(selectedNode) ? (
                <>
                    {selectedNode?.heading ? (
                        <>
                            <Stack
                                direction={"row"}
                                alignItems={"center"}
                                justifyContent={"space-between"}
                                sx={{ padding: "20px 12px" }}
                            >
                                <Typography
                                    variant="h6"
                                    sx={{ display: "flex", alignItems: "center", gap: 2 }}
                                >
                                    {/* {campaignChannelIcon?.[]} */}
                                    {selectedNode?.icon ||
                                        campaignChannelIcon?.[selectedNode?.channel]}
                                    {selectedNode?.heading}
                                </Typography>
                                <IconButton onClick={() => setSelectedNode("")}>
                                    <Close />
                                </IconButton>
                            </Stack>
                            <Divider />
                            {selectedNode?.id !== "campaign_info" ? <Stack sx={{ p: 3, pb: 0 }}>
                                <Label>Description </Label>
                                <TextField
                                    size="small"
                                    value={campaignState?.[selectedNode?.id]?.description || ""}
                                    onChange={(e) => {
                                        onEditForm({
                                            payload: { description: e.target.value },
                                            key: selectedNode?.id,
                                        });
                                    }}
                                />
                                {/* <FormHelperText>{campaignState?.[selectedNode?.id]?.description?.length}</FormHelperText> */}
                            </Stack> : null}
                        </>
                    ) : null}

                    {componentsObject?.[selectedNode?.heading || selectedNode?.id]}
                </>
            ) : (
                <>
                    {/* <EmptyState
                        heading={"Nothing Here"}
                        text={
                            // "There are no Campaign logs to show."
                            "Click Card to Set Action"
                        }
                        icon={
                            <img
                                alt={"empty state"}
                                src={
                                    "https://control.msg91.com/app/assets/images/background/no_records_graphic.png"
                                }
                                width={"50%"}
                            />
                        }
                    /> */}

                </>
            )}
            {console.log(selectedEdge)}

        </>
    );
};

export default SideBar;
