import {
  Box,
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  IconButton,
  Link,
  Stack,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { editChats, getCampaignHistory } from "../../redux/actions/chatActions";
import {
  fetchJweroCustomers,
  formatDate,
  formatServerValue,
  isArray,
  isArrayWithValues,
  isObjWithValues,
  primaryLabelLarge,
  secondaryLabel,
  validateNumber,
} from "../../helper";
import { useDispatch, useSelector } from "react-redux";
import { ArrowBackIos, OpenInNew } from "@material-ui/icons";
import { ShoppingCartOutlined } from "@mui/icons-material";
import { DashboardTotalCard } from "../dashboards/Default";
import ReactApexChart from "react-apexcharts";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

function CampaignView() {
  const [campaignsLoading, setCampaignsLoading] = useState(false);
  const [campaignId, setCampaignId] = useState("");

  const campaignHistory = useSelector((state) => state.chats.campaignHistory);
  const selectedCampaign = useSelector((state) => state.chats.selectedCampaign);
  const scrollRef = useRef();

  const campaignHistoryFetched = useSelector(
    (state) => state.chats.campaignHistoryFetched
  );
  const gettingCampaignHistory = useSelector(
    (state) => state.chats.gettingCampaignHistory
  );
  const location = useLocation();
  const dispatch = useDispatch();

  console.log(selectedCampaign);

  useEffect(() => {});

  useEffect(() => {
    let { pathname } = location;
    setCampaignId(pathname?.replace("/campaign/", ""));
  }, [location?.pathname]);

  useEffect(() => {
    if (isObjWithValues(campaignHistory) && campaignHistory[campaignId])
      dispatch(editChats({ selectedCampaign: campaignHistory[campaignId] }));
  }, [campaignHistory, campaignId]);

  useEffect(() => {
    if (!isObjWithValues(campaignHistory) && !gettingCampaignHistory)
      dispatch(getCampaignHistory());
  }, []);

  const handleScroll = (e, f) => {
    const scrollTop = scrollRef.current.scrollTop;
    const totalHeight = scrollRef.current.scrollHeight;
    const height = scrollRef.current.offsetHeight;
    console.log(
      totalHeight,
      height,
      scrollTop,
      10,
      totalHeight - height - scrollTop < 10
    );
    if (
      totalHeight - height - scrollTop <
      10
      // &&
      // !productLoading &&
      // !noMoreProducts
    ) {
      console.log("Will fetch products");
      // setParams((state) => ({ ...state, page: state.page + 1 }));
      // fetchProducts(
      //   { ...params, page: params.page + 1 },
      //   (products) => setProducts((state) => state.concat(products)),
      //   setProductsLoading,
      //   setNoMoreProducts
      // );
    }
    // if (
    //   window.innerHeight + document.documentElement.scrollTop !==
    //   document.documentElement.offsetHeight
    // )
    //   return;
    // console.log("Fetch more list items!");
  };

  return (
    <Box
      sx={{
        overflowY: "scroll",
        height: "100vh",
        position: "relative",
        pb: 6,
      }}
      className="scrollbar-hidden"
      onScroll={handleScroll}
      ref={scrollRef}
    >
      <CampaignTitle campaign={selectedCampaign} />
      <CampaignOverview campaign={selectedCampaign} />
      <CampaignEventGraph campaign={selectedCampaign} />
      <CampaignCustomers campaign={selectedCampaign} />
    </Box>
  );
}

export default CampaignView;

const CampaignTitle = ({
  campaign = {},
  quotation = {},
  onCreateOrderPress,
}) => {
  const navigate = useNavigate();
  const isIpad = useMediaQuery((theme) => theme.breakpoints.down("md"));
  let { is_order_created } = quotation;
  let { campaign_info = {} } = campaign;
  const onCheckOrder = () => {
    navigate(`/orders/${is_order_created}`);
  };
  return (
    <Grid
      justifyContent="space-between"
      alignItems={"center"}
      container
      spacing={2}
      p={isIpad ? 3 : 6}
      px={isIpad ? 1 : 3}
    >
      <Grid item>
        <Stack direction="row" alignItems={"center"}>
          <IconButton size="small" onClick={() => navigate(-1)}>
            <ArrowBackIos />
          </IconButton>
          <Box>
            <Typography variant="h5" gutterBottom display="inline">
              {campaign_info.name}
            </Typography>
            <Breadcrumbs aria-label="Breadcrumb" mt={2}>
              <Link component={NavLink} to="/">
                Dashboard
              </Link>
              <Link component={NavLink} to="/campaign">
                Campaings
              </Link>
              <Typography>{campaign_info.name}</Typography>
            </Breadcrumbs>
          </Box>
        </Stack>
      </Grid>
      <Grid item sx={{ marginLeft: "auto" }}>
        {/* {is_order_created ? (
          <Button
            // onClick={() => onCheckOrder()}
            // endIcon={<OpenInNew />}
            variant="outlined"
          >
            Check Order
          </Button>
        ) : (
          <Button
            // onClick={() => onCreateOrderPress()}
            // endIcon={<ShoppingCartOutlined />}
            variant="outlined"
          >
            create order
          </Button>
        )} */}
      </Grid>
    </Grid>
  );
};

const CampaignOverview = ({ campaign = {}, quotation = {} }) => {
  const [totalSent, setTotalSent] = useState(false);
  const [totalDelivered, setTotalDelivered] = useState(false);
  let { customer = {} } = quotation;
  let { campaign_info = {}, sent_info = {} } = campaign;
  return (
    <Box sx={{ mt: 3 }}>
      <Stack
        direction="row"
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Typography sx={{ ...primaryLabelLarge }}>Overview</Typography>
        <Stack direction="row" alignItems="center">
          <Typography sx={{ ...secondaryLabel }}>
            Created on:{" "}
            {formatDate(new Date(campaign?.start_time), "dd-mm-yyyy")}
          </Typography>
          {/* <IconButton>
            <OpenInNew />
          </IconButton> */}
        </Stack>
      </Stack>
      <Grid container spacing={3} sx={{ mt: 1 }}>
        <Grid item xs={6} sm={4} md={3} lg={2}>
          <DashboardTotalCard
            hideNavigate
            hideGraph
            title="Total"
            value={
              campaign?.total_customers
                ? `${campaign?.total_customers} Customers`
                : "-"
            }
            {...topCardsExtraProps}
          />
        </Grid>
        <Grid item xs={6} sm={4} md={3} lg={2}>
          <DashboardTotalCard
            hideNavigate
            hideGraph
            {...topCardsExtraProps}
            title="Status"
            value={campaign?.status || "-"}
          />
        </Grid>
        <Grid item xs={6} sm={4} md={3} lg={2}>
          <DashboardTotalCard
            hideNavigate
            hideGraph
            {...topCardsExtraProps}
            title="Delivery"
            value={`${sent_info?.success?.length || "-"}/${
              campaign?.total_customers || "-"
            }`}
          />
        </Grid>
        <Grid item xs={6} sm={4} md={3} lg={2}>
          <DashboardTotalCard
            hideNavigate
            hideGraph
            {...topCardsExtraProps}
            title="Channel"
            value={campaign_info?.campaign_type || ""}
          />
        </Grid>
        {/* <Grid item xs={6} sm={4} md={3} lg={2}>
          <DashboardTotalCard
            hideNavigate
            hideGraph
            {...topCardsExtraProps}
            title="Time spent"
          />
        </Grid>
        <Grid item xs={6} sm={4} md={3} lg={2}>
          <DashboardTotalCard
            hideNavigate
            hideGraph
            {...topCardsExtraProps}
            title="Visits"
          />
        </Grid> */}
      </Grid>
    </Box>
  );
};

const supportedEvents = ["success", "sent", "delivered", "read"];

const combineTimestampsByDate = ({ timestamps = [] }) => {
  // Initialize an empty object to store the results
  const result = {};

  // Iterate through each timestamp and count occurrences for each date
  timestamps.forEach((timestamp) => {
    // Convert the Unix timestamp to a JavaScript Date object
    const date = new Date(timestamp);

    // Extract the date portion (YYYY-MM-DD) from the Date object
    let formattedDate = date.toISOString().split("T")[0];
    formattedDate = new Date(formattedDate).getTime();
    // Check if the date already exists in the object, if not, initialize the count to 0
    if (!result[formattedDate]) {
      result[formattedDate] = 0;
    }

    // Increment the count for the corresponding date
    result[formattedDate]++;
  });
  return result;
};

const CampaignEventGraph = ({ campaign = {} }) => {
  const [series, setSeries] = useState([]);
  useEffect(() => {
    if (!isObjWithValues(campaign)) return;
    let series = {};
    let { sent_info } = { ...campaign };
    if (isObjWithValues(sent_info)) {
      for (let event in sent_info) {
        if (
          !supportedEvents.includes(event) &&
          isArrayWithValues(sent_info?.[event])
        )
          continue;
        let timeStampObjByDate = combineTimestampsByDate({
          timestamps: sent_info?.[event]
            ?.map((i) => i.time || null)
            .filter(Boolean),
        });
        if (isObjWithValues(timeStampObjByDate))
          series[event] = Object.keys(timeStampObjByDate)
            .map((i) =>
              timeStampObjByDate[i] && i
                ? [validateNumber(i), validateNumber(timeStampObjByDate[i])]
                : ""
            )
            .filter(Boolean);
      }
    }
    setSeries(
      Object.keys(series).map((i) => ({
        name: formatServerValue(i),
        data: series[i],
      }))
    );
  }, [campaign]);

  return (
    <Card sx={{ mt: 4 }}>
      <CardContent>
        <Stack
          direction="row"
          alignItems={"center"}
          justifyContent={"space-between"}
          mb={3}
        >
          <Typography sx={{ ...primaryLabelLarge }}>Campaign Events</Typography>
          <Stack direction="row" alignItems="center">
            {/* <Typography sx={{ ...secondaryLabel }}>
              Created on:{" "}
              {formatDate(new Date(campaign?.start_time), "dd-mm-yyyy")}
            </Typography> */}
            {/* <IconButton>
            <OpenInNew />
          </IconButton> */}
          </Stack>
        </Stack>
        <ReactApexChart
          options={eventGraphOptions}
          series={series}
          type="area"
          height={350}
        />
      </CardContent>
    </Card>
  );
};

const eventGraphOptions = {
  chart: {
    type: "area",
    height: 350,
    stacked: true,
    events: {
      selection: function (chart, e) {
        console.log(new Date(e.xaxis.min));
      },
    },
  },
  colors: ["#008FFB", "#00E396", "#CED4DC"],
  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: "smooth",
  },
  fill: {
    type: "gradient",
    gradient: {
      opacityFrom: 0.6,
      opacityTo: 0.8,
    },
  },
  legend: {
    position: "top",
    horizontalAlign: "left",
  },
  xaxis: {
    type: "datetime",
  },
};

const topCardsExtraProps = {
  valueContainerStyle: { my: 1, mb: 0 },
  labelStyle: { fontSize: "15px" },
  valueProps: {
    sx: { fontSize: "16px", color: "text.primary", fontWeight: "500" },
  },
};

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const filterCustomers = (customers) => {
  return customers.map((i) => {
    let obj = { ...i };
    let { meta_data = [] } = i;
    if (isArrayWithValues(meta_data))
      for (let i of meta_data) {
        obj[i.key] = i.value;
      }
    return obj;
  });
};

const CampaignCustomers = forwardRef(({ campaign }, ref) => {
  const [customers, setCustomers] = useState();
  const [selectedTab, setSelectedTab] = useState("all");
  const [rows, setRows] = useState([]);
  const [AllTabs, setAllTabs] = useState([{ label: "All", value: "all" }]);
  const [fetchingCustomers, setFetchingCustomers] = useState(false);
  const [sentInfo, setSentInfo] = useState({});

  useImperativeHandle(ref, () => {
    return {
      fetchMoreCustomers() {
        _fetchMoreCustomers();
      },
    };
  });

  const _fetchMoreCustomers = async () => {
    if (isArrayWithValues(sentInfo?.[selectedTab])) {
      let ids = sentInfo?.[selectedTab]
        ?.map((i) => i.id || "")
        ?.filter(Boolean);
      console.log(ids, 1);
      let addedCustomers = customers?.filter((i) => {
        let isAdded = ids?.includes(i.id);
        if (isAdded) ids = ids.filter((j) => j !== i.id);
        return isAdded;
      });
      console.log(ids, 2);
      let fetchedCustomers = [];
      if (isArrayWithValues(ids))
        fetchedCustomers = await fetchCustomersByIds({ ids: ids?.join() });
      console.log(fetchedCustomers);
      setRows([...(addedCustomers || []), ...fetchedCustomers]);
    }
  };

  useEffect(() => {
    const fetch = async () => {
      console.log(sentInfo, sentInfo?.[selectedTab]);
      if (isArrayWithValues(sentInfo?.[selectedTab])) {
        let ids = sentInfo?.[selectedTab]
          ?.map((i) => i.id || "")
          ?.filter(Boolean);
        console.log(ids, 1);
        let addedCustomers = customers?.filter((i) => {
          let isAdded = ids?.includes(i.id);
          if (isAdded) ids = ids.filter((j) => j !== i.id);
          return isAdded;
        });
        console.log(ids, 2);
        let fetchedCustomers = [];
        if (isArrayWithValues(ids))
          fetchedCustomers = await fetchCustomersByIds({ ids: ids?.join() });
        console.log(fetchedCustomers, addedCustomers);
        setRows([...(addedCustomers || []), ...fetchedCustomers]);
      }
    };
    fetch();
  }, [selectedTab]);

  useEffect(() => {
    if (!isObjWithValues(campaign)) return;
    let { sent_info, customer_ids } = campaign;
    if (isObjWithValues(sent_info)) {
      setSentInfo(sent_info);
      let currentKeys = Object.keys(sent_info);
      currentKeys = currentKeys.filter((i) => supportedEvents.includes(i));
      currentKeys.unshift("all");
      setAllTabs(
        currentKeys.filter(Boolean).map((i) => ({
          label: formatServerValue(i),
          value: i,
        }))
      );
    }
    if (!isArrayWithValues(customer_ids)) return;
    const fetchFirstCustomers = async () => {
      let ids = customer_ids.slice(0, 100);
      let customers = await fetchCustomersByIds({
        ids: ids
          ?.map((i) => i?.id)
          .filter(Boolean)
          ?.join(),
      });
      setCustomers(filterCustomers(customers));
      console.log(filterCustomers(customers));
    };
    fetchFirstCustomers();
  }, [campaign?.id]);

  useEffect(() => {
    if (!isArrayWithValues(customers) || !isObjWithValues(campaign)) return;
    if (selectedTab === "all") setRows(customers);
  }, [selectedTab, customers]);

  const fetchCustomersByIds = async ({ ids }) => {
    setFetchingCustomers(true);
    try {
      let customers = await fetchJweroCustomers({ params: { include: ids } });
      console.log(customers, "fetched customers");
      if (isArrayWithValues(customers)) return customers;
    } catch (error) {
      return false;
    } finally {
      setFetchingCustomers(false);
    }
  };

  return (
    <Card sx={{ mt: 4 }}>
      <CardContent>
        <Stack
          direction="row"
          alignItems={"center"}
          justifyContent={"space-between"}
          mb={3}
        >
          <Typography sx={{ ...primaryLabelLarge }}>
            Campaign Customers
          </Typography>
          <Stack direction="row" alignItems="center">
            {/* <Typography sx={{ ...secondaryLabel }}>
            Created on:{" "}
            {formatDate(new Date(campaign?.start_time), "dd-mm-yyyy")}
          </Typography> */}
            {/* <IconButton>
          <OpenInNew />
        </IconButton> */}
          </Stack>
        </Stack>
        <Box sx={{}}>
          <Tabs
            value={selectedTab}
            onChange={(a, e) => setSelectedTab(e)}
            aria-label="basic tabs example"
          >
            {AllTabs.map((i) => {
              return <Tab label={i.label} key={i.value} value={i.value} />;
            })}
          </Tabs>
        </Box>
        <TableContainer component={Paper}>
          <Table sx={{}} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell align="right">City</TableCell>
                <TableCell align="right">Branch</TableCell>
                <TableCell align="right">Phone</TableCell>
                {/* <TableCell align="right">Protein&nbsp;(g)</TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow
                  key={row.name}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.first_name || ""} {row.last_name || ""}
                  </TableCell>
                  <TableCell align="right">
                    {row?.billing?.city || "-"}
                  </TableCell>
                  <TableCell align="right">{row.branch_from || "-"}</TableCell>
                  <TableCell align="right">{`${row?.dialcode_whatsapp || ""}${
                    row?.whatsapp || ""
                  } `}</TableCell>
                  {/* <TableCell align="right">{row.carbs}</TableCell>
                  <TableCell align="right">{row.protein}</TableCell> */}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Stack
          direction="row"
          alignItems={"center"}
          mt={2}
          justifyContent={"center"}
        >
          <Stack
            sx={{ visibility: fetchingCustomers ? "visible" : "hidden" }}
            direction="row"
            alignItems="center"
            spacing={3}
          >
            <CircularProgress style={{ height: "20px", width: "20px" }} />
            <Typography>Fetching customers</Typography>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
});
