export const galleryStyles = {
  mainContainer: {
    marginBottom: "10px",
    padding: 0,
    display: "flex",
    flexDirection: "row",
  },
  cardsMainContainer: {
    minWidth: "400px",
    marginBottom: "10px",
    display: "flex",
    flexDirection: "row",
  },
  cardsComponents: {
    position: "relative",
    // width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginRight: "10px",
  },
  cardsDetailsBox: {
    display: "flex",
    flexDirection: "column",
    borderRadius: "15px",
    backgroundColor: "#fff",
    position: "relative",
  },
  galleryImgCard: {
    width: "240px",
    borderTopRightRadius: "15px",
    borderTopLeftRadius: "15px",
    minHeight: "150px",
    backgroundColor: "#e3e3e3",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    padding: "0",
    margin: "0",
  },
  galleryBottom: {
    display: "flex",
    flexDirection: "column",
    margin: "0px",
    padding: "0px",
  },
  cardMedia: {
    borderTopLeftRadius: "10px",
    borderTopRightRadius: "10px",
    objectFit: "fit",
    height: "150px",
  },
  cardsTitleTextField: {
    padding: "5px 10px 0px 15px",
    transition: "0.3s ease",
    border: "1px solid #fff",
    "&:hover": {
      border: "1px solid #008aff",
    },
  },
  cardsDescriptionField: {
    padding: "5px 10px 0px 15px",
    transition: "0.3s ease",
    borderTop: "1px solid #fff",
    borderLeft: "1px solid #fff",
    borderRight: "1px solid #fff",
    borderBottom: "1.5px solid rgb(205 195 195)",
    "&:hover": {
      border: "1px solid #008aff",
    },
  },
  boxLineDesign: {
    marginTop: "2px",
    height: "4px",
    backgroundColor: "rgba(0, 0, 0, 0.54)",
    width: "2px",
    marginLeft: "19px",
  },
  iconButton: {
    height: "24px",
    width: "24px",
    position: "relative",
    bottom: "5px",
    backgroundColor: "#fff",
    color: "#008aff",
    left: "8px",
    transition: "0.3s ease",
    "&:hover": {
      color: "#fff",
      backgroundColor: "#008aff",
    },
  },
  buttonBox: {
    position: "relative", right: "8px"
  },
  buttonsContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "5px",
  },
  iconAddCardButton: {
    height: "24px",
    width: "24px",
    position: "absolute",
    top: "143px",
    right: "-11px",
    bottom: "35px",
    borderRadius: "50%",
    backgroundColor: "#fff",
    color: "#008aff",
    transition: "0.3s ease",
    "&:hover": {
      color: "#fff",
      backgroundColor: "#008aff",
    },
  },
  deleteIcon: {
    position: "absolute",
    top: "-9px",
    right: "7px",
    fontSize: "20px",
    cursor: "pointer",
    color: "#008aff",
    backgroundColor: "#fff",
    borderRadius: "50%",
    padding: "3px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)",
    "&:hover": {
      backgroundColor: "#008aff",
      color: "#fff",
    },
  }
};
