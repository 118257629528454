import React, { useCallback, useContext, useRef, useState } from "react";
import {
  addFileAws3,
  formatServerValue,
  getAxiosError,
  getToken,
  getWebsite,
  getWooCommerce,
  isArrayWithValues,
  isObjWithValues,
  sleep,
} from "../../helper";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Slider,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Cropper from "react-easy-crop";
import {
  ArticleOutlined,
  CloudUpload,
  RotateLeft,
  RotateRight,
} from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { getCroppedImg } from "../../pages/components/ProductMediaLibrary";
import imageCompression from "browser-image-compression";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import {
  updateAllSku,
  updateProductInRedux,
} from "../../redux/actions/productActions";
import {
  SET_HELPER_DATA,
  setGlobalToast,
} from "../../redux/actions/helperActions";
import { MediaLibraryContext } from "../NewMediaLibrary";

const UploadPage = ({ setSelectedView }) => {
  const theme = useTheme();
  const isLg = useMediaQuery(theme.breakpoints.down("lg"));
  const isIpad = useMediaQuery(theme.breakpoints.down("md"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const {
    platform,
    enable_crop,
    hideUploadInfo,
    aspect,
    setParentImages,
    onSelect,
  } = useContext(MediaLibraryContext);
  //// states
  const [droppedImages, setDroppedImages] = useState("");
  const [selectedEditingImage, setSelectedEditingImage] = useState("0");
  // const [selectedImages, setSelectedImages] = useState([]);
  const [crop, setCrop] = useState({});
  const [zoom, setZoom] = useState({});
  const [rotate, setRotate] = useState({});
  const [croppedAreaPixels, setCroppedAreaPixels] = useState({});
  const [uploadingImage, setUploadingImage] = useState(false);
  const [uploadFailed, setUploadFailed] = useState(false);
  const [uploadedInfo, setUploadedInfo] = useState("");
  // const [allMedia, setAllMedia] = useState([]);
  const [droppedRowFiles, setDroppedRowFiles] = useState([]);
  const [value, setValue] = React.useState(0);
  const [imageToUpload, setImageToUpload] = useState("");
  const [droppedImageObj, setDroppedImageObj] = useState("");

  const [resultToShow, setResultToShow] = useState("");
  const cropperSize = isMobile ? "300px" : "300px";
  const inputFile = useRef(null);

  //redux
  const dispatch = useDispatch();
  const reduxMedia = useSelector((state) => state?.helper?.media);

  ///functions
  const onCropComplete = useCallback(
    (croppedArea, croppedAreaPixels, selectedEditingImage) => {
      setCroppedAreaPixels((state) => ({
        ...state,
        [selectedEditingImage]: croppedAreaPixels,
      }));
    },
    []
  );
  const onImgLoad = async ({ target: img }, selectedImage) => {
    const { naturalHeight, naturalWidth } = img;
    // let { } = (img.naturalHeight, img.naturalWidth);
    let isVertical = naturalHeight > naturalWidth;
    let size = isVertical ? naturalWidth : naturalHeight;

    let axis = isVertical
      ? { x: 0, y: (naturalHeight - naturalWidth) / 2 }
      : { y: 0, x: (naturalWidth - naturalHeight) / 2 };
    setCroppedAreaPixels((state) => ({
      ...state,
      [selectedImage]: {
        height: aspect?.height || size,
        width: aspect?.width || size,
        ...axis,
      },
    }));
  };
  const onRotate = useCallback((value, direction) => {
    if (value) return setRotate(value);
    let temp;
    if (direction === "left") {
      if (rotate <= 90) temp = 0;
      else if (rotate <= 180) temp = 90;
      else if (rotate <= 270) temp = 180;
      else if (rotate <= 360) temp = 270;
    } else if (direction === "right") {
      if (rotate < 90) temp = 90;
      else if (rotate < 180) temp = 180;
      else if (rotate < 270) temp = 270;
      else if (rotate <= 360) temp = 360;
    }
    setRotate((state) => ({ ...state, [selectedEditingImage]: temp }));
  });
  const uploadImageToLibrary = async (croppedImage, rowImage) => {
    let image = croppedImage;
    console.log(image, "<<<<blob");
    image = new File([image], rowImage.name);
    console.log(image, "<<<<file");
    setUploadingImage(true);
    let payload = { image: image };
    if (platform) payload.params = { path: `${platform}` };
    let imageRes = await addFileAws3(payload);
    return imageRes;
    // const formData = new FormData();
    // formData.append("file", image, image.name);
    // console.log(formData);
    // // const formData = new FormData();
    // // formData.append("file", rowImageToUpload);
    // const website = getWebsite();
    // const token = getToken();
    // if (website && token) {
    //     let res = await axios({
    //         url: `${website}/wp-json/wp/v2/media`,
    //         data: formData,
    //         headers: {
    //             accept: "application/json",
    //             Authorization: `Basic ${token}`,
    //             "Content-Type": `multipart/form-data`,
    //         },
    //         method: "POST",
    //     });
    //     console.log(res.data);
    //     return res.data;
    // }
  };
  const cropImage = async () => {
    setUploadingImage(true);
    setUploadFailed(false);
    try {
      let index = 0,
        deleteIndex = 0;
      let totalLength = droppedImages.length;
      setUploadedInfo(`${index}/${totalLength}`);
      let mapImagesData = [];
      let fileRes = [];
      for await (let i of Object.keys(croppedAreaPixels)) {
        try {
          let croppedImage;
          let compressedImage;
          if (droppedImages[i].type?.includes("image")) {
            if (enable_crop)
              croppedImage = await getCroppedImg(
                droppedImages[i]?.url,
                croppedAreaPixels[i],
                rotate[i]
              );

            compressedImage = await imageCompression(
              enable_crop ? croppedImage : droppedRowFiles[i],
              {
                maxSizeMB: droppedImages[i].type?.includes("image") ? 0.6 : 5,
                // maxWidthOrHeight: 1024,
                alwaysKeepResolution: true,
              }
            );

            // return
          } else compressedImage = droppedImages[i]?.file;
          let imageRes = await uploadImageToLibrary(
            compressedImage,
            // croppedImage,
            droppedRowFiles[i]
          );
          if (!imageRes?.id) throw new Error("Unable to upload image");
          fileRes = [...fileRes, imageRes];
          if (setParentImages) {
            onSelect(imageRes);
          }

          // setSelectedImages((state) => [{ ...imageRes }, ...state]);
          // setAllMedia((state) => [{ ...imageRes }, ...state]);
          try {
            if (imageRes?.mime_type?.includes("image"))
              mapImagesData.push({
                name: droppedRowFiles[i].name,
                data: imageRes,
              });
            if (imageRes?.mime_type?.includes("video"))
              await mapVideoURLToProduct(droppedRowFiles[i].name, imageRes);

            // let mapImageRes = await mapImageToProduct(
            //   droppedRowFiles[i].name,
            //   imageRes
            // );
          } catch (error) {
            console.log("map error");
          }
          if (Number(selectedEditingImage) + 1 >= totalLength - index)
            setSelectedEditingImage((state) =>
              Number(state) ? Number(state) - 1 : 0
            );
          if (index !== totalLength - 1) {
            setDroppedImages((state) =>
              state.filter((e, i) => i !== deleteIndex)
            );
            setDroppedRowFiles((state) =>
              state.filter((e, i) => i !== deleteIndex)
            );
          }
        } catch (error) {
          console.log(getAxiosError(error), "< upload image error");
          deleteIndex++;
          setUploadFailed(true);
          break;
        }
        index++;
        setUploadedInfo(`${index}/${totalLength}`);
        if (index !== totalLength - 1) await sleep(500);
      }

      dispatch({
        type: SET_HELPER_DATA,
        payload: {
          media: [...fileRes, ...(reduxMedia || [])],
        },
      });
      console.log(mapImagesData, "1");

      if (isArrayWithValues(mapImagesData)) {
        let obj = {};
        setUploadedInfo(`Mapping images`);
        for (let image of mapImagesData) {
          let { name, data } = image;
          name = name?.split("_")?.[0];
          name = name?.split(".")?.[0];
          if (!obj[name]) obj[name] = [];
          obj[name].push(data);
        }
        if (isObjWithValues(obj)) {
          for await (let sku of Object.keys(obj)) {
            await mapImageToProduct(sku, obj[sku]);
          }
        }
      }
      console.log("2");

      setUploadingImage(false);
      if (!deleteIndex) {
        setDroppedImages([]);
        setDroppedRowFiles([]);
        setValue(1);
        setUploadFailed(false);
      } else {
        dispatch(
          setGlobalToast({
            show: true,
            message: `Couldn't upload images, Please try again!`,
            severity: "error",
          })
        );
        // setToast({
        //   show: true,
        //   message: `Couldn't upload images, Please try again!`,
        //   severity: "error",
        // });
      }
      // setImageToUpload(croppedImage);
      // setResultToShow(URL.createObjectURL(croppedImage));
    } catch (error) {
      setUploadingImage(false);
      console.log(getAxiosError(error));
    } finally {
      setSelectedView("grid");
    }
    // selectedView("grid")
    // setImageToUpload(croppedImage);
  };
  const fetchProduct = async (sku) => {
    try {
      let website = await getWebsite();
      let token = await getToken();
      let { data } = await axios({
        url: `${website}/wp-json/wc/v3/products?sku=${new URLSearchParams(sku)
          .toString()
          .replace("=", "")}`,
        headers: { Authorization: `Basic ${token}` },
      });
      console.log(data, "FETCHED PRODUCT");
      if (isArrayWithValues(data) && !data[0].error) return data?.[0];
    } catch (error) {
      console.log(getAxiosError(error));
    }
  };
  const mapVideoURLToProduct = async (fileName, imageRes) => {
    let sku = fileName?.replace(":", "/");
    //  && fileName.replace(/\.[^/.]+$/, "");
    if (sku.includes("_")) sku = sku?.split("_")?.[0];
    if (!sku) return;
    let obj = await fetchProduct(sku);
    if (!obj) return;
    // let obj = getSkuObj(sku);
    const website = getWebsite();
    const token = getToken();
    if (obj && website && token) {
      try {
        let { data } = await axios({
          url: `${website}//wp-json/wc/v3/products/${obj.id}`,
          data: {
            meta_data: [
              { key: "_woodmart_product_video", value: imageRes?.source_url },
            ],
          },
          method: "PUT",
          headers: {
            Authorization: `Basic ${token}`,
          },
        });
        // if (data.id)
        //   updateAllSku({
        //     id: data.id,
        //     images: data.images,
        //     sku: data.sku,
        //   });
        console.log("product video mapped ", data, fileName);
        return true;
      } catch (error) {
        console.log(getAxiosError(error));
      }
    }
  };
  const mapImageToProduct = async (fileName, images) => {
    if (!fileName || !images) return;
    let sku = fileName?.replace(":", "/");
    // = fileName && fileName.replace(/\.[^/.]+$/, "");
    if (sku?.includes("_")) sku = sku?.split("_")?.[0];

    if (isArrayWithValues(sku) && sku?.includes("-"))
      sku = sku?.[0]?.split("-")?.[0];
    else if (sku?.includes("-")) sku = sku?.split("-")?.[0];

    if (!sku) return;
    let obj = await fetchProduct(sku);
    const website = getWebsite();
    const token = getToken();
    if (obj && website && token) {
      let { images: addedImages } = obj || {};
      // let obj = getSkuObj(sku);
      addedImages = addedImages || [];
      try {
        let { data } = await axios({
          url: `${website}//wp-json/wc/v3/products/${obj.id}`,
          data: {
            images: addedImages.concat(
              images.map((i) => ({ id: i.id, src: i.source_url }))
            ),
          },
          method: "PUT",
          headers: {
            Authorization: `Basic ${token}`,
          },
        });
        dispatch(updateProductInRedux(data));
        // if (data.id)
        //   updateAllSku({
        //     id: data.id,
        //     images: data.images,
        //     sku: data.sku,
        //   });
        return true;
      } catch (error) {
        console.log(getAxiosError(error));
      }
    }
  };
  const onReset = () => {
    setCrop((state) => ({ ...state, [selectedEditingImage]: { x: 0, y: 0 } }));
    setRotate((state) => ({ ...state, [selectedEditingImage]: 0 }));
    setZoom((state) => ({ ...state, [selectedEditingImage]: 1 }));
  };
  const onCancel = () => {
    setDroppedImages([]);
    setDroppedRowFiles([]);
  };
  // const uploadImage = async () => {
  //     let image = imageToUpload;
  //     image = new File([image], droppedImageObj.name);
  //     setUploadingImage(true);

  //     const formData = new FormData();
  //     formData.append("file", image, image.name);
  //     const website = getWebsite();
  //     const token = getToken();
  //     const WooCommerce = await getWooCommerce();
  //     if (website && token)
  //         axios
  //             .post(`${website}/wp-json/wp/v2/media`, formData, {
  //                 headers: {
  //                     accept: "application/json",
  //                     Authorization: `Basic ${token}`,
  //                     "Content-Type": `multipart/form-data`,
  //                 },
  //             })
  //             .then(async (res) => {
  //                 let { data } = res;
  //                 if (data) {
  //                     let media = [...allMedia];
  //                     media.unshift(data);
  //                     setSelectedImages([{ ...data }].concat([...selectedImages]));
  //                     setAllMedia(media);
  //                     setResultToShow("");
  //                     setImageToUpload("");
  //                     let sku = image.name.replace(/\.[^/.]+$/, "");
  //                     if (sku?.includes("_")) sku = sku?.split("_")?.[0];
  //                     console.log(sku, "<<<< SKU SEARCH");
  //                     // let obj = getSkuObj(sku);
  //                     let obj = await fetchProduct(sku);
  //                     if (obj && WooCommerce) {
  //                         let images = obj.images || [];
  //                         WooCommerce.put(`products/${obj.id}`, {
  //                             images: images.concat({ src: data.source_url }),
  //                         })
  //                             .then((res) => {
  //                                 res = res.data;
  //                                 if (res.id)
  //                                     dispatch(
  //                                         updateAllSku({
  //                                             id: res.id,
  //                                             images: res.images,
  //                                             sku: res.sku,
  //                                         })
  //                                     );
  //                             })
  //                             .catch((e) => console.log(getAxiosError(e)))
  //                             .finally(() => {
  //                                 setUploadingImage(false);
  //                             });
  //                     } else {
  //                         setUploadingImage(false);
  //                     }
  //                     setValue(1);
  //                 }
  //                 // if (data.source_url) setImageURL(data.source_url);
  //             })
  //             .catch((e) => {
  //                 console.log(getAxiosError(e));
  //                 e = getAxiosError(e);
  //                 if (e.message) {
  //                     dispatch(
  //                         setGlobalToast({
  //                             show: true,
  //                             severity: "error",
  //                             message: e.message,
  //                             title: "",
  //                         })
  //                     );
  //                     // setToast({
  //                     //   show: true,
  //                     //   severity: "error",
  //                     //   message: e.message,
  //                     //   title: "",
  //                     // });
  //                 }
  //                 setUploadingImage(false);
  //             });
  //     // .finally((e) => {
  //     //   setUploadingImage(false);
  //     // });
  // };
  const onFileDrop = async (files) => {
    const types = ["image/jpeg", "image/png", "image/gif", "image/x-icon"];
    if (files) {
      let array = [];
      let rowFiles = [];
      let hasUnsupportedFileType = false;
      let hasLargeFiles = false;
      for (let i = 0; i < files.length; i++) {
        let { size, name, type } = files[i];
        // if (!types.includes(type)) {
        //   hasUnsupportedFileType = true;
        //   continue;
        // }
        //also change size below in message
        if (size > 5000000) {
          hasLargeFiles = true;
          continue;
        }
        array.push({
          url: URL.createObjectURL(files[i]),
          type,
          file: files[i],
        });
        rowFiles.push(files[i]);
      }
      if (
        hasLargeFiles
        // || hasUnsupportedFileType
      ) {
        dispatch(
          setGlobalToast({
            show: true,
            severity: "error",
            message: `Skipping ${
              hasUnsupportedFileType ? "Unsupported" : ""
            } files ${hasUnsupportedFileType && hasLargeFiles ? "and" : ""} ${
              hasLargeFiles ? "greater than 5 mb" : ""
            }!`,
          })
        );
        // setToast({
        //   show: true,
        //   severity: "error",
        //   message: `Skipping ${hasUnsupportedFileType ? "Unsupported" : ""
        //     } files ${hasUnsupportedFileType && hasLargeFiles ? "and" : ""} ${hasLargeFiles ? "greater than 15 mb" : ""
        //     }!`,
        // });
      }
      if (isArrayWithValues(array)) {
        // console.log(array);
        setDroppedImages(array);
        setDroppedRowFiles(rowFiles);
        setSelectedEditingImage("0");
        let cropObj = {},
          zoomObj = {},
          rotateObj = {},
          croppedAreaPixelesObj = {};

        array.map((value, i) => {
          cropObj[i.toString()] = { x: 0, y: 0 };
          zoomObj[i.toString()] = 1;
          rotateObj[i.toString()] = 0;
          croppedAreaPixelesObj[i.toString()] = {};
        });
        setCrop(cropObj);
        setZoom(zoomObj);
        setRotate(rotateObj);
        setCroppedAreaPixels(croppedAreaPixelesObj);
      }
    }

    // setDroppedImage(URL.createObjectURL(e));
  };
  return (
    <>
      <input
        type="file"
        multiple
        id="file"
        // accept="image/*"
        onChange={(e) => onFileDrop(e.target.files)}
        ref={inputFile}
        style={{ display: "none" }}
      />

      {/* <TabPanel
                value={value}
                style={{
                    // minHeight: "60vh",
                    minHeight: "75vh",

                    justifyContent: "center",
                    textAlign: "center",
                }}
                index={0}
            > */}
      {isArrayWithValues(droppedImages) ? (
        true ? (
          <Grid
            container
            spacing={2}
            sx={{ width: "95%", margin: "auto", marginTop: 8 }}
          >
            <Grid item xs={12} sm={8}>
              <Box
                sx={{
                  // maxWidth: "280px",
                  margin: "auto",
                  height: cropperSize,
                  width: cropperSize,
                  position: "relative",
                }}
              >
                {droppedImages[selectedEditingImage]?.type?.includes("image") &&
                  (enable_crop ? (
                    <Cropper
                      image={
                        droppedImages[selectedEditingImage]?.url ||
                        "https://upload.wikimedia.org/wikipedia/commons/9/9a/Gull_portrait_ca_usa.jpg"
                      }
                      // video={droppedImages[selectedEditingImage]?.url ||
                      //   "https://upload.wikimedia.org/wikipedia/commons/9/9a/Gull_portrait_ca_usa.jpg"}
                      // image={imageToUpload}
                      crop={crop[selectedEditingImage]}
                      zoom={zoom[selectedEditingImage]}
                      rotation={rotate[selectedEditingImage]}
                      objectFit={"contain"}
                      aspect={aspect ? aspect?.width / aspect?.height : 4 / 4}
                      onCropChange={(value) =>
                        setCrop((state) => ({
                          ...state,
                          [selectedEditingImage]: value,
                        }))
                      }
                      onCropComplete={(a, b) =>
                        onCropComplete(a, b, selectedEditingImage)
                      }
                      onZoomChange={(value) =>
                        setZoom((state) => ({
                          ...state,
                          [selectedEditingImage]: value,
                        }))
                      }
                    />
                  ) : (
                    <img
                      alt={"selected file"}
                      src={droppedImages[selectedEditingImage]?.url}
                      style={{
                        objectFit: "contain",
                        aspect: aspect ? aspect?.width / aspect?.height : 4 / 4,

                        height: cropperSize,
                        width: cropperSize,
                      }}
                    />
                  ))}
                {droppedImages[selectedEditingImage]?.type?.includes(
                  "video"
                ) && (
                  <>
                    <video
                      style={{
                        height: cropperSize,
                        width: cropperSize,
                        borderRadius: "5px",
                        objectFit: "contain",
                        // border:
                        //   selectedEditingImage == i
                        //     ? "1px solid rgba(0,0,0,0.3)"
                        //     : "1px solid transparent",
                        // boxShadow:
                        //   selectedEditingImage == i
                        //     ? "1px 1px 2px rgba(0,0,0,0.3)"
                        //     : "0px 0px 0px rgba(0,0,0,0.3)",
                        // cursor: "pointer",
                        // marginTop: "12px",
                      }}
                      controls
                    >
                      <source
                        src={droppedImages[selectedEditingImage]?.url}
                        type={droppedImages[selectedEditingImage]?.type}
                      />
                      {/* <source src="movie.ogg" type="video/ogg" /> */}
                      Your browser does not support the video tag.
                    </video>

                    {/* <Cropper
                            // image={
                            //   droppedImages[selectedEditingImage]?.url ||
                            //   "https://upload.wikimedia.org/wikipedia/commons/9/9a/Gull_portrait_ca_usa.jpg"
                            // }
                            video={droppedImages[selectedEditingImage]?.url ||
                              "https://upload.wikimedia.org/wikipedia/commons/9/9a/Gull_portrait_ca_usa.jpg"}
                            // image={imageToUpload}
                            crop={crop[selectedEditingImage]}
                            zoom={zoom[selectedEditingImage]}
                            rotation={rotate[selectedEditingImage]}
                            objectFit="horizontal-cover"
                            aspect={4 / 4}
                            onCropChange={(value) =>
                              setCrop((state) => ({
                                ...state,
                                [selectedEditingImage]: value,
                              }))
                            }
                            onCropComplete={(a, b) =>
                              onCropComplete(a, b, selectedEditingImage)
                            }
                            onZoomChange={(value) =>
                              setZoom((state) => ({
                                ...state,
                                [selectedEditingImage]: value,
                              }))
                            }
                          /> */}
                  </>
                )}
                {droppedImages[selectedEditingImage]?.type?.includes(
                  "application"
                ) && (
                  <Box>
                    <Box
                      sx={{
                        backgroundColor: "#ccc",
                        borderRadius: "3px",
                        display: "flex",
                        justifyContent: "center",
                        padding: "100px 100px",
                      }}
                    >
                      <ArticleOutlined
                        style={{
                          height: "50px",
                          width: "50px",
                          color: "#000",
                        }}
                      />
                    </Box>
                    <Typography>
                      {droppedImages[selectedEditingImage]?.file?.name || ""}
                    </Typography>
                    {/* <Cropper
                            image={
                              droppedImages[selectedEditingImage]?.url ||
                              "https://upload.wikimedia.org/wikipedia/commons/9/9a/Gull_portrait_ca_usa.jpg"
                            }
                            // video={droppedImages[selectedEditingImage]?.url ||
                            //   "https://upload.wikimedia.org/wikipedia/commons/9/9a/Gull_portrait_ca_usa.jpg"}
                            // image={imageToUpload}
                            crop={crop[selectedEditingImage]}
                            zoom={zoom[selectedEditingImage]}
                            rotation={rotate[selectedEditingImage]}
                            objectFit="horizontal-cover"
                            aspect={aspect || 4 / 4}
                            onCropChange={(value) =>
                              setCrop((state) => ({
                                ...state,
                                [selectedEditingImage]: value,
                              }))
                            }
                            onCropComplete={(a, b) =>
                              onCropComplete(a, b, selectedEditingImage)
                            }
                            onZoomChange={(value) =>
                              setZoom((state) => ({
                                ...state,
                                [selectedEditingImage]: value,
                              }))
                            }
                          /> */}
                  </Box>
                )}
              </Box>
              <Stack
                direction="row"
                justifyContent={"center"}
                spacing={3}
                mt={3}
                flexWrap="wrap"
                px={3}
              >
                {droppedImages.map((value, i) => {
                  return (
                    <>
                      {droppedImages[i]?.type?.includes("image") && (
                        <img
                          alt={"drop images"}
                          onLoad={(e) => onImgLoad(e, i)}
                          src={droppedImages[i]?.url}
                          style={{
                            height: "70px",
                            width: "70px",
                            borderRadius: "5px",
                            objectFit: "contain",
                            border:
                              selectedEditingImage == i
                                ? "1px solid rgba(0,0,0,0.3)"
                                : "1px solid transparent",
                            boxShadow:
                              selectedEditingImage == i
                                ? "1px 1px 2px rgba(0,0,0,0.3)"
                                : "0px 0px 0px rgba(0,0,0,0.3)",
                            cursor: "pointer",
                            marginTop: "12px",
                          }}
                          onClick={() => setSelectedEditingImage(`${i}`)}
                        />
                      )}
                      {droppedImages[i]?.type?.includes("video") && (
                        <>
                          <video
                            style={{
                              height: "70px",
                              width: "70px",
                              borderRadius: "5px",
                              objectFit: "contain",
                              border:
                                selectedEditingImage == i
                                  ? "1px solid rgba(0,0,0,0.3)"
                                  : "1px solid transparent",
                              boxShadow:
                                selectedEditingImage == i
                                  ? "1px 1px 2px rgba(0,0,0,0.3)"
                                  : "0px 0px 0px rgba(0,0,0,0.3)",
                              cursor: "pointer",
                              marginTop: "12px",
                            }}
                          >
                            <source
                              src={droppedImages[i]?.url}
                              type={droppedImages[i]?.type}
                            />
                            {/* <source src="movie.ogg" type="video/ogg" /> */}
                            Your browser does not support the video tag.
                          </video>

                          {/* <img
                                      onLoad={(e) => onImgLoad(e, i)}
                                      src={droppedImages[i]?.url}
                                      style={{
                                        height: "70px",
                                        width: "70px",
                                        borderRadius: "5px",
                                        objectFit: "contain",
                                        border:
                                          selectedEditingImage == i
                                            ? "1px solid rgba(0,0,0,0.3)"
                                            : "1px solid transparent",
                                        boxShadow:
                                          selectedEditingImage == i
                                            ? "1px 1px 2px rgba(0,0,0,0.3)"
                                            : "0px 0px 0px rgba(0,0,0,0.3)",
                                        cursor: "pointer",
                                        marginTop: "12px",
                                      }}
                                      onClick={() => setSelectedEditingImage(`${i}`)}
                                    />  */}
                        </>
                      )}
                    </>
                  );
                })}
              </Stack>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Box sx={{ textAlign: "left" }}>
                <Typography variant="h5">Rotate</Typography>
                <Stack direction={"row"} alignItems="center" mt={4}>
                  <IconButton
                    sx={{
                      backgroundColor: "primary.main",
                      color: "#fff",
                      ":hover": {
                        backgroundColor: "primary.main",
                      },
                    }}
                    size="small"
                    onClick={() => onRotate(false, "left")}
                  >
                    <RotateLeft />
                  </IconButton>
                  <IconButton
                    sx={{
                      backgroundColor: "primary.main",
                      color: "#fff",
                      ml: 2,
                      ":hover": {
                        backgroundColor: "primary.main",
                      },
                    }}
                    disableFocusRipple
                    size="small"
                    onClick={() => onRotate(false, "right")}
                  >
                    <RotateRight />
                  </IconButton>
                  <Slider
                    sx={{ ml: 4 }}
                    value={rotate[selectedEditingImage]}
                    onChange={(e, value) =>
                      setRotate((state) => ({
                        ...state,
                        [selectedEditingImage]: value,
                      }))
                    }
                    max={360}
                    min={0}
                  />
                </Stack>
                <Typography mt={3} variant="h5">
                  Zoom
                </Typography>
                <Stack direction={"row"} alignItems="center" mt={2}>
                  <Slider
                    sx={{ ml: 2, mr: 2 }}
                    value={zoom[selectedEditingImage] * 100}
                    onChange={(e, value) =>
                      setZoom((state) => ({
                        ...state,
                        [selectedEditingImage]: value / 100,
                      }))
                    }
                    max={100}
                    min={0}
                  />
                </Stack>
                <Stack direction="row" mt={7}>
                  <LoadingButton
                    loading={uploadingImage}
                    loadingPosition="start"
                    // loadingIndicator={uploadedInfo}
                    variant="contained"
                    fullWidth
                    // endIcon={<FileUploadOutlinedIcon />}
                    sx={{}}
                    onClick={cropImage}
                  >
                    {uploadingImage ? uploadedInfo : "Upload"}
                  </LoadingButton>
                  <Button
                    variant="text"
                    fullWidth
                    // endIcon={<FileUploadOutlinedIcon />}
                    sx={{ ml: 3 }}
                    onClick={onReset}
                    disabled={uploadingImage}
                  >
                    Reset
                  </Button>
                </Stack>
                {uploadFailed && (
                  <Typography fontSize="12px" color="error" my={2}>
                    Uploading file failed. Please upload again!
                  </Typography>
                )}
                <Button
                  variant="outlined"
                  fullWidth
                  color="error"
                  // endIcon={<FileUploadOutlinedIcon />}
                  sx={{ mt: 4 }}
                  disabled={uploadingImage}
                  onClick={onCancel}
                >
                  Cancel
                </Button>
              </Box>
            </Grid>
          </Grid>
        ) : null
      ) : (
        // <Box justifyContent={"center"}>
        //     <img
        //         alt={"result"}
        //         src={resultToShow}
        //         style={{
        //             height: cropperSize,
        //             width: cropperSize,
        //             backgroundColor: "transparent",
        //         }}
        //     />
        //     <Stack
        //         direction="row"
        //         mt={7}
        //         margin="auto"
        //         maxWidth={"500px"}
        //     >
        //         <LoadingButton
        //             loading={uploadingImage}
        //             variant="contained"
        //             fullWidth
        //             // endIcon={<FileUploadOutlinedIcon />}
        //             sx={{}}
        //             onClick={uploadImage}
        //         >
        //             Upload
        //         </LoadingButton>
        //         <Button
        //             variant="outlined"
        //             // color="grey.400"
        //             fullWidth
        //             // endIcon={<FileUploadOutlinedIcon />}
        //             sx={{ ml: 3 }}
        //             onClick={() => {
        //                 setResultToShow("");
        //                 setImageToUpload("");
        //             }}
        //         >
        //             Cancel
        //         </Button>
        //     </Stack>
        // </Box>
        // <FileUploader
        //   handleChange={onFileDrop}
        //   name="file"
        //   types={["JPG", "PNG"]}
        //   children={
        //   }
        // />
        <>
          <Stack
            direction={"row"}
            sx={{
              justifyContent: "center",
              display: "flex",
              alignItems: "center",
              height: "100%",
              border: "2px dashed",
              padding: "14px 6px",
              textAlign: "left",
              maxWidth: "400px",
              borderRadius: "10px",
              margin: "10vh auto",
              cursor: "pointer",

              "&:hover": {
                backgroundColor: "rgba(0,0,0,0.1)",
              },
            }}
            onClick={() => inputFile.current.click()}
          >
            <Box
              sx={{
                height: "80px",
                width: " 80px",
                backgroundColor: "primary.main",
                borderRadius: "50%",

                position: "relative",
              }}
            >
              <CloudUpload
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  color: "#fff",
                }}
              />
            </Box>
            <div style={{ marginLeft: 5 }}>
              <h3 style={{ margin: "0px" }}>Drop or select file</h3>
              <span style={{ margin: "0px" }}>
                drop files here form your machine
                {/* drop files here or drag through your machine */}
              </span>
            </div>
          </Stack>
          {hideUploadInfo ? null : (
            <Stack
              // direction={"row"}
              sx={{
                justifyContent: "center",
                display: "flex",
                alignItems: "center",
                height: "80%",
                // border: "2px dashed",
                padding: "14px 6px",
                textAlign: "left",
                // maxWidth: "400px",
                borderRadius: "10px",
                margin: "auto",
                // mt: 2,
                // cursor: "pointer",
                // "&:hover": {
                //   backgroundColor: "rgba(0,0,0,0.1)",
                // },
              }}

              // onClick={() => inputFile.current.click()}
            >
              {Object.keys(uploadInfo || {})?.map((key) => {
                return (
                  <Stack key={key} sx={{ mt: 3 }}>
                    <Typography variant="h6">
                      {formatServerValue(key)}
                    </Typography>
                    <ol>
                      {uploadInfo?.[key]?.map((str) => {
                        return <li>{str}</li>;
                      })}
                    </ol>
                  </Stack>
                );
              })}
            </Stack>
          )}
        </>
      )}
      {/* </TabPanel> */}
    </>
  );
};

export default UploadPage;

const uploadInfo = {
  images: [
    "Dimensions: 800px * 800px or 1000px * 1000px",
    "Size :  Under 1MB",
    "Naming : Images Name Saved by their SKU. Ex.SKU.jpeg",
    "Images must be in jpeg(mandatory)",
  ],
  video: [
    "Dimensions: 800px * 800px or 1000px * 1000px",
    "Size :  Under 5MB",
    "Naming : Images Name Saved by their SKU. Ex.SKU.mp4",
  ],
};
