import { typingIndicatorStyles } from "../utils/typingIndicatorStyles";
import "../public/css/typing-styles.css";

const styles = typingIndicatorStyles;

const TypingIndicator = () => {
  return (
    <>
      <div style={styles.mainDiv}>
        <div style={styles.typingTextField}>
          <div className="typing-animation">
            <div className="type-dot" style={{ "--delay": "200ms" }}></div>
            <div className="type-dot" style={{ "--delay": "300ms" }}></div>
            <div className="type-dot" style={{ "--delay": "400ms" }}></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TypingIndicator;
