import {
  alpha,
  Box,
  Card,
  CardContent,
  Chip,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  formatDate,
  getComparator,
  getMonthDifference,
  isArrayWithValues,
  isObjWithValues,
  reduceLargeText,
  stableSort,
} from "../../helper";
import { currencySymbols } from "../pricing/Gold";
import {
  DigiOrdersTable,
  SectionLabel,
  StyledTableRow,
  TopCards,
  TotalCard,
} from "./Dashboard";
import { visuallyHidden } from "@mui/utils";
import { getStatusBackgroundColor, statusFontColor } from "../orders/Orders";

function Payments({
  dashboardTotals,
  currency,
  orders,
  digiCustomers,
  digiPlans,
  todayGoldRate,
  topGraphs,
  setUserView,
  setSelectedTab,
}) {
  return (
    <Box>
      <TopCards
        topGraphs={topGraphs}
        dashboardTotals={dashboardTotals}
        setSelectedTab={setSelectedTab}
        currency={currency}
      />
      <DigiOrdersTable
        orders={orders}
        currency={currency}
        plans={digiPlans}
        customers={digiCustomers}
        setUserView={setUserView}
      />
      <DigiMissedPayments
        orders={orders}
        currency={currency}
        customers={digiCustomers}
        digiPlans={digiPlans}
        todayGoldRate={todayGoldRate}
        setUserView={setUserView}
      />
    </Box>
  );
}

export default Payments;

export const DigiMissedPayments = ({
  orders,
  currency,
  customers,
  digiPlans,
  setUserView,
  todayGoldRate,
}) => {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows, setRows] = useState([]);
  const [extra, setExtra] = useState({
    customers: 0,
    payments: 0,
  });

  const theme = useTheme();
  const isDarkTheme = theme.palette && theme.palette.mode === "dark";

  useEffect(() => {
    if (!isArrayWithValues(customers)) return;
    let rows = [];
    let totalCustomers = 0;
    let totalPayments = 0;
    for (let customer of customers) {
      let { plans } = customer;
      if (!isObjWithValues(plans)) continue;
      for (let plan of Object.values(plans)) {
        let { ledger } = plan;
        if (!isArrayWithValues(ledger)) continue;
        ledger = ledger?.sort(
          (a, b) =>
            new Date(a.date_paid).getTime() - new Date(b.date_paid).getTime()
        );
        let firstPayment = new Date(ledger[0].date_paid);
        let currentDate = new Date();
        let requiredPayments = getMonthDifference(firstPayment, currentDate);
        let missedPayments = requiredPayments - ledger.length;
        if (missedPayments > 0) {
          // let lastPayment = new Date(firstPayment.setMonth())
          let temp = new Date(firstPayment);
          let paidPayments = requiredPayments - missedPayments;
          let lastPayment = new Date(
            temp.setMonth(temp.getMonth() + paidPayments)
          );
          let monthTemp = 0;

          for (let a of Array.from(new Array(missedPayments).fill(1))) {
            let missedDate = new Date(firstPayment);
            let due_date = new Date(
              missedDate.setMonth(
                missedDate.getMonth() + paidPayments + monthTemp
              )
            );
            if (new Date().getTime() < new Date(due_date).getTime()) {
              monthTemp++;
              continue;
            }
            let obj = {
              ...customer,
              plan_name: plan.plan_name,
              due_date,
              paying_amount: 0,
              customerDetail: customer,
            };
            if (isArrayWithValues(digiPlans)) {
              let planObj = digiPlans.find((i) => i.id == plan.product);
              if (planObj) {
                let { meta_data } = planObj;
                if (isArrayWithValues(meta_data))
                  for (let metaObj of meta_data)
                    planObj[metaObj.key] = metaObj.value;
                if (planObj.digi_plan_type === "amount")
                  obj.paying_amount = planObj.price;
                if (planObj.digi_plan_type === "grams") {
                  let gramsPerMonth =
                    Number(planObj.plan_total_grams) /
                    Number(planObj.digi_plan_duration);
                  if (gramsPerMonth && todayGoldRate)
                    obj.paying_amount = Math.round(
                      Number(todayGoldRate) * gramsPerMonth
                    );
                }
              }
            }
            monthTemp++;
            rows.push(obj);
          }
        }
      }
    }
    setRows(rows);
    // for (let order of orders) {
    //   let obj = { ...order };
    //   let customer = {};
    //   if (isArrayWithValues(customers)) {
    //     let customerObj = customers.find((i) => i.id == obj.customer_id);
    //     if (customerObj) {
    //       let { digi_refer_obj } = customerObj;
    //       obj.digi_refer_obj = digi_refer_obj;
    //     }
    //   }

    //   let { meta_data } = order;
    //   if (isArrayWithValues(meta_data)) {
    //     for (let metaObj of meta_data) {
    //       obj[metaObj.key] = metaObj.value;
    //     }
    //   }
    //   rows.push(obj);
    // }
    // console.log(rows, customers);
    // setRows(rows);
    // if (!isArrayWithValues(plans)) return;
    // let rows = [];
    // for (let plan of plans) {
    //   let {
    //     totalUsers,
    //     totalPaid,
    //     totalPayments,
    //     name,
    //     date_created,
    //     meta_data,
    //   } = plan;
    //   let metaObj = {};
    //   if (isArrayWithValues(meta_data)) {
    //     for (let obj of meta_data) {
    //       let { key, value } = obj;
    //       if (value) metaObj[key] = value;
    //     }
    //   }
    //   let obj = {
    //     totalUsers,
    //     totalPaid,
    //     totalPayments,
    //     name,
    //     duration: metaObj.digi_plan_duration,
    //   };
    //   if (date_created)
    //     obj.date_created = formatDate(
    //       new Date(date_created),
    //       "dd:mm:yyyy hh:mm"
    //     );
    //   rows.push(obj);
    // }
    // setRows(rows);
  }, [orders, customers]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <Card sx={{ mt: 3 }}>
      <CardContent>
        <Box sx={{ width: "100%" }}>
          <Paper sx={{ width: "100%", mb: 2 }}>
            <Toolbar
              sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                ...(selected.length > 0 && {
                  bgcolor: (theme) =>
                    alpha(
                      theme.palette.primary.main,
                      theme.palette.action.activatedOpacity
                    ),
                }),
              }}
            >
              {selected.length > 0 ? (
                <Typography
                  sx={{ flex: "1 1 100%" }}
                  color="inherit"
                  variant="subtitle1"
                  component="div"
                >
                  {selected.length} selected
                </Typography>
              ) : (
                <SectionLabel
                  sx={{ flex: "1 1 100%" }}
                  variant="h6"
                  id="tableTitle"
                  component="div"
                >
                  Missed Payments
                </SectionLabel>
              )}

              {/* {selected.length > 0 ? (
                <Tooltip title="Delete">
                  <IconButton>
                    <Delete />
                  </IconButton>
                </Tooltip>
              ) : (
                <Tooltip title="Filter list">
                  <IconButton>
                    <FilterList />
                  </IconButton>
                </Tooltip>
              )} */}
            </Toolbar>
            <TableContainer>
              <Table
                aria-labelledby="tableTitle"
                size={dense ? "small" : "medium"}
              >
                <DigiOrdersTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                />
                <TableBody>
                  {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.sort(getComparator(order, orderBy)).slice() */}
                  {/* {stableSort(rows, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) */}
                  {stableSort(rows, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const isItemSelected = isSelected(row.name);
                      const labelId = `enhanced-table-checkbox-${index}`;
                      // console.log(row);
                      return (
                        <StyledTableRow
                          // hover
                          onClick={() => setUserView(row.customerDetail)}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.name}
                          selected={isItemSelected}
                          sx={{ cursor: "pointer" }}
                        >
                          {/* <TableCell padding="checkbox">
                            <Checkbox
                              color="primary"
                              checked={isItemSelected}
                              inputProps={{
                                "aria-labelledby": labelId,
                              }}
                            />
                          </TableCell> */}
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            // padding="none"
                          >
                            <Typography
                              sx={{ fontWeight: "600", fontWeight: "15px" }}
                            >
                              {/* {row?.billing?.first_name || ""}{" "}
                              {row?.billing?.last_name || ""} */}
                              <Typography
                                sx={{ fontSize: "14px", fontWeight: "500" }}
                              >
                                {row?.billing?.first_name || ""}{" "}
                                {row?.billing?.last_name || ""}
                              </Typography>
                              <Typography
                                sx={{
                                  fontSize: "11px",
                                  color: "text.secondary",
                                  mt: 0.5,
                                }}
                              >
                                Customer ID: {row.customer_id}
                              </Typography>
                              <Typography
                                sx={{
                                  fontSize: "11px",
                                  color: "text.secondary",
                                  mt: 0.5,
                                }}
                              >
                                Join date:{" "}
                                {row.date_created
                                  ? formatDate(
                                      new Date(row.date_created),
                                      // "relative_date"
                                      "dd-mm-yyyy"
                                    )
                                  : ""}
                              </Typography>
                            </Typography>
                          </TableCell>
                          <TableCell align="left">
                            {row?.digi_refer_obj ? (
                              <>
                                <Typography
                                  sx={{ fontSize: "14px", fontWeight: "500" }}
                                >
                                  {row?.digi_refer_obj?.first_name || ""}{" "}
                                  {row?.digi_refer_obj?.last_name || ""}
                                </Typography>
                                <Typography
                                  sx={{
                                    fontSize: "11px",
                                    color: "text.secondary",
                                    mt: 0.5,
                                  }}
                                >
                                  A/c ID: {row?.digi_refer_obj?.id}
                                </Typography>
                                <Typography
                                  sx={{
                                    fontSize: "11px",
                                    color: "text.secondary",
                                    mt: 0.5,
                                  }}
                                >
                                  J. date:{" "}
                                  {row?.digi_refer_obj?.date_created
                                    ? formatDate(
                                        new Date(
                                          row?.digi_refer_obj?.date_created
                                        ),
                                        "dd-mm-yyyy"
                                      )
                                    : ""}
                                </Typography>
                              </>
                            ) : (
                              ""
                            )}
                          </TableCell>
                          <TableCell align="right">
                            {row?.billing?.city || ""}
                          </TableCell>
                          <TableCell align="right">
                            <Tooltip title={row?.line_items?.[0]?.name || ""}>
                              <Stack
                                direction="row"
                                alignItems={"center"}
                                justifyContent="flex-end"
                              >
                                {row?.plan_name || "-"}
                              </Stack>
                            </Tooltip>
                          </TableCell>
                          <TableCell align="right">
                            {row.due_date
                              ? formatDate(
                                  new Date(row.due_date),
                                  "relative_date"
                                )
                              : ""}
                          </TableCell>
                          <TableCell
                            align="right"
                            sx={{ fontSize: "14px", fontWeight: "500" }}
                          >{`${currencySymbols[currency] || ""} ${
                            row?.paying_amount || ""
                          }`}</TableCell>
                          <TableCell align="right">
                            <Typography
                              sx={{ fontWeight: "600", fontWeight: "15px" }}
                            >
                              {/* <Typography sx={{ fontSize: "13px" }}>
                                {row.manual_payment === "yes"
                                  ? "Manual"
                                  : "Automatic"}
                              </Typography>
                              <Typography
                                sx={{
                                  fontSize: "11px",
                                  color: "text.secondary",
                                  mt: 0.5,
                                }}
                              >
                                Ref. No: {row.id}
                              </Typography>
                              <Typography
                                sx={{
                                  fontSize: "11px",
                                  color: "text.secondary",
                                  mt: 0.5,
                                }}
                              >
                                {row.payment_method_title}
                              </Typography> */}
                            </Typography>
                            {/* {currency ? currencySymbols[currency] : ""} {0} */}
                          </TableCell>
                          <TableCell align="right">
                            {/* <Chip
                              size="small"
                              mr={1}
                              mb={1}
                              label={row.status}
                              // color="warning"
                              sx={{
                                color: statusFontColor(row.status),
                                border: isDarkTheme
                                  ? "0.7px solid"
                                  : "0px solid",
                                // borderColor: statusFontColor(row.status),
                                backgroundColor: isDarkTheme
                                  ? "transparent"
                                  : getStatusBackgroundColor(row.status),
                              }}
                              variant="outlined"
                            /> */}
                          </TableCell>
                        </StyledTableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow
                      style={{
                        height: (dense ? 33 : 53) * emptyRows,
                      }}
                    >
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Box>
      </CardContent>
    </Card>
  );
};

const DigiOrdersTableHeadCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Customer Name",
  },
  {
    id: "family_name",
    numeric: false,
    disablePadding: false,
    label: "Refer by",
  },
  {
    id: "location",
    numeric: true,
    disablePadding: false,
    label: "City/ Location",
  },
  {
    id: "plan_name",
    numeric: true,
    disablePadding: false,
    label: "Plan Name",
  },
  {
    id: "date",
    numeric: true,
    disablePadding: false,
    label: "Due Date",
  },
  {
    id: "amt_missed",
    numeric: true,
    disablePadding: false,
    label: "Amt Missed",
  },
  {
    id: "payment_mode",
    numeric: true,
    disablePadding: false,
    label: "Status",
  },
  {
    id: "remarks",
    numeric: true,
    disablePadding: false,
    label: "Remarks",
  },
];

function DigiOrdersTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell> */}
        {DigiOrdersTableHeadCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
