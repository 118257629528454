import { Box, Card, CardContent, Typography } from "@mui/material";
import React from "react";
import ReactApexChart from "react-apexcharts";
import { useSelector } from "react-redux";
import { globalCardStyle, globalColors } from "../../../helper";

function ProductAvailabliltyPie() {
  const designbankProductsAnalytics = useSelector(
    (state) => state.supplier.designbankProductsAnalytics
  );
  return (
    <Card sx={{ ...globalCardStyle }}>
      <CardContent>
        <Typography variant="h6" sx={{ mb: 3 }}>
          Total products availability
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <ReactApexChart
            options={options}
            series={[
              designbankProductsAnalytics?.readyStock || 0,
              designbankProductsAnalytics?.makeToOrder || 0,
            ]}
            type="pie"
            height={320}
            width={380}
          />
        </Box>
      </CardContent>
    </Card>
  );
}

export default ProductAvailabliltyPie;

const options = {
  chart: {
    width: 380,
    type: "pie",
  },
  colors: globalColors,
  legend: {
    position: "bottom",
  },
  labels: ["Ready Stock", "Make to Order"],
  responsive: [
    {
      breakpoint: 480,
      options: {
        chart: {
          width: 200,
        },
        legend: {
          position: "bottom",
        },
      },
    },
  ],
};
