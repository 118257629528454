const CUSTOMER_CONTACT_TYPES = [
  { label: "Customer", value: "customer" },
  { label: "Lead", value: "lead" },
  { label: "Supplier", value: "supplier" },
  { label: "Vendor", value: "vendor" },
  { label: "Karigar", value: "karigar" },
  { label: "Others", value: "others" },
];

export { CUSTOMER_CONTACT_TYPES };
