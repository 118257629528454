import {
  Box,
  Breadcrumbs,
  Button,
  FormControl,
  Grid,
  Link,
  MenuItem,
  Select,
  Stack,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { isArrayWithValues, isObjWithValues } from "../../../helper";
import { styled } from "@mui/material/styles";
import { LoadingButton } from "@mui/lab";
import { useDispatch } from "react-redux";
import {
  fetchAllSettings,
  getProductDefaultSettings,
  updateCustomerMasterPricings,
  updateMasterPricings,
} from "../../../redux/actions/settingActions";
import PropTypes from "prop-types";
import { useTheme } from "@mui/system";
import { Cached } from "@mui/icons-material";
import Labour from "./Labour";
import Diamond from "./Diamond";
import Gemstone from "./Gemstone";
import Additional from "./Additional";

const defaultTabs = [
  // { label: "Gold", value: "gold" },
  // { label: "Silver", value: "silver" },
  // { label: "Platinum", value: "platinum" },
  { label: "Labour", value: "labour" },
  { label: "Diamond", value: "diamond" },
  { label: "Gemstone", value: "gemstone" },
  { label: "Additional", value: "additional" },
];

const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))(({ theme }) => ({
  marginBottom: "15px",
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
  },
  "& .MuiTabs-indicatorSpan": {
    maxWidth: 40,
    width: "100%",
    backgroundColor: theme.palette.primary.main,
    // backgroundColor: "#635ee7",
  },
}));

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    padding: "5px 8px",
    minWidth: "50px",
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    color: theme.palette.text.secondary,
    "&.Mui-selected": {
      color: theme.palette.text.primary,
    },
    "&.Mui-focusVisible": {
      backgroundColor: "rgba(100, 95, 228, 0.32)",
    },
  })
);
// hallmark , certificate, rodiam

function Pricing(props) {
  const [allTabs, setAllTabs] = useState(defaultTabs);
  const [selectedTabName, setSelectedTabName] = useState("");
  const [selectedTab, setSelectedTab] = useState(0);
  const [customerId, setCustomerId] = useState(null);
  const [updating, setUpdating] = useState(false);
  const [customerCurrency, setCustomerCurrency] = useState();
  const [pricingType, setPricingType] = useState("master");
  const onTabChange = (e) => setSelectedTab(e.target.value);
  const dispatch = useDispatch();
  const goldRef = useRef();
  const silverRef = useRef();
  const platinumRef = useRef();
  const labourRef = useRef();
  const diamondRef = useRef();
  const gemstoneRef = useRef();
  const additionalRef = useRef();

  const productSettings = useSelector(
    (state) => state.settings.productSettings
  );
  const customerMasterPricing = useSelector(
    (state) => state.settings.customerMasterPricing
  );
  const fetchingAllSettings = useSelector(
    (state) => state.settings.fetchingAllSettings
  );

  useEffect(() => {
    if (isObjWithValues(props.customer)) setCustomerId(props.customer.id);
    let { customer } = props;
    if (isObjWithValues(customer) && isArrayWithValues(customer.meta_data)) {
      let currencyObj = customer.meta_data.find((i) => i.key === "currency");
      if (currencyObj) setCustomerCurrency(currencyObj.value);
    }
  }, [props.customer]);

  // useEffect(() => {
  //   if (props.customerCurrency) setCustomerCurrency(props.customerCurrency);
  // }, [props.customerCurrency]);

  useEffect(() => {
    if (isObjWithValues(productSettings))
      if (isArrayWithValues(productSettings.currency)) {
        let { default_currency } = productSettings;
        setCustomerCurrency(default_currency);
      }
  }, [productSettings]);

  useEffect(() => {
    if (isObjWithValues(productSettings)) {
      let {
        types,
        gold_purities,
        silver_purities,
        platinum_purities,
        default_currency,
      } = productSettings;
      let array = [];
      if (default_currency) {
        // setDefaultCurrency(default_currency);
        // setSelectedCurrency(default_currency);
      }
    }
    dispatch(getProductDefaultSettings());
  }, [productSettings]);

  useEffect(() => {
    // if (!isObjWithValues(productSettings)) dispatch(getProductSettings());
    // if (!isObjWithValues(customerMasterPricing))
    //   dispatch(getCustomerMasterPricings({ setLoading: setUpdating }));
    setCustomerPricingType();
    // if (!isObjWithValues(reduxProductSettings)) getProductSettings(isMounted);
    // else setSettingsFromRedux({ ...reduxProductSettings });
  }, []);

  useEffect(() => {
    setCustomerPricingType();
  }, [customerMasterPricing]);

  const setCustomerPricingType = () => {
    if (isObjWithValues(customerMasterPricing)) {
      let { customers } = customerMasterPricing;
      if (
        customers &&
        customers[props.customer && props.customer.id] &&
        customers[props.customer && props.customer.id].pricing_type
      ) {
        setPricingType(
          customers[props.customer && props.customer.id].pricing_type
        );
      }
    }
  };

  const fetchMasterPricings = () => {
    console.log("pressed");
    dispatch(fetchAllSettings({ setLoading: setUpdating }));
    // dispatch(getCustomerMasterPricings({ setLoading: setUpdating }));
  };

  useEffect(() => {
    if (isArrayWithValues(allTabs)) setSelectedTabName(allTabs[0].value);
    // setSelectedTab(3);
  }, [allTabs]);
  useEffect(() => {
    setSelectedTabName(allTabs[selectedTab].value);
  }, [selectedTab]);

  const onUpdate = ({
    labour_pricing,
    diamond_pricing,
    gemstone_pricing,
    additional_pricing,
    setLoading,
    callback,
  }) => {
    let customers = customerMasterPricing.customers;
    let customerObj =
      (customerMasterPricing.customers &&
        customerMasterPricing.customers[customerId]) ||
      {};
    customerObj = isObjWithValues(customerObj) ? customerObj : {};
    if (!labour_pricing)
      customerObj.labour_pricing = labourRef.current.getLabourPricing();
    else customerObj.labour_pricing = labour_pricing;
    if (!diamond_pricing)
      customerObj.diamond_pricing = diamondRef.current.getDiamondPricing();
    else customerObj.diamond_pricing = diamond_pricing;
    if (!gemstone_pricing)
      customerObj.gemstone_pricing = gemstoneRef.current.getGemstonePricing();
    else customerObj.gemstone_pricing = gemstone_pricing;
    if (!additional_pricing)
      customerObj.additional_pricing =
        additionalRef.current.getAdditionalPricing();
    customers = isObjWithValues(customers) ? customers : {};
    customerObj.pricing_type = pricingType;
    // console.log(customerObj);
    // return;
    // console.log({
    //   ...customerMasterPricing,
    //   customers: { ...customers, [customerId]: { ...customerObj } },
    // });
    dispatch(
      updateCustomerMasterPricings({
        data: {
          ...customerMasterPricing,
          customers: { ...customers, [customerId]: customerObj },
        },
        setLoading: (value) => {
          setUpdating(value);
          setLoading && setLoading(value);
        },
        callback,
      })
    );
  };
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Box>
      <Stack
        direction={"row"}
        alignItems="flex-end"
        justifyContent={"flex-end"}
        spacing={2}
        p={isMobile ? 3 : 4}
        pt={4}
      >
        <Stack direction="row" justifyContent={"flex-end"}>
          <LoadingButton
            loading={updating || fetchingAllSettings}
            // variant="contained"
            // loadingPosition=""
            onClick={fetchMasterPricings}
            startIcon={<Cached />}
          >
            {isMobile ? "" : "Refresh"}
          </LoadingButton>
          <LoadingButton
            // loading={updating}
            disabled={updating || fetchingAllSettings}
            variant="contained"
            // loadingPosition=""
            onClick={onUpdate}
            sx={{ mr: 2 }}
          >
            Update
          </LoadingButton>
          <FormControl size="small" disabled={updating || fetchingAllSettings}>
            {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={pricingType}
              // label="Age"
              onChange={(e) => setPricingType(e.target.value)}
            >
              <MenuItem value={"master"}>{`Master`}</MenuItem>
              <MenuItem value={"custom"}>{`Custom`}</MenuItem>
            </Select>
          </FormControl>
        </Stack>
      </Stack>
      {/* <Stack direction={"row"} justifyContent="flex-end"></Stack> */}
      <StyledTabs
        value={selectedTab}
        onChange={(e, value) => setSelectedTab(value)}
        aria-label="styled tabs example"
        variant="scrollable"
      >
        {allTabs.map((i) => (
          <StyledTab label={i.label} />
        ))}
        {/* <StyledTab label="Grouping" />
        <StyledTab label="Size Masters" />
        <StyledTab label="Team members" />
        <StyledTab label="Product Shortcuts" /> */}
        {/* <StyledTab label="Masters" /> */}
      </StyledTabs>
      {/* <Stack direction="row" sx={{ overflow: "auto" }}>
        {allTabs.map((i) => {
          const isSelected = i.value === selectedTab;
          return (
            <Button
              //   variant={isSelected ? "contained" : "text"}
              disableRipple
              sx={{
                mx: 2,
                backgroundColor: isSelected ? "#eee" : "transparent",
                color: isSelected ? "primary.main" : "text.secondary",
                whiteSpace: "nowrap",
              }}
              onClick={() => setSelectedTab(i.value)}
            >
              {i.label}
            </Button>
          );
        })}
      </Stack> */}
      <Box>
        <Box sx={{ display: selectedTabName === "labour" ? "block" : "none" }}>
          <Labour
            customerId={customerId}
            customerCurrency={customerCurrency}
            customerMasterPricing={customerMasterPricing}
            // pricing=
            pricingType={pricingType}
            ref={labourRef}
            productSettings={productSettings}
            // selectedCurrency={selectedCurrency}
            // defaultCurrency={defaultCurrency}
            // isDefaultCurrency={selectedCurrency === defaultCurrency}
            onMasterUpdate={onUpdate}
          />
        </Box>
        <Box sx={{ display: selectedTabName === "diamond" ? "block" : "none" }}>
          <Diamond
            ref={diamondRef}
            onMasterUpdate={onUpdate}
            pricingType={pricingType}
            customerId={customerId}
            productSettings={productSettings}
            customerCurrency={customerCurrency}
            customerMasterPricing={customerMasterPricing}
          />
        </Box>
        <Box
          sx={{ display: selectedTabName === "gemstone" ? "block" : "none" }}
        >
          <Gemstone
            ref={gemstoneRef}
            onMasterUpdate={onUpdate}
            pricingType={pricingType}
            customerId={customerId}
            productSettings={productSettings}
            customerCurrency={customerCurrency}
            customerMasterPricing={customerMasterPricing}
          />
        </Box>
        <Box
          sx={{ display: selectedTabName === "additional" ? "block" : "none" }}
        >
          <Additional
            ref={additionalRef}
            onMasterUpdate={onUpdate}
            pricingType={pricingType}
            customerId={customerId}
            productSettings={productSettings}
            customerCurrency={customerCurrency}
            customerMasterPricing={customerMasterPricing}
          />
        </Box>
        {/*
        <Box
          sx={{ display: selectedTabName === "gemstone" ? "block" : "none" }}
        >
          <Gemstone
            ref={gemstoneRef}
            onMasterUpdate={onUpdate}
            isDefaultCurrency={selectedCurrency === defaultCurrency}
            defaultCurrency={defaultCurrency}
            selectedCurrency={selectedCurrency}
            productSettings={productSettings}
          />
        </Box>
        <Box
          sx={{ display: selectedTabName === "additional" ? "block" : "none" }}
        >
          <Additional
            ref={additionalRef}
            productSettings={productSettings}
            isDefaultCurrency={selectedCurrency === defaultCurrency}
            defaultCurrency={defaultCurrency}
            selectedCurrency={selectedCurrency}
            onMasterUpdate={onUpdate}
          />
        </Box> */}
        {/* <TabPanel value={selectedTab} index={0}>
          <Gold ref={goldRef} goldPurities={goldPurities} />
        </TabPanel>
        <TabPanel value={selectedTab} index={1}>
          <Silver ref={silverRef} silverPurities={silverPurities} />
        </TabPanel> */}
        {/* {selectedTabName === "gold" && (
          <Gold ref={goldRef} goldPurities={goldPurities} />
        )}
        {selectedTabName === "silver" && (
          <Silver ref={silverRef} silverPurities={silverPurities} />
        )} */}
      </Box>
    </Box>
  );
}

export default Pricing;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
