import * as React from "react";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemButton,
  TextField,
} from "@mui/material";
import { KeyboardArrowRight, Search } from "@material-ui/icons";
import { columns as allColumns, products_keys_mapping } from "../product/utils";
import {
  primaryLabel,
  secondaryLabel,
  secondaryLabelSmall,
  validateNumber,
} from "../../../helper";
import { List as VirtualizedList } from "react-virtualized";

const ErrorsPopover = React.forwardRef(
  ({ navigateError, errors = [] }, ref) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    React.useImperativeHandle(ref, () => {
      return {
        open(e) {
          handleClick(e);
        },
        close() {
          handleClose();
        },
      };
    });

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;
    // return null;

    return (
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={{
          maxHeight: "400px",
          // boxShadow:
          //   "0rem 0.25rem 1.125rem -0.125rem #1f212414,0rem 0.75rem 1.125rem -0.125rem #1f212426",
        }}
      >
        {/* <Typography sx={{ p: 2 }}>The content of the Popover.</Typography> */}
        <Box sx={{ px: 3, py: 3, borderRadius: 10 }}>
          <Typography sx={{ ...secondaryLabel, color: "error.main" }}>
            Total {errors?.length} errors found
          </Typography>
          <List dense sx={{ width: "100%", maxWidth: 360 }}>
            <VirtualizedList
              rowCount={errors.length} // Total number of items in your data
              width={360}
              height={400}
              rowHeight={50} // Height of each row
              rowRenderer={({ index, key, style }) => {
                let i = errors?.[index];
                const labelId = `checkbox-list-secondary-label-${index}`;
                return (
                  <ListItem
                    key={key}
                    style={style}
                    secondaryAction={
                      <Typography
                        inputProps={{ "aria-labelledby": labelId }}
                        sx={{ ...secondaryLabelSmall }}
                      >
                        Row {validateNumber(i.row + 1)}
                      </Typography>
                    }
                    onClick={() => navigateError(i)}
                    //   disablePadding
                    sx={{ mt: 1.5, pl: 1, cursor: "pointer" }}
                  >
                    <ListItemButton sx={{ px: 0 }} disableGutters>
                      <Typography
                        id={labelId}
                        sx={{
                          ...secondaryLabel,
                          whiteSpace: "pre-wrap",
                          // pr: 4,
                        }}
                      >
                        {i.error}
                      </Typography>
                    </ListItemButton>
                  </ListItem>
                );
              }}
            />
            {/* {errors.map((i, index) => {
              const labelId = `checkbox-list-secondary-label-${index}`;
              return (
                <ListItem
                  key={index}
                  secondaryAction={
                    <Typography
                      inputProps={{ "aria-labelledby": labelId }}
                      sx={{ ...secondaryLabelSmall }}
                    >
                      Row {validateNumber(i.row + 1)}
                    </Typography>
                  }
                  onClick={() => navigateError(i)}
                  //   disablePadding
                  sx={{ mt: 1.5, pl: 1, cursor: "pointer" }}
                >
                  <ListItemButton sx={{ px: 0 }} disableGutters>
                    
                    <Typography
                      id={labelId}
                      sx={{
                        ...secondaryLabel,
                        whiteSpace: "pre-wrap",
                        // pr: 4,
                      }}
                    >
                      {i.error}
                    </Typography>
                  </ListItemButton>
                </ListItem>
              );
            })} */}
          </List>
        </Box>
      </Popover>
    );
  }
);

export default ErrorsPopover;
