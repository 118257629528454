import { getUserProduct, isArrayWithValues } from "./helper";

export default function displayState({ key }) {
  let user_products = getUserProduct();
  const products = {
    whatsapp: {
      //chats / Message
      chatsChannelBar: false,
      mergeOptions: false,
      overviewChannelCard: false,
      //dashborad
      dashboardproductAndScore: false,
      //profile
      profileSocialLink: false,
      profileCompanyDetails: false,
      // team members / form
      productStack: false,
      crmStack: false,
      catalogStack: false,
      /// chats > filters
      chatsCrmFilters: false,
    },
    chats: {
      // team members / form
      productStack: false,
      crmStack: false,
      catalogStack: false,
      /// chats > filters
      chatsCrmFilters: false,
      dashboardproductAndScore: false,
    },
    crm: {
      // team members / form
      chatsStack: false,
      catalogStack: false,
      dashboardproductAndScore: false,
    },
  };

  let returnValur = true;
  if (isArrayWithValues(user_products)) {
    for (const product of user_products || []) {
      if (Object.keys(products)?.includes(product)) {
        let tempReturnValue = products?.[product]?.[key];

        if (typeof tempReturnValue === "undefined") {
          // Code to execute if myVariable is undefined
        } else {
          // Code to execute if myVariable is defined

          returnValur = products?.[product]?.[key];
        }
      }
    }
  }

  return returnValur;
}
