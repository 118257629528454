import { Card, CardContent, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useSelector } from "react-redux";
import {
  formatServerValue,
  globalCardStyle,
  globalColors,
  isObjWithValues,
  sortObjectByValues,
} from "../../../helper";

function ProductsPulledGraph() {
  const [info, setInfo] = useState({
    labels: [],
    series: [],
  });
  const designbankProductsAnalytics = useSelector(
    (state) => state.supplier.designbankProductsAnalytics
  );
  const allSuppliers = useSelector((state) => state.supplier.allSuppliers);

  useEffect(() => {
    if (!isObjWithValues(designbankProductsAnalytics)) return;
    let { totalProductsByCollection, totalProductsPulledByCollection } =
      designbankProductsAnalytics;
    if (
      !isObjWithValues(totalProductsByCollection) ||
      !isObjWithValues(totalProductsPulledByCollection)
    )
      return;

    let pulledCollection = sortObjectByValues(
      totalProductsPulledByCollection,
      true
    );

    let totalCollection = {};
    Object.keys(pulledCollection).map(
      (i) => (totalCollection[i] = totalProductsByCollection[i])
    );

    setInfo({
      labels: Object.keys(totalCollection)
        .map((i) => formatServerValue(i))
        .filter(Boolean),
      series: [
        { data: Object.values(totalCollection).filter(Boolean), name: "Total" },
        {
          data: Object.values(pulledCollection).filter(Boolean),
          name: "Pulled",
        },
      ],
    });
  }, [designbankProductsAnalytics, allSuppliers]);
  return (
    <Card sx={{ ...globalCardStyle }}>
      <CardContent>
        <Typography variant="h6" sx={{ mb: 3 }}>
          Total products pulled
        </Typography>
        <ReactApexChart
          options={{
            ...options,
            xaxis: {
              categories: info.labels,
            },
          }}
          series={info.series}
          type="bar"
          height={350}
        />
      </CardContent>
    </Card>
  );
}

export default ProductsPulledGraph;

const options = {
  chart: {
    type: "bar",
    height: 350,
  },
  colors: globalColors,
  plotOptions: {
    bar: {
      horizontal: false,
      columnWidth: "55%",
      endingShape: "rounded",
    },
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    show: true,
    width: 2,
    colors: ["transparent"],
  },
  // yaxis: {
  //   title: {
  //     text: "$ (thousands)",
  //   },
  // },
  fill: {
    opacity: 1,
  },
  tooltip: {
    // y: {
    //   formatter: function (val) {
    //     return "$ " + val + " thousands";
    //   },
    // },
  },
};
