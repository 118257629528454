import { Cancel, CheckCircle, Close, FileUpload } from "@mui/icons-material";
import {
  DesktopDatePicker,
  DesktopDateTimePicker,
  LoadingButton,
  LocalizationProvider,
  Masonry,
  MobileDateTimePicker,
} from "@mui/lab";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import {
  Avatar,
  Button,
  Card,
  CardContent,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  ImageList,
  ImageListItem,
  Paper,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import React, {
  createRef,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import styled, { useTheme } from "styled-components";
import {
  dialogStyle,
  getAxiosError,
  getIntegrationObj,
  getToken,
  getWebsite,
  isArrayWithValues,
  isObjWithValues,
  postIntegrationData,
  validateNumber,
} from "../../helper";
import { setGlobalToast } from "../../redux/actions/helperActions";
import { Facebook, Google, InstagramSvg, Linkedin } from "../../Svgs";
import CustomSwitch from "../components/CustomeSwitch";
import ProductMediaLibrary from "../components/ProductMediaLibrary";
import { FormLabel } from "../settings/Email";
import { addScheduleActivity } from "../../redux/actions/settingActions";

const CreatePost = forwardRef(({ onPostCreated }, ref) => {
  const defaultHasError = {
    title: false,
    photos: false,
    caption: false,
    time: false,
  };
  const dispatch = useDispatch();
  const [mediaLibraryOpen, setMediaLibraryOpen] = useState(false);
  const [open, setOpen] = useState(false);
  // const [selectedImage, setSelectedImage] = useState({});
  const [description, setDescription] = useState("");
  // const [source_url, setSource_url] = useState("");
  // const [imageTitle, setImageTitle] = useState("");
  const [title, setTitle] = useState("");
  const [rawImage, setRawImage] = useState(null);
  const inputRef = createRef();
  const [selectedSocial, setSelectedSocial] = useState(["facebook"]);
  const [posting, setPosting] = useState(false);
  const [facebookPage, setFacebookpage] = useState({});
  const [instagramId, setInstagramId] = useState("");
  const [imageArray, setImageArray] = useState([]);
  const [schedulePost, setSchedulePost] = useState(false);
  const [schedulePostTime, setSchedulePostTime] = useState(Date.now());
  const [scheduleTitle, setScheduleTitle] = useState("");
  const [hasError, setHasError] = useState(defaultHasError);
  const facebookIntegration = useSelector(
    (state) => state.settings.facebookIntegration
  );
  const reduxMetaIntegration = useSelector(
    (state) => state.settings.metaIntegration
  );
  const [uploadedUrl, setUploadedUrl] = useState("");
  useEffect(() => {
    if (isObjWithValues(reduxMetaIntegration)) {
      for (const key in reduxMetaIntegration) {
        if (!isArrayWithValues(reduxMetaIntegration[key]?.connected)) return;
        if (reduxMetaIntegration[key]?.connected.includes("facebook")) {
          let facebookObj = { ...reduxMetaIntegration[key] };
          setFacebookpage(facebookObj?.facebook_pages_info || {});
        }
      }
    }
    let instagramObj = getIntegrationObj(reduxMetaIntegration, "instagram");

    if (instagramObj?.obj?.instagram_business_id)
      setInstagramId(instagramObj?.obj?.instagram_business_id);
  }, [reduxMetaIntegration]);
  const removeTagsFromString = (string) =>
    string ? string.replace(/<\/?[^>]+(>|$)/g, "") : "";
  const setImages = async (arr) => {
    setImageArray(arr.map((obj) => obj?.source_url));
    // setSelectedImage(arr[0]);
    // setSource_url(arr[0]?.source_url);
    // setImageTitle(arr[0]?.title?.rendered);
    setMediaLibraryOpen(false);
  };
  useImperativeHandle(ref, () => ({
    getAlert() {
      alert("get Alert from Child");
    },
    parentData(data) {
      setTitle(data?.name);
      setDescription(removeTagsFromString(data?.description));
      if (isArrayWithValues(data?.images)) {
        setImageArray(data?.images.map((obj) => obj.src));
        // setImageTitle(data?.images[0]?.name || "");
        // setSource_url(data?.images[0]?.src || "");
        // setUploadedUrl(data?.images[0]?.src || "");
      }
    },

    openCreateDialog() {
      setOpen(true);
    },
    closeCreateDialog() {
      setOpen(false);
    },
  }));
  const onFileDrop = (value) => {
    //JPG, PNG, GIF, TIFF, HEIF or WebP
    const types = [
      "image/jpeg",
      "image/png",
      "image/gif",
      "image/tiff",
      "image/webp",
      "image/heif",
    ];
    let size = Math.round(validateNumber(value.size / 1000));
    let type = value.type;
    if (!types.includes(type))
      return dispatch(
        setGlobalToast({
          show: true,
          message: "Only JPG, PNG, GIF, TIFF, HEIF or WebP formats are allowed",
          severity: "error",
        })
      );
    if (size > 512)
      return dispatch(
        setGlobalToast({
          show: true,
          message: "File size should not exceed 500kb.",
          severity: "error",
        })
      );

    setRawImage(value);
    // setSelectedImage(URL.createObjectURL(value));
  };

  const onSocialPress = (value) => {
    if (value === "google") return;
    if (value === "linkedin") return;
    let array = [...selectedSocial];
    if (selectedSocial.includes(value))
      array = array.filter((i) => i !== value);
    else array.push(value);
    setSelectedSocial(array);
  };

  const onCreatePost = async () => {
    if (!description) {
      setHasError((pre) => ({ ...pre, caption: true }));
      return;
    }
    if (schedulePost) {
      if (!scheduleTitle) {
        setHasError((pre) => ({ ...pre, scheduleTitle: true }));
        return;
      }
      if (new Date(schedulePostTime).getTime() < new Date().getTime()) {
        return;
      }
      if (selectedSocial.includes("facebook")) {
        dispatch(
          addScheduleActivity({
            time: new Date(schedulePostTime).getTime(),
            patform: "facebook",
            caption: description,
            imageArray: imageArray,
            schedule_title: scheduleTitle,
          })
        );
      }
      if (selectedSocial.includes("instagram")) {
        dispatch(
          addScheduleActivity({
            time: new Date(schedulePostTime).getTime(),
            patform: "facebook",
            caption: description,
            imageArray: imageArray,
            schedule_title: scheduleTitle,
          })
        );
      }
      return;
    } else {
      setPosting(true);
      if (selectedSocial.includes("facebook")) {
        let facebookRes = await createFacebookPost();
      }
      if (selectedSocial.includes("instagram")) {
        let instaRes = await createInstagramPost(imageArray);
      }
      // let instaRes = await createInstagramPost();
      // console.log();
      // return;

      // setSelectedImage({});
      setImageArray([]);
      setRawImage(null);
      setDescription("");
      setPosting(false);
      setTitle("");
      setOpen(false);
      onPostCreated && onPostCreated();
    }
  };
  // console.log((schedulePostTime.getTime() - schedulePostTime.getMilliseconds()));
  const createFacebookPost = async () => {
    try {
      let endpoint = `/${facebookPage?.id}/photos?message=${description}&url=${imageArray[0]}`;
      let res = await postIntegrationData({
        app: "facebook",
        endpoint: endpoint,
        method: "POST",
      });

      // let res = await axios({
      //   url: `${address}/${page.id}/photos?message=${description}&url=${source_url}&access_token=${access_token}`,
      //   method: "POST",
      // });
    } catch (e) {
      console.log(getAxiosError(e));
      let { error } = e;
      if (error && error.error_user_msg)
        dispatch(
          setGlobalToast({
            show: true,
            message: error.error_user_msg,
            severity: "error",
          })
        );
      else
        dispatch(
          setGlobalToast({
            show: true,
            message: "Couldn't create post! try again later.",
            severity: "error",
          })
        );
    }
  };

  const onRemoveImage = (url) => {
    let newImageArray = imageArray.filter((imgeUrl) => imgeUrl !== url);
    setImageArray(newImageArray);
  };
  const createPostContiner = async (imageLink) => {
    console.log(
      `/${instagramId}/media?image_url=${imageLink}&caption=${description}&is_carousel_item=${true}`
    );
    try {
      let res = await postIntegrationData({
        app: "facebook",
        endpoint: `/${instagramId}/media?image_url=${imageLink}&caption=${description}&is_carousel_item=${true}`,
        method: "POST",
      });
      return res;
    } catch (error) {
      console.log(getAxiosError(error));
    }
  };
  const makeCarouselItem = async (continerArr) => {
    try {
      let res = await postIntegrationData({
        app: "facebook",
        endpoint: `/${instagramId}/media?media_type=CAROUSEL&caption=${description}${continerArr
          .map((id, index) => `&children[${index}]=${id}`)
          .join("")}`,
        method: "POST",
      });
      return res;
    } catch (error) {
      console.log(getAxiosError(error));
    }
  };
  const pusblishPost = async (carouselid) => {
    console.log(`/${instagramId}/media_publish?creation_id=${carouselid}`);
    try {
      let res = await postIntegrationData({
        app: "facebook",
        endpoint: `/${instagramId}/media_publish?creation_id=${carouselid}`,
        method: "POST",
      });
      return res;
    } catch (error) {
      console.log(getAxiosError(error));
    }
  };
  const createInstagramPost = async (imageArr) => {
    let imageContinerId = [];
    for await (const url of imageArr) {
      let imageContinerRes = await createPostContiner(url);
      console.log(imageContinerRes?.data);
      if (imageContinerRes?.data?.id)
        imageContinerId.push(imageContinerRes?.data?.id);
    }
    if (imageContinerId.length === 1) {
      let postRes = await pusblishPost(imageContinerId[0]);
      console.log(postRes);
    } else {
      let carouselRes = await makeCarouselItem(imageContinerId);
      console.log(carouselRes);
      let postRes = await pusblishPost(carouselRes?.data?.id);
      console.log(postRes);
    }
  };
  const globalPopupStyle =
    useSelector((state) => state.helper.globalPopupStyle) || {};
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <React.Fragment>
      <input
        type="file"
        style={{ display: "none" }}
        ref={inputRef}
        onChange={(event) => onFileDrop(event.target.files[0])}
      />
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="sm"
        sx={dialogStyle}
        {...globalPopupStyle}
      >
        <Stack
          direction={"row"}
          justifyContent="space-between"
          alignContent={"center"}
          sx={{ padding: "16px" }}
        >
          <Typography variant="h6" alignSelf={"center"}>
            Create Post
          </Typography>
          <IconButton onClick={() => setOpen(false)}>
            <Close />
          </IconButton>
        </Stack>
        {/* <DialogTitle id="alert-dialog-title" sx={{ borderBottom: 1, borderColor: "divider", mb: 2 }}></DialogTitle> */}
        <Stack
          direction={"row"}
          spacing={3}
          px={6}
          alignItems="center"
          // justifyContent={"center"}
          overflow="scroll"
          sx={isMobile ? { paddingRight: "130px" } : {}}
        >
          <ButtonCard
            icon={<Facebook />}
            isSelected={selectedSocial.includes("facebook")}
            onClick={() => onSocialPress("facebook")}
          >
            Facebook
          </ButtonCard>
          {/* <ButtonCard
            icon={<Google />}
            isSelected={selectedSocial.includes("google")}
            onClick={() => onSocialPress("google")}
            disabled={true}
          >
            Google
          </ButtonCard> */}
          <ButtonCard
            icon={<InstagramSvg />}
            isSelected={selectedSocial.includes("instagram")}
            onClick={() => onSocialPress("instagram")}
          >
            Instagram
          </ButtonCard>
          {/* <ButtonCard
            icon={<Linkedin />}
            isSelected={selectedSocial.includes("linkedin")}
            onClick={() => onSocialPress("linkedin")}
            disabled={true}
          >
            Linkedin
          </ButtonCard> */}

          {/* <Button
            size="small"
            variant={
              !selectedSocial.includes("facebook") ? "outlined" : "contained"
            }
            startIcon={<Facebook />}
            onClick={() => onSocialPress("facebook")}
            disabled={!facebookPage?.id}
          >
            Facebook
          </Button>
          <Button
            startIcon={<Google />}
            size="small"
            variant={
              !selectedSocial.includes("google") ? "outlined" : "contained"
            }
            disabled
            onClick={() => onSocialPress("google")}
          >
            Google
          </Button>
          <Button
            startIcon={<InstagramSvg />}
            size="small"
            variant={
              !selectedSocial.includes("instagram") ? "outlined" : "contained"
            }
            disabled={!instagramId}
            onClick={() => onSocialPress("instagram")}
          >
            Instagram
          </Button>
          <Button
            startIcon={<Linkedin />}
            size="small"
            variant={
              !selectedSocial.includes("linkedin") ? "outlined" : "contained"
            }
            disabled
            onClick={() => onSocialPress("linkedin")}
          >
            Linkedin
          </Button> */}
        </Stack>
        <DialogContent>
          <FormLabel>Title</FormLabel>
          <TextField
            fullWidth
            size="small"
            // label="Title"
            placeholder=" Title"
            // sx={{ mt: 2 }}
            // variant="standard"
            autoFocus
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
          <FormLabel sx={{ marginTop: "14px" }}>Add to your post</FormLabel>
          {!isArrayWithValues(imageArray) ? (
            <Button
              variant="outlined"
              fullWidth
              sx={{ height: "200px", border: "2px dashed" }}
              startIcon={
                <FileUpload />
                // source_url ? (
                //   <img
                //     src={source_url}
                //     style={{ height: "90px", width: "90px" }}
                //   />
                // ) : (
                //   <FileUpload />
                // )
              }
              // onClick={() => inputRef.current.click()}
              onClick={() => setMediaLibraryOpen(true)}
            >
              {/* {imageTitle ? imageTitle : "Add Image"} */}
              {"Add Image"}
            </Button>
          ) : null}
          {isArrayWithValues(imageArray) ? (
            <ImageList
              cols={3}
              gap={8}
              sx={{ height: "200px", border: "2px dashed" }}
            >
              {imageArray.map((item) => (
                <ImageListItem key={item}>
                  <IconButton
                    sx={{
                      position: "absolute",
                      right: "-12px",
                      top: "-12px",
                      zIndex: 2,
                      overflow: "hidden",
                    }}
                    onClick={() => onRemoveImage(item)}
                    aria-label="delete"
                  >
                    <Cancel
                      size="small"
                      color="primary"
                      sx={{
                        backgroundColor: "#fff",
                        borderRadius: "50%",
                      }}
                    />
                  </IconButton>
                  <img
                    src={`${item}`}
                    // srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                    alt={item}
                    loading="lazy"
                  />
                </ImageListItem>
              ))}
            </ImageList>
          ) : null}
          {/* {isArrayWithValues(imageArray) ?
            <Box sx={{ border: "2px dashed" }}>
              <Masonry columns={2} spacing={1} sx={{ height: "200px", width: "400px" }}>
                {imageArray.map((image, index) => (
                  <Item key={index} >
                    <img src={image} style={{ width: "100%" }} />
                  </Item>
                ))}
              </Masonry>
            </Box>
            : null} */}
          <FormLabel sx={{ marginTop: "14px" }}>Caption</FormLabel>
          <TextField
            fullWidth
            size="small"
            placeholder="Caption"
            // variant="standard"
            error={hasError?.caption}
            helperText={hasError?.caption ? "Caption is required* " : ""}
            multiline
            rows={3}
            minRows={3}
            sx={{ lineClamp: 3 }}
            value={description}
            onChange={(e) => {
              setDescription(e.target.value);
              setHasError((pre) => ({ ...pre, caption: false }));
            }}
          />
          <Stack
            sx={{ marginTop: "14px" }}
            direction="row"
            gap={"10px"}
            alignItems="center"
          >
            <FormLabel>Schedule Post</FormLabel>
            <CustomSwitch
              onChange={(e) => setSchedulePost(e)}
              checked={schedulePost}
            />
          </Stack>
          <Collapse in={schedulePost}>
            <Box sx={{ mt: 2 }}>
              <Stack sx={{ mt: 1, mb: 3 }}>
                <FormLabel sx={{ marginTop: "14px" }}>Schedule Title</FormLabel>
                <TextField
                  // fullWidth
                  size="small"
                  placeholder="Schedule Title"
                  // variant="standard"
                  error={hasError?.scheduleTitle}
                  helperText={
                    hasError?.scheduleTitle
                      ? "Schedule title is required* "
                      : ""
                  }
                  value={scheduleTitle}
                  onChange={(e) => {
                    setScheduleTitle(e.target.value);
                    setHasError((pre) => ({ ...pre, scheduleTitle: false }));
                  }}
                />
              </Stack>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDateTimePicker
                  error={hasError?.time}
                  value={schedulePostTime ? schedulePostTime : null}
                  onChange={(newValue) => {
                    setHasError((pre) => ({
                      ...pre,
                      time: new Date(newValue).getTime() < new Date().getTime(),
                    }));
                    setSchedulePostTime(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      helperText={
                        hasError?.time ? "Time must be of future" : ""
                      }
                      error={hasError?.time}
                    />
                  )}
                />
              </LocalizationProvider>
            </Box>
          </Collapse>
        </DialogContent>
        <DialogActions>
          {/* <Button
            onClick={() => setOpen(false)}
            sx={{ color: "text.secondary" }}
          >
            Close
          </Button> */}
          <LoadingButton loading={posting} onClick={onCreatePost} autoFocus>
            Post
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <ProductMediaLibrary
        open={mediaLibraryOpen}
        onChange={() => {
          setMediaLibraryOpen(false);
        }}
        setParentImages={setImages}
        // multiple={false}
      />
    </React.Fragment>
  );
});

export default CreatePost;

const ButtonCard = (props) => {
  const { isSelected } = props;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const padding = isMobile ? "9px" : "12px";
  return (
    <Card
      sx={{
        border: `1px solid `,
        borderColor: !isSelected ? "grey.500" : "priamry.main",
        cursor: "pointer",
        margin: "6px 6px",
        overflow: "visible",
        position: "relative",
        cursor: "pointer",
      }}
      {...props}
    >
      <CardContent
        sx={{
          textAlign: "center",
          padding,
          "&:last-child": { paddingBottom: padding },
        }}
      >
        {isSelected ? (
          <CheckCircle
            style={{
              position: "absolute",
              right: "-4px",
              top: "-4px",
              width: "16px",
              height: "16px",
            }}
            width={9}
            height={9}
          />
        ) : null}
        <div style={{ display: "flex" }}>
          {props?.icon}
          <Typography
            variant={isMobile ? "body2" : "h6"}
            ml={2}
            color={props?.disabled ? "text.secondary" : "text.primary"}
            // color="GrayText"
          >
            {props?.children}
          </Typography>
        </div>
      </CardContent>
    </Card>
  );
};

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(0.5),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
