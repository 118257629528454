import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { columns, products_keys_mapping } from "../utils";

const ExportDialog = ({ open, handleClose, onAddFile }) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Export products</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description" sx={{ mt: 2 }}>
          {/* Let Google help apps determine location. This means sending anonymous
            location data to Google, even when no apps are running. */}
          This CSV file can update all products information
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="outlined">
          Cancel
        </Button>
        <Button onClick={handleClose} autoFocus>
          Export
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ExportDialog;
