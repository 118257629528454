import { Cancel } from "@material-ui/icons";
import { Add } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { useSelector } from "react-redux";
import {
  dialogStyle,
  formatServerValue,
  isArrayWithValues,
  isObjWithValues,
} from "../../helper";
import { currencySymbols } from "./Gold";

const Additional = forwardRef((props, ref) => {
  let { isDefaultCurrency, defaultCurrency, selectedCurrency } = props;
  const [allCurrency, setAllCurrency] = useState([]);
  // const [selectedCurrency, setSelectedCurrency] = useState("");
  const [pricing, setPricing] = useState({
    hallmark: {},
    certificate: {},
    rhodium: {},
  });
  const [addFormOpen, setAddFormOpen] = useState(false);
  const [addLabel, setAddLabel] = useState("");
  const [addLabelError, setAddLabelError] = useState("");
  const masterPricing = useSelector((state) => state.settings.masterPricing);
  const fetchingMasterPricing = useSelector(
    (state) => state.settings.fetchingMasterPricing
  );

  useImperativeHandle(ref, () => ({
    getAdditionalPricing() {
      //   let obj = {};
      //   for (let i = 0; i < allCurrency.length; i++)
      //     obj[allCurrency[i].value] = pricing[allCurrency[i].value];

      return { ...pricing };
    },
  }));

  useEffect(() => {
    if (
      isObjWithValues(masterPricing) &&
      isObjWithValues(masterPricing.additional_pricing)
    ) {
      setPricing((state) => ({
        ...state,
        ...masterPricing.additional_pricing,
      }));
    }
  }, [masterPricing]);
  useEffect(() => {
    if (isObjWithValues(props.productSettings))
      if (isArrayWithValues(props.productSettings.currency)) {
        let { currency } = props.productSettings;
        // setSelectedCurrency(currency[0].value);
        setAllCurrency(props.productSettings.currency);
      }
  }, [props.productSettings]);

  const onPricingChange = (label, value) => {
    setPricing((state) => ({
      ...state,
      [label]: { ...pricing[label], [selectedCurrency]: value },
    }));
  };
  const closeAddForm = () => setAddFormOpen(false);
  const openAddForm = () => setAddFormOpen(true);

  const onAddLabel = () => {
    if (pricing[addLabel])
      return setAddLabelError(`${addLabel} is already added.`);
    setPricing((state) => ({ ...state, [addLabel]: {} }));
    setAddLabelError("");
    setAddFormOpen(false);
  };
  const onAddLabelChange = (e) => {
    if (addLabelError) setAddLabelError("");
    setAddLabel(e.target.value);
  };
  const onRemoveLabel = (label) => {
    let obj = { ...pricing };
    delete obj[label];
    setPricing(obj);
  };

  if (fetchingMasterPricing)
    return (
      <Box>
        <Card
          sx={{
            borderRadius: "10px",
            boxShadow: "0px 0px 3px rgba(0, 0, 0, 0.25)",
          }}
        >
          <CardContent
            sx={{
              "&:last-child": { paddingBottom: "16px" },
            }}
          >
            <Stack
              direction="row"
              justifyContent={"center"}
              alignItems="center"
              my={4}
            >
              <CircularProgress />
            </Stack>
          </CardContent>
        </Card>
      </Box>
    );
  return (
    <Box>
      <Stack direction="row" justifyContent={"flex-end"}>
        <Button
          size="small"
          sx={{ mr: 2 }}
          startIcon={<Add />}
          onClick={openAddForm}
        >
          Add New Label
        </Button>
      </Stack>
      <Card
        sx={{
          borderRadius: "10px",
          boxShadow: "0px 0px 3px rgba(0, 0, 0, 0.25)",
          marginTop: "14px",
        }}
      >
        <CardContent
          sx={{
            "&:last-child": { paddingBottom: "16px" },
          }}
        >
          <Grid container spacing={5}>
            {Object.keys(pricing).map((i) => {
              return (
                <Grid item xs={12} sm={6} md={4} style={{ marginTop: "12px" }}>
                  <Stack
                    direction="row"
                    alignItems={"center"}
                    justifyContent="space-between"
                  >
                    <Typography sx={{ fontSize: "14px", fontWeight: "600" }}>
                      {formatServerValue(i)}
                    </Typography>
                    <Button
                      size="small"
                      color="error"
                      onClick={() => onRemoveLabel(i)}
                    >
                      Remove
                    </Button>
                  </Stack>
                  <TextField
                    value={pricing[i][selectedCurrency] || ""}
                    onChange={(e) => onPricingChange(i, e.target.value)}
                    // size="small"
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {currencySymbols[selectedCurrency]}
                        </InputAdornment>
                      ),
                    }}
                  />
                  {/* <Stack
                    direction="row"
                    alignItems={"center"}
                    justifyContent="space-between"
                  >
                    <Typography variant="h6">{i}: </Typography>
                    <Stack direction="row" alignItems={"center"}>
                      <TextField
                        value={pricing[i][selectedCurrency] || ""}
                        onChange={(e) => onPricingChange(i, e.target.value)}
                        size="small"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              {currencySymbols[selectedCurrency]}
                            </InputAdornment>
                          ),
                        }}
                      />
                      <IconButton size="small">
                        <Cancel />
                      </IconButton>
                    </Stack>
                  </Stack> */}
                </Grid>
              );
            })}
          </Grid>
        </CardContent>
      </Card>
      <Dialog
        sx={dialogStyle}
        open={addFormOpen}
        maxWidth="sm"
        fullWidth
        onClose={closeAddForm}
      >
        <DialogTitle>Add New</DialogTitle>
        <DialogContent>
          {/* <DialogContentText>
            To subscribe to this website, please enter your email address here.
            We will send updates occasionally.
          </DialogContentText> */}
          <TextField
            value={addLabel}
            onChange={onAddLabelChange}
            autoFocus
            margin="dense"
            id="name"
            label="New Label"
            fullWidth
            variant="standard"
            error={Boolean(addLabelError)}
            helperText={addLabelError}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={closeAddForm}>Cancel</Button>
          <Button onClick={onAddLabel} disabled={!Boolean(addLabel)}>
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
});

export default Additional;
