import {
  alpha,
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Paper,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Tabs,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
  TablePagination,
  IconButton,
  Avatar,
  Menu,
  MenuItem,
} from "@mui/material";
import { useTheme } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  a11yProps,
  formatDate,
  formatServerValue,
  getComparator,
  getLastMessageFromObj,
  getNameFromPlatform,
  getRandomInt,
  globalCardStyle,
  isArrayWithValues,
  isObjWithValues,
  reduceLargeText,
  sortObjectByValues,
  stableSort,
  TabPanel,
  validateNumber,
} from "../../helper";
import {
  NoTasks,
  platformIcons,
  primaryLabel,
  secondaryLabel,
  sectionLabel,
  userTasksHeadCells,
  userTasksHeadCellsWithoutCustomer,
} from "./ChatHelper";
import { visuallyHidden } from "@mui/utils";
import { MoreVert } from "@material-ui/icons";
import { ChatStatusChip } from ".";
import { CallCircle } from "../../Svgs";

const CallTable = ({ customer, openUpdateForm, openDeleteForm, user_from }) => {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [rows, setRows] = useState([]);

  const allUserTasksFetched = useSelector(
    (state) => state.chats.allUserTasksFetched
  );
  const settingsUpUserTasks = useSelector(
    (state) => state.chats.settingsUpUserTasks
  );
  const allCalls = useSelector((state) => state.chats.allCalls);
  const no_user_tasks = useSelector((state) => state.chats.no_user_tasks);
  const [showCustomerColumn, setShowCustomerColumn] = useState(true);

  useEffect(() => {
    setShowCustomerColumn(!isObjWithValues(customer));
  }, [customer]);

  // useEffect(() => {
  //   if (!isArrayWithValues(allCalls)) return;
  //   setRows(allCalls);
  // }, [allCalls]);

  useEffect(() => {
    if (!isArrayWithValues(allCalls)) return;
    if (isObjWithValues(customer)) {
      setRows(
        allCalls
          .filter((i) => i.user_id == customer.id)
          ?.filter((i) =>
            user_from !== "chat" ? i.user_from === user_from : true
          )
      );
    } else setRows(allCalls);
  }, [allCalls, customer]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <Card sx={{ width: "100%", mt: 4, ...globalCardStyle }}>
      <CardContent>
        {/* <Paper sx={{ width: "100%", mb: 2, pt: 3 }}> */}
        {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
        <Stack direction="row" alignItems={"center"} spacing={2} sx={{ ml: 2 }}>
          <CallCircle height={"25px"} width="25px" />
          <Typography
            sx={{ ...sectionLabel, padding: "0px", paddingLeft: "3px" }}
          >
            Calls
          </Typography>
        </Stack>
        {(allUserTasksFetched &&
          !settingsUpUserTasks &&
          !isArrayWithValues(rows)) ||
        no_user_tasks ? (
          <NoTasks
            text="No reminder added yet"
            icon={
              <CallCircle
                style={{ height: "70px", width: "70px", fill: "#c4c4c4" }}
                fillCircle="#c4c4c4"
              />
            }
          />
        ) : (
          <TableContainer>
            <Table
              aria-labelledby="tableTitle"
              size={dense ? "small" : "medium"}
            >
              <TableHead>
                <TableRow>
                  {/* <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{
                          'aria-label': 'select all desserts',
                        }}
                      />
                    </TableCell> */}
                  {(showCustomerColumn
                    ? userTasksHeadCells
                    : userTasksHeadCellsWithoutCustomer
                  ).map((headCell) => (
                    <TableCell
                      key={headCell.id}
                      align={headCell.numeric ? "right" : "left"}
                      padding={headCell.disablePadding ? "none" : "normal"}
                      sortDirection={orderBy === headCell.id ? order : false}
                    >
                      <TableSortLabel
                        active={orderBy === headCell.id}
                        direction={orderBy === headCell.id ? order : "asc"}
                        // onClick={createSortHandler(headCell.id)}
                      >
                        {headCell.label}
                        {orderBy === headCell.id ? (
                          <Box component="span" sx={visuallyHidden}>
                            {order === "desc"
                              ? "sorted descending"
                              : "sorted ascending"}
                          </Box>
                        ) : null}
                      </TableSortLabel>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                   rows.sort(getComparator(order, orderBy)).slice() */}
                {stableSort(rows, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row.name);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    let { platform } = row;
                    let lastMessage = "";
                    if (platform === "whatsapp") {
                      lastMessage = row?.message?.text?.body || "";
                    }
                    if (platform !== "whatsapp")
                      lastMessage = row?.message?.text || "";
                    return (
                      <Row
                        showCustomerColumn={showCustomerColumn}
                        row={row}
                        index={index}
                        openForm={openUpdateForm}
                        openDeleteForm={openDeleteForm}
                      />
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        {rows.length > 5 ? (
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        ) : null}
        {/* </Paper> */}
      </CardContent>
    </Card>
  );
};
export default CallTable;

const Row = ({ showCustomerColumn, row, index, openForm, openDeleteForm }) => {
  const labelId = `enhanced-table-checkbox-${index}`;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <TableRow
      hover
      onClick={() => {
        handleClose();
        openForm(row);
      }}
      // role="checkbox"
      sx={{ py: 2, cursor: "pointer" }}
      // aria-checked={isItemSelected}
      tabIndex={-1}
      key={row.name}
      // selected={isItemSelected}
    >
      {/* <TableCell padding="checkbox">
                              <Checkbox
                                color="primary"
                                checked={isItemSelected}
                                inputProps={{
                                  "aria-labelledby": labelId,
                                }}
                              />
                            </TableCell> */}
      {showCustomerColumn ? (
        <TableCell
          component="th"
          id={labelId}
          scope="row"
          // padding="none"
        >
          <Stack direction={"row"} alignItems="center" spacing={3}>
            <Avatar src={row.profile_pic} />
            <Typography sx={{ ...primaryLabel }}>
              {row.userDetails?.name}
            </Typography>
          </Stack>
        </TableCell>
      ) : null}
      <TableCell align="left">
        <Typography sx={{ ...primaryLabel }}>
          {formatServerValue(row.title)}
        </Typography>
      </TableCell>
      <TableCell align="right" style={{ whiteSpace: "nowrap" }}>
        <Typography sx={{ ...primaryLabel }}>
          {formatDate(new Date(row.date_created), "dd-mm-yyyy")}
        </Typography>
        <Typography sx={{ ...secondaryLabel }}>
          {formatDate(new Date(row.date_created), "hh:mm am/pm")}
        </Typography>
      </TableCell>
      <TableCell align="right">
        {reduceLargeText(row.description, 20)}
      </TableCell>
      <TableCell align="right">
        <Typography sx={{ ...primaryLabel }}>
          {row?.teamMemberInfo?.name}
        </Typography>
        {/* <Typography sx={{ ...secondaryLabel }}>Sales executive</Typography> */}
      </TableCell>
      <TableCell align="right" style={{ whiteSpace: "nowrap" }}>
        <Typography sx={{ ...primaryLabel }}>
          {formatDate(new Date(row.date), "dd-mm-yyyy")}
        </Typography>
        <Typography sx={{ ...secondaryLabel }}>
          {formatDate(new Date(row.date), "hh:mm am/pm")}
        </Typography>
      </TableCell>
      <TableCell align="right">
        <Typography sx={{ ...primaryLabel }}>
          <ChatStatusChip status={formatServerValue(row.status)} />
        </Typography>
      </TableCell>
      <TableCell align="right" onClick={(e) => e.stopPropagation()}>
        <IconButton onClick={handleClick}>
          <MoreVert />
        </IconButton>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem
            onClick={() => {
              handleClose();
              openForm(row);
            }}
          >
            Edit
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleClose();
              openDeleteForm(row);
            }}
          >
            Delete
          </MenuItem>
        </Menu>
      </TableCell>
    </TableRow>
  );
};
