import React, { useEffect, useState } from "react";
import { CircleNotifications, Lock, QuizSharp } from "@mui/icons-material";
import { DateTimePicker, LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Card,
  Checkbox,
  Collapse,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  InputAdornment,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import axios from "axios";
import { WooCommerceSvg } from "../../../../Svgs";
import WooCommerceOverview from "./WooCommerceOverview";
import WooCommerceIntegration from "./WooCommerceIntegration";
import {
  Base64,
  getAxiosError,
  getStoreId,
  getToken,
  getWebsite,
  isObjWithValues,
  paramsToJson,
} from "../../../../helper";
import { useLocation, useNavigate } from "react-router-dom";
import { EDIT_SETTINGS } from "../../../../redux/actions/settingActions";
import { useDispatch, useSelector } from "react-redux";
import Settings from "./Settings";
import Mapping from "./components/Mapping";
import Import from "./Import";
import Activity from "./Activity";

function WooCommerce() {
  const [selectedTab, setSelectedTab] = useState("overview");

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const reduxWooCommerceIntegration = useSelector(
    (state) => state.settings.wooCommerceIntegration
  );

  // Get OAuth success params
  useEffect(() => {
    let params_string = location.search;
    const all_params = new URLSearchParams(params_string);
    const success = new URLSearchParams(params_string).get("success");
    let state = new URLSearchParams(params_string).get("user_id");

    // if (!state || !status) return;

    if (state && success) {
      try {
        setSelectedTab("integration");
        console.log("Calling...");
        getUpdatedSetting();
      } catch (err) {
        console.log("Error", err);
      }
    }
  }, []);

  // Exchange AuthCode for AccessToken
  const getUpdatedSetting = async () => {
    let website = getWebsite();
    let token = getToken();

    if (website && token) {
      try {
        const response = await axios({
          method: "GET",
          url: `${website}/wp-json/store/v1/settings/woocommerce_integration`,
          headers: {
            Authorization: `Basic ${token}`,
          },
        });

        if (response.status === 200) {
          const integrationData = response.data?.data;

          const updateSettings = await axios({
            method: "POST",
            url: `${website}/wp-json/store/v1/settings/woocommerce_integration`,
            headers: {
              Authorization: `Basic ${token}`,
            },
            data: {
              setting: "woocommerce_integration",
              data: {
                ...integrationData,
                website: integrationData?.authorised_store,
                integrated: true,
              },
            },
          });

          if (updateSettings.status === 200) {
            batchCreateWebhooks();
            dispatch({
              type: EDIT_SETTINGS,
              payload: {
                wooCommerceIntegration: {
                  ...integrationData,
                  website: integrationData?.authorised_store,
                  integrated: true,
                },
              },
            });
          }
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  const batchCreateWebhooks = async () => {
    let website = getWebsite();
    let token = getToken();

    if (
      isObjWithValues(reduxWooCommerceIntegration) &&
      reduxWooCommerceIntegration?.integrated
    ) {
      const { authorised_store, consumer_key, consumer_secret } =
        reduxWooCommerceIntegration;
      if (website && token) {
        try {
          const response = await axios({
            method: "POST",
            url: `${authorised_store}/wp-json/wc/v3/webhooks/batch`,
            headers: {
              Authorization: `Basic ${Base64.btoa(
                `${consumer_key}:${consumer_secret}`
              )}`,
            },
            data: {
              create: [
                {
                  name: "Coupon created",
                  topic: "coupon.created",
                  delivery_url: `${website}/wc-api/ecommerce`,
                },
                {
                  name: "Customer deleted",
                  topic: "customer.deleted",
                  delivery_url: `${website}/wc-api/ecommerce`,
                },
              ],
            },
          });

          console.log("wooComWebHooks", response.data);
        } catch (err) {
          console.log("CreateWebhooks", err);
        }
      }
    }
  };

  return (
    <Box sx={{ height: "100%", paddingRight: "15px" }}>
      <Stack
        sx={{
          position: "sticky",
          top: "0",
          backgroundColor: "#fff",
          zIndex: 13,
          margin: 0,
          padding: "12px",
        }}
      >
        <Stack
          direction="row"
          justifyContent={"space-between"}
          alignItems="center"
        >
          <Typography
            variant="h3"
            sx={{ display: "flex", alignItems: "center", gap: "10px" }}
          >
            {" "}
            <WooCommerceSvg height={25} width={25} /> WooCommerce
          </Typography>
        </Stack>
        <Tabs
          onChange={(e, newValue) => setSelectedTab(newValue)}
          value={selectedTab}
          sx={{ borderBottom: 1, borderColor: "divider" }}
        >
          <Tab label="Overview" value="overview" />
          <Tab label="Integration" value="integration" />
          {reduxWooCommerceIntegration?.integrated
            ? [
                <Tab label="Mapping" value="mapping" />,
                <Tab label="Import" value="import" />,
                <Tab label="Activity" value="activity" />,
                <Tab label="Settings" value="settings" />,
              ]
            : null}
        </Tabs>
      </Stack>
      <TabPanel value={selectedTab} index={"overview"}>
        <WooCommerceOverview />
      </TabPanel>
      <TabPanel value={selectedTab} index={"mapping"} sx={{ paddingTop: 0 }}>
        <Mapping />
      </TabPanel>
      <TabPanel value={selectedTab} index={"import"} sx={{ paddingTop: 0 }}>
        <Import />
      </TabPanel>
      <TabPanel value={selectedTab} index={"integration"}>
        <WooCommerceIntegration />
      </TabPanel>
      <TabPanel value={selectedTab} index={"activity"} sx={{ p: 3, pt: 0 }}>
        <Activity />
      </TabPanel>
      <TabPanel value={selectedTab} index={"settings"} sx={{ p: 3, pt: 0 }}>
        <Settings />
      </TabPanel>
    </Box>
  );
}

export default WooCommerce;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }} {...other}>
          {/* <Typography>{children}</Typography> */}
          {children}
        </Box>
      )}
    </div>
  );
}
