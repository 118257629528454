import { LoadingButton } from '@mui/lab'
import { Stack, TextField, Typography } from '@mui/material'
import React from 'react'
import { textFeildRadius } from '../../../helper'
import { IntegrationAuthorize } from '../../../Svgs'

const AuthorizeView = ({ title, buttonTitle, buttonProps = {}, onClickButton, textFieldProps = {} }) => {
    return (
        <>

            <Stack
                alignItems={"center"}
                justifyContent={"center"}
                rowGap={3}
                gap={3}
            >
                <IntegrationAuthorize height={280} width={280} />

                <TextField
                    // value={userStoreUrl}
                    // onChange={(event) => setUserStoreUrl(event.target.value)}
                    // variant="outlined"
                    placeholder="Enter your store url"
                    size="small"
                    sx={{ width: "350px", ...textFeildRadius }}
                    {...textFieldProps}

                // fullWidth
                // sx={{ mt: 2 }}
                // disabled={reduxShopifyIntegration?.integrated}
                // InputProps={
                //     reduxShopifyIntegration?.integrated
                //         ? {
                //             endAdornment: (
                //                 <InputAdornment position="start">
                //                     <Lock />
                //                 </InputAdornment>
                //             ),
                //         }
                //         : null
                // }
                />
                <Typography
                    variant="h6"
                    color={"text.secondary"}
                    sx={{ width: "40%", textAlign: "center", lineHeight: 1.6 }}
                >{title}
                    {/* Please enter your Woocommerce store Url to integrate with Jwero */}
                </Typography>
                {/* {!reduxShopifyIntegration?.integrated ? ( */}
                <LoadingButton
                    size="large"
                    // loading={loading}
                    // disabled={virtualTryIntegrated}
                    // disabled={istryb4uIntegrated}
                    variant="contained"
                    onClick={() => {
                        onClickButton && onClickButton()
                    }}

                    {...buttonProps}
                // onClick={onPressAuthorise}
                >
                    {buttonTitle}
                </LoadingButton>
                {/* ) : null} */}

            </Stack>
        </>
    )
}

export default AuthorizeView