import { Box, Link, Typography } from '@mui/material'
import React from 'react'
import { ListItem } from '../meta/MetaOverview'

const feature = [
    '👉Fast Account Activation + Easy Integration',
    '👉Allows International Transaction',
    '👉High Security for Fraud Detection',
    '👉Allows recurring Payments',
    '👉Allows multiple currency Procession',
    '👉Invoice Generation',

]
const Overview = () => {
    return (
        <Box sx={{ p: 3, mb: 3 }}>
            <Typography variant="subtitle1" sx={{ fontSize: "16px", mb: 4 }}>
                CCAvenue is one of the most popular payment gateway providers in south Asia including India💎
            </Typography>
            <Typography variant="subtitle1" sx={{ fontSize: "16px", mb: 4 }}>
                It is extremely Easy to use, Fast and Secure 🍃

            </Typography>
            <Typography variant="subtitle1" sx={{ fontSize: "16px", mb: 4 }}>
                With more than 200 payment options including credit cards, net banking, debit cards and so on.
                Being one of the very well-known payment gateway options for retailers their transaction page is fully responsive and provides quick and simple checkout 🛒


            </Typography>
            <Typography variant="subtitle2" sx={{ fontSize: "16px", mb: 4 }}>Features of  CC Avenue</Typography>
            <ListItem arr={feature} />
            <Typography sx={{ fontSize: "16px", mt: 3 }}>
                📨 Send your queries <Link href="https://dashboard.ccavenue.com/web/contactUs.do?command=navigateContactUs" style={{ fontSize: "16px" }} >Contact  CC Avenue</Link>
            </Typography>
            <Typography sx={{ fontSize: "16px", mt: 3 }}>
                📞   Number <Link href="tel:+912267425555" style={{ fontSize: "16px" }} >Contact  CC Avenue</Link>
            </Typography>


        </Box>
    )
}

export default Overview