/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
// import { styled } from "@mui/material/styles";
// import Tabs from "@mui/material/Tabs";
// import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
// import MasterSettings from "./MasterSettings";

// import Grouping from "./grouping";
// import Roles from "./roles";
// import SizeMasters from "./SizeMasters";
// import ProductDefault from "./ProductDefault";
import { useSelector } from "react-redux";
import { Button, CircularProgress, Stack } from "@mui/material";
import { SettingsIllustration } from "../../Svgs";

// import Pricing from "../pricing";
import { useLocation } from "react-router-dom";
// import ProductSettingIndex from "./product/Index";
// import Shipping from "./Shipping";
// import Coupons from "./Coupons";
// import Tax from "./Tax";
// import Email from "./Email";
// import Seo from "./Seo";
// import Instore from "./Instore";
// import Profile from "./Profile";
import { isArrayWithValues, isDev, isOurWebsite } from "../../helper";
import asyncComponent from "../../components/Async";
import { settingsTabsByProduct } from "../../App";
import ProductUpdateHistory from "./history/ProductUpdateHistory";

const Loader = () => {
  return (
    <Stack
      sx={{ width: "-webkit-fill-available", height: "70vh" }}
      justifyContent="center"
      alignItems={"center"}
    >
      <CircularProgress color="primary" disableShrink />
    </Stack>
  );
};

const Pricing = asyncComponent(() => import("../pricing"), 100, <Loader />);
const MasterSettings = asyncComponent(
  () => import("./MasterSettings"),
  100,
  <Loader />
);
const ProductSettingIndex = asyncComponent(
  () => import("./product/Index"),
  100,
  <Loader />
);
const Shipping = asyncComponent(() => import("./Shipping"), 100, <Loader />);
const Coupons = asyncComponent(() => import("./Coupons"), 100, <Loader />);
const Tax = asyncComponent(() => import("./Tax"), 100, <Loader />);
const Email = asyncComponent(() => import("./Email"), 100, <Loader />);
const Seo = asyncComponent(() => import("./Seo"), 100, <Loader />);
const Instore = asyncComponent(() => import("./Instore"), 100, <Loader />);
const Profile = asyncComponent(() => import("./Profile"), 100, <Loader />);
const Roles = asyncComponent(() => import("./roles"), 100, <Loader />);

// const StyledTabs = styled((props) => (
//   <Tabs
//     {...props}
//     TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
//   />
// ))(({ theme }) => ({
//   marginBottom: "15px",
//   "& .MuiTabs-indicator": {
//     display: "flex",
//     justifyContent: "center",
//     backgroundColor: "transparent",
//   },
//   "& .MuiTabs-indicatorSpan": {
//     maxWidth: 40,
//     width: "100%",
//     backgroundColor: theme.palette.primary.main,
//     // backgroundColor: "#635ee7",
//   },
// }));

// const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
//   ({ theme }) => ({
//     textTransform: "none",
//     padding: "5px 8px",
//     minWidth: "50px",
//     fontWeight: theme.typography.fontWeightRegular,
//     fontSize: theme.typography.pxToRem(15),
//     marginRight: theme.spacing(1),
//     color: theme.palette.text.secondary,
//     "&.Mui-selected": {
//       color: theme.palette.text.primary,
//     },
//     "&.Mui-focusVisible": {
//       backgroundColor: "rgba(100, 95, 228, 0.32)",
//     },
//   })
// );

const AllStaticTabs = [
  { label: "Master" },
  { label: "Pricing" },
  { label: "Product" },
  // { label: "Grouping" },
  { label: "Team Members" },
  { label: "Shipping" },
  { label: "Coupons" },
  { label: "Tax" },
  { label: "Email" },
  { label: "SEO" },
  // { label: "Catalog / Sales App" },
  { label: "Profile" },
];
const customOrder = [
  "Master",
  "Pricing",
  "Product",
  "Team Members",
  "Shipping", // I noticed this label in your desired output
  "Coupons",
  "Tax",
  "Email",
  "SEO",
  "Profile",
];
const retailerOnlyTabs = ["Shipping", "Coupons", "Tax", "SEO"];
export default function CustomizedTabs() {
  // const [value, setValue] = React.useState(0);
  const [selectedTab, setSelectedTab] = React.useState("");
  const [AllTabs, setAllTabs] = React.useState([...AllStaticTabs]);

  const location = useLocation();

  const user_products = useSelector(
    (state) => state.user?.store_plan?.user_products
  );

  const settingsAccess = useSelector((state) => state.user.settingsAccess);

  const productSettings = useSelector(
    (state) => state.settings.productSettings
  );

  const isAdmin = useSelector((state) => state.user.isAdmin);
  // const [tabs, setTabs] = React.useState([]);
  const user_type = useSelector((state) => state.user.user_type);

  React.useEffect(() => {
    let array = [];
    if (isArrayWithValues(user_products)) {
      for (let product of user_products) {
        if (settingsTabsByProduct[product])
          array = array.concat(settingsTabsByProduct[product]);
      }
      console.log(array);
      // if (user_products.includes("catalog"))
      //   array = array.concat([
      //     { label: "Master" },
      //     { label: "Pricing" },
      //     { label: "Product" },
      //     { label: "Profile" },
      //   ]);
      // if (user_products.includes("chats"))
      // array = array.concat([{ label: "Profile" }]);
      array = array.filter(
        (v, i, a) => a.findIndex((v2) => v2.label === v.label) === i
      );
    } else array = AllStaticTabs;

    if (!isAdmin && !isDev())
      array = array.filter((i) => i.label !== "Team Members");
    else if (user_type === "supplier")
      array = array.filter((i) => !retailerOnlyTabs.includes(i.label));

    if (productSettings?.hide_pricing)
      array = array.filter((i) => i?.label !== "Pricing");
    const sortedArray = array.sort((a, b) => {
      const indexA = customOrder.indexOf(a.label);
      const indexB = customOrder.indexOf(b.label);
      return indexA - indexB;
    });
    setAllTabs(sortedArray);
    if (!selectedTab) setSelectedTab(array?.[0]?.label);
  }, [user_products, isAdmin, productSettings]);

  React.useEffect(() => {
    let { hash } = location;
    if (hash && hash.includes("product_grouping")) setSelectedTab("Product");
    if (hash && hash.includes("product_shortcuts")) setSelectedTab(5);
    if (hash && hash.includes("email")) setSelectedTab("Email");
  }, []);

  // React.useEffect(() => {
  //   if (!isAdmin && !isDev())
  //     setTabs(AllTabs.filter((i) => i.label !== "Team Members"));
  //   else if (user_type == "supplier")
  //     setTabs(AllTabs.filter((i) => !retailerOnlyTabs.includes(i.label)));
  //   else setTabs(AllTabs);
  // }, [isAdmin]);

  if (!settingsAccess && !isAdmin)
    return (
      <Box>
        <Stack direction={"row"} mt="20vh" justifyContent={"center"}>
          <SettingsIllustration />
        </Stack>
        <Stack direction={"row"} justifyContent={"center"}>
          {/* <SettingsIllustration /> */}
          <Typography variant="h6" mt={2}>
            You don't have access to change or view settings!
          </Typography>
        </Stack>
      </Box>
    );

  return (
    <Box sx={{ width: "100%" }}>
      <Stack
        direction="row"
        sx={{
          // backgroundColor: "Background",
          padding: "10px",
          borderBottomColor: "#e8e8e8",
          borderBottomWidth: 0.2,
          borderBottomStyle: "solid",
          overflow: "auto",
          whiteSpace: "nowrap",
        }}
        spacing={4}
      >
        {AllTabs?.length > 1 &&
          AllTabs?.map((i) => {
            return (
              <Button
                key={i?.label}
                variant={"text"}
                sx={{
                  backgroundColor:
                    selectedTab === i.label ? "#e6e6e6" : "transparent",
                  color: "#000",

                  "&:hover": {
                    backgroundColor:
                      selectedTab === i.label ? "#e6e6e6" : "transparent",
                  },
                }}
                style={{ minWidth: "auto" }}
                onClick={() => setSelectedTab(i.label)}
              >
                {i.label}
              </Button>
            );
          })}
      </Stack>

      <TabPanel value={selectedTab} index={"Master"}>
        <MasterSettings />
      </TabPanel>
      <TabPanel value={selectedTab} index={"Pricing"}>
        <Pricing />
      </TabPanel>
      <TabPanel value={selectedTab} index={"Product"}>
        <ProductSettingIndex />
      </TabPanel>
      {/* <TabPanel value={value} index={3}>
        <Grouping />
      </TabPanel> */}
      {/* <TabPanel value={value} index={4}>
        <SizeMasters />
      </TabPanel> */}
      {isAdmin || isDev() ? (
        <TabPanel value={selectedTab} index={"Team Members"}>
          <Roles />
        </TabPanel>
      ) : null}
      {/* <TabPanel value={value} index={4}>
        <ProductDefault />
      </TabPanel> */}
      <TabPanel value={selectedTab} index={"Shipping"}>
        <Shipping />
      </TabPanel>
      <TabPanel value={selectedTab} index={"Coupons"}>
        <Coupons />
      </TabPanel>
      <TabPanel value={selectedTab} index={"Tax"}>
        <Tax />
      </TabPanel>
      <TabPanel value={selectedTab} index={"Email"}>
        <Email />
      </TabPanel>
      <TabPanel value={selectedTab} index={"SEO"}>
        <Seo />
      </TabPanel>
      <TabPanel value={selectedTab} index={"Catalog / Sales App"}>
        <Instore />
      </TabPanel>
      <TabPanel value={selectedTab} index={"Profile"}>
        <Profile />
      </TabPanel>
    </Box>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
