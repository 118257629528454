import React, { useEffect, useState } from "react";
import {
  IntegrationAuthorize,
  ShopifySvg,
  WooCommerceSvg,
} from "../../../../Svgs";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
  Box,
  TextField,
  Icon,
  InputAdornment,
} from "@mui/material";
import {
  getAxiosError,
  getStoreId,
  getToken,
  getWebsite,
  isObjWithValues,
  getGlobalRedirectURL,
  isDev,
  shopifyProductKeys,
  apiCallFromSocket,
  removeTrailingSlash,
} from "../../../../helper";
import { useDispatch, useSelector } from "react-redux";
import { EDIT_SETTINGS } from "../../../../redux/actions/settingActions";
import axios from "axios";
import { Lock } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import * as qs from "qs";
import AuthorizeView from "../../helper/AuthorizeView";
import { setGlobalToast } from "../../../../redux/actions/helperActions";

// import querystring from "querystring";

function ShopifyIntegration() {
  const dispatch = useDispatch();

  const [userStoreUrl, setUserStoreUrl] = useState("");

  const reduxShopifyIntegration = useSelector(
    (state) => state.settings.shopifyIntegration
  );
  const fetchingAllSettings = useSelector(
    (state) => state.settings.fetchingAllSettings
  );
  const reduxIntegrationAttributes = useSelector(
    (state) => state.settings.integrationAttributes
  );
  const integratingShopify = useSelector(
    (state) => state.settings.integratingShopify
  );
  const [mappedAttributes, setMappedAttributes] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getShopifyKeys();
  }, []);

  useEffect(() => {
    getShopifyKeys();
    if (reduxShopifyIntegration?.integrated) {
      reduxShopifyIntegration.shop.includes("https://")
        ? setUserStoreUrl(reduxShopifyIntegration.shop)
        : setUserStoreUrl(`https://${reduxShopifyIntegration.shop}`);
    } else {
      setUserStoreUrl("");
    }
  }, [reduxShopifyIntegration]);

  const getShopifyKeys = async () => {
    if (
      !reduxIntegrationAttributes?.hasOwnProperty("shopify") &&
      reduxShopifyIntegration?.hasOwnProperty("shop")
    ) {
      const { shop, access_token } = reduxShopifyIntegration;
      try {
        const response = await apiCallFromSocket({
          method: "GET",
          url: `https://${shop}/admin/api/2023-01/products.json?limit=1`,
          headers: {
            "X-Shopify-Access-Token": access_token,
          },
        });

        const data = response.data?.data?.products;
        if (response.status == 200) {
          const product = data[0];
          const attributes = Object.keys(product)
            .filter((filterItem) => shopifyProductKeys.includes(filterItem))
            .map((item, index) => ({
              [item]: typeof product[item],
            }));

          dispatch({
            type: EDIT_SETTINGS,
            payload: {
              integrationAttributes: {
                ...reduxIntegrationAttributes,
                shopify: attributes,
              },
            },
          });
        }
      } catch (err) {
        console.debug(err);
      }
    }
    autoMapWithKeys();
  };

  const autoMapWithKeys = async () => {
    try {
      if (
        reduxIntegrationAttributes?.hasOwnProperty("mapped") &&
        reduxIntegrationAttributes?.mapped?.hasOwnProperty("shopify")
      ) {
        setMappedAttributes(reduxIntegrationAttributes.mapped.shopify);
      } else if (
        reduxIntegrationAttributes?.hasOwnProperty("shopify") &&
        reduxIntegrationAttributes?.hasOwnProperty("jwero")
      ) {
        let preMappedAttributes = {};

        const jweroKeys = Object.keys(
          reduxIntegrationAttributes.jwero.reduce(
            (r, c) => Object.assign(r, c),
            {}
          )
        );
        const shopifyKeys = Object.keys(
          reduxIntegrationAttributes.shopify.reduce(
            (r, c) => Object.assign(r, c),
            {}
          )
        );

        jweroKeys.forEach((item, index) => {
          if (shopifyKeys.includes(item)) {
            preMappedAttributes = {
              ...preMappedAttributes,
              item,
            };
          }
        });

        setMappedAttributes(preMappedAttributes);
      }
    } catch (er) {}
  };

  const onPressAuthorise = () => {
    // onAuthorizeShopify(userStoreUrl);
    let storeId = getStoreId();
    const store_url = userStoreUrl;
    const endpoint = "/admin/oauth/authorize";
    const params = {
      client_id: "10596520b77ff26498d53de6b3781e58",
      scope: "read_products,write_products,read_orders,read_customers",
      redirect_uri: isDev()
        ? "http://localhost:3000/integrations"
        : "https://app.jwero.ai/integrations",
      state: `shopify_${storeId}`,
      "grant_options[]": "per_user",
    };

    const queryParams = qs
      .stringify(params, { encode: false })
      .replace(/%20/g, "+");
    // const queryParams = querystring.stringify(params).replace(/%20/g, "+");
    window.open(
      `${
        store_url?.includes("https://") ? "" : "https://"
      }${store_url}${endpoint}?${queryParams}`,
      "_self"
    );
  };

  const onPressRevoke = async () => {
    let website = getWebsite();
    let token = getToken();

    if (website && token) {
      dispatch({
        type: EDIT_SETTINGS,
        payload: {
          integratingShopify: true,
        },
      });
      const { shop, access_token } = reduxShopifyIntegration;
      try {
        const response = await apiCallFromSocket({
          method: "DELETE",
          url: `https://${shop}/admin/api_permissions/current.json`,
          headers: {
            "X-Shopify-Access-Token": access_token,
          },
        });
        console.log(response);
      } catch (error) {}
      try {
        const response = await axios({
          url: `${website}/wp-json/store/v1/settings/shopify_integration`,
          method: "POST",
          headers: {
            Authorization: `Basic ${token}`,
          },
          data: {
            setting: "shopify_integration",
            data: {
              integrated: false,
            },
          },
        });
        if (response?.data?.type === "success") {
          dispatch({
            type: EDIT_SETTINGS,
            payload: {
              shopifyIntegration: response?.data?.data,
            },
          });
        }
        dispatch(
          setGlobalToast({
            show: true,
            message: "Shopify revoked successfully!",
            severity: "success",
          })
        );
      } catch (err) {
        console.log("Shopify revoke", getAxiosError(err));
      }
      dispatch({
        type: EDIT_SETTINGS,
        payload: {
          integratingShopify: false,
        },
      });
    }
  };

  const onPressApplyMapping = async () => {
    let website = getWebsite();
    let token = getToken();

    const updatedIntegrationAttributes = {
      ...reduxIntegrationAttributes,
      mapped: {
        ...reduxIntegrationAttributes?.mapped,
        shopify: mappedAttributes,
      },
    };

    if (website && token) {
      try {
        const response = await axios({
          url: `${website}/wp-json/store/v1/settings/integration_attributes`,
          method: "POST",
          headers: {
            Authorization: `Basic ${token}`,
          },
          data: {
            setting: "integration_attributes",
            data: updatedIntegrationAttributes,
          },
        });
        if (response.status === 200) {
          dispatch({
            type: EDIT_SETTINGS,
            payload: {
              integrationAttributes: response?.data.data,
            },
          });
        }
      } catch (err) {
        console.log("Update shopiyIntegrationAttributes", getAxiosError(err));
      }
    }
  };

  const handleChangeAttribute = (target, value) => {
    setMappedAttributes({
      ...mappedAttributes,
      [target]: value?.target?.value,
    });
  };

  const _renderAttributes = () => {
    return (
      <div>
        <div
          style={{
            flexDirection: "row",
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h5">Shopify</Typography>
          <Typography variant="h5">Jwero</Typography>
        </div>

        {isObjWithValues(reduxIntegrationAttributes) &&
          reduxIntegrationAttributes.hasOwnProperty("shopify") &&
          reduxIntegrationAttributes?.shopify?.map((item, index) =>
            Object.keys(item).map((shopifyKey, attributeIndex) => {
              return (
                <div style={{ flexDirection: "row", display: "flex" }}>
                  <FormControl
                    fullWidth
                    sx={{
                      marginTop: "14px",
                    }}
                  >
                    <InputLabel id={attributeIndex}>{shopifyKey}</InputLabel>
                    <TextField
                      disabled
                      labelId={"shopify-attributes"}
                      id={`shopify-select-${attributeIndex}`}
                      value={shopifyKey}
                      style={{ color: "#000000" }}
                    ></TextField>
                  </FormControl>
                  {/* --------- */}
                  <FormControl
                    fullWidth
                    sx={{
                      marginTop: "14px",
                      marginLeft: "14px",
                    }}
                  >
                    <InputLabel id={attributeIndex}>
                      {mappedAttributes[shopifyKey]}
                    </InputLabel>
                    <Select
                      labelId={"jwero-attributes"}
                      id={attributeIndex}
                      value={mappedAttributes[shopifyKey]}
                      label={mappedAttributes[shopifyKey]}
                      onChange={(value) =>
                        handleChangeAttribute(shopifyKey, value)
                      }
                      sx={{ fontStyle: { color: "#000000" } }}
                    >
                      {reduxIntegrationAttributes?.jwero &&
                        Object.keys(
                          reduxIntegrationAttributes?.jwero?.reduce(
                            (r, c) => Object.assign(r, c),
                            {}
                          )
                        )
                          .filter(
                            (item) =>
                              !Object.values(mappedAttributes).includes(item)
                          )
                          .map((otherAttributeKey, otherAttributeIndex) => (
                            <MenuItem value={otherAttributeKey}>
                              {otherAttributeKey}
                            </MenuItem>
                          ))}
                    </Select>
                  </FormControl>
                </div>
              );
            })
          )}
      </div>
    );
  };

  const _renderAuthorizedView = () => {
    return (
      <div style={{ margin: "14px" }}>
        <div
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            display: "flex",
            marginBottom: "30px",
          }}
        >
          <Typography variant="h4">Map Product Attributes</Typography>
          <div
            style={{
              flexDirection: "row",
              justifyContent: "flex-end",
              display: "flex",
            }}
          >
            <Button
              size="large"
              onClick={onPressRevoke}
              sx={{
                borderRadius: "30px",
                backgroundColor: "#FF6263",
                color: "#FFFFFF",
              }}
              variant="contained"
            >
              Revoke
            </Button>
            {reduxIntegrationAttributes?.hasOwnProperty("jwero") &&
              reduxIntegrationAttributes?.hasOwnProperty("shopify") && (
                <Button
                  size="large"
                  onClick={onPressApplyMapping}
                  sx={{
                    marginLeft: "24px",
                    borderRadius: "30px",
                    backgroundColor: "#383CC1",
                    color: "#FFFFFF",
                  }}
                  variant="contained"
                >
                  Apply Mapping
                </Button>
              )}
          </div>
        </div>
        <div>{_renderAttributes()}</div>
      </div>
    );
  };

  const _renderNotAuthorizedView = () => {
    return (
      <div style={{ flexDirection: "row", display: "flex", margin: "14px" }}>
        <TextField
          id="outlined-basic"
          label="Store URL"
          variant="outlined"
          placeholder="https://example.com"
          value={userStoreUrl}
          onChange={(event) => setUserStoreUrl(event.target.value)}
        />
        <Button
          onClick={onPressAuthorise}
          sx={{
            padding: "4px 12px",
            borderRadius: "8px",
            boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
            borderColor: "#022687",
            textDecoration: { color: "#FFFFFF" },
            marginLeft: "8px",
          }}
        >
          Authorise
        </Button>
      </div>
    );
  };

  const onVisitStore = () => window.open(userStoreUrl, "_blank");

  return (
    <>
      <AuthorizeView
        buttonTitle={
          !reduxShopifyIntegration?.integrated ? "Integrate" : "Revoke"
        }
        onClickButton={
          !reduxShopifyIntegration?.integrated
            ? onPressAuthorise
            : onPressRevoke
        }
        title={
          !reduxShopifyIntegration?.integrated
            ? "Please enter your Shopify store Url to integrate with Jwero"
            : ""
        }
        buttonProps={{
          ...(reduxShopifyIntegration?.integrated && { color: "error" }),
          loading: integratingShopify,
        }}
        textFieldProps={{
          value: userStoreUrl,
          onChange: (event) => setUserStoreUrl(event.target.value),
          disabled: reduxShopifyIntegration?.integrated,
          InputProps: reduxShopifyIntegration?.integrated
            ? {
                endAdornment: (
                  <InputAdornment position="start">
                    <Button onClick={onVisitStore}>Visit Store</Button>
                    {/* <Lock /> */}
                  </InputAdornment>
                ),
              }
            : null,
        }}
      />
    </>
  );

  return (
    <Box sx={{ padding: "12px 18px" }}>
      <Stack alignItems={"center"} justifyContent={"center"} rowGap={3} gap={3}>
        <IntegrationAuthorize height={200} width={200} />
        <TextField
          value={userStoreUrl}
          onChange={(event) => setUserStoreUrl(event.target.value)}
          // variant="outlined"
          placeholder="Enter your store url"
          size="small"
          sx={{ width: "350px" }}
          // fullWidth
          // sx={{ mt: 2 }}
          disabled={reduxShopifyIntegration?.integrated}
          InputProps={
            reduxShopifyIntegration?.integrated
              ? {
                  endAdornment: (
                    <InputAdornment position="start">
                      <Lock />
                    </InputAdornment>
                  ),
                }
              : null
          }
        />
        <Typography
          variant="h6"
          color={"text.secondary"}
          sx={{ width: "40%", textAlign: "center", lineHeight: 1.6 }}
        >
          Please enter your Woocommerce store Url to integrate with Jwero
        </Typography>
        {!reduxShopifyIntegration?.integrated ? (
          <LoadingButton
            size="large"
            loading={loading}
            // disabled={virtualTryIntegrated}
            // disabled={istryb4uIntegrated}
            variant="contained"
            onClick={onPressAuthorise}
          >
            Integrate
          </LoadingButton>
        ) : null}
        {reduxShopifyIntegration?.integrated ? (
          <LoadingButton
            size="large"
            color="error"
            loading={loading}
            variant="contained"
            onClick={onPressRevoke}
          >
            Revoke
          </LoadingButton>
        ) : null}
      </Stack>
      <Stack
        // direction="row"

        justifyContent={"center"}
        alignItems="center"
      >
        {/* <Typography
          variant="h5"
          sx={{ display: "flex", alignItems: "center", gap: "10px" }}
        >
          <ShopifySvg height={22} width={22} /> Shopify
        </Typography> */}
      </Stack>
      <Stack direction={"row"} alignItems="center" sx={{ gap: "20px", mt: 2 }}>
        <TextField
          value={userStoreUrl}
          onChange={(event) => setUserStoreUrl(event.target.value)}
          // variant="outlined"
          placeholder="Enter your store url"
          size="small"
          sx={{ width: "350px" }}
          // fullWidth
          // sx={{ mt: 2 }}
          disabled={reduxShopifyIntegration?.integrated}
          InputProps={
            reduxShopifyIntegration?.integrated
              ? {
                  endAdornment: (
                    <InputAdornment position="start">
                      <Lock />
                    </InputAdornment>
                  ),
                }
              : null
          }
        />

        {!reduxShopifyIntegration?.integrated ? (
          <LoadingButton
            size="small"
            loading={loading}
            // disabled={virtualTryIntegrated}
            // disabled={istryb4uIntegrated}
            variant="contained"
            onClick={onPressAuthorise}
          >
            Integrate
          </LoadingButton>
        ) : null}
        {reduxShopifyIntegration?.integrated ? (
          <LoadingButton
            size="small"
            color="error"
            loading={loading}
            variant="contained"
            onClick={onPressRevoke}
          >
            Revoke
          </LoadingButton>
        ) : null}
      </Stack>
      {false ? <Typography sx={{ mt: 2 }}>Integrated on</Typography> : null}
    </Box>
  );
  // return reduxShopifyIntegration?.integrated
  //   ? _renderAuthorizedView()
  //   : _renderNotAuthorizedView();
}

export default ShopifyIntegration;

export const onAuthorizeShopify = (store_url) => {
  try {
    store_url = removeTrailingSlash(store_url);
    let storeId = getStoreId();
    // const store_url = userStoreUrl;
    const endpoint = "/admin/oauth/authorize";
    const params = {
      client_id: "10596520b77ff26498d53de6b3781e58",
      scope: "read_products,write_products,read_orders,read_customers",
      redirect_uri: isDev()
        ? "http://localhost:3000/integrations"
        : "https://app.jwero.ai/integrations",
      state: `shopify_${storeId}`,
      "grant_options[]": "per_user",
    };

    const queryParams = qs
      .stringify(params, { encode: false })
      .replace(/%20/g, "+");
    // const queryParams = querystring.stringify(params).replace(/%20/g, "+");
    window.open(
      `${
        store_url?.includes("https://") ? "" : "https://"
      }${store_url}${endpoint}?${queryParams}`,
      "_self"
    );
  } catch (err) {}
};
