import { Box, Typography, Button } from "@mui/material";
import FilePreview from "./FilePreview";

const BotWhatsappTemplate = ({ message }) => {
  const template = message?.currentSavedState;
  console.log(template);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "white",
        borderRadius: "20px",
        paddingBottom: "20px",
        width: "75%",
      }}
    >
      {template &&
        template?.components?.length > 0 &&
        template?.components?.map((component, index) => (
          //   <h6 key={index}>{component.type}</h6>
          <>
            {component?.type === "HEADER" &&
              (component?.parameters?.[0].type === "text" ? (
                <Box
                  sx={{
                    padding: "10px",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "18px",
                      fontWeight: 600,
                      fontFamily: "Inter",
                    }}
                  >
                    {component?.text}
                  </Typography>
                </Box>
              ) : (
                <FilePreview component={component} />
              ))}

            {component?.type === "BODY" && (
              <Box sx={{ padding: "10px", borderBottom: "1px solid black" }}>
                <Box>
                  <Typography variant="span" style={{ whiteSpace: "pre-wrap" }}>
                    {component.text}
                  </Typography>
                </Box>
              </Box>
            )}

            {component?.type === "FOOTER" && (
              <span
                style={{
                  paddingTop: "5px",
                  paddingBottom: "5px",
                  fontFamily: "Inter",
                  fontSize: "14",
                  fontWeight: "600",
                  marginLeft: "8px",
                }}
              >
                {component.text}
              </span>
            )}

            {component?.type === "BUTTONS" &&
              component?.buttons.map((button, buttonIndex) => (
                <Button
                  key={buttonIndex}
                  variant="standard"
                  sx={{
                    boxShadow: 0,
                    margin: 0,
                    padding: 0,
                    minHeight: 0,
                    minWidth: 0,
                    height: "40px",
                    color: "#008aff",
                    borderRadius: "0px",
                    border: "white 0px solid",
                    backgroundColor: "white",
                    textTransform: "none",
                    fontSize: "15px",
                    fontWeight: "800px",
                  }}
                >
                  {button.text}
                </Button>
              ))}
          </>
        ))}
    </Box>
  );
};

export default BotWhatsappTemplate;
