import { useState } from "react";
import { Popover, Box, Typography } from "@mui/material";

const ProductCatalogPopover = ({
  prodCtgAnchorEl,
  setProdCtgAnchorEl,
  products,
  handleProdCtgBtn,
}) => {
  const open = Boolean(prodCtgAnchorEl);
  const id = open ? "simple-popover" : undefined;
  const popoverTop = prodCtgAnchorEl?.getBoundingClientRect().top || 0;
  const popoverLeft = prodCtgAnchorEl?.getBoundingClientRect().left || 0;

  const handleClose = () => {
    setProdCtgAnchorEl(null);
  };
  return (
    <Popover
      id={id}
      open={open}
      anchorReference="anchorPosition"
      anchorPosition={{ top: popoverTop, left: popoverLeft }}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "center",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "center",
        horizontal: "right",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          fontFamily: "Inter",
          // width: "150px",
          padding: "7px 0px 7px 0px",
        }}
      >
        {/* <h1>HELLO</h1> */}
        {products &&
          products.length > 0 &&
          products.map((product) => (
            <Box
              key={product.id}
              onClick={() => handleProdCtgBtn(product.title)}
              sx={{
                padding: "5px 50px 5px 15px",
                cursor: "pointer",
                transition: "0.3s ease",
                "&:hover": {
                  backgroundColor: "#ebebeb",
                },
              }}
            >
              <Typography
                variant="span"
                sx={{
                  fontFamily: "Inter",
                  fontWeight: 600,
                  fontSize: "16px",
                  padding: "5px",
                  "&:hover": {
                    backgroundColor: "#fafbfc",
                  },
                }}
              >
                {product?.title}
              </Typography>
            </Box>
          ))}
      </Box>
    </Popover>
  );
};

export default ProductCatalogPopover;
