import {
  backendApiCall,
  getId,
  getStoreUserId,
  getToken,
  getWebsite,
  isObjWithValues,
} from "../../helper";
import * as qs from "qs";

let backendEndpoints = {
  chats: "chat/users",
  batch: "chat/users/batch",
  chat_conversations: "conversations",
  message: `social/message`,
  template: `social/templates`,
  get_folder: `media/get-folders`,
  media: `media`,
  settings: "settings",
  signup: `users`,
  login: `api/auth/login`,
  sub_user: `sub_user`,
  users: "users",
  campaign: "campaign",
  social: "social",
  activities: "activities",
  broadcastAnalytics: "broadcast/analytics",
  broadcastContactsUpdate: "broadcast/contacts_update",
  tagsContactsUpdate: "tags/update_tags_customers",
  createNotificationToken: "notification/save_token",
  dashboard: `dashboard/chats`,
  actions: "action",
  read_conversation: "read_conversation",
  digigold_pdf_table: "digital_gold/pdf_table",
  test_bot: "flow/test-chatbot",
  flowSettings: "settings/flow_settings",
};

const setupMetadata = (object, keys, removeKeys = []) => {
  let resultObj = {};
  delete object.meta_data;
  let meta_data = {};
  for (let key of Object.keys(object || {})) {
    if (removeKeys?.includes(key)) {
      delete object[key];
      continue;
    }
    if (keys?.includes(key)) resultObj[key] = object[key];
    else {
      meta_data[key] = object[key];
      delete object[key];
    }
  }
  if (isObjWithValues(meta_data)) resultObj.meta_data = meta_data;
  return resultObj;
  // {...resultObj , meta_data}
};

const createChatUser = async ({ payload }) => {
  let keys = [
    "first_name",
    "last_name",
    "whatsapp",
    "email",
    "platform",
    "platform_id",
    "profile_pic",
    "dialcode_phone",
    "dialcode_whatsapp",
    "contact_type",
    "company_name",
  ];
  let removeKeys = ["id", "created_at"];
  payload = {
    ...payload,
    platform_id:
      payload?.platform_id ||
      `${payload?.dialcode_whatsapp || ""}${payload?.whatsapp || ""}`,
  };
  payload = setupMetadata(payload, keys, removeKeys);

  try {
    let requestObj = {
      endpoint: `${backendEndpoints?.["chats"]}`,
      method: "POST",
      data: payload,
    };
    return await backendApiCall(requestObj);
  } catch (error) {
    console.log(getAxiosError(error), "update chat user Error");
  }
};

const sendCampaign = async ({
  type = "Bulk",
  channel = "whatsapp",
  template = {},

  params = {
    //  includes: selectedCustomer?.map((i) => i.id)?.join()
  },
}) => {
  try {
    let data = await backendApiCall({
      endpoint: backendEndpoints.campaign,
      data: {
        type,
        channel,
        template,
        customers: {
          params,
        },
      },
      method: "POST",
    });
    return data;
  } catch (error) {
    console.log(error);
  }
};

const getChatsUsers = async ({ params, body, dispatch }) => {
  try {
    const controller = new AbortController();
    const signal = controller.signal;

    let requestObj = {
      endpoint: backendEndpoints.chats,
      signal,
      params,
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    };
    // if (params) requestObj.params = params;
    if (body) requestObj.data = body;

    let res = await backendApiCall(requestObj);
    return res;
  } catch (error) {
    console.log(getAxiosError(error), "get chats Error");
  } finally {
  }
};
const updateBroadcastContacts = async ({ contact_ids, broadcast_ids }) => {
  try {
    let data = await backendApiCall({
      endpoint: backendEndpoints.broadcastContactsUpdate,
      method: "POST",
      data: { contact_ids, broadcast_ids },
      extractDataFromResponse: true,
    });
    return data;
  } catch (error) {
    console.log(getAxiosError(error), "get chats Error");
  }
};

const bulkActionChatUser = async ({ data, params }) => {
  try {
    let res = await backendApiCall({
      endpoint: backendEndpoints.batch,
      method: "POST",
      data,
      params,
    });
    return res;
  } catch (error) {
    console.log(error);
  }
};

const getChatDashboard = async () => {
  try {
    let res = await backendApiCall({
      endpoint: backendEndpoints.dashboard,
    });
    return res;
  } catch (error) {
    console.log(error);
  }
};
const settingApi = async ({ endpoint, method = "GET", data }) => {
  try {
    let res = await backendApiCall({
      endpoint: `${backendEndpoints.settings}/${endpoint}`,
      method,
      data,
    });
    return res;
  } catch (error) {
    console.log(error);
  }
};

// const updateBroadcast = async ({contact_ids, broadcast_ids })=>{
//   try {
//     let res = await backendApiCall({
//       endpoint : broadcastContactsUpdate,
//       method :"POST" ,
//       data :{

//       }
//     })
//   } catch (error) {
//     console.log(error);
//   }
// }

const sendMessage = async ({ payload, platform }) => {
  try {
    let requestObj = {
      // extractDataFromResponse :false,
      endpoint: backendEndpoints?.["message"],
      method: "POST",
      data: {
        payload,
        platform,
      },
    };

    let res = await backendApiCall(requestObj);

    return res?.data;
  } catch (error) {
    console.log(getAxiosError(error), "send Message");
    return error;
  }
};

const readMessageFunction = async ({ id }) => {
  try {
    let res = await backendApiCall({
      endpoint: `${backendEndpoints.chats}/${backendEndpoints.read_conversation}/${id}`,
      method: "PUT",
    });
    return res;
  } catch (error) {
    console.log(error);
  }
};

const migrateFunction = async () => {
  try {
    let res = await backendApiCall({
      endpoint: `migrate/aug_23/chats`,
      method: "POST",
      headers: {
        Authorization: `Basic ${getToken()}`,
      },
      data: {
        website: getWebsite(),
        token: getToken(),
        user_id: getId(),
      },
    });
    return res;
  } catch (error) {
    console.log(error);
  }
};

export {
  createChatUser,
  sendCampaign,
  backendEndpoints,
  getChatsUsers,
  updateBroadcastContacts,
  bulkActionChatUser,
  getChatDashboard,
  settingApi,
  readMessageFunction,
  sendMessage,
  migrateFunction,
};
