import {
  ArrowBackIosNew,
  Download,
  LocationOnOutlined,
} from "@mui/icons-material";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  Skeleton,
  Stack,
  Tab,
  TablePagination,
  Tabs,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CircularProgressWithLabel,
  formatNumber,
  formatServerValue,
  getRelativeTimeDifference,
  getVisitorsInfo,
  isArrayWithValues,
  isObjWithValues,
  mainSocketEvents,
  randomVisitorName,
  secondaryLabel,
  secondaryLabelSmall,
  TabPanel,
  validateNumber,
} from "../../helper";
import { getMoreVisitors, SET_VISITOR_DATA } from "../../redux/actions/visitorActions";
import { LiveVisitor } from "../../Svgs";
import { NoTasks } from "../chat/ChatHelper";
import { VisitorListItem } from "./components/helper";
import Sessions from "./components/Sessions";
import OpportunityLeadScore from "./components/OpportunityLeadScore";
import { useParams } from "react-router-dom";
const tabs = [
  { label: "All", value: "" },
  { label: "Returning", value: "returning" },
  { label: "Today", value: "today" },
  { label: "Today's new", value: "today_new" },
];

const customerViewTabs = [
  // { label: "Profile", value: "" },
  // { label: "Notes", value: "notes" },
  // { label: "Calls", value: "calls" },
  { label: "Sessions", value: "sessions" },
];
const AllVisitors = () => {
  const [selectedCustomer, setSelectedCustomer] = useState([]);
  const theme = useTheme();
  const isIpad = useMediaQuery(theme.breakpoints.down("md"));
  let height = isIpad ? 120 : 165;

  return (
    <Box p={isIpad ? 0 : 3}>
      <Grid container>
        {/* <Grid
                    item
                    xs={isArrayWithValues(selectedCustomer) && isIpad ? 12 : 0}
                    md={isArrayWithValues(selectedCustomer) && isIpad ? 12 : 0}
                    lg={3}
                >
                    <AllVisitorsList
                        isIpad={isIpad}
                        height={height}
                        setSelectedCustomer={setSelectedCustomer}
                        selectedCustomer={selectedCustomer}
                    />
                </Grid>
                <Grid
                    item
                    xs={!isArrayWithValues(selectedCustomer) && isIpad ? 12 : 0}
                    md={!isArrayWithValues(selectedCustomer) && isIpad ? 12 : 0}
                    lg={9}
                >
                    <VisitorCustomerView
                        isIpad={isIpad}
                        setSelectedCustomer={setSelectedCustomer}
                        height={height}
                        selectedCustomer={selectedCustomer}
                    />
                </Grid> */}

        {!isIpad ? (
          <>
            <Grid item md={12} lg={3}>
              <AllVisitorsList
                height={height}
                setSelectedCustomer={setSelectedCustomer}
                selectedCustomer={selectedCustomer}
              />
            </Grid>
            <Grid item md={12} lg={9}>
              <VisitorCustomerView
                isIpad={isIpad}
                setSelectedCustomer={setSelectedCustomer}
                height={height}
                selectedCustomer={selectedCustomer}
              />
            </Grid>
          </>
        ) : (
          <>
            {isArrayWithValues(selectedCustomer) ? (
              <Grid item xs={12} md={12} lg={9}>
                <VisitorCustomerView
                  height={height}
                  selectedCustomer={selectedCustomer}
                  isIpad={isIpad}
                  setSelectedCustomer={setSelectedCustomer}
                />
              </Grid>
            ) : (
              <Grid item xs={12} md={12} lg={9}>
                <AllVisitorsList
                  isIpad={isIpad}
                  height={height}
                  setSelectedCustomer={setSelectedCustomer}
                  selectedCustomer={selectedCustomer}
                />
              </Grid>
            )}
          </>
        )}
      </Grid>
    </Box>
  );
};

export default AllVisitors;

const AllVisitorsList = ({
  height,
  setSelectedCustomer,
  selectedCustomer,
  isIpad,
}) => {
  const [selectedTab, setSelectedTab] = useState("");
  const [onlineVisitors, setOnlineVisitors] = useState({});
  const [page, setPage] = React.useState(2);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const headerRef = useRef();
  const dispatch = useDispatch();
  const allOnlineVisitors = useSelector(
    (state) => state.visitor.allOnlineVisitors
  );
  const fetchingAllOnlineVisitors = useSelector(
    (state) => state.visitor.fetchingAllOnlineVisitors
  );
  const gettingMoreVisitor = useSelector(
    (state) => state.visitor.gettingMoreVisitor
  );
  const noMoreVisitor = useSelector((state) => state.visitor.noMoreVisitor);
  const allOnlineVisitorsCounts = useSelector(
    (state) => state.visitor.allOnlineVisitorsCounts
  );

  const scrollRef = useRef();
  const handleScroll = (e, f) => {
    const scrollTop = scrollRef.current.scrollTop;
    const totalHeight = scrollRef.current.scrollHeight;
    const height = scrollRef.current.offsetHeight;
    // console.log(totalHeight - height - scrollTop);
    if (
      totalHeight - height - scrollTop < 120 &&
      !fetchingAllOnlineVisitors &&
      !gettingMoreVisitor &&
      !noMoreVisitor &&
      isObjWithValues(allOnlineVisitors)
    ) {
      dispatch(getMoreVisitors({ page, filter: selectedTab }));
      setPage((pre) => pre + 1);
      // console.log("hello");
    }
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const params = useParams()
  console.log(params);
  useEffect(async () => {
    if (isObjWithValues(allOnlineVisitors)) {
      if (params?.id) {
        let visitorId = params?.id?.includes("_") ? `random${params?.id}` : `user_${params?.id}`
        console.log(params?.id, allOnlineVisitors, isArrayWithValues(allOnlineVisitors?.[params?.id]));
        if (isArrayWithValues(allOnlineVisitors?.[visitorId])) {

          setSelectedCustomer && setSelectedCustomer(allOnlineVisitors?.[visitorId])

        }
        // if (!isArrayWithValues(allOnlineVisitors?.[visitorId]))
        else {
          let singleVisitor = await getVisitorsInfo(params?.id?.replace("_", "")) || [];
          setSelectedCustomer && setSelectedCustomer(singleVisitor)
          if (isArrayWithValues(singleVisitor)) {

            dispatch({
              type: SET_VISITOR_DATA,
              payload: {
                allOnlineVisitors: { [visitorId]: singleVisitor, ...allOnlineVisitors, }
              }
            })
          }
          // setOnlineVisitors({ [params?.id]: singleVisitor, ...(allOnlineVisitors || {}) });
        }
      }
    }
  }, [params, fetchingAllOnlineVisitors])
  useMemo(() => {
    if (
      !isIpad &&
      isObjWithValues(allOnlineVisitors) &&
      !isArrayWithValues(selectedCustomer) &&
      setSelectedCustomer
    ) {
      setSelectedCustomer(Object.values(allOnlineVisitors || {})?.[0] || []);
    }
    setOnlineVisitors(allOnlineVisitors || {});
  }, [allOnlineVisitors]);
  const onTabChange = (newValue) => {
    setPage(2);
    dispatch(getMoreVisitors({ page: 1, filter: newValue }));
  };
  // useMemo(() => {
  //     setPage(2)
  //     if (!selectedTab || !isObjWithValues(allOnlineVisitors))
  //     // if (selectedTab === "today") {
  //     //     let newObj = {};
  //     //     for (const key of Object.keys(allOnlineVisitors || {})) {
  //     //         if (
  //     //             new Date(allOnlineVisitors?.[key]?.[0]?.time * 1000).getDate() ===
  //     //             new Date(Date.now()).getDate()
  //     //         )
  //     //             newObj = { ...newObj, [key]: allOnlineVisitors?.[key] };
  //     //     }
  //     //     setOnlineVisitors(newObj);
  //     // }
  //     // if (selectedTab === "returning") {
  //     //     let newObj = {};
  //     //     for (const key of Object.keys(allOnlineVisitors || {})) {
  //     //         if (
  //     //             allOnlineVisitors?.[key]?.filter((obj) =>
  //     //                 mainSocketEvents?.includes(obj?.type)
  //     //             )?.length > 1
  //     //         )
  //     //             newObj = { ...newObj, [key]: allOnlineVisitors?.[key] };
  //     //     }
  //     //     console.log(newObj);
  //     //     setOnlineVisitors(newObj);
  //     // }
  //     // if (selectedTab === "") {
  //     //     setOnlineVisitors(allOnlineVisitors);
  //     // }
  // }, [selectedTab]);
  return (
    <Card sx={{ height: `calc(100vh - ${height}px)` }}>
      <CardContent sx={{ padding: 0, ".last-child": { padding: 0 } }}>
        <List
          sx={{ height: `calc(100vh - ${height}px)`, overflow: "auto", pt: 0 }}
          className="scrollbar-hidden"
          onScroll={handleScroll}
          ref={scrollRef}
        >
          {/* <ListItem > */}

          <Stack
            ref={headerRef}
            sx={{
              position: "sticky",
              top: 0,
              backgroundColor: "background.paper",
              zIndex: 2,
            }}
          >
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent="space-between"
              sx={{ px: 3, height: "40px" }}
            >
              <Typography variant="h6">
                All Visitors{" "}
                {!fetchingAllOnlineVisitors &&
                  `(${allOnlineVisitorsCounts?.toLocaleString() || ""})`}
              </Typography>
              {false && (
                <IconButton>
                  <Download />
                </IconButton>
              )}
            </Stack>
            <Tabs
              value={selectedTab}
              onChange={(e, newValue) => {
                onTabChange(newValue);
                setSelectedTab(newValue);
              }}
              variant="scrollable"
              sx={{
                boxShadow: "rgba(17, 17, 26, 0.1) 0px 1px 0px",
                borderBottom: "divider",
              }}
              scrollButtons={false}
            >
              {tabs.map((obj) => (
                <Tab label={obj?.label} value={obj?.value} />
              ))}
            </Tabs>
          </Stack>
          {/* <Stack
                        sx={{
                            position: "sticky",
                            top: "88px",
                            zIndex: 2,
                            backgroundColor: "background.paper",
                        }}
                    >
                        <TablePagination
                            sx={{
                                // maxWidth: "fit-content",
                                ".MuiTablePagination-selectLabel": {
                                    display: "none",
                                },
                                ".MuiTablePagination-toolbar": {
                                    padding: 0,
                                },
                            }}
                            component="div"
                            count={Object.values(onlineVisitors || {})?.length || 0}
                            page={page}
                            onPageChange={handleChangePage}
                            rowsPerPage={rowsPerPage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Stack> */}
          {/* </ListItem> */}
          {fetchingAllOnlineVisitors ? (
            new Array(10).fill("")?.map((str) => (
              <>
                <Stack
                  direction="row"
                  alignItems="center"
                  sx={{ padding: "15px 10px" }}
                >
                  {/* <Skeleton variant="circular" width={50} height={40} /> */}
                  <Box sx={{ ml: 2, width: "100%" }}>
                    <Skeleton variant="text" width={"90%"} height={25} />
                    <Skeleton
                      variant="text"
                      width={"75%"}
                      height={20}
                      sx={{ mt: 0.5 }}
                    />
                  </Box>
                </Stack>
                <Divider />
              </>
            ))
          ) : isObjWithValues(onlineVisitors) ? (
            Object.values(onlineVisitors || {})?.map((arr) => (
              <VisitorListItem
                visitor={arr}
                onClick={() => setSelectedCustomer && setSelectedCustomer(arr)}
                selected={selectedCustomer?.[0]?.user_id === arr?.[0]?.user_id}
              />
            ))
          ) : (
            <NoTasks
              // sx={{ height: "100%" }}
              // sx={{ my: 25 }}
              sx={{ my: 0 }}
              text="No visitors"
              icon={
                // <Button variant="outlined">
                //   Go to visitor page
                // </Button>
                <LiveVisitor
                  style={{
                    height: "70px",
                    width: "70px",
                    fill: "#c4c4c4",
                  }}
                  fillCircle="#c4c4c4"
                />
              }
            />
          )}

          {gettingMoreVisitor && !noMoreVisitor && (
            <Stack
              justifyContent={"center"}
              alignItems="center"
              rowGap={1.5}
              sx={{ my: 1 }}
            >
              <CircularProgress style={{ height: "20px", width: "20px" }} />
              <Typography sx={{ ...secondaryLabelSmall }}>
                Gettting visitors ...
              </Typography>
            </Stack>
          )}
          {noMoreVisitor && (
            <Stack
              justifyContent={"center"}
              alignItems="center"
              rowGap={1.5}
              sx={{ my: 1 }}
            >
              <Typography sx={{ ...secondaryLabelSmall }}>
                No More Visitors
              </Typography>
            </Stack>
          )}
        </List>
      </CardContent>
    </Card>
  );
};

const VisitorCustomerView = ({
  height,
  selectedCustomer,
  isIpad,
  setSelectedCustomer,
}) => {
  const [selectedTab, setSelectedTab] = useState("sessions");
  // console.log(selectedCustomer);
  const [topScore, setTopScore] = useState(0);
  const [score, setScore] = useState(0);
  let customerDeatils = selectedCustomer?.[0];
  let { user_name, user_id, location, time } = customerDeatils || {};
  let { city } = location || {};
  let timeObj = getRelativeTimeDifference(new Date(time * 1000));
  let returning =
    selectedCustomer?.filter((obj) => mainSocketEvents?.includes(obj?.type))
      ?.length > 1;

  const visitorLeadScores = useSelector(
    (state) => state.visitor.visitorLeadScores
  );

  useEffect(() => {
    if (isObjWithValues(visitorLeadScores)) {
      let { customer = {}, lead = {} } = visitorLeadScores;

      // console.log(customer?.[user_id], lead?.[user_id]);
      let userObj = customer?.[user_id] || lead?.[user_id];
      if (userObj) setScore(userObj?.score);
      else setScore(0);
      // let customers = [];
      customer = [...(Object.keys(customer) || [])]?.map((i) => ({
        ...customer?.[i],
        id: i,
        type: "customer",
      }));
      lead = [...(Object.keys(lead) || [])]?.map((i) => ({
        ...lead?.[i],
        id: i,
        type: "lead",
      }));
      let allCustomers = Object.values(customer).concat(Object.values(lead));
      // if (selectedTab === "all") customers = allCustomers;
      // else if (selectedTab === "customer") customers = Object.values(customer);
      // else if (selectedTab === "lead") customers = Object.values(lead);
      // setCustomers(customers.sort((a, b) => b.score - a.score));
      setTopScore(Math.max(...allCustomers?.map((o) => o.score)));
    } else {
      setTopScore(0);
      setScore(0);
    }
  }, [visitorLeadScores, user_id]);

  return (
    <Card
      sx={{
        height: `calc(100vh - ${height}px)`,
        borderLeft: "0.5px solid #e5eef8",
      }}
    >
      <CardContent
        sx={{
          padding: 0,
          ".last-child": { padding: 0 },
          height: `calc(100vh - ${height}px)`,
          overflow: "auto",
        }}
        className="scrollbar-hidden"
      >
        <Stack
          sx={{
            position: "sticky",
            top: 0,
            backgroundColor: "background.paper",
            zIndex: 2,
          }}
        >
          <Stack
            sx={{
              boxShadow: "rgba(17, 17, 26, 0.1) 0px 1px 0px",
              px: 3,
              py: 5,
            }}
            direction={"row"}
            alignItems="center"
            justifyContent={"space-between"}
            gap={3}
          >
            <Stack
              direction={"row"}
              alignItems="center"
              // justifyContent={"space-between"}
              gap={3}
            >
              {isIpad && (
                <IconButton
                  onClick={() => setSelectedCustomer && setSelectedCustomer([])}
                >
                  {" "}
                  <ArrowBackIosNew />
                </IconButton>
              )}
              <Avatar />
              <Stack rowGap={1}>
                <Stack
                  direction={"row"}
                  alignItems="center"
                  // justifyContent={"space-between"}
                  gap={3}
                >
                  <Typography variant="h5">
                    {user_name?.trim() ||
                      randomVisitorName(user_id || "") ||
                      ""}
                  </Typography>
                  {/* {selectedCustomer?.filter((obj) =>
                                        mainSocketEvents?.includes(obj?.type)
                                    )?.length > 1 && ( */}
                  <Chip
                    label={returning ? "Returning" : "New"}
                    // color={"secondary"}
                    size={"small"}
                    variant="filled"
                    sx={{
                      // color: statusFontColor(row.status),
                      color: returning ? "#5570bc" : "#1aae6f",
                      // border: isDarkTheme ? "0.7px solid" : "0px solid",
                      // borderColor: statusFontColor(row.status),
                      // backgroundColor: isDarkTheme
                      //     ? "transparent"
                      backgroundColor: returning ? "#EAECFB" : "#e0f6ed",
                    }}
                  // sx={{
                  //     ".MuiChip-labelSmall": {
                  //         color: "text.primary",
                  //     },
                  //     backgroundColor: "#CEE1FF",
                  // }}
                  />
                  {/* )} */}
                </Stack>
                {city && (
                  <Typography sx={{ display: "flex", alignItems: "center" }}>
                    <LocationOnOutlined
                      fontSize="small"
                      color={"primary"}
                      style={{ opacity: "0.7", width: "16px", height: "16px" }}
                    />
                    {city}
                  </Typography>
                )}
              </Stack>
            </Stack>
            {!isIpad && (
              <Stack
                direction={"row"}
                alignItems="center"
                // justifyContent={"space-between"}
                gap={8}
              >
                <OpportunityLeadScore
                  score={score}
                  topScore={topScore}
                  leadScoreUI="2"
                />
                <Stack rowGap={0.7}>
                  <Typography sx={{ ...secondaryLabel }}> Type</Typography>
                  <Typography variant="h6" fontWeight={400}>
                    {user_name?.trim() ? "Customer" : "Lead"}
                  </Typography>
                </Stack>
                <Stack rowGap={0.7}>
                  <Typography sx={{ ...secondaryLabel }}> Visits</Typography>
                  <Typography variant="h6" fontWeight={400}>
                    {selectedCustomer?.filter((obj) =>
                      mainSocketEvents?.includes(obj?.type)
                    )?.length ||
                      [
                        ...new Set(
                          selectedCustomer?.map((obj) =>
                            new Date(obj?.time * 1000)?.getDate()
                          )
                        ),
                      ]?.length}
                  </Typography>
                </Stack>
                {time && (
                  <Stack rowGap={0.7}>
                    <Typography sx={{ ...secondaryLabel }}>
                      Latest visit
                    </Typography>
                    <Typography variant="h6" fontWeight={400}>
                      {timeObj?.value} {timeObj?.label} ago
                    </Typography>
                  </Stack>
                )}
              </Stack>
            )}
          </Stack>
          <Tabs
            value={selectedTab}
            onChange={(e, newValue) => setSelectedTab(newValue)}
            variant="scrollable"
            sx={{
              boxShadow: "rgba(17, 17, 26, 0.1) 0px 1px 0px",
              borderBottom: "divider",
            }}
            scrollButtons={false}
          >
            {customerViewTabs.map((obj) => (
              <Tab label={obj?.label} value={obj?.value} />
            ))}
          </Tabs>
        </Stack>
        <TabPanel index={selectedTab} value={"sessions"}>
          <Sessions allSessions={selectedCustomer} />
        </TabPanel>
      </CardContent>
    </Card>
  );
};
