import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isObjWithValues, settingsEndpoint } from "../../../../helper";
import { editSettings } from "../../../../redux/actions/settingActions";
import { default as WooCommerceActivity } from "../shopify/Activity";

const Activity = () => {
  const woocommerceHistory =
    useSelector((state) => state.settings.woocommerceHistory) || {};
  const wooCommerceIntegration =
    useSelector((state) => state.settings.wooCommerceIntegration) || {};
  const dispatch = useDispatch();
  useEffect(async () => {
    if (!isObjWithValues(woocommerceHistory)) {
      let res =
        (await settingsEndpoint({
          endpoint: `woocommerce_history_${wooCommerceIntegration?.website?.replaceAll(
            ".",
            "_"
          )}`,
        })) || {};
      console.log(res);
      dispatch(editSettings({ woocommerceHistory: res }));
    }
  }, []);

  return (
    <>
      <WooCommerceActivity historyObject={woocommerceHistory} />
    </>
  );
};

export default Activity;
