import {
    Box,
    Checkbox,
    FormHelperText,
    InputAdornment,
    MenuItem,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import React, { useState } from "react";
import { Label } from "../../../customers/components/Info";
import { ContentCopy } from "@mui/icons-material";
import { getStoreId } from "../../../../helper";
import { useDispatch } from "react-redux";
import { setGlobalToast } from "../../../../redux/actions/helperActions";
import { useSelector } from "react-redux";

const Integration = () => {
    const isAdmin = useSelector((state) => state.user.isAdmin);
    let url = `https://websockets.tanika.tech/webhooks/paysharp/${getStoreId()}`;
    const apikey = useSelector((state) => state.user)?.appcredentials?.key;

    const dispatch = useDispatch();
    const [hideKey, setHideKey] = useState({
        apikey: true,
    });
    return (
        <Box p={"12px 26px"}>
            <Stack sx={{ width: "55%" }} gap={5}>
                <Stack>
                    <Label>Webhooks Link</Label>
                    <TextField
                        fullWidth
                        value={url}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment
                                    position="end"
                                    sx={{ cursor: "pointer" }}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        navigator.clipboard.writeText(url);
                                        dispatch(
                                            setGlobalToast({
                                                show: true,
                                                severity: "success",
                                                message: "link is copied",
                                            })
                                        );
                                    }}
                                >
                                    <ContentCopy />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Stack>
                <Stack>
                    <Label>API Key</Label>
                    <TextField
                        value={apikey}
                        fullWidth
                        type={hideKey?.apikey ? "password" : "text"}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment
                                    position="end"
                                    sx={{ cursor: "pointer" }}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        navigator.clipboard.writeText(apikey);
                                        dispatch(
                                            setGlobalToast({
                                                show: true,
                                                severity: "success",
                                                message: "Key copied",
                                            })
                                        );
                                    }}
                                >
                                    <ContentCopy />
                                </InputAdornment>
                            ),
                        }}
                    />
                    <FormHelperText
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 3,
                            cursor: "pointer",
                        }}
                        onClick={(e) => {
                            setHideKey({ ...hideKey, apikey: !hideKey?.apikey });
                        }}
                    >
                        <Checkbox
                            sx={{ "& .MuiSvgIcon-root": { fontSize: 18 }, padding: "0" }}
                            checked={!hideKey?.apikey}
                            disabled={!isAdmin}
                        // onChange={(e) => {
                        //     setHideKey({ ...hideKey, apikey: !hideKey?.apikey })
                        // }}
                        />{" "}
                        Show key
                    </FormHelperText>
                </Stack>
                {/* <Stack>
                    <Label>Customer Id</Label>
                    <TextField select >
                        {dropDownOptions?.map(option => <MenuItem key={option.value} value={option.value}>{option?.label}</MenuItem>)}
                    </TextField>
                </Stack> */}
            </Stack>
            <Stack sx={{ gap: 3, width: "80%", mt: 5 }}>
                <Typography variant="h6">
                    To complete your registration with Paysharp, follow these steps to
                    configure the webhook link and API key in the Paysharp dashboard:
                </Typography>
                <ol style={{ marginLeft: "16px" }}>
                    <li>Copy the below-provided webhook link and API key.</li>
                    <li>Access the Paysharp dashboard.</li>
                    <li>
                        Paste the copied webhook link and API key into the appropriate
                        fields in the Paysharp dashboard.
                    </li>
                    <li>
                        Once the webhook link and API key are successfully configured,
                        navigate to the Paysharp Settings section in Jwero.
                    </li>
                    <li>
                        From the dropdown menu, select the option to configure the Decided
                        Customer ID.
                    </li>
                </ol>

            </Stack>
        </Box>
    );
};

export default Integration;
