import {
  Box,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  capitalizeFirstLetter,
  isArrayWithValues,
  isObjWithValues,
  primaryLabel,
  secondaryLabelSmall,
} from "../../../helper";
import {
  platinumPurities,
  purities,
  silverPurities,
} from "../../settings/MasterSettings";
import { currencySymbols } from "../../pricing/Gold";
import { useSelector } from "react-redux";

function ProductCardDetails({ product }) {
  const [rows, setRows] = useState([]);
  const default_currency = useSelector(
    (state) => state.settings.productSettings
  )?.default_currency;
  useEffect(() => {
    if (!isObjWithValues(product)) return;
    let { details_rows } = product;
    if (isArrayWithValues(details_rows)) setRows(details_rows);
    return;
    let {
      metal_types,
      diamond,
      priceBreakup,
      diamond_from,
      colorstone_details,
      colorstone_from,
      wastage_percent,
      labourType,
      minimum_labour,
    } = product;
    let rows = [];
    let metalPurities = {
      gold: purities,
      silver: silverPurities,
      platinium: platinumPurities,
    };
    if (isArrayWithValues(metal_types)) {
      let purityKey = {
        gold: "gold_kt",
        silver: "silver_purity",
        platinium: "platinium_purity",
      };
      for (let metal of metal_types) {
        let purities = metalPurities[metal];
        let purity = purities.find(
          (i) => i.value == product[product[purityKey]]
        );
        rows.push({
          metal: capitalizeFirstLetter(metal),
          purity: purity?.label || "-",
          gross: product[`${metal}_gross`] || "-",
          gross_suffix: "gms",
          amount:
            priceBreakup?.metalBreakup?.[
              metal === "platinium" ? "platinum" : metal
            ] || "-",
          amount_prefix: currencySymbols[default_currency],
        });
      }
      //   let diamond_details = isObjWithValues(diamond)
      //     ? Object.values(diamond)
      //     : isArrayWithValues(diamond)
      //     ? diamond
      //     : null;
      let firstDiamond = diamond?.[0] || diamond?.[1] || "";
      let firstGemstone =
        colorstone_details?.[0] || colorstone_details?.[1] || "";
      console.log(firstDiamond, "<<, diamnd");
      if (isObjWithValues(firstDiamond)) {
        rows.push({
          metal: "Diamond",
          purity: firstDiamond?.diamond_purity || "-",
          gross: firstDiamond?.diamond_weight || "-",
          gross_suffix: "cts",
          amount: priceBreakup?.[diamond_from]?.["diamond"] || "-",
          amount_prefix: currencySymbols[default_currency],
        });
      }
      if (isObjWithValues(firstGemstone)) {
        rows.push({
          metal: "Gemstone",
          purity: firstGemstone?.colorstone_purity || "-",
          gross: firstGemstone?.colorstone_weight || "-",
          gross_suffix: "cts",
          amount: priceBreakup?.[colorstone_from]?.["colorstone"] || "-",
          amount_prefix: currencySymbols[default_currency],
        });
      }
      rows.push({
        metal: "Labour:",
        purity: wastage_percent || "-",
        purity_suffix: "% Wastage",
        gross: minimum_labour || "-",
        gross_suffix: "/gram",
        amount: priceBreakup?.[labourType]?.["labour"] || "-",
        amount_prefix: currencySymbols[default_currency],
      });
      rows.push({
        metal: "Total",
        purity: "",
        gross: "",
        amount: priceBreakup?.["price"] || "-",
        amount_prefix: currencySymbols[default_currency],
      });
    }
    setRows(rows);
  }, [product]);
  return (
    <Box>
      <TableContainer>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ ...secondaryLabelSmall, pb: 1 }}>
                Metal
              </TableCell>
              <TableCell sx={{ ...secondaryLabelSmall, pb: 1 }} align="right">
                Purity
              </TableCell>
              <TableCell sx={{ ...secondaryLabelSmall, pb: 1 }} align="right">
                Gross Wt
              </TableCell>
              <TableCell sx={{ ...secondaryLabelSmall, pb: 1 }} align="right">
                Amt
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody sx={{ rowGap: "4px" }}>
            {rows.map((row) => (
              <>
                <TableRow
                  key={row.metal}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    border: row.metal?.includes("Labour") ? 1 : 0,
                    borderTop:
                      row.metal?.includes("Total") ||
                      row.metal?.includes("Labour")
                        ? 1
                        : 0,
                    borderRadius: "6px",
                    borderColor: "#e8e8e8",
                    "& td": { border: 0, py: 2 },
                    "& th": { border: 0 },
                    py: 1,
                    my: 1,
                    ...primaryLabel,
                  }}
                >
                  <TableCell
                    sx={{
                      ...(row.metal?.includes("Total") ? primaryLabel : {}),
                    }}
                    scope="row"
                  >
                    {row.metal}
                  </TableCell>
                  <TableCell
                    sx={{
                      ...(row.metal?.includes("Total") ? primaryLabel : {}),
                    }}
                    align="right"
                  >
                    {row.purity} {row?.purity_suffix || ""}
                  </TableCell>
                  <TableCell
                    sx={{
                      ...(row.metal?.includes("Total") ? primaryLabel : {}),
                    }}
                    align="right"
                  >
                    {row.gross} {row?.gross_suffix || ""}
                  </TableCell>
                  <TableCell
                    sx={{
                      ...(row.metal?.includes("Total") ? primaryLabel : {}),
                    }}
                    align="right"
                  >
                    {row?.amount_prefix || ""} {row.amount}
                  </TableCell>
                </TableRow>
              </>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default ProductCardDetails;
