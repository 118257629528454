import { Visibility, VisibilityOff } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  FormHelperText,
  FormLabel,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getToken,
  getWebsite,
  isDev,
  isObjWithValues,
} from "../../../../helper";
import axios from "axios";
import { setGlobalToast } from "../../../../redux/actions/helperActions";
import { EDIT_SETTINGS } from "../../../../redux/actions/settingActions";
import { useTheme } from "styled-components";
import Switch from "rc-switch";

function Integration() {
  const theme = useTheme();
  const dispatch = useDispatch();
  let backgroundColor = theme.palette.primary && theme.palette.primary.main;

  const [formData, setFormData] = useState({
    enabled: "no",
    description: "",
    instance_domain: "",
    store_code: "",
    user_id: "",
    password: "",
    // custom_mloyal_name: "",
    // custom_mloyal_logo: "",
  });

  const [visibility, setVisibility] = useState({
    instance_domain: false,
    store_code: false,
    user_id: false,
    password: false,
  });
  const [loading, setLoading] = useState(false);

  const mloyalIntegration = useSelector(
    (state) => state.settings.mloyalIntegration
  );
  const globalToast = useSelector((state) => state.helper.globalToast);
  const isPhone = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    if (isObjWithValues(mloyalIntegration))
      setFormData({ ...mloyalIntegration });
  }, [mloyalIntegration]);

  const onFormEdit = (payload) => {
    setFormData((pre) => ({ ...pre, ...payload }));
  };

  const onUpdateForm = async () => {
    let website = getWebsite();
    let token = getToken();
    if (!website && !token) return false;
    setLoading(true);
    let res = await axios({
      url: `${website}/wp-json/store/v1/settings/mloyal_integration`,
      method: "PUT",
      headers: {
        Authorization: `Basic ${token}`,
      },
      data: {
        setting: "mloyal_integration",
        data: formData,
      },
    });
    if (res.data.success) {
      dispatch(
        setGlobalToast({
          ...globalToast,
          show: true,
          message: "MLoyal setting saved successfully",
          severity: "success",
        })
      );
      dispatch({
        type: EDIT_SETTINGS,
        payload: {
          mloyalIntegration: res.data.data,
        },
      });
    } else {
      dispatch(
        setGlobalToast({
          ...globalToast,
          show: true,
          message: "Integration Failed! Please try again.",
          severity: "error",
        })
      );
    }
    setLoading(false);
  };

  return (
    <Box sx={{ paddingLeft: "12px", paddingBottom: "25px" }}>
      <Stack
        direction={"row"}
        alignItems="center"
        sx={{ gap: "14px", mb: 2 }}
        justifyContent="space-between"
      >
        <Stack direction={"row"} alignItems="center" sx={{ gap: "14px" }}>
          <FormLabel variant="h6">Enable /Disabled</FormLabel>
          <Switch
            onChange={(e) => onFormEdit({ enabled: e ? "yes" : "no" })}
            checked={formData.enabled === "yes"}
            style={
              formData.enabled === "yes"
                ? {
                    border: `1px solid ${backgroundColor}`,
                    backgroundColor,
                  }
                : {}
            }
          />
        </Stack>
        <LoadingButton
          loading={loading}
          variant={"contained"}
          onClick={onUpdateForm}
          sx={{ mr: 4 }}
        >
          Update
        </LoadingButton>
      </Stack>
      <Stack spacing={7} sx={{ width: isPhone ? "100%" : "70%" }}>
        {/* <Stack>
          <FormLabel variant="h6">Title</FormLabel>
          <TextField
            value={formData.title}
            onChange={(e) => onFormEdit({ title: e.target.value })}
            size="small"
            placeholder="Title"
            helperText=""
          />
          <FormHelperText>This controls the title which the user sees during checkout</FormHelperText>
        </Stack> */}
        <Stack>
          <FormLabel variant="h6">Instance Domain</FormLabel>
          <TextField
            type={visibility.instance_domain ? "text" : "password"}
            value={formData.instance_domain}
            onChange={(e) => onFormEdit({ instance_domain: e.target.value })}
            size="small"
            placeholder="Your Instance"
            helperText=""
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    // aria-label="toggle password visibility"
                    onClick={() =>
                      setVisibility((pre) => ({
                        ...pre,
                        instance_domain: !visibility.instance_domain,
                      }))
                    }
                    // onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {visibility.instance_domain ? (
                      <VisibilityOff />
                    ) : (
                      <Visibility />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {/* <FormHelperText>CLIENT ID</FormHelperText> */}
        </Stack>
        <Stack>
          <FormLabel variant="h6">Store Code</FormLabel>
          <TextField
            type={visibility.store_code ? "text" : "password"}
            value={formData.store_code}
            onChange={(e) => onFormEdit({ store_code: e.target.value })}
            size="small"
            placeholder="Store Code"
            helperText=""
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    // aria-label="toggle password visibility"
                    onClick={() =>
                      setVisibility((pre) => ({
                        ...pre,
                        store_code: !visibility.store_code,
                      }))
                    }
                    // onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {visibility.store_code ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {/* <FormHelperText>CLIENT KEY</FormHelperText> */}
        </Stack>
        <Stack>
          <FormLabel variant="h6">User ID</FormLabel>
          <TextField
            type={visibility.user_id ? "text" : "password"}
            value={formData.user_id}
            onChange={(e) => onFormEdit({ user_id: e.target.value })}
            size="small"
            placeholder="User ID"
            helperText=""
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    // aria-label="toggle password visibility"
                    onClick={() =>
                      setVisibility((pre) => ({
                        ...pre,
                        user_id: !visibility.user_id,
                      }))
                    }
                    // onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {visibility.user_id ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {/* <FormHelperText>CLIENT SECRET</FormHelperText> */}
        </Stack>
        <Stack>
          <FormLabel variant="h6">Password</FormLabel>
          <TextField
            type={visibility.password ? "text" : "password"}
            value={formData.password}
            onChange={(e) => onFormEdit({ password: e.target.value })}
            size="small"
            placeholder="Password"
            // helperText=""
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    // aria-label="toggle password visibility"
                    onClick={() =>
                      setVisibility((pre) => ({
                        ...pre,
                        password: !visibility.password,
                      }))
                    }
                    // onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {visibility.password ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {/* <FormHelperText>ElitePay CLIENT DATA ENCRYPTION KEY</FormHelperText> */}
        </Stack>
        {/* <Stack>
          <FormLabel variant="h6">Description</FormLabel>
          <TextField
            value={formData.description}
            onChange={(e) => onFormEdit({ description: e.target.value })}
            size="small"
            placeholder="Description"
            helperText=""
            multiline
            rows={3}
          />
          <FormHelperText>
            This controls the description which the user sees inside loyalty
            points page.
          </FormHelperText>
        </Stack> */}
      </Stack>
    </Box>
  );
}

export default Integration;
