import React, { forwardRef, useImperativeHandle, useState } from "react";
import { dialogStyle } from "../../../helper";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
import { GoogleSheet } from "../../../Svgs";
import { BorderLinearProgress } from "../../products/AllProducts";

const UpdateDialog = forwardRef(({ title, progress, loadingText }, ref) => {
  const [open, setOpen] = useState(false);
  useImperativeHandle(ref, () => {
    return {
      open() {
        setOpen(true);
      },
      close() {
        setOpen(false);
      },
    };
  });

  return (
    <Dialog
      open={open}
      // onClose={close}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="xs"
      fullWidth
      sx={dialogStyle}
    >
      <DialogTitle id="alert-dialog-title">
        {title || "Syncing your products"}
      </DialogTitle>
      <DialogContent>
        <Stack
          direction={"row"}
          justifyContent="center"
          sx={{ margin: "10px auto", alignSelf: "center" }}
        >
          <img
            src="/static/img/brands/jwero_icon.png"
            style={{ height: "130px", width: "130px" }}
          />
          {/* <GoogleSheet
            width="130px"
            height="130px"
            // className="animated_background_color"
          /> */}
        </Stack>
        <Typography
          sx={{
            mt: 3,
            fontSize: "12px",
            textAlign: "center",
            color: "text.secondary",
          }}
        >
          {loadingText || "Syncing in progress..."}
        </Typography>
        <Box sx={{ mt: 6 }}>
          <BorderLinearProgress
            variant="determinate"
            value={Math.round(progress)}
          />
          <Typography
            sx={{
              fontSize: "14px",
              textAlign: "right",
              mr: 2,
              mt: 2,
              fontWeight: "600",
            }}
          >
            {Math.round(progress)}%
          </Typography>
        </Box>
        {/* <DialogContentText id="alert-dialog-description">
              Let Google help apps determine location. This means sending
              anonymous location data to Google, even when no apps are running.
            </DialogContentText> */}
      </DialogContent>
    </Dialog>
  );
});

export default UpdateDialog;
