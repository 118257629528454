import React, { forwardRef, useImperativeHandle, useState } from "react";
import { Avatar, Box, Button, Slide, Snackbar, Stack } from "@mui/material";
import {
  PrimaryLabel,
  PrimaryLabelSmall,
  SecondaryLabelExtraSmall,
} from "../../helper/utility/Labels";
import { LoadingButton } from "@mui/lab";
import { Check, Close } from "@mui/icons-material";
import HorizontalStack from "../../helper/utility/HorizontalStack";
import { getFullName } from "../../helper";

const IncomingRequestToast = forwardRef(
  (
    {
      onWebchatReuqestAccept,
      acceptingWebchatRequest,
      obj,
      onWebchatReuqestClose,
    },
    ref
  ) => {
    const [open, setOpen] = useState(true);

    useImperativeHandle(ref, () => {
      return {
        editOpen: setOpen,
      };
    });

    return (
      //   <Snackbar
      //     open={open}
      //     anchorOrigin={{
      //       vertical: "top",
      //       horizontal: "right",
      //     }}
      //     TransitionComponent={Slide}
      //     transitionDuration={900}
      //   >
      //   </Snackbar>
      <Box
        sx={{
          backgroundColor: "#091E42",
          borderRadius: "16px",
          // width: "400px",
          padding: "20px 25px",
          position: "relative",
          boxShadow:
            "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
        }}
        direction="column"
      >
        <Stack>
          <SecondaryLabelExtraSmall sx={{ flex: 1, color: "#fff" }}>
            Incoming Chat...
          </SecondaryLabelExtraSmall>
        </Stack>
        <HorizontalStack spacing={2} sx={{ my: 2 }}>
          <Avatar />

          <Box>
            <PrimaryLabel sx={{ color: "#fff" }}>
              {getFullName(obj?.user_info)?.trim() || "Unknown"}
            </PrimaryLabel>
            <PrimaryLabelSmall sx={{ color: "#fff" }}>
              From {obj?.user_info?.meta_data?.city}
            </PrimaryLabelSmall>
          </Box>
        </HorizontalStack>
        <div>
          <Stack sx={{ flex: 1 }} direction={"row"} spacing={2}>
            <LoadingButton
              color="success"
              sx={{
                // position: "absolute",
                // top: "10px",
                // right: "10px",
                padding: "8px 46px",
                whiteSpace: "nowrap",
                backgroundColor: "#67BD86",
                color: "#fff",
              }}
              loading={acceptingWebchatRequest?.[obj?.user_info?.id]}
              size="small"
              variant="contained"
              startIcon={<Check />}
              fullWidth
              onClick={() =>
                onWebchatReuqestAccept && onWebchatReuqestAccept(obj)
              }
            >
              Accept Request
            </LoadingButton>
            <Button
              color="error"
              sx={{
                // position: "absolute",
                // top: "10px",
                // right: "10px",
                backgroundColor: "#E04F55",
              }}
              onClick={() => onWebchatReuqestClose(obj)}
              variant="contained"
              startIcon={<Close />}
              size="small"
              fullWidth
            >
              Ignore
            </Button>
          </Stack>
        </div>
      </Box>
    );
  }
);

export default IncomingRequestToast;
