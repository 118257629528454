import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as actions from "../../../../../../redux/actions/flowsActions";
import { Popover, Box, Typography, TextField } from "@mui/material";

const ChangeNamePopover = ({
  changeNameAnchor,
  setChangeNameAnchor,
  setAnchorEl,
}) => {
  const dispatch = useDispatch();
  const botName = useSelector((state) => state.flow?.chatbotStyles)?.botName;
  const tagline = useSelector((state) => state.flow?.chatbotStyles)?.tagline;
  const open = Boolean(changeNameAnchor);
  const id = open ? "simple-popover" : undefined;
  const chatbotStyles = useSelector((state) => state.flow?.chatbotStyles);

  const [limitWarnings, setLimitWarnings] = useState({
    nameLimitWarning: false,
    minimumNameLimit: false,
    taglineLimitWarning: false,
  });

  const handleNamePopoverClose = () => {
    if (!limitWarnings.minimumNameLimit) {
      setChangeNameAnchor(null);
      setAnchorEl(null);
    }
  };

  const handleNameChange = (e) => {
    const value = e.target.value;
    if (value.length > 15) {
      setLimitWarnings((prevState) => ({
        ...prevState,
        nameLimitWarning: true,
      }));
      return;
    }
    if (value.length < 1) {
      setLimitWarnings((prevState) => ({
        ...prevState,
        minimumNameLimit: true,
      }));
    }
    if (value.length < 16 && value.length > 0) {
      setLimitWarnings((prevState) => ({
        ...prevState,
        nameLimitWarning: false,
        minimumNameLimit: false,
      }));
    }
    dispatch({
      type: actions.EDIT_ELEMENT_IN_REDUX,
      payload: {
        chatbotStyles: {
          ...chatbotStyles,
          botName: value,
        },
      },
    });
  };

  const handletaglineChange = (e) => {
    const value = e.target.value;
    if (value.length > 30) {
      setLimitWarnings((prevState) => ({
        ...prevState,
        taglineLimitWarning: true,
      }));
      return;
    }
    setLimitWarnings((prevState) => ({
      ...prevState,
      taglineLimitWarning: false,
    }));
    dispatch({
      type: actions.EDIT_ELEMENT_IN_REDUX,
      payload: {
        chatbotStyles: {
          ...chatbotStyles,
          tagline: value,
        },
      },
    });
  };

  useEffect(() => {}, []);

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={changeNameAnchor}
      onClose={handleNamePopoverClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <Box
        sx={{
          width: "220px",
          minHeight: "160px",
          maxHeight: "195px",
          padding: "15px 15px 15px 20px",
          fontFamily: "Inter",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            fontFamily: "Inter",
            marginBottom: "5px",
            fontSize: "16px",
            fontWeight: 600,
          }}
        >
          Change Name
        </Typography>
        <TextField
          value={botName}
          placeholder="Enter name..."
          onChange={handleNameChange}
          size="small"
          variant="outlined"
        />
        {limitWarnings.nameLimitWarning && (
          <Typography
            sx={{
              fontFamily: "Inter",
              marginBottom: "5px",
              color: "red",
              fontSize: "14px",
              fontWeight: 550,
            }}
          >
            Maximum 15 Characters only !!
          </Typography>
        )}
        {limitWarnings.minimumNameLimit && (
          <Typography
            sx={{
              fontFamily: "Inter",
              marginBottom: "5px",
              color: "red",
              fontSize: "14px",
              fontWeight: 550,
            }}
          >
            There must be at least Minimum 1 Character !!
          </Typography>
        )}
        <Typography
          variant="h6"
          sx={{
            fontFamily: "Inter",
            marginTop: "15px",
            marginBottom: "5px",
            fontSize: "16px",
            fontWeight: 600,
          }}
        >
          Change Tagline
        </Typography>
        <TextField
          value={tagline}
          placeholder="Enter tagline..."
          onChange={handletaglineChange}
          size="small"
          variant="outlined"
        />
        {limitWarnings.taglineLimitWarning && (
          <Typography
            sx={{
              fontFamily: "Inter",
              marginBottom: "5px",
              color: "red",
              fontSize: "14px",
              fontWeight: 550,
            }}
          >
            Maximum 30 Characters only !!
          </Typography>
        )}
      </Box>
    </Popover>
  );
};

export default ChangeNamePopover;
