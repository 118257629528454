const SET_USER = "SET_USER";
export const APPEND_INTEGRATION = "APPEND_INTEGRATION";

export const setUser = (user) => {
  return (dispatch) => {
    return dispatch({ type: SET_USER, payload: user });
  };
};

export const addTourHistory = (data) => {
  return (dispatch, getState) => {
    let state = getState();
    let tour_history = state.user?.tour_history || {};

    dispatch({
      type: SET_USER,
      payload: { tour_history: { ...tour_history, ...data } },
    });
  };
};

export const appendIntegrations = (integrations) => {
  return {
    type: APPEND_INTEGRATION,
    payload: integrations,
  };
};
