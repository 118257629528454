import React, { useEffect } from "react";
import { AmazonSvg, EbaySvg } from "../../../../Svgs";
import { Box, Button, Stack, Typography } from "@mui/material";
import {
  getStoreId,
  getToken,
  getWebsite,
  isObjWithValues,
  primaryLabel,
  secondaryLabel,
  secondaryLabelSmall,
} from "../../../../helper";
import { useDispatch, useSelector } from "react-redux";
import {
  AmazonSP,
  generateAwsS3,
  generateAwsSignatureExecuteApi,
  generateAwsSts,
  getXAmzDate,
} from "./constants";
import { EDIT_SETTINGS } from "../../../../redux/actions/settingActions";
import axios from "axios";
import IntegrationView from "../../helper/IntegrationView";
import { setGlobalToast } from "../../../../redux/actions/helperActions";

function TabIntegration() {
  const reduxAmazonIntegration = useSelector(
    (state) => state.settings.amazonIntegration
  );

  const revokingAmazon = useSelector((state) => state.settings.revokingAmazon);
  const integratingAmazon = useSelector(
    (state) => state.settings.integratingAmazon
  );

  const onPressAuthorise = () => {
    window.open(`${AmazonSP.OAuthUrl}&state=amazon_${getStoreId()}`, "_self");
  };

  const dispatch = useDispatch();

  useEffect(async () => {
    // const xAmzDate = getXAmzDate();
    // const xAmzHeaders = {
    //   "X-Amz-Security-Token": reduxAmazonIntegration.awsTokens.sessionToken,
    //   "x-amz-access-token": reduxAmazonIntegration.tokens.accessToken,
    //   "X-Amz-Date": xAmzDate,
    // };
    // let body = "";

    // const awsSignature = await generateAwsSignatureExecuteApi({
    //   path: `/listings/2021-08-01/items/${reduxAmazonIntegration.store.sellerID}/DC-BR-040?marketplaceIds=A21TJRUUN4KGV`,
    //   method: "PUT",
    //   serviceName: "execute-api",
    //   sessionToken: reduxAmazonIntegration.awsTokens.sessionToken,
    //   accessToken: reduxAmazonIntegration.tokens.accessToken,
    //   xAmzDate: xAmzDate,
    //   regionName: "eu-west-1",
    //   headers: {
    //     ...xAmzHeaders,
    //     host: "sandbox.sellingpartnerapi-eu.amazon.com",
    //   },
    //   body,
    //   accessKeyId: reduxAmazonIntegration.awsTokens.accessKey,
    //   secretAccessKey: reduxAmazonIntegration.awsTokens.secretKey,
    // });

    // if (awsSignature) {
    //   var myHeaders = new Headers();
    //   myHeaders.append(
    //     "x-amz-access-token",
    //     reduxAmazonIntegration.tokens.accessToken
    //   );
    //   myHeaders.append(
    //     "X-Amz-Security-Token",
    //     reduxAmazonIntegration.awsTokens.sessionToken
    //   );
    //   myHeaders.append("X-Amz-Date", xAmzDate);
    //   myHeaders.append("Authorization", awsSignature.Authorization);

    //   var requestOptions = {
    //     method: "PUT",
    //     headers: {
    //       ...xAmzHeaders,
    //       Authorization: awsSignature.Authorization,
    //     },
    //     data: body,
    //   };

    //   try {
    //     let postProduct = await axios({
    //       url: `${AmazonSP.spApiHost}-eu.amazon.com/listings/2021-08-01/items/${reduxAmazonIntegration.store.sellerID}/DC-BR-040?marketplaceIds=A21TJRUUN4KGV`,
    //       ...requestOptions,
    //     });

    //     console.log(postProduct);
    //   } catch (err) {
    //     console.log(err);
    //   }
    // }

    if (isObjWithValues(reduxAmazonIntegration)) {
      try {
        const stsData = await generateAwsSts();

        if (stsData) {
          const { Credentials } = stsData;

          const updatedAmazonIntegrationData = {
            ...reduxAmazonIntegration,
            awsTokens: {
              isExpired: false,
              accessKey: Credentials.AccessKeyId,
              secretKey: Credentials.SecretAccessKey,
              sessionToken: Credentials.SessionToken,
            },
          };

          updateAmazonTokens(updatedAmazonIntegrationData);
        } else {
          console.log(stsData);
        }
      } catch (stsError) {
        console.log("stsError", stsError);
      }
    }
  }, []);

  const updateAmazonTokens = async (amazonIntegrationData) => {
    let website = getWebsite();
    let token = getToken();

    if (website && token) {
      try {
        let updateRes = await axios({
          url: `${website}/wp-json/store/v1/settings/amazon_integration`,
          method: "POST",
          headers: {
            Authorization: `Basic ${token}`,
          },
          data: {
            setting: "amazon_integration",
            data: amazonIntegrationData,
          },
        });

        if (updateRes.data.success) {
          dispatch({
            type: EDIT_SETTINGS,
            payload: {
              amazonIntegration: updateRes.data.data,
            },
          });
        }
      } catch (err) {
        console.debug(err);
      }
    }
  };

  const onPressRevoke = async () => {
    let website = getWebsite();
    let token = getToken();
    dispatch({
      type: EDIT_SETTINGS,
      payload: {
        revokingAmazon: true,
      },
    });

    if (website && token) {
      try {
        let { data } = await axios({
          url: `${website}/wp-json/store/v1/settings/amazon_integration`,
          method: "POST",
          headers: {
            Authorization: `Basic ${token}`,
          },
          data: {
            setting: "amazon_integration",
            data: { integrated: false },
          },
        });
        if (data.type === "success") {
          dispatch({
            type: EDIT_SETTINGS,
            payload: {
              amazonIntegration: data.data,
            },
          });
          dispatch(
            setGlobalToast({
              show: true,
              message: "Amazon integration revoked successfully",
              severity: "success",
            })
          );
        } else throw new Error();
      } catch (err) {
        console.log("Amazon revoke", err);

        dispatch(
          setGlobalToast({
            show: true,
            message: "Coudn't revoke Amazon integration! Please try again.",
            severity: "error",
          })
        );
      }
    }
    dispatch({
      type: EDIT_SETTINGS,
      payload: {
        revokingAmazon: false,
      },
    });
  };
  return (
    <IntegrationView
      onIntegrate={onPressAuthorise}
      onRevoke={onPressRevoke}
      integrationTitle={
        <Box>
          <Stack spacing={2} direction="row" alignItems="row">
            <AmazonSvg height={25} width={25} />
            <Typography sx={{ ...primaryLabel }}>Amazon</Typography>
          </Stack>
          {reduxAmazonIntegration?.integrated && (
            <Typography sx={{ ...secondaryLabelSmall, mt: 1 }}>
              Integrated on: 12 May, 2023
            </Typography>
          )}
        </Box>
      }
      subtitle={
        "With Amazon integration, you can easily manage orders and products right from your dashboard."
      }
      integrateButtonProps={{
        disabled: integratingAmazon,
        children: integratingAmazon ? "Integrating" : "Integrate",
      }}
      revokeButtonProps={{
        disabled: revokingAmazon,
        children: revokingAmazon ? "Revoking" : "Revoke",
      }}
      isIntegrated={reduxAmazonIntegration?.integrated}
    />
  );
  return reduxAmazonIntegration.hasOwnProperty("integrated") &&
    reduxAmazonIntegration.integrated ? (
    <Button
      startIcon={<AmazonSvg height={25} width={25} />}
      onClick={onPressRevoke}
      sx={{
        padding: "12px 16px",
        borderRadius: "14px",
        boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
        backgroundColor: "#FFFFFF",
      }}
    >
      Revoke
    </Button>
  ) : (
    <Button
      startIcon={<AmazonSvg height={40} width={40} />}
      onClick={onPressAuthorise}
      sx={{
        padding: "12px 16px",
        borderRadius: "29px",
        boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
        backgroundColor: "#fff",
      }}
    >
      Seller Authorise
    </Button>
  );
}

export default TabIntegration;
