import {
  Box,
  Button,
  Card,
  DialogActions,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { useState } from "react";
import { PhoneSvg } from "../../Svgs";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  fetchIPInfo,
  formatDate,
  getAxiosError,
  getRandomString,
  getStoreId,
  getTimeInMilliSeconds,
  getToken,
  getWebsite,
  isObjWithValues,
  nodeWebsite,
  settingsEndpoint,
} from "../../helper";
import { AddAPhoto } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { setGlobalToast } from "../../redux/actions/helperActions";
import ProductMediaLibrary from "./ProductMediaLibrary";
import { getIp } from "../../App";
import { UAParser } from "ua-parser-js";
import { EDIT_CHATS } from "../../redux/actions/chatActions";

const SendNotification = ({
  buttonLabel,
  onClose,
  include_external_user_ids,
  // include_player_ids,
  returnPayload,
  getPayload,
  sendBulk,
  selected,
}) => {
  const [formData, setFormData] = useState({
    title: "",
    subtitle: "",
    message: "",
    image: "",
    launchUrl: "",
    name: "",
    included_segments: ["All"],
  });
  const [authKey, setAuthKey] = useState("");
  const [appId, setAppId] = useState("");
  const [loading, setLoading] = useState(false);
  const [mediaLibraryOpen, setMediaLibraryOpen] = useState(false);
  // const inistalSchedule = {
  //     enable: false,
  //     time: new Date(),
  //     scheduleTitle: "",
  // }
  // const [schedule, setSchedule] = useState(inistalSchedule);

  const dispatch = useDispatch();
  const reduxOnesignalIntegration = useSelector(
    (state) => state.settings.onesignalIntegration
  );
  const globalToast = useSelector((state) => state.helper.globalToast);
  const [hasError, setHasError] = useState({
    title: false,
    message: false,
    name: false,
  });
  useEffect(() => {
    if (!isObjWithValues(reduxOnesignalIntegration)) return;
    if (isObjWithValues(reduxOnesignalIntegration?.selectedAccount)) {
      setAppId(reduxOnesignalIntegration?.selectedAccount?.id);
      setAuthKey(
        reduxOnesignalIntegration?.selectedAccount?.basic_auth_key
        // ||
        // reduxOnesignalIntegration?.key
        // "OWZhYTZjZGYtZDkzYy00MzA0LWFjNzAtMzk3OTAyYjI1ZTNl"
        // "YjJlZGQyZTQtMjkzOS00MmRjLWIzZDAtZmFiNjEwMThjZTg0"
      );
    }
  }, [reduxOnesignalIntegration]);

  const onFormEdit = (payload) => {
    setFormData((pre) => ({ ...pre, ...payload }));
  };
  const setImages = (arr) => {
    onFormEdit({ image: arr[0]?.source_url });
    setMediaLibraryOpen(false);
  };
  const createNotification = async () => {
    if (!formData.name) {
      setHasError((pre) => ({ ...pre, name: true }));
      return;
    }
    if (!formData.message) {
      setHasError((pre) => ({ ...pre, message: true }));
      return;
    }
    if (!formData.title) {
      setHasError((pre) => ({ ...pre, title: true }));
      return;
    }
    let data = {
      app_id: appId,
      name: formData.name,
      contents: {
        en: formData.message,
      },
      headings: {
        en: formData.title,
      },
      // included_segments: formData.included_segments,
      // include_player_ids: ["f05ac10f-98ba-403a-9f09-b7f040b1cb87"],
      // "filters": [
      //     {
      //         "field": "tag", "key": "external_id", "relation": "=", "value": "1"
      //         //  "bd75b32d-864f-45b9-b914-5987d3b8cc57"
      //     }
      // ],
      // "filters": [
      //     { "field": "tag", "key": "level", "relation": "=", "value": "10" },
      //     { "operator": "OR" }, { "field": "amount_spent", "relation": ">", "value": "0" }
      // ],
      url: formData.launchUrl,
    };
    if (formData.image)
      data = {
        ...data,
        chrome_web_image: formData.image,
        ios_attachments: { id1: formData.image },
        big_picture: formData.image,
        global_image: formData.image,
        chrome_big_picture: formData.image,
      };
    // if (schedule?.enable) {
    //     dispatch(
    //         addScheduleActivity({
    //             time: new Date(schedule?.time).getTime(),
    //             patform: "onesignal",
    //             payload: { ...data },
    //             schedule_title: schedule?.scheduleTitle
    //         }))
    //     onClose();
    //     return
    // }
    if (include_external_user_ids) {
      // data.include_player_ids = include_player_ids;
      data.include_external_user_ids = include_external_user_ids;
      // data = {
      //   ...data,
      //     webhooks: {
      //       cors: false, // Defaults to false if omitted
      //       "notification.displayed":
      //         "https://websockets.tanika.tech/webhooks/onesignal/27", // e.g. https://site.com/hook
      //       "notification.clicked":
      //         "https://websockets.tanika.tech/webhooks/onesignal/27",
      //       // ... follow the same format for any event in the list above
      //     },
      //   };
    } else {
      data.included_segments = formData.included_segments;
    }

    ///return payload for Campagin
    if (returnPayload) {
      let temp = { ...data, channel: "" };
      delete temp.included_segments;
      getPayload && getPayload(temp);
      onClose();
      return;
    }
    // sending bulk push notification from CRM ANd creating Campagin Without Campaign
    if (sendBulk) {
      delete data.included_segments;
      let website = getWebsite();
      let token = getToken();

      if (website && token) {
        setLoading(true);
        let time = Date.now();
        let id = `${time}_${getRandomString(5)}`;
        let historyObj = {
          id,
          campaign_info: {
            is_campaign: false,
            selected_ids: selected?.map((obj) => ({ id: obj.id })) || [],
            history_obj_id: id,
            campaign_type: "push_notification",
            channel: "onesignal",
            payload: data,
          },
          is_campaign: false,
          start_time: time,
          ip: await getIp(),
          ua: new UAParser().getUA(),
        };

        if (!historyObj.name)
          historyObj.name = formatDate(
            new Date(time),
            "dd:mm:yyyy hh:mm am/pm"
          );
        let location = await fetchIPInfo(historyObj.ip);
        historyObj.location = location;

        // return;
        let res = await axios({
          url: `${website}/wp-json/store/v1/settings/campaign_history`,
          headers: {
            Authorization: `Basic ${token}`,
          },
          method: "PUT",
          data: {
            setting: "campaign_history",
            data: {
              [id]: historyObj,
            },
          },
        });
        if (res?.data?.success) {
          dispatch({
            type: EDIT_CHATS,
            payload: {
              campaignHistory: res?.data?.data,
            },
          });
          dispatch(
            setGlobalToast({
              show: true,
              severity: "success",
              message: "Bulk Push Notification initiated Successfully",
              //   draft
              //       ? darfCampaign(createCampaignState?.name)
              //       : sentCampaign(createCampaignState?.name),
            })
          );
          await axios({
            // url: `http://192.168.1.12:8080/campaign`,
            url: `${nodeWebsite}/campaign`,
            method: "POST",
            headers: {
              Authorization: `Basic ${token}`,
            },
            data: {
              history_obj_id: id,
              website,
              store_id: getStoreId(),
            },
          });
          setLoading(false);

          onClose();

          // onClose();
        }
      }

      return;
    }
    setLoading(true);
    console.log(appId, authKey);

    console.log(data, authKey);
    try {
      const res = await axios({
        url: "https://onesignal.com/api/v1/notifications",
        headers: {
          Authorization: `Basic ${authKey}`,
        },
        // data:
        //  {
        //     "app_id": "3938a11f-6742-410b-85df-87f9c868f681",
        //     "include_player_ids": [
        //         "c1c48ec6-646f-45c9-97b9-6e4703db5227",
        //         "e61fafb3-6c38-4c81-9911-f3b463b3c4bb"
        //     ],
        //     "contents": {
        //         "en": "Hello, {{username}} Your order {{ORDERID}} is ready."
        //     },
        //     "data": {
        //         username: "Himanshu",
        //         ORDERID: "234567"
        //     },
        //     // "info": [
        //     //     {
        //     //         "id": "c1c48ec6-646f-45c9-97b9-6e4703db5227",
        //     //         "data": {
        //     //             "username": "John",
        //     //             "order_id": "123"
        //     //         }
        //     //     },
        //     //     {
        //     //         "id": "e61fafb3-6c38-4c81-9911-f3b463b3c4bb",
        //     //         "data": {
        //     //             "username": "Sarah",
        //     //             "order_id": "456"
        //     //         }
        //     //     }
        //     // ],
        //     "headings": {
        //         "en": "Order Update"
        //     }
        // }
        data,
        method: "POST",
      });
      console.log(res.data);
      if (res?.data?.id) {
        if (include_external_user_ids) {
          let historyRes = await settingsEndpoint({
            endpoint: `push_notification_history_${include_external_user_ids[0]}`,
            method: "PUT",
            data: {
              [res?.data?.id]: {
                time: getTimeInMilliSeconds(),
                payload: data,
                request_id: res?.data?.id,
                status: "success",
              },
            },
          });
          dispatch({
            type: EDIT_CHATS,
            payload: {
              [`push_notification_history_${include_external_user_ids[0]}`]:
                historyRes,
            },
          });
        }
        dispatch(
          setGlobalToast({
            ...globalToast,
            show: true,
            message: "Push notification successfully",
            severity: "success",
          })
        );
        onClose();
      } else {
        if (include_external_user_ids) {
          let time = getTimeInMilliSeconds();
          let historyRes = await settingsEndpoint({
            endpoint: `push_notification_history_${include_external_user_ids[0]}`,
            method: "PUT",
            data: {
              [time]: {
                time,
                payload: data,
                request_id: res?.data?.id,
                status: Object.keys(res?.data?.errors || [])?.join(","),
              },
            },
          });
          dispatch({
            type: EDIT_CHATS,
            payload: {
              [`push_notification_history_${include_external_user_ids[0]}`]:
                historyRes,
            },
          });
        }
        // dispatch(
        //   setGlobalToast({
        //     ...globalToast,
        //     show: true,
        //     message: "Push notification successfully",
        //     severity: "success",
        //   })
        // );

        dispatch(
          setGlobalToast({
            ...globalToast,
            show: true,
            message: Object.keys(res?.data?.errors || [])?.join(","),
            // getAxiosError(error)?.errors?.[0]?.constructor === String
            //   ? getAxiosError(error)?.errors?.[0]
            //   : ""
            severity: "error",
          })
        );
        onClose();
      }
    } catch (error) {
      console.log(getAxiosError(error));
      dispatch(
        setGlobalToast({
          ...globalToast,
          show: true,
          message:
            getAxiosError(error)?.errors?.[0]?.constructor === String
              ? getAxiosError(error)?.errors?.[0]
              : "",
          severity: "error",
        })
      );
    }
    // onClose();
    setLoading(false);
  };

  // const sendbulkThroughCampagin =()=>{
  //     setLoading(true);

  // }
  // const sendPayload = () => {
  //     getPayload && getPayload(
  //         {}
  //     )
  //     onClose();
  // }
  return (
    <>
      <Box>
        <Grid container spacing={15}>
          <Grid item xs={6}>
            <Stack spacing={2}>
              <Stack>
                <Typography variant="subtitle1">Name</Typography>
                <TextField
                  required
                  placeholder="Name"
                  size="small"
                  error={hasError.name}
                  helperText={hasError.name ? "Required*" : ""}
                  value={formData.name}
                  onChange={(e) => {
                    onFormEdit({ name: e.target.value });
                    setHasError((pre) => ({ ...pre, message: false }));
                  }}
                />
              </Stack>
              <Stack>
                <Typography variant="subtitle1">Title</Typography>
                <TextField
                  placeholder="Title (Enter in preferred language)"
                  size="small"
                  error={hasError.title}
                  value={formData.title}
                  onChange={(e) => {
                    onFormEdit({ title: e.target.value });
                    setHasError((pre) => ({ ...pre, title: false }));
                  }}
                />
              </Stack>
              <Stack>
                <Typography variant="subtitle1">Subtitle</Typography>
                <TextField
                  placeholder="Subtitle (Enter in preferred language)"
                  size="small"
                  value={formData.subtitle}
                  onChange={(e) => {
                    onFormEdit({ subtitle: e.target.value });
                  }}
                />
              </Stack>
              <Stack>
                <Typography variant="subtitle1">Message *</Typography>
                <TextField
                  error={hasError.message}
                  helperText={hasError.message ? "Required*" : ""}
                  placeholder="Message (Enter in preferred language)"
                  size="small"
                  required
                  multiline
                  rows={3}
                  value={formData.message}
                  onChange={(e) => {
                    onFormEdit({ message: e.target.value });
                    setHasError((pre) => ({ ...pre, message: false }));
                  }}
                />
              </Stack>
              <Stack>
                <Typography variant="subtitle1">Image</Typography>
                <TextField
                  placeholder="Upload or input url"
                  size="small"
                  value={formData.image}
                  onChange={(e) => {
                    onFormEdit({ image: e.target.value });
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <IconButton onClick={() => setMediaLibraryOpen(true)}>
                          <AddAPhoto />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Stack>
              <Stack>
                <Typography variant="subtitle1">Launch URL</Typography>
                <TextField
                  placeholder="http://bit.ly/abc"
                  size="small"
                  value={formData.launchUrl}
                  onChange={(e) => {
                    onFormEdit({ launchUrl: e.target.value });
                  }}
                />
              </Stack>
            </Stack>
            {/* <Stack sx={{ mt: 3, mb: 2 }}>
                            <Stack direction={"row"} gap={"30px"}>
                                <Typography variant="subtitle1">Schedule</Typography>{" "}
                                <CustomSwitch
                                    checked={schedule?.enable}
                                    onChange={(e) => {
                                        setSchedule((pre) => ({ ...pre, enable: e }));
                                    }}
                                />
                            </Stack>
                            <Collapse in={schedule?.enable}>
                                <Box sx={{}}>
                                    <Stack sx={{ mt: 1, mb: 3 }}>
                                        <Typography variant="subtitle1" sx={{ marginTop: "14px" }}>Schedule Title</Typography>
                                        <TextField
                                            // fullWidth
                                            size="small"
                                            placeholder="Schedule Title"
                                            // variant="standard"
                                            error={hasError?.scheduleTitle}
                                            helperText={hasError?.scheduleTitle ? "Schedule title is required* " : ""}
                                            value={schedule?.scheduleTitle}
                                            onChange={(e) => {
                                                setSchedule(pre => ({ ...pre, scheduleTitle: e.target.value }));
                                                setHasError((pre) => ({ ...pre, scheduleTitle: false }));
                                            }}
                                        />
                                    </Stack>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DesktopDateTimePicker
                                            error={hasError?.time}
                                            value={schedule?.time ? schedule?.time : null}
                                            onChange={(newValue) => {
                                                setHasError((pre) => ({ ...pre, time: new Date(newValue).getTime() < new Date().getTime() }));
                                                setSchedule(pre => ({ ...pre, time: newValue }));
                                            }}
                                            renderInput={(params) => (
                                                <TextField {...params} size="small"
                                                    helperText={hasError?.time ? "Time must be of future" : ""} error={hasError?.time}
                                                />
                                            )}
                                        />
                                    </LocalizationProvider>
                                </Box>
                            </Collapse>
                        </Stack> */}
          </Grid>
          <Grid item xs={6} sx={{ position: "relative", overflow: "hidden" }}>
            <PhoneSvg height="60vh" />
            <Card
              sx={{
                position: "absolute",
                top: formData.image ? "45%" : "60%",
                width: "76%",
                right: "25px",
                padding: "10px",
                boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                borderRadius: "18px",
              }}
            >
              <Stack direction={"row"} justifyContent="space-between">
                <Typography>{formData.title}</Typography>
                <Typography>now</Typography>
              </Stack>
              <Stack>
                <Typography>{formData.subtitle}</Typography>
                <Typography>{formData.message}</Typography>
              </Stack>
              {formData.image ? (
                <img
                  alt="notification "
                  src={formData.image}
                  style={{ width: "100%", borderRadius: "7px" }}
                />
              ) : null}
            </Card>
          </Grid>
        </Grid>
      </Box>
      <ProductMediaLibrary
        open={mediaLibraryOpen}
        onChange={() => {
          setMediaLibraryOpen(false);
        }}
        setParentImages={setImages}
        multiple={false}
      />
      <DialogActions>
        <Button autoFocus onClick={onClose}>
          Cancel
        </Button>
        <LoadingButton
          loading={loading}
          onClick={createNotification}
          autoFocus
          variant="contained"
        >
          {buttonLabel || "Create Notification"}
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default SendNotification;
