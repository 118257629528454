const StaticDropdown = {
  metal_type: [],
  status: [
    { label: "Publish", value: "publish", type: "completed" },
    { label: "Draft", value: "draft", type: "failed" },
  ],
  stock_status: [
    { label: "In Stock", value: "instock", type: "completed" },
    { label: "Make to Order", value: "onbackorder", type: "pending" },
    { label: "Out of Stock", value: "outofstock", type: "failed" },
  ],
  diamond_lab: [
    { label: "AGS", value: "AGS" },
    { label: "DGLA", value: "DGLA" },
    { label: "DGSL", value: "DGSL" },
    { label: "DHC", value: "DHC" },
    { label: "EGL", value: "EGL" },
    { label: "Forevermark", value: "Forevermark" },
    { label: "GIA", value: "GIA" },
    { label: "GII", value: "GII" },
    { label: "GSI", value: "GSI" },
    { label: "HRD", value: "HRD" },
    { label: "IDT", value: "IDT" },
    { label: "IIDGR", value: "IIDGR" },
    { label: "IGI", value: "IGI" },
    { label: "IIG", value: "IIG" },
    { label: "SGL", value: "SGL" },
  ],
  // diamond_lab: [
  //   { label: "IGI", value: "IGI" },
  //   { label: "SGL", value: "SGL" },
  //   { label: "DGLA", value: "DGLA" },
  //   { label: "IIG", value: "IIG" },
  //   { label: "DHC", value: "DHC" },
  //   { label: "GIA", value: "GIA" },
  //   { label: "HRD", value: "HRD" },
  //   { label: "DGSL", value: "DGSL" },
  // ],
  platinum_purity: [
    { label: "850", value: "850" },
    { label: "900", value: "900" },
    { label: "950", value: "950" },
  ],
  silver_purity: [
    { label: "650", value: "650" },
    { label: "750", value: "750" },
    { label: "850", value: "850" },
    { label: "Sterling (925)", value: "925" },
    { label: "Fine (995)", value: "995" },
    { label: "Fine (999)", value: "999" },
  ],
  gold_kt: [
    { label: "24KT (999.99)", value: "999.99" },
    { label: "24KT (999)", value: "999" },
    { label: "24KT (995)", value: "995" },
    { label: "22KT (916)", value: "916" },
    { label: "18KT (750)", value: "750" },
    { label: "14KT (583)", value: "583" },
    { label: "9KT (375)", value: "375" },
  ],
  custom_wastage_from: [
    { label: "Gross", value: "gross" },
    { label: "Net", value: "net" },
  ],
  colorstone_type: [
    { label: "Ruby", value: "ruby" },
    { label: "Emerald", value: "emerald" },
    { label: "Pearl", value: "pearl" },
    { label: "Sapphire", value: "sapphire" },
    { label: "Amethyst", value: "amethyst" },
    { label: "Beads", value: "beads" },
    { label: "Black beeds", value: "black-beeds" },
    { label: "Black diamond", value: "black-diamond" },
    { label: "Blue sapphire", value: "blue-sapphire" },
    { label: "Blue sapphire-beads", value: "blue-sapphire-beads" },
    { label: "Real coral", value: "real-coral" },
    { label: "Claw", value: "claw" },
    { label: "Cubic zirconia", value: "cubic-zirconia" },
    { label: "Real emerald", value: "real-emerald" },
    { label: "Garnet", value: "garnet" },
    { label: "Gempu ruby", value: "gempu-ruby" },
    { label: "Multicolor mix", value: "multicolor-mix" },
    {
      label: "Navratna set with cubic zirconia",
      value: "navratna-set-with-cubic-zirconia",
    },
    {
      label: "Navratna set with diamond",
      value: "navratna-set-with-diamond",
    },
    { label: "Ruby beads", value: "ruby-beads" },
    { label: "Ruby emerald", value: "ruby-emerald" },
    { label: "Real ruby", value: "real-ruby" },
    { label: "Southsea cream-pearl", value: "southsea-cream-pearl" },
    { label: "Spinnel ruby red", value: "spinnel-ruby-red" },
    { label: "Synthetic", value: "synthetic" },
    { label: "Synthetic beads", value: "synthetic-beads" },
    { label: "Turquoise", value: "turquoise" },
    { label: "Watch", value: "watch" },
    { label: "Wax", value: "wax" },
    { label: "Yellow topaz", value: "yellow-topaz" },
    { label: "Topaz", value: "topaz" },
  ],
  colorstone_shape: [
    { label: "Round", value: "round" },
    { label: "Square", value: "square" },
    { label: "Oval", value: "oval" },
    { label: "Emerald", value: "emerald" },
    { label: "Pear", value: "pear" },
    { label: "Marquise", value: "marquise" },
    { label: "Marquee", value: "marquee" },
    { label: "Tapper bugget", value: "tapper-bugget" },
    { label: "Heart", value: "heart" },
    { label: "Triangle", value: "triangle" },
    { label: "Cushion", value: "cushion" },
    { label: "Beads", value: "beads" },
  ],
  colorstone_quality: [
    { label: "Precious", value: "precious" },
    { label: "Semi precious", value: "semi-precious" },
    { label: "Polki", value: "polki" },
    { label: "Kundan", value: "kundan" },
    { label: "Uncut", value: "uncut" },
    { label: "Hydro", value: "hydro" },
    { label: "Doublet", value: "doublet" },
  ],
  metal_color: [
    {
      label: "white",
      value: "white",
    },
    {
      label: "yellow",
      value: "yellow",
    },
    {
      label: "rose",
      value: "rose",
    },
    {
      label: "two-tone",
      value: "two-tone",
    },
  ],
  labourType: [
    {
      label: "master",
      value: "master",
    },
    {
      label: "customize",
      value: "customize",
    },
  ],
  collections: [
    {
      id: 343,
      label: "",
      value: "343",
      count: 0,
    },
    {
      id: 474,
      label: "0",
      value: "0",
      count: 1,
    },
    {
      id: 205,
      label: "Antique Jewellery",
      value: "antique-jewellery",
      count: 0,
    },
    {
      id: 403,
      label: "Dazzling Collections",
      value: "dazzling-collections",
      count: 0,
    },
    {
      id: 12,
      label: "Diamond Jewellery",
      value: "diamond-jewellery",
      count: 4591,
    },
    {
      id: 308,
      label: "Diamond Jewellery, Gold Jewellery",
      value: "diamond-jewellery-gold-jewellery",
      count: 0,
    },
    {
      id: 317,
      label: "Diamond Jewellery, platinum-jewellery",
      value: "diamond-jewellery-platinum-jewellery",
      count: 0,
    },
    {
      id: 15,
      label: "Gemstone Jewellery",
      value: "gemstone-jewellery",
      count: 0,
    },
    {
      id: 324,
      label: "Gemstone Jewellery, Gold Jewellery",
      value: "gemstone-jewellery-gold-jewellery",
      count: 0,
    },
    {
      id: 354,
      label: "gold and diamond",
      value: "gold-and-diamond",
      count: 1,
    },
    {
      id: 345,
      label: "Gold and gemstone jewellery",
      value: "gold-and-gemstone-jewellery",
      count: 0,
    },
    {
      id: 11,
      label: "Gold Jewellery",
      value: "gold-jewellery",
      count: 83,
    },
    {
      id: 467,
      label: "Jewellery",
      value: "jewellery",
      count: 2,
    },
    {
      id: 381,
      label: "Lab Grown Diamonds",
      value: "lab-grown-diamonds",
      count: 0,
    },
    {
      id: 213,
      label: "platinum-jewellery",
      value: "platinum-jewellery",
      count: 0,
    },
    {
      id: 406,
      label: "Ruby Collections",
      value: "ruby-collections",
      count: 0,
    },
    {
      id: 524,
      label: "Silver",
      value: "silver",
      count: 2,
    },
    {
      id: 286,
      label: "silver-jewellery",
      value: "silver-jewellery",
      count: 25,
    },
    {
      id: 355,
      label: "solitaire",
      value: "solitaire",
      count: 0,
    },
    {
      id: 391,
      label: "T-1",
      value: "t-1",
      count: 0,
    },
  ],
  categories: [
    {
      id: 468,
      label: "B",
      value: "b",
      count: 0,
    },
    {
      id: 413,
      label: "BABY CHAIN",
      value: "baby-chain",
      count: 0,
    },
    {
      id: 469,
      label: "D",
      value: "d",
      count: 0,
    },
    {
      id: 284,
      label: "Kids",
      value: "kids",
      count: 0,
    },
    {
      id: 280,
      label: "Mens",
      value: "mens",
      count: 2,
    },
    {
      id: 296,
      label: "MIHIKA",
      value: "mihika",
      count: 0,
    },
    {
      id: 405,
      label: "Nathnis",
      value: "nathnis",
      count: 0,
    },
    {
      id: 525,
      label: "SL",
      value: "sl",
      count: 2,
    },
    {
      id: 470,
      label: "STONE",
      value: "stone",
      count: 0,
    },
    {
      id: 295,
      label: "UMRAO",
      value: "umrao",
      count: 0,
    },
    {
      id: 262,
      label: "Uncategorized",
      value: "uncategorized",
      count: 90,
    },
    {
      id: 257,
      label: "wedding-ring",
      value: "wedding-ring",
      count: 1,
    },
    {
      id: 283,
      label: "Womens",
      value: "womens",
      count: 0,
    },
    {
      id: 359,
      label: "Anniversary",
      value: "anniversary",
      count: 0,
    },
    {
      id: 230,
      label: "Bangles",
      value: "bangles",
      count: 80,
    },
    {
      id: 350,
      label: "Birthday",
      value: "birthday",
      count: 0,
    },
    {
      id: 299,
      label: "Chain",
      value: "chain",
      count: 4,
    },
    {
      id: 207,
      label: "Demo",
      value: "demo",
      count: 0,
    },
    {
      id: 288,
      label: "dsadsdasdsa",
      value: "dsadsdasdsa",
      count: 0,
    },
    {
      id: 390,
      label: "Haram",
      value: "haram",
      count: 0,
    },
    {
      id: 434,
      label: "Mangalsutra",
      value: "mangalsutra",
      count: 0,
    },
    {
      id: 356,
      label: "Marriage",
      value: "marriage",
      count: 0,
    },
    {
      id: 2,
      label: "Earrings",
      value: "earrings",
      count: 1166,
    },
    {
      id: 8,
      label: "Bracelet",
      value: "bracelet",
      count: 107,
    },
    {
      id: 9,
      label: "Ladies Ring",
      value: "ladies-ring",
      count: 1016,
    },
    {
      id: 10,
      label: "Gents Ring",
      value: "gents-ring",
      count: 413,
    },
    {
      id: 100,
      label: "Nose Pins",
      value: "nose-pins",
      count: 93,
    },
    {
      id: 5,
      label: "Necklaces",
      value: "necklaces",
      count: 35,
    },
    {
      id: 98,
      label: "Pendants",
      value: "pendants",
      count: 1066,
    },
  ],
  subcategories: [
    {
      id: 277,
      label: "",
      value: "277",
      count: 0,
    },
    {
      id: 282,
      label: "anniversary",
      value: "anniversary",
      count: 3,
    },
    {
      id: 412,
      label: "BABY CHAIN",
      value: "baby-chain",
      count: 0,
    },
    {
      id: 439,
      label: "Bali",
      value: "bali",
      count: 0,
    },
    {
      id: 285,
      label: "Birthday",
      value: "birthday",
      count: 1,
    },
    {
      id: 404,
      label: "Bombay Collections",
      value: "bombay-collections",
      count: 1,
    },
    {
      id: 409,
      label: "CHAIN",
      value: "chain",
      count: 0,
    },
    {
      id: 247,
      label: "Closed Setting",
      value: "closed-setting",
      count: 0,
    },
    {
      id: 245,
      label: "CNC Machine Collection",
      value: "cnc-machine-collection",
      count: 0,
    },
    {
      id: 276,
      label: "DIAMOND NOSE PIN",
      value: "diamond-nose-pin",
      count: 0,
    },
    {
      id: 440,
      label: "Fulkari",
      value: "fulkari",
      count: 1,
    },
    {
      id: 360,
      label: "Gold Jewellery",
      value: "gold-jewellery",
      count: 0,
    },
    {
      id: 255,
      label: "Group Ahmedabad",
      value: "group-ahmedabad",
      count: 0,
    },
    {
      id: 236,
      label: "Group Kolkata",
      value: "group-kolkata",
      count: 0,
    },
    {
      id: 234,
      label: "Group Mumbai",
      value: "group-mumbai",
      count: 0,
    },
    {
      id: 292,
      label: "Handmade Bangles",
      value: "handmade-bangles",
      count: 0,
    },
    {
      id: 357,
      label: "Kids",
      value: "kids",
      count: 0,
    },
    {
      id: 291,
      label: "Machine Bangle",
      value: "machine-bangle",
      count: 0,
    },
    {
      id: 459,
      label: "Mangalsutra pendant",
      value: "mangalsutra-pendant",
      count: 0,
    },
    {
      id: 287,
      label: "Marriage",
      value: "marriage",
      count: 1,
    },
    {
      id: 348,
      label: "Men",
      value: "men",
      count: 0,
    },
    {
      id: 475,
      label: "New Arrival Rings",
      value: "new-arrival-rings",
      count: 1,
    },
    {
      id: 419,
      label: "new Category",
      value: "new-category",
      count: 0,
    },
    {
      id: 460,
      label: "Religious Pendant",
      value: "religious-pendant",
      count: 1,
    },
    {
      id: 294,
      label: "Studded Pen",
      value: "studded-pen",
      count: 0,
    },
    {
      id: 465,
      label: "SwarnaSri",
      value: "swarnasri",
      count: 0,
    },
    {
      id: 389,
      label: "TAG1",
      value: "tag1",
      count: 0,
    },
    {
      id: 384,
      label: "thread",
      value: "thread",
      count: 0,
    },
    {
      id: 471,
      label: "tribal Necklace",
      value: "tribal-necklace",
      count: 1,
    },
    {
      id: 281,
      label: "Wedding",
      value: "wedding",
      count: 2,
    },
    {
      id: 351,
      label: "Women",
      value: "women",
      count: 1,
    },
  ],
  tags: [
    {
      id: 140,
      label: "1 GRAM GOLD COIN",
      value: "1-gram-gold-coin",
      count: 0,
    },
    {
      id: 141,
      label: "2 GRAM GOLD COIN",
      value: "2-gram-gold-coin",
      count: 0,
    },
    {
      id: 85,
      label: "3 Diamonds",
      value: "3-diamonds",
      count: 53,
    },
    {
      id: 46,
      label: "4 Diamond",
      value: "4-diamond",
      count: 0,
    },
    {
      id: 420,
      label: "4 Diamonds",
      value: "4-diamonds-2",
      count: 67,
    },
    {
      id: 110,
      label: "4-diamonds",
      value: "4-diamonds",
      count: 0,
    },
    {
      id: 43,
      label: "7 Diamond",
      value: "7-diamond",
      count: 1,
    },
    {
      id: 433,
      label: "7 Diamonds",
      value: "7-diamonds-2",
      count: 28,
    },
    {
      id: 96,
      label: "7-diamonds",
      value: "7-diamonds",
      count: 1,
    },
    {
      id: 304,
      label: "7-diamonds, Couple Band",
      value: "7-diamonds-couple-band",
      count: 0,
    },
    {
      id: 303,
      label: "7-diamonds, Couple Band, Designer",
      value: "7-diamonds-couple-band-designer",
      count: 0,
    },
    {
      id: 326,
      label: "7-diamonds, Couple Band, Heart",
      value: "7-diamonds-couple-band-heart",
      count: 0,
    },
    {
      id: 83,
      label: "9 Diamonds",
      value: "9-diamonds",
      count: 58,
    },
    {
      id: 320,
      label: "9 Diamonds, Designer",
      value: "9-diamonds-designer",
      count: 0,
    },
    {
      id: 428,
      label: "Alphabets",
      value: "alphabets",
      count: 104,
    },
    {
      id: 444,
      label: "antique",
      value: "antique",
      count: 2,
    },
    {
      id: 95,
      label: "bali",
      value: "bali",
      count: 154,
    },
    {
      id: 325,
      label: "bali, Tops",
      value: "bali-tops",
      count: 0,
    },
    {
      id: 25,
      label: "Band",
      value: "band",
      count: 108,
    },
    {
      id: 334,
      label: "Band, Bridal, Broad Ring",
      value: "band-bridal-broad-ring",
      count: 0,
    },
    {
      id: 302,
      label: "Band, Couple Band",
      value: "band-couple-band",
      count: 1,
    },
    {
      id: 329,
      label: "Band, Couple Band, Designer",
      value: "band-couple-band-designer",
      count: 0,
    },
    {
      id: 333,
      label: "Band, Couple Band, Heart",
      value: "band-couple-band-heart",
      count: 0,
    },
    {
      id: 319,
      label: "Band, Designer",
      value: "band-designer",
      count: 0,
    },
    {
      id: 81,
      label: "Bands",
      value: "bands",
      count: 142,
    },
    {
      id: 464,
      label: "beautiful ring",
      value: "beautiful-ring",
      count: 0,
    },
    {
      id: 450,
      label: "Best",
      value: "best",
      count: 9,
    },
    {
      id: 162,
      label: "Bridal",
      value: "bridal",
      count: 8,
    },
    {
      id: 318,
      label: "Bridal, Broad",
      value: "bridal-broad",
      count: 0,
    },
    {
      id: 321,
      label: "Bridal, Chandeliers",
      value: "bridal-chandeliers",
      count: 0,
    },
    {
      id: 335,
      label: "Bridal, Heart",
      value: "bridal-heart",
      count: 0,
    },
    {
      id: 332,
      label: "Bridal, Jhumki",
      value: "bridal-jhumki",
      count: 0,
    },
    {
      id: 70,
      label: "Broad",
      value: "broad",
      count: 44,
    },
    {
      id: 47,
      label: "Broad Ring",
      value: "broad-ring",
      count: 0,
    },
    {
      id: 323,
      label: "Broad, Cluster, Tennis",
      value: "broad-cluster-tennis",
      count: 0,
    },
    {
      id: 451,
      label: "Casual",
      value: "casual",
      count: 7,
    },
    {
      id: 163,
      label: "Chandeliers",
      value: "chandeliers",
      count: 0,
    },
    {
      id: 322,
      label: "Chandeliers, Nakshatra",
      value: "chandeliers-nakshatra",
      count: 0,
    },
    {
      id: 157,
      label: "Chocker",
      value: "chocker",
      count: 0,
    },
    {
      id: 316,
      label: "Chocker, Designer",
      value: "chocker-designer",
      count: 0,
    },
    {
      id: 161,
      label: "Close Set",
      value: "close-set",
      count: 0,
    },
    {
      id: 430,
      label: "Close Setting",
      value: "close-setting-2",
      count: 25,
    },
    {
      id: 93,
      label: "close-setting",
      value: "close-setting",
      count: 0,
    },
    {
      id: 209,
      label: "Cluster",
      value: "cluster",
      count: 3,
    },
    {
      id: 312,
      label: "Cluster, Designer",
      value: "cluster-designer",
      count: 0,
    },
    {
      id: 446,
      label: "Coin",
      value: "coin",
      count: 0,
    },
    {
      id: 74,
      label: "Color Stone",
      value: "color-stone",
      count: 0,
    },
    {
      id: 117,
      label: "colorcstone",
      value: "colorcstone",
      count: 0,
    },
    {
      id: 423,
      label: "Colour Stone",
      value: "colour-stone-2",
      count: 192,
    },
    {
      id: 143,
      label: "colour-stone",
      value: "colour-stone",
      count: 1,
    },
    {
      id: 232,
      label: "Composites",
      value: "composites",
      count: 0,
    },
    {
      id: 156,
      label: "Couple Band",
      value: "couple-band",
      count: 0,
    },
    {
      id: 300,
      label: "Couple Band, Heart",
      value: "couple-band-heart",
      count: 0,
    },
    {
      id: 259,
      label: "Couple Rings",
      value: "couple-rings",
      count: 0,
    },
    {
      id: 417,
      label: "cus",
      value: "cus",
      count: 0,
    },
    {
      id: 415,
      label: "Custom Tag",
      value: "custom-tag",
      count: 0,
    },
    {
      id: 452,
      label: "Daily",
      value: "daily",
      count: 6,
    },
    {
      id: 297,
      label: "Dancing Diamond",
      value: "dancing-diamond",
      count: 0,
    },
    {
      id: 75,
      label: "Designer",
      value: "designer",
      count: 655,
    },
    {
      id: 462,
      label: "designer ring",
      value: "designer-ring",
      count: 0,
    },
    {
      id: 313,
      label: "Designer, Eternity",
      value: "designer-eternity",
      count: 0,
    },
    {
      id: 315,
      label: "Designer, Floret, Nakshatra",
      value: "designer-floret-nakshatra",
      count: 0,
    },
    {
      id: 314,
      label: "Designer, Hanging",
      value: "designer-hanging",
      count: 0,
    },
    {
      id: 414,
      label: "diwali",
      value: "diwali",
      count: 0,
    },
    {
      id: 472,
      label: "Diwali Collection",
      value: "diwali-collection",
      count: 1,
    },
    {
      id: 426,
      label: "Enamel",
      value: "enamel",
      count: 28,
    },
    {
      id: 235,
      label: "Eternity",
      value: "eternity",
      count: 0,
    },
    {
      id: 298,
      label: "Evil Eye",
      value: "evil-eye",
      count: 0,
    },
    {
      id: 422,
      label: "Fancy Diamonds",
      value: "fancy-diamonds-2",
      count: 12,
    },
    {
      id: 92,
      label: "fancy-diamond",
      value: "fancy-diamond",
      count: 0,
    },
    {
      id: 89,
      label: "fancy-diamonds",
      value: "fancy-diamonds",
      count: 1,
    },
    {
      id: 416,
      label: "festive collection",
      value: "festive-collection",
      count: 0,
    },
    {
      id: 223,
      label: "Floral",
      value: "floral",
      count: 0,
    },
    {
      id: 73,
      label: "Floret",
      value: "floret",
      count: 569,
    },
    {
      id: 40,
      label: "Flower",
      value: "flower",
      count: 0,
    },
    {
      id: 260,
      label: "Fusion Rings Gold and Platinum",
      value: "fusion-rings-gold-and-platinum",
      count: 0,
    },
    {
      id: 18,
      label: "Gemstone Earring",
      value: "gemstone-earring",
      count: 0,
    },
    {
      id: 168,
      label: "gents",
      value: "gents",
      count: 6,
    },
    {
      id: 48,
      label: "Gents Ring",
      value: "gents-ring",
      count: 0,
    },
    {
      id: 528,
      label: "GIFTCARD",
      value: "giftcard",
      count: 0,
    },
    {
      id: 80,
      label: "God",
      value: "god",
      count: 0,
    },
    {
      id: 139,
      label: "GOLD COINS",
      value: "gold-coins",
      count: 0,
    },
    {
      id: 457,
      label: "Half Pavan",
      value: "half-pavan",
      count: 0,
    },
    {
      id: 458,
      label: "Half Sovereign",
      value: "half-sovereign",
      count: 0,
    },
    {
      id: 221,
      label: "Handmade",
      value: "handmade",
      count: 0,
    },
    {
      id: 76,
      label: "Hanging",
      value: "hanging",
      count: 76,
    },
    {
      id: 278,
      label: "Haram",
      value: "haram",
      count: 0,
    },
    {
      id: 77,
      label: "Heart",
      value: "heart",
      count: 194,
    },
    {
      id: 438,
      label: "jali",
      value: "jali",
      count: 0,
    },
    {
      id: 159,
      label: "Jhumki",
      value: "jhumki",
      count: 2,
    },
    {
      id: 79,
      label: "Kids",
      value: "kids",
      count: 25,
    },
    {
      id: 45,
      label: "Kite",
      value: "kite",
      count: 0,
    },
    {
      id: 454,
      label: "Lakshmi",
      value: "lakshmi",
      count: 0,
    },
    {
      id: 72,
      label: "Line",
      value: "line",
      count: 2,
    },
    {
      id: 166,
      label: "Long",
      value: "long",
      count: 0,
    },
    {
      id: 425,
      label: "Long Earring",
      value: "long-earring-2",
      count: 27,
    },
    {
      id: 94,
      label: "long-earring",
      value: "long-earring",
      count: 1,
    },
    {
      id: 225,
      label: "Loose",
      value: "loose",
      count: 0,
    },
    {
      id: 437,
      label: "Loose Bracelet",
      value: "loose-bracelet-2",
      count: 11,
    },
    {
      id: 167,
      label: "loose-bracelet",
      value: "loose-bracelet",
      count: 1,
    },
    {
      id: 331,
      label: "Loose, Tennis",
      value: "loose-tennis",
      count: 0,
    },
    {
      id: 441,
      label: "Makar Sankrant",
      value: "makar-sankrant",
      count: 1,
    },
    {
      id: 407,
      label: "Manav",
      value: "manav",
      count: 0,
    },
    {
      id: 473,
      label: "Meenakari Collection",
      value: "meenakari-collection",
      count: 1,
    },
    {
      id: 442,
      label: "mens",
      value: "mens",
      count: 1,
    },
    {
      id: 82,
      label: "Multi Line",
      value: "multi-line",
      count: 106,
    },
    {
      id: 49,
      label: "Multiline",
      value: "multiline",
      count: 0,
    },
    {
      id: 28,
      label: "Multiline Ring",
      value: "multiline-ring",
      count: 0,
    },
    {
      id: 171,
      label: "Nakshatra",
      value: "nakshatra",
      count: 0,
    },
    {
      id: 119,
      label: "navgruha",
      value: "navgruha",
      count: 1,
    },
    {
      id: 408,
      label: "offers",
      value: "offers",
      count: 0,
    },
    {
      id: 99,
      label: "others",
      value: "others",
      count: 14,
    },
    {
      id: 19,
      label: "Oval",
      value: "oval",
      count: 0,
    },
    {
      id: 463,
      label: "Party ring",
      value: "party-ring",
      count: 0,
    },
    {
      id: 447,
      label: "Pavan",
      value: "pavan",
      count: 0,
    },
    {
      id: 443,
      label: "peacock",
      value: "peacock",
      count: 1,
    },
    {
      id: 445,
      label: "Pearl",
      value: "pearl",
      count: 2,
    },
    {
      id: 261,
      label: "Platinum and Gold Wedding Band",
      value: "platinum-and-gold-wedding-band",
      count: 0,
    },
    {
      id: 448,
      label: "Queen",
      value: "queen",
      count: 0,
    },
    {
      id: 41,
      label: "Round",
      value: "round",
      count: 0,
    },
    {
      id: 208,
      label: "Screw",
      value: "screw",
      count: 0,
    },
    {
      id: 424,
      label: "Single Diamond",
      value: "single-diamond-2",
      count: 7,
    },
    {
      id: 431,
      label: "Single Line",
      value: "single-line-2",
      count: 21,
    },
    {
      id: 427,
      label: "Single Stone",
      value: "single-stone",
      count: 4,
    },
    {
      id: 137,
      label: "single-diamond",
      value: "single-diamond",
      count: 0,
    },
    {
      id: 97,
      label: "single-line",
      value: "single-line",
      count: 0,
    },
    {
      id: 24,
      label: "Solitaire",
      value: "solitaire",
      count: 22,
    },
    {
      id: 421,
      label: "Solitaire Diamond",
      value: "solitaire-diamond-2",
      count: 21,
    },
    {
      id: 50,
      label: "Solitaire Ring",
      value: "solitaire-ring",
      count: 0,
    },
    {
      id: 136,
      label: "solitaire-diamond",
      value: "solitaire-diamond",
      count: 0,
    },
    {
      id: 449,
      label: "Sovereign",
      value: "sovereign",
      count: 0,
    },
    {
      id: 429,
      label: "Spiritual",
      value: "spiritual",
      count: 50,
    },
    {
      id: 84,
      label: "Square",
      value: "square",
      count: 8,
    },
    {
      id: 456,
      label: "Stud",
      value: "stud",
      count: 1,
    },
    {
      id: 142,
      label: "stylish",
      value: "stylish",
      count: 0,
    },
    {
      id: 418,
      label: "tags",
      value: "tags",
      count: 0,
    },
    {
      id: 211,
      label: "Tennis",
      value: "tennis",
      count: 0,
    },
    {
      id: 432,
      label: "Thin",
      value: "thin",
      count: 5,
    },
    {
      id: 78,
      label: "Tops",
      value: "tops",
      count: 227,
    },
    {
      id: 453,
      label: "Traditional",
      value: "traditional",
      count: 7,
    },
    {
      id: 86,
      label: "Triangle",
      value: "triangle",
      count: 8,
    },
    {
      id: 71,
      label: "Twist & Turns",
      value: "twist-turns",
      count: 808,
    },
    {
      id: 435,
      label: "U Shape",
      value: "u-shape-2",
      count: 0,
    },
    {
      id: 122,
      label: "u-shape",
      value: "u-shape",
      count: 0,
    },
    {
      id: 87,
      label: "Under 1 Ct",
      value: "under-1-ct",
      count: 2,
    },
    {
      id: 436,
      label: "V Shape",
      value: "v-shape-2",
      count: 0,
    },
    {
      id: 121,
      label: "v-shape",
      value: "v-shape",
      count: 0,
    },
    {
      id: 88,
      label: "Valentine",
      value: "valentine",
      count: 0,
    },
    {
      id: 385,
      label: "Valentine Collection",
      value: "valentine-collection",
      count: 0,
    },
    {
      id: 455,
      label: "Workwear",
      value: "workwear",
      count: 0,
    },
  ],
  diamond_wt: [
    {
      id: null,
      label: null,
      value: null,
      count: null,
    },
    {
      id: null,
      label: null,
      value: null,
      count: null,
    },
  ],
  gold_wt: [
    {
      id: null,
      label: null,
      value: null,
      count: null,
    },
    {
      id: null,
      label: null,
      value: null,
      count: null,
    },
  ],
  Gender: [
    {
      id: 478,
      label: "Female",
      value: "female",
      count: 0,
    },
    {
      id: 479,
      label: "Kids",
      value: "kids",
      count: 0,
    },
    {
      id: 477,
      label: "Male",
      value: "male",
      count: 0,
    },
    {
      id: 480,
      label: "Unisex",
      value: "unisex",
      count: 0,
    },
  ],
};
export default StaticDropdown;
