import { HelpOutline } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import React from "react";
import { isArray } from "../../helper";
import PopperTooltip from "./PopperTooltip";

function InfoTooltip({ title }) {
  return (
    <PopperTooltip points={isArray(title) ? title : [title]}>
      <HelpOutline
        fontSize="small"
        style={{ opacity: "0.5", height: "16px", width: "16px" }}
        color="text.secondary"
      />
    </PopperTooltip>
  );
}

export default InfoTooltip;
