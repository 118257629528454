import {
    AccessTimeOutlined,
    DescriptionOutlined,
    ExpandMoreOutlined,
    LocationOnOutlined,
} from "@mui/icons-material";
import {
    Timeline,
    TimelineConnector,
    TimelineContent,
    TimelineDot,
    TimelineItem,
    timelineItemClasses,
    TimelineSeparator,
} from "@mui/lab";
import {
    Box,
    Chip,
    Collapse,
    Divider,
    IconButton,
    Stack,
    Tooltip,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import React, { useMemo, useState } from "react";
import {
    formatDate,
    getDeviceIconFromUA,
    getPlatformIconFromUA,
    getRelativeTimeDifference,
    isArrayWithValues,
    isObjWithValues,
    mainSocketEvents,
    secondaryLabel,
    secondaryLabelSmall,
    validateNumber,
} from "../../../helper";
import { getEventType } from "./helper";

const Sessions = ({ allSessions }) => {
    const [sessionObject, setSessionObject] = useState({});
    const theme = useTheme()
    const isIpad = useMediaQuery(theme.breakpoints.down('md'))
    useMemo(() => {
        let sessionObj = {};
        if (isArrayWithValues(allSessions)) {
            for (const session of [...(allSessions || [])]?.reverse()) {
                let { type, time } = session || {};
                if (mainSocketEvents?.includes(type)) {
                    sessionObj = {
                        ...sessionObj,
                        [time]: {
                            mainSession: session,
                        },
                    };
                } else {
                    let keys = Object.keys(sessionObj || {});

                    let key = keys.at(-1);
                    // ?.[Object.keys(sessionObj || {})?.length - 1]
                    if (key)
                        sessionObj = {
                            ...sessionObj,
                            [key]: {
                                ...(sessionObj?.[key] || {}),
                                subEvents: [...(sessionObj?.[key]?.subEvents || []), session],
                            },
                        };
                }
            }
        }
        if (isArrayWithValues(allSessions) && !isObjWithValues(sessionObj)) {
            let dateWiseObj = {};
            for (const session of [...(allSessions || [])]) {
                let date = new Date(session?.time * 1000).getDate();
                dateWiseObj = {
                    ...dateWiseObj,
                    [date]: [...(dateWiseObj?.[date] || []), session],
                };
            }
            for (const dateKey of Object.keys(dateWiseObj)) {
                let key = dateWiseObj?.[dateKey]?.[0]?.time;
                sessionObj = {
                    ...sessionObj,
                    [key]: {
                        mainSession: dateWiseObj?.[dateKey]?.[0],
                        subEvents: dateWiseObj?.[dateKey] || [],
                    },
                };
            }

        }
        setSessionObject(sessionObj || {});


    }, [allSessions]);
    return (
        <Box p={4}>
            <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
            ></Stack>
            <Stack rowGap={2} mt={3}>
                {Object.keys(sessionObject || {})
                    ?.sort((a, b) => validateNumber(b) - validateNumber(a))
                    .map((key) => {
                        return (
                            <SessionsRow
                                isIpad={isIpad}
                                key={key}
                                keyValue={key}
                                sessionObject={sessionObject}
                            />
                        );
                    })}
            </Stack>
        </Box>
    );
};

export default Sessions;

const SessionsRow = ({ keyValue, sessionObject, isIpad }) => {
    const [viewMore, setViewMore] = useState(false);
    let key = keyValue;
    let particularSession = sessionObject?.[keyValue] || {};
    let timeObj = getRelativeTimeDifference(
        new Date(Number(particularSession?.mainSession?.time)),
        new Date(Number(particularSession?.mainSession?.end_time))
    );
    let sessionTime = particularSession?.mainSession?.time && particularSession?.mainSession?.end_time
    let { ua } = particularSession?.mainSession || {};
    let { location } = particularSession?.mainSession || {};
    let countryCode = location?.country;
    let { city, region, country, ip } = location || {};
    let url = `https://flagcdn.com/w40/${countryCode?.toLowerCase()}.png`;
    const userLoaction = city
    // `${city && `${city}`}`

    // console.log(particularSession?.mainSession);


    return (
        <>
            <Stack rowGap={4}>
                <Stack
                    direction={"row"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                >
                    <Stack
                        direction={"row"}
                        alignItems={"center"}
                        gap={2}
                        sx={{ cursor: "pointer" }}
                        flexWrap={"wrap"}
                        onClick={() => {
                            isArrayWithValues(particularSession?.subEvents) &&
                                setViewMore((pre) => !pre);
                        }}
                    >
                        <Typography variant="h6" fontSize={"16px"}>
                            {formatDate(new Date(Number(key) * 1000), "dd/mm/yyyy")}
                            {isArrayWithValues(particularSession?.subEvents) ? (
                                <IconButton
                                    sx={{
                                        py: 0,
                                        px: 0,
                                        ":hover": {
                                            background: "background",
                                        },
                                    }}
                                // onClick={() => setViewMore((pre) => !pre)}
                                >
                                    <ExpandMoreOutlined
                                        style={{
                                            transform: viewMore ? "rotate(180deg)" : "",
                                            transition: "transform 400ms ease",
                                            padding: 0,
                                        }}
                                    />
                                </IconButton>
                            ) : null}
                        </Typography>
                        {!isIpad ? <Stack direction={"row"} alignItems="center" gap={3}>
                            {sessionTime ? (
                                <Typography
                                    variant="h6"
                                    fontSize={"14px"}
                                    color={"text.secondary"}
                                    fontWeight={400}
                                    sx={{ display: "flex", alignItems: "center", gap: 1 }}
                                >
                                    <AccessTimeOutlined fontSize="small" /> {timeObj?.value}{" "}
                                    {timeObj?.label}
                                </Typography>
                            ) : null}
                            {city ? (
                                <Chip label={userLoaction} size="small" icon={<LocationOnOutlined
                                    color={"primary"}
                                    style={{ opacity: "0.7" }}
                                    fontSize="small"
                                />} />
                                // <Typography
                                //     variant="h6"
                                //     fontSize={"14px"}
                                //     color={"text.secondary"}
                                //     fontWeight={400}
                                //     sx={{ display: "flex", alignItems: "center", gap: 1 }}
                                // >

                                //     {}
                                // </Typography>
                            ) : null}

                            {ip &&

                                <Chip label={`IP: ${ip} `} size="small" />
                                // <Stack direction={"row"} alignItems={"center"} gap={"2px"}>
                                //     <Typography
                                //         variant="h6"
                                //         fontSize={"14px"}
                                //         // color={"text.secondary"}
                                //         fontWeight={800}
                                //         sx={{ display: "flex", alignItems: "center", gap: 1 }}
                                //     >IP:</Typography>
                                //     <Typography
                                //         variant="h6"
                                //         fontSize={"14px"}
                                //         color={"text.secondary"}
                                //         fontWeight={400}
                                //         sx={{ display: "flex", alignItems: "center", gap: 1 }}
                                //     >{ip}</Typography>
                                // </Stack>
                            }
                        </Stack> : <>




                        </>}


                        {/* {ua &&
                            getDeviceIconFromUA(ua || "", {
                                opacity: "0.7",
                                fontSize: "20px",
                            })}
                        {ua &&
                            getPlatformIconFromUA(ua || "", {
                                height: "20px",
                                width: "20px",
                            })}
                        {isObjWithValues(particularSession?.mainSession) ? (
                            <>
                                {countryCode ? (
                                    <img
                                        src={url}
                                        style={{
                                            borderRadius: "50%",
                                            height: "20px",
                                            width: "20px",
                                        }}
                                    />
                                ) : null}
                            </>
                        ) : null} */}
                    </Stack>


                    <Stack direction={"row"} alignItems={"center"} gap={4}>
                        {particularSession?.subEvents?.length && (
                            <Typography
                                variant="h6"
                                fontSize={"14px"}
                                color={"text.secondary"}
                                fontWeight={400}
                                sx={{ display: "flex", alignItems: "center", gap: 1 }}
                            >
                                <DescriptionOutlined fontSize="small" />{" "}
                                {particularSession?.subEvents?.length}
                            </Typography>
                        )}
                        {ua &&
                            getDeviceIconFromUA(ua || "", {
                                opacity: "0.7",
                                fontSize: "20px",
                            })}
                        {ua &&
                            getPlatformIconFromUA(ua || "", {
                                height: "20px",
                                width: "20px",
                            })}
                        {isObjWithValues(particularSession?.mainSession) ? (
                            <>
                                {countryCode ? (
                                    <img
                                        src={url}
                                        style={{
                                            // borderRadius: "50%",
                                            height: "16px", width: "25px",
                                            borderRadius: "10%"
                                        }}
                                    />
                                ) : null}
                            </>
                        ) : null}
                        {/* {validateNumber(timeObj?.value) ? (
                            <Typography
                                variant="h6"
                                fontSize={"14px"}
                                color={"text.secondary"}
                                fontWeight={400}
                                sx={{ display: "flex", alignItems: "center", gap: 1 }}
                            >
                                <AccessTimeOutlined fontSize="small" /> {timeObj?.value}{" "}
                                {timeObj?.label}
                            </Typography>
                        ) : null} */}
                    </Stack>
                </Stack>
                {isIpad && <Stack direction={"row"} alignItems="center" gap={"6px"}>
                    {validateNumber(timeObj?.value) ? (
                        <Typography
                            variant="h6"
                            fontSize={"14px"}
                            color={"text.secondary"}
                            fontWeight={400}
                            sx={{ display: "flex", alignItems: "center", gap: 1 }}
                        >
                            <AccessTimeOutlined fontSize="small" /> {timeObj?.value}{" "}
                            {timeObj?.label}
                        </Typography>
                    ) : null}
                    {city ? (
                        <Chip label={userLoaction} size="small" icon={<LocationOnOutlined
                            color={"primary"}
                            style={{ opacity: "0.7" }}
                            fontSize="small"
                        />} />
                        // <Typography
                        //     variant="h6"
                        //     fontSize={"14px"}
                        //     color={"text.secondary"}
                        //     fontWeight={400}
                        //     sx={{ display: "flex", alignItems: "center", gap: 1 }}
                        // >

                        //     {}
                        // </Typography>
                    ) : null}

                    {ip &&

                        <Chip label={`IP: ${ip} `} size="small" />
                        // <Stack direction={"row"} alignItems={"center"} gap={"2px"}>
                        //     <Typography
                        //         variant="h6"
                        //         fontSize={"14px"}
                        //         // color={"text.secondary"}
                        //         fontWeight={800}
                        //         sx={{ display: "flex", alignItems: "center", gap: 1 }}
                        //     >IP:</Typography>
                        //     <Typography
                        //         variant="h6"
                        //         fontSize={"14px"}
                        //         color={"text.secondary"}
                        //         fontWeight={400}
                        //         sx={{ display: "flex", alignItems: "center", gap: 1 }}
                        //     >{ip}</Typography>
                        // </Stack>
                    }
                </Stack>}
                <Collapse in={!viewMore}>
                    {!viewMore && (
                        <Stack
                            direction={"row"}
                            alignItems={"center"}
                            justifyContent="space-between"
                        >
                            <Stack direction={"row"} alignItems={"center"} gap={1}>
                                {getEventType(particularSession?.mainSession?.type)}
                                <Typography
                                    variant="h6"
                                    fontSize={"14px"}
                                    color={"main.primary"}
                                    fontWeight={400}
                                    sx={{ display: "flex", alignItems: "center", gap: 1 }}
                                >
                                    {particularSession?.mainSession?.event_name}
                                </Typography>
                            </Stack>
                            <Typography sx={{ ...secondaryLabelSmall, fontWeight: 700 }}>
                                {formatDate(
                                    new Date(particularSession?.mainSession?.time * 1000),
                                    "hh:mm am/pm"
                                )}
                            </Typography>
                        </Stack>
                    )}
                </Collapse>
                <Collapse in={viewMore}>
                    <Timeline
                        sx={{
                            [`& .${timelineItemClasses?.root}: before`]: {
                                flex: 0,
                                padding: 0,
                            },
                            padding: 0,
                        }}
                    >
                        <TimelineItem>
                            <TimelineSeparator>
                                <TimelineDot
                                    variant="outlined"
                                    sx={{ border: "0px solid", padding: 0 }}
                                >
                                    {getEventType(particularSession?.mainSession?.type)}
                                </TimelineDot>
                                <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent>
                                <Typography
                                    variant="h6"
                                    fontSize={"14px"}
                                    color={"main.primary"}
                                    fontWeight={400}
                                >
                                    {particularSession?.mainSession?.event_name}
                                </Typography>
                                <Typography sx={{ ...secondaryLabelSmall, fontWeight: 700 }}>
                                    {formatDate(
                                        new Date(particularSession?.mainSession?.time * 1000),
                                        "hh:mm am/pm"
                                    )}
                                </Typography>
                            </TimelineContent>
                        </TimelineItem>
                        {particularSession?.subEvents?.map((obj, index) => {
                            if (!obj?.event_name) return;
                            return (
                                <TimelineItem key={obj?.id}>
                                    <TimelineSeparator sx={{ ml: "6px" }}>
                                        <TimelineDot />
                                        {/* {getEventType(particularSession?.mainSession?.type)} */}
                                        {/* </TimelineDot > */}
                                        {index ===
                                            particularSession?.subEvents?.length - 1 ? null : (
                                            <TimelineConnector />
                                        )}
                                    </TimelineSeparator>
                                    <TimelineContent>
                                        <Typography
                                            variant="h6"
                                            color={"main.primary"}
                                            fontSize={"14px"}
                                            fontWeight={400}
                                        >
                                            {obj?.event_name}
                                        </Typography>
                                        <Typography
                                            sx={{ ...secondaryLabelSmall, fontWeight: 700 }}
                                        >
                                            {formatDate(new Date(obj?.time * 1000), "hh:mm am/pm")}
                                        </Typography>
                                    </TimelineContent>
                                </TimelineItem>
                            );
                        })}
                    </Timeline>
                </Collapse>
            </Stack>
            <Divider />
        </>
    );
};
