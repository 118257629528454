import { default as React, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import { visuallyHidden } from "@mui/utils";
import { isArray, isArrayWithValues, validateNumber } from "../../helper";
import { useDispatch, useSelector } from "react-redux";
import { getDigigoldUsers } from "../../redux/actions/crmActions";
import { Button, Skeleton, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ArrowBackIosNew } from "@mui/icons-material";

export default function OverdueUsers() {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [rows, setRows] = useState([]);
  const [defaultParams, setDefaultParams] = useState({
    page: 1,
    per_page: 75,
    sortby: "digigold",
  });
  const [params, setParams] = useState(defaultParams);
  const reduxDigiCustomers = useSelector(
    (state) => state.customers.allDigiCustomers
  );
  const fetchingDigiCustomers = useSelector(
    (state) => state.customers.fetchingDigiCustomers
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (!reduxDigiCustomers) dispatch(getDigigoldUsers(params));
  }, []);

  useEffect(() => {
    if (isArray(reduxDigiCustomers)) setRows(reduxDigiCustomers);
  }, [reduxDigiCustomers]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <Box sx={{ width: "100%" }}>
      <Button
        variant="text"
        startIcon={<ArrowBackIosNew />}
        onClick={() => navigate("/digigold")}
      >
        Digigold
      </Button>
      <Paper sx={{ width: "100%", mb: 2, mt: 2 }}>
        <EnhancedTableToolbar numSelected={selected.length} />
        <TableContainer>
          <Table aria-labelledby="tableTitle" size={dense ? "small" : "medium"}>
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {fetchingDigiCustomers && false && (
                <>
                  {Array(10)
                    .fill(null)
                    .map((item, index) => (
                      <TableRow
                        key={index}
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                          py: 2,
                          cursor: "pointer",
                        }}
                      >
                        {/* <TableCell padding="checkbox">
                          <IconButton>
                            <Skeleton
                              variant="rectangular"
                              sx={{
                                width: "18px",
                                height: "18px",
                                borderRadius: "3px",
                                ml: 1,
                              }}
                            />
                          </IconButton>
                        </TableCell> */}
                        <TableCell component="th" scope="row">
                          <Skeleton
                            variant="text"
                            sx={{ fontSize: "1rem", width: "100%" }}
                          />
                        </TableCell>

                        <TableCell align="left">
                          <Skeleton
                            variant="text"
                            sx={{
                              fontSize: "1rem",
                              width: "35%",
                              // marginLeft: "auto",
                            }}
                          />
                        </TableCell>
                        <TableCell align="right">
                          <Skeleton
                            variant="text"
                            sx={{
                              fontSize: "1rem",
                              width: "35%",
                              marginLeft: "auto",
                            }}
                          />
                        </TableCell>
                        <TableCell align="right">
                          <Skeleton
                            variant="text"
                            sx={{
                              fontSize: "1rem",
                              width: "35%",
                              marginLeft: "auto",
                            }}
                          />
                        </TableCell>
                        <TableCell align="right">
                          <Skeleton
                            variant="text"
                            sx={{
                              fontSize: "1rem",
                              width: "35%",
                              marginLeft: "auto",
                            }}
                          />
                        </TableCell>
                      </TableRow>
                      //   <TableRow key={index}>
                      //     <TableCell>
                      //       <Skeleton
                      //         variant="text"
                      //         sx={{ fontSize: "1rem", width: "50%" }}
                      //       />
                      //     </TableCell>
                      //     <TableCell>
                      //       <Skeleton
                      //         variant="text"
                      //         sx={{ fontSize: "1rem", width: "50%" }}
                      //       />
                      //     </TableCell>
                      //     <TableCell>
                      //       <Skeleton
                      //         variant="text"
                      //         sx={{ fontSize: "1rem", width: "50%" }}
                      //       />
                      //     </TableCell>
                      //     <TableCell>
                      //       <Skeleton
                      //         variant="text"
                      //         sx={{ fontSize: "1rem", width: "50%" }}
                      //       />
                      //     </TableCell>
                      //     <TableCell>
                      //       <Skeleton
                      //         variant="text"
                      //         sx={{ fontSize: "1rem", width: "50%" }}
                      //       />
                      //     </TableCell>
                      //   </TableRow>
                    ))}
                </>
              )}
              {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.sort(getComparator(order, orderBy)).slice() */}
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <CustomerRow
                      customer={row}
                      key={index}
                      navigate={navigate}
                    />
                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[25, 50]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      />
    </Box>
  );
}

const CustomerRow = ({ customer, navigate }) => {
  const [info, setInfo] = useState({});
  useEffect(() => {
    let { billing, meta_data, digigold_details } = customer;
    digigold_details = digigold_details || {};
    let obj = {
      ...customer,
      billing,
      digigold_details,
      phone: billing.phone,
      plans: digigold_details.plans,
      total: digigold_details.total,
      paid: digigold_details.paid,
      grams: digigold_details.grams,
    };
    if (isArrayWithValues(meta_data)) {
      let branchobj = meta_data.find((i) => i.key === "branch_from");
      if (branchobj) obj.branch = branchobj.value;
    }

    setInfo({ ...obj });
  }, [customer]);
  // const isItemSelected = isSelected(row.name);
  // const labelId = `enhanced-table-checkbox-${index}`;
  // let digigold_details = row.digigold_details || {};
  // let billing = row.billing || {};

  return (
    <TableRow
      hover
      onClick={(event) => navigate(`/digigold/users/${info.id}`)}
      // role="checkbox"
      // aria-checked={isItemSelected}
      tabIndex={-1}
      sx={{ cursor: "pointer" }}
      // key={row.name}
      // selected={isItemSelected}
    >
      {/* <TableCell padding="checkbox">
    <Checkbox
      color="primary"
      checked={isItemSelected}
      inputProps={{
        "aria-labelledby": labelId,
      }}
    />
  </TableCell> */}
      <TableCell
        component="th"
        // id={labelId}
        scope="row"
        // padding="none"
      >
        <Typography sx={{ fontSize: "13px", fontWeight: "600" }}>
          {info.first_name} {info.last_name}
        </Typography>
        <Typography sx={{ fontSize: "12px", color: "text.secondary" }}>
          {info.email}
        </Typography>
      </TableCell>
      <TableCell align="left">
        <Stack justifyContent="flex-start">
          <Typography>
            Plan: {info.plans ? Object.keys(info.plans).length : 0}
          </Typography>
          <Typography>Paid: {info.total || 0}</Typography>
          <Typography>
            Collected: {validateNumber(Number(info.grams).toFixed(3)) || 0} g
          </Typography>
        </Stack>
      </TableCell>
      <TableCell align="right">{info.fat}</TableCell>
      <TableCell
        align="right"
        sx={{
          fontSize: "13px",
          fontWeight: "500",
        }}
      >
        {info.phone}
      </TableCell>
      <TableCell align="right">{info.branch}</TableCell>
    </TableRow>
  );
};

function createData(name, calories, fat, carbs, protein) {
  return {
    name,
    calories,
    fat,
    carbs,
    protein,
  };
}

const rows = [
  createData("Cupcake", 305, 3.7, 67, 4.3),
  createData("Donut", 452, 25.0, 51, 4.9),
  createData("Eclair", 262, 16.0, 24, 6.0),
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
  createData("Gingerbread", 356, 16.0, 49, 3.9),
  createData("Honeycomb", 408, 3.2, 87, 6.5),
  createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
  createData("Jelly Bean", 375, 0.0, 94, 0.0),
  createData("KitKat", 518, 26.0, 65, 7.0),
  createData("Lollipop", 392, 0.2, 98, 0.0),
  createData("Marshmallow", 318, 0, 81, 2.0),
  createData("Nougat", 360, 19.0, 9, 37.0),
  createData("Oreo", 437, 18.0, 63, 4.0),
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "customer",
    numeric: false,
    disablePadding: false,
    label: "Customer",
  },
  {
    id: "summary",
    numeric: false,
    disablePadding: false,
    label: "Summary",
  },
  {
    id: "added_by",
    numeric: true,
    disablePadding: false,
    label: "Added by",
  },
  {
    id: "mobile",
    numeric: true,
    disablePadding: false,
    label: "Mobile",
  },
  {
    id: "branch",
    numeric: true,
    disablePadding: false,
    label: "Branch",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {/*
        <TableCell padding="checkbox">
           <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell> */}
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Digigold Customers
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};
