import {
  APPEND_MEDIA_LIBRARY,
  APPEND_PRODUCT_DATA,
  CREATE_QUOTATION,
  EMPTY_MEDIA_LIBRARY,
  REPLACE_MEDIA_LIBRARY,
  SET_PRODUCT_DATA,
  SET_PRODUCT_SETTINGS,
  SET_PRODUCT_TAXONOMIES,
} from "../actions/productActions";

const inititalState = {
  allProducts: [],
  media: [],
  productSettings: {},
};

const reducer = (state = inititalState, { type, payload }) => {
  switch (type) {
    case SET_PRODUCT_DATA:
      return { ...state, ...payload };
    case APPEND_PRODUCT_DATA:
      return { ...state, allProducts: state.allProducts.concat(payload) };
    case APPEND_MEDIA_LIBRARY:
      return { ...state, media: state.media.concat(payload) };
    case EMPTY_MEDIA_LIBRARY:
      return { ...state, media: [] };
    case REPLACE_MEDIA_LIBRARY:
      return { ...state, media: payload };
    case SET_PRODUCT_SETTINGS:
      return {
        ...state,
        productSettings: { ...payload, types: [...payload.types] },
      };
    case SET_PRODUCT_TAXONOMIES:
      return { ...state, admin_taxonomies: payload };
    case CREATE_QUOTATION:
      return {
        ...state,
        quotations: Array.isArray(state.quotations)
          ? state.quotations.unshift(payload)
          : [{ ...payload }],
      };
    default:
      return state;
  }
};
export default reducer;
