import { Box, Button, Divider, Stack, Typography } from '@mui/material'
import React, { useState } from 'react'
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import { formatServerValue, TabPanel } from '../../../helper';
import styled from 'styled-components';

const tabs = [
    { label: "Privacy Policy", value: "privacy_policy" },
    { label: "Return Policy", value: "return_policy" },
    { label: "Terms Conditions", value: "terms_conditions" },
]
const StaticPages = () => {
    const [selectedTab, setSelectedTab] = useState("privacy_policy")
    return (
        <Box sx={{ padding: 0 }}>
            <Stack sx={{ position: "sticky", top: "0", marginTop: "0", padding: "12px", backgroundColor: "#fff", zIndex: 11 }}>
                <Typography variant="h3" >Reviews</Typography>
                <Stack direction={"row"} sx={{ mt: 5 }}>
                    {tabs.map((i) => (
                        <Button
                            variant={"text"}
                            // startIcon={i.icon}
                            // variant={selectedTab !== i.value ? "outlined" : "contained"}
                            sx={{
                                // gap: "15px",
                                padding: "6px 20px",
                                backgroundColor:
                                    selectedTab === i.value ? "#e6e6e6" : "transparent",
                                color: "#000",
                                // color: selectedTab === i.value ? "primary.main" : "#000",
                                "&:hover": {
                                    backgroundColor:
                                        selectedTab === i.value ? "#e6e6e6" : "transparent",
                                },
                            }}
                            onClick={() => setSelectedTab(i.value)}
                        >
                            <Typography>{i.label}</Typography>
                        </Button>
                    ))}
                </Stack>
                <Divider sx={{ mt: 3 }} />
            </Stack>
            <Box sx={{ padding: "15px" }}>
                <TabPanel value={selectedTab} index={"privacy_policy"} >
                    <Typography variant='subtitle2' sx={{ mb: 2, fontSize: "15px" }}>Add {formatServerValue(selectedTab)} </Typography>
                    <QuillWrapper>
                        <ReactQuill
                            style={{ borderRadius: "8px" }}
                            theme="snow"
                            // value={description}
                            // onChange={setValue}
                            placeholder="Type something.."
                        // onChange={(e) => onEditProduct({ description: e })}
                        />
                    </QuillWrapper>
                </TabPanel>
            </Box>
        </Box>
    )
}

export default StaticPages

const QuillWrapper = styled.div`
  .ql-editor {
    min-height: calc(100vh - 320px);,
    border-radius: 6px;
  }
`;