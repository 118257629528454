import { ArrowBackIos } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import {
  formatDate,
  getRandomInt,
  isArrayWithValues,
  isObjWithValues,
  validateNumber,
} from "../../helper";
import { currencySymbols } from "../pricing/Gold";
import {
  CustomersTable,
  digigoldGlobalColors,
  DigiOrdersTable,
  SectionLabel,
} from "./Dashboard";

let planMetaKeys = [
  "digi_plan_type",
  "digi_plan_duration",
  "gold_kt",
  "plan_total_grams",
];

function PlanView({
  info,
  setPlanView,
  currency,
  customers,
  digiPlans,
  orders,
  setUserView,
}) {
  let [plan, setPlan] = useState({});
  useEffect(() => {
    if (!isObjWithValues(info)) return;
    let { meta_data } = info;
    let planMetaObj = {};
    if (isArrayWithValues(meta_data)) {
      for (let obj of meta_data)
        if (planMetaKeys.includes(obj.key)) planMetaObj[obj.key] = obj.value;
    }
    let obj = { ...info, ...planMetaObj };
    setPlan(obj);
  }, [info]);
  return (
    <Box>
      <Button startIcon={<ArrowBackIos />} onClick={() => setPlanView(false)}>
        {info.name}
      </Button>

      <Grid container spacing={3} sx={{ marginTop: "10px" }}>
        <Grid item xs={12} md={3}>
          <TotalCard
            title="Amount"
            value={0}
            values={[
              {
                title: "Amount/Month",
                value: plan?.price || 0,
                prefix: currencySymbols[currency],
              },
              //   {
              //     title: "In grams",
              //     value: 0,
              //     suffix: "g",
              //   },
            ]}
            // onClick={() => navigate("users")}
            // breakup={totalUsersBreakup}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TotalCard
            title="Total Customers"
            value={0}
            values={[
              {
                title: "Customers",
                value: plan?.totalUsers || 0,
                // prefix: currencySymbols[currency],
              },
              //   {
              //     title: "In grams",
              //     value: 0,
              //     suffix: "g",
              //   },
            ]}
            // onClick={() => navigate("users")}
            // breakup={totalUsersBreakup}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TotalCard
            title="Total expected collection"
            value={0}
            values={[
              {
                title: "In Amount",
                value: info?.totalExpected || 0,
                prefix: currencySymbols[currency],
              },
              {
                title: "Avg/Customer",
                value:
                  info?.totalExpected && info.totalUsers
                    ? Math.round(info?.totalExpected / info.totalUsers)
                    : 0,
                prefix: currencySymbols[currency],
                // suffix: "g",
              },
            ]}
            // onClick={() => navigate("users")}
            // breakup={totalUsersBreakup}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TotalCard
            title="Total collection"
            value={0}
            values={[
              {
                title: "Amount",
                value: info?.totalPaid || 0,
                prefix: currencySymbols[currency],
              },
              {
                title: "Avg/Customer",
                value:
                  info?.totalPaid && info.totalUsers
                    ? Math.round(info?.totalPaid / info.totalUsers)
                    : 0,
                prefix: currencySymbols[currency],
                // suffix: "g",
              },
            ]}
            // onClick={() => navigate("users")}
            // breakup={totalUsersBreakup}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TotalCard
            title="Total missed collection"
            value={0}
            values={[
              {
                title: "Amount",
                value: info?.totalMissed || 0,

                prefix: currencySymbols[currency],
              },
              {
                title: "Number",
                value: 0,
                // prefix: currencySymbols[currency],
                // suffix: "g",
              },
            ]}
            // onClick={() => navigate("users")}
            // breakup={totalUsersBreakup}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TotalCard
            title="Account closed"
            value={0}
            values={[
              {
                title: "Amount",
                value: 0,
                prefix: currencySymbols[currency],
              },
              {
                title: "Number",
                value: 0,
                // prefix: currencySymbols[currency],
                // suffix: "g",
              },
            ]}
            // onClick={() => navigate("users")}
            // breakup={totalUsersBreakup}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TotalCard
            title="Account closing this month"
            value={0}
            values={[
              {
                title: "Amount",
                value: 0,
                prefix: currencySymbols[currency],
              },
              {
                title: "Number",
                value: 0,
                // prefix: currencySymbols[currency],
                // suffix: "g",
              },
            ]}
            // onClick={() => navigate("users")}
            // breakup={totalUsersBreakup}
          />
        </Grid>
      </Grid>
      <Chart1 users={customers} currency={currency} id={plan.id} plan={plan} />

      <DigiOrdersTable
        orders={orders}
        customers={customers}
        setUserView={setUserView}
        plans={digiPlans}
        plan_id={plan.id}
      />
      <CustomersTable
        customers={customers}
        plans={digiPlans}
        currency={currency}
        setUserView={setUserView}
        id={plan.id}
      />
    </Box>
  );
}

export default PlanView;

const Chart1 = ({ users, currency, id, plan }) => {
  const [series, setSeries] = useState([]);

  useEffect(() => {
    if (!isArrayWithValues(users)) return;
    let seriesObj = {};
    let series = [];
    for (let user of users) {
      let { plans } = user;
      if (!isObjWithValues(plans)) continue;
      let allLedgers = [];
      for (let plan of Object.values(plans)) {
        let { ledger, product } = plan;
        if (id && id != product) continue;

        if (!isArrayWithValues(ledger)) continue;
        for (let ledgerObj of ledger) {
          let { total, date_paid } = ledgerObj;
          let paidDate = formatDate(new Date(date_paid), "mm-dd-yyyy");
          if (!seriesObj[paidDate]) seriesObj[paidDate] = 0;
          seriesObj[paidDate] += validateNumber(total);
        }
      }
      //   Object.values(plans).map((i) => {
      //     if (isArrayWithValues(i.ledger))
      //       allLedgers = allLedgers.concat(i.ledger);
      //   });
      //   let sortedLedger = allLedgers.sort(
      //     (a, b) =>
      //       new Date(a.date_paid).getTime() - new Date(b.date_paid).getTime()
      //   );
      //   if (sortedLedger?.[0]?.date_paid) {
      //     let paidDate = formatDate(
      //       new Date(sortedLedger?.[0]?.date_paid),
      //       "mm-dd-yyyy"
      //     );
      //     if (!seriesObj[paidDate]) seriesObj[paidDate] = 0;
      //     seriesObj[paidDate] += 1;
      //   }
    }
    for (let date in seriesObj) {
      series.push([new Date(date).getTime(), seriesObj[date]]);
    }
    series = series.sort(
      (a, b) => new Date(a[0]).getTime() - new Date(b[0]).getTime()
    );
    setSeries(series);
  }, [users, id]);

  return (
    <>
      <Card sx={{ borderRadius: "15px", mt: 4 }}>
        <CardContent>
          <SectionLabel sx={{ marginBottom: "14px" }}>
            Plan Collection
          </SectionLabel>
          <ReactApexChart
            options={chart1Options}
            series={[
              {
                data: series,
                name: `Total paid ${
                  currency ? `(${currencySymbols[currency]})` : ""
                }`,
              },
            ]}
            type="area"
            height={350}
          />
        </CardContent>
      </Card>
    </>
    // <Card sx={{ borderRadius: "15px" }}>
    //   <CardContent>
    //     <Stack
    //       direction={"row"}
    //       justifyContent="space-between"
    //       alignItems={"center"}
    //     >
    //       <Typography>Analytics</Typography>
    //       {/* <Button startIcon={<ArrowBackIos />} endIcon={<ArrowForwardIos />}>
    //                     Total
    //                 </Button> */}
    //     </Stack>
    //     <ReactApexChart
    //       options={options}
    //       series={series}
    //       type="bar"
    //       height={350}
    //     />
    //   </CardContent>
    // </Card>
  );
};

const chart1Options = {
  chart: {
    id: "area-datetime",
    type: "area",
    height: 350,
    zoom: {
      autoScaleYaxis: true,
    },
    toolbar: { show: false },
  },
  colors: digigoldGlobalColors,
  dataLabels: {
    enabled: false,
  },
  markers: {
    size: 0,
    style: "hollow",
  },
  yaxis: [
    {
      labels: {
        formatter: function (val) {
          return val.toFixed(0);
        },
      },
    },
  ],
  xaxis: {
    type: "datetime",
    // min: new Date("01 Mar 2012").getTime(),
    tickAmount: 6,
  },
  tooltip: {
    x: {
      format: "dd MMM yyyy",
    },
  },
  fill: {
    type: "gradient",
    gradient: {
      shadeIntensity: 1,
      opacityFrom: 0.7,
      opacityTo: 0.9,
      stops: [0, 100],
    },
  },
};

const TotalCard = ({
  title,
  value,
  values = [],
  suffix,
  backgroundColor,
  onClick,
  breakup,
}) => {
  return (
    <>
      <Card
        sx={{
          border: "0.5px solid #e5eef8",
          // height: "100%",
          backgroundColor: backgroundColor || "background.paper",
          cursor: "pointer",
        }}
        onClick={() => onClick && onClick()}
      >
        <CardContent sx={{ "&:last-child": { paddingBottom: "20px" } }}>
          <Stack
            direction="row"
            alignItems={"center"}
            justifyContent="space-between"
          >
            <Typography variant="h5" fontSize="16px" fontWeight={"500"}>
              {title}
            </Typography>
            {/* <Button
              variant="contained"
              size="small"
              color="primary"
              sx={{ minWidth: "50px" }}
            >
              <ArrowForward style={{ height: "17px", width: "17px" }} />
            </Button> */}
          </Stack>

          <Stack
            mt={3}
            alignItems="flex-end"
            direction={"row"}
            // justifyContent="space-around"
            flexWrap="wrap"
            spacing={7}
          >
            {values.map((obj) => {
              return (
                <Box>
                  <Typography
                    sx={{ fontSize: "12px", color: "text.secondary" }}
                  >
                    {obj.title}
                  </Typography>
                  <Stack direction="row" alignItems={"center"} sx={{ mt: 1 }}>
                    {obj.prefix ? (
                      <Typography
                        ml={1}
                        variant="h5"
                        fontSize="22px"
                        fontWeight={"500"}
                        mr={1}
                      >
                        {obj.prefix || ""}
                      </Typography>
                    ) : null}
                    <Typography variant="h5" fontSize="22px" fontWeight={"500"}>
                      {obj.value}
                    </Typography>
                    <Typography
                      ml={1}
                      variant="body2"
                      fontSize="16px"
                      color="text.secondary"
                    >
                      {obj.suffix || ""}
                    </Typography>
                  </Stack>
                </Box>
              );
            })}
          </Stack>
        </CardContent>
      </Card>
      {/* {isObjWithValues(breakup) && (
          <Card sx={{}}>
            <CardContent>
              {Object.keys(breakup).map((key) => {
                return (
                  <RenderLabelValue
                    value={breakup[key]}
                    label={formatServerValue(key)}
                  />
                );
              })}
            </CardContent>
          </Card>
        )} */}
    </>
  );
};
