import { Box, Link, Typography } from '@mui/material'
import React from 'react'
import { ListItem } from '../meta/MetaOverview'
const feature = [
    '👉Instant Invoice Generation',
    '👉Simple website and Mobile Integration',
    '👉Recurring Billing Configuration',
    '👉Dedicated Account manager',
    '👉Allows International Payment',
    '👉100+ Foreign Currency',
    '👉24/7 Customer Support',
]
const Overview = () => {
    return (
        <Box sx={{ p: 3, mb: 3 }}>
            <Typography variant="subtitle1" sx={{ fontSize: "16px", mb: 4 }}>
                PayU is a fintech business with headquarters in the Netherlands that provides online payment solutions to retailers. The business has operations in 17 nations 🌐
            </Typography>
            <Typography variant="subtitle1" sx={{ fontSize: "16px", mb: 4 }}>
                Due to its exceptional characteristics, it is included among India's payment gateways 🧩

            </Typography>
            <Typography variant="subtitle1" sx={{ fontSize: "16px", mb: 4 }}>
                The business offers more than 100 payment options, including buy now, pay later, EMI, UPI, cards, and online banking. Due to the availability of more than 100 international currencies, businesses can accept payments from overseas 🛫

            </Typography>
            <Typography variant="subtitle2" sx={{ fontSize: "16px", mb: 4 }}>Features of PayU</Typography>
            <ListItem arr={feature} />
            <Typography sx={{ fontSize: "16px", mt: 3 }}>
                📨 Send your queries <Link href="https://help.payu.in/" style={{ fontSize: "16px" }} >Contact Payu</Link>
            </Typography>


        </Box>
    )
}

export default Overview