import {
    Avatar,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    InputAdornment,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Skeleton,
    TextField,
    Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
    EmptyState,
    dialogStyle,
    getCustomerWidthFilter,
    getFullName,
    isArrayWithValues,
    isObjWithValues,
} from "../../helper";
import { SearchOutlined } from "@mui/icons-material";

const ViewCustomerList = ({ open, handleClose, heading, ids, params }) => {
    const [allCustomers, setAllCustomer] = useState([])
    const [customers, setCustomers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState("")
    useEffect(async () => {
        // let params = { custom_filter: [{ key: 'first_name', value: 'Manav', compare: 'LIKE' }] }
        if (isArrayWithValues(ids)) params.include = ids?.join(",");
        setLoading(true);
        if (isObjWithValues(params)) {
            console.log(params);
            let res = await getCustomerWidthFilter({
                params: {
                    ...params,
                    _felids: "first_name,last_name,meta_data"
                },

            });

            console.log(res);
            let customerRes = res?.data?.map((obj) => removeMetaData(obj)) || []
            setAllCustomer(customerRes)
            setCustomers(customerRes);
        }

        setLoading(false);
        return () => {
            setCustomers([]);
            setAllCustomer([])
        };
    }, [ids, params]);
    const onSearchChange = (e) => {
        setSearchTerm(e.target?.value)
        if (e.target.value) {
            setCustomers(allCustomers?.filter(custom => `${custom.first_name} ${custom.last_name}`?.includes(e.target?.value)) || [])
        } else {
            setCustomers(allCustomers)
        }

    }

    return (
        <Dialog
            open={open}
            onClose={() => handleClose && handleClose()}
            sx={{ ...dialogStyle }}
            fullWidth
            maxWidth="xs"
        >
            <DialogTitle id="alert-dialog-title">
                {heading || "Customers"}{" "}
                {customers?.length ? `(${customers?.length})` : null}
            </DialogTitle>
            <DialogContent>
                <List
                    sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
                >
                    <ListItem alignItems="flex-start" sx={{ position: "sticky", top: "0px", backgroundColor: "background.paper", zIndex: 1 }}>
                        <TextField
                            placeholder="Search"
                            fullWidth
                            size="small"
                            value={searchTerm}
                            onChange={onSearchChange}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end"><SearchOutlined /></InputAdornment>
                                ),
                            }}
                        />
                    </ListItem>
                    {loading ? (
                        new Array(7)?.fill("")?.map((str, index) => {
                            return (
                                <React.Fragment key={index}>
                                    <ListItem alignItems="flex-start">
                                        <ListItemAvatar>
                                            <Skeleton variant="circular" width={40} height={40} />
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={
                                                <>
                                                    <Skeleton
                                                        variant="text"
                                                        sx={{ fontSize: "1rem", width: "50%" }}
                                                    />
                                                </>
                                            }
                                            secondary={
                                                <React.Fragment>
                                                    <Skeleton
                                                        variant="text"
                                                        sx={{ fontSize: "0.7rem" }}
                                                    />
                                                </React.Fragment>
                                            }
                                        />
                                    </ListItem>
                                    <Divider variant="inset" component="li" />
                                </React.Fragment>
                            );
                        })
                    ) : isArrayWithValues(customers) ? (
                        customers?.sort((a, b) => getFullName(a)?.localeCompare(getFullName(b)))?.map((obj, index) => {
                            let name = getFullName(obj);
                            return (
                                <React.Fragment key={index} >
                                    <ListItem alignItems="flex-start">
                                        <ListItemAvatar>
                                            <Avatar alt={name} src="/static/images/avatar/1.jpg" />
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={name}
                                            secondary={
                                                <React.Fragment>
                                                    <Typography
                                                        sx={{ display: "inline" }}
                                                        component="span"
                                                        variant="body2"
                                                        color="text.primary"
                                                    >
                                                        {obj?.contact_type}
                                                    </Typography>
                                                    {obj?.contact_type ? " — " : ""}
                                                    {obj?.email}
                                                    {/* {" — I'll be in your neighborhood doing errands this…"} */}
                                                </React.Fragment>
                                            }
                                        />
                                    </ListItem>
                                    <Divider variant="inset" component="li" />
                                </React.Fragment>
                            );
                        })
                    ) : (
                        <EmptyState text={"No Customer Found"} />
                    )}
                    {/* {} */}
                </List>
            </DialogContent>
            <DialogActions>
                {/* <Button onClick={() => handleClose && handleClose()}>Disagree</Button> */}
                <Button
                    onClick={() => handleClose && handleClose()}
                    variant="outlined"
                    autoFocus
                >
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ViewCustomerList;

const removeMetaData = (customer) => {
    if (isArrayWithValues(customer?.meta_data)) {
        for (const metaObj of customer?.meta_data) {
            customer[metaObj?.key] = metaObj?.value;
        }
    }
    return customer;
};
