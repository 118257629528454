import { useState } from "react";
import { Typography, IconButton, CardContent } from "@mui/material";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import PropTypes from "prop-types";
import { botStyles } from "../styles/botStyles";
import DeleteAndSwap from "../../components/DeleteAndSwap";
import { uploadDataToAwsBucket } from "../utils/uploadDataToAwsBucket";
import { bytesToMegabytes } from "../utils/bytesToMegabytes";
import BounceLoader from "react-spinners/BounceLoader";
// import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";

const styles = botStyles;

const BotDocumentField = ({
  botData,
  handleButtonClick,
  setBotData,
  data,
  fileInputRefs,
  index,
}) => {
  // const docs = [{ uri: data.file}]
  const [uploadingFile, setUploadingFile] = useState(false);

  const handleOpenDocument = (e, url) => {
    window.open(url, "_blank");
  };

  const handleFileDocumentSelect = async (e, data) => {
    setUploadingFile(true);
    const uploadedFile = e.target.files[0];
    const documentSize = bytesToMegabytes(uploadedFile.size);

    if (documentSize > 100) {
      alert("Image size exceeds the 5MB limit.");
      setUploadingFile(false);
      return;
    }

    const id = data.id;
    // const docs = [{
    //   uri: window.URL.createObjectURL(uploadedFile),
    //   fileName: uploadedFile.name,
    // }]
    const formData = new FormData();
    formData.append("file", uploadedFile);
    const res = await uploadDataToAwsBucket({ formData });
    const documentURL = res.data.source_url;

    setBotData((documentFields) => {
      return documentFields.map((field) => {
        if (field.id === id) {
          return { ...field, file: documentURL };
        }
        return field;
      });
    });
    setUploadingFile(false);
  };

  return (
    <div key={data.id} style={{ marginBottom: "10px", display: "flex" }}>
      <DeleteAndSwap index={index} setBotData={setBotData} botData={botData} />

      {!data?.file ? (
        <CardContent style={styles.imageCard}>
          <input
            type="file"
            accept=".doc,.txt,.pdf,.csv,.docx,.xls,.xlsx"
            style={{ display: "none" }}
            ref={fileInputRefs.current[data.id]}
            onChange={(e) => handleFileDocumentSelect(e, data)}
          />

          {uploadingFile ? (
            <>
              <BounceLoader color="#36d7b7" />
              <Typography variant="span">Uploading, please wait...</Typography>
            </>
          ) : (
            <>
              <IconButton onClick={() => handleButtonClick(data.id)}>
                <FileUploadIcon style={{ fontSize: "50px" }} />
              </IconButton>
              <Typography variant="span">
                Upload Documents <br /> 
              </Typography>
            </>
          )}
        </CardContent>
      ) : (
        <CardContent style={styles.imageCard}>
          <div
            onClick={() => handleOpenDocument(data.file)}
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <embed src={data.file} width="300px" height="120px" />
          </div>
        </CardContent>
      )}
    </div>
  );
};

BotDocumentField.propTypes = {
  data: PropTypes.object.isRequired,
  botData: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  setBotData: PropTypes.func.isRequired,
  handleButtonClick: PropTypes.func.isRequired,
  fileInputRefs: PropTypes.object.isRequired,
};

export default BotDocumentField;
