import {
  Box,
  Checkbox,
  FormHelperText,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  Stack,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import { useState } from "react";
import axios from "axios";
import { getToken, getWebsite, isObjWithValues, } from "../../../../helper";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import Switch from "rc-switch";
import "rc-switch/assets/index.css";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { EDIT_SETTINGS } from "../../../../redux/actions/settingActions";
import { FormLabel } from "../../Integrations";
import { setGlobalToast } from "../../../../redux/actions/helperActions";
const CCAvenueForm = () => {
  const [formData, setFormData] = useState({
    enabled: "no",
    sandbox: "no",
    enable_currency_conversion: "no",
    iframemode: "no",
    title: "",
    description: "",
    merchant_id: "",
    working_key: "",
    access_code: "",
    // default_add1: "",
    // default_city: "",
    // default_state: "",
    // default_zip: "",
    // default_country: "",
    // default_phone: ""
  });

  const dispatch = useDispatch();
  const [visibility, setVisibility] = useState({
    working_key: false,
    access_code: false,
  });
  const [loading, setLoading] = useState(false);
  const woocommerceCcavenueSettings = useSelector(
    (state) => state.settings.woocommerceCcavenueSettings
  );
  const globalToast = useSelector((state) => state.helper.globalToast);
  const theme = useTheme();
  let backgroundColor = theme.palette.primary && theme.palette.primary.main;
  const isPhone = useMediaQuery(theme.breakpoints.down("sm"));
  console.log(woocommerceCcavenueSettings);
  useEffect(() => {
    if (isObjWithValues(woocommerceCcavenueSettings))
      setFormData({ ...woocommerceCcavenueSettings });
  }, [woocommerceCcavenueSettings]);

  const onFormEdit = (payload) => {
    setFormData((pre) => ({ ...pre, ...payload }));
  };
  const onUpdateForm = async () => {
    let website = getWebsite();
    let token = getToken();
    if (!website && !token) return false
    setLoading(true);
    let res = await axios({
      url: `${website}/wp-json/store/v1/settings/woocommerce_ccavenue_settings`,
      method: "PUT",
      headers: {
        Authorization: `Basic ${token}`,
      },
      data: {
        setting: "woocommerce_ccavenue_settings",
        data: formData,
      },
    });
    if (res.data.success) {
      dispatch(
        setGlobalToast({
          ...globalToast,
          show: true,
          message: "CCAvenue setting saved successfully",
          severity: "success",
        })
      );
      dispatch({
        type: EDIT_SETTINGS,
        payload: {
          woocommerceCashfree_settings: res.data.data,
        },
      });
    } else {
      dispatch(
        setGlobalToast({
          ...globalToast,
          show: true,
          message: "Error",
          severity: "error",
        })
      );
    }
    setLoading(false);
  };
  return (
    <Box sx={{ paddingLeft: "12px", marginBottom: "25px" }}>
      <Stack
        direction={"row"}
        alignItems="center"
        sx={{ gap: "14px", mb: 2 }}
        justifyContent="space-between"
      >
        <Stack direction={"row"} alignItems="center" sx={{ gap: "14px" }}>
          <FormLabel variant="h6">Enable /Disabled</FormLabel>
          <Switch
            onChange={(e) => onFormEdit({ enabled: e ? "yes" : "no" })}
            checked={formData.enabled === "yes"}
            style={
              formData.enabled === "yes"
                ? {
                  border: `1px solid ${backgroundColor}`,
                  backgroundColor,
                }
                : {}
            }
          />
        </Stack>
        <LoadingButton
          loading={loading}
          variant={"contained"}
          onClick={onUpdateForm}
          sx={{ mr: 4 }}
        >
          Update
        </LoadingButton>
      </Stack>
      <Stack spacing={7} sx={{ width: isPhone ? "100%" : "70%" }}>
        <Stack>
          <FormLabel variant="h6">Title</FormLabel>
          <TextField
            value={formData.title}
            onChange={(e) => onFormEdit({ title: e.target.value })}
            size="small"
            placeholder="Title"
          />
          <FormHelperText>
            This controls the title which the user sees during checkout
          </FormHelperText>
        </Stack>
        <Stack>
          <FormLabel variant="h6">Description</FormLabel>
          <TextField
            value={formData.description}
            onChange={(e) => onFormEdit({ description: e.target.value })}
            size="small"
            placeholder="Description"
            multiline
            rows={3}
          />
          <FormHelperText>
            This controls the description which the user sees during checkout
          </FormHelperText>
        </Stack>
        <Stack>
          <FormLabel variant="h6">Merchant id</FormLabel>
          <TextField
            value={formData.merchant_id}
            onChange={(e) => onFormEdit({ merchant_id: e.target.value })}
            size="small"
            placeholder="Title"
          />
          <FormHelperText>
            Given to Merchant by CCAvenue
          </FormHelperText>
        </Stack>
        <Stack>
          <FormLabel variant="h6">Working key</FormLabel>
          <TextField
            type={visibility.working_key ? "text" : "password"}
            value={formData.working_key}
            onChange={(e) => onFormEdit({ working_key: e.target.value })}
            size="small"
            placeholder="Key ID"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    // aria-label="toggle password visibility"
                    onClick={() =>
                      setVisibility((pre) => ({
                        ...pre,
                        working_key: !visibility.working_key,
                      }))
                    }
                    // onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {visibility.working_key ? (
                      <VisibilityOff />
                    ) : (
                      <Visibility />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <FormHelperText>
            This id(USER ID) available at "Generate Working Key" of "Settings
            and Options at CCAvenue."
          </FormHelperText>
        </Stack>
        <Stack>
          <FormLabel variant="h6">Access code</FormLabel>
          <TextField
            type={visibility.access_code ? "text" : "password"}
            value={formData.access_code}
            onChange={(e) => onFormEdit({ access_code: e.target.value })}
            size="small"
            placeholder="Key Secret"
            // helperText=""
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    // aria-label="toggle password visibility"
                    onClick={() =>
                      setVisibility((pre) => ({
                        ...pre,
                        access_code: !visibility.access_code,
                      }))
                    }
                    // onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {visibility.access_code ? (
                      <VisibilityOff />
                    ) : (
                      <Visibility />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <FormHelperText>Given to Merchant by CCAvenue</FormHelperText>
        </Stack>
        <Stack>
          <Stack
            direction={"row"}
            alignItems="center"
            justifyContent={"space-between"}
            sx={{ gap: "14px" }}
          >
            <FormLabel variant="h6">Enable iframe </FormLabel>
            <Switch
              onChange={(e) => onFormEdit({ iframemode: e ? "yes" : "no" })}
              checked={formData.iframemode === "yes"}
              style={
                formData.iframemode === "yes"
                  ? {
                    border: `1px solid ${backgroundColor}`,
                    backgroundColor,
                  }
                  : {}
              }
            />
          </Stack>
          <FormHelperText>
            {" "}
            If you do not want customer to redirect on CCAvenue site and do the
            payment from checkout page only.
          </FormHelperText>
        </Stack>

        <Stack>
          <Stack
            direction={"row"}
            alignItems="center"
            justifyContent={"space-between"}
            sx={{ gap: "14px" }}
          >
            <FormLabel variant="h6">Sandbox</FormLabel>
            <Switch
              onChange={(e) => onFormEdit({ sandbox: e ? "yes" : "no" })}
              checked={formData.sandbox === "yes"}
              style={
                formData.sandbox === "yes"
                  ? {
                    border: `1px solid ${backgroundColor}`,
                    backgroundColor,
                  }
                  : {}
              }
            />
          </Stack>
          <FormHelperText>
            CCAvenue sandbox can be used to test payments.
          </FormHelperText>
        </Stack>
      </Stack>
    </Box>
  );
};

export default CCAvenueForm;
