import { Button, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { quickRepliesStyle } from "../utils/chatbotFieldsStyles";
import { useDispatch } from "react-redux";
import { saveFlow } from "./SaveFlow";
import * as actions from "../../../../../../redux/actions/flowsActions";
import { format } from "date-fns";
import { useSelector } from "react-redux";

const styles = quickRepliesStyle;

const BotQuickReply = ({ message, data }) => {
  const dispatch = useDispatch();
  const prevNode = useSelector((state) => state.flow?.chatbotMessageResponse);

  const sendTime = (currTime) => {
    const formattedTime = format(currTime, "HH:mm");
    return formattedTime;
  };

  const normalizeURL = (url) => {
    if (url.startsWith("http://") || url.startsWith("https://")) {
      return url;
    } else {
      if (!url.startsWith("www.")) {
        return "http://www." + url;
      } else {
        return "http://" + url;
      }
    }
  };

  const handleClick = async (button) => {
    const newMessage = {
      id: Date.now(),
      text: button.title,
      isUser: true,
      time: sendTime(Date.now()),
    };

    if (button.type === "open_url") {
      const url = normalizeURL(button.url);
      window.open(url, "_blank");
    }

    dispatch({ type: actions.TEST_BOT_ADD_MESSAGES, payload: newMessage });
    await saveFlow({
      flow_data: data,
      user_response: button.title,
      prev_node: prevNode,
    });
  };

  return (
    <div style={styles.container}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          margin: "4px",
        }}
      >
        <Typography style={styles.textField}>{message?.description}</Typography>
      </div>
      <div style={styles.buttonDiv}>
        {message?.buttons.map((button) => (
          <Button
            key={button.id}
            variant="contained"
            style={styles.button}
            onClick={() => handleClick(button)}
          >
            {button?.title}
          </Button>
        ))}
      </div>
    </div>
  );
};

BotQuickReply.propTypes = {
  message: PropTypes.object.isRequired,
};

export default BotQuickReply;
