import { Typography } from "@mui/material"
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { questionStyles } from "../styles/questionStyles";

const styles = questionStyles;

const AddQuestion = ({ handleAddQuestion }) => {
    return ( 
        <Typography variant="span" onClick={handleAddQuestion}>
        <Typography variant="h2" sx={styles.footerTextBox}>
          <Typography variant="span" sx={styles.footerText}>
            <AddOutlinedIcon sx={styles.footerIcon} />
            Add next question
          </Typography>
        </Typography>
      </Typography>
     );
}
 
export default AddQuestion;