const unformatChatUser = ({ user }) => {
  let { meta_data, ...obj } = user || {};
  obj = { ...obj, ...(meta_data || {}) };
  return obj;
};

const formatChatUser = (user) => {
  let {
    // first_name,
    // last_name,
    // email,
    // mobile,
    // dialcode_mobile,
    // dialcode_whatsapp,
    // whatsapp,
    branch,
    gender,
    ...otherUser
  } = user;
  let meta_data = { gender, branch };
  return { ...otherUser, ...(meta_data || {}) };
};

export { unformatChatUser, formatChatUser };
