import {
  Box,
  Checkbox,
  FormHelperText,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import { useState } from "react";
import axios from "axios";
import { getToken, getWebsite, isObjWithValues, } from "../../../../helper";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import Switch from "rc-switch";
import "rc-switch/assets/index.css";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { EDIT_SETTINGS } from "../../../../redux/actions/settingActions";
import { FormLabel } from "../../Integrations";
import { setGlobalToast } from "../../../../redux/actions/helperActions";
const PayUForm = () => {
  const woocommerce_checkout_page_id = useSelector(
    (state) => state.settings.woocommerce_checkout_page_id
  );
  const [formData, setFormData] = useState({
    enabled: "no",
    description: "",
    pum_key: "",
    pum_salt: "",
    gateway_module: "",
    redirect_page_id: woocommerce_checkout_page_id
  });
  const dispatch = useDispatch();
  const [visibility, setVisibility] = useState({
    pum_key: false,
    pum_salt: false,
  });
  const [loading, setLoading] = useState(false);
  const woocommercePayumboltSettings = useSelector(
    (state) => state.settings.woocommercePayumboltSettings
  );
  const globalToast = useSelector((state) => state.helper.globalToast);
  const theme = useTheme();
  let backgroundColor = theme.palette.primary && theme.palette.primary.main;
  const isPhone = useMediaQuery(theme.breakpoints.down("sm"));
  console.log(woocommercePayumboltSettings);
  useEffect(() => {
    if (isObjWithValues(woocommercePayumboltSettings))
      setFormData({ ...woocommercePayumboltSettings });
  }, [woocommercePayumboltSettings]);

  const onFormEdit = (payload) => {
    setFormData((pre) => ({ ...pre, ...payload }));
  };
  const onUpdateForm = async () => {
    let website = getWebsite();
    let token = getToken();
    if (!website && !token) return false
    setLoading(true);
    let res = await axios({
      url: `${website}/wp-json/store/v1/settings/woocommerce_payumbolt_settings`,
      method: "PUT",
      headers: {
        Authorization: `Basic ${token}`,
      },
      data: {
        setting: "woocommerce_payumbolt_settings",
        data: formData,
      },
    });
    if (res.data.success) {
      dispatch(setGlobalToast({ ...globalToast, show: true, message: "PayU setting saved successfully", severity: "success" }))
      dispatch({
        type: EDIT_SETTINGS,
        payload: {
          woocommerceCashfree_settings: res.data.data,
        },
      });
    } else {
      dispatch(setGlobalToast({ ...globalToast, show: true, message: "Intregration Failed! Please try again.", severity: "error" }))
    }
    setLoading(false);
  };
  return (
    <Box sx={{ paddingLeft: "12px", paddingBottom: "25px" }}>
      <Stack
        direction={"row"}
        alignItems="center"
        sx={{ gap: "14px", mb: 2 }}
        justifyContent="space-between"
      >
        <Stack direction={"row"} alignItems="center" sx={{ gap: "14px" }}>
          <FormLabel variant="h6">Enable /Disabled</FormLabel>
          <Switch
            onChange={(e) => onFormEdit({ enabled: e ? "yes" : "no" })}
            checked={formData.enabled === "yes"}
            style={
              formData.enabled === "yes"
                ? {
                  border: `1px solid ${backgroundColor}`,
                  backgroundColor,
                }
                : {}
            }
          />
        </Stack>
        <LoadingButton
          loading={loading}
          variant={"contained"}
          onClick={onUpdateForm}
          sx={{ mr: 4 }}
        >
          Update
        </LoadingButton>
      </Stack>
      <Stack spacing={7} sx={{ width: isPhone ? "100%" : "70%" }}>
        {/* <Stack>
          <FormLabel variant="h6">Title</FormLabel>
          <TextField
            value={formData.title}
            onChange={(e) => onFormEdit({ title: e.target.value })}
            size="small"
            placeholder="Title"
            helperText=""
          />
          <FormHelperText>This controls the title which the user sees during checkout</FormHelperText>
        </Stack> */}
        <Stack>
          <FormLabel variant="h6">Description</FormLabel>
          <TextField
            value={formData.description}
            onChange={(e) => onFormEdit({ description: e.target.value })}
            size="small"
            placeholder="Description"
            helperText=""
            multiline
            rows={3}
          />
          <FormHelperText>This controls the description which the user sees during checkout</FormHelperText>
        </Stack>
        <Stack>
          <FormLabel variant="h6">Gateway Key</FormLabel>
          <TextField
            type={visibility.pum_key ? "text" : "password"}
            value={formData.pum_key}
            onChange={(e) => onFormEdit({ pum_key: e.target.value })}
            size="small"
            placeholder="Key ID"
            helperText=""
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    // aria-label="toggle password visibility"
                    onClick={() =>
                      setVisibility((pre) => ({
                        ...pre,
                        pum_key: !visibility.pum_key,
                      }))
                    }
                    // onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {visibility.pum_key ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <FormHelperText>PayUmoney merchant key.</FormHelperText>
        </Stack>
        <Stack>
          <FormLabel variant="h6">Gateway Salt</FormLabel>
          <TextField
            type={visibility.pum_salt ? "text" : "password"}
            value={formData.pum_salt}
            onChange={(e) => onFormEdit({ pum_salt: e.target.value })}
            size="small"
            placeholder="Key Secret"
            // helperText=""
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    // aria-label="toggle password visibility"
                    onClick={() =>
                      setVisibility((pre) => ({
                        ...pre,
                        pum_salt: !visibility.pum_salt,
                      }))
                    }
                    // onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {visibility.pum_salt ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <FormHelperText>PayUmoney merchant salt.</FormHelperText>
        </Stack>
        <Stack>
          <FormLabel variant="h6">Gateway Mode</FormLabel>
          <Select size="small" value={formData.gateway_module} onChange={(e) => onFormEdit({ gateway_module: e.target.value })}>
            <MenuItem value="sandbox">Sandbox</MenuItem>
            <MenuItem value="production">Production</MenuItem>
          </Select>
          <FormHelperText>Mode of gateway subscription.</FormHelperText>
        </Stack>
      </Stack>
    </Box>
  );
};

export default PayUForm;
