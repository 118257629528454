import { Box, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { isObjWithValues, secondaryLabel, secondaryLabelSmall } from "../../../helper";

export const StoryReply = ({ url, isMobile, isIpad, style = {} }) => {
    const [error, setError] = useState(false);
    const [onVideoError, setOnVideoError] = useState(false);
    return (

        <Box
            sx={style ? style : {
                borderRadius: "20px",
                width: isMobile ? "50vw" : isIpad ? "40vw" : "10vw",
                // mx: 3
            }}
        >

            {onVideoError ? (
                <Stack direction={"row"} alignContent={"center"} gap={3}>
                    <Box
                        sx={{ backgroundColor: "#808080", width: "2px", height: "40px" }}
                    ></Box>
                    <Stack rowGap={1}>
                        <Typography>Story Unavailable</Typography>
                        <Typography sx={{ ...secondaryLabelSmall }}>
                            This story is unavailable
                        </Typography>
                    </Stack>
                </Stack>
            ) : error ? (
                <video
                    style={isObjWithValues(style) ? style : {
                        width: isMobile ? "50vw" : isIpad ? "40vw" : "10vw",
                        // width: "30vw",
                        // maxWidth: "calc(10vw-44px)",
                        // width: "calc(10vw-44px)",
                        // height: "100px",
                        // height: "400px",
                        // objectFit: "contain",
                        borderRadius: "20px",
                        ...style
                        // objectPosition: "50% 100%",
                    }}
                    controls
                >
                    <source
                        onError={() => {
                            setOnVideoError(true);
                        }}
                        src={url}
                        type={"video/mp4"}
                    />
                    {/* <source src="movie.ogg" type="video/ogg" /> */}
                    Your browser does not support the video tag.
                </video>
            ) : (
                <a download={true}>

                    <img
                        src={url}
                        onError={(e) => {
                            setError(true);
                            // console.log(e.target);
                        }}
                        style={isObjWithValues(style) ? style : {
                            width: isMobile ? "50vw" : isIpad ? "40vw" : "10vw",
                            // width: "30vw",
                            // maxWidth: "calc(10vw-44px)",
                            // width: "calc(10vw-44px)",
                            // height: "100px",
                            // height: "400px",
                            objectFit: "contain",
                            borderRadius: "20px",
                            ...style
                            // objectPosition: "50% 100%",
                        }}
                    />
                </a>
            )}
            {/* <StoryReply url={storyReply} isMobile={isMobile} isIpad={isIpad} /> */}
        </Box>


    );
};
