import {
  Box,
  Button,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  TabPanel,
  isArrayWithValues,
  isObjWithValues,
  primaryLabel,
  secondaryLabelSmall,
} from "../../../helper";

let MetalTabs = {
  gold: { label: "Gold", value: "metal" },
  silver: { label: "Silver", value: "metal" },
  platinium: { label: "Platinum", value: "metal" },
};

const allTabs1 = [
  // { label: "Gold", value: "gold" },
  { label: "Diamond", value: "diamond" },
  { label: "Gemstone", value: "gemstone" },
  { label: "Other", value: "other" },
];
const allTabs2 = [
  { label: "Metal", value: "metal" },
  { label: "Diamond", value: "diamond" },
  { label: "Gemstone", value: "gemstone" },
  { label: "Other", value: "other" },
];
function ProductCardBreakup({ product }) {
  const [selectedTab, setSelectedTab] = useState("gold");
  const [tabs, setTabs] = useState([]);
  useEffect(() => {
    if (!isObjWithValues(product)) return;
    let allTabs = [];
    let selectedTab = "";
    let {
      metal_types,
      metal_breakup_rows,
      diamond_rows,
      gemstone_rows,
      other_rows,
    } = product;
    if (isArrayWithValues(metal_types) && metal_types?.length > 1) {
      allTabs = allTabs2;
      // selectedTab = allTabs2[0].value;
    } else {
      let tabs = [...allTabs1];
      if (isObjWithValues(metal_breakup_rows)) {
        let metal = Object.keys(metal_breakup_rows)?.[0];
        if (MetalTabs?.[metal]) {
          tabs.unshift(MetalTabs?.[metal]);
        }
      }
      allTabs = tabs;
      // selectedTab = tabs[0]?.value;
    }
    if (!isObjWithValues(metal_breakup_rows))
      allTabs = allTabs.filter((i) => i.value !== "metal");
    if (!isArrayWithValues(diamond_rows))
      allTabs = allTabs.filter((i) => i.value !== "diamond");
    if (!isArrayWithValues(gemstone_rows))
      allTabs = allTabs.filter((i) => i.value !== "gemstone");
    if (!isArrayWithValues(other_rows))
      allTabs = allTabs.filter((i) => i.value !== "others");
    setTabs(allTabs);
    setSelectedTab(allTabs?.[0]?.value);
  }, [product]);

  return (
    <Box>
      <Stack direction="row" alignItems={"center"} spacing={2}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={selectedTab}
            onChange={(e, i) => setSelectedTab(i)}
            aria-label="basic tabs example"
          >
            {tabs.map((i) => {
              return <Tab label={i.label} value={i.value} sx={{ pb: 1 }} />;
            })}
          </Tabs>
        </Box>
      </Stack>
      <TabPanel value={selectedTab} index="metal">
        <MetalTabPanel product={product} />
      </TabPanel>
      <TabPanel value={selectedTab} index="diamond">
        <DiamondTabPanel product={product} />
      </TabPanel>
      <TabPanel value={selectedTab} index="gemstone">
        <GemstoneTabPanel product={product} />
      </TabPanel>
      <TabPanel value={selectedTab} index="other">
        <OthersTabPanel product={product} />
      </TabPanel>
    </Box>
  );
}

export default ProductCardBreakup;

const MetalTabPanel = ({ product }) => {
  let [rows, setRows] = useState([]);
  useEffect(() => {
    if (!isObjWithValues(product)) return;
    let { metal_breakup_rows } = product;
    if (isObjWithValues(metal_breakup_rows))
      setRows(Object.values(metal_breakup_rows));
  }, [product]);
  return (
    <TableContainer>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            {rows?.length > 2 && (
              <TableCell sx={{ ...secondaryLabelSmall, pb: 1 }}>
                Metal
              </TableCell>
            )}
            <TableCell
              sx={{ ...secondaryLabelSmall, pb: 1 }}
              align={rows.length > 2 ? "right" : "left"}
            >
              Purity
            </TableCell>
            <TableCell
              sx={{ ...secondaryLabelSmall, pb: 1, whiteSpace: "nowrap" }}
              align="right"
            >
              Gross Wt
            </TableCell>
            <TableCell
              sx={{ ...secondaryLabelSmall, pb: 1, whiteSpace: "nowrap" }}
              align="right"
            >
              Net Wt
            </TableCell>
            <TableCell sx={{ ...secondaryLabelSmall, pb: 1 }} align="right">
              Rate
            </TableCell>
            <TableCell sx={{ ...secondaryLabelSmall, pb: 1 }} align="right">
              Amt
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody sx={{ rowGap: "4px" }}>
          {rows.map((row) => (
            <>
              <TableRow
                key={row.metal}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  border: row.metal?.includes("Labour") ? 1 : 0,
                  borderTop:
                    row.metal?.includes("Total") ||
                    row.metal?.includes("Labour")
                      ? 1
                      : 0,
                  borderRadius: "6px",
                  borderColor: "#e8e8e8",
                  "& td": { border: 0, py: 2 },
                  "& th": { border: 0 },
                  py: 1,
                  my: 1,
                  ...primaryLabel,
                }}
              >
                {rows.length > 2 && (
                  <TableCell
                    sx={{
                      ...(row.metal?.includes("Total") ? primaryLabel : {}),
                    }}
                    scope="row"
                  >
                    {row.metal}
                  </TableCell>
                )}
                <TableCell
                  sx={{
                    ...(row.metal?.includes("Total") ? primaryLabel : {}),
                  }}
                  align={rows.length > 2 ? "right" : "left"}
                >
                  {row.purity} {row?.purity_suffix || ""}
                </TableCell>
                <TableCell
                  sx={{
                    ...(row.metal?.includes("Total") ? primaryLabel : {}),
                  }}
                  align="right"
                >
                  {row.gross} {row?.gross_suffix || ""}
                </TableCell>
                <TableCell
                  sx={{
                    ...(row.metal?.includes("Total") ? primaryLabel : {}),
                  }}
                  align="right"
                >
                  {row.net} {row?.net_suffix || ""}
                </TableCell>
                <TableCell
                  sx={{
                    ...(row.metal?.includes("Total") ? primaryLabel : {}),
                    whiteSpace: "nowrap",
                  }}
                  align="right"
                >
                  {row?.rate_prefix || ""} {row.rate}
                </TableCell>
                <TableCell
                  sx={{
                    ...(row.metal?.includes("Total") ? primaryLabel : {}),
                    whiteSpace: "nowrap",
                  }}
                  align="right"
                >
                  {row?.amount_prefix || ""} {row.amount}
                </TableCell>
              </TableRow>
            </>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
const DiamondTabPanel = ({ product }) => {
  let [rows, setRows] = useState([]);
  useEffect(() => {
    if (!isObjWithValues(product)) return;
    let { diamond_rows } = product;
    if (isArrayWithValues(diamond_rows)) setRows(diamond_rows);
  }, [product]);
  return (
    <TableContainer>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell sx={{ ...secondaryLabelSmall, pb: 1 }} align={"left"}>
              Purity
            </TableCell>
            <TableCell
              sx={{ ...secondaryLabelSmall, pb: 1, whiteSpace: "nowrap" }}
              align="right"
            >
              Shape
            </TableCell>
            <TableCell
              sx={{ ...secondaryLabelSmall, pb: 1, whiteSpace: "nowrap" }}
              align="right"
            >
              Size
            </TableCell>
            <TableCell sx={{ ...secondaryLabelSmall, pb: 1 }} align="right">
              Weight
            </TableCell>
            <TableCell sx={{ ...secondaryLabelSmall, pb: 1 }} align="right">
              Amt
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody sx={{ rowGap: "4px" }}>
          {rows.map((row, index) => {
            let isTotal =
              row.metal?.includes("Total") || row.purity?.includes("Total");
            return (
              <>
                <TableRow
                  key={row.metal}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    // border: row.metal?.includes("Labour") ? 1 : 0,
                    // borderTop:
                    //   row.metal?.includes("Total") ||
                    //   row.metal?.includes("Labour")
                    //     ? 1
                    //     : 0,
                    // borderRadius: "6px",
                    // borderColor: "#e8e8e8",
                    // "& td": { border: 0, py: 2 },
                    // "& th": { border: 0 },
                    py: 1,
                    my: 1,
                    ...primaryLabel,
                  }}
                >
                  {rows.length > 2 && (
                    <TableCell
                      sx={{
                        ...(isTotal ? primaryLabel : {}),
                      }}
                      scope="row"
                    >
                      {row.metal}
                    </TableCell>
                  )}
                  <TableCell
                    sx={{
                      ...(isTotal ? primaryLabel : {}),
                    }}
                    scope="row"
                  >
                    {row.purity}
                  </TableCell>
                  <TableCell
                    sx={{
                      ...(isTotal ? primaryLabel : {}),
                    }}
                    align="right"
                  >
                    {row.shape}
                  </TableCell>
                  <TableCell
                    sx={{
                      ...(isTotal ? primaryLabel : {}),
                    }}
                    align="right"
                  >
                    {row.size}
                  </TableCell>
                  <TableCell
                    sx={{
                      ...(isTotal ? primaryLabel : {}),
                      whiteSpace: "nowrap",
                    }}
                    align="right"
                  >
                    {row?.weight || ""}

                    <Typography sx={{ ...secondaryLabelSmall, mt: 0.5 }}>
                      {row?.amount_prefix || ""}{" "}
                      {product?.priceBreakup?.diamondRates?.[index + 1]} per cts
                    </Typography>
                  </TableCell>
                  <TableCell
                    sx={{
                      ...(isTotal ? primaryLabel : {}),
                      whiteSpace: "nowrap",
                    }}
                    align="right"
                  >
                    {row?.amount_prefix || ""} {row.amount}
                  </TableCell>
                </TableRow>
              </>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
const GemstoneTabPanel = ({ product }) => {
  let [rows, setRows] = useState([]);
  useEffect(() => {
    if (!isObjWithValues(product)) return;
    let { gemstone_rows } = product;
    if (isArrayWithValues(gemstone_rows)) setRows(gemstone_rows);
  }, [product]);
  return (
    <TableContainer>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell sx={{ ...secondaryLabelSmall, pb: 1 }} align={"left"}>
              Purity
            </TableCell>
            <TableCell
              sx={{ ...secondaryLabelSmall, pb: 1, whiteSpace: "nowrap" }}
              align="right"
            >
              Shape
            </TableCell>
            <TableCell
              sx={{ ...secondaryLabelSmall, pb: 1, whiteSpace: "nowrap" }}
              align="right"
            >
              Size
            </TableCell>
            <TableCell sx={{ ...secondaryLabelSmall, pb: 1 }} align="right">
              Weight
            </TableCell>
            <TableCell sx={{ ...secondaryLabelSmall, pb: 1 }} align="right">
              Amt
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody sx={{ rowGap: "4px" }}>
          {rows.map((row, index) => (
            <>
              <TableRow
                key={row.metal}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  // border: row.metal?.includes("Labour") ? 1 : 0,
                  // borderTop:
                  //   row.metal?.includes("Total") ||
                  //   row.metal?.includes("Labour")
                  //     ? 1
                  //     : 0,
                  // borderRadius: "6px",
                  // borderColor: "#e8e8e8",
                  // "& td": { border: 0, py: 2 },
                  // "& th": { border: 0 },
                  py: 1,
                  my: 1,
                  ...primaryLabel,
                }}
              >
                {rows.length > 2 && (
                  <TableCell
                    sx={{
                      ...(row.metal?.includes("Total") ||
                      row.purity?.includes("Total")
                        ? primaryLabel
                        : {}),
                    }}
                    scope="row"
                  >
                    {row.metal}
                  </TableCell>
                )}
                <TableCell
                  sx={{
                    ...(row.metal?.includes("Total") ||
                    row.purity?.includes("Total")
                      ? primaryLabel
                      : {}),
                  }}
                  scope="row"
                >
                  {row.purity}
                </TableCell>
                <TableCell
                  sx={{
                    ...(row.metal?.includes("Total") ||
                    row.purity?.includes("Total")
                      ? primaryLabel
                      : {}),
                  }}
                  align="right"
                >
                  {row.shape}
                </TableCell>
                <TableCell
                  sx={{
                    ...(row.metal?.includes("Total") ||
                    row.purity?.includes("Total")
                      ? primaryLabel
                      : {}),
                  }}
                  align="right"
                >
                  {row.size}
                </TableCell>
                <TableCell
                  sx={{
                    ...(row.metal?.includes("Total") ||
                    row.purity?.includes("Total")
                      ? primaryLabel
                      : {}),
                    whiteSpace: "nowrap",
                  }}
                  align="right"
                >
                  {row?.weight || ""}

                  <Typography sx={{ ...secondaryLabelSmall, mt: 0.5 }}>
                    {row?.amount_prefix || ""}{" "}
                    {product?.priceBreakup?.gemstoneRates?.[index + 1]} per cts
                  </Typography>
                </TableCell>
                <TableCell
                  sx={{
                    ...(row.metal?.includes("Total") ||
                    row.purity?.includes("Total")
                      ? primaryLabel
                      : {}),
                    whiteSpace: "nowrap",
                  }}
                  align="right"
                >
                  {row?.amount_prefix || ""} {row.amount}
                </TableCell>
              </TableRow>
            </>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
const OthersTabPanel = ({ product }) => {
  let [rows, setRows] = useState([]);
  useEffect(() => {
    if (!isObjWithValues(product)) return;
    let { other_rows } = product;
    if (isArrayWithValues(other_rows)) setRows(other_rows);
    console.log(other_rows, "<<< other_rows");
  }, [product]);
  return (
    <TableContainer>
      <Table aria-label="simple table">
        {/* <TableHead>
          <TableRow>
            <TableCell sx={{ ...secondaryLabelSmall, pb: 1 }} align={"left"}>
              Purity
            </TableCell>
            <TableCell
              sx={{ ...secondaryLabelSmall, pb: 1, whiteSpace: "nowrap" }}
              align="right"
            >
              Shape
            </TableCell>
            <TableCell
              sx={{ ...secondaryLabelSmall, pb: 1, whiteSpace: "nowrap" }}
              align="right"
            >
              Size
            </TableCell>
            <TableCell sx={{ ...secondaryLabelSmall, pb: 1 }} align="right">
              Weight
            </TableCell>
            <TableCell sx={{ ...secondaryLabelSmall, pb: 1 }} align="right">
              Amt
            </TableCell>
          </TableRow>
        </TableHead> */}
        <TableBody sx={{ rowGap: "4px", mt: 2 }}>
          {rows.map((row) => (
            <>
              <TableRow
                key={row.title}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  border: row.title?.includes("Total") ? 0 : 1,
                  // borderTop:
                  //   row.metal?.includes("Total") ||
                  //   row.metal?.includes("Labour")
                  //     ? 1
                  //     : 0,
                  borderRadius: "6px",
                  borderColor: "#e8e8e8",
                  "& td": { border: 0, py: 2 },
                  "& th": { border: 0 },
                  py: 1,
                  my: 1,
                  ...primaryLabel,
                }}
              >
                <TableCell
                  sx={{
                    ...(row.title?.includes("Total") ? primaryLabel : {}),
                  }}
                  scope="row"
                >
                  {row.title}
                </TableCell>
                <TableCell
                  sx={{
                    ...(row.title?.includes("Total") ? primaryLabel : {}),
                  }}
                  align="right"
                >
                  {/* {row.shape} */}
                </TableCell>
                <TableCell
                  sx={{
                    ...(row.title?.includes("Total") ? primaryLabel : {}),
                    whiteSpace: "nowrap",
                  }}
                  align="right"
                >
                  {row?.amount_prefix || ""} {row.amount}
                </TableCell>
              </TableRow>
            </>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
