import { Box, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { ShopifyImport } from "../../../../../Svgs";
import { LoadingButton } from "@mui/lab";
import { useDispatch, useSelector } from "react-redux";
import { setGlobalToast } from "../../../../../redux/actions/helperActions";
import {
  fetchIPInfo,
  getRandomString,
  getToken,
  getWebsite,
  isArrayWithValues,
  settingsEndpoint,
  websocketApiCall,
} from "../../../../../helper";
import { getIp } from "../../../../../App";
import { useEffect } from "react";
import { editSettings } from "../../../../../redux/actions/settingActions";
import { isObjWithValues } from "jwero-javascript-utils";
import { UAParser } from "ua-parser-js";

export default function AllProducts() {
  const dispatch = useDispatch();
  const [importingState, setImportingState] = useState({});
  const [syncHistory, setSyncHistory] = useState({});

  const reduxShopifySyncHistory = useSelector(
    (state) => state.settings.shopifyHistory
  );

  function isOneHourOlder(firstTimestamp, secondTimestamp) {
    // Parse the timestamps into Date objects
    const firstDate = new Date(firstTimestamp);
    const secondDate = new Date(secondTimestamp);

    // Get the time difference in milliseconds
    const timeDifference = secondDate - firstDate;

    // Convert the time difference to hours
    const hoursDifference = timeDifference / (1000 * 60 * 60);

    // Check if the first date is at least 1 hour older
    setImportingState((pre) => ({
      ...pre,
      loading: hoursDifference <= 1 && syncHistory?.pending,
      showRetry: syncHistory?.pending,
    }));
  }

  useEffect(() => {
    if (isObjWithValues(reduxShopifySyncHistory)) {
      const keys = Object?.keys(reduxShopifySyncHistory);

      let lastSyncHistory = reduxShopifySyncHistory?.[keys[0]]; // Initialize with the first item

      keys.forEach((key) => {
        if (
          new Date(reduxShopifySyncHistory?.[key]?.last_updated) >
            new Date(lastSyncHistory.last_updated) &&
          reduxShopifySyncHistory?.[key]?.type === "product"
        ) {
          lastSyncHistory = reduxShopifySyncHistory?.[key];
        }
      });

      setSyncHistory(lastSyncHistory);
      isOneHourOlder(lastSyncHistory?.last_updated, new Date());
    }

    return () => {};
  }, []);

  const getSyncHistory = async () => {
    try {
      let res = await settingsEndpoint({
        endpoint: `shopify_sync_history`,
      });

      dispatch(
        editSettings({
          shopifyHistory: res,
        })
      );
    } catch (e) {
      console.log(e);
    } finally {
    }
  };

  useEffect(() => {
    getSyncHistory();

    return () => {};
  }, []);

  const onImporting = async () => {
    try {
      let token = getToken();
      let website = getWebsite();
      let historyObj = {
        id: getRandomString(8),
        start: Math.floor(Date.now() / 1000),
        ip: await getIp(),
        ua: new UAParser().getUA(),
        import_info: {},
        // ip:
        // imported: 0,
        // products: [],
      };
      let location = await fetchIPInfo(historyObj.ip);
      if (isObjWithValues(location)) {
        let { city, region, postal, country } = location;
        historyObj.location = {
          city,
          state: region,
          postcode: postal,
          country,
        };
      }

      setImportingState((pre) => ({ ...pre, loading: true }));
      const res = await websocketApiCall({
        method: "POST",
        endpoint: "shopify/sync/products",
        headers: {
          token: token,
        },
        data: {
          website: website,
          history: {
            ...historyObj,
            type: "product",
          },
        },
      });

      if (res?.success) {
        dispatch(
          setGlobalToast({
            show: true,
            message: res?.data?.message,
            severity: "success",
          })
        );
      }

      console.log(res);
    } catch (error) {
      dispatch(
        setGlobalToast({
          show: true,
          message: "Request failed, please try again",
          severity: "success",
        })
      );
    } finally {
      // setImportingState((pre) => ({ ...pre, loading: false }));
    }
  };

  console.log(syncHistory);

  return (
    <Box sx={{ px: 2, mt: 3 }}>
      <Stack alignItems={"center"} justifyContent={"center"} rowGap={3} gap={3}>
        <ShopifyImport height={300} width={300} />
        <Typography
          variant="h6"
          color={"text.secondary"}
          sx={{ width: "40%", textAlign: "center", lineHeight: 1.6 }}
        >
          Import your products from your Shopify store to Jwero
        </Typography>
        {importingState?.loading && (
          <Typography
            variant="h10"
            color={"text.secondary"}
            sx={{ width: "40%", textAlign: "center", lineHeight: 1.6 }}
          >
            Product syncing is in progress
          </Typography>
        )}
        <LoadingButton
          loading={importingState?.loading}
          size="large"
          sx={{ mt: 3 }}
          variant="contained"
          onClick={onImporting}
        >
          Import Products
        </LoadingButton>
      </Stack>
    </Box>
  );
}
