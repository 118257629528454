import { Add, ArrowForward, Store, Storefront } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  formatDate,
  getRandomRangeNumbers,
  isArrayWithValues,
  isObjWithValues,
  sectionLabel,
} from "../../../helper";
import { fetchRecentCustomers } from "../../../redux/actions/crmActions";
import {
  CustomersIcon,
  InstagramSvg,
  Messenger,
  UserInfoSvg,
  Whatsapp,
} from "../../../Svgs";
import { NoTasks } from "../../chat/ChatHelper";

function RecentCustomers({}) {
  // const recentCustomers =
  //   useSelector((state) => state.customers.recentCustomers) || [];
  const [recentCustomers, setRecentCustomers] = useState([]);
  const fetchingRecentCustomers = useSelector(
    (state) => state.customers.fetchingRecentCustomers
  );

  const dashboardDetails = useSelector(
    (state) => state.settings.dashboardDetails
  );
  const fetchingDashboardDetails = useSelector(
    (state) => state.settings.fetchingDashboardDetails
  );

  useEffect(() => {
    if (!isObjWithValues(dashboardDetails)) return;
    let { contacts } = dashboardDetails;
    if (isArrayWithValues(contacts?.recent))
      setRecentCustomers(contacts.recent.slice(0, 6));
  }, [dashboardDetails]);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  // useEffect(() => {
  //   if (!isArrayWithValues(recentCustomers)) dispatch(fetchRecentCustomers());
  // }, []);
  return (
    <Card sx={{ borderRadius: "15px", height: "100%" }}>
      <CardContent>
        <Typography sx={{ ...sectionLabel }}>New Customers</Typography>
        {fetchingDashboardDetails ? (
          <>
            {new Array(5).fill("").map((str, index) => (
              <LoadingRecentCustomer key={index} />
            ))}
          </>
        ) : null}
        {!fetchingDashboardDetails && !isArrayWithValues(recentCustomers) ? (
          <>
            <Box sx={{ margin: "auto", height: "100%" }}>
              <NoTasks
                text={
                  <Box sx={{ textAlign: "center" }}>
                    <Typography>You have no contacts added in CRM.</Typography>
                    <Button
                      variant="outlined"
                      sx={{ mt: 3 }}
                      size="small"
                      startIcon={<Add />}
                      onClick={() => navigate("/crm")}
                    >
                      Add Customer
                    </Button>
                  </Box>
                }
                icon={
                  <CustomersIcon style={{ height: "70px", width: "70px" }} />
                }
              />
            </Box>
          </>
        ) : null}
        {recentCustomers.map((customer) => (
          <CustomerCard customer={customer} />
        ))}
      </CardContent>
    </Card>
  );
}

export default RecentCustomers;

const CustomerCard = ({ customer }) => {
  const [customerInfo, setCustomerInfo] = useState(customer);
  let fullName = `${customer?.first_name || ""} ${customer?.last_name || ""}`;
  const navigate = useNavigate();
  useEffect(() => {
    if (!isObjWithValues(customer)) return;
    let { meta_data } = customer;
    let customerObj = { ...customer };

    const metaKeys = [
      "whatsapp",
      "profile_url",
      "whatsapp_id",
      "facebook_id",
      "instagram_id",
      "wc_last_active",
    ];
    if (isArrayWithValues(meta_data)) {
      for (let metaObj of meta_data) {
        if (metaKeys.includes(metaObj.key) && metaObj.value)
          customerObj[metaObj.key] = metaObj.value;
      }
    }
    setCustomerInfo(customerObj);
    // setCustomerInfoObj(customer)
  }, []);

  return (
    <Stack
      direction={"row"}
      sx={{ mt: 5, mb: 5 }}
      // sx={{ textAlign: "center", justifyContent: "center" }}
      gap={"8px"}
    >
      <Avatar src={customer?.profile_url} />
      <Stack sx={{ width: "-webkit-fill-available" }}>
        <Stack direction="row" alignItems={"center"}>
          <Typography fontSize={"16px"} fontWeight={"500"}>
            {fullName}
          </Typography>
          <Box sx={{ ml: 2 }}>
            {customerInfo?.facebook_id ? (
              <Messenger height={17} width={17} />
            ) : customerInfo?.instagram_id ? (
              <InstagramSvg height={17} width={17} />
            ) : customerInfo?.whatsapp_id ? (
              <Whatsapp height={17} width={17} />
            ) : (
              <Storefront style={{ height: "17px", width: "17px" }} />
            )}
          </Box>
        </Stack>
        <Stack
          direction={"row"}
          alignItems="center"
          justifyContent={"space-between"}
          sx={{ width: "80%" }}
        >
          <Typography sx={{ fontSize: "13px", color: "text.secondary" }}>
            Registered :{" "}
            {customerInfo?.registered
              ? formatDate(
                  new Date(customerInfo?.registered),
                  "dd:mm:yyyy hh:mm"
                )
              : ""}
          </Typography>
          {/* <Typography sx={{ fontSize: "13px", color: "text.secondary" }}>
            Last active :{" "}
            {customerInfo?.wc_last_active
              ? formatDate(
                  new Date(Number(customerInfo?.wc_last_active) * 1000),
                  "dd:mm:yyyy hh:mm"
                )
              : ""}
          </Typography> */}
        </Stack>
      </Stack>
      <IconButton onClick={() => navigate(`/crm/${customerInfo?.id}`)}>
        <ArrowForward />
      </IconButton>
    </Stack>
  );
};

const LoadingRecentCustomer = () => {
  return (
    <Stack
      direction={"row"}
      sx={{ mt: 5, mb: 5 }}
      // sx={{ textAlign: "center", justifyContent: "center" }}
      gap={"8px"}
    >
      <Skeleton variant="circular" width={40} height={40} />

      <Stack sx={{ width: "-webkit-fill-available" }}>
        <Skeleton variant="text" sx={{ fontSize: "1rem", width: "180px" }} />

        <Stack
          direction={"row"}
          alignItems="center"
          justifyContent={"space-between"}
          sx={{ width: "80%" }}
        >
          <Skeleton variant="text" sx={{ fontSize: "1rem", width: "100px" }} />
          <Skeleton variant="circular" width={25} height={25} />
        </Stack>
      </Stack>
    </Stack>
  );
};
