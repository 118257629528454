import { AddBoxOutlined, InventoryOutlined, People } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Grid,
  Popover,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import axios from "axios";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addSuppliersettings,
  getAxiosError,
  getRandomString,
  getStoreId,
  getSupplierInfo,
  getSupplierProductBatch,
  getToken,
  getWebsite,
  globalColors,
  isArrayWithValues,
  isObjWithValues,
  updateSupplierProductBatch,
  validateNumber,
} from "../../../helper";
import { setGlobalToast } from "../../../redux/actions/helperActions";
import { addUserActivity } from "../../../redux/actions/hubActions";
import { useTheme } from "@mui/system";
import { addDesignBankActivity } from "../../../redux/actions/supplierActions";
import { SET_PRODUCT_DATA } from "../../../redux/actions/productActions";
import { useEffect } from "react";
import { MaketoOrder, Person, Persons, ReadyStock } from "../../../Svgs";

const ProductSelectHeader = () => {
  const selectedProducts = useSelector(
    (state) => state.product.designBankSelectedProducts
  );
  const reduxSupplier =
    useSelector((state) => state.supplier.allSuppliers) || [];
  console.log(selectedProducts);
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [anchorEl1, setAnchorEl1] = React.useState(null);
  const [creating, setCreating] = useState(false);
  const [suppliers, setSuppliers] = useState({});
  const handlePopoverOpen1 = (event) => {
    setAnchorEl1(event.currentTarget);
  };
  const handlePopoverClose1 = () => {
    setAnchorEl1(null);
  };

  useEffect(() => {
    let supplerobj = {};
    for (let index = 0; index < selectedProducts?.length; index++) {
      const product = selectedProducts[index];
      let arr = supplerobj?.[product?.supplier_id] || [];
      supplerobj[product?.supplier_id] = [...arr, product?.id];
    }
    setSuppliers(supplerobj);
  }, [selectedProducts]);

  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const handlePopoverOpen2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };
  const handlePopoverClose2 = () => {
    setAnchorEl2(null);
  };
  const open2 = Boolean(anchorEl2);
  const [anchorEl3, setAnchorEl3] = React.useState(null);
  const handlePopoverOpen3 = (event) => {
    setAnchorEl3(event.currentTarget);
  };
  const handlePopoverClose3 = () => {
    setAnchorEl3(null);
  };
  const open3 = Boolean(anchorEl3);
  const open1 = Boolean(anchorEl1);
  const onAddInventory = async () => {
    let website = getWebsite();
    let token = getToken();
    let storeId = getStoreId();
    if (!website || !token) return;

    // let
    setCreating(true);
    let removeKeys = [
      "date_on_sale_from",
      "date_on_sale_from_gmt",
      "date_on_sale_to",
      "date_on_sale_to_gmt",
      "permalink",
      "date_created",
      "date_created_gmt",
      "date_modified",
      "date_modified_gmt",
      "id",
      "_links",
      "retailer_pull",
      "supplier_name",
      "supplier_id",
      "tax",
      "weight",
      "variations",
      "tax_status",
    ];
    // let supplierObj = {};
    /// create products in reatiler
    let createProdutsArr = selectedProducts?.map((obj) => {
      obj.meta_data.push({
        key: "pull_source",
        value: `${obj?.supplier_id}_${obj?.id}`,
      });
      obj.sku = `${obj.sku}_${getRandomString(4)}`;
      obj.stock_quantity = validateNumber(obj.stock_quantity);
      for (let keysindex = 0; keysindex < removeKeys.length; keysindex++) {
        const key = removeKeys[keysindex];
        delete obj[key];
      }
      obj.images = obj.images.map((image) => {
        delete image.id;
        image.meta_data = [
          {
            key: "pull_source",
            value: `${obj?.supplier_id}_${obj?.id}`,
          },
        ];
        return image;
      });
      obj.collections = obj.collections
        ?.filter((coll) => coll?.name?.trim())
        ?.map((obj) => obj?.name);
      obj.category = obj.categories
        ?.filter((cat) => cat?.name?.trim())
        ?.map((obj) => obj?.name);
      delete obj.categories;
      if (obj.subcategory)
        obj.subcategory = obj.subcategory
          ?.filter((cat) => cat?.name?.trim())
          ?.map((obj) => obj?.name);
      if (obj?.tags)
        obj.tags = obj?.tags
          ?.filter((cat) => cat?.name?.trim())
          ?.map((obj) => obj?.name);
      // delete obj.collections
      // delete obj.categories
      // delete obj.subcategory
      delete obj.tags;
      // delete obj.tags
      return obj;
    });

    try {
      let res = await axios({
        url: `${website}/wp-json/wc/v3/products/batch`,
        method: "POST",
        headers: {
          Authorization: `Basic ${token}`,
        },
        data: {
          create: createProdutsArr,
        },
      });
      let userActivitieArr = [];
      if (isArrayWithValues(res?.data?.create)) {
        console.log("ress>>", res?.data?.create);
        let supplerWiseObj = {};
        let createdProdcutArr = [];
        let reatilerActivityArry = {};
        for (let ProductObj of res?.data?.create) {
          if (ProductObj?.id) {
            for (const metaObj of ProductObj?.meta_data || []) {
              ProductObj[metaObj?.key] = metaObj?.value;
            }
            console.log(ProductObj?.pull_source);
            let supplierId = ProductObj?.pull_source?.split("_")?.[0];
            let SupplierProdcutId = ProductObj?.pull_source?.split("_")?.[1];
            ProductObj.SupplierProdcutId = SupplierProdcutId;
            createdProdcutArr.push(ProductObj);
            let oldArr = supplerWiseObj[supplierId] || [];
            supplerWiseObj[supplierId] = [...oldArr, ProductObj];
            let oldreatilerActivityArry =
              reatilerActivityArry[supplierId] || [];
            reatilerActivityArry[supplierId] = [
              ...oldreatilerActivityArry,
              { pulled: ProductObj?.id, source: SupplierProdcutId },
            ];
            userActivitieArr.push({
              product_name: ProductObj?.name,
              supplier_id: supplierId,
              supplier_name: getSupplierInfo(reduxSupplier, supplierId)?.name,
            });
          }
        }

        let id = `${Date.now()}_${getRandomString(6)}`;
        dispatch(
          addDesignBankActivity({
            [id]: {
              id: id,
              time: Date.now(),
              pulled: reatilerActivityArry,
            },
          })
        );
        dispatch(
          addUserActivity({
            event: "pull_products",
            event_info: {
              products: userActivitieArr,
            },
          })
        );
        dispatch(
          setGlobalToast({
            show: true,
            message: `Pulled products ${res?.data?.create?.length} successfully`,
            severity: "success",
          })
        );
        for await (const key of Object.keys(supplerWiseObj)) {
          let supplierId = key;
          let supplier = getSupplierInfo(reduxSupplier);
          try {
            let updateSupplierActivity = await addSuppliersettings(supplier, {
              [id]: {
                time: Date.now(),
                store_id: getStoreId(),
                pulled: supplerWiseObj?.[key].map((obj) => ({
                  id: obj?.SupplierProdcutId,
                })),
              },
            });
          } catch (error) {
            console.log(getAxiosError(error));
          }
        }
      }

      dispatch({
        type: SET_PRODUCT_DATA,

        payload: {
          designBankSelectedProducts: [],
        },
      });
    } catch (error) {
      console.log(getAxiosError(error));
    }
    setCreating(true);
    // return

    // let createProdutsArr = [];
    // let userActivitieArr = [];

    // //making  batch for create product and object for update supplier
    // for (let index = 0; index < selectedProducts.length; index++) {
    //   const product = selectedProducts[index];
    //   let supplier = getSupplierInfo(reduxSupplier, product?.supplier_id);
    //   let productObj = { ...product };
    //   let oldArr = supplierObj[product?.supplier_id] || [];
    //   supplierObj[product?.supplier_id] = [...oldArr, product];
    //   productObj.meta_data.push({
    //     key: "pull_source",
    //     value: `${productObj?.supplier_id}_${productObj?.id}`,
    //   });

    //   // setProducts((pre) =>
    //   //   pre.map((obj) =>
    //   //     obj.id === product?.id ? { ...obj, retailer_pull: [storeId] } : obj
    //   //   )
    //   // );
    //   userActivitieArr.push({
    //     product_name: product?.name,
    //     supplier_id: product?.supplier_id,
    //     supplier_name: supplier?.name,
    //   });
    //   productObj.images = productObj.images.map((obj) => {
    //     delete obj.id;
    //     return obj;
    //   });
    //   productObj.collections = productObj.collections?.map(obj => ({ id: obj?.id }))
    //   productObj.categories = productObj.categories?.map(obj => ({ id: obj?.id }))
    //   for (let keysindex = 0; keysindex < removeKeys.length; keysindex++) {
    //     const key = removeKeys[keysindex];
    //     delete productObj[key];
    //   }
    //   createProdutsArr.push(productObj);
    //   console.log(createProdutsArr);
    // }
    // // return
    // let notificationObj = {}
    // //save retailer basic info in supplier products - (in supplier)
    // for await (const supplier_id of Object.keys(supplierObj)) {
    //   //geting product

    //   let supplier = getSupplierInfo(reduxSupplier, supplier_id);
    //   let getproducts = await getSupplierProductBatch(
    //     supplier,
    //     supplierObj[supplier_id].map((obj) => obj?.id)
    //   );
    //   notificationObj = { [supplier_id]: supplierObj[supplier_id].map((obj) => ({ id: obj?.id })) }
    //   console.log(getproducts);
    //   let liveProduct = getproducts?.data || [];
    //   //find and upadate key
    //   let updateDataMatadata = liveProduct.map((pro) => {
    //     let { meta_data } = pro || {};
    //     let retailer_pull = meta_data.find(
    //       (obj) => obj.key === "retailer_pull"
    //     );
    //     if (isObjWithValues(retailer_pull)) {
    //       retailer_pull?.value?.push(storeId);
    //       return { id: pro?.id, meta_data: [retailer_pull] };
    //     } else {
    //       return {
    //         id: pro?.id,
    //         meta_data: [{ key: "retailer_pull", value: [storeId] }],
    //       };
    //     }
    //   });
    //   // console.log(updateDataMatadata);
    //   let updateSupplierProducts = await updateSupplierProductBatch(
    //     supplier,
    //     updateDataMatadata
    //   );

    //   // if()

    //   let updateSupplierActivity = await addSuppliersettings(supplier, {
    //     event: "pull_product",
    //     products: liveProduct.map((obj) => obj.id),
    //   });
    //   console.log(updateSupplierProducts, updateSupplierActivity);
    // }
    // console.log(supplierObj);
    // console.log(createProdutsArr);
    // //creating product
    // // return
    // try {
    //   let res = await axios({
    //     url: `${website}/wp-json/wc/v3/products/batch`,
    //     method: "POST",
    //     headers: {
    //       Authorization: `Basic ${token}`,
    //     },
    //     data: {
    //       create: createProdutsArr,
    //     },
    //   });
    //   console.log(res);
    //   if (isArrayWithValues(res?.data?.create)) {
    //     dispatch(
    //       setGlobalToast({
    //         show: true,
    //         message: `Pulled products  ${res?.data?.create
    //           .filter((obj) => obj.name)
    //           .map((obj) => obj?.name)
    //           .join(",")}  successfully`,
    //         severity: "success",
    //       })
    //     );
    //   }
    // } catch (error) {
    //   console.log(getAxiosError(error));
    // }
    // dispatch(
    //   addUserActivity({
    //     event: "pull_products",
    //     event_info: {
    //       products: userActivitieArr,
    //       // supplier_name :
    //       // name: itemToDelete?.title,
    //     },
    //   })
    // );
    setCreating(false);
    // setSelectedProducts([]);
  };

  // setSelectedProducts([]);

  return (
    <Box>
      {selectedProducts?.length ? (
        <>
          <Stack direction={"row"} alignItems="center" gap={"20px"}>
            <Stack direction={"row"} alignItems="center" gap={"10px"}>
              <Typography variant="subtitle1" color={"text.secondary"}>
                Selected :
              </Typography>
              <Stack
                direction={"row"}
                alignItems="center"
                gap={"5px"}
                sx={{ borderRadius: "12px" }}
              >
                <Stack
                  direction={"row"}
                  gap={"10px"}
                  sx={{
                    backgroundColor: globalColors[0],
                    color: "#fff",
                    py: 1.5,
                    px: 3,
                    borderTopLeftRadius: "5px",
                    borderBottomLeftRadius: "5px",
                    // height: "100%"
                  }}
                  // alignItems="center"
                  aria-owns={open3 ? "mouse-over-popover" : undefined}
                  aria-haspopup="true"
                  onMouseEnter={handlePopoverOpen3}
                  onMouseLeave={handlePopoverClose3}
                >
                  <Typography>
                    {Object.keys(suppliers || {})?.length}
                  </Typography>{" "}
                  <Persons style={{ height: "24px", width: "24px" }} />
                </Stack>
                <Stack
                  direction={"row"}
                  gap={"10px"}
                  sx={{
                    backgroundColor: globalColors[1],
                    color: "#fff",
                    py: 1.5,
                    px: 3,
                  }}
                  alignItems="center"
                  aria-owns={open1 ? "mouse-over-popover" : undefined}
                  aria-haspopup="true"
                  onMouseEnter={handlePopoverOpen1}
                  onMouseLeave={handlePopoverClose1}
                >
                  <Typography>
                    {
                      selectedProducts?.filter(
                        (obj) => obj?.stock_status === "instock"
                      )?.length
                    }
                  </Typography>{" "}
                  <ReadyStock />
                </Stack>
                <Stack
                  direction={"row"}
                  gap={"10px"}
                  sx={{
                    backgroundColor: globalColors[3],
                    color: "#fff",
                    py: 1.5,
                    px: 3,
                    borderTopRightRadius: "5px",
                    borderBottomRightRadius: "5px",
                  }}
                  alignItems="center"
                  aria-owns={open2 ? "mouse-over-popover" : undefined}
                  aria-haspopup="true"
                  onMouseEnter={handlePopoverOpen2}
                  onMouseLeave={handlePopoverClose2}
                >
                  <Typography>
                    {
                      selectedProducts?.filter(
                        (obj) => obj?.stock_status === "onbackorder"
                      )?.length
                    }
                  </Typography>{" "}
                  <MaketoOrder />
                </Stack>
              </Stack>
            </Stack>
            <LoadingButton
              loading={creating}
              variant="contained"
              onClick={onAddInventory}
            >
              Add {selectedProducts?.length} products
            </LoadingButton>
          </Stack>
          <Popover
            id="mouse-over-popover"
            sx={{
              pointerEvents: "none",
            }}
            open={open3}
            anchorEl={anchorEl3}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            onClose={handlePopoverClose3}
            disableRestoreFocus
          >
            <Stack sx={{ padding: "12px" }} rowGap={2}>
              <Typography variant="h6">Suppliers</Typography>
              <Typography>{Object.keys(suppliers || {})?.length} </Typography>
            </Stack>
          </Popover>
          <Popover
            id="mouse-over-popover"
            sx={{
              pointerEvents: "none",
            }}
            open={open1}
            anchorEl={anchorEl1}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            onClose={handlePopoverClose1}
            disableRestoreFocus
          >
            <Stack sx={{ padding: "12px" }}>
              <Typography>Make to order</Typography>
              <Typography>
                {
                  selectedProducts?.filter(
                    (obj) => obj?.stock_status === "instock"
                  )?.length
                }{" "}
                Selected
              </Typography>
            </Stack>
          </Popover>
          <Popover
            id="mouse-over-popover"
            sx={{
              pointerEvents: "none",
            }}
            open={open2}
            anchorEl={anchorEl2}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            onClose={handlePopoverClose2}
            disableRestoreFocus
          >
            <Stack sx={{ padding: "12px" }}>
              <Typography>Ready Stock</Typography>
              <Typography>
                {
                  selectedProducts?.filter(
                    (obj) => obj?.stock_status === "onbackorder"
                  )?.length
                }{" "}
                Selected
              </Typography>
            </Stack>
          </Popover>
        </>
      ) : null}
    </Box>
  );
};

export default ProductSelectHeader;
