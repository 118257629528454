import { Box, Stack, Tab, Tabs, Typography } from "@mui/material";
import React, { useState } from "react";
import { TabPanel, isDev, isOurWebsite } from "../../../../helper";
import { ExotelSvg } from "../../../../Svgs";
import Integration from "./Integration";
import Settings from "./Settings";
import Activities from "./Activities";
import { useSelector } from "react-redux";
import { ListItem } from "../meta/MetaOverview";
import Template from "./Template";

const Exotel = () => {
  const isAdmin = useSelector((state) => state.user.isAdmin);
  const [selectedTab, setSelectedTab] = useState("overview");

  const exotelIntegration = useSelector(
    (state) => state.settings.exotelIntegration
  );
  return (
    <Box sx={{ height: "100%", paddingRight: "15px" }}>
      <Stack
        sx={{
          position: "sticky",
          top: "0",
          backgroundColor: "#fff",
          zIndex: 13,
          margin: 0,
          padding: "12px",
        }}
      >
        <Stack
          direction="row"
          justifyContent={"space-between"}
          alignItems="center"
        >
          <Typography
            variant="h3"
            sx={{ display: "flex", alignItems: "center", gap: "10px" }}
          >
            {" "}
            <ExotelSvg width={50} /> Exotel
          </Typography>
        </Stack>
        <Tabs
          onChange={(e, newValue) => setSelectedTab(newValue)}
          value={selectedTab}
          sx={{ borderBottom: 1, borderColor: "divider" }}
        >
          <Tab label="Overview" value="overview" />
          <Tab label="How to Use" value="how_to_use" />
          <Tab label="Integration" value="integration" />
          {exotelIntegration?.api_key && [
            <Tab label="Settings" value="settings" />,
            isOurWebsite() && isDev() && (
              <Tab label="Temaplate" value="template" />
            ),
            <Tab label="Activities" value="activities" />,
          ]}
        </Tabs>
      </Stack>
      <TabPanel value={selectedTab} index={"overview"}>
        <Overview />
      </TabPanel>
      <TabPanel value={selectedTab} index={"how_to_use"}>
        <HowToUse />
      </TabPanel>
      <TabPanel value={selectedTab} index={"integration"}>
        <Integration />
      </TabPanel>
      <TabPanel value={selectedTab} index={"settings"}>
        <Settings />
      </TabPanel>
      <TabPanel value={selectedTab} index={"template"}>
        <Template />
      </TabPanel>
      <TabPanel value={selectedTab} index={"activities"}>
        <Activities />
      </TabPanel>
    </Box>
  );
};

export default Exotel;

let featureArray = [
  "✅ Voice Call - Inbound and Outbound",
  "✅ Call Recording",
  "✅ IVR - Interactive voice response(IVR)  allows businesses to interact with callers through a series of automated menus, often enabling the customer to self - serve rrency Sfor faster resolution.",
  "✅ Call Logs",
  "✅ Call Monitoring - A call center feature that lets managers listen in on agents’ calls in order to improve agent performance.",
  "✅ Prioritise Routing - Assign incoming calls call to specific operator based on the rule they match",
];

const Overview = () => {
  return (
    <Box p={5}>
      <Typography variant="subtitle1" sx={{ fontSize: "16px" }}>
        Exotel is a Cloud communication solution that offers effective and the
        best-in-class APIs for voice, messaging and other communication modes.
        The software helps users to build a secure connection with customers
        over SMS and voice.
      </Typography>
      <Box sx={{ mt: 4 }}>
        <Typography variant="h5">Features :</Typography>
        <ListItem arr={featureArray} />
        <Typography variant="h5" sx={{ mt: 6 }}>
          Jwero - Exotel Advantage{" "}
        </Typography>
        <ListItem
          arr={[
            "✅ Direct Dialing from CRM",
            "✅ Call Log in respective CRM account",
            "✅ Team member based call assign",
          ]}
        />
        {/* Jweo - Exotel Advantage
        Direct Dialing from CRM
        Call Log in respective CRM account
        Team member based call assign */}
      </Box>
    </Box>
  );
};
let contentArray = [
  "If 1st Team Member doesn't pick up the call, it will be passed on to 2nd",
  "If 2nd also doesn't pick up or deny the call, it will be passed to 3rd Team Member",
  `If 3rd also fails to pick-up or deny it, a message you can type, which will be played as voice (eg. "Sorry all our Team members are busy, try again later.........)  And the call will get disconnected)`,
  `2nd incoming call will be routed to 1st Team Member`,
];

const outgoingContentArray = [
  "Outgoing Call will be assigned to a user whose Dashboard is used to dial the call.",
  "The system will initiate an outgoing call,",
  "User’s phone rings, first.",
  "The user picks up the phone and then the recipient receives the call",
  "If the user hung up the call, (before either the recipient could pick up or answer it), it will get cancelled.",
  "The user needs to restart the process.  Outgoing calls don’t get passed to other priority users, as happens in case of incoming calls.",
];

const HowToUse = () => {
  return (
    <Box p={5}>
      <Typography variant="h5">Incoming Calls :</Typography>

      <ListItem arr={contentArray} bulletList={true} />
      <Box sx={{ mt: 8 }}>
        <Typography variant="h5">Outgoing Call :</Typography>
        <ListItem arr={outgoingContentArray} bulletList={true} />
        {/* Jweo - Exotel Advantage
        Direct Dialing from CRM
        Call Log in respective CRM account
        Team member based call assign */}
      </Box>
    </Box>
  );
};
