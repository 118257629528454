import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as actions from "../../../../../../redux/actions/flowsActions";
import { Popover, Box, Typography, TextField, CardMedia } from "@mui/material";
import { changeIconStyles } from "../styles/changeIconStyles";
import { bytesToMegabytes } from "../../sidebar-bot/utils/bytesToMegabytes";
import { uploadDataToAwsBucket } from "../../sidebar-bot/utils/uploadDataToAwsBucket";
import BounceLoader from "react-spinners/BounceLoader";

const styles = changeIconStyles;

const ChangeIconPopover = ({
  iconChangeAnchor,
  setIconChangeAnchor,
  setAnchorEl,
}) => {
  const dispatch = useDispatch();
  const botIcon = useSelector((state) => state.flow?.chatbotStyles)?.botIcon;
  const chatbotStyles = useSelector((state) => state.flow?.chatbotStyles);
  const [uploadingFile, setUploadingFile] = useState(false);
  const open = Boolean(iconChangeAnchor);
  const id = open ? "simple-popover" : undefined;

  const handleIconPopoverClose = () => {
    setIconChangeAnchor(null);
    setAnchorEl(null);
  };

  const handleIconSubmit = async (e) => {
    const uploadedIcon = e.target.files[0];
    setUploadingFile(true);

    const uploadedFile = e.target.files[0];
    const imageSize = bytesToMegabytes(uploadedFile.size);

    if (imageSize > 5) {
      alert("Image size exceeds the 5MB limit.");
      setUploadingFile(false);
      return;
    }
    const formData = new FormData();
    formData.append("file", uploadedFile);
    const res = await uploadDataToAwsBucket({ formData });

    const imageURL = res.data.source_url;
    dispatch({
      type: actions.EDIT_ELEMENT_IN_REDUX,
      payload: {
        chatbotStyles: {
          ...chatbotStyles,
          botIcon: imageURL,
        },
      },
    });
    setUploadingFile(false);
  };

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={iconChangeAnchor}
      onClose={handleIconPopoverClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <Box sx={styles.popoverContainer}>
        <Typography variant="h6" sx={styles.title}>
          Select from below :
        </Typography>
        <Box sx={styles.iconsContainter}>
          <Box sx={styles.iconBox}></Box>
          <Box sx={styles.iconBox}></Box>
          <Box sx={styles.iconBox}></Box>
          <Box sx={styles.iconBox}></Box>
        </Box>
        <Typography variant="h6" sx={styles.title}>
          Upload Icon:
        </Typography>
        <Box sx={styles.iconBox}>
          {uploadingFile ? (
            <BounceLoader color="#36d7b7" />
          ) : (
            <CardMedia
              alt="Icon"
              src={botIcon}
              component="img"
              sx={{
                width: "100%",
                height: "100%",
                borderRadius: "50%",
              }}
            />
          )}
        </Box>
        <input
          type="file"
          accept="image/jpeg, image/jpg, image/png"
          onChange={handleIconSubmit}
        />
      </Box>
    </Popover>
  );
};

export default ChangeIconPopover;
