import { useState, useRef } from "react";
import { useSelector } from "react-redux";
import { Card, IconButton, TextField } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import InsertEmoticonOutlinedIcon from "@mui/icons-material/InsertEmoticonOutlined";
import EmojiPopover from "../helper/EmojiPopover";

const BottomSection = ({
  handleSendMessage,
  handleInputChange,
  inputText,
  setInputText,
}) => {
  const ref = useRef(null);
  const emojiPopoverRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);

  const isTyping = useSelector((state) => state.flow?.testBotTypingIndicator);

  const openEmoji = (e) => {
    setAnchorEl(emojiPopoverRef.current);
  };

  const handleEmojiSelect = (emoji) => {
    console.log("ref", ref.current);
    console.log("EmoJI", emoji.native);
    const cursor = ref.current.querySelector("input").selectionStart;
    console.log("cursor", cursor.selectionStart);
    const newValue =
      inputText.slice(0, cursor) + emoji.native + inputText.slice(cursor);
    setInputText(newValue);
    setAnchorEl(null);
  };

  return (
    <Card
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        height: "50px",
        backgroundColor: "rgb(255, 255, 255)",
        padding: "0px",
        margin: "0px",
      }}
    >
      <TextField
        ref={ref}
        variant="standard"
        placeholder="Send a message..."
        style={{
          width: "250px",
          color: "white",
          marginLeft: "20px",
          fontSize: "10px",
        }}
        inputProps={{
          autoFocus: true,
        }}
        InputProps={{
          disableUnderline: true,
        }}
        value={inputText}
        onChange={handleInputChange}
        onKeyPress={(e) => {
          if (e.key === "Enter") handleSendMessage();
        }}
      />
      <div ref={emojiPopoverRef}>
        <IconButton
          sx={{ color: "rgb(215, 215, 215)" }}
          onClick={(e) => openEmoji(e)}
        >
          <InsertEmoticonOutlinedIcon sx={{ color: "#8d8d8d" }} />
        </IconButton>
        <IconButton
          onClick={() => handleSendMessage()}
          sx={{ color: "rgb(215, 215, 215)", marginRight: "5px" }}
        >
          <SendIcon sx={{ color: "#8d8d8d" }} />
        </IconButton>
      </div>
      <EmojiPopover
        handleEmojiSelect={handleEmojiSelect}
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
      />
    </Card>
  );
};

export default BottomSection;
