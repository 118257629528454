import {
  Box,
  Breadcrumbs,
  Button,
  Grid,
  Link,
  Typography,
  Stack,
  useMediaQuery,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Autocomplete,
  Slide,
  Collapse,
  Alert,
  Tabs,
  Tab,
  Card,
  CardContent,
  InputAdornment,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import { visuallyHidden } from "@mui/utils";
import { useSelector, useDispatch } from "react-redux";
import {
  createQuotation,
  deleteQuotationInRedux,
  getAllQuotation,
} from "../../redux/actions/productActions";
import {
  Base64,
  TabPanel,
  catalogQuotationLink,
  dialogStyle,
  formatDate,
  getStoreId,
  getStoreName,
  getToken,
  getWebsite,
  isArrayWithValues,
  isDev,
  isInRange,
  isObjWithValues,
  jweroColorPalette,
  localHostLink,
  primaryLabelLarge,
  secondaryLabel,
  validateNumber,
} from "../../helper";
import {
  Add,
  Cached,
  DeleteOutlined,
  LaunchRounded,
  Search,
} from "@mui/icons-material";
import { NotFound } from "../../Svgs";
import { useTheme } from "@mui/system";
import axios from "axios";
import { LoadingButton } from "@mui/lab";
import SelectProducts from "../components/SelectProducts";
import { fetchCustomers } from "../products/AllProducts";
import { setGlobalToast } from "../../redux/actions/helperActions";
import { DashboardTotalCard } from "../dashboards/Default";
import { primaryLabel } from "../chat/ChatHelper";
import ReactApexChart from "react-apexcharts";
import { CustomDateRangeFilter } from "../digital_gold/Dashboard";

export function Quotations2() {
  const [quotations, setQuotations] = useState([]);
  const reduxQuotations = useSelector((state) => state.product.quotations);
  const [productSelectionOpen, setProductSelectionOpen] = useState(false);
  const [showQuotationForm, setShowQuotationForm] = useState(false);

  const closeProductSelection = () => setProductSelectionOpen(false);
  const openProductSelection = () => setProductSelectionOpen(true);

  const openQuotationForm = () => setShowQuotationForm(true);
  const closeQuotationForm = () => setShowQuotationForm(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isArrayWithValues(reduxQuotations)) dispatch(getAllQuotation());
  }, []);

  useEffect(() => {
    if (isArrayWithValues(reduxQuotations)) setQuotations([...reduxQuotations]);
  }, [reduxQuotations]);
  const [] = useState();
  return (
    <Box>
      <Grid
        justifyContent="space-between"
        alignItems={"center"}
        container
        spacing={2}
        p={6}
      >
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Quotations
          </Typography>
          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/">
              Dashboard
            </Link>
            {/* <Link component={NavLink} to="/">
              Products
            </Link> */}
            <Typography>Quotations</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item sx={{ marginLeft: "auto" }}>
          <Button
            // disabled={fetchingQuotations}
            variant="text"
            // onClick={() => navigate("/products")}
            startIcon={<Add />}
            sx={{ mr: 2 }}
          >
            Add Quotation
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={2}></Grid>
    </Box>
  );
}

export default function Quotations({ customerId }) {
  const [rows, setRows] = useState([]);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [quotationError, setQuotationError] = useState("");
  const [allQuotations, setAllQuotations] = useState([]);

  const [quotationForm, setQuotationForm] = useState({
    customers: {},
    title: "",
    otp_enabled: false,
  });
  const reduxQuotations = useSelector((state) => state.product.quotations);
  const fetchingQuotations = useSelector(
    (state) => state.product.fetchingQuotations
  );
  const creatingQuotation = useSelector(
    (state) => state.product.creatingQuotation
  );
  let customerSearchTimeout;

  const selectQuotaionCustomerRef = useRef();

  const changeQuotationForm = (payload) => {
    if (quotationError) setQuotationError("");
    setQuotationForm({ ...quotationForm, ...payload });
  };
  const [allQuotationCustomers, setQuotationCustomers] = useState([]);
  const [customerSearching, setCustomerSearching] = useState(false);

  const globalPopupStyle =
    useSelector((state) => state.helper.globalPopupStyle) || {};

  const [productSelectionOpen, setProductSelectionOpen] = useState(false);
  const [showQuotationForm, setShowQuotationForm] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState([]);

  const closeProductSelection = () => setProductSelectionOpen(false);
  const openProductSelection = () => setProductSelectionOpen(true);

  const openQuotationForm = () => setShowQuotationForm(true);
  const closeQuotationForm = () => setShowQuotationForm(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();

  useEffect(() => {
    if (!isArrayWithValues(reduxQuotations)) dispatch(getAllQuotation());
  }, []);

  useEffect(() => {
    if (isArrayWithValues(reduxQuotations)) {
      let array = [...reduxQuotations];
      if (customerId)
        array = array.filter((i) => i.customer && i.customer.ID == customerId);
      setRows([...array]);
      setAllQuotations([...array]);
    }
  }, [reduxQuotations, customerId]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const openDeleteDialog = () => {
    setDeleteDialog(true);
  };
  const closeDeleteDialog = () => {
    setDeleteDialog(false);
  };

  const onDeleteQuotation = async (id) => {
    let website = await getWebsite();
    let token = await getToken();
    if (website && token) {
      setDeleting(true);
      try {
        let res = await axios({
          url: `${website}/wp-json/store/v1/quotations`,
          headers: {
            Authorization: `Basic ${token}`,
          },

          method: "POST",
          params: {
            type: "delete",
            id,
          },
        });
        let { data } = res;
        if (data.success) {
          dispatch(deleteQuotationInRedux(id));
          setDeleting(false);
          setDeleteDialog(false);
        } else throw new Error();
      } catch (error) {
        setDeleting(false);
      }
    }
  };

  const onProductSelect = (products) => {
    setSelectedProducts(products);
    closeProductSelection();
    openQuotationForm();
  };

  const onQuotationCustomerSearch = async (value, setState) => {
    if (!value || (value && value.length < 2)) return;
    if (customerSearchTimeout) clearTimeout(customerSearchTimeout);

    customerSearchTimeout = setTimeout(async () => {
      setCustomerSearching(true);
      await fetchCustomers(value, setState);
      setCustomerSearching(false);
    }, 500);
  };

  const setUpdatedQuotationCustomers = (customers) => {
    if (isArrayWithValues(customers)) {
      let arr = [];
      for (let i = 0; i < customers.length; i++) {
        arr.push({
          label: `${customers[i].first_name} ${customers[i].last_name}`,
          value: customers[i].id,
        });
      }
      setQuotationCustomers(arr);
    }
  };

  const onQuotationCreate = () => {
    let { title, customers, otp_enabled } = quotationForm;
    if (!title) return setQuotationError("Please enter title!");
    if (!isObjWithValues(customers))
      return setQuotationError("Please select an customer!");

    let obj = {
      title,
      customer: `${customers.value}`,
      otp_enabled,
      products: selectedProducts.map((i) => i.id).toString(),
    };
    console.log(obj, selectedProducts);
    dispatch(
      createQuotation(
        obj,
        () => {
          dispatch(getAllQuotation());
          closeQuotationForm();
          // navigate("/quotations");
        },
        () => {
          dispatch(
            setGlobalToast({
              show: true,
              message: "Couldn't create quotation, Please try again later",
              severity: "error",
            })
          );
        }
      )
    );
  };

  const isIpad = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box sx={{ width: "100%" }}>
      <Grid
        justifyContent="space-between"
        alignItems={"center"}
        container
        spacing={2}
        p={isIpad ? 3 : 6}
      >
        <Grid item>
          {customerId ? (
            <Box />
          ) : (
            <React.Fragment>
              <Typography variant="h3" gutterBottom display="inline">
                Quotations
              </Typography>
              <Breadcrumbs aria-label="Breadcrumb" mt={2}>
                <Link component={NavLink} to="/">
                  Dashboard
                </Link>
                {/* <Link component={NavLink} to="/">
              Products
            </Link> */}
                <Typography>Quotations</Typography>
              </Breadcrumbs>
            </React.Fragment>
          )}
        </Grid>
        <Grid item sx={{ marginLeft: "auto" }}>
          <Stack direction="row">
            <Button
              disabled={fetchingQuotations}
              variant="text"
              onClick={() => dispatch(getAllQuotation())}
              startIcon={<Cached />}
            >
              Refresh
            </Button>
            <Button
              disabled={fetchingQuotations}
              variant="contained"
              // onClick={() => navigate("/products")}
              onClick={openProductSelection}
              startIcon={<Add />}
            >
              Add Quotaion
            </Button>
          </Stack>
        </Grid>
      </Grid>
      {isDev() && <QuotationTopCards />}
      {/* {!fetchingQuotations && !isArrayWithValues(rows) ? (
        <Box
          sx={{
            width: "300px",
            margin: "auto",
            marginTop: customerId ? "14vh" : "18vh",
            marginBottom: "10vh",
            textAlign: "center",
          }}
        >
          <NotFound />
          <Typography variant="h6" color="text.secondary" mt={5}>
            Didn't Found any Quotations!
          </Typography>
        </Box>
      ) : null} */}
      {/* {fetchingQuotations && !isArrayWithValues(rows) ? (
        <Box
          sx={{
            width: "300px",
            margin: "auto",
            marginTop: customerId ? "30vh" : "36vh",
            textAlign: "center",
          }}
        >
          <LinearProgress />
          <Typography variant="h6" color="text.secondary" mt={3}>
            Fetching Quotations
          </Typography>
        </Box>
      ) : null} */}
      {(isArrayWithValues(rows) || true) && (
        <QuotationsTable
          selected={selected}
          order={order}
          orderBy={orderBy}
          handleSelectAllClick={handleSelectAllClick}
          handleRequestSort={handleRequestSort}
          isSelected={isSelected}
          setDeleteDialog={setDeleteDialog}
          dense={dense}
          page={page}
          rows={rows}
          setRows={setRows}
          rowsPerPage={rowsPerPage}
          allQuotations={allQuotations}
        />
      )}
      {isDev() && (
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12} md={6}>
            <QuotationsSentGraph />
          </Grid>
          <Grid item xs={12} md={6}>
            <QuotationProductDistribution />
          </Grid>
        </Grid>
      )}
      <Dialog
        open={productSelectionOpen}
        onClose={closeProductSelection}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <SelectProducts
          onClose={closeProductSelection}
          onSelect={onProductSelect}
          onlyImage
        />
      </Dialog>
      <Dialog
        open={showQuotationForm}
        maxWidth="sm"
        fullWidth
        onClose={closeQuotationForm}
        TransitionComponent={Slide}
        sx={{ padding: "26px", ...dialogStyle }}
        {...globalPopupStyle}
      >
        <DialogTitle>
          Create Quotation (
          {isArrayWithValues(selectedProducts) && selectedProducts.length}{" "}
          Products)
        </DialogTitle>
        <DialogContent sx={{}}>
          <Collapse in={Boolean(quotationError)}>
            <Alert
              variant="outlined"
              severity="error"
              sx={{ my: 3, padding: "2px 7px" }}
            >
              {quotationError}
            </Alert>
          </Collapse>
          {/* <DialogContentText>
              To subscribe to this website, please enter your email address here.
              We will send updates occasionally.
            </DialogContentText> */}
          <Typography>Title</Typography>
          <TextField
            autoFocus
            // id="outlined-basic"
            size="small"
            // label=""
            variant="standard"
            fullWidth
            value={quotationForm.title}
            onChange={(e) => changeQuotationForm({ title: e.target.value })}
          />
          <Box sx={{ mt: 2.5 }} />
          <Typography>Select Customer</Typography>
          <Autocomplete
            ref={selectQuotaionCustomerRef}
            disablePortal
            id="combo-box-demo"
            options={allQuotationCustomers}
            size="small"
            // sx={{ width: 300 }}
            value={quotationForm.customers}
            getOptionLabel={(option) => option.label || ""}
            loading={customerSearching}
            openOnFocus
            renderInput={(params) => (
              <TextField
                {...params}
                // label="UP SELL"
                onChange={(e) =>
                  onQuotationCustomerSearch(
                    e.target.value,
                    setUpdatedQuotationCustomers
                  )
                }
              />
            )}
            onChange={(e, value) => changeQuotationForm({ customers: value })}
            ListboxProps={{
              style: {
                maxHeight: "100px",
              },
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={closeQuotationForm}>Cancel</Button>
          <LoadingButton
            loading={creatingQuotation}
            onClick={onQuotationCreate}
          >
            Create
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <Dialog
        open={Boolean(deleteDialog)}
        onClose={closeDeleteDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="sm"
        sx={dialogStyle}
      >
        <DialogTitle id="alert-dialog-title">
          Delete "{deleteDialog && deleteDialog.ID}" Quotation?
        </DialogTitle>
        {/* <DialogContent>
          <DialogContentText id="alert-dialog-description"></DialogContentText>
        </DialogContent> */}
        <DialogActions>
          <Button onClick={closeDeleteDialog} size="small">
            Cancel
          </Button>
          <LoadingButton
            loading={deleting}
            variant="contained"
            size="small"
            color="error"
            onClick={() => onDeleteQuotation(deleteDialog && deleteDialog.id)}
          >
            Delete
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

const QuotationTopCards = () => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6} md={3}>
        <DashboardTotalCard
          title="Quotation created"
          value={0}
          values={[
            {
              title: "Catalog converted",
              value: "0 (0%)",
              // prefix: currencySymbols[currency],
              // info: ["Share catalog link via Whatsapp, Email etc."],
            },
          ]}
          // onClick={() => navigate("/catalogs#sent")}
          // points={["Total nos of catalogs created, Public and Private"]}
          graphData={0}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <DashboardTotalCard
          title="Quotation response"
          value={0}
          values={[
            {
              title: "Products Sent",
              value: 0,
              // prefix: currencySymbols[currency],
            },
            {
              title: "Products Confirmed",
              value: 0,

              // suffix: "g",
            },
          ]}
          // onClick={() => navigate("/catalogs#sent")}
          graphData={0}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <DashboardTotalCard
          title="Sent to Contacts"
          value={0}
          values={[
            {
              title: "Customers",
              value: "0 (0%)",
            },
            {
              title: "Leads",
              value: "0 (0%)",
            },
          ]}
          // onClick={() => navigate("/catalogs#requests")}
          graphData={0}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <DashboardTotalCard
          title="Confirm order amount"
          value={0}
          values={[
            {
              title: "Avg confirmed quotation price",
              value: "$0 (0%)",
            },
          ]}
          hideNavigate
          // onClick={() => navigate("/catalogs")}
          graphData={0}
        />
      </Grid>
    </Grid>
  );
};

const QuotationsTableToolbar = ({ filterForm, editForm }) => {
  return (
    <Box>
      {/* <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Age</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={filterForm.type}
          // label="Age"
          onChange={handleChange}
        >
          <MenuItem value={"all"}>All</MenuItem>
          <MenuItem value={"sent"}>Sent</MenuItem>
          <MenuItem value={"confirmed"}>Confirmed</MenuItem>
          <MenuItem value={"part_selection"}>Part Selection</MenuItem>
        </Select>
      </FormControl> */}
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={filterForm?.type}
            onChange={(a, e) => editForm({ type: e })}
            aria-label="basic tabs example"
          >
            <Tab label="All" value="all" />
            <Tab label="Sent" value="sent" />
            <Tab label="Confirmed" value="confirmed" />
            <Tab label="Part Selection" value="part_selection" />
          </Tabs>
        </Box>
        {/* <TabPanel value={filterForm?.type} index={"all"}>
          All
        </TabPanel>
        <TabPanel value={filterForm?.type} index={"sent"}>
          Sent
        </TabPanel>
        <TabPanel value={filterForm?.type} index={"confirmed"}>
          Confirmed
        </TabPanel>
        <TabPanel value={filterForm?.type} index={"part_selection"}>
          Part Selection
        </TabPanel> */}
      </Box>
    </Box>
  );
};

const QuotationsTable = ({
  selected,
  order,
  orderBy,
  handleSelectAllClick,
  handleRequestSort,
  isSelected,
  setDeleteDialog,
  dense,
  page,
  rows,
  rowsPerPage,
  allQuotations,
  setRows,
}) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isIpad = useMediaQuery(theme.breakpoints.down("md"));
  const isDarkTheme = theme.palette && theme.palette.mode === "dark";
  const [filterForm, setFilterForm] = useState({
    type: "all",
    search: "",
    dateRange: { startDate: null, endDate: null },
  });

  const editForm = (payload) => {
    setFilterForm((state) => ({ ...state, ...payload }));
  };

  useEffect(() => {
    let { type, dateRange, search } = filterForm;
    let { startDate, endDate } = dateRange;
    let rows = [];
    for (let i of allQuotations) {
      let shouldShow = true;
      let { created_at, title } = i;
      let date = new Date(created_at).getTime();
      let start = new Date(startDate).getTime();
      let end = new Date(endDate).getTime();

      if (start && end) shouldShow = isInRange(date, start, end);
      if (search) shouldShow = title?.toLowerCase()?.includes(search);

      if (shouldShow) rows.push(i);
    }
    setRows(rows);
  }, [filterForm]);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const onQuotationLinkPress = (row) => {
    let encr = Base64.btoa(
      JSON.stringify({
        quotation_id: row.id,
        store_id: getStoreId(),
        customer_id: row?.customer?.ID,
      })
    );
    window.open(
      isDev() ? `${catalogQuotationLink}/quotations/${encr}` : row.link,
      "_blank"
    );
  };
  return (
    <Paper
      sx={{
        width: "100%",
        mb: 2,
        mt: 6,
        borderWidth: "8px",
        boxShadow: "0.5px 0.5px 3px rgba(0,0,0,0.3)",
        borderRadius: "10px",
      }}
    >
      {isDev() ? (
        <>
          <Stack
            direction="row"
            alignItems={"center"}
            justifyContent={"space-between"}
            sx={{ pt: 2, mx: 3 }}
          >
            <Typography sx={{ ...primaryLabelLarge }}>Quotations</Typography>
          </Stack>
          <Stack
            direction="row"
            alignItems={"center"}
            justifyContent={"space-between"}
            sx={{ pt: 2, mx: 3 }}
          >
            <TextField
              variant="outlined"
              size="small"
              value={filterForm.search}
              onChange={(e) => editForm({ search: e.target.value })}
              InputProps={{
                startAdornment: (
                  <InputAdornment>
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
            <CustomDateRangeFilter
              dateRange={filterForm.dateRange}
              // hideCustom
              setDateRange={(range) => editForm({ dateRange: range })}
            />
          </Stack>
          <QuotationsTableToolbar filterForm={filterForm} editForm={editForm} />
          <Typography sx={{ ...secondaryLabel, ml: 3, pt: 2 }}>
            Showing {rows?.length} quotations
          </Typography>
        </>
      ) : null}
      <TableContainer>
        <Table aria-labelledby="tableTitle" size={dense ? "small" : "medium"}>
          <EnhancedTableHead
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
            isIpad={isIpad}
          />
          <TableBody>
            {/* if you don't need to support IE11, you can replace the `stableSort` call with:
         rows.slice().sort(getComparator(order, orderBy)) */}
            {/* {stableSort(rows, getComparator(order, orderBy))
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map((row, index) => { */}
            {rows.map((row, index) => {
              const isItemSelected = isSelected(row.id);
              const labelId = `enhanced-table-checkbox-${index}`;
              let {
                products,
                unselected_products,
                customer,
                edit_history,
                id,
                link,
                title,
              } = row;
              const totalProducts = isArrayWithValues(row.products)
                ? validateNumber(products.length) +
                  validateNumber(unselected_products.length)
                : 0;
              const selectedProducts = totalProducts
                ? totalProducts - validateNumber(unselected_products.length)
                : 0;

              const customerName = customer
                ? `${customer.first_name} ${customer.last_name}`
                : "";
              const customerCity = customer ? customer.city : "";
              const hasResponse =
                isArrayWithValues(edit_history) &&
                edit_history.find((i) => i.by !== "supplier");
              let storeName = getStoreName();
              storeName =
                typeof storeName === "string"
                  ? storeName
                      .split(" ")
                      .map((i) => i[0] || "")
                      .join("")
                  : "";
              let ID = `${storeName}-${id}`;
              return (
                <TableRow
                  hover
                  onClick={() => navigate(`/quotations/${row.id}`)}
                  // onClick={(event) => handleClick(event, row.id)}
                  // role="checkbox"
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  key={row.id}
                  selected={isItemSelected}
                  sx={{ cursor: "pointer" }}
                >
                  {isIpad ? (
                    <React.Fragment>
                      <TableCell>
                        <Stack
                          direction="row"
                          alignItems={"center"}
                          justifyContent="space-between"
                        >
                          <Box>
                            <Typography fontWeight={"bold"} alignItems="center">
                              {ID} ({selectedProducts}/{totalProducts})
                            </Typography>
                            <Typography mt={1}>{customerName}</Typography>
                            <Typography
                              fontSize={"12px"}
                              color="text.secondary"
                              mt={1}
                            >
                              {row.created_at
                                ? formatDate(
                                    new Date(row.created_at),
                                    "dd:mm:yyyy hh:mm"
                                  )
                                : null}
                            </Typography>
                          </Box>
                          <Box>
                            <Typography>
                              {hasResponse ? (
                                <Chip
                                  label={"Response"}
                                  variant="outlined"
                                  color={"success"}
                                  size="small"
                                  sx={{
                                    border: isDarkTheme ? "0.7px solid" : "0px",
                                    backgroundColor: isDarkTheme
                                      ? "transparent"
                                      : "#E6F8E0",
                                    borderRadius: "10px",
                                    fontSize: "11px",
                                    ml: 2,
                                  }}
                                />
                              ) : (
                                <Chip
                                  label={"Sent"}
                                  variant="outlined"
                                  color={"info"}
                                  size="small"
                                  sx={{
                                    border: isDarkTheme ? "0.7px solid" : "0px",
                                    backgroundColor: isDarkTheme
                                      ? "transparent"
                                      : "#e8f4fa",
                                    borderRadius: "10px",
                                    fontSize: "11px",
                                    ml: 2,
                                  }}
                                />
                              )}
                            </Typography>
                          </Box>
                        </Stack>
                      </TableCell>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        sx={{ fontSize: "15px", fontWeight: "500" }}
                      >
                        <Stack>
                          <Typography fontWeight={"500"} fontSize="15px">
                            {title}
                          </Typography>
                          <Typography
                            fontSize="12px"
                            color="text.secondary"
                            mt={0.2}
                          >
                            {ID}
                          </Typography>
                        </Stack>
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        sx={{ fontSize: "15px", fontWeight: "500" }}
                      >
                        <Stack>
                          <Typography fontWeight={"500"} fontSize="15px">
                            {customerName}
                          </Typography>
                          <Typography
                            fontSize="12px"
                            color="text.secondary"
                            mt={0.2}
                          >
                            {" "}
                            {customerCity}
                          </Typography>
                        </Stack>
                      </TableCell>
                      {/* <TableCell align="left">{ID}</TableCell> */}
                      <TableCell align="left">
                        {row.created_at
                          ? formatDate(
                              new Date(row.created_at),
                              "dd:mm:yyyy hh:mm"
                            )
                          : null}
                      </TableCell>
                      <TableCell align="left">
                        {selectedProducts}/{totalProducts}
                      </TableCell>
                      <TableCell>
                        {hasResponse ? (
                          <Chip
                            label={"Response"}
                            variant="outlined"
                            color={"success"}
                            size="small"
                            sx={{
                              border: isDarkTheme ? "0.7px solid" : "0px",
                              backgroundColor: isDarkTheme
                                ? "transparent"
                                : "#E6F8E0",
                              borderRadius: "10px",
                              fontSize: "11px",
                            }}
                          />
                        ) : (
                          <Chip
                            label={"Sent"}
                            variant="outlined"
                            color={"info"}
                            size="small"
                            sx={{
                              border: isDarkTheme ? "0.7px solid" : "0px",
                              backgroundColor: isDarkTheme
                                ? "transparent"
                                : "#e8f4fa",
                              borderRadius: "10px",
                              fontSize: "11px",
                            }}
                          />
                        )}
                      </TableCell>
                      <TableCell>
                        <Stack direction="row">
                          <IconButton
                            onClick={(e) => {
                              e.stopPropagation();
                              isDev()
                                ? onQuotationLinkPress(row)
                                : window.open(link, "_blank");
                            }}
                          >
                            <LaunchRounded />
                          </IconButton>
                          <IconButton
                            onClick={(e) => {
                              e.stopPropagation();
                              setDeleteDialog({ ID, id });
                            }}
                          >
                            <DeleteOutlined color="error" />
                          </IconButton>
                        </Stack>
                      </TableCell>
                    </React.Fragment>
                  )}
                </TableRow>
              );
            })}
            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: (dense ? 33 : 53) * emptyRows,
                }}
              >
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {/* <TablePagination
    rowsPerPageOptions={[5, 10, 25]}
    component="div"
    count={rows.length}
    rowsPerPage={rowsPerPage}
    page={page}
    onPageChange={handleChangePage}
    onRowsPerPageChange={handleChangeRowsPerPage}
  /> */}
    </Paper>
  );
};

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    isIpad,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  let cells = isIpad ? [] : headCells;

  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell> */}
        {cells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={"left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Quotations
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

const QuotationsSentGraph = () => {
  const [options, setOptions] = useState({
    chart: {
      type: "donut",
    },
    labels: ["Catalog Converted", "Direct"],
    colors: jweroColorPalette,
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  });
  const [series, setSeries] = useState([44, 55]);
  return (
    <Card>
      <CardContent>
        <Typography sx={{ ...primaryLabelLarge }}>Quotations Sent</Typography>
        <ReactApexChart
          options={options}
          series={series}
          type="donut"
          width={600}
          height={300}
        />
      </CardContent>
    </Card>
  );
};
const QuotationProductDistribution = () => {
  const [options, setOptions] = useState({
    labels: ["Catalog Converted", "Direct"],
    colors: jweroColorPalette,
    chart: {
      width: 380,
      type: "pie",
    },
    labels: ["Earrings", "Pendants", "Mangalsutras", "Ladies Ring"],
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  });
  const [series, setSeries] = useState([44, 10, 20, 25]);
  return (
    <Card>
      <CardContent>
        <Typography sx={{ ...primaryLabelLarge }}>Quotations Sent</Typography>
        <ReactApexChart
          options={options}
          series={series}
          type="pie"
          width={600}
          height={300}
        />
      </CardContent>
    </Card>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

function createData(name, calories, fat, carbs, protein) {
  return {
    name,
    calories,
    fat,
    carbs,
    protein,
  };
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "quotation",
    numeric: false,
    disablePadding: false,
    label: "Quotation",
  },
  {
    id: "customer",
    numeric: false,
    disablePadding: false,
    label: "Customer",
  },
  // {
  //   id: "id",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "ID",
  // },
  {
    id: "created",
    numeric: true,
    disablePadding: false,
    label: "Created",
  },
  {
    id: "products",
    numeric: true,
    disablePadding: false,
    label: "Products",
  },
  {
    id: "status",
    numeric: true,
    disablePadding: false,
    label: "Status",
  },
  {
    id: "actions",
    numeric: true,
    disablePadding: false,
    label: "Actions",
  },
  // {
  //   id: "response",
  //   numeric: true,
  //   disablePadding: false,
  //   label: "Response",
  // },
  //   {
  //     id: "carbs",
  //     numeric: true,
  //     disablePadding: false,
  //     label: "Carbs (g)",
  //   },
  //   {
  //     id: "protein",
  //     numeric: true,
  //     disablePadding: false,
  //     label: "Protein (g)",
  //   },
];
