import {
  AccessTimeOutlined,
  ArrowBack,
  Close,
  FiberManualRecord,
  InputOutlined,
  LanguageOutlined,
  LaptopMac,
  LocationOnOutlined,
  PhoneIphone,
} from "@material-ui/icons";
import { AccessTime } from "@mui/icons-material";
import {
  CardContent,
  Card,
  Typography,
  Stack,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Divider,
  Box,
  Button,
  useTheme,
  useMediaQuery,
  IconButton,
  Grid,
} from "@mui/material";
import React, { createRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { UAParser } from "ua-parser-js";
import {
  CircularProgressWithLabel,
  formatNumber,
  formatServerValue,
  getDeviceIconFromUA,
  getPlatformIconFromUA,
  getRelativeTimeDifference,
  isArrayWithValues,
  isObjWithValues,
  mainSocketEvents,
  primaryLabel,
  randomVisitorName,
  secondaryLabel,
  validateNumber,
} from "../../../helper";
import { SET_VISITOR_DATA } from "../../../redux/actions/visitorActions";
import { CatalogIcon } from "../../../Svgs";
import { getLabelFromType, UserTimeline } from "../../visitors/VisitorsDetails";
import CustomChip from "../../components/CustomChip";
import RedialBarMeter from "../../components/RedialBarMeter";
import OpportunityLeadScore from "./OpportunityLeadScore";
export const VisitorList = ({
  visitors,
  heading,
  hoverEffect,
  cardStyle = {},
  live,
}) => {
  const navigate = useNavigate();
  const [hover, setHover] = useState(false);
  return (
    <Card
      sx={{ ...cardStyle }}
      onMouseEnter={() => hoverEffect && setHover(true)}
      onMouseLeave={() => hoverEffect && setHover(false)}
      className="scrollbar-hidden"
    >
      {hoverEffect && (
        <>
          <Box
            sx={{
              width: "100%",
              height: "3px",
              backgroundColor: "primary.main",
              opacity: "0.7",
              position: "sticky",
              top: 0,
              zIndex: 2,
            }}
          />
          <Box
            sx={{
              maxWidth: hover ? "0%" : "100%",
              height: "3px",
              backgroundColor: "primary.main",
              position: "relative",
              top: -3,
              right: 0,
              left: 0,
              transition: "all 0.5s",
            }}
          />
        </>
      )}
      <CardContent sx={{ pt: 0, height: "100%" }}>
        <List className="scrollbar-hidden" sx={{ pt: 0 }}>
          {heading ? (
            <ListItem
              sx={{
                position: "sticky",
                top: hoverEffect ? "4px" : "0",
                backgroundColor: "background.paper",
                zIndex: 1,
                py: 4,
              }}
            >
              <Typography
                variant="h6"
                sx={{ display: "flex", alignItems: "center", gap: 1 }}
              >
                {heading}{" "}
                {live && (
                  <FiberManualRecord
                    fontSize="small"
                    style={{ color: "#5ABE37" }}
                  />
                )}
              </Typography>
            </ListItem>
          ) : null}

          {!isArrayWithValues(visitors) ? (
            <Stack
              alignItems={"center"}
              justifyContent={"center"}
              sx={{ mt: "30vh" }}
            >
              {hoverEffect ? (
                <Typography variant="h6" color={"text.secondary"}>
                  No new visitors
                </Typography>
              ) : (
                <Stack
                  justifyContent={"center"}
                  alignItems={"center"}
                  rowGap={3}
                >
                  <Typography variant="h6" color={"text.secondary"}>
                    You have no live visitor at the moment
                  </Typography>
                  <Button
                    variant="outlined"
                    onClick={() => navigate(`/dashboard`)}
                  >
                    Go to dashboard
                  </Button>
                </Stack>
              )}
            </Stack>
          ) : null}
          {visitors?.map((visitor, index) => (
            <>
              <VisitorRow
                visitor={
                  isArrayWithValues(visitor) ? visitor : visitor?.events || []
                }
                key={visitor?.id}
              />
              {visitors?.length === index + 1 ? null : <Divider />}
            </>
          ))}
        </List>
      </CardContent>
    </Card>
  );
};

export const VisitorRow = ({
  visitor,
  visitorObj,
  topScore,
  hideEventType,
  onClick,
}) => {
  const [info, setInfo] = useState({});
  const [mainObj, setMainObj] = useState({});
  let { first_name, last_name, user_name, user_id, ua, type, time, end_time } =
    visitorObj || mainObj || {};
  let location = visitorObj?.location || mainObj?.location || {};
  // visitor?.find((obj) => mainSocketEvents?.includes(obj?.type))?.location ||
  // {};
  // console.log(visitor);
  const dispatch = useDispatch();
  useEffect(() => {
    if (isArrayWithValues(visitor))
      setMainObj(
        visitor?.reverse()?.find((obj) => mainSocketEvents?.includes(obj?.type))
      );
    else setMainObj({});
  }, [visitor]);

  useEffect(() => {
    if (isArrayWithValues(visitorObj?.meta_data)) {
      let obj = {};
      for (let metaObj of visitorObj?.meta_data) {
        obj[metaObj.key] = metaObj.value;
      }
      setInfo(obj);
    } else setInfo({});
  }, [visitorObj]);

  let countryCode = location?.country || visitorObj?.country;
  let { city } = location || {};
  city = city || visitorObj?.city || visitorObj?.billing?.city;
  let url = `https://flagcdn.com/w40/${countryCode?.toLowerCase()}.png`;
  let timeObj = getRelativeTimeDifference(
    new Date(time * 1000),
    new Date(validateNumber(end_time * 1000) || Date.now())
  );
  let timeStr = `${Math.max(0, timeObj?.value || 0)} ${timeObj?.label || ""}`;
  const onVistorClick = () => {
    dispatch({
      type: SET_VISITOR_DATA,
      payload: { userDetailView: visitor },
    });
  };
  user_name = !user_name ? `${first_name || ""} ${last_name || ""}` : user_name;
  return (
    <ListItem
      sx={{
        cursor: "pointer",
        alignItems: "center",
        borderBottom: "0.6px solid #b7b7b7",
      }}
    >
      <ListItemText
        onClick={() =>
          onClick ? onClick(visitorObj || mainObj || {}) : onVistorClick()
        }
        disableTypography={true}
        primary={
          <Stack
            direction={"row"}
            alignItems="center"
            justifyContent={"space-between"}
            gap={2}
          >
            <Stack
              direction={"row"}
              alignItems="center"
              justifyContent={"space-between"}
              gap={2}
            >
              <Typography variant="h6" mr={2}>
                {user_name?.trim() || randomVisitorName(user_id || "")}
              </Typography>
              {ua &&
                getDeviceIconFromUA(ua || "", {
                  opacity: "0.7",
                  fontSize: "20px",
                })}
              {ua &&
                getPlatformIconFromUA(ua || "", {
                  height: "20px",
                  width: "20px",
                })}
              {countryCode ? (
                <img
                  src={url}
                  style={{
                    // borderRadius: "50%",
                    height: "15px",
                    width: "23px",
                    borderRadius: "15%",
                  }}
                />
              ) : null}
            </Stack>
            {!visitorObj?.score && (
              <Typography sx={{ ...secondaryLabel }}>
                {info.contact_type ? info.contact_type : "Lead"}
              </Typography>
            )}
          </Stack>
        }
        secondary={
          <React.Fragment>
            <Stack
              direction={"row"}
              alignItems="center"
              justifyContent={"space-between"}
              gap={2}
              mt={2}
            >
              <Stack direction={"row"} alignItems="center" gap={2}>
                {(type === "customer" || type === "lead") && (
                  <CustomChip
                    status={type}
                    size="small"
                    type={type === "lead" ? "completed" : "processing"}
                  />
                )}
                {city && (
                  <Typography sx={{ display: "flex", alignItems: "center" }}>
                    <LocationOnOutlined
                      fontSize="small"
                      color={"primary"}
                      style={{
                        opacity: "0.7",
                        width: "16px",
                        height: "16px",
                      }}
                    />
                    {city}
                  </Typography>
                )}
                {type && getEventType(type) && !hideEventType && (
                  <Typography
                    sx={{ display: "flex", alignItems: "center", gap: "4px" }}
                  >
                    {getEventType(type)}{" "}
                    {formatServerValue(type || "")?.split(" ")?.[0]}
                  </Typography>
                )}
              </Stack>
              {timeObj?.value ? <Typography>{timeStr}</Typography> : null}
            </Stack>
          </React.Fragment>
        }
      />
      {visitorObj?.score && (
        <OpportunityLeadScore score={visitorObj?.score} topScore={topScore} />
        // <RedialBarMeter
        //   series={[
        //     Math.round(
        //       (validateNumber(visitorObj?.score) / topScore) * 100
        //     ).toFixed(0),
        //   ]}
        // />
        // <CircularProgressWithLabel
        //   value={(validateNumber(visitorObj?.score) / topScore) * 100}
        //   label={formatNumber(validateNumber(visitorObj?.score))}
        //   thickness={5}
        // />
      )}
    </ListItem>
  );
};

export const getEventType = (event, style = {}) => {
  if (event?.includes("catalog"))
    return <CatalogIcon style={{ height: "14px", width: "14px", ...style }} />;
  if (event?.includes("website"))
    return (
      <LanguageOutlined
        color="action"
        fontSize="small"
        style={{ height: "16px", width: "16px", ...style }}
      />
    );
};

export const AnalyticsCard = ({ title, icon, value, valueStyle = {} }) => {
  return (
    <Card
      sx={{
        border: "0.5px solid #e5eef8",
        height: "100%",
        minHeight: "110px",
        borderRadius: "14px",
        //   backgroundColor: ,
      }}
    >
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          paddingBottom: "24px",
          height: "100%",
        }}
      >
        <Stack
          direction={"row"}
          alignItems="center"
          justifyContent={"space-between"}
        >
          <Typography variant="h6">{title}</Typography>
          {icon}
        </Stack>
        <Typography variant="h4" sx={{ ...valueStyle }}>
          {value}
        </Typography>
      </CardContent>
    </Card>
  );
};

export const VisitorListItem = ({ visitor, ...otherProps }) => {
  let [visitorObj, setVisitorObj] = useState({});
  let { user_name, user_id, location, ua, type, time, end_time } = visitorObj;
  useEffect(() => {
    if (isArrayWithValues(visitor)) {
      setVisitorObj(
        isObjWithValues(
          visitor?.filter((i) => mainSocketEvents.includes(i.type))?.at(-1)
        )
          ? visitor?.filter((i) => mainSocketEvents.includes(i.type))?.at(-1)
          : visitor?.[0] || {}
      );
    } else setVisitorObj({});
  }, [visitor]);
  let countryCode = location?.country;
  let { city } = location || {};
  let url = `https://flagcdn.com/w40/${countryCode?.toLowerCase()}.png`;
  let timeObj = getRelativeTimeDifference(new Date(time * 1000));
  let timeStr = `${timeObj?.value || ""} ${timeObj?.label || ""} ago`;

  return (
    <>
      {" "}
      <ListItem {...otherProps} sx={{ cursor: "pointer" }}>
        {/* <ListItemAvatar> */}
        {/* <Avatar><ImageIcon /></Avatar> */}
        {/* </ListItemAvatar> */}
        <ListItemText
          disableTypography={true}
          primary={
            <Stack
              direction={"row"}
              alignItems="center"
              justifyContent={"space-between"}
              gap={2}
            >
              <Stack
                direction={"row"}
                alignItems="center"
                justifyContent={"space-between"}
                gap={2}
              >
                <Typography variant="h6" fontSize={"16px"}>
                  {user_name?.trim() || randomVisitorName(user_id || "") || ""}
                </Typography>
                {/* {ua &&
                            getDeviceIconFromUA(ua || "", {
                                opacity: "0.7",
                                fontSize: "20px",
                            })}
                        {ua &&
                            getPlatformIconFromUA(ua || "", {
                                height: "20px",
                                width: "20px",
                            })} */}
                {countryCode ? (
                  <img
                    src={url}
                    style={{
                      // borderRadius: "50%",
                      height: "15px",
                      width: "23px",
                      borderRadius: "15%",
                    }}
                  />
                ) : null}
              </Stack>

              <Typography sx={{ ...secondaryLabel }}>
                {user_name?.trim() ? "Customer" : "Lead"}
              </Typography>
            </Stack>
          }
          secondary={
            <React.Fragment>
              <Stack
                direction={"row"}
                alignItems="center"
                justifyContent={"space-between"}
                gap={2}
              >
                <Stack direction={"row"} alignItems="center" gap={2}>
                  {city && (
                    <Typography sx={{ display: "flex", alignItems: "center" }}>
                      <LocationOnOutlined
                        fontSize="small"
                        color={"primary"}
                        style={{
                          opacity: "0.7",
                          width: "16px",
                          height: "16px",
                        }}
                      />
                      {city}
                    </Typography>
                  )}
                  {/* {type && (
                                <Typography
                                    sx={{ display: "flex", alignItems: "center", gap: "4px" }}
                                >
                                    {getEventType(type)}{" "}
                                    {formatServerValue(type || "")?.split(" ")?.[0]}
                                </Typography>
                            )} */}
                </Stack>
                {time ? (
                  <Typography
                    sx={{ display: "flex", alignItems: "center", gap: "4px" }}
                  >
                    <AccessTime
                      style={{
                        // opacity: "0.7",
                        width: "15px",
                        height: "15px",
                      }}
                      fontSize="small"
                      color="action"
                    />
                    {timeStr}
                  </Typography>
                ) : null}
              </Stack>
            </React.Fragment>
          }
        />
      </ListItem>
      <Divider />
    </>
  );
};

export const UserDetailsDialog = ({ onUserDetailClose, userDetailView }) => {
  let lastEvent = userDetailView?.[0] || {};
  let { type } = lastEvent;
  let mainEvent =
    userDetailView?.find((obj) => mainSocketEvents?.includes(obj?.type)) || {};
  let { ua, user_name, user_id } = lastEvent || {};
  let visitorName = user_name?.trim() || randomVisitorName(user_id || "");
  let { location } = mainEvent || {};
  let { city, region, country } = location || {};
  let countryCode = location?.country;
  let url = `https://flagcdn.com/w40/${countryCode?.toLowerCase()}.png`;
  const userLoaction = `${city ? `${city},` : ""} ${
    region ? `${region},` : ""
  } ${country ? `${country}` : ""}`;
  let onlineTime = getRelativeTimeDifference(
    new Date(validateNumber(mainEvent?.time) * 1000)
  );
  let userAgent = ua ? new UAParser(ua).getResult() : false;
  userAgent = userAgent || {};
  let { browser, os } = userAgent;
  let isFromLaptop =
    os && os.name && (os.name === "Mac OS" || os.name === "Windows");
  const theme = useTheme();
  const isIpad = useMediaQuery(theme.breakpoints.down("md"));
  let events = userDetailView;
  const [topScore, setTopScore] = useState(0);
  const [score, setScore] = useState(0);

  const visitorLeadScores = useSelector(
    (state) => state.visitor.visitorLeadScores
  );

  useEffect(() => {
    if (isObjWithValues(visitorLeadScores)) {
      let { customer = {}, lead = {} } = visitorLeadScores;

      // console.log(customer?.[user_id], lead?.[user_id]);
      let userObj = customer?.[user_id] || lead?.[user_id];
      if (userObj) setScore(userObj?.score);
      else setScore(0);
      // let customers = [];
      customer = [...(Object.keys(customer) || [])]?.map((i) => ({
        ...customer?.[i],
        id: i,
        type: "customer",
      }));
      lead = [...(Object.keys(lead) || [])]?.map((i) => ({
        ...lead?.[i],
        id: i,
        type: "lead",
      }));
      let allCustomers = Object.values(customer).concat(Object.values(lead));
      // if (selectedTab === "all") customers = allCustomers;
      // else if (selectedTab === "customer") customers = Object.values(customer);
      // else if (selectedTab === "lead") customers = Object.values(lead);
      // setCustomers(customers.sort((a, b) => b.score - a.score));
      setTopScore(Math.max(...allCustomers?.map((o) => o.score)));
    } else {
      setTopScore(0);
      setScore(0);
    }
  }, [visitorLeadScores, user_id]);

  return (
    <React.Fragment>
      <IconButton
        sx={{
          position: "absolute",
          // transform: "translateY(-40px)",
          // transitionDelay: "4s",
          top: "-50px",
          right: "10px",
          backgroundColor: "background.paper",
          "&:hover": {
            backgroundColor: "background.paper",
          },
        }}
        size="small"
        onClick={onUserDetailClose}
      >
        <Close />
      </IconButton>
      <Card sx={{ overflow: "auto" }}>
        <CardContent>
          <Grid container spacing={2} alignItems="center" pb={2}>
            <Grid item xs={12} sm={12}>
              <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Stack direction="row" spacing={2} alignItems={"center"}>
                  <Avatar sx={{ width: 45, height: 45 }} />
                  <Box sx={{ ml: 2, flex: 1 }}>
                    <Stack direction="row" alignItems={"center"} gap={2}>
                      <Typography
                        variant="body1"
                        fontWeight={"bold"}
                        fontSize="15px"
                      >
                        {visitorName}
                      </Typography>
                      {countryCode ? (
                        <img
                          src={url}
                          style={{
                            // borderRadius: "50%",
                            height: "15px",
                            width: "23px",
                            borderRadius: "15%",
                          }}
                        />
                      ) : null}
                    </Stack>
                    <Stack direction={"row"} alignItems="center">
                      <Typography variant="h6" fontSize="12px">
                        {formatServerValue(type || "")?.split(" ")?.[0]}
                        {/* {from === "app" ? "Retailer App" : "Website"} */}
                      </Typography>
                    </Stack>
                  </Box>

                  <OpportunityLeadScore
                    score={score}
                    topScore={topScore}
                    leadScoreUI="2"
                  />
                </Stack>
                {isIpad && (
                  <IconButton
                    sx={{
                      // position: "absolute",
                      // transform: "translateY(-40px)",
                      // transitionDelay: "4s",
                      // top: "-50px",
                      // right: "10px",
                      backgroundColor: "background.paper",
                      "&:hover": {
                        backgroundColor: "background.paper",
                      },
                    }}
                    size="small"
                    onClick={onUserDetailClose}
                  >
                    <Close />
                  </IconButton>
                )}
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6}>
              {/* <Typography sx={{ ...primaryLabel, textAlign: "center" }}>
                Customer journey
              </Typography> */}
              {/* <Button
                variant="contained"
                sx={{ px: 6 }}
                onClick={() => navigate(`/crm/${user_id}#chats`)}
                fullWidth
              >
                Start Chat
              </Button> */}
            </Grid>
          </Grid>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={6} my={5} px={5}>
              <Grid container spacing={5}>
                <Grid item xs={12} sm={6} md={6}>
                  <UserDetailInfoCard
                    Icon={LocationOnOutlined}
                    label={userLoaction || "Unavailable"}
                    // label={ip || ""}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <UserDetailInfoCard
                    Icon={AccessTimeOutlined}
                    label={
                      isObjWithValues(onlineTime)
                        ? `${onlineTime.value} ${onlineTime.label}`
                        : "N/A"
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <UserDetailInfoCard
                    Icon={InputOutlined}
                    label={getLabelFromType(mainEvent?.type || lastEvent?.type)}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <UserDetailInfoCard
                    Icon={!isFromLaptop ? PhoneIphone : LaptopMac}
                    label={
                      !browser && !os
                        ? "Unavailable"
                        : `${browser?.name ? `${browser.name},` : ""} ${
                            os?.name ? os.name : ""
                          }`.trim() || "Unavailable"
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              sx={{ maxHeight: "250px", overflowY: "scroll", pt: 0 }}
              className="scrollbar-hidden"
            >
              <Typography sx={{ ...secondaryLabel, textAlign: "center" }}>
                Customer journey
              </Typography>
              <UserTimeline events={events} />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </React.Fragment>
  );
};

const UserDetailInfoCard = ({ Icon, label }) => {
  return (
    <Card sx={{ backgroundColor: "background.default", borderRadius: "8px" }}>
      <CardContent sx={{ "&:last-child": { paddingBottom: "16px" } }}>
        <Stack justifyContent={"center"} alignItems="center">
          <Icon style={{ height: "40px", width: "40px" }} />
          <Typography mt={2} varinat="h6">
            {label}
          </Typography>
        </Stack>
      </CardContent>
    </Card>
  );
};
