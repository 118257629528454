import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";

import {
  Alert,
  Button,
  Collapse,
  Paper,
  Slide,
  Snackbar,
  Stack,
  TextField,
  Typography,
  Box,
  CardContent,
  Card,
} from "@mui/material";

import { ReactComponent as Logo } from "../../vendor/logo.svg";
import ResetPasswordComponent from "../../components/auth/ResetPassword";
import { getAxiosError, mainWebsite, validateEmail } from "../../helper";
import axios from "axios";
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router-dom";

const Brand = styled(Box)`
  fill: ${(props) => props.theme.palette.primary.main};
  width: 64px;
  height: 64px;
  margin-bottom: 32px;
`;

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;

function ResetPassword() {
  const [alert, setAlert] = useState({ show: false });
  const [loading, setLoading] = useState(false);
  const [OTPSent, setOTPSent] = useState(false);
  const [OTP, setOTP] = useState("");
  const [resentCount, setResetCount] = useState(null);
  const [checkingMail, setCheckingMail] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [email, setEmail] = useState("");
  const [toast, setToast] = useState({ show: false });
  const navigate = useNavigate();
  let resentTimeout;

  useEffect(() => {
    if (alert.show) setAlert({ ...alert, show: false });
  }, [email, newPassword, confirmPassword, OTP]);

  useEffect(() => {
    if (resentCount)
      resentTimeout = setTimeout(() => {
        if (resentCount === 0) {
          clearTimeout(resentTimeout);
          setResetCount("");
        }
        let num = resentCount - 1;
        setResetCount(num);
      }, 1000);
    return () => clearTimeout(resentTimeout);
  }, [resentCount]);

  const onEmailSent = () => {
    setLoading(false);
    setOTPSent(true);
    setResetCount(30);
  };

  const onResendEmail = () => {
    onEmailVerity(email, onEmailSent, setLoading, setAlert);
  };

  const handleEnter = (event) => {
    if (event.key === "Enter") {
      onEmailVerity(email, onEmailSent, setLoading, setAlert);
    }
  };

  const afterOTPVerify = () => {
    setShowNewPassword(true);
  };

  const onSubmit = () => {
    if (OTPSent) onOtpVerify(email, OTP, setLoading, afterOTPVerify, setAlert);
    else onEmailVerity(email, onEmailSent, setLoading, setAlert);
  };

  const onCreatePassword = () => {
    if (newPassword.length < 6)
      return setAlert({
        show: true,
        severity: "error",
        message: "Enter atlease 6 characters",
      });
    if (newPassword !== confirmPassword)
      return setAlert({
        show: true,
        severity: "error",
        message: "Password does not match!",
      });

    onPasswordUpdate({
      email,
      newPassword,
      setLoading,
      setAlert,
      otp: OTP,
      callback: onPasswordCreated,
    });
  };

  const onPasswordCreated = () => {
    setAlert({
      show: true,
      severity: "success",
      message: "Password updated successfully!",
    });
    setTimeout(() => navigate("/auth/sign-in", { state: { email } }), 1500);
  };

  return (
    <React.Fragment>
      <Box sx={{ mb: 3 }}>
        <img
          src="https://tanika.tech/wp-content/uploads/2021/04/new-logo.png"
          style={{
            height: "54px",
            width: "170px",
          }}
        />
      </Box>
      <Card sx={{ mx: 3, py: 4 }}>
        <CardContent>
          <Helmet title="Reset Password" />
          <Snackbar
            open={toast.show}
            autoHideDuration={3000}
            anchorOrigin={{ vertical: "top", horizontal: "left" }}
            TransitionComponent={Slide}
            style={{ marginRight: "2vw" }}
            onClose={() => setToast({ ...toast, show: false })}
          >
            <Alert severity={toast.severity} sx={{ width: "100%" }}>
              {toast.message}
            </Alert>
          </Snackbar>
          <Typography component="h1" variant="h4" align="center" gutterBottom>
            Reset Password
          </Typography>
          <Typography component="h2" variant="body1" align="center">
            Enter your email to reset your password
          </Typography>
          <Collapse in={alert.show}>
            <Alert severity={alert.severity} sx={{ mt: 3 }}>
              {alert.message}
            </Alert>
          </Collapse>
          <Collapse in={!showNewPassword}>
            <TextField
              type="email"
              name="email"
              label="Email Address"
              value={email}
              // error={Boolean(touched.email && errors.email)}
              fullWidth
              // helperText={touched.email && errors.email}
              // onBlur={handleBlur}
              onChange={(e) => setEmail(e.target.value)}
              onKeyDown={handleEnter}
              sx={{ mt: 6 }}
            />
            <Collapse in={OTPSent}>
              <TextField
                type="number"
                name="otp"
                label="One Time Password"
                value={OTP}
                // error={Boolean(touched.email && errors.email)}
                fullWidth
                // helperText={touched.email && errors.email}
                // onBlur={handleBlur}
                onChange={(e) => setOTP(e.target.value)}
                sx={{ mt: 4 }}
              />
              <Stack direction="row" alignItems={"center"} mt={3}>
                <Typography variant="body2"> Didn't receive mail?</Typography>
                <Button
                  variant="text"
                  disabled={resentCount}
                  disableRipple
                  onClick={onResendEmail}
                >
                  (Resend email {resentCount ? `in ${resentCount}` : ""})
                </Button>
              </Stack>
            </Collapse>
            <LoadingButton
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              loading={loading}
              onClick={onSubmit}
              sx={{ mt: 6 }}
            >
              {OTPSent ? "Verify OTP" : "Reset password"}
            </LoadingButton>
          </Collapse>
          <Collapse in={showNewPassword}>
            <TextField
              type="password"
              name="otp"
              label="New password"
              value={newPassword}
              // error={Boolean(touched.email && errors.email)}
              fullWidth
              // helperText={touched.email && errors.email}
              // onBlur={handleBlur}
              onChange={(e) => setNewPassword(e.target.value)}
              sx={{ mt: 4 }}
            />
            <TextField
              type="password"
              name="otp"
              label="Confirm password"
              value={confirmPassword}
              // error={Boolean(touched.email && errors.email)}
              fullWidth
              // helperText={touched.email && errors.email}
              // onBlur={handleBlur}
              onChange={(e) => setConfirmPassword(e.target.value)}
              sx={{ mt: 4 }}
            />
            <LoadingButton
              type="Create Password"
              fullWidth
              variant="contained"
              color="primary"
              loading={loading}
              onClick={onCreatePassword}
              sx={{ mt: 4 }}
            >
              {OTPSent ? "Verify OTP" : "Reset password"}
            </LoadingButton>
          </Collapse>
          {/* <ResetPasswordComponent /> */}
        </CardContent>
      </Card>
    </React.Fragment>
  );
}

export default ResetPassword;

const onEmailVerity = async (email, callback, setLoading, setAlert) => {
  email = email.trim();
  if (!email)
    return setAlert({
      show: true,
      severity: "error",
      message: "Email required!",
    });
  if (!validateEmail(email))
    return setAlert({
      show: true,
      severity: "error",
      message: "Invalid email!",
    });
  setLoading(true);
  let formData = new FormData();
  formData.append("email", email);
  let requestOptions = {
    method: "POST",
    url: `${mainWebsite}/wp-json/bdpwr/v1/reset-password`,
    data: formData,
    // redirect: 'follow',
  };
  axios(requestOptions)
    .then((res) => {
      console.log(res);
      setAlert({
        show: true,
        severity: "success",
        message: "Check your mail!",
      });
      setTimeout(() => {
        setAlert({
          show: false,
          severity: "success",
          message: "Check your mail!",
        });
      }, 2000);
      callback && callback();
    })
    .catch((e) => {
      console.log(e, "EMAIL VERIFY ERROR");
      e = getAxiosError(e);
      if (e && e.code === "bad_email")
        setAlert({
          show: true,
          severity: "error",
          message: "Email not found!",
        });
      if (e && e.code === "invalid_json")
        setAlert({
          show: true,
          severity: "error",
          message: "Invalid credentials!",
        });
      // this.setState({ loading: false });
    })
    .finally(() => setLoading(false));
};
const onOtpVerify = (email, otp, setLoading, callback, setAlert) => {
  console.log(email, otp);
  if (!otp)
    return setAlert({ show: true, severity: "error", message: "Enter OTP!" });
  let formData = new FormData();
  formData.append("email", email);
  formData.append("code", Number(otp));
  setLoading(true);
  console.log(formData);
  let requestOptions = {
    method: "POST",
    url: `${mainWebsite}/wp-json/bdpwr/v1/validate-code`,
    data: formData,
    // redirect: 'follow',
  };
  axios(requestOptions)
    .then((res) => {
      console.log(res);
      callback && callback();
    })
    .catch((e) => {
      let error = getAxiosError(e);
      if (error.message)
        return setAlert({
          show: true,
          severity: "error",
          message: error.message,
        });
      console.log(getAxiosError(e), "OTP ERROR");
    })
    .finally(() => setLoading(false));
};

const onPasswordUpdate = (obj) => {
  let { newPassword, email, otp, callback, setAlert, setLoading } = obj;
  setLoading(true);
  let formData = new FormData();
  formData.append("email", email);
  formData.append("code", Number(otp));
  formData.append("password", newPassword);
  let requestOptions = {
    method: "POST",
    url: `${mainWebsite}/wp-json/bdpwr/v1/set-password`,
    data: formData,
    // redirect: 'follow',
  };
  axios(requestOptions)
    .then((res) => {
      // console.log(res);

      callback && callback();
    })
    .catch((e) => {
      console.log(getAxiosError(e));
    })
    .finally(() => setLoading(false));
};
