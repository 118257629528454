import axios from "axios";
import { isObjWithValues } from "../../helper";

export const SET_PERSIST_DATA = "SET_PERSIST_DATA";
export const GET_COUNTRIES_DATA = "GET_COUNTRIES_DATA";

export const setPersistData = (payload) => {
  return {
    type: "SET_PERSIST_DATA",
    payload,
  };
};

export const getDropdownData = () => {
  return (dispatch) => {
    dispatch({
      type: GET_COUNTRIES_DATA,
      payload: {
        fetchingCountries: true,
        countries: [],
      },
    });
    axios({
      url: `https://gist.githubusercontent.com/akshy78695/54136a0b7075095a9bcd8a3b2b30b0b4/raw/f1cc92a907b9e47665bb1cdfb78ccd7cdac89c6d/dropdownData3.json`,
    }).then((res) => {
      if (isObjWithValues(res.data)) {
        dispatch({
          type: GET_COUNTRIES_DATA,
          payload: {
            countries: res.data.countries,
            occupations: res.data.occupations,
            states: res.data.states,
            fetchingCountries: false,
          },
        });
      }
    });
  };
};
