import { Box, Link, Typography } from "@mui/material";
import React from "react";
import { ListItem } from "../meta/MetaOverview";

const feature = [
  "👉 Realtime Price Update: With Jwero's integration with Shopify, updating pricing for metal, labor, or diamond is a breeze! No need to manually update each and every price on Shopify. :woman-gesturing-no:Just one update in Jwero, and pricing will be auto-updated in real-time on Shopify and across all other channels where your products are listed. :rocket:",
  "👉 Seamless inventory management: With Shopify and Jwero integration, you can manage your inventory seamlessly. You can update your product information, pricing, and stock levels in real-time, and ensure that your online store is always up-to-date with your physical inventory.",
  "👉 Automatic order fulfillment: Jwero’s integration with Shopify allows you to automate your order fulfillment process. Whenever a customer places an order on your Shopify store, Jwero automatically picks up the order and processes it, reducing the time and effort required to manually fulfill orders.",
  "👉 Multi-channel sales: Jwero allows you to sell your products on multiple channels, including your own online store, marketplaces, and social media platforms. With Shopify integration, you can easily manage your products and orders across all your sales channels, and keep track of your inventory levels.",
  "👉 Improved customer experience: With Jwero and Shopify integration, you can offer a seamless customer experience, from browsing and purchasing to order fulfillment and shipping. This can lead to higher customer satisfaction and increased loyalty, as customers will appreciate the convenience and ease of shopping on your store.",
  // "👉Easy to integrate",
  // "👉Seamless, secure and fast payments",
  // "👉Credt/Debit Card,UPI,net banking and 100+ options",
  // "👉Better conversion rate",
  // "👉Major International cards accepted",
  // "👉Zero Setup Cost",
];
const ShopifyOverview = () => {
  return (
    <Box sx={{ p: 3, mb: 3 }}>
      <Typography variant="subtitle1" sx={{ fontSize: "16px", mb: 4 }}>
        Benefits of Integrating Shopify with Jwero
      </Typography>

      <Typography variant="subtitle2" sx={{ fontSize: "16px", mb: 4 }}>
        Features of Shopify
      </Typography>
      <ListItem arr={feature} />
      {/* <Typography sx={{ fontSize: "16px", mt: 3 }}>
        📨 Send your queries{" "}
        <Link
          href="https://razorpay.com/support/?utm_referrer=&referral_code=meetanshi7m92j"
          style={{ fontSize: "16px" }}
        >
          Contact RazorPay
        </Link>
      </Typography>
      <Typography sx={{ fontSize: "16px", mt: 3 }}>
        📞 Contact number:
        <Link href="tel:+18001231272" style={{ fontSize: "16px" }}>
          {" "}
          1800-123-1272
        </Link>
      </Typography> */}
    </Box>
  );
};

export default ShopifyOverview;
