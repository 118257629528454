export const shopifyProductsMappingArray = [
  {
    jwero_key: [
      {
        key: "images",
        data_type: "array",
        array_obj_key: "src",
        array_obj_value: "src",
        array_map: [{ from: "src", to: "src" }],
      },
    ],
    key: [
      {
        key: "images",
        data_type: "array",
        array_map: [{ from: "src", to: "src" }],
      },
    ],
  },
  {
    jwero_key: [
      {
        key: "name",
        data_type: "string",
      },
    ],
    key: [
      {
        key: "title",
        data_type: "string",
      },
    ],
  },
  {
    jwero_key: [
      {
        key: "tags",
        data_type: "array",
        array_obj_key: "name",
        array_obj_value: "name",
      },
    ],
    key: [
      {
        key: "tags",
        data_type: "string",
        key_to_map: "name",
      },
    ],
  },
  {
    jwero_key: [
      {
        key: "description",
        data_type: "string",
      },
    ],
    key: [
      {
        key: "body_html",
        data_type: "string",
      },
    ],
  },
  {
    jwero_key: [
      {
        key: "sku",
        data_type: "string",
      },
    ],
    key: [
      {
        key: "variants",
        data_type: "array",
        array_obj_key: "sku",
        array_obj_value: "sku",
        index: 0,
      },

      {
        key: "sku",
        data_type: "string",
      },
    ],
  },
  {
    jwero_key: [
      {
        key: "priceBreakup",
        data_type: "object",
      },
      {
        key: "price",
        data_type: "string",
      },
    ],
    key: [
      {
        key: "variants",
        data_type: "array",
        array_obj_key: "price",
        array_obj_value: "price",
        index: 0,
      },

      {
        key: "price",
        data_type: "string",
      },
    ],
  },
  {
    jwero_key: [
      {
        key: "priceBreakup",
        data_type: "object",
      },
      {
        key: "totalPrice",
        data_type: "string",
      },
    ],
    key: [
      {
        key: "variants",
        data_type: "array",
        array_obj_key: "compare_at_price",
        array_obj_value: "compare_at_price",
        index: 0,
      },
      {
        key: "compare_at_price",
        data_type: "string",
      },
    ],
  },
];
export const wooCommerceProductsMappingArray = [
  {
    jwero_key: [
      {
        key: "images",
        data_type: "array",
        array_obj_key: "src",
        array_obj_value: "src",
        array_map: [{ from: "src", to: "src" }],
      },
    ],
    key: [
      {
        key: "images",
        data_type: "array",
        array_obj_key: "src",
        array_obj_value: "src",
        array_map: [{ from: "src", to: "src" }],
      },
    ],
  },
  {
    jwero_key: [
      {
        key: "name",
        data_type: "string",
      },
    ],
    key: [
      {
        key: "name",
        data_type: "string",
      },
    ],
  },
  {
    jwero_key: [
      {
        key: "tags",
        data_type: "array",
        array_obj_key: "name",
        array_obj_value: "name",
        array_map: [{ from: "name", to: "name" }],
      },
    ],
    key: [
      {
        key: "tags",
        data_type: "string",
        // key_to_map: "name",
      },
      //   {
      //     key: "tags",
      //     data_type: "array",
      //     array_obj_key: "name",
      //     array_obj_value: "name",
      //     array_map: [{ from: "name", to: "name" }],
      //   },
      //   {

      //   }
    ],
  },
  {
    jwero_key: [
      {
        key: "description",
        data_type: "string",
      },
    ],
    key: [
      {
        key: "short_description",
        data_type: "string",
      },
    ],
  },
  {
    jwero_key: [
      {
        key: "sku",
        data_type: "string",
      },
    ],
    key: [
      {
        key: "sku",
        data_type: "string",
      },
    ],
  },
  {
    jwero_key: [
      {
        key: "priceBreakup",
        data_type: "object",
      },
      {
        key: "price",
        data_type: "string",
      },
    ],
    key: [
      // {
      //     key: "variants",
      //     data_type: "array",
      //     array_obj_key: "price",
      //     array_obj_value: "price",
      //     index: 0,
      // },

      {
        key: "price",
        data_type: "string",
      },
    ],
  },
  {
    jwero_key: [
      {
        key: "priceBreakup",
        data_type: "object",
      },
      {
        key: "totalPrice",
        data_type: "string",
      },
    ],
    key: [
      // {
      //     key: "variants",
      //     data_type: "array",
      //     array_obj_key: "price",
      //     array_obj_value: "price",
      //     index: 0,
      // },

      {
        key: "regular_price",
        data_type: "string",
      },
    ],
  },
  // {
  //     jwero_key: [
  //         {
  //             key: "priceBreakup",
  //             data_type: "object",
  //         },
  //         {
  //             key: "totalPrice",
  //             data_type: "string",
  //         },
  //     ],
  //     key: [
  //         {
  //             key: "variants",
  //             data_type: "array",
  //             array_obj_key: "compare_at_price",
  //             array_obj_value: "compare_at_price",
  //             index: 0,
  //         },
  //         {
  //             key: "compare_at_price",
  //             data_type: "string",
  //         },
  //     ],
  // },
];
export const jemisysMapping = [
  // {
  //   jwero_key: [
  //     {
  //       key: "meta_data",
  //       data_type: "array",
  //       array_obj_key: "key",
  //       array_obj_value: "value",
  //       array_map: [{ from: "goldType", to: "value" }],
  //     },

  //     {
  //       key: "gold_kt",
  //       data_type: "string",
  //     }
  //   ],
  //   key: [
  //     {
  //       key: "goldType",
  //       data_type: "string",

  //     },
  //   ],
  // },
  {
    jwero_key: [
      {
        key: "meta_data",
        data_type: "array",
        array_obj_key: "key",
        array_obj_value: "value",
        array_map: [{ from: "making", to: "value" }],
      },

      {
        key: "minimum_making",
        data_type: "string",
      }
    ],
    key: [
      {
        key: "making",
        data_type: "string",

      },
    ],
  },
  {
    jwero_key: [
      {
        key: "meta_data",
        data_type: "array",
        array_obj_key: "key",
        array_obj_value: "value",
        array_map: [{ from: "making", to: "value" }],
      },

      {
        key: "minimum_labour",
        data_type: "string",
      }
    ],
    key: [
      {
        key: "making",
        data_type: "string",

      },
    ],
  },
  {
    jwero_key: [
      {
        key: "meta_data",
        data_type: "array",
        array_obj_key: "key",
        array_obj_value: "value",
        array_map: [{ from: "basePrice", to: "value" }],
      },

      {
        key: "manual_price",
        data_type: "string",
      }
    ],
    key: [
      {
        key: "basePrice",
        data_type: "string",

      },
    ],
  },


  {
    jwero_key: [
      {
        key: "name",
        data_type: "string",
      },
    ],
    key: [
      {
        key: "productName",
        data_type: "string",
      },
    ],
  },
  {
    key: [
      {
        key: "category",
        data_type: "string",
      },
    ],
    jwero_key: [
      {
        key: "category",
        data_type: "array",
        index: 0
      },
    ],
  },
  {
    key: [
      {
        key: "collections",
        data_type: "string",
      },
    ],
    jwero_key: [
      {
        key: "collections",
        data_type: "array",
        index: 0
      },
    ],
  },
  {
    key: [
      {
        key: "subCategory",
        data_type: "string",
      },
    ],
    jwero_key: [
      {
        key: "subCategory",
        data_type: "array",
        index: 0
      },
    ],
  },
  {
    key: [
      {
        key: "seoTile",
        data_type: "string",
      },
    ],
    jwero_key: [
      {
        key: "meta_data",
        data_type: "array",
        array_obj_key: "key",
        array_obj_value: "value",
        index: 0
      },
      { key: "seo", data_type: "object", object_key: "seo_title" },
    ],
  },

  {
    jwero_key: [
      {
        key: "description",
        data_type: "string",
      },
    ],
    key: [
      {
        key: "description",
        data_type: "string",
      },
    ],
  },
  {
    jwero_key: [
      {
        key: "sku",
        data_type: "string",
      },
    ],
    key: [
      {
        key: "productCode",
        data_type: "string",
      },
    ],
  },
  // {
  //   jwero_key: [
  //     {
  //       key: "meta_data",
  //       data_type: "array",
  //       array_obj_key: "key",
  //       array_obj_value: "value",
  //       array_map: [{ from: "gold_gross", to: "value" }],
  //     },

  //     {
  //       key: "gold_gross",
  //       data_type: "string",
  //     }
  //   ],
  //   key: [
  //     {
  //       key: "grams",
  //       data_type: "string",

  //     },
  //   ],
  // },

]