import {
  Alert,
  Avatar,
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Link,
  Slide,
  Snackbar,
  Box,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
  Stack,
  useMediaQuery,
  Autocomplete,
  Collapse,
  Checkbox,
  Tooltip,
} from "@mui/material";
import ButtonUnstyled from "@mui/base/ButtonUnstyled";
import React, {
  useMemo,
  useRef,
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from "react";
import { Helmet } from "react-helmet-async";
import { NavLink, useNavigate } from "react-router-dom";
import AddPhotoAlternateOutlinedIcon from "@mui/icons-material/AddPhotoAlternateOutlined";
import { LoadingButton } from "@mui/lab";
import { FileUploader } from "react-drag-drop-files";
import axios from "axios";
import {
  getAxiosError,
  getRandomString,
  getShortRefLink,
  getToken,
  getWebsite,
  isArrayWithValues,
  isObjWithValues,
  validateEmail,
} from "../../helper";
import { useDispatch, useSelector } from "react-redux";
import { SET_CRM_DATA, unshiftCustomer } from "../../redux/actions/crmActions";
import { useTheme } from "@mui/system";
import { CheckCircle } from "@mui/icons-material";
import {
  getDropdownData,
  getProductGroups,
} from "../../redux/actions/settingActions";
import Switch from "rc-switch";
import "rc-switch/assets/index.css";
import { jobRoles } from "./View";

const defaultUser = {
  first_name: "",
  last_name: "",
  email: "",
  full_name: "",
  company_name: "",
  gst: "",
  landline: "",
  whatsapp: "",
  whatsapp_alt: "",
  contact_1: "",
  contact_2: "",
  billing_mobile: "",
  customer_group: "",
  address_1: "",
  address_2: "",
  city: "",
  state: "",
  postcode: "",
  country: "",
  product_groups: [],
  access: [],
  whatsapp_dial: { label: "🇮🇳 India (+91)", value: "91" },
  contact_1_dial: { label: "🇮🇳 India (+91)", value: "91" },
  contact_2_dial: { label: "🇮🇳 India (+91)", value: "91" },
  whatsapp_alt_dial: { label: "🇮🇳 India (+91)", value: "91" },
  all_product_access: false,
  send_invite: false,
  vendor_type: "",
  job_role: "",
};

function Add() {
  const [user, setUser] = useState(defaultUser);
  const [profile, setProfile] = useState("");
  const [uploadingImage, setUploadingImage] = useState(false);
  const [imageURL, setImageURL] = useState("");
  const [toast, setToast] = useState({ show: false });
  const [creatingCustomer, setCreatingCustomer] = useState(false);
  const retailerInvites = useSelector(
    (state) => state.customers.retailer_invites
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const editUser = (payload) => {
    setUser({ ...user, ...payload });
  };
  const BasicDetailsRef = useRef(null);

  // useEffect(() => {
  //   if (!isArrayWithValues(retailerInvites)) dispatch(getRetailerInvites());
  // }, []);

  const uploadImage = (profile) => {
    setUploadingImage(true);
    const formData = new FormData();
    formData.append("file", profile, profile.name);

    const website = getWebsite();
    const token = getToken();
    if (website && token)
      axios
        .post(`${website}/wp-json/wp/v2/media`, formData, {
          headers: {
            accept: "application/json",
            Authorization: `Basic ${token}`,
            "Content-Type": `multipart/form-data`,
          },
        })
        .then((res) => {
          console.log(res.data);
          let { data } = res;
          if (data.source_url) setImageURL(data.source_url);
        })
        .catch((e) => console.log(getAxiosError(e)))
        .finally((e) => setUploadingImage(false));
  };

  const onAddUser = async (obj) => {
    let { meta_data } = obj;
    if (Array.isArray(meta_data) && imageURL)
      meta_data.push({ key: "profile_url", value: imageURL });
    if (Array.isArray(meta_data)) {
      let uid = getRandomString(14);
      meta_data.push({
        key: "retailerUniqueID",
        value: uid,
      });
      meta_data.push({
        key: "referral_link",
        value: await getShortRefLink({
          type: "supplier",
          by: "1234",
          retailerUniqueID: uid,
        }),
      });
    }
    console.log(JSON.stringify(obj, null, 2));
    let sendInvitation = obj.send_invite;
    return;
    try {
      setCreatingCustomer(true);
      const res = await createCustomer(obj, setCreatingCustomer);
      if (res && res.success) {
        dispatch(unshiftCustomer(res.data));
        if (sendInvitation) {
          await sendInvitationRetailer(res.data.id);
          await addRetailerIdInInvite(retailerInvites, res.data.id);
        }
        setCreatingCustomer(false);
        setToast({
          show: true,
          message: `User Added ${
            sendInvitation ? "and Invited" : ""
          } Successfully!`,
          severity: "success",
        });
        navigate("/customers");
      } else {
        setCreatingCustomer(false);
        if (res.code && res.code === "registration-error-email-exists")
          setToast({
            show: true,
            message: "Email already exist, please use another!",
            severity: "error",
          });
        else
          setToast({
            show: true,
            message: "Couldn't add user, please try again!",
            severity: "error",
          });
      }
    } catch (error) {
      console.log(getAxiosError(error));
      setToast({
        show: true,
        message: "Couldn't add user, please try again!",
        severity: "error",
      });
    }
  };
  return (
    <React.Fragment>
      <Snackbar
        open={toast.show}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        TransitionComponent={Slide}
        style={{ marginRight: "2vw" }}
        onClose={() => setToast({ ...toast, show: false })}
      >
        <Alert severity={toast.severity} sx={{ width: "100%" }}>
          {toast.message}
        </Alert>
      </Snackbar>
      <Grid justifyContent="space-between" container spacing={2} p={6}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Add User
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/">
              Dashboard
            </Link>
            <Link component={NavLink} to="/customers">
              Customers
            </Link>
            {/* <Link component={NavLink} to="/">
              Products
            </Link> */}
            <Typography>Add</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item></Grid>
      </Grid>
      <Grid container spacing={0}>
        <Grid item xs={12} sm={12}>
          <PrimaryCard
            user={user}
            imageURL={imageURL}
            uploadImage={uploadImage}
            uploadingImage={uploadingImage}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <BasicDetails
            // ref={BasicDetailsRef}
            ref={BasicDetailsRef}
            user={user}
            onAddUser={onAddUser}
            creatingCustomer={creatingCustomer}
          />
          {/* {rednerBasic()} */}
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

const PrimaryCard = ({
  user,
  editUser,
  imageURL,
  uploadImage,
  uploadingImage,
}) => {
  let { first_name, last_name, email } = user;
  const inputFile = useRef(null);
  return (
    <Card sx={{ borderRadius: 0 }}>
      <CardContent sx={{ p: 0 }}>
        <input
          type="file"
          id="file"
          onChange={(e) => uploadImage(e.target.files[0])}
          ref={inputFile}
          style={{ display: "none" }}
        />
        <div
          style={{
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            marginTop: "15px",
          }}
        >
          {/* <FileUploader
            name="file"
            types={["JPG", "PNG"]}
            handleChange={(e) => console.log(e.target.files[0])}
            children={
              <>
              </>
            }
          /> */}
          <Button
            style={{
              height: "8rem",
              width: "8rem",
              alignSelf: "center",
              margin: "14px auto",
              // display: "block",
              justifyContent: "center",
              alignItems: "center",
              position: "relative",
              cursor: "pointer",
              borderRadius: "50%",
              overflow: "hidden",
              backgroundColor: "#ededed",
              boxShadow: "1px 1px 5px 0px rgba(0,0,0,0.2)",
            }}
            sx={{ p: 0 }}
            onClick={() => inputFile.current.click()}
          >
            <Box
              style={{ alignSelf: "center", margin: "auto" }}
              sx={{}}
              color="GrayText"
            >
              {!imageURL && !uploadingImage ? (
                <>
                  <AddPhotoAlternateOutlinedIcon
                    style={{ height: "30px", width: "30px" }}
                  />
                  <Typography variant="body2" fontSize={12} fontWeight="bold">
                    Upload photo
                  </Typography>
                </>
              ) : null}
              {imageURL && !uploadingImage ? (
                <img
                  src={
                    imageURL
                    // "https://res.cloudinary.com/minimal-ui/image/upload/v1614655910/upload_minimal/avatar/minimal_avatar.jpg"
                  }
                  style={{
                    height: "8rem",
                    width: "8rem",
                    // zIndex: -10,
                    // position: "relative",
                  }}
                />
              ) : null}
              {uploadingImage ? (
                <Box sx={{}}>
                  <CircularProgress size="18px" />
                  <Typography
                    variant="body2"
                    fontSize={12}
                    color="primary"
                    fontWeight="bold"
                  >
                    Uploading photo
                  </Typography>
                </Box>
              ) : null}
            </Box>
          </Button>
          <Typography variant="body2" color="text.secondary" fontSize={12}>
            Allowed *.jpeg, *.jpg, *.png
          </Typography>
          <Typography variant="body2" color="text.secondary" fontSize={12}>
            max size of 2 MB
          </Typography>
          {/* <div>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    // checked={catalogForm.public}
                    // onChange={(e) =>
                    //   changeCatalogForm({
                    //     public: e.target.checked,
                    //   })
                    // }
                    defaultChecked
                    color="primary"
                  />
                }
                label="Verified"
                labelPlacement="start"
                style={{
                  justifyContent: "space-between",
                  padding: "14px 24px",
                  marginLeft: "0px",
                }}
              />
            </FormGroup>
          </div> */}
        </div>
      </CardContent>
    </Card>
  );
};

const AccessCard = ({ obj, isSelected, onClick, isMobile }) => {
  const padding = isMobile ? "9px" : "12px";
  return (
    <Card
      sx={{
        border: `0.5px solid`,
        borderColor: !isSelected ? "grey.500" : "priamry.main",
        margin: "6px 6px",
        overflow: "visible",
        position: "relative",
        cursor: "pointer",
      }}
      onClick={() => onClick && !obj.disabled && onClick(obj.value)}
    >
      <CardContent
        sx={{
          textAlign: "center",
          padding,
          "&:last-child": { paddingBottom: padding },
          display: "flex",
          alignItems: "center",
        }}
      >
        {/* <div
          style={{
            width: "17px",
            height: "17px",
            borderRadius: "14px",
            // background: colorsBackground[obj.value],
            // background: "linear-gradient(to right, #E1C16E,#edd595 )",
          }}
        /> */}
        {isSelected ? (
          <CheckCircle
            style={{
              position: "absolute",
              right: "-4px",
              top: "-4px",
              width: "16px",
              height: "16px",
            }}
            width={9}
            height={9}
          />
        ) : null}
        <Typography
          variant={isMobile ? "body2" : "h6"}
          sx={obj.disabled ? { color: "text.secondary" } : {}}
        >
          {obj.label}
        </Typography>
      </CardContent>
    </Card>
  );
};

const BasicDetails = forwardRef(
  ({ customer, onAddUser, creatingCustomer }, ref) => {
    const [info, setInfo] = useState(defaultUser);
    const [toast, setToast] = useState({ show: false });
    const [postcodeFetching, setPostcodeFetching] = useState(false);
    const [productGroups, setProductGroups] = useState([
      // {id: 1, enabled: true,title: "All", group_no:0}
    ]);

    const dispatch = useDispatch();
    const reduxProductGroups = useSelector(
      (state) => state.settings.productGroups
    );
    useEffect(() => {
      if (
        !info.all_product_access &&
        !isArrayWithValues(info.product_groups) &&
        info.send_invite
      )
        setInfo((state) => ({ ...state, send_invite: false }));
    }, [info]);

    useEffect(() => {
      if (isArrayWithValues(reduxProductGroups))
        setProductGroups(reduxProductGroups.filter((i) => i.enabled));
    }, [reduxProductGroups]);

    useEffect(() => {
      if (!isArrayWithValues(reduxProductGroups)) dispatch(getProductGroups());
    }, []);

    useImperativeHandle(ref, () => ({
      onUserUpdate() {
        onUpdate();
      },
    }));
    const changeInfo = (label, value) => {
      setInfo((state) => ({ ...state, [label]: value }));
    };
    const changeInfoObj = (obj) => {
      setInfo((state) => ({ ...state, ...obj }));
    };
    const onUpdate = async () => {
      const {
        first_name,
        last_name,
        email,
        address_1,
        address_2,
        company_name,
        city,
        postcode,
        country,
        state,
      } = info;
      let obj = {
        first_name,
        last_name,
        email,
        send_invite,
        billing: {
          first_name,
          last_name,
          address_1,
          address_2,
          company: company_name,
          city,
          postcode,
          country,
          state,
        },
      };
      if (!email)
        return setToast({
          show: true,
          severity: "error",
          message: "Please enter email!",
        });
      if (!validateEmail(email))
        return setToast({
          show: true,
          severity: "error",
          message: "Please enter valid email!",
        });

      obj.username = email;
      const metaKeys = [
        "company_name",
        "gst",
        "whatsapp",
        "whatsapp_alt",
        "billing_mobile",
        "contact_1",
        "contact_2",
        "customer_group",
        "access",
        "product_groups",
        // "whatsapp_alt_dial",
        // "whatsapp_dial",
        // "contact_1_dial",
        // "contact_2_dial",
        "all_product_access",
        "vendor_type",
        "job_role",
      ];
      let meta_data = [];
      for (let i = 0; i < metaKeys.length; i++) {
        if (info[metaKeys[i]])
          meta_data.push({
            key: metaKeys[i],
            value: info[metaKeys[i]],
          });
      }
      meta_data.push({
        key: "whatsapp_alt_dial",
        value: info.whatsapp_alt_dial.value,
      });
      meta_data.push({
        key: "whatsapp_dial",
        value: info.whatsapp_dial.value,
      });
      meta_data.push({
        key: "contact_1_dial",
        value: info.contact_1_dial.value,
      });
      meta_data.push({
        key: "contact_2_dial",
        value: info.contact_2_dial.value,
      });

      obj.meta_data = meta_data;
      // console.log(JSON.stringify(obj, null, 2));
      onAddUser(obj);
    };

    const handleProductGroupChange = (event) => {
      const {
        target: { value },
      } = event;
      return changeInfo(
        "product_groups",
        typeof value === "string" ? value.split(",") : value
      );
    };

    const onAccessClick = (value) => {
      if (!value) return;
      let array = [].concat(access);
      if (array.includes(value)) array = array.filter((i) => i !== value);
      else array.push(value);
      changeInfo("access", array);
    };

    const postcodeChange = (e) => {
      changeInfoObj({ postcode: e.target.value });
      setTimeout(() => {
        onPostcodeChange(e.target.value, changeInfoObj, setPostcodeFetching);
      }, 300);
    };

    // const onCityChange = (e) => {
    //   changeInfo({ city: e.target.value });
    //   if (changeCityTimeout) clearTimeout(changeCityTimeout);
    //   changeCityTimeout = setTimeout(() => {
    //     cityChange(e.target.value, changeInfo, setFetchingLocation);
    //   }, 500);
    // };

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const {
      first_name,
      last_name,
      email,
      company_name,
      gst,
      whatsapp,
      whatsapp_alt,
      contact_1,
      contact_2,
      billing_mobile,
      product_groups,
      access,
      address_1,
      address_2,
      city,
      state,
      postcode,
      country,
      whatsapp_dial,
      contact_1_dial,
      contact_2_dial,
      whatsapp_alt_dial,
      vendor_type,
      send_invite,
      job_role,
    } = info;

    let backgroundColor = theme.palette.primary && theme.palette.primary.main;
    return (
      <>
        <Snackbar
          open={toast.show}
          autoHideDuration={3000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          TransitionComponent={Slide}
          style={{ marginRight: "2vw" }}
          onClose={() => setToast({ ...toast, show: false })}
        >
          <Alert severity={toast.severity} sx={{ width: "100%" }}>
            {toast.message}
          </Alert>
        </Snackbar>
        <Card style={{ borderRadius: 0 }}>
          <CardContent>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography variant="h6">Basic details</Typography>
            </div>
            <Grid container spacing={4} mt={2}>
              <Grid item xs={6}>
                <TextField
                  label="First name"
                  fullWidth
                  onChange={(e) => changeInfo("first_name", e.target.value)}
                  value={first_name}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Last name"
                  fullWidth
                  onChange={(e) => changeInfo("last_name", e.target.value)}
                  value={last_name}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Company name"
                  fullWidth
                  onChange={(e) => changeInfo("company_name", e.target.value)}
                  value={company_name}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="GST"
                  fullWidth
                  onChange={(e) => changeInfo("gst", e.target.value)}
                  value={gst}
                />
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth sx={{}}>
                  <InputLabel id="demo-simple-select-label">
                    Vendor Type
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={vendor_type}
                    fullWidth
                    label="Vendor Type"
                    // renderValue={}
                    onChange={(e) => changeInfo("vendor_type", e.target.value)}
                    // renderValue={(value) => `+${value.value}`}
                  >
                    {vendorTypes.map((i) => {
                      return <MenuItem value={i.value}>{i.label}</MenuItem>;
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth sx={{}}>
                  <InputLabel id="demo-simple-select-label">
                    Job Role
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={job_role}
                    label="Job Role"
                    onChange={(e) => changeInfo("job_role", e.target.value)}
                  >
                    {jobRoles.map((i) => (
                      <MenuItem value={i.value}>{i.label}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Typography variant="h6">Contact Info</Typography>
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={6}>
                <TextField
                  label="Email*"
                  fullWidth
                  onChange={(e) => changeInfo("email", e.target.value)}
                  value={email}
                />
              </Grid>
              <Grid item xs={12} sm={8} md={6}>
                <Stack direction="row" alignItems={"center"} spacing={2}>
                  {/* <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={dialCodes}
                    sx={{ width: 300, flex: 1, minWidth: "120px" }}
                    // renderOption={(obj) => `+${obj.value}`}
                    renderInput={(params) => (
                      <TextField {...params} label="Dial" />
                    )}
                    clearIcon={() => <Box sx={{ width: "0px" }} />}
                    value={whatsapp_dial}
                    getOptionLabel={(option) => `+${option.value}`}
                    openOnFocus
                    renderOption={(props, obj) => (
                      <Box
                        component="li"
                        sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                        {...props}
                      >
                        {obj.label}
                      </Box>
                    )}
                    onChange={(e, value) => changeInfo("whatsapp_dial", value)}
                  /> */}
                  <FormControl sx={{ flex: 1, minWidth: "82px" }}>
                    <InputLabel id="demo-simple-select-label">Dial</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={whatsapp_dial}
                      label="Dial"
                      // renderValue={}
                      onChange={(e) =>
                        changeInfo("whatsapp_dial", e.target.value)
                      }
                      renderValue={(value) => `+${value.value}`}
                    >
                      {dialCodes.map((i) => {
                        return <MenuItem value={i}>{i.label}</MenuItem>;
                      })}
                    </Select>
                  </FormControl>
                  <TextField
                    label="Whatsapp"
                    fullWidth
                    onChange={(e) => changeInfo("whatsapp", e.target.value)}
                    value={whatsapp}
                    type="number"
                    sx={{ flex: 4 }}
                  />
                </Stack>
              </Grid>
              <Grid item xs={12} sm={4} md={6}>
                <TextField
                  label="Landline"
                  fullWidth
                  onChange={(e) => changeInfo("billing_mobile", e.target.value)}
                  value={billing_mobile}
                  type="number"
                />
              </Grid>
              <Grid item xs={12} sm={8} md={6}>
                <Stack direction={"row"} spacing={2}>
                  <FormControl sx={{ flex: 1, minWidth: "82px" }}>
                    <InputLabel id="demo-simple-select-label">Dial</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={contact_1_dial}
                      label="Dial"
                      // renderValue={}
                      onChange={(e) =>
                        changeInfo("contact_1_dial", e.target.value)
                      }
                      renderValue={(value) => `+${value.value}`}
                    >
                      {dialCodes.map((i) => {
                        return <MenuItem value={i}>{i.label}</MenuItem>;
                      })}
                    </Select>
                  </FormControl>
                  <TextField
                    label="Contact 1"
                    fullWidth
                    onChange={(e) => changeInfo("contact_1", e.target.value)}
                    value={contact_1}
                    sx={{ flex: 4 }}
                    type="number"
                  />
                </Stack>
              </Grid>
              <Grid item xs={12} md={6}>
                <Stack direction="row" spacing={2}>
                  <FormControl sx={{ flex: 1, minWidth: "82px" }}>
                    <InputLabel id="demo-simple-select-label">Dial</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={contact_2_dial}
                      label="Dial"
                      // renderValue={}
                      onChange={(e) =>
                        changeInfo("contact_2_dial", e.target.value)
                      }
                      renderValue={(value) => `+${value.value}`}
                    >
                      {dialCodes.map((i) => {
                        return <MenuItem value={i}>{i.label}</MenuItem>;
                      })}
                    </Select>
                  </FormControl>
                  <TextField
                    label="Contact 2"
                    fullWidth
                    onChange={(e) => changeInfo("contact_2", e.target.value)}
                    value={contact_2}
                    sx={{ flex: 4 }}
                    type="number"
                  />
                </Stack>
              </Grid>
              <Grid item xs={12} md={6}>
                <Stack direction={"row"} spacing={2}>
                  <FormControl sx={{ flex: 1, minWidth: "82px" }}>
                    <InputLabel id="demo-simple-select-label">Dial</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={whatsapp_alt_dial}
                      label="Dial"
                      // renderValue={}
                      onChange={(e) =>
                        changeInfo("whatsapp_alt_dial", e.target.value)
                      }
                      renderValue={(value) => `+${value.value}`}
                    >
                      {dialCodes.map((i) => {
                        return <MenuItem value={i}>{i.label}</MenuItem>;
                      })}
                    </Select>
                  </FormControl>
                  <TextField
                    label="Alternate whatsapp"
                    fullWidth
                    onChange={(e) => changeInfo("whatsapp_alt", e.target.value)}
                    value={whatsapp_alt}
                    sx={{ flex: 4 }}
                    type="number"
                  />
                </Stack>
              </Grid>

              <Grid item xs={12}>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Typography variant="h6">Address Info</Typography>
                </div>
              </Grid>
              <Grid item xs={6} sm={6} md={4}>
                <TextField
                  label="Address line 1"
                  fullWidth
                  sx={{ mt: 2 }}
                  size="medium"
                  value={address_1}
                  onChange={(e) => changeInfo("address_1", e.target.value)}
                ></TextField>
              </Grid>
              <Grid item xs={6} sm={6} md={4}>
                <TextField
                  label="Address line 2"
                  fullWidth
                  sx={{ mt: 2 }}
                  size="medium"
                  value={address_2}
                  onChange={(e) => changeInfo("address_2", e.target.value)}
                ></TextField>
              </Grid>
              <Grid item xs={6} sm={6} md={4}>
                <TextField
                  label="Pin code"
                  fullWidth
                  sx={{ mt: 2 }}
                  type="number"
                  size="medium"
                  value={postcode}
                  onChange={postcodeChange}
                ></TextField>
              </Grid>
              <Grid item xs={6} sm={6} md={4}>
                <TextField
                  label="City"
                  fullWidth
                  sx={{ mt: 2 }}
                  size="medium"
                  value={city}
                  onChange={(e) => changeInfo("city", e.target.value)}
                ></TextField>
              </Grid>
              <Grid item xs={6} sm={6} md={4}>
                <TextField
                  label="State"
                  fullWidth
                  sx={{ mt: 2 }}
                  size="medium"
                  value={state}
                  onChange={(e) => changeInfo("state", e.target.value)}
                ></TextField>
              </Grid>
              <Grid item xs={6} sm={6} md={4}>
                <TextField
                  label="Country"
                  fullWidth
                  sx={{ mt: 2 }}
                  size="medium"
                  value={country}
                  onChange={(e) => changeInfo("country", e.target.value)}
                ></TextField>
              </Grid>
              <Grid item xs={12}>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Typography variant="h6">Access Info</Typography>
                </div>
              </Grid>
            </Grid>
            <FormGroup
              sx={{ flex: 1, pt: 3 }}
              style={{
                padding: "0px",
                paddingTop: "13px",
                paddingRight: "10px",
              }}
            >
              <FormControlLabel
                control={
                  <Switch
                    checked={info.all_product_access}
                    onChange={(e) =>
                      changeInfoObj({
                        all_product_access: e,
                      })
                    }
                    style={
                      info.all_product_access
                        ? {
                            margin: "4px 8px",
                            border: `1px solid ${backgroundColor}`,
                            backgroundColor,
                            marginLeft: "auto",
                          }
                        : {
                            margin: "4px 8px",
                            marginLeft: "auto",
                          }
                    }
                  />
                }
                label="All Products Access"
                labelPlacement="start"
              />
            </FormGroup>
            <Collapse in={!info.all_product_access}>
              <div>
                <FormControl sx={{ mt: 4 }} fullWidth>
                  <InputLabel id="demo-multiple-chip-label">
                    Select Product Groups
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    // labelId="demo-multiple-chip-label"
                    // id="demo-multiple-chip"
                    multiple
                    value={product_groups || []}
                    onChange={handleProductGroupChange}
                    input={
                      <OutlinedInput
                        id="select-multiple-chip"
                        label="Select Product Groups"
                      />
                    }
                    // renderValue={(selected) => (
                    //   <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    //     {selected.map((value) => (
                    //       <Chip key={value.id} label={value.title} />
                    //     ))}
                    //   </Box>
                    // )}
                    // MenuProps={MenuProps}
                  >
                    {productGroups.map((group) => {
                      return (
                        <MenuItem value={group.id}>{group.title}</MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </div>
            </Collapse>
            {/* <Stack mt={3}>
              <Typography variant="h6">Access to</Typography>
            </Stack> */}
            <Stack direction={"row"} sx={{ flexWrap: "wrap", mt: 2 }}>
              {AllAcceess.map((item) => {
                return (
                  <AccessCard
                    obj={item}
                    isMobile={isMobile}
                    isSelected={
                      Array.isArray(access) && access.includes(item.value)
                    }
                    onClick={onAccessClick}
                  />
                );
              })}
            </Stack>
            <Alert
              sx={{ border: "0px solid" }}
              variant="outlined"
              severity="info"
            >
              Website and Instore are coming soon!
            </Alert>
            <Stack
              direction={"row"}
              justifyContent="flex-end"
              alignItems={"center"}
            >
              <Tooltip
                title={
                  !info.all_product_access && !isArrayWithValues(product_groups)
                    ? "Need products access to Send Invite"
                    : ""
                }
              >
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox />}
                    value={send_invite}
                    onChange={(e) =>
                      changeInfoObj({ send_invite: e.target.checked })
                    }
                    label="Send Invitation"
                    disabled={
                      !info.all_product_access &&
                      !isArrayWithValues(product_groups)
                    }
                  />
                </FormGroup>
              </Tooltip>
              <LoadingButton
                // loading={creatingCustomer}
                variant="contained"
                // size="small"
                sx={{ float: "right", margin: "12px 0px" }}
                onClick={onUpdate}
                // onClick={() =>
                //   BasicDetailsRef.current &&
                //   BasicDetailsRef.current.onUserUpdate()
                // }
              >
                Add User
              </LoadingButton>
            </Stack>
          </CardContent>
        </Card>
      </>
    );
  }
);

export default Add;

const onPostcodeChange = (value, setState, setLoading) => {
  if (value.length >= 6) {
    setLoading(true);
    axios({
      method: "GET",
      url: `https://api.postalpincode.in/pincode/${value}`,
    })
      .then((res) => {
        let { data } = res;
        if (Array.isArray(data)) {
          let obj = data[0];
          if (obj.Status === "Success") {
            let { PostOffice } = obj;
            if (
              PostOffice &&
              Array.isArray(PostOffice) &&
              PostOffice.length > 0
            ) {
              let info = PostOffice[0];
              setState({
                city: info.Name ? info.Name : "",
                state: info.State ? info.State : "",
                country: info.Country ? info.Country : "",
              });
            }
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }
};

export const AllAcceess = [
  { label: "Retailer App", value: "retailer_app", disabled: false },
  { label: "Catalogs", value: "catalogs", disabled: false },
  { label: "Website", value: "website", disabled: false },
  { label: "Instore", value: "instore", disabled: false },
  { label: "Social Media", value: "social_media", disabled: false },
  { label: "Market Places", value: "market_places", disabled: true },
];

const createCustomer = async (data, setLoading) => {
  setLoading(true);
  const website = getWebsite();
  const token = getToken();
  if (website && token)
    return axios({
      method: "POST",
      url: `${website}/wp-json/wc/v3/customers`,
      headers: {
        Authorization: `Basic ${token}`,
      },
      data: {
        password: "password",
        ...data,
      },
    })
      .then((res) => {
        return { success: true, data: res.data };
      })
      .catch((e) => {
        console.log(getAxiosError(e));
        return getAxiosError(e);
      })
      .finally(() => {
        setLoading(false);
      });
};

export const sendInvitationRetailer = async (id) => {
  let website = await getWebsite();
  let token = await getToken();
  let res = await axios({
    url: `${website}/wp-json/store/v1/invite`,
    headers: {
      Authorization: `Basic ${token}`,
    },
    params: { retailers: id },
    method: "POST",
  });

  return res.data;
};

// export const getRetailerInvites = () => {
//     let website = await getWebsite();
//     let token = await getToken();
// try {
//   let res =   axios({
//       url: `${website}/wp-json/store/v1/settings/retailer_invites`,
//       headers: {
//         Authorization: `Basic ${token}`,
//       },
//     })
//      let { data } = res;
//      if (data.success) {
//        dispatch({
//          type: SET_CRM_DATA,
//          payload: { retailer_invites: data.data || [] },
//        });
//      }

// } catch (error) {
//   console.log(error)
// }
// };

export const addRetailerIdInInvite = async (allInvites, id) => {
  let obj = {
    retailer_id_in_supplier: id,
    id: Math.floor(Date.now()),
  };

  try {
    let website = await getWebsite();
    let token = await getToken();
    let res = await axios({
      url: `${website}/wp-json/store/v1/settings/retailer_invites`,
      method: "POST",
      headers: {
        Authorization: `Basic ${token}`,
      },
      data: [...allInvites, { ...obj }],
    });
    if (res.data) {
      console.log(res.data, "Retailer invites response");
    }
  } catch (error) {
    console.log(getAxiosError(error, "add retailer invite error"));
  }
};

const vendorTypes = [
  { label: "Manufacturer", value: "manufacturer" },
  { label: "Wholesaler", value: "wholesaler" },
  { label: "Retailer", value: "retailer" },
  { label: "Exporter", value: "exporter" },
  { label: "Designer", value: "designer" },
];

export const commonDialCodes = [
  { label: "India 🇮🇳 (+91)", value: "91" },
  { label: "United States 🇺🇸 (+1)", value: "1" },
  { label: "United Kingdom 🇬🇧 (+44)", value: "44" },
];
export const dialCodes = [
  { label: "Afghanistan 🇦🇫 (+93)", value: "93" },
  { label: "Åland Islands 🇦🇽 (+358)", value: "358" },
  { label: "Albania 🇦🇱 (+355)", value: "355" },
  { label: "Algeria 🇩🇿 (+213)", value: "213" },
  { label: "American Samoa 🇦🇸 (+1684)", value: "1684" },
  { label: "Andorra 🇦🇩 (+376)", value: "376" },
  { label: "Angola 🇦🇴 (+244)", value: "244" },
  { label: "Anguilla 🇦🇮 (+1264)", value: "1264" },
  { label: "Antarctica 🇦🇶 (+672)", value: "672" },
  { label: "Antigua and Barbuda 🇦🇬 (+1268)", value: "1268" },
  { label: "Argentina 🇦🇷 (+54)", value: "54" },
  { label: "Armenia 🇦🇲 (+374)", value: "374" },
  { label: "Aruba 🇦🇼 (+297)", value: "297" },
  { label: "Australia 🇦🇺 (+61)", value: "61" },
  { label: "Austria 🇦🇹 (+43)", value: "43" },
  { label: "Azerbaijan 🇦🇿 (+994)", value: "994" },
  { label: "Bahamas 🇧🇸 (+1242)", value: "1242" },
  { label: "Bahrain 🇧🇭 (+973)", value: "973" },
  { label: "Bangladesh 🇧🇩 (+880)", value: "880" },
  { label: "Barbados 🇧🇧 (+1246)", value: "1246" },
  { label: "Belarus 🇧🇾 (+375)", value: "375" },
  { label: "Belgium 🇧🇪 (+32)", value: "32" },
  { label: "Belize 🇧🇿 (+501)", value: "501" },
  { label: "Benin 🇧🇯 (+229)", value: "229" },
  { label: "Bermuda 🇧🇲 (+1441)", value: "1441" },
  { label: "Bhutan 🇧🇹 (+975)", value: "975" },
  {
    label: "Bolivia, Plurinational State of bolivia 🇧🇴 (+591)",
    value: "591",
  },
  { label: "Bosnia and Herzegovina 🇧🇦 (+387)", value: "387" },
  { label: "Botswana 🇧🇼 (+267)", value: "267" },
  { label: "Bouvet Island 🇧🇻 (+47)", value: "47" },
  { label: "Brazil 🇧🇷 (+55)", value: "55" },
  { label: "British Indian Ocean Territory 🇮🇴 (+246)", value: "246" },
  { label: "Brunei Darussalam 🇧🇳 (+673)", value: "673" },
  { label: "Bulgaria 🇧🇬 (+359)", value: "359" },
  { label: "Burkina Faso 🇧🇫 (+226)", value: "226" },
  { label: "Burundi 🇧🇮 (+257)", value: "257" },
  { label: "Cambodia 🇰🇭 (+855)", value: "855" },
  { label: "Cameroon 🇨🇲 (+237)", value: "237" },
  { label: "Canada 🇨🇦 (+1)", value: "1" },
  { label: "Cape Verde 🇨🇻 (+238)", value: "238" },
  { label: "Cayman Islands 🇰🇾 (+345)", value: "345" },
  { label: "Central African Republic 🇨🇫 (+236)", value: "236" },
  { label: "Chad 🇹🇩 (+235)", value: "235" },
  { label: "Chile 🇨🇱 (+56)", value: "56" },
  { label: "China 🇨🇳 (+86)", value: "86" },
  { label: "Christmas Island 🇨🇽 (+61)", value: "61" },
  { label: "Cocos 🇨🇨 (Keeling) Islands (+61)", value: "61" },
  { label: "Colombia 🇨🇴 (+57)", value: "57" },
  { label: "Comoros 🇰🇲 (+269)", value: "269" },
  { label: "Congo 🇨🇬 (+242)", value: "242" },
  {
    label: "Congo, The Democratic Republic of the Congo 🇨🇩 (+243)",
    value: "243",
  },
  { label: "Cook Islands 🇨🇰 (+682)", value: "682" },
  { label: "Costa Rica 🇨🇷 (+506)", value: "506" },
  { label: "Cote d'Ivoire 🇨🇮 (+225)", value: "225" },
  { label: "Croatia 🇭🇷 (+385)", value: "385" },
  { label: "Cuba 🇨🇺 (+53)", value: "53" },
  { label: "Cyprus 🇨🇾 (+357)", value: "357" },
  { label: "Czech Republic 🇨🇿 (+420)", value: "420" },
  { label: "Denmark 🇩🇰 (+45)", value: "45" },
  { label: "Djibouti 🇩🇯 (+253)", value: "253" },
  { label: "Dominica 🇩🇲 (+1767)", value: "1767" },
  { label: "Dominican Republic 🇩🇴 (+1849)", value: "1849" },
  { label: "Ecuador 🇪🇨 (+593)", value: "593" },
  { label: "Egypt 🇪🇬 (+20)", value: "20" },
  { label: "El Salvador 🇸🇻 (+503)", value: "503" },
  { label: "Equatorial Guinea 🇬🇶 (+240)", value: "240" },
  { label: "Eritrea 🇪🇷 (+291)", value: "291" },
  { label: "Estonia 🇪🇪 (+372)", value: "372" },
  { label: "Ethiopia 🇪🇹 (+251)", value: "251" },
  { label: "Falkland Islands 🇫🇰 (Malvinas) (+500)", value: "500" },
  { label: "Faroe Islands 🇫🇴 (+298)", value: "298" },
  { label: "Fiji 🇫🇯 (+679)", value: "679" },
  { label: "Finland 🇫🇮 (+358)", value: "358" },
  { label: "France 🇫🇷 (+33)", value: "33" },
  { label: "French Guiana 🇬🇫 (+594)", value: "594" },
  { label: "French Polynesia 🇵🇫 (+689)", value: "689" },
  { label: "French Southern Territories 🇹🇫 (+262)", value: "262" },
  { label: "Gabon 🇬🇦 (+241)", value: "241" },
  { label: "Gambia 🇬🇲 (+220)", value: "220" },
  { label: "Georgia 🇬🇪 (+995)", value: "995" },
  { label: "Germany 🇩🇪 (+49)", value: "49" },
  { label: "Ghana 🇬🇭 (+233)", value: "233" },
  { label: "Gibraltar 🇬🇮 (+350)", value: "350" },
  { label: "Greece 🇬🇷 (+30)", value: "30" },
  { label: "Greenland 🇬🇱 (+299)", value: "299" },
  { label: "Grenada 🇬🇩 (+1473)", value: "1473" },
  { label: "Guadeloupe 🇬🇵 (+590)", value: "590" },
  { label: "Guam 🇬🇺 (+1671)", value: "1671" },
  { label: "Guatemala 🇬🇹 (+502)", value: "502" },
  { label: "Guernsey 🇬🇬 (+44)", value: "44" },
  { label: "Guinea 🇬🇳 (+224)", value: "224" },
  { label: "Guinea-Bissau 🇬🇼 (+245)", value: "245" },
  { label: "Guyana 🇬🇾 (+592)", value: "592" },
  { label: "Haiti 🇭🇹 (+509)", value: "509" },
  {
    label: "Heard Island and Mcdonald Islands 🇭🇲 (+672)",
    value: "672",
  },
  { label: "Holy See 🇻🇦 (Vatican City State) (+379)", value: "379" },
  { label: "Honduras 🇭🇳 (+504)", value: "504" },
  { label: "Hong Kong 🇭🇰 (+852)", value: "852" },
  { label: "Hungary 🇭🇺 (+36)", value: "36" },
  { label: "Iceland 🇮🇸 (+354)", value: "354" },
  { label: "India 🇮🇳 (+91)", value: "91" },
  { label: "Indonesia 🇮🇩 (+62)", value: "62" },
  {
    label: "Iran, Islamic Republic of Persian Gulf 🇮🇷 (+98)",
    value: "98",
  },
  { label: "Iraq 🇮🇶 (+964)", value: "964" },
  { label: "Ireland 🇮🇪 (+353)", value: "353" },
  { label: "Isle of Man 🇮🇲 (+44)", value: "44" },
  { label: "Israel 🇮🇱 (+972)", value: "972" },
  { label: "Italy 🇮🇹 (+39)", value: "39" },
  { label: "Jamaica 🇯🇲 (+1876)", value: "1876" },
  { label: "Japan 🇯🇵 (+81)", value: "81" },
  { label: "Jersey 🇯🇪 (+44)", value: "44" },
  { label: "Jordan 🇯🇴 (+962)", value: "962" },
  { label: "Kazakhstan 🇰🇿 (+7)", value: "7" },
  { label: "Kenya 🇰🇪 (+254)", value: "254" },
  { label: "Kiribati 🇰🇮 (+686)", value: "686" },
  {
    label: "Korea, Democratic People's Republic of Korea 🇰🇵 (+850)",
    value: "850",
  },
  { label: "Korea, Republic of South Korea 🇰🇷 (+82)", value: "82" },
  { label: "Kosovo 🇽🇰 (+383)", value: "383" },
  { label: "Kuwait 🇰🇼 (+965)", value: "965" },
  { label: "Kyrgyzstan 🇰🇬 (+996)", value: "996" },
  { label: "Laos 🇱🇦 (+856)", value: "856" },
  { label: "Latvia 🇱🇻 (+371)", value: "371" },
  { label: "Lebanon 🇱🇧 (+961)", value: "961" },
  { label: "Lesotho 🇱🇸 (+266)", value: "266" },
  { label: "Liberia 🇱🇷 (+231)", value: "231" },
  { label: "Libyan Arab Jamahiriya 🇱🇾 (+218)", value: "218" },
  { label: "Liechtenstein 🇱🇮 (+423)", value: "423" },
  { label: "Lithuania 🇱🇹 (+370)", value: "370" },
  { label: "Luxembourg 🇱🇺 (+352)", value: "352" },
  { label: "Macao 🇲🇴 (+853)", value: "853" },
  { label: "Macedonia 🇲🇰 (+389)", value: "389" },
  { label: "Madagascar 🇲🇬 (+261)", value: "261" },
  { label: "Malawi 🇲🇼 (+265)", value: "265" },
  { label: "Malaysia 🇲🇾 (+60)", value: "60" },
  { label: "Maldives 🇲🇻 (+960)", value: "960" },
  { label: "Mali 🇲🇱 (+223)", value: "223" },
  { label: "Malta 🇲🇹 (+356)", value: "356" },
  { label: "Marshall Islands 🇲🇭 (+692)", value: "692" },
  { label: "Martinique 🇲🇶 (+596)", value: "596" },
  { label: "Mauritania 🇲🇷 (+222)", value: "222" },
  { label: "Mauritius 🇲🇺 (+230)", value: "230" },
  { label: "Mayotte 🇾🇹 (+262)", value: "262" },
  { label: "Mexico 🇲🇽 (+52)", value: "52" },
  {
    label: "Micronesia, Federated States of Micronesia 🇫🇲 (+691)",
    value: "691",
  },
  { label: "Moldova 🇲🇩 (+373)", value: "373" },
  { label: "Monaco 🇲🇨 (+377)", value: "377" },
  { label: "Mongolia 🇲🇳 (+976)", value: "976" },
  { label: "Montenegro 🇲🇪 (+382)", value: "382" },
  { label: "Montserrat 🇲🇸 (+1664)", value: "1664" },
  { label: "Morocco 🇲🇦 (+212)", value: "212" },
  { label: "Mozambique 🇲🇿 (+258)", value: "258" },
  { label: "Myanmar 🇲🇲 (+95)", value: "95" },
  { label: "Namibia 🇳🇦 (+264)", value: "264" },
  { label: "Nauru 🇳🇷 (+674)", value: "674" },
  { label: "Nepal 🇳🇵 (+977)", value: "977" },
  { label: "Netherlands 🇳🇱 (+31)", value: "31" },
  { label: "New Caledonia 🇳🇨 (+687)", value: "687" },
  { label: "New Zealand 🇳🇿 (+64)", value: "64" },
  { label: "Nicaragua 🇳🇮 (+505)", value: "505" },
  { label: "Niger 🇳🇪 (+227)", value: "227" },
  { label: "Nigeria 🇳🇬 (+234)", value: "234" },
  { label: "Niue 🇳🇺 (+683)", value: "683" },
  { label: "Norfolk Island 🇳🇫 (+672)", value: "672" },
  { label: "Northern Mariana Islands 🇲🇵 (+1670)", value: "1670" },
  { label: "Norway 🇳🇴 (+47)", value: "47" },
  { label: "Oman 🇴🇲 (+968)", value: "968" },
  { label: "Pakistan 🇵🇰 (+92)", value: "92" },
  { label: "Palau 🇵🇼 (+680)", value: "680" },
  {
    label: "Palestinian Territory, Occupied 🇵🇸 (+970)",
    value: "970",
  },
  { label: "Panama 🇵🇦 (+507)", value: "507" },
  { label: "Papua New Guinea 🇵🇬 (+675)", value: "675" },
  { label: "Paraguay 🇵🇾 (+595)", value: "595" },
  { label: "Peru 🇵🇪 (+51)", value: "51" },
  { label: "Philippines 🇵🇭 (+63)", value: "63" },
  { label: "Pitcairn 🇵🇳 (+64)", value: "64" },
  { label: "Poland 🇵🇱 (+48)", value: "48" },
  { label: "Portugal 🇵🇹 (+351)", value: "351" },
  { label: "Puerto Rico 🇵🇷 (+1939)", value: "1939" },
  { label: "Qatar 🇶🇦 (+974)", value: "974" },
  { label: "Romania 🇷🇴 (+40)", value: "40" },
  { label: "Russia 🇷🇺 (+7)", value: "7" },
  { label: "Rwanda 🇷🇼 (+250)", value: "250" },
  { label: "Reunion 🇷🇪 (+262)", value: "262" },
  { label: "Saint Barthelemy 🇧🇱 (+590)", value: "590" },
  {
    label: "Saint Helena, Ascension and Tristan Da Cunha 🇸🇭 (+290)",
    value: "290",
  },
  { label: "Saint Kitts and Nevis 🇰🇳 (+1869)", value: "1869" },
  { label: "Saint Lucia 🇱🇨 (+1758)", value: "1758" },
  { label: "Saint Martin 🇲🇫 (+590)", value: "590" },
  { label: "Saint Pierre and Miquelon 🇵🇲 (+508)", value: "508" },
  {
    label: "Saint Vincent and the Grenadines 🇻🇨 (+1784)",
    value: "1784",
  },
  { label: "Samoa 🇼🇸 (+685)", value: "685" },
  { label: "San Marino 🇸🇲 (+378)", value: "378" },
  { label: "Sao Tome and Principe 🇸🇹 (+239)", value: "239" },
  { label: "Saudi Arabia 🇸🇦 (+966)", value: "966" },
  { label: "Senegal 🇸🇳 (+221)", value: "221" },
  { label: "Serbia 🇷🇸 (+381)", value: "381" },
  { label: "Seychelles 🇸🇨 (+248)", value: "248" },
  { label: "Sierra Leone 🇸🇱 (+232)", value: "232" },
  { label: "Singapore 🇸🇬 (+65)", value: "65" },
  { label: "Slovakia 🇸🇰 (+421)", value: "421" },
  { label: "Slovenia 🇸🇮 (+386)", value: "386" },
  { label: "Solomon Islands 🇸🇧 (+677)", value: "677" },
  { label: "Somalia 🇸🇴 (+252)", value: "252" },
  { label: "South Africa 🇿🇦 (+27)", value: "27" },
  { label: "South Sudan 🇸🇸 (+211)", value: "211" },
  {
    label: "South Georgia and the South Sandwich Islands 🇬🇸 (+500)",
    value: "500",
  },
  { label: "Spain 🇪🇸 (+34)", value: "34" },
  { label: "Sri Lanka 🇱🇰 (+94)", value: "94" },
  { label: "Sudan 🇸🇩 (+249)", value: "249" },
  { label: "Suriname 🇸🇷 (+597)", value: "597" },
  { label: "Svalbard and Jan Mayen 🇸🇯 (+47)", value: "47" },
  { label: "Swaziland 🇸🇿 (+268)", value: "268" },
  { label: "Sweden 🇸🇪 (+46)", value: "46" },
  { label: "Switzerland 🇨🇭 (+41)", value: "41" },
  { label: "Syrian Arab Republic 🇸🇾 (+963)", value: "963" },
  { label: "Taiwan 🇹🇼 (+886)", value: "886" },
  { label: "Tajikistan 🇹🇯 (+992)", value: "992" },
  {
    label: "Tanzania, United Republic of Tanzania 🇹🇿 (+255)",
    value: "255",
  },
  { label: "Thailand 🇹🇭 (+66)", value: "66" },
  { label: "Timor-Leste 🇹🇱 (+670)", value: "670" },
  { label: "Togo 🇹🇬 (+228)", value: "228" },
  { label: "Tokelau 🇹🇰 (+690)", value: "690" },
  { label: "Tonga 🇹🇴 (+676)", value: "676" },
  { label: "Trinidad and Tobago 🇹🇹 (+1868)", value: "1868" },
  { label: "Tunisia 🇹🇳 (+216)", value: "216" },
  { label: "Turkey 🇹🇷 (+90)", value: "90" },
  { label: "Turkmenistan 🇹🇲 (+993)", value: "993" },
  { label: "Turks and Caicos Islands 🇹🇨 (+1649)", value: "1649" },
  { label: "Tuvalu 🇹🇻 (+688)", value: "688" },
  { label: "Uganda 🇺🇬 (+256)", value: "256" },
  { label: "Ukraine 🇺🇦 (+380)", value: "380" },
  { label: "United Arab Emirates 🇦🇪 (+971)", value: "971" },
  { label: "United Kingdom 🇬🇧 (+44)", value: "44" },
  { label: "United States 🇺🇸 (+1)", value: "1" },
  { label: "Uruguay 🇺🇾 (+598)", value: "598" },
  { label: "Uzbekistan 🇺🇿 (+998)", value: "998" },
  { label: "Vanuatu 🇻🇺 (+678)", value: "678" },
  {
    label: "Venezuela, Bolivarian Republic of Venezuela 🇻🇪 (+58)",
    value: "58",
  },
  { label: "Vietnam 🇻🇳 (+84)", value: "84" },
  { label: "Virgin Islands, British 🇻🇬 (+1284)", value: "1284" },
  { label: "Virgin Islands, U.S. 🇻🇮 (+1340)", value: "1340" },
  { label: "Wallis and Futuna 🇼🇫 (+681)", value: "681" },
  { label: "Yemen 🇾🇪 (+967)", value: "967" },
  { label: "Zambia 🇿🇲 (+260)", value: "260" },
  { label: "Zimbabwe 🇿🇼 (+263)", value: "263" },
];
