export const buttonsFieldStyles = {
  button: {
    boxShadow: 0,
    margin: 0,
    padding: 0,
    minHeight: 0,
    minWidth: 0,
    height: "40px",
    color: "#008aff",
    borderRadius: "0px",
    border: "white 0px solid",
    backgroundColor: "white",
    textTransform: "none",
    fontSize: "15px",
    fontWeight: "800px",
  },
  textField: {
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 600,
    marginBottom: "20px",
    color: "#22272d",
  },
  popoverBox: {
    height: "200px",
    width: "300px",
    padding: "15px",
  },
};
