import { backendApiCall, backendApiEndpoints } from "../../../../helper";

const createCustomField = async ({ payload }) => {
  try {
    let fields = await backendApiCall({
      endpoint: `${backendApiEndpoints.settings}/custom_fields`,
      extractDataFromResponse: true,
    });
    let customFields = fields?.crm || {};
    let data = await backendApiCall({
      endpoint: `${backendApiEndpoints.settings}/custom_fields`,
      method: "PUT",
      data: {
        data: { crm: { ...customFields, [payload?.id]: payload } },
      },
    });

    return data;
  } catch (error) {
    return { success: false };
  }
};

const deleteCustomField = async ({ id }) => {
  try {
    let fields = await backendApiCall({
      endpoint: `${backendApiEndpoints.settings}/custom_fields`,
      extractDataFromResponse: true,
    });
    let customFields = fields?.crm || {};
    delete customFields[id];
    let data = await backendApiCall({
      endpoint: `${backendApiEndpoints.settings}/custom_fields`,
      method: "PUT",
      data: {
        data: { crm: { ...customFields } },
      },
    });

    return data;
  } catch (error) {
    return { success: false };
  }
};
export { createCustomField, deleteCustomField };
