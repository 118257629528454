import { Box, Link, Typography } from '@mui/material'
import React from 'react'
import { ListItem } from '../meta/MetaOverview'

const feature = [
    '👉24/7 Customer Support',
    '👉Instant and simple KYC onboarding',
    '👉Intuitive Dashboard and easy to use',
    '👉Seamless mobile and website integration'
]
const Overview = () => {
    return (
        <Box sx={{ p: 3, mb: 3 }}>
            <Typography variant="subtitle1" sx={{ fontSize: "16px", mb: 4 }}>

                Easebuzz is one of India's Leading Digital Payment Solutions platforms with more than 70,000+ businesses accepting payments, sending payouts and managing their online business with ease 💸


            </Typography>
            <Typography variant="subtitle1" sx={{ fontSize: "16px", mb: 4 }}>
                24/7 Customer Support option is available for their customers 📞
            </Typography>
            <Typography variant="subtitle1" sx={{ fontSize: "16px", mb: 4 }}>
                They offer payment through all cards, UPI modes, 10+ wallets, 50+ Netbanking options, Ola postpaid, etc.
            </Typography>
            <Typography variant="subtitle2" sx={{ fontSize: "16px", mb: 4 }}>Features of Easebuzz </Typography>
            <ListItem arr={feature} />
            {/* <Typography sx={{ fontSize: "16px", mt: 3 }}>
                📨 Send your queries <Link href="https://razorpay.com/support/?utm_referrer=&referral_code=meetanshi7m92j" style={{ fontSize: "16px" }} >Contact RazorPay</Link>
            </Typography>
            <Typography sx={{ fontSize: "16px", mt: 3 }}>
                📞 Contact number:
                <Link href="tel:+18001231272" style={{ fontSize: "16px" }} > 1800-123-1272</Link>
            </Typography> */}

        </Box>
    )
}

export default Overview