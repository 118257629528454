import { Card } from "@mui/material";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import { documentFieldStyles } from "../utils/chatbotFieldsStyles";
import '../public/css/document-title.css'
import PropTypes from "prop-types";

const styles = documentFieldStyles;

const BotDocumentMessage = ({ message }) => {
  const docs = [{ uri: message.file }];

  return (
    <Card style={styles.card}>
      <DocViewer
        documents={docs}
        pluginRenderers={DocViewerRenderers}
        className="docs-main"    
      />
    </Card>
  );
};

BotDocumentMessage.propTypes = {
  message: PropTypes.object.isRequired,
};

export default BotDocumentMessage;
