import axios from "axios";
import {
  getToken,
  getWebsite,
  isArrayWithValues,
  isObjWithValues,
  mainSocketEvents,
  validateNumber,
} from "../../helper";
import { addVisitorInCatalog } from "./productActions";

export const ADD_VISITOR = "ADD_VISITOR";
export const REMOVE_VISITOR = "REMOVE_VISITOR";
export const SET_VISITOR_DATA = "SET_VISITOR_DATA";

export const addVisitor = (visitor) => {
  return (dispatch, getState) => {
    let state = getState();
    let visitor_audio_mute = state.persist.visitor_audio_mute;
    dispatch({
      type: ADD_VISITOR,
      payload: { ...visitor, visitor_audio_mute },
    });
    dispatch(addVisitorInCatalog(visitor));
  };
};

export const removeVisitor = (id) => {
  return (dispatch) => {
    dispatch({
      type: REMOVE_VISITOR,
      payload: id,
    });
  };
};

export const getPresetVisitors = () => {
  return async (dispatch, getState) => {
    try {
      let website = await getWebsite();
      const token = await getToken();
      if (website && token) {
        dispatch({
          type: SET_VISITOR_DATA,
          payload: { fetchingAllOnlineVisitors: true },
        });
        let res = await axios({
          url: `${website}/wp-json/store/v1/visitor_info`,
          headers: {
            Authorization: `Basic ${token}`,
          },
        });

        if (res.data.success && isObjWithValues(res.data.data?.data)) {
          let state = getState();
          let allOnlineVisitors = state.visitor.allOnlineVisitors || {};
          let obj = res?.data?.data?.data;
          let updatedObj = {};
          for (let i in obj)
            if (isArrayWithValues(obj[i])) updatedObj[i] = obj[i].reverse();

          let { city, customers, leads, sessions, leadscores, top_products } =
            res?.data?.data || {};
          dispatch({
            type: SET_VISITOR_DATA,
            payload: {
              allOnlineVisitors: {
                ...allOnlineVisitors,
                ...(updatedObj || {}),
              },
              allOnlineVisitorsCounts: res?.data?.data?.count,
              visitordashboard: {
                city,
                customers,
                leads,
                sessions,
              },
              visitorLeadScores: leadscores,
              top_products,
            },
          });
        }
        // else if (!res.data.success &&){

        // }
        dispatch({
          type: SET_VISITOR_DATA,
          payload: { fetchingAllOnlineVisitors: false },
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
};
export const getMoreVisitors = (params) => {
  return async (dispatch, getState) => {
    let website = await getWebsite();
    const token = await getToken();
    // if (params && params?.page === 1) delete params?.page
    // if (!params?.filter) delete params?.filter
    if (website && token) {
      dispatch({
        type: SET_VISITOR_DATA,
        payload: { gettingMoreVisitor: params?.page === 1 ? false : true },
      });
      if (params?.page === 1) {
        dispatch({
          type: SET_VISITOR_DATA,
          payload: { fetchingAllOnlineVisitors: true },
        });
      }
      let res = await axios({
        url: `${website}/wp-json/store/v1/visitor_info`,
        params,
        headers: {
          Authorization: `Basic ${token}`,
        },
      });
      // console.log(res, "<<<vistor res");
      if (res.data.success && isObjWithValues(res.data.data?.data)) {
        let state = getState();
        let allOnlineVisitors = state.visitor.allOnlineVisitors;
        let obj = res?.data?.data?.data;
        let updatedObj = {};
        for (let i in obj)
          if (isArrayWithValues(obj[i])) updatedObj[i] = obj[i].reverse();
        let { city, customers, leads, sessions } = res?.data?.data || {};
        dispatch({
          type: SET_VISITOR_DATA,
          payload: {
            allOnlineVisitors:
              params?.page === 1
                ? obj
                : { ...(allOnlineVisitors || {}), ...obj },
            allOnlineVisitorsCounts: res?.data?.data?.count,
            visitordashboard: {
              city,
              customers,
              leads,
              sessions,
            },
          },
        });
      }
      if (!res?.data?.success && res?.data?.data?.includes("Max page")) {
        dispatch({
          type: SET_VISITOR_DATA,
          payload: {
            noMoreVisitor: true,
          },
        });
      }

      dispatch({
        type: SET_VISITOR_DATA,
        payload: {
          gettingMoreVisitor: false,
          fetchingAllOnlineVisitors: false,
        },
      });
    }
  };
};

// export const setupLiveCustomers = () => {
//   return (dispatch, getState) => {
//     let state = getState();
//     let allVisitors = state.visitor.allOnlineVisitors;
//     for (let visitor in allVisitors) {
//       let visitorEvents = allVisitors[visitor];
//       if (isArrayWithValues(visitorEvents)) {
//         for (let event of visitorEvents) {
//           let { event_id, type, online, user_id } = event;
//           if (mainSocketEvents.includes(type)) {
//             if (!online) break;

//           }
//         }
//       }
//     }
//     dispatch({
//       type: SET_VISITOR_DATA,
//       payload: {},
//     });
//   };
// };

export const setupLiveCatalogs = () => {
  return (dispatch, getState) => {
    let state = getState();
    let allVisitors = state.visitor.allOnlineVisitors;
    let live_catalogs = state.visitor.live_catalogs;
    let liveCatalogs = [...(live_catalogs || [])];
    for (let visitor in allVisitors) {
      let visitorEvents = allVisitors[visitor];
      if (isArrayWithValues(visitorEvents)) {
        for (let event of visitorEvents) {
          // for (let i = visitorEvents?.length - 1; i >= 0; i--) {
          // let event = visitorEvents[i];
          let { event_id, type, online, user_id } = event;
          if (mainSocketEvents.includes(type)) {
            let addedLiveObj = liveCatalogs.find((i) => i.id == event_id);
            // console.log(
            //   event,
            //   addedLiveObj,
            //   isArrayWithValues(addedLiveObj?.customers),
            //   !addedLiveObj?.customers?.find((i) => i.user_id == user_id),
            //   "<<<<< added live object"
            // );
            if (!online) {
              if (addedLiveObj)
                if (
                  isArrayWithValues(addedLiveObj?.customers) &&
                  addedLiveObj?.customers?.find((i) => i.user_id == user_id)
                ) {
                  let customers = addedLiveObj.customers;
                  customers = customers.filter((i) => i.user_id != user_id);
                  liveCatalogs = liveCatalogs.map((i) =>
                    i.id == event_id
                      ? {
                          ...addedLiveObj,
                          customers,
                        }
                      : i
                  );
                }
              break;
            }
            if (online) {
              if (addedLiveObj) {
                if (
                  isArrayWithValues(addedLiveObj?.customers) &&
                  !addedLiveObj?.customers?.find((i) => i.user_id == user_id)
                ) {
                  let customers = addedLiveObj.customers;
                  customers.push(event);
                  liveCatalogs = liveCatalogs.map((i) =>
                    i.id == event_id
                      ? {
                          ...i,
                          customers,
                        }
                      : i
                  );
                }
              } else {
                liveCatalogs.push({
                  id: event_id,
                  type,
                  customers: [{ ...event }],
                });
              }
              // console.log(liveCatalogs, "<<< liveCatalogs");

              break;
            }
          }

          // let updatedObj = {};
          // let preaddedObj = liveCatalogs.find((i) => i.id == event_id);
          // if (mainSocketEvents.includes(type) && !online) {
          //   if (preaddedObj) {
          //     if (preaddedObj?.user_ids?.includes(user_id)) {
          //       let { user_ids } = preaddedObj;
          //       let updated_user_ids = [...(user_ids || [])];
          //       updated_user_ids.splice(updated_user_ids.indexOf(user_id), 1);
          //       if (!isArrayWithValues(updated_user_ids))
          //         liveCatalogs = liveCatalogs
          //           .filter((i) => i.id != event_id)
          //           .filter(Boolean);
          //       else {
          //         updatedObj = { ...preaddedObj, user_ids: updated_user_ids };
          //         liveCatalogs = liveCatalogs.map((i) =>
          //           i.id == event_id ? updatedObj : i
          //         );
          //       }
          //       break;
          //     } else if (!isArrayWithValues(preaddedObj?.user_ids)) {
          //       liveCatalogs = liveCatalogs
          //         .filter((i) => i.id != event_id)
          //         .filter(Boolean);
          //       break;
          //     }
          //   } else {
          //     liveCatalogs = liveCatalogs
          //       .filter((i) => i.id != event_id)
          //       .filter(Boolean);
          //     break;
          //   }
          //   break;
          // }
          // console.log(liveCatalogs, "1.5");
          // if (type === "catalog" && online) {
          //   if (preaddedObj) {
          //     let { user_ids } = preaddedObj || {};
          //     if (!user_ids?.includes(user_id))
          //       updatedObj = {
          //         ...preaddedObj,
          //         user_ids: [...(user_ids || []), user_id],
          //       };
          //     liveCatalogs = liveCatalogs.map((i) =>
          //       i.id == event_id ? updatedObj : i
          //     );
          //   } else {
          //     updatedObj = {
          //       id: event_id,
          //       user_ids: [user_id],
          //     };
          //     if (isObjWithValues(updatedObj)) liveCatalogs.push(updatedObj);
          //   }
          //   break;
          // }
        }
      }
    }
    // console.log(liveCatalogs, allVisitors, "1 live catalogs");
    liveCatalogs = liveCatalogs.filter((i) => isArrayWithValues(i.customers));
    // console.log(liveCatalogs, "2 live catalogs");
    dispatch({
      type: SET_VISITOR_DATA,
      payload: {
        live_catalogs: liveCatalogs.filter((i) => isObjWithValues(i)),
        live_visitors: liveCatalogs.filter((i) => isObjWithValues(i)),
      },
    });
  };
};

export const setVisitorsData = (allOnlineVisitors, when) => {
  return (dispatch) => {
    let currentVisitors = {
      0: [],
      1: [],
      5: [],
      10: [],
    };
    // if (allOnlineVisitors.length === 0) {
    //   // setVisitors({
    //   //   0: [],
    //   //   1: [],
    //   //   5: [],
    //   //   10: [],
    //   // });
    // } else {

    for (let i = 0; i < allOnlineVisitors.length; i++) {
      // let location =
      //   visitor?.events?.find((obj) => isObjWithValues(obj?.location))?.location ||
      //   {};
      let visitorObj =
        allOnlineVisitors[i]?.events?.find((obj) =>
          mainSocketEvents?.includes(obj?.type)
        ) || {};
      let visitor = allOnlineVisitors[i];
      let currentTime = parseInt(Date.now() / 1000);

      let minutesDiff =
        // Math.round(
        (currentTime - validateNumber(visitorObj.time)) / 60;
      // );
      // let visitor = allOnlineVisitors[i];
      // let currentTime = parseInt(Date.now() / 1000);
      // let minutesDiff = (currentTime - visitor.time) / 60;
      // console.log(minutesDiff, visitor?.user_id, "MINUTES DIFFERENCE");
      if (minutesDiff < 1) currentVisitors[0].push(visitor);
      else if (minutesDiff < 5) currentVisitors[1].push(visitor);
      else if (minutesDiff < 10) currentVisitors[5].push(visitor);
      else if (minutesDiff >= 10) currentVisitors[10].push(visitor);
    }
    // console.log();
    // if (
    //   currentVisitors[0].length === 0 &&
    //   currentVisitors[1].length === 0 &&
    //   currentVisitors[5].length === 0
    // )
    // clearInterval(visitorsDataInterval);
    dispatch({
      type: SET_VISITOR_DATA,
      payload: { currentVisitors },
    });
    // setVisitors(currentVisitors);
    // }
  };
};
