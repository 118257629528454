import {
  getTemplateVariableObjFromCustomer,
  sendSmsMsg91,
} from "../../../helper";

const onSendSMS = async ({
  smsIntegration = {},
  type,
  onClose,
  customer,
  phone_number,
}) => {
  let responseObj = {};
  if (smsIntegration?.selected_channel === "msg91") {
    let selectedTemplate = smsIntegration?.msg91?.[type];
    let template = selectedTemplate?.template;
    let variableState = template?.variables;
    let varState = { ...variableState };
    let variableObj = getTemplateVariableObjFromCustomer(customer);
    let otp = variableObj?.["{OTP}"];

    for (const key of Object.keys(variableState || {})) {
      if (variableObj?.[variableState?.[key]]) {
        varState[key] = variableObj?.[variableState?.[key]];
        // varState = { ...varState, [key]: variableObj?.[variableState?.key] }
      }
    }

    // setSendingSms(true);

    let res = await sendSmsMsg91(
      {
        template_id: selectedTemplate?.template_id,
        sender: smsIntegration?.[smsIntegration?.selected_channel]?.sender_id,
        mobiles:
          phone_number ||
          `${customer?.dialcode_mobile || ""}${
            customer?.billing?.phone || ""
          }`?.trim() ||
          customer?.whatsapp,
        ...varState,
      },
      smsIntegration?.[smsIntegration?.selected_channel]?.auth_key
    );
    if (res?.data?.success) {
      responseObj.success = true;
      responseObj.otp_sent = otp;
    }
    // dispatch(
    //   setGlobalToast({
    //     show: true,
    //     message: `Message initiated!`,
    //     severity: "success",
    //   })
    // );
    // setSendingSms(false);
    onClose && onClose();
  }

  //   if (smsIntegration?.selected_channel === "exotel") {
  //     setSendingSms(true);
  //     // data array should be like  [ {To : "91XXXXXX" , Body : "Abc" }]
  //     let dataArray = [];

  //     let varState = { ...variableState };
  //     let variableObj = getTemplateVariableObjFromCustomer(customer);
  //     let Body = selectedTemplate?.body;
  //     for (const key of Object.keys(variableState || {})) {
  //       if (variableObj?.[variableState?.[key]]) {
  //         varState[key] = variableObj?.[variableState?.[key]];
  //         Body = replaceVariable({
  //           template: Body,
  //           key,
  //           value: variableState?.[key],
  //         });
  //         // varState = { ...varState, [key]: variableObj?.[variableState?.key] }
  //       }
  //     }
  //     //This is a test message powered by Exotel. Report abuse to +918088919888 -Exotel
  //     // for
  //     let response = await sendExotelSms({
  //       ...(smsIntegration?.exotel || {}),
  //       dataArray: [
  //         {
  //           To: `${customer?.dialcode_mobile}${customer.billing?.phone}`,
  //           Body: Body,
  //         },
  //       ],
  //     });
  //     setSendingSms(false);
  //     let parser = new DOMParser();

  //     console.log(
  //       xml2Json(parser.parseFromString(response?.error?.data, "text/xml"))
  //     );

  //     let errorString = xml2Json(
  //       parser.parseFromString(response?.error?.data, "text/xml")
  //     )?.TwilioResponse?.RestException?.Message?.["#text"];

  //     if (response?.error)
  //       dispatch(
  //         setGlobalToast({
  //           show: true,
  //           message: `${errorString}`,
  //           severity: "error",
  //         })
  //       );
  //     else {
  //       dispatch(
  //         setGlobalToast({
  //           show: true,
  //           message: `Message initiated!`,
  //           severity: "success",
  //         })
  //       );
  //       setSendingSms(false);
  //       handleClose();
  //     }
  //   }
  return responseObj;
};

export { onSendSMS };
