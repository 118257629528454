import { Download, Search, Sync } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { alpha, Box, Button, Checkbox, Chip, Dialog, DialogContent, DialogTitle, IconButton, InputAdornment, LinearProgress, linearProgressClasses, Paper, Stack, styled, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, TextField, Toolbar, Tooltip, Typography, useTheme } from '@mui/material';
import React, { useMemo, useState } from 'react'
import PropTypes from "prop-types";
import { visuallyHidden } from "@mui/utils";
import { useDispatch, useSelector } from 'react-redux';
import { fetchIPInfo, getFullName, getRandomString, getToken, getWebsite, isArrayWithValues, isObjWithValues } from '../../../../../helper';
import { getWoocommerceCustomers } from '../../../../../redux/actions/settingActions';
import { getIp } from '../../../../../App';
import { GoogleSheet } from '../../../../../Svgs';
import { setGlobalToast } from '../../../../../redux/actions/helperActions';
import { createMultipleCustomer } from '../../../../../redux/actions/crmActions';
const headCells = [
    {
        id: "sr",
        numeric: false,
        // disablePadding: true,
        label: "Sr.",
    },
    {
        id: "label",
        numeric: false,
        // disablePadding: true,
        label: "Name",
    },
    {
        id: "product",
        numeric: false,
        // disablePadding: true,
        label: "Email",
    },
    {
        id: "stock",
        numeric: true,
        // disablePadding: false,
        label: "City",
    },
    // {
    //     id: "amount",
    //     numeric: true,
    //     disablePadding: false,
    //     label: "Amount",
    // },
    {
        id: "last_sync",
        numeric: true,
        // disablePadding: false,
        label: "Last Synced",
    },
    //   {
    //     id: "carbs",
    //     numeric: true,
    //     disablePadding: false,
    //     label: "Carbs (g)",
    //   },
    //   {
    //     id: "protein",
    //     numeric: true,
    //     disablePadding: false,
    //     label: "Protein (g)",
    //   },
];
const Customers = () => {
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [selected, setSelected] = useState([])
    const [parmas, setParmas] = useState({
        page: 1,
        per_page: 100
    })
    const [importing, setImporting] = useState(false)
    const [importingProgress, setImportingProgress] = useState(0)
    const isSelected = (obj) => selected.findIndex((i) => i.id == obj.id) !== -1;
    const theme = useTheme()
    const dispatch = useDispatch()
    const isDarkTheme = theme.palette && theme.palette.mode === "dark";
    const wooCommerceCustomers = useSelector(state => state.settings.wooCommerceCustomer) || []
    const fetchingWooCommerceCustomer = useSelector(state => state.settings.fetchingWooCommerceCustomer) || []
    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - wooCommerceCustomers?.length) : 0;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const handleClick = (event, obj) => {
        // if (importedSku.includes(obj.label)) return;
        const selectedIndex = selected.findIndex((i) => i.id == obj.id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, obj);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }
        setSelected(newSelected);
    };
    const startImport = async () => {
        let website = await getWebsite();
        let token = await getToken();
        if (
            website &&
            token

        ) {
            setImporting(true);
            setImportingProgress(1);
            let selectedCreate = selected?.map(obj => {
                obj.meta_data = [...(obj.meta_data || []), { key: "woocommerce_id", value: `${obj?.id}` }]
                delete obj.id
                return obj
            })
            console.log(selectedCreate, "<<");
            await dispatch(createMultipleCustomer(selectedCreate))

            setImportingProgress(100);
            setImporting(false);

            setSelected([]);

            dispatch(
                setGlobalToast({
                    show: true,
                    message: "Customer Imported successfully!",
                    severity: "success",
                })
            );
        }
    };
    useMemo(() => {
        if (!isArrayWithValues(wooCommerceCustomers)) {
            dispatch(getWoocommerceCustomers(parmas))
        }
    }, [])
    return (
        <>
            <Dialog
                open={importing}
                // onClose={close}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="xs"
                fullWidth
            >
                <ImportyingCustomer open={importing} progress={importingProgress} />
            </Dialog>
            <Paper sx={{ width: "100%", mb: 2 }}>
                <EnhancedTableToolbar
                    numSelected={selected.length}
                    fetchingWooCommerceCustomer={fetchingWooCommerceCustomer}
                    startImport={startImport}
                />
                <TableContainer>
                    <Table aria-labelledby="tableTitle" size={dense ? "small" : "medium"}>
                        <EnhancedTableHead
                            numSelected={selected.length}

                        // order={order}
                        // orderBy={orderBy}
                        // onSelectAllClick={handleSelectAllClick}
                        // onRequestSort={handleRequestSort}
                        // rowCount={
                        //     rows.filter((i) => !importedSku.includes(i.label)).length
                        // }
                        />
                        <TableBody>
                            {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                   rows.slice().sort(getComparator(order, orderBy)) */}
                            {
                                // stableSort(rows, getComparator(order, orderBy))
                                //     
                                wooCommerceCustomers?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((row, index) => {
                                    const isItemSelected = isSelected(row);
                                    const labelId = `enhanced-table-checkbox-${index}`;

                                    return (
                                        <TableRow
                                            hover
                                            onClick={(event) => handleClick(event, row)}
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={row.id}
                                            selected={isItemSelected}
                                        >
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    color="primary"
                                                    checked={isItemSelected}
                                                    inputProps={{
                                                        "aria-labelledby": labelId,
                                                    }}
                                                    size="small"
                                                // disabled={importedSku.includes(row.label)}
                                                />
                                            </TableCell>
                                            <TableCell
                                                padding="checkbox"
                                                align="left"
                                                sx={{ fontWeight: "600" }}
                                            >
                                                {page * rowsPerPage + index + 1}
                                            </TableCell>
                                            <TableCell scope="row" >
                                                {getFullName(row)}
                                            </TableCell>
                                            <TableCell
                                                // component="th"
                                                id={labelId}
                                                scope="row"
                                            // padding="none"
                                            >
                                                {row?.email}
                                                <Stack
                                                    direction="row"
                                                    alignItems={"center"}
                                                //   justifyContent="space-between"
                                                >

                                                </Stack>
                                            </TableCell>
                                            <TableCell align="right">
                                                {row?.billing?.city}

                                            </TableCell>
                                            {/* <TableCell
                                            align="right"
                                            sx={{ fontWeight: "600", fontSize: "13px" }}
                                        >
                                            {row.TotalAmt}
                                        </TableCell> */}
                                            <TableCell align="right" sx={{ fontSize: "12px" }}>
                                                {/* {getSyncDate(row.label)} */}
                                            </TableCell>
                                            {/* <TableCell align="right">{row.carbs}</TableCell>
                      <TableCell align="right">{row.protein}</TableCell> */}
                                        </TableRow>
                                    );
                                })}
                            {emptyRows > 0 && (
                                <TableRow
                                    style={{
                                        height: (dense ? 33 : 53) * emptyRows,
                                    }}
                                >
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Stack direction={"row"} justifyContent="flex-start">
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={wooCommerceCustomers?.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Stack>
            </Paper>
        </>
    )
}

export default Customers
function EnhancedTableHead(props) {
    const {
        onSelectAllClick,
        order,
        orderBy,
        numSelected,
        rowCount,
        onRequestSort,

    } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                    <Checkbox
                        color="primary"
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{
                            "aria-label": "select all desserts",
                        }}
                        size="small"
                    />
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? "right" : "left"}
                        padding={headCell.disablePadding ? "none" : "normal"}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : "asc"}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === "desc" ? "sorted descending" : "sorted ascending"}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};
const EnhancedTableToolbar = (props) => {
    const {
        numSelected,
        startImport,
        openImport,
        setFilterForm,
        fetchingWooCommerceCustomer,
        ornateProductsInTanika,
        ornateProducts,
        ornateIntegration,
        reduxOrnateIntegration,
        addHistroyObj,
        editFilter,
        search,
        fetchingWooCommerceCustomerInTanika,
    } = props;
    const [showHistory, setShowHistory] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [syncing, setSyncing] = useState(false);
    // const open = Boolean(anchorEl);
    // const handleClick = (event) => {
    //     setAnchorEl(event.currentTarget);
    // };
    // const handleClose = () => {
    //     setAnchorEl(null);
    // };
    // const dispatch = useDispatch();
    // const closeHistory = () => setShowHistory(false);

    // const addOrnateApiCalls = async (calls) => {
    //     let website = await getWebsite();
    //     let token = await getToken();
    //     if (website && token) {
    //         try {
    //             let { data } = await axios({
    //                 url: `${website}/wp-json/store/v1/settings/ornate_api_calls`,
    //                 headers: {
    //                     Authorization: `Basic ${token}`,
    //                 },
    //                 method: "PUT",
    //                 data: {
    //                     type: "ornate_api_calls",
    //                     data: {
    //                         [new Date().getTime()]: calls,
    //                     },
    //                 },
    //             });
    //             console.log(data);
    //             if (data.success && isObjWithValues(data.data)) {
    //                 dispatch(editSettings({ ornateApiCalls: data.data }));
    //             }
    //         } catch (error) {
    //             console.log(getAxiosError(error));
    //         }
    //     }
    // };

    // const addLastSyncTime = async () => {
    //     let website = await getWebsite();
    //     let token = await getToken();
    //     if (website && token) {
    //         try {
    //             let { data } = await axios({
    //                 url: `${website}/wp-json/store/v1/settings/ornate_integration`,
    //                 headers: {
    //                     Authorization: `Basic ${token}`,
    //                 },
    //                 method: "PUT",
    //                 data: {
    //                     type: "ornate_integration",
    //                     data: {
    //                         last_sync_time: new Date().getTime(),
    //                     },
    //                 },
    //             });
    //             console.log(data);
    //             if (data.success && isObjWithValues(data.data)) {
    //                 dispatch(editSettings({ ornateIntegration: data.data }));
    //             }
    //         } catch (error) {
    //             console.log(getAxiosError(error));
    //         }
    //     }
    // };

    // const onSyncPress = async () => {
    //     setSyncing(true);
    //     let website = await getWebsite();
    //     let token = await getToken();
    //     let oldOrnateData = ornateProducts;
    //     console.log(ornateIntegration);
    //     let { ornate_settings } = ornateIntegration || {};

    //     // const ornateProductsInTanika = await _getOrnateProductsFromTanika(
    //     //   website,
    //     //   token
    //     // );
    //     let historyObj = {
    //         id: getRandomString(8),
    //         start: Math.floor(Date.now() / 1000),
    //         ip: await getIp(),
    //         ua: new UAParser().getUA(),
    //         // ip:
    //         imported: 0,
    //         products: [],
    //     };
    //     let location = await fetchIPInfo(historyObj.ip);
    //     if (isObjWithValues(location)) {
    //         let { city, region, postal, country } = location;
    //         historyObj.location = {
    //             city,
    //             state: region,
    //             postcode: postal,
    //             country,
    //         };
    //     }
    //     // let ornateData = ornateProducts;
    //     let ornateData;
    //     let fetchTries = 3;
    //     for await (let i of Array.from(new Array(fetchTries))) {
    //         ornateData = await getDataFromOrnate(website, token);
    //         if (ornateData?.LabelDetail) break;
    //     }
    //     // let ornateData = oldOrnateData;
    //     if (!isDev()) await addOrnateApiCalls(ornateData?.LabelDetail?.length);
    //     // setSyncing(false);
    //     // return;
    //     let isFetched = false;
    //     isFetched = ornateData?.Status?.[0]?.Status === "Sucess";
    //     if (!isFetched) {
    //         // if (!ornateData?.Status?.[0]?.Status === "Sucess") {
    //         setSyncing(false);
    //         return dispatch(
    //             setGlobalToast({
    //                 show: true,
    //                 message: "Ornate sync failed, please try again!",
    //                 severity: "error",
    //             })
    //         );
    //     }
    //     let tanikaData = await setDataToTanika(website, token, ornateData);
    //     if (!tanikaData) {
    //         setSyncing(false);
    //         return dispatch(
    //             setGlobalToast({
    //                 show: true,
    //                 message: "Ornate sync failed, please try again!",
    //                 severity: "error",
    //             })
    //         );
    //     }
    //     let tanikaProductsToUpdate = [];
    //     if (isArrayWithValues(ornateProductsInTanika))
    //         for await (let productObj of ornateProductsInTanika) {
    //             let { meta_data, id } = productObj;
    //             if (!isArrayWithValues(meta_data)) meta_data = [];
    //             let ornate_id = meta_data.find((i) => i.key === "ornate_unique_id");
    //             ornate_id = ornate_id?.value;
    //             if (!ornate_id) ornate_id = productObj.sku;
    //             if (!ornate_id) continue;

    //             // if (isDev() && ornate_id !== "WA/13") continue;
    //             let product = await getTanikaProductFromOrnate(
    //                 ornate_id,
    //                 ornateProducts,
    //                 {
    //                     ornate_integration: ornateIntegration,
    //                 }
    //             );
    //             if (product) tanikaProductsToUpdate.push({ ...product, id });
    //             else {
    //                 let obj = {};
    //                 if (ornate_settings.maketoorder) {
    //                     obj = {
    //                         id,
    //                         stock_status: "onbackorder",
    //                         manage_stock: false,
    //                         backorders: "yes",
    //                         backorders_allowed: true,
    //                         backordered: true,
    //                     };
    //                 } else
    //                     obj = {
    //                         id,
    //                         stock_status: "outofstock",
    //                         manage_stock: false,
    //                         backorders: "no",
    //                         backorders_allowed: false,
    //                         backordered: false,
    //                     };
    //                 tanikaProductsToUpdate.push({
    //                     ...obj,
    //                 });
    //             }
    //         }

    //     if (isArrayWithValues(tanikaProductsToUpdate)) {
    //         let updateBatchLength =
    //             Math.round(tanikaProductsToUpdate.length / 100) || 1;
    //         let productsToUpdate = [...tanikaProductsToUpdate];
    //         for await (let temp of Array.from(new Array(updateBatchLength))) {
    //             await updateTanikaBulkProducts(
    //                 website,
    //                 token,
    //                 productsToUpdate.splice(0, 100)
    //             );
    //         }
    //         historyObj.updated = tanikaProductsToUpdate.length;
    //     }
    //     historyObj.end = Math.floor(Date.now() / 1000);
    //     if (!isDev()) {
    //         await addHistroyObj(
    //             { [historyObj.id]: historyObj },
    //             reduxOrnateIntegration?.ornate_auth?.ip_port
    //         );
    //         await addLastSyncTime();
    //     }
    //     setSyncing(false);
    //     dispatch(
    //         setGlobalToast({
    //             show: true,
    //             message: "Ornate Sync Success!",
    //             severity: "success",
    //         })
    //     );
    // };

    // const _getOrnateProductsFromTanika = async (website, token) => {
    //     try {
    //         let array = [];
    //         let page = 1;
    //         let per_page = 500;
    //         for (let i of Array(100)) {
    //             let { data } = await axios({
    //                 url: `${website}/wp-json/wc/v3/products`,
    //                 headers: { Authorization: `Basic ${token}` },
    //                 // headers: { apikey: `${token}` },
    //                 params: {
    //                     per_page,
    //                     page,
    //                     "custom_filter[0][key]": "ornate_unique_id",
    //                     "custom_filter[0][value]": "",
    //                     "custom_filter[0][compare]": "LIKE",
    //                     // "custom_filter[0][type]": "NUMERIC",
    //                 },
    //             });
    //             if (isArrayWithValues(data)) {
    //                 array = array.concat(data);
    //                 if (data.length < per_page) break;
    //                 await sleep(page < 10 ? page * 1000 : 3000);
    //             }
    //             page++;
    //         }
    //         return array;
    //     } catch (error) {
    //         console.log(getAxiosError(error), "fetching orante products from tanika");
    //     }
    // };

    // const getDataFromOrnate = async (website, token) => {
    //     try {
    //         let { data } = await axios({
    //             url: `${website}/wp-json/store/v1/channel/ornate?endpoint=/OnlineJewelleryShopping/GetLabelData_API`,
    //             headers: { Authorization: `Basic ${token}` },
    //             method: "POST",
    //         });
    //         return data;
    //     } catch (error) {
    //         console.log(getAxiosError(error));
    //     }
    // };
    // const setDataToTanika = async (website, token, payload) => {
    //     try {
    //         let { data } = await axios({
    //             url: `${website}/wp-json/store/v1/settings/ornate_product_details`,
    //             headers: { Authorization: `Basic ${token}` },
    //             data: { type: "ornate_product_details", data: payload },
    //             method: "POST",
    //         });
    //         return true;
    //         // return data?.success || data?.type === "success";
    //     } catch (error) {
    //         console.log(getAxiosError(error));
    //     }
    // };

    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                // ...(numSelected > 0 && {
                //     bgcolor: (theme) =>
                //         alpha(
                //             theme.palette.primary.main,
                //             theme.palette.action.activatedOpacity
                //         ),
                // }),
            }}
        >
            {numSelected > 0 ? (
                <Typography
                    sx={{ flex: "1 1 100%" }}
                    color="inherit"
                    variant="subtitle1"
                    component="div"
                >
                    {numSelected} selected
                </Typography>
            ) : (
                <Typography
                    sx={{ fontSize: "12px", color: "text.secondary", flex: "1 1 100%" }}
                >
                    Last Sync:{" "}
                    {/* {ornateIntegration.last_sync_time
                        ? formatDate(
                            new Date(ornateIntegration.last_sync_time),
                            "dd:mm:yyyy hh:mm am/pm"
                        )
                        : null} */}
                </Typography>
                // <Typography
                //   sx={{ flex: "1 1 100%" }}
                //   variant="h6"
                //   id="tableTitle"
                //   component="div"
                // ></Typography>
            )}

            <Stack direction="row" alignItems={"center"}>
                {/* <Button
          size="small"
          sx={{ whiteSpace: "nowrap" }}
          onClick={() => setShowHistory(true)}
        >
          Sync History
        </Button> */}
                <TextField
                    variant="standard"
                    // fullWidth
                    placeholder="Search Customer "
                    value={search}
                    // onChange={(e) => editFilter({ search: e.target.value })}
                    size="small"
                    sx={{ mr: 2, width: "200px" }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment>
                                <Search sx={{ mr: 1, mb: 1 }} />
                            </InputAdornment>
                        ),
                    }}
                />
                <LoadingButton
                    size="small"
                    sx={{ whiteSpace: "nowrap" }}
                // loading={fetchingWooCommerceCustomer || fetchingWooCommerceCustomerInTanika}
                // onClick={() => dispatch(fetchOrnateProducts())}
                >
                    Refresh
                </LoadingButton>

                <IconButton
                    className={syncing ? "infinite_rotate" : ""}
                    // onClick={onSyncPress}
                    disabled={fetchingWooCommerceCustomer || fetchingWooCommerceCustomerInTanika}
                >
                    <Sync />
                </IconButton>
                {numSelected > 0 ? (
                    <Button
                        size="small"
                        variant="contained"
                        startIcon={<Download sx={{ height: "18px", width: "18px" }} />}
                        onClick={startImport}
                    >
                        Import
                    </Button>
                ) : (
                    <Tooltip title="Filter list">
                        <IconButton
                        // onClick={() =>
                        // setFilterForm((state) => ({ ...state, show: !state.show }))
                        // }
                        >
                            {/* <FilterListIcon /> */}
                        </IconButton>
                    </Tooltip>
                )}
            </Stack>

            {/* <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem>
          <Avatar /> Profile
        </MenuItem>
        <MenuItem>
          <Avatar /> My account
        </MenuItem>
      </Menu> */}
            {/* <Dialog
                open={showHistory}
                onClose={closeHistory}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle variant="h5" id="alert-dialog-title">
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems={"center"}
                    >
                        Ornate History
                        <IconButton onClick={closeHistory}>
                            <Close />
                        </IconButton>
                    </Stack>
                </DialogTitle>
                <DialogContent>
                    <OrnateActivity />
                </DialogContent> */}
            {/* <DialogActions>
          <Button onClick={closeHistory}>Disagree</Button>
          <Button onClick={closeHistory} autoFocus>
            Agree
          </Button>
        </DialogActions> */}
            {/* </Dialog> */}
        </Toolbar>
    );
};

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};

const ImportyingCustomer = ({ progress }) => {
    const BorderLinearProgress2 = styled(LinearProgress)(({ theme }) => ({
        height: 6,
        borderRadius: 5,
        [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor:
                theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
        },
        [`& .${linearProgressClasses.bar}`]: {
            borderRadius: 5,
            backgroundColor: theme.palette.mode === "light" ? "#1ca25e" : "#1ca25e",
        },
    }));
    return <>
        <DialogTitle id="alert-dialog-title">Customer importing</DialogTitle>
        <DialogContent>
            <Stack
                direction={"row"}
                justifyContent="center"
                sx={{ margin: "10px auto", alignSelf: "center" }}
            >
                <GoogleSheet
                    width="130px"
                    height="130px"
                // className="animated_background_color"
                />
            </Stack>
            <Box sx={{ mt: 6 }}>
                <BorderLinearProgress2 variant="determinate" value={progress} />
                <Typography
                    sx={{
                        fontSize: "14px",
                        textAlign: "right",
                        mr: 2,
                        mt: 2,
                        fontWeight: "600",
                    }}
                >
                    {progress}%
                </Typography>
            </Box>

        </DialogContent>
    </>
}