import { Box, Button, Stack } from "@mui/material";
import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isArrayWithValues, TabPanel } from "../../../../helper";
import { getWoocommerceCustomers } from "../../../../redux/actions/settingActions";
import Customers from "./components/Customers";
import Mapping from "./components/Mapping";
import { default as SettingsCompoennt } from "./components/Settings";

const tabs = [
  {
    label: "Overview",
    value: "overview",
  },
  {
    label: "Mapping",
    value: "mapping",
  },
  {
    label: "Customers",
    value: "customers",
  },
  { label: "Settings", value: "settings" },
  { label: "Activity", value: "history" },
];
const Settings = () => {
  const [selectedTab, setSelectedTab] = useState("mapping");
  return <SettingsCompoennt />;
  return (
    <Box pt={0}>
      <Stack
        direction="row"
        sx={{
          backgroundColor: "Background.paper",
          padding: "10px",
          borderBottomColor: "#e8e8e8",
          borderBottomWidth: 0.2,
          borderBottomStyle: "solid",
          overflow: "scroll",
        }}
        spacing={4}
        className="scrollbar-hidden"
      >
        {tabs.map((i) => {
          return (
            <Button
              variant={"text"}
              // variant={selectedTab !== i.value ? "outlined" : "contained"}
              sx={{
                backgroundColor:
                  selectedTab === i.value ? "#e6e6e6" : "transparent",
                color: "#000",
                // color: selectedTab === i.value ? "primary.main" : "#000",
                "&:hover": {
                  backgroundColor:
                    selectedTab === i.value ? "#e6e6e6" : "transparent",
                },
              }}
              onClick={() => setSelectedTab(i.value)}
            >
              {i.label}
            </Button>
          );
        })}
      </Stack>
      <TabPanel index={selectedTab} value={"customers"}>
        <Customers />
      </TabPanel>
      <TabPanel index={selectedTab} value={"mapping"}>
        <Mapping />
      </TabPanel>
      <TabPanel index={selectedTab} value={"settings"}></TabPanel>
    </Box>
  );
};

export default Settings;
