import { LoadingButton } from "@mui/lab";
import {
    Box,
    Button,
    IconButton,
    InputAdornment,
    MenuItem,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import * as qs from "qs";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    ConfimPopup,
    apiCallFromSocket,
    getStoreId,
    isArrayWithValues,
    isObjWithValues,
    nodeWebsite,
    settingsEndpoint,
} from "../../../../helper";
import { EDIT_SETTINGS } from "../../../../redux/actions/settingActions";
import { Label } from "../../../customers/components/Info";

import { Visibility, VisibilityOff } from "@mui/icons-material";
import { getExotelUsers } from "../../../customers/Customers";
import { setGlobalToast } from "../../../../redux/actions/helperActions";
import { isDev } from "../../../../helper";

const Integration = () => {
    return (
        <Box p={4}>
            <InfoForm />
        </Box>
    );
};

export default Integration;

const isIntegrated = (obj) => {
    let checkKeys = ["api_key", "api_token", "sid"];
    for (const key of checkKeys) {
        if (!obj?.[key]?.trim()) return false;
    }
    return true;
};

const InfoForm = () => {
    const [hide, setHide] = useState({
        api_key: true,
        api_token: true,
    });
    const fields = [
        {
            label: "Your API Key",
            value: "api_key",
            heplerLabel: (
                <Typography>

                    (How to get api keys{" "}
                    <a href="https://support.exotel.com/support/solutions/articles/3000023019-how-to-find-my-api-key-api-token-account-sid-and-subdomain-" target="_blank">
                        Click here
                    </a>)
                </Typography>
            ),
            props: {
                type: hide?.api_key ? "password" : "text",
                InputProps: {
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => {
                                    setHide((pre) => ({ ...pre, api_key: !hide?.api_key }));
                                }}
                                // onClick={handleClickShowPassword}
                                // onMouseDown={handleMouseDownPassword}
                                edge="end"
                            >
                                {
                                    // showPassword
                                    hide?.api_key ? <VisibilityOff /> : <Visibility />
                                }
                            </IconButton>
                        </InputAdornment>
                    ),
                },
            },
        },
        {
            label: "Your API Token",
            value: "api_token",
            props: {
                type: hide?.api_token ? "password" : "text",
                InputProps: {
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                // onClick={handleClickShowPassword}
                                // onMouseDown={handleMouseDownPassword}
                                edge="end"
                                onClick={() => {
                                    setHide((pre) => ({ ...pre, api_token: !hide?.api_token }));
                                }}
                            >
                                {hide?.api_token ? ( // showPassword
                                    <VisibilityOff />
                                ) : (
                                    <Visibility />
                                )}
                            </IconButton>
                        </InputAdornment>
                    ),
                },
            },
        },
        { label: "Your SId", value: "sid" },
        // { label: "Your Sender ID", value: "sender_id" }, 

        // {
        //     label: "Sub Domain",
        //     value: "subdomain",
        //     props: { select: true },
        //     menuList: [
        //         { label: "Singapore cluster", value: "@api.exotel.com" },
        //         { label: "Mumbai cluster", value: "@api.in.exotel.com" },
        //     ],
        // },
    ];
    const [formState, setFormState] = useState({ subdomain: "@api.exotel.com" });

    const [hasError, setHasError] = useState({});
    const [updating, setUpdating] = useState(false);
    const exotelIntegration = useSelector(
        (state) => state.settings.exotelIntegration
    );
    const [openConfirm, setOpenConfirm] = useState(false);
    const reduxTeamMembers = useSelector((state) => state.settings.teamMembers);
    const admin = useSelector((state) => state.user?.userAdminData) || {};
    const dispatch = useDispatch();
    const onEditForm = (payload) => {
        setHasError({});
        setFormState((pre) => ({ ...pre, ...(payload || {}) }));
    };

    useEffect(() => {
        // if (isObjWithValues(exotelIntegration)) {
        setFormState(exotelIntegration || {});
        // }
    }, [exotelIntegration]);

    const onSave = async () => {
        let hasError = false;
        for (const defaultObj of fields) {
            if (!formState?.[defaultObj?.value]) {
                hasError = true;
                setHasError((pre) => ({ ...pre, [defaultObj?.value]: true }));
            }
        }
        if (hasError) return;
        let data = { ...formState };
        if (!isArrayWithValues(exotelIntegration?.settings?.phones)) {
            data = {
                ...data,
                settings: {
                    phones: (isObjWithValues(admin)
                        ? [
                            {
                                id: admin?.id,
                                phone: admin?.mobile_no,
                                dialcode: admin?.dialcode_mobile,
                                enabled: true,
                            },
                        ]
                        : []
                    )?.concat(
                        reduxTeamMembers?.map((o) => {
                            let { id, phone, dialcode } = o;
                            return {
                                id,
                                phone,
                                dialcode,
                                enabled: true,
                            };
                        }) || []
                    ),
                },
            };
        }
        setUpdating(true);
        let exotelUsers = await getExotelUsers(formState);
        if (!exotelUsers) {
            setUpdating(false);
            setHasError((pre) => ({ ...pre, authentication: true }));
            return;
        }

        let res = await settingsEndpoint({
            endpoint: "exotel_integration",
            method: "PUT",
            data: data,
        });
        if (res) {
            dispatch({
                type: EDIT_SETTINGS,
                payload: {
                    exotelIntegration: res,
                },
            });
            dispatch(
                setGlobalToast({
                    show: true,
                    message: "Exotel Settings has been saved successfully",
                    severity: "success",
                })
            );
        }
        setUpdating(false);
    };
    const sendMessge = async ({ sid, api_key, api_token, subdomain }) => {
        // let sendObj = new URLSearchParams({
        //     From: "09464626007",
        //     To: "09998060495",
        //     Body: "Jwero testing"
        // })
        let bodyObj = {
            From: "EXOSMS",

            To: "+919464626007",
            // Body: "This is a test message powered by Ex,leflepd[; ;dws] ;otel. Report abuse to +918088919888 -Exotel",
            Body: "This is a test message powered by Exotel. Report abuse to +918088919888 -Exotel",
            // CallerId: "095-138-86363",
            // DltTemplateId: "1307161011366292953",
            // DltEntityId: "himanshur.tanikatech@gmail.com",
            // SmsType: "transactional"
        };
        let res = await apiCallFromSocket({
            // url: `https://${api_key}:${api_token}${subdomain}/v1/Accounts/${sid}/Calls/connect`,
            url: `https://api.exotel.com/v1/Accounts/${sid}/Sms/send.json`,
            // url: `https://api.exotel.com/v1/Accounts/jwero1/Calls/connect.json`,
            method: "POST",
            headers: {
                Authorization: `Basic ${btoa(api_key + ":" + api_token)}`,
            },
            data: qs.stringify(bodyObj),
        }, "https://websockets.tanika.tech");
        console.log(res);
    };
    const onRevoke = async () => {
        setUpdating(true);
        let res = await settingsEndpoint({
            endpoint: "exotel_integration",
            method: "DELETE",
            // data: {},
            params: {
                force_delete: 1,
            },
        });
        console.log(res);
        // if (res?.success) {
        dispatch({
            type: EDIT_SETTINGS,
            payload: {
                exotelIntegration: {},
            },
        });
        dispatch(
            setGlobalToast({
                show: true,
                message: "Exotel Revoked successfully",
                severity: "success",
            })
        );
        // }
        setOpenConfirm(false);
        setUpdating(false);
    };
    return (
        <>
            <Box sx={{ width: "50%" }}>
                <Stack sx={{ rowGap: 3 }}>
                    {fields?.map((obj) => {
                        return (
                            <Stack key={obj?.label}>
                                <Stack direction={"row"} gap={2}>
                                    <Label>{obj?.label} </Label>
                                    {obj?.heplerLabel}
                                </Stack>
                                <TextField
                                    size="small"
                                    value={formState?.[obj?.value] || ""}
                                    onChange={(e) => {
                                        onEditForm({ [obj?.value]: e.target.value });
                                    }}
                                    {...(obj?.props || {})}
                                    error={hasError?.[obj?.value]}
                                >
                                    {isArrayWithValues(obj?.menuList) &&
                                        obj?.menuList?.map((o) => {
                                            return <MenuItem value={o.value}>{o.label}</MenuItem>;
                                        })}
                                </TextField>
                            </Stack>
                        );
                    })}
                </Stack>
                {hasError?.authentication && (
                    <Typography color={"error"}>Authentication Error</Typography>
                )}

                <Stack direction={"row"} sx={{ mt: 2 }} justifyContent={"flex-end"}>
                    {!isIntegrated(exotelIntegration) ? (
                        <LoadingButton
                            loading={updating}
                            variant="contained"
                            onClick={onSave}
                        >
                            Integrate
                        </LoadingButton>
                    ) : (
                        <LoadingButton
                            //   loading={updating}
                            variant="contained"
                            onClick={() => setOpenConfirm(true)}
                            color="error"
                        >
                            Revoke
                        </LoadingButton>
                    )}
                </Stack>
                {isDev() && (
                    <Button onClick={() => sendMessge(exotelIntegration)}>
                        send Message
                    </Button>
                )}
            </Box>
            <ConfimPopup
                open={openConfirm}
                handleClose={() => {
                    setOpenConfirm(false);
                }}
                heading={"Are you sure you want to Revoke Exotel Integration?"}
                subtitle={"This action can not be undone!"}
                button={
                    <LoadingButton
                        loading={updating}
                        variant="contained"
                        onClick={onRevoke}
                        color="error"
                    >
                        Revoke
                    </LoadingButton>
                }
            />
        </>
    );
};
