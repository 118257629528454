import { Box, Stack, Tab, Tabs, Typography } from "@mui/material";
import React, { useState } from "react";
import { TabPanel, isOurWebsite } from "../../../../helper";
import Integration from "./Integration";
import { SmsOutlined } from "@mui/icons-material";
import Templates from "./Templates";
import Activities from "./Activites";
import { ListItem } from "../meta/MetaOverview";
import { Link } from "react-router-dom";
import Settings from "./Settings";

const Sms = () => {
    const [selectedTab, setSelectedTab] = useState("overview");
    return (
        <Box sx={{ height: "100%", paddingRight: "15px" }}>
            <Stack
                sx={{
                    position: "sticky",
                    top: "0",
                    backgroundColor: "#fff",
                    zIndex: 13,
                    margin: 0,
                    padding: "12px",
                }}
            >
                <Stack
                    direction="row"
                    justifyContent={"space-between"}
                    alignItems="center"
                >
                    <Typography
                        variant="h3"
                        sx={{ display: "flex", alignItems: "center", gap: "10px" }}
                    >
                        <SmsOutlined /> SMS
                    </Typography>
                </Stack>
                <Tabs
                    onChange={(e, newValue) => setSelectedTab(newValue)}
                    value={selectedTab}
                    sx={{ borderBottom: 1, borderColor: "divider" }}
                >
                    <Tab label="Overview" value="overview" />
                    <Tab label="How to Use" value="how_to_use" />
                    <Tab label="Integration" value="integration" />
                    <Tab label="Settings" value="settings" />
                    <Tab label="Templates" value="template" />
                    <Tab label="Activities" value="activities" />
                </Tabs>
            </Stack>
            <TabPanel value={selectedTab} index={"overview"}>
                <Overview />
            </TabPanel>
            <TabPanel value={selectedTab} index={"how_to_use"}>
                <HowToUse />
            </TabPanel>
            <TabPanel value={selectedTab} index={"integration"}>
                <Integration />
            </TabPanel>
            <TabPanel value={selectedTab} index={"settings"}>
                <Settings />
            </TabPanel>
            <TabPanel value={selectedTab} index={"template"}>
                <Templates />
            </TabPanel>
            <TabPanel value={selectedTab} index={"activities"}>
                <Activities />
            </TabPanel>
        </Box>
    );
};

export default Sms;

export const RenderPoints = ({ points, WarperTagProps = {}, warperTag }) => {
    const WarperTag = warperTag || "ul";
    return (
        <WarperTag style={{ marginLeft: "16px" }} {...WarperTagProps}>
            {points?.map((str) => {
                return (
                    <li key={str} style={{ marginTop: "4px" }}>
                        <Typography
                            variant="subtitle1"
                            dangerouslySetInnerHTML={{ __html: str }}
                        ></Typography>
                    </li>
                );
            })}
        </WarperTag>
    );
};

const featureArray = [
    "✅ Send and Receive messages",
    "✅ Send Personalised Transactional, Promotional and OTP messages",
    "✅ Send message to an individual contact from Jwero CRM",
    "✅ Send SMS campaign from Campaign module",
    "✅ Get Sent, delivery & Failed count",
];

let overviewPoints = [
    ` Jewellers can Enable <strong>OTP Based Log In System</strong>  for Jeweller's website removing the depending of external tool which mandates on compulsory Captcha process which may or may not hamper customer experience`,
    `Upon any Digi gold plan closing process jewellers can send <strong>OTP to the customers to verify their details</strong> and
              request <strong>for plan closing.</strong>`,
    ` <strong>Bulk Promote Discount Codes</strong> with SMS marketing.`,
    `  <strong>Bulk Promote new offers.</strong> `,
    `<strong>Inform customers of their Digigold plans</strong>  information via SMS
              templates`,
];

const Overview = () => {
    return (
        <Box p={5}>
            <Typography variant="subtitle1" sx={{ fontSize: "16px" }}>
                Jwero has enabled multiple kind of Communication Channels for marketing
                purposes and to stay connected with their customers and keep them
                engaged with their brand.
            </Typography>
            <Typography variant="subtitle1" sx={{ fontSize: "16px", mt: 5 }}>
                With SMS Integration in Jwero, Jewellers can enable OTP Based log for
                customers on their website, Digi gold plan closing verification and Bulk
                SMS marketing,etc
            </Typography>
            <Box sx={{ mt: 4 }}>
                <Typography variant="h5">With Jwero {"<>"} SMS Integration</Typography>

                <RenderPoints points={overviewPoints} />

                {/* <ListItem arr={featureArray} /> */}
                {/* <Typography variant="h5" sx={{ mt: 6 }}>
                Pre-requisite{" "}Before using the SMS service for your business, you need to :
            </Typography>
            <ListItem
                arr={[
                    "✅ Register your business entity for DLT",
                    "✅ Apply for Header",
                    "✅ Apply for Consent message",
                    "✅ Apply for message Template message"
                ]}
            /> */}
                {/* Jweo - Exotel Advantage
        Direct Dialing from CRM
        Call Log in respective CRM account
        Team member based call assign */}
            </Box>
        </Box>
    );
};

const howToUsePoints = [
    "<strong>Complete sign up process and training with the Subscribed Service Provider.</strong>",
    "To <strong>Integrate </strong> the <strong> services </strong> with <strong> Jwero,switch to Integrations </strong> tab and <strong> add API Key and other credentials. </strong> ",
    "Next ,<strong>Create Templates of SMS </strong> (Ex.OTP,Digital Reminder,Plan Subscribed,marketing,etc) and get it approved by DLT process.",
    "DLT approved <strong> Templates must be uploaded in the respective services Template tab.</strong>",
    "Jwero will automatically <strong> Sync </strong> the <strong> Templates </strong> added in the <strong> Subscribed Service Provider.</strong>",
    "Once all the Templates are added Jewellers can <strong> start using </strong> it manually ,automated format or for <strong> Bulk SMS marketing.</strong>",
];

const HowToUse = () => {
    return (
        <Box p={5}>
            <Typography variant="h5" sx={{ fontStyle: "italic" }}>
                To Get Started with with SMS integration ,you must be subscribed for an
                SMS Service provider.
            </Typography>
            <img
                src={"/static/img/content/HowtoIntegrateSMS.jpg"}
                width={"60%"}
                style={{ margin: "auto", marginTop: "8px" }}
            />
            <RenderPoints
                points={[
                    "<strong>You can sign up for below services as it is already integrated with Jwero :</strong>",
                ]}
            />
            <RenderPoints
                warperTag={"ol"}
                points={[
                    `<strong>MSG91 :</strong> <a href="https://bit.ly/42gZl0n">Click here</a>`,
                    `<strong>Exotel SMS </strong> `,
                ]}
            />
            <RenderPoints points={howToUsePoints} WarperTagProps={{ style: { marginLeft: "16px", marginBottom: "24px" } }} />
            {/* <div style={{ }}> */}
            {/* <Typography variant="h5" >
            Register your Business Entity for DLT Compliance
        </Typography>
        <Typography variant="subtitle2" sx={{ mt: 2 }}>
            To comply with India's new regulations for SMS messaging, it is now mandatory to register your business entity on a DLT (Distributed Ledger Technology) platform. This process involves creating a unique sender ID and registering your organization with the DLT provider.
        </Typography>
        <Typography variant="subtitle2" sx={{ mt: 2 }}>
            We recommend using the Jio DLT platform as it is one of the approved providers by the Telecom Regulatory Authority of India (TRAI). To register your business entity on Jio DLT, please follow these steps:
        </Typography>
        <ListItem
            arr={[
                "✅ Login to the Jio DLT portal using your credentials.",
                `✅ Click on the "Entity Registration" tab.`,
                "✅ Enter the required information about your organization, including your GSTIN number and PAN card details.",
                "✅ Upload the necessary documents to verify your organization's identity.",
                "✅ Once your registration is approved, you can create your unique sender ID and register your SMS templates."
            ]}
        /> */}

            <Typography variant="body1" gutterBottom>
                Please note that DLT registration is mandatory for both promotional and
                transactional SMS messages, and failure to comply may result in your SMS
                messages being blocked.
            </Typography>
            <a
                href="https://msg91.com/help/entity-registration-on-dlt-platform#:~:text=Login%20to%20the%20Jio%20DLT,and%20Promotional%20for%20Promotional%20SMS."
                target="_black"
            >
                Entity Registration on DLT Platform
            </a>

            {/* </div> */}
        </Box>
    );
};
