import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";


import {
  Card as MuiCard,

  IconButton,

  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Stack,
  Button,
  useMediaQuery,
  Typography,

  Box,
  Menu,
  MenuItem,
  Skeleton,
} from "@mui/material";
import { spacing, useTheme } from "@mui/system";
import {
  formatDate,
  getRelativeTimeDifference,
  isArrayWithValues,
  isObjWithValues,
  secondaryLabel,
  sectionLabel,
  StyledTableRow,
} from "../../../helper";

import { useNavigate } from "react-router-dom";

import { CatalogIcon } from "../../../Svgs";
import { useDispatch, useSelector } from "react-redux";
import { Add, MoreVert } from "@mui/icons-material";
import { setGlobalToast } from "../../../redux/actions/helperActions";
import { NoTasks } from "../../chat/ChatHelper";
import { ResponsiveTabaleRow } from "../../components/helper";
const Card = styled(MuiCard)(spacing);

const Paper = styled(MuiPaper)(spacing);

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)});
`;



const RecentCatalogs = (props) => {

  let [requests, setRequests] = useState([]);

  let [catalogs, setCatalogs] = useState([]);
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const dispatch = useDispatch();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const catalogRequests = useSelector(
    (state) => state.product.dashboardCatalogRequests
  );

  const dashboardDetails = useSelector(
    (state) => state.settings.dashboardDetails
  );
  const fetchingDashboardDetails = useSelector(
    (state) => state.settings.fetchingDashboardDetails
  );

  useEffect(() => {
    if (!isObjWithValues(dashboardDetails)) return;
    let { catalogs } = dashboardDetails;
    if (isArrayWithValues(catalogs?.recent))
      setCatalogs(catalogs.recent.slice(0, 5));
  }, [dashboardDetails]);

  useEffect(() => {
    if (!isArrayWithValues(catalogRequests)) return;
    setRequests(catalogRequests.slice(0, 5));
  }, [catalogRequests]);


  const theme = useTheme();
  const isIpad = useMediaQuery(theme.breakpoints.down("md"));

  const onCopyLink = async (row) => {
    await navigator?.clipboard?.writeText(row.link);
    if (navigator?.clipboard?.writeText)
      dispatch(
        setGlobalToast({
          show: true,
          message: "Catalog link copied to clipboard",
          severity: "success",
        })
      );
    else
      dispatch(
        setGlobalToast({
          show: true,
          message: "Couldn't copy!",
          severity: "error",
        })
      );
    handleClose();
  };
  return (
    <Card mb={6} sx={{ padding: "0 15px", borderRadius: "15px", py: 3, height: "100%" }}>
      <Paper sx={{ height: "100%" }}>
        <Typography sx={{ ...sectionLabel, mt: 2 }}>Recent catalogs</Typography>
        {!fetchingDashboardDetails && !isArrayWithValues(catalogs) ? (
          <Box sx={{ margin: "auto", height: "100%" }}>
            <NoTasks
              text={
                <Box sx={{ textAlign: "center" }}>
                  <Typography>
                    You have no catalogs active currently.
                  </Typography>
                  <Typography sx={{ ...secondaryLabel, fontSize: "12px" }}>
                    Let’s create your first catalog.
                  </Typography>
                  <Button
                    variant="outlined"
                    sx={{ mt: 3 }}
                    size="small"
                    startIcon={<Add />}
                    onClick={() => navigate("/products")}
                  >
                    Create catalog
                  </Button>
                </Box>
              }
              icon={<CatalogIcon style={{ height: "70px", width: "70px" }} />}
            />
          </Box>
        ) : null}

        <TableWrapper>
          <Table>
            <TableHead>
              <TableRow>
                {!isIpad ? (
                  <>
                    <TableCell
                      sx={{ color: "text.secondary", fontSize: "15px" }}
                    >
                      Catalog
                    </TableCell>
                    <TableCell
                      sx={{ color: "text.secondary", fontSize: "15px" }}
                    >
                      Type
                    </TableCell>
                    {/* <TableCell>Time spent</TableCell> */}
                    <TableCell
                      sx={{ color: "text.secondary", fontSize: "15px" }}
                    >
                      Date
                    </TableCell>
                    <TableCell
                      sx={{ color: "text.secondary", fontSize: "15px" }}
                    >
                      Sent to
                    </TableCell>
                    <TableCell
                      sx={{ color: "text.secondary", fontSize: "15px" }}
                    >
                      Products
                    </TableCell>
                    {/* <TableCell
                      sx={{ color: "text.secondary", fontSize: "15px" }}
                    >
                      Action
                    </TableCell> */}
                  </>
                ) : null}
              </TableRow>
            </TableHead>
            <TableBody>
              {fetchingDashboardDetails && !isArrayWithValues(catalogs) && (
                <>
                  {Array(5)
                    .fill(null)
                    .map((item, index) => (
                      <TableRow
                        key={index}
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        sx={{
                          "&:last-child td, &:last-child th": {
                            border: 0,
                          },
                          py: 2,
                          cursor: "pointer",
                        }}
                      >
                        {/* <TableCell padding="checkbox">
                              <IconButton>
                                <Skeleton
                                  variant="rectangular"
                                  sx={{
                                    width: "18px",
                                    height: "18px",
                                    borderRadius: "3px",
                                    ml: 1,
                                  }}
                                />
                              </IconButton>
                            </TableCell> */}
                        <TableCell component="th" scope="row">
                          <Skeleton
                            variant="text"
                            sx={{ fontSize: "1rem", width: "100%" }}
                          />
                        </TableCell>

                        <TableCell align="left">
                          <Skeleton
                            variant="text"
                            sx={{
                              fontSize: "1rem",
                              width: "35%",
                              // marginLeft: "auto",
                            }}
                          />
                        </TableCell>
                        <TableCell align="left">
                          <Skeleton
                            variant="text"
                            sx={{
                              fontSize: "1rem",
                              width: "35%",
                              // marginLeft: "auto",
                            }}
                          />
                        </TableCell>
                        <TableCell align="left">
                          <Skeleton
                            variant="text"
                            sx={{
                              fontSize: "1rem",
                              width: "35%",
                              // marginLeft: "auto",
                            }}
                          />
                        </TableCell>
                        <TableCell align="left">
                          <Skeleton
                            variant="text"
                            sx={{
                              fontSize: "1rem",
                              width: "35%",
                              // marginLeft: "auto",
                            }}
                          />
                        </TableCell>
                        {/* <TableCell align="left">
                          <Skeleton
                            variant="text"
                            sx={{
                              fontSize: "1rem",
                              width: "35%",
                              // marginLeft: "auto",
                            }}
                          />
                        </TableCell> */}
                      </TableRow>
                    ))}
                </>
              )}
              {catalogs.map((row) => {
                // let status = row.isPublic ? "Public" : "";
                // let { expiry_date } = row;
                // expiry_date = expiry_date.replaceAll("-", "/");
                // expiry_date = new Date(expiry_date).getTime() / 1000;
                // if (Date.now() / 1000 > expiry_date) status = "expired";
                let seconds = row.analytics && row.analytics.seconds;
                let timeSpent;
                if (seconds)
                  timeSpent = getRelativeTimeDifference(
                    Number(new Date().getTime()),
                    Number(new Date().getTime() + seconds * 1000)
                  );

                let productLength = 0;
                if (typeof row.products === "string")
                  productLength = (row.products.match(/,/g) || []).length;
                else if (isArrayWithValues(row.products))
                  productLength = row?.products?.length;
                // row.products && row.products.toString().split(",");
                if (isIpad)
                  return (
                    <ResponsiveTabaleRow
                      title={row.title || ""}
                      onClick={() => navigate(`/catalogs/${row.id}`)}
                      chipLabel={<Typography>{row?.status}</Typography>}
                      subTitle={
                        formatDate(
                          new Date(row.created_at),
                          "dd:mm:yyyy hh:mm am/pm"
                        ) || "0"
                      }
                      menu={
                        <Box>
                          <IconButton onClick={handleClick}>
                            <MoreVert />
                          </IconButton>
                          <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{
                              "aria-labelledby": "basic-button",
                            }}
                          >
                            <MenuItem
                              onClick={() => navigate(`/catalogs/${row.id}`)}
                            >
                              View
                            </MenuItem>
                            {/* <MenuItem
                            onClick={() => {
                              handleClose();
                              //   setShowDelete(row);
                            }}
                          >
                            Delete
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              handleClose();
                              //   openCloneForm(row);
                            }}
                          >
                            Recreate
                          </MenuItem> */}
                            <MenuItem onClick={() => onCopyLink(row)}>
                              Copy Link
                            </MenuItem>
                          </Menu>
                        </Box>
                      }
                    />
                  );

                return (
                  <StyledTableRow
                    hover
                    key={row.id}
                    //   onClick={() => requestClick(row)}
                    onClick={() => navigate(`/catalogs/${row.id}`)}
                    role="checkbox"
                    sx={{ cursor: "pointer" }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{
                        fontWeight: "bold",
                        textTransform: "capitalize",
                        borderBottom: 0,
                      }}
                    >
                      <Stack direction="row" justifyContent={"space-between"}>
                        <Stack direction="row" alignItems={"flex-end"}>
                          <Typography
                            fontWeight={"bold"}
                            sx={{
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {row.title || ""}
                          </Typography>
                        </Stack>
                      </Stack>
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        textTransform: "capitalize",
                        borderBottom: 0,
                      }}
                    >
                      {row?.status}
                    </TableCell>
                    {/* <TableCell
                            align="left"
                            sx={{ fontWeight: "bold" }}
                          >{`${
                            timeSpent
                              ? `${timeSpent.value} ${timeSpent.label[0]}`
                              : "0 s"
                          }`}</TableCell> */}
                    <TableCell align="left" sx={{ borderBottom: 0 }}>
                      {formatDate(
                        new Date(row.created_at),
                        "dd:mm:yyyy hh:mm am/pm"
                      ) || "0"}
                    </TableCell>
                    <TableCell align="left" sx={{ borderBottom: 0 }}>
                      {row?.customer?.length || 0} Customer
                    </TableCell>
                    <TableCell align="left" sx={{ borderBottom: 0 }}>
                      {row?.products || 0}
                    </TableCell>
                    {false && <TableCell align="left" sx={{ borderBottom: 0 }}>
                      <IconButton onClick={handleClick}>
                        <MoreVert />
                      </IconButton>
                      <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                          "aria-labelledby": "basic-button",
                        }}
                      >
                        <MenuItem
                          onClick={() => navigate(`/catalogs/${row.id}`)}
                        >
                          View
                        </MenuItem>
                        {/* <MenuItem
                            onClick={() => {
                              handleClose();
                              //   setShowDelete(row);
                            }}
                          >
                            Delete
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              handleClose();
                              //   openCloneForm(row);
                            }}
                          >
                            Recreate
                          </MenuItem> */}
                        <MenuItem onClick={() => onCopyLink(row)}>
                          Copy Link
                        </MenuItem>
                      </Menu>
                    </TableCell>}
                  </StyledTableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableWrapper>
      </Paper>
      {/* {!fetchingDashboardDetails && !isArrayWithValues(catalogs) && (
        <>
          <Box sx={{ py: 4 }}>
            <Stack
              direction="row"
              justifyContent={"center"}
              sx={{ margin: "14px auto", alignItems: "center" }}
            >
              <MyFiles height={200} width={200} />
            </Stack>
            <Typography
              sx={{
                fontSize: "14px",
                color: "text.secondary",
                mt: 5,
                textAlign: "center",
              }}
            >
              {" "}
              No catalogs created yet
            </Typography>
          </Box>
        </>
      )} */}
      {/* {loading && (
        <Stack
          justifyContent={"center"}
          textAlign="center"
          width="100%"
          height={`${50 * 3 || 0}px`}
        >
          <CircularProgress sx={{ margin: "auto" }} />
        </Stack>
      )} */}
      {/* {!loading && !noRequests && (
        <Stack justifyContent={"flex-end"}>
          <Button
            variant="text"
            endIcon={<ArrowForwardIosIcon style={{ fontSize: "15px" }} />}
            sx={{ m: 2, marginLeft: "auto" }}
            onClick={() => navigate("/catalogs/requests")}
          >
            See More
          </Button>
        </Stack>
      )} */}
    </Card>
  );
};

export default RecentCatalogs;
