import { Button, Grid, Stack, Typography } from "@mui/material";
import axios from "axios";
import React, { useRef } from "react";
import { getAxiosError, getToken, getWebsite } from "../../helper";
import MasterSettings from "../settings/MasterSettings";

function BasicSettings() {
  const masterSettingsRef = useRef();

  const onSave = () => {
    masterSettingsRef.current.onSave(async () => {
      console.log("business settings updating");
      await updateBasicSettings({ basic_settings: true });
    });
  };

  return (
    <div>
      <Grid container spacing={3} sx={{ mt: 3 }}>
        <Grid item xs />

        <Grid item xs={10}>
          <Typography variant="h4">Please enter required settings</Typography>
          <Stack direction="row" justifyContent={"flex-end"} my={2}>
            <Button variant="contained">Update settings</Button>
          </Stack>
          <MasterSettings
            ref={masterSettingsRef}
            hideUpdate
            // updateLoading={setSaveLoading}
          />
        </Grid>
        <Grid item xs />
      </Grid>
    </div>
  );
}

export default BasicSettings;

export const updateBasicSettings = async (obj) => {
  let website = await getWebsite();
  let token = await getToken();
  if (website && token) {
    try {
      let { data } = await axios({
        url: `${website}/wp-json/store/v1/settings/basic_requirements`,
        headers: { Authorization: `Basic ${token}` },
        data: { type: "basic_requirements", data: obj },
        method: "PUT",
      });
      if (data.success) return true;
    } catch (error) {
      console.log(getAxiosError(error));
      return false;
    }
  }
};
