import { Close } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  darken,
  Divider,
  Grid,
  IconButton,
  lighten,
  Paper,
  Stack,
  styled,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  capitalizeFirstLetter,
  formatServerValue,
  getDropDownValueFromObj,
  getToken,
  getWebsite,
  isArray,
  isArrayWithValues,
  isObjWithValues,
  setupAttribute,
  TabPanel,
  validateNumber,
} from "../../../../../helper";
import { EDIT_SETTINGS } from "../../../../../redux/actions/settingActions";
const tabs = [
  { label: "Customer", value: "customer" },
  { label: "Products", value: "products" },
];

const Mapping = () => {
  const [selectedTab, setSelectedTab] = useState("customer");
  const [updatingMap, setUpdatingMap] = useState(false);
  const [hideProductMapping, setHideProductMapping] = useState(false);
  const reduxWoocommerceMapping = useSelector(
    (state) => state.settings?.woocommerceMapping
  );
  const user_products = useSelector(
    (state) => state.user?.store_plan?.user_products
  );

  const dispatch = useDispatch();

  useEffect(() => {
    setHideProductMapping(
      user_products?.length === 1 && user_products.includes("crm")
    );
  }, [user_products]);

  const saveMap = async (attributeObj) => {
    console.log(setupAttribute(attributeObj));
    let website = getWebsite();
    let token = getToken();
    if (website && token) {
      setUpdatingMap(true);
      try {
        let res = await axios({
          url: `${website}/wp-json/store/v1/settings/woocommerce_mapping`,
          headers: { Authorization: `Basic ${token}` },

          method: "PUT",
          data: {
            setting: "woocommerce_mapping",
            data: {
              customers: {
                attributes: attributeObj,
                mapping: setupAttribute(attributeObj),
              },
            },
          },
        });
        if (res?.data?.success) {
          dispatch({
            type: EDIT_SETTINGS,
            payload: {
              woocommerceMapping: res?.data?.data,
            },
          });
          setUpdatingMap(false);
        }
      } catch (error) {
        setUpdatingMap(false);
      }
      setUpdatingMap(false);
    }
  };

  let ignoreKeys = [
    "first_name",
    "last_name",
    "email",
    "phone",
    "state",
    "address_Line_1",
    "address_Line_2",
    "city",
    "postcode",
    "country",
    "total_purchase",
  ];
  return (
    <>
      <Stack
        direction="row"
        sx={{
          backgroundColor: "Background.paper",
          padding: "10px",
          borderBottomColor: "#e8e8e8",
          borderBottomWidth: 0.2,
          borderBottomStyle: "solid",
          overflow: "scroll",
          paddingTop: 0,
        }}
        spacing={4}
        className="scrollbar-hidden"
      >
        {tabs
          .filter((i) => {
            return i.value === "products" ? !hideProductMapping : true;
          })
          ?.map((i) => {
            return (
              <Button
                variant={"text"}
                // variant={selectedTab !== i.value ? "outlined" : "contained"}
                sx={{
                  backgroundColor:
                    selectedTab === i.value ? "#e6e6e6" : "transparent",
                  color: "#000",
                  // color: selectedTab === i.value ? "primary.main" : "#000",
                  "&:hover": {
                    backgroundColor:
                      selectedTab === i.value ? "#e6e6e6" : "transparent",
                  },
                }}
                onClick={() => setSelectedTab(i.value)}
              >
                {i.label}
              </Button>
            );
          })}
      </Stack>

      <TabPanel value={"customer"} index={selectedTab}>
        <CustomerMapping
          customer={wooCommerceCustomer}
          channel={"woocommerce"}
          attributes={reduxWoocommerceMapping?.customers?.attributes || {}}
          updatingMap={updatingMap}
          onMap={saveMap}
          ignoreKeys={ignoreKeys}
        />
      </TabPanel>
    </>
  );
};

export default Mapping;
export const GroupHeader = styled("div")(({ theme }) => ({
  position: "sticky",
  top: "-8px",
  padding: "4px 10px",
  color: theme.palette.primary.main,
  backgroundColor:
    theme.palette.mode === "light"
      ? lighten(theme.palette.primary.light, 0.85)
      : darken(theme.palette.primary.main, 0.8),
}));

export const GroupItems = styled("ul")({
  padding: 0,
});
export const CustomerMapping = ({
  customer,
  channel,
  attributes,
  updatingMap,
  onMap,
  uiJson,
  ignoreKeys,
}) => {
  const [options, setOptions] = useState([]);
  const [attribute, setAttribute] = useState({});
  const [feildsJson, setFeildsJson] = useState({});
  const [mappingUIState, setMappingUIState] = useState([]);
  const dispatch = useDispatch();

  const editArrayForm = (payload) => {
    setMappingUIState((pre) => {
      let array = [...pre];
      let key = Object.keys(payload)?.[0];
      let value = Object.values(payload)?.[0];
      array[validateNumber(key)] = value;
      return array;
    });
  };

  useEffect(() => {
    setFeildsJson(isObjWithValues(uiJson) ? uiJson : mappingFeilds);
    // console.log(Object.values(feildsJson || {})?.map((obj) => obj?.keys).flat);
  }, [uiJson]);

  useEffect(() => {
    setOptions(
      getDropDownValueFromObj({
        obj: customer,
        channel: channel,
      })
    );
  }, [customer]);
  useEffect(() => {
    if (isArrayWithValues(attributes)) setMappingUIState(attributes || {});
  }, [attributes]);

  const onMapClick = () => {
    console.log(mappingUIState);
  };
  const removeMapping = (index) => {
    setMappingUIState((pre) => {
      return pre.filter((obj, i) => i !== index);
    });
  };

  console.log(options);

  return (
    <Box p={4}>
      <Stack
        direction={"row"}
        alignItems={"center"}
        sx={{ justifyContent: "flex-end", gap: 3 }}
      >
        <Button
          variant="outlined"
          onClick={() => {
            setMappingUIState((pre) => [...pre, {}]);
          }}
        >
          Create New Field
        </Button>
        <LoadingButton
          loading={updatingMap}
          variant="outlined"
          onClick={() => {
            onMap(mappingUIState);
          }}
        >
          Update
        </LoadingButton>
      </Stack>
      {/* {listArray?.map()} */}

      <TableContainer
        component={Paper}
        // className={"shadow_card"}
        sx={{ borderRadius: "14px" }}
      >
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              {/* <TableCell colSpan={0.5}>SR. no</TableCell> */}
              <TableCell>Jwero Fields</TableCell>
              <TableCell>{capitalizeFirstLetter(channel)} Fields </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {mappingUIState.map((obj, index) => {
              return (
                <TableRow>
                  {/* <TableCell colSpan={0.5}>
                                    {index + 1}
                                </TableCell> */}
                  <TableCell>
                    <Autocomplete
                      value={mappingUIState?.[index]?.jwero_key || null}
                      onChange={(e, newValue) => {
                        console.log(newValue, index);
                        editArrayForm({
                          [index]: {
                            ...(mappingUIState?.[index] || {}),
                            jwero_key: newValue,
                          },
                        });
                        // onEditForm({
                        //     [obj?.key]: {
                        //         ...(newValue || {}),
                        //         jwero_key: obj?.jwero_key,
                        //     },
                        // });
                        // console.log(obj);
                      }}
                      options={Object.values(feildsJson || {})
                        ?.map((obj) => obj?.keys)
                        ?.flat()
                        ?.filter((obj) => !ignoreKeys?.includes(obj?.key))}
                      // groupBy={(option) => option?.from}
                      getOptionLabel={(option) => {
                        // console.log(option)
                        return `${option?.label}`;
                      }}
                      // sx={{ width: 160 }}
                      renderInput={(params) => (
                        <TextField {...params} size="small" />
                      )}
                      renderGroup={(params) => (
                        <li key={params.key}>
                          {params.group && (
                            <GroupHeader>{params.group}</GroupHeader>
                          )}
                          <GroupItems>{params.children}</GroupItems>
                        </li>
                      )}
                    />
                  </TableCell>
                  <TableCell
                    sx={{ display: "flex", alignItems: "center", gap: 2 }}
                  >
                    <Autocomplete
                      sx={{ flexGrow: 1 }}
                      value={mappingUIState?.[index]?.their_key || null}
                      onChange={(e, newValue) => {
                        editArrayForm({
                          [index]: {
                            ...(mappingUIState?.[index] || {}),
                            their_key: {
                              ...(newValue || {}),
                              // jwero_key: obj?.jwero_key,
                            },
                          },
                        });
                        // onEditForm({
                        //     [obj?.key]: {
                        //         ...(newValue || {}),
                        //         jwero_key: obj?.jwero_key,
                        //     },
                        // });
                      }}
                      options={options}
                      groupBy={(option) => option?.from}
                      getOptionLabel={(option) =>
                        `${option?.from ? `${option?.from} >` : ""} ${
                          option?.key || ""
                        }`
                      }
                      renderInput={(params) => (
                        <TextField {...params} size="small" />
                      )}
                      renderGroup={(params) => (
                        <li key={params.key}>
                          {params.group && (
                            <GroupHeader>{params.group}</GroupHeader>
                          )}
                          <GroupItems>{params.children}</GroupItems>
                        </li>
                      )}
                    />
                    <Button color="error" onClick={() => removeMapping(index)}>
                      Remove
                    </Button>

                    {/* <IconButton onClick={() => removeMapping(index)}>
                      <Close />
                    </IconButton> */}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {/* <Grid container rowSpacing={2} spacing={3}>
                {Object.keys(feildsJson)?.map((key) => {
                    return (
                        <>
                            <Grid item xs={12}>
                                <Typography variant="h6">{formatServerValue(key)}</Typography>
                            </Grid>
                            {feildsJson?.[key]?.keys?.map((obj) => {
                                return (
                                    <Grid item md={12} lg={6} spacing={2} rowSpacing={2}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={6}>
                                                {" "}
                                                <Typography>{obj?.label}</Typography>{" "}
                                            </Grid>
                                            <Grid item xs={6}>
                                                <MapTextFeild onEditForm={onEditForm} obj={obj} attribute={attribute} options={options} />
                                                
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                );
                            })}

                            <Grid item xs={12} my={2}>
                                <Divider />
                            </Grid>
                        </>
                    );
                })}
            </Grid> */}
    </Box>
  );
};

const MapTextFeild = ({ obj, attribute, options, onEditForm }) => {
  useEffect(() => {
    let preDefindobj = options.find(
      (o) => Object.keys(o)?.length === 1 && o?.key === obj.key
    );
    if (!attribute?.[obj?.key] && preDefindobj) {
      onEditForm({
        [obj?.key]: {
          ...(preDefindobj || {}),
          jwero_key: obj?.jwero_key,
        },
      });
    }

    //   return () => {

    //   }
  }, [options]);

  return (
    <Autocomplete
      value={attribute?.[obj?.key] || null}
      onChange={(e, newValue) => {
        console.log(newValue);
        onEditForm({
          [obj?.key]: {
            ...(newValue || {}),
            jwero_key: obj?.jwero_key,
          },
        });
        // console.log(obj);
      }}
      options={options}
      groupBy={(option) => option?.from}
      getOptionLabel={(option) =>
        `${option?.from ? `${option?.from} >` : ""} ${option?.key || ""}`
      }
      // sx={{ width: 160 }}
      renderInput={(params) => <TextField {...params} size="small" />}
      renderGroup={(params) => (
        <li key={params.key}>
          {params.group && <GroupHeader>{params.group}</GroupHeader>}
          <GroupItems>{params.children}</GroupItems>
        </li>
      )}
    />
  );
};

let meta_dataKey = {
  key: "meta_data",
  data_type: "array",
  array_obj_key: "key",
  array_obj_value: "value",
};
export const mappingFeilds = {
  account_info: {
    keys: [
      {
        key: "first_name",
        label: "First Name",
        type: "string",
      },
      { key: "last_name", label: "Last Name", data_type: "string" },
      { key: "email", label: "Email", data_type: "string" },
      {
        key: "dialcode_mobile",
        label: "Mobile Dialcode",
        data_type: "string",
        jwero_key: [meta_dataKey],
        // from: "meta_data",
        // dataType: "array",
      },
      {
        key: "phone",
        label: "Mobile",
        from: "meta_data",
        data_type: "string",
        jwero_key: [meta_dataKey],
      },
      {
        key: "dialcode_whatsapp",
        label: "Whatsapp Dialcode",
        from: "meta_data",
        data_type: "string",
        jwero_key: [meta_dataKey],
      },
      {
        key: "whatsapp",
        label: "Whatsapp",
        from: "meta_data",
        data_type: "string",
        jwero_key: [meta_dataKey],
      },
      {
        key: "contact_type",
        label: "Contact type",
        from: "meta_data",
        data_type: "string",
        jwero_key: [meta_dataKey],
      },
    ],
  },
  personal_info: {
    keys: [
      {
        key: "user_birthday",
        label: "Birthday",
        from: "meta_data",
        data_type: "string",
        jwero_key: [meta_dataKey],
      },
      {
        key: "gender",
        label: "Gender",
        from: "meta_data",
        data_type: "string",
        jwero_key: [meta_dataKey],
      },
      {
        key: "user_anniversary",
        label: "Anniversary",
        from: "meta_data",
        data_type: "string",
        jwero_key: [meta_dataKey],
      },
      {
        key: "profession",
        label: "Profession",
        from: "meta_data",
        data_type: "string",
        jwero_key: [meta_dataKey],
      },
      {
        key: "annual_income",
        label: "Annual Income",
        from: "meta_data",
        data_type: "string",
        jwero_key: [meta_dataKey],
      },
      {
        key: "ethincity",
        label: "Ethnicity",
        from: "meta_data",
        data_type: "string",
        jwero_key: [meta_dataKey],
      },
      {
        key: "language",
        label: "Language",
        from: "meta_data",
        data_type: "string",
        jwero_key: [meta_dataKey],
      },
      {
        key: "religion",
        label: "Religion",
        from: "meta_data",
        data_type: "string",
        jwero_key: [meta_dataKey],
      },
    ],
  },
  address_Info: {
    keys: [
      {
        key: "address_Line_1",
        label: "Address Line 1",
        from: "billing",
        data_type: "string",
        jwero_key: [
          {
            key: "billing",
            data_type: "object",
          },
        ],
      },
      {
        key: "address_Line_2",
        label: "Address Line 2",
        data_type: "string",
        from: "billing",
        data_type: "string",
        jwero_key: [
          {
            key: "billing",
            data_type: "object",
          },
        ],
      },
      {
        key: "city",
        label: "City",
        from: "billing",
        jwero_key: [
          {
            key: "billing",
            data_type: "object",
          },
        ],
      },
      {
        key: "postcode",
        label: "Post Code",
        from: "billing",
        data_type: "string",
        jwero_key: [
          {
            key: "billing",
            data_type: "object",
          },
        ],
      },
      {
        key: "country",
        label: "Country",
        from: "billing",
        data_type: "string",
        jwero_key: [
          {
            key: "billing",
            data_type: "object",
          },
        ],
      },
      {
        key: "state",
        label: "State",
        from: "billing",
        data_type: "string",
        jwero_key: [
          {
            key: "billing",
            data_type: "object",
          },
        ],
      },
    ],
  },
  others_info: {
    keys: [
      {
        key: "lead_source",
        label: "Lead Source",
        from: "meta_data",
        data_type: "string",
        jwero_key: [meta_dataKey],
      },
      {
        key: "customer_since",
        label: "Customer Since",
        from: "meta_data",
        data_type: "string",
        jwero_key: [meta_dataKey],
      },
      {
        key: "first_purchase",
        label: "First Purchase",
        from: "meta_data",
        data_type: "string",
        jwero_key: [meta_dataKey],
      },
      {
        key: "total_purchase",
        label: "Total Purchase",
        from: "meta_data",
        data_type: "string",
        jwero_key: [meta_dataKey],
      },
      {
        key: "visits_in_showroom",
        label: "Showroom Visits",
        from: "meta_data",
        data_type: "string",
        jwero_key: [meta_dataKey],
      },
      {
        key: "last_visit_in_showrrom",
        label: "Last Visited",
        from: "meta_data",
        data_type: "string",
        jwero_key: [meta_dataKey],
      },
      {
        key: "visits_on_website",
        label: "Website Visits",
        from: "meta_data",
        data_type: "string",
        jwero_key: [meta_dataKey],
      },
      {
        key: "last_visit_on_website",
        label: "Last Visited",
        from: "meta_data",
        data_type: "string",
        jwero_key: [meta_dataKey],
      },
      {
        key: "branch_from",
        label: "Branch",
        from: "meta_data",
        data_type: "string",
        jwero_key: [meta_dataKey],
      },
    ],
  },
  social_info: {
    keys: [
      {
        key: "customer_facebook",
        label: "Facebook",
        from: "meta_data",
        data_type: "string",
        jwero_key: [meta_dataKey],
      },
      {
        key: "customer_instagram",
        label: "Instagram",
        from: "meta_data",
        data_type: "string",
        jwero_key: [meta_dataKey],
      },
      {
        key: "customer_instagram",
        label: "Instagram",
        from: "meta_data",
        data_type: "string",
        jwero_key: [meta_dataKey],
      },
      {
        key: "customer_twitter",
        label: "Twitter",
        from: "meta_data",
        data_type: "string",
        jwero_key: [meta_dataKey],
      },
      {
        key: "customer_linkedin",
        label: "Linkedin",
        from: "meta_data",
        data_type: "string",
        jwero_key: [meta_dataKey],
      },
      {
        key: "customer_pinterest",
        label: "Pinterest",
        from: "meta_data",
        data_type: "string",
        jwero_key: [meta_dataKey],
      },
    ],
  },
};

let wooCommerceCustomer = {
  id: 6939,
  date_created: "2022-02-25T11:04:49",
  date_created_gmt: "2022-02-25T05:34:49",
  date_modified: "2023-03-03T17:30:56",
  date_modified_gmt: "2023-03-03T12:00:56",
  email: "",
  first_name: "Karan",
  last_name: "Jagani",
  role: "customer",
  username: "9920282002",
  billing: {
    first_name: "K",
    last_name: "J",
    company: "",
    address_1: "Test",
    address_2: "",
    city: "Mumbai",
    postcode: "401101",
    country: "IN",
    state: "MH",
    email: "karanjagani@hotmail.com",
    phone: "9920282002",
  },
  shipping: {
    first_name: "K",
    last_name: "J",
    company: "",
    address_1: "Test",
    address_2: "",
    city: "Mumbai",
    postcode: "401101",
    country: "IN",
    state: "MH",
    phone: "",
  },
  is_paying_customer: true,
  avatar_url: "https://secure.gravatar.com/avatar/?s=96&d=mm&r=g",
  meta_data: [
    {
      id: 193818,
      key: "dialcode_mobile",
      value: "91",
    },
    {
      id: 193819,
      key: "whatsapp",
      value: "9920282002",
    },
    {
      id: 193820,
      key: "dialcode_whatsapp",
      value: "91",
    },
    {
      id: 193821,
      key: "lead_source",
      value: "Whatsapp",
    },
    {
      id: 193881,
      key: "billing_mobile",
      value: "9920282002",
    },
    {
      id: 220762,
      key: "primary_blog",
      value: "27",
    },
    {
      id: 220763,
      key: "source_domain",
      value: "tiarabytj.com",
    },
    {
      id: 220766,
      key: "lead_source_27",
      value: "Catalog",
    },
    {
      id: 220768,
      key: "wc_last_active",
      value: "1677801600",
    },
    {
      id: 220769,
      key: "woodmart_wishlist_330",
      value: {
        expires: 1678256010,
        products: [],
      },
    },
    {
      id: 227325,
      key: "shipping_method",
      value: "",
    },
    {
      id: 238269,
      key: "lead_source_18",
      value: "Website",
    },
    {
      id: 238332,
      key: "woodmart_wishlist_375",
      value: {
        expires: 1651824593,
        products: [],
      },
    },
    {
      id: 238333,
      key: "last_autoreply_time_18",
      value: "1651220957",
    },
    {
      id: 239548,
      key: "digigold",
      value: "1",
    },
    {
      id: 239549,
      key: "nominee_name",
      value: "Test",
    },
    {
      id: 239550,
      key: "nominee_relation",
      value: "Test",
    },
    {
      id: 239551,
      key: "digigold_proof_type",
      value: "aadharcard",
    },
    {
      id: 247861,
      key: "lead_source_486",
      value: "Whatsapp",
    },
    {
      id: 254024,
      key: "product_groups",
      value: [],
    },
    {
      id: 254025,
      key: "access",
      value: ["retailer_app", "website"],
    },
    {
      id: 254026,
      key: "label",
      value: "Order Complete",
    },
    {
      id: 293605,
      key: "lead_source_496",
      value: "Whatsapp",
    },
    {
      id: 625827,
      key: "group_name_27",
      value: [
        "",
        "VIP",
        "Ranchi",
        "VIP Customer ",
        "Kajol",
        "Follow Up",
        "return",
        "Daily Customers",
        "Team",
        "MUMBAI ZONE ",
        "Testing",
        "Internation Customer",
      ],
    },
    {
      id: 731124,
      key: "last_autoreply_time_27",
      value: "1665467746",
    },
    {
      id: 1106614,
      key: "woodmart_wishlist_groups",
      value: {
        330: "My wishlist",
      },
    },
    {
      id: 1109222,
      key: "whatsapp_id",
      value: "919920282002",
    },
    {
      id: 1292959,
      key: "lead_source_559",
      value: "Catalog",
    },
    {
      id: 1296098,
      key: "otp_verified",
      value: "1",
    },
    {
      id: 1734764,
      key: "digigold_uid_27",
      value: {
        69766: "SP-6939",
      },
    },
  ],
  nickname: "Karan Jagani Tanika Tech",

  orders: [70841, 69561, 69431],
};
