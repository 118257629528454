// Import the functions you need from the SDKs you need
import firebase from "firebase";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyC1S9XvUPUI7IXKWJfuSqyHJcKMbK9gznc",
  authDomain: "tanika-admin.firebaseapp.com",
  projectId: "tanika-admin",
  storageBucket: "tanika-admin.appspot.com",
  messagingSenderId: "827516174119",
  appId: "1:827516174119:web:cd6ebc029f35853ba7f302",
  measurementId: "G-BQS6ZNRSV7",
};

// Initialize Firebase
// const app =
// const analytics = getAnalytics(app);
firebase.initializeApp(firebaseConfig);

export default firebase;
