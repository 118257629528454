import {
  Add,
  ArrowBack,
  ArrowForward,
  Cached,
  ChatBubbleOutline,
  ChatBubbleOutlined,
  ChatBubbleOutlineOutlined,
  Close,
  FavoriteBorderOutlined,
  FavoriteOutlined,
  FileUpload,
  MoreVert,
  PlayArrowRounded,
  Reply,
  ReplyOutlined,
  ThumbUpOutlined,
} from "@mui/icons-material";
import { LoadingButton, Masonry } from "@mui/lab";
import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
  CircularProgress,
  LinearProgress,
  Snackbar,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  TextareaAutosize,
  Avatar,
  useMediaQuery,
  IconButton,
  Popover,
  ListItem,
  ListItemButton,
  ListItemText,
  Card,
  CardContent,
  Paper,
  styled,
  Link,
} from "@mui/material";
import { useTheme } from "@mui/system";
import axios from "axios";
import React, { createRef, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  dialogStyle,
  formatDate,
  getAxiosError,
  getChannelData,
  getIntegrationObj,
  getRandomInt,
  getToken,
  getWebsite,
  isArrayWithValues,
  isObjWithValues,
  postIntegrationData,
  validateNumber,
} from "../../../helper";
import {
  deleteFacebookPost,
  setHubData,
} from "../../../redux/actions/hubActions";
import { EDIT_SETTINGS } from "../../../redux/actions/settingActions";
import { SocialIllustration } from "../../../Svgs";
import { getFacebookIntegrationInRedux } from "../../integrations/Integrations";
import CreatePost from "../CreatePost";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, EffectFade } from "swiper";

import "swiper/css"; // core Swiper
import "swiper/css/pagination";

import { setGlobalToast } from "../../../redux/actions/helperActions";

function Facebook() {
  const [accessToken, setAccessToken] = useState(
    ""
    // "EAAKDqRpyaucBAPVXeZCDKg1wMzqERAxvUFnocDSLacXQj2zRcJ8vZCoMszsjK3BGNoJ6vO6mhG9YbgmQCJrh08zOZBUwaZBJa9Ci0tP10SP3vyhtJvrKZAE4N7D0K9VYVMkD3XV7pVoImbWT0gK0BMyOIZA6rS3GCi6WRFQPkA4gX6P3swKXfi"
  );
  const [pageId, setPageId] = useState("");

  const [selectedPageAccessToken, setSelectedPageAccessToken] = useState("");
  // "EAAKDqRpyaucBAMsTSVtz9kUoXLwMzCZA2tZBNwLIzPKWc2G8o6PrcoUeyKruwmJLHTtW9RuN5aZBFLR2BYg9x3rkNCg7inXZBhWg9tUsrKkj3bjnC9OeZCZCfqQeeJTSJHAbWzbqQssZBaz7uFWTEAjB8ILebhxCIzgJwntZB0oPKeKrfS5KxzfB"
  const [pages, setPages] = useState([]);
  const [selectedPage, setSelectedPage] = useState("");
  const [posts, setPosts] = useState([]);
  const [info, setInfo] = useState({});
  const [nextFeedLink, setNextFeedLink] = useState("");
  const [notIntegrated, setNotIntegrated] = useState(false);
  const [facebookBasicInfo, setFacebookBasicInfo] = useState({});

  const [fetchingFeed, setFetchingFeed] = useState(false);
  const [pagesDisabled, setPagesDisabled] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [noMoreFeed, setNoMoreFeed] = useState(true);
  const [noFeed, setNoFeed] = useState(false);
  const [toast, setToast] = useState({ show: false });
  const [showCreateCatalog, setShowCreateCatalog] = useState(false);
  const createPostRef = useRef();
  const [postDialog, setPostDialog] = useState(false);
  const [postDetails, setPostDetail] = useState({});

  const dispatch = useDispatch();

  const theme = useTheme();
  const isIpad = useMediaQuery(theme.breakpoints.down("md"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const facebookIntegration = useSelector(
    (state) => state.settings.facebookIntegration
  );
  const reduxMetaIntegration = useSelector(
    (state) => state.settings.metaIntegration
  );

  const facebookPosts = useSelector((state) => state.hub.facebookPosts);
  const facebookPages = useSelector((state) => state.hub.facebookPages);
  const globalPopupStyle =
    useSelector((state) => state.helper.globalPopupStyle) || {};

  // const facebookInfo = useSelector((state) => state.hub.facebookInfo);
  const facebookNextFeedLink = useSelector(
    (state) => state.hub.facebookNextFeedLink
  );
  const facebookInfo =
    getIntegrationObj(reduxMetaIntegration, "facebook")?.obj
      ?.facebook_pages_info || false;
  useEffect(() => {
    if (Array.isArray(facebookPosts)) setPosts(facebookPosts);
  }, [facebookPosts]);
  useEffect(() => {
    if (Array.isArray(facebookPages)) setPages(facebookPages);
  }, [facebookPages]);

  useEffect(() => {
    if (facebookInfo) setInfo(facebookInfo);
  }, [facebookInfo]);

  useEffect(async () => {
    if (isArrayWithValues(facebookPosts)) return;
    if (isObjWithValues(reduxMetaIntegration)) {
      for (const key in reduxMetaIntegration) {
        if (!isArrayWithValues(reduxMetaIntegration[key]?.connected)) return;
        if (reduxMetaIntegration[key]?.connected.includes("facebook")) {
          let facebookObj = { ...reduxMetaIntegration[key] };
          setPageId(facebookObj?.facebook_pages_info?.id);
          setFacebookBasicInfo(facebookObj?.facebook_pages_info);
          setInfo(facebookObj?.facebook_pages_info);

          // if (!isArrayWithValues(facebookPosts)) {
          setFetchingFeed(true);
          let res = await fetchPosts(facebookObj?.facebook_pages_info?.id);
          setFetchingFeed(false);

          // dispatch(setHubData({ facebookNextFeedLink: res?.paging?.cursors?.after }))
          if (isArrayWithValues(res?.data)) {
            dispatch(setHubData({ facebookPosts: res.data }));

            // setPosts(res?.data)
          }
          // }
        }
      }
    }
  }, [reduxMetaIntegration]);

  // useEffect(async () => {
  //   if (accessToken && !facebookInfo) {
  //     setFetchingFeed(true);
  //     setPagesDisabled(true);
  //     setNoMoreFeed(false);
  //     await getInfoAndFeed();
  //   }

  // }, [accessToken]);

  // const getInfoAndFeed = async (updatedPage) => {
  //   let info = await fetchFacebookInfo(accessToken);
  //   dispatch(setHubData({ facebookInfo: info }));
  //   if (info && info.id) {
  //     try {
  //       let pages = await fetchFacebookPages(info.id, accessToken);
  //       if (pages && isArrayWithValues(pages.data)) {
  //         dispatch(setHubData({ facebookPages: pages.data }));
  //         setPages(pages.data);
  //         let { data, paging } = pages;
  //         let feed;
  //         if (facebookIntegration && facebookIntegration.page) {
  //           let { page } = facebookIntegration;
  //           page = updatedPage ? updatedPage : page;
  //           feed = await fetchPosts(page.id);
  //         } else {
  //           feed = await fetchPosts(data[0].id);
  //         }
  //         console.log(feed);

  //         if (feed.paging && feed.paging.next)
  //           dispatch(setHubData({ facebookNextFeedLink: feed.paging.next }));
  //         // setNextFeedLink(feed.paging.next);
  //         else setNoMoreFeed(true);

  //         if (isArrayWithValues(feed.data))
  //           dispatch(setHubData({ facebookPosts: feed.data }));
  //         else if (Array.isArray(feed.data) && feed.data.length === 0)
  //           setNoFeed(true);
  //         else setNoFeed(true);
  //       }

  //       setFetchingFeed(false);
  //       setPagesDisabled(false);
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   }
  // };

  const getPosts = async (page_id) => {
    let feed = await fetchPosts(page_id ? page_id : pageId);

    // dispatch(setHubData({ facebookPosts: feed.data }));
    // if ()
  };

  useEffect(() => {
    if (!isObjWithValues(facebookIntegration))
      getFacebookIntegrationInRedux(dispatch);
  }, []);

  useEffect(() => {
    if (facebookIntegration && facebookIntegration.access_token)
      setAccessToken(facebookIntegration.access_token);
    // else setNotIntegrated(true);

    // console.log(facebookIntegration, "<<< ");
  }, [facebookIntegration]);

  useEffect(() => {
    if (isArrayWithValues(pages)) {
      if (facebookIntegration && facebookIntegration.page) {
        let { page } = facebookIntegration;
        setSelectedPage(page.id);
      } else setSelectedPage(pages[0].id);
    }
  }, [pages]);

  useEffect(() => {
    if (selectedPage && isArrayWithValues(pages)) {
      let obj = pages.find((i) => i.id == selectedPage);
      if (obj) setSelectedPageAccessToken(obj.access_token);
      else setSelectedPageAccessToken("");
    } else setSelectedPageAccessToken("");
  }, [selectedPage]);

  const fetchFacebookInfo = async () => {
    try {
      // let res = await axios({
      //   url: `https://graph.facebook.com/v8.0/me?fields=id,name,email,picture&access_token=${accessToken}`,
      // });
      let res = await getChannelData("app", "/me?fields=id,name,email,picture");
      console.log(res);
      return res;
    } catch (error) {
      console.log(getAxiosError(error));
    }
  };

  // const fetchPosts = async (pageId) => {
  //   const link = "https://graph.facebook.com/v9.0";
  //   const fields =
  //     "full_picture,message,created_time,status_type,application,permalink_url,attachments,likes.summary(1),comments.summary(1).limit(0)";
  //   try {
  //     let res = await axios({
  //       url: `${link}/${pageId}/feed?fields=${fields}&access_token=${accessToken}&limit=15&page=1`,
  //     });
  //     return res.data;
  //   } catch (error) {
  //     console.log(getAxiosError(error));
  //   }
  // };
  const fetchPosts = async (pageId) => {
    const fields =
      "full_picture,message,created_time,status_type,application,permalink_url,attachments,likes.summary(1),comments.summary(1).limit(0)";
    let endpoint = `/${pageId}/feed?fields=${fields}&limit=20`;
    const res = await getChannelData("facebook", endpoint);

    dispatch(setHubData({ facebookNextFeedLink: res?.paging?.next }));
    return res;
  };

  const loadMore = async () => {
    setLoadingMore(true);
    if (facebookNextFeedLink) {
      const fields =
        "full_picture,message,created_time,status_type,application,permalink_url,attachments,likes.summary(1),comments.summary(1).limit(0)";
      try {
        let endpoint = `/${pageId}/feed?fields=${fields}&limit=20&after=${facebookNextFeedLink}`;
        const res = await getChannelData("facebook", endpoint);
        // let res = await axios({
        //   url: nextFeedLink,
        // });

        let data = res;
        // console.log(data);
        dispatch(
          setHubData({ facebookNextFeedLink: data?.paging?.cursors?.after })
        );
        if (data.data) {
          let { paging } = data;
          dispatch(setHubData({ facebookPosts: [...posts, ...data.data] }));
          setNextFeedLink(paging?.cursors?.after);
        }
        setLoadingMore(false);
      } catch (error) {
        console.log(getAxiosError(error));
        setLoadingMore(false);
      }
    }
  };

  // const onPageChange = async (value) => {
  //   setSelectedPage(value);
  //   setPagesDisabled(true);
  //   setFetchingFeed(true);
  //   let page = pages.find((i) => i.id === value);
  //   dispatch(setHubData({ facebookPosts: [] }));
  //   await setPageInFacebookIntegration(page);
  // };

  // const setPageInFacebookIntegration = async (page) => {
  //   setNoFeed(false);
  //   try {
  //     const website = await getWebsite();
  //     const token = await getToken();

  //     let FBIntegration = { ...facebookIntegration };
  //     let res = await axios({
  //       url: `${website}/wp-json/store/v1/settings/facebook_integration`,
  //       headers: {
  //         Authorization: `Basic ${token}`,
  //       },
  //       data: {
  //         type: "facebook_integration",
  //         data: {
  //           ...FBIntegration,
  //           page: page ? page : {},
  //         },
  //       },
  //       method: "POST",
  //     });
  //     let { data } = res;
  //     if (data.success) {
  //       dispatch({
  //         type: EDIT_SETTINGS,
  //         payload: {
  //           facebookIntegration: data.data,
  //         },
  //       });
  //       await getInfoAndFeed(page);
  //     } else throw new Error();
  //   } catch (error) {
  //     setToast({
  //       show: true,
  //       message: "Error changing page! Try again later",
  //       severity: "error",
  //     });
  //   }
  // };

  const closeCreateDialog = () => {
    setShowCreateCatalog(false);
  };

  const openCreateDialog = () => {
    setShowCreateCatalog(true);
  };

  const onRefresh = async () => {
    setPosts([]);
    if (!facebookInfo) return;
    setFetchingFeed(true);
    let feed = await fetchPosts(facebookInfo.id);

    // if (feed.paging && feed.paging.next)
    // dispatch(setHubData({ facebookNextFeedLink: feed?.paging?.cursors?.after }));
    // setNextFeedLink(feed.paging.next);
    // else setNoMoreFeed(true);

    if (isArrayWithValues(feed.data))
      dispatch(setHubData({ facebookPosts: feed.data }));
    else if (Array.isArray(feed.data) && feed.data.length === 0)
      setNoFeed(true);
    else setNoFeed(true);
    setFetchingFeed(false);
  };

  const postPress = (obj) => {
    setPostDetail(obj);
    setPostDialog(true);
  };

  const postDialogClose = () => {
    setPostDialog(false);
    setPostDetail({});
  };

  const updateFacebookPostInRedux = (obj) => {
    if (isArrayWithValues(posts)) {
      const updatedPost = posts.map((i) => (i.id === obj.id ? obj : i));

      dispatch(setHubData({ facebookPosts: updatedPost }));
    }
  };

  if (notIntegrated)
    return (
      <Box
        sx={{
          width: "300px",
          margin: "auto",
          marginTop: "15vh",
          marginBottom: "10vh",
          textAlign: "center",
        }}
      >
        <SocialIllustration />
        <Typography variant="h6" color="text.secondary" mt={5}>
          Looks like facebook not integrated yet!
        </Typography>
      </Box>
    );

  return (
    <Box sx={{ padding: "6px" }}>
      <Snackbar
        open={toast.show}
        autoHideDuration={4000}
        onClose={() => setToast((state) => ({ ...state, show: false }))}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert severity={toast.severity} sx={{ width: "100%" }}>
          {toast.message}
        </Alert>
      </Snackbar>
      <Stack direction="row" justifyContent={"flex-end"}>
        {getIntegrationObj(reduxMetaIntegration, "facebook")?.obj
          ?.facebook_pages_info && (
            <LoadingButton
              disabled={fetchingFeed}
              onClick={onRefresh}
              startIcon={<Cached />}
            >
              Refresh
            </LoadingButton>
          )}
        <Button
          variant="outlined"
          size="small"
          endIcon={<Add />}
          sx={{ mr: 2 }}
          onClick={() => createPostRef.current.openCreateDialog()}
        >
          Create
        </Button>
        {/* <FormControl size="small" sx={{ width: "200px" }}>
          <InputLabel id="demo-simple-select-label">Pages</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selectedPage}
            label="Pages"
            disabled={pagesDisabled}
            onChange={(e) => onPageChange(e.target.value)}
          >
            {pages.map((i) => {
              return <MenuItem value={i.id}>{i.name}</MenuItem>;
            })}
          </Select>
        </FormControl> */}
      </Stack>
      <Stack
        direction={"row"}
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant="h4" my={4}>
          Posts
        </Typography>
      </Stack>
      {fetchingFeed && (
        <Box
          sx={{
            width: "300px",
            margin: "auto",
            marginTop: "30vh",
            marginBottom: "30vh",
            textAlign: "center",
          }}
        >
          <LinearProgress />
          <Typography variant="h6" color="text.secondary" mt={3}>
            Fetching Feed
          </Typography>
        </Box>
      )}
      {noFeed && (
        <Box
          sx={{
            width: "300px",
            margin: "auto",
            marginTop: "10vh",
            marginBottom: "10vh",
            textAlign: "center",
          }}
        >
          <Typography variant="h5" color="text.secondary" mt={3}>
            You haven't posted anything here!
          </Typography>
        </Box>
      )}

      {/* <Grid container spacing={3}>
        {posts.map((i) => {
          return (
            <Grid
              xs={6}
              sm={6}
              md={4}
              lg={3}
              xl={3}
              sx={
                {
                  // width: { xs: "45%", md: "250px", lg: "300px", xl: "350px" },
                  // height: { xs: "45%", md: "250px", lg: "300px", xl: "350px" },
                }
              }
              key={i.id}
              item
            >
              <FacebookThumbnail data={i} info={info} />
            </Grid>
          );
        })}
      </Grid> */}

      <Box
        sx={{
          marginRight: "4px",
          width: isIpad ? "calc(100vw - 10px)" : "calc(100vw - 150px)",
        }}
      >
        <Masonry
          columns={isMobile ? 1 : isIpad ? 2 : 4}
          spacing={4}
          sx={{ alignContent: "flex-start" }}
        >
          {posts.map((i, index) => (
            // <Grid item xs={3}>
            <Item key={index}>
              <PostCard
                facebookBasicInfo={facebookBasicInfo}
                data={i}
                key={i.id}
                info={info}
                postPress={postPress}
              />
            </Item>
            // </Grid>
          ))}
        </Masonry>
      </Box>

      {/* old grid */}
      {/* <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(12, 1fr)",
          gridTemplateColumns: "repeat(12, minmax(180px, 1fr))",
          gridTemplateColumns: "repeat(auto-fill, minmax(180px, 1fr))",
          gap: "1rem",
        }}
      >
        {posts.map((i) => {
          return (
            <FacebookThumbnail
              data={i}
              key={i.id}
              info={info}
              postPress={postPress}
            />
          );
        })}
      </Box> */}

      {facebookNextFeedLink && (
        <Stack direction="row" justifyContent={"center"} mt={7}>
          <LoadingButton
            loading={loadingMore}
            onClick={loadMore}
            variant="contained"
          >
            Load More
          </LoadingButton>
        </Stack>
      )}
      <CreatePost
        ref={createPostRef}
        setToast={setToast}
        onPostCreated={() => {
          getPosts(pageId);
        }}
      />

      <Dialog
        open={postDialog}
        onClose={postDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        // fullScreen
        maxWidth="lg"
        fullWidth
        sx={{
          height: isIpad ? "80vh" : "90vh",
          margin: "auto 0px",
          padding: "0px",
          borderRadius: "20px",
          ".MuiDialog-paper": {
            borderRadius: "30px",
          },
        }}
        {...globalPopupStyle}
      >
        <DialogContent
          sx={{
            height: "100vh",
            padding: "0px",
            borderRadius: isIpad ? "0" : "20px",
          }}
        >
          <FacebookPost
            postDialogClose={postDialogClose}
            data={postDetails}
            info={info}
            selectedPageAccessToken={selectedPageAccessToken}
            updateFacebookPostInRedux={updateFacebookPostInRedux}
            setPostDetail={setPostDetail}
          />
        </DialogContent>
      </Dialog>
    </Box>
  );
}

export default Facebook;

const FacebookPost = ({
  data,
  info,
  selectedPageAccessToken,
  updateFacebookPostInRedux,
  setPostDetail,
  postDialogClose,
}) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isIpad = useMediaQuery(theme.breakpoints.down("md"));
  // const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [imageUrl, setImageUrl] = useState("");
  const [videoUrl, setVideoUrl] = useState("");
  const [images, setImages] = useState([]);
  const [comments, setComments] = useState([]);
  const [fetchingComments, setFetchingComments] = useState(false);
  const [addingComment, setAddingComment] = useState(false);
  const [comment, setComment] = useState("");
  // const [showReplies, setShowReplies] = useState(false)
  const [replyObj, setReplyObj] = useState(false);
  useEffect(() => {
    let { attachments, id, comments } = data;
    if (attachments && isArrayWithValues(attachments.data)) {
      if (
        attachments.data[0].subattachments &&
        isArrayWithValues(attachments.data[0].subattachments.data)
      )
        setImages(attachments.data[0].subattachments.data);
      else setImages(attachments.data);
    }

    const temp = async () => {
      setFetchingComments(true);
      let comments = await getComments(id);
      setFetchingComments(false);

      if (isArrayWithValues(comments)) {
        let updatedObj = {
          ...data,
          comments: { ...data.comments, data: comments },
        };
        setComments(comments);
        updateFacebookPostInRedux(updatedObj);

        // setPostDetail(updatedObj)
      }
    };

    if (comments && isArrayWithValues(comments.data))
      setComments(comments.data);

    if (
      attachments &&
      isArrayWithValues(attachments.data) &&
      attachments.data[0].media &&
      attachments.data[0].media.source
    )
      setVideoUrl(attachments.data[0].media.source);

    if (
      id &&
      !isArrayWithValues(comments.data) &&
      comments.summary &&
      comments.summary.total_count
    )
      temp();
  }, [data]);

  const getComments = async (id) => {
    try {
      let fields = "comments.summary(true),message,from{name,picture},likes";
      // setFetchingComments(true);

      let endpoint = `/${id}/comments?fields=${fields}`;
      let res = await getChannelData("facebook", endpoint);

      // setFetchingComments(false);
      if (res.data && res.data) return res.data;
    } catch (error) {
      console.log(getAxiosError(error));
      setFetchingComments(false);
    }
  };
  const [viewfullCaption, setViewFullCaption] = useState(false);
  const addComment = async () => {
    let { id } = data;
    try {
      setAddingComment(true);
      let fields = "comments.summary(true),message,from,likes";
      // let res = await axios({
      //   url: `https://graph.facebook.com/v14.0/${id}/comments?fields=${fields}&access_token=${selectedPageAccessToken}`,
      //   method: "POST",
      //   data: { message: comment },
      // });

      let endpoint = isObjWithValues(replyObj)
        ? `/${replyObj?.id}/comments?message=${comment}`
        : `/${id}/comments?fields=${fields}`;
      let res = await postIntegrationData({
        app: "facebook",
        endpoint: endpoint,
        method: "POST",
        data: {
          payload: { message: comment },
        },
      });
      // console.log(res);

      let updatedComments;
      updatedComments = await getComments(id);

      // if (isObjWithValues(replyObj)) {
      //   let getComment = await getChannelData("facebook", `/comment/${replyObj?.id}?fields=${fields}`)
      //   // updatedComments = [{ ...getComment }, ...comments]
      //   console.log(getComment);
      // }
      // if (!isObjWithValues(replyObj)) updatedComments = [{ ...res.data }, ...comments];

      if (
        // data.comments && data.comments.summary &&
        isArrayWithValues(updatedComments)
      ) {
        setReplyObj({});
        let updatedObj = {
          ...data,
          comments: {
            ...data.comments,
            data: [...updatedComments],
            summary: {
              ...data.comments.summary,
              total_count:
                validateNumber(data.comments.summary.total_count) + 1,
            },
          },
        };
        updateFacebookPostInRedux(updatedObj);
      }
      setComments(updatedComments);
      setComment("");
    } catch (error) {
      console.log(getAxiosError(error));
    }
    setReplyObj({});
    setAddingComment(false);
  };

  let { full_picture, status_type } = data;
  let { name, picture } = info;
  let profile = picture && picture.data && picture.data.url;

  const [anchorEl, setAnchorEl] = useState(null);
  let message = data?.message || "";
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const onDelete = async () => {
    postDialogClose();
    dispatch(deleteFacebookPost(data));
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <Grid container sx={isIpad ? {} : { height: "100%" }}>
      {isIpad && (
        <Grid item xs={12}>
          <Stack
            direction="row"
            py={3}
            px={3}
            alignItems="center"
            justifyContent={"space-between"}
            sx={{ backgroundColor: "#fff" }}
          >
            <Stack direction={"row"} alignItems="center">
              <IconButton onClick={postDialogClose}>
                <ArrowBack />
              </IconButton>
              <Avatar
                src={profile}
                sx={{ mr: 2, width: 30, height: 30 }}
              ></Avatar>
              <Typography fontWeight="600" mr={1}>
                {name}
              </Typography>
              <Typography>
                {message.length > 30 ? (
                  <>
                    {viewfullCaption ? (
                      <>
                        {`${message}`}
                        <Link
                          onClick={() => setViewFullCaption(false)}
                          sx={{ cursor: "pointer" }}
                        >
                          {viewfullCaption ? "   View less" : "   View more"}
                        </Link>
                      </>
                    ) : (
                      <>
                        {" "}
                        {`${message.slice(0, 30)}`}
                        <Link
                          onClick={() => setViewFullCaption(true)}
                          sx={{ cursor: "pointer" }}
                        >
                          {viewfullCaption
                            ? "    View less"
                            : "...    View more"}
                        </Link>{" "}
                      </>
                    )}
                  </>
                ) : (
                  message
                )}
              </Typography>
              {/* <Typography>{message}</Typography> */}
            </Stack>
            <IconButton onClick={handleClick}>
              <MoreVert />{" "}
            </IconButton>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              sx={{ padding: 0 }}
            >
              <ListItem sx={{ padding: "0 16px" }}>
                <ListItemButton onClick={onDelete}>
                  <ListItemText primary={"Delete"} />
                </ListItemButton>
              </ListItem>
              {/* <Typography onClick={onDelete}>  Delete</Typography> */}
            </Popover>
          </Stack>
        </Grid>
      )}
      <Grid
        item
        xs={12}
        sm={12}
        md={7}
        sx={{ backgroundColor: "#000", maxHeight: isIpad ? "50%" : "100%" }}
      >
        {isArrayWithValues(images) &&
          status_type !== "added_video" &&
          !videoUrl && (
            <Swiper
              spaceBetween={0}
              slidesPerView={1}
              // grabCursor
              pagination={{
                // dynamicBullets: false,
                enabled: true,
                clickable: true,
              }}
              modules={[Pagination]}
              // modules={[EffectFade]}
              style={{
                maxWidth: "100%",
                // width: "100%",
                overflowX: "hidden",

                width: "100%",
                height: "100%",
              }}
            // fadeEffect={""}
            // effect="fade"
            // direction="vertical"
            // onSlideChange={() => console.log('slide change')}
            // onSwiper={(swiper) => console.log(swiper)}
            >
              {images.map((image) => {
                let url =
                  image.media && image.media.image && image.media.image.src;
                return (
                  <SwiperSlide
                    style={{
                      maxWidth: "100%",
                      maxHeight: "100%",

                      width: "100%",
                      height: "100%",
                      // maxWidth: "160px",
                      // border: `0.3px solid rgba(0, 0, 0,0.3)`,
                    }}
                  >
                    <img
                      src={url || "/static/img/woocommerce_placeholder.png"}
                      style={{
                        width: "100%",
                        height: "100%",
                        flex: 1,
                        objectFit: "contain",
                        margin: "auto",
                      }}
                    />
                  </SwiperSlide>
                );
              })}
            </Swiper>
          )}
        {/* {status_type === "added_photos" && (
          <img
            src={full_picture || "/static/img/woocommerce_placeholder.png"}
            style={{
              width: "100%",
              height: "100%",
              flex: 1,
              objectFit: "contain",
              margin: "auto",
            }}
          />
        )} */}
        {status_type === "added_video" && videoUrl && (
          <video
            width="100%"
            // height="100%"
            style={{ margin: "auto 0px", objectFit: "contain" }}
            controls
            autoPlay
          >
            <source
              src={videoUrl}
              style={{ height: "100%", width: "100%", margin: "auto 0px" }}
              type="video/mp4"
            />
            Your browser does not support HTML video.
          </video>
        )}
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={5}
        sx={{
          backgroundColor: "Background.default",
          position: "relative",
          height: isIpad ? "50%" : "100%",
          overflow: "hidden",
        }}
      >
        {/* <Box sx={{}}> */}
        {!isIpad && (
          <Stack
            direction="row"
            py={3}
            px={3}
            alignItems="center"
            justifyContent={"space-between"}
          >
            <Stack direction={"row"} alignItems="center">
              <Avatar
                src={profile}
                sx={{ mr: 2, width: 30, height: 30 }}
              ></Avatar>
              <Typography fontWeight="600" mr={1}>
                {name}
              </Typography>
              <Typography>
                {message.length > 30 ? (
                  <>
                    {viewfullCaption ? (
                      <>
                        {`${message}`}
                        <Link
                          onClick={() => setViewFullCaption(false)}
                          sx={{ cursor: "pointer" }}
                        >
                          {viewfullCaption ? "   View less" : "   View more"}
                        </Link>
                      </>
                    ) : (
                      <>
                        {" "}
                        {`${message.slice(0, 30)}`}
                        <Link
                          onClick={() => setViewFullCaption(true)}
                          sx={{ cursor: "pointer" }}
                        >
                          {viewfullCaption
                            ? "    View less"
                            : "...    View more"}
                        </Link>{" "}
                      </>
                    )}
                  </>
                ) : (
                  message
                )}
              </Typography>
              {/* <Typography>{message}</Typography> */}
            </Stack>
            <IconButton onClick={handleClick}>
              <MoreVert />{" "}
            </IconButton>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              sx={{ padding: 0 }}
            >
              <ListItem sx={{ padding: "0 16px" }}>
                <ListItemButton onClick={onDelete}>
                  <ListItemText primary={"Delete"} />
                </ListItemButton>
              </ListItem>
              {/* <Typography onClick={onDelete}>  Delete</Typography> */}
            </Popover>
          </Stack>
        )}
        <Divider />
        <Box
          sx={{ overflowY: "scroll", maxHeight: "100%", paddingBottom: "30px" }}
        >
          {!fetchingComments &&
            comments.map((comment) => {
              // let picture = comment?.from?.picture?.data?.url
              return <Comment setReplyObj={setReplyObj} comment={comment} />;
            })}
        </Box>
        {fetchingComments && (
          <Stack textAlign={"center"} mt={5}>
            <CircularProgress
              color="secondary"
              style={{ margin: "0px auto", width: "25px", height: "25px" }}
            />
          </Stack>
        )}
        <Box
          sx={{
            position: isIpad ? "fixed" : "absolute",
            bottom: 0,
            right: 0,
            left: 0,
            backgroundColor: "Background.paper",
            paddingBottom: "10px",
            // display: "flex",
            // alignItems: "flex-end",
          }}
        // alignItems="center"
        >
          <Divider sx={{ marginBottom: "10px" }} />
          {isObjWithValues(replyObj) ? (
            <Stack
              direction={"row"}
              gap="7px"
              alignItems={"center"}
              justifyContent="space-between"
            >
              <Stack direction={"row"} gap="7px" alignItems={"center"}>
                <Avatar
                  src={replyObj?.from?.picture?.data?.url}
                  sx={{ mr: 2, width: 30, height: 30 }}
                ></Avatar>
                <Typography fontWeight="600" mr={1}>
                  @{replyObj?.from?.name}
                </Typography>
                <Typography>{replyObj?.message}</Typography>
              </Stack>
              <IconButton onClick={() => setReplyObj(false)}>
                <Close />
              </IconButton>
            </Stack>
          ) : null}

          <Stack direction="row" alignItems={"center"} px={2}>
            <ChatBubbleOutlineOutlined sx={{ ml: 1, mr: 2, my: 0.5 }} />
            <TextField
              variant="standard"
              fullWidth
              placeholder="Add a comment..."
              size="medium"
              value={comment}
              onChange={(e) => {
                setComment(e.target.value);
              }}
              onKeyDown={(e) => {
                if (e.key == "Enter") {
                  addComment();
                }
              }}
            />
            <LoadingButton
              loading={addingComment}
              color="primary"
              onClick={addComment}
            >
              Send
            </LoadingButton>
          </Stack>
        </Box>
        {/* </Box> */}
      </Grid>
    </Grid>
  );
};
const Comment = ({ setReplyObj, comment }) => {
  let { message, from } = comment;
  // console.log(comment.comment);
  let picture =
    from?.picture && from?.picture?.data && from?.picture?.data?.url;
  const [showReplyIcon, setShowReplyIcon] = useState(false);
  return (
    <Stack
      onMouseEnter={() => setShowReplyIcon(true)}
      onMouseLeave={() => setShowReplyIcon(false)}
    >
      <Stack direction="row" mt={5} px={3} alignItems="center">
        <Avatar src={picture} sx={{ mr: 2, width: 30, height: 30 }}></Avatar>
        <Typography fontWeight="600" mr={1}>
          {from.name}
        </Typography>
        <Typography>{message}</Typography>
        {showReplyIcon ? (
          <IconButton sx={{ padding: 0 }} onClick={() => setReplyObj(comment)}>
            <Reply />
          </IconButton>
        ) : null}
      </Stack>
      {isArrayWithValues(comment?.comments?.data) ? (
        <CommentsReply comments={comment?.comments?.data} />
      ) : null}
    </Stack>
  );
};
const CommentsReply = ({ comments }) => {
  const [showReplies, setShowReplies] = useState(true);
  let picture = comments?.from?.picture?.data?.url;

  return (
    <Stack>
      {showReplies ? (
        <>
          {comments.map((reply) => (
            <Stack
              direction={"row"}
              alignItems="center"
              gap={2}
              sx={{ ml: "35px", mb: 2 }}
            >
              <Stack>
                <Avatar src={picture} sx={{ width: 25, height: 25 }}></Avatar>
              </Stack>
              <Stack
                sx={{
                  borderRadius: "15px",
                  padding: "5px 12px",
                  backgroundColor: "#EFEFEF",
                }}
              >
                <Typography fontWeight="500">{reply?.from?.name}</Typography>
                <Typography>{reply?.message}</Typography>
              </Stack>
            </Stack>
          ))}
        </>
      ) : null}
      <Typography
        sx={{ ml: "35px", cursor: "pointer" }}
        onClick={() => setShowReplies((pre) => !pre)}
      >
        {showReplies ? "Hide" : "Show"} {comments.length} replies
      </Typography>
    </Stack>
  );
};
const FacebookThumbnail = ({ data, info, postPress }) => {
  let [hover, setHover] = useState(false);
  const [likesCount, setLikesCount] = useState(0);
  const [commentsCount, setCommentsCount] = useState(0);
  const [isVideo, setIsVideo] = useState(0);
  useEffect(() => {
    let { likes, comments, status_type } = data;
    setIsVideo(status_type === "added_video");
    if (likes && likes.summary && likes.summary.total_count)
      setLikesCount(likes.summary.total_count);
    if (comments && comments.summary && comments.summary.total_count)
      setCommentsCount(comments.summary.total_count);
  }, [data]);
  const theme = useTheme();
  let { full_picture, message, created_time, id } = data;
  let { name, picture } = info;
  let profile = picture && picture.data && picture.data.url;

  const mouseEnter = () => {
    setHover(true);
  };
  const mouseLeave = () => {
    setHover(false);
  };

  return (
    <Card>
      <Box
        sx={{
          borderRadius: "7px",
          overflow: "hidden",
          cursor: "pointer",
          position: "relative",
          backgroundColor: "#000",
          maxHeight: "200px",
        }}
        onClick={() => postPress(data)}
        onMouseEnter={mouseEnter}
        onMouseLeave={mouseLeave}
      >
        {isVideo && (
          <PlayArrowRounded
            style={{
              position: "absolute",
              height: "24px",
              width: "24px",
              color: "#fff",
              top: 10,
              right: 10,
            }}
          />
        )}
        <img
          src={full_picture || "/static/img/woocommerce_placeholder.png"}
          style={{
            width: "100%",
            height: "100%",
            flex: 1,
            objectFit: "contain",

            opacity: hover ? 0.5 : 1,
            backgroundColor: "#000",
            transition: "opacity 0.15s",
          }}
        />
        {hover && (
          <Box
            sx={{
              position: "absolute",
              top: "45%",
              left: 0,
              right: 0,
              margin: "auto",
            }}
          >
            <Stack
              direction="row"
              alignItems={"center"}
              textAlign="center"
              color="#fff"
              justifyContent="center"
            >
              <FavoriteBorderOutlined
                color="#fff"
                style={{ marginRight: "4px" }}
              />
              {likesCount}
              <ChatBubbleOutlineOutlined
                style={{ marginLeft: "7px", marginRight: "4px" }}
              />
              {commentsCount}
            </Stack>
          </Box>
        )}
      </Box>
      <Stack
        direction="row"
        alignItems={"center"}
        textAlign="center"
        color="#808080"
        justifyContent="center"
      >
        <FavoriteBorderOutlined color="#fff" style={{ marginRight: "4px" }} />
        {likesCount}
        <ChatBubbleOutlineOutlined
          style={{ marginLeft: "7px", marginRight: "4px" }}
        />
        {commentsCount}
      </Stack>
    </Card>
  );
};

export const fetchFacebookInfo = async (accessToken) => {
  try {
    let res = await axios({
      url: `https://graph.facebook.com/v14.0/me?fields=id,name,email,picture&access_token=${accessToken}`,
    });
    return res.data;
  } catch (error) {
    console.log(getAxiosError(error));
  }
};

export const fetchFacebookPages = async (id) => {
  try {
    // let res = await axios({
    //   url: `https://graph.facebook.com/v14.0/${id}/accounts?access_token=${access_token}`,
    // });
    let res = await getChannelData("facebook", `/${id}/accounts`);
    console.log(res);
    return res;
  } catch (error) {
    console.log(getAxiosError(error));
  }
};
// let temp = <Box
//   sx={{
//     // maxWidth: "300px",
//     border: "0.3px solid rgba(0,0,0,0.3)",
//     py: 3,
//     borderRadius: "6px",
//   }}
// >
//   <Stack direction={"row"} alignItems="center" px={2}>
//         {profile ? (
//           <img
//             src={profile}
//             style={{
//               height: "50px",
//               width: "50px",
//               borderRadius: "50%",
//               // backgroundColor: "#DCE6FA",
//             }}
//           />
//         ) : (
//           <Box
//             src={profile}
//             sx={{
//               height: "50px",
//               width: "50px",
//               borderRadius: "50%",
//               backgroundColor: "#DCE6FA",
//             }}
//           />
//         )}
//         <Box ml={2}>
//           <Typography variant="h6" fontSize="15px">
//             {name}
//           </Typography>
//           <Typography variant="body2">Today 10:12 pm</Typography>
//         </Box>
//       </Stack>
//   <Typography
//     mt={2}
//     px={2}
//     variant="body2"
//     sx={{ lineClamp: 2 }}
//     height={"38px"}
//     maxHeight={"38px"}
//     overflow="hidden"
//   >
//     {message}
//   </Typography>
//   <Box sx={{ margin: "14px 0px" }}>
//     <img
//       src={full_picture || "/static/img/woocommerce_placeholder.png"}
//       style={{ width: "100%", height: "240px", objectFit: "contain" }}
//     />
//   </Box>
//   {/* <Divider /> */}
//   {/* <Box mt={2} mx={2}>
//         <Button
//           fullWidth
//           variant="outlined"
//           color="secondary"
//           endIcon={<ArrowForward />}
//         ></Button>
//       </Box> */}
// </Box>

const PostCard = ({ data, info, postPress, facebookBasicInfo }) => {
  let [hover, setHover] = useState(false);
  const [likesCount, setLikesCount] = useState(0);
  const [commentsCount, setCommentsCount] = useState(0);
  const [isVideo, setIsVideo] = useState(0);

  useEffect(() => {
    let { likes, comments, status_type } = data;
    setIsVideo(status_type === "added_video");
    if (likes && likes.summary && likes.summary.total_count)
      setLikesCount(likes.summary.total_count);
    if (comments && comments.summary && comments.summary.total_count)
      setCommentsCount(comments.summary.total_count);
  }, [data]);
  const theme = useTheme();
  let { full_picture, created_time, id } = data;
  let { name, picture } = info;
  let profile = picture && picture.data && picture.data.url;
  let message = data?.message || "";
  return (
    <Card className="shadow_card" onClick={() => postPress(data)}>
      <CardContent sx={{ padding: "5px" }}>
        <Stack
          direction={"row"}
          alignItems="center"
          sx={{ padding: "10px", gap: "10px" }}
        >
          <Avatar src={profile} />
          <Stack>
            <Typography variant="subtitle1" fontWeight="600">
              {name}
            </Typography>
            <Typography>
              {formatDate(new Date(created_time), "relative_date")}
            </Typography>
          </Stack>
        </Stack>
        <Stack>
          <Typography fontWeight={"450"} sx={{ pl: "10px" }}>
            {message.length > 30 ? `${message.slice(0, 30)}...` : message}
          </Typography>
          {/* <Typography fontWeight={"450"} sx={{ pl: "10px" }}>{message}</Typography> */}
        </Stack>
        <Divider sx={{ mb: 1 }} />
        <Stack>
          {isVideo && (
            <PlayArrowRounded
              style={{
                position: "absolute",
                height: "24px",
                width: "24px",
                color: "#fff",
                top: 10,
                right: 10,
              }}
            />
          )}
          <img
            src={full_picture || "/static/img/woocommerce_placeholder.png"}
          />
        </Stack>
        <Stack>
          <Divider sx={{ mt: 1 }} />
          <Stack
            direction={"row"}
            alignItems="center"
            justifyContent={"space-evenly"}
          >
            <Button startIcon={<ThumbUpOutlined />}>
              Like {likesCount ? likesCount : null}
            </Button>
            <Button startIcon={<ChatBubbleOutlineOutlined />}>
              Comment {commentsCount ? commentsCount : null}
            </Button>
            {/* <Button startIcon={<ReplyOutlined />}>Share</Button> */}
          </Stack>
          <Divider />
        </Stack>
      </CardContent>
    </Card>
  );
};

const Item = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(0.5),
  color: theme.palette.text.secondary,
}));
