import { getAxiosError } from "jwero-javascript-utils";
import { backendApiCall } from "../../helper";
import { backendEndpoints } from "../../pages/chat_new/apiFunctions";

const assignTeamMember = async ({
  sub_users = [],
  customers = [],
  replace,
  params = {},
}) => {
  try {
    let requestObj = {
      endpoint: `${backendEndpoints?.sub_user}/assign_customer`,
      method: "POST",
      data: { sub_users, customers, channel: "chats", replace },
      params,
    };
    // console.log({ ...data, channel: "chats" });
    return await backendApiCall(requestObj);
  } catch (error) {
    console.log(getAxiosError(error), "update chat user Error");
  }
};

const updateChatUser = async ({ payload }) => {
  let { id, ...restPayload } = payload || {};
  try {
    let requestObj = {
      endpoint: `${backendEndpoints?.["chats"]}/${id}`,
      method: "PUT",
      data: restPayload,
    };
    return await backendApiCall(requestObj);
  } catch (error) {
    console.log(getAxiosError(error), "update chat user Error");
  }
};
const getChatUser = async (id) => {
  try {
    let requestObj = {
      endpoint: `${backendEndpoints?.["chats"]}/${id}`,
    };
    return await backendApiCall(requestObj);
  } catch (error) {
    console.log(getAxiosError(error), "update chat user Error");
  }
};

const getChatsUserConversation = async ({ id }) => {
  try {
    const controller = new AbortController();
    const signal = controller.signal;

    let requestObj = {
      endpoint: `${backendEndpoints?.chat_conversations}/${id}`,
      signal,
      // params: { inverse_chats: true },
    };

    let res = await backendApiCall(requestObj);
    return res;
  } catch (error) {
    console.log(getAxiosError(error), "get chats Error");
  }
};

export {
  assignTeamMember,
  updateChatUser,
  getChatUser,
  getChatsUserConversation,
};
