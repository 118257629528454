import axios from "axios";
import {
  getAxiosError,
  getToken,
  getWebsite,
  isArray,
  isArrayWithValues,
  isObjWithValues,
} from "./helper";
import { getAllLabourMasterPricing } from "./pages/products/AddProduct";

export { getNewProductObj, updateMigration };

const getGoldObj = (obj) => {};

const goldPurities = {
  23.99976: "999.99",
  23.976: "999",
  23.88: "995",
  // 24: "999",
  22: "916",
  18: "750",
  14: "583",
  9: "375",
};

const getNewProductObj = (
  productObj,
  productSettings = {},
  masterPricing = {},
  diamondGroup = []
) => {
  let { default_currency } = productSettings;
  let { labour_pricing, diamond_pricing } = masterPricing;
  let { meta_data, collections, categories, subcategory, id, sku } = productObj;
  let product = {
    id,
    // sku,
  };
  if (productObj.description && !productObj.short_description)
    product.short_description = productObj.description;
  if (isArrayWithValues(meta_data)) {
    for (let obj of meta_data) productObj[obj.key] = obj.value;
  }
  // if (!updateDiamond[productObj.sku]) return;
  let {
    discount_sale_on,
    discount_type,
    discount_value,
    metal_type,
    huid,
    gold_gross,
    silver_gross,
    platinium_gross,
    shipping_days,
    subcatsetting,
    gold_kt,
    labour,
    per_gram,
  } = productObj;
  let metaKeys = [
    "huid",
    "metal_types",
    "product_shipping",
    "subcatsetting",
    "gold_kt",
    "custom_discounts",
    "per_gram",
    "labour_pricing_title",
    "labour_from",
    "wastage_from",
    "making_from",
    "minimum_making",
    "wastage",
    "labour",
    "wastage_percent",
    "minimum_labour",
    "diamond",
  ];
  if (productObj.huid && !isArray(productObj.huid))
    product.huid = [productObj.huid];
  if (metal_type) {
    if (metal_type !== "multi") product.metal_types = [metal_type];
    else {
      let array = [];
      if (gold_gross) array.push("gold");
      if (silver_gross) array.push("silver");
      if (platinium_gross) array.push("platinium");
      product.metal_types = array;
    }
  }
  if (shipping_days) product.product_shipping = { min: shipping_days, max: "" };
  if (isArrayWithValues(subcatsetting))
    product.subcatsetting = subcatsetting.slice(0, 1);

  if (discount_sale_on && discount_type && discount_value) {
    product.custom_discounts = {
      [discount_sale_on]: {
        type: discount_type,
        value: discount_value,
      },
    };
  }
  if (!productObj.labourType) productObj.labourType = "master";
  if (
    productObj.labourType === "master" &&
    isArrayWithValues(collections) &&
    isArrayWithValues(categories) &&
    labour_pricing &&
    labour_pricing[default_currency]
  ) {
    let temp_collections = collections.map((i) => i.label || i.name);
    let temp_categories = categories.map((i) => i.label || i.name);
    let temp_subcategories = isArrayWithValues(subcategory)
      ? subcategory.map((i) => i.label || i.name)
      : [];
    let array = getAllLabourMasterPricing(
      labour_pricing[default_currency],
      temp_collections,
      temp_categories,
      temp_subcategories
    );
    if (isArrayWithValues(array)) {
      product.labour_from = array[0]?.labour_from;
      product.wastage_from = array[0]?.wastage_from;
      product.making_from = array[0]?.making_from;
      product.minimum_labour = array[0]?.minimum_making;
      // product.labour = array[0]?.minimum_making;
      product.wastage_percent = array[0]?.wastage;
      product.per_gram = array[0]?.per_gram;
      product.labour_pricing_title = array[0]?.id;
    }
    // if(isArrayWithValues(array))
  }
  if (
    !product.labour_from &&
    !productObj.labour_from &&
    (productObj.labourType === "individual" ||
      productObj.labourType === "customize")
  )
    product.labour_from = "gross";

  if (isObjWithValues(productObj.diamond)) {
    let diamondArray = [];
    let index = 0;
    // let array = [];
    let array = Object.values(productObj.diamond).filter(
      (i) => i.diamond_weight || i.diamond_quality
    );
    // let weights = updateDiamond[productObj.sku].diamond_weight;
    // let temp = 0;
    // for (let weight of weights) {
    //   array.push({
    //     diamond_weight: weight,
    //     diamond_shape: updateDiamond[productObj.sku]?.diamond_shape[temp],
    //     diamond_pieces: updateDiamond[productObj.sku]?.diamond_pieces[temp],
    //     diamond_type: "lab_grown",
    //     diamond_quality: "VS-FG",
    //   });
    //   temp++;
    // }

    for (let obj of array) {
      let { diamond_shape, diamond_weight, diamond_pieces, diamond_type } = obj;
      diamond_shape = diamond_shape?.trim()?.toLowerCase();
      // diamond_shape = updateDiamond[productObj.sku].diamond_shape?.[index];
      // diamond_pieces = updateDiamond[productObj.sku].diamond_pieces?.[index];
      // diamond_weight = updateDiamond[productObj.sku].diamond_weight?.[index];

      let netWeight = Number(diamond_weight) / Number(diamond_pieces);
      let diamondSieve = diamondGroup.find((i) => {
        return (
          i.shape === diamond_shape &&
          netWeight >= Number(i.from) &&
          netWeight <= Number(i.to)
        );
      });
      if (!diamondSieve)
        diamondSieve = diamondGroup.find((i) => {
          let netWeightFixed2 = Number(netWeight).toFixed(2);
          return (
            i.shape === diamond_shape &&
            netWeightFixed2 >= Number(i.from) &&
            netWeightFixed2 <= Number(i.to)
          );
        });
      if (!diamond_type) diamond_type = "natural";
      if (diamond_weight)
        diamondArray.push({
          ...obj,
          diamond_sieve: diamondSieve?.id || "",
          diamond_type,
          diamond_shape,
          diamond_pieces,
          diamond_weight,
        });
      index++;
    }
    if (isArrayWithValues(diamondArray)) {
      let diamondObj = {};
      diamondArray.map((i, index) => {
        diamondObj[index + 1] = i;
      });
      product.diamond = diamondObj;
    }
  }
  if (
    gold_kt ||
    goldPurities[gold_kt] ||
    goldPurities[`${gold_kt?.replace("kt", "")}`]
  )
    product.gold_kt =
      goldPurities[gold_kt] || goldPurities[`${gold_kt?.replace("kt", "")}`];
  if (labour && !per_gram) product.per_gram = labour;
  let metaData = [
    // {
    //   key: "discount_sale_on",
    //   value: "",
    // },
    // {
    //   key: "discount_type",
    //   value: "",
    // },
    // {
    //   key: "discount_value",
    //   value: "",
    // },
    // {
    //   key: "custom_discounts",
    //   value: {},
    // },
  ];
  for (let key of metaKeys) {
    if (product[key])
      metaData.push({
        key,
        value: product[key],
      });
    delete product[key];
  }
  return { ...product, meta_data: metaData };
  // return { ...product, meta_data: [{ key: "manual_price", value: "" }] };
};

const updateMigration = async (obj) => {
  let website = await getWebsite();
  let token = await getToken();
  if (website && token) {
    try {
      let { data } = await axios({
        url: `${website}/wp-json/store/v1/settings/migration_info`,
        headers: { Authorization: `Basic ${token}` },
        data: { type: "migration_info", data: obj },
        method: "PUT",
      });
      if (data.success) return true;
    } catch (error) {
      console.log(getAxiosError(error));
      return false;
    }
  }
};

const updateDiamond = {
  MEB0001: {
    sku: "MEB0001",
    diamond_shape: ["pear", "round", "round"],
    diamond_pieces: ["1", "16", "24"],
    diamond_weight: ["1.25", "0.09", "0.53"],
  },
  MEB0002: {
    sku: "MEB0002",
    diamond_shape: ["round", "round", "round", "round", "oval"],
    diamond_pieces: ["10", "6", "4", "28", "1"],
    diamond_weight: ["0.08", "0.04", "0.04", "0.34", "1.25"],
  },
  MEB0003: {
    sku: "MEB0003",
    diamond_shape: ["round", "round", "round", "round", "round"],
    diamond_pieces: ["6", "10", "14", "1", "8"],
    diamond_weight: ["0.03", "0.07", "0.14", "1", "0.09"],
  },
  MEB0004: {
    sku: "MEB0004",
    diamond_shape: ["round", "round", "round"],
    diamond_pieces: ["12", "1", "24"],
    diamond_weight: ["0.07", "1", "0.52"],
  },
  MEB0005: {
    sku: "MEB0005",
    diamond_shape: ["round", "round", "round"],
    diamond_pieces: ["1", "14", "16"],
    diamond_weight: ["1.5", "0.08", "0.19"],
  },
  MEB0006: {
    sku: "MEB0006",
    diamond_shape: ["round", "round"],
    diamond_pieces: ["1", "12"],
    diamond_weight: ["1", "0.07"],
  },
  MEB0007: {
    sku: "MEB0007",
    diamond_shape: ["emerald", "round", "round"],
    diamond_pieces: ["1", "16", "24"],
    diamond_weight: ["1.5", "0.09", "0.52"],
  },
  MEB0008: {
    sku: "MEB0008",
    diamond_shape: ["emerald", "round"],
    diamond_pieces: ["1", "16"],
    diamond_weight: ["1.5", "0.096"],
  },
  MEB0009: {
    sku: "MEB0009",
    diamond_shape: ["round", "Cushion"],
    diamond_pieces: ["12", "1"],
    diamond_weight: ["0.06", "1.5"],
  },
  MEB0010: {
    sku: "MEB0010",
    diamond_shape: ["Cushion", "round", "round", "round"],
    diamond_pieces: ["1", "8", "12", "26"],
    diamond_weight: ["1.5", "0.04", "0.14", "0.31"],
  },
  MEB0011: {
    sku: "MEB0011",
    diamond_shape: ["round", "round", "round", "round", "round", "round"],
    diamond_pieces: ["1", "10", "2", "2", "2", "2"],
    diamond_weight: ["1", "0.07", "0.02", "0.02", "0.05", "0.08"],
  },
  MEB0012: {
    sku: "MEB0012",
    diamond_shape: ["round", "round", "round", "round", "round", "round"],
    diamond_pieces: ["4", "1", "5", "3", "4", "6"],
    diamond_weight: ["0.02", "1", "0.03", "0.03", "0.06", "0.11"],
  },
  MEB0013: {
    sku: "MEB0013",
    diamond_shape: ["round", "round", "emerald", "round"],
    diamond_pieces: ["4", "38", "1", "16"],
    diamond_weight: ["0.04", "0.46", "1.5", "0.11"],
  },
  MEB0014: {
    sku: "MEB0014",
    diamond_shape: [
      "round",
      "round",
      "round",
      "round",
      "round",
      "round",
      "round",
      "round",
    ],
    diamond_pieces: ["2", "1", "29", "1", "1", "1", "1", "1"],
    diamond_weight: [
      "0.01",
      "1",
      "0.2",
      "0.01",
      "0.01",
      "0.01",
      "0.02",
      "0.02",
    ],
  },
  MEB0015: {
    sku: "MEB0015",
    diamond_shape: ["round"],
    diamond_pieces: ["1"],
    diamond_weight: ["1"],
  },
  MEB0016: {
    sku: "MEB0016",
    diamond_shape: ["round", "round"],
    diamond_pieces: ["64", "1"],
    diamond_weight: ["0.48", "1"],
  },
  MEB0017: {
    sku: "MEB0017",
    diamond_shape: ["round", "round", "round"],
    diamond_pieces: ["30", "1", "34"],
    diamond_weight: ["0.24", "1", "0.23"],
  },
  MEB0018: {
    sku: "MEB0018",
    diamond_shape: ["emerald", "round", "round", "round"],
    diamond_pieces: ["1", "10", "8", "52"],
    diamond_weight: ["1.5", "0.06", "0.04", "0.39"],
  },
  MEB0019: {
    sku: "MEB0019",
    diamond_shape: ["emerald", "round", "round", "round"],
    diamond_pieces: ["1", "10", "8", "28"],
    diamond_weight: ["1.5", "0.06", "0.04", "0.21"],
  },
  MEB0020: {
    sku: "MEB0020",
    diamond_shape: ["round"],
    diamond_pieces: ["14"],
    diamond_weight: ["0.085"],
  },
  MEB0021: {
    sku: "MEB0021",
    diamond_shape: ["round"],
    diamond_pieces: ["1"],
    diamond_weight: ["1"],
  },
  MEB0022: {
    sku: "MEB0022",
    diamond_shape: ["Cushion", "round", "round", "round", "round", "round"],
    diamond_pieces: ["1", "16", "24", "20", "4", "2"],
    diamond_weight: ["1.5", "0.12", "0.24", "0.12", "0.07", "0.04"],
  },
  MEB0023: {
    sku: "MEB0023",
    diamond_shape: ["round", "round", "round", "round", "round"],
    diamond_pieces: ["1", "2", "2", "2", "12"],
    diamond_weight: ["1", "0.02", "0.02", "0.03", "0.3"],
  },
  MEB0024: {
    sku: "MEB0024",
    diamond_shape: ["oval", "round", "round"],
    diamond_pieces: ["1", "14", "16"],
    diamond_weight: ["1.25", "0.1", "0.19"],
  },
  MEB0025: {
    sku: "MEB0025",
    diamond_shape: ["round"],
    diamond_pieces: ["1"],
    diamond_weight: ["1"],
  },
  MEB0026: {
    sku: "MEB0026",
    diamond_shape: ["round", "round"],
    diamond_pieces: ["1", "20"],
    diamond_weight: ["1", "0.15"],
  },
  MEB0027: {
    sku: "MEB0027",
    diamond_shape: ["round", "round", "round", "round", "round", "round"],
    diamond_pieces: ["2", "7", "6", "23", "12", "1"],
    diamond_weight: ["0.015", "0.07", "0.065", "0.28", "0.04", "1"],
  },
  MEB0028: {
    sku: "MEB0028",
    diamond_shape: ["round", "round", "round", "round"],
    diamond_pieces: ["42", "6", "16", "1"],
    diamond_weight: ["0.26", "0.06", "0.12", "1"],
  },
  MEB0029: {
    sku: "MEB0029",
    diamond_shape: ["round", "round", "round", "round", "round"],
    diamond_pieces: ["1", "4", "12", "14", "16"],
    diamond_weight: ["1", "0.04", "0.26", "0.1", "0.19"],
  },
  MEB0030: {
    sku: "MEB0030",
    diamond_shape: ["round", "round", "round", "round", "round"],
    diamond_pieces: ["8", "14", "6", "8", "1"],
    diamond_weight: ["0.06", "0.19", "0.1", "0.17", "1"],
  },
  MEB0031: {
    sku: "MEB0031",
    diamond_shape: ["round", "round", "round", "round"],
    diamond_pieces: ["4", "4", "8", "1"],
    diamond_weight: ["0.02", "0.03", "0.08", "1"],
  },
  MEB0032: {
    sku: "MEB0032",
    diamond_shape: ["round", "round"],
    diamond_pieces: ["48", "1"],
    diamond_weight: ["0.28", "1"],
  },
  MEB0033: {
    sku: "MEB0033",
    diamond_shape: [
      "round",
      "round",
      "round",
      "round",
      "round",
      "round",
      "round",
      "round",
      "round",
    ],
    diamond_pieces: ["1", "8", "2", "2", "2", "2", "2", "2", "4"],
    diamond_weight: [
      "1.5",
      "0.06",
      "0.05",
      "0.04",
      "0.03",
      "0.02",
      "0.02",
      "0.02",
      "0.03",
    ],
  },
  MEB0034: {
    sku: "MEB0034",
    diamond_shape: ["Cushion", "round", "round", "round", "round", "round"],
    diamond_pieces: ["1", "8", "8", "2", "6", "20"],
    diamond_weight: ["2", "0.04", "0.06", "0.02", "0.03", "0.35"],
  },
  MEB0035: {
    sku: "MEB0035",
    diamond_shape: ["Cushion", "round", "round", "round", "round"],
    diamond_pieces: ["1", "8", "8", "2", "6"],
    diamond_weight: ["2", "0.04", "0.06", "0.02", "0.03"],
  },
  MEB0036: {
    sku: "MEB0036",
    diamond_shape: ["princess", "round", "round", "round", "round", "round"],
    diamond_pieces: ["1", "8", "8", "2", "6", "20"],
    diamond_weight: ["1.7", "0.04", "0.06", "0.02", "0.03", "0.35"],
  },
  MEB0037: {
    sku: "MEB0037",
    diamond_shape: [
      "round",
      "round",
      "round",
      "round",
      "round",
      "round",
      "round",
    ],
    diamond_pieces: ["1", "16", "20", "12", "2", "2", "20"],
    diamond_weight: ["1", "0.09", "0.15", "0.07", "0.01", "0.02", "0.28"],
  },
  MEB0038: {
    sku: "MEB0038",
    diamond_shape: ["round", "round", "round", "round"],
    diamond_pieces: ["1", "12", "2", "20"],
    diamond_weight: ["2", "0.07", "0.02", "0.35"],
  },
  MEB0039: {
    sku: "MEB0039",
    diamond_shape: ["round", "round"],
    diamond_pieces: ["1", "12"],
    diamond_weight: ["2", "0.07"],
  },
  MEB0040: {
    sku: "MEB0040",
    diamond_shape: ["round", "round", "round", "round", "round"],
    diamond_pieces: ["2", "14", "2", "16", "1"],
    diamond_weight: ["0.79", "0.08", "0.02", "0.28", "1.5"],
  },
  MEB0041: {
    sku: "MEB0041",
    diamond_shape: ["emerald", "round", "round", "round"],
    diamond_pieces: ["1", "6", "4", "14"],
    diamond_weight: ["1", "0.03", "0.03", "0.19"],
  },
  MEB0042: {
    sku: "MEB0042",
    diamond_shape: ["round", "round", "marquise"],
    diamond_pieces: ["28", "20", "1"],
    diamond_weight: ["0.14", "0.42", "1.25"],
  },
  MEB0043: {
    sku: "MEB0043",
    diamond_shape: ["princess", "round", "round"],
    diamond_pieces: ["1", "16", "14"],
    diamond_weight: ["3", "0.09", "1.4"],
  },
  MEB0044: {
    sku: "MEB0044",
    diamond_shape: ["pear"],
    diamond_pieces: ["1"],
    diamond_weight: ["1"],
  },
  MEB0045: {
    sku: "MEB0045",
    diamond_shape: [
      "round",
      "round",
      "round",
      "round",
      "round",
      "round",
      "round",
    ],
    diamond_pieces: ["1", "2", "18", "16", "2", "10", "8"],
    diamond_weight: ["1.5", "1.5", "0.1", "0.12", "0.04", "0.17", "0.11"],
  },
  MEB0046: {
    sku: "MEB0046",
    diamond_shape: ["round", "round"],
    diamond_pieces: ["1", "78"],
    diamond_weight: ["1", "0.46"],
  },
  MEB0047: {
    sku: "MEB0047",
    diamond_shape: ["round", "round"],
    diamond_pieces: ["78", "1"],
    diamond_weight: ["0.46", "1"],
  },
  MEB0048: {
    sku: "MEB0048",
    diamond_shape: ["round", "round", "round"],
    diamond_pieces: ["32", "28", "1"],
    diamond_weight: ["0.19", "0.19", "1"],
  },
  MEB0049: {
    sku: "MEB0049",
    diamond_shape: ["round", "round", "round"],
    diamond_pieces: ["1", "24", "10"],
    diamond_weight: ["1", "0.14", "0.07"],
  },
  MEB0050: {
    sku: "MEB0050",
    diamond_shape: ["round", "round", "round", "round"],
    diamond_pieces: ["32", "2", "12", "1"],
    diamond_weight: ["0.15", "0.03", "0.25", "0.93"],
  },
  MEB0051: {
    sku: "MEB0051",
    diamond_shape: ["emerald", "round"],
    diamond_pieces: ["1", "22"],
    diamond_weight: ["1.5", "0.48"],
  },
  MEB0052: {
    sku: "MEB0052",
    diamond_shape: ["round", "round", "round", "round", "round"],
    diamond_pieces: ["1", "8", "12", "12", "2"],
    diamond_weight: ["1", "0.04", "0.09", "0.36", "0.02"],
  },
  MEB0053: {
    sku: "MEB0053",
    diamond_shape: ["round", "round", "round", "round", "round"],
    diamond_pieces: ["1", "12", "16", "20", "2"],
    diamond_weight: ["0.38", "0.12", "0.12", "0.2", "0.02"],
  },
  MEB0054: {
    sku: "MEB0054",
    diamond_shape: ["oval", "round", "round"],
    diamond_pieces: ["1", "20", "30"],
    diamond_weight: ["0.52", "0.14", "0.3"],
  },
  MEB0055: {
    sku: "MEB0055",
    diamond_shape: [
      "round",
      "round",
      "round",
      "round",
      "round",
      "round",
      "round",
      "round",
    ],
    diamond_pieces: ["1", "42", "4", "6", "2", "2", "2", "2"],
    diamond_weight: [
      "0.25",
      "0.31",
      "0.04",
      "0.03",
      "0.04",
      "0.04",
      "0.05",
      "0.08",
    ],
  },
  MEB0056: {
    sku: "MEB0056",
    diamond_shape: [
      "oval",
      "round",
      "round",
      "round",
      "round",
      "round",
      "round",
      "round",
      "round",
      "round",
    ],
    diamond_pieces: ["1", "14", "6", "14", "6", "16", "2", "2", "2", "2"],
    diamond_weight: [
      "0.38",
      "0.1",
      "0.06",
      "0.1",
      "0.03",
      "0.12",
      "0.03",
      "0.04",
      "0.05",
      "0.07",
    ],
  },
  MEB0057: {
    sku: "MEB0057",
    diamond_shape: ["round", "round", "round", "round"],
    diamond_pieces: ["1", "26", "4", "14"],
    diamond_weight: ["0.32", "0.21", "0.04", "0.24"],
  },
  MEB0058: {
    sku: "MEB0058",
    diamond_shape: ["oval", "round", "round", "round"],
    diamond_pieces: ["1", "28", "6", "14"],
    diamond_weight: ["0.39", "0.21", "0.06", "0.25"],
  },
  MEB0059: {
    sku: "MEB0059",
    diamond_shape: [
      "round",
      "round",
      "round",
      "round",
      "round",
      "round",
      "round",
      "round",
      "round",
      "round",
    ],
    diamond_pieces: ["1", "4", "8", "16", "4", "16", "2", "2", "2", "2"],
    diamond_weight: [
      "0.33",
      "0.03",
      "0.09",
      "0.12",
      "0.04",
      "0.12",
      "0.03",
      "0.04",
      "0.05",
      "0.07",
    ],
  },
  MEB0060: {
    sku: "MEB0060",
    diamond_shape: ["round", "round", "round", "round", "round"],
    diamond_pieces: ["1", "8", "4", "18", "32"],
    diamond_weight: ["0.33", "0.06", "0.04", "0.13", "0.24"],
  },
  MEB0061: {
    sku: "MEB0061",
    diamond_shape: ["round", "round", "round", "round", "round", "round"],
    diamond_pieces: ["1", "4", "8", "16", "4", "4"],
    diamond_weight: ["0.33", "0.06", "0.04", "0.12", "0.04", "0.02"],
  },
  MEB0062: {
    sku: "MEB0062",
    diamond_shape: ["princess", "round", "round", "round"],
    diamond_pieces: ["1", "20", "8", "4"],
    diamond_weight: ["0.32", "0.14", "0.11", "0.05"],
  },
  MEB0063: {
    sku: "MEB0063",
    diamond_shape: ["round", "round", "round"],
    diamond_pieces: ["1", "12", "6"],
    diamond_weight: ["0.38", "0.14", "0.03"],
  },
  MEB0064: {
    sku: "MEB0064",
    diamond_shape: ["emerald", "round", "round", "round"],
    diamond_pieces: ["1", "10", "8", "6"],
    diamond_weight: ["0.75", "0.07", "0.04", "0.04"],
  },
  MEB0065: {
    sku: "MEB0065",
    diamond_shape: [
      "round",
      "round",
      "round",
      "round",
      "round",
      "round",
      "round",
      "round",
      "round",
      "round",
    ],
    diamond_pieces: ["1", "14", "4", "2", "2", "2", "2", "2", "2", "2"],
    diamond_weight: [
      "0.33",
      "0.16",
      "0.03",
      "0.01",
      "0.02",
      "0.02",
      "0.04",
      "0.06",
      "0.07",
      "0.09",
    ],
  },
  MEB0066: {
    sku: "MEB0066",
    diamond_shape: [
      "round",
      "round",
      "round",
      "round",
      "round",
      "round",
      "round",
      "round",
      "round",
    ],
    diamond_pieces: ["1", "16", "4", "2", "2", "2", "2", "2", "2"],
    diamond_weight: [
      "0.7",
      "0.11",
      "0.02",
      "0.02",
      "0.02",
      "0.04",
      "0.04",
      "0.06",
      "0.07",
    ],
  },
  MEB0067: {
    sku: "MEB0067",
    diamond_shape: [
      "round",
      "round",
      "round",
      "round",
      "round",
      "round",
      "pear",
    ],
    diamond_pieces: ["8", "18", "2", "2", "2", "2", "1"],
    diamond_weight: ["0.06", "0.17", "0.03", "0.04", "0.04", "0.06", "1.7"],
  },
  MEB0068: {
    sku: "MEB0068",
    diamond_shape: ["round", "round", "round", "round"],
    diamond_pieces: ["1", "4", "12", "14"],
    diamond_weight: ["0.41", "0.03", "0.12", "0.24"],
  },
  MEB0069: {
    sku: "MEB0069",
    diamond_shape: ["oval", "round", "round", "round", "round"],
    diamond_pieces: ["1", "14", "12", "12", "14"],
    diamond_weight: ["0.5", "0.14", "0.09", "0.12", "0.24"],
  },
  MEB0070: {
    sku: "MEB0070",
    diamond_shape: [
      "round",
      "round",
      "round",
      "round",
      "round",
      "round",
      "round",
    ],
    diamond_pieces: ["1", "4", "2", "2", "2", "2", "2"],
    diamond_weight: ["0.33", "0.05", "0.03", "0.04", "0.05", "0.06", "0.07"],
  },
  MEB0071: {
    sku: "MEB0071",
    diamond_shape: [
      "round",
      "round",
      "round",
      "round",
      "round",
      "round",
      "round",
      "round",
    ],
    diamond_pieces: ["8", "4", "2", "2", "2", "2", "2", "1"],
    diamond_weight: [
      "0.08",
      "0.054",
      "0.03",
      "0.04",
      "0.04",
      "0.05",
      "0.07",
      "0.43",
    ],
  },
  MEB0072: {
    sku: "MEB0072",
    diamond_shape: ["round", "round"],
    diamond_pieces: ["1", "18"],
    diamond_weight: ["0.33", "0.18"],
  },
  MEB0073: {
    sku: "MEB0073",
    diamond_shape: ["round", "round", "round"],
    diamond_pieces: ["1", "10", "18"],
    diamond_weight: ["0.38", "0.03", "0.18"],
  },
  MEB0074: {
    sku: "MEB0074",
    diamond_shape: ["princess", "round"],
    diamond_pieces: ["1", "18"],
    diamond_weight: ["0.62", "0.18"],
  },
  MEB0075: {
    sku: "MEB0075",
    diamond_shape: ["princess", "round", "round"],
    diamond_pieces: ["1", "12", "18"],
    diamond_weight: ["0.67", "0.06", "0.17"],
  },
  MEB0076: {
    sku: "MEB0076",
    diamond_shape: ["round", "round"],
    diamond_pieces: ["1", "40"],
    diamond_weight: ["0.38", "0.24"],
  },
  MEB0077: {
    sku: "MEB0077",
    diamond_shape: ["round", "round"],
    diamond_pieces: ["1", "40"],
    diamond_weight: ["0.75", "0.24"],
  },
  MEB0078: {
    sku: "MEB0078",
    diamond_shape: ["princess", "round", "round", "round", "round", "round"],
    diamond_pieces: ["1", "4", "4", "2", "4", "4"],
    diamond_weight: ["0.62", "0.03", "0.04", "0.02", "0.05", "0.07"],
  },
  MEB0079: {
    sku: "MEB0079",
    diamond_shape: ["round", "round", "round", "round", "round", "round"],
    diamond_pieces: ["1", "4", "4", "2", "4", "4"],
    diamond_weight: ["0.38", "0.03", "0.04", "0.02", "0.05", "0.07"],
  },
  MEB0080: {
    sku: "MEB0080",
    diamond_shape: ["round", "round", "round", "round", "round", "round"],
    diamond_pieces: ["1", "4", "4", "2", "4", "4"],
    diamond_weight: ["0.33", "0.03", "0.04", "0.02", "0.05", "0.07"],
  },
  MEB0081: {
    sku: "MEB0081",
    diamond_shape: ["round", "round", "round", "round", "round"],
    diamond_pieces: ["1", "4", "4", "4", "6"],
    diamond_weight: ["0.32", "0.03", "0.04", "0.05", "0.11"],
  },
  MEB0082: {
    sku: "MEB0082",
    diamond_shape: ["round", "round", "round", "round", "round"],
    diamond_pieces: ["1", "4", "4", "4", "6"],
    diamond_weight: ["0.33", "0.03", "0.04", "0.04", "0.1"],
  },
  MEB0083: {
    sku: "MEB0083",
    diamond_shape: [
      "round",
      "round",
      "round",
      "round",
      "round",
      "round",
      "round",
    ],
    diamond_pieces: ["1", "20", "4", "8", "12", "12", "14"],
    diamond_weight: ["0.33", "0.15", "0.04", "0.09", "0.09", "0.12", "0.24"],
  },
  MEB0084: {
    sku: "MEB0084",
    diamond_shape: ["round", "round", "round", "round"],
    diamond_pieces: ["1", "14", "14", "2"],
    diamond_weight: ["0.33", "0.1", "0.14", "0.02"],
  },
  MEB0085: {
    sku: "MEB0085",
    diamond_shape: ["round", "round", "round"],
    diamond_pieces: ["1", "12", "4"],
    diamond_weight: ["0.27", "0.12", "0.02"],
  },
  MEB0086: {
    sku: "MEB0086",
    diamond_shape: ["round", "round"],
    diamond_pieces: ["1", "35"],
    diamond_weight: ["0.54", "0.31"],
  },
  MEB0087: {
    sku: "MEB0087",
    diamond_shape: ["round", "round", "round", "round"],
    diamond_pieces: ["1", "12", "2", "16"],
    diamond_weight: ["0.33", "0.12", "0.01", "0.22"],
  },
  MEB0088: {
    sku: "MEB0088",
    diamond_shape: ["round", "round", "round", "round"],
    diamond_pieces: ["1", "14", "2", "16"],
    diamond_weight: ["0.8", "0.14", "0.01", "0.1"],
  },
  MEB0089: {
    sku: "MEB0089",
    diamond_shape: ["round", "round", "round", "round"],
    diamond_pieces: ["1", "14", "4", "14"],
    diamond_weight: ["0.34", "0.15", "0.04", "0.26"],
  },
  MEB0090: {
    sku: "MEB0090",
    diamond_shape: ["round", "round", "round", "round", "round", "round"],
    diamond_pieces: ["1", "4", "12", "22", "4", "6"],
    diamond_weight: ["0.28", "0.02", "0.09", "0.4", "0.15", "0.08"],
  },
  MEB0091: {
    sku: "MEB0091",
    diamond_shape: [
      "round",
      "round",
      "round",
      "round",
      "round",
      "round",
      "round",
      "oval",
    ],
    diamond_pieces: ["26", "18", "6", "4", "2", "2", "4", "1"],
    diamond_weight: [
      "0.26",
      "0.14",
      "0.03",
      "0.05",
      "0.02",
      "0.04",
      "0.09",
      "0.52",
    ],
  },
  MEB0092: {
    sku: "MEB0092",
    diamond_shape: ["round", "round", "round", "round", "round"],
    diamond_pieces: ["1", "20", "32", "14", "8"],
    diamond_weight: ["0.24", "0.2", "0.25", "0.08", "0.11"],
  },
  MEB0093: {
    sku: "MEB0093",
    diamond_shape: ["round", "round", "round", "round", "round", "round"],
    diamond_pieces: ["1", "24", "4", "14", "8", "8"],
    diamond_weight: ["0.71", "0.25", "0.05", "0.08", "0.06", "0.11"],
  },
  MEB0094: {
    sku: "MEB0094",
    diamond_shape: [
      "round",
      "round",
      "round",
      "round",
      "round",
      "round",
      "round",
      "round",
    ],
    diamond_pieces: ["1", "30", "14", "4", "4", "4", "6", "2"],
    diamond_weight: [
      "0.28",
      "0.3",
      "0.08",
      "0.03",
      "0.05",
      "0.07",
      "0.12",
      "0.05",
    ],
  },
  MEB0095: {
    sku: "MEB0095",
    diamond_shape: ["round", "round", "round", "round"],
    diamond_pieces: ["1", "12", "20", "12"],
    diamond_weight: ["0.28", "0.09", "0.2", "0.07"],
  },
  MEB0096: {
    sku: "MEB0096",
    diamond_shape: ["round", "round", "round", "round", "round"],
    diamond_pieces: ["1", "24", "8", "4", "18"],
    diamond_weight: ["0.75", "0.14", "0.1", "0.1", "0.18"],
  },
  MEB0097: {
    sku: "MEB0097",
    diamond_shape: [
      "oval",
      "round",
      "round",
      "round",
      "round",
      "round",
      "round",
      "round",
    ],
    diamond_pieces: ["1", "18", "4", "6", "2", "2", "2", "2"],
    diamond_weight: [
      "0.67",
      "0.19",
      "0.02",
      "0.1",
      "0.04",
      "0.05",
      "0.05",
      "0.06",
    ],
  },
  MEB0098: {
    sku: "MEB0098",
    diamond_shape: ["round", "round", "round", "round"],
    diamond_pieces: ["1", "12", "20", "6"],
    diamond_weight: ["0.28", "0.11", "0.14", "0.03"],
  },
  MEB0099: {
    sku: "MEB0099",
    diamond_shape: ["round", "round", "round"],
    diamond_pieces: ["1", "22", "32"],
    diamond_weight: ["0.7", "0.15", "0.22"],
  },
  MEB0100: {
    sku: "MEB0100",
    diamond_shape: [
      "round",
      "round",
      "round",
      "round",
      "round",
      "round",
      "round",
      "round",
      "round",
      "round",
    ],
    diamond_pieces: ["1", "4", "4", "4", "2", "2", "2", "2", "4", "2"],
    diamond_weight: [
      "0.75",
      "0.02",
      "0.04",
      "0.03",
      "0.02",
      "0.02",
      "0.02",
      "0.03",
      "0.08",
      "0.05",
    ],
  },
  MEB0101: {
    sku: "MEB0101",
    diamond_shape: ["round", "round", "round", "round", "round"],
    diamond_pieces: ["8", "46", "30", "6", "1"],
    diamond_weight: ["0.045", "0.19", "0.38", "0.04", "1.5"],
  },
  MEB0103: {
    sku: "MEB0103",
    diamond_shape: ["CHUSHION", "round", "round", "round", "round", "round"],
    diamond_pieces: ["1", "48", "12", "14", "2", "2"],
    diamond_weight: ["0.65", "0.33", "0.06", "0.13", "0.03", "0.04"],
  },
  MEB0104: {
    sku: "MEB0104",
    diamond_shape: [
      "round",
      "round",
      "round",
      "round",
      "round",
      "round",
      "round",
      "round",
    ],
    diamond_pieces: ["1", "4", "12", "30", "8", "2", "2", "2"],
    diamond_weight: [
      "0.85",
      "0.05",
      "0.16",
      "0.18",
      "0.06",
      "0.05",
      "0.06",
      "0.09",
    ],
  },
  MEB0105: {
    sku: "MEB0105",
    diamond_shape: ["round", "round"],
    diamond_pieces: ["1", "2"],
    diamond_weight: ["0.5", "0.2"],
  },
  MEB0106: {
    sku: "MEB0106",
    diamond_shape: ["round", "round"],
    diamond_pieces: ["1", "2"],
    diamond_weight: ["0.72", "0.36"],
  },
  MEB0107: {
    sku: "MEB0107",
    diamond_shape: ["round", "round"],
    diamond_pieces: ["1", "2"],
    diamond_weight: ["1", "0.5"],
  },
  MEB0108: {
    sku: "MEB0108",
    diamond_shape: ["round", "round"],
    diamond_pieces: ["1", "2"],
    diamond_weight: ["1.5", "0.5"],
  },
  MEB0109: {
    sku: "MEB0109",
    diamond_shape: ["round", "round"],
    diamond_pieces: ["1", "2"],
    diamond_weight: ["2", "0.66"],
  },
  MEB0110: {
    sku: "MEB0110",
    diamond_shape: ["round", "pear"],
    diamond_pieces: ["1", "2"],
    diamond_weight: ["0.73", "0.68"],
  },
  MEB0111: {
    sku: "MEB0111",
    diamond_shape: ["round", "pear"],
    diamond_pieces: ["1", "2"],
    diamond_weight: ["1.01", "0.67"],
  },
  MEB0112: {
    sku: "MEB0112",
    diamond_shape: ["round", "pear"],
    diamond_pieces: ["1", "2"],
    diamond_weight: ["1.5", "1"],
  },
  MEB0113: {
    sku: "MEB0113",
    diamond_shape: ["oval", "pear"],
    diamond_pieces: ["1", "2"],
    diamond_weight: ["1.5", "0.66"],
  },
  MEB0114: {
    sku: "MEB0114",
    diamond_shape: ["oval", "pear"],
    diamond_pieces: ["1", "2"],
    diamond_weight: ["2", "0.66"],
  },
  MEB0116: {
    sku: "MEB0116",
    diamond_shape: ["oval", "round"],
    diamond_pieces: ["1", "2"],
    diamond_weight: ["1", "0.5"],
  },
  MEB0117: {
    sku: "MEB0117",
    diamond_shape: ["round", "pear"],
    diamond_pieces: ["2", "1"],
    diamond_weight: ["0.67", "1"],
  },
  MEB0118: {
    sku: "MEB0118",
    diamond_shape: ["round", "oval"],
    diamond_pieces: ["2", "1"],
    diamond_weight: ["0.6", "2"],
  },
  MEB0119: {
    sku: "MEB0119",
    diamond_shape: ["emerald", "emerald"],
    diamond_pieces: ["2", "1"],
    diamond_weight: ["0.72", "1"],
  },
  MEB0120: {
    sku: "MEB0120",
    diamond_shape: ["emerald", "emerald"],
    diamond_pieces: ["1", "2"],
    diamond_weight: ["1.5", "0.66"],
  },
  MEB0121: {
    sku: "MEB0121",
    diamond_shape: ["emerald", "emerald"],
    diamond_pieces: ["1", "2"],
    diamond_weight: ["2", "1"],
  },
  MEB0122: {
    sku: "MEB0122",
    diamond_shape: ["round", "round", "round", "round"],
    diamond_pieces: ["1", "12", "18", "40"],
    diamond_weight: ["0.24", "0.12", "0.14", "0.19"],
  },
  MEB0123: {
    sku: "MEB0123",
    diamond_shape: ["oval", "round", "round", "round"],
    diamond_pieces: ["1", "32", "16", "22"],
    diamond_weight: ["0.5", "0.23", "0.09", "0.27"],
  },
  MEB0124: {
    sku: "MEB0124",
    diamond_shape: ["round"],
    diamond_pieces: ["1"],
    diamond_weight: ["0.71"],
  },
  MEB0125: {
    sku: "MEB0125",
    diamond_shape: ["round", "round", "round", "round", "round", "round"],
    diamond_pieces: ["1", "2", "22", "10", "2", "6"],
    diamond_weight: ["0.46", "0.05", "0.13", "0.08", "0.02", "0.08"],
  },
  MEB0126: {
    sku: "MEB0126",
    diamond_shape: ["round"],
    diamond_pieces: ["1"],
    diamond_weight: ["0.75"],
  },
  MEB0127: {
    sku: "MEB0127",
    diamond_shape: ["round"],
    diamond_pieces: ["1"],
    diamond_weight: ["0.84"],
  },
  MEB0129: {
    sku: "MEB0129",
    diamond_shape: ["round", "round", "round", "round", "round"],
    diamond_pieces: ["1", "12", "16", "2", "50"],
    diamond_weight: ["0.24", "0.07", "0.13", "0.2", "0.49"],
  },
  MEB0130: {
    sku: "MEB0130",
    diamond_shape: ["round", "round", "round", "round", "round"],
    diamond_pieces: ["1", "4", "6", "8", "4"],
    diamond_weight: ["0.47", "0.02", "0.05", "0.08", "0.02"],
  },
  MEB0131: {
    sku: "MEB0131",
    diamond_shape: ["round", "round", "round", "round", "round", "round"],
    diamond_pieces: ["1", "8", "6", "6", "2", "2"],
    diamond_weight: ["0.71", "0.05", "0.04", "0.06", "0.02", "0.03"],
  },
  MEB0132: {
    sku: "MEB0132",
    diamond_shape: ["round", "round", "round"],
    diamond_pieces: ["1", "14", "12"],
    diamond_weight: ["0.72", "0.08", "0.09"],
  },
  MEB0133: {
    sku: "MEB0133",
    diamond_shape: ["round", "round"],
    diamond_pieces: ["1", "20"],
    diamond_weight: ["0.23", "0.12"],
  },
  MEB0134: {
    sku: "MEB0134",
    diamond_shape: ["round", "round"],
    diamond_pieces: ["1", "2"],
    diamond_weight: ["0.46", "0.07"],
  },
  MEB0135: {
    sku: "MEB0135",
    diamond_shape: ["round", "round", "round", "round"],
    diamond_pieces: ["1", "58", "6", "16"],
    diamond_weight: ["0.28", "0.35", "0.04", "0.16"],
  },
  MEB0136: {
    sku: "MEB0136",
    diamond_shape: ["round", "round", "round"],
    diamond_pieces: ["1", "64", "6"],
    diamond_weight: ["0.51", "0.38", "0.04"],
  },
  MEB0137: {
    sku: "MEB0137",
    diamond_shape: ["round", "round", "round", "round"],
    diamond_pieces: ["1", "24", "30", "12"],
    diamond_weight: ["0.2", "0.18", "0.3", "0.07"],
  },
  MEB0138: {
    sku: "MEB0138",
    diamond_shape: ["round", "round", "round", "round", "round"],
    diamond_pieces: ["1", "4", "6", "8", "8"],
    diamond_weight: ["0.46", "0.02", "0.05", "0.08", "0.08"],
  },
  MEB0139: {
    sku: "MEB0139",
    diamond_shape: ["round", "round"],
    diamond_pieces: ["1", "20"],
    diamond_weight: ["0.25", "0.12"],
  },
  MEB0140: {
    sku: "MEB0140",
    diamond_shape: ["round", "round", "round"],
    diamond_pieces: ["1", "8", "18"],
    diamond_weight: ["0.75", "0.04", "0.18"],
  },
  MEB0141: {
    sku: "MEB0141",
    diamond_shape: ["round", "round", "round", "round", "round", "round"],
    diamond_pieces: ["1", "24", "24", "4", "8", "6"],
    diamond_weight: ["0.51", "0.14", "0.18", "0.05", "0.1", "0.06"],
  },
  MEB0142: {
    sku: "MEB0142",
    diamond_shape: ["round", "round", "round"],
    diamond_pieces: ["1", "2", "12"],
    diamond_weight: ["0.52", "0.22", "0.16"],
  },
  MEB0143: {
    sku: "MEB0143",
    diamond_shape: ["round", "round", "round", "round", "round", "round"],
    diamond_pieces: ["1", "2", "4", "14", "10", "10"],
    diamond_weight: ["0.3", "0.01", "0.03", "0.17", "0.34", "0.18"],
  },
  MEB0144: {
    sku: "MEB0144",
    diamond_shape: ["round"],
    diamond_pieces: ["1"],
    diamond_weight: ["0.23"],
  },
  MEB0145: {
    sku: "MEB0145",
    diamond_shape: ["round", "round", "round", "round", "round"],
    diamond_pieces: ["1", "2", "10", "12", "12"],
    diamond_weight: ["0.56", "0.18", "0.06", "0.09", "0.22"],
  },
  MEB0146: {
    sku: "MEB0146",
    diamond_shape: [
      "oval",
      "round",
      "round",
      "round",
      "round",
      "round",
      "round",
      "round",
      "round",
      "round",
      "round",
      "round",
    ],
    diamond_pieces: ["1", "8"],
    diamond_weight: ["0.48", "0.05"],
  },
  MEB0147: {
    sku: "MEB0147",
    diamond_shape: ["round"],
    diamond_pieces: ["1"],
    diamond_weight: ["0.31"],
  },
  MEB0148: {
    sku: "MEB0148",
    diamond_shape: ["round", "round", "round", "round"],
    diamond_pieces: ["1", "12", "18", "32"],
    diamond_weight: ["0.25", "0.09", "0.18", "0.24"],
  },
  MEB0149: {
    sku: "MEB0149",
    diamond_shape: ["round", "round", "round", "round", "round"],
    diamond_pieces: ["1", "28", "36", "16", "4"],
    diamond_weight: ["0.23", "0.17", "0.28", "0.15", "0.05"],
  },
  MEB0150: {
    sku: "MEB0150",
    diamond_shape: [
      "round",
      "round",
      "round",
      "round",
      "round",
      "round",
      "round",
    ],
    diamond_pieces: ["12", "1", "24", "32", "4", "8", "4"],
    diamond_weight: ["0.164", "0.18", "0.18", "0.19", "0.04", "0.096", "0.07"],
  },
  MEB0151: {
    sku: "MEB0151",
    diamond_shape: ["round", "round", "round"],
    diamond_pieces: ["1", "12", "68"],
    diamond_weight: ["0.33", "0.028", "0.452"],
  },
  MEB0152: {
    sku: "MEB0152",
    diamond_shape: ["round", "round"],
    diamond_pieces: ["1", "12"],
    diamond_weight: ["0.24", "0.07"],
  },
  MEB0153: {
    sku: "MEB0153",
    diamond_shape: ["round", "round"],
    diamond_pieces: ["1", "35"],
    diamond_weight: ["0.23", "0.19"],
  },
  MEB0154: {
    sku: "MEB0154",
    diamond_shape: [
      "round",
      "round",
      "round",
      "round",
      "round",
      "round",
      "round",
    ],
    diamond_pieces: ["1", "12", "6", "4", "4", "2", "10"],
    diamond_weight: ["0.35", "0.12", "0.07", "0.09", "0.15", "0.14", "0.18"],
  },
  MEB0155: {
    sku: "MEB0155",
    diamond_shape: ["round", "round", "round", "round"],
    diamond_pieces: ["1", "6", "60", "16"],
    diamond_weight: ["0.28", "0.03", "0.45", "0.16"],
  },
  MEB0156: {
    sku: "MEB0156",
    diamond_shape: ["round", "round", "round", "round"],
    diamond_pieces: ["1", "26", "12", "12"],
    diamond_weight: ["0.28", "0.26", "0.07", "0.09"],
  },
  MEB0157: {
    sku: "MEB0157",
    diamond_shape: ["round"],
    diamond_pieces: ["20"],
    diamond_weight: ["1.95"],
  },
  MEB0158: {
    sku: "MEB0158",
    diamond_shape: ["oval"],
    diamond_pieces: ["21"],
    diamond_weight: ["3.2"],
  },
  MEB0159: {
    sku: "MEB0159",
    diamond_shape: ["oval", "oval"],
    diamond_pieces: ["13", "1"],
    diamond_weight: ["2.63", "0.21"],
  },
  MEB0160: {
    sku: "MEB0160",
    diamond_shape: ["round"],
    diamond_pieces: ["22"],
    diamond_weight: ["1.66"],
  },
  MEB0161: {
    sku: "MEB0161",
    diamond_shape: ["round", "oval"],
    diamond_pieces: ["9", "9"],
    diamond_weight: ["0.79", "1.25"],
  },
  MEB0162: {
    sku: "MEB0162",
    diamond_shape: ["pear"],
    diamond_pieces: ["18"],
    diamond_weight: ["3"],
  },
  MEB0163: {
    sku: "MEB0163",
    diamond_shape: ["pear"],
    diamond_pieces: ["18"],
    diamond_weight: ["2.85"],
  },
  MEB0164: {
    sku: "MEB0164",
    diamond_shape: ["round", "round"],
    diamond_pieces: ["20", "40"],
    diamond_weight: ["1.97", "0.26"],
  },
  MEB0165: {
    sku: "MEB0165",
    diamond_shape: ["heart"],
    diamond_pieces: ["32"],
    diamond_weight: ["5.3"],
  },
  MEB0166: {
    sku: "MEB0166",
    diamond_shape: ["oval"],
    diamond_pieces: ["19"],
    diamond_weight: ["3.01"],
  },
  MEB0167: {
    sku: "MEB0167",
    diamond_shape: ["round", "round"],
    diamond_pieces: ["25", "50"],
    diamond_weight: ["1.09", "0.26"],
  },
  MEB0168: {
    sku: "MEB0168",
    diamond_shape: ["emerald"],
    diamond_pieces: ["21"],
    diamond_weight: ["5.703"],
  },
  MEB0169: {
    sku: "MEB0169",
    diamond_shape: ["emerald"],
    diamond_pieces: ["15"],
    diamond_weight: ["3.81"],
  },
  MEB0170: {
    sku: "MEB0170",
    diamond_shape: ["oval"],
    diamond_pieces: ["20"],
    diamond_weight: ["3.3"],
  },
  MEB0171: {
    sku: "MEB0171",
    diamond_shape: ["heart", "round", "round", "round"],
    diamond_pieces: ["1", "12", "8", "14"],
    diamond_weight: ["1", "0.07", "0.06", "0.17"],
  },
  MEB5000: {
    sku: "MEB5000",
    diamond_shape: ["round", "round"],
    diamond_pieces: ["2", "18"],
    diamond_weight: ["0.76", "0.11"],
  },
  MEB5001: {
    sku: "MEB5001",
    diamond_shape: ["round", "round"],
    diamond_pieces: ["22", "2"],
    diamond_weight: ["0.13", "1.5"],
  },
  MEB5002: {
    sku: "MEB5002",
    diamond_shape: ["round", "round"],
    diamond_pieces: ["22", "2"],
    diamond_weight: ["0.17", "2"],
  },
  MEB5003: {
    sku: "MEB5003",
    diamond_shape: ["round", "round"],
    diamond_pieces: ["2", "8"],
    diamond_weight: ["0.665", "0.11"],
  },
  MEB5004: {
    sku: "MEB5004",
    diamond_shape: ["round", "round"],
    diamond_pieces: ["8", "2"],
    diamond_weight: ["0.18", "1"],
  },
  MEB5005: {
    sku: "MEB5005",
    diamond_shape: ["round", "round"],
    diamond_pieces: ["2", "8"],
    diamond_weight: ["1.52", "0.21"],
  },
  MEB5006: {
    sku: "MEB5006",
    diamond_shape: ["round", "round"],
    diamond_pieces: ["8", "2"],
    diamond_weight: ["0.4", "2"],
  },
  MEB5007: {
    sku: "MEB5007",
    diamond_shape: ["round", "round"],
    diamond_pieces: ["8", "2"],
    diamond_weight: ["0.62", "3"],
  },
  MEB5008: {
    sku: "MEB5008",
    diamond_shape: ["round"],
    diamond_pieces: ["2"],
    diamond_weight: ["0.5"],
  },
  MEB5009: {
    sku: "MEB5009",
    diamond_shape: ["round"],
    diamond_pieces: ["2"],
    diamond_weight: ["0.76"],
  },
  MEB5010: {
    sku: "MEB5010",
    diamond_shape: ["heart", "round"],
    diamond_pieces: ["2", "16"],
    diamond_weight: ["0.66", "0.1"],
  },
  MEB5011: {
    sku: "MEB5011",
    diamond_shape: ["round"],
    diamond_pieces: ["2"],
    diamond_weight: ["1.458"],
  },
  MEB5012: {
    sku: "MEB5012",
    diamond_shape: ["round", "round"],
    diamond_pieces: ["16", "2"],
    diamond_weight: ["0.1", "0.76"],
  },
  MEB5013: {
    sku: "MEB5013",
    diamond_shape: ["round", "round"],
    diamond_pieces: ["2", "16"],
    diamond_weight: ["0.98", "0.09"],
  },
  MEB5014: {
    sku: "MEB5014",
    diamond_shape: ["round", "round"],
    diamond_pieces: ["16", "2"],
    diamond_weight: ["0.12", "1.5"],
  },
  MEB5015: {
    sku: "MEB5015",
    diamond_shape: ["round"],
    diamond_pieces: ["2"],
    diamond_weight: ["0.485"],
  },
  MEB5016: {
    sku: "MEB5016",
    diamond_shape: ["round"],
    diamond_pieces: ["2"],
    diamond_weight: ["0.78"],
  },
  MEB5017: {
    sku: "MEB5017",
    diamond_shape: ["round"],
    diamond_pieces: ["2"],
    diamond_weight: ["1.5"],
  },
  MEB5018: {
    sku: "MEB5018",
    diamond_shape: ["round"],
    diamond_pieces: ["2"],
    diamond_weight: ["2"],
  },
  MEB5019: {
    sku: "MEB5019",
    diamond_shape: ["heart", "round"],
    diamond_pieces: ["2", "18"],
    diamond_weight: ["0.75", "0.11"],
  },
  MEB5020: {
    sku: "MEB5020",
    diamond_shape: ["round", "round"],
    diamond_pieces: ["2", "20"],
    diamond_weight: ["0.465", "0.1"],
  },
  MEB5021: {
    sku: "MEB5021",
    diamond_shape: ["round", "round"],
    diamond_pieces: ["2", "24"],
    diamond_weight: ["0.98", "0.14"],
  },
  MEB5022: {
    sku: "MEB5022",
    diamond_shape: ["round", "round"],
    diamond_pieces: ["24", "2"],
    diamond_weight: ["0.18", "1.5"],
  },
  MEB5023: {
    sku: "MEB5023",
    diamond_shape: ["round", "round"],
    diamond_pieces: ["24", "2"],
    diamond_weight: ["0.34", "2"],
  },
  MEB5024: {
    sku: "MEB5024",
    diamond_shape: ["oval", "round"],
    diamond_pieces: ["2", "24"],
    diamond_weight: ["0.7", "0.14"],
  },
  MEB5025: {
    sku: "MEB5025",
    diamond_shape: ["round", "round", "round", "round"],
    diamond_pieces: ["2", "2", "2", "52"],
    diamond_weight: ["0.26", "0.26", "0.55", "0.31"],
  },
  MEB5027: {
    sku: "MEB5027",
    diamond_shape: ["round", "round"],
    diamond_pieces: ["24", "2"],
    diamond_weight: ["0.09", "0.66"],
  },
  MEB5028: {
    sku: "MEB5028",
    diamond_shape: ["round", "round", "round"],
    diamond_pieces: ["2", "16", "8"],
    diamond_weight: ["1.03", "0.15", "0.09"],
  },
  MEB5029: {
    sku: "MEB5029",
    diamond_shape: ["round", "round", "round"],
    diamond_pieces: ["16", "8", "2"],
    diamond_weight: ["0.219", "0.14", "1.5"],
  },
  MEB5030: {
    sku: "MEB5030",
    diamond_shape: ["oval", "round"],
    diamond_pieces: ["2", "28"],
    diamond_weight: ["0.66", "0.21"],
  },
  MEB5031: {
    sku: "MEB5031",
    diamond_shape: ["oval", "round", "round"],
    diamond_pieces: ["2", "16", "12"],
    diamond_weight: ["1", "0.16", "0.14"],
  },
  MEB5032: {
    sku: "MEB5032",
    diamond_shape: ["heart", "round"],
    diamond_pieces: ["2", "30"],
    diamond_weight: ["0.66", "0.18"],
  },
  MEB5033: {
    sku: "MEB5033",
    diamond_shape: ["round", "round", "round"],
    diamond_pieces: ["2", "28", "24"],
    diamond_weight: ["0.4", "0.51", "0.14"],
  },
  MEB5034: {
    sku: "MEB5034",
    diamond_shape: ["oval", "round", "round", "round"],
    diamond_pieces: ["2", "32", "12", "16"],
    diamond_weight: ["0.69", "0.68", "0.09", "0.1"],
  },
  MEB5035: {
    sku: "MEB5035",
    diamond_shape: ["round"],
    diamond_pieces: ["2"],
    diamond_weight: ["3"],
  },
  MEB5036: {
    sku: "MEB5036",
    diamond_shape: ["round"],
    diamond_pieces: ["2"],
    diamond_weight: ["2"],
  },
  MEB5037: {
    sku: "MEB5037",
    diamond_shape: ["round", "round"],
    diamond_pieces: ["2", "4"],
    diamond_weight: ["0.42", "0.13"],
  },
  MEB5038: {
    sku: "MEB5038",
    diamond_shape: ["round", "round"],
    diamond_pieces: ["24", "2"],
    diamond_weight: ["0.24", "0.4"],
  },
  MEB5039: {
    sku: "MEB5039",
    diamond_shape: ["round", "round"],
    diamond_pieces: ["2", "24"],
    diamond_weight: ["0.66", "0.33"],
  },
  MEB5040: {
    sku: "MEB5040",
    diamond_shape: ["round", "round", "round"],
    diamond_pieces: ["2", "6", "18"],
    diamond_weight: ["1.07", "0.109", "0.384"],
  },
  MEB5041: {
    sku: "MEB5041",
    diamond_shape: ["round", "round"],
    diamond_pieces: ["42", "2"],
    diamond_weight: ["0.21", "2"],
  },
  MEB5042: {
    sku: "MEB5042",
    diamond_shape: ["round", "round", "round"],
    diamond_pieces: ["2", "40", "24"],
    diamond_weight: ["0.51", "0.22", "0.18"],
  },
  MEB5043: {
    sku: "MEB5043",
    diamond_shape: ["round", "round", "round"],
    diamond_pieces: ["2", "40", "24"],
    diamond_weight: ["0.61", "0.38", "0.3"],
  },
  MEB5044: {
    sku: "MEB5044",
    diamond_shape: ["round", "round", "round", "round"],
    diamond_pieces: ["36", "48", "12", "2"],
    diamond_weight: ["0.22", "0.36", "0.14", "2"],
  },
  MEB5045: {
    sku: "MEB5045",
    diamond_shape: ["round", "round", "round"],
    diamond_pieces: ["2", "16", "16"],
    diamond_weight: ["1.46", "0.11", "0.16"],
  },
  MEB5046: {
    sku: "MEB5046",
    diamond_shape: ["round", "round", "round", "round"],
    diamond_pieces: ["2", "40", "16", "8"],
    diamond_weight: ["0.68", "0.3", "0.19", "0.08"],
  },
  MEB5047: {
    sku: "MEB5047",
    diamond_shape: ["round"],
    diamond_pieces: ["32"],
    diamond_weight: ["0.96"],
  },
  MEB5048: {
    sku: "MEB5048",
    diamond_shape: ["round", "round"],
    diamond_pieces: ["96", "28"],
    diamond_weight: ["0.77", "0.7"],
  },
  MEB5049: {
    sku: "MEB5049",
    diamond_shape: ["round"],
    diamond_pieces: ["54"],
    diamond_weight: ["1.19"],
  },
  MEB5050: {
    sku: "MEB5050",
    diamond_shape: ["emerald", "round", "round"],
    diamond_pieces: ["2", "16", "32"],
    diamond_weight: ["1.6", "0.16", "0.38"],
  },
  MEB5051: {
    sku: "MEB5051",
    diamond_shape: ["round"],
    diamond_pieces: ["2"],
    diamond_weight: ["2"],
  },
  MEB5052: {
    sku: "MEB5052",
    diamond_shape: ["marquiseSH", "marquiseSH", "princess"],
    diamond_pieces: ["2", "4", "2"],
    diamond_weight: ["0.4", "0.6", "1"],
  },
  MEB5053: {
    sku: "MEB5053",
    diamond_shape: ["round"],
    diamond_pieces: ["54"],
    diamond_weight: ["1.19"],
  },
  MEB5054: {
    sku: "MEB5054",
    diamond_shape: ["round", "round", "round"],
    diamond_pieces: ["11", "7", "2"],
    diamond_weight: ["0.108", "0.075", "2.5"],
  },
  MEB5055: {
    sku: "MEB5055",
    diamond_shape: ["round", "round", "round", "round"],
    diamond_pieces: ["32", "6", "6", "2"],
    diamond_weight: ["0.22", "0.08", "0.1", "1.5"],
  },
  MEB5056: {
    sku: "MEB5056",
    diamond_shape: ["round", "round"],
    diamond_pieces: ["36", "2"],
    diamond_weight: ["0.22", "1.28"],
  },
  MEB5057: {
    sku: "MEB5057",
    diamond_shape: ["heart", "round", "round", "round", "round"],
    diamond_pieces: ["2", "30", "8", "14", "16"],
    diamond_weight: ["2", "0.19", "0.07", "0.17", "0.27"],
  },
  MEB5058: {
    sku: "MEB5058",
    diamond_shape: ["oval", "round", "round", "round"],
    diamond_pieces: ["2", "28", "16", "8"],
    diamond_weight: ["2.5", "0.17", "0.11", "0.07"],
  },
  MEB5059: {
    sku: "MEB5059",
    diamond_shape: ["cushion", "round", "round", "round"],
    diamond_pieces: ["2", "20", "22", "2"],
    diamond_weight: ["2.5", "0.12", "0.2", "0.02"],
  },
  MEB5060: {
    sku: "MEB5060",
    diamond_shape: [
      "oval",
      "pear",
      "round",
      "round",
      "round",
      "round",
      "round",
      "round",
    ],
    diamond_pieces: ["2", "2", "50", "28", "20", "12", "16", "2"],
    diamond_weight: [
      "2.5",
      "0.8",
      "0.31",
      "0.2",
      "0.18",
      "0.14",
      "0.22",
      "0.07",
    ],
  },
  MEB5061: {
    sku: "MEB5061",
    diamond_shape: ["cushion", "pear", "round", "round", "round"],
    diamond_pieces: ["8", "2", "108", "62", "28"],
    diamond_weight: ["1.2", "3", "0.67", "0.43", "0.25"],
  },
  MEB5062: {
    sku: "MEB5062",
    diamond_shape: ["cushion", "heart", "round", "round"],
    diamond_pieces: ["2", "2", "14", "6"],
    diamond_weight: ["1", "2", "0.09", "0.07"],
  },
  MEB5063: {
    sku: "MEB5063",
    diamond_shape: ["pear", "round", "round", "round", "round", "round"],
    diamond_pieces: ["2", "74", "8", "14", "8", "2"],
    diamond_weight: ["3", "0.46", "0.06", "0.13", "0.1", "0.92"],
  },
  MEB5064: {
    sku: "MEB5064",
    diamond_shape: ["heart", "pear", "round", "round", "round"],
    diamond_pieces: ["2", "2", "78", "40", "24"],
    diamond_weight: ["1", "3", "0.48", "0.28", "0.22"],
  },
  MEB9000: {
    sku: "MEB9000",
    diamond_shape: [
      "round",
      "oval",
      "oval",
      "pear",
      "oval",
      "emerald",
      "oval",
      "pear",
      "round",
      "oval",
      "pear",
      "round",
    ],
    diamond_pieces: ["54", "27", "1"],
    diamond_weight: ["1.16", "4.5", "0.21"],
  },
  MEB9001: {
    sku: "MEB9001",
    diamond_shape: ["pear"],
    diamond_pieces: ["28"],
    diamond_weight: ["5.6"],
  },
  MEB9002: {
    sku: "MEB9002",
    diamond_shape: ["oval"],
    diamond_pieces: ["33"],
    diamond_weight: ["6.6"],
  },
  MEB9003: {
    sku: "MEB9003",
    diamond_shape: ["emerald"],
    diamond_pieces: ["35"],
    diamond_weight: ["8.73"],
  },
  MEB9004: {
    sku: "MEB9004",
    diamond_shape: ["oval", "pear", "round"],
    diamond_pieces: ["14", "52", "13"],
    diamond_weight: ["7", "10.4", "0.09"],
  },
  MEB9005: {
    sku: "MEB9005",
    diamond_shape: ["oval", "pear", "round", "round"],
    diamond_pieces: ["9", "9", "99", "117"],
    diamond_weight: ["1.8", "1.8", "0.89", "1.4"],
  },
  TCL150: {
    sku: "TCL150",
    diamond_shape: ["round"],
    diamond_pieces: ["91"],
    diamond_weight: ["1.27"],
  },
  TCL175: {
    sku: "TCL175",
    diamond_shape: ["round"],
    diamond_pieces: ["89"],
    diamond_weight: ["1.78"],
  },
  TCL200: {
    sku: "TCL200",
    diamond_shape: ["round"],
    diamond_pieces: ["80"],
    diamond_weight: ["2.56"],
  },
  TCL225: {
    sku: "TCL225",
    diamond_shape: ["round"],
    diamond_pieces: ["64"],
    diamond_weight: ["2.82"],
  },
  TCL250: {
    sku: "TCL250",
    diamond_shape: ["round"],
    diamond_pieces: ["62"],
    diamond_weight: ["3.84"],
  },
  TCL275: {
    sku: "TCL275",
    diamond_shape: ["round"],
    diamond_pieces: ["60"],
    diamond_weight: ["4.86"],
  },
  TCL300: {
    sku: "TCL300",
    diamond_shape: ["round"],
    diamond_pieces: ["59"],
    diamond_weight: ["6.19"],
  },
  TCL400: {
    sku: "TCL400",
    diamond_shape: ["round"],
    diamond_pieces: ["44"],
    diamond_weight: ["10.95"],
  },
  TCL375: {
    sku: "TCL375",
    diamond_shape: ["round"],
    diamond_pieces: ["45"],
    diamond_weight: ["9"],
  },
  TCR275: {
    sku: "TCR275",
    diamond_shape: ["round"],
    diamond_pieces: ["46"],
    diamond_weight: ["3.67"],
  },
  TCR300: {
    sku: "TCR300",
    diamond_shape: ["round"],
    diamond_pieces: ["46"],
    diamond_weight: ["4.83"],
  },
  TCL350: {
    sku: "TCL350",
    diamond_shape: ["round"],
    diamond_pieces: ["48"],
    diamond_weight: ["8.16"],
  },
  TCR375: {
    sku: "TCR375",
    diamond_shape: ["round"],
    diamond_pieces: ["34"],
    diamond_weight: ["6.8"],
  },
  TCR425: {
    sku: "TCR425",
    diamond_shape: ["round"],
    diamond_pieces: ["31"],
    diamond_weight: ["9.92"],
  },
  TCR465: {
    sku: "TCR465",
    diamond_shape: ["round"],
    diamond_pieces: ["29"],
    diamond_weight: ["11.6"],
  },
  TCL500: {
    sku: "TCL500",
    diamond_shape: ["round"],
    diamond_pieces: ["36"],
    diamond_weight: ["18"],
  },
  TCR150: {
    sku: "TCR150",
    diamond_shape: ["round"],
    diamond_pieces: ["73"],
    diamond_weight: ["1.02"],
  },
  TCR175: {
    sku: "TCR175",
    diamond_shape: ["round"],
    diamond_pieces: ["70"],
    diamond_weight: ["1.4"],
  },
  TCR200: {
    sku: "TCR200",
    diamond_shape: ["round"],
    diamond_pieces: ["59"],
    diamond_weight: ["1.89"],
  },
  TCR225: {
    sku: "TCR225",
    diamond_shape: ["round"],
    diamond_pieces: ["53"],
    diamond_weight: ["2.33"],
  },
  TCR250: {
    sku: "TCR250",
    diamond_shape: ["round"],
    diamond_pieces: ["48"],
    diamond_weight: ["2.98"],
  },
  TCR330: {
    sku: "TCR330",
    diamond_shape: ["round"],
    diamond_pieces: ["42"],
    diamond_weight: ["5.88"],
  },
  TCR350: {
    sku: "TCR350",
    diamond_shape: ["round"],
    diamond_pieces: ["39"],
    diamond_weight: ["6.63"],
  },
  TCR400: {
    sku: "TCR400",
    diamond_shape: ["round"],
    diamond_pieces: ["35"],
    diamond_weight: ["8.75"],
  },
  TCR500: {
    sku: "TCR500",
    diamond_shape: ["round"],
    diamond_pieces: ["27"],
    diamond_weight: ["13.5"],
  },
  BCL235: {
    sku: "BCL235",
    diamond_shape: ["round"],
    diamond_pieces: ["66"],
    diamond_weight: ["3.15"],
  },
  BCL425: {
    sku: "BCL425",
    diamond_shape: ["round"],
    diamond_pieces: ["36"],
    diamond_weight: ["11.85"],
  },
  BCR235: {
    sku: "BCR235",
    diamond_shape: ["round"],
    diamond_pieces: ["51"],
    diamond_weight: ["2.4"],
  },
  BCR340: {
    sku: "BCR340",
    diamond_shape: ["round"],
    diamond_pieces: ["37"],
    diamond_weight: ["5.6"],
  },
  BCR425: {
    sku: "BCR425",
    diamond_shape: ["round"],
    diamond_pieces: ["32"],
    diamond_weight: ["10.5"],
  },
  BCL340: {
    sku: "BCL340",
    diamond_shape: ["round"],
    diamond_pieces: ["44"],
    diamond_weight: ["6.65"],
  },
};
