export const updateUserConnected = ({ nodes, id }) => {
  const updateUserCount = nodes.map((node) => {
    if (node.id === id) {
      return { ...node, userConnected: node.userConnected - 1 };
    }
    return node;
  });
  
  return updateUserCount
};
