import { ArrowForward } from "@material-ui/icons";
import {
  Box,
  Button,
  Card,
  CardContent,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import ReactApexChart from "react-apexcharts";
import styled from "styled-components";
import {
  getRandomInt,
  isArrayWithValues,
  jweroColorPalette,
} from "../../helper";

export const skeletonSpacing = 5;

export const catalogCardStyle = {
  borderRadius: "10px",
};

export const catalogDateFormat = "dd/mm/yyyy";

export const catalogUserDefaultAvatar =
  "https://t4.ftcdn.net/jpg/00/64/67/63/360_F_64676383_LdbmhiNM6Ypzb3FM4PPuFP9rHe7ri8Ju.jpg";

export const catalogWatchImpression = 2;
export const catalogProductWatchImpression = 2;

export const catalogGlobalColorsLight = [
  "#48CAE4",
  "#90E0EF",
  "#ADE8F4",
  "#CAF0F8",
];

export const TotalCard = ({
  title,
  value,
  values = [],
  suffix,
  backgroundColor,
  onClick,
  breakup,
  graphData,
  hideGraph,
  extra,
  hideNavigate = false,
  headerIcon: HeaderIcon,
  containerStyle,
}) => {
  return (
    <>
      <Card
        sx={{
          border: "0.5px solid #e5eef8",
          // height: "100%",
          backgroundColor: backgroundColor || "background.paper",
          cursor: "pointer",
          height: "100%",
          ...catalogCardStyle,
          ...containerStyle,
        }}
      >
        <CardContent sx={{ "&:last-child": { paddingBottom: "20px" } }}>
          <Stack
            direction="row"
            alignItems={"center"}
            justifyContent="space-between"
          >
            <Typography variant="h5" fontSize="22px" fontWeight={"500"}>
              {value || 0}
            </Typography>
            {!hideNavigate ? (
              <Button
                // variant="text"
                onClick={() => onClick && onClick()}
                variant="contained"
                size="small"
                color="primary"
                sx={{ minWidth: "44px", height: "23px", borderRadius: "9px" }}
              >
                <ArrowForward style={{ height: "17px", width: "17px" }} />
              </Button>
            ) : null}
            {HeaderIcon ? (
              <HeaderIcon
                height={35}
                width={35}
                style={{ marginLeft: "20px" }}
              />
            ) : null}
          </Stack>
          <Typography
            fontSize="16px"
            fontWeight={"500"}
            sx={{ color: "text.secondary" }}
          >
            {title}
          </Typography>
          <Stack sx={{ my: 2.5, py: 1, display: hideGraph ? "none" : "block" }}>
            <ReactApexChart
              options={totalCardChartOptions}
              series={[
                {
                  data: isArrayWithValues(graphData)
                    ? graphData
                    : [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                },
              ]}
              type="line"
              height={65}
              width={"100%"}
            />
          </Stack>
          <Stack
            mt={hideGraph ? 5 : 2}
            alignItems="flex-end"
            direction={"row"}
            // justifyContent="space-around"
            // flexWrap="wrap"
            // sx={{ marginTop: "auto" }}
            spacing={10}
          >
            {values.map((obj) => {
              return (
                <Box sx={{ alignSelf: "flex-start" }}>
                  <Typography
                    sx={{ fontSize: "14px", color: "text.secondary" }}
                  >
                    {obj.title}
                  </Typography>
                  <Stack direction="row" alignItems={"center"} sx={{ mt: 1 }}>
                    {obj.prefix && (
                      <Typography
                        ml={1}
                        variant="h5"
                        fontSize="22px"
                        fontWeight={"500"}
                        mr={1}
                      >
                        {obj.prefix || ""}
                      </Typography>
                    )}
                    <Tooltip title={obj.tooltip || ""}>
                      <Typography
                        variant="h5"
                        fontSize="22px"
                        fontWeight={"500"}
                      >
                        {obj.value}
                      </Typography>
                    </Tooltip>
                    <Typography
                      ml={1}
                      variant="body2"
                      fontSize="16px"
                      color="text.secondary"
                    >
                      {obj.suffix || ""}
                    </Typography>
                  </Stack>
                </Box>
              );
            })}
          </Stack>
          {extra}
        </CardContent>
      </Card>
      {/* {isObjWithValues(breakup) && (
          <Card sx={{}}>
            <CardContent>
              {Object.keys(breakup).map((key) => {
                return (
                  <RenderLabelValue
                    value={breakup[key]}
                    label={formatServerValue(key)}
                  />
                );
              })}
            </CardContent>
          </Card>
        )} */}
    </>
  );
};

export const catalogGlobalColors = [
  // "#023E8A",
  // "#0077B6",
  // "#0096C7",
  // "#00B4D8",
  // "#48CAE4",
  "#012687",
  "#0012b9",
  "#616ada",
  "#f6b11c",
];
export const TableLabel = styled(Typography)`
  font-size: 14px;
  font-weight: 500;
`;
export const TableSecondaryLabel = styled(Typography)`
  font-size: 11px;
  color: ${(props) => props?.theme?.palette?.text?.secondary};
`;
// font-weight: 500;

export const SectionLabel = styled(Typography)`
  font-size: 20px;
  font-weight: 500;
  margin-left: 5px;
`;

export const IconValue = ({ icon, text, textStyle, tooltip }) => {
  return (
    <Tooltip title={tooltip || ""}>
      <Stack direction="row" alignItems={"center"}>
        {icon}
        <Typography sx={{ fontSize: "12px", color: "text.secondary", ml: 1 }}>
          {text}
        </Typography>
      </Stack>
    </Tooltip>
  );
};

const totalCardChartOptions = {
  chart: {
    type: "line",
    // width: 100,
    // height: 35,
    sparkline: {
      enabled: true,
    },
  },
  colors: catalogGlobalColors,
  grid: {
    padding: {
      top: 5,
      bottom: 5,
    },
  },
  stroke: {
    show: true,
    curve: "smooth",
    lineCap: "butt",
    colors: undefined,
    width: 3,
    dashArray: 0,
  },
  tooltip: {
    fixed: {
      enabled: false,
    },
    x: {
      show: false,
    },
    y: {
      title: {
        formatter: function (seriesName) {
          return "";
        },
      },
    },
    marker: {
      show: false,
    },
  },
};

export const recentRequestHeadCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Catalog name",
  },
  {
    id: "contact_name",
    numeric: false,
    disablePadding: false,
    label: "Contact name",
  },
  {
    id: "request_date",
    numeric: false,
    disablePadding: false,
    label: "Request date",
  },
  {
    id: "products_requested",
    numeric: true,
    disablePadding: false,
    label: "Products selected",
  },
  {
    id: "stock",
    numeric: false,
    disablePadding: false,
    label: "Stock type",
  },
  {
    id: "contacts_responded",
    numeric: false,
    disablePadding: false,
    label: "Contacts responded",
    // hasSubheader: 1,
  },
  {
    id: "products_request_received",
    numeric: false,
    disablePadding: false,
    label: "Total products selected",
    // hasSubheader: 1,
  },
  // {
  //   id: "remarks",
  //   numeric: true,
  //   disablePadding: false,
  //   label: "Remarks",
  // },

  {
    id: "actions",
    numeric: true,
    disablePadding: false,
    label: "Actions",
  },
];
export const catalogCustomerHeadCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Catalog Sent to",
  },
  {
    id: "contact_name",
    numeric: false,
    disablePadding: false,
    label: "Type",
  },
  {
    id: "request_date",
    numeric: false,
    disablePadding: false,
    label: "Date",
  },
  {
    id: "products_requested",
    numeric: true,
    disablePadding: false,
    label: "Number of products",
  },
  {
    id: "stock",
    numeric: false,
    disablePadding: false,
    label: "Requests received",
    hasSubheader: 1,
  },
  // {
  //   id: "contacts_responded",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "Statistic",
  //   // hasSubheader: 1,
  // },
];

export const topCustomersHeadCells = [
  {
    id: "contact_name",
    numeric: false,
    disablePadding: false,
    label: "Contact name",
  },
  {
    id: "send",
    numeric: true,
    disablePadding: false,
    label: "Catalog sent",
  },
  {
    id: "requst",
    numeric: true,
    disablePadding: false,
    label: "Catalog request",
  },
  {
    id: "product_request",
    numeric: true,
    disablePadding: false,
    label: "Product request",
  },
  {
    id: "shared",
    numeric: true,
    disablePadding: false,
    label: "Products sent",
    // hasSubheader: 1,
  },
  {
    id: "product_orders",
    numeric: true,
    disablePadding: false,
    label: "Products ordered",
    // hasSubheader: 1,
  },
];
export const catalogsSentHeadCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Catalog name",
  },
  {
    id: "type",
    numeric: false,
    disablePadding: false,
    label: "Type",
  },
  {
    id: "date",
    numeric: false,
    disablePadding: false,
    label: "Date",
  },
  {
    id: "sent_to",
    numeric: false,
    disablePadding: false,
    label: "To",
  },
  {
    id: "stock",
    numeric: true,
    disablePadding: false,
    label: "Products",
  },
  // {
  //   id: "remarks",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "Remarks",
  // },
  {
    id: "response from",
    numeric: false,
    disablePadding: false,
    label: "Response from",
  },
  {
    id: "products_request_received",
    numeric: false,
    disablePadding: false,
    label: "Products request received",
    hasSubheader: 1,
  },
  {
    id: "contacts_request_received",
    numeric: false,
    disablePadding: false,
    label: "Requests",
    hasSubheader: 2,
  },

  {
    id: "actions",
    numeric: true,
    disablePadding: false,
    label: "Actions",
  },
];
export const catalogsSentHeadCellsCollapsed = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Catalog name",
  },
  {
    id: "type",
    numeric: false,
    disablePadding: false,
    label: "Type",
  },
  {
    id: "date",
    numeric: false,
    disablePadding: false,
    label: "Date",
  },
  {
    id: "sent_to",
    numeric: false,
    disablePadding: false,
    label: "To",
  },
  {
    id: "stock",
    numeric: true,
    disablePadding: false,
    label: "Products",
  },
  {
    id: "response from",
    numeric: false,
    disablePadding: false,
    label: "Response from",
  },
  // {
  //   id: "remarks",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "Remarks",
  // },
  // {
  //   id: "products_request_received",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "Products request received",
  //   hasSubheader: 1,
  // },
  // {
  //   id: "contacts_request_received",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "Requests received",
  //   hasSubheader: 2,
  // },

  {
    id: "actions",
    numeric: true,
    disablePadding: false,
    label: "Actions",
  },
];

export const MostViewedProductsGraphOptions = {
  chart: {
    type: "bar",
    height: 350,
    stacked: true,
  },
  colors: catalogGlobalColors,
  plotOptions: {
    bar: {
      horizontal: true,
      dataLabels: {
        total: {
          enabled: true,
          offsetX: 0,
          style: {
            fontSize: "13px",
            fontWeight: 900,
          },
        },
      },
      borderRadius: 10,
      barHeight: "50%",
    },
  },
  stroke: {
    width: 1,
    colors: ["#fff"],
  },
  // title: {
  //   text: "Most viewed products",
  // },
  yaxis: {
    title: {
      text: undefined,
    },
  },
  tooltip: {
    y: {
      formatter: function (val) {
        return val;
      },
    },
  },
  fill: {
    opacity: 1,
  },
  legend: {
    position: "top",
    horizontalAlign: "left",
    offsetX: 40,
  },
  grid: {
    show: false,
  },
};
export const TopCustomersGraphOptions = {
  chart: {
    type: "bar",
    height: 350,
    stacked: true,
  },
  colors: catalogGlobalColors,
  plotOptions: {
    bar: {
      horizontal: true,
      dataLabels: {
        total: {
          enabled: true,
          offsetX: 0,
          style: {
            fontSize: "13px",
            fontWeight: 900,
          },
        },
      },
      borderRadius: 10,
      barHeight: "70%",
    },
  },
  stroke: {
    width: 1,
    colors: ["#fff"],
  },
  // title: {
  //   text: "Top Contacts - View & Requests",
  // },
  yaxis: {
    title: {
      text: undefined,
    },
  },
  tooltip: {
    y: {
      maxWidth: 100,
      formatter: function (val) {
        return val;
      },
    },
  },
  fill: {
    opacity: 1,
  },
  legend: {
    position: "top",
    horizontalAlign: "left",
    offsetX: 40,
  },
  grid: {
    show: false,
  },
};

// export const CatalogSentRequestChartOptions = {
//   chart: {
//     type: "bar",
//     height: 350,
//   },
//   colors: catalogGlobalColors,
//   plotOptions: {
//     bar: {
//       horizontal: false,
//       columnWidth: "55%",
//       endingShape: "rounded",
//     },
//   },
//   dataLabels: {
//     enabled: false,
//   },
//   stroke: {
//     show: true,
//     width: 2,
//     colors: ["transparent"],
//   },
//   xaxis: {
//     categories: ["Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct"],
//   },
//   yaxis: {
//     title: {
//       text: "$ (thousands)",
//     },
//   },
//   fill: {
//     opacity: 1,
//   },
//   tooltip: {
//     y: {
//       formatter: function (val) {
//         return "$ " + val + " thousands";
//       },
//     },
//   },
// };

export const CatalogSentRequestChartOptions = {
  chart: {
    id: "area-datetime",
    type: "line",
    height: 350,
    zoom: {
      autoScaleYaxis: true,
    },
    // toolbar: { show: false },
  },
  colors: catalogGlobalColors,
  dataLabels: {
    enabled: false,
  },
  markers: {
    size: 0,
    style: "hollow",
  },
  yaxis: [
    {
      labels: {
        formatter: function (val) {
          return val?.toFixed(0);
        },
      },
    },
  ],
  xaxis: {
    type: "datetime",
    // min: new Date("01 Mar 2012").getTime(),
    tickAmount: 6,
  },
  tooltip: {
    x: {
      format: "dd MMM yyyy",
    },
  },
  stroke: {
    width: 5,
    curve: "smooth",
  },
  // fill: {
  //   type: "gradient",
  //   gradient: {
  //     shadeIntensity: 1,
  //     opacityFrom: 0.7,
  //     opacityTo: 0.9,
  //     stops: [0, 100],
  //   },
  // },
};

// function RecentCatalogRequest() {
//   const [order, setOrder] = React.useState("asc");
//   const [orderBy, setOrderBy] = React.useState("calories");
//   const [selected, setSelected] = React.useState([]);
//   const [page, setPage] = React.useState(0);
//   const [dense, setDense] = React.useState(false);
//   const [rowsPerPage, setRowsPerPage] = React.useState(5);
//   const [rows, setRows] = useState([]);

//   const handleRequestSort = (event, property) => {
//     const isAsc = orderBy === property && order === "asc";
//     setOrder(isAsc ? "desc" : "asc");
//     setOrderBy(property);
//   };

//   const handleSelectAllClick = (event) => {
//     if (event.target.checked) {
//       const newSelected = rows.map((n) => n.name);
//       setSelected(newSelected);
//       return;
//     }
//     setSelected([]);
//   };

//   const handleClick = (event, name) => {
//     const selectedIndex = selected.indexOf(name);
//     let newSelected = [];

//     if (selectedIndex === -1) {
//       newSelected = newSelected.concat(selected, name);
//     } else if (selectedIndex === 0) {
//       newSelected = newSelected.concat(selected.slice(1));
//     } else if (selectedIndex === selected.length - 1) {
//       newSelected = newSelected.concat(selected.slice(0, -1));
//     } else if (selectedIndex > 0) {
//       newSelected = newSelected.concat(
//         selected.slice(0, selectedIndex),
//         selected.slice(selectedIndex + 1)
//       );
//     }

//     setSelected(newSelected);
//   };

//   const handleChangePage = (event, newPage) => {
//     setPage(newPage);
//   };

//   const handleChangeRowsPerPage = (event) => {
//     setRowsPerPage(parseInt(event.target.value, 10));
//     setPage(0);
//   };

//   const handleChangeDense = (event) => {
//     setDense(event.target.checked);
//   };

//   const createSortHandler = (property) => (event) => {
//     handleRequestSort(event, property);
//   };

//   const isSelected = (name) => selected.indexOf(name) !== -1;

//   // Avoid a layout jump when reaching the last page with empty rows.
//   const emptyRows =
//     page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

//   return (
//     <Box sx={{ width: "100%" }}>
//       <Paper sx={{ width: "100%", mb: 2 }}>
//         <Toolbar
//           sx={{
//             pl: { sm: 2 },
//             pr: { xs: 1, sm: 1 },
//             ...(selected.length > 0 && {
//               bgcolor: (theme) =>
//                 alpha(
//                   theme.palette.primary.main,
//                   theme.palette.action.activatedOpacity
//                 ),
//             }),
//           }}
//         >
//           {selected.length > 0 ? (
//             <Typography
//               sx={{ flex: "1 1 100%" }}
//               color="inherit"
//               variant="subtitle1"
//               component="div"
//             >
//               {selected.length} selected
//             </Typography>
//           ) : (
//             <Typography
//               sx={{ flex: "1 1 100%" }}
//               variant="h6"
//               id="tableTitle"
//               component="div"
//             >
//               Nutrition
//             </Typography>
//           )}

//           {selected.length > 0 ? (
//             <Tooltip title="Delete">
//               <IconButton>
//                 <DeleteIcon />
//               </IconButton>
//             </Tooltip>
//           ) : (
//             <Tooltip title="Filter list">
//               <IconButton>
//                 <FilterListIcon />
//               </IconButton>
//             </Tooltip>
//           )}
//         </Toolbar>
//         <TableContainer>
//           <Table
//             sx={{ minWidth: 750 }}
//             aria-labelledby="tableTitle"
//             size={dense ? "small" : "medium"}
//           >
//             <TableHead>
//               <TableRow>
//                 <TableCell padding="checkbox">
//                   <Checkbox
//                     color="primary"
//                     indeterminate={
//                       selected.length > 0 && selected.length < rows.length
//                     }
//                     checked={rows.length > 0 && selected.length === rows.length}
//                     onChange={handleSelectAllClick}
//                     inputProps={{
//                       "aria-label": "select all desserts",
//                     }}
//                   />
//                 </TableCell>
//                 {recentRequestHeadCells.map((headCell) => (
//                   <TableCell
//                     key={headCell.id}
//                     align={headCell.numeric ? "right" : "left"}
//                     padding={headCell.disablePadding ? "none" : "normal"}
//                     sortDirection={orderBy === headCell.id ? order : false}
//                   >
//                     <TableSortLabel
//                       active={orderBy === headCell.id}
//                       direction={orderBy === headCell.id ? order : "asc"}
//                       onClick={createSortHandler(headCell.id)}
//                     >
//                       {headCell.label}
//                       {orderBy === headCell.id ? (
//                         <Box component="span" sx={visuallyHidden}>
//                           {order === "desc"
//                             ? "sorted descending"
//                             : "sorted ascending"}
//                         </Box>
//                       ) : null}
//                     </TableSortLabel>
//                   </TableCell>
//                 ))}
//               </TableRow>
//             </TableHead>
//             {/* <EnhancedTableHead
//                 numSelected={selected.length}
//                 order={order}
//                 orderBy={orderBy}
//                 onSelectAllClick={handleSelectAllClick}
//                 onRequestSort={handleRequestSort}
//                 rowCount={rows.length}
//               /> */}
//             <TableBody>
//               {/* if you don't need to support IE11, you can replace the `stableSort` call with:
//                      rows.sort(getComparator(order, orderBy)).slice() */}
//               {stableSort(rows, getComparator(order, orderBy))
//                 .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
//                 .map((row, index) => {
//                   const isItemSelected = isSelected(row.name);
//                   const labelId = `enhanced-table-checkbox-${index}`;

//                   return (
//                     <TableRow
//                       hover
//                       onClick={(event) => handleClick(event, row.name)}
//                       role="checkbox"
//                       aria-checked={isItemSelected}
//                       tabIndex={-1}
//                       key={row.name}
//                       selected={isItemSelected}
//                     >
//                       <TableCell padding="checkbox">
//                         <Checkbox
//                           color="primary"
//                           checked={isItemSelected}
//                           inputProps={{
//                             "aria-labelledby": labelId,
//                           }}
//                         />
//                       </TableCell>
//                       <TableCell
//                         component="th"
//                         id={labelId}
//                         scope="row"
//                         padding="none"
//                       >
//                         {row.name}
//                       </TableCell>
//                       <TableCell align="right">{row.calories}</TableCell>
//                       <TableCell align="right">{row.fat}</TableCell>
//                       <TableCell align="right">{row.carbs}</TableCell>
//                       <TableCell align="right">{row.protein}</TableCell>
//                     </TableRow>
//                   );
//                 })}
//               {emptyRows > 0 && (
//                 <TableRow
//                   style={{
//                     height: (dense ? 33 : 53) * emptyRows,
//                   }}
//                 >
//                   <TableCell colSpan={6} />
//                 </TableRow>
//               )}
//             </TableBody>
//           </Table>
//         </TableContainer>
//         <TablePagination
//           rowsPerPageOptions={[5, 10, 25]}
//           component="div"
//           count={rows.length}
//           rowsPerPage={rowsPerPage}
//           page={page}
//           onPageChange={handleChangePage}
//           onRowsPerPageChange={handleChangeRowsPerPage}
//         />
//       </Paper>
//       <FormControlLabel
//         control={<Switch checked={dense} onChange={handleChangeDense} />}
//         label="Dense padding"
//       />
//     </Box>
//   );
// }
