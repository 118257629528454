import React from "react";
import HorizontalStack from "./HorizontalStack";
import { Box, Checkbox } from "@mui/material";
import { SecondaryLabelExtraSmall, SecondaryLabelSmall } from "./Labels";

function CustomCheckbox({
  label,
  subtitle,
  value,
  onChange = () => {},
  containerStyle,
}) {
  return (
    <HorizontalStack
      style={{ cursor: "pointer", ...containerStyle }}
      onClick={() => onChange(!value)}
    >
      <Checkbox
        size="small"
        checked={value}
        onChange={(e) => onChange(!value)}
      />
      <Box>
        <SecondaryLabelSmall>{label}</SecondaryLabelSmall>
        <SecondaryLabelExtraSmall>{subtitle}</SecondaryLabelExtraSmall>
      </Box>
    </HorizontalStack>
  );
}

export default CustomCheckbox;
