import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Fade,
    Grid,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from "@mui/material";
import React, { useState } from "react";
import { EmptyState, dialogStyle, formatDate, getId, getTimeInMilliSeconds, isArrayWithValues, isObjWithValues, settingsEndpoint } from "../../../../helper";
import { Add, DescriptionOutlined } from "@mui/icons-material";
import { Label } from "../../../customers/components/Info";
import { useDispatch, useSelector } from "react-redux";
import { EDIT_SETTINGS } from "../../../../redux/actions/settingActions";
import { setGlobalToast } from "../../../../redux/actions/helperActions";
import { LoadingButton } from "@mui/lab";

const Template = () => {
    const [openForm, setOpenForm] = useState(false);
    const allTemplates = useSelector(
        (state) => state.settings.exotelIntegration
    )?.templates || [];
    return (
        <Box px={5}>
            <Stack direction="row" spacing={2} justifyContent={"space-between"} sx={{ mb: 3 }}>
                <Typography variant="h6">Sms Templates</Typography>
                <Button
                    onClick={() => {
                        setOpenForm(true);
                    }}
                    variant="outlined"
                    startIcon={<Add />}
                >
                    Add Template
                </Button>
            </Stack>
            <SmsTemplate rows={allTemplates || []} />
            <AddSmsTemplate
                open={openForm}
                handleClose={() => {
                    setOpenForm(false);
                }}
            />
        </Box>
    );
};

export default Template;

export const SmsTemplate = ({ rows, fetching }) => {
    return (
        <TableContainer>
            {fetching ? (
                <Stack
                    justifyContent={"center"}
                    alignItems={"center"}
                    sx={{ height: "30vh" }}
                    gap={2}
                >
                    <CircularProgress />
                    <Typography variant="h6">Getting logs...</Typography>
                </Stack>
            ) : isArrayWithValues(rows) ? (
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Sr.</TableCell>
                            <TableCell>Template Name</TableCell>
                            <TableCell>Template ID</TableCell>
                            <TableCell>Sms Template</TableCell>
                            <TableCell>Status</TableCell>

                            <TableCell>Date</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows?.map((row, index) => (
                            <TableRow
                                key={index}
                                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    {index + 1}
                                </TableCell>
                                <TableCell>

                                    {row.template_name}

                                </TableCell>
                                <TableCell>{row.dtl_template_id}</TableCell>
                                <TableCell>
                                    <Typography noWrap sx={{ maxWidth: "200px" }}>

                                        {row.body}
                                    </Typography>

                                </TableCell>
                                <TableCell>{row?.status}</TableCell>
                                <TableCell>{formatDate(new Date(row?.time), "dd mm yyyy")}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            ) : (
                <EmptyState
                    text={"No template Added Yet!"}
                    icon={
                        <DescriptionOutlined
                            style={{ fontSize: "100px" }}
                            color={"action"}
                        />
                    }
                />
            )}
        </TableContainer>
    );
};

const feilds = [
    {
        label: "Template Name",
        value: "template_name",
        girdProps: {
            sx: 12,
            md: 6,
        },
    },
    {
        label: "DLT template Id",
        value: "dtl_template_id",
        girdProps: {
            sx: 12,
            md: 6,
        },
    },
    {
        label: "Sms Template",
        value: "body",
        girdProps: {
            sx: 12,
            md: 12,
        },
        props: {
            multiline: true,
            maxRows: 3,
        },
    },
];
const AddSmsTemplate = ({ handleClose, open }) => {
    const [values, setValues] = useState({});

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const isAdmin = useSelector((state) => state.user.isAdmin);
    const onChangeHandler = (payload) => {
        setValues((pre) => ({ ...pre, ...payload }));
        setError(false)
    };
    const allTemplates = useSelector(
        (state) => state.settings.exotelIntegration
    )?.templates || [];
    const dispatch = useDispatch();

    async function onSubmit() {
        if (!values.template_name || !values.dtl_template_id || !values.body) {
            setError(pre => ({ ...pre, errorMessage: "Name,Dtl Template ID , Body required" }))
            return;
        }

        if (allTemplates?.find((template) => template.template_name === values.template_name)) {
            setError(pre => ({ ...pre, template_name: "Template name already exists" }))
            return;
        }
        if (allTemplates?.find((template) => template.dtl_template_id === values.dtl_template_id)) {
            setError(pre => ({ ...pre, template_name: "Template ID already exists" }))
            return;
        }
        if (allTemplates?.find((template) => template.body === values.body)) {
            setError(pre => ({ ...pre, body: "Template ID already exists" }))
            return;
        }
        setLoading(true)
        let res = await settingsEndpoint({
            endpoint: "exotel_integration",
            method: "PUT",
            data: {
                templates: [...allTemplates,

                {
                    ...values,
                    time: getTimeInMilliSeconds(),
                    isAdmin,
                    created_by: getId()

                }
                ],
            },
        });

        if (res) {
            await dispatch({
                type: EDIT_SETTINGS,
                payload: {
                    exotelIntegration: res,
                },
            });
            dispatch(setGlobalToast({
                show: true,
                message: "Template Added Sucessfully",
                severity: "success"
            }))
        }
        setLoading(false);

        handleClose();
    }
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{ ...dialogStyle }}
        >
            <DialogTitle id="alert-dialog-title">Add Template</DialogTitle>
            <DialogContent>
                <Grid container spacing={3} rowSpacing={4}>
                    {feilds?.map((feild) => {
                        return (
                            <Grid
                                item
                                xs={12}
                                {...(feild?.girdProps || {})}
                                key={feild.value}

                            >
                                <Label>{feild.label}</Label>
                                <TextField
                                    size="small"
                                    fullWidth
                                    value={values[feild.value]}
                                    error={error?.[feild.value]}
                                    onChange={(e) =>
                                        onChangeHandler({ [feild.value]: e.target.value })
                                    }
                                    {...(isObjWithValues(feild?.props) ? feild?.props : {})}
                                    helperText={error?.[feild.value] || ""}
                                />
                            </Grid>

                        );
                    })}
                    <Grid item xs={12}>
                        <Fade in={error?.errorMessage}>

                            <Typography color={"error"} >{error?.errorMessage}</Typography>
                        </Fade>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <LoadingButton loading={loading} onClick={onSubmit} variant="contained" autoFocus>
                    Add
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};
