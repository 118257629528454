export const conditionButtons = [
  {
    id: "BUTTON_1",
    label: "= equals",
    value: "equal_to",
    boxLabel: "equal to",
  },
  {
    id: "BUTTON_2",
    label: "≠ isn't equal",
    value: "not_equal_to",
    boxLabel: "is'nt equal",
  },
  {
    id: "BUTTON_3",
    label: "contains",
    value: "contains",
    boxLabel: "contains",
  },
  {
    id: "BUTTON_4",
    label: "doesn't contain",
    value: "not_contains",
    boxLabel: "doesn't contain",
  },
  {
    id: "BUTTON_5",
    label: "starts with",
    value: "starts_with",
    boxLabel: "starts with",
  },
  {
    id: "BUTTON_6",
    label: "ends with",
    value: "ends_with",
    boxLabel: "ends with",
  },
  {
    id: "BUTTON_7",
    label: "is empty",
    value: "is_empty",
    boxLabel: "is empty",
  },
  {
    id: "BUTTON_10",
    label: "< is less than",
    value: "is_less_than",
    boxLabel: "is less than",
  },
  {
    id: "BUTTON_11",
    label: "> is greater than",
    value: "is_greater_than",
    boxLabel: "is greater than",
  },
];
