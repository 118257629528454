import {
  Box,
  Button,
  ButtonGroup,
  Card,
  Grid,
  MenuItem,
  Stack,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { sidebarExtraWidth } from "../../../App";
import {
  formatServerValue,
  getUserType,
  hasOnlyProduct,
  isObjWithValues,
  validateNumber,
} from "../../../helper";
import { VisitorList } from "./helper";

const defaultState =
  getUserType() === "supplier"
    ? {
        Catalogs: [],
        Website: [],
        Quotation: [],
        sales_app: [],
      }
    : {
        Catalogs: [],
        Website: [],
        sales_app: [],
      };
const LiveVisitorListview = ({
  chartDataOption,
  currentVisitors,
  allLiveVisitors,
  isIpad,
  setChartDataOption,
}) => {
  let height = 195;
  const theme = useTheme();

  let isPhone = useMediaQuery(theme.breakpoints.down("sm"));
  const [listData, setListData] = useState(defaultState);
  const [listDataPhone, setListDataPhone] = useState("");

  const user_products = useSelector(
    (state) => state.user?.store_plan?.user_products
  );
  useMemo(() => {
    if (chartDataOption === "time_spent") {
      setListData(
        currentVisitors || {
          0: [],
          1: [],
          5: [],
          10: [],
        }
      );
    }
    if (chartDataOption === "module") {
      getUserType() === "supplier"
        ? setListData({
            Catalogs:
              allLiveVisitors?.filter((visi) =>
                visi?.events?.[0]?.type?.includes("catalog")
              ) || [],
            Website:
              allLiveVisitors?.filter((visi) =>
                visi?.events?.[0]?.type?.includes("website")
              ) || [],
            Quotation:
              allLiveVisitors?.filter((visi) =>
                visi?.events?.[0]?.type?.includes("quotation")
              ) || [],
            sales_app:
              allLiveVisitors?.filter((visi) =>
                visi?.events?.[0]?.type?.includes("sales_app")
              ) || {},
          })
        : setListData({
            Catalogs:
              allLiveVisitors?.filter((visi) =>
                visi?.events?.[0]?.type?.includes("catalog")
              ) || [],
            Website:
              allLiveVisitors?.filter((visi) =>
                visi?.events?.[0]?.type?.includes("website")
              ) || [],
            sales_app:
              allLiveVisitors?.filter((visi) =>
                visi?.events?.[0]?.type?.includes("sales_app")
              ) || [],
          });
    }
    // console.log(chartDataOption);
  }, [chartDataOption, currentVisitors]);

  return (
    <Box sx={{ height: `calc(100vh - ${height}px)`, mt: 2 }}>
      {isPhone && (
        <Stack
          direction={"row"}
          alignItems="center"
          justifyContent={"space-between"}
        >
          <TextField
            select
            size="small"
            sx={{ minWidth: "150px" }}
            value={listDataPhone}
            onChange={(e, newValue) => {
              // console.log(e?.target?.value, newValue);
              setListDataPhone(e?.target?.value);
            }}
          >
            {Object.keys(listData)?.map((valKey) => (
              <MenuItem key={valKey} value={valKey}>
                {isNaN(Number(formatServerValue(valKey || "")))
                  ? formatServerValue(valKey || "")
                  : `Greater then ${valKey} ${
                      validateNumber(valKey) ? "min" : "sec"
                    }`}
              </MenuItem>
            ))}
          </TextField>
          {!hasOnlyProduct("catalog", user_products) && (
            <ButtonGroup
              variant="outlined"
              aria-label="outlined primary button group"
              size="small"
            >
              <Button
                variant={
                  chartDataOption === "time_spent" ? "contained" : "outlined"
                }
                onClick={() => setChartDataOption("time_spent")}
              >
                Time spent
              </Button>
              <Button
                variant={
                  chartDataOption === "module" ? "contained" : "outlined"
                }
                onClick={() => setChartDataOption("module")}
              >
                Module
              </Button>
            </ButtonGroup>
          )}
        </Stack>
      )}
      {!isPhone && (
        <Stack
          direction={"row"}
          alignItems={"center"}
          gap={4}
          className="scrollbar-hidden"
          sx={{
            overflow: "auto",
            width: `calc(100vw - ${sidebarExtraWidth + 50}px)`,
          }}
        >
          {isObjWithValues(listData)
            ? Object.keys(listData)?.map((objKey) => {
                return (
                  <VisitorList
                    cardStyle={{
                      height: `calc(100vh - ${height}px)`,
                      overflow: "auto",
                      borderRadius: "14px",
                      border: "0.5px solid #e5eef8",
                      minWidth: "350px",
                    }}
                    hoverEffect={true}
                    visitors={listData?.[objKey]}
                    heading={
                      isNaN(Number(formatServerValue(objKey || "")))
                        ? formatServerValue(objKey || "")
                        : `Greater then ${objKey} ${
                            validateNumber(objKey) ? "min" : "sec"
                          }`
                    }
                  />
                );
              })
            : null}
        </Stack>
      )}
      {isPhone && (
        <Grid container mt={2.5}>
          <Grid item xs={12}>
            <VisitorList
              cardStyle={{
                height: `calc(100vh - ${height}px)`,
                overflow: "auto",
                borderRadius: "14px",
                border: "0.5px solid #e5eef8",
                minWidth: "300px",
              }}
              hoverEffect={true}
              visitors={listData?.[listDataPhone]}
              heading={
                isNaN(Number(formatServerValue(listDataPhone || "")))
                  ? formatServerValue(listDataPhone || "")
                  : `Greater then ${listDataPhone} "min`
              }
            />
          </Grid>
        </Grid>
      )}
      {/* <Grid container spacing={5}>
                <Grid item xs={12} md={6} lg={4}>

                    <VisitorList
                        cardStyle={{
                            height,
                            overflow: "auto",
                            borderRadius: "14px",
                            border: "0.5px solid #e5eef8",
                        }}
                        hoverEffect={true}
                        visitors={Object.values(allOnlineVisitors || {})}
                        heading={`Website`}
                    />

                </Grid>
                <Grid item xs={12} md={6} lg={4}>

                    <VisitorList
                        cardStyle={{
                            height,
                            overflow: "auto",
                            borderRadius: "14px",
                            border: "0.5px solid #e5eef8",
                        }}
                        hoverEffect={true}
                        visitors={Object.values(allOnlineVisitors || {})}
                        heading={`Catalogs`}
                    />

                </Grid>
                <Grid item xs={12} md={6} lg={4}>

                    <VisitorList
                        cardStyle={{
                            height,
                            overflow: "auto",
                            borderRadius: "14px",
                            border: "0.5px solid #e5eef8",
                        }}
                        hoverEffect={true}
                        visitors={Object.values(allOnlineVisitors || {})}
                        heading={`Quotations`}
                    />

                </Grid>
            </Grid> */}
    </Box>
  );
};

export default LiveVisitorListview;
