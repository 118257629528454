import { useState, useRef } from "react";
import { Typography, CardContent, Button } from "@mui/material";
import { buttonFieldStyles } from "../utils/chatbotFieldsStyles";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../../../redux/actions/flowsActions";
import { saveFlow } from "./SaveFlow";
import { format } from "date-fns";
import ProductCatalogPopover from "./ProductCatalogPopover";
import ReplyAllIcon from "@mui/icons-material/ReplyAll";

const styles = buttonFieldStyles;

const BotButtonsMessage = ({ message, data }) => {
  const dispatch = useDispatch();
  const popoverRef = useRef();
  const prevNode = useSelector((state) => state.flow?.chatbotMessageResponse);
  const [prodCtgAnchorEl, setProdCtgAnchorEl] = useState(null);
  const sendTime = (currTime) => {
    const formattedTime = format(currTime, "HH:mm");
    return formattedTime;
  };

  const normalizeURL = (url) => {
    if (url.startsWith("http://") || url.startsWith("https://")) {
      return url;
    } else {
      if (!url.startsWith("www.")) {
        return "http://www." + url;
      } else {
        return "http://" + url;
      }
    }
  };

  const handleClick = async (button) => {
    if (button.type === "send_message") {
      const newMessage = {
        id: Date.now(),
        text: button.title,
        isUser: true,
        time: sendTime(Date.now()),
      };
      dispatch({ type: actions.TEST_BOT_ADD_MESSAGES, payload: newMessage });
      await saveFlow({
        flow_data: data,
        user_response: button.title,
        prev_node: prevNode,
      });
    }

    if (button.type === "phone_number") {
      const newMessage = {
        id: Date.now(),
        text: `tel: ${button.number}`,
        isUser: true,
        time: sendTime(Date.now()),
      };
      dispatch({ type: actions.TEST_BOT_ADD_MESSAGES, payload: newMessage });
      await saveFlow({
        flow_data: data,
        user_response: button.title,
        prev_node: prevNode,
      });
    }

    if (button.type === "open_url") {
      const url = normalizeURL(button.url);
      window.open(url, "_blank");
    }

    if (button.type === "go-to-block") {
      const url = normalizeURL(button.url);
      window.open(url, "_blank");
    }
  };

  const handleProdCtgBtn = async (value) => {
    setProdCtgAnchorEl(null);
    // setProdCtgAnchorEl(null);
    const newMessage = {
      id: Date.now(),
      text: value,
      isUser: true,
      time: sendTime(Date.now()),
    };
    dispatch({ type: actions.TEST_BOT_ADD_MESSAGES, payload: newMessage });
    await saveFlow({
      flow_data: data,
      user_response: value,
      prev_node: prevNode,
    });
  };

  const openProdCtgPopover = (e) => {
    setProdCtgAnchorEl(e.currentTarget);
  };

  return (
    <>
      <CardContent key={Date.now()} style={styles.buttonCard} ref={popoverRef}>
        <Typography style={styles.buttonTextField}>
          {message?.description || message?.title}
        </Typography>
        {message?.button_type === "product-catalog" ? (
          <>
            <div style={styles.buttonDiv} onClick={openProdCtgPopover}>
              <Button
                style={styles.button}
                variant="outlined"
                startIcon={<ReplyAllIcon />}
              >
                Choose one
              </Button>
            </div>
          </>
        ) : (
          <div style={styles.buttonDiv}>
            {message?.buttons?.map((button) => (
              <Button
                style={styles.button}
                key={button.id}
                variant="outlined"
                onClick={() => handleClick(button)}
              >
                {button.title}
              </Button>
            ))}
          </div>
        )}
      </CardContent>
      <ProductCatalogPopover
        products={message.buttons}
        prodCtgAnchorEl={prodCtgAnchorEl}
        setProdCtgAnchorEl={setProdCtgAnchorEl}
        handleProdCtgBtn={handleProdCtgBtn}
      />
    </>
  );
};

BotButtonsMessage.propTypes = {
  message: PropTypes.object.isRequired,
};

export default BotButtonsMessage;
