import { Add } from "@mui/icons-material";
import { Button, Card, CardContent, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  dashboardCardSx,
  isObjWithValues,
  sectionLabel,
} from "../../../helper";
import { getCampaignHistory } from "../../../redux/actions/chatActions";
import { EmailCampaignList } from "../../customers/components/CampaignHistory";
import EmailTemplate from "../../customers/components/EmailTemplate";

const RecentCampaign = () => {
  const dispatch = useDispatch();
  const [emailOpen, setEmailOpen] = useState(false);
  const [rows, setRows] = useState(false);
  const campaignHistory = useSelector((state) => state.chats.campaignHistory);
  const getttingCampaignHistory = useSelector(
    (state) => state.chats.getttingCampaignHistory
  );

  useEffect(() => {
    if (isObjWithValues(campaignHistory))
      setRows(Object.values(campaignHistory)?.filter((i) => i.is_campaign));
  }, [campaignHistory]);
  const campaignHistoryFetched = useSelector(
    (state) => state.chats.campaignHistoryFetched
  );
  useEffect(() => {
    if (!isObjWithValues(campaignHistory)) dispatch(getCampaignHistory());
  }, []);
  return (
    <>
      <EmailTemplate
        setEmailOpen={setEmailOpen}
        emailOpen={emailOpen}
        // selected={selected}
        // setSelected={setSelected}
        // withFilters={withFilters}
        // setWithFilters={setWithFilters}
        // openCampaignHistory={openCampaignHistory}
        // setOpenCampaignHistory={setOpenCampaignHistory}
      />
      <Card sx={{ ...dashboardCardSx }}>
        <CardContent>
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
            flexWra={"wrap"}
          >
            <Typography sx={{ ...sectionLabel }}>
              Recent campaigns sent
            </Typography>
            <Button
              size="small"
              variant="contained"
              startIcon={<Add />}
              onClick={() => {
                setEmailOpen(true);
              }}
            >
              New Campaign
            </Button>
          </Stack>

          <EmailCampaignList
            rows={rows}
            fetched={campaignHistoryFetched}
            fetching={getttingCampaignHistory}
          />
        </CardContent>
      </Card>
    </>
  );
};

export default RecentCampaign;
