export const optionPopoverStyles = {
  outerBox: {
    display: "flex",
    flexDirection: "column",
    fontFamily: "Inter",
    width: "100px",
    padding: "10px 4px 10px 5px",
  },
  title: {
    padding: "5px 10px 5px 10px",
    color: "#22272d",
    fontSize: "15px",
    "&:hover": {
      backgroundColor: "#ebebeb",
    },
  },
};
