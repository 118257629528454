import { Box, Typography } from "@mui/material";
import React from "react";

const Overview = () => {
    return (
        <Box sx={{ p: 3, mb: 3 }}>
            <Typography variant="subtitle1" sx={{ fontSize: "16px", mb: 4 }}>
                Using AR and AI, JewelTryStudio provides a fully immersive purchasing
                experience. They provide jewellers with a cutting-edge virtual jewellery
                studio to educate, engage, and boost conversion rates 🌟
            </Typography>
            <Typography variant="subtitle1" sx={{ fontSize: "16px", mb: 4 }}>
                The solution is available on both the web and mobile, it gets easily
                integrated with the website in a matter of minutes 💡
            </Typography>
            <Typography variant="subtitle1" sx={{ fontSize: "16px", mb: 4 }}>
                Jewellers selling on e-commerce websites can provide virtual assistance
                instantly to their customers 💍
            </Typography>
            <Typography variant="subtitle1" sx={{ fontSize: "16px", mb: 4 }}>
                Before making a purchase, customers may quickly get a feel for the
                jewellery 📸
            </Typography>
        </Box>
    );
};

export default Overview;
