/* eslint-disable import/no-anonymous-default-export */
import {
  isArrayWithValues,
  mainSocketEvents,
  validateNumber,
} from "../../helper";
import {
  ADD_VISITOR,
  REMOVE_VISITOR,
  SET_VISITOR_DATA,
} from "../actions/visitorActions";

const inititalState = {
  allOnlineVisitors: {},
};

// let customerInAudio = new Audio("/static/sound/customer_in.mp3");
// let customerOutAudio = new Audio("/static/sound/customer_out.mp3");

const visitorReducers = (state = inititalState, { type, payload }) => {
  switch (type) {
    case SET_VISITOR_DATA:
      return { ...state, ...payload };
    case ADD_VISITOR:
      console.log(payload, "payload in reducer");
      let { allOnlineVisitors } = state;
      let { user_id } = payload;
      let date = new Date();
      let todayDate = date.toISOString().substring(0, 10);
      date = date.setDate(date.getDate() - 1);
      date = new Date(date);
      let yesterdayDate = date.toISOString().substring(0, 10);
      console.log(allOnlineVisitors[user_id], "1");
      if (!allOnlineVisitors[user_id]) {
        // play sound here
        try {
          if (
            mainSocketEvents.includes(payload.type) &&
            !payload.visitor_audio_mute
          ) {
            // customerInAudio.play();
          }
        } catch (error) {}
        if (!payload.force) {
          console.log(
            {
              [user_id]: [{ ...payload }],
            },
            "alsdfjalsfdkjalsdfj"
          );
          return {
            ...state,
            allOnlineVisitors: {
              ...state.allOnlineVisitors,
              [user_id]: [{ ...payload }],
            },
          };
        }
        return state;
      } else {
        let array = allOnlineVisitors[user_id];
        // console.log(array, "1");
        // following variable if
        let payloadAdded = false;
        if (isArrayWithValues(array) || true) {
          // for (let i = array.length - 1; i >= 0; i--) {
          for (let i = 0; i < array.length; i++) {
            let obj = array[i];
            let eventDate = new Date(validateNumber(obj.time) * 1000);
            eventDate = eventDate.toISOString().substring(0, 10);
            if (eventDate === todayDate || eventDate === yesterdayDate) {
              // if for e.g design bank is open and product is also open and user directly closes then following will close the product also
              if (payload.force && !mainSocketEvents.includes(obj.type)) {
                array[i] = {
                  ...obj,
                  online: false,
                  end_time: new Date().getTime() / 1000,
                };
                // if (!payload.visitor_audio_mute) customerOutAudio.play();
              }
              //following is for closing main events
              if (
                obj.id === payload.id ||
                (payload.force && mainSocketEvents.includes(obj.type))
              ) {
                array[i] = {
                  ...obj,
                  online: false,
                  end_time: new Date().getTime() / 1000,
                };
                payloadAdded = true;
                // if (
                //   mainSocketEvents.includes(obj.type) &&
                //   !payload.visitor_audio_mute
                // )
                //   customerOutAudio.play();
                break;
              }
              if (
                mainSocketEvents.includes(obj.type) &&
                mainSocketEvents.includes(payload.type) &&
                obj.online &&
                payload.online &&
                obj.time - payload.time < 20
              ) {
                payloadAdded = true;
                break;
              }
            } else {
              // if (
              //   (!payload.online || payload.online === "false") &&
              //   !payload.visitor_audio_mute
              // )
              //   customerOutAudio.play();
              // else
              // if (
              //   mainSocketEvents.includes(payload.type) &&
              //   payload.online &&
              //   !payload.visitor_audio_mute
              // ) {
              //   // customerInAudio.play();
              // }
              array.unshift(payload);
              payloadAdded = true;
              break;
            }
          }
          if (
            allOnlineVisitors[user_id].length === array.length &&
            !payloadAdded
          ) {
            // if (
            //   (!payload.online || payload.online === "false") &&
            //   !payload.visitor_audio_mute
            // )
            //   customerOutAudio.play();
            // else
            if (
              mainSocketEvents.includes(payload.type) &&
              payload.online &&
              !payload.visitor_audio_mute
            ) {
              // customerInAudio.play();
            }
            array.unshift(payload);
          }
        }
        // console.log(array, "2");
        console.log(array, "<<<< vistors info array");
        return {
          ...state,
          allOnlineVisitors: {
            ...state.allOnlineVisitors,
            [user_id]: array,
          },
        };
      }

    // if (!allOnlineVisitors[user_id]) {
    //   //play sound here
    //   return {
    //     ...state,
    //     allOnlineVisitors: {
    //       ...state.allOnlineVisitors,
    //       [user_id]: { [currentDate]: [{ ...payload }] },
    //     },
    //   };
    // } else {
    //   let userObj = { ...state.allOnlineVisitors[user_id] };
    //   if (isArrayWithValues(userObj[currentDate])) {
    //     let array = userObj[currentDate];
    //     if (array.find((i) => i.id === payload.id))
    //       userObj[currentDate] = array.map((i) =>
    //         i.id === payload.id ? { ...i, online: false } : i
    //       );
    //     else userObj[currentDate].push(payload);
    //   } else userObj[currentDate] = [{ ...payload }];
    //   return {
    //     ...state,
    //     allOnlineVisitors: { ...state.allOnlineVisitors, [user_id]: userObj },
    //   };
    // }
    case REMOVE_VISITOR:
      return {
        ...state,
        allVisitors: state.allVisitors.filter((i) => i.username !== payload),
      };
    default:
      return state;
  }
};

export default visitorReducers;

// {
//   "success": true,
//   "data": [
//     {
//       "3": [
//         {
//           "id": "1657059707",
//           "online": "true",
//           "time": "1657059707",
//           "type": "catalog",
//           "catalog_name": "Unique Collections",
//           "user_id": "3",
//           "user_name": "Akshay Pagare",
//           "ua": "Mozilla/5.0 (iPhone; CPU iPhone OS 14_6 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/14.1.1 Mobile/15E148 Safari/604.1",
//           "ip": "49.36.105.46"
//         },
//         {
//           "id": "1657075521",
//           "online": "true",
//           "time": "1657075521",
//           "type": "catalog",
//           "catalog_name": "Unique Collections",
//           "user_id": "3",
//           "user_name": "Akshay Pagare",
//           "ua": "Mozilla/5.0 (Linux; Android 10; M2004J19PI) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/103.0.0.0 Mobile Safari/537.36",
//           "ip": "106.193.166.112"
//         }
//       ]
//     },
//     {
//       "1": [
//         {
//           "id": "1657034027",
//           "online": "true",
//           "time": "1657034027",
//           "type": "catalog",
//           "catalog_name": "test123",
//           "user_id": "1",
//           "user_name": "Test ",
//           "ua": "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/103.0.0.0 Safari/537.36",
//           "ip": "115.96.77.127"
//         }
//       ]
//     }
//   ]
// }
