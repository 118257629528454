import { Add, ArrowBackIosNew, Person } from "@mui/icons-material";
import {
  Box,
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
import { isArray, isArrayWithValues, isObjWithValues } from "../../helper";
import { getDropdownData } from "../../redux/actions/persistActions";
import { getAllBusinesses } from "../../redux/actions/supplierActions";
import { Facebook, Whatsapp } from "../../Svgs";
import { Profile } from "./ProfilePage";

const NewSupplier = () => {
  const theme = useTheme();
  const isIpad = useMediaQuery(theme.breakpoints.down("md"));
  const allSuppliers = useSelector((state) => state.supplier.allSuppliers);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [supplier, setSupplier] = useState({});
  // console.log(allBusinesses, "<<<all");
  useEffect(() => {
    if (!isArrayWithValues(allSuppliers)) dispatch(getAllBusinesses());
  }, []);
  useEffect(() => {
    setSupplier(allSuppliers?.find((obj) => obj?.id === id) || {});
  }, [allSuppliers, id]);
  useEffect(() => {
    let productsData = {
      goldJewellery: {
        total: 0,
        instock: 0,
        onbackorder: 0,
      },
      silverJewellery: {
        total: 0,
        instock: 0,
        onbackorder: 0,
      },
      diamondJewellery: {
        total: 0,
        instock: 0,
        onbackorder: 0,
      },
    };
    if (isObjWithValues(supplier) && isObjWithValues(supplier?.filters)) {
      for (const product of Object.values(supplier?.filters || {})) {
        if (product?.collections?.includes("diamond")) {
          if (product?.stock_status)
            productsData.diamondJewellery[product?.stock_statu] += 1;
        }

        if (product?.collections?.includes("gold")) {
          if (product?.stock_status)
            productsData.goldJewellery[product?.stock_statu] += 1;
        }

        if (product?.collections?.includes("silver")) {
          if (product?.stock_status)
            productsData.silverJewellery[product?.stock_statu] += 1;
        }
      }
    }
  }, [supplier]);

  return (
    <Box padding={isIpad ? 0 : "20px"}>
      <Stack
        direction={"row"}
        justifyContent="space-between"
        alignItems={"center"}
      >
        <Box>
          <Stack direction={"row"} alignItems="center">
            <IconButton onClick={() => navigate(-1)}>
              <ArrowBackIosNew />
            </IconButton>
            <Typography variant="h3" display="inline">
              Supplier Profile
            </Typography>
          </Stack>
          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/design-bank">
              Dashboard
            </Link>
            <Link component={NavLink} to="/design-bank/suppliers">
              Suppliers
            </Link>
            <Typography>Suppliers Profile</Typography>
          </Breadcrumbs>
        </Box>
        <Button variant="outlined" startIcon={<Add />}>
          Invite Supplier
        </Button>
      </Stack>
      <Card sx={{ mt: 5 }}>
        <CardContent>
          <Profile supplier={supplier} />
        </CardContent>
      </Card>
      <ProfileInfo supplier={supplier} />
      <NewSupplierFootor supplier={supplier} />
    </Box>
  );
};

export default NewSupplier;

const ProfileInfo = ({ supplier }) => {
  const countries = useSelector((state) => state.persist.countries);
  const states = useSelector((state) => state.persist.states);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!isArrayWithValues(countries)) dispatch(getDropdownData());
  }, []);

  console.log(supplier);
  return (
    <Box sx={{ mt: 5 }}>
      <Grid container spacing={4} rowSpacing={7}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <AboutCompany supplier={supplier} />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={6}>
          <Card sx={{ height: "100%" }}>
            <CardContent>
              <Typography variant="h6" mt={2}>
                About company
              </Typography>
              <Grid container rowSpacing={3} spacing={3} mt={1}>
                <Grid item xs={12}>
                  <TextField
                    label="Address"
                    fullWidth
                    value={`${supplier?.address?.address_line_1 || ""} ,${
                      supplier?.address?.address_line_2 || ""
                    }`}
                    rows={2}
                    multiline
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    label="City"
                    fullWidth
                    value={supplier?.address?.city}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    label="State"
                    fullWidth
                    value={
                      states?.[
                        supplier?.address?.["country:state"]?.split(":")?.[0]
                      ]?.find(
                        (obj) =>
                          obj.code ===
                          supplier?.address?.["country:state"]?.split(":")?.[1]
                      )?.label
                    }
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    label="Country"
                    fullWidth
                    value={
                      countries?.find(
                        (obj) =>
                          obj.code ===
                          supplier?.address?.["country:state"]?.split(":")?.[0]
                      )?.label || ""
                    }
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    label="Zip code"
                    fullWidth
                    value={supplier?.address?.pincode}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={6}>
          <Card sx={{ height: "100%" }}>
            <CardContent>
              <Typography variant="h6" mt={2}>
                Contact Person
              </Typography>
              <Grid container rowSpacing={3} spacing={3} mt={1}>
                <Grid item xs={12}>
                  <Stack direction={"row"} alignItems="center">
                    <Person />
                    <Typography>Person 1</Typography>
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Name"
                    fullWidth
                    value={supplier?.contact_name || ""}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField label="Salutation" fullWidth />
                </Grid>
                <Grid item xs={6}>
                  <TextField label="Designation" fullWidth />
                </Grid>
                <Grid item xs={6}>
                  <TextField label="Whatsapp no." fullWidth />
                </Grid>
                <Grid item xs={6}>
                  <TextField label="Mobile no." fullWidth />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Email id"
                    fullWidth
                    value={supplier?.email || ""}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography variant="h6" mt={2}>
                Other details{" "}
              </Typography>
              <Grid container rowSpacing={3} spacing={3} mt={1}>
                <Grid item xs={2}>
                  <TextField fullWidth label="No. of employees" />
                </Grid>
                <Grid item xs={2}>
                  <TextField fullWidth label="Annual turnover" />
                </Grid>
                <Grid item xs={2}>
                  <TextField fullWidth label="Member of" />
                </Grid>
                <Grid item xs={2}>
                  <TextField fullWidth label="Supply loaction" />
                </Grid>
                <Grid item xs={4}>
                  <TextField fullWidth label="Bank name" />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

const AboutCompany = ({ supplier }) => {
  return (
    <Grid container spacing={4}>
      <Grid item xs={12} mt={2} md={6}>
        <Typography variant="h6">About company</Typography>
        <Grid container rowSpacing={3} spacing={3} mt={1}>
          <Grid item md={4}>
            <TextField fullWidth disabled label="Billing A/c name" />
          </Grid>
          <Grid item md={4}>
            <TextField fullWidth disabled label="GSTIN" />
          </Grid>
          <Grid item md={4}>
            <TextField fullWidth disabled label="Ownership type" />
          </Grid>
          <Grid item md={10}>
            <TextField fullWidth disabled label="Products categories" />
          </Grid>
          <Grid item md={2}>
            <TextField fullWidth disabled label="Ested.in" />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} mt={2} md={6}>
        <Typography variant="h6">Contact details</Typography>
        <Grid container rowSpacing={3} spacing={3} mt={1}>
          <Grid item md={6}>
            <TextField fullWidth label="Landline no." />
          </Grid>
          <Grid item md={6}>
            <TextField fullWidth label="Whatsapp no." />
          </Grid>
          <Grid item md={6}>
            <TextField fullWidth label="Email" value={supplier?.email || ""} />
          </Grid>
          <Grid item md={6}>
            <TextField fullWidth label="Website" value={supplier?.url || ""} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const NewSupplierFootor = ({ supplier }) => {
  return (
    <Card sx={{ my: 7 }}>
      <CardContent>
        <Typography variant="h6">Marketing network</Typography>
        <Grid container mt={4}>
          <Grid item xs={12} md={5}>
            <Typography fontWeight={500}>Supplier active on</Typography>
            <Stack
              direction={"row"}
              gap={"20px"}
              sx={{ my: 4 }}
              alignItems="center"
            >
              <Whatsapp height={35} width={35} />
              <Facebook height={30} width={30} />
            </Stack>
          </Grid>
          <Grid item xs={12} md={7}>
            <Typography fontWeight={500}>Products and brands</Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
