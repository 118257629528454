export const buttonPopoverStyles = {
  buttonsFieldButton: {
    color: "#008aff",
    textTransform: "none",
    borderTop: "black",
  },
  quickReplyButton: {
    backgroundColor: "white",
    borderRadius: "20px",
    textTransform: "none",
    fontWeight: "800px",
    marginRight: "8px",
    marginBottom: "5px",
  },
  deleteIcon: {
    position: "absolute",
    top: "-4px",
    right: "7px",
    fontSize: "16px",
    cursor: "pointer",
    color: "#008aff",
    backgroundColor: "#fff",
    borderRadius: "50%",
    padding: "3px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)",
    "&:hover": {
      backgroundColor: "#008aff",
      color: "#fff",
    },
  },
  galleryFieldButton: {
    boxShadow: 0,
    margin: 0,
    padding: 0,
    minHeight: 0,
    minWidth: 0,
    height: "40px",
    color: "#008aff",
    borderRadius: "0px",
    backgroundColor: "#fff",
    textTransform: "none",
    fontSize: "15px",
    fontWeight: "800px",
    borderBottom: "1.5px solid rgb(205 195 195)",
  },
};
