import { Button, useMediaQuery } from "@mui/material";
import React from "react";
import { EmptyState } from "../../helper";
import CampaignFlowBuilder from ".";
import { useNavigate } from "react-router-dom";
import { MobileView } from "../../Svgs";

const CampaignFlow = () => {
  const navigate = useNavigate();
  const isIpad = useMediaQuery((theme) => theme.breakpoints.down("md"));
  if (isIpad)
    return (
      <>
        <EmptyState
          heading={"Couldn't Support Mobile View !"}
          icon={<MobileView height={300} width={300} />}
          button={
            <Button variant="contained" onClick={() => navigate(`/campaign`)}>
              Go to Home
            </Button>
          }
        />
      </>
    );
  return (
    <>
      <CampaignFlowBuilder />
    </>
  );
};

export default CampaignFlow;
