import { ErrorOutline, Visibility, VisibilityOff } from "@material-ui/icons";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Card,
  Chip,
  Divider,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getToken, getWebsite, isObjWithValues, TabPanel } from "../../helper";
import { setGlobalToast } from "../../redux/actions/helperActions";
import { EDIT_SETTINGS } from "../../redux/actions/settingActions";

const Email = () => {
  return (
    <Box>
      <EmailSetup />
    </Box>
  );
};

export default Email;

const smtpEampales = [
  { label: "Gmail.com", value: "smtp.gmail.com" },
  { label: "Outlook.com ", value: "smtp.live.com" },
  { label: "Yahoo.com ", value: "smtp.mail.yahoo.com" },
  { label: "Aol.com ", value: "smtp.aol.com" },
];

const EmailSetup = () => {
  const reduxSetting = useSelector((state) => state.settings.smtpMail);
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({});
  const [password, setPassword] = useState(true);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (isObjWithValues(reduxSetting)) setFormData({ ...reduxSetting });
  }, [reduxSetting]);
  const onEditForm = (payload) => {
    setFormData((pre) => ({ ...pre, ...payload }));
  };

  const onSubmit = async () => {
    let website = getWebsite();
    let token = getToken();
    if (!website || !token) return false;
    setLoading(true);
    let res = await axios({
      url: `${website}/wp-json/store/v1/settings/smtp_mail`,
      method: "PUT",
      headers: {
        Authorization: `Basic ${token}`,
      },
      data: {
        setting: "smtp_mail",
        data: {
          ...formData,
        },
      },
    });
    if (res?.data?.success) {
      dispatch({
        type: EDIT_SETTINGS,
        payload: {
          smtpMail: res?.data?.data,
        },
      });
      dispatch(
        setGlobalToast({
          show: true,
          message: `Email Setting update Successfully`,
          severity: "success",
        })
      );
    } else {
      dispatch(
        setGlobalToast({
          show: true,
          message: `Update failed!`,
          severity: "error",
        })
      );
    }

    setLoading(false);
  };
  const theme = useTheme();
  const isPhone = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Stack sx={{ padding: "10px" }}>
      <Stack direction={"row"} justifyContent="end">
        {" "}
        <LoadingButton loading={loading} variant="contained" onClick={onSubmit}>
          {" "}
          Save Changes
        </LoadingButton>
      </Stack>
      <Card className="shadow_card" sx={{ padding: "30px", mt: 5 }}>
        <Stack
          direction={"row"}
          justifyContent="space-between"
          alignItems={"center"}
        >
          <Typography variant="h5">E-MAIL SETUP</Typography>
        </Stack>
        <Stack sx={{ width: isPhone ? "100%" : "50%" }}>
          <Stack sx={{ mt: 3 }}>
            <FormLabel>Host</FormLabel>
            <TextField
              size="small"
              placeholder="E.g. smtp.gmail.com"
              value={formData.host}
              onChange={(e) => onEditForm({ host: e.target.value })}
            />
            <FormHelperText
              sx={{ display: "flex", alignItems: "center", gap: "4px" }}
            >
              <ErrorOutline style={{ height: "11px", width: "11px" }} />
              Set up email to send email via Campaign.
            </FormHelperText>
          </Stack>
          <Stack direction={"row"} flexWrap={"wrap"} my={2} spacing={2}>
            {smtpEampales.map((i) => {
              return (
                <Tooltip title="Click to replace host">
                  <Chip
                    size="small"
                    label={i.label}
                    onClick={() => onEditForm({ host: i.value })}
                  />
                </Tooltip>
              );
            })}
          </Stack>
          <Stack
            direction={"row"}
            alignItems="center"
            sx={{ mt: 5, gap: "5%" }}
          >
            <FormLabel>Encryption</FormLabel>{" "}
            <Stack direction={"row"} sx={{ gap: "15px" }}>
              <Button
                size="small"
                variant={
                  formData?.encryption === "ssl" ? "contained" : "outlined"
                }
                onClick={(e) => onEditForm({ encryption: "ssl" })}
              >
                SSL
              </Button>{" "}
              <Button
                size="small"
                variant={
                  formData?.encryption === "tls" ? "contained" : "outlined"
                }
                onClick={(e) => onEditForm({ encryption: "tls" })}
              >
                TLS
              </Button>
            </Stack>
          </Stack>
          <Stack sx={{ mt: 5 }}>
            <FormLabel>Port</FormLabel>
            <TextField
              size="small"
              placeholder="E.g. smtp.gmail.com"
              value={formData.port}
              onChange={(e) => onEditForm({ port: e.target.value })}
            />
            <FormHelperText
              sx={{ display: "flex", alignItems: "center", gap: "4px" }}
            >
              <ErrorOutline style={{ height: "11px", width: "11px" }} /> Choose
              the SMTP port as recommended by your mail server.
            </FormHelperText>
          </Stack>
        </Stack>
        <Divider sx={{ mt: 5, mb: 5 }} />
        <Grid container spacing={10}>
          <Grid item xs={12} lg={6}>
            <FormLabel>Username</FormLabel>
            <TextField
              fullWidth
              size="small"
              sx={{ maxWidth: "500px" }}
              placeholder="Write Sender Email id"
              value={formData.username}
              onChange={(e) => onEditForm({ username: e.target.value })}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <FormLabel>Password</FormLabel>
            <TextField
              size="small"
              fullWidth
              sx={{ maxWidth: "500px" }}
              placeholder="Write password of the email id"
              value={formData.password}
              type={password ? "password" : "text"}
              onChange={(e) => onEditForm({ password: e.target.value })}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setPassword((pre) => !pre)}
                      // onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {!password ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
      </Card>
    </Stack>
  );
};

export const FormLabel = (props) => {
  return (
    <Typography
      variant="subtitle2"
      sx={{ fontSize: "15px", ...(props?.sx || {}) }}
    >
      {props.children}
    </Typography>
  );
};
