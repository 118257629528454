import {
  Button,
  CircularProgress,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ConfimPopup,
  EmptyState,
  convertToMilliseconds,
  formatDate,
  formatServerValue,
  getAuthToken,
  getStoreId,
  getToken,
  getWebsite,
  isArrayWithValues,
  isObjWithValues,
  nodeWebsite,
  settingsEndpoint,
  validateNumber,
} from "../../helper";
import {
  EDIT_CAMPAIGN,
  getFlows,
  getFlowsHistories,
} from "../../redux/actions/campaignActions";
import { campaignChannelIcon } from "../customers/components/CampaignHistory";
import { useNavigate } from "react-router-dom";
import {
  ArrowForwardIos,
  Delete,
  MoreVert,
  NavigateNext,
  PauseCircleOutlineOutlined,
} from "@material-ui/icons";
import { LoadingButton } from "@mui/lab";
import { setGlobalToast } from "../../redux/actions/helperActions";
import {
  BarChartRounded,
  PeopleOutlineRounded,
  PlayCircleOutlined,
  RocketLaunchOutlined,
  StopCircleOutlined,
} from "@mui/icons-material";
import CustomChip from "../components/CustomChip";
import axios from "axios";

const Flowlist = () => {
  const dispatch = useDispatch();
  const reduxFlowArray = useSelector((state) => state.campaign.campaignFlow);
  const fetchingFlowArray = useSelector((state) => state.campaign.fetchingFLow);
  const campaignFlowHistory = useSelector(
    (state) => state.campaign.campaignFlowHistory
  );
  useEffect(() => {
    if (!isArrayWithValues(reduxFlowArray)) dispatch(getFlows());
    if (!isObjWithValues(campaignFlowHistory)) dispatch(getFlowsHistories());
    return () => {};
  }, []);

  return (
    <>
      <FlowTable flows={reduxFlowArray || []} loading={fetchingFlowArray} />
    </>
  );
};

export default Flowlist;

const FlowTable = ({ flows, loading }) => {
  const dispatch = useDispatch();
  const [rows, setRows] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedCampaign, setSelectedCampaign] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [campaignAction, setCampaignAction] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const campaignFlowHistory = useSelector(
    (state) => state.campaign.campaignFlowHistory
  );
  const open = Boolean(anchorEl);
  const handleClick = (event, camp) => {
    setAnchorEl(event.currentTarget);
    setSelectedCampaign(camp);
  };
  const handleClose = () => {
    setAnchorEl(null);
    // setSelectedCampaign(false)
  };
  const onDelete = async () => {
    setDeleting(true);
    let campaignFlow = await settingsEndpoint({
      endpoint: "campaign_flow",
      method: "DELETE",
      params: {
        id: selectedCampaign?.id,
      },
      // data: {
      // },
    });
    console.log(campaignFlow);
    if (isObjWithValues(campaignFlow)) {
      dispatch(
        setGlobalToast({
          show: true,
          message: `${selectedCampaign?.name} Flow Deleted Successfully!`,
          severity: "success",
        })
      );
      dispatch({
        type: EDIT_CAMPAIGN,
        payload: { campaignFlow: Object.values(campaignFlow || {}) },
      });
      setOpenDelete(false);
      //   setCampaignAction(false);
    }
    setDeleting(false);
  };

  useEffect(() => {
    setRows(flows || []);
    return () => {};
  }, [flows]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const onSetStatus = async () => {
    setDeleting(true);
    let campaignFlow = await settingsEndpoint({
      endpoint: "campaign_flow",
      method: "PUT",
      //   params: {
      //     id: selectedCampaign?.id,
      //   },
      data: {
        [selectedCampaign?.id]: {
          ...selectedCampaign,
          status: campaignAction,
        },
      },
      // data: {
      // },
    });
    console.log(campaignFlow);
    if (isObjWithValues(campaignFlow)) {
      dispatch(
        setGlobalToast({
          show: true,
          message: `${formatServerValue(campaignAction)} ${
            selectedCampaign?.name
          } flow!`,
          severity: "success",
        })
      );
      dispatch({
        type: EDIT_CAMPAIGN,
        payload: { campaignFlow: Object.values(campaignFlow || {}) },
      });
      setCampaignAction(false);
    }
    setDeleting(false);
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Campaign</TableCell>
              <TableCell>Channels</TableCell>
              <TableCell>Created on</TableCell>
              <TableCell>Audience Reached</TableCell>
              <TableCell>Status</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow
                sx={{
                  "&:last-child td, &:last-child th": {
                    border: 0,
                  },
                }}
              >
                <TableCell colSpan={5}>
                  {/* <Typography>No Sms Template found!</Typography> */}
                  <EmptyState
                    icon={<CircularProgress />}
                    text={"Getting Flows"}
                  />
                </TableCell>
              </TableRow>
            ) : isArrayWithValues(rows) ? (
              rows
                ?.sort(
                  (a, b) =>
                    // validateNumber(convertToMilliseconds(a?.created_on)) -
                    validateNumber(convertToMilliseconds(b?.created_on)) -
                    validateNumber(convertToMilliseconds(a?.created_on))
                )
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)

                ?.map((row) => (
                  <FLowRow
                    key={row?.id}
                    row={row}
                    handleClick={handleClick}
                    history={campaignFlowHistory?.[row?.id] || {}}
                  />
                ))
            ) : (
              <TableRow
                sx={{
                  "&:last-child td, &:last-child th": {
                    border: 0,
                  },
                }}
              >
                <TableCell colSpan={6}>
                  {/* <Typography>No Sms Template found!</Typography> */}
                  <EmptyState text={"No Template found!"} />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={flows?.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <Actions
        anchorEl={anchorEl}
        handleClose={handleClose}
        open={open}
        setOpenDelete={setOpenDelete}
        selectedCampaign={selectedCampaign}
        setCampaignAction={setCampaignAction}
      />
      {openDelete && (
        <ConfimPopup
          open={openDelete}
          handleClose={() => setOpenDelete(false)}
          heading={`Are you Sure you want to delete ${selectedCampaign?.name}  Flow ?`}
          subtitle={"This action cannot be undone!"}
          button={
            <LoadingButton
              loading={deleting}
              onClick={onDelete}
              variant="contained"
              color={"error"}
            >
              Delete
            </LoadingButton>
          }
        />
      )}
      {campaignAction && (
        <ConfimPopup
          open={Boolean(campaignAction)}
          handleClose={() => setCampaignAction(false)}
          heading={`Are you Sure you want to ${formatServerValue(
            campaignAction
          )} ${selectedCampaign?.name}  Flow ?`}
          //   subtitle={"This action cannot be undone!"}
          button={
            <LoadingButton
              loading={deleting}
              onClick={onSetStatus}
              variant="contained"
              //   color={"error"}
            >
              Update
            </LoadingButton>
          }
        />
      )}
    </>
  );
};

const FLowRow = ({ row, handleClick, history }) => {
  let channels = [...new Set(row?.connection?.map((o) => o?.channel))];
  const [launching, setLaunching] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // console.log(row);
  let audience = [
    ...new Set(
      Object.values(history?.sent_info || {})
        ?.map((o) => Object.values(o || {}))
        ?.flat(4)
        ?.map((o) => o?.id?.toString())
    ),
  ]?.length;

  const onLaunch = async () => {
    const token = getToken();
    const website = getWebsite();
    setLaunching(true);
    let socketRes = await axios({
      // url: `http://192.168.1.15:8081/campaign_flow`,
      url: `${nodeWebsite}/campaign_flow`,
      method: "POST",
      headers: {
        Authorization: `Basic ${token}`,
      },
      data: {
        flow_id: row?.is,
        website,
        store_id: getStoreId(),
        newToken: getAuthToken(),
      },
    });
    setLaunching(false);
    dispatch(
      setGlobalToast({
        show: true,
        message: "Launch successfully!",
        severity: "success",
      })
    );
  };
  return (
    <>
      <TableRow
        key={row.name}
        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
      >
        <TableCell component="th" scope="row">
          <Typography variant="subtitle2">{row.name}</Typography>
        </TableCell>
        <TableCell>
          <Stack direction={"row"} alignItems={"center"} gap={2}>
            {channels?.map((str) => campaignChannelIcon?.[str])}
          </Stack>
        </TableCell>
        <TableCell>
          <Typography>
            {formatDate(new Date(row?.created_on), "dd/mm/yyyy")}
          </Typography>
          <Typography>
            {formatDate(new Date(row?.created_on), "hh:mm am/pm")}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography
            variant="h6"
            sx={{ display: "flex", alignItems: "center", gap: 2 }}
          >
            <PeopleOutlineRounded color={"action"} /> {audience}
          </Typography>
        </TableCell>
        <TableCell>
          {/* <CustomChip
            status={
              row?.pause ? "Pause" : history?.launch_time ? "Live" : "Draft"
            }
            type={
              row?.pause ? "pause" : history?.launch_time ? "live" : "draft"
            }
          /> */}
          <CustomChip
            status={
              row?.status === "pause"
                ? "Pause"
                : history?.launch_time
                ? "Live"
                : "Draft"
            }
            type={
              row?.status === "pause"
                ? "pause"
                : history?.launch_time
                ? "live"
                : "draft"
            }
          />
        </TableCell>
        <TableCell align="right">
          <Stack
            direction={"row"}
            alignItems={"center"}
            gap={2}
            justifyContent={"end"}
          >
            {!history?.launch_time && false && (
              <LoadingButton
                loading={launching}
                variant="outlined"
                onClick={() => onLaunch()}
                endIcon={<RocketLaunchOutlined />}
              >
                Launch
              </LoadingButton>
            )}
            <Button
              variant="outlined"
              onClick={() => navigate(`/campaign-flow/${row?.id}`)}
              endIcon={<NavigateNext />}
            >
              Flow
            </Button>
            <IconButton
              onClick={(e) => {
                handleClick(e, row);
              }}
            >
              <MoreVert />
            </IconButton>
          </Stack>
          {/* </TableCell>
                    <TableCell> */}
        </TableCell>
      </TableRow>
    </>
  );
};

const Actions = ({
  anchorEl,
  open,
  handleClose,
  setOpenDelete,
  selectedCampaign,
  setCampaignAction,
}) => {
  const navigate = useNavigate();
  const actionsArray = [
    {
      label: "Play",
      Icon: PlayCircleOutlined,
      onClick: () => {
        setCampaignAction("play");
      },
    },
    {
      label: "Pause",
      Icon: StopCircleOutlined,
      onClick: () => {
        setCampaignAction("pause");
      },
    },
    {
      label: "Analytics",
      Icon: BarChartRounded,
      onClick: () => {
        navigate(`/campaign-flow/${selectedCampaign?.id}/report`);
      },
    },
    {
      label: "Delete",
      Icon: Delete,
      onClick: () => {
        setOpenDelete(true);
      },
    },
  ];
  return (
    <>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {actionsArray?.map((action) => {
          let { Icon, label, onClick } = action;
          return (
            <MenuItem
              key={label}
              onClick={() => {
                onClick();
                handleClose();
              }}
            >
              <ListItemIcon>
                <Icon fontSize="small" />
              </ListItemIcon>
              <ListItemText>{label}</ListItemText>
            </MenuItem>
          );
        })}
        {/* <MenuItem onClick={handleClose}>Profile</MenuItem>
            <MenuItem onClick={handleClose}>My account</MenuItem>
            <MenuItem onClick={handleClose}>Logout</MenuItem> */}
      </Menu>
    </>
  );
};
