import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useDispatch } from "react-redux";
import { addCannedMessage } from "../../../redux/actions/chatActions";
import { getRandomString } from "../../../helper";

const AddCannedMessages = React.forwardRef(({}, ref) => {
  const [open, setOpen] = React.useState(false);
  let [message, setMessage] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const dispatch = useDispatch();

  React.useImperativeHandle(ref, () => {
    return {
      openAddCannedMessages: () => setOpen(true),
    };
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onAdd = () => {
    let obj = {
      id: getRandomString(10),
      time: new Date().getTime(),
      message,
    };
    dispatch(addCannedMessage(obj, setLoading, () => handleClose()));
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle id="alert-dialog-title">Add new quick reply</DialogTitle>
      <DialogContent>
        <TextField
          multiline
          rows={2}
          fullWidth
          placeholder="e.g. Hi! How can I help you?"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
        <LoadingButton loading={loading} onClick={onAdd}>
          Create
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
});
export default AddCannedMessages;
