import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  Typography,
} from "@mui/material";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { useSelector } from "react-redux";
import {
  dialogStyle,
  getRelativeTimeDifference,
  isObjWithValues,
} from "../../helper";
import { UpgradeIllustration } from "../../Svgs";
import { primaryLabel } from "../chat/ChatHelper";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const PlanUpgraded = forwardRef((props, ref) => {
  const [expiryOn, setExpiryOn] = useState("");
  const [open, setOpen] = React.useState(false);
  const [isExpired, setIsExpired] = useState(false);

  useImperativeHandle(ref, () => {
    return {
      openExpiryWarning() {
        setOpen(true);
      },
      setExpiry(value) {
        setIsExpired(value);
      },
      setExpiryTime(value) {
        setExpiryOn(value);
      },
    };
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    if (!isExpired) setOpen(false);
  };
  return (
    <Box>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        sx={{ ...dialogStyle }}
      >
        <DialogContent>
          <UpgradeIllustration height="300px" width="300px" />

          <Typography sx={{ ...primaryLabel }}>
            Your plan is{" "}
            {isExpired ? (
              <strong>Expired.</strong>
            ) : (
              <Typography sx={{ ...primaryLabel }} variant="caption">
                going to expire{" "}
                <strong>{expiryOn ? `in ${expiryOn}` : "soon"}</strong>
              </Typography>
            )}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
          <Button color="primary" onClick={handleClose} variant="contained">
            Upgrade
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
});

export default PlanUpgraded;
