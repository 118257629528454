import { Stack } from "@mui/material";
import React from "react";

function HorizontalStack(props) {
  let { children } = props;
  return (
    <Stack direction={"row"} alignItems="center" {...props}>
      {children}
    </Stack>
  );
}

export default HorizontalStack;
