import {
  Avatar,
  Card,
  CardContent,
  Rating,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { getRandomAvatar } from "..";
import {
  getRandomString,
  globalCardStyle,
  secondaryLabel,
} from "../../../helper";

const CompanyCard = ({ }) => {
  return (
    <Card sx={{ maxWidth: 375, ...globalCardStyle }}>
      <CardContent>
        <Stack direction={"row"} alignItems="center" gap={"15px"}>
          <Avatar src={getRandomAvatar()} sx={{ height: 100, width: 100 }} />
          <Stack rowGap={2}>
            <Typography variant="h6">{getRandomString(20)}</Typography>
            <Rating name="size-large" defaultValue={2} size="medium" />
            <Typography sx={{ ...secondaryLabel, fontSize: "15px" }}>
              Gold Jewellery
            </Typography>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default CompanyCard;
