import {
  CheckCircleOutline,
  CircleOutlined,
  NavigateNextOutlined,
} from "@mui/icons-material";
import {
  Box,
  List,
  ListItemButton,
  ListItemText,
  ListSubheader,
  Popover,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useTour } from "@reactour/tour";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllTourSteps,
  metaIntegrationSteps,
  metaIntegrationSteps2,
} from "../../App";
import { getUserProduct, isArrayWithValues } from "../../helper";
import {
  setHelperData,
  SET_HELPER_DATA,
} from "../../redux/actions/helperActions";

const HelpPopper = ({ anchorEl, id, setAnchorEl, open }) => {
  const handleClose = () => {
    setAnchorEl(null);
  };
  const dispatch = useDispatch();
  const theme = useTheme();
  const isIpad = useMediaQuery(theme.breakpoints.down("md"));
  let tour_history = useSelector((state) => state.user?.tour_history);
  let dashboard_tour = useSelector((state) => state.helper?.dashboard_tour);

  const { setSteps, setCurrentStep, setIsOpen } = useTour();

  const getHelpList = (product_arr) => {
    const heplerObj = {
      chats: [
        {
          label: "Chats Tour",
          onClick: () => {
            setSteps(dashboard_tour || []);
            setCurrentStep(0);
            setIsOpen(true);
          },
          value: "chats_tour",
          done: tour_history?.chats_tour,
        },
        {
          label: "Chats Introduction",
          onClick: () => {
            dispatch({
              type: SET_HELPER_DATA,
              payload: {
                onBoardingDialogState: true,
                featureProduct: "chats",
              },
            });
          },
          value: "chats_introduction",
          done: tour_history?.chats_introduction,
        },
        {
          label: "Meta Integration",
          onClick: () => {
            setSteps(metaIntegrationSteps);
            setCurrentStep(0);
            dispatch(
              setHelperData({
                restrictedTour: [0, 1, 2, 4],
                currentTour: "meta_integration",
              })
            );
            setIsOpen(true);
          },
          value: "meta_integration",
        },
      ],
      whatsapp: [
        {
          label: "Whatsapp Tour",
          onClick: () => {
            setSteps(dashboard_tour || []);
            setCurrentStep(0);
            setIsOpen(true);
          },
          value: "whatsapp_tour",
          done: tour_history?.whatsapp_tour,
        },
        {
          label: "Whatsapp Introduction",
          onClick: () => {
            dispatch({
              type: SET_HELPER_DATA,
              payload: {
                onBoardingDialogState: true,
                featureProduct: "whatsapp",
              },
            });
          },
          value: "whatsapp_introduction",
          done: tour_history?.whatsapp_introduction,
        },
        {
          label: "Whatsapp Integration",
          onClick: () => {
            setSteps(metaIntegrationSteps);
            setCurrentStep(0);
            dispatch(
              setHelperData({
                // restrictedTour: [0, 1, 2, 4],
                currentTour: "meta_integration",
              })
            );
            setIsOpen(true);
          },
          value: "meta_integration",
        },
      ],
      crm: [
        {
          label: "CRM Tour",
          onClick: () => {
            setSteps(dashboard_tour || []);
            setCurrentStep(0);
            setIsOpen(true);
          },
          value: "crm_tour",
          done: tour_history?.crm_tour,
        },
        {
          label: "CRM Introduction",
          onClick: () => {
            dispatch({
              type: SET_HELPER_DATA,
              payload: {
                onBoardingDialogState: true,
                featureProduct: "crm",
              },
            });
          },
          value: "crm_introduction",
          done: tour_history?.crm_introduction,
        },
      ],
      catalog: [
        {
          label: "Catalog Tour",
          onClick: () => {
            setSteps(dashboard_tour || []);
            setCurrentStep(0);
            setIsOpen(true);
          },
          value: "catalog_tour",
        },
        {
          label: "Catalog Introduction",
          onClick: () => {
            dispatch({
              type: SET_HELPER_DATA,
              payload: {
                onBoardingDialogState: true,
                featureProduct: "catalogs",
              },
            });
          },
          value: "catalogs_introduction",
        },
        // {
        //     label: "Meta Integration",
        //     onClick: () => {
        //         setSteps(metaIntegrationSteps);
        //         setCurrentStep(0);
        //         dispatch(
        //             setHelperData({
        //                 restrictedTour: [0, 1, 2, 4],
        //                 currentTour: "meta_integration",
        //             })
        //         );
        //         setIsOpen(true);
        //     },
        // },
      ],
    };

    let finalArr = [];
    if (isArrayWithValues(product_arr))
      finalArr = [
        ...finalArr,
        ...(product_arr || [])?.map((key) => heplerObj[key] || []).flat(),
      ];
    else
      finalArr = [
        ...finalArr,
        {
          label: "Dasborad tour",
          value: "dashboard_tour",
          onClick: () => {
            setSteps(dashboard_tour || []);
            setCurrentStep(0);
            setIsOpen(true);
          },
        },
        ,
        ...Object.values(heplerObj || {})
          ?.flat()
          ?.filter((obj) => !obj?.value?.includes("tour")),
      ];
    if (isIpad)
      finalArr = finalArr.filter((obj) => obj?.value?.includes("introd"));
    return finalArr.sort((a, b) => (a?.value?.includes("introd") ? -1 : 1));
  };

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      PaperProps={{
        style: {
          // backgroundColor: "transparent",
          boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
          borderRadius: 3,
        },
      }}
      sx={{
        maxHeight: "350px",
        // boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
      }}
    >
      <Box
      // sx={{
      //     width: "100%",
      //     maxWidth: 360,
      //     bgcolor: "background.paper",
      //     position: "relative",
      //     mt: "10px",
      //     "&::before": {
      //         backgroundColor: "white",
      //         content: '""',
      //         display: "block",
      //         position: "absolute",
      //         width: 12,
      //         height: 12,
      //         top: -6,
      //         transform: "rotate(45deg)",
      //         left: "calc(50% - 6px)",
      //     },
      // }}
      >
        <List
          sx={{ minWidth: "330px" }}
          component="nav"
          aria-label="main mailbox folders"
          subheader={
            <ListSubheader sx={{ lineHeight: "38px", color: "text.primary" }}>
              Getting started
            </ListSubheader>
          }
        >
          {(getHelpList(getUserProduct()) || []).map((obj, index) => {
            return (
              <>
                <ListItemButton
                  // selected={selectedIndex === 0}
                  key={index}
                  onClick={() => {
                    obj?.onClick && obj?.onClick();
                    handleClose();
                  }}
                >
                  {tour_history?.[obj?.value] ? (
                    <CheckCircleOutline
                      style={{ color: "#012687", marginRight: "8px" }}
                    />
                  ) : (
                    <CircleOutlined
                      style={{ color: "#012687", marginRight: "8px" }}
                    />
                  )}

                  <ListItemText primary={obj?.label} />
                  <NavigateNextOutlined style={{ opacity: "0.6" }} />
                </ListItemButton>
              </>
            );
          })}
        </List>
      </Box>

      {/* <Typography sx={{ p: 2 }}>The content of the Popover.</Typography> */}
    </Popover>
  );
};

export default HelpPopper;
