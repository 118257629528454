import {
  Check,
  CheckCircle,
  CheckCircleOutline,
  Close,
  NavigateBefore,
  NavigateNext,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Grow,
  Slide,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useTour } from "@reactour/tour/dist";
import React, { useEffect, useMemo, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getAllTourSteps,
  metaIntegrationSteps,
  multipleUserProduct,
} from "../../App";

import {
  dialogStyle,
  getAdminName,
  getUserProduct,
  isArrayWithValues,
  isDev,
  isObjWithValues,
  putTourHistory,
  secondaryLabel,
} from "../../helper";
import { setHelperData } from "../../redux/actions/helperActions";
import { addTourHistory } from "../../redux/actions/userActions";
import { Confetti } from "../../Svgs";
// const product = "chats";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const buttons = [
  "Welcome to Jwero Chats ",
  "Take  a guided tour ",
  "Integrate meta",
];
const getUserButton = (product) => {
  switch (product) {
    case "chats":
      return [
        "Welcome to Jwero Chats ",
        "Take  a guided tour ",
        "Integrate meta",
      ];
    case "whatsapp":
      return [
        "Welcome to Jwero Whatsapp",
        "Take  a guided tour ",
        "Integrate Whatsapp",
      ];
    case "catalog":
      return [
        "Welcome to Jwero Catalog ",
        "Take a guided tour ",
        // "Integrate meta",
      ];
    case "catalogs":
      return [
        "Welcome to Jwero Catalog ",
        "Take a guided tour ",
        // "Integrate meta",
      ];
    case "crm":
      return [
        "Welcome to Jwero CRM",
        "Take a guided tour ",
        // "Integrate meta",
      ];

    default:
      break;
  }
};
const OnBoardingDialog = ({ open, setOpen }) => {
  const product = useSelector((state) => state.helper.featureProduct);
  let tour_history = useSelector((state) => state.user?.tour_history);

  // const product = useSelector((state) => state.helper.featureProduct);
  const [stepsState, setStepsState] = useState(0);
  const [steps, setSteps] = useState(1);
  const onboardingDialog = useSelector(
    (state) => state.helper.onboardingDialog
  );

  const [selectedProcess, setSelectedProcess] = useState(
    multipleUserProduct ? 2 : 1
  );

  // const setOpen =()=>{
  //   setSelectedProcess()
  // }
  const globalPopupStyle = useSelector(
    (state) => state.helper.globalPopupStyle
  );
  const theme = useTheme();
  const isIpad = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    // return
    if (isObjWithValues(onboardingDialog) && onboardingDialog.show) {
      setOpen(true);
      setStepsState(1);
      setSteps(Object.keys(dialogContent?.[product] || {})?.length + 1);
      setSelectedProcess(onboardingDialog.process);
    }
  }, [onboardingDialog]);
  // console.log(open);
  useEffect(() => {
    if (!open) {
      setStepsState(0);
      setSelectedProcess(multipleUserProduct ? 2 : 1);
      setSteps(1);
    }
  }, [open]);
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted={false}
      onClose={() => isDev() && setOpen(false)}
      aria-describedby="alert-dialog-slide-description"
      fullWidth
      maxWidth={"md"}
      sx={{
        ".MuiDialog-paper": {
          padding: "9px 7px",
          borderRadius: "30px",
          // height: stepsState ? "80vh" : "70vh",
          height: stepsState || true ? "80vh" : "70vh",
          ...(stepsState || true ? { maxWidth: "1150px" } : {}),
        },
      }}
      {...globalPopupStyle}
      fullScreen={isIpad}
    >
      <DialogTitle
        sx={{
          display: "flex",
          gap: "8px",
          alignItems: "center",
          justifyContent: "space-between",
          lg: {
            py: 2,
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: "8px",
          }}
        >
          Welcome! {getAdminName()}
          {/* Get Started{" "} */}
          <img
            src="/static/img/brands/jwero_icon.png"
            style={{ height: "20px", width: "20px" }}
          />
        </Box>

        {tour_history?.[`${product}_introduction`] && (
          <Button startIcon={<Close />} onClick={() => setOpen(false)}>
            Close
          </Button>
        )}
      </DialogTitle>
      <Divider />

      {!stepsState && <Step1 setStepsState={setStepsState} isIpad={isIpad} />}
      {stepsState ? (
        <Step2
          stepsState={stepsState}
          setStepsState={setStepsState}
          setOpen={setOpen}
          isIpad={isIpad}
          steps={steps}
          setSelectedProcess={setSelectedProcess}
          setSteps={setSteps}
          selectedProcess={selectedProcess}
        />
      ) : null}
    </Dialog>
  );
};

export default OnBoardingDialog;

const Step1 = ({ setStepsState, isIpad }) => {
  const product = useSelector((state) => state.helper.featureProduct);
  return (
    <>
      <DialogContent className="scrollbar-hidden">
        <Stack
          justifyContent={"center"}
          alignItems={"center"}
          sx={{ height: "100%", width: "100%" }}
        >
          <img
            src={starterPageContent?.[product]?.image}
            // src="\static\img\onboarding\Welcomtojwerochats.png"
            // style={{  background: "linear-gradient(#004092, #020202, transparent)" }}
            style={{ width: isIpad ? "100%" : "70%", mt: 2 }}
          />

          <Typography
            color={"primary.main"}
            fontSize={"24px"}
            fontWeight={"700"}
          >
            {starterPageContent?.[product]?.title} <Confetti />
          </Typography>
          <Typography
            color={"text.secondary"}
            sx={{
              lg: {
                width: "50%",
              },
              mt: 2,
            }}
            textAlign="center"
            variant="h6"
            fontWeight={"400"}
          >
            {starterPageContent?.[product]?.subTitle}
          </Typography>
          <Button
            size="large"
            variant="contained"
            sx={{
              mt: 4,
              mb: 4,
              borderRadius: "8px",
              boxShadow: "rgba(0, 0, 0, 0.04) 0px 3px 5px",
              textAlign: "center",
            }}
            onClick={() => setStepsState(1)}
          >
            Get Started
          </Button>
        </Stack>
      </DialogContent>

      {/* <DialogActions sx={{ justifyContent: "center" }}>
        
      </DialogActions> */}
    </>
  );
};
const Step2 = ({
  setStepsState,
  stepsState,
  setOpen,
  isIpad,
  steps,
  setSteps,
  selectedProcess,
  setSelectedProcess,
}) => {
  const [loading, setLoading] = useState(true);
  const product = useSelector((state) => state.helper.featureProduct);
  const tour_history = useSelector((state) => state.user.tour_history);
  const dispatch = useDispatch();
  const onClick = () => {
    setSteps((pre) => ++pre);
    if (Object.keys(dialogContent?.[product] || {})?.length === steps) {
      // setSelectedProcess(pre => ++pre)

      putTourHistory({ [`${product}_introduction`]: true });
      dispatch(addTourHistory({ [`${product}_introduction`]: true }));
      if (tour_history?.[`${product}_introduction`]) {
        setOpen(false);
      }
    }
  };
  // const list = ["One dashboard to manage multiple channels.",
  //     "Whatsapp, message & instagram message now on one screen.",
  //     "Send & receive message from mutiple channels.",
  //     "Create tasks, activities for a specific contact.",
  //     "Send bulk campaigns & broadcasts.",
  //     "Whatsapp chatbot",
  //     "Analytics"

  // ]

  // let image = setTimeout(() => , timeout);
  return (
    <>
      {Object.keys(dialogContent?.[product] || {})?.length < steps ? (
        <FinalStep
          setOpen={setOpen}
          isIpad={isIpad}
          selectedProcess={selectedProcess}
        />
      ) : (
        <>
          <DialogContent className="scrollbar-hidden">
            <Grid
              container
              className="scrollbar-hidden"
              // direction={isIpad ? "column-reverse" : "row"}

              sx={{ height: !isIpad && "100%" }}
            >
              <Grid item md={5.5} xs={12} order={isIpad && 2}>
                <Typography
                  color={"primary.main"}
                  fontSize={"24px"}
                  fontWeight={"700"}
                  mb={2}
                >
                  {dialogContent?.[product]?.[steps]?.title}
                </Typography>

                <Stack rowGap={"8px"} sx={{ width: "75%" }}>
                  {isArrayWithValues(
                    dialogContent?.[product]?.[steps]?.list
                  ) ? (
                    dialogContent?.[product]?.[steps]?.list?.map((str) => (
                      <Stack
                        direction={"row"}
                        alignItems="flex-start"
                        gap={"4px"}
                        key={str}
                      >
                        <Check color="success" />
                        <Typography
                          color={"text.secondary"}
                          fontSize="15px"
                          variant="body1"
                          fontWeight={"400"}
                        >
                          {str}
                        </Typography>
                      </Stack>
                    ))
                  ) : (
                    <Stack rowGap={3}>
                      {Object.keys(
                        dialogContent?.[product]?.[steps]?.list
                      )?.map((key) => {
                        return (
                          <Stack rowGap={2} key={key}>
                            <Typography
                              fontSize="15px"
                              variant="body1"
                              fontWeight={"400"}
                            >
                              {key}
                            </Typography>
                            <Stack rowGap={"8px"}>
                              {dialogContent?.[product]?.[steps]?.list?.[
                                key
                              ]?.map((str) => (
                                <Stack
                                  direction={"row"}
                                  alignItems="flex-start"
                                  gap={"4px"}
                                  key={str}
                                >
                                  <Check color="success" />
                                  <Typography
                                    color={"text.secondary"}
                                    fontSize="15px"
                                    variant="body1"
                                    fontWeight={"400"}
                                  >
                                    {str}
                                  </Typography>
                                </Stack>
                              ))}
                            </Stack>
                          </Stack>
                        );
                      })}
                    </Stack>
                  )}
                </Stack>
              </Grid>
              <Grid
                item
                md={6.5}
                xs={12}
                order={isIpad && 1}
                sx={
                  !isIpad
                    ? {
                        display: "flex",
                        alignItems: "center",
                        height: !isIpad && "100%",
                        position: "relative",
                        // justifyContent: 'center'
                      }
                    : { position: "relative" }
                }
              >
                {/* <LazyLoadImage
                                        
                                    alt={dialogContent?.[product]?.[steps]?.image}
                                    src={dialogContent?.[product]?.[steps]?.image}
                                    effect={"blur"}
                                    style={{ width: "100%", height: "100%", objectFit: "cover" }}
                                     /> */}
                {loading ? (
                  <CircularProgress
                    sx={{
                      position: "absolute",
                      top: "40%",
                      left: "40%",
                      transform: "translate(-50% , -50%)",
                    }}
                  />
                ) : null}
                {
                  <Grow
                    in={!loading}
                    timeout={"auto"}
                    // onEnter={!loading || dialogContent?.[product]?.[steps]?.image}
                    // {...(dialogContent?.[product]?.[steps]?.image ? { timeout: 1000 } : {})}
                  >
                    <img
                      loading="lazy"
                      onLoad={() => setLoading(false)}
                      alt={dialogContent?.[product]?.[steps]?.image}
                      src={dialogContent?.[product]?.[steps]?.image}
                      effect={"blur"}
                      style={{
                        width: "100%",
                        height: isIpad ? "30vh" : "100%",
                        objectFit: "contain",
                      }}
                    />
                  </Grow>
                }
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions
            sx={{
              justifyContent: "space-between",
              alignItems: "center",
              px: 6,
              mb: 2,
            }}
          >
            <Button
              variant="outlined"
              startIcon={<NavigateBefore />}
              onClick={() =>
                steps === 1 ? setStepsState(0) : setSteps((pre) => --pre)
              }
            >
              previous
            </Button>
            <Stack direction={"row"} alignItems="center" gap={4}>
              <Typography sx={{ ...secondaryLabel }}>
                {" "}
                <span style={{ fontWeight: "700" }}>{steps}</span> out of{" "}
                {Object.keys(dialogContent?.[product] || {})?.length}
              </Typography>
              <Button
                variant="contained"
                endIcon={
                  Object.keys(dialogContent?.[product] || {})?.length ===
                  steps ? null : (
                    <NavigateNext />
                  )
                }
                onClick={() => {
                  setLoading(true);

                  onClick();
                }}
              >
                {Object.keys(dialogContent?.[product] || {})?.length === steps
                  ? "Complete"
                  : "Next"}
              </Button>
            </Stack>
          </DialogActions>
        </>
      )}
    </>
  );
};

export const starterPageContent = {
  chats: {
    title: "Introduction to Jwero Chats",
    subTitle:
      " Multi-channel chats super simplified for your Jewellery business",
    image: "/static/img/onboarding/Welcomtojwerochats.png",
  },
  whatsapp: {
    title: "Introduction to Jwero Whatsapp",
    subTitle:
      "WhatsApp channel can be used for receiving inquiries, taking orders, and providing customer support for your jewelry business.",

    // " Multi-channel chats super simplified for your Jewellery business",
    image: "/static/img/onboarding/Welcomtojwerochats.png",
  },
  catalogs: {
    title: "Introduction to Jwero catalog",
    subTitle:
      "Super simple cataloging system designed for your jewelry business.",
    image: "/static/img/onboarding/WelcomToJweroCatalog.png",
  },
  crm: {
    title: "Introduction to Jwero CRM",
    subTitle:
      "Streamline your jewelry business with personalized customer relationships",
    image: "/static/img/onboarding/crm/crm_get_started.png",
  },
};

const dialogContent = {
  chats: {
    1: {
      title: "Jwero Chats",
      list: [
        "One dashboard to manage multiple channels.",
        "Whatsapp, message & instagram message now on one screen.",
        "Send & receive message from mutiple channels.",
        "Create tasks, activities for a specific contact.",
        "Send bulk campaigns & broadcasts.",
        "Whatsapp chatbot",
        "Analytics",
      ],
      image: "/static/img/onboarding/Jwerochat.png",
    },
    2: {
      title: "Whatsapp chats",
      list: [
        "1000 free conversations every month",
        "Higher engagement time, Bulk message, send templates ",
        "Unlimited contact initiated chats in (24 hrs window) a conversation",
        "Create message template.",
        "Create unlimited broadcast lists.",
        "Green tick verification",
        "Analytics",
      ],
      image: "/static/img/onboarding/Whatsappchat.png",
    },
    3: {
      title: "Messages",
      list: [
        "Broadcast promotional & marketing messages.",
        "Send bulk messages to contacts in one go, even unknown contacts.",
        "Push notifications - alerts to your customers.",
        "Create templates",
        "Send business initiated template message.",
        "Search & filter contact.",
        "Channel wise messages & its media count.",
      ],
      image: "/static/img/onboarding/Messages.png",
    },
    4: {
      title: "Manage contact",
      list: [
        "Add / edit / delete a contact.",
        "Segregate contacts as customers or leads or spam.",
        "Merge contacts across channels to avoid duplicate entries.",
        "Assign & reassign team member to a contact for better customer service.",
        "Mark contacts as follow up, done , spam.",
        "Pin a chat",
        "Perform bulk actions on contacts.",
      ],
      image: "/static/img/onboarding/Managecontact.png",
    },
    5: {
      title: "Bulk actions",
      list: [
        "You can perform various bulk actions for contact at a click such as : ",
        "Segregate contacts as customers or leads or spam.",
        "Delete multiple contacts.",
        "Assign contacts to a team member.",
        "Mark contacts as follow up, done , spam.",
        "Mark contacts as spam",
        "Perform bulk actions on contacts.",
      ],
      image: "/static/img/onboarding/Bulkactions.png",
    },
    6: {
      title: "Activities",
      list: [
        "Create task, reminders  and follow up activites. ",
        "Edit or delete a activity.",
        "Assign task to a department or a team member.",
        "Update activity status.",
      ],
      image: "/static/img/onboarding/Activities.png",
    },
    7: {
      title: "Whatsapp chatbot",
      list: [
        "Sell or service to your customers 24/7",
        "Segregate contacts as customers or leads or spam.",
        "Delete multiple contacts.",
        "Assign contacts to a team member.",
        "Mark contacts as follow up, done , spam.",
        "Mark contacts as spam",
        "Perform bulk actions on contacts.",
      ],
      image: "/static/img/onboarding/Whatsappchatbot.png",
    },
  },
  whatsapp: {
    // 1: {
    //   title: "Jwero Chats",
    //   list: [
    //     "One dashboard to manage multiple channels.",
    //     "Whatsapp, message & instagram message now on one screen.",
    //     "Send & receive message from mutiple channels.",
    //     "Create tasks, activities for a specific contact.",
    //     "Send bulk campaigns & broadcasts.",
    //     "Whatsapp chatbot",
    //     "Analytics",
    //   ],
    //   image: "/static/img/onboarding/Jwerochat.png",
    // },
    1: {
      title: "Whatsapp chats",
      list: [
        "1000 free conversations every month",
        "Higher engagement time, Bulk message, send templates ",
        "Unlimited contact initiated chats in (24 hrs window) a conversation",
        "Create message template.",
        "Create unlimited broadcast lists.",
        "Green tick verification",
        "Analytics",
      ],
      image: "/static/img/onboarding/Whatsappchat.png",
    },
    // 3: {
    //   title: "Messages",
    //   list: [
    //     "Broadcast promotional & marketing messages.",
    //     "Send bulk messages to contacts in one go, even unknown contacts.",
    //     "Push notifications - alerts to your customers.",
    //     "Create templates",
    //     "Send business initiated template message.",
    //     "Search & filter contact.",
    //     "Channel wise messages & its media count.",
    //   ],
    //   image: "/static/img/onboarding/Messages.png",
    // },
    // 4: {
    //   title: "Manage contact",
    //   list: [
    //     "Add / edit / delete a contact.",
    //     "Segregate contacts as customers or leads or spam.",
    //     "Merge contacts across channels to avoid duplicate entries.",
    //     "Assign & reassign team member to a contact for better customer service.",
    //     "Mark contacts as follow up, done , spam.",
    //     "Pin a chat",
    //     "Perform bulk actions on contacts.",
    //   ],
    //   image: "/static/img/onboarding/Managecontact.png",
    // },
    // 5: {
    //   title: "Bulk actions",
    //   list: [
    //     "You can perform various bulk actions for contact at a click such as : ",
    //     "Segregate contacts as customers or leads or spam.",
    //     "Delete multiple contacts.",
    //     "Assign contacts to a team member.",
    //     "Mark contacts as follow up, done , spam.",
    //     "Mark contacts as spam",
    //     "Perform bulk actions on contacts.",
    //   ],
    //   image: "/static/img/onboarding/Bulkactions.png",
    // },
    2: {
      title: "Activities",
      list: [
        "Create task, reminders  and follow up activites. ",
        "Edit or delete a activity.",
        "Assign task to a department or a team member.",
        "Update activity status.",
      ],
      image: "/static/img/onboarding/Activities.png",
    },
    3: {
      title: "Whatsapp chatbot",
      list: [
        "Sell or service to your customers 24/7",
        "Segregate contacts as customers or leads or spam.",
        "Delete multiple contacts.",
        "Assign contacts to a team member.",
        "Mark contacts as follow up, done , spam.",
        "Mark contacts as spam",
        "Perform bulk actions on contacts.",
      ],
      image: "/static/img/onboarding/Whatsappchatbot.png",
    },
  },
  catalogs: {
    1: {
      title: "Create dynamic, time bound & trackable catalogs within  seconds",
      list: [
        "Live tracking.",
        "Hassle free photography",
        "Multi-layered product pricing.",
        "Track products & contact performance.",
        "One page website.",
        "Advance analytics",
      ],
      image: "/static/img/onboarding/createdynamic.png",
    },
    2: {
      title: "Catalogs",
      list: {
        "Public catalog": [
          "Create a catalog and share its link to any contact via WhatsApp, Facebook, or other channels.",
        ],
        "Private catalog": [
          "Create a catalog and share it to the contacts which are listed in your CRM.",
          "Catalog is shared as a link on WhatsApp",
        ],
      },
      image: "/static/img/onboarding/Catalogs.png",
    },
    3: {
      title: "Dynamic catalog",
      list: {
        "A link based digital catalog designed for your jewelry business": [
          "Send time bound catalogs, to get timely response.",
          "Send catalog with or without product price.",
          "Multi-images view.",
          "In-catalog filters helps to find exact products one is interested in ",
          "Metal weight based product selection, instead of amount.",
          "Mark add to cart products as sold.",
          "In-catalog dynamic stock status.",
        ],
      },
      image: "/static/img/onboarding/DynamicCatalog.png",
    },
    4: {
      title: "Hassle free photography",
      list: {
        "Jwelpix - jewelry photography and editing app integrated with your catalog system.":
          [
            "Take photography from jwelpix.",
            "Automatically removes background.",
            "Auto - resign, file compression.",
            "Publish image directly to your product listed, on catalog.",
          ],
      },
      image: "/static/img/onboarding/Jwelpix.png",
      // button1: <Button startIcon="" >Google Play</Button>
    },
    5: {
      title: "Product pricing",
      list: {
        "Set product price, suitable to your business.": [
          "Master & customize price.",
          "Set labour price on wastage.",
          "Auto-update pricing updates thousands of products.",
        ],
      },
      image: "/static/img/onboarding/MasterPricing.png",
    },
    6: {
      title: "Live tracking",
      list: {
        "Track every action a contact performs, from the moment they open catalog.":
          [
            "Get instant notification on desktop / admin app when a catalog is open.",
            "Monitor their interaction on catalog product by product.",
            "Know the time duration they spent on particular  products.",
            "Visit history can be viewed any time.",
          ],
      },
      image: "/static/img/onboarding/LiveTracking.png",
    },
    7: {
      title: "Contact performance",
      list: {
        "Track important catalog metrics of your contacts.": [
          "Mark contacts as customer or leads. (Potential customers) ",
          "Know whom you are sending catalogs, customer or leads, & how they are responding.",
          "Identify best performing & non responsive contacts.",
        ],
      },
      image: "/static/img/onboarding/screely-16755001024921.png",
    },
    8: {
      title: "Product Performance.",
      list: {
        "Track important product metrics": [
          "Dynamic product status updates as ready or  make to order.",
          "Identify most viewed, shared, liked, selected & ordered products.",
          "Identify products never shared via catalog.",
          "Know the never selected products.",
        ],
      },
      image: "/static/img/onboarding/productPerformance.png",
    },
    9: {
      title: "Statistics & analytics",
      list: {
        "Advance analytics for decision making.": [
          "Catalog - sent, opened, views.",
          "Product reach & performance.",
          "Device based tracking.",
          "Know the never selected products.",
        ],
      },
      image: "/static/img/onboarding/StatisticsAndAnalytics.png",
    },
  },
  crm: {
    1: {
      title: "Jwero CRM",
      list: [
        "One dashboard to manage customers",
        "View & track your contacts performance at one place",
        "Offer Hyper Personalized services to your Customers & Leads",
        "Send Email Campaign",
        "Send bulk campaigns & brodcasts",
        "Dynamic Contact’s profile management",
        "Add Team member with controlled access",
        "Get notifications / alerts",
      ],
      image: "/static/img/onboarding/crm/crm_personal_info.png",
    },
    2: {
      title: "Well organized contact management",
      list: [
        "Add or Bulk upload contacts",
        "Detailed contact profile to offer hyper personalised services",
        "Assign a Team member and Store Branch",
        "Create message templates.",
        "Mark as Lead, Customer",
        "Add Tags for identification & sorting",
        "Filter on multiple parameters to find exact record",
      ],
      image: "/static/img/onboarding/crm/crm_organized.png",
    },
    3: {
      title: "Bulk Actions for better efficiency",
      list: [
        "Bulk upload / update contacts via Google Sheet",
        "Bulk Delete",
        "Send emails to multiple customers and Leads",
        "Delete contacts",
        "Assign Tags",
      ],
      image: "/static/img/onboarding/crm/crm_bulk_actions.png",
    },
    4: {
      title: "Campaigns: Email Marketing Solution",
      list: [
        "Send Email campaign to Customers or Leads",
        "Create Email Templates and Customer Segmentation",
        "Send Campaign, based on Contact segmentation",
        "Delete contacts",
        "Track campaign Sent & Delivery",
      ],
      image: "/static/img/onboarding/crm/crm_campaign.png",
    },
    5: {
      title: "Analytics & Reports",
      list: {
        "Channel wise analysis for :": [
          "Products",
          "Communication",
          "Catalog",
          "Orders",
          "Gold Saving schemes",
          "Website",
          "App",
          "In-store (Sales App)",
          // "* based on service subscribed",
        ],
      },
      image: "/static/img/onboarding/crm/crm_analytics.png",
    },
    6: {
      title: "In-depth Contact Analytics",
      list: [
        "Captures channel wise visit details",
        "Device based Session details",
        "Time spent on website, app",
        "Products Added to Cart",
        "Products added in Wish-List",
        "Session Visit and Activities",
        "Keeps track of last visit ",
        "Save customer’s showroom visit data",
        // "* based on service subscribed",
      ],
      image: "/static/img/onboarding/crm/crm_indepth_analytics.png",
    },
  },
};

export const FinalStep = ({ setOpen, isIpad, selectedProcess }) => {
  const featureProduct = useSelector((state) => state.helper.featureProduct);
  const user_products = useSelector(
    (state) => state.user?.store_plan?.user_products
  );
  const { setIsOpen, beforeClose, setSteps, setCurrentStep } = useTour();
  const dispatch = useDispatch();
  const naviagte = useNavigate();
  const onClick = () => {
    setOpen(false);
    dispatch(
      setHelperData({
        restrictedTour: [""],
      })
    );
    if (selectedProcess === 1) {
      if (isIpad) return;
      setTimeout(() => {
        if (featureProduct === "whatsapp") {
          if (isIpad) return;
          // setSteps(getAllTourSteps(user_products));
          setCurrentStep(0);
          dispatch(
            setHelperData({
              // restrictedTour: [0, 1, 2, 3, 4],
            })
          );
          setIsOpen(true);
        }
        setIsOpen(true);
      }, 700);

      dispatch(
        setHelperData({
          onTourEnd: () => {
            dispatch(
              setHelperData({
                onboardingDialog: { show: true, process: 2 },
              })
            );
          },
        })
      );
    }
    if (selectedProcess === 2) {
      if (featureProduct === "chats") {
        if (isIpad) return;
        setSteps(metaIntegrationSteps);
        setCurrentStep(0);
        dispatch(
          setHelperData({
            restrictedTour: [0, 1, 2, 3, 4],
          })
        );
        setIsOpen(true);
      }
      if (featureProduct === "whatsapp") {
        if (isIpad) return;

        setSteps(metaIntegrationSteps);
        setCurrentStep(0);
        dispatch(
          setHelperData({
            // restrictedTour: [0, 1, 2, 3, 4],
          })
        );
        setIsOpen(true);
      }
      if (featureProduct === "crm") {
        setOpen(false);
      }
    }
    // setOpen(false);
    // if (selectedProcess === 1) {
    //   console.log("first");
    //   dispatch(
    //     setHelperData({
    //       onTourEnd: () => {
    //         dispatch(
    //           setHelperData({
    //             onboardingDialog: { show: true, process: 2 },
    //           })
    //         );
    //       },
    //     })
    //   );
    // }
    // if (selectedProcess === 2) {
    //   switch (featureProduct) {
    //     case "chats":
    //       if (!isIpad) {

    //         // setTimeout(() => {
    //         //   setIsOpen(true);
    //         // }, 700);

    //         console.log("chats", featureProduct);
    //         setSteps(metaIntegrationSteps);
    //         setCurrentStep(0);
    //         dispatch(
    //           setHelperData({
    //             restrictedTour: [0, 1, 2, 4],
    //           })
    //         );
    //         setIsOpen(true);
    //       }
    //       setOpen(false)
    //       break;
    //     case "catalogs":
    //       if (!isIpad) {

    //         setIsOpen(true);
    //       }
    //       setOpen(false)
    //       break;

    //     default:
    //       console.log("deafault", featureProduct);
    //       setOpen(false)

    //       break;
    //   }

    // }
  };

  return (
    <>
      <DialogContent className="scrollbar-hidden">
        <Stack
          justifyContent={"center"}
          alignItems="center"
          sx={{ height: "100%" }}
        >
          <Typography
            color={"primary.main"}
            fontSize={"24px"}
            fontWeight={"700"}
            mb={3}
            textAlign={isIpad && "center"}
          >
            {selectedProcess == 1
              ? "Yay! you just completed Introduction"
              : selectedProcess === 2
              ? "Yay! you just completed product tour"
              : ""}{" "}
            <Confetti />
          </Typography>

          <Stack
            mt={3}
            rowGap={5}
            mb={3}
            sx={{ width: isIpad ? "auto" : "40%" }}
          >
            {getUserButton(featureProduct)?.map((i, index) => {
              return (
                <CardButton
                  title={i}
                  key={i}
                  isSelected={selectedProcess == index}
                  selected={selectedProcess > index}
                />
              );
            })}
            {/* <CardButton title={"Welcome to Jwero Chats "} selected={true} />
            <CardButton title={"Integarte meta"} selected={false} /> */}
          </Stack>
          <Typography
            color={"text.secondary"}
            sx={{ width: isIpad ? "auto" : "50%", mt: 3 }}
            textAlign="center"
            variant="h6"
            fontWeight={"400"}
          >
            {selectedProcess === 1
              ? `A guided tour can assist in making you feel at ease with Jwero ${featureProduct}`
              : selectedProcess === 2 && featureProduct === "chats"
              ? "You need to integrate meta from integrations to start sending and receiving messages."
              : selectedProcess === 2 && featureProduct === "crm"
              ? "Start using CRM Dashboard"
              : "You can choose your preferred settings or create your first catalog."}
          </Typography>
          {selectedProcess === 1 ? (
            <Button
              size="large"
              variant="contained"
              sx={{ mt: 5, mb: 4, borderRadius: "8px", padding: "12px 38px" }}
              onClick={onClick}
            >
              Start tour
            </Button>
          ) : selectedProcess === 2 && featureProduct !== "catalogs" ? (
            <Button
              size="large"
              variant="contained"
              sx={{ mt: 5, mb: 4, borderRadius: "8px", padding: "12px 38px" }}
              onClick={onClick}
            >
              {featureProduct === "chats" ? "Start Integration" : "Start"}
            </Button>
          ) : featureProduct === "crm" ? (
            <Button
              size="large"
              variant="contained"
              sx={{ mt: 5, mb: 4, borderRadius: "8px", padding: "12px 38px" }}
              onClick={onClick}
            >
              Start Integration
            </Button>
          ) : null}
          {selectedProcess === 2 && featureProduct === "catalogs" ? (
            <Stack direction={"row"} alignContent={"center"} gap={4}>
              <Button
                size="large"
                variant="contained"
                sx={{ mt: 5, mb: 4, borderRadius: "8px", padding: "12px 38px" }}
                onClick={() => {
                  naviagte(`/catalogs`);
                  setOpen(false);
                }}
              >
                Create First Catalog
              </Button>
              <Button
                size="large"
                // variant="contained"
                sx={{ mt: 5, mb: 4, borderRadius: "8px", padding: "12px 38px" }}
                onClick={() => {
                  naviagte(`/settings`);
                  setOpen(false);
                }}
              >
                Go to Settings
              </Button>
            </Stack>
          ) : null}
          {false && (
            <Button
              size="large"
              variant="contained"
              sx={{ mt: 5, mb: 4, borderRadius: "8px", padding: "12px 38px" }}
              onClick={onClick}
            >
              {selectedProcess === 1
                ? "Start Tour"
                : selectedProcess === 2 && featureProduct === "chats"
                ? "Start Integration"
                : "Start Using Jwero"}
            </Button>
          )}
        </Stack>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center" }}></DialogActions>
    </>
  );
};

const CardButton = ({ title, selected, isSelected }) => {
  return (
    <Card
      sx={{
        border: isSelected ? "1px solid #93b0d0" : "1px solid #e7e7e7",
        width: "100%",
      }}
    >
      <CardContent
        sx={{
          display: "flex",
          gap: "8px",
          backgroundColor: isSelected ? "#F4F4F4" : "#d6d5d4",
          ":last-child": {
            pb: "16px",
          },
        }}
      >
        {selected ? (
          <CheckCircle style={{ color: "#012687" }} />
        ) : (
          <CheckCircleOutline style={{ color: "#012687" }} />
        )}{" "}
        <Typography variant="h5" color={"text.secondary"}>
          {title}
        </Typography>
      </CardContent>
    </Card>
  );
};
