import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import CreateKeywordAction from "./components/CreateKeywordAction";
import {
  formatDate,
  isArrayWithValues,
  isObjWithValues,
} from "jwero-javascript-utils";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import { visuallyHidden } from "@mui/utils";
import {
  editSettings,
  fetchAndSaveSingleSettings,
  fetchSingleSettings,
} from "../../../../../../redux/actions/settingActions";
import { useDispatch, useSelector } from "react-redux";
import CustomChip from "../../../../../components/CustomChip";
import {
  backendApiCall,
  backendApiEndpoints,
  formatServerValue,
} from "../../../../../../helper";
import HorizontalStack from "../../../../../../helper/utility/HorizontalStack";
import { Button, Menu, MenuItem, Stack } from "@mui/material";
import { Add, Delete, Edit, MoreVert } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import {
  PrimaryLabelSmall,
  SecondaryLabel,
  SecondaryLabelSmall,
} from "../../../../../../helper/utility/Labels";
import { setGlobalToast } from "../../../../../../redux/actions/helperActions";
import CustomConfirmationDialog from "../../../../../../helper/utility/CustomConfirmationDialog";

const KeywordActions = forwardRef((props, ref) => {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const createRef = useRef();
  const reduxFlowSettings = useSelector(
    (state) => state?.settings?.flowSettings
  );
  const fetchingFlowKeywords = useSelector(
    (state) => state?.settings?.fetchingFlowKeywords
  );
  const [rows, setRows] = useState([]);
  const confirmationRef = useRef();
  const [confirmationProps, setConfirmationProps] = useState({});
  const [deleteLoading, setDeleteLoading] = React.useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (isArrayWithValues(reduxFlowSettings?.keywords_flow_actions)) {
      setRows(reduxFlowSettings?.keywords_flow_actions);
    }
  }, [reduxFlowSettings]);

  useImperativeHandle(ref, () => {
    return {
      onCreateKeywordActionPress: createRef?.current?.open,
    };
  });

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = stableSort(rows, getComparator(order, orderBy)).slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const onOpenConfirmation = () => {
    confirmationRef?.current?.open();
  };
  const onCloseConfirmation = () => {
    confirmationRef?.current?.close();
  };

  const onEditPress = (obj) => {
    createRef?.current?.openEdit(obj);
  };
  const onDeletePress = (item) => {
    setConfirmationProps({
      title: `Delete keywords action ${item?.title}?`,
      subtitle: "This action cannot be undone",
      submitButtonProps: {
        label: "Delete",
        onClick: () => onDelete(item),
        loading: deleteLoading,
        color: "error",
      },
    });
    onOpenConfirmation();
  };

  const onDelete = async (item) => {
    try {
      setDeleteLoading(true);
      let keywords = rows?.filter((i) => i?.id !== item?.id);
      let res = await backendApiCall({
        endpoint: `${backendApiEndpoints.settings}/flow_settings`,
        method: "PUT",
        data: { data: { keywords_flow_actions: keywords } },
      });
      if (res?.success && isObjWithValues(res?.data)) {
        dispatch(editSettings({ flowSettings: res?.data }));
        dispatch(
          setGlobalToast({
            show: true,
            message: "Keywords action has been deleted",
            severity: "success",
          })
        );
        onCloseConfirmation();
      } else {
        dispatch(
          setGlobalToast({
            show: true,
            message: "Unable to delete keywords action",
            severity: "error",
          })
        );
      }
    } catch (error) {
      console.log(error, "<<<< delete error");
    } finally {
      setDeleteLoading(false);
    }
  };
  const createKeywordActionPress = () => {
    createRef?.current?.open();
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          createKeywordActionPress={createKeywordActionPress}
        />
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {visibleRows?.map((row, index) => {
                return (
                  <RowItem
                    key={row?.id}
                    row={row}
                    onEditPress={onEditPress}
                    onDeletePress={onDeletePress}
                    rows={rows}
                  />
                );
              })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      {/* <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      /> */}
      <CreateKeywordAction ref={createRef} />
      <CustomConfirmationDialog ref={confirmationRef} {...confirmationProps} />
    </Box>
  );
});

export default KeywordActions;

const RowItem = ({ row, onEditPress, rows, onDeletePress }) => {
  const flowsList = useSelector((state) => state.flow?.flowsList);
  const [flow, setFlow] = useState({});
  const [statusChecked, setStatusChecked] = React.useState(false);
  const [updatingStatus, setUpdatingStatus] = React.useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    setFlow(flowsList?.find((i) => i?.id === row?.flow_id));
  }, [flowsList, row]);

  React.useEffect(() => {
    setStatusChecked(row?.status === "publish");
  }, []);

  const onStatusChange = (value) => {
    setStatusChecked(value);
    onStatusUpdate(value);
  };

  const onStatusUpdate = async (checked) => {
    try {
      setUpdatingStatus(true);
      let status = checked ? "publish" : "draft";
      let keywords = rows?.map((i) => {
        if (i?.id === row?.id) return { ...i, status };
        return i;
      });
      let res = await backendApiCall({
        endpoint: `${backendApiEndpoints.settings}/flow_settings`,
        method: "PUT",
        data: { data: { keywords_flow_actions: keywords } },
      });
      if (res?.success && isObjWithValues(res?.data)) {
        dispatch(editSettings({ flowSettings: res?.data }));
        dispatch(
          setGlobalToast({
            show: true,
            message: "Status has been updated",
            severity: "success",
          })
        );
      }
    } catch (error) {
    } finally {
      setUpdatingStatus(false);
    }
  };
  return (
    <TableRow
      hover
      // onClick={(event) => handleClick(event, row.id)}
      role="checkbox"
      tabIndex={-1}
      key={row.id}
    >
      {/* <TableCell padding="checkbox">
        <Checkbox
          color="primary"
          checked={isItemSelected}
          inputProps={{
            "aria-labelledby": labelId,
          }}
        />
      </TableCell> */}
      <TableCell
        component="th"
        scope="row"
        // padding="none"
        onClick={() => onEditPress(row)}
        sx={{ cursor: "pointer" }}
      >
        <HorizontalStack spacing={2}>
          {row.keywords?.map((i) => {
            return <CustomChip key={i} label={i} />;
          })}
        </HorizontalStack>
      </TableCell>
      <TableCell>
        <PrimaryLabelSmall>{flow?.title}</PrimaryLabelSmall>
      </TableCell>
      <TableCell align="right">{formatServerValue(row.match_method)}</TableCell>
      <TableCell align="right">
        <SecondaryLabel>
          {formatDate(new Date(row.created_on), "DD MMM, YYYY")}
        </SecondaryLabel>
        <SecondaryLabelSmall sx={{ mt: 0.5 }}>
          {formatDate(new Date(row.created_on), "hh:mm A")}
        </SecondaryLabelSmall>
      </TableCell>
      <TableCell align="right">
        <Switch
          disabled={updatingStatus}
          checked={statusChecked}
          onChange={(e) => onStatusChange(e.target.checked)}
        />
      </TableCell>
      <TableCell align="right">
        <KeywordRowActions
          onEdit={() => onEditPress(row)}
          onDelete={() => onDeletePress(row)}
        />
        {/* <Stack direction="row" justifyContent={"flex-end"} spacing={2}>
          <IconButton
            // onClick={() => onEditPress(row?.id)}
            size="small"
            // sx={{ backgroundColor: "text.secondary" }}
            onClick={() => onEditPress(row)}
          >
            <Edit />
          </IconButton>
          <IconButton size="small" onClick={() => onDeletePress(row)}>
            <Delete />
          </IconButton>
        </Stack> */}
      </TableCell>
    </TableRow>
  );
};

function KeywordRowActions({ onEdit, onDelete }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onEditPress = () => {
    onEdit();
    handleClose();
  };
  const onDeletePress = () => {
    onDelete();
    handleClose();
  };

  return (
    <div>
      <IconButton
        // onClick={() => onEditPress(row?.id)}
        size="small"
        // sx={{ backgroundColor: "text.secondary" }}
        onClick={handleClick}
        // onClick={() => onEditPress(row)}
      >
        <MoreVert />
      </IconButton>
      {/* <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        Dashboard
      </Button> */}
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={onEditPress}>Edit</MenuItem>
        <MenuItem onClick={onDeletePress}>Delete</MenuItem>
      </Menu>
    </div>
  );
}

function createData(id, name, calories, fat, carbs, protein) {
  return {
    id,
    name,
    calories,
    fat,
    carbs,
    protein,
  };
}

const rows = [
  createData(1, "Cupcake", 305, 3.7, 67, 4.3),
  createData(2, "Donut", 452, 25.0, 51, 4.9),
  createData(3, "Eclair", 262, 16.0, 24, 6.0),
  createData(4, "Frozen yoghurt", 159, 6.0, 24, 4.0),
  createData(5, "Gingerbread", 356, 16.0, 49, 3.9),
  createData(6, "Honeycomb", 408, 3.2, 87, 6.5),
  createData(7, "Ice cream sandwich", 237, 9.0, 37, 4.3),
  createData(8, "Jelly Bean", 375, 0.0, 94, 0.0),
  createData(9, "KitKat", 518, 26.0, 65, 7.0),
  createData(10, "Lollipop", 392, 0.2, 98, 0.0),
  createData(11, "Marshmallow", 318, 0, 81, 2.0),
  createData(12, "Nougat", 360, 19.0, 9, 37.0),
  createData(13, "Oreo", 437, 18.0, 63, 4.0),
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "keywords",
    numeric: false,
    disablePadding: true,
    label: "Keywords",
  },
  {
    id: "flow",
    numeric: false,
    disablePadding: true,
    label: "Flow",
  },
  {
    id: "match_method",
    numeric: true,
    disablePadding: false,
    label: "Match Method",
  },
  {
    id: "fat",
    numeric: true,
    disablePadding: false,
    label: "Created on",
  },
  {
    id: "status",
    numeric: true,
    disablePadding: false,
    label: "Status",
  },
  {
    id: "carbs",
    numeric: true,
    disablePadding: false,
    label: "Actions",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell> */}
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={"normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {/* {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null} */}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};
function EnhancedTableToolbar(props) {
  const { numSelected, selectedTab, setSelectedTab, createKeywordActionPress } =
    props;
  const navigate = useNavigate();

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        // ...(numSelected > 0 && {
        //   bgcolor: (theme) =>
        //     alpha(
        //       theme.palette.primary.main,
        //       theme.palette.action.activatedOpacity
        //     ),
        // }),
        justifyContent: "space-between",
      }}
    >
      <Typography
        // sx={{ flex: "1 1 100%" }}
        variant="h5"
        id="tableTitle"
        component="div"
      >
        Keyword Actions
      </Typography>

      <Button
        variant="contained"
        color="primary"
        size={"medium"}
        startIcon={<Add />}
        sx={{ ml: 2 }}
        onClick={createKeywordActionPress}
      >
        Create a New Keyword
      </Button>
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};
