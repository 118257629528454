import { Box, Card, CardContent, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useSelector } from "react-redux";
import {
  convertArrayToPercentage,
  globalColors,
  isArrayWithValues,
  isObjWithValues,
  reduceLargeText,
  sortObjectByValues,
  sumupElementsAfterIndex,
} from "../../../helper";

function ProductsPulledFrom() {
  const [info, setInfo] = useState({
    series: [],
    labels: [],
  });
  const designbankProductsAnalytics = useSelector(
    (state) => state.supplier.designbankProductsAnalytics
  );

  const allSuppliers = useSelector((state) => state.supplier.allSuppliers);

  useEffect(() => {
    if (
      !isArrayWithValues(allSuppliers) ||
      !isObjWithValues(designbankProductsAnalytics)
    )
      return;
    let { totalProductsPulledBySuppliers } = designbankProductsAnalytics;
    if (!isObjWithValues(totalProductsPulledBySuppliers)) return;
    let obj = {};
    for (let supplierId in totalProductsPulledBySuppliers) {
      let supplierInfo = allSuppliers?.find((i) => i.id == supplierId);
      if (
        !supplierInfo ||
        !supplierInfo?.name ||
        !totalProductsPulledBySuppliers[supplierId]
      )
        continue;
      obj[supplierInfo.name] =
        totalProductsPulledBySuppliers[supplierId]?.length;
    }
    let sortedObj = sortObjectByValues(obj, true);
    let max = 4;
    let series = Object.values(sortedObj);
    let labels = Object.keys(sortedObj).slice(0, max);
    if (series.length >= max) {
      series = sumupElementsAfterIndex(series, max);
      labels = labels.concat(["Other"]);
    }
    setInfo({ series: convertArrayToPercentage(series), labels });
  }, [allSuppliers, designbankProductsAnalytics]);
  return (
    <Card>
      <CardContent>
        <Typography variant="h6" sx={{ mb: 3 }}>
          Products Pulled From
        </Typography>
        <Box sx={{ maxWidth: "485px", margin: "0px auto" }}>
          <ReactApexChart
            options={{ ...options, labels: info.labels }}
            series={info.series}
            type="radialBar"
            height={320}
          />
        </Box>
        {/* <ReactApexChart
          options={options}
          series={[44, 55, 13, 43]}
          type="radialBar"
          width={380}
        /> */}
      </CardContent>
    </Card>
  );
}

export default ProductsPulledFrom;

let colors = ["#3AC0EB", "#2494FD", "#556BAA", "#2489BE"];
const options = {
  chart: {
    height: 320,
    type: "radialBar",
  },
  colors: globalColors,
  plotOptions: {
    radialBar: {
      offsetY: 0,
      startAngle: 0,
      endAngle: 270,
      hollow: {
        margin: 5,
        size: "30%",
        background: "transparent",
        image: undefined,
      },
      dataLabels: {
        name: {
          show: false,
          formatter: (val) => {
            return val.substring(0, 5);
          },
        },
        value: {
          show: false,
        },
      },
    },
  },
  legend: {
    show: true,
    floating: true,
    fontSize: "16px",
    position: "left",
    offsetX: 0,
    offsetY: 15,
    labels: {
      useSeriesColors: true,
    },
    markers: {
      size: 0,
    },
    formatter: function (seriesName, opts) {
      return (
        reduceLargeText(`${seriesName}`, 12) +
        " :  " +
        `${opts.w.globals.series[opts.seriesIndex]}%`
      );
    },
    itemMargin: {
      vertical: 3,
    },
  },
  responsive: [
    {
      breakpoint: 480,
      options: {
        legend: {
          show: false,
        },
      },
    },
  ],
};
