import { useState } from "react";
import { Typography, Card, IconButton, CardContent } from "@mui/material";
import ReactPlayer from "react-player";
import VideocamIcon from "@mui/icons-material/Videocam";
import PropTypes from "prop-types";
import { botStyles } from "../styles/botStyles";
import DeleteAndSwap from "../../components/DeleteAndSwap";
import { uploadDataToAwsBucket } from "../utils/uploadDataToAwsBucket";
import { bytesToMegabytes } from "../utils/bytesToMegabytes";
import BounceLoader from "react-spinners/BounceLoader";

const styles = botStyles;

const BotVideoField = ({
  botData,
  handleButtonClick,
  setBotData,
  data,
  fileInputRefs,
  index,
}) => {
  const [uploadingFile, setUploadingFile] = useState(false);

  const handleVideoFileSelect = async (e, data) => {
    setUploadingFile(true);
    const uploadedFile = e.target.files[0];

    const videoSize = bytesToMegabytes(uploadedFile.size);

    if (videoSize > 16) {
      alert("Image size exceeds the 5MB limit.");
      setUploadingFile(false);
      return;
    }

    const formData = new FormData();
    formData.append("file", uploadedFile);
    const res = await uploadDataToAwsBucket({ formData });
    const id = data.id;
    const videoURL = res.data.source_url;

    setBotData((imageFields) => {
      return imageFields.map((field) => {
        if (field.id === id) {
          return { ...field, file: videoURL };
        }
        return field;
      });
    });
    setUploadingFile(false);
  };

  return (
    <div key={data.id} style={{ marginBottom: "10px", display: "flex" }}>
      <DeleteAndSwap index={index} setBotData={setBotData} botData={botData} />
      {!data?.file ? (
        <CardContent
          style={{ ...styles.imageCard, width: "325px", height: "195px" }}
        >
          <input
            type="file"
            accept="video/mp4, video/3gp"
            style={{ display: "none" }}
            ref={fileInputRefs.current[data.id]}
            onChange={(e) => handleVideoFileSelect(e, data)}
          />
          {uploadingFile ? (
            <>
              <BounceLoader color="#36d7b7" />
              <Typography variant="span">Uploading, please wait...</Typography>
            </>
          ) : (
            <>
              <IconButton onClick={() => handleButtonClick(data.id)}>
                <VideocamIcon style={{ fontSize: "50px" }} />
              </IconButton>
              <Typography variant="span">Upload Video</Typography>
            </>
          )}
        </CardContent>
      ) : (
        <Card
          sx={{
            width: "325px",
            height: "195px",
            borderRadius: "15px",
            marginLeft: "15px",
          }}
        >
          <ReactPlayer url={data.file} width="640" height="560" controls />
        </Card>
      )}
    </div>
  );
};

BotVideoField.propTypes = {
  data: PropTypes.object.isRequired,
  botData: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  setBotData: PropTypes.func.isRequired,
  handleButtonClick: PropTypes.func.isRequired,
  fileInputRefs: PropTypes.object.isRequired,
};

export default BotVideoField;
