import { Box } from "@material-ui/core";
import { Button, useMediaQuery } from "@mui/material";
import React, { useState } from "react";
import { isDev } from "../../helper";
import NewMediaLibrary from "../../mediaLibrary/NewMediaLibrary";

import CampaignHistory from "../customers/components/CampaignHistory";
import EmailTemplate from "../customers/components/EmailTemplate";
import EmailBuilder from "./components/EmailBuilder";

const Campaign = () => {
  const isPhone = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const [emailOpen, setEmailOpen] = useState(false);
  const [openMedia, setOpenMedia] = useState(false);
  const [withFilters, setWithFilters] = useState(false);

  return (
    <>
      {/* {isDev() && <Button onClick={getIdentifer}>
        indentifier
      </Button>} */}
      <div hidden={!emailOpen}>
        {emailOpen && (
          <EmailTemplate
            setEmailOpen={setEmailOpen}
            emailOpen={emailOpen}
            // selected={selected}
            // setSelected={setSelected}
            withFilters={withFilters}
            setWithFilters={setWithFilters}
            // openCampaignHistory={openCampaignHistory}
            // setOpenCampaignHistory={setOpenCampaignHistory}
            isPhone={isPhone}
            wrapperTag={Box}
            isCampaign={true}
          />
        )}
      </div>
      <div hidden={emailOpen}>
        <CampaignHistory
          isPhone={isPhone}
          setOpenEmailCampaign={(val) => {
            setEmailOpen(val);
            setWithFilters(val);
          }}
          //   onClose={() => {
          //     setOpenCampaignHistory(false);
          //     setEmailOpen(false);
          //   }}
          // setCreateTemplate={setCreateTemplate}
        />
      </div>
      <NewMediaLibrary
        open={openMedia}
        handleClose={() => setOpenMedia(false)}
      />
    </>
  );
};

export default Campaign;
