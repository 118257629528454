import { Box, Card, CardContent, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useSelector } from "react-redux";
import {
  globalCardStyle,
  globalColors,
  isObjWithValues,
  sortObjectByValues,
} from "../../../helper";

function ProductsInDBgraph() {
  const [info, setInfo] = useState({
    series: [],
    labels: [],
  });
  const designbankProductsAnalytics = useSelector(
    (state) => state.supplier.designbankProductsAnalytics
  );

  useEffect(() => {
    if (!isObjWithValues(designbankProductsAnalytics)) return;
    let { totalProductsByCollection } = designbankProductsAnalytics;
    if (!isObjWithValues(totalProductsByCollection)) return;
    let data = sortObjectByValues({ ...totalProductsByCollection }, true);
    setInfo({
      series: Object.values(data).slice(0, 5),
      labels: Object.keys(data).slice(0, 5),
    });
  }, [designbankProductsAnalytics]);

  return (
    <Card sx={{ ...globalCardStyle }}>
      <CardContent>
        <Typography variant="h6" sx={{ mb: 3 }}>
          Total products in design bank
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <ReactApexChart
            options={{ ...options, labels: info.labels }}
            series={info.series}
            type="donut"
            height={400}
            width={480}
          />
        </Box>
      </CardContent>
    </Card>
  );
}

export default ProductsInDBgraph;

const options = {
  chart: {
    type: "donut",
  },
  colors: globalColors,
  legend: {
    position: "bottom",
  },
  responsive: [
    {
      breakpoint: 480,
      options: {
        chart: {
          width: 200,
        },
        legend: {
          position: "bottom",
        },
      },
    },
  ],
};
