import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  Typography,
} from "@mui/material";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  dialogStyle,
  getRelativeTimeDifference,
  isObjWithValues,
  secondaryLabel,
} from "../../helper";
import useAuth from "../../hooks/useAuth";
import { UpgradeIllustration } from "../../Svgs";
import { primaryLabel } from "../chat/ChatHelper";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const UpgradeDialog = forwardRef((props, ref) => {
  const [expiryOn, setExpiryOn] = useState("");
  const [open, setOpen] = React.useState(false);
  const [isExpired, setIsExpired] = useState(false);
  const [data, setData] = useState({
    title: "",
    primaryButton: "",
    secondaryButton: "",
    primaryButtonClick: "",
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { signOut } = useAuth();

  useImperativeHandle(ref, () => {
    return {
      setUpgradeData(data) {
        setData(data);
        setOpen(true);
      },
      closeUpgradeDialog() {
        handleClose();
      },
    };
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        sx={{ ...dialogStyle }}
      >
        <DialogContent style={{ alignItems: "center", textAlign: "center" }}>
          <UpgradeIllustration height="250px" width="250px" />

          <Typography sx={{ ...primaryLabel }}>{data.title}</Typography>
          <Typography sx={{ ...secondaryLabel, mt: 1, textAlign: "center" }}>
            {data.subtitle || ""}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>
            {data.secondaryButton || "Close"}
          </Button>
          <Button
            color="primary"
            onClick={() => data.primaryButtonClick && data.primaryButtonClick()}
            variant="contained"
          >
            {data.primaryButton || ""}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
});

export default UpgradeDialog;
