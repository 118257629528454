import { IconButton } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
// import "../styles/glowing-circle.css"
import BounceLoader from "react-spinners/BounceLoader";
import AddIcon from "@mui/icons-material/Add";

const ShowChatIndicator = () => {
  return (
      <div
        style={{
          position: "relative",
          bottom: "15px",
          right: "15px"
        }}
      >
        <BounceLoader color="#3185FC" speedMultiplier="0.5" size="35px" />
      </div>
  );
};

export default ShowChatIndicator;
