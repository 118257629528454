import * as React from "react";
import { Navigate, useLocation } from "react-router-dom";

import useAuth from "../../hooks/useAuth";

// For routes that can only be accessed by authenticated users
function AuthGuard({ children }) {
  const { isAuthenticated, isInitialized } = useAuth();
  const location = useLocation();

  let params_string = location.search;
  let refCode = new URLSearchParams(params_string).get("e");
  if (isInitialized && !isAuthenticated) {
    let redirect = "/auth/sign-in";
    if (refCode) redirect = `/auth/sign-in?e=${refCode}`;
    return <Navigate to={redirect} />;
  }

  return <React.Fragment>{children}</React.Fragment>;
}

export default AuthGuard;
