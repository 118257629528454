import { CardContent } from "@material-ui/core";
import { FormatListBulleted } from "@material-ui/icons";
import { AlignHorizontalLeft } from "@mui/icons-material";
import {
  Box,
  Button,
  ButtonGroup,
  Card,
  Dialog,
  Grid,
  Slide,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  dialogStyle,
  hasOnlyProduct,
  isArrayWithValues,
  isObjWithValues,
  mainSocketEvents,
  TabPanel,
} from "../../helper";
import {
  setVisitorsData,
  SET_VISITOR_DATA,
} from "../../redux/actions/visitorActions";
import { isValidDate } from "../../utils/dateFunctions";
import { UserDetailsDialog } from "./components/helper";

import LiveVisitorListview from "./components/LiveVisitorListview";
import LiveVisitorOverview from "./components/LiveVisitorOverview";

const LiveVisistors = () => {
  const [chartDataOption, setChartDataOption] = useState("time_spent");
  const [visitorView, setVisitorView] = useState("dashborad");
  const [intervalStarted, setIntervalStarted] = useState(false);
  const [allLiveVisitors, setAllLiveVisitor] = useState([]);
  const dispatch = useDispatch();
  const allOnlineVisitors =
    useSelector((state) => state.visitor.allOnlineVisitors) || {};
  const currentVisitors = useSelector((state) => state.visitor.currentVisitors);
  const globalPopupStyle =
    useSelector((state) => state.helper.globalPopupStyle) || {};
  const userDetailView = useSelector((state) => state.visitor.userDetailView);
  const visitordashboard = useSelector(
    (state) => state.visitor.visitordashboard
  );
  const theme = useTheme();
  const isIpad = useMediaQuery(theme.breakpoints.down("md"));
  const isPhone = useMediaQuery(theme.breakpoints.down("sm"));

  const user_products = useSelector(
    (state) => state.user?.store_plan?.user_products
  );

  const onUserDetailClose = () => {
    dispatch({
      type: SET_VISITOR_DATA,
      payload: { userDetailView: [] },
    });
  };
  // console.log(allOnlineVisitors, "all vivistor change");
  let visitorsDataInterval;
  const setUpVisitorsData = (visitors, skipTime) => {
    let obj = { ...visitors };
    // console.log(obj, "AllVisitors");
    let date = new Date();
    let todayDate = date.toISOString().substring(0, 10);
    date = date.setDate(date.getDate() - 1);
    date = new Date(date);
    let yesterdayDate = date.toISOString().substring(0, 10);
    let visitorsArray = [];
    for (let id in obj) {
      let events = obj[id];

      // for (let i = events.length - 1; i >= 0; i--) {
      for (let i = 0; i < events.length; i++) {
        let { time, id, online, user_id, type, user_name } = events[i];
        let eventTime = new Date(time * 1000);

        eventTime = isValidDate(eventTime)
          ? eventTime.toISOString().substring(0, 10)
          : "";
        // console.log(
        //   id,
        //   eventTime === todayDate || eventTime === yesterdayDate,
        //   mainEvents.includes(type),
        //   online || online === "true"
        // );
        if (
          (eventTime === todayDate || eventTime === yesterdayDate) &&
          mainSocketEvents.includes(type)
        ) {
          if (online || online === "true") {
            // console.log(online);
            visitorsArray.push({
              time,
              user_id,
              user_name,
              events,
            });
            break;
          } else break;
        }
      }
    }
    // console.log(visitorsArray, "all online visitor array");
    return visitorsArray;
  };

  useEffect(() => {
    return clearInterval(visitorsDataInterval);
  }, []);

  useEffect(() => {
    // console.log(setUpVisitorsData(allOnlineVisitors || {}), "<<??>>");
    setAllLiveVisitor(setUpVisitorsData(allOnlineVisitors || {}));
  }, [allOnlineVisitors]);
  // useEffect(() => {
  //   if (isActivated) {
  //     timerRef.current = setInterval(increment, 1000);
  //   } else {
  //     clearInterval(timerRef.current);
  //   }
  // }, [isActivated]);
  // console.log(allLiveVisitors, "change");
  useEffect(() => {
    dispatch(setVisitorsData(allLiveVisitors, "outer"));
  }, [allLiveVisitors, allOnlineVisitors]);

  function useInterval(callback, delay) {
    const savedCallback = useRef();

    // Remember the latest callback.
    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
      function tick() {
        savedCallback.current();
      }
      if (delay !== null) {
        let id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    }, [delay]);
  }

  useInterval(() => {
    // Your custom logic here
    dispatch(setVisitorsData(allLiveVisitors));
  }, 1000);

  // useEffect(() => {
  //   if (isArrayWithValues(allLiveVisitors)) {
  //     if (!intervalStarted) {
  //       setIntervalStarted(true);

  //       timerRef.current = setInterval(() => {
  //         // console.log();
  //         // setAllLiveVisitor(pre => {
  //         //   console.log(pre, "inner array");
  //         //   dispatch(setVisitorsData(pre || [], "inter"))

  //         //   return pre
  //         // })
  //         dispatch(setVisitorsData(allLiveVisitors, "inter"))

  //         // console.log("in interval", setUpVisitorsData(allOnlineVisitors ||{}));
  //         // setVisitorsData(allVisitorsRef.current);
  //       }, 10000);
  //     }
  //   }
  //   if (!isArrayWithValues(allLiveVisitors) || allLiveVisitors.length === 0)
  //     clearInterval(timerRef.current);
  // }, [allLiveVisitors, allOnlineVisitors, intervalStarted])

  return (
    <>
      <Box sx={{ px: 3, py: 1.5 }}>
        <Stack
          direction={"row"}
          alignItems="center"
          justifyContent={"space-between"}
          flexWrap={"wrap"}
        >
          <Typography variant="h5">
            You have {allLiveVisitors?.length} live visitors
          </Typography>

          {
            <Stack direction={"row"} alignItems="center" gap={3}>
              <ButtonGroup
                variant="outlined"
                aria-label="outlined primary button group"
              >
                <Button
                  sx={{ backgroundColor: visitorView === "list" && "#e6e6e6" }}
                  onClick={() => setVisitorView("list")}
                  startIcon={<FormatListBulleted />}
                ></Button>
                <Button
                  sx={{
                    backgroundColor: visitorView === "dashborad" && "#e6e6e6",
                  }}
                  onClick={() => setVisitorView("dashborad")}
                  startIcon={<AlignHorizontalLeft />}
                ></Button>
              </ButtonGroup>
              {!isPhone && !hasOnlyProduct("catalog", user_products) && (
                <ButtonGroup
                  variant="outlined"
                  aria-label="outlined primary button group"
                >
                  <Button
                    variant={
                      chartDataOption === "time_spent"
                        ? "contained"
                        : "outlined"
                    }
                    onClick={() => setChartDataOption("time_spent")}
                  >
                    Time spent
                  </Button>
                  <Button
                    variant={
                      chartDataOption === "module" ? "contained" : "outlined"
                    }
                    onClick={() => setChartDataOption("module")}
                  >
                    Module
                  </Button>
                </ButtonGroup>
              )}
            </Stack>
          }
        </Stack>
        <TabPanel index={visitorView} value={"dashborad"}>
          <LiveVisitorOverview
            chartDataOption={chartDataOption}
            currentVisitors={currentVisitors}
            allLiveVisitors={allLiveVisitors}
            isIpad={isIpad}
            visitordashboard={visitordashboard}
            isPhone={isPhone}
            setChartDataOption={setChartDataOption}
          />
        </TabPanel>
        <TabPanel index={visitorView} value={"list"}>
          <LiveVisitorListview
            chartDataOption={chartDataOption}
            currentVisitors={currentVisitors}
            allLiveVisitors={allLiveVisitors}
            isIpad={isIpad}
            setChartDataOption={setChartDataOption}
          />
        </TabPanel>
      </Box>
      <Dialog
        open={isArrayWithValues(userDetailView)}
        onClose={onUserDetailClose}
        TransitionComponent={Transition}
        // keepMounted
        aria-describedby="alert-dialog-slide-description"
        maxWidth="md"
        fullWidth
        PaperProps={{ sx: { overflow: "visible", ...dialogStyle } }}
        {...globalPopupStyle}
        keepMounted={false}
      >
        <UserDetailsDialog
          onUserDetailClose={onUserDetailClose}
          userDetailView={userDetailView || []}
        />
      </Dialog>
    </>
  );
};

export default LiveVisistors;
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
