import {
  Box,
  Card,
  CardContent,
  Checkbox,
  Collapse,
  Fade,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Slider,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getPricingdiff,
  isArrayWithValues,
  isObjWithValues,
} from "../../helper";
import { addUserActivity } from "../../redux/actions/hubActions";
import { currencySymbols, PurityCard } from "./Gold";

const defaultPricing = {
  default: "",
  automatic: {
    850: { base: "", margin: 850, rate: "" },
    900: { base: "", margin: 900, rate: "" },
    950: { base: "", margin: 950, rate: "" },
  },
  manual: {
    850: { base: "", rate: "" },
    900: { base: "", rate: "" },
    950: { base: "", rate: "" },
  },
  enabled_purities: {
    850: true,
    900: true,
    950: true,
  },
};
const defaultPricingObj = {
  INR: defaultPricing,
  USD: defaultPricing,
  EUR: defaultPricing,
  GBP: defaultPricing,
  AUD: defaultPricing,
  AED: defaultPricing,
  SGD: defaultPricing,
  SAR: defaultPricing,
  ZAR: defaultPricing,
  CAD: defaultPricing,
  JPY: defaultPricing,
  CNY: defaultPricing,
  VND: defaultPricing,
  THB: defaultPricing,
  KWD: defaultPricing,
};

const Platinum = forwardRef((props, ref) => {
  let { selectedCurrency: currency } = props;
  const [type, setType] = useState("automatic");
  const [from, setFrom] = useState("gross");
  const [platinumPurities, setPlatinumPurities] = useState([]);
  const [pricing, setPricing] = useState(defaultPricingObj);
  let { changePurity, onPricngChange } = props;
  // const [currency, selectedCurrency] = useState("");
  const [allCurrency, setAllCurrency] = useState([]);
  const masterPricing = useSelector((state) => state.settings.masterPricing);
  const onlyChats = useSelector((state) => state.helper.onlyChats);
  const user_products = useSelector(
    (state) => state.user?.store_plan?.user_products
  );
  const dispatch = useDispatch();
  useImperativeHandle(ref, () => ({
    getPlatinumPricing(updateDiff) {
      let obj = {};

      for (let i = 0; i < allCurrency.length; i++) {
        let currencyObj = pricing[allCurrency[i].value];
        let enabled_purities = {};
        for (let j = 0; j < platinumPurities.length; j++) {
          enabled_purities[platinumPurities[j].value] =
            currencyObj.enabled_purities[platinumPurities[j].value];
        }
        obj[allCurrency[i].value] = { ...currencyObj, enabled_purities };
      }
      // for (let i = 0; i < allCurrency.length; i++) {
      //   obj[allCurrency[i].value] = pricing[allCurrency[i].value];
      // }
      let oldPricingObj = masterPricing?.platinum_pricing;
      let defaultPurityAmount = oldPricingObj?.[currency]?.[type];
      let diffrenceObj = getPricingdiff(
        defaultPurityAmount,
        obj?.[currency]?.[type]
      );
      let event_info = {};
      for (const purity of changePurity?.platinum) {
        let diffrenceObjpurity = diffrenceObj[purity] || {};
        event_info = { ...event_info, [purity]: diffrenceObjpurity };
      }
      if (
        isObjWithValues(diffrenceObj) &&
        isObjWithValues(event_info) &&
        isArrayWithValues(changePurity?.platinum)
      ) {
        updateDiff &&
          updateDiff({
            platinum: event_info,
          });
        dispatch(
          addUserActivity({
            event: `pricing_update`,
            currency,
            event_info: {
              platinum: event_info,
            },
          })
        );
      }
      return { ...obj, from, type };
    },
  }));

  useEffect(() => {
    if (isObjWithValues(props.productSettings))
      if (isArrayWithValues(props.productSettings.currency)) {
        let { currency } = props.productSettings;
        // selectedCurrency(currency[0].value);
        setAllCurrency(props.productSettings.currency);
      }
  }, [props.productSettings]);

  useEffect(() => {
    if (
      isObjWithValues(masterPricing) &&
      isObjWithValues(masterPricing.platinum_pricing)
    ) {
      setPricing((state) => ({ ...state, ...masterPricing.platinum_pricing }));
      let { from, type } = masterPricing.platinum_pricing;
      setFrom(from);
      setType(type);
    }
  }, [masterPricing]);

  useEffect(() => {
    if (isArrayWithValues(props.platinumPurities))
      setPlatinumPurities(props.platinumPurities);
  }, [props.platinumPurities]);

  const onDefaultChange = (value) => {
    let obj = { ...pricing[currency][type][value] };
    if (obj) {
      obj.margin = Number(value);
      obj.rate = obj.base;
    }
    setPricing((state) => ({
      ...state,
      [currency]: {
        ...state[currency],
        [type]: { ...state[currency][type], [value]: obj },
        default: state[currency].default === value ? "" : value,
      },
    }));
  };

  const onBaseAmountChange = (purity, amount, dontChangeOtherPurities) => {
    let obj = { ...pricing[currency][type][purity] };
    obj.base = amount;
    obj.rate = amount;
    if (type === "automatic") {
      if (Number(obj.margin)) {
        obj.rate = Math.round(
          (Number(amount) * Number(obj.margin)) / Number(purity)
        );
      }
      if (!dontChangeOtherPurities) {
        let purities = Object.keys(pricing[currency].enabled_purities).filter(
          (i) => i !== purity
        );
        for (let i = 0; i < purities.length; i++) {
          let otherPurityAmount = Math.round(
            (Number(amount) * Number(purities[i])) / Number(obj.margin)
          );
          onBaseAmountChange(purities[i], otherPurityAmount, true);
        }
      }
    }
    setPricing((state) => ({
      ...state,
      [currency]: {
        ...state[currency],
        [type]: { ...state[currency][type], [purity]: obj },
      },
    }));
  };
  const onMarginChange = (purity, value) => {
    let obj = { ...pricing[currency][type][purity] };
    obj.margin = value;
    if (type === "automatic") {
      if (Number(obj.margin)) {
        obj.rate = Math.round(
          (Number(obj.base) * Number(value)) / Number(purity)
        );
      }
    }
    setPricing((state) => ({
      ...state,
      [currency]: {
        ...state[currency],
        [type]: { ...state[currency][type], [purity]: obj },
      },
    }));
  };

  const onPurityChange = (obj) => {
    setPricing((state) => ({
      ...state,
      [currency]: {
        ...state[currency],
        enabled_purities: {
          ...state[currency]?.enabled_purities,
          [obj.value]: !state[currency]?.enabled_purities[obj.value],
        },
      },
    }));
  };
  return (
    <Box>
      {/* {onlyChats || user_products?.includes("whatsapp") ? null : ( */}
      {false ? null : (
        <Grid container spacing={3} style={{ marginTop: "10px" }}>
          <Grid item xs={12} md={6}>
            <Card
              sx={{
                borderRadius: "10px",
                boxShadow: "0px 0px 3px rgba(0, 0, 0, 0.25)",
              }}
            >
              <CardContent
                sx={{
                  "&:last-child": { paddingBottom: "16px" },
                }}
              >
                <Stack
                  alignItems={"center"}
                  justifyContent="space-between"
                  direction="row"
                  // p={2}
                  // border="0.5px solid #d1d1d1 "
                  // borderRadius={"6px"}
                >
                  <Typography>How do you want to set price?</Typography>
                  <FormControl size="small">
                    {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={type}
                      // label="Age"
                      onChange={(e) => setType(e.target.value)}
                    >
                      <MenuItem value={"automatic"}>Automatic</MenuItem>
                      <MenuItem value={"manual"}>Manual</MenuItem>
                    </Select>
                  </FormControl>
                </Stack>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card
              sx={{
                borderRadius: "10px",
                boxShadow: "0px 0px 3px rgba(0, 0, 0, 0.25)",
              }}
            >
              <CardContent
                sx={{
                  "&:last-child": { paddingBottom: "16px" },
                }}
              >
                <Stack
                  alignItems={"center"}
                  justifyContent="space-between"
                  direction="row"
                  // p={2}
                  // border="0.5px solid #d1d1d1 "
                  // borderRadius={"6px"}
                >
                  <Typography>Calculate platinum pricing from?</Typography>
                  <FormControl size="small">
                    {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={from}
                      // label="Age"
                      onChange={(e) => setFrom(e.target.value)}
                    >
                      <MenuItem value={"gross"}>Gross</MenuItem>
                      <MenuItem value={"net"}>Net</MenuItem>
                    </Select>
                  </FormControl>
                </Stack>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      )}
      {/* <Card>
        <CardContent></CardContent>
      </Card> */}
      <Grid
        container
        spacing={2}
        justifyContent={"space-between"}
        alignItems="center"
        style={{ marginTop: "13px" }}
      >
        <Grid item>
          <Stack direction="row" alignItems={"center"} flexWrap="wrap">
            {platinumPurities.map((i) => {
              return (
                <PurityCard
                  key={i.value}
                  obj={i}
                  isSelected={pricing[currency]?.enabled_purities[i.value]}
                  onClick={onPurityChange}
                />
              );
            })}
          </Stack>
        </Grid>
        {/* <Grid item>
          <FormControl size="small">
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={currency}
              // label="Age"
              onChange={(e) => selectedCurrency(e.target.value)}
            >
              {allCurrency.map((i) => {
                return (
                  <MenuItem
                    value={i.value}
                  >{`${i.label} (${i.symbol})`}</MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid> */}
      </Grid>
      <Grid container spacing={3} sx={{ marginTop: "14px" }}>
        {Array.isArray(platinumPurities) &&
          platinumPurities.map((purity) => {
            if (!pricing[currency].enabled_purities[purity.value]) return null;
            return (
              <Grid item xs={12} md={6} lg={4}>
                <PricingCard
                  title={purity.label}
                  purity={purity.value}
                  baseAmount={pricing[currency][type][purity.value].base}
                  rateAmount={pricing[currency][type][purity.value].rate}
                  margin={pricing[currency][type][purity.value].margin}
                  onMarginChange={onMarginChange}
                  onBaseAmountChange={onBaseAmountChange}
                  defaultValue={pricing[currency].default}
                  onDefaultChange={onDefaultChange}
                  type={type}
                  currency={currency}
                  onPricngChange={onPricngChange}
                />
              </Grid>
            );
          })}
        {/* <Grid item xs={12} md={6} lg={4}>
          <PricingCard title="22 KT (999)" />
        </Grid> */}
      </Grid>
    </Box>
  );
});

export default Platinum;

const PricingCard = ({
  title,
  purity,
  defaultValue,
  onDefaultChange,
  baseAmount,
  rateAmount,
  onBaseAmountChange,
  margin,
  onMarginChange,
  type,
  currency,
  onPricngChange,
}) => {
  return (
    <Card
      style={{
        borderRadius: "10px",
        boxShadow: "0px 0px 3px rgba(0, 0, 0, 0.25)",
      }}
    >
      <CardContent>
        <Stack
          direction={"row"}
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h6">{title}</Typography>
          {/* <Fade in={type === "automatic"}>
          </Fade> */}
          <FormControlLabel
            value="end"
            control={
              <Checkbox
                checked={defaultValue === purity}
                onChange={(e) => onDefaultChange(purity)}
              />
            }
            label="Set Default"
            labelPlacement="start"
          />
        </Stack>
        <Stack
          direction="row"
          justifyContent={"space-between"}
          alignItems={"center"}
          mt={3}
        >
          <Typography variant="body2">Price</Typography>
          <TextField
            type="number"
            size="small"
            value={baseAmount}
            onChange={(e) => {
              onBaseAmountChange(purity, e.target.value);
              onPricngChange("platinum", purity);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {currencySymbols[currency]}
                </InputAdornment>
              ),
            }}
          />
        </Stack>
        <Collapse in={type === "automatic"}>
          <Box sx={{ px: 3, pt: 1 }}>
            <Slider
              // aria-label="Restricted values"
              // defaultValue={20}
              // valueLabelFormat={valueLabelFormat}
              // getAriaValueText={valuetext}
              disabled={defaultValue === purity}
              value={margin}
              onChange={(e, value) => onMarginChange(purity, value)}
              valueLabelDisplay="on"
              step={null}
              sx={{ transform: "translateY(35px)" }}
              defaultValue={Number(purity)}
              // scale={() => " "}
              componentsProps={{ markLabel: { style: { display: "none" } } }}
              marks={marks[purity]}
              min={marks[purity][0].value}
              max={marks[purity][marks[purity].length - 1].value}
              aria-labelledby="non-linear-slider"
            />
          </Box>
        </Collapse>
        <Stack mt={3} justifyContent={"flex-end"} direction="row">
          <Typography
            variant="body1"
            sx={{ fontSize: "15px", fontWeight: 700 }}
          >
            Sell: {currencySymbols[currency]} {rateAmount || 0}
          </Typography>
        </Stack>
      </CardContent>
    </Card>
  );
};

const marks = {
  850: [
    { label: "810", value: 810 },
    { label: "820", value: 820 },
    { label: "830", value: 830 },
    { label: "840", value: 840 },
    { label: "850", value: 850 },
    { label: "855", value: 855 },
    { label: "860", value: 860 },
    { label: "865", value: 865 },
    { label: "870", value: 870 },
    { label: "875", value: 875 },
    { label: "880", value: 880 },
    { label: "885", value: 885 },
    { label: "890", value: 890 },
    { label: "895", value: 895 },
    { label: "900", value: 900 },
    { label: "905", value: 905 },
    { label: "910", value: 910 },
    { label: "915", value: 915 },
    { label: "920", value: 920 },
    { label: "925", value: 925 },
    { label: "930", value: 930 },
    { label: "935", value: 935 },
    { label: "940", value: 940 },
    { label: "945", value: 945 },
  ],
  900: [
    { label: "860", value: 860 },
    { label: "870", value: 870 },
    { label: "880", value: 880 },
    { label: "890", value: 890 },
    { label: "900", value: 900 },
    { label: "905", value: 905 },
    { label: "910", value: 910 },
    { label: "915", value: 915 },
    { label: "920", value: 920 },
    { label: "925", value: 925 },
    { label: "930", value: 930 },
    { label: "935", value: 935 },
    { label: "940", value: 940 },
    { label: "945", value: 945 },
    { label: "950", value: 950 },
    { label: "955", value: 955 },
    { label: "960", value: 960 },
    { label: "965", value: 965 },
    { label: "970", value: 970 },
    { label: "975", value: 975 },
    { label: "980", value: 980 },
    { label: "985", value: 985 },
    { label: "990", value: 990 },
    { label: "995", value: 995 },
    { label: "1000", value: 1000 },
  ],
  950: [
    { label: "910", value: 910 },
    { label: "920", value: 920 },
    { label: "930", value: 930 },
    { label: "940", value: 940 },
    { label: "950", value: 950 },
    { label: "955", value: 955 },
    { label: "960", value: 960 },
    { label: "965", value: 965 },
    { label: "970", value: 970 },
    { label: "975", value: 975 },
    { label: "980", value: 980 },
    { label: "985", value: 985 },
    { label: "990", value: 990 },
    { label: "995", value: 995 },
    { label: "1000", value: 1000 },
  ],
};
