import { ArrowBackIosNew } from "@mui/icons-material";
import { Box, Breadcrumbs, Button, Card, CardContent, IconButton, MenuItem, Paper, Select, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { secondaryLabel } from "../../helper";

const filters = [
    { label: "All notifications", value: "" },
    { label: "Alerts", value: "alerts" },
    { label: "Supplier connect request", value: "supplier_connect _request" },
    { label: "Supplier connect request status", value: "supplier_connect_request_status" },

]
const DesignbankNotifications = () => {
    const theme = useTheme();
    const isIpad = useMediaQuery(theme.breakpoints.down("md"));
    const navigate = useNavigate()
    let rows = []
    return (
        <Box padding={isIpad ? 0 : "20px"}>
            <Stack direction={"row"} alignItems="center" >
                <IconButton onClick={() => navigate(-1)}>
                    <ArrowBackIosNew />
                </IconButton>
                <Typography variant="h3" display="inline">
                    Notification history
                </Typography>
            </Stack>
            <Typography sx={{ ...secondaryLabel, ml: "40px" }}>Your notifications are listed below</Typography>

            <Card sx={{ mt: 4 }}>
                <CardContent>
                    <Stack direction={"row"} justifyContent="space-between">
                        <Stack rowGap={1.4}>
                            <Typography variant="h4" color={"text.secondary"} >
                                Today
                            </Typography>
                            <Typography color={"text.secondary"} >
                                Showing notifications
                            </Typography>
                        </Stack>
                        <Stack direction={"row"} alignItems="center">
                            <Select value={""} size="small">
                                {filters.map(obj => <MenuItem value={obj?.value}>{obj?.label}</MenuItem>)}
                            </Select>
                        </Stack>
                    </Stack>

                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell colSpan={2}>Notification</TableCell>
                                    <TableCell >Time</TableCell>
                                    <TableCell >Supplier</TableCell>
                                    <TableCell >Action</TableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {new Array(5).fill("").map(obj => <TableRow>
                                    <TableCell colSpan={2}>

                                    </TableCell>
                                    <TableCell >

                                    </TableCell>
                                    <TableCell >

                                    </TableCell>
                                    <TableCell >
                                        <Button size="small">See prodcut</Button>
                                    </TableCell>
                                </TableRow>)}
                            </TableBody>
                        </Table>
                    </TableContainer>

                </CardContent>

            </Card>
        </Box>
    );
};

export default DesignbankNotifications;
