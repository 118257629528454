import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import Wave from "react-wavify";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import {
  Avatar,
  Button,
  Paper,
  Stack,
  Typography,
  Box,
  Grid,
  useMediaQuery,
  Stepper,
  Step,
  StepLabel,
} from "@mui/material";

// import { ReactComponent as Logo } from "../../vendor/logo.svg";
import SignInComponent from "../../components/auth/SignIn";
import { spacing, useTheme } from "@mui/system";
import { ChatIllustration, MyFiles, People, WaveBackground } from "../../Svgs";
import "./index.css";
import { StarRounded } from "@mui/icons-material";

// const Brand = styled(Logo)`
//   fill: ${(props) => props.theme.palette.primary.main};
//   width: 64px;
//   height: 64px;
//   margin-bottom: 32px;
// `;

const Spacer = styled.div(spacing);

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;

const BigAvatar = styled(Avatar)`
  width: 102px;
  height: 102px;
  text-align: center;
  margin: 0 auto ${(props) => props.theme.spacing(5)};
  padding: 20px;
`;

function Onboarding() {
  const [transform, setTransform] = useState(false);
  const [isTransformed, setIsTransformed] = useState(false);
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const theme = useTheme();
  const primary = theme.palette.primary && theme.palette.primary.main;
  const isIpad = useMediaQuery(theme.breakpoints.down("md"));
  console.log(primary);
  useEffect(() => {
    if (transform) setTimeout(() => setIsTransformed(true), 1000);
    else setTimeout(() => setIsTransformed(false), 1000);
  }, [transform]);

  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <React.Fragment>
      {/* <Brand /> */}
      <Box
        sx={{
          position: "relative",
          // backgroundColor: "#EBF3FB",
          // height: "100vh",
          overflow: "hidden",
          height: "100vh",
          m: 0,
          // zIndex: -200,
        }}
      >
        <Box
          sx={{
            // position: "relative",
            // backgroundColor: "#efefef",
            // backgroundColor: "#EBF3FB",
            height: "100vh",
            width: "100%",
            alignItems: "center",
          }}
        >
          <Stack
            direction="row"
            py={4}
            px={4}
            sx={{ zIndex: 100 }}
            justifyContent={"space-between"}
            alignItems="center"
          >
            <img
              src="https://tanika.tech/wp-content/uploads/2021/04/new-logo.png"
              style={{
                height: isIpad ? "50px" : "70px",
                opacity: transform ? 0 : 1,
                // display: isTransformed ? "none" : "block",
                transition: "opacity 0.5s",
              }}
            />
            {/* <BigAvatar alt="Lucy" src="/static/img/brands/tanika_icon.png" /> */}
            <Stack direction="row" sx={{ marginLeft: "auto" }}>
              <Button
                // variant="contained"
                sx={{ backgroundColor: "#fff" }}
              >
                Sign In
              </Button>
              <Button variant="contained">Sign Up</Button>
            </Stack>
          </Stack>
          {!isTransformed && (
            <Grid
              container
              spacing={4}
              sx={{
                py: 4,
                px: 0,
                height: "80vh",

                //   display: isTransformed ? "none" : "block",
                opacity: transform ? 0 : 1,
                transition: "opacity 1s",
                // flexGrow: 1,
                // position: "relative",
                // margin: "auto",
                // backgroundColor: "#e8e8e8",
                // alignSelf: "streach",
              }}
            >
              <Grid
                item
                xs={12}
                md={6}
                sx={{
                  position: "relative",
                }}
              >
                <Box
                  sx={{
                    textAlign: "center",
                    position: "absolute",
                    top: "50%",
                    transform: "translateY(-50%)",
                    //   transform: "translateX(-25%)",
                    left: isIpad ? "0%" : "10%",
                    // backgroundColor: "red",
                    // textAlign: isIpad ? "center" : "left",
                    m: 0,
                  }}
                >
                  <Typography
                    variant="h1"
                    textAlign={isIpad ? "center" : "left"}
                    fontSize={isIpad ? "60px" : "80px"}
                    fontWeight="normal"
                    mx={isIpad ? 3 : 0}
                  >
                    Manage all your products from one place
                  </Typography>
                  <Button
                    fullWidth
                    sx={{ maxWidth: "400px", mt: 5 }}
                    size="large"
                    variant="contained"
                    onClick={() => setTransform(!transform)}
                  >
                    Get started
                  </Button>
                </Box>
              </Grid>
              {/* <Grid item xs /> */}
              <Grid
                item
                xs={12}
                sm={6}
                sx={{
                  position: "relative",
                  display: { xs: "none", lg: "block" },
                }}
              >
                <Testimonials />
              </Grid>
            </Grid>
          )}
          {transform && (
            <Box
              sx={{
                width: "100%",
                // height: "100%",
                alignContent: "center",
                position: "relative",
                opacity: isTransformed ? 1 : 0,
                transition: "opacity 0.5s",
                justifyContent: "center",
                display: "flex",
              }}
            >
              <Box
                sx={{
                  maxWidth: isIpad ? "100%" : "40%",
                  // position: "absolute",
                  // // top: "42%",
                  // left: "50%",
                  // transform: "translateX(-50%)",
                  zIndex: 100,
                  // transform: "translate(-50%, -50%)",
                }}
              >
                <Box
                  sx={{
                    minWidth: "100vh",
                    height: "10px",
                    // backgroundColor: "red",
                    flex: 1,
                    flexGrow: 1,
                  }}
                ></Box>
                {/* <Typography sx={{ color: "#fff" }}>hellow</Typography> */}
                <Carousel
                  responsive={responsive}
                  // showDots
                  // infinite
                  autoPlay
                  autoPlaySpeed={3000}
                  arrows={isIpad}
                  renderDotsOutside
                  // customDot={}
                >
                  <CarouselItem
                    title="Catalogs"
                    svg={<MyFiles height="300px" width="300px" />}
                  />
                  <CarouselItem
                    title="CRM"
                    svg={<People height="300px" width="300px" />}
                  />
                  <CarouselItem
                    title="Whatsapp"
                    svg={<ChatIllustration height="300px" width="300px" />}
                  />
                </Carousel>
                {/* <Stepper activeStep={activeStep}>
                  {["1", 2, 3].map((label, index) => {
                    const stepProps = {};
                    const labelProps = {};
                    if (isStepSkipped(index)) {
                      stepProps.completed = false;
                    }
                    return (
                      <Step
                        key={label}
                        itemProp={{ style: { color: "#fff" } }}
                        {...stepProps}
                      >
                        <StepLabel
                          itemProp={{ style: { color: "#fff" } }}
                          {...labelProps}
                          color="blue"
                        >
                          {}
                        </StepLabel>
                      </Step>
                    );
                  })}
                </Stepper> */}
              </Box>
            </Box>
          )}

          {/* <WaveBackground /> */}
        </Box>
        {!false && (
          <Stack
            direction="row"
            className="background"
            style={{
              transform: transform ? "translateX(-50%)" : "translateX(0)",
              transition: "transform 1s",
            }}
            justifyContent={"flex-end"}
          >
            <svg
              viewBox="0 0 350 150"
              preserveAspectRatio="none"
              style={{
                height: "100vh",
                width: "100vw",
                // marginLeft: "auto",
                // width: "500px",
                // backgroundColor: "blue",
              }}
            >
              <path
                d="M204.29,-2.45 C145.60,83.39 318.28,90.30 279.34,152.47 L351.01,150.50 L351.58,-1.47 Z"
                // d="M213.19,0.00 C91.98,47.87 318.28,90.30 279.34,152.47 L351.01,150.50 L351.58,-1.47 Z"
                // d="M213.19,0.00 C91.98,47.87 248.87,82.41 224.04,152.47 L351.01,150.50 L351.58,-1.47 Z"
                // d="M213.19,0.00 C141.65,84.38 248.87,82.41 229.12,150.50 L297.40,151.48 L296.84,-1.47 Z"
                stroke="none"
                fill={!transform && isIpad ? "#fff" : primary}
                // fill={!transform ? "#fff" : primary}
                style={{ transition: "fill 0.2s" }}
              ></path>
              {/* <path
              d="M213.19,0.00 C141.65,84.38 248.87,82.41 202.99,150.00 L265.80,151.48 L265.24,-1.47 Z"
              stroke="none"
              fill={"#08f"}
            ></path> */}
            </svg>
            <Box
              style={{
                height: "100vh",
                width: "100vw",
                backgroundColor: !transform ? "#fff" : primary,
                marginLeft: "-3px",
                transition: "backgroundColor 0.5s",
              }}
            ></Box>
          </Stack>
        )}
        {isIpad && (
          <svg
            viewBox="0 0 350 150"
            preserveAspectRatio="none"
            style={{
              height: "100vh",
              width: "100vw",
              // marginLeft: "auto",
              // width: "500px",
              // backgroundColor: "blue",
            }}
          >
            <path
              d="M0.00,49.99 C150.00,150.00 349.20,-49.99 500.00,49.99 L500.00,150.00 L0.00,150.00 Z"
              // d="M213.19,0.00 C91.98,47.87 318.28,90.30 279.34,152.47 L351.01,150.50 L351.58,-1.47 Z"
              // d="M213.19,0.00 C91.98,47.87 248.87,82.41 224.04,152.47 L351.01,150.50 L351.58,-1.47 Z"
              // d="M213.19,0.00 C141.65,84.38 248.87,82.41 229.12,150.50 L297.40,151.48 L296.84,-1.47 Z"
              stroke="none"
              fill={primary}
            ></path>
          </svg>
        )}
      </Box>
    </React.Fragment>
  );
}

export default Onboarding;

const CarouselItem = ({ svg, title }) => {
  return (
    <Box sx={{ textAlign: "center", mt: 5, color: "#fff" }}>
      <Box>{svg}</Box>
      <Typography variant="h4">{title}</Typography>
      <Typography variant="body1" lineHeight={1.4} fontSize="18px" mt={5}>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam iaculis
        augue leo, in suscipit libero ornare in. Vivamus laoreet scelerisque
        nibh quis tempor. Fusce dignissim, urna a ultrices interdum, augue leo
        sagittis quam, a lacinia ante orci sed mauris. Vestibulum rhoncus orci
        erat, eu venenatis odio accumsan eget.
      </Typography>
    </Box>
  );
};

const Testimonials = () => {
  return (
    <Box
      sx={{
        position: "absolute",
        top: "40%",
        left: "50%",
        transform: "translateX(-25%)",
      }}
    >
      <Stack direction="row" justifyContent={"center"}>
        <CustomerImage src="https://retailer.tanika.tech/wp-content/themes/retailer-dashboard/img/testimonials/talla.png" />
        <CustomerImage
          src="https://retailer.tanika.tech/wp-content/themes/retailer-dashboard/img/testimonials/Manjunatha-Devadatta-Nayak.png"
          transform={"translateX(-15px)"}
        />
        <CustomerImage
          transform={"translateX(-30px)"}
          src="https://retailer.tanika.tech/wp-content/themes/retailer-dashboard/img/testimonials/mohan.png"
        />
      </Stack>
      <Stack direction={"row"} justifyContent="center" mt={5}>
        <StarRounded sx={{ color: "#fff" }} />
        <StarRounded sx={{ color: "#fff" }} />
        <StarRounded sx={{ color: "#fff" }} />
        <StarRounded sx={{ color: "#fff" }} />
        <StarRounded sx={{ color: "#fff" }} />
      </Stack>
    </Box>
  );
};
const CustomerImage = ({ src, transform }) => {
  return (
    <Box sx={{ transform }}>
      <img
        src={src}
        style={{
          height: "90px",
          width: "90px",
          borderRadius: "50%",
          border: "2px solid white",
          padding: "5px",
        }}
      />
    </Box>
  );
};

CustomerImage.defaultProps = {
  transform: "translateX(0px)",
};
