import {
  GET_COUNTRIES_DATA,
  SET_PERSIST_DATA,
} from "../actions/persistActions";

const initialState = {
  countries: [],
  states: [],
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_PERSIST_DATA:
      return { ...state, ...payload };
    case GET_COUNTRIES_DATA:
      return { ...state, ...payload };
    default:
      return state;
  }
};

export default reducer;
