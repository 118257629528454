import { Grid } from '@mui/material';
import React from 'react'
import Thumbnail from './Thumbnail';

const ImageGrid = ({ medias, containerRef, onSelect, selected, setViewImage, handleScroll }) => {
    return (
        <>
            <Grid container spacing={4} rowSpacing={4} sx={{ mt: 0.6 }}  >
                {medias?.map((obj) => {
                    return (
                        <Grid item md={3} key={obj?.id}>
                            <Thumbnail data={obj} onSelect={onSelect} selected={selected} setViewImage={setViewImage} />
                        </Grid>
                    );
                })}
            </Grid>
        </>
    )
}

export default ImageGrid