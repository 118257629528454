import { HourglassBottomRounded } from "@mui/icons-material";
import { Box, Button, IconButton, Typography } from "@mui/material";
import { useMemo } from "react";
import { BaseEdge, EdgeLabelRenderer, getBezierPath, useReactFlow } from "reactflow";
import { yellowJwero } from "..";

export function CustomEdge({
    id,
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
    style = {},
    markerEnd,
}) {

    const [edgePath, labelX, labelY] = getBezierPath({
        sourceX,
        sourceY,
        sourcePosition,
        targetX,
        targetY,
        targetPosition,
    });
    const { getEdge, getEdges } = useReactFlow();
    let edges = getEdges()
    console.log(getEdge(id));
    let edge = useMemo(() => getEdge(id), [edges])

    return (
        <>
            <BaseEdge path={edgePath} markerEnd={markerEnd} style={style} />
            <EdgeLabelRenderer>
                <div
                    style={{
                        position: 'absolute',
                        transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
                        fontSize: 12,
                        // everything inside EdgeLabelRenderer has no pointer events by default
                        // if you have an interactive element, set pointer-events: all
                        pointerEvents: 'all',
                    }}
                    className="nodrag nopan"
                >
                    {edge?.delay ?
                        <Box p={1} sx={{ backgroundColor: "primary.main", borderRadius: 4, color: "background.paper", }} >
                            <Typography variant="subtitle2" >{edge?.delay?.value} {edge?.delay?.label}</Typography>
                        </Box>

                        : <IconButton
                            sx={{
                                backgroundColor: yellowJwero || "primary.main",
                                color: "background.paper",
                                "&:hover": {
                                    backgroundColor: yellowJwero || "primary.main",
                                    color: "background.paper",
                                },
                            }}
                        >
                            <HourglassBottomRounded />
                        </IconButton>}
                    {/* <Button variant="outlined" startIcon={<HourglassBottomRounded />}>
                        Delay
                    </Button> */}

                    {/* <IconButton     >
                        <Add />
                    </IconButton> */}
                    {/* <button className="edgebutton"
                    // onClick={(event) => onEdgeClick(event, id)}
                    >
                        ×
                    </button> */}
                </div>
            </EdgeLabelRenderer>
        </>
    );
}