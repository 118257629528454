import "react-app-polyfill/stable";

import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";

import reportWebVitals from "./utils/reportWebVitals";
import App from "./App";

import "./mocks";

import { ThemeProvider } from "./contexts/ThemeContext";
import { PersistGate } from "redux-persist/integration/react";
import { persistor, store } from "./redux/store";
import { Provider, useDispatch, useSelector } from "react-redux";

import * as serviceWorker from "./serviceWorker";
import { TourProvider } from "@reactour/tour";
import { Button } from "@mui/material";
import jssPreset from "@mui/styles/jssPreset";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import {
  // createTheme,
  ThemeProvider as MuiThemeProvider,
  useTheme as muiUseTheme,
} from "@mui/material/styles";
import StylesProvider from "@mui/styles/StylesProvider";
import { AuthProvider } from "./contexts/JWTContext";
import { StyledEngineProvider } from "@mui/styled-engine-sc";
import createTheme from "./theme";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { create } from "jss";
import useTheme from "./hooks/useTheme";
import { setHelperData } from "./redux/actions/helperActions";
import { isArrayWithValues } from "./helper";
import { GoogleOAuthProvider } from "@react-oauth/google";

const jss = create({
  ...jssPreset(),
  insertionPoint: document.getElementById("jss-insertion-point"),
});

ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <AppWrapper />
      </PersistGate>
    </Provider>
  </BrowserRouter>,
  document.getElementById("root")
);

function AppWrapper() {
  const theme = createTheme({
    components: {
      MuiPaper: {
        defaultProps: {
          sx: {
            boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
          },
        },
        styleOverrides: {
          root: { boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px" },
        },
      },
    },
  });
  // const { theme } = useTheme();
  const dispatch = useDispatch();
  const restrictedTour = useSelector((state) => state.helper.restrictedTour);
  return (
    <GoogleOAuthProvider clientId="448848454597-rbba0c3q0kbcn0f8l893813l98rgra7p.apps.googleusercontent.com">
      <ThemeProvider>
        <AuthProvider>
          <StylesProvider jss={jss}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <StyledEngineProvider injectFirst>
                <MuiThemeProvider theme={theme}>
                  <ThemeProvider theme={theme}>
                    <TourProvider
                      styles={{
                        popover: (base) => ({
                          ...base,
                          borderRadius: "10px",
                        }),
                      }}
                      position="right"
                      onClickMask={() => {}}
                      padding={{ mask: 0, popover: 0, wrapper: 0 }}
                      showDots={false}
                      showCloseButton={!restrictedTour}
                      afterClose={() => {
                        dispatch(
                          setHelperData({
                            restrictedTour: false,
                            currentTour: "",
                          })
                        );
                      }}
                      nextButton={({
                        currentStep,
                        setCurrentStep,
                        stepsLength,
                        setIsOpen,
                      }) => {
                        if (
                          isArrayWithValues(restrictedTour) &&
                          restrictedTour.includes(currentStep)
                        )
                          return null;
                        return (
                          <Button
                            // variant="contained"
                            onClick={() => {
                              if (currentStep === stepsLength) {
                                setCurrentStep(0);
                                setIsOpen(false);
                              } else setCurrentStep((state) => state + 1);
                            }}
                          >
                            {currentStep === stepsLength ? "Finish" : "Next"}
                          </Button>
                        );
                      }}
                      prevButton={({
                        currentStep,
                        setCurrentStep,
                        stepsLength,
                        setIsOpen,
                      }) => {
                        if (
                          isArrayWithValues(restrictedTour) &&
                          restrictedTour.includes(currentStep)
                        )
                          return null;
                        return (
                          <Button
                            onClick={() => setCurrentStep((state) => state - 1)}
                            disabled={currentStep === 0}
                          >
                            {"Back"}
                          </Button>
                        );
                      }}
                    >
                      <App />
                    </TourProvider>
                  </ThemeProvider>
                </MuiThemeProvider>
              </StyledEngineProvider>
            </LocalizationProvider>
          </StylesProvider>
        </AuthProvider>
      </ThemeProvider>
    </GoogleOAuthProvider>
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

// serviceWorker.unregister();
// serviceWorker.register();

// if ("serviceWorker" in navigator) {
//   window.addEventListener("load", () => {
//     navigator.serviceWorker
//       .register("/serviceWorker.js")
//       .then((registration) => {
//         console.log("Service Worker registered:", registration);
//       })
//       .catch((error) => {
//         console.error("Service Worker registration failed:", error);
//       });
//   });
// }

// if ("serviceWorker" in navigator) {
//   navigator.serviceWorker
//     .register("/path/to/sw.js")
//     .then(function (registration) {
//       console.log("Service worker registration successful");
//     })
//     .catch(function (err) {
//       console.error("Service worker registration failed:", err);
//     });
// }
