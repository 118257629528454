import {
  Add,
  AutoStoriesOutlined,
  ConnectingAirportsOutlined,
  Groups,
  LanguageOutlined,
  Share,
  Upload,
} from "@mui/icons-material";
import {
  Box,
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  Chip,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  Icon,
  InputLabel,
  Link,
  MenuItem,
  Select,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/system";
import axios from "axios";
import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import {
  formatDate,
  getAxiosError,
  getRandomInt,
  getToken,
  getWebsite,
  isArrayWithValues,
  isObjWithValues,
} from "../../helper";
import { getProductGroups } from "../../redux/actions/settingActions";
import { UnderConstructionComponent } from "../design_bank/DesignBank";

function RetailerConnect() {
  const [connectedRetailers, setConnectedRetailer] = useState([]);
  const [invitedRetailers, setInvitedRetailers] = useState([]);
  const [productGroups, setProductGroups] = useState([]);
  const [totalProducts, setTotalProducts] = useState([]);
  const [sortBy, setSortBy] = useState("accepted");

  const user = useSelector((state) => state.user);
  useEffect(() => {
    if (isObjWithValues(user) && user.store_used)
      setTotalProducts(user.store_used.products);
  }, [user]);

  const retailerInvites = useSelector(
    (state) => state.customers.retailer_invites
  );
  const reduxProductGroups = useSelector(
    (state) => state.settings.productGroups
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (isArrayWithValues(reduxProductGroups))
      setProductGroups(reduxProductGroups);
  }, [reduxProductGroups]);

  useEffect(() => {
    // if (!isArrayWithValues(retailerInvites)) dispatch(getRetailerInvites());
    if (!isArrayWithValues(reduxProductGroups)) dispatch(getProductGroups());
  }, []);

  useEffect(() => {
    if (isArrayWithValues(retailerInvites)) {
      let temp = async () => {
        let customers = await fetchCustomers({
          include: retailerInvites.map((i) => i.retailer_id_in_supplier),
        });
        if (isArrayWithValues(customers)) {
          let invitedRetailers = [];
          let connectedRetailers = [];
          let retailerActivityOptions = [];
          for (let customer of customers) {
            let { meta_data, id, first_name, last_name } = customer;
            if (!isArrayWithValues(meta_data)) continue;
            let invite_time = retailerInvites.find(
              (i) => i.retailer_id_in_supplier == id
            );
            invite_time = invite_time ? invite_time.id : "";
            let companyNameObj = meta_data.find(
              (i) => i.key === "company_name"
            );
            let connectStatusObj = meta_data.find(
              (i) => i.key === "connect_status"
            );
            let allProductAccessObj = meta_data.find(
              (i) => i.key === "all_product_access"
            );
            let productGroupsObj = meta_data.find(
              (i) => i.key === "product_groups"
            );

            let company_name,
              connect_status,
              company_name_array = [],
              all_product_access,
              product_groups;
            if (companyNameObj) company_name = companyNameObj.value;
            if (connectStatusObj) connect_status = connectStatusObj.value;
            if (allProductAccessObj)
              all_product_access = allProductAccessObj.value;
            if (productGroupsObj) product_groups = productGroupsObj.value;
            if (typeof company_name === "string")
              company_name_array = company_name.split(" ");
            if (connect_status === "invited")
              invitedRetailers.push({
                company_name_array,
                connect_status,
                company_name,
                invite_time,
                first_name,
                last_name,
                id,
              });
            if (connect_status === "connected") {
              retailerActivityOptions.push(`retailer_activity_${id}`);
              connectedRetailers.push({
                all_product_access,
                company_name_array,
                connect_status,
                product_groups,
                company_name,
                invite_time,
                first_name,
                last_name,
                id,
              });
            }
          }
          setInvitedRetailers(invitedRetailers);
          if (isArrayWithValues(retailerActivityOptions)) {
            let activities = await fetchRetailerActivity(
              retailerActivityOptions.join()
            );
            if (isObjWithValues(activities)) {
              let object = {};
              for (let id in activities) {
                let obj = connectedRetailers.find((i) => i.id == id);
                obj.activities = activities[id];
                object[id] = obj;
              }
              setConnectedRetailer(Object.values(object));
            }
          }
        }
      };
      temp();
    }
  }, [retailerInvites]);

  useEffect(() => {
    if (
      isArrayWithValues(productGroups) &&
      isArrayWithValues(connectedRetailers)
    ) {
      let retailers = [...connectedRetailers];
      for (let retailer of retailers) {
        let { all_product_access, product_groups } = retailer;
        if (all_product_access) retailer.total_product_access = totalProducts;
        if (!all_product_access && isArrayWithValues(product_groups)) {
          let total_product_access = 0;
          for (let group of productGroups) {
            let { id, product_ids } = group;
            if (product_groups.includes(id))
              total_product_access = total_product_access + product_ids.length;
          }
          retailer.total_product_access = total_product_access;
        }
      }
      setConnectedRetailer(retailers);
    }
  }, [connectedRetailers, productGroups]);

  return (
    <Box>
      <Stack>
        <Typography variant="h3" gutterBottom display="inline">
          Retailer Connect
        </Typography>

        <Breadcrumbs aria-label="Breadcrumb" mt={2}>
          <Link component={NavLink} to="/">
            Dashboard
          </Link>
          {/* <Link component={NavLink} to="/">
              Products
            </Link> */}
          <Typography>Connect</Typography>
        </Breadcrumbs>
      </Stack>
      {/* <UnderConstructionComponent /> */}
      <Stack
        direction="row"
        justifyContent={"space-between"}
        alignItems="center"
        mt={8}
      >
        <FormControl size="small">
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={sortBy}
            // label="Age"
            onChange={(e) => setSortBy(e.target.value)}
          >
            <MenuItem value={"sent"}>Invite Sent</MenuItem>
            <MenuItem value={"accepted"}>Invite accepted</MenuItem>
          </Select>
        </FormControl>
        <Button variant="contained" size="small" startIcon={<Add />}>
          Add retailer
        </Button>
      </Stack>
      <Grid container spacing={4} mt={1}>
        {sortBy === "accepted" &&
          connectedRetailers.map((i) => {
            return (
              <Grid item xs={12} sm={12} lg={6} key={i.id}>
                <InviteAcceptedCard retailer={i} />
              </Grid>
            );
          })}
        {sortBy === "sent" &&
          invitedRetailers.map((i) => {
            return (
              <Grid item xs={12} sm={12} lg={6} key={i.id}>
                <InviteSentCard retailer={i} />
              </Grid>
            );
          })}
      </Grid>
    </Box>
  );
}

export default RetailerConnect;

export const fetchCustomers = async (params, setState) => {
  const website = getWebsite();
  const token = getToken();
  if (website && token) {
    try {
      let res = await axios({
        url: `${website}/wp-json/wc/v3/customers`,
        params,
        headers: {
          Authorization: `Basic ${token}`,
        },
      });
      if (res.data) return res.data;
    } catch (error) {
      console.log(getAxiosError(error), "Fetching customers error");
    }
  }
};

const brands = [
  // "https://jwero.ai/wp-content/uploads/2021/04/talla1.png",
  "https://jwero.ai/wp-content/uploads/2021/04/s1.png",
  "https://jwero.ai/wp-content/uploads/2022/01/kc.png",
  "https://jwero.ai/wp-content/uploads/2021/08/ratnalayajewellers.png",
  "https://jwero.ai/wp-content/uploads/2021/04/images-8-e1646711743728.png",
  "https://jwero.ai/wp-content/uploads/2021/04/atul1.png",
  "https://jwero.ai/wp-content/uploads/2021/04/akshaya1.png",
  "https://jwero.ai/wp-content/uploads/2021/04/13-1.png",
];

const InviteSentCard = ({ retailer }) => {
  let { first_name, last_name, company_name, invite_time, company_name_array } =
    retailer;
  return (
    <Card
      sx={{
        borderRadius: "15px",
        boxShadow: "0px 0px 3px rgba(0, 0, 0, 0.25)",
        // boxShadow: "1px 1px 3px 0.1px rgba(158,158,158,0.5)",
        py: 2.4,
      }}
    >
      <CardContent
        sx={{
          "&:last-child": { paddingBottom: "16px" },
        }}
      >
        <Grid container spacing={2}>
          <Grid item>
            <Stack direction="row" alignItems={"center"}>
              {/* <img
                src={brands[getRandomInt(9)]}
                style={{ height: "40px", width: "80px" }}
              /> */}

              <TempLogo array={company_name_array} />
              <Stack ml={2}>
                <Typography variant="h6">{company_name}</Typography>
                <Typography variant="body2">
                  {first_name || ""} {last_name || ""}
                </Typography>
              </Stack>
            </Stack>
          </Grid>
          <Grid xs item />
          <Grid item sx={{}}>
            <Stack ml={2}>
              <Typography
                variant="body2"
                sx={{ fontSize: "13px", color: "primary" }}
              >
                Status: Invite sent
              </Typography>
              <Typography
                variant="caption"
                sx={{ fontSize: "12px", color: "text.secondary" }}
              >
                Date:{" "}
                {invite_time &&
                  formatDate(new Date(invite_time), "dd:mm:yyyy hh:mm am/pm")}
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

const InviteAcceptedCard = ({ retailer }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  let [totalShares, setTotalShares] = useState(0);
  const [totalRemoveFromWebite, setTotalRemovedFromWebsite] = useState(0);
  let [totalPulled, setTotalPulled] = useState(0);
  let [totalAddedToWebsite, setTotalAddedToWebsite] = useState(0);
  const [totalInCatalogs, setTotalInCatalogs] = useState(0);

  useEffect(() => {
    let { activities } = retailer;
    if (activities) {
      if (isArrayWithValues(activities.pulled))
        setTotalPulled(activities.pulled.length);
      if (isArrayWithValues(activities.added_to_website))
        setTotalAddedToWebsite(activities.added_to_website.length);
      if (isArrayWithValues(activities.removed_from_websites))
        setTotalRemovedFromWebsite(activities.removed_from_websites.length);
      if (isArrayWithValues(activities.catalogs))
        setTotalInCatalogs(activities.catalogs.length);
      if (isArrayWithValues(activities.social_media))
        setTotalShares(activities.social_media.length);
    }
  }, [retailer]);
  let {
    first_name,
    last_name,
    company_name,
    invite_time,
    company_name_array,
    total_product_access,
  } = retailer;
  const isDarkTheme = theme.palette && theme.palette.mode === "dark";
  return (
    <Card
      sx={{
        borderRadius: "15px",
        boxShadow: "0px 0px 3px rgba(0, 0, 0, 0.25)",
      }}
    >
      <CardContent sx={{ margin: "20px" }}>
        <Grid container spacing={2}>
          <Grid item>
            <Stack direction="row" alignItems={"center"}>
              {/* <img
                src={brands[getRandomInt(9)]}
                style={{ height: "40px", width: "80px" }}
              /> */}
              {/* <TempLogo array={company_name_array} /> */}
              <Stack ml={0}>
                <Typography variant="h6">
                  {company_name}
                  {isMobile && (
                    <Chip
                      size="small"
                      label="Connected"
                      color="success"
                      sx={{
                        border: isDarkTheme ? "0.7px solid" : "0px",
                        backgroundColor: isDarkTheme
                          ? "transparent"
                          : "#edfaee",
                        ml: 1,
                      }}
                      variant="outlined"
                    />
                  )}
                </Typography>
                <Typography variant="body2">
                  {first_name || ""} {last_name || ""}
                </Typography>
                {isMobile && (
                  <Typography
                    variant="body2"
                    mt={1}
                    sx={{ fontSize: "12px", color: "text.secondary" }}
                  >
                    Date:{" "}
                    {invite_time &&
                      formatDate(
                        new Date(invite_time),
                        "dd:mm:yyyy hh:mm am/pm"
                      )}
                  </Typography>
                )}
              </Stack>
            </Stack>
          </Grid>
          <Grid xs item />
          {!isMobile && (
            <Grid item sx={{}}>
              <Stack ml={2}>
                <Typography
                  variant="body2"
                  sx={{ fontSize: "13px", color: "primary" }}
                >
                  Status: Connected
                </Typography>
                <Typography
                  variant="caption"
                  sx={{ fontSize: "12px", color: "text.secondary" }}
                >
                  Date:{" "}
                  {invite_time &&
                    formatDate(new Date(invite_time), "dd:mm:yyyy hh:mm am/pm")}
                </Typography>
              </Stack>
            </Grid>
          )}
        </Grid>
        <Grid container style={{ marginTop: "14px" }} spacing={3}>
          <Grid item xs={6}>
            <Stack direction="row">
              <Typography>Product Access</Typography>
            </Stack>
            <Divider sx={{ mt: 2, mb: 1.5 }} />
            <Typography fontSize={"25px"}>{total_product_access}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Stack
              justifyContent={"space-between"}
              alignItems="center"
              direction="row"
            >
              <Typography>
                {isMobile ? "Retailer Inventory" : "Product Added to Inventory"}
              </Typography>
              {/* <Upload /> */}
            </Stack>
            <Divider sx={{ mt: 2, mb: 1.5 }} />
            <Typography fontSize={"25px"}>{totalPulled}</Typography>
          </Grid>
        </Grid>
        <Grid container style={{ marginTop: "14px" }} spacing={3}>
          <Grid item md={4}>
            <Temp
              icon={<LanguageOutlined />}
              title={isMobile || true ? "Website" : "Added In Website"}
              value={totalAddedToWebsite}
            />
          </Grid>
          <Grid item md={4}>
            <Temp
              icon={<Share />}
              title={isMobile || true ? "Shares" : "Social Media Shares"}
              value={totalAddedToWebsite}
            />
          </Grid>
          <Grid item md={4}>
            <Temp
              icon={<AutoStoriesOutlined />}
              title={isMobile || true ? "Catalogs" : "Added in Catalogs"}
              value={totalInCatalogs}
            />
          </Grid>
        </Grid>
        {/* <Box sx={{ mt: 8 }}>
          <Grid
            container
            spacing={3}
            alignItems="center"
            justifyContent={"space-around"}
          >
            {isMobile && (
              <Grid item xs={12}>
                <ReactApexChart
                  options={getRedialOptions({
                    label: "Label 2",
                    percentage: 15,
                    trackBackground: "#629f2f",
                  })}
                  series={[35]}
                  type="radialBar"
                  height={220}
                  // minWidth={"0%"}
                />
              </Grid>
            )}
            <Grid item xs={6} md={4}>
              <ReactApexChart
                options={getRedialOptions({
                  label: "Label 1",
                  percentage: 135,
                  trackBackground: "rgba(89, 102, 147, 1)",
                })}
                series={[35]}
                type="radialBar"
                height={180}
                // minWidth={"0%"}
              />
            </Grid>
            {!isMobile && (
              <Grid item xs={6} md={4}>
                <ReactApexChart
                  options={getRedialOptions({
                    label: "Label 2",
                    percentage: 15,
                    trackBackground: "#629f2f",
                  })}
                  series={[35]}
                  type="radialBar"
                  height={210}
                  // minWidth={"0%"}
                />
              </Grid>
            )}
            <Grid item xs={6} md={4}>
              <ReactApexChart
                options={getRedialOptions({
                  label: "Label 3",
                  percentage: 215,
                  trackBackground: "rgba(89, 102, 147, 1)",
                })}
                series={[65]}
                type="radialBar"
                height={180}
                // minWidth={"0%"}
              />
            </Grid>
          </Grid>
        </Box> */}
        {/* <Stack direction="row"></Stack> */}
      </CardContent>
    </Card>
  );
};

const Temp = ({ icon, title, value }) => {
  return (
    <Stack
      direction="row"
      alignItems="center"
      // justifyContent={"center"}
      justifyContent={"space-between"}
      sx={{
        border: "0.4px solid",
        padding: "5px 8px",
        borderRadius: "7px",
        paddingRight: "18px",
      }}
    >
      <Box sx={{ ml: 3 }}>
        <Typography fontSize={"20px"}>{value}</Typography>
        <Typography fontSize={"11px"} color="text.secondary">
          {title}
        </Typography>
      </Box>
      {icon}
    </Stack>
  );
};

function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress
        variant="determinate"
        sx={{ borderRadius: "10px" }}
        {...props}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography variant="h2" component="div" color="text.secondary">
          {`${Math.round(props.label)}`}
        </Typography>
      </Box>
    </Box>
  );
}

const getRedialOptions = ({ label, percentage, trackBackground }) => {
  return {
    chart: {
      type: "radialBar",
      toolbar: {
        show: false,
      },
      width: "100%",
      animations: { enabled: false },
    },
    plotOptions: {
      radialBar: {
        startAngle: 0,
        endAngle: 360,
        hollow: {
          margin: 0,
          size: "70%",
          background: "#fff",
          image: undefined,
          imageOffsetX: 0,
          imageOffsetY: 0,
          position: "front",
          dropShadow: {
            enabled: true,
            top: 2,
            left: 0,
            blur: 4,
            opacity: 0.24,
          },
        },
        track: {
          background: "#ededed",
          strokeWidth: "67%",
          margin: 0, // margin is in pixels
          dropShadow: {
            enabled: true,
            top: -3,
            left: 0,
            blur: 4,
            opacity: 0.35,
          },
        },

        dataLabels: {
          show: true,
          name: {
            offsetY: -10,
            show: Boolean(label),
            color: "#888",
            fontSize: "14px",
          },
          value: {
            formatter: (val) => percentage || 0,
            offsetY: 10,
            color: "#111",
            fontSize: "28px",
            show: true,
          },
        },
      },
    },
    fill: {
      type: "solid",
      colors: [`${trackBackground || "#21AC28"}`],
      gradient: {
        shade: "dark",
        type: "horizontal",
        shadeIntensity: 0.5,
        gradientToColors: ["#21AC28"],
        inverseColors: true,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 100],
      },
    },
    stroke: {
      lineCap: "round",
    },
    labels: [label],
  };
};

const TempLogo = ({ array }) => {
  array = isArrayWithValues(array)
    ? array.length < 2
      ? ["Company", "Logo"]
      : array
    : ["Company", "Logo"];
  return (
    <Box
      sx={{
        textAlign: "center",
        // backgroundColor: "#dce8fa",
        py: 1.4,
        px: 2,
        borderRadius: "5px",
        minWidth: "70px",
      }}
    >
      <Typography
        fontSize={"13px"}
        fontWeight="600"
        sx={{
          transform: "scale(1,1.2)",
          color: "primary",
          fontFamily: "Monaco",
        }}
      >
        {array[0] || ""}
      </Typography>
      <Typography sx={{ mt: 0, color: "secondary" }} fontSize={"12px"}>
        {array[1] || ""}
      </Typography>
    </Box>
  );
};

const fetchRetailerActivity = async (options) => {
  let website = await getWebsite();
  let token = await getToken();
  if (website && token) {
    try {
      let res = await axios({
        url: `${website}/wp-json/store/v1/settings`,
        params: { options },
        headers: {
          Authorization: `Basic ${token}`,
        },
      });
      if (res.data.success && isObjWithValues(res.data.data)) {
        let obj = {};
        for (let i in res.data.data) {
          let id = i.replace("retailer_activity_", "");
          obj[id] = res.data.data[i];
        }
        return obj;
      } else return {};
    } catch (error) {
      console.log(getAxiosError(error));
      return {};
    }
  }
};
