import { Box, Stack, Tab, Tabs, Typography } from "@mui/material";
import React, { useState } from "react";
import { TabPanel } from "../../../../helper";
import Integration from "./Integration";

function LoyltyRewardz() {
  const [selectedTab, setSelectedTab] = useState("overview");
  return (
    <Box sx={{ height: "100%", paddingRight: "15px" }}>
      <Stack
        sx={{
          position: "sticky",
          top: "0",
          backgroundColor: "#fff",
          zIndex: 13,
          margin: 0,
          padding: "12px",
        }}
      >
        <Stack
          direction="row"
          justifyContent={"space-between"}
          alignItems="center"
        >
          <Typography
            variant="h3"
            sx={{ display: "flex", alignItems: "center", gap: "10px" }}
          >
            {/* <PaysharpSvg /> Loylty Rewardz */}
            <img
              src="/static/img/brands/loylty-rewardz.webp"
              width="25px"
              height="25px"
              alt="Loylty Rewardz"
            />
            Loylty Rewardz
          </Typography>
        </Stack>
        <Tabs
          onChange={(e, newValue) => setSelectedTab(newValue)}
          value={selectedTab}
          sx={{ borderBottom: 1, borderColor: "divider" }}
        >
          <Tab label="Overview" value="overview" />
          {/* <Tab label="How to Use" value="how_to_use" /> */}
          <Tab label="Integration" value="integration" />
          {/* <Tab label="Settings" value="settings" /> */}
        </Tabs>
      </Stack>
      <TabPanel value={selectedTab} index={"overview"}>
        <Overview />
      </TabPanel>
      {/* <TabPanel value={selectedTab} index={"how_to_use"}>
        <HowToUse />
      </TabPanel> */}
      <TabPanel value={selectedTab} index={"integration"}>
        <Integration />
      </TabPanel>

      {/* <TabPanel value={selectedTab} index={"settings"}>
        <Settings />
      </TabPanel> */}
    </Box>
  );
}

export default LoyltyRewardz;

function Overview() {
  return <Typography>Overview</Typography>;
}
