import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import IntegrationView from "../../helper/IntegrationView";
import { WebchatSvg } from "../../../../Svgs";
import {
  backendApiCall,
  primaryLabel,
  settingsEndpoint,
} from "../../../../helper";
import {
  addUpdateNestedSettings,
  editSettings,
  fetchAndUpdateSingleSettings,
} from "../../../../redux/actions/settingActions";
import { useDispatch, useSelector } from "react-redux";
// import { setGlobalToast } from "../../../../redux/actions/helperActions";
import { getWebchatScript } from "./utils";
import { backendEndpoints } from "../../../chat_new/apiFunctions";
import { setGlobalToast } from "../../../../redux/actions/helperActions";

function Integration() {
  const integratingWebchat = useSelector(
    (state) => state?.settings?.integratingWebchat
  );
  const webchatIntegration = useSelector(
    (state) => state?.settings?.webchatIntegration
  );
  const mainUserDetails = useSelector((state) => state?.user?.chatsCreds);

  const dispatch = useDispatch();
  const onIntegrate = async (props = {}) => {
    let { revoke } = props;
    try {
      dispatch(editSettings({ integratingWebchat: true }));

      let res = await backendApiCall({
        endpoint: `${backendEndpoints.settings}/webchat_integration`,
        method: "PUT",
        data: {
          data: { integrated: !revoke },
        },
      });
      await settingsEndpoint({
        endpoint: "custom_footer_code",
        method: "POST",
        data: revoke
          ? "<script></script>"
          : getWebchatScript({ widget_id: mainUserDetails?.widget_id }),
      });

      if (res?.success)
        dispatch(
          editSettings({
            webchatIntegration: res?.data,
          })
        );
      dispatch(
        setGlobalToast({
          show: true,
          message: `Webchat has been ${
            revoke ? "revoked" : "integrated"
          } successfully`,
          severity: "success",
        })
      );
    } catch (error) {
      console.log(error);
      dispatch(
        setGlobalToast({
          show: true,
          message: `Unable to ${revoke ? "revoke" : "integrate"} Webchat`,
          severity: "error",
        })
      );
    } finally {
      dispatch(editSettings({ integratingWebchat: false }));
    }
  };
  return (
    <Box sx={{ p: 3 }}>
      <IntegrationView
        onIntegrate={onIntegrate}
        onRevoke={() => onIntegrate({ revoke: true })}
        integrationTitle={
          <Box>
            <Stack spacing={2} direction="row" alignItems="center">
              <WebchatSvg height={25} width={25} />
              <Typography sx={{ ...primaryLabel }}>Webchat</Typography>
            </Stack>
            {/* {reduxAmazonIntegration?.integrated && (
              <Typography sx={{ ...secondaryLabelSmall, mt: 1 }}>
                Integrated on: 12 May, 2023
              </Typography>
            )} */}
          </Box>
        }
        subtitle={
          "With Webchat integration, visitors coming to your website can easily solve their queries"
        }
        integrateButtonProps={{
          disabled: integratingWebchat,
          children: integratingWebchat ? "Integrating" : "Integrate",
        }}
        revokeButtonProps={{
          disabled: integratingWebchat,
          children: integratingWebchat ? "Revoking" : "Revoke",
        }}
        isIntegrated={webchatIntegration?.integrated}
      />
    </Box>
  );
}

export default Integration;
