import {
  Add,
  Cancel,
  Close,
  Delete,
  Diamond,
  ExpandMore,
  KeyboardArrowDown,
  LaunchRounded,
  Person,
  Remove,
  Search,
  SendRounded,
  TableRows,
} from "@mui/icons-material";
import {
  Box,
  Collapse,
  Divider,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TextField,
  InputAdornment,
  InputLabel,
  Checkbox,
  ListItemText,
  OutlinedInput,
  ButtonGroup,
  Button,
  Tabs,
  Tab,
  Tooltip,
  useMediaQuery,
  LinearProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  CircularProgress,
  Snackbar,
  Slide,
  Alert,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { ArrowDown } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchProducts,
  formatDate,
  getRelativeTimeDifference,
  getToken,
  getWebsite,
  isArrayWithValues,
  isObjWithValues,
  validateNumber,
  validateWeight,
} from "../../helper";
// import {
//   getProductSettings,
//   getSizeMastsers,
// } from "../../redux/actions/settingActions";
import { currencySymbols } from "../pricing/Gold";
import { getDiamondRate, getGemstoneRate } from "../products/AddProduct";
import PropTypes from "prop-types";
import { Gemstone } from "../../Svgs";
import { useTheme } from "@mui/system";
import { useLocation } from "react-router-dom";
import {
  getAllQuotation,
  setProductData,
  updateQuotation,
} from "../../redux/actions/productActions";
import { LoadingButton } from "@mui/lab";
// import Carousel from "react-multi-carousel";
// import Carousel from "react-material-ui-carousel";
import axios from "axios";
import SelectProducts from "../components/SelectProducts";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";

import "swiper/css"; // core Swiper
import "swiper/css/pagination";
import { setGlobalToast } from "../../redux/actions/helperActions";

function QuotationView() {
  const [quotationView, setQuotationView] = useState({
    customer: {},
    fetched: false,
  });
  const [quotationBackup, setQuotationBackup] = useState({});
  let [historyObj, setHistoryObj] = useState({
    in: Date.now() / 1000,
    by: "supplier",
  });
  const [fetchedProducts, setFetchedProducts] = useState([]);
  const [fetchingProducts, setFetchingProducts] = useState(false);
  const [pricing, setPricing] = useState({});
  const [goldPricingType, setGoldPricingType] = useState("default");
  const [silverPricingType, setSilverPricingType] = useState("default");
  const [platinumPricingType, setPlatinumPricingType] = useState("default");
  const [goldPurities, setGoldPurities] = useState([]);
  const [silverPurities, setSilverPurities] = useState([]);
  const [platinumPurities, setPlatinumPurities] = useState([]);
  const [allQuotations, setAllQuotations] = useState([]);
  const [allSizeMasters, setAllSizeMasters] = useState([]);
  const [defaultSizes, setDefaultSizes] = useState({});
  const [productSearch, setProductSearch] = useState("");
  const [productsSearched, setProductsSearched] = useState([]);
  const [fetchingSearchProducts, setFetchingSearchProducts] = useState(false);
  const [showProductDialog, toggleProductDialog] = useState(false);
  const [noProductsFound, setNoProductsFound] = useState(false);
  const [sizesBackup, setSizesBackup] = useState({});
  const [showDiamondSummary, setShowDiamondSummary] = useState(true);
  const [showMetalSummary, setShowMetalSummary] = useState(false);
  const [toast, setToast] = useState({ show: false });
  const [showSilverSummary, setShowSilverSummary] = useState(true);
  const [showGoldSummary, setShowGoldSummary] = useState(true);
  const [showPlatinumSummary, setShowPlatinumSummary] = useState(true);
  const [selectedTab, setSelectedTab] = useState(0);
  const [addingComment, setAddingComment] = useState(false);
  const [comment, setComment] = useState("");
  const [currency, setCurrency] = useState("");
  const quotationBackupRef = useRef();

  const masterPricing = useSelector((state) => state.settings.masterPricing);
  const customerMasterPricing = useSelector(
    (state) => state.settings.customerMasterPricing
  );
  const quotationBackupFromRedux = useSelector(
    (state) => state.product.quotationBackup
  );

  const reduxProductSettings = useSelector(
    (state) => state.settings.productSettings
  );

  const dispatch = useDispatch();
  const theme = useTheme();
  const location = useLocation();
  const reduxQuotations = useSelector((state) => state.product.quotations);
  const fetchingQuotations = useSelector(
    (state) => state.product.fetchingQuotations
  );
  const sizeMasters = useSelector((state) => state.settings.sizeMasters);
  const updatingQuotation = useSelector(
    (state) => state.product.updatingQuotation
  );

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isIpad = useMediaQuery(theme.breakpoints.down("md"));
  const isLg = useMediaQuery(theme.breakpoints.down("lg"));

  useEffect(() => {
    if (reduxProductSettings) {
      let { default_currency } = reduxProductSettings;
      setCurrency(default_currency);
    }
  }, [reduxProductSettings]);

  useEffect(() => {
    if (
      !isArrayWithValues(productsSearched) &&
      isArrayWithValues(fetchedProducts)
    )
      onProductSearch("", true);
  }, [fetchedProducts]);

  useEffect(() => {
    if (
      isObjWithValues(masterPricing) &&
      isObjWithValues(customerMasterPricing)
    ) {
      let id = quotationView.customer.ID;
      let { currency } = quotationView.customer;
      let { customers, labour_pricing, gemstone_pricing, diamond_pricing } = {
        ...customerMasterPricing,
      };
      if (
        customers &&
        customers[id] &&
        customers[id].pricing_type === "custom"
      ) {
        let pricingObj = { ...masterPricing };
        if (isArrayWithValues(labour_pricing[currency])) {
          let customerLabourPricing = customers[id].labour_pricing[currency];
          if (isArrayWithValues(customerLabourPricing))
            pricingObj = {
              ...pricingObj,
              labour_pricing: {
                ...labour_pricing,
                [currency]: labour_pricing[currency].map((i) => {
                  let obj = customerLabourPricing.find(
                    (obj) => obj.id === i.id
                  );
                  if (obj) return { ...i, ...obj };
                  else return i;
                }),
              },
            };
        }
        if (isArrayWithValues(gemstone_pricing[currency])) {
          let customerGemstonePricing =
            customers[id].gemstone_pricing[currency];
          if (isArrayWithValues(customerGemstonePricing))
            pricingObj = {
              ...pricingObj,
              gemstone_pricing: {
                ...gemstone_pricing,
                [currency]: gemstone_pricing[currency].map((i) => {
                  let obj = customerGemstonePricing.find(
                    (obj) => obj.id === i.id
                  );
                  if (obj) return { ...i, ...obj };
                  else return i;
                }),
              },
            };
        }
        if (isArrayWithValues(diamond_pricing[currency])) {
          let customerDiamondPricing = customers[id].diamond_pricing[currency];
          if (isArrayWithValues(customerDiamondPricing))
            pricingObj = {
              ...pricingObj,
              diamond_pricing: {
                ...diamond_pricing,
                [currency]: diamond_pricing[currency].map((i) => {
                  let obj = customerDiamondPricing.find(
                    (obj) => obj.id === i.id
                  );
                  if (obj) return { ...i, ...obj };
                  else return i;
                }),
              },
            };
        }
        setPricing({ ...pricingObj });
        // let customerLabourPricing = labourPricing[currency];
      } else setPricing({ ...masterPricing });
    }
  }, [masterPricing, quotationView]);

  useEffect(() => {
    let pieces = location.pathname.split("/");
    let quotationId = pieces[pieces.length - 1];
    if (isArrayWithValues(allQuotations)) {
      let obj = allQuotations.find((i) => i.id === Number(quotationId));
      // obj.products = [55593, 55600];
      // obj.customer.currency = "INR";
      obj.fetched = true;
      if (!obj.customize) {
        obj.customize = {};
        obj.customize.sizes = {};
      }
      if (obj) {
        setQuotationView(obj);
        // console.log("updating ref backup");
        // quotationBackupRef.current = Object.assign({ ...obj });
        // setQuotationBackup({ ...obj2 });
        // dispatch(
        //   setProductData({
        //     quotationBackup: Object.assign({ ...obj }),
        //   })
        // );
        // if (!isObjWithValues(quotationBackup)) {
        //   let obj2 = { ...obj };
        // }
      }
    }
  }, [location.pathname, allQuotations]);

  useEffect(() => {
    if (!isArrayWithValues(fetchedProducts) && quotationView.fetched)
      fetchProducts(
        {
          per_page: 100,
          include: quotationView.products,
        },
        setFetchedProducts,
        setFetchingProducts
      );
  }, [quotationView]);

  useEffect(() => {
    if (!reduxQuotations) dispatch(getAllQuotation());
    // if (!isObjWithValues(sizeMasters)) dispatch(getSizeMastsers());
    // if (!isObjWithValues(reduxProductSettings)) dispatch(getProductSettings());
  }, []);

  useEffect(() => {
    if (isArrayWithValues(reduxQuotations))
      setAllQuotations([...reduxQuotations]);
  }, [reduxQuotations]);

  useEffect(() => {
    if (isObjWithValues(sizeMasters)) {
      let obj = {};
      let defaultSizes = {};
      let keys = Object.keys(sizeMasters);
      for (let i = 0; i < keys.length; i++) {
        let key = keys[i];
        let value = sizeMasters[key];
        let trimmedKey = key.substring(0, key.lastIndexOf("_"));
        if (value.size)
          if (Array.isArray(obj[trimmedKey])) {
            if (value.enable === "on")
              obj[trimmedKey].push({
                ...value,
                label: value.size,
                value: value.size,
              });
          } else
            obj[trimmedKey] = [
              { ...value, label: value.size, value: value.size },
            ];

        if (value.default_size) {
          let temp = sizeMasters[value.default_size];
          if (isObjWithValues(temp))
            defaultSizes[key] = { ...temp, label: temp.size, value: temp.size };
        }
      }
      setDefaultSizes(defaultSizes);
      setAllSizeMasters(obj);
    }
  }, [sizeMasters]);

  const onUpdate = () => {
    let quotation = { ...quotationView };
    console.log(quotation);
    // return;
    let { unselected_products } = quotation;
    let obj = { ...historyObj };
    let oldProducts = quotationBackupFromRedux
      ? quotationBackupFromRedux.products || []
      : [];
    let products = quotationView.products || [];
    let customize = quotationView.customize || {};
    let removedProducts = oldProducts.filter((id) => !products.includes(id));
    let addedProducts = products.filter((id) => !oldProducts.includes(id));
    obj.added_products = addedProducts;
    obj.removed_products = removedProducts;
    obj.out = Date.now() / 1000;
    obj.sizes = {};

    // if (
    //   isArrayWithValues(unselected_products) &&
    //   isArrayWithValues(removedProducts)
    // ) {
    //   quotation.unselected_products =
    //     unselected_products.concat(removedProducts);
    // } else quotation.unselected_products = removedProducts;

    // quotation.unselected_products = [...new Set(quotation.unselected_products)];
    // quotation.unselected_products = quotation.unselected_products.filter(
    //   (i) => !quotation.products.find((j) => Number(j) === Number(i))
    // );
    // let temp =
    //current products which wasn't removed and added
    let currentProducts = oldProducts.filter((id) => products.includes(id));
    if (isArrayWithValues(currentProducts)) {
      let sizes = customize.sizes;

      for (let i = 0; i < currentProducts.length; i++) {
        if (sizesBackup[currentProducts[i]] && sizes[currentProducts[i]]) {
          let oldSizes = sizesBackup[currentProducts[i]].map((i) => i.size);
          let newSizes = sizes[currentProducts[i]].map((i) => i.size);
          let removedSizes = oldSizes.filter((i) => !newSizes.includes(i));
          let addedSizes = newSizes.filter((i) => !oldSizes.includes(i));
          obj.sizes[currentProducts[i]] = {
            added: addedSizes,
            removed: removedSizes,
          };
        }
        // let product = fetchedProducts.find(
        //   (product) => product.id === currentProducts[i].id
        // );
      }
    }
    let edit_history = quotation.edit_history || [];
    edit_history.push(obj);
    quotation.edit_history = edit_history;

    // quotation.customer = `${quotation.customer.ID}` || 5116;
    if (isArrayWithValues(quotation.products))
      quotation.products = quotation.products.join();
    let updateObj = {
      id: quotation.id,
      // here products will be send to string and gets array ****
      products: quotation.products,
      customer: quotation.customer.ID,
      title: quotation.title,
      customize: quotation.customize,
      edit_history: quotation.edit_history,
      unselected_products: quotation.unselected_products,
      comments: quotation.comments,
      product_comments: quotation.product_comments,
    };
    console.log(updateObj, "update obj");
    // return;
    dispatch(
      updateQuotation(
        updateObj,
        () =>
          setToast({
            show: true,
            message: "Quotaion has been updated",
            severity: "success",
          }),
        () =>
          setToast({
            show: true,
            message: "Couldn't update quotation, Please try again later",
            severity: "error",
          })
      )
    );
  };

  // useEffect(() => {
  //   let { customize } = quotationBackup;
  //   if (customize && customize.sizes) setSizesBackup(customize.sizes);
  // }, [quotationBackup]);

  // useEffect(() => {
  //   let { products } = quotationView;
  //   let goldPurities = [];
  //   let silverPurities = [];
  //   let platinumPurities = [];
  //   if (isArrayWithValues(products)) {
  //     for (let i = 0; i < products.length; i++) {
  //       let { meta_data } = products[i];
  //       if (isArrayWithValues(meta_data)) {
  //         let gold_kt = meta_data.find((i) => i.key === "gold_kt");
  //         if (gold_kt.value) goldPurities.push(gold_kt.value);
  //         let silver_purity = meta_data.find((i) => i.key === "silver_purity");
  //         if (silver_purity.value) silverPurities.push(silver_purity.value);
  //         let platinium_purity = meta_data.find(
  //           (i) => i.key === "platinium_purity"
  //         );
  //         if (platinium_purity.value)
  //           platinumPurities.push(platinium_purity.value);
  //       }
  //     }
  //   }
  //   setGoldPurities([...new Set(goldPurities)]);
  //   setSilverPurities([...new Set(silverPurities)]);
  //   setPlatinumPurities([...new Set(platinumPurities)]);
  // }, []);

  // useEffect(() => {
  //   let {
  //     customPricing,
  //     goldPricingType,
  //     silverPricingType,
  //     platinumPricingType,
  //     currency,
  //   } = quotationView;
  //   const defaultPricing = { gold: {}, silver: {}, platinum: {} };
  //   if (isObjWithValues(pricing)) {
  //     let { gold_pricing, silver_pricing, platinum_pricing } = pricing;
  //     let goldPricing = gold_pricing[currency][gold_pricing.type];
  //     let silverPricing = silver_pricing[currency][silver_pricing.type];
  //     let platinumPricing = platinum_pricing[currency][platinum_pricing.type];
  //     for (let i = 0; i < goldPurities.length; i++) {
  //       defaultPricing.gold[goldPurities[i]] = validateNumber(
  //         goldPricing[goldPurities[i]].rate
  //       );
  //     }
  //     for (let i = 0; i < silverPurities.length; i++) {
  //       defaultPricing.silver[silverPurities[i]] = validateNumber(
  //         silverPricing[silverPurities[i]].rate
  //       );
  //     }
  //     for (let i = 0; i < platinumPurities.length; i++) {
  //       defaultPricing.platinum[platinumPurities[i]] = validateNumber(
  //         platinumPricing[platinumPurities[i]].rate
  //       );
  //     }
  //   }
  //   setQuotationView((state) => ({ ...state, defaultPricing }));
  // }, [pricing]);
  // const onPricingChange = (metal, purity, value) => {
  //   let customPricing = quotationView.customPricing;
  //   customPricing = {
  //     ...customPricing,
  //     [metal]: { ...customPricing[metal], [purity]: value },
  //   };
  //   setQuotationView((state) => ({ ...state, customPricing }));
  // };
  // const onPricingTypeChange = (setState, value, quotationLabel) => {
  //   setState(value);
  //   setQuotationView((state) => ({ ...state, [quotationLabel]: value }));
  // };

  const onProductSearch = (value, firstTime) => {
    setProductSearch(value);
    setNoProductsFound(false);
    setProductsSearched([]);
    if (value.length > 2 || firstTime)
      return fetchProducts(
        {
          search: value,
          per_page: 100,
          // _fields: "id, name, sku, images,price, categories, permalink",
        },
        (products) => {
          if (isArrayWithValues(products)) {
            setProductsSearched(
              products.filter(
                (i) => !fetchedProducts.find((product) => product.id === i.id)
              )
            );
          } else {
            setNoProductsFound(true);
            setProductsSearched([]);
          }
        },
        setFetchingSearchProducts
      );
  };

  const addComment = async () => {
    setAddingComment(true);
    let {
      customer,
      products,
      title,
      id,
      comments,
      product_comments,
      customize,
    } = quotationView;
    let commentObj = {
      message: comment,
      time: Math.floor(new Date().getTime() / 1000),
      from: "supplier",
    };
    let obj = {
      type: "edit",
      id,
      data: {
        title,
        products: products.join(),
        customer: customer.ID,
        product_comments,
        comments: isArrayWithValues(comments)
          ? comments.push(commentObj)
          : [{ ...commentObj }],
        customize,
      },
    };
    try {
      let website = await getWebsite();
      let token = await getToken();
      let res = await axios({
        url: `${website}/wp-json/store/v1/quotations`,
        method: "POST",
        headers: {
          Authorization: `Basic ${token}`,
        },
        data: obj,
      });
      console.log(res.data);
      if (res.data.success) {
        setComment("");
        let { comments } = res.data.data;
        if (comments) {
          setQuotationView((state) => ({
            ...state,
            comments,
          }));
          setToast({
            show: true,
            message: "Comment Added successfully",
            severity: "success",
          });
        } else throw new Error();
      }
    } catch (error) {
      setToast({
        show: true,
        message: "Couldn't add comment, Please try again later!",
        severity: "error",
      });
    }
    setAddingComment(false);
  };

  const onProductAdd = (product) => {
    if (fetchedProducts.find((i) => i.id === product.id))
      return setToast({
        show: true,
        message: "Product is already added!",
        severity: "error",
      });
    setQuotationView((state) => ({
      ...state,
      products: [product.id, ...state.products],
    }));
    setFetchedProducts((state) => [{ ...product }, ...state]);
    toggleProductDialog(false);
  };

  const onProductsAdd = (products) => {
    if (!isArrayWithValues(products)) return;
    products = products.filter(
      (i) => !fetchedProducts.find((j) => j.id === i.id)
    );

    let productsIds = products.map((i) => i.id);

    // if (fetchedProducts.find((i) => i.id === product.id))
    //   return setToast({
    //     show: true,
    //     message: "Product is already added!",
    //     severity: "error",
    //   });
    setQuotationView((state) => ({
      ...state,
      products: productsIds.concat(state.products),
    }));
    setFetchedProducts((state) => products.concat(state));
    toggleProductDialog(false);
    setToast({
      show: true,
      message: `${products.length} products has been added`,
      severity: "success",
    });
  };

  const {
    // currency,
    customPricing,
    title,
    defaultPricing,
    created_at,
    comments,
    link,
  } = quotationView;
  if (fetchingQuotations && !isArrayWithValues(allQuotations))
    return (
      <Box
        sx={{
          width: "300px",
          margin: "auto",
          marginTop: "36vh",
          textAlign: "center",
        }}
      >
        <LinearProgress />
        <Typography variant="h6" color="text.secondary" mt={3}>
          Fetching Quotation
        </Typography>
      </Box>
    );
  return (
    <Box maxWidth="100%">
      <Snackbar
        open={toast.show}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        TransitionComponent={Slide}
        style={{ marginRight: "2vw" }}
        onClose={() => setToast((state) => ({ ...state, show: false }))}
      >
        <Alert severity={toast.severity} sx={{ width: "100%" }}>
          {toast.message}
        </Alert>
      </Snackbar>
      <Stack my={3} direction="row" justifyContent={"flex-end"}>
        <LoadingButton
          loading={updatingQuotation}
          onClick={onUpdate}
          variant="contained"
        >
          Update
        </LoadingButton>
      </Stack>
      <Paper sx={{ p: 3, maxWidth: "100%" }}>
        <Stack
          direction="row"
          alignItems={"center"}
          justifyContent={"center"}
          my={3}
        >
          <Box sx={{}}>
            <Typography variant="h2" sx={{ textAlign: "center", mt: 2 }}>
              {title}
            </Typography>
            <Typography
              variant="h6"
              color="text.secondary"
              sx={{ textAlign: "center", mt: 2 }}
            >
              {created_at
                ? formatDate(new Date(created_at), "dd:mm:yyyy hh:mm am/pm")
                : null}
            </Typography>
          </Box>
        </Stack>
        <Stack mt={1} direction="row" justifyContent="flex-end" mb={2}>
          <Button
            endIcon={<LaunchRounded />}
            onClick={() => window.open(link, "_blank")}
          >
            View on website
          </Button>
        </Stack>
        {quotationView.confirmed_on ? (
          <Stack direction="row" justifyContent="flex-end" mb={2}>
            <Typography variant="h6" fontSize="12px" color="info.main">
              Confirmed On:{" "}
              {quotationView.confirmed_on
                ? formatDate(
                    new Date(Number(quotationView.confirmed_on) * 1000),
                    "dd:mm:yyyy hh:mm am/pm"
                  )
                : ""}
            </Typography>
          </Stack>
        ) : (
          ""
        )}
        <QuotationAddressRow
          isIpad={isIpad}
          customer={quotationView.customer}
        />
        <Stack
          direction="row"
          alignItems={"center"}
          sx={{ mt: 3, px: 2 }}
          justifyContent="space-between"
        >
          <Typography variant="h6">Products</Typography>
          <Button
            variant="outlined"
            startIcon={<Add />}
            onClick={() => toggleProductDialog(true)}
          >
            Add Product
          </Button>
        </Stack>
        {fetchingProducts && !isArrayWithValues(fetchedProducts) && (
          <Box
            sx={{
              width: "300px",
              margin: "16vh auto",
              textAlign: "center",
            }}
          >
            <LinearProgress />
            <Typography variant="h6" color="text.secondary" mt={3}>
              Fetching Products
            </Typography>
          </Box>
        )}
        <Box maxWidth={"100%"}>
          {!fetchingProducts &&
            isArrayWithValues(fetchedProducts) &&
            fetchedProducts.map((i, index) => (
              <ProductView
                product={i}
                index={index}
                key={i.id}
                pricing={pricing}
                quotation={quotationView}
                setQuotationView={setQuotationView}
                setQuotationBackup={setQuotationBackup}
                isIpad={isIpad}
                isLg={isLg}
                isMobile={isMobile}
                allSizeMasters={allSizeMasters}
                defaultSizes={defaultSizes}
                fetchedProducts={fetchedProducts}
                setFetchedProducts={setFetchedProducts}
                setSizesBackup={setSizesBackup}
                isLastProduct={fetchedProducts.length === 1}
                currency={currency}
                // quotationBackupRef={quotationBackupRef}
              />
            ))}
        </Box>
        <Overview
          quotation={quotationView}
          default_currency={currency}
          pricing={pricing}
          products={fetchedProducts}
        />
        <Box sx={{ height: "30px" }} />
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={selectedTab}
            onChange={(e, v) => setSelectedTab(v)}
            aria-label="basic tabs example"
          >
            <Tab label="Summary" {...a11yProps(0)} />
            <Tab label="Comments" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <TabPanel value={selectedTab} index={0}>
          {showDiamondSummary && (
            <DiamondSummary
              products={fetchedProducts}
              currency={currency}
              pricing={pricing}
              setShowDiamondSummary={setShowDiamondSummary}
              quotationView={quotationView}
            />
          )}
          {!fetchingQuotations && !fetchingProducts && showGoldSummary && (
            <MetalSummary
              products={fetchedProducts}
              currency={currency}
              quotationView={quotationView}
              pricing={pricing.gold_pricing && pricing.gold_pricing[currency]}
              type={pricing.gold_pricing && pricing.gold_pricing.type}
              from={pricing.gold_pricing && pricing.gold_pricing.from}
              meta_keys={["gold_kt", "gold_gross", "gold_net"]}
              title="Gold Summary"
              setShow={setShowGoldSummary}
            />
          )}
          {!fetchingQuotations && !fetchingProducts && showSilverSummary && (
            <MetalSummary
              products={fetchedProducts}
              currency={currency}
              quotationView={quotationView}
              pricing={
                pricing.silver_pricing && pricing.silver_pricing[currency]
              }
              type={pricing.silver_pricing && pricing.silver_pricing.type}
              from={pricing.silver_pricing && pricing.silver_pricing.from}
              meta_keys={["silver_purity", "silver_gross", "silver_net"]}
              title="Silver Summary"
              setShow={setShowSilverSummary}
            />
          )}
          {!fetchingQuotations && !fetchingProducts && showPlatinumSummary && (
            <MetalSummary
              products={fetchedProducts}
              currency={currency}
              quotationView={quotationView}
              pricing={
                pricing.platinum_pricing && pricing.platinum_pricing[currency]
              }
              type={pricing.platinum_pricing && pricing.platinum_pricing.type}
              from={pricing.platinum_pricing && pricing.platinum_pricing.from}
              meta_keys={[
                "platinium_purity",
                "platinium_gross",
                "platinium_net",
              ]}
              title="Platinum Summary"
              setShow={setShowPlatinumSummary}
            />
          )}
        </TabPanel>
        <TabPanel value={selectedTab} index={1}>
          {quotationView &&
            isArrayWithValues(comments) &&
            comments.map((comment) => {
              let time = formatDate(
                new Date(comment.time * 1000),
                "dd:mm:yyyy hh:mm am/pm"
              );
              return (
                <>
                  <Box sx={{ mt: 3 }}>
                    <Stack direction="row">
                      <Typography variant="h6" fontSize="13px">
                        {comment.from === "supplier" ? "Admin" : "Anonymous"}
                      </Typography>
                      <Typography fontSize="11px" ml={2}>
                        {time}
                      </Typography>
                    </Stack>
                    <Typography mt={2} variant="h6" fontSize="13px">
                      {comment.message}
                    </Typography>
                  </Box>
                  <Divider sx={{ my: 2 }} />
                </>
              );
            })}
          <TextField
            variant="standard"
            fullWidth
            placeholder="Type a comment"
            sx={{ my: 3 }}
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            // endAdornment={
            //   <InputAdornment position="end">
            //     <IconButton
            //     // onClick={handleClickShowPassword}
            //     // onMouseDown={handleMouseDownPassword}
            //     // edge="end"
            //     >
            //       <SendRounded
            //         style={{ height: "15px", width: "15px" }}
            //       />
            //     </IconButton>
            //   </InputAdornment>
            // }
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <LoadingButton
                    loading={addingComment}
                    onClick={addComment}
                    // onMouseDown={handleMouseDownPassword}
                    // edge="end"
                    sx={{ mb: 1 }}
                  >
                    <SendRounded style={{ height: "25px", width: "25px" }} />
                  </LoadingButton>
                </InputAdornment>
              ),
            }}
          />
        </TabPanel>
        <Box sx={{ height: "40px" }} />
      </Paper>
      <Dialog
        onClose={() => toggleProductDialog(false)}
        open={showProductDialog}
        scroll={"paper"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        maxWidth="sm"
        sx={{
          minHeight: "60vh",
          padding: "",
        }}
        fullWidth
      >
        <SelectProducts
          onClose={() => toggleProductDialog(false)}
          onSelect={onProductsAdd}
        />
        {/* <Stack
          direction="row"
          alignItems={"center"}
          justifyContent="space-between"
        >
          <DialogTitle id="scroll-dialog-title">Select products</DialogTitle>
          <IconButton onClick={() => toggleProductDialog(false)} sx={{ ml: 2 }}>
            <Close />
          </IconButton>
        </Stack>
        <DialogContent sx={{ mt: 2 }} dividers={"paper"}>
          <DialogContentText
            id="scroll-dialog-description"
            sx={{
              minHeight: "60vh",
              padding: "",
            }}
            tabIndex={-1}
          >
            <TextField
              value={productSearch}
              onChange={(e) => onProductSearch(e.target.value)}
              sx={{ mb: 2 }}
              variant="outlined"
              fullWidth
              size="small"
              type="search"
              placeholder="Search products"
              // onSubmit={() =>
              //   fetchProducts(
              //     productSearch,
              //     setProductsSearched,
              //     setFetchingProducts
              //   )
              // }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      type="submit"
                      // onClick={(e) => onProductSearch(e.target.value)}

                      // onClick={() =>
                      //   fetchProducts(
                      //     productSearch,
                      //     setProductsSearched,
                      //     setFetchingProducts
                      //   )
                      // }
                    >
                      <Search />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              // onSubmit={() => console.log("submit")}
            />
            {isArrayWithValues(productsSearched)
              ? productsSearched.map((product) => {
                  let { images, id } = product;
                  let image = isArrayWithValues(images) && images[0].src;
                  return (
                    <Box
                      sx={{
                        display: "flex",
                        padding: "7px 5px",
                        // backgroundColor: selectedProducts.find(
                        //   (i) => i.id === id
                        // )
                        //   ? "Highlight"
                        //   : "transparent",
                        cursor: "pointer",
                        "&:hover": {
                          backgroundColor: "rgba(0,0,0,0.02)",
                        },
                        borderRadius: "5px",
                      }}
                      key={product.id}
                      // onClick={() => onProductSelect(product)}
                      onClick={() => onProductAdd(product)}
                    >
                      {image ? (
                        <img
                          src={image}
                          style={{
                            height: "50px",
                            width: "50px",
                            borderRadius: "50%",
                          }}
                          onError={() => (image = "")}
                        />
                      ) : (
                        <img
                          src={"/static/img/woocommerce_placeholder.png"}
                          style={{
                            height: "50px",
                            width: "50px",
                            borderRadius: "50%",
                          }}
                        />
                      )}
                      <Box sx={{ margin: "auto 6px" }}>
                        <Typography variant="body1" fontSize={"14px"}>
                          {product.name}
                        </Typography>
                        <div style={{ fontSize: "12px" }}>{product.sku}</div>
                      </Box>
                    </Box>
                  );
                })
              : null}
            {noProductsFound ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "30px",
                }}
              >
                <Typography variant="body1" ml={2}>
                  No products found
                </Typography>
              </div>
            ) : null}
            {fetchingSearchProducts ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "30px",
                }}
              >
                <CircularProgress size="13px" />
                <Typography variant="body1" ml={2}>
                  Loading products.
                </Typography>
              </div>
            ) : null}
          </DialogContentText>
        </DialogContent> */}
      </Dialog>
    </Box>
  );
}

export default QuotationView;

const Overview = ({ quotation, products, pricing, default_currency }) => {
  const defaultState = {
    metal_wt: 0,
    metal: 0,
    diamond_wt: 0,
    diamond: 0,
    gemstone_wt: 0,
    gemstone: 0,
    labour: 0,
    total: 0,
  };
  const [info, setInfo] = useState(defaultState);

  const allDiamondGroups = useSelector(
    (state) => state.settings.allDiamondGroups
  );
  useEffect(() => {
    if (
      !isObjWithValues(quotation) ||
      !isObjWithValues(pricing) ||
      (pricing && !pricing.gold_pricing)
    )
      return setInfo(defaultState);
    let { customize } = quotation;
    if (!customize || (customize && !isObjWithValues(customize.sizes)))
      return setInfo(defaultState);
    let { sizes } = customize;
    console.log(sizes, isArrayWithValues(products) && products.length);
    if (!isArrayWithValues(products)) return setInfo(defaultState);

    let totalMetalWeight = 0;
    let totalMetalPrice = 0;
    let totalDiamondWeight = 0;
    let totalDiamondPrice = 0;
    let totalGemstoneWeight = 0;
    let totalGemstonePrice = 0;
    let totalLabourPrice = 0;

    for (let product of products) {
      let { id, meta_data } = product;
      if (!isArrayWithValues(sizes[id])) continue;
      let quantity = 0;
      if (isArrayWithValues(sizes[id]))
        sizes[id].map((i) => (quantity += Number(i.quantity)));
      quantity = quantity || 1;
      let metal_weight = 0;
      let obj = {};
      let metaKeys = [
        "gold_gross",
        "silver_gross",
        "platinium_gross",
        "gold_kt",
        "silver_purity",
        "platinium_purity",
        "metal_types",
        "diamond",
        "colorstone_details",
        "labour_pricing_title",
      ];
      if (isArrayWithValues(meta_data))
        meta_data.map((i) => {
          if (metaKeys.includes(i.key)) obj[i.key] = i.value;
        });
      let {
        gold_gross,
        silver_gross,
        platinium_gross,
        gold_kt,
        silver_purity,
        platinium_purity,
        metal_types,
        diamond,
        colorstone_details,
        labour_pricing_title,
      } = obj;
      let {
        gold_pricing,
        silver_pricing,
        platinum_pricing,
        diamond_pricing,
        gemstone_pricing,
        labour_pricing,
      } = pricing;
      let totalWeight = 0;

      let labourPricing =
        isArrayWithValues(labour_pricing[default_currency]) &&
        labour_pricing[default_currency].find(
          (i) => i.id == obj.labour_pricing_title
        );

      let gold_rate = 0,
        silver_rate = 0,
        platinum_rate = 0;
      if (isArrayWithValues(metal_types))
        if (metal_types?.includes("gold") && gold_gross) {
          totalMetalWeight += Number(gold_gross) * quantity;
          if (labourPricing)
            totalWeight += Number(obj[`gold_${labourPricing.labour_from}`]);
          gold_rate = getMetalRate(
            gold_pricing[default_currency],
            gold_pricing.type,
            gold_kt
          );
          // console.log(gold_rate)
          if (gold_rate)
            totalMetalPrice += Math.round(
              Number(gold_rate) *
                Number(obj[`gold_${gold_pricing.from}`]) *
                quantity
            );
        }

      if (metal_types?.includes("silver") && silver_gross) {
        totalMetalWeight += Number(silver_gross) * quantity;

        if (labourPricing)
          totalWeight += Number(obj[`silver_${labourPricing.labour_from}`]);
        silver_rate = getMetalRate(
          silver_pricing[default_currency],
          silver_pricing.type,
          silver_purity
        );
        if (silver_rate)
          totalMetalPrice += Math.round(
            Number(silver_rate) *
              Number(obj[`silver_${silver_pricing.from}`]) *
              quantity
          );
      }
      if (metal_types?.includes("platinium") && platinium_gross) {
        totalMetalWeight += Number(obj.platinium_gross) * quantity;

        if (labourPricing)
          totalWeight += Number(obj[`platinium_${labourPricing.labour_from}`]);
        platinum_rate = getMetalRate(
          platinum_pricing[default_currency],
          platinum_pricing.type,
          platinium_purity
        );
        if (platinum_rate)
          totalMetalPrice += Math.round(
            Number(platinum_rate) *
              Number(obj[`platinium_${platinum_pricing.from}`]) *
              quantity
          );
      }

      if (isObjWithValues(labourPricing)) {
        let totalMetalRate =
          validateNumber(gold_rate) +
          validateNumber(silver_rate) +
          validateNumber(platinum_rate);
        let { minimum_making, per_gram, wastage } = labourPricing;
        let total = totalWeight * validateNumber(per_gram);
        let totalWastage = wastage ? wastage / 100 : 1;
        let wastageTemp =
          totalWeight * validateNumber(totalMetalRate) * totalWastage;
        // total += Math.round(total * (wastage_percent / 100));
        total += wastageTemp;
        let totalLabour =
          validateNumber(minimum_making) < total
            ? validateNumber(total)
            : validateNumber(minimum_making);
        if (totalLabour)
          totalLabourPrice += Number(Math.round(totalLabour)) * quantity;
      }

      if (isObjWithValues(diamond)) {
        let diamonds = Object.values(diamond);
        let totalWeight = 0;
        let totalPieces = 0;
        for (let diamond of diamonds) {
          let {
            diamond_type,
            diamond_quality,
            diamond_shape,
            diamond_cut,
            diamond_pieces,
            diamond_weight,
          } = diamond;
          if (diamond_weight)
            totalDiamondWeight += Number(diamond_weight) * quantity;

          totalWeight += Number(diamond_weight);
          totalPieces += Number(diamond_pieces);

          let diamond_sieve;
          if (isObjWithValues(allDiamondGroups)) {
            let netWeight = Number(
              Number(diamond_weight) / Number(diamond_pieces)
            );
            let diamondGroup = Object.values(allDiamondGroups);
            // console.log(diamondGroup);
            let obj = diamondGroup.find((i) => {
              return (
                i.shape === diamond_shape &&
                netWeight >= Number(i.from) &&
                netWeight <= Number(i.to)
              );
            });
            if (!obj)
              obj = diamondGroup.find((i) => {
                let netWeightFixed2 = Number(netWeight).toFixed(2);
                return (
                  i.shape === diamond_shape &&
                  netWeightFixed2 >= Number(i.from) &&
                  netWeightFixed2 <= Number(i.to)
                );
              });
            diamond_sieve = obj?.id;
          }
          let diamond_rate = getDiamondRate(
            diamond_pricing[default_currency],
            diamond_type,
            diamond_quality,
            diamond_shape,
            diamond_sieve,
            diamond_cut
          );
          if (diamond_rate)
            totalDiamondPrice += Math.round(
              Number(diamond_rate) * Number(diamond_weight) * quantity
            );
        }
      }
      if (isObjWithValues(colorstone_details)) {
        let all_colorstone_details = Object.values(colorstone_details);
        for (let colorstone of all_colorstone_details) {
          let {
            colorstone_quality,
            colorstone_type,
            colorstone_shape,
            colorstone_pieces,
            colorstone_weight,
            colorstone_size,
          } = colorstone;
          let colorstone_rate = getGemstoneRate(
            gemstone_pricing[default_currency],
            colorstone_quality,
            colorstone_type,
            colorstone_shape,
            colorstone_size
          );
          if (colorstone_weight)
            totalGemstoneWeight += Number(colorstone_weight) * quantity;
          if (colorstone_rate)
            totalGemstonePrice += Math.round(
              Number(colorstone_rate) * Number(colorstone_weight) * quantity
            );
        }
      }
    }
    setInfo({
      metal_wt: Number(totalMetalWeight).toFixed(3),
      diamond_wt: Number(totalDiamondWeight).toFixed(3),
      gemstone_wt: Number(totalGemstoneWeight).toFixed(3),
      metal: totalMetalPrice,
      diamond: totalDiamondPrice,
      gemstone: totalGemstonePrice,
      labour: totalLabourPrice,
      total:
        totalMetalPrice +
        totalDiamondPrice +
        totalGemstonePrice +
        totalLabourPrice,
    });
  }, [quotation, products]);

  return (
    <Box
      sx={{ border: "0.5px solid rgba(0,0,0,0.3)", borderRadius: "7px", p: 4 }}
    >
      <Stack
        mb={5}
        direction="row"
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Typography variant="h5">Overview</Typography>
        <Typography variant="h5">
          Total: {currencySymbols[default_currency]} {info.total}
        </Typography>
      </Stack>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4} lg={3}>
          <Stack direction="row" alignItems={"center"}>
            <Typography variant="h6">
              Metal: {currencySymbols[default_currency]} {info.metal}
            </Typography>
          </Stack>
          <Typography
            variant="h6"
            fontSize="12px"
            color="text.secondary"
            mt={1}
          >
            {info.metal_wt} grams
          </Typography>
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
          <Stack direction="row" alignItems={"center"}>
            <Typography variant="h6">
              Diamond: {currencySymbols[default_currency]} {info.diamond}
            </Typography>
          </Stack>
          <Typography
            variant="h6"
            fontSize="12px"
            color="text.secondary"
            mt={1}
          >
            {info.diamond_wt} cts.
          </Typography>
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
          <Stack direction="row" alignItems={"center"}>
            <Typography variant="h6">
              Gemstone: {currencySymbols[default_currency]} {info.gemstone}
            </Typography>
          </Stack>
          <Typography
            variant="h6"
            fontSize="12px"
            color="text.secondary"
            mt={1}
          >
            {info.gemstone_wt} cts
          </Typography>
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
          <Stack direction="row" alignItems={"center"}>
            <Typography variant="h6">
              Labour: {currencySymbols[default_currency]} {info.labour}
            </Typography>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

const QuotationAddressRow = ({ isIpad, customer }) => {
  const {
    address_line_1,
    address_line_2,
    city,
    country,
    first_name,
    last_name,
    pincode,
    state,
    logo,
  } = customer;
  return (
    <Grid container spacing={3} mt={isIpad ? 3 : 0}>
      <Grid item xs={12} md={6}>
        <Box
          sx={{
            pt: 2,
            pb: 3,
            borderRadius: "6px",
          }}
        >
          <img
            src="https://jwero.ai/wp-content/uploads/2021/04/new-logo.png"
            style={{
              width: "200px",
              height: "40px",
              alignSelf: "flex-start",
              objectFit: "contain",
            }}
          />
          <Divider
            sx={{
              my: 2,
              borderBottomWidth: "0.3px",
            }}
          />
          <Box sx={{ px: 3 }}>
            <Typography variant="h6">Tiara by TJ,</Typography>
            <Typography variant="h6" fontSize="13px">
              Sagar Darshan, Station Road, Bhayander (west) <br /> Mumbai 401105{" "}
              <br />
            </Typography>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} md={6} sx={{ justifyContent: "flex-end" }}>
        <Box
          sx={{
            pt: 2,
            pb: 3,
            borderRadius: "6px",
            textAlign: "right",
          }}
        >
          {logo ? (
            <img
              src="https://jwero.ai/wp-content/uploads/2021/08/ratnalayajewellers.png"
              style={{
                width: "200px",
                height: "40px",
                alignSelf: isIpad ? "flex-end" : "flex-start",
                objectFit: "contain",
              }}
            />
          ) : null}
          <Divider
            sx={{
              my: 2,
              borderBottomWidth: "0.3px",
            }}
          />
          <Box sx={{ px: 3 }}>
            <Typography variant="h6">
              {`${first_name} ${last_name}`},
            </Typography>
            <Typography variant="h6" fontSize="15px" mt={2}>
              {`${address_line_1 ? `${address_line_1},` : ""} ${
                address_line_2 ? `${address_line_2},` : ""
              }`}
              <br />
              {`${city ? `${city},` : ""} `}
              <br />
              {`${state ? `${state},` : ""} ${pincode}`}

              {/* Building, Road no, City, District <br /> State 402392 <br /> */}
            </Typography>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

const ProductView = ({
  product,
  pricing,
  quotation,
  setQuotationView,
  isIpad,
  isLg,
  isMobile,
  allSizeMasters,
  defaultSizes,
  setFetchedProducts,
  setQuotationBackup,
  setSizesBackup,
  isLastProduct,
  quotationBackupRef,
  currency,
  index,
}) => {
  const [info, setInfo] = useState({});
  // const [currency, setCurrency] = useState("");
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [diamondTd, setDiamondTd] = useState([]);
  const [gemstoneTd, setGemstoneTd] = useState([]);
  const [extraChargesTd, setExtraChargesTd] = useState([]);
  const [diamondTotal, setDiamondTotal] = useState({});
  const [gemstoneTotal, setGemstoneTotal] = useState({});
  const [selectedTab, setSelectedTab] = useState(0);
  const [goldRate, setGoldRate] = useState(0);
  const [silverRate, setSilverRate] = useState(0);
  const [platinumRate, setPlatinumRate] = useState(0);
  const [allSizes, setAllSizes] = useState([]);
  const [productSizes, setProductSizes] = useState([]);
  const [totalQuantity, setTotalQuantity] = useState(1);
  const [totalLabour, setTotalLabour] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);

  const [addingComment, setAddingComment] = useState(false);
  const [goldAmount, setGoldAmount] = useState(0);
  const [silverAmount, setSilverAmount] = useState(0);
  const [platinumAmount, setPlatinumAmount] = useState(0);

  const [variantsTotal, setVariantsTotal] = useState({});
  const [comment, setComment] = useState("");

  let [diamondCustomizes, setDiamondCustomizes] = useState([]);

  const allDiamondGroups = useSelector(
    (state) => state.settings.allDiamondGroups
  );
  const [toast, setToast] = useState({ show: false });
  const dispatch = useDispatch();

  useEffect(() => {
    let {
      images,
      meta_data,
      categories,
      category,
      name,
      sku,
      id,
      stock_quantity,
    } = product;
    let obj = {
      id,
      images,
      name,
      sku,
      categories,
      category,
      stock_quantity,
    };

    let meta_keys = [
      "gold_kt",
      "silver_purity",
      "platinium_purity",
      "gold_gross",
      "silver_gross",
      "platinium_gross",
      "gold_net",
      "silver_net",
      "platinium_net",
      "diamond",
      "colorstone_details",
      "variants",
      "labour",
      "per_gram",
      "wastage_percent",
      "metal_color",
      "subcatsetting",
      "minimum_making",
      "metal_types",
      "labour_from",
      "labour_pricing_title",
      "extra_charges",
    ];

    if (isArrayWithValues(meta_data))
      meta_data.map((i) => {
        if (meta_keys.includes(i.key)) obj[i.key] = i.value;
      });
    if (isObjWithValues(pricing)) {
      let { gold_pricing, silver_pricing, platinum_pricing, labour_pricing } =
        pricing;

      if (
        obj.labour_pricing_title &&
        isArrayWithValues(labour_pricing[currency])
      ) {
        let pricing = labour_pricing[currency].find(
          (i) => i.id == obj.labour_pricing_title
        );
        if (isObjWithValues(pricing)) {
          let { minimum_making, per_gram, wastage, labour_from } = pricing;
          obj = {
            ...obj,
            minimum_making,
            per_gram,
            wastage_percent: wastage,
            labour_from,
          };
        }
      }

      if (isObjWithValues(obj.extra_charges)) {
        let extra_charges = {},
          extra_charges_total = 0,
          extra_charges_td = [];

        for (let i of Object.values(obj.extra_charges)) {
          extra_charges[i.extra_charge_label] = i.extra_charge_value;
          // extra_charges_td.push([i.extra_charge_label, i.extra_charge_value]);
        }

        for (let i of Object.values(extra_charges))
          extra_charges_total += Number(i);

        obj.extra_charges = extra_charges;
        obj.extra_charges_total = extra_charges_total;
        // obj.extra_charges_td = extra_charges_td;
      }

      let { type } = pricing.gold_pricing;
      if (
        pricing &&
        pricing.gold_pricing &&
        pricing.gold_pricing[currency] &&
        pricing.gold_pricing[currency][type]
      ) {
        if (obj.gold_kt) {
          let rateObj = pricing.gold_pricing[currency][type][obj.gold_kt];
          if (rateObj) obj.gold_rate = rateObj.rate;
        }
      }
      if (
        pricing &&
        pricing.silver_pricing &&
        pricing.silver_pricing[currency] &&
        pricing.silver_pricing[currency][type]
      ) {
        if (obj.silver_purity) {
          let rateObj =
            pricing.silver_pricing[currency][type][obj.silver_purity];
          if (rateObj) obj.silver_rate = rateObj.rate;
        }
      }
      if (
        pricing &&
        pricing.platinum_pricing &&
        pricing.platinum_pricing[currency] &&
        pricing.platinum_pricing[currency][type]
      ) {
        if (obj.platinium_purity) {
          let rateObj =
            pricing.platinum_pricing[currency][type][obj.platinium_purity];
          if (rateObj) obj.platinum_rate = rateObj.rate;
        }
      }

      let {
        customPricing,
        defaultPricing,
        goldPricingType,
        silverPricingType,
        platinumPricingType,
      } = quotation;
      if (obj.gold_kt && gold_pricing.from) {
        if (goldPricingType === "default") {
          obj.gold_rate = defaultPricing.gold[obj.gold_kt];
        }
        if (goldPricingType === "custom")
          obj.gold_rate = customPricing.gold[obj.gold_kt];
        if (obj.gold_rate) {
          if (gold_pricing.from === "gross")
            obj.gold_amount = Math.round(
              validateNumber(obj.gold_rate) * validateNumber(obj.gold_gross)
            );
          if (gold_pricing.from === "gross")
            obj.gold_amount = Math.round(
              validateNumber(obj.gold_rate) * validateNumber(obj.gold_net)
            );
        }
      }
      if (obj.silver_purity) {
        if (silverPricingType === "default")
          obj.silver_rate = defaultPricing.silver[obj.silver_purity];
        if (silverPricingType === "custom")
          obj.silver_rate = customPricing.silver[obj.silver_purity];

        if (obj.silver_rate) {
          if (silver_pricing.from === "gross")
            obj.silver_amount = Math.round(
              validateNumber(obj.silver_rate) * validateNumber(obj.silver_gross)
            );
          if (silver_pricing.from === "gross")
            obj.silver_amount = Math.round(
              validateNumber(obj.silver_rate) * validateNumber(obj.silver_net)
            );
        }
      }
      if (obj.platinium_purity) {
        if (platinumPricingType === "default")
          obj.platinum_rate = defaultPricing.platinum[obj.platinium_purity];
        if (platinumPricingType === "custom")
          obj.platinum_rate = customPricing.platinum[obj.platinium_purity];
        if (obj.platinum_rate) {
          if (platinum_pricing.from === "gross")
            obj.platinum_amount = Math.round(
              validateNumber(obj.platinum_rate) *
                validateNumber(obj.platinium_gross)
            );
          if (platinum_pricing.from === "gross")
            obj.platinum_amount = Math.round(
              validateNumber(obj.platinum_rate) *
                validateNumber(obj.platinium_net)
            );
        }
      }
    }

    let { customize } = quotation;
    // if (
    //   !customize ||
    //   (customize && !customize.sizes) ||
    //   (customize && customize.sizes && !customize.sizes[id])
    // ) {
    //   if (isArrayWithValues(obj.subcatsetting)) {
    //     obj.subcatsetting = obj.subcatsetting || [];
    //     let arr = obj.subcatsetting.map((i) => ({
    //       size: i,
    //       color: obj.metal_color || "",
    //       quantity: 1,
    //       id: Date.now(),
    //     }));
    //     let customizeObj = {};
    //     if (customize && customize.sizes)
    //       customizeObj = {
    //         ...customize,
    //         sizes: { ...customize.sizes, [id]: arr },
    //       };
    //     if (!customize) {
    //       customizeObj = {
    //         sizes: {
    //           [id]: arr,
    //         },
    //       };
    //     }
    //     if (isArrayWithValues(arr)) {
    //       setQuotationView((state) => ({ ...state, customize: customizeObj }));
    //       // quotationBackupRef.current = {
    //       //   ...(quotationBackupRef.current || {}),
    //       //   customize: customizeObj,
    //       // };
    //       let obj = { ...customizeObj };
    //       // setQuotationBackup((state) => ({
    //       //   ...state,
    //       //   customize: { ...obj },
    //       // }));
    //       // console.log("updating backup 2");
    //       // setSizesBackup({ ...customizeObj.sizes });
    //       // if (customize && customize.sizes && !customize.sizes[id])
    //       setProductSizes(arr);
    //     }
    //   }
    // } else
    if (
      customize &&
      customize.sizes &&
      isArrayWithValues(customize.sizes[id])
    ) {
      setProductSizes(customize.sizes[id]);
    }
    setInfo(obj);
    // let { customer } = quotation;
    // if (customer.currency) setCurrency(customer.currency);
  }, [pricing]);

  useEffect(() => {
    if (isArrayWithValues(productSizes)) {
      let totalQuantity = 0;
      productSizes.map((i) => (totalQuantity += Number(i.quantity)));
      setTotalQuantity(totalQuantity);
    }
  }, [productSizes, quotation]);

  useEffect(() => {
    getDiamondRows();
    getGemstoneRows();
    setMetalRates();
    setLabour();
    getExtraChargesTd();
  }, [totalQuantity]);

  useEffect(() => {
    if (currency) {
      if (pricing.diamond_pricing && !isArrayWithValues(diamondTd))
        getDiamondRows();
      if (pricing.gemstone_pricing && !isArrayWithValues(gemstoneTd))
        getGemstoneRows();
      if (pricing) {
        setMetalRates();
        setLabour();
      }
    }
  }, [pricing, info, currency]);

  useEffect(() => {
    let { categories, id, variants, subcatsetting, metal_color } = info;
    // console.log(categories, allSizeMasters, singleSizeMasters);
    if (isArrayWithValues(categories)) {
      let { value } = categories[0];
      if (!value) value = categories[0].slug;
      // onEditProduct({ subcatsetting: [] });
      if (allSizeMasters[value] && isObjWithValues(allSizeMasters)) {
        // setSingleSizeSettings([]);
        setAllSizes(allSizeMasters[value]);
        // if (isObjWithValues(defaultSizes)) {
        //   setTimeout(
        //     () => onEditProduct({ subcatsetting: [defaultSizes[value]] }),
        //     1500
        //   );
        // }
      } else if (singleSizeMasters[value]) {
        setAllSizes(singleSizeMasters[value]);
      } else {
        setAllSizes([]);
        // setSingleSizeSettings([]);
        // setSizeSettings([]);
      }
    }
  }, [info]);

  useEffect(() => {
    getExtraChargesTd();
  }, [info.extra_charges_total]);

  const getExtraChargesTd = () => {
    if (isObjWithValues(extra_charges)) {
      let extra_charges_td = [];

      for (let i in extra_charges) {
        extra_charges_td.push([i, extra_charges[i]]);
      }
      if (extra_charges_td.length > 1)
        extra_charges_td.push([
          "Total",
          `${currencySymbols[currency]} ` + extra_charges_total,
        ]);
      extra_charges_td.push([
        `Grand Total x ${totalQuantity}`,
        `${currencySymbols[currency]} ` +
          totalQuantity * validateNumber(extra_charges_total),
      ]);

      setExtraChargesTd(extra_charges_td);
    }
  };

  const getDiamondRows = () => {
    let tableRows = [];
    let { diamond_pricing } = pricing;
    let { diamond } = info;
    if (
      diamond_pricing &&
      diamond_pricing[currency] &&
      isObjWithValues(diamond)
    ) {
      let allDiamonds = Object.values(diamond);
      let totalPieces = 0,
        totalRate = 0,
        totalWeight = 0;
      for (let i = 0; i < allDiamonds.length; i++) {
        let {
          diamond_type,
          diamond_quality,
          diamond_shape,
          // diamond_sieve,
          diamond_cut,
          diamond_pieces,
          diamond_weight,
        } = allDiamonds[i];
        totalWeight += Number(diamond_weight);
        totalPieces += Number(diamond_pieces);

        let diamond_sieve;
        if (isObjWithValues(allDiamondGroups)) {
          let netWeight = Number(
            Number(diamond_weight) / Number(diamond_pieces)
          );
          let diamondGroup = Object.values(allDiamondGroups);
          // console.log(diamondGroup);
          let obj = diamondGroup.find((i) => {
            return (
              i.shape === diamond_shape &&
              netWeight >= Number(i.from) &&
              netWeight <= Number(i.to)
            );
          });
          if (!obj)
            obj = diamondGroup.find((i) => {
              let netWeightFixed2 = Number(netWeight).toFixed(2);
              return (
                i.shape === diamond_shape &&
                netWeightFixed2 >= Number(i.from) &&
                netWeightFixed2 <= Number(i.to)
              );
            });
          diamond_sieve = obj?.id;
        }
        let diamond_rate = getDiamondRate(
          diamond_pricing[currency],
          diamond_type,
          diamond_quality,
          diamond_shape,
          diamond_sieve,
          diamond_cut
        );
        tableRows.push([
          diamond_quality,
          diamond_shape,
          diamond_sieve,
          diamond_pieces,
          diamond_weight,
          `${currencySymbols[currency]} ` +
            Math.round(
              validateNumber(diamond_rate) * validateNumber(diamond_weight)
            ),
        ]);
        totalPieces += validateNumber(diamond_pieces);
        totalWeight += validateNumber(diamond_weight);
        totalRate += Math.round(
          validateNumber(diamond_rate) * validateNumber(diamond_weight)
        );
      }
      totalWeight = validateNumber(parseFloat(totalWeight).toFixed(3));
      if (tableRows.length > 1)
        tableRows.push([
          "Total",
          "",
          "",
          totalPieces,
          totalWeight,
          `${currencySymbols[currency]} ` + totalRate,
        ]);
      // tableRows.push([
      //   `Grand Total x ${totalQuantity}`,
      //   "",
      //   "",
      //   totalPieces * totalQuantity,
      //   validateNumber(parseFloat(totalWeight * totalQuantity).toFixed(3)),
      //   `${currencySymbols[currency]} ` + totalRate * totalQuantity,
      // ]);
      setDiamondTotal({
        totalPieces,
        totalWeight,
        totalRate,
      });
      setDiamondTd(tableRows);
    }
  };

  const getGemstoneRows = () => {
    let tableRows = [];
    let { gemstone_pricing } = pricing;
    if (gemstone_pricing && colorstone_details) {
      let allGemstone = Object.values(colorstone_details);
      let totalPieces = 0,
        totalRate = 0,
        totalWeight = 0;
      for (let i = 0; i < allGemstone.length; i++) {
        let {
          colorstone_quality,
          colorstone_type,
          colorstone_shape,
          colorstone_pieces,
          colorstone_weight,
          colorstone_size,
        } = allGemstone[i];
        let colorstone_rate = getGemstoneRate(
          gemstone_pricing[currency],
          colorstone_quality,
          colorstone_type,
          colorstone_shape,
          colorstone_size
        );
        tableRows.push([
          colorstone_quality,
          colorstone_type,
          colorstone_shape,
          colorstone_pieces,
          colorstone_weight,
          `${currencySymbols[currency]} ` +
            Math.round(
              validateNumber(colorstone_rate) *
                validateNumber(colorstone_weight)
            ),
        ]);
        totalPieces += validateNumber(colorstone_pieces);
        totalWeight += validateNumber(colorstone_weight);
        totalRate += Math.round(
          validateNumber(colorstone_rate) * validateNumber(colorstone_weight)
        );
      }
      totalWeight = validateNumber(parseFloat(totalWeight).toFixed(3));
      if (tableRows.length > 1)
        tableRows.push([
          "Total",
          "",
          "",
          totalPieces,
          totalWeight,
          `${currencySymbols[currency]} ` + totalRate,
        ]);
      // tableRows.push([
      //   `Grand Total x ${totalQuantity}`,
      //   "",
      //   "",
      //   totalPieces * totalQuantity,
      //   validateNumber(parseFloat(totalWeight * totalQuantity).toFixed(3)),
      //   `${currencySymbols[currency]} ` + totalRate * totalQuantity,
      // ]);

      setGemstoneTotal({
        totalPieces,
        totalWeight,
        totalRate,
      });
      setGemstoneTd(tableRows);
    }
    // return [["", "", "", "", ""]];
  };

  const setMetalRates = () => {
    let { gold_pricing, silver_pricing, platinum_pricing } = pricing;
    let {
      gold_kt,
      silver_purity,
      platinium_purity,
      gold_gross,
      gold_net,
      variants,
    } = info;
    // console.log(gold_pricing, gold_kt);
    if (gold_pricing && gold_kt) {
      let gold_rate = getMetalRate(
        gold_pricing[currency],
        gold_pricing.type,
        gold_kt
      );
      // setGoldRate(gold_rate);
      // if (isArrayWithValues(variants)) {
      //   let totalGross = 0,
      //     totalNet = 0,
      //     totalRate = 0;
      //   for (let i = 0; i < variants.length; i++) {
      //     let obj = variants[i];
      //     totalGross += validateNumber(obj.gold_gross);
      //     totalNet += validateNumber(obj.gold_net);
      //     totalRate +=
      //       validateNumber(gold_rate) *
      //       validateNumber(obj[`gold_${gold_pricing.from}`]);
      //   }
      //   setVariantsTotal(totalRate, totalGross, totalNet);
      // }
      let amount =
        validateNumber(gold_rate) *
        validateNumber(info[`gold_${gold_pricing.from}`]);
      setGoldAmount(Math.round(amount));
    }
    if (silver_pricing && silver_purity) {
      let silver_rate = getMetalRate(
        silver_pricing[currency],
        silver_pricing.type,
        silver_purity
      );
      // setSilverRate(silver_rate);
      let amount =
        validateNumber(silver_rate) *
        validateNumber(info[`silver_${silver_pricing.from}`]);
      setSilverAmount(Math.round(amount));
    }
    if (platinum_pricing && platinium_purity) {
      let platinum_rate = getMetalRate(
        platinum_pricing[currency],
        platinum_pricing.type,
        platinium_purity
      );
      // setPlatinumRate(platinum_rate);
      let amount =
        validateNumber(platinum_rate) *
        validateNumber(info[`platinium_${platinum_pricing.from}`]);
      setPlatinumAmount(Math.round(amount));
    }
  };

  const setLabour = () => {
    let { gold_pricing, silver_pricing, platinum_pricing } = pricing;
    if (!isObjWithValues(pricing)) return;
    let {
      minimum_making,
      per_gram,
      wastage_percent,
      labour_from,
      metal_types,
      gold_kt,
      silver_purity,
      platinium_purity,
    } = info;
    let totalWeight = 0,
      totalMetalRate = 0;
    if (isArrayWithValues(metal_types)) {
      if (metal_types?.includes("gold")) {
        totalWeight += validateNumber(info[`gold_${labour_from}`]);
        let { type } = gold_pricing;
        if (
          gold_pricing[currency] &&
          gold_pricing[currency][type] &&
          gold_pricing[currency][type][gold_kt]
        ) {
          totalMetalRate += validateNumber(
            gold_pricing[currency][type][gold_kt].rate
          );
        }
      }

      if (metal_types?.includes("silver")) {
        totalWeight += validateNumber(info[`silver_${labour_from}`]);
        let { type } = silver_pricing;
        if (
          silver_pricing[currency] &&
          silver_pricing[currency][type] &&
          silver_pricing[currency][type][silver_purity]
        ) {
          totalMetalRate += validateNumber(
            silver_pricing[currency][type][silver_purity].rate
          );
        }
      }

      if (metal_types?.includes("platinium")) {
        totalWeight += validateNumber(info[`platinium_${labour_from}`]);

        let { type } = platinum_pricing;
        if (
          platinum_pricing[currency] &&
          platinum_pricing[currency][type] &&
          platinum_pricing[currency][type][platinium_purity]
        ) {
          totalMetalRate += validateNumber(
            platinum_pricing[currency][type][platinium_purity].rate
          );
        }
      }
      let total = totalWeight * validateNumber(per_gram);
      // let wastage = wastage_percent ? wastage_percent / 100 : 1;
      // let wastageTemp = totalWeight * validateNumber(totalMetalRate) * wastage;
      let wastageTemp =
        totalWeight * validateNumber(totalMetalRate) * (wastage_percent / 100);
      // total += Math.round(total * (wastage_percent / 100));
      total += wastageTemp;
      let totalLabour =
        validateNumber(minimum_making) < total
          ? validateNumber(total)
          : validateNumber(minimum_making);
      setTotalLabour(Math.round(totalLabour));
    }
  };

  const getMetalAmount = (pricing, purity, gross, net) => {
    let { type, from } = pricing;

    if (isObjWithValues(pricing[currency])) {
      pricing = pricing[currency];
      let rates = pricing[type];
      let rate = 0;
      if (isObjWithValues(rates) && rates[purity]) rate = rates[purity].rate;
      if (from === "gross")
        return Math.round(validateNumber(rate) * validateNumber(gross));
      if (from === "net")
        return Math.round(validateNumber(rate) * validateNumber(net));
      return 0;
    }
  };

  // const setLabourTotal = () => {
  //   let { labour_from, per_gram, wastage, variant, metal_types } = info;
  //   if (metal_types?.includes("gold"))
  //     if (isArrayWithValues(variants))
  //       variants.map(
  //         (i) => (totalWeight += validateNumber(i[`gold_${labour_from}`]))
  //       );
  //     else totalWeight += validateNumber(product[`gold_${labour_from}`]);
  //   if (metal_types?.includes("silver"))
  //     if (isArrayWithValues(variants))
  //       variants.map(
  //         (i) => (totalWeight += validateNumber(i[`silver_${labour_from}`]))
  //       );
  //     else totalWeight += validateNumber(product[`silver_${labour_from}`]);
  //   if (metal_types?.includes("platinium"))
  //     if (isArrayWithValues(variants))
  //       variants.map(
  //         (i) => (totalWeight += validateNumber(i[`platinium_${labour_from}`]))
  //       );
  //     else totalWeight += validateNumber(product[`platinium_${labour_from}`]);
  //   let total = totalWeight * validateNumber(per_gram);
  //   total += total * (wastage / 100);
  // };

  const onRemove = (id) => {
    // let products = quotation.products
    //   .split(",")
    //   .filter((i) => i != id)
    //   .join();
    // products = products.filter((i) => i.id !== id);
    setFetchedProducts((state) => state.filter((i) => i.id !== id));
    setQuotationView((state) => ({
      ...state,
      products: state.products.filter((i) => i != id),
      unselected_products: [...(state.unselected_products || []), id],
    }));
  };

  const onSizeAdd = (form) => {
    form.id = Date.now();
    let { customize } = quotation;
    let { sizes } = customize;
    let { id } = info;
    if (isArrayWithValues(sizes[id])) sizes[id].push(form);
    else sizes[id] = [{ ...form }];
    // quotation.customize.sizes = sizes;
    // console.log(sizes);
    setQuotationView((state) => ({
      ...state,
      customize: { ...state.customize, sizes },
    }));
    setProductSizes(sizes[id]);
  };
  const onSizeRemove = (id) => {
    let { customize } = quotation;
    let { sizes } = customize;
    let array = sizes[info.id];
    if (isArrayWithValues(array)) {
      array = array.filter((i) => i.id !== id);
    }
    sizes[info.id] = array;

    setQuotationView((state) => ({
      ...state,
      customize: { ...state.customize, sizes },
    }));
    setProductSizes(sizes[info.id]);
  };

  const getDiamondQualityAlternatives = (pricings, shape, sieve) => {
    if (isArrayWithValues(pricings)) {
      let arr = [];
      for (let pricing of pricings)
        if (
          isArrayWithValues(pricing.shapes) &&
          pricing.shapes.find((i) => i.value == shape)
        )
          if (
            isArrayWithValues(pricing.sieves) &&
            pricing.sieves.find((i) => i.value == sieve)
          )
            arr.push(pricing);
      return arr;
    }
    return [];
  };

  useEffect(() => {
    let total = 0;
    let metalPrice = goldAmount + silverAmount + platinumAmount;
    total += metalPrice;
    total += validateNumber(diamondTotal.totalRate);
    total += validateNumber(gemstoneTotal.totalRate);
    total += totalLabour;
    total += validateNumber(extra_charges_total);
    setTotalPrice(total);
  }, [
    diamondTotal,
    gemstoneTotal,
    totalLabour,
    goldAmount,
    silverAmount,
    platinumAmount,
  ]);

  let {
    id,
    name,
    images,
    sku,
    gold_kt,
    silver_purity,
    platinium_purity,
    gold_gross,
    silver_gross,
    platinium_gross,
    gold_net,
    silver_net,
    platinium_net,
    diamond,
    colorstone_details,
    gold_rate,
    silver_rate,
    platinum_rate,
    gold_amount,
    silver_amount,
    platinum_amount,
    variants,
    wastage_percent,
    per_gram,
    labour,
    category,
    extra_charges,
    extra_charges_total,
  } = info;
  let {
    customPricing,
    defaultPricing,
    goldPricingType,
    silverPricingType,
    platinumPricingType,
  } = quotation;

  const onTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const addProductComment = async () => {
    let { id } = info;
    setAddingComment(true);
    let {
      customer,
      products,
      title,
      id: quotationId,
      product_comments,
      comments,
      customize,
    } = quotation;
    let commentObj = {
      message: comment,
      time: Math.floor(new Date().getTime() / 1000),
      from: "supplier",
    };
    let allComments = isObjWithValues(product_comments)
      ? {
          ...product_comments,
          [id]: isArrayWithValues(product_comments[id])
            ? [...product_comments[id], { ...commentObj }]
            : [{ ...commentObj }],
        }
      : { [id]: [{ ...commentObj }] };

    try {
      let obj = {
        type: "edit",
        id: quotationId,
        data: {
          title,
          products: products.join(),
          customer: customer.ID,
          comments,
          product_comments: allComments,
          customize,
        },
      };
      let website = await getWebsite();
      let token = await getToken();
      let res = await axios({
        url: `${website}/wp-json/store/v1/quotations`,
        method: "POST",
        headers: {
          Authorization: `Basic ${token}`,
        },
        data: obj,
      });
      if (res.data.success) {
        let { product_comments } = res.data.data;
        if (product_comments) {
          setComment("");
          setQuotationView((state) => ({ ...state, product_comments }));
          dispatch(
            setGlobalToast({
              show: true,
              message: "Comment Added successfully",
              severity: "success",
            })
          );
          // setToast({
          //   show: true,
          //   message: "Comment Added successfully",
          //   severity: "success",
          // });
        } else throw new Error();
      }
    } catch (error) {
      dispatch(
        setGlobalToast({
          show: true,
          message: "Couldn't add comment, Please try again later!",
          severity: "error",
        })
      );
      // setToast({
      //   show: true,
      //   message: "Couldn't add comment, Please try again later!",
      //   severity: "error",
      // });
    }
    setAddingComment(false);
  };

  const iconSize = isLg ? "15px" : "20px";
  const ImageSliderStyle = { width: "100%", height: "100%" };
  let { product_comments } = quotation;
  let goldPurity = goldPurities.find((i) => i.value == gold_kt);
  goldPurity = goldPurity ? goldPurity.label : gold_kt;

  return (
    <Box maxWidth={"100%"}>
      <Snackbar
        open={toast.show}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        TransitionComponent={Slide}
        style={{ marginRight: "2vw" }}
        onClose={() => setToast((state) => ({ ...state, show: false }))}
      >
        <Alert severity={toast.severity} sx={{ width: "100%" }}>
          {toast.message}
        </Alert>
      </Snackbar>
      <Box
        sx={{
          mt: 4,
          // border: "0.5px solid rgba(0,0,0,0.4)",
          px: 2,
          py: 3,
          maxWidth: "100%",
        }}
        maxWidth="100%"
      >
        <Stack direction={"row"} alignItems="center">
          <Typography variant="h6">{name}</Typography>
          <Button color="error" onClick={() => onRemove(id)}>
            Remove
          </Button>
        </Stack>
        <Typography color="text.secondary" mb={2} mt={0.5}>
          {/* {sku}{" "} */}
          {isArrayWithValues(category) && category[0].name
            ? category[0].name
            : ""}
        </Typography>
        <Grid container spacing={3}>
          <Grid
            item
            xs={12}
            sm={3}
            lg={3}
            sx={{ maxWidth: "100%", overflowX: "hidden" }}
          >
            <Box
              maxWidth="100%"
              maxHeight="100%"
              style={{ overflowX: "hidden", maxWidth: "400px" }}
            >
              {/* <Stack
                  direction="row"
                  alignItems={"center"}
                  mb={3}
                  maxWidth={"130px"}
                >
                  <IconButton
                    color="error"
                    disabled={isLastProduct}
                    onClick={() => onRemove(id)}
                  >
                    <Delete />
                  </IconButton>
                  <Typography variant="h6" textAlign={"center"}>
                    #{sku}
                  </Typography>
                </Stack> */}
              <Swiper
                spaceBetween={0}
                slidesPerView={1}
                // grabCursor
                pagination={{
                  // dynamicBullets: false,
                  enabled: true,
                  clickable: true,
                }}
                modules={[Pagination]}
                // modules={[EffectFade]}
                style={{
                  maxWidth: "100%",
                  // width: "100%",
                  overflowX: "hidden",
                }}
                // fadeEffect={""}
                // effect="fade"
                // direction="vertical"
                // onSlideChange={() => console.log('slide change')}
                // onSwiper={(swiper) => console.log(swiper)}
              >
                {(images || ["/static/img/woocommerce_placeholder.png"])
                  // .filter((e, i) => i <= 1)
                  .map((i) => {
                    return (
                      <SwiperSlide
                        style={{
                          maxWidth: "100%",
                          maxHeight: "100%",
                          // maxWidth: "160px",
                          // border: `0.3px solid rgba(0, 0, 0,0.3)`,
                        }}
                      >
                        <img
                          src={i.src || i}
                          style={{
                            maxWidth: "100%",
                            maxHeight: "100%",
                            // height: "200px",
                            // width: "100%",
                            userSelect: "none",
                            pointerEvents: "none",
                            // width: "100%",
                            // width: "160px",
                            // maxWidth: "160px",
                            // alignSelf: "flex-start",
                            // border: `0.3px solid rgba(0, 0, 0,0.3)`,
                            borderRadius: "8px",
                            // marginTop: "14px",
                            objectFit: "contain",
                            margin: "0px auto",
                            // marginTop: "8px",
                            textAlign: "center",
                            // flex: 1,
                          }}
                        />
                      </SwiperSlide>
                    );
                  })}
              </Swiper>
              {/* {isArrayWithValues(images) && (
              )} */}
              {/* {isArrayWithValues(images) && (
                <Carousel
                  autoPlay={false}
                  sx={{
                    maxWidth: "100%",
                    maxHeight: "100%",
                  }}
                >
                  {images.map((i) => {
                    return (
                      <Paper
                        textAlign={"center"}
                        sx={{
                          maxWidth: "100%",
                          maxHeight: "100%",
                          // maxWidth: "160px",
                        }}
                      >
                        <img
                          src={i.src}
                          style={{
                            maxWidth: "100%",
                            maxHeight: "100%",
                            // height: "200px",
                            // width: "100%",
                            userSelect: "none",
                            pointerEvents: "none",
                            // width: "100%",
                            // width: "160px",
                            // maxWidth: "160px",
                            // alignSelf: "flex-start",
                            // border: `0.3px solid rgba(0, 0, 0,0.3)`,
                            borderRadius: "8px",
                            // marginTop: "14px",
                            objectFit: "contain",
                            margin: "0px auto",
                            marginTop: "8px",
                            textAlign: "center",
                            // flex: 1,
                          }}
                        />
                      </Paper>
                    );
                  })}
                </Carousel>
              )} */}
              {/* {isArrayWithValues(images) && (
                <Carousel
                  responsive={responsive}
                  showDots
                  arrows={false} 

                  // renderDotsOutside
                  className="margin-bottom"
                  // dotListClass="margin-bottom"
                  // style={{ maxWidth: "100%" }}
                >
                  {images.map((i) => {
                    return (
                      <Box
                        textAlign={"center"}
                        sx={{
                          maxWidth: "100%",
                          // maxWidth: "160px",
                        }}
                      >
                        <img
                          src={i.src}
                          la
                          style={{
                            // height: "200px",
                            width: "100%",
                            height: "100%",
                            userSelect: "none",
                            pointerEvents: "none",
                            // width: "100%",
                            // width: "160px",
                            // maxWidth: "160px",
                            // alignSelf: "flex-start",
                            // border: `0.3px solid rgba(0, 0, 0,0.3)`,
                            borderRadius: "8px",
                            // marginTop: "14px",
                            objectFit: "contain",
                            margin: "0px auto",
                            marginTop: "8px",
                            textAlign: "center",
                            // flex: 1,
                          }}
                        />
                      </Box>
                    );
                  })}
                </Carousel>
              )} */}
            </Box>
          </Grid>
          <Grid item xs={12} sm={9} lg={9}>
            <Box sx={{ height: "100%", width: "100%" }}>
              <Box sx={{ width: "100%" }}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={selectedTab}
                    onChange={onTabChange}
                    variant="scrollable"
                    aria-label="basic tabs example"
                  >
                    <Tab label="Brief" {...a11yProps(0)} />
                    <Tab label="Break Up" {...a11yProps(1)} />
                    <Tab label="Customize" {...a11yProps(2)} />
                    <Tab label="Comments" {...a11yProps(3)} />
                  </Tabs>
                </Box>
                <TabPanel value={selectedTab} index={0}>
                  <Stack direction={"column"}>
                    {gold_gross && gold_kt ? (
                      <BriefRow
                        icon={
                          <img
                            // src="/static/img/plier-min.png"
                            src="https://upload.wikimedia.org/wikipedia/commons/d/d6/Gold_coin_icon.png"
                            style={{
                              height: isLg ? "19px" : "24px",
                              width: isLg ? "19px" : "24px",
                            }}
                          />
                        }
                        data={[
                          ["Kt", goldPurity],
                          [
                            "G Wt.",
                            validateNumber(
                              Number(+gold_gross * totalQuantity).toFixed(3)
                            ),
                          ],
                          [
                            "N Wt.",
                            validateNumber(
                              Number(+gold_net * totalQuantity).toFixed(3)
                            ),
                          ],
                          [
                            "Amt",
                            `${
                              currencySymbols[currency] || ""
                            }  ${validateNumber(
                              Number(+goldAmount * totalQuantity).toFixed(3)
                            )}`,
                          ],
                        ]}
                      />
                    ) : null}
                    {silver_gross && silver_purity ? (
                      <BriefRow
                        icon={
                          <img
                            src="https://shop.mmtcpamp.com/uploads/product/51utjbwkicl.png"
                            // src="https://www.regencymint.com/shop/wp-content/uploads/2020/01/Blank-Silver-Coin.png"
                            style={{
                              height: isLg ? "19px" : "24px",
                              width: isLg ? "19px" : "24px",
                            }}
                          />
                        }
                        data={[
                          ["Kt", silver_purity],
                          ["G Wt.", +silver_gross * totalQuantity],
                          ["N Wt.", +silver_net * totalQuantity],
                          [
                            "Amt",
                            `${currencySymbols[currency] || ""} ${
                              +silverAmount * totalQuantity
                            }`,
                          ],
                        ]}
                      />
                    ) : null}
                    {platinium_gross && platinium_purity ? (
                      <BriefRow
                        icon={
                          <img
                            src="https://www.regencymint.com/shop/wp-content/uploads/2020/01/Blank-Silver-Coin-324x324.png"
                            style={{
                              height: isLg ? "19px" : "24px",
                              width: isLg ? "19px" : "24px",
                            }}
                          />
                        }
                        data={[
                          ["Kt", platinium_purity],
                          ["G Wt.", +platinium_gross * totalQuantity],
                          ["N Wt.", +platinium_net * totalQuantity],
                          [
                            "Amt",
                            `${currencySymbols[currency] || ""} ${
                              +platinumAmount * totalQuantity
                            }`,
                          ],
                        ]}
                      />
                    ) : null}
                    {per_gram ? (
                      <BriefRow
                        icon={
                          <img
                            // src="/static/img/plier-min.png"
                            src="https://icons.iconarchive.com/icons/icons8/windows-8/512/Transport-Road-Worker-icon.png"
                            style={{
                              height: isLg ? "17px" : "22px",
                              width: isLg ? "17px" : "22px",
                              color: "blue",
                            }}
                          />
                          // <Person />
                        }
                        data={[
                          ["Wastage", `${wastage_percent}%`],
                          [
                            "Min Making",
                            `${currencySymbols[currency] || ""} ${
                              labour || ""
                            }`,
                          ],
                          [
                            "",
                            `${currencySymbols[currency] || ""} ${per_gram}/gm`,
                          ],
                          [
                            "Amt",
                            `${currencySymbols[currency] || ""} ${
                              +totalLabour * totalQuantity
                            }`,
                          ],
                        ]}
                      />
                    ) : null}
                    {isObjWithValues(diamond) &&
                    diamondTotal.totalRate &&
                    diamondTotal.totalWeight ? (
                      <BriefRow
                        icon={
                          <img
                            src="https://cdn-icons-png.flaticon.com/512/218/218466.png"
                            style={{
                              height: isLg ? "19px" : "24px",
                              width: isLg ? "19px" : "24px",
                            }}
                          />
                          // <Diamond />
                        }
                        data={[
                          [
                            "Qlty.",
                            diamond["1"] && diamond["1"].diamond_quality,
                          ],
                          ["Shape", diamond["1"] && diamond["1"].diamond_shape],
                          ["D Wt.", diamondTotal.totalWeight],
                          [
                            "Amt",
                            `${currencySymbols[currency] || ""} ${
                              diamondTotal.totalRate
                            }`,
                          ],
                        ]}
                      />
                    ) : null}
                    {isObjWithValues(colorstone_details) &&
                    gemstoneTotal.totalWeight &&
                    gemstoneTotal.totalRate ? (
                      <BriefRow
                        icon={
                          <img
                            src="https://cdn-icons-png.flaticon.com/512/785/785126.png"
                            style={{
                              height: isLg ? "19px" : "24px",
                              width: isLg ? "19px" : "24px",
                            }}
                          />
                        }
                        data={[
                          [
                            "Qlty.",
                            colorstone_details["1"] &&
                              colorstone_details["1"].colorstone_quality,
                          ],
                          [
                            "Shape",
                            colorstone_details["1"] &&
                              colorstone_details["1"].colorstone_shape,
                          ],
                          ["G Wt.", `${gemstoneTotal.totalWeight}`],
                          [
                            "Amt",
                            `${currencySymbols[currency] || ""} ${
                              gemstoneTotal.totalRate
                            }`,
                          ],
                        ]}
                      />
                    ) : null}
                    {isObjWithValues(extra_charges) ? (
                      <BriefRow
                        icon={
                          <img
                            // src="/static/img/plier-min.png"
                            src="https://tanikatech.app.tanika.tech/wp-content/themes/supplier-frontend/assets/labour.png"
                            style={{
                              height: isLg ? "17px" : "22px",
                              width: isLg ? "17px" : "22px",
                              color: "blue",
                            }}
                          />
                          // <Person />
                        }
                        data={[
                          ["Extra Charges", ``],
                          ["", ``],
                          ["", ``],
                          [
                            "Amt",
                            `${
                              currencySymbols[currency] || ""
                            } ${extra_charges_total}`,
                          ],
                        ]}
                      />
                    ) : null}
                    <Stack
                      direction="row"
                      justifyContent={"space-between"}
                      maxWidth={!isLg ? "80%" : "100%"}
                      sx={{
                        border: "0.3px solid rgba(0,0,0,0.3)",
                        px: 3,
                        py: 2,
                        borderRadius: "6px",
                        mt: 3,
                      }}
                    >
                      <Typography variant="h5">
                        Grand Total x {totalQuantity}:
                      </Typography>
                      <Typography variant="h5" fontWeight={"bold"}>
                        {currencySymbols[currency] || ""}{" "}
                        {Math.round(totalPrice * totalQuantity)}
                      </Typography>
                    </Stack>
                  </Stack>
                </TabPanel>
                <TabPanel value={selectedTab} index={1}>
                  <Grid container columnSpacing={3}>
                    {goldAmount || silverAmount || platinumAmount ? (
                      <Grid item xs={12} md={6}>
                        <Box sx={{}}>
                          <MetalDetailBox
                            title="Metals"
                            th={
                              isArrayWithValues(variants)
                                ? ["Size", "KT", "Gross", "Net", "Amt"]
                                : ["Metal", "KT", "Gross", "Net", "Amt"]
                            }
                            td={[
                              gold_kt &&
                                gold_gross && [
                                  <Box
                                    sx={{
                                      height: "20px",
                                      width: "20px",
                                      borderRadius: "10px",
                                      backgroundColor: "#FFD700",
                                      ml: 1,
                                    }}
                                  />,
                                  gold_kt,
                                  gold_gross,
                                  gold_net,
                                  `${
                                    currencySymbols[currency] || ""
                                  }  ${goldAmount}`,
                                ],
                              silver_purity &&
                                silver_gross && [
                                  <Box
                                    sx={{
                                      height: "20px",
                                      width: "20px",
                                      borderRadius: "10px",
                                      backgroundColor: "#C0C0C0",
                                      ml: 1,
                                    }}
                                  />,
                                  silver_purity,
                                  silver_gross,
                                  silver_net,
                                  `${
                                    currencySymbols[currency] || ""
                                  }  ${silverAmount}`,
                                ],
                              platinium_purity &&
                                platinium_gross && [
                                  <Box
                                    sx={{
                                      height: "20px",
                                      width: "20px",
                                      borderRadius: "10px",
                                      backgroundColor: "#e5e4e2",
                                      ml: 1,
                                    }}
                                  />,
                                  platinium_purity,
                                  platinium_gross,
                                  platinium_net,
                                  `${
                                    currencySymbols[currency] || ""
                                  }  ${platinumAmount}`,
                                ],
                              [
                                "Total",
                                "",
                                validateNumber(gold_gross) +
                                  validateNumber(silver_gross) +
                                  validateNumber(platinium_gross),
                                validateNumber(gold_net) +
                                  validateNumber(silver_net) +
                                  validateNumber(platinium_net),
                                `${currencySymbols[currency] || ""} ${
                                  validateNumber(goldAmount) +
                                  validateNumber(silverAmount) +
                                  validateNumber(platinumAmount)
                                }`,
                              ],
                              // [
                              //   `Grand Total x ${totalQuantity}`,
                              //   "",
                              //   `${validateNumber(
                              //     (
                              //       (validateNumber(gold_gross) +
                              //         validateNumber(silver_gross) +
                              //         validateNumber(platinium_gross)) *
                              //       totalQuantity
                              //     ).toFixed(3)
                              //   )}`,
                              //   `${validateNumber(
                              //     (
                              //       (validateNumber(gold_net) +
                              //         validateNumber(silver_net) +
                              //         validateNumber(platinium_net)) *
                              //       totalQuantity
                              //     ).toFixed(3)
                              //   )}`,
                              //   `${currencySymbols[currency] || ""} ${
                              //     (validateNumber(goldAmount) +
                              //       validateNumber(silverAmount) +
                              //       validateNumber(platinumAmount)) *
                              //     totalQuantity
                              //   }`,
                              // ],
                            ]}
                            showSizes={isArrayWithValues(variants)}
                          />
                        </Box>
                      </Grid>
                    ) : null}
                    {per_gram ? (
                      <Grid item xs={12} md={6}>
                        <MetalDetailBox
                          title="Labour"
                          th={["%", "Per gram", "Min Making", "Amt"]}
                          td={[
                            [
                              wastage_percent,
                              per_gram,
                              `${currencySymbols[currency] || ""} ${labour}`,
                              `${
                                currencySymbols[currency] || ""
                              } ${totalLabour}`,
                            ],
                            // [
                            //   `Grand Total x ${totalQuantity}`,
                            //   "",
                            //   "",
                            //   ` ${currencySymbols[currency] || ""} ${
                            //     totalLabour * totalQuantity
                            //   }`,
                            // ],
                          ]}
                        />
                      </Grid>
                    ) : null}
                    {isArrayWithValues(diamondTd) &&
                    isArrayWithValues(diamondTd[diamondTd.length - 1]) &&
                    diamondTd[diamondTd.length - 1][
                      diamondTd[diamondTd.length - 1].length - 1
                    ] ? (
                      <Grid item xs={12} md={12}>
                        <MetalDetailBox
                          title="Diamond"
                          th={["Quality", "Shape", "Size", "Pcs", "Wt", "Rate"]}
                          td={diamondTd}
                        />
                      </Grid>
                    ) : null}

                    {isArrayWithValues(gemstoneTd) &&
                    isArrayWithValues(gemstoneTd[gemstoneTd.length - 1]) &&
                    gemstoneTd[gemstoneTd.length - 1][
                      gemstoneTd[gemstoneTd.length - 1].length - 1
                    ] ? (
                      <Grid item xs={12} md={12}>
                        <Box>
                          <MetalDetailBox
                            title="Gemstone"
                            th={[
                              "Qty.",
                              "Type",
                              "Shape",
                              "Pcs.",
                              "wt.",
                              "Rate",
                            ]}
                            td={gemstoneTd}
                          />
                        </Box>
                      </Grid>
                    ) : null}

                    {extra_charges_total && (
                      <Grid item xs={12} md={5}>
                        <Box>
                          <MetalDetailBox
                            title="Extra Charges"
                            th={["Type", "Rate"]}
                            td={extraChargesTd}
                          />
                        </Box>
                      </Grid>
                    )}
                  </Grid>
                  {/* <Stack
                direction="row"
                justifyContent={"flex-start"}
                flexWrap="wrap"
                spacing={4}
              >
              </Stack> */}
                </TabPanel>
                <TabPanel value={selectedTab} index={2}>
                  <Customize
                    allSizes={allSizes}
                    onAdd={onSizeAdd}
                    selectedSizes={
                      quotation.customize &&
                      quotation.customize.sizes &&
                      quotation.customize.sizes[id]
                    }
                    onRemove={onSizeRemove}
                  />
                </TabPanel>
                <TabPanel value={selectedTab} index={3}>
                  {quotation &&
                    isObjWithValues(product_comments) &&
                    isArrayWithValues(product_comments[id]) &&
                    product_comments[id].map((comment) => {
                      let time = formatDate(
                        new Date(comment.time * 1000),
                        "dd:mm:yyyy hh:mm am/pm"
                      );
                      return (
                        <>
                          <Box sx={{ mt: 3 }}>
                            <Stack direction="row">
                              <Typography variant="h6" fontSize="13px">
                                {comment.from === "supplier"
                                  ? "Admin"
                                  : "Anonymous"}
                              </Typography>
                              <Typography fontSize="11px" ml={2}>
                                {time}
                              </Typography>
                            </Stack>
                            <Typography mt={2} variant="h6" fontSize="13px">
                              {comment.message}
                            </Typography>
                          </Box>
                          <Divider sx={{ my: 2 }} />
                        </>
                      );
                    })}
                  <TextField
                    variant="standard"
                    fullWidth
                    placeholder="Type a comment"
                    sx={{ my: 3 }}
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                    // endAdornment={
                    //   <InputAdornment position="end">
                    //     <IconButton
                    //     // onClick={handleClickShowPassword}
                    //     // onMouseDown={handleMouseDownPassword}
                    //     // edge="end"
                    //     >
                    //       <SendRounded
                    //         style={{ height: "15px", width: "15px" }}
                    //       />
                    //     </IconButton>
                    //   </InputAdornment>
                    // }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">
                          <LoadingButton
                            loading={addingComment}
                            onClick={addProductComment}
                            // onMouseDown={handleMouseDownPassword}
                            // edge="end"
                            sx={{ mb: 1 }}
                          >
                            <SendRounded
                              style={{ height: "25px", width: "25px" }}
                            />
                          </LoadingButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </TabPanel>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

const singleSizeMasters = {
  earrings: [
    { label: "Mumbai Screw", value: "Mumbai Screw" },
    { label: "South Screw", value: "South Screw" },
    { label: "Others", value: "Others" },
  ],
  necklaces: [
    { label: "Without Chain", value: "Without Chain" },
    { label: "With Chain", value: "With Chain" },
  ],
  pendants: [
    { label: "Without Chain", value: "Without Chain" },
    { label: "With Chain", value: "With Chain" },
  ],
  "nose-pins": [
    { label: "Screw", value: "Screw" },
    { label: "Wire Type", value: "Wire Type" },
  ],
};

const Customize = ({ allSizes, onAdd, selectedSizes, onRemove }) => {
  const [form, setForm] = useState({
    size: "",
    color: "",
    quantity: 1,
  });
  return (
    <Box>
      {isArrayWithValues(selectedSizes) && (
        <Box
          sx={{
            border: "0.5px solid rgba(0,0,0,0.4)",
            borderRadius: "6px",
          }}
        >
          <Typography variant="body2" fontWeight={"bold"} ml={3} mt={2}>
            Sizes
          </Typography>
          {selectedSizes.map((obj) => {
            return (
              <Grid container spacing={2} mb={2} alignItems="center">
                <Grid item xs={3} textAlign="center">
                  <Typography variant="body2">
                    Size: <strong>{obj.size}</strong>
                  </Typography>
                </Grid>
                <Grid item xs={3} textAlign="center">
                  <Typography variant="body2">
                    Color: <strong>{obj.color}</strong>
                  </Typography>
                </Grid>
                <Grid item xs={3} textAlign="center">
                  <Typography variant="body2">
                    Qty: <strong>{obj.quantity}</strong>
                  </Typography>
                </Grid>
                <Grid item xs={3} textAlign="center">
                  <Button
                    color="error"
                    // disabled={selectedSizes.length === 1}
                    onClick={() => onRemove(obj.id)}
                  >
                    Remove
                  </Button>
                </Grid>
              </Grid>
            );
          })}
        </Box>
      )}
      <Grid container spacing={3} mt={2}>
        <Grid item xs={6} sm={3}>
          <FormControl sx={{}} fullWidth size="small">
            <InputLabel id="demo-multiple-checkbox-label">Sizes</InputLabel>
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              value={form.size}
              onChange={(e) =>
                setForm((state) => ({ ...state, size: e.target.value }))
              }
              input={<OutlinedInput label="Sizes" />}
              // renderValue={(selected) => selected.join(", ")}
              // MenuProps={MenuProps}
            >
              {allSizes.map((obj) => (
                <MenuItem key={obj.value} value={obj.value}>
                  {/* <Checkbox /> */}
                  <Stack
                    direction={"row"}
                    justifyContent="space-between"
                    sx={{ flex: 1 }}
                  >
                    <Typography variant="h6" fontSize={"13px"}>
                      {obj.label}
                    </Typography>
                    {/* <Typography
                      variant="h6"
                      fontSize={"13px"}
                      style={{ marginLeft: "auto" }}
                    >
                      {obj.label}
                      {stockStatus[obj.stock_status]}{" "}
                      {obj.stock_status === "instock"
                        ? `(${obj.stock_quantity})`
                        : `(0)`}
                    </Typography> */}
                  </Stack>
                  {/* <ListItemText
                  primary={`₹{obj.size} ₹{stockStatus[obj.stock_status]}`}
                /> */}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6} sm={3}>
          <FormControl sx={{}} fullWidth size="small">
            <InputLabel id="demo-multiple-checkbox-label">Color</InputLabel>
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              value={form.color}
              onChange={(e) =>
                setForm((state) => ({ ...state, color: e.target.value }))
              }
              input={<OutlinedInput label="Color" />}
              // renderValue={(selected) => selected.join(", ")}
              // MenuProps={MenuProps}
            >
              {allMetalColors.map((obj) => (
                <MenuItem key={obj.value} value={obj.value}>
                  {/* <Checkbox /> */}
                  <Stack
                    direction={"row"}
                    justifyContent="space-between"
                    sx={{ flex: 1 }}
                  >
                    <Typography variant="h6" fontSize={"13px"}>
                      {obj.label}
                    </Typography>
                    {/* <Typography
                        variant="h6"
                        fontSize={"13px"}
                        style={{ marginLeft: "auto" }}
                      >
                        {obj.label}
                        {stockStatus[obj.stock_status]}{" "}
                        {obj.stock_status === "instock"
                          ? `(${obj.stock_quantity})`
                          : `(0)`}
                      </Typography> */}
                  </Stack>
                  {/* <ListItemText
                    primary={`₹{obj.size} ₹{stockStatus[obj.stock_status]}`}
                  /> */}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {/* <Box
            sx={{
              // border: "0.3px solid rgba(0,0,0,0.3)",
              // py: 0.8,
              // px: 2,
              borderRadius: "6px",
            }}
          >
            <Stack direction={"row"} alignItems="center">
              <Typography variant="h6">Colors: </Typography>
              {allMetalColors.map((i) => {
                return (
                  <Tooltip title={i.value}>
                    <IconButton
                      sx={{ p: 1.5 }}
                      onClick={() =>
                        setForm((state) => ({ ...state, color: i.value }))
                      }
                      // onClick={() => onColorClick(id, i.value)}
                    >
                      <Box
                        sx={{
                          background: colorsBackground[i.value],
                          height: "18px",
                          width: "18px",
                          borderRadius: "50%",
                          border: `2px solid ${
                            i.value === form.color ? "#000" : "transparent"
                          }`,
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                );
              })}
            </Stack>
          </Box> */}
        </Grid>
        <Grid item xs={6} sm={3} lg={3}>
          <ButtonGroup size="medium" fullWidth aria-label="small button group">
            <Button
              sx={{
                maxWidth: "22px",
                border: "1px solid #8c8c8c",
                color: "#8c8c8c",
                height: "32px",
              }}
              onClick={() =>
                setForm((state) => ({
                  ...state,
                  quantity: state.quantity - 1,
                }))
              }
              disabled={form.quantity <= 1}
            >
              <Remove />
            </Button>
            <Button
              sx={{
                height: "32px",
              }}
              disabled
              fullWidth
            >
              {form.quantity}
            </Button>
            <Button
              sx={{
                maxWidth: "22px",
                border: "1px solid #8c8c8c",
                color: "#8c8c8c",
                height: "32px",
              }}
              onClick={() =>
                setForm((state) => ({
                  ...state,
                  quantity: state.quantity + 1,
                }))
              }
            >
              <Add />
            </Button>
          </ButtonGroup>
        </Grid>
        <Grid item xs={6} sm={3} lg={3}>
          <Button
            variant="contained"
            fullWidth
            size="small"
            disabled={!form.size || !form.color}
            onClick={() => onAdd(form)}
          >
            Add
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

// const selectedSizes = ({ form, allSizes }) => {
//   return (
//     <Grid container spacing={3}>
//       <Grid item xs={6} sm={3}>
//         <FormControl disabled sx={{}} fullWidth size="small">
//           <InputLabel id="demo-multiple-checkbox-label">Sizes</InputLabel>
//           <Select
//             labelId="demo-multiple-checkbox-label"
//             id="demo-multiple-checkbox"
//             value={form.size}
//             onChange={(e) =>
//               setForm((state) => ({ ...state, size: e.target.value }))
//             }
//             input={<OutlinedInput label="Sizes" />}
//             // renderValue={(selected) => selected.join(", ")}
//             // MenuProps={MenuProps}
//           >
//             {allSizes.map((obj) => (
//               <MenuItem key={obj.value} value={obj.value}>
//                 {/* <Checkbox /> */}
//                 <Stack
//                   direction={"row"}
//                   justifyContent="space-between"
//                   sx={{ flex: 1 }}
//                 >
//                   <Typography variant="h6" fontSize={"13px"}>
//                     {obj.label}
//                   </Typography>
//                   {/* <Typography
//                       variant="h6"
//                       fontSize={"13px"}
//                       style={{ marginLeft: "auto" }}
//                     >
//                       {obj.label}
//                       {stockStatus[obj.stock_status]}{" "}
//                       {obj.stock_status === "instock"
//                         ? `(${obj.stock_quantity})`
//                         : `(0)`}
//                     </Typography> */}
//                 </Stack>
//                 {/* <ListItemText
//                   primary={`₹{obj.size} ₹{stockStatus[obj.stock_status]}`}
//                 /> */}
//               </MenuItem>
//             ))}
//           </Select>
//         </FormControl>
//       </Grid>
//       <Grid item xs={6} sm={3}>
//         <FormControl disabled sx={{}} fullWidth size="small">
//           <InputLabel id="demo-multiple-checkbox-label">Color</InputLabel>
//           <Select
//             labelId="demo-multiple-checkbox-label"
//             id="demo-multiple-checkbox"
//             value={form.color}
//             onChange={(e) =>
//               setForm((state) => ({ ...state, color: e.target.value }))
//             }
//             input={<OutlinedInput label="Color" />}
//             // renderValue={(selected) => selected.join(", ")}
//             // MenuProps={MenuProps}
//           >
//             {allMetalColors.map((obj) => (
//               <MenuItem key={obj.value} value={obj.value}>
//                 {/* <Checkbox /> */}
//                 <Stack
//                   direction={"row"}
//                   justifyContent="space-between"
//                   sx={{ flex: 1 }}
//                 >
//                   <Typography variant="h6" fontSize={"13px"}>
//                     {obj.label}
//                   </Typography>
//                   {/* <Typography
//                         variant="h6"
//                         fontSize={"13px"}
//                         style={{ marginLeft: "auto" }}
//                       >
//                         {obj.label}
//                         {stockStatus[obj.stock_status]}{" "}
//                         {obj.stock_status === "instock"
//                           ? `(${obj.stock_quantity})`
//                           : `(0)`}
//                       </Typography> */}
//                 </Stack>
//                 {/* <ListItemText
//                     primary={`₹{obj.size} ₹{stockStatus[obj.stock_status]}`}
//                   /> */}
//               </MenuItem>
//             ))}
//           </Select>
//         </FormControl>
//         {/* <Box
//             sx={{
//               // border: "0.3px solid rgba(0,0,0,0.3)",
//               // py: 0.8,
//               // px: 2,
//               borderRadius: "6px",
//             }}
//           >
//             <Stack direction={"row"} alignItems="center">
//               <Typography variant="h6">Colors: </Typography>
//               {allMetalColors.map((i) => {
//                 return (
//                   <Tooltip title={i.value}>
//                     <IconButton
//                       sx={{ p: 1.5 }}
//                       onClick={() =>
//                         setForm((state) => ({ ...state, color: i.value }))
//                       }
//                       // onClick={() => onColorClick(id, i.value)}
//                     >
//                       <Box
//                         sx={{
//                           background: colorsBackground[i.value],
//                           height: "18px",
//                           width: "18px",
//                           borderRadius: "50%",
//                           border: `2px solid ${
//                             i.value === form.color ? "#000" : "transparent"
//                           }`,
//                         }}
//                       />
//                     </IconButton>
//                   </Tooltip>
//                 );
//               })}
//             </Stack>
//           </Box> */}
//       </Grid>
//       <Grid item xs={6} sm={3} lg={3}>
//         <ButtonGroup size="medium" fullWidth aria-label="small button group">
//           <Button
//             sx={{
//               maxWidth: "22px",
//               border: "1px solid #8c8c8c",
//               color: "#8c8c8c",
//               height: "32px",
//             }}
//             onClick={() =>
//               setForm((state) => ({
//                 ...state,
//                 quantity: state.quantity - 1,
//               }))
//             }
//             disabled
//             // disabled={form.quantity <= 1}
//           >
//             <Remove />
//           </Button>
//           <Button
//             sx={{
//               height: "32px",
//             }}
//             disabled
//             fullWidth
//           >
//             {form.quantity}
//           </Button>
//           <Button
//             sx={{
//               maxWidth: "22px",
//               border: "1px solid #8c8c8c",
//               color: "#8c8c8c",
//               height: "32px",
//             }}
//             disabled
//             // onClick={() =>
//             //   setForm((state) => ({
//             //     ...state,
//             //     quantity: state.quantity + 1,
//             //   }))
//             // }
//           >
//             <Add />
//           </Button>
//         </ButtonGroup>
//       </Grid>
//       <Grid item xs={6} sm={3} lg={3}>
//         <Button
//           variant="contained"
//           fullWidth
//           size="small"
//           // onClick={() => onAdd(form)}
//         >
//           Remove
//         </Button>
//       </Grid>
//     </Grid>
//   );
// };

const allMetalColors = [
  { label: "White", value: "white" },
  { label: "Yellow", value: "yellow" },
  { label: "Rose", value: "rose" },
  { label: "Two Tone", value: "two-tone" },
];

const colorsBackground = {
  white: "#EBEBEB",
  yellow: "#EAC97D",
  green: "#14903F",
  rose: "#FFCBB2",
  "two-tone": "linear-gradient(to right, #EBEBEB 40%, #FFCBB2) 50%",
};
//name, customer name, created, total value, selected products,product quantity, last edited, visits
const BriefRow = ({ icon, data }) => {
  const theme = useTheme();
  const isLg = useMediaQuery(theme.breakpoints.down("lg"));
  // const isLg = false;
  return (
    <Box
      sx={{
        overflow: "scroll",
        maxWidth: isLg ? "100%" : "80%",
        mt: 3,
      }}
      className="scrollbar-hidden"
    >
      <Stack direction={"row"} alignItems="center">
        <Box sx={{ mx: 2 }}>{icon}</Box>
        {isLg ? (
          isArrayWithValues(data) &&
          data.map((i) => {
            return (
              <RenderBriefRowValue key={JSON.stringify(i)} i={i} isLg={isLg} />
            );
          })
        ) : (
          <Grid container spacing={2}>
            {isArrayWithValues(data) &&
              data.map((i) => {
                if (isLg)
                  return (
                    <RenderBriefRowValue
                      key={JSON.stringify(i)}
                      i={i}
                      isLg={isLg}
                    />
                  );
                return (
                  <Grid item xs={3}>
                    <RenderBriefRowValue
                      key={JSON.stringify(i)}
                      i={i}
                      isLg={isLg}
                    />
                  </Grid>
                );
              })}
          </Grid>
        )}
      </Stack>
      <Divider
        sx={{
          my: 2,
          // backgroundColor: "warning.main",
          borderBottomWidth: "0.3px",
        }}
      />
    </Box>
  );
};

const RenderBriefRowValue = ({ i, isLg }) => {
  return (
    <Stack
      direction={"row"}
      mx={isLg ? 2 : 3}
      alignItems={"flex-end"}
      whiteSpace="nowrap"
    >
      {i[0] && (
        <Typography
          variant="body2"
          fontWeight={"bold"}
          fontSize={"12px"}
          color="text.secondary"
          whiteSpace={"nowrap"}
          mr={2}
        >
          {i[0]}
        </Typography>
      )}
      <Typography
        variant="h6"
        whiteSpace={"nowrap"}
        fontWeight={"bold"}
        fontSize={isLg ? "17px" : "16px"}
      >
        {i[1]}
      </Typography>
    </Stack>
  );
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-₹{index}`}
      aria-labelledby={`simple-tab-₹{index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const UnexpandedDetails = ({ title, data }) => {
  return (
    <Box>
      <Typography variant="h6">{title}</Typography>
      <Box sx={{ ml: 1, mt: 1 }}>
        {Array.isArray(data) &&
          data.map((i) => {
            return (
              <Stack direction={"row"} alignItems="center">
                <Typography variant="body2" mr={1}>
                  {i[0]}:{" "}
                </Typography>
                <Typography variant="body1" fontWeight="bold">
                  {i[1]}
                </Typography>
              </Stack>
            );
          })}
        {/* <Stack direction={"row"} alignItems="center">
          <Typography variant="body2" mr={1}>
            Weight:{" "}
          </Typography>
          <Typography variant="body1" fontWeight="bold">
            0.12
          </Typography>
        </Stack>
        <Stack direction={"row"} alignItems="center">
          <Typography variant="body2" mr={1}>
            Amount:{" "}
          </Typography>
          <Typography variant="body1" fontWeight="bold">
            10,300
          </Typography>
        </Stack> */}
      </Box>
    </Box>
  );
};

const MetalDetailBox = ({ title, th, td, showSizes }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const onExpand = () => setIsExpanded(!isExpanded);
  const theme = useTheme();
  const isLg = useMediaQuery(theme.breakpoints.down("lg"));
  useEffect(() => {
    if (!isLg) setIsExpanded(true);
    else setIsExpanded(false);
  }, [isLg]);
  return (
    <Box sx={{ flex: 1 }}>
      <Stack
        direction="row"
        justifyContent={"space-between"}
        alignItems="center"
      >
        <Typography variant="h6" my={2} ml={2}>
          {title}
        </Typography>
        {isLg && (
          <IconButton onClick={onExpand}>
            <ExpandMore />
          </IconButton>
        )}
      </Stack>
      <Collapse in={isExpanded}>
        <Box
          sx={{
            border: "0.4px solid",
            borderColor: "rgba(0,0,0,0.3)",
            borderRadius: "6px",
            // height: "100%",
            p: 4,
            pt: 2,
            // width: "100%",
          }}
        >
          <TableContainer>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  {Array.isArray(th) &&
                    th.map((i) => {
                      return (
                        <TableCell sx={{ p: 2 }} align="left" key={i}>
                          {i}
                        </TableCell>
                      );
                    })}
                </TableRow>
              </TableHead>
              <TableBody
                sx={{
                  "&:last-child": {
                    marginTop: "34px",
                  },
                }}
              >
                {Array.isArray(td) &&
                  td.map((data, index) => {
                    //   console.log(data);
                    let isTotal =
                      data &&
                      (data[0] === "Total" ||
                        (typeof data[0] === "string" &&
                          data[0].includes("Grand Total")));
                    const isGrandTotal =
                      data &&
                      typeof data[0] === "string" &&
                      data[0].includes("Grand Total");
                    return (
                      <TableRow key={index}>
                        {Array.isArray(data) &&
                          data.map((i) => {
                            return (
                              <TableCell
                                sx={{
                                  p: 2,
                                  pt: isGrandTotal ? 4 : 2,
                                  borderBottom: "none",
                                  fontWeight: isTotal ? "bold" : "normal",
                                  fontSize: isTotal ? "14px" : "12px",
                                }}
                                align="left"
                                key={i}
                              >
                                {i}
                              </TableCell>
                            );
                          })}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Collapse>
    </Box>
  );
};

const DiamondSummary = ({
  products,
  currency,
  pricing,
  setShowDiamondSummary,
  quotationView,
}) => {
  const [diamonds, setDiamonds] = useState([]);
  const [diamondsByCategory, setDiamondByCategory] = useState([]);
  const [allDiamonds, setAllDiamonds] = useState({});
  const [allDiamonds2, setAllDiamonds2] = useState({});
  const [diamondPricings, setDiamondPricings] = useState([]);
  const masterPricing = useSelector((state) => state.settings.masterPricing);
  const dispatch = useDispatch();

  const allDiamondGroups = useSelector(
    (state) => state.settings.allDiamondGroups
  );
  useEffect(() => {
    if (isObjWithValues(pricing)) {
      if (pricing.diamond_pricing && pricing.diamond_pricing[currency])
        setDiamondPricings([...pricing.diamond_pricing[currency]]);
    }
  }, [pricing]);

  useEffect(() => {
    if (isObjWithValues(allDiamonds) && isArrayWithValues(diamondPricings)) {
      let array = [];
      for (let i in allDiamonds) {
        let qualityDiamond = allDiamonds[i];

        let totalPieces = 0,
          totalRate = 0,
          totalWeight = 0;
        for (let j = 0; j < qualityDiamond.length; j++) {
          let {
            diamond_quality,
            diamond_shape,
            // diamond_sieve,
            diamond_cut,
            diamond_weight,
            diamond_type,
            diamond_pieces,
          } = qualityDiamond[j];

          totalWeight += Number(diamond_weight);
          totalPieces += Number(diamond_pieces);

          let diamond_sieve;
          if (isObjWithValues(allDiamondGroups)) {
            let netWeight = Number(
              Number(diamond_weight) / Number(diamond_pieces)
            );
            let diamondGroup = Object.values(allDiamondGroups);
            // console.log(diamondGroup);
            let obj = diamondGroup.find((i) => {
              return (
                i.shape === diamond_shape &&
                netWeight >= Number(i.from) &&
                netWeight <= Number(i.to)
              );
            });
            if (!obj)
              obj = diamondGroup.find((i) => {
                let netWeightFixed2 = Number(netWeight).toFixed(2);
                return (
                  i.shape === diamond_shape &&
                  netWeightFixed2 >= Number(i.from) &&
                  netWeightFixed2 <= Number(i.to)
                );
              });
            diamond_sieve = obj?.id;
          }
          let rate = getDiamondRate(
            diamondPricings,
            diamond_type,
            diamond_quality,
            diamond_shape,
            diamond_sieve,
            diamond_cut
          );
          array.push({
            ...qualityDiamond[j],
            diamond_rate: validateNumber(
              Math.round(validateNumber(rate) * Number(diamond_weight))
            ),
          });
        }
      }
      let totalWeight = 0,
        totalPieces = 0,
        totalRate = 0;
      array.map((i) => {
        totalWeight += validateNumber(i.diamond_weight);
        totalPieces += validateNumber(i.diamond_pieces);
        totalRate += validateNumber(i.diamond_rate);
      });
      totalWeight = validateNumber(parseFloat(totalWeight).toFixed(3));
      array.push({
        totalPieces,
        totalWeight,
        totalRate,
      });
      setDiamonds(array);
    }
  }, [allDiamonds, diamondPricings]);

  useEffect(() => {
    if (isObjWithValues(allDiamonds2) && isArrayWithValues(diamondPricings)) {
      let array = [];
      let totalMW = 0,
        totalP = 0,
        totalW = 0,
        totalQ = 0;
      for (let i in allDiamonds2) {
        let { quantity, totalPieces, totalWeight, totalMetalWeight } =
          allDiamonds2[i];
        array.push({
          ...allDiamonds2[i],
          category: i,
          totalWeight: validateNumber(parseFloat(totalWeight).toFixed(2)),
        });
        totalMW += totalMetalWeight;
        totalP += totalPieces;
        totalQ += Number(quantity);
        totalW += totalWeight;
        // let qualityDiamond = diamondsByCategory[i];
        // for (let j = 0; j < qualityDiamond.length; j++) {
        //   let {
        //     diamond_quality,
        //     diamond_shape,
        //     diamond_sieve,
        //     diamond_cut,
        //     diamond_weight,
        //   } = qualityDiamond[j];
        //   let rate = getDiamondRate(
        //     diamondPricings,
        //     diamond_quality,
        //     diamond_shape,
        //     diamond_sieve,
        //     diamond_cut
        //   );
        //   array.push({
        //     ...qualityDiamond[j],
        //     diamond_rate: Math.round(
        //       validateNumber(rate) * Number(diamond_weight)
        //     ),
        //   });
        // }
        totalW = validateNumber(parseFloat(totalW).toFixed(2));
      }
      totalW = parseFloat(totalW).toFixed(2);
      totalMW = validateNumber(parseFloat(totalMW).toFixed(2));

      array.push({ totalMW, totalP, totalW, totalQ });
      // let totalWeight = 0,
      //   totalPieces = 0,
      //   totalRate = 0;
      // array.map((i) => {
      //   totalWeight += validateNumber(i.diamond_weight);
      //   totalPieces += validateNumber(i.diamond_pieces);
      //   totalRate += validateNumber(i.diamond_rate);
      // });
      // array.push({
      //   totalPieces,
      //   totalWeight,
      //   totalRate,
      // });
      setDiamondByCategory(array);
    }
  }, [allDiamonds2, diamondPricings]);

  useEffect(() => {
    let allDiamonds = {};
    let allDiamonds2 = {};

    if (isArrayWithValues(products)) {
      for (let i = 0; i < products.length; i++) {
        let { meta_data, categories, id } = products[i];
        // console.log(categories[0].slug);
        let { customize } = quotationView;
        let sizes = customize && customize.sizes && customize.sizes[id];

        let productQuantity = 0;
        if (isArrayWithValues(sizes))
          sizes.map((i) => (productQuantity += Number(i.quantity)));

        if (allDiamonds2[categories[0].slug]) {
          let quantityIncrease = productQuantity;
          let { quantity } = allDiamonds2[categories[0].slug];
          allDiamonds2[categories[0].slug] = {
            ...allDiamonds2[categories[0].slug],
            quantity: validateNumber(quantity + quantityIncrease),
          };
        } else
          allDiamonds2[categories[0].slug] = {
            ...allDiamonds2[categories[0].slug],
            quantity: productQuantity,
          };
        let diamond = meta_data.find((i) => i.key === "diamond");

        if (diamond) {
          diamond = Object.values(diamond.value);
          if (isArrayWithValues(diamond)) {
            setShowDiamondSummary(true);
            let totalDiamondWeight = 0,
              totalDiamondPieces = 0,
              totalMetalWeightMain = 0;

            if (isArrayWithValues(categories)) {
              let totalMW = 0;
              let gold_net = meta_data.find((i) => i.key === "gold_net");
              let silver_net = meta_data.find((i) => i.key === "silver_net");
              let platinium_net = meta_data.find(
                (i) => i.key === "platinum_net"
              );
              if (gold_net && gold_net.value) totalMW += Number(gold_net.value);
              if (silver_net && silver_net.value)
                totalMW += Number(silver_net.value);
              if (platinium_net && platinium_net.value)
                totalMW += Number(platinium_net.value);
              totalMetalWeightMain += totalMW;
            }

            for (let j = 0; j < diamond.length; j++) {
              let singleDiamond = diamond[j];
              totalDiamondWeight += !isNaN(+singleDiamond.diamond_weight)
                ? +singleDiamond.diamond_weight
                : 0;
              totalDiamondPieces += +singleDiamond.diamond_pieces || 0;
              if (isArrayWithValues(categories))
                singleDiamond = {
                  ...singleDiamond,
                  diamond_pieces:
                    +singleDiamond.diamond_pieces * productQuantity,
                  diamond_weight:
                    parseFloat(
                      +singleDiamond.diamond_weight * productQuantity
                    ).toFixed(2) || 0,
                  category: categories[0].name || categories[0].slug,
                };
              if (Array.isArray(allDiamonds[singleDiamond.diamond_quality])) {
                let diamondsArray = allDiamonds[singleDiamond.diamond_quality];
                let index = diamondsArray.findIndex(
                  (i) =>
                    i.diamond_quality === singleDiamond.diamond_quality &&
                    i.diamond_shape === singleDiamond.diamond_shape &&
                    i.diamond_sieve === singleDiamond.diamond_sieve
                );
                if (index > -1) {
                  let { diamond_pieces, diamond_weight } = diamondsArray[index];
                  diamondsArray[index] = {
                    ...singleDiamond,
                    diamond_pieces:
                      validateNumber(singleDiamond.diamond_pieces) +
                      validateNumber(diamond_pieces),
                    diamond_weight:
                      parseFloat(
                        +singleDiamond.diamond_weight + +diamond_weight
                      ).toFixed(2) || 0,
                  };
                } else
                  allDiamonds[singleDiamond.diamond_quality].push({
                    ...singleDiamond,
                    diamond_weight:
                      parseFloat(singleDiamond.diamond_weight).toFixed(2) || 0,
                  });
              } else
                allDiamonds[singleDiamond.diamond_quality] = [
                  {
                    ...singleDiamond,
                    diamond_weight:
                      parseFloat(singleDiamond.diamond_weight).toFixed(2) || 0,
                  },
                ];
            }
            let { totalWeight, totalPieces, totalMetalWeight } =
              allDiamonds2[categories[0].slug];

            if (!totalWeight) totalWeight = 0;
            if (!totalPieces) totalPieces = 0;
            if (!totalMetalWeight) totalMetalWeight = 0;

            totalMetalWeight += +totalMetalWeightMain * +productQuantity || 0;
            totalMetalWeight = +totalMetalWeight.toFixed(2) || 0;

            totalWeight += +(+totalDiamondWeight * +productQuantity) || 0;
            totalWeight = +totalWeight.toFixed(2) || 0;
            totalPieces += +totalDiamondPieces * +productQuantity || 0;

            allDiamonds2[categories[0].slug].totalMetalWeight =
              totalMetalWeight;
            //   validateWeight(
            //   2
            // );
            allDiamonds2[categories[0].slug].totalPieces = totalPieces;
            allDiamonds2[categories[0].slug].totalWeight = totalWeight;
            //   validateWeight(
            //   totalWeight,
            //   2
            // );
            // console.log(allDiamonds2[categories[0].slug], categories[0].slug);
          }
        }
        // else {
        //   setShowDiamondSummary(false);
        // }
      }
    }

    // for (let i in allDiamonds2) {
    //   let { totalPieces, totalWeight, quantity } = allDiamonds2[i];
    //   allDiamonds2[i] = {
    //     ...allDiamonds2[i],
    //     totalPieces: totalPieces * quantity,
    //     totalWeight: totalWeight * quantity,
    //   };
    // }

    // allDiamonds2[categories[0].slug] = {
    //   ...allDiamonds2[categories[0].slug],
    //   totalPieces:
    //     allDiamonds2[categories[0].slug].totalPieces *
    //     validateNumber(allDiamonds2[categories[0].slug].quantity),
    //   totalWeight:
    //     allDiamonds2[categories[0].slug].totalWeight *
    //     validateNumber(allDiamonds2[categories[0].slug].quantity),
    // };

    setAllDiamonds2(allDiamonds2);
    setAllDiamonds(allDiamonds);
  }, [products, quotationView]);

  return (
    <>
      <Typography variant="h5" ml={2} mt={4} my={2}>
        Category Summary
      </Typography>
      <TableContainer
        component={Paper}
        sx={{
          border: "0.3px solid rgba(0,0,0,0.3)",
          mt: 4,
        }}
      >
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Jewellery Type</TableCell>
              <TableCell align="right">Metal Weight(Net)</TableCell>
              <TableCell align="right">Qty.</TableCell>
              <TableCell align="right">Pcs</TableCell>
              {/* <TableCell align="right">Fine Weight</TableCell> */}
              <TableCell align="right">Wt</TableCell>
            </TableRow>
          </TableHead>
          <TableBody sx={{ "&:last-child": { fontWeight: "bold" } }}>
            {diamondsByCategory.map((row, index) => {
              let {
                totalMW,
                totalP,
                totalW,
                totalQ,
                category,
                totalPieces,
                totalWeight,
                totalMetalWeight,
                quantity,
              } = row;
              if (totalMW || totalP) {
                return (
                  <TableRow
                    key={JSON.stringify(row)}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{ fontWeight: "bold" }}
                    >
                      Total
                    </TableCell>
                    <TableCell align="right" sx={{ fontWeight: "600" }}>
                      {totalMW} Gms.
                    </TableCell>
                    <TableCell align="right" sx={{ fontWeight: "600" }}>
                      {totalQ}
                    </TableCell>
                    <TableCell align="right" sx={{ fontWeight: "600" }}>
                      {totalP}
                    </TableCell>
                    <TableCell align="right" sx={{ fontWeight: "600" }}>
                      {totalW} Cts.
                    </TableCell>
                  </TableRow>
                );
              }
              return (
                <TableRow
                  key={JSON.stringify(row)}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {category}
                  </TableCell>
                  <TableCell align="right">{totalMetalWeight}</TableCell>
                  <TableCell align="right">{quantity}</TableCell>
                  <TableCell align="right">{totalPieces}</TableCell>
                  <TableCell align="right">{totalWeight}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={{ height: "20px" }} />

      <Typography variant="h5" ml={2} my={2}>
        Diamond Summary
      </Typography>
      <TableContainer
        component={Paper}
        sx={{
          border: "0.3px solid rgba(0,0,0,0.3)",
        }}
      >
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Quality</TableCell>
              <TableCell align="right">Shape</TableCell>
              <TableCell align="right">Sieve</TableCell>
              <TableCell align="right">Pieces</TableCell>
              <TableCell align="right">Weight</TableCell>
              {/* <TableCell align="right">Fine Weight</TableCell> */}
              {/* <TableCell align="right">Rate</TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {diamonds.map((row, index) => {
              let hidePreviousQuality =
                index > 0 &&
                diamonds[index - 1].diamond_quality === row.diamond_quality;
              if (row.totalPieces || row.totalRate)
                return (
                  <TableRow
                    key={JSON.stringify(row) + index}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{ fontWeight: "600" }}
                    >
                      Total
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={{ fontWeight: "600" }}
                    ></TableCell>
                    <TableCell
                      align="right"
                      sx={{ fontWeight: "600" }}
                    ></TableCell>
                    <TableCell align="right" sx={{ fontWeight: "600" }}>
                      {row.totalPieces}
                    </TableCell>
                    <TableCell align="right" sx={{ fontWeight: "600" }}>
                      {row.totalWeight} Cts.
                    </TableCell>
                    {/* <TableCell align="left">{row.totalRate}</TableCell> */}
                  </TableRow>
                );
              return (
                <TableRow
                  key={JSON.stringify(row) + index}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    sx={hidePreviousQuality ? { color: "#c9c9c9" } : {}}
                  >
                    {/* {hidePreviousQuality ? "" : row.diamond_quality} */}
                    {row.diamond_quality}
                  </TableCell>
                  <TableCell align="right">{row.diamond_shape}</TableCell>
                  <TableCell align="right">{row.diamond_sieve}</TableCell>
                  <TableCell align="right">{row.diamond_pieces}</TableCell>
                  <TableCell align="right">{row.diamond_weight}</TableCell>
                  {/* <TableCell align="left">{row.diamond_rate}</TableCell> */}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

const MetalSummary = ({
  products,
  currency,
  pricing,
  type,
  from,
  title,
  meta_keys,
  setShow,
  quotationView,
}) => {
  const [metals, setMetals] = useState([]);
  const [rows, setRows] = useState([]);

  useEffect(() => {
    if (pricing && pricing[type])
      if (isArrayWithValues(products)) {
        let object = {};
        let rows = [];
        let totalGross = 0,
          totalNet = 0,
          totalAmount = 0,
          totalQuantity = 0;
        for (let i = 0; i < products.length; i++) {
          let { meta_data, categories, id } = products[i];
          // console.log(categories[0].slug);
          let { customize } = quotationView;
          let sizes = customize && customize.sizes && customize.sizes[id];

          let productQuantity = 0;
          if (isArrayWithValues(sizes))
            sizes.map((i) => (productQuantity += Number(i.quantity)));

          totalQuantity += productQuantity;
          let obj = { rate: {} };
          meta_data.map((i) => {
            if (meta_keys.includes(i.key)) obj[i.key] = i.value;
          });
          if (obj[meta_keys[0]] && pricing[type][obj[meta_keys[0]]]) {
            let rateObj = pricing[type][obj[meta_keys[0]]];
            if (rateObj.rate) {
              obj.rate = rateObj;
              obj.amount = Math.round(validateNumber(rateObj.rate));
            }
          }
          if (isArrayWithValues(categories) && categories[0].name)
            obj.category = categories[0].name;
          else obj.category = "";

          // if (meta_keys.find((i) => i.includes("gross")))
          //   totalGross += validateNumber(
          //     obj[meta_keys.find((i) => i.search("gross"))]
          //   );
          let purity =
            obj[
              meta_keys.find((i) => i.includes("purity") || i.includes("kt"))
            ];
          let gross = obj[meta_keys.find((i) => i.includes("gross"))];
          let net = obj[meta_keys.find((i) => i.includes("net"))];
          if (
            !object[purity] ||
            (object[purity] && !object[purity]["category"])
          ) {
            if (!object[purity]) object[purity] = {};
          }
          if (
            rows.find((i) => i[0] === purity && i[1] === obj.category) ||
            true
          ) {
            let { rate } = obj;
            rate = Math.round(
              validateNumber(rate.rate) *
                validateNumber(from === "gross" ? gross : net)
            );
            let row = {
              purity,
              category: obj.category,
              gross: +gross * productQuantity,
              net: +net * productQuantity,
              amount: rate,
            };

            totalGross += row.gross;
            totalNet += row.net;
            let objToAdd = rows.find((i) => i.purity === purity);
            // console.log(objToAdd);
            if (objToAdd) {
              let { gross, net, amount, quantity } = objToAdd;
              objToAdd = {
                ...objToAdd,
                gross: parseFloat(
                  validateNumber(gross) + validateNumber(row.gross)
                ).toFixed(3),
                net: parseFloat(
                  validateNumber(net) + validateNumber(row.net)
                ).toFixed(2),
                amount: validateNumber(amount + row.amount),
                quantity: quantity + productQuantity,
              };
              rows = rows.map((i) =>
                i.purity === objToAdd.purity ? objToAdd : i
              );
              totalAmount += validateNumber(row.amount) * validateNumber(1);
            } else {
              row.quantity = productQuantity;
              row.gross = row.gross.toFixed(3);
              row.net = row.net.toFixed(2);
              if (row.amount) rows.push(row);
              totalAmount +=
                validateNumber(row.amount) * validateNumber(row.quantity);
            }
          }
        }
        if (rows.length > 0) {
          let totalPureWeight = 0;
          if (title === "Gold Summary")
            rows = rows.map((i) => {
              let { purity, net } = i;
              let label = goldPurities.find((i) => i.value == purity);
              let pureWeight = +net * (+purity / 1000);
              totalPureWeight += pureWeight;
              return {
                ...i,
                pureWeight: pureWeight.toFixed(2),
                purity_label: label ? label.label : purity,
              };
            });
          rows.push({
            purity: "Total",
            category: "",
            gross: parseFloat(totalGross).toFixed(3),
            net: parseFloat(totalNet).toFixed(2),
            pureWeight: parseFloat(totalPureWeight).toFixed(2),
            quantity: totalQuantity,
            amount: totalAmount,
          });
          setShow(true);
        } else setShow(false);
        setRows(rows);
      }
  }, [pricing, products, quotationView]);
  return (
    <>
      <Box sx={{ height: "20px" }} />
      <Typography variant="h5" ml={2} my={2}>
        {title ? title : "Metal Summary"}
      </Typography>
      <TableContainer
        component={Paper}
        sx={{
          border: "0.3px solid rgba(0,0,0,0.3)",
          mt: 3,
          px: 3,
        }}
      >
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Purity</TableCell>
              {/* <TableCell align="left">Type</TableCell> */}
              <TableCell align="right">Gross Wt</TableCell>
              <TableCell align="right">Net Wt</TableCell>
              {title === "Gold Summary" && (
                <TableCell align="right">999</TableCell>
              )}
              <TableCell align="right">Quantity</TableCell>
              {/* <TableCell align="left">Amount</TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => {
              let isTotal = row.purity === "Total";
              if (false)
                return (
                  <TableRow
                    key={row}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      fontWeight: "bold",
                    }}
                  >
                    <TableCell component="th" scope="row">
                      Total
                    </TableCell>
                    <TableCell align="left"></TableCell>
                    <TableCell align="left">{row.totalPieces}</TableCell>
                    <TableCell align="left">{row.totalWeight}</TableCell>
                    <TableCell align="left"></TableCell>
                    <TableCell align="left">{row.totalRate}</TableCell>
                  </TableRow>
                );
              return (
                <TableRow
                  key={JSON.stringify(row) + index}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    sx={isTotal ? { fontWeight: "600" } : {}}
                  >
                    {row.purity_label || row.purity}
                  </TableCell>
                  {/* <TableCell align="left">{row.category}</TableCell> */}
                  <TableCell
                    align="right"
                    sx={isTotal ? { fontWeight: "600" } : {}}
                  >
                    {row.gross} {isTotal && "Gms."}
                  </TableCell>
                  <TableCell
                    align="right"
                    sx={isTotal ? { fontWeight: "600" } : {}}
                  >
                    {row.net} {isTotal && "Gms."}
                  </TableCell>
                  {title === "Gold Summary" && (
                    <TableCell
                      align="right"
                      sx={isTotal ? { fontWeight: "600" } : {}}
                    >
                      {row.pureWeight} {isTotal && "Gms."}
                    </TableCell>
                  )}
                  <TableCell
                    align="right"
                    sx={isTotal ? { fontWeight: "600" } : {}}
                  >
                    {row.quantity}
                  </TableCell>
                  {/* <TableCell align="right">{row.amount}</TableCell> */}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

const getMetalRate = (pricing, type, purity) => {
  if (isObjWithValues(pricing)) {
    let rates = pricing[type];
    if (isObjWithValues(rates) && rates[purity]) return rates[purity].rate;
  }
};

const goldPurities = [
  { label: "24KT (999.99)", value: "999.99" },
  { label: "24KT (999)", value: "999" },
  { label: "24KT (995)", value: "995" },
  { label: "22KT (916)", value: "916" },
  { label: "18KT (750)", value: "750" },
  { label: "14KT (583)", value: "583" },
  { label: "9KT (375)", value: "375" },
];

// const ProductView = ({ product, pricing, quotation, setQuotationView }) => {
//   const [info, setInfo] = useState({});
//   const [isCollapsed, setIsCollapsed] = useState(true);
//   const [diamondTd, setDiamondTd] = useState([]);
//   const [gemstoneTd, setGemstoneTd] = useState([]);
//   const [diamondTotal, setDiamondTotal] = useState({});
//   const [gemstoneTotal, setGemstoneTotal] = useState({});
//   useEffect(() => {
//     let { images, meta_data, name, sku, id } = product;
//     let obj = {
//       id,
//       images,
//       name,
//       sku,
//     };
//     let meta_keys = [
//       "gold_kt",
//       "silver_purity",
//       "platinium_purity",
//       "gold_gross",
//       "silver_gross",
//       "platinium_gross",
//       "gold_net",
//       "silver_net",
//       "platinium_net",
//       "diamond",
//       "colorstone_details",
//       "variants",
//     ];
//     if (isArrayWithValues(meta_data))
//       meta_data.map((i) => {
//         if (meta_keys.includes(i.key)) obj[i.key] = i.value;
//       });
//     if (isObjWithValues(pricing)) {
//       let { gold_pricing, silver_pricing, platinum_pricing } = pricing;
//       let { type } = pricing.gold_pricing;
//       if (
//         pricing &&
//         pricing.gold_pricing &&
//         pricing.gold_pricing[quotation.currency] &&
//         pricing.gold_pricing[quotation.currency][type]
//       ) {
//         if (obj.gold_kt) {
//           let rateObj =
//             pricing.gold_pricing[quotation.currency][type][obj.gold_kt];
//           if (rateObj) obj.gold_rate = rateObj.rate;
//         }
//       }
//       if (
//         pricing &&
//         pricing.silver_pricing &&
//         pricing.silver_pricing[quotation.currency] &&
//         pricing.silver_pricing[quotation.currency][type]
//       ) {
//         if (obj.silver_purity) {
//           let rateObj =
//             pricing.silver_pricing[quotation.currency][type][obj.silver_purity];
//           if (rateObj) obj.silver_rate = rateObj.rate;
//         }
//       }
//       if (
//         pricing &&
//         pricing.platinum_pricing &&
//         pricing.platinum_pricing[quotation.currency] &&
//         pricing.platinum_pricing[quotation.currency][type]
//       ) {
//         if (obj.platinium_purity) {
//           let rateObj =
//             pricing.platinum_pricing[quotation.currency][type][
//               obj.platinium_purity
//             ];
//           if (rateObj) obj.platinum_rate = rateObj.rate;
//         }
//       }

//       let {
//         customPricing,
//         defaultPricing,
//         goldPricingType,
//         silverPricingType,
//         platinumPricingType,
//       } = quotation;
//       if (obj.gold_kt && gold_pricing.from) {
//         if (goldPricingType === "default") {
//           obj.gold_rate = defaultPricing.gold[obj.gold_kt];
//         }
//         if (goldPricingType === "custom")
//           obj.gold_rate = customPricing.gold[obj.gold_kt];
//         if (obj.gold_rate) {
//           if (gold_pricing.from === "gross")
//             obj.gold_amount = Math.round(
//               validateNumber(obj.gold_rate) * validateNumber(obj.gold_gross)
//             );
//           if (gold_pricing.from === "gross")
//             obj.gold_amount = Math.round(
//               validateNumber(obj.gold_rate) * validateNumber(obj.gold_net)
//             );
//         }
//       }
//       if (obj.silver_purity) {
//         if (silverPricingType === "default")
//           obj.silver_rate = defaultPricing.silver[obj.silver_purity];
//         if (silverPricingType === "custom")
//           obj.silver_rate = customPricing.silver[obj.silver_purity];

//         if (obj.silver_rate) {
//           if (silver_pricing.from === "gross")
//             obj.silver_amount = Math.round(
//               validateNumber(obj.silver_rate) * validateNumber(obj.silver_gross)
//             );
//           if (silver_pricing.from === "gross")
//             obj.silver_amount = Math.round(
//               validateNumber(obj.silver_rate) * validateNumber(obj.silver_net)
//             );
//         }
//       }
//       if (obj.platinium_purity) {
//         if (platinumPricingType === "default")
//           obj.platinum_rate = defaultPricing.platinum[obj.platinium_purity];
//         if (platinumPricingType === "custom")
//           obj.platinum_rate = customPricing.platinum[obj.platinium_purity];
//         if (obj.platinum_rate) {
//           if (platinum_pricing.from === "gross")
//             obj.platinum_amount = Math.round(
//               validateNumber(obj.platinum_rate) *
//                 validateNumber(obj.platinium_gross)
//             );
//           if (platinum_pricing.from === "gross")
//             obj.platinum_amount = Math.round(
//               validateNumber(obj.platinum_rate) *
//                 validateNumber(obj.platinium_net)
//             );
//         }
//       }
//     }
//     setInfo(obj);
//     if (pricing.diamond_pricing && !isArrayWithValues(diamondTd))
//       getDiamondRows();
//     if (pricing.gemstone_pricing && !isArrayWithValues(gemstoneTd))
//       getGemstoneRows();
//   }, [pricing, quotation]);

//   const getDiamondRows = () => {
//     let tableRows = [];
//     let { diamond_pricing } = pricing;
//     if (diamond_pricing) {
//       let allDiamonds = Object.values(diamond);
//       let totalPieces = 0,
//         totalRate = 0,
//         totalWeight = 0;
//       for (let i = 0; i < allDiamonds.length; i++) {
//         let {
//           diamond_quality,
//           diamond_shape,
//           diamond_sieve,
//           diamond_cut,
//           diamond_pieces,
//           diamond_weight,
//         } = allDiamonds[i];
//         let diamond_rate = getDiamondRate(
//           diamond_pricing[quotation.currency],
//           diamond_quality,
//           diamond_shape,
//           diamond_sieve,
//           diamond_cut
//         );
//         tableRows.push([
//           diamond_quality,
//           diamond_sieve,
//           diamond_pieces,
//           diamond_weight,
//           diamond_rate,
//         ]);
//         totalPieces += validateNumber(allDiamonds[i].diamond_pieces);
//         totalWeight += validateNumber(allDiamonds[i].diamond_weight);
//         totalRate += validateNumber(diamond_rate);
//       }
//       tableRows.push(["Total", "", totalPieces, totalWeight, totalRate]);

//       setDiamondTotal({
//         totalPieces,
//         totalWeight,
//         totalRate,
//       });
//       return setDiamondTd(tableRows);
//     }
//     // return [["", "", "", "", ""]];
//   };
//   const getGemstoneRows = () => {
//     let tableRows = [];
//     let { gemstone_pricing } = pricing;
//     if (gemstone_pricing) {
//       let allGemstone = Object.values(colorstone_details);
//       let totalPieces = 0,
//         totalRate = 0,
//         totalWeight = 0;
//       for (let i = 0; i < allGemstone.length; i++) {
//         let {
//           colorstone_quality,
//           colorstone_type,
//           colorstone_shape,
//           colorstone_pieces,
//           colorstone_weight,
//           colorstone_size,
//         } = allGemstone[i];
//         let colorstone_rate = getGemstoneRate(
//           gemstone_pricing[quotation.currency],
//           colorstone_quality,
//           colorstone_type,
//           colorstone_shape,
//           colorstone_size
//         );
//         tableRows.push([
//           colorstone_quality,
//           colorstone_type,
//           colorstone_shape,
//           colorstone_pieces,
//           colorstone_weight,
//           colorstone_rate,
//         ]);
//         totalPieces += validateNumber(allGemstone[i].colorstone_pieces);
//         totalWeight += validateNumber(allGemstone[i].colorstone_weight);
//         totalRate += validateNumber(colorstone_rate);
//       }
//       tableRows.push(["Total", "", "", totalPieces, totalWeight, totalRate]);

//       setGemstoneTotal({
//         totalPieces,
//         totalWeight,
//         totalRate,
//       });
//       return setGemstoneTd(tableRows);
//     }
//     // return [["", "", "", "", ""]];
//   };

//   const onRemove = (id) => {
//     setQuotationView((state) => ({
//       ...state,
//       products: quotation.products.filter((i) => i.id !== id),
//     }));
//   };

//   let {
//     id,
//     name,
//     images,
//     gold_kt,
//     silver_purity,
//     platinium_purity,
//     gold_gross,
//     silver_gross,
//     platinium_gross,
//     gold_net,
//     silver_net,
//     platinium_net,
//     diamond,
//     colorstone_details,
//     gold_rate,
//     silver_rate,
//     platinum_rate,
//     gold_amount,
//     silver_amount,
//     platinum_amount,
//     variants,
//   } = info;
//   let {
//     customPricing,
//     defaultPricing,
//     goldPricingType,
//     silverPricingType,
//     platinumPricingType,
//   } = quotation;
//   return (
//     <Box
//       sx={{
//         my: 4,
//         ml: 2,
//         flex: 1,
//         border: "0.4px solid",
//         borderColor: "rgba(0,0,0,0.3)",
//         borderRadius: "6px",
//         // overflow: "hidden",
//         position: "relative",
//       }}
//     >
//       <IconButton
//         size="large"
//         sx={{ position: "absolute", right: -17, top: -18 }}
//         onClick={() => onRemove(id)}
//       >
//         <Cancel />
//       </IconButton>
//       <Box
//         sx={{
//           borderLeft: `2px solid ₹{
//             isCollapsed ? "transparent" : "rgba(0,0,0,0.3)"
//           }`,

//           //   bordeColor: isCollapsed ? "transparent" : "blue",
//           //   borderLeftWidth: "3px",
//           //   borderLeftWidth: isCollapsed ? "0px" : "3px",
//           py: 3,
//           transition: "all 0.3s",
//         }}
//       >
//         <Stack direction={"row"}>
//           {isArrayWithValues(images) ? (
//             <img
//               src={images[0].src}
//               style={{
//                 height: "130px",
//                 width: "130px",
//               }}
//             />
//           ) : (
//             <img
//               src={"/static/img/woocommerce_placeholder.png"}
//               style={{
//                 height: "130px",
//                 width: "130px",
//               }}
//             />
//           )}
//           <Box
//             style={{
//               width: "100%",
//               alignContent: "center",
//             }}
//           >
//             <Stack
//               direction={"row"}
//               alignItems="center"
//               justifyContent="space-between"
//             >
//               <Typography variant="h6" fontSize={"14px"}>
//                 {name}
//                 {/* {isArrayWithValues(variants) && `(₹{variants[0].size})`} */}
//               </Typography>

//               <FormControl sx={{ mx: 1, minWidth: "120px" }} size="small">
//                 <InputLabel id="demo-multiple-checkbox-label">Sizes</InputLabel>
//                 <Select
//                   labelId="demo-multiple-checkbox-label"
//                   id="demo-multiple-checkbox"
//                   multiple
//                   value={[]}
//                   // onChange={handleChange}
//                   input={<OutlinedInput label="Sizes" />}
//                   renderValue={(selected) => selected.join(", ")}
//                   // MenuProps={MenuProps}
//                 >
//                   {["11", "12", "14", "15", "16", "17"].map((name) => (
//                     <MenuItem key={name} value={name}>
//                       <Checkbox />
//                       <ListItemText primary={name} />
//                     </MenuItem>
//                   ))}
//                 </Select>
//               </FormControl>
//             </Stack>
//             <Stack
//               direction={"row"}
//               height={"100%"}
//               // alignItems="center"
//               spacing={4}
//               mt={2}
//               ml={4}
//             >
//               {isArrayWithValues(variants) && (
//                 <UnexpandedDetails
//                   title="Gold"
//                   data={[
//                     ["Gross Wt", variants[0].gold_gross],
//                     ["Gross Net", variants[0].gold_net],
//                     ["Amount", gold_amount],
//                   ]}
//                 />
//               )}
//               {gold_gross && !isArrayWithValues(variants) && (
//                 <UnexpandedDetails
//                   title="Gold"
//                   data={[
//                     ["Gross Wt", gold_gross],
//                     ["Gross Net", gold_net],
//                     ["Amount", gold_amount],
//                   ]}
//                 />
//               )}
//               {silver_gross && (
//                 <UnexpandedDetails
//                   title="Gold"
//                   data={[
//                     ["Gross Wt", silver_gross],
//                     ["Gross Net", silver_net],
//                     ["Amount", silver_amount],
//                   ]}
//                 />
//               )}
//               {platinium_gross && (
//                 <UnexpandedDetails
//                   title="Gold"
//                   data={[
//                     ["Gross Wt", platinium_gross],
//                     ["Gross Net", platinium_net],
//                     ["Amount", platinum_amount],
//                   ]}
//                 />
//               )}
//               <UnexpandedDetails
//                 title="Diamond"
//                 data={[
//                   ["Pieces", diamondTotal.totalPieces],
//                   ["Weight", diamondTotal.totalWeight],
//                   ["Amount", diamondTotal.totalRate],
//                 ]}
//               />
//               <UnexpandedDetails
//                 title="Gemstone"
//                 data={[
//                   ["Pieces", gemstoneTotal.totalPieces],
//                   ["Weight", gemstoneTotal.totalWeight],
//                   ["Amount", gemstoneTotal.totalRate],
//                 ]}
//               />
//               <UnexpandedDetails
//                 title="Labour"
//                 data={[
//                   ["Wastage", "4%"],
//                   ["Per gram", "₹ 400"],
//                   ["Min. Making", "₹ 1200"],
//                 ]}
//               />
//               {isArrayWithValues(variants) ? (
//                 <Box>
//                   <Stack direction={"row"} alignItems="center">
//                     <Typography sx={{ whiteSpace: "nowrap", mr: 2 }}>
//                       Quantity (12):{" "}
//                     </Typography>

//                     <ButtonGroup
//                       fullWidth
//                       size="small"
//                       aria-label="small button group"
//                     >
//                       <Button
//                         sx={{
//                           maxWidth: "18px",
//                           border: "1px solid #8c8c8c",
//                           color: "#8c8c8c",
//                           height: "25px",
//                         }}
//                       >
//                         <Remove />
//                       </Button>
//                       <Button
//                         sx={{
//                           height: "25px",
//                         }}
//                         disabled
//                         fullWidth
//                       >
//                         1
//                       </Button>
//                       <Button
//                         sx={{
//                           maxWidth: "18px",
//                           border: "1px solid #8c8c8c",
//                           color: "#8c8c8c",
//                           height: "25px",
//                         }}
//                       >
//                         <Add />
//                       </Button>
//                     </ButtonGroup>
//                   </Stack>
//                   <Stack direction={"row"} alignItems="center" mt={2}>
//                     <Typography sx={{ whiteSpace: "nowrap", mr: 2 }}>
//                       Quantity (14):{" "}
//                     </Typography>

//                     <ButtonGroup
//                       fullWidth
//                       size="small"
//                       aria-label="small button group"
//                     >
//                       <Button
//                         sx={{
//                           maxWidth: "16px",
//                           border: "1px solid #8c8c8c",
//                           color: "#8c8c8c",
//                           height: "25px",
//                         }}
//                       >
//                         <Remove />
//                       </Button>
//                       <Button
//                         sx={{
//                           height: "25px",
//                         }}
//                         disabled
//                         fullWidth
//                       >
//                         1
//                       </Button>
//                       <Button
//                         sx={{
//                           maxWidth: "16px",
//                           border: "1px solid #8c8c8c",
//                           color: "#8c8c8c",
//                           height: "25px",
//                         }}
//                       >
//                         <Add />
//                       </Button>
//                     </ButtonGroup>
//                   </Stack>
//                 </Box>
//               ) : (
//                 <Box>
//                   <Stack direction={"row"} alignItems="center" mt={2}>
//                     <Typography sx={{ whiteSpace: "nowrap", mr: 2 }}>
//                       Quantity:{" "}
//                     </Typography>

//                     <ButtonGroup
//                       fullWidth
//                       size="small"
//                       aria-label="small button group"
//                     >
//                       <Button
//                         sx={{
//                           maxWidth: "16px",
//                           border: "1px solid #8c8c8c",
//                           color: "#8c8c8c",
//                           height: "25px",
//                         }}
//                       >
//                         <Remove />
//                       </Button>
//                       <Button
//                         sx={{
//                           height: "25px",
//                         }}
//                         disabled
//                         fullWidth
//                       >
//                         1
//                       </Button>
//                       <Button
//                         sx={{
//                           maxWidth: "16px",
//                           border: "1px solid #8c8c8c",
//                           color: "#8c8c8c",
//                           height: "25px",
//                         }}
//                       >
//                         <Add />
//                       </Button>
//                     </ButtonGroup>
//                   </Stack>
//                 </Box>
//               )}
//               <UnexpandedDetails
//                 title="Total"
//                 data={[
//                   ["Amount", "₹ 43,400"],
//                   // ["Per gram", "₹ 400"],
//                   // ["Min. Making", "₹ 1200"],
//                 ]}
//               />
//             </Stack>
//           </Box>
//           <IconButton
//             onClick={() => setIsCollapsed(!isCollapsed)}
//             sx={{ margin: "auto 5px" }}
//           >
//             <KeyboardArrowDown
//               sx={{
//                 transform: isCollapsed ? "rotate(0deg)" : "rotate(180deg)",
//                 transition: "all 0.5s",
//               }}
//             />
//           </IconButton>
//         </Stack>
//         <Collapse in={!isCollapsed}>
//           <Stack sx={{ mt: 6 }}>
//             <Grid container mb={3} px={5} spacing={5}>
//               <Grid item sx={{ flex: 1 }}>
//                 <MetalDetailBox
//                   title="Gold"
//                   th={
//                     isArrayWithValues(variants)
//                       ? ["Size", "KT", "Gross", "Net", "Amt"]
//                       : ["KT", "Gross", "Net", "Amt"]
//                   }
//                   td={
//                     isArrayWithValues(variants)
//                       ? variants.map((i) => [
//                           i.size,
//                           gold_kt,
//                           i.gold_gross,
//                           i.gold_net,
//                           gold_amount,
//                         ])
//                       : [[gold_kt, gold_gross, gold_net, gold_amount]]
//                   }
//                   showSizes={isArrayWithValues(variants)}
//                 />
//               </Grid>
//               {silver_gross && silver_net && (
//                 <Grid item sx={{ flex: 1 }}>
//                   <MetalDetailBox
//                     title="Silver"
//                     th={["KT", "Gross", "Net", "Amt"]}
//                     td={[[silver_purity, silver_gross, silver_net, ""]]}
//                   />
//                 </Grid>
//               )}
//               {platinium_gross && platinium_net && (
//                 <Grid item sx={{ flex: 1 }}>
//                   <MetalDetailBox
//                     title="Platinum"
//                     th={["KT", "Gross", "Net", "Amt"]}
//                     td={[
//                       [platinium_purity, platinium_gross, platinium_net, ""],
//                     ]}
//                   />
//                 </Grid>
//               )}
//               {isObjWithValues(diamond) && (
//                 <Grid item sx={{ flex: 1 }}>
//                   <MetalDetailBox
//                     title="Diamond"
//                     th={["Quality", "Size", "Pcs", "Wt", "Rate"]}
//                     td={diamondTd}
//                   />
//                 </Grid>
//               )}
//               {isObjWithValues(colorstone_details) && (
//                 <Grid item sx={{ flex: 1 }}>
//                   <MetalDetailBox
//                     title="Gemstone"
//                     th={[
//                       "Quality",
//                       "Type",
//                       "Shape",
//                       "Pieces",
//                       "Weight",
//                       "Rate",
//                     ]}
//                     td={gemstoneTd}
//                   />
//                 </Grid>
//               )}
//             </Grid>
//           </Stack>
//         </Collapse>
//       </Box>
//     </Box>
//   );
// };
