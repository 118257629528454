import { Box, Button, Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/system";
import axios from "axios";
import Switch from "rc-switch";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  apiCallFromSocket,
  Base64,
  getAxiosError,
  getToken,
  getWebsite,
  isArrayWithValues,
  isObjWithValues,
  json2Xml,
  nodeWebsite,
  settingsEndpoint,
  sleep,
  StackRow,
  TabPanel,
} from "../../../../../helper";
import { setGlobalToast } from "../../../../../redux/actions/helperActions";
import { editSettings } from "../../../../../redux/actions/settingActions";
const tabs = [
  // {
  //     label: "Overview",
  //     value: "overview",
  // },
  // {
  //     label: "Mapping",
  //     value: "mapping",
  // },
  {
    label: "Sync",
    value: "Sync",
  },
  // { label: "Settings", value: "settings" },
  // { label: "Activity", value: "history" },
];
const Settings = () => {
  const [selectedTab, setSelectedTab] = useState("Sync");
  const [settings, setSettings] = useState({});
  const ebaySettingsFetched = useSelector(
    (state) => state.settings.ebaySettingsFetched
  );
  const dispatch = useDispatch();

  const fetchEbaySettings = async () => {
    dispatch(
      editSettings({
        fetchingWooCommerceShopSettings: true,
      })
    );
    let settings = await settingsEndpoint({
      endpoint: "ebay_shop_settings",
    });
    if (settings) {
      setSettings(settings);
    }

    dispatch(
      editSettings({
        ebaySettingsFetched: true,
        fetchingWooCommerceShopSettings: false,
      })
    );
  };

  useEffect(() => {
    if (!ebaySettingsFetched) fetchEbaySettings();
  }, []);
  return (
    <Box>
      <Stack
        direction="row"
        sx={{
          backgroundColor: "Background.paper",
          padding: "10px",
          borderBottomColor: "#e8e8e8",
          borderBottomWidth: 0.2,
          borderBottomStyle: "solid",
          overflow: "scroll",
        }}
        spacing={4}
        className="scrollbar-hidden"
      >
        {tabs.map((i) => {
          return (
            <Button
              variant={"text"}
              // variant={selectedTab !== i.value ? "outlined" : "contained"}
              sx={{
                backgroundColor:
                  selectedTab === i.value ? "#e6e6e6" : "transparent",
                color: "#000",
                // color: selectedTab === i.value ? "primary.main" : "#000",
                "&:hover": {
                  backgroundColor:
                    selectedTab === i.value ? "#e6e6e6" : "transparent",
                },
              }}
              onClick={() => setSelectedTab(i.value)}
            >
              {i.label}
            </Button>
          );
        })}
      </Stack>
      <TabPanel index={selectedTab} value={"Sync"}>
        <Sync settings={settings} />
      </TabPanel>
    </Box>
  );
};

export default Settings;

const Sync = () => {
  const [form, setForm] = useState({});
  const [loading, setLoading] = useState(false);
  const store_id = useSelector((state) => state.user.store_id);

  const dispatch = useDispatch();

  const fetchingEbaySettings = useSelector(
    (state) => state.settings.fetchingEbaySettings
  );
  const ebaySettings = useSelector((state) => state.settings.ebaySettings);

  useEffect(() => {
    if (isObjWithValues(ebaySettings?.sync)) {
      setForm(ebaySettings?.sync);
    }
  }, [ebaySettings]);

  useEffect(() => {
    setupWebhooks();
  }, []);

  const editForm = (label, value, update = true) => {
    setForm((state) => ({
      ...state,
      [label]: { ...state[label], enabled: value },
    }));

    onFormUpdate({
      form: {
        ...form,
        [label]: { ...form[label], enabled: value },
      },
    });
  };

  const setupWebhooks = async () => {
    const xmlHeader = `<?xml version="1.0" encoding="utf-8" ?><GetNotificationPreferencesRequest xmlns="urn:ebay:apis:eBLBaseComponents">`;
    const xmlFooter = "</GetNotificationPreferencesRequest>";
    const xmlData = json2Xml({
      Version: 1217,
      ErrorLanguage: "en_US",
      WarningLevel: "High",
      PreferenceLevel: "User",
    });

    const GET_BODY = xmlHeader + xmlData + xmlFooter;

    let website = getWebsite();
    try {
      dispatch(
        editSettings({
          fetchingEbaySettings: true,
        })
      );
      const { data: GetNotificationResponse } = await axios({
        method: "POST",
        url: `${website}/wp-json/store/v1/channel/ebay?endpoint=/ws/api.dll`,
        data: {
          payload: {
            headers: {
              "X-EBAY-API-CALL-NAME": "GetNotificationPreferences",
              "X-EBAY-API-SITEID": 0,
              "X-EBAY-API-COMPATIBILITY-LEVEL": 1217,
            },
            body: GET_BODY,
          },
        },
      });
      if (GetNotificationResponse.Ack !== "Failure") {
        let { UserDeliveryPreferenceArray } = GetNotificationResponse;
        if (isObjWithValues(UserDeliveryPreferenceArray?.NotificationEnable)) {
          UserDeliveryPreferenceArray.NotificationEnable = [
            UserDeliveryPreferenceArray?.NotificationEnable,
          ];
        } else if (
          isArrayWithValues(UserDeliveryPreferenceArray?.NotificationEnable)
        ) {
          let form = {};
          for (let obj of UserDeliveryPreferenceArray?.NotificationEnable) {
            let { EventType, EventEnable } = obj;
            if (!form[EventType]) form[EventType] = {};
            form[EventType].enabled = EventEnable === "Enable";
          }
          let settings = await settingsEndpoint({
            method: "PUT",
            data: {
              sync: {
                ...form,
              },
            },
            endpoint: "ebay_shop_settings",
          });

          if (settings) {
            dispatch(
              editSettings({
                ebaySettings: settings,
              })
            );
          }
        }
      }
    } catch (error) {
      console.log(getAxiosError(error));
    }

    dispatch(
      editSettings({
        fetchingEbaySettings: false,
      })
    );
  };

  const onFormUpdate = async ({ form }) => {
    const xmlHeader = `<?xml version="1.0" encoding="utf-8" ?><SetNotificationPreferences xmlns="urn:ebay:apis:eBLBaseComponents">`;
    const xmlFooter = "</SetNotificationPreferences>";
    const xmlData = json2Xml({
      Version: 1217,
      ErrorLanguage: "en_US",
      WarningLevel: "High",
      ApplicationDeliveryPreferences: {
        ApplicationEnable: "Enable",
        ApplicationURL: `${nodeWebsite}/ebay/${store_id}`,
      },
      UserDeliveryPreferenceArray: {
        NotificationEnable: Object.keys(form).map((i) => ({
          EventType: i,
          EventEnable: form[i].enabled ? "Enable" : "Disable",
        })),
      },
    });
    const GET_BODY = xmlHeader + xmlData + xmlFooter;

    let website = getWebsite();
    try {
      dispatch(
        editSettings({
          fetchingEbaySettings: true,
        })
      );
      const { data: SetNotificationResponse } = await axios({
        method: "POST",
        url: `${website}/wp-json/store/v1/channel/ebay?endpoint=/ws/api.dll`,
        data: {
          payload: {
            headers: {
              "X-EBAY-API-CALL-NAME": "SetNotificationPreferences",
              "X-EBAY-API-SITEID": 0,
              "X-EBAY-API-COMPATIBILITY-LEVEL": 1217,
            },
            body: GET_BODY,
          },
        },
      });
      if (SetNotificationResponse.Ack !== "Failure") {
        let settings = await settingsEndpoint({
          method: "PUT",
          data: {
            sync: {
              ...form,
            },
          },
          endpoint: "ebay_shop_settings",
        });

        if (settings) {
          dispatch(
            editSettings({
              ebaySettings: settings,
            })
          );
        }
        dispatch(
          setGlobalToast({
            show: true,
            message: "Ebay synced settings updated successfully",
          })
        );
      }
    } catch (error) {
      console.log(getAxiosError(error));
    }

    dispatch(
      editSettings({
        fetchingEbaySettings: false,
      })
    );
  };

  return (
    <Box>
      <SwitchRow
        title="Ebay Fixed Price Transaction"
        subtitle="Sync when buyer purchases an item from a seller at a fixed price"
        loading={loading}
        forceLoading={fetchingEbaySettings}
        editForm={editForm}
        form={form}
        value={"FixedPriceTransaction"}
      />
      {/* <SwitchRow
        title="Ebay Fixed Price Transaction"
        subtitle="Sync when buyer purchases an item from a seller at a fixed price"
        loading={loading}
        forceLoading={fetchingEbaySettings}
        editForm={editForm}
        form={form}
        value={"ItemSold"}
      /> */}
    </Box>
  );
};
const SwitchRow = ({
  form = {},
  title,
  subtitle,
  editForm,
  value,
  loading,
  forceLoading,
}) => {
  const theme = useTheme();
  let backgroundColor = theme.palette.primary && theme.palette.primary.main;
  return (
    <Box sx={{ px: 4 }}>
      <Stack
        direction={"row"}
        alignItems="center"
        justifyContent="space-between"
        sx={{ mt: 5 }}
      >
        <Typography variant="h5" fontSize="15px">
          {title}
        </Typography>
        <Switch
          disabled={(loading === value && loading) || forceLoading}
          onChange={(e) => {
            editForm(value, e);
          }}
          checked={form[value]?.enabled}
          style={{
            border: `1px solid ${
              form[value]?.enabled ? backgroundColor : "#e8e8e8"
            }`,
            backgroundColor: form[value]?.enabled ? backgroundColor : "#e8e8e8",
          }}
        />
      </Stack>
      <Typography
        variant="body2"
        sx={{ fontSize: "12px", color: "text.secondary", mt: 1 }}
      >
        {subtitle}
      </Typography>
    </Box>
  );
};
