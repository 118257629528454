import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  Button,
  Chip,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { Label } from "../customers/components/Info";
import { CloudUpload, ExpandMore } from "@material-ui/icons";
import CustomChip from "../components/CustomChip";
import { isArrayWithValues, isObjWithValues } from "../../helper";
import { useSelector } from "react-redux";
import { colorsBackground } from "./AddProduct";

const AddProductAi = ({ handleClose }) => {
  const inputFile = useRef();
  const productSettings = useSelector(
    (state) => state.settings.productSettings
  );
  const [droppedRowFiles, setDroppedRowFiles] = useState([]);
  const [expanded, setExpanded] = useState(true);
  // const [metalTypes ,setMetalTypes ] = useState ({})
  const [dropDownData, setDropDownData] = useState({});
  const [generatedData, setGeneratedData] = useState({});
  useEffect(() => {
    if (productSettings) {
      if (isArrayWithValues(productSettings.types)) {
        let types = productSettings.types;
        types = types.filter((i) =>
          ["gold", "silver", "platinium"].includes(i.value)
        );
        // setMetalTypes(types);
        setDropDownData((pre) => ({ ...pre, metalTypes: types }));
        // if (isArrayWithValues(types)) onEditProduct({ noMetals: false });
        // else onEditProduct({ noMetals: true });
      }
      if (isArrayWithValues(productSettings.colors))
        setDropDownData((pre) => ({
          ...pre,
          metalColors: productSettings.colors,
        }));
      // setMetalColors(productSettings.colors);
      if (isArrayWithValues(productSettings.gold_purities)) {
        // setGoldPurities(productSettings.gold_purities);
        setDropDownData((pre) => ({
          ...pre,
          goldPurities: productSettings.gold_purities,
        }));
      }
      if (isArrayWithValues(productSettings.silver_purities)) {
        // setSilverPurities(productSettings.silver_purities);
        setDropDownData((pre) => ({
          ...pre,
          silverPurities: productSettings.silver_purities,
        }));
      }
      if (isArrayWithValues(productSettings.platinum_purities)) {
        // setPlatinumPurities(productSettings.platinum_purities);
        setDropDownData((pre) => ({
          ...pre,
          platinumPurities: productSettings.platinum_purities,
        }));
      }
    }
  }, [productSettings]);
  const onFileDrop = async (files) => {
    console.log(files);
    let isSquareArray = [];
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      console.log("index", index);
      if (file) {
        const reader = new FileReader();
        reader.onload = (event) => {
          const img = new Image();
          img.src = event.target.result;
          img.onload = () => {
            console.log("image", img.width, img.height);
            const isSquare = img.width === img.height;
            isSquareArray[i] = isSquare;
            // If all images have been checked, update the state
            if (isSquareArray.length === files.length) {
              // this.setState({ isSquareArray });
            }
          };
        };
        reader.readAsDataURL(file);
      }
    }
    console.log(isSquareArray);
  };
  return (
    <>
      <input
        ref={inputFile}
        onChange={(e) => onFileDrop(e.target.files)}
        type="file"
        accept="image/*"
        style={{ display: "none" }}
      />
      <DialogTitle id="alert-dialog-title" sx={{ textAlign: "center" }}>
        Add Products
      </DialogTitle>
      <DialogContent>
        <Box>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Label>SKU/Design no.</Label>
              <TextField size="small" placeholder="Enter SKU title" fullWidth />
            </Grid>
            <Grid item xs={12}>
              <Label>Upload product images</Label>
              <Stack
                direction={"row"}
                sx={{
                  justifyContent: "center",
                  display: "flex",
                  alignItems: "center",
                  // height: "100%",
                  border: "2px dashed",
                  padding: "14px 6px",
                  textAlign: "left",
                  maxWidth: "400px",
                  borderRadius: "10px",

                  cursor: "pointer",

                  "&:hover": {
                    backgroundColor: "rgba(0,0,0,0.1)",
                  },
                }}
                onClick={() => inputFile.current.click()}
              >
                <Box
                  sx={{
                    height: "80px",
                    width: " 80px",
                    backgroundColor: "primary.main",
                    borderRadius: "50%",

                    position: "relative",
                  }}
                >
                  <CloudUpload
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      color: "#fff",
                    }}
                  />
                </Box>
                <div style={{ marginLeft: 5 }}>
                  <h3 style={{ margin: "0px" }}>Drop or select file</h3>
                  <span style={{ margin: "0px" }}>
                    drop files here form your machine
                    {/* drop files here or drag through your machine */}
                  </span>
                </div>
              </Stack>
            </Grid>

            {isObjWithValues(generatedData) && (
              <>
                <Grid item xs={12}>
                  <Stack
                    direction={"row"}
                    alignItems={"center"}
                    gap={2}
                    flexWrap={"wrap"}
                  >
                    {["Neckless", "Netted", "Peacock"]?.map((str) => {
                      return <CustomChip label={str} onDelete={() => {}} />;
                    })}
                  </Stack>
                  <Label>Tags</Label>
                </Grid>
                <Grid item xs={12}>
                  <Label>Collections</Label>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={[]}
                    value={["gold Jewellery"]}
                    freeSolo={true}
                    fullWidth
                    multiple
                    // sx={{ width: 300 }}
                    renderInput={(params) => (
                      <TextField {...params} size="small" />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Label>Gender </Label>
                  <TextField select size="small" fullWidth>
                    {[
                      {
                        label: "Male",
                        value: "male",
                      },
                      {
                        label: "Female",
                        value: "female",
                      },
                      {
                        label: "Other",
                        value: "other",
                      },
                    ]?.map((obj) => {
                      return (
                        <MenuItem value={obj?.value}>{obj?.label}</MenuItem>
                      );
                    })}
                  </TextField>
                </Grid>
              </>
            )}
          </Grid>
          {isObjWithValues(generatedData) && (
            <Accordion
              expanded={expanded}
              onChange={(_, val) => setExpanded(val)}
            >
              <AccordionSummary expandIcon={<ExpandMore />}>
                <Typography>Metal Details</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={3}>
                  <Grid xs={12} item>
                    <Label>Jewellery type</Label>
                    <Stack direction={"row"} alignItems={"center"} gap={3}>
                      {dropDownData?.metalTypes?.map((obj) => {
                        console.log(obj);
                        let { label } = obj;
                        return <Chip label={label} variant="outlined" />;
                      })}
                    </Stack>
                  </Grid>
                  <Grid xs={12} item>
                    <Label>Category</Label>
                    <Stack direction={"row"} alignItems={"center"} gap={3}>
                      {["Ring", "Neckless", "Pendent"]?.map((str) => {
                        // console.log(obj);
                        // let { label } = obj;
                        return <Chip label={str} variant="outlined" />;
                      })}
                    </Stack>
                  </Grid>
                  <Grid xs={12} item>
                    <Label>Metal color</Label>
                    <Stack direction={"row"} alignItems={"center"} gap={3}>
                      {dropDownData?.metalColors?.map((i) => {
                        return (
                          <Tooltip title={i.value}>
                            <IconButton
                              sx={{ p: 1.5 }}
                              onClick={
                                (e) => {}
                                // !product?.pull_source &&
                                // onEditProduct({
                                //   metal_color: metal_color === i.value ? "" : i.value,
                                // })
                              }
                              // onClick={() => onColorClick(id, i.value)}
                            >
                              <Box
                                sx={{
                                  background: colorsBackground[i.value],
                                  height: "25px",
                                  width: "25px",
                                  borderRadius: "50%",
                                  border: `2px solid ${
                                    // i.value === metal_color
                                    false ? "#000" : "transparent"
                                  }`,
                                }}
                              />
                            </IconButton>
                          </Tooltip>
                        );
                      })}
                    </Stack>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Disagree</Button>
        <Button onClick={handleClose} autoFocus>
          Agree
        </Button>
      </DialogActions>
    </>
  );
};

export default AddProductAi;
