import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import { visuallyHidden } from "@mui/utils";
import { formatDate, isArrayWithValues, isObjWithValues } from "../../helper";
import { Button, Stack, useMediaQuery } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useTheme } from "@mui/system";
import { NotFound } from "../../Svgs";

const EnhancedTableToolbar = (props) => {
  const { numSelected, customerName } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          {customerName ? `${customerName}'s` : "Customer"} Catalogs
        </Typography>
      )}

      {/* {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )} */}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    isIpad,
    isMobile,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  let headCellsArr = isIpad ? [] : headCells;

  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell> */}
        {headCellsArr.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align}
            // align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const Catalogs = (props) => {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rows, setRows] = useState([]);
  const [customerName, setCustomerName] = useState("");
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const navigate = useNavigate();
  useEffect(() => {
    let { customer } = props;
    if (isObjWithValues(customer) && isArrayWithValues(customer.catalogs)) {
      setRows(customer.catalogs);
      setCustomerName(`${customer.first_name} ${customer.last_name}`);
    }
  }, [props.customer]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const onOpenCatalog = (link, expired, row) => {
    if (expired)
      return navigate(`/catalogs/view`, {
        state: { catalogView: { ...row } },
      });
    window.open(link, "_blank");
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isIpad = useMediaQuery(theme.breakpoints.down("md"));

  let { customer } = props;

  return (
    <Box sx={{ width: "100%", p: 0 }}>
      {!isArrayWithValues(customer.catalogs) && !props.fetchingCustomer && (
        <Box style={{ textAlign: "center" }}>
          <NotFound
            style={{
              height: isMobile ? 280 : 400,
              width: isMobile ? 280 : 400,
            }}
            // style={{ height: "auto", width: isMobile ? "70vw" : "45vw" }}
          />
          <Typography variant="h6" color="text.secondary">
            No catalogs found
          </Typography>
        </Box>
      )}
      {isArrayWithValues(rows) && (
        <>
          <Paper sx={{ width: "100%", mb: 2, p: 0 }}>
            <EnhancedTableToolbar
              numSelected={selected.length}
              customerName={customerName}
            />
            <TableContainer>
              <Table
                // sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
                size={dense ? "small" : "medium"}
              >
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                  isMobile={isMobile}
                  isIpad={isIpad}
                />
                <TableBody>
                  {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
                  {stableSort(rows, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const isItemSelected = isSelected(row.name);
                      const labelId = `enhanced-table-checkbox-${index}`;
                      return (
                        <TableRow
                          hover
                          onClick={(event) =>
                            onOpenCatalog(row.link, row.status === "draft", row)
                          }
                          //   onClick={(event) => handleClick(event, row.name)}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.name}
                          selected={isItemSelected}
                        >
                          {/* <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            "aria-labelledby": labelId,
                          }}
                        />
                      </TableCell> */}
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            // padding="none"
                          >
                            <Box sx={{ width: "100%" }}>
                              <Stack
                                direction="row"
                                justifyContent={"space-between"}
                              >
                                <Typography variant="h6">
                                  {row.title}
                                </Typography>
                                {isIpad && (
                                  <Typography
                                    variant="caption"
                                    sx={{ fontWeight: "bold" }}
                                  >
                                    Requests:
                                    {isArrayWithValues(row.catalogRequests)
                                      ? row.catalogRequests.length
                                      : 0}
                                  </Typography>
                                )}
                              </Stack>
                              {isIpad && (
                                <>
                                  <Typography
                                    variant="body2"
                                    style={{ fontSize: "11px" }}
                                  >
                                    Created At: {row.created_at}
                                  </Typography>
                                  <Stack direction="row" alignItems={"center"}>
                                    <Typography
                                      variant="body2"
                                      style={{ fontSize: "11px" }}
                                    >
                                      Products: {row.products}
                                    </Typography>
                                    <Typography
                                      variant="caption"
                                      color={
                                        row.status === "draft"
                                          ? "error.main"
                                          : row.status === "publish"
                                          ? "success.main"
                                          : "#000"
                                      }
                                      fontWeight={"bold"}
                                      ml={2}
                                    >
                                      {row.status}
                                    </Typography>
                                  </Stack>
                                </>
                              )}
                            </Box>
                          </TableCell>
                          {!isIpad && (
                            <>
                              <TableCell align="left">
                                {row.created_at}
                              </TableCell>
                              <TableCell align="left">{row.products}</TableCell>
                              <TableCell align="left">{row.status}</TableCell>
                              <TableCell align="left">
                                {isArrayWithValues(row.catalogRequests)
                                  ? row.catalogRequests.length
                                  : 0}
                              </TableCell>
                            </>
                          )}
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow
                      style={{
                        height: (dense ? 33 : 53) * emptyRows,
                      }}
                    >
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
          <FormControlLabel
            control={<Switch checked={dense} onChange={handleChangeDense} />}
            label="Dense padding"
          />
        </>
      )}
    </Box>
  );
};

export default Catalogs;

const headCells = [
  {
    id: "title",
    numeric: false,
    disablePadding: false,
    align: "left",
    label: "Title",
  },
  {
    id: "created_on",
    numeric: true,
    disablePadding: false,
    align: "left",
    label: "Created on",
  },
  {
    id: "products",
    numeric: true,
    disablePadding: false,
    align: "left",
    label: "Products",
  },
  {
    id: "status",
    numeric: true,
    disablePadding: false,
    align: "left",
    label: "Status",
  },
  {
    id: "requests",
    numeric: true,
    disablePadding: false,
    align: "left",
    label: "Requests",
  },
];
const headCellsMobile = [
  {
    id: "details",
    numeric: false,
    disablePadding: false,
    align: "left",
    label: "Catalog Details",
  },
];

function createData(name, calories, fat, carbs, protein) {
  return {
    name,
    calories,
    fat,
    carbs,
    protein,
  };
}

const rows = [
  createData("Cupcake", 305, 3.7, 67, 4.3),
  createData("Donut", 452, 25.0, 51, 4.9),
  createData("Eclair", 262, 16.0, 24, 6.0),
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
  createData("Gingerbread", 356, 16.0, 49, 3.9),
  createData("Honeycomb", 408, 3.2, 87, 6.5),
  createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
  createData("Jelly Bean", 375, 0.0, 94, 0.0),
  createData("KitKat", 518, 26.0, 65, 7.0),
  createData("Lollipop", 392, 0.2, 98, 0.0),
  createData("Marshmallow", 318, 0, 81, 2.0),
  createData("Nougat", 360, 19.0, 9, 37.0),
  createData("Oreo", 437, 18.0, 63, 4.0),
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
