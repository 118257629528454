import { Button, Dialog, DialogActions, DialogContent } from '@mui/material'
import React from 'react'
import { dialogStyle } from '../../../helper'
import { BasicInfo } from '../ProfilePage'

const ProfilePopup = ({ open, handleClose }) => {
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{ ...dialogStyle }}
        >
            {/* <DialogTitle id="alert-dialog-title">
              {"Use Google's location service?"}
          </DialogTitle> */}
            <DialogContent>
                <BasicInfo />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Disagree</Button>
                <Button onClick={handleClose} autoFocus>
                    Agree
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ProfilePopup