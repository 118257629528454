import { Apple, Laptop, PhoneIphone } from "@mui/icons-material";
import {
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UAParser } from "ua-parser-js";
import {
  formatDate,
  formatServerValue,
  isObjWithValues,
  primaryLabel,
  secondaryLabel,
  settingsEndpoint,
} from "../../../../helper";
import { editSettings } from "../../../../redux/actions/settingActions";
import { Windows } from "../../../../Svgs";

const Activity = ({ historyObject, hideImport }) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows, setRows] = useState([]);

  const reduxshopifyHistory = useSelector(
    (state) => state.settings.shopifyHistory
  );

  const reduxShopifyIntegration = useSelector(
    (state) => state.settings.shopifyIntegration
  );
  const dispatch = useDispatch();
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;
  useEffect(() => {
    if (!historyObject) {
      if (isObjWithValues(reduxshopifyHistory))
        setRows(Object.values(reduxshopifyHistory).reverse());
    }
  }, [reduxshopifyHistory]);

  useEffect(() => {
    if (isObjWithValues(historyObject))
      setRows(Object.values(historyObject).reverse());
  }, [historyObject]);

  useEffect(async () => {
    if (!historyObject) {
      if (!isObjWithValues(reduxshopifyHistory)) {
        let res =
          (await settingsEndpoint({
            // endpoint: `shopify_history_${reduxShopifyIntegration?.shop?.replaceAll(
            //   ".",
            //   "_"
            // )}`,
            endpoint: `shopify_sync_history`,
          })) || {};
        console.log(res);
        dispatch(editSettings({ shopifyHistory: res }));
      }
    }
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{}} aria-label="custom pagination table">
          <TableHead>
            <TableRow>
              <TableCell>Sr.</TableCell>
              <TableCell>History</TableCell>
              <TableCell>Imported</TableCell>
              <TableCell>Updated</TableCell>
              <TableCell>Device</TableCell>
              <TableCell>Location</TableCell>
              <TableCell>IP</TableCell>
              {/* <TableCell align="right">Fat&nbsp;(g)</TableCell>
              <TableCell align="right">Protein&nbsp;(g)</TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : rows
            ).map((row, index) => {
              let { ua } = row;
              let os = new UAParser(ua).getOS();
              let browser = new UAParser(ua).getBrowser();
              return (
                <TableRow key={row.id}>
                  <TableCell padding="checkbox" align="left">
                    <Typography sx={{ ml: 3, fontWeight: "600" }}>
                      {index + 1}
                    </Typography>
                  </TableCell>

                  <TableCell component="th" scope="row">
                    <Typography sx={{ ...primaryLabel }}>
                      {formatServerValue(row?.type)}{" "}
                      {hideImport ? null : "Imported"}
                    </Typography>
                    <Typography
                      fontSize={"11px"}
                      sx={{ mt: 0.5, ...secondaryLabel }}
                    >
                      {row.start
                        ? formatDate(
                            new Date(row.start * 1000),
                            "dd:mm:yyyy hh:mm am/pm"
                          )
                        : ""}
                    </Typography>
                    {row.is_manual || row.automatic ? (
                      <Typography
                        sx={{
                          fontSize: "11px",
                          color: "text.secondary",
                          mt: 1,
                        }}
                      >
                        Automatic
                      </Typography>
                    ) : null}
                  </TableCell>
                  <TableCell>
                    {(isObjWithValues(row?.batchDetails) &&
                      row?.batchDetails?.imported) ||
                      0}
                  </TableCell>
                  <TableCell>
                    {(isObjWithValues(row?.batchDetails) &&
                      row?.batchDetails?.updated) ||
                      0}
                  </TableCell>
                  {/* <TableCell>{row.totalProductError || 0}</TableCell> */}
                  {/* <TableCell>{row.updated || 0}</TableCell> */}
                  <TableCell padding="checkbox" sx={{ fontWeight: "600" }}>
                    <Stack direction="row" alignItems={"center"} spacing={2}>
                      {os && os.name === "Mac OS" ? (
                        <Apple style={{ color: "#666666" }} />
                      ) : os.name === "Windows" ? (
                        <Windows style={{ color: "#666666" }} />
                      ) : null}
                      {os && (os.name === "Mac OS" || os.name === "Windows") ? (
                        <Laptop width="20px" height="20px" />
                      ) : (
                        <PhoneIphone />
                      )}
                    </Stack>
                  </TableCell>
                  <TableCell>
                    <Typography
                      // variant="h6"
                      fontSize={"11px"}
                      sx={{ mt: 0.5 }}
                    >
                      {isObjWithValues(row.location)
                        ? `${row.location.city || ""} ${row.location.state} ${
                            row.location.country
                          }`
                        : ""}
                    </Typography>
                  </TableCell>

                  <TableCell>
                    <Typography
                      // variant="h6"
                      fontSize={"11px"}
                      sx={{ mt: 0.5 }}
                    >
                      {row.ip}
                    </Typography>
                  </TableCell>
                </TableRow>
              );
            })}

            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                // colSpan={3}
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    "aria-label": "rows per page",
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                // ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </>
  );
};

export default Activity;
