export const PREDEFINED_CHAT_USER_ATTRIBUTES = [
  { label: "First Name", value: "first_name" },
  { label: "Last Name", value: "last_name" },
  { label: "Email", value: "email" },
  { label: "Phone", value: "phone" },
  { label: "Gender", value: "gender" },
  { label: "Birthday", value: "birthday" }, // *
  { label: "Anniversary", value: "anniversary" }, // *
  { label: "Contact Type", value: "contact_type" },
  { label: "Opt-In", value: "opt_in" },
  { label: "City", value: "city" }, // *
  { label: "State", value: "state" }, // *
  { label: "Country", value: "country" }, // *
  { label: "Language", value: "language" }, // *
  { label: "IP", value: "ip_address" }, // *
  { label: "Channel", value: "channel" },
  { label: "Referrer", value: "referrer" },
  { label: "Company Name", value: "company_name" },
];
