import { Box, Button, Stack, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Label } from "../../../customers/components/Info";
import { LoadingButton } from "@mui/lab";
import { useDispatch, useSelector } from "react-redux";
import { apiCallFromSocket, settingsEndpoint } from "../../../../helper";
import { EDIT_SETTINGS } from "../../../../redux/actions/settingActions";
import { setGlobalToast } from "../../../../redux/actions/helperActions";
import axios from "axios";

const Integration = () => {
    const felids = [
        { label: "Flow", value: "flow" },
        { label: "Caller Id", value: "caller_id" },
        { label: "Auth Key", value: "authkey" },
    ];
    const dispatch = useDispatch()
    const msg91Integration = useSelector(state => state.settings.msg91Integration)
    const [stateObject, setStateObject] = useState({});
    const [loading, setLoading] = useState(false);
    useEffect(() => {

        setStateObject(msg91Integration || {})
        return () => {

        }
    }, [msg91Integration])

    const onSave = async () => {
        setLoading(true);
        let settingRes = await settingsEndpoint({
            endpoint: "msg91_integration",
            method: "PUT",
            data: {
                ...stateObject
            }
        })
        if (settingRes) {
            dispatch({
                type: EDIT_SETTINGS,
                payload: {
                    msg91Integration: settingRes
                }
            })
            dispatch(setGlobalToast({
                show: true,
                message: "Integrated Successfully",
                severity: "success"
            }))
        }
        setLoading(false);
    };
    const onEdit = (payload) => {
        setStateObject((pre) => ({ ...pre, ...payload }));
    };
    const onCall = async () => {
        let res = await apiCallFromSocket({
            url: 'https://control.msg91.com/api/v5/voice/call/',
            headers: {
                authkey: stateObject?.authkey
            },
            method: 'POST',
            data: {
                flow: stateObject?.flow,
                caller_id: stateObject?.caller_id,
                client_number: "919321542605"
            }
        }, "https://websockets.tanika.tech")
    }

    return (
        <Box p={3}>
            <Stack sx={{ width: "50%" }} gap={3}>
                {felids?.map((o) => (
                    <Stack>
                        <Label>{o?.label}</Label>
                        <TextField
                            size="small"
                            fullWidth
                            onChange={(e) => {
                                onEdit({ [o?.value]: e.target.value });
                            }}
                            value={stateObject?.[o?.value] || ""}
                        />
                    </Stack>
                ))}

                <Stack direction={"row"} justifyContent={"end"}>
                    <Button onClick={onCall} >Call</Button>
                    <LoadingButton loading={loading} variant="contained" onClick={onSave}>
                        Integrate Call
                    </LoadingButton>
                </Stack>
            </Stack>
        </Box>
    );
};

export default Integration;
