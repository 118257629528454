function extractPrefix(inputString) {
    const regex = /^([a-zA-Z_]+)_.*$/;
    const matches = inputString.match(regex);
    if (matches && matches.length > 1) {
        return matches[1];
    }
    return '';
}
function getNestedValue(obj, keys) {
    if (!obj || typeof obj !== "object") {
        return undefined;
    }

    const keyArray = Array.isArray(keys) ? keys : keys.split(".");
    const currentKey = keyArray[0];

    if (keyArray.length === 1) {
        return obj[currentKey];
    }

    return getNestedValue(obj[currentKey], keyArray.slice(1));
}

export { extractPrefix, getNestedValue }