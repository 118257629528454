import { Card } from "@mui/material";
import { videoFieldStyles } from "../utils/chatbotFieldsStyles";
import PropTypes from "prop-types";
import ReactPlayer from "react-player";

const styles = videoFieldStyles;

const BotVideoMessage = ({ message }) => {
  return (
    <Card style={styles.card}>
      <ReactPlayer url={message.file} width="640" height="560" controls />
    </Card>
  );
};

BotVideoMessage.propTypes = {
  message: PropTypes.object.isRequired,
};

export default BotVideoMessage;
