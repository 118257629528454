import { Add } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { dialogStyle, isArrayWithValues } from "../../helper";
import {
  getConversations,
  getUsersWithTasks,
} from "../../redux/actions/chatActions";
import CallTable from "../chat/CallTable";
import ReminderTable from "../chat/ReminderTable";
import TaskTable from "../chat/TaskTable";
import { CreateUserTask, DeleteUserTask } from "../chat/UserTasks";

function UserTasks() {
  const [formOpen, setFormOpen] = useState(false);
  const [editForm, setEditForm] = useState(false);
  const [deleteFormOpen, setDeleteFormOpen] = useState(false);
  const dispatch = useDispatch();
  const conversationsFetched = useSelector(
    (state) => state.chats.conversationsFetched
  );
  const users_with_tasks_fetched = useSelector(
    (state) => state.chats.users_with_tasks_fetched
  );
  const no_user_tasks = useSelector((state) => state.chats.no_user_tasks);
  const settingsUpUserTasks = useSelector(
    (state) => state.chats.settingsUpUserTasks
  );
  const allUserTasks = useSelector((state) => state.chats.allUserTasks);

  const openUpdateForm = (edit) => {
    setEditForm(edit);
    setFormOpen(true);
  };
  const openAddForm = (edit) => {
    setEditForm({});
    setFormOpen(true);
  };
  const openDeleteForm = (edit) => {
    setEditForm(edit);
    setDeleteFormOpen(true);
  };

  const closeForm = () => setFormOpen(false);
  const closeDeleteForm = () => setDeleteFormOpen(false);

  // useEffect(() => {
  //   if (!conversationsFetched) dispatch(getConversations());
  // }, [conversationsFetched]);

  useEffect(() => {
    if (!users_with_tasks_fetched) dispatch(getUsersWithTasks());
  }, []);

  return (
    <Box>
      <Stack
        direction={"row"}
        alignItems="center"
        // justifyContent={"space-between"}
        sx={{ flex: 1, padding: "8px" }}
        spacing={1}
      >
        <Typography variant="h3" display="inline">
          User Activities
        </Typography>
        {/* <InfoTooltip
          title="Connect your platform to other 3rd party tools for smooth
            functioning and to manage one or more services from a single
            dashboard"
        /> */}
      </Stack>
      {settingsUpUserTasks &&
        !isArrayWithValues(allUserTasks) &&
        !no_user_tasks ? (
        <Box
          sx={{
            minHeight: "calc(100vh - 160px)",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
          {/* <Typography></Typography> */}
        </Box>
      ) : (
        <>
          <Stack
            direction={"row"}
            alignItems="center"
            justifyContent={"flex-end"}
            my={3}
          >
            <Button
              variant="outlined"
              startIcon={<Add />}
              onClick={openAddForm}
            >
              Add Activity
            </Button>
          </Stack>
          <TaskTable openDeleteForm={openDeleteForm} user_from="crm" />
          <ReminderTable
            openUpdateForm={openUpdateForm}
            openDeleteForm={openDeleteForm}
            user_from="crm"
          />
          <CallTable
            openUpdateForm={openUpdateForm}
            openDeleteForm={openDeleteForm}
            user_from="crm"
          />
        </>
      )}

      <Dialog
        open={formOpen}
        keepMounted
        onClose={closeForm}
        aria-describedby="alert-dialog-slide-description"
        maxWidth="sm"
        fullWidth
        sx={{ ...dialogStyle }}
      >
        <CreateUserTask
          closeForm={closeForm}
          updateForm={editForm}
          user_from="crm"
        />
      </Dialog>
      <Dialog
        open={deleteFormOpen}
        keepMounted
        onClose={closeDeleteForm}
        aria-describedby="alert-dialog-slide-description"
        maxWidth="sm"
        fullWidth
        sx={{ ...dialogStyle }}
      >
        <DeleteUserTask closeForm={closeDeleteForm} deleteForm={editForm} />
      </Dialog>
    </Box>
  );
}

export default UserTasks;
