import * as React from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import { visuallyHidden } from "@mui/utils";
import {
  a11yProps,
  formatDate,
  getRelativeTimeDifference,
  isArrayWithValues,
  primaryLabel,
  secondaryLabel,
  sectionLabel,
  StyledTableRow,
  validateNumber,
} from "../../../helper";
import { Button, Chip, Stack, Tab, Tabs } from "@mui/material";
import { useTheme } from "@mui/system";
import { useSelector } from "react-redux";
import { CatalogIcon, NotFound, OrdersSvg } from "../../../Svgs";
import { NoTasks } from "../../chat/ChatHelper";
import { Add } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

export default function RecentQuotation({ quotations }) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rows, setRows] = React.useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [selectedTab, setSelectedTab] = React.useState(0);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const theme = useTheme();
  const navigate = useNavigate();
  const isDarkTheme = theme.palette && theme.palette.mode === "dark";

  React.useEffect(() => {
    if (!isArrayWithValues(quotations)) return;
    let _quotations = [...quotations];
    if (selectedTab == 1)
      _quotations = _quotations.filter((i) => i.is_confirmed);
    setRows(_quotations);
  }, [quotations, selectedTab]);

  const dashboardDetails = useSelector(
    (state) => state.settings.dashboardDetails
  );
  const fetchingDashboardDetails = useSelector(
    (state) => state.settings.fetchingDashboardDetails
  );

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2, pt: 2 }}>
        <Typography
          sx={{ flex: "1 1 100%", ...sectionLabel, mt: 3, ml: 3 }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Recent Quotations
        </Typography>
        <Box sx={{ borderBottomWidth: 1, borderBottomColor: "divider" }}>
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            aria-label="basic tabs example"
            sx={{ mt: 2 }}
          >
            <Tab label="Sent" {...a11yProps(0)} />
            <Tab label="Confirmed" {...a11yProps(1)} />
          </Tabs>
        </Box>

        {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
        <TableContainer>
          <Table
            // sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
          >
            {isArrayWithValues(rows) && (
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
              />
            )}
            {!fetchingDashboardDetails && !isArrayWithValues(rows) ? (
              <Box sx={{ margin: "auto", height: "100%" }}>
                <NoTasks
                  text={
                    <Box sx={{ textAlign: "center" }}>
                      <Typography>No Recent quotations found.</Typography>
                      <Typography sx={{ ...secondaryLabel, fontSize: "12px" }}>
                        Let's create your first quotation.
                      </Typography>
                      <Button
                        variant="outlined"
                        sx={{ mt: 3 }}
                        size="small"
                        startIcon={<Add />}
                        onClick={() => navigate("/products")}
                      >
                        Create quotation
                      </Button>
                    </Box>
                  }
                  icon={
                    <CatalogIcon style={{ height: "70px", width: "70px" }} />
                  }
                />
              </Box>
            ) : null}
            <TableBody>
              {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                   rows.sort(getComparator(order, orderBy)).slice() */}
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.name);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  let {
                    products,
                    unselected_products,
                    title,
                    id,
                    created_at,
                    is_confirmed,
                    customer,
                  } = row;

                  const totalProducts = isArrayWithValues(products)
                    ? validateNumber(products.length) +
                      validateNumber(unselected_products.length)
                    : 0;

                  const selectedProducts = totalProducts
                    ? totalProducts - validateNumber(unselected_products.length)
                    : 0;
                  let timeDifference = getRelativeTimeDifference(
                    new Date(created_at),
                    new Date()
                  );
                  const customerName = customer
                    ? `${customer.first_name} ${customer.last_name}`
                    : "";
                  return (
                    <StyledTableRow
                      hover
                      onClick={(event) => handleClick(event, row.name)}
                      //   role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.name}
                      selected={isItemSelected}
                    >
                      {/* <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            "aria-labelledby": labelId,
                          }}
                        />
                      </TableCell> */}
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        // padding="none"
                      >
                        <Typography sx={{ ...primaryLabel }}>
                          {row.title}
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        {formatDate(new Date(row.created_at), "dd-mm-yyyy")}
                      </TableCell>
                      <TableCell align="right">
                        <Typography sx={{ ...primaryLabel }}>
                          {row?.customer?.company_name}
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        {selectedProducts}/{totalProducts}
                      </TableCell>
                      <TableCell align="right">
                        {is_confirmed ? (
                          <Chip
                            label={"Confirmed"}
                            variant="outlined"
                            color={"success"}
                            size="small"
                            sx={{
                              border: isDarkTheme ? "0.7px solid" : "0px",
                              backgroundColor: isDarkTheme
                                ? "transparent"
                                : "#E6F8E0",
                              borderRadius: "10px",
                              fontSize: "11px",
                            }}
                          />
                        ) : (
                          <Chip
                            label={"Sent"}
                            variant="outlined"
                            color={"info"}
                            size="small"
                            sx={{
                              border: isDarkTheme ? "0.7px solid" : "0px",
                              backgroundColor: isDarkTheme
                                ? "transparent"
                                : "#e8f4fa",
                              borderRadius: "10px",
                              fontSize: "11px",
                            }}
                          />
                        )}
                      </TableCell>
                    </StyledTableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {/* <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        /> */}
      </Paper>
      {/* <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      /> */}
    </Box>
  );
}

function createData(name, calories, fat, carbs, protein) {
  return {
    name,
    calories,
    fat,
    carbs,
    protein,
  };
}

const rows = [
  createData("Cupcake", 305, 3.7, 67, 4.3),
  createData("Donut", 452, 25.0, 51, 4.9),
  createData("Eclair", 262, 16.0, 24, 6.0),
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
  createData("Gingerbread", 356, 16.0, 49, 3.9),
  createData("Honeycomb", 408, 3.2, 87, 6.5),
  createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
  createData("Jelly Bean", 375, 0.0, 94, 0.0),
  createData("KitKat", 518, 26.0, 65, 7.0),
  createData("Lollipop", 392, 0.2, 98, 0.0),
  createData("Marshmallow", 318, 0, 81, 2.0),
  createData("Nougat", 360, 19.0, 9, 37.0),
  createData("Oreo", 437, 18.0, 63, 4.0),
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Quotation",
  },
  {
    id: "calories",
    numeric: true,
    disablePadding: false,
    label: "Date",
  },
  {
    id: "fat",
    numeric: true,
    disablePadding: false,
    label: "Firm name",
  },
  {
    id: "carbs",
    numeric: true,
    disablePadding: false,
    label: "Product Qty",
  },
  {
    id: "protein",
    numeric: true,
    disablePadding: false,
    label: "Status",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell> */}
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {/* {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%", ...sectionLabel }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Recent Quotations
        </Typography>
      )} */}

      {/* {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )} */}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};
