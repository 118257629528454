import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getFacebookIntegrationInRedux } from "../../integrations/Integrations";
import {
  dialogStyle,
  formatDate,
  getAxiosError,
  getChannelData,
  getIntegrationObj,
  getRandomInt,
  getToken,
  getWebsite,
  isArrayWithValues,
  isObjWithValues,
  postIntegrationData,
  validateNumber,
} from "../../../helper";
import { setHubData } from "../../../redux/actions/hubActions";
import axios from "axios";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  IconButton,
  LinearProgress,
  Link,
  Paper,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { SocialIllustration } from "../../../Svgs";
import {
  Add,
  ArrowBack,
  Cached,
  ChatBubbleOutlineOutlined,
  Close,
  FavoriteBorderOutlined,
  PlayArrowRounded,
  Reply,
} from "@mui/icons-material";
import { LoadingButton, Masonry } from "@mui/lab";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";
import { useTheme } from "@mui/system";
import styled from "styled-components";
import CreatePost from "../CreatePost";
import { useRef } from "react";

import "swiper/css"; // core Swiper
import "swiper/css/pagination";
import "swiper/css/navigation";
import { StoryReply } from "../../chat/components/helper";

function Instagram({ facebookUserInfo, selectedPageAccessToken }) {
  const createPostRef = useRef();
  const [posts, setPosts] = useState([]);
  const [postDialog, setPostDialog] = useState(false);
  const [postDetails, setPostDetail] = useState({});
  const [fetchingFeed, setFetchingFeed] = useState(false);

  const [info, setInfo] = useState({});
  const [loadMore, setLoadMore] = useState(false);

  const dispatch = useDispatch();
  const theme = useTheme();
  const isIpad = useMediaQuery(theme.breakpoints.down("md"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // const instagram_id = useSelector((state) => state.hub.instagram_id);
  const instagram_posts = useSelector((state) => state.hub.instagram_posts);

  const instagram_next_feed_link = useSelector(
    (state) => state.hub.instagram_next_feed_link
  );
  const instagram_not_integrated = useSelector(
    (state) => state.hub.instagram_not_integrated
  );
  const no_instagram_posts = useSelector(
    (state) => state.hub.no_instagram_posts
  );
  const facebookIntegration = useSelector(
    (state) => state.settings.facebookIntegration
  );
  const instagram_info = useSelector((state) => state.settings.instagram_info);
  const reduxMetaIntegration = useSelector(
    (state) => state.settings.metaIntegration
  );
  const globalPopupStyle =
    useSelector((state) => state.helper.globalPopupStyle) || {};

  const [instagram_id, setInstagramId] = useState("");
  useEffect(() => {
    if (!isObjWithValues(facebookIntegration))
      getFacebookIntegrationInRedux(dispatch);
  }, []);

  useEffect(() => {
    if (Array.isArray(instagram_posts)) setPosts(instagram_posts);
  }, [instagram_posts]);
  useEffect(() => {
    if (isObjWithValues(reduxMetaIntegration)) {
      for (const key in reduxMetaIntegration) {
        if (!isArrayWithValues(reduxMetaIntegration[key]?.connected)) return;
        if (reduxMetaIntegration[key]?.connected.includes("instagarm")) {
          let facebookObj = { ...reduxMetaIntegration[key] };
          // setFacebookpage(facebookObj?.facebook_pages_info || {})
        }
      }
    }
  }, [reduxMetaIntegration]);
  useEffect(async () => {
    // if (
    //   isObjWithValues(facebookIntegration) &&
    //   facebookIntegration.access_token &&
    //   facebookIntegration.page &&
    //   facebookIntegration.page.id &&
    //   !instagram_id
    // ) {
    //   fetchInstagramId(
    //     facebookIntegration.page.id,
    //     facebookIntegration.access_token,
    //     dispatch
    //   );
    // }
    // if (
    //   isObjWithValues(facebookIntegration) &&
    //   facebookIntegration.access_token &&
    //   instagram_id
    // )
    const instagarmObj = getIntegrationObj(
      reduxMetaIntegration,
      "instagram"
    )?.obj;
    console.log(instagarmObj);
    if (isObjWithValues(instagarmObj)) {
      if (instagarmObj?.instagram_business_id) {
        setFetchingFeed(true);
        await fetchInstagramPosts(
          instagarmObj?.instagram_business_id
          // facebookIntegration.access_token,
          // dispatch
        );
        setFetchingFeed(false);
      }
    }
  }, [reduxMetaIntegration, instagram_id]);

  const postPress = (obj) => {
    setPostDetail(obj);
    setPostDialog(true);
  };

  const postDialogClose = () => {
    setPostDialog(false);
    setPostDetail({});
  };
  const updateInstagarmPostInRedux = (obj) => {
    console.log(obj, "updated obj");
    if (isArrayWithValues(posts)) {
      const updatedPost = posts.map((i) => (i.id === obj.id ? obj : i));
      dispatch(setHubData({ instagram_posts: updatedPost }));
    }
  };
  const refreshPost = async () => {
    const instagarmObj = getIntegrationObj(
      reduxMetaIntegration,
      "instagram"
    )?.obj;
    setFetchingFeed(true);
    await fetchInstagramPosts(
      instagarmObj?.instagram_business_id
      // facebookIntegration.access_token,
      // dispatch
    );
    setFetchingFeed(false);
  };

  const createPost = async () => {
    const instagarmObj = getIntegrationObj(
      reduxMetaIntegration,
      "instagram"
    )?.obj;
    await fetchInstagramPosts(
      instagarmObj?.instagram_business_id
      // facebookIntegration.access_token,
      // dispatch
    );
  };
  const fetchInstagramPosts = async (page_id, instagram_next_feed_link) => {
    if (!page_id) {
      const instagarmid = getIntegrationObj(reduxMetaIntegration, "instagram")
        ?.obj?.instagram_business_id;
      if (instagarmid) page_id = instagarmid;
    }

    try {
      const fields =
        "id,username,caption,media_type,permalink,thumbnail_url,media_url,timestamp,like_count,comments_count,like,comments{id,text,username,timestamp,replies},children{media_url,thumbnail_url}";
      // let res = await axios({
      //   url: `https://graph.facebook.com/v9.0/${page_id}/media?fields=${fields}&limit=15&access_token=${access_token}`,
      // });
      let endpoint = `/${page_id}/media?fields=${fields}&limit=25${instagram_next_feed_link ? `&after=${instagram_next_feed_link}` : ""
        }`;
      if (instagram_next_feed_link) setLoadMore(true);
      let resData = await getChannelData("facebook", endpoint);
      let res = { data: { ...resData } };
      setLoadMore(false);
      if (res.data) {
        if (isArrayWithValues(res.data.data)) {
          console.log(instagram_next_feed_link);
          if (instagram_next_feed_link) {
            if (isArrayWithValues(res.data.data))
              dispatch(
                setHubData({
                  instagram_posts: [...posts, ...res.data.data],
                  no_instagram_posts: false,
                })
              );
          } else {
            dispatch(
              setHubData({
                instagram_posts: res.data.data,
                no_instagram_posts: false,
              })
            );
          }
        } else
          dispatch(
            setHubData({
              no_instagram_posts: true,
            })
          );
      }

      console.log(res);
      console.log(res?.data?.paging?.cursors?.after);
      if (res?.data?.paging?.next) {
        dispatch(
          setHubData({
            instagram_next_feed_link: res?.data?.paging?.cursors?.after,
          })
        );
      } else {
        dispatch(
          setHubData({
            instagram_next_feed_link: ``,
          })
        );
      }
    } catch (error) {
      console.log(getAxiosError(error));
    }
  };

  if (
    !getIntegrationObj(reduxMetaIntegration, "instagram")?.obj
      ?.instagram_business_id
  )
    return (
      <Box
        sx={{
          maxWidth: "350px",
          margin: "auto",
          marginTop: "15vh",
          marginBottom: "10vh",
          textAlign: "center",
        }}
      >
        <SocialIllustration />
        <Typography variant="h6" color="text.secondary" mt={5}>
          Looks like Instagram Account Selected yet!
        </Typography>
      </Box>
    );

  if (no_instagram_posts)
    return (
      <Box
        sx={{
          width: "350px",
          margin: "auto",
          marginTop: "15vh",
          marginBottom: "10vh",
          textAlign: "center",
        }}
      >
        <SocialIllustration />
        <Typography variant="h6" color="text.secondary" mt={5}>
          No posts added yet!
        </Typography>
      </Box>
    );
  return (
    <Box>
      <Stack
        direction={"row"}
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant="h4" my={4}>
          Posts
        </Typography>
        {
          <Stack direction={"row"}>
            <LoadingButton
              // disabled={fetchingFeed}
              // onClick={onRefresh}
              startIcon={<Cached />}
              onClick={() => refreshPost()}
            >
              Refresh
            </LoadingButton>
            <Button
              variant="outlined"
              size="small"
              endIcon={<Add />}
              sx={{ mr: 2 }}
              onClick={() => createPostRef.current.openCreateDialog()}
            >
              Create
            </Button>
          </Stack>
        }
        <CreatePost ref={createPostRef} onPostCreated={createPost} />
      </Stack>
      {fetchingFeed && (
        <Box
          sx={{
            width: "300px",
            margin: "auto",
            marginTop: "30vh",
            marginBottom: "30vh",
            textAlign: "center",
          }}
        >
          <LinearProgress />
          <Typography variant="h6" color="text.secondary" mt={3}>
            Fetching Feed
          </Typography>
        </Box>
      )}
      {!fetchingFeed && (
        <Box
          sx={{
            marginRight: "4px",
            width: isIpad ? "calc(100vw - 10px)" : "calc(100vw - 150px)",
          }}
        >
          <Masonry
            columns={isMobile ? 1 : isIpad ? 2 : 4}
            spacing={4}
            sx={{ alignContent: "flex-start" }}
          >
            {posts.map((i, index) => (
              // <Grid item xs={3}>
              <Item key={index}>
                <PostCard
                  // facebookBasicInfo={facebookBasicInfo}
                  data={i}
                  key={i.id}
                  info={instagram_info}
                  postPress={postPress}
                />
              </Item>
              // </Grid>
            ))}
          </Masonry>
        </Box>
      )}
      {instagram_next_feed_link ? (
        <Stack direction={"row"} justifyContent="center">
          {" "}
          <LoadingButton
            variant="contained"
            loading={loadMore}
            onClick={() => fetchInstagramPosts("", instagram_next_feed_link)}
          >
            Load more
          </LoadingButton>{" "}
        </Stack>
      ) : null}

      {/* <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(12, 1fr)",
          gridTemplateColumns: "repeat(12, minmax(180px, 1fr))",
          gridTemplateColumns: "repeat(auto-fill, minmax(180px, 1fr))",
          gap: "1rem",
        }}
      >
        {isArrayWithValues(posts) &&
          posts.map((i) => {
            return (
              <InstagramThumbnail
                data={i}
                key={i.id}
                info={info}
                postPress={postPress}
              />
            );
          })}
      </Box> */}
      <Dialog
        open={postDialog}
        onClose={postDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        // fullScreen
        maxWidth="lg"
        fullWidth
        sx={{
          height: isIpad ? "80vh" : "90vh",
          margin: "auto 0px",
          ...dialogStyle,
          padding: "0px",
          ".MuiDialog-paper": {
            padding: "0",
            borderRadius: "30px",
          },
        }}
        {...globalPopupStyle}
      >
        <DialogContent sx={{ height: "100vh", padding: "0px" }}>
          <InstagramPost
            data={postDetails}
            info={facebookUserInfo}
            selectedPageAccessToken={selectedPageAccessToken}
            updateInstagarmPostInRedux={updateInstagarmPostInRedux}
            setPostDetail={setPostDetail}
            onClose={postDialogClose}
          />
        </DialogContent>
      </Dialog>
    </Box>
  );
}

export default Instagram;

const InstagramPost = ({
  data,
  info,
  selectedPageAccessToken,
  updateInstagarmPostInRedux,
  setPostDetail,
  onClose,
}) => {
  const [imageUrl, setImageUrl] = useState("");
  const [videoUrl, setVideoUrl] = useState("");
  const [images, setImages] = useState([]);
  const [comments, setComments] = useState([]);
  const [fetchingComments, setFetchingComments] = useState(false);
  const [addingComment, setAddingComment] = useState(false);
  const [comment, setComment] = useState("");
  const [replyObj, setReplyObj] = useState({});
  useEffect(() => {
    console.log(data);
    // const comments
    let { id, comments, children, media_type, media_url } = data;
    if (
      media_type === "CAROUSEL_ALBUM" &&
      children &&
      isArrayWithValues(children.data)
    )
      setImages(children.data);
    else if (media_type === "IMAGE") setImages([{ id, media_url }]);
    else if (media_type === "VIDEO") setVideoUrl(media_url);

    if (comments && isArrayWithValues(comments.data))
      setComments(comments.data);
  }, [data]);

  const addComment = async () => {
    let { id } = data;
    try {
      setAddingComment(true);
      let fields = "id,text,username,timestamp";
      // let commentId = replyObj?.id ? replyObj?.id : id;
      let endpoint = replyObj?.id
        ? `/${replyObj?.id}/replies?message=${comment}`
        : `/${id}/comments?message=${comment}&fields=id,text,username,timestamp,replies`;
      let res = await postIntegrationData({
        app: "facebook",
        endpoint: endpoint,
      });
      console.log(res);
      if (res?.data?.id) {
        let newComments = await getChannelData(
          "facebook",
          `/${id}/comments?&fields=id,text,username,timestamp,replies`
        );
        console.log(newComments);
        if (isArrayWithValues(newComments?.data)) {
          // let updatedComments = [{ ...res.data }, ...comments];
          let updatedComments = [...newComments?.data];
          let updatedObj = {
            ...data,
            comments: {
              ...data.comments,
              data: [...updatedComments],
            },
            comments_count: validateNumber(data.comments_count) + 1,
          };
          updateInstagarmPostInRedux(updatedObj);
          setComments(updatedComments);
          setComment("");
        }
        // if (data.comments) {
        //   let updatedObj = {
        //     ...data,
        //     comments: {
        //       ...data.comments,
        //       data: [...updatedComments],
        //     },
        //     comments_count: validateNumber(data.comments_count) + 1,
        //   };
        //   updateInstagarmPostInRedux(updatedObj);
        // }
        // setComments(updatedComments);
        // setComment("");
      }
    } catch (error) {
      console.log(getAxiosError(error));
    }
    setAddingComment(false);
  };

  let { full_picture, status_type, caption, message, media_type } = data;
  let { name, picture } = info;
  let profile = picture && picture.data && picture.data.url;
  const theme = useTheme();
  const isIpad = useMediaQuery(theme.breakpoints.down("md"));
  const [viewfullCaption, setViewFullCaption] = useState(false);
  return (
    <Grid container sx={isIpad ? {} : { height: "100%" }}>
      {isIpad && (
        <Grid item xs={12}>
          <Stack direction="row" py={3} px={3} alignItems="center">
            <IconButton onClick={onClose}>
              <ArrowBack />
            </IconButton>
            <Avatar
              src={profile}
              sx={{ mr: 2, width: 30, height: 30 }}
            ></Avatar>
            <Typography fontWeight="600" mr={1}>
              {name}
            </Typography>
            {/* <Typography>
            {caption.length > 30 ? (
              <>
                {viewfullCaption ? (
                  <>
                    {`${caption}`}
                    <Link onClick={() => setViewFullCaption(false)} sx={{cursor:"pointer"}}>
                      {viewfullCaption ? "   View less" : "   View more"}
                    </Link>
                  </>
                ) : (
                  <>
                    {" "}
                    {`${caption.slice(0, 30)}`}
                    <Link onClick={() => setViewFullCaption(true)} sx={{cursor:"pointer"}}>
                      {viewfullCaption ? "    View less" : "...    View more"}
                    </Link>{" "}
                  </>
                )}
              </>
            ) : (
              caption
            )}
          </Typography> */}
            <Typography>{caption}</Typography>
          </Stack>{" "}
        </Grid>
      )}
      <Grid
        item
        xs={12}
        sm={12}
        md={7}
        sx={{ backgroundColor: "#000", height: isIpad ? "50%" : "100%" }}
      >
        {isArrayWithValues(images) &&
          (media_type === "IMAGE" || media_type === "CAROUSEL_ALBUM") && (
            <Swiper
              spaceBetween={0}
              slidesPerView={1}
              // grabCursor
              pagination={{
                // dynamicBullets: false,
                enabled: true,
                clickable: true,
              }}
              navigation={true}
              modules={[Pagination, Navigation]}
              // modules={[EffectFade]}
              style={{
                maxWidth: "100%",
                // width: "100%",
                overflowX: "hidden",

                width: "100%",
                height: "100%",
              }}
            // fadeEffect={""}
            // effect="fade"
            // direction="vertical"
            // onSlideChange={() => console.log('slide change')}
            // onSwiper={(swiper) => console.log(swiper)}
            >
              {images.map((image) => {
                let url = image.media_url;
                return (
                  <SwiperSlide
                    style={{
                      maxWidth: "100%",
                      maxHeight: "100%",

                      width: "100%",
                      height: "100%",
                      // maxWidth: "160px",
                      // border: `0.3px solid rgba(0, 0, 0,0.3)`,
                    }}
                  >
                    <StoryReply
                      url={url} style={{
                        width: "100%",
                        height: "100%",
                        flex: 1,
                        objectFit: "contain",
                        margin: "auto",
                      }}
                    />
                    {/* <img
                      src={url}
                      style={{
                        width: "100%",
                        height: "100%",
                        flex: 1,
                        objectFit: "contain",
                        margin: "auto",
                      }}
                    /> */}
                  </SwiperSlide>
                );
              })}
            </Swiper>
          )}
        {/* {status_type === "added_photos" && (
          <img
            src={full_picture || "/static/img/woocommerce_placeholder.png"}
            style={{
              width: "100%",
              height: "100%",
              flex: 1,
              objectFit: "contain",
              margin: "auto",
            }}
          />
        )} */}
        {media_type === "VIDEO" && videoUrl && (
          <video
            width="100%"
            height="100%"
            style={{ margin: "auto 0px", objectFit: "contain" }}
            controls
            autoPlay
          >
            <source
              src={videoUrl}
              // style={{ height: "100%", width: "100%", margin: "auto 0px" }}
              type="video/mp4"
            />
            Your browser does not support HTML video.
          </video>
        )}
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={5}
        sx={{
          backgroundColor: "Background.default",
          position: "relative",
          height: isIpad ? "50%" : "100%",
          overflow: "hidden",
        }}
      >
        {/* <Box sx={{}}> */}
        {!isIpad && (
          <Stack direction="row" py={3} px={3} alignItems="center">
            <Avatar
              src={profile}
              sx={{ mr: 2, width: 30, height: 30 }}
            ></Avatar>
            <Typography fontWeight="600" mr={1}>
              {name}
            </Typography>
            {/* <Typography>
            {caption.length > 30 ? (
              <>
                {viewfullCaption ? (
                  <>
                    {`${caption}`}
                    <Link onClick={() => setViewFullCaption(false)} sx={{cursor:"pointer"}}>
                      {viewfullCaption ? "   View less" : "   View more"}
                    </Link>
                  </>
                ) : (
                  <>
                    {" "}
                    {`${caption.slice(0, 30)}`}
                    <Link onClick={() => setViewFullCaption(true)} sx={{cursor:"pointer"}}>
                      {viewfullCaption ? "    View less" : "...    View more"}
                    </Link>{" "}
                  </>
                )}
              </>
            ) : (
              caption
            )}
          </Typography> */}
            <Typography>{caption}</Typography>
          </Stack>
        )}
        <Divider />
        <Box
          sx={{ overflowY: "scroll", maxHeight: "100%", paddingBottom: "30px" }}
        >
          {!fetchingComments &&
            comments.map((comment) => {
              return (
                <>
                  <Comment comment={comment} setReplyObj={setReplyObj} />
                </>
              );
            })}
        </Box>
        {fetchingComments && (
          <Stack textAlign={"center"} mt={5}>
            <CircularProgress
              color="secondary"
              style={{ margin: "0px auto", width: "25px", height: "25px" }}
            />
          </Stack>
        )}
        <Box
          sx={{
            position: isIpad ? "fixed" : "absolute",
            bottom: 0,
            right: 0,
            left: 0,
            backgroundColor: "Background.paper",
            paddingBottom: "10px",
            // display: "flex",
            // alignItems: "flex-end",
          }}
        // alignItems="center"
        >
          <Divider sx={{ marginBottom: "10px" }} />
          {isObjWithValues(replyObj) ? (
            <Stack
              direction={"row"}
              sx={{ backgroundColor: "#fff", justifyContent: "space-between" }}
            >
              <Stack sx={{ ml: 3 }}>
                <Typography>@{replyObj?.username}</Typography>
                <Typography>{replyObj?.text}</Typography>
              </Stack>
              <IconButton
                onClick={() => {
                  setReplyObj({});
                }}
              >
                <Close />
              </IconButton>
            </Stack>
          ) : null}
          <Stack direction="row" alignItems={"center"} px={2}>
            <ChatBubbleOutlineOutlined sx={{ ml: 1, mr: 2, my: 0.5 }} />
            <TextField
              variant="standard"
              fullWidth
              placeholder="Add a comment..."
              size="medium"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              onKeyDown={(e) => {
                console.log(e.key);
                if (e.key == "Enter") {
                  addComment();
                }
              }}
            />
            <LoadingButton
              loading={addingComment}
              color="primary"
              onClick={addComment}
            >
              Send
            </LoadingButton>
          </Stack>
        </Box>
        {/* </Box> */}
      </Grid>
    </Grid>
  );
};

const InstagramThumbnail = ({ data, info, postPress }) => {
  let [hover, setHover] = useState(false);
  const [likesCount, setLikesCount] = useState(0);
  const [commentsCount, setCommentsCount] = useState(0);
  const [thumbnail, setThumbnail] = useState("");
  useEffect(() => {
    let { like_count, comments_count, media_type, media_url, thumbnail_url } =
      data;
    setLikesCount(like_count || 0);
    setCommentsCount(comments_count || 0);
    if (media_type === "IMAGE" || media_type === "CAROUSEL_ALBUM")
      setThumbnail(media_url);
    else if (media_type === "VIDEO") setThumbnail(thumbnail_url);
  }, [data]);

  const mouseEnter = () => {
    setHover(true);
  };
  const mouseLeave = () => {
    setHover(false);
  };
  let { media_type } = data;

  return (
    <Box
      sx={{
        borderRadius: "7px",
        overflow: "hidden",
        cursor: "pointer",
        position: "relative",
        backgroundColor: "#000",
        maxHeight: "200px",
      }}
      onClick={() => postPress(data)}
      onMouseEnter={mouseEnter}
      onMouseLeave={mouseLeave}
    >
      {media_type === "VIDEO" && (
        <PlayArrowRounded
          style={{
            position: "absolute",
            height: "24px",
            width: "24px",
            color: "#fff",
            top: 10,
            right: 10,
          }}
        />
      )}
      <img
        src={thumbnail}
        style={{
          width: "100%",
          height: "100%",
          flex: 1,
          objectFit: "contain",

          opacity: hover ? 0.5 : 1,
          backgroundColor: "#000",
          transition: "opacity 0.15s",
        }}
      />
      {hover && (
        <Box
          sx={{
            position: "absolute",
            top: "45%",
            left: 0,
            right: 0,
            margin: "auto",
          }}
        >
          <Stack
            direction="row"
            alignItems={"center"}
            textAlign="center"
            color="#fff"
            justifyContent="center"
          >
            <FavoriteBorderOutlined
              color="#fff"
              style={{ marginRight: "4px" }}
            />
            {likesCount}
            <ChatBubbleOutlineOutlined
              style={{ marginLeft: "7px", marginRight: "4px" }}
            />
            {commentsCount}
          </Stack>
        </Box>
      )}
    </Box>
  );
};
const fetchInstagramId = async (page_id, access_token, dispatch) => {
  try {
    let res = await axios({
      url: `https://graph.facebook.com/v9.0/${page_id}?fields=instagram_business_account&access_token=${access_token}`,
    });
    if (
      res.data &&
      res.data.instagram_business_account &&
      res.data.instagram_business_account.id
    ) {
      dispatch(
        setHubData({
          instagram_id: res.data.instagram_business_account.id,
          instagram_not_integrated: false,
        })
      );
    } else
      dispatch(
        setHubData({
          instagram_not_integrated: true,
        })
      );
  } catch (error) {
    console.log(getAxiosError(error));
    dispatch(
      setHubData({
        instagram_not_integrated: true,
      })
    );
  }
};

const PostCard = ({ data, info, postPress, facebookBasicInfo }) => {
  let [hover, setHover] = useState(false);
  const [likesCount, setLikesCount] = useState(0);
  const [commentsCount, setCommentsCount] = useState(0);
  const [thumbnail, setThumbnail] = useState("");
  useEffect(() => {
    let { like_count, comments_count, media_type, media_url, thumbnail_url } =
      data;
    setLikesCount(like_count || 0);
    setCommentsCount(comments_count || 0);
    if (media_type === "IMAGE" || media_type === "CAROUSEL_ALBUM")
      setThumbnail(media_url);
    else if (media_type === "VIDEO") setThumbnail(thumbnail_url);
  }, [data]);

  const mouseEnter = () => {
    setHover(true);
  };
  const mouseLeave = () => {
    setHover(false);
  };
  let { media_type } = data;
  let name = "";
  let profile = "";
  let caption = data?.caption || "";
  let timestamp = data?.timestamp;
  return (
    <Card className="shadow_card" onClick={() => postPress(data)}>
      <CardContent sx={{ padding: "5px" }}>
        <Stack
          direction={"row"}
          alignItems="center"
          sx={{ padding: "10px", gap: "10px" }}
        >
          <Avatar src={profile} />
          <Stack>
            <Typography variant="subtitle1" fontWeight="600" sx={{ mb: 1 }}>
              {data?.username || ""}
            </Typography>
            {timestamp ? (
              <Typography>
                {formatDate(new Date(timestamp), "relative_date")}
              </Typography>
            ) : null}
          </Stack>
        </Stack>
        <Stack>
          <Typography fontWeight={"450"} sx={{ pl: "10px" }}>
            {caption.length > 30 ? `${caption.slice(0, 30)}...` : caption}
          </Typography>
        </Stack>
        <Divider />
        <Stack>
          {media_type === "VIDEO" && (
            <PlayArrowRounded
              style={{
                position: "absolute",
                height: "24px",
                width: "24px",
                color: "#fff",
                top: 10,
                right: 10,
              }}
            />
          )}
          <img src={thumbnail || "/static/img/woocommerce_placeholder.png"} />
        </Stack>
        <Stack>
          <Divider />
          <Stack
            direction={"row"}
            alignItems="center"
            justifyContent={"space-evenly"}
          >
            <Button startIcon={<FavoriteBorderOutlined />}>
              Like {likesCount ? likesCount : null}
            </Button>
            <Button startIcon={<ChatBubbleOutlineOutlined />}>
              Comment {commentsCount ? commentsCount : null}
            </Button>
            {/* <Button startIcon={<ReplyOutlined />}>Share</Button> */}
          </Stack>
          <Divider />
        </Stack>
      </CardContent>
    </Card>
  );
};

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(0.5),
  color: theme.palette.text.secondary,
}));

const Comment = ({ setReplyObj, comment }) => {
  // let { message, from } = comment;
  let { text, username } = comment;

  // let picture =
  //   from?.picture && from?.picture?.data && from?.picture?.data?.url;
  const [showReplyIcon, setShowReplyIcon] = useState(false);
  return (
    <Stack
      onMouseEnter={() => setShowReplyIcon(true)}
      onMouseLeave={() => setShowReplyIcon(false)}
    >
      <Stack direction="row" mt={5} px={3} alignItems="center">
        <Avatar src={""} sx={{ mr: 2, width: 30, height: 30 }}></Avatar>
        <Typography fontWeight="600" mr={1}>
          {username}
        </Typography>
        <Typography>{text}</Typography>
        {showReplyIcon ? (
          <IconButton sx={{ padding: 0 }} onClick={() => setReplyObj(comment)}>
            <Reply />
          </IconButton>
        ) : null}
      </Stack>
      {isArrayWithValues(comment?.replies?.data) ? (
        <CommentsReply comments={comment?.replies?.data} />
      ) : null}
    </Stack>
  );
};
const CommentsReply = ({ comments }) => {
  const [showReplies, setShowReplies] = useState(true);
  let picture = comments?.from?.picture?.data?.url;

  return (
    <Stack>
      {showReplies ? (
        <>
          {comments.map((reply) => (
            <Stack
              direction={"row"}
              alignItems="center"
              gap={2}
              sx={{ ml: "35px", mb: 2 }}
            >
              <Stack>
                <Avatar
                  src={picture || ""}
                  sx={{ width: 25, height: 25 }}
                ></Avatar>
              </Stack>
              <Stack
                sx={{
                  borderRadius: "15px",
                  padding: "5px 12px",
                  backgroundColor: "#EFEFEF",
                }}
              >
                {/* <Typography fontWeight="500">{reply?.from?.name || ""}</Typography> */}
                <Typography>{reply?.text || ""}</Typography>
              </Stack>
            </Stack>
          ))}
        </>
      ) : null}
      <Typography
        sx={{ ml: "35px", cursor: "pointer" }}
        onClick={() => setShowReplies((pre) => !pre)}
      >
        {showReplies ? "Hide" : "Show"} {comments.length} replies
      </Typography>
    </Stack>
  );
};
