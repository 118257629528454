import {
  Box,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import CustomLabelSwitch from "../../components/CustomLabelSwitch";
import { primaryLabel } from "../../../helper";

function ProfitMargin({ product, onChange }) {
  const onEdit = (payload) => {
    onChange({ profit_margin: { ...product.profit_margin, ...payload } });
  };
  console.log(product?.profit_margin);
  return (
    <Box>
      <CustomLabelSwitch
        label="Enable profit margin?"
        checked={Boolean(product?.profit_margin?.enabled)}
        onChange={(e) => onEdit({ enabled: e })}
      />
      <Stack
        direction="row"
        justifyContent={"space-between"}
        alignItems={"center"}
        mt={4}
        spacing={3}
      >
        <Typography sx={{ ...primaryLabel }}>Enter profit margin</Typography>
        <TextField
          value={product?.profit_margin?.value}
          onChange={(e) => onEdit({ value: e.target.value })}
          placeholder="Enter percentage"
          size="small"
          type="number"
          InputProps={{
            endAdornment: <InputAdornment position="start">%</InputAdornment>,
          }}
        />
      </Stack>
      <Stack
        direction="row"
        justifyContent={"space-between"}
        alignItems={"center"}
        mt={4}
        // spacing={3}
      >
        <Typography sx={{ ...primaryLabel }}>Select Cost Component</Typography>
        <FormControl size="small" sx={{ width: "175px" }}>
          <InputLabel id="demo-simple-select-label">Cost Component</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={product?.profit_margin?.cost_component}
            label="Cost Component"
            onChange={(e) => onEdit({ cost_component: e.target.value })}
          >
            <MenuItem value={"diamond"}>Diamond</MenuItem>
            <MenuItem value={"colorstone"}>Gemstone</MenuItem>
            <MenuItem value={"labour"}>Labour</MenuItem>
          </Select>
        </FormControl>
      </Stack>
      {/* <CustomLabelSwitch
        label="Enter profit margin"
        checked={Boolean(product?.profit_margin?.value)}
        onChange={(e) => onEdit({ value: e })}
      /> */}
    </Box>
  );
}

export default ProfitMargin;
