const STATUS_MATERIAL_ICONS_PROPS = {
  sent: { name: "check", color: "grey" },
  delivered: { name: "check-all", color: "grey" },
  read: { name: "check-all", color: "#62AFE6", iconColor: "blue" },
  sending: { name: "clock-time-eight-outline" },
  failed: { name: "alert-circle-outline", color: "red", iconColor: "red" },
};

const CHAT_USER_CUSTOM_FILTER_FIELDS = [
  "broadcast",
  "assigned_to",
  "tags",
  "assigned_team_member",
];

const RESEVERD_KEYS_FROM_CUSTOM_FIELDS = [
  "created_at",
  "updated_at",
  "status_updated",
  "last_message",
  "last_message_sent",
  "last_message_received",
  "conversation",
  // "assigned_team_member",
  "assign_to",
  "id",
  "name",
  "username",
  "profile_pic",
  "is_verified_user",
  "status",
  "unread",
  "platform",
  "platform_id",
  "dialcode_mobile",
  "contact_type",
  "email",
  "company_name",
  "billing",
  "first_name",
  "last_name",
  "phone",
  "dial_code",
  "email",
  "branch",
  "city",
  "state",
  "country",
];

export {
  STATUS_MATERIAL_ICONS_PROPS,
  CHAT_USER_CUSTOM_FILTER_FIELDS,
  RESEVERD_KEYS_FROM_CUSTOM_FIELDS,
};
