import {
  Breadcrumbs,
  Grid,
  Link,
  Typography,
  Card,
  CardContent,
  Stack,
  List,
  ListItem,
  Avatar,
  ListItemAvatar,
  ListItemText,
  CircularProgress,
  Box,
  IconButton,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useSelector } from "react-redux";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import {
  fetchIPInfo,
  fetchProducts,
  formatDate,
  getAxiosError,
  getRemainingTime,
  isArrayWithValues,
  isObjWithValues,
} from "../../helper";
import UAParser from "ua-parser-js";
import { Clock, Map, MapPin } from "react-feather";
import LaptopMacIcon from "@mui/icons-material/LaptopMac";
import { ArrowBackIos } from "@material-ui/icons";
function RequestView() {
  const [request, setRequest] = useState({});
  let { fullname, catalogue_name } = request;
  // const reduxRequestView = useSelector((state) => state.product.requestView);
  const reduxRequestView = {};
  const [requestView, setRequestView] = useState({});
  const navigate = useNavigate();
  const { state } = useLocation();

  useEffect(() => {
    setRequest(state.requestView);
  }, [state.requestView]);

  useEffect(() => {
    if (isObjWithValues(reduxRequestView))
      setRequestView({ ...reduxRequestView });
    // else navigate("/catalogs/requests");
  }, [reduxRequestView]);

  // useEffect(() => {
  //   setRequest(requestView);
  // }, [requestView]);
  return (
    <React.Fragment>
      <Helmet title="Catalogs" />

      <Grid
        justifyContent="space-between"
        alignItems={"center"}
        container
        spacing={2}
        p={6}
      >
        <Grid item>
          <Stack direction="row" alignItems={"center"}>
            <IconButton
              size="small"
              sx={{}}
              onClick={() => navigate("/catalogs/requests")}
            >
              <ArrowBackIos />
            </IconButton>
            <Typography variant="h3" display="inline">
              {catalogue_name}
            </Typography>
          </Stack>

          {/* <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/">
              Dashboard
            </Link>
            <Typography>Catalogs</Typography>
          </Breadcrumbs> */}
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Card>
            {/* <Typography variant="h3" sx={{mb: 3}} gutterBottom display="inline">
                {catalogue_name}
              </Typography> */}
            <CardContent>
              <Grid container spacing={4}>
                <Grid item xs={12} sm={6} md={6}>
                  <CatalogInfo request={request} />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <UserInfo request={request} />
                </Grid>
              </Grid>
              <Grid container spacing={4} mt={3}>
                <Grid item xs={12} sm={4}>
                  <TimeSpentCard request={request} />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <LocationCard request={request} />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <DeviceCard request={request} />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        {/* <Grid item xs={12} sm={6} md={6}>
        </Grid> */}
        <Grid item xs={12} sm={12} md={12}>
          <Requirements request={request} />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <Products request={request} />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default RequestView;

const TimeSpentCard = ({ request }) => {
  const [remainingTime, setRemainingTime] = useState("00 : 00 : 00");
  useEffect(() => {
    if (request.analytics) {
      let { analytics } = request;
      if (analytics.seconds) {
        setRemainingTime(
          getRemainingTime(Math.floor(Date.now() / 1000) + analytics.seconds)
        );
      }
    }
  }, [request]);
  return (
    <Card sx={{ backgroundColor: "#EAF3FB", color: "#31516E" }}>
      <CardContent sx={{ position: "relative" }}>
        <Stack direction="row" alignItems={"center"}>
          <Clock size={"20px"} />
          <Typography variant="h5" ml={2}>
            Time Spent
          </Typography>
        </Stack>
        <Typography fontWeight={"normal"} variant="h2" mt={3}>
          {remainingTime}
        </Typography>
        {/* <Box
          sx={{
            position: "absolute",
            top: "-40px",
            width: "100%",
            justifyContent: "center",
          }}
        >
          <Clock
            size={"200px"}
            style={{ textAlign: "center", color: "rgba(0,0,0,0.1)" }}
          />
        </Box> */}
      </CardContent>
    </Card>
  );
};
const LocationCard = ({ request }) => {
  const [location, setLocation] = useState("");
  useEffect(async () => {
    if (request.analytics) {
      const { analytics } = request;
      if (analytics.ip) {
        try {
          setLocation("Loading...");
          let location = await fetchIPInfo(analytics.ip);
          if (isObjWithValues(location)) {
            let {
              geoplugin_city,
              geoplugin_regionCode,
              geoplugin_countryCode,
            } = location;
            setLocation(
              `${geoplugin_city ? geoplugin_city + ", " : ""}${
                geoplugin_regionCode ? geoplugin_regionCode + ", " : ""
              }${geoplugin_countryCode || ""}`
            );
          } else setLocation("Unavailable");
        } catch (error) {
          console.log(error);
          setLocation("Unavailable");
        }
      } else setLocation("Unavailable");
    } else setLocation("Unavailable");
  }, [request]);

  return (
    <Card sx={{ backgroundColor: "#FBEAF6", color: "#934B79", height: "100%" }}>
      <CardContent sx={{ position: "relative" }}>
        <Stack direction="row" alignItems={"center"}>
          <MapPin size={"20px"} />
          <Typography variant="h5" ml={2}>
            Location
          </Typography>
        </Stack>
        <Typography variant="h2" fontWeight={"normal"} mt={3}>
          {location}
        </Typography>
        {/* <Box
          sx={{
            position: "absolute",
            top: "-40px",
            width: "100%",
            justifyContent: "center",
          }}
        >
          <MapPin
            size={"200px"}
            style={{ textAlign: "center", color: "rgba(0,0,0,0.05)" }}
          />
        </Box> */}
      </CardContent>
    </Card>
  );
};
const DeviceCard = ({ request }) => {
  const [browser, setBrowser] = useState("");
  const [OS, setOS] = useState("");
  useEffect(() => {
    if (request.analytics) {
      let { analytics } = request;
      if (analytics.user_agent) {
        let obj = new UAParser(analytics.user_agent);
        let info = obj.getResult();
        setBrowser(`${info.browser.name} / `);
        setOS(info.os.name);
      } else setBrowser("Unavailable");
    } else setBrowser("Unavailable");
  }, [request]);
  return (
    <Card sx={{ backgroundColor: "#DFEFD7", color: "#4B733A", height: "100%" }}>
      <CardContent sx={{ position: "relative" }}>
        <Stack direction="row" alignItems={"center"} mb={3}>
          <LaptopMacIcon size={"20px"} />
          <Typography variant="h5" ml={2}>
            Device
          </Typography>
        </Stack>
        <Grid item sx={{ height: "100%" }} />
        <Typography
          variant="h3"
          fontWeight={"normal"}
          sx={{ marginTop: "auto" }}
        >
          {browser}
          {OS}
        </Typography>
        {/* <Box
          sx={{
            position: "absolute",
            // top: "-40px",
            width: "100%",
            justifyContent: "center",
          }}
        >
          <LaptopMacIcon
            style={{
              textAlign: "center",
              color: "rgba(0,0,0,2)",
              width: "200px",
              height: "200px",
            }}
          />
        </Box> */}
      </CardContent>
    </Card>
  );
};

const LabelValue = (props) => {
  let { label, value } = props;
  return (
    <Stack direction="row" mt={2}>
      <Typography variant="h6" fontWeight={"normal"}>
        {label} :{" "}
      </Typography>
      <Typography variant="h6" ml={2}>{`  ${value}`}</Typography>
    </Stack>
  );
};

const CatalogInfo = (props) => {
  let { created_at, allProducts, products } = props.request;
  let productsLength = products ? products.toString().split(",") : 0;
  productsLength = isArrayWithValues(productsLength)
    ? productsLength.length
    : 0;
  return (
    <>
      <Typography variant="h5">Catalog Info</Typography>
      <LabelValue
        label="Products"
        value={`${productsLength} / ${allProducts || 0}`}
      />
      <LabelValue
        label="Created at"
        value={`${
          created_at
            ? formatDate(new Date(created_at), "dd:mm:yyyy hh:mm am/pm")
            : ""
        }`}
      />
    </>
  );
};

const UserInfo = ({ request }) => {
  let { fullname, email, phone } = request;
  return (
    <>
      <Typography variant="h5">User Info</Typography>
      <LabelValue label="Full name" value={`${fullname}`} />
      <LabelValue label="Email" value={`${email}`} />
      <LabelValue label="Phone" value={`${phone}`} />
    </>
  );
};
const Requirements = ({ request }) => {
  let { option, optiontext } = request;
  let [options, setOptions] = useState([]);
  useEffect(() => {
    let options = option ? option.split(",") : [];
    if (optiontext) options.push(optiontext);
    setOptions(options);
  }, [request]);
  return (
    <Card>
      <CardContent>
        <Typography variant="h5">User Requirements</Typography>
        {isArrayWithValues(options) &&
          options.map((obj, i) => (
            <Typography key={obj} variant="h6" mt={2}>{`${
              i + 1
            } - ${obj}`}</Typography>
          ))}
      </CardContent>
    </Card>
  );
};
const Products = ({ request }) => {
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [productsLength, setProductsLength] = useState();
  useEffect(async () => {
    if (isObjWithValues(request) && !isArrayWithValues(products)) {
      let { products } = request;
      let ids = products ? products.split(",") : [];
      setProductsLength(ids.length);
      if (isArrayWithValues(ids))
        await fetchProducts({ include: ids }, setProducts, setLoading);
    }
  }, [request]);
  return (
    <Card>
      <CardContent>
        <Stack direction="row">
          <Typography variant="h5">Products</Typography>
        </Stack>
        <Grid container spacing={2}>
          {isArrayWithValues(products)
            ? products.map((obj, i) => (
                <Grid key={obj.id} item xs={12} sm={6} md={3}>
                  <ProductItem product={obj} />
                </Grid>
              ))
            : null}
        </Grid>
        {loading ? (
          <Stack
            justifyContent={"center"}
            textAlign="center"
            width="100%"
            height={`${50 * productsLength}px`}
          >
            <CircularProgress sx={{ margin: "auto" }} />
          </Stack>
        ) : null}
      </CardContent>
    </Card>
  );
};

const ProductItem = ({ product }) => {
  let { images, name, sku } = product;
  let image = isArrayWithValues(images) && images[0].src;

  return (
    <Card sx={{ height: "100%", mb: 5 }}>
      <CardContent sx={{ textAlign: "center", position: "relative" }}>
        <Box
          sx={{
            minHeight: "240px",
            alignItems: "center",
            alignContent: "center",
            position: "relative",
            // mt: "50px",
          }}
        >
          <Box
            sx={{
              top: "50%",
              position: "absolute",
              transform: "translateY(-50%)",
            }}
          >
            <img
              src={image || "/static/img/woocommerce_placeholder.png"}
              style={{
                height: "100%",
                width: "100%",
                alignSelf: "center",
              }}
            />
          </Box>
        </Box>
        <Typography variant="h5" sx={{ mt: "auto" }}>
          {name}
        </Typography>
        <Typography variant="body1">{sku}</Typography>
      </CardContent>
    </Card>
    // <ListItem alignItems="flex-start">
    //   <ListItemAvatar>
    //     <Avatar alt={name} src={image} />
    //   </ListItemAvatar>
    //   <ListItemText
    //     primary={name}
    //     primaryTypographyProps={{ variant: "h6" }}
    //     secondary={
    //       <React.Fragment>
    //         <Typography
    //           sx={{ display: "inline" }}
    //           component="span"
    //           variant="body2"
    //           color="text.primary"
    //         >
    //           {sku}
    //         </Typography>
    //       </React.Fragment>
    //     }
    //   />
    // </ListItem>
  );
};

const demorequest = {
  request_id: "d7e850ec055e871",
  post_id: 122,
  fullname: "Akshay pagare",
  email: "test@gmail.com",
  phone: "7476463638",
  catalogue_name: "Test Catalog Akshay Pagare",
  products: "53541,53663,53401",
  option: "Want to buy selected products, Others",
  optiontext: "Also I want 10% discount",
  catalogue_request_timing: "",
  analytics: {
    ip: "66.249.66.201",
    user_agent:
      "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/99.0.4844.83 Safari/537.36",
    start: 1648645879,
    end: 1648645983,
    seconds: 30,
  },
};
