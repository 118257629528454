import ReactScrollableFeed from "react-scrollable-feed";
import PropTypes from "prop-types";
import BotMessages from "../helper/BotMessages";
import UserMessages from "../helper/UserMessages";
import { useSelector } from "react-redux";
import TypingIndicator from "../helper/TypingIndicator";
import { messageSectionStyles } from "../styles/MessageSectionStyles";

const styles = messageSectionStyles;

const MessageSection = ({ messages, data }) => {
  const chatWindowColor = useSelector(
    (state) => state.flow?.chatbotStyles
  )?.chatWindowColor;
  const testBotTypingIndicator = useSelector(
    (state) => state?.flow?.testBotTypingIndicator
  );

  return (
    // <div style={{ ...styles.mainContainer, backgroundColor: chatWindowColor }}>
    <div style={styles.mainContainer}>
      <ReactScrollableFeed>
        <div
          style={{
            flex: 1,
          }}
        >
          <div style={styles.innerContainer}>
            {messages.map((message) => {
              if (message?.isUser === true) {
                return <UserMessages key={message.id} message={message} />;
              }

              if (
                message.isUser === false ||
                message.template_message_payload?.isUser === false
              ) {
                return (
                  <BotMessages key={message.id} message={message} data={data} />
                );
              }
            })}
            {testBotTypingIndicator && <TypingIndicator />}
          </div>
        </div>
      </ReactScrollableFeed>
    </div>
  );
};

MessageSection.propTypes = {
  messages: PropTypes.array.isRequired,
};

export default MessageSection;
