import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Stack,
  Typography,
} from "@mui/material";
import React, { forwardRef, useImperativeHandle, useState } from "react";
import { MediaIllustrations } from "../../../../Svgs";
import { dialogStyle, secondaryLabelSmall } from "../../../../helper";

const MapMediaDialog = forwardRef(({ onSubmit = () => {} }, ref) => {
  const [open, setOpen] = useState(false);
  useImperativeHandle(ref, () => {
    return {
      open() {
        setOpen(true);
      },
    };
  });
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="xs"
      fullWidth
      sx={dialogStyle}
    >
      {/* <DialogTitle id="alert-dialog-title">
    {"Are you sure you want to assgin media it will take bit more time"}
  </DialogTitle> */}
      <DialogContent>
        <Stack
          direction={"row"}
          justifyContent="center"
          sx={{ margin: "10px auto", alignSelf: "center" }}
        >
          <MediaIllustrations
            width="130px"
            height="130px"
            // className="animated_background_color"
          />
        </Stack>
        <Typography
          variant="subtitle2"
          sx={{
            mt: 3,
            // fontSize: "12px",
            textAlign: "center",
            // color: "text.secondary",
          }}
        >
          Would you like to Auto-Map Images to respective products?
        </Typography>
        <Typography
          variant="subtitle2"
          sx={{
            mt: 3,
            textAlign: "center",
            ...secondaryLabelSmall,
          }}
        >
          If there is no image in the product's cell, we will search for it in
          the media library and associate it with the SKU.
        </Typography>
        {/* <Box sx={{ mt: 6 }}>
      <BorderLinearProgress variant="determinate" value={syncProgress} />
      <Typography
        sx={{
          fontSize: "14px",
          textAlign: "right",
          mr: 2,
          mt: 2,
          fontWeight: "600",
        }}
      >
        {syncProgress}%
      </Typography>
    </Box> */}
        {/* <DialogContentText id="alert-dialog-description">
        Let Google help apps determine location. This means sending
        anonymous location data to Google, even when no apps are running.
      </DialogContentText> */}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setOpen(false);
            onSubmit(false);
          }}
        >
          No
        </Button>
        <Button
          onClick={() => {
            setOpen(false);
            onSubmit(true);
          }}
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
});

export default MapMediaDialog;
