import { ArrowBackIosNew, ContentCopy } from "@mui/icons-material";
import {
    Box,
    Card,
    CardContent,
    CardMedia,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    Paper,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { formatDate, validateNumber } from "../../helper";
import { Label } from "../../pages/customers/components/Info";
import { setGlobalToast } from "../../redux/actions/helperActions";

const ImageDetails = ({ data, onClose }) => {
    let { date } = data || {};
    let src = data?.media_details?.sizes?.medium_large?.source_url;
    let link = data?.source_url;
    let name = data?.title?.rendered;
    let size = validateNumber(data?.media_details?.filesize);
    const dispatch = useDispatch();
    return (
        <>
            <Stack
                component={Paper}
                direction={"row"}
                gap={1}
                alignItems="center"

                sx={{
                    boxShadow: "rgba(0, 0, 0, 0.05) 0px 1px 2px 0px",
                    padding: "6px 24px",
                    position: "sticky",
                    top: 0,
                    zIndex: 10,
                    borderRadius: 0,
                    cursor: "pointer",
                }}
            >
                <IconButton onClick={() => onClose && onClose()}>
                    <ArrowBackIosNew />
                </IconButton>

                <Typography variant="h6">Attachment Details</Typography>
            </Stack>
            <Box p={3}>
                <Grid container spacing={8} rowSpacing={5}>
                    <Grid item md={12}>
                        <Stack
                            direction={"row"}
                            alignItems={"center"}
                            justifyContent={"space-between"}
                        >
                            <Typography variant="h6">File name : {name}</Typography>
                            <Stack direction={"row"} alignItems={"center"} gap={3}>
                                <Typography variant="h6">
                                    {" "}
                                    {Number(parseFloat(size / 1024 / 1024).toFixed(2))} mb
                                </Typography>
                                <Typography variant="h6">
                                    {" "}
                                    {formatDate(new Date(date), "dd:mm:yyyy hh:mm")}
                                </Typography>
                            </Stack>
                        </Stack>
                    </Grid>
                    <Grid item md={5}>
                        <Card>
                            <CardMedia component={"img"} image={src} />
                        </Card>
                        <CardContent
                            sx={{
                                px: 2,
                                ":last-child": {
                                    pb: 4,
                                },
                            }}
                        >

                        </CardContent>
                    </Grid>
                    <Grid item md={7}>
                        <Stack rowGap={2}>
                            {detailsArray?.map((obj) => (
                                <Stack>
                                    <Label>{obj?.label}</Label>
                                    <TextField
                                        fullWidth
                                        value={obj?.value ? obj?.value(data) : ""}
                                        size={"small"}
                                        {...obj?.props}
                                    />
                                    {obj?.helperText && (
                                        <FormHelperText>{obj?.helperText}</FormHelperText>
                                    )}
                                </Stack>
                            ))}
                            <Stack>
                                <Label>File URL</Label>
                                <TextField
                                    fullWidth
                                    value={link}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={() => {
                                                        navigator.clipboard.writeText(link);
                                                        dispatch(
                                                            setGlobalToast({
                                                                show: true,
                                                                message: "Link copied on clipboard",
                                                                severity: "success",
                                                            })
                                                        );
                                                    }}
                                                >
                                                    <ContentCopy />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Stack>

                        </Stack>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

export default ImageDetails;

const detailsArray = [
    {
        label: "Alternative text",
        props: {},
        value: (obj) => obj?.alt_text,
        helperText:
            "Describe the purpose of the image. Leave if the image is decorative",
    },
    { label: "Title", props: {}, value: (obj) => obj?.title?.rendered },
    {
        label: "Caption",
        props: {
            multiline: true,
            rows: 2,
            value: (obj) => obj?.caption?.rendered
        },
    },
    {
        label: "Description ",
        props: {
            multiline: true,
            rows: 2,
            value: (obj) => extractTextFromHtml(obj?.description?.rendered || ""),
        },
    },
];
function extractTextFromHtml(htmlString) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, "text/html");
    return doc.body.textContent || "";
}
export let data = {
    id: 71685,
    date: "2023-04-11T12:42:48",
    date_gmt: "2023-04-11T07:12:48",
    guid: {
        rendered:
            "https://tiarabytj.com/wp-content/uploads/sites/27/2023/04/brooke-cagle-kElEigko7PU-unsplash.jpg",
    },
    modified: "2023-04-11T12:42:48",
    modified_gmt: "2023-04-11T07:12:48",
    slug: "brooke-cagle-keleigko7pu-unsplash-2",
    status: "inherit",
    type: "attachment",
    link: "https://tiarabytj.com/brooke-cagle-keleigko7pu-unsplash-2/",
    title: {
        rendered: "brooke-cagle-kElEigko7PU-unsplash",
    },
    author: 702,
    comment_status: "open",
    ping_status: "closed",
    template: "",
    meta: [],
    smush: {
        stats: {
            percent: 0.89721481819232796,
            bytes: 11098,
            size_before: 1236939,
            size_after: 1225841,
            time: 1.6199999999999999,
            api_version: "1.0",
            lossy: true,
            keep_exif: 0,
        },
        sizes: {
            thumbnail: {
                percent: 6.6699999999999999,
                bytes: 433,
                size_before: 6494,
                size_after: 6061,
                time: 0.02,
            },
            medium: {
                percent: 3.8500000000000001,
                bytes: 671,
                size_before: 17444,
                size_after: 16773,
                time: 0.029999999999999999,
            },
            woocommerce_thumbnail: {
                percent: 2.4500000000000002,
                bytes: 1173,
                size_before: 47960,
                size_after: 46787,
                time: 0.050000000000000003,
            },
            medium_large: {
                percent: 1.8799999999999999,
                bytes: 1289,
                size_before: 68618,
                size_after: 67329,
                time: 0.050000000000000003,
            },
            large: {
                percent: 1.5700000000000001,
                bytes: 1682,
                size_before: 107414,
                size_after: 105732,
                time: 0.10000000000000001,
            },
            full: {
                percent: 0,
                bytes: 0,
                size_before: 320156,
                size_after: 320156,
                time: 0.48999999999999999,
            },
            woodmart_shop_catalog_x2: {
                percent: 1.29,
                bytes: 1793,
                size_before: 138462,
                size_after: 136669,
                time: 0.19,
            },
            "1536x1536": {
                percent: 1.0800000000000001,
                bytes: 2232,
                size_before: 206932,
                size_after: 204700,
                time: 0.20999999999999999,
            },
            "2048x2048": {
                percent: 0.56000000000000005,
                bytes: 1825,
                size_before: 323459,
                size_after: 321634,
                time: 0.47999999999999998,
            },
        },
    },
    description: {
        rendered:
            '<p class="attachment"><a href=\'https://tiarabytj.com/wp-content/uploads/sites/27/2023/04/brooke-cagle-kElEigko7PU-unsplash.jpg\'><img width="300" height="300" src="https://tiarabytj.com/wp-content/uploads/sites/27/2023/04/brooke-cagle-kElEigko7PU-unsplash-10x10.jpg" class="attachment-medium size-medium wd-lazy-load woodmart-lazy-load wd-lazy-blur" alt="" decoding="async" loading="lazy" srcset="" sizes="(max-width: 300px) 100vw, 300px" data-wood-src="https://tiarabytj.com/wp-content/uploads/sites/27/2023/04/brooke-cagle-kElEigko7PU-unsplash-300x300.jpg" data-srcset="https://tiarabytj.com/wp-content/uploads/sites/27/2023/04/brooke-cagle-kElEigko7PU-unsplash-300x300.jpg 300w, https://tiarabytj.com/wp-content/uploads/sites/27/2023/04/brooke-cagle-kElEigko7PU-unsplash-1024x1024.jpg 1024w, https://tiarabytj.com/wp-content/uploads/sites/27/2023/04/brooke-cagle-kElEigko7PU-unsplash-150x150.jpg 150w, https://tiarabytj.com/wp-content/uploads/sites/27/2023/04/brooke-cagle-kElEigko7PU-unsplash-768x768.jpg 768w, https://tiarabytj.com/wp-content/uploads/sites/27/2023/04/brooke-cagle-kElEigko7PU-unsplash-1536x1536.jpg 1536w, https://tiarabytj.com/wp-content/uploads/sites/27/2023/04/brooke-cagle-kElEigko7PU-unsplash-2048x2048.jpg 2048w, https://tiarabytj.com/wp-content/uploads/sites/27/2023/04/brooke-cagle-kElEigko7PU-unsplash-1200x1200.jpg 1200w, https://tiarabytj.com/wp-content/uploads/sites/27/2023/04/brooke-cagle-kElEigko7PU-unsplash-600x600.jpg 600w" /></a></p>\n',
    },
    caption: {
        rendered: "",
    },
    alt_text: "",
    media_type: "image",
    mime_type: "image/jpeg",
    media_details: {
        width: 2237,
        height: 2237,
        file: "2023/04/brooke-cagle-kElEigko7PU-unsplash.jpg",
        filesize: 320156,
        sizes: {
            medium: {
                file: "brooke-cagle-kElEigko7PU-unsplash-300x300.jpg",
                width: 300,
                height: 300,
                filesize: 17444,
                mime_type: "image/jpeg",
                source_url:
                    "https://tiarabytj.com/wp-content/uploads/sites/27/2023/04/brooke-cagle-kElEigko7PU-unsplash-300x300.jpg",
            },
            large: {
                file: "brooke-cagle-kElEigko7PU-unsplash-1024x1024.jpg",
                width: 1024,
                height: 1024,
                filesize: 107414,
                mime_type: "image/jpeg",
                source_url:
                    "https://tiarabytj.com/wp-content/uploads/sites/27/2023/04/brooke-cagle-kElEigko7PU-unsplash-1024x1024.jpg",
            },
            thumbnail: {
                file: "brooke-cagle-kElEigko7PU-unsplash-150x150.jpg",
                width: 150,
                height: 150,
                filesize: 6494,
                mime_type: "image/jpeg",
                source_url:
                    "https://tiarabytj.com/wp-content/uploads/sites/27/2023/04/brooke-cagle-kElEigko7PU-unsplash-150x150.jpg",
            },
            medium_large: {
                file: "brooke-cagle-kElEigko7PU-unsplash-768x768.jpg",
                width: 768,
                height: 768,
                filesize: 68618,
                mime_type: "image/jpeg",
                source_url:
                    "https://tiarabytj.com/wp-content/uploads/sites/27/2023/04/brooke-cagle-kElEigko7PU-unsplash-768x768.jpg",
            },
            "1536x1536": {
                file: "brooke-cagle-kElEigko7PU-unsplash-1536x1536.jpg",
                width: 1536,
                height: 1536,
                filesize: 206932,
                mime_type: "image/jpeg",
                source_url:
                    "https://tiarabytj.com/wp-content/uploads/sites/27/2023/04/brooke-cagle-kElEigko7PU-unsplash-1536x1536.jpg",
            },
            "2048x2048": {
                file: "brooke-cagle-kElEigko7PU-unsplash-2048x2048.jpg",
                width: 2048,
                height: 2048,
                filesize: 323459,
                mime_type: "image/jpeg",
                source_url:
                    "https://tiarabytj.com/wp-content/uploads/sites/27/2023/04/brooke-cagle-kElEigko7PU-unsplash-2048x2048.jpg",
            },
            woodmart_shop_catalog_x2: {
                file: "brooke-cagle-kElEigko7PU-unsplash-1200x1200.jpg",
                width: 1200,
                height: 1200,
                filesize: 138462,
                mime_type: "image/jpeg",
                source_url:
                    "https://tiarabytj.com/wp-content/uploads/sites/27/2023/04/brooke-cagle-kElEigko7PU-unsplash-1200x1200.jpg",
            },
            woocommerce_thumbnail: {
                file: "brooke-cagle-kElEigko7PU-unsplash-600x600.jpg",
                width: 600,
                height: 600,
                filesize: 47960,
                uncropped: false,
                mime_type: "image/jpeg",
                source_url:
                    "https://tiarabytj.com/wp-content/uploads/sites/27/2023/04/brooke-cagle-kElEigko7PU-unsplash-600x600.jpg",
            },
            woocommerce_single: {
                file: "brooke-cagle-kElEigko7PU-unsplash-1200x1200.jpg",
                width: 1200,
                height: 1200,
                filesize: 138462,
                mime_type: "image/jpeg",
                source_url:
                    "https://tiarabytj.com/wp-content/uploads/sites/27/2023/04/brooke-cagle-kElEigko7PU-unsplash-1200x1200.jpg",
            },
            woocommerce_gallery_thumbnail: {
                file: "brooke-cagle-kElEigko7PU-unsplash-150x150.jpg",
                width: 150,
                height: 150,
                filesize: 6494,
                mime_type: "image/jpeg",
                source_url:
                    "https://tiarabytj.com/wp-content/uploads/sites/27/2023/04/brooke-cagle-kElEigko7PU-unsplash-150x150.jpg",
            },
            full: {
                file: "brooke-cagle-kElEigko7PU-unsplash.jpg",
                width: 2237,
                height: 2237,
                mime_type: "image/jpeg",
                source_url:
                    "https://tiarabytj.com/wp-content/uploads/sites/27/2023/04/brooke-cagle-kElEigko7PU-unsplash.jpg",
            },
        },
        image_meta: {
            aperture: "0",
            credit: "",
            camera: "",
            caption: "",
            created_timestamp: "0",
            copyright: "",
            focal_length: "0",
            iso: "0",
            shutter_speed: "0",
            title: "",
            orientation: "0",
            keywords: [],
        },
    },
    post: null,
    source_url:
        "https://tiarabytj.com/wp-content/uploads/sites/27/2023/04/brooke-cagle-kElEigko7PU-unsplash.jpg",
    _links: {
        self: [
            {
                href: "https://tiarabytj.com/wp-json/wp/v2/media/71685",
            },
        ],
        collection: [
            {
                href: "https://tiarabytj.com/wp-json/wp/v2/media",
            },
        ],
        about: [
            {
                href: "https://tiarabytj.com/wp-json/wp/v2/types/attachment",
            },
        ],
        author: [
            {
                embeddable: true,
                href: "https://tiarabytj.com/wp-json/wp/v2/users/702",
            },
        ],
        replies: [
            {
                embeddable: true,
                href: "https://tiarabytj.com/wp-json/wp/v2/comments?post=71685",
            },
        ],
    },
};
