import {
  Box,
  Checkbox,
  FormHelperText,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import { useState } from "react";
import axios from "axios";
import { getToken, getWebsite, isObjWithValues, } from "../../../../helper";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import Switch from "rc-switch";
import "rc-switch/assets/index.css";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { EDIT_SETTINGS } from "../../../../redux/actions/settingActions";
import { setGlobalToast } from "../../../../redux/actions/helperActions";
const EaseBuzzForm = () => {
  const woocommerce_checkout_page_id = useSelector(
    (state) => state.settings.woocommerce_checkout_page_id
  );

  console.log(woocommerce_checkout_page_id);
  const [formData, setFormData] = useState({
    enabled: "no",
    title: "",
    description: "",
    key_id: "",
    key_secret: "",
    test_mode: "",
    redirect_page: woocommerce_checkout_page_id || "",
    redirect_fail_page: woocommerce_checkout_page_id || "",
    show_logo: "",
    enable_iframe: "",
  });
  const dispatch = useDispatch();
  const [visibility, setVisibility] = useState({
    key_id: false,
    key_secret: false,
  });
  const [loading, setLoading] = useState(false);
  const woocommercePayeasebuzzSettings = useSelector(
    (state) => state.settings.woocommercePayeasebuzzSettings
  );

  const theme = useTheme();
  let backgroundColor = theme.palette.primary && theme.palette.primary.main;
  const isPhone = useMediaQuery(theme.breakpoints.down("sm"));
  console.log(woocommercePayeasebuzzSettings);
  const globalToast = useSelector((state) => state.helper.globalToast);
  useEffect(() => {
    if (isObjWithValues(woocommercePayeasebuzzSettings))
      setFormData({ ...woocommercePayeasebuzzSettings });
  }, [woocommercePayeasebuzzSettings]);

  const onFormEdit = (payload) => {
    setFormData((pre) => ({ ...pre, ...payload }));
  };
  const onUpdateForm = async () => {
    let website = getWebsite();
    let token = getToken();
    if (!website && !token) return false
    setLoading(true);
    let res = await axios({
      url: `${website}/wp-json/store/v1/settings/woocommerce_payeasebuzz_settings`,
      method: "PUT",
      headers: {
        Authorization: `Basic ${token}`,
      },
      data: {
        setting: "woocommerce_payeasebuzz_settings",
        data: formData,
      },
    });
    if (res.data.success) {
      dispatch(setGlobalToast({ ...globalToast, show: true, message: "EaseBuzz setting saved successfully", severity: "success" }))
      dispatch({
        type: EDIT_SETTINGS,
        payload: {
          woocommerceCashfree_settings: res.data.data,
        },
      });
    } else {
      dispatch(setGlobalToast({ ...globalToast, show: true, message: "Intregration Failed! Please try again.", severity: "error" }))
    }
    setLoading(false);
  };
  return (
    <Box sx={{ paddingLeft: "12px", paddingBottom: "25px" }}>
      <Stack
        direction={"row"}
        alignItems="center"
        sx={{ gap: "14px", mb: 2 }}
        justifyContent="space-between"
      >
        <Stack direction={"row"} alignItems="center" sx={{ gap: "14px" }}>
          <Typography variant="h6">Enable /Disabled</Typography>
          <Switch
            onChange={(e) => onFormEdit({ enabled: e ? "yes" : "no" })}
            checked={formData.enabled === "yes"}
            style={
              formData.enabled === "yes"
                ? {
                  border: `1px solid ${backgroundColor}`,
                  backgroundColor,
                }
                : {}
            }
          />
        </Stack>
        <LoadingButton
          loading={loading}
          variant={"contained"}
          onClick={onUpdateForm}
          sx={{ mr: 4 }}
        >
          Update
        </LoadingButton>
      </Stack>
      <Stack spacing={5} sx={{ width: isPhone ? "100%" : "70%" }}>
        <Stack>
          <Typography variant="h6">Title</Typography>
          <TextField
            value={formData.title}
            onChange={(e) => onFormEdit({ title: e.target.value })}
            size="small"
            placeholder="Title"
          />
          <FormHelperText>This controls the title which the user sees during checkout</FormHelperText>
        </Stack>
        <Stack>
          <Typography variant="h6">Description</Typography>
          <TextField
            value={formData.description}
            onChange={(e) => onFormEdit({ description: e.target.value })}
            size="small"
            placeholder="Description"

            multiline
            rows={3}
          />
          <FormHelperText>This controls the description which the user sees during checkout</FormHelperText>
        </Stack>

        <Stack>
          <Typography variant="h6">Merchant Key</Typography>
          <TextField
            type={visibility.key_id ? "text" : "password"}
            value={formData.key_id}
            onChange={(e) => onFormEdit({ key_id: e.target.value })}
            size="small"
            placeholder="Key ID"

            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    // aria-label="toggle password visibility"
                    onClick={() =>
                      setVisibility((pre) => ({
                        ...pre,
                        key_id: !visibility.key_id,
                      }))
                    }
                    // onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {visibility.key_id ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <FormHelperText>Given to Merchant by Easebuzz</FormHelperText>
        </Stack>
        <Stack>
          <Typography variant="h6">Merchant Salt </Typography>
          <TextField
            type={visibility.key_secret ? "text" : "password"}
            value={formData.key_secret}
            onChange={(e) => onFormEdit({ key_secret: e.target.value })}
            size="small"
            placeholder="Key Secret"

            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    // aria-label="toggle password visibility"
                    onClick={() =>
                      setVisibility((pre) => ({
                        ...pre,
                        key_secret: !visibility.key_secret,
                      }))
                    }
                    // onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {visibility.key_secret ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <FormHelperText>Given to Merchant by Easebuzz</FormHelperText>
        </Stack>
        <Stack>
          <Stack
            direction={"row"}
            alignItems="center"
            justifyContent={"space-between"}
            sx={{ gap: "14px" }}
          >
            <Typography variant="h6">Show Logo</Typography>
            <Switch
              onChange={(e) => onFormEdit({ show_logo: e ? "yes" : "no" })}
              checked={formData.show_logo === "yes"}
              style={
                formData.show_logo === "yes"
                  ? {
                    border: `1px solid ${backgroundColor}`,
                    backgroundColor,
                  }
                  : {}
              }
            />
          </Stack>
          <FormHelperText>
            Show easebuzz logo on checkout page
          </FormHelperText>
        </Stack>
        <Stack>
          <Stack
            direction={"row"}
            alignItems="center"
            justifyContent={"space-between"}
            sx={{ gap: "14px" }}
          >
            <Typography variant="h6">Enable Iframe</Typography>
            <Switch
              onChange={(e) => onFormEdit({ enable_iframe: e ? "yes" : "no" })}
              checked={formData.enable_iframe === "yes"}
              style={
                formData.enable_iframe === "yes"
                  ? {
                    border: `1px solid ${backgroundColor}`,
                    backgroundColor,
                  }
                  : {}
              }
            />
          </Stack>
          <FormHelperText>
            EaseBuzz In Context can be used to accept payments without redirection.
          </FormHelperText>
        </Stack>
        <Stack>
          <Typography variant="h6">Gateway Mode</Typography>
          <Select value={formData.test_mode} onChange={(e) => onFormEdit({ test_mode: e.target.value })} size="small">
            <MenuItem value="test">Test Mode</MenuItem>
            <MenuItem value="prod">Live Mode</MenuItem>
          </Select>
          <FormHelperText>Mode of Easebuzz activities</FormHelperText>
        </Stack>

      </Stack>
    </Box>
  );
};

export default EaseBuzzForm;
