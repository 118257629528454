import { MoreVert, OpenInNew, Person, Widgets } from "@mui/icons-material";
import {
  Alert,
  Autocomplete,
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  Menu,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Base64,
  catalogQuotationLink,
  dialogStyle,
  formatDate,
  getAxiosError,
  getComparator,
  getRelativeTimeDifference,
  getRemainingTime,
  getStoreId,
  getToken,
  getWebsite,
  isArrayWithValues,
  isDev,
  isObjWithValues,
  localHostLink,
  reduceLargeText,
  ResponsiveStackRow,
  stableSort,
  StackRow,
  validateNumber,
} from "../../helper";
import {
  catalogCardStyle,
  catalogCustomerHeadCells,
  catalogDateFormat,
  CatalogSentRequestChartOptions,
  catalogsSentHeadCells,
  catalogsSentHeadCellsCollapsed,
  IconValue,
  recentRequestHeadCells,
  SectionLabel,
  TableLabel,
  TableSecondaryLabel,
  TotalCard,
} from "./CatalogHelper";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import { visuallyHidden } from "@mui/utils";
import {
  fetchAllCustomerOfCatalog,
  fetchCatalogs,
  setProductData,
} from "../../redux/actions/productActions";
import {
  ArrowBackIos,
  Favorite,
  FavoriteBorder,
  Share,
  ShareOutlined,
  Visibility,
  VisibilityOutlined,
} from "@material-ui/icons";
import { useNavigate } from "react-router-dom";
import { setGlobalToast } from "../../redux/actions/helperActions";
import { LoadingButton } from "@mui/lab";
import axios from "axios";
import { fetchCustomers } from "../products/AllProducts";
import { set } from "date-fns";
import ReactApexChart from "react-apexcharts";
import { currencySymbols } from "../pricing/Gold";
import { ResponsiveTabaleRow } from "../components/helper";
import { primaryLabel } from "../../helper";

function CatalogsSent() {
  const reduxCatalogs = useSelector((state) => state.product.allCatalogs);
  const fetchedAllCatalogs = useSelector(
    (state) => state.product.fetchedAllCatalogs
  );
  const fetchingCatalogs = useSelector(
    (state) => state.product.fetchingCatalogs
  );
  const catalogExtra = useSelector((state) => state.product.catalogExtra);
  const live_customers = useSelector((state) => state.product.live_customers);
  const access = useSelector((state) => state.user.access);
  const catalogsAccess = useSelector((state) => state.user.catalogsAccess);
  const live_catalogs = useSelector((state) => state.visitor.live_catalogs);
  const [productAccess, setProductAccess] = useState([]);
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const primaryColor = theme?.palette?.primary?.main;

  useEffect(() => {
    if (isArrayWithValues(access?.product)) setProductAccess(access.product);
  }, [access]);

  useEffect(() => {
    if (!fetchedAllCatalogs) dispatch(fetchCatalogs());
  }, []);
  return (
    <Box sx={{ columnGap: "14px" }}>
      <Stack
        direction={"row"}
        alignItems="center"
        justifyContent={"space-between"}
        mb={6}
        flexWrap="wrap"
        display="none"
      >
        <Button
          size="large"
          startIcon={<ArrowBackIos />}
          onClick={() => navigate(-1)}
        >
          Catalogs Sent
        </Button>
        <Stack
          direction={"row"}
          alignItems="center"
          justifyContent={"space-between"}
          spacing={3}
        >
          {/* <Button variant="outlined">View Sent catalogs</Button> */}
          <Button
            variant="outlined"
            onClick={() => navigate("/catalogs/requests")}
          >
            View request catalogs
          </Button>
          <Button variant="contained" onClick={() => navigate("/catalogs")}>
            Go to dashboard
          </Button>
        </Stack>
      </Stack>
      <CatalogStatics catalogExtra={catalogExtra} />
      <CatalogsSentTable />
      <CatalogCustomers />
    </Box>
  );
}

export default CatalogsSent;

const CatalogStatics = ({ catalogExtra }) => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={6}>
            <TotalCard
              title="Catalog requests"
              value={catalogExtra?.totalRequests || 0}
              hideGraph
              values={[
                {
                  title: "Total products",
                  value: catalogExtra?.totalProducts || 0,
                  // prefix: currencySymbols[currency],
                },
                {
                  title: "Avg products per catalog",
                  value:
                    validateNumber(
                      Number(
                        catalogExtra?.avgProductsRequestsPerCatalog
                      ).toFixed(2)
                    ) || 0,
                  // suffix: "g",
                },
              ]}
              // graphData={topGraphs?.plans}
              hideNavigate
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <TotalCard
              title="Catalog sent to contacts"
              value={catalogExtra?.totalSentToCustomers || 0}
              hideGraph
              values={[
                {
                  title: "Customers",
                  value: catalogExtra?.totalSentToCustomers || 0,
                  // prefix: currencySymbols[currency],
                },
                {
                  title: "Leads",
                  value: 0,
                  // suffix: "g",
                },
              ]}
              // graphData={topGraphs?.plans}
              hideNavigate
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <TotalCard
              title="Response received"
              value={catalogExtra.totalResponse}
              hideGraph
              values={[
                {
                  title: "Catalog requests",
                  value: catalogExtra?.totalRequests || 0,
                  // prefix: currencySymbols[currency],
                },
                {
                  title: "Unique products requested",
                  value: catalogExtra?.totalUniqueProductsRequested || 0,
                  // suffix: "g",
                },
              ]}
              // graphData={topGraphs?.plans}
              hideNavigate
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <TotalCard
              title="Catalog requests"
              value={0}
              hideGraph
              values={[
                {
                  title: "Product request qty",
                  value: 0,
                  // prefix: currencySymbols[currency],
                },
                {
                  title: "No response products",
                  value:
                    catalogExtra?.totalProductsSentUnique -
                      catalogExtra?.totalUniqueProductsRequested >
                    0
                      ? catalogExtra?.totalProductsSentUnique -
                        catalogExtra?.totalUniqueProductsRequested
                      : 0,
                  // suffix: "g",
                },
              ]}
              hideNavigate
              // graphData={topGraphs?.plans}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={6}>
        <Card>
          <CardContent>
            <ReactApexChart
              options={CatalogSentRequestChartOptions}
              series={[
                {
                  data: catalogExtra?.sentArrayGraph || [],
                  name: `Catalogs Sent`,
                },
                {
                  data: catalogExtra?.requestArrayGraph || [],
                  name: `Catalogs received`,
                },
              ]}
              type="line"
              height={320}
              hideNavigate
            />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};
export function CatalogsSentTable({ customerId }) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [rows, setRows] = useState([]);
  const [showDelete, setShowDelete] = useState({});
  const [deleting, setDeleting] = useState(false);
  const [cloneFormOpen, setCloneFormOpen] = useState(false);
  const [catalogClone, setCatalogClone] = useState({});
  const [expanded, setExpanded] = useState(false);

  const catalogs = useSelector((state) => state.product.catalogs);
  const live_catalogs = useSelector((state) => state.visitor.live_catalogs);
  const theme = useTheme();
  const dispatch = useDispatch();
  const isDarkTheme = theme.palette && theme.palette.mode === "dark";
  const isIpad = useMediaQuery(theme.breakpoints.down("md"));

  const closeCloneForm = () => setCloneFormOpen(false);

  const openCloneForm = (catalog) => {
    setCatalogClone(catalog);
    setCloneFormOpen(true);
  };

  useEffect(() => {
    if (!isArrayWithValues(catalogs)) return;
    // console.log(catalogs, customerId);
    let _catalogs = [...catalogs];
    if (isObjWithValues(customerId)) {
      _catalogs = _catalogs.filter((i) =>
        i?.customer?.includes(`${customerId}`)
      );
    }
    // console.log(_catalogs, "<<< catalogs");
    let publishCatalogs = _catalogs
      .filter(
        (i) => new Date(i.expiry_date).getTime() - new Date().getTime() > 0
      )
      .sort(
        (a, b) =>
          new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
        // return new Date().getTime() - new Date(a.expiry_date).getTime();
      );
    let expiredCatalogs = _catalogs
      .filter(
        (i) => new Date(i.expiry_date).getTime() - new Date().getTime() < 0
      )
      .sort(
        (a, b) =>
          new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
        // return new Date().getTime() - new Date(a.expiry_date).getTime();
      );

    setRows([...publishCatalogs, ...expiredCatalogs]);
  }, [catalogs, customerId]);

  const closeDelete = () => {
    setShowDelete({});
  };
  const openDelete = (obj) => {
    setShowDelete(obj);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const onDelete = async () => {
    setDeleting(true);
    let itemToDelete = showDelete;
    if (itemToDelete?.id) {
      // console.log(itemToDelete.id);
      let formData = new FormData();
      formData.append("id", itemToDelete.id);
      let website = await getWebsite();
      let token = await getToken();
      try {
        let { data } = await axios({
          url: `${website}/wp-json/store/v1/catalogs?type=delete_catalogue`,
          data: formData,
          method: "POST",
          headers: {
            Authorization: `Basic ${token}`,
          },
        });
        console.log(data, "catalog delete response");
        if (data.success) {
          dispatch(
            setGlobalToast({
              show: true,
              message: "Catalog deleted successfully!",
              severity: "success",
            })
          );
          let catalogList = [...catalogs];
          if (isArrayWithValues(catalogList)) {
            let array = catalogList.filter((i) => i.id !== itemToDelete.id);
            // this.props.changeCatalogData({ catalogList: array });
            dispatch(setProductData({ catalogs: array }));
          }
        }
      } catch (e) {
        dispatch(
          setGlobalToast({
            show: true,
            message: "Couldn't delete product. Try again!",
            severity: "error",
          })
        );
      }
      setDeleting(false);
      closeDelete();
    }
  };
  return (
    <Box sx={{ width: "100%", mt: 5 }}>
      <Paper sx={{ width: "100%", mb: 2, mt: 2, ...catalogCardStyle }}>
        <Stack
          direction="row"
          justifyContent={"space-between"}
          alignItems="center"
          sx={{ px: 2, pt: 3, pb: 2 }}
        >
          <SectionLabel>Catalogs sent</SectionLabel>
          {!isIpad && (
            <Button onClick={() => setExpanded((state) => !state)}>
              Expand
            </Button>
          )}
        </Stack>
        <Toolbar
          sx={{
            pl: { sm: 2 },
            pr: { xs: 1, sm: 1 },
            ...(selected.length > 0 && {
              bgcolor: (theme) =>
                alpha(
                  theme.palette.primary.main,
                  theme.palette.action.activatedOpacity
                ),
            }),
            display: "none",
          }}
        >
          {selected.length > 0 ? (
            <Typography
              sx={{ flex: "1 1 100%" }}
              color="inherit"
              variant="subtitle1"
              component="div"
            >
              {selected.length} selected
            </Typography>
          ) : (
            <Typography
              sx={{ flex: "1 1 100%" }}
              variant="h6"
              id="tableTitle"
              component="div"
            >
              Recent catalog request received
            </Typography>
          )}

          {selected.length > 0 ? (
            <Tooltip title="Delete">
              <IconButton>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title="Filter list">
              <IconButton>
                <FilterListIcon />
              </IconButton>
            </Tooltip>
          )}
        </Toolbar>
        <TableContainer>
          <Table aria-labelledby="tableTitle" size={dense ? "small" : "medium"}>
            {isIpad ? (
              <TableHead>
                {/* <TableRow>
                  <TableCell>Name</TableCell>
                </TableRow> */}
              </TableHead>
            ) : (
              <TableHead>
                <TableRow>
                  {/* <TableCell padding="checkbox">
                  <Checkbox
                    color="primary"
                    indeterminate={
                      selected.length > 0 && selected.length < rows.length
                    }
                    checked={rows.length > 0 && selected.length === rows.length}
                    onChange={handleSelectAllClick}
                    inputProps={{
                      "aria-label": "select all desserts",
                    }}
                  />
                </TableCell> */}
                  {(expanded
                    ? catalogsSentHeadCells
                    : catalogsSentHeadCellsCollapsed
                  ).map((headCell) => (
                    <TableCell
                      key={headCell.id}
                      align={headCell.numeric ? "right" : "left"}
                      // padding={headCell.disablePadding ? "none" : "normal"}
                      padding={"normal"}
                      sortDirection={orderBy === headCell.id ? order : false}
                    >
                      <TableSortLabel
                        active={orderBy === headCell.id}
                        direction={orderBy === headCell.id ? order : "asc"}
                        onClick={createSortHandler(headCell.id)}
                      >
                        {headCell.label}
                        {orderBy === headCell.id ? (
                          <Box component="span" sx={visuallyHidden}>
                            {order === "desc"
                              ? "sorted descending"
                              : "sorted ascending"}
                          </Box>
                        ) : null}
                      </TableSortLabel>
                      {headCell.hasSubheader === 1 && (
                        <Stack
                          direction={"row"}
                          alignItems="center"
                          justifyContent={"space-between"}
                          // px={2}
                        >
                          <Typography sx={{ fontSize: "10px" }}>
                            Total <br />
                            products
                          </Typography>
                          <Typography sx={{ fontSize: "10px" }}>
                            Ready <br />
                            stock
                          </Typography>
                          <Typography sx={{ fontSize: "10px" }}>
                            Make <br />
                            to order
                          </Typography>
                        </Stack>
                      )}
                      {/* {headCell.hasSubheader === 2 && (
                      <Stack
                        direction={"row"}
                        alignItems="center"
                        justifyContent={"space-between"}
                      >
                        <Typography sx={{ fontSize: "10px" }}>
                          Contacts
                        </Typography>
                        <Typography sx={{ fontSize: "10px" }}>
                          Products
                        </Typography>
                      </Stack>
                    )} */}
                    </TableCell>
                  ))}
                </TableRow>
                {/* <TableRow sx={{}}>
                <TableCell
                  align={"left"}
                  // padding={headCell.disablePadding ? "none" : "normal"}
                  padding={"normal"}
                  //   sortDirection={false}
                  colSpan={5}
                ></TableCell>
                <TableCell
                  align={"left"}
                  sx={{ fontSize: "11px", color: "text.secondary" }}
                  // padding={headCell.disablePadding ? "none" : "normal"}
                  padding={"normal"}
                  //   sortDirection={false}
                  colSpan={0.5}
                >
                  Contacts responded
                </TableCell>
                <TableCell
                  sx={{ fontSize: "11px", color: "text.secondary" }}
                  align={"left"}
                  // padding={headCell.disablePadding ? "none" : "normal"}
                  padding={"normal"}
                  //   sortDirection={false}
                  colSpan={0.5}
                >
                  Unique products
                </TableCell>
                <TableCell
                  align={"left"}
                  // padding={headCell.disablePadding ? "none" : "normal"}
                  padding={"normal"}
                  colSpan={1}

                  //   sortDirection={false}
                ></TableCell>
              </TableRow> */}
              </TableHead>
            )}
            {/* <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            /> */}
            <TableBody>
              {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                   rows.sort(getComparator(order, orderBy)).slice() */}
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.name);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <CatalogSentRow
                      key={row.id}
                      row={row}
                      isItemSelected={isItemSelected}
                      labelId={labelId}
                      isDarkTheme={isDarkTheme}
                      setShowDelete={setShowDelete}
                      openCloneForm={openCloneForm}
                      expanded={expanded}
                      live_catalogs={live_catalogs}
                    />
                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {rows.length > 5 && (
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </Paper>
      <RecreateCatalog
        open={cloneFormOpen}
        onClose={closeCloneForm}
        catalogClone={catalogClone}
      />
      <Dialog
        open={isObjWithValues(showDelete)}
        onClose={closeDelete}
        maxWidth="sm"
        fullWidth
        sx={{ ...dialogStyle }}
      >
        <DialogTitle>
          Delete <strong>{showDelete.title}</strong>?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>This action cannot be undone.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDelete}>Cancel</Button>
          <LoadingButton
            loading={deleting}
            color="error"
            onClick={() => onDelete()}
          >
            Delete
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

const RecreateCatalog = ({ open, onClose, catalogClone }) => {
  const [customerSearching, setCustomerSearching] = useState(false);
  const [allCustomers, setAllCustomers] = useState([]);
  const [form, setForm] = useState({ expiry: "", isPublic: false });
  const [expiryError, setExpiryError] = useState("");
  const [selectedCustomers, setSelectedCustomers] = useState([]);
  const [cloning, setCloning] = useState(false);
  const [catalogError, setCatalogError] = useState("");

  let customerSearchTimeout;

  const dispatch = useDispatch();

  const editForm = (payload) => {
    if (catalogError) setCatalogError(false);
    setForm((state) => ({ ...state, ...payload }));
  };

  const onCustomerSearch = async (value, setState) => {
    if (!value || (value && value.length < 2)) return;
    if (customerSearchTimeout) clearTimeout(customerSearchTimeout);

    customerSearchTimeout = setTimeout(async () => {
      setCustomerSearching(true);
      await fetchCustomers(value, setState);
      setCustomerSearching(false);
    }, 500);
  };

  const setUpdatedCustomers = (customers) => {
    if (isArrayWithValues(customers)) {
      let arr = [];
      for (let i = 0; i < customers.length; i++) {
        arr.push({
          label: `${customers[i].first_name} ${customers[i].last_name}`,
          value: customers[i].id,
        });
      }
      setAllCustomers(arr);
    }
  };
  const onCloneCatalog = () => {
    let { title, content, productsData } = catalogClone;
    let { isPublic, expiry } = form;

    let obj = {
      title,
      content,
      // expiry: form.expiry,
      expiry: isPublic ? validateNumber(form.expiry) * 24 : form.expiry,
      products: productsData,
      isPublic: form.isPublic,
      // customers: Array.isArray(selectedCustomers)
      //   ? selectedCustomers.map((i) => i.value)
      //   : customer.split(","),
      // customers: selectedCustomers,
    };
    if (!obj.isPublic)
      obj.customers = selectedCustomers.map((i) => i.value && i.value);
    if (!isPublic)
      if (expiry > 72 || expiry < 1)
        return setCatalogError(
          "Expiry should be minimun 1 to maximum 72 hours"
        );
    if (isPublic)
      if (expiry > 15 || expiry < 1)
        return setCatalogError("Expiry should be minimun 1 to maximum 15 days");
    if (!isPublic) {
      if (!isArrayWithValues(obj.customers))
        return setCatalogError("Please select atleast one customer");
    }
    // console.log(obj);
    // // return;
    setCloning(true);
    let website = getWebsite();
    let token = getToken();
    axios({
      url: `${website}/wp-json/store/v1/catalogs?type=add_catalogue`,
      headers: {
        Authorization: `Basic ${token}`,
        "Content-Type": "application/json",
      },
      method: "POST",
      data: JSON.stringify({ data: { ...obj } }),
    })
      .then((res) => {
        let { data } = res;
        onClose();
        if (data.success) {
          dispatch(fetchCatalogs());
          dispatch(
            setGlobalToast({
              show: true,
              severity: "success",
              message: "Catalog added successfully!",
            })
          );
          // setExpiryFormOpen(false);
        } else throw new Error();
      })
      .catch((e) => {
        console.log(getAxiosError(e));
        dispatch(
          setGlobalToast({
            show: true,
            severity: "error",
            message: "Couldn't add Catalog, try again!",
          })
        );
      })
      .finally(() => {
        setCloning(false);
      });
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      sx={{ ...dialogStyle }}
    >
      <DialogTitle>Recreate Catalog</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please enter expiry time for new catalog
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          label={`Expiry time (in ${form.isPublic ? "days" : "hours"})`}
          type="number"
          fullWidth
          variant="standard"
          value={form.expiry}
          onChange={(e) => editForm({ expiry: e.target.value })}
          error={expiryError ? true : false}
          helperText={
            expiryError
              ? expiryError
              : form.isPublic
              ? "Between 1 to 15 days"
              : `Between 1 to 72 hours`
          }
        />
        <FormControl>
          <Typography mt={3}>Select customer(s)</Typography>

          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            value={form.isPublic}
            onChange={(e) => {
              console.log(
                typeof e.target.value,
                e.target.value,
                Boolean(e.target.value)
              );
              editForm({
                isPublic: e.target.value === "true" ? true : false,
              });
            }}
          >
            <FormControlLabel
              value={false}
              control={<Radio />}
              label="Private"
            />
            <FormControlLabel
              value={true}
              control={<Radio />}
              label={"Public"}
            />
          </RadioGroup>
        </FormControl>
        <Collapse in={!form.isPublic}>
          <>
            <Typography mt={3}>Select customer(s)</Typography>
            <Autocomplete
              multiple
              disablePortal
              id="combo-box-demo"
              options={allCustomers}
              size="small"
              sx={{ mt: 2 }}
              value={selectedCustomers}
              loading={customerSearching}
              openOnFocus
              renderInput={(params) => (
                <TextField
                  {...params}
                  // label="UP SELL"
                  onChange={(e) =>
                    onCustomerSearch(e.target.value, setUpdatedCustomers)
                  }
                />
              )}
              onChange={(e, value) => setSelectedCustomers(value)}
            />
          </>
        </Collapse>
      </DialogContent>
      <Collapse in={catalogError ? true : false}>
        <Alert
          variant="outlined"
          severity="error"
          sx={{ marginTop: "10px", mx: 4 }}
        >
          {catalogError}
        </Alert>
      </Collapse>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <LoadingButton
          loading={cloning}
          disabled={!form.expiry}
          onClick={() => onCloneCatalog()}
        >
          Clone
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

const CatalogSentRow = ({
  row,
  isItemSelected,
  labelId,
  isDarkTheme,
  setShowDelete,
  openCloneForm,
  expanded,
  live_catalogs,
}) => {
  const [expiryTimer, setExpiryTimer] = useState("00 : 00 : 00");
  const [isExpired, setIsExpired] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [imageUrl, setImageUrl] = useState("");
  const [isLive, setIsLive] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const isIpad = useMediaQuery(theme.breakpoints.down("md"));
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  let interval;

  const startInterval = (expiry_date) => {
    if (expiry_date)
      interval = setInterval(() => {
        setExpiryTimer(getRemainingTime(expiry_date));
      }, 1000);
  };
  const endInterval = () => {
    setIsExpired(true);
    if (interval) clearInterval(interval);
  };

  useEffect(() => {
    if (!isObjWithValues(row)) return;
    let { productDetails } = row;
    if (isArrayWithValues(productDetails)) {
      for (let product of productDetails) {
        let { images } = product;
        if (isArrayWithValues(images)) {
          setImageUrl(images[0]?.src);
          break;
        }
      }
    }
  }, [row]);

  useEffect(() => {
    if (isArrayWithValues(live_catalogs))
      setIsLive(Boolean(live_catalogs?.find((i) => i.id == row.id)));
    else setIsLive(false);
  }, [row?.id, live_catalogs]);

  useEffect(() => {
    let { created_at, expiry_date, id, catalogRequests } = row;

    // created_at =
    //   created_at && Platform.OS === "ios"
    //     ? created_at.replaceAll("-", "/")
    //     : created_at;
    // expiry_date = expiry_date.replaceAll("-", "/");
    expiry_date = new Date(expiry_date).getTime() / 1000;
    // getRemainingTime(expiry_date);
    let time = Date.parse(created_at);
    let dateTime = formatDate(new Date(time), "dd:mm:yyyy hh:mm");
    if (interval) clearInterval(interval);
    if (Math.floor(Date.now() / 1000) < expiry_date) {
      startInterval(expiry_date);
      setIsExpired(false);
    } else setIsExpired(true);

    // if(catalog)
    return () => endInterval();
  }, []);

  const onCopyLink = async () => {
    await navigator?.clipboard?.writeText(row.link);
    if (navigator?.clipboard?.writeText)
      dispatch(
        setGlobalToast({
          show: true,
          message: "Catalog link copied to clipboard",
          severity: "success",
        })
      );
    else
      dispatch(
        setGlobalToast({
          show: true,
          message: "Couldn't copy!",
          severity: "error",
        })
      );
    handleClose();
  };

  const onCatalogLinkPress = (row) => {
    let encr = Base64.btoa(
      JSON.stringify({
        catalog_id: row.id,
        store_id: getStoreId(),
        // customer_id:
      })
    );
    window.open(
      isDev() ? `${catalogQuotationLink}/catalogs/${encr}` : row.link,
      "_blank"
    );
  };

  return (
    <>
      {isIpad ? (
        <ResponsiveTabaleRow
          title={reduceLargeText(row.title, 20)}
          titleProps={{ onClick: () => navigate(`/catalogs/${row.id}`) }}
          src={imageUrl || "/static/img/woocommerce_placeholder.png"}
          avatarProps={{ onClick: () => navigate(`/catalogs/${row.id}`) }}
          subTitle2={
            <>
              <TableLabel
                sx={{
                  whiteSpace: "nowrap",
                  fontSize: "11px",
                  color: isExpired ? "error.main" : "primary.main",
                }}
              >
                {expiryTimer}
              </TableLabel>

              <Stack direction={"row"} gap={1.5}>
                <StackRow spacing={2}>
                  <TableSecondaryLabel>
                    {formatDate(new Date(row.created_at), "hh:mm am/pm")}
                  </TableSecondaryLabel>
                  <TableSecondaryLabel sx={{ fontWeight: "bold" }}>
                    {formatDate(new Date(row.created_at), catalogDateFormat)}
                  </TableSecondaryLabel>
                  <Button
                    endIcon={<OpenInNew />}
                    size="small"
                    onClick={() => onCatalogLinkPress(row)}
                  >
                    {isIpad ? "Open" : "Open catalog"}
                  </Button>
                </StackRow>
              </Stack>
            </>
          }
          subTitle={
            <Stack direction={"row"} spacing={3}>
              <IconValue
                icon={
                  <Visibility
                    style={{
                      height: "15px",
                      width: "15px",
                      fill: "#818181",
                    }}
                  />
                }
                text={row.totalViews || 0}
                tooltip="Catalog visits"
              />
              {isLive ? (
                <IconValue
                  icon={
                    <Box className="example">
                      <Box className="dot"></Box>
                    </Box>
                    // <Box
                    //   style={{
                    //     height: "7px",
                    //     width: "7px",
                    //     backgroundColor: "#6ea365",
                    //     borderRadius: "50%",
                    //     mr: 1,
                    //   }}
                    // />
                  }
                  text={"Live" || 0}
                />
              ) : null}
            </Stack>
          }
          chipLabel={row.is_public ? "Public" : "Private"}
          chipProps={{
            size: "small",
            mr: 1,
            mb: 1,

            sx: {
              // color: statusFontColor(row.status),
              color: !row.is_public ? "#5570bc" : "#1aae6f",
              border: isDarkTheme ? "0.7px solid" : "0px solid",
              // borderColor: statusFontColor(row.status),
              backgroundColor: isDarkTheme
                ? "transparent"
                : !row.is_public
                ? "#EAECFB"
                : "#e0f6ed",
            },
            variant: "outlined",
          }}
          menu={
            <IconButton onClick={handleClick} size="small">
              <MoreVert />
            </IconButton>
          }
          accordionObject={[
            { key: "To", value: row.totalSentToCustomers },
            { key: "Prodcuts", value: row.products },
            { key: "Response from", value: row.totalRequests },

            { key: "Total Prodcuts", value: row.totalProductsInRequests },
            { key: "Ready stock", value: row.totalInStockInRequests },
            {
              key: "Make to order",
              value: row.totalMakeToOrderInRequestsPercent,
            },
            { key: "Requests", value: row.totalProductRequest },
          ]}
        />
      ) : (
        <TableRow
          hover
          // onClick={(event) => handleClick(event, row.name)}
          //   role="checkbox"
          aria-checked={isItemSelected}
          tabIndex={-1}
          key={row.name}
          selected={isItemSelected}
        >
          {/* <TableCell padding="checkbox">
    <Checkbox
      color="primary"
      checked={isItemSelected}
      inputProps={{
        "aria-labelledby": labelId,
      }}
    />
  </TableCell> */}
          <TableCell
            component="th"
            id={labelId}
            scope="row"
            // padding="none"
          >
            <Stack direction="row" alignItems={"center"}>
              <img
                src={imageUrl || "/static/img/woocommerce_placeholder.png"}
                style={{
                  height: "50px",
                  width: "50px",
                  borderRadius: "50%",
                }}
                onClick={() => window.open(`${row.link}`, "_blank")}
              />
              <Box sx={{ ml: 1.5 }}>
                <Stack direction="row" alignItems={"center"} spacing={3} mt={1}>
                  <Typography
                    sx={{
                      fontSize: "13px",
                      fontWeight: "600",
                      cursor: "pointer",
                    }}
                    onClick={() => navigate(`/catalogs/${row.id}`)}
                  >
                    {reduceLargeText(row.title, 20)}
                  </Typography>
                </Stack>
                <Stack direction="row" alignItems={"center"} spacing={3} mt={1}>
                  {/* <IconValue
                icon={
                  <Favorite
                    style={{
                      height: "13px",
                      width: "13px",
                      fill: "#bb6666",
                    }}
                  />
                }
                text={"0"}
              /> */}
                  <IconValue
                    icon={
                      <Visibility
                        style={{
                          height: "15px",
                          width: "15px",
                          fill: "#818181",
                        }}
                      />
                    }
                    text={row.totalViews || 0}
                    tooltip="Catalog visits"
                  />
                  {isLive ? (
                    <IconValue
                      icon={
                        <Box className="example">
                          <Box className="dot"></Box>
                        </Box>
                        // <Box
                        //   style={{
                        //     height: "7px",
                        //     width: "7px",
                        //     backgroundColor: "#6ea365",
                        //     borderRadius: "50%",
                        //     mr: 1,
                        //   }}
                        // />
                      }
                      text={"Live" || 0}
                    />
                  ) : null}
                  {/* <IconValue
                icon={
                  <Share
                    style={{
                      height: "13px",
                      width: "13px",
                      fill: "#35557f",
                    }}
                  />
                }
                text={"0"}
              /> */}
                </Stack>
              </Box>
            </Stack>
          </TableCell>
          <TableCell align="left">
            <Chip
              size="small"
              mr={1}
              mb={1}
              label={row.is_public ? "Public" : "Private"}
              // color="warning"
              sx={{
                // color: statusFontColor(row.status),
                color: !row.is_public ? "#5570bc" : "#1aae6f",
                border: isDarkTheme ? "0.7px solid" : "0px solid",
                // borderColor: statusFontColor(row.status),
                backgroundColor: isDarkTheme
                  ? "transparent"
                  : !row.is_public
                  ? "#EAECFB"
                  : "#e0f6ed",
              }}
              variant="outlined"
            />
            <TableLabel
              sx={{
                whiteSpace: "nowrap",
                fontSize: "13px",
                color: isExpired ? "error.main" : "primary.main",
              }}
            >
              {expiryTimer}
            </TableLabel>
          </TableCell>
          <TableCell align="left">
            <TableSecondaryLabel sx={{ fontWeight: "bold" }}>
              {formatDate(new Date(row.created_at), catalogDateFormat)}
            </TableSecondaryLabel>
            <TableSecondaryLabel>
              {formatDate(new Date(row.created_at), "hh:mm am/pm")}
            </TableSecondaryLabel>
          </TableCell>
          <TableCell align="left">
            <Tooltip
              title={
                <>
                  <div>
                    Customers: <strong>0</strong>
                  </div>
                  <div>
                    Leads: <strong>0</strong>
                  </div>
                </>
              }
            >
              <TableLabel>
                {row.totalSentToCustomers}{" "}
                <TableSecondaryLabel variant="caption">
                  Contacts
                </TableSecondaryLabel>
              </TableLabel>
            </Tooltip>
          </TableCell>
          <TableCell align="right">
            <TableLabel style={{ fontSize: "18px" }}>{row.products}</TableLabel>
          </TableCell>
          <TableCell align="left">
            <TableSecondaryLabel>
              <Tooltip
                title={
                  <>
                    <div>
                      Customers: <strong>0</strong>
                    </div>
                    <div>
                      Leads: <strong>0</strong>
                    </div>
                  </>
                }
              >
                <TableLabel>
                  {row.totalRequests}{" "}
                  <TableSecondaryLabel variant="caption">
                    Contacts
                  </TableSecondaryLabel>
                </TableLabel>
              </Tooltip>
            </TableSecondaryLabel>
          </TableCell>
          {expanded ? (
            <>
              <TableCell align="left">
                <Stack
                  direction={"row"}
                  alignItems="center"
                  justifyContent={"space-between"}
                  px={2}
                >
                  <Box>
                    <TableLabel>{row.totalProductsInRequests}</TableLabel>
                    <TableSecondaryLabel>
                      Unique: {row.uniqueProductsRequested}
                    </TableSecondaryLabel>
                  </Box>
                  <Box>
                    <TableLabel>{row.totalInStockInRequests}</TableLabel>
                    <TableSecondaryLabel>
                      {row.totalInStockInRequestsPercent}%
                    </TableSecondaryLabel>
                  </Box>
                  <Box>
                    <TableLabel>{row.totalMakeToOrderInRequests}</TableLabel>
                    <TableSecondaryLabel>
                      {row.totalMakeToOrderInRequestsPercent}%
                    </TableSecondaryLabel>
                  </Box>
                </Stack>
              </TableCell>
              <TableCell align="left">
                {/* <Stack
          direction={"row"}
          alignItems="center"
          justifyContent={"space-between"}
        >
          <Box>
            <TableLabel>23</TableLabel>
          </Box>
        </Stack> */}
                <Box>
                  <TableLabel>{row.totalProductRequest}</TableLabel>
                </Box>
                {/* <TableSecondaryLabel>
      Total products: <strong>35</strong>
    </TableSecondaryLabel> */}
                <TableSecondaryLabel>
                  Per contact:{" "}
                  <strong>{row.totalProductRequestPerContact}</strong>
                </TableSecondaryLabel>
              </TableCell>
            </>
          ) : null}

          <TableCell align="right">
            <IconButton onClick={handleClick} size="small">
              <MoreVert />
            </IconButton>
            <IconButton
              // onClick={() => window.open(`${row.link}`, "_blank")}
              onClick={() => onCatalogLinkPress(row)}
              size="small"
            >
              <OpenInNew />
            </IconButton>
          </TableCell>
        </TableRow>
      )}
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={() => navigate(`/catalogs/${row.id}`)}>
          View
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            setShowDelete(row);
          }}
        >
          Delete
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            openCloneForm(row);
          }}
        >
          Recreate
        </MenuItem>
        <MenuItem onClick={onCopyLink}>Copy Link</MenuItem>
      </Menu>
    </>
  );
};

const CatalogCustomers = () => {
  let [catalog, setCatalog] = useState({});
  let [requests, setRequests] = useState([]);
  const catalogs = useSelector((state) => state.product.catalogs);
  const catalog_requests = useSelector(
    (state) => state.product.catalog_requests
  );
  const [catalogDropdown, setCatalogDropdown] = useState([]);
  const [selectedCatalog, setSelectedCatalog] = useState("");
  const [expiryTimer, setExpiryTimer] = useState("00 : 00 : 00");
  const [isExpired, setIsExpired] = useState(false);
  const [imageUrl, setImageUrl] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();
  let interval;

  useEffect(() => {
    if (!isObjWithValues(catalog)) return;
    let { productDetails } = catalog;
    if (isArrayWithValues(productDetails)) {
      for (let product of productDetails) {
        let { images } = product;
        if (isArrayWithValues(images)) {
          setImageUrl(images[0]?.src);
          break;
        }
      }
    }
  }, [catalog]);

  const startInterval = (expiry_date) => {
    if (expiry_date)
      interval = setInterval(() => {
        setExpiryTimer(getRemainingTime(expiry_date));
      }, 1000);
  };
  const endInterval = () => {
    setIsExpired(true);
    if (interval) clearInterval(interval);
    setExpiryTimer("00 : 00 : 00");
  };

  useEffect(() => {
    let { created_at, expiry_date, id, catalogRequests } = { ...catalog };

    // created_at =
    //   created_at && Platform.OS === "ios"
    //     ? created_at.replaceAll("-", "/")
    //     : created_at;
    // expiry_date = expiry_date.replaceAll("-", "/");
    expiry_date = new Date(expiry_date).getTime() / 1000;
    // getRemainingTime(expiry_date);
    let time = Date.parse(created_at);
    let dateTime = formatDate(new Date(time), "dd:mm:yyyy hh:mm");
    if (interval) clearInterval(interval);
    if (Math.floor(Date.now() / 1000) < expiry_date) startInterval(expiry_date);
    else endInterval();

    // if(catalog)
    return () => endInterval();
  }, [catalog]);

  useEffect(() => {
    if (!isArrayWithValues(catalogs)) return;
    if (!isObjWithValues(catalog)) {
      setCatalog(catalogs[0]);
      setSelectedCatalog(catalogs[0].id);
      if (
        !catalogs[0].allCustomersFetched &&
        !isArrayWithValues(catalog.allCustomerDetails)
      )
        dispatch(fetchAllCustomerOfCatalog(catalogs[0].id));
    }
    if (!isArrayWithValues(catalogDropdown))
      setCatalogDropdown(
        catalogs.map((i) => ({ label: i.title, value: i.id }))
      );
  }, [catalogs]);

  useEffect(() => {
    if (!isArrayWithValues(catalog_requests)) return;
    if (!isObjWithValues(catalog)) return setRequests([]);
    let requests = [];
    for (let request of catalog_requests) {
      if (catalog.id == request.post_id && request.products)
        requests.push(request);
    }
    setRequests(requests);
    if (
      isObjWithValues(catalog) &&
      !catalog.allCustomersFetched &&
      !isArrayWithValues(catalog.allCustomerDetails)
    )
      dispatch(fetchAllCustomerOfCatalog(catalog.id));
  }, [catalog]);
  // console.log(catalog);

  const onCatalogChange = (id) => {
    setSelectedCatalog(id);
    let allCatalogs = [...catalogs];
    let catalog = [...(allCatalogs || [])]?.find((i) => i.id == id);
    setCatalog(catalog || {});
  };

  return (
    <>
      <Stack
        direction="row"
        justifyContent={"space-between"}
        alignItems="center"
        sx={{ my: 6 }}
        flexWrap="wrap"
        gap="14px"
      >
        {/* <FormControl
          size="small"
          sx={{ maxWidth: "300px", alignSelf: "flex-end" }}
        >
          <InputLabel id="demo-simple-select-label">
            Selected Catalog
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selectedCatalog}
            label="Selected Catalog"
            onChange={(e) => onCatalogChange(e.target.value)}
            sx={{}}
            MenuProps={{ sx: { maxHeight: "300px" } }}
          >
            {catalogDropdown.map((i) => {
              return <MenuItem value={i.value}>{i.label}</MenuItem>;
            })}
          </Select>
        </FormControl> */}
        <ResponsiveStackRow
          firstElement={<SectionLabel>Catalog sent to contact</SectionLabel>}
          secondElement={
            <FormControl
              size="small"
              sx={{
                minWidth: "200px",
                maxWidth: "300px",
                // alignSelf: "flex-end",
              }}
            >
              <InputLabel id="demo-simple-select-label">
                Selected Catalog
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedCatalog}
                label="Selected Catalog"
                onChange={(e) => onCatalogChange(e.target.value)}
                sx={{}}
                MenuProps={{ sx: { maxHeight: "300px" } }}
              >
                {catalogDropdown.map((i) => {
                  return <MenuItem value={i.value}>{i.label}</MenuItem>;
                })}
              </Select>
            </FormControl>
          }
        />
        {/* <Autocomplete
          disablePortal
          id="combo-box-demo"
          options={catalogDropdown || []}
          sx={{ width: 300 }}
          renderInput={(params) => <TextField {...params} label="Catalogs" />}
          size="small"
        /> */}
      </Stack>
      <Card>
        <CardContent>
          <Stack direction="row" alignItems={"flex-start"}>
            <img
              src={imageUrl || "/static/img/woocommerce_placeholder.png"}
              style={{
                height: "100px",
                width: "100px",
                borderRadius: "5%",
              }}
            />
            <Grid container spacing={3} sx={{ px: 2 }}>
              <Grid item xs={12} sm={6} md={4} lg={3} sx={{ direction: "row" }}>
                <Stack direction="row" alignItems={"flex-start"}>
                  <TableLabel
                    sx={{
                      color: "text.secondary",
                      mr: 1,
                      whiteSpace: "nowrap",
                    }}
                  >
                    Name:{" "}
                  </TableLabel>
                  <TableLabel>{catalog?.title || ""}</TableLabel>
                </Stack>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3} sx={{ direction: "row" }}>
                <Stack direction="row" alignItems={"flex-start"}>
                  <TableLabel
                    sx={{
                      color: "text.secondary",
                      mr: 1,
                      whiteSpace: "nowrap",
                    }}
                  >
                    Sent date:{" "}
                  </TableLabel>
                  <TableLabel>
                    {formatDate(
                      new Date(catalog.created_at),
                      catalogDateFormat
                    )}
                  </TableLabel>
                </Stack>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3} sx={{ direction: "row" }}>
                <Stack direction="row" alignItems={"flex-start"}>
                  <TableLabel
                    sx={{
                      color: "text.secondary",
                      mr: 1,
                      whiteSpace: "nowrap",
                    }}
                  >
                    Sent to:{" "}
                  </TableLabel>
                  <TableLabel>{catalog?.customer?.length || ""}</TableLabel>
                  <Person
                    style={{ marginLeft: "4px", height: "15px", width: "15px" }}
                  />
                </Stack>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3} sx={{ direction: "row" }}>
                <Stack direction="row" alignItems={"flex-start"}>
                  <TableLabel
                    sx={{
                      color: "text.secondary",
                      mr: 1,
                      whiteSpace: "nowrap",
                    }}
                  >
                    Valid for:{" "}
                  </TableLabel>
                  <TableLabel>{expiryTimer}</TableLabel>
                </Stack>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3} sx={{ direction: "row" }}>
                <Stack direction="row" alignItems={"flex-start"}>
                  <TableLabel
                    sx={{
                      color: "text.secondary",
                      mr: 1,
                      whiteSpace: "nowrap",
                    }}
                  >
                    Request received:{" "}
                  </TableLabel>
                  <TableLabel>{0 || "0"}</TableLabel>
                </Stack>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3} sx={{ direction: "row" }}>
                <Stack direction="row" alignItems={"flex-start"}>
                  <TableLabel
                    sx={{
                      color: "text.secondary",
                      mr: 1,
                      whiteSpace: "nowrap",
                    }}
                  >
                    Unique Views:{" "}
                  </TableLabel>
                  <TableLabel>{0 || "0"}</TableLabel>
                </Stack>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3} sx={{ direction: "row" }}>
                <Stack direction="row" alignItems={"flex-start"}>
                  <TableLabel
                    sx={{
                      color: "text.secondary",
                      mr: 1,
                      whiteSpace: "nowrap",
                    }}
                  >
                    Type:{" "}
                  </TableLabel>
                  <TableLabel>{0 || "0"}</TableLabel>
                </Stack>
              </Grid>
            </Grid>
          </Stack>
          <CatalogCustomersTable
            customers={catalog.allCustomerDetails}
            catalog={catalog}
          />
        </CardContent>
      </Card>
    </>
  );
};

// const CatalogSentRequestChart = () => {
//   return (
//     <Card>
//       <CardContent>
//         <ReactApexChart
//           options={CatalogSentRequestChart}
//           series={this.state.series}
//           type="bar"
//           height={350}
//         />
//       </CardContent>
//     </Card>
//   );
// };

export function CatalogCustomersTable({ customers, catalog }) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [rows, setRows] = useState([]);
  const fetchingAllCustomersOfCatalog = useSelector(
    (state) => state.product.fetchingAllCustomersOfCatalog
  );

  const isIpad = useMediaQuery((theme) => theme.breakpoints.down("md"));

  useEffect(() => {
    if (!isArrayWithValues(customers)) return setRows([]);
    setRows(customers);
  }, [customers]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <Box sx={{ width: "100%", mt: 5 }}>
      <SectionLabel>Catalog requests</SectionLabel>
      <Paper sx={{ width: "100%", mb: 2, mt: 2, ...catalogCardStyle }}>
        <Toolbar
          sx={{
            pl: { sm: 2 },
            pr: { xs: 1, sm: 1 },
            ...(selected.length > 0 && {
              bgcolor: (theme) =>
                alpha(
                  theme.palette.primary.main,
                  theme.palette.action.activatedOpacity
                ),
            }),
            display: "none",
          }}
        >
          {selected.length > 0 ? (
            <Typography
              sx={{ flex: "1 1 100%" }}
              color="inherit"
              variant="subtitle1"
              component="div"
            >
              {selected.length} selected
            </Typography>
          ) : (
            <Typography
              sx={{ flex: "1 1 100%" }}
              variant="h6"
              id="tableTitle"
              component="div"
            >
              Recent catalog request received
            </Typography>
          )}

          {selected.length > 0 ? (
            <Tooltip title="Delete">
              <IconButton>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title="Filter list">
              <IconButton>
                <FilterListIcon />
              </IconButton>
            </Tooltip>
          )}
        </Toolbar>
        <TableContainer>
          <Table aria-labelledby="tableTitle" size={dense ? "small" : "medium"}>
            {!isIpad && (
              <TableHead style={{}}>
                <TableRow>
                  {/* <TableCell padding="checkbox">
                    <Checkbox
                      color="primary"
                      indeterminate={
                        selected.length > 0 && selected.length < rows.length
                      }
                      checked={rows.length > 0 && selected.length === rows.length}
                      onChange={handleSelectAllClick}
                      inputProps={{
                        "aria-label": "select all desserts",
                      }}
                    />
                  </TableCell> */}
                  {catalogCustomerHeadCells.map((headCell) => (
                    <TableCell
                      key={headCell.id}
                      align={
                        headCell.numeric
                          ? "right"
                          : headCell.hasSubheader
                          ? "center"
                          : "left"
                      }
                      // padding={headCell.disablePadding ? "none" : "normal"}
                      padding={"normal"}
                      sortDirection={orderBy === headCell.id ? order : false}
                    >
                      <TableSortLabel
                        active={orderBy === headCell.id}
                        direction={orderBy === headCell.id ? order : "asc"}
                        onClick={createSortHandler(headCell.id)}
                      >
                        {headCell.label}
                        {orderBy === headCell.id ? (
                          <Box component="span" sx={visuallyHidden}>
                            {order === "desc"
                              ? "sorted descending"
                              : "sorted ascending"}
                          </Box>
                        ) : null}
                      </TableSortLabel>
                      {headCell.hasSubheader && (
                        <Stack
                          direction={"row"}
                          alignItems="center"
                          justifyContent={"space-between"}
                        >
                          <Typography sx={{ fontSize: "10px" }}>
                            Products
                          </Typography>
                          <Typography sx={{ fontSize: "10px" }}>
                            Ready Stock
                          </Typography>
                          <Typography sx={{ fontSize: "10px" }}>
                            Make to order
                          </Typography>
                        </Stack>
                      )}
                    </TableCell>
                  ))}
                </TableRow>
                {/* <TableRow sx={{}}>
                  <TableCell
                    align={"left"}
                    // padding={headCell.disablePadding ? "none" : "normal"}
                    padding={"normal"}
                    //   sortDirection={false}
                    colSpan={5}
                  ></TableCell>
                  <TableCell
                    align={"left"}
                    sx={{ fontSize: "11px", color: "text.secondary" }}
                    // padding={headCell.disablePadding ? "none" : "normal"}
                    padding={"normal"}
                    //   sortDirection={false}
                    colSpan={0.5}
                  >
                    Contacts responded
                  </TableCell>
                  <TableCell
                    sx={{ fontSize: "11px", color: "text.secondary" }}
                    align={"left"}
                    // padding={headCell.disablePadding ? "none" : "normal"}
                    padding={"normal"}
                    //   sortDirection={false}
                    colSpan={0.5}
                  >
                    Unique products
                  </TableCell>
                  <TableCell
                    align={"left"}
                    // padding={headCell.disablePadding ? "none" : "normal"}
                    padding={"normal"}
                    colSpan={1}
  
                    //   sortDirection={false}
                  ></TableCell>
                </TableRow> */}
              </TableHead>
            )}
            {/* <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
              /> */}
            <TableBody>
              {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                     rows.sort(getComparator(order, orderBy)).slice() */}
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <CustomerRow row={row} index={index} catalog={catalog} />
                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {rows.length > 5 && (
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </Paper>
    </Box>
  );
}

const CustomerRow = ({ row, catalog, index, isSelected }) => {
  // console.log(catalog, "catalog");
  // const isItemSelected = isSelected(row.name);
  const labelId = `enhanced-table-checkbox-${index}`;
  const catalogs = useSelector((state) => state.product.catalogs);
  // const [catalog, setCatalog] = useState({});
  const [customer, setCustomer] = useState({});
  const [responseTime, setResponseTime] = useState("");
  const [info, setInfo] = useState({});
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [imageUrl, setImageUrl] = useState("");
  const [request, setRequest] = useState({});
  const open = Boolean(anchorEl);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const isIpad = useMediaQuery(theme.breakpoints.down("md"));
  const isDarkTheme = theme.palette && theme.palette.mode === "dark";

  const catalog_requests = useSelector(
    (state) => state.product.catalog_requests
  );

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (!isObjWithValues(catalog)) return setRequest({});
    let { catalogRequests } = catalog;
    let request =
      isArrayWithValues(catalogRequests) &&
      catalogRequests.find((i) => i.customer_id == row.id);
    if (request && isArrayWithValues(catalog_requests)) {
      let _request = catalog_requests.find(
        (i) => i.request_id == request.request_id
      );
      setRequest(_request);
    }
  }, [catalog, catalog_requests, row]);

  useEffect(() => {
    if (!isObjWithValues(row)) return;
    let { productDetails } = row;
    if (isArrayWithValues(productDetails)) {
      for (let product of productDetails) {
        let { images } = product;
        if (isArrayWithValues(images)) {
          setImageUrl(images[0]?.src);
          break;
        }
      }
    }
  }, [row]);

  // useEffect(() => {
  //   if (!isArrayWithValues(catalogs)) return;
  //   let catalog = catalogs.find((i) => i.id == row.post_id);
  //   setCatalog(catalog || {});
  // }, [catalogs]);

  // useEffect(() => {
  //   if (isObjWithValues(catalog)) {
  //     let;
  //   }
  // }, [catalog]);

  // useEffect(() => {
  //   let { customerDetail, catalogue_request_timing, products } = row || {};
  //   let obj = {};

  //   if (products)
  //     obj.totalSelected = products?.split(",")?.filter(Boolean)?.length;
  //   if (catalogue_request_timing) {
  //     let obj = getRelativeTimeDifference(
  //       new Date(catalogue_request_timing),
  //       new Date()
  //     );
  //     setResponseTime(`${obj.value} ${obj.label}`);
  //   }
  //   setInfo((state) => ({ ...state, ...obj }));
  //   if (customerDetail) setCustomer(customerDetail);
  // }, [row]);

  const onCopyLink = () => {
    navigator.clipboard.writeText(catalog.link);
    dispatch(
      setGlobalToast({
        show: true,
        message: "Catalog link copied to clipboard",
        severity: "success",
      })
    );
    handleClose();
  };

  if (isIpad)
    return (
      <ResponsiveTabaleRow
        title={`${row.first_name || ""} ${row.last_name || ""}`}
        titleProps={{
          onClick: () => navigate(`/catalogs/requests/${request.request_id}`),
        }}
        subTitle2={
          <>
            <TableSecondaryLabel sx={{ mt: 1 }}>
              {request.catalogue_request_timing
                ? formatDate(
                    new Date(
                      `${request.catalogue_request_timing}`.length > 10
                        ? request.catalogue_request_timing
                        : request.catalogue_request_timing * 1000
                    ),
                    catalogDateFormat
                  )
                : ""}
              {"  "}
              {request.catalogue_request_timing
                ? formatDate(
                    new Date(
                      `${request.catalogue_request_timing}`.length > 10
                        ? request.catalogue_request_timing
                        : request.catalogue_request_timing * 1000
                    ),
                    "hh:mm am/pm"
                  )
                : ""}
            </TableSecondaryLabel>
          </>
        }
        src={imageUrl || "/static/img/woocommerce_placeholder.png"}
        chipLabel={
          <Typography>
            {request.productsLength}/
            <TableSecondaryLabel variant="caption">
              {catalog?.products || 0}
            </TableSecondaryLabel>
          </Typography>
        }
        chipProps={{
          size: "small",
          mr: 1,
          mb: 1,

          sx: {
            // color: statusFontColor(row.status),
            color: !row.is_public ? "#5570bc" : "#1aae6f",
            border: isDarkTheme ? "0.7px solid" : "0px solid",
            // borderColor: statusFontColor(row.status),
            backgroundColor: isDarkTheme
              ? "transparent"
              : !row.is_public
              ? "#EAECFB"
              : "#e0f6ed",
          },
          variant: "outlined",
        }}
      />
    );
  return (
    <TableRow
      hover
      // onClick={(event) => handleClick(event, row.name)}
      //   role="checkbox"
      // aria-checked={isItemSelected}
      tabIndex={-1}
      key={row.title}
      // selected={isItemSelected}
    >
      {/* <TableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            checked={isItemSelected}
                            inputProps={{
                              "aria-labelledby": labelId,
                            }}
                          />
                        </TableCell> */}
      <TableCell
        component="th"
        id={labelId}
        // scope="row"
        // padding="none"
      >
        <Stack direction="row" alignItems={"center"}>
          <img
            src={imageUrl || "/static/img/woocommerce_placeholder.png"}
            style={{
              height: "50px",
              width: "50px",
              borderRadius: "50%",
            }}
          />
          <Box sx={{ ml: 1.5 }}>
            <Typography
              sx={{ fontSize: "13px", fontWeight: "600", cursor: "pointer" }}
              onClick={() => navigate(`/catalogs/requests/${row.request_id}`)}
            >
              {row.first_name || ""} {row.last_name || ""}
            </Typography>
            <Stack direction="row" alignItems={"center"} spacing={3} mt={1}>
              <IconValue
                icon={
                  <Favorite
                    style={{
                      height: "13px",
                      width: "13px",
                      fill: "#bb6666",
                    }}
                  />
                }
                text={"0"}
              />
              <IconValue
                icon={
                  <Visibility
                    style={{
                      height: "15px",
                      width: "15px",
                      fill: "#818181",
                    }}
                  />
                }
                text={catalog.totalViews || 0}
              />
              {/* <IconValue
                icon={
                  <Share
                    style={{
                      height: "13px",
                      width: "13px",
                      fill: "#35557f",
                    }}
                  />
                }
                text={"0"}
              /> */}
            </Stack>
          </Box>
        </Stack>
      </TableCell>
      <TableCell align="left">
        <TableLabel>Lead</TableLabel>
        {/* <Box>
          <TableLabel>
            {customer.first_name || ""} {customer.last_name || ""}
          </TableLabel>
          <TableSecondaryLabel>{customer.email || ""}</TableSecondaryLabel>
          <TableSecondaryLabel>{customer?.billing?.phone}</TableSecondaryLabel>
        </Box> */}
      </TableCell>
      <TableCell align="left">
        {catalog.created_at
          ? formatDate(new Date(catalog.created_at), catalogDateFormat)
          : ""}
        <TableSecondaryLabel>
          {catalog.created_at
            ? formatDate(new Date(catalog.created_at), "hh:mm am/pm")
            : ""}
        </TableSecondaryLabel>
        {/* <TableSecondaryLabel>
          Responded in
          <br /> {responseTime}
        </TableSecondaryLabel> */}
      </TableCell>
      <TableCell align="right">
        <TableLabel>
          {/* <TableSecondaryLabel variant="caption">
            {catalog?.products || 0}
          </TableSecondaryLabel> */}

          <Typography>
            {request.productsLength || 0}/
            <TableSecondaryLabel variant="caption">
              {catalog?.products || 0}
            </TableSecondaryLabel>
          </Typography>
        </TableLabel>
        {/* <TableSecondaryLabel>40%</TableSecondaryLabel> */}
      </TableCell>
      <TableCell align="left">
        <Grid container spacing={2}>
          <Grid item xs>
            {request.productsLength || 0}
          </Grid>
          <Grid item xs>
            {request.totalInStock || 0}
          </Grid>
          <Grid item xs>
            {request.totalMakeToOrder || 0}
          </Grid>
        </Grid>
      </TableCell>
      {/* <TableCell align="left">
        <TableLabel>{catalog.totalRequests}</TableLabel>
        <TableSecondaryLabel>(0%)</TableSecondaryLabel>
      </TableCell> */}
    </TableRow>
  );
};
const MostViewedProducts = () => {
  return (
    <Box>
      <SectionLabel>Most viewed products</SectionLabel>

      <Stack
        direction={"row"}
        sx={{
          maxWidth: "100%",
          overflow: "auto",
          display: "flex",
          gap: "12px",
          mt: 3,
        }}
      >
        {/* {map(range(10), (_) => (
          <Container />
        ))} */}
        {Array.from(new Array(4)).map((i) => {
          return <CatalogProductCard />;
        })}
      </Stack>
    </Box>
  );
};

const CatalogProductCard = () => {
  return (
    <Card sx={{ maxWidth: "250px", p: 0, ...catalogCardStyle }}>
      <CardContent sx={{ p: 0 }}>
        <img
          src="https://tiarabytj.com/wp-content/uploads/sites/27/2022/11/1615371830359.jpg"
          style={{
            height: "100%",
            width: "100%",
            //   height: "50px",
            //   width: "50px",
            //   borderRadius: "50%",
          }}
        />
        <Box sx={{ px: 1.5 }}>
          <Stack
            direction="row"
            alignItems={"center"}
            justifyContent="space-between"
            //   spacing={1}
            px={1.2}
            mt={1}
          >
            <IconValue
              icon={
                <Favorite
                  style={{
                    height: "16px",
                    width: "16px",
                    fill: "#bb6666",
                  }}
                />
              }
              text={"43"}
              textStyle={{ fontSize: "15px" }}
            />
            <IconValue
              icon={
                <Visibility
                  style={{
                    height: "17px",
                    width: "17px",
                    fill: "#818181",
                  }}
                />
              }
              text={"23"}
              textStyle={{ fontSize: "15px" }}
            />
            {/* <IconValue
              icon={
                <Share
                  style={{
                    height: "16px",
                    width: "16px",
                    fill: "#35557f",
                  }}
                />
              }
              text={"13"}
              textStyle={{ fontSize: "15px" }}
            /> */}
          </Stack>
          <Typography sx={{ fontSize: "14px", fontWeight: "500", mt: 2 }}>
            Tanika product DC-23-VR
          </Typography>
          <TableLabel>Diamond, Necklace</TableLabel>
          <Grid container style={{ marginTop: "6px" }}>
            <Grid item xs={6}>
              <Box>
                <TableLabel>Sent</TableLabel>
                <TableSecondaryLabel>40</TableSecondaryLabel>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box>
                <TableLabel>Request</TableLabel>
                <TableSecondaryLabel>50</TableSecondaryLabel>
              </Box>
            </Grid>
          </Grid>
          {/* <Stack
            direction={"row"}
            alignItems="flex-start"
            justifyContent={"space-evenly"}
          >
            <Box>
              <TableLabel>Sent</TableLabel>
              <TableSecondaryLabel>40</TableSecondaryLabel>
              {/* <TableLabel>40</TableLabel> 
            </Box>
            <Box>
              <TableLabel>Request</TableLabel>
              <TableSecondaryLabel>50</TableSecondaryLabel>
            </Box>
          </Stack> */}
        </Box>
      </CardContent>
    </Card>
  );
};
