import React, { useEffect, useState } from "react";
import { WooCommerceSvg } from "../../../../Svgs";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
  Box,
  TextField,
  Icon,
  InputAdornment,
} from "@mui/material";
import {
  getAxiosError,
  getStoreId,
  getToken,
  getWebsite,
  isObjWithValues,
  getGlobalRedirectURL,
  Base64,
  jweroProductKeys,
  isDev,
} from "../../../../helper";
import { useDispatch, useSelector } from "react-redux";
import { EDIT_SETTINGS } from "../../../../redux/actions/settingActions";
import axios from "axios";
import * as qs from "qs";
import IntegrationView from "../../helper/IntegrationView";
import { Lock } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import AuthorizeView from "../../helper/AuthorizeView";
// import querystring from "querystring";

function WooCommerceIntegration() {
  const [userStoreUrl, setUserStoreUrl] = useState(
    isDev() ? "https://tiarabytj.com" : ""
  );
  const [mappedAttributes, setMappedAttributes] = useState({});
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const reduxWooCommerceIntegration = useSelector(
    (state) => state.settings.wooCommerceIntegration
  );
  const fetchingAllSettings = useSelector(
    (state) => state.settings.fetchingAllSettings
  );

  const reduxIntegrationAttributes =
    useSelector((state) => state.settings.integrationAttributes) || {};

  useEffect(() => {
    getWooProductsKeys();
  }, []);

  useEffect(() => {
    getWooProductsKeys();
  }, [reduxIntegrationAttributes]);

  const getWooProductsKeys = async () => {
    if (
      !reduxIntegrationAttributes?.hasOwnProperty("woocommerce") &&
      reduxWooCommerceIntegration?.hasOwnProperty("authorised_store")
    ) {
      const wooStore = reduxWooCommerceIntegration?.authorised_store;
      const cKey = reduxWooCommerceIntegration?.consumer_key;
      const cSecret = reduxWooCommerceIntegration?.consumer_secret;
      const authToken = Base64.btoa(`${cKey}:${cSecret}`);

      try {
        const response = await axios({
          method: "GET",
          url: `${wooStore}/wp-json/wc/v3/products?status=publish&page=1&per_page=1`,
          headers: {
            Authorization: `Basic ${authToken}`,
          },
        });
        const data = response.data;
        if (response.status == 200) {
          const product = data[0];
          const attributes = Object.keys(product)
            .filter((item) => jweroProductKeys.includes(item))
            .map((item, index) => ({
              [item]: typeof product[item],
            }));

          dispatch({
            type: EDIT_SETTINGS,
            payload: {
              integrationAttributes: {
                ...reduxIntegrationAttributes,
                woocommerce: attributes,
              },
            },
          });
        }
      } catch (err) {}
    }
    autoMapWithKeys();
  };

  const autoMapWithKeys = async () => {
    try {
      if (
        reduxIntegrationAttributes?.hasOwnProperty("mapped") &&
        reduxIntegrationAttributes?.mapped?.hasOwnProperty("woocommerce")
      ) {
        setMappedAttributes(reduxIntegrationAttributes.mapped.woocommerce);
      } else if (
        reduxIntegrationAttributes?.hasOwnProperty("woocommerce") &&
        reduxIntegrationAttributes?.hasOwnProperty("jwero")
      ) {
        let preMappedAttributes = {};

        const jweroKeys = Object.keys(
          reduxIntegrationAttributes.jwero.reduce(
            (r, c) => Object.assign(r, c),
            {}
          )
        );
        const wooComKeys = Object.keys(
          reduxIntegrationAttributes.woocommerce.reduce(
            (r, c) => Object.assign(r, c),
            {}
          )
        );
        jweroKeys.forEach((item, index) => {
          if (wooComKeys.includes(item)) {
            preMappedAttributes = {
              ...preMappedAttributes,
              item,
            };
          }
        });

        setMappedAttributes(preMappedAttributes);
      }
    } catch (er) {}
  };

  const onPressAuthorise = () => {
    try {
      const website = getWebsite();
      const store_url = userStoreUrl;
      const endpoint = "/wc-auth/v1/authorize";

      let state = {
        website: website,
        endpoint: "woocommerce_integration",
        authorised_store: store_url,
      };

      const params = {
        app_name: "Jwero",
        scope: "read_write",
        user_id: Base64.btoa(JSON.stringify(state)),
        return_url: isDev()
          ? "http://localhost:3000/integrations"
          : "http://app.jwero.ai/integrations",
        callback_url: `https://websockets.tanika.tech/woocommerce_auth`,
      };

      const queryParams = qs.stringify(params).replace(/%20/g, "+");
      window.open(`${store_url}${endpoint}?${queryParams}`, "_self");
    } catch (err) {
      // console.log(err);
    }
  };

  const onPressRevoke = async () => {
    let website = getWebsite();
    let token = getToken();

    if (website && token) {
      try {
        const response = await axios({
          url: `${website}/wp-json/store/v1/settings/woocommerce_integration`,
          method: "POST",
          headers: {
            Authorization: `Basic ${token}`,
          },
          data: {
            setting: "woocommerce_integration",
            data: {
              integrated: false,
            },
          },
        });
        if (response?.data?.type === "success") {
          dispatch({
            type: EDIT_SETTINGS,
            payload: {
              wooCommerceIntegration: response?.data.data,
            },
          });
        }
      } catch (err) {
        console.log("WooCommerce revoke", getAxiosError(err));
      }
    }
  };

  const onPressApplyMapping = async () => {
    let website = getWebsite();
    let token = getToken();

    const updatedIntegrationAttributes = {
      ...reduxIntegrationAttributes,
      mapped: {
        ...reduxIntegrationAttributes?.mapped,
        woocommerce: mappedAttributes,
      },
    };

    if (website && token) {
      try {
        const response = await axios({
          url: `${website}/wp-json/store/v1/settings/integration_attributes`,
          method: "POST",
          headers: {
            Authorization: `Basic ${token}`,
          },
          data: {
            setting: "integration_attributes",
            data: updatedIntegrationAttributes,
          },
        });
        if (response.status === 200) {
          dispatch({
            type: EDIT_SETTINGS,
            payload: {
              integrationAttributes: response?.data.data,
            },
          });
        }
      } catch (err) {
        console.log("Error updatetokens wooCommerce", getAxiosError(err));
      }
    }
  };

  const handleChangeAttribute = (target, value) => {
    setMappedAttributes({
      ...mappedAttributes,
      [target]: value?.target?.value,
    });
  };

  const _renderAttributes = () => {
    return (
      <div>
        <div
          style={{
            flexDirection: "row",
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h5">WooCommerce</Typography>
          <Typography variant="h5">Jwero</Typography>
        </div>

        {isObjWithValues(reduxIntegrationAttributes) &&
          reduxIntegrationAttributes.hasOwnProperty("woocommerce") &&
          reduxIntegrationAttributes?.woocommerce?.map((item, index) =>
            Object.keys(item).map((jweroKey, attributeIndex) => {
              return (
                <div style={{ flexDirection: "row", display: "flex" }}>
                  <FormControl
                    fullWidth
                    sx={{
                      marginTop: "14px",
                    }}
                  >
                    <InputLabel id={attributeIndex}>{jweroKey}</InputLabel>
                    <TextField
                      disabled
                      labelId={"woocommerce-attributes"}
                      id={`woocommerce-select-${attributeIndex}`}
                      value={jweroKey}
                      style={{ color: "#000000" }}
                    ></TextField>
                  </FormControl>
                  {/* --------- */}
                  <FormControl
                    fullWidth
                    sx={{
                      marginTop: "14px",
                      marginLeft: "14px",
                    }}
                  >
                    <InputLabel id={attributeIndex}>
                      {mappedAttributes[jweroKey]}
                    </InputLabel>
                    <Select
                      labelId={"jwero-attributes"}
                      id={attributeIndex}
                      value={mappedAttributes[jweroKey]}
                      label={mappedAttributes[jweroKey]}
                      onChange={(value) =>
                        handleChangeAttribute(jweroKey, value)
                      }
                      sx={{ fontStyle: { color: "#000000" } }}
                    >
                      {reduxIntegrationAttributes?.jwero &&
                        Object.keys(
                          reduxIntegrationAttributes?.jwero?.reduce(
                            (r, c) => Object.assign(r, c),
                            {}
                          )
                        )
                          .filter(
                            (item) =>
                              !Object.values(mappedAttributes).includes(item)
                          )
                          .map((otherAttributeKey, otherAttributeIndex) => (
                            <MenuItem value={otherAttributeKey}>
                              {otherAttributeKey}
                            </MenuItem>
                          ))}
                    </Select>
                  </FormControl>
                </div>
              );
            })
          )}
      </div>
    );
  };

  const _renderAuthorizedView = () => {
    return (
      <div>
        <div
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            display: "flex",
            marginBottom: "30px",
          }}
        >
          <Typography variant="h4">Map Attributes</Typography>
          <div
            style={{
              flexDirection: "row",
              justifyContent: "flex-end",
              display: "flex",
            }}
          >
            <Button
              size="large"
              onClick={onPressRevoke}
              sx={{
                borderRadius: "30px",
                backgroundColor: "#FF6263",
                color: "#FFFFFF",
              }}
              variant="contained"
            >
              Revoke
            </Button>
            {reduxIntegrationAttributes?.hasOwnProperty("jwero") &&
              reduxIntegrationAttributes?.hasOwnProperty("woocommerce") && (
                <Button
                  size="large"
                  onClick={onPressApplyMapping}
                  sx={{
                    marginLeft: "24px",
                    borderRadius: "30px",
                    backgroundColor: "#383CC1",
                    color: "#FFFFFF",
                  }}
                  variant="contained"
                >
                  Apply Mapping
                </Button>
              )}
          </div>
        </div>
        <div>{_renderAttributes()}</div>
      </div>
    );
  };

  const _renderNotAuthorizedView = () => {
    return (
      <div style={{ flexDirection: "row", display: "flex" }}>
        <TextField
          id="outlined-basic"
          label="Store URL"
          variant="outlined"
          value={userStoreUrl}
          onChange={(event) => setUserStoreUrl(event.target.value)}
        />
        <Button
          onClick={onPressAuthorise}
          sx={{
            padding: "4px 12px",
            borderRadius: "30px",
            boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
            borderColor: "#022687",
            textDecoration: { color: "#FFFFFF" },
            marginLeft: "8px",
          }}
        >
          Authorise
        </Button>
      </div>
    );
  };

  return (
    <>
      <AuthorizeView
        buttonTitle={
          !reduxWooCommerceIntegration?.integrated ? "Integrate" : "Revoke"
        }
        onClickButton={
          !reduxWooCommerceIntegration?.integrated
            ? onPressAuthorise
            : onPressRevoke
        }
        title={
          !reduxWooCommerceIntegration?.integrated
            ? "Please enter your Woocommerce store Url to integrate with Jwero"
            : ""
        }
        buttonProps={{
          color: reduxWooCommerceIntegration?.integrated ? "error" : "primary",
          loading,
        }}
        textFieldProps={{
          value: userStoreUrl,
          onChange: (event) => setUserStoreUrl(event.target.value),
          disabled: reduxWooCommerceIntegration?.integrated,
          InputProps: reduxWooCommerceIntegration?.integrated
            ? {
                endAdornment: (
                  <InputAdornment position="start">
                    <Lock />
                  </InputAdornment>
                ),
              }
            : null,
        }}
      />
    </>
  );

  return (
    <Box sx={{ padding: "12px 18px" }}>
      <Stack direction={"row"} alignItems={"center"} sx={{ gap: "20px" }}>
        <Typography
          variant="h5"
          sx={{ display: "flex", alignItems: "center", gap: "10px" }}
        >
          <WooCommerceSvg height={22} width={22} /> WooCommerce
        </Typography>
      </Stack>
      <Stack direction={"row"} alignItems="center" sx={{ gap: "20px", mt: 2 }}>
        <TextField
          value={userStoreUrl}
          onChange={(event) => setUserStoreUrl(event.target.value)}
          // variant="outlined"
          placeholder="Enter your store url"
          size="small"
          sx={{ width: "350px" }}
          // fullWidth
          // sx={{ mt: 2 }}
          disabled={reduxWooCommerceIntegration?.integrated}
          InputProps={
            reduxWooCommerceIntegration?.integrated
              ? {
                  endAdornment: (
                    <InputAdornment position="start">
                      <Lock />
                    </InputAdornment>
                  ),
                }
              : null
          }
        />

        {!reduxWooCommerceIntegration?.integrated ? (
          <LoadingButton
            size="small"
            loading={loading}
            // disabled={virtualTryIntegrated}
            // disabled={istryb4uIntegrated}
            variant="contained"
            onClick={onPressAuthorise}
          >
            Integrate
          </LoadingButton>
        ) : null}
        {reduxWooCommerceIntegration?.integrated ? (
          <LoadingButton
            size="small"
            color="error"
            loading={loading}
            variant="contained"
            onClick={onPressRevoke}
          >
            Revoke
          </LoadingButton>
        ) : null}
      </Stack>
      {false ? <Typography sx={{ mt: 2 }}>Integrated on</Typography> : null}
      {/* <Typography variant="h6" fontSize={"13px"} mt={3}>
                        Gupshup App Name
                    </Typography>
                    <TextField
                        value={name}
                        onChange={(e) => onEditGupshupForm({ name: e.target.value })}
                        variant="outlined"
                        size="small"
                        sx={{ mt: 2 }}
                        fullWidth
                        disabled={fetchingIntegrations}
                    /> */}
    </Box>
  );
  // return isObjWithValues(reduxWooCommerceIntegration) &&
  //   reduxWooCommerceIntegration.integrated
  //   ? _renderAuthorizedView()
  //   : _renderNotAuthorizedView();
}

export default WooCommerceIntegration;
