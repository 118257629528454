import {
  Box,
  Button,
  Divider,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import { isArrayWithValues } from "../../../../../helper";
import { Facebook, InstagramSvg, WhatsappSvg } from "../../../../../Svgs";
import Switch from "rc-switch";
import { FacebookTwoTone, ShoppingCart, YouTube } from "@mui/icons-material";

function MetaIntegrationView({
  filterIntegration,
  integratedApp,
  setRevokeApp,
  selectedScope,
  setSelectedScope,
  fetchingMetaIntegration,
  onEmbedPress,
  FacebookConfiguration,
}) {
  const theme = useTheme();
  let backgroundColor = theme.palette.primary && theme.palette.primary.main;

  return (
    <>
      <div>
        {(
          isArrayWithValues(filterIntegration)
            ? filterIntegration.includes("facebook")
            : true
        ) ? (
          <>
            <Stack
              direction="row"
              // justifyContent={""}
              alignItems="center"
              mt={6}
              sx={{ gap: "30px" }}
            >
              <Typography
                variant="h6"
                sx={{ display: "flex", alignItems: "center", gap: "8px" }}
              >
                <Facebook />
                Facebook
              </Typography>
              <Switch
                onChange={(e) => {
                  setSelectedScope((pre) => ({ ...pre, facebook: e }));
                }}
                checked={
                  integratedApp?.includes("facebook") || selectedScope?.facebook
                }
                disabled={
                  fetchingMetaIntegration || integratedApp.includes("facebook")
                }
                style={
                  selectedScope?.facebook
                    ? {
                        border: `1px solid ${backgroundColor}`,
                        backgroundColor,
                      }
                    : {}
                }
              />
              {integratedApp.includes("facebook") && (
                <Button
                  variant="outlined"
                  color="error"
                  size="small"
                  onClick={() => {
                    setRevokeApp("facebook");
                  }}
                >
                  Revoke
                </Button>
              )}
            </Stack>
            <Typography sx={{ mt: 1, marginBottom: "30px" }}>
              Enables your jewellery business to communicate with your customers
              through posts, messaging, etc 🌐
            </Typography>
            <Divider />
          </>
        ) : null}
        {(
          isArrayWithValues(filterIntegration)
            ? filterIntegration.includes("instagram")
            : true
        ) ? (
          <>
            <Stack
              direction="row"
              // justifyContent={"space-between"}
              sx={{ gap: "30px" }}
              alignItems="center"
              mt={6}
            >
              <Typography
                variant="h6"
                sx={{ display: "flex", alignItems: "center", gap: "8px" }}
              >
                <InstagramSvg /> Instagram{" "}
              </Typography>
              <Switch
                onChange={(e) => {
                  setSelectedScope((pre) => ({ ...pre, instagram: e }));
                }}
                checked={
                  integratedApp.includes("instagram") ||
                  selectedScope?.instagram
                }
                disabled={
                  fetchingMetaIntegration || integratedApp.includes("instagram")
                }
                style={
                  selectedScope?.instagram
                    ? {
                        border: `1px solid ${backgroundColor}`,
                        backgroundColor,
                      }
                    : {}
                }
              />
              {integratedApp.includes("instagram") && (
                <Button
                  variant="outlined"
                  color="error"
                  size="small"
                  onClick={() => {
                    setRevokeApp("instagram");
                  }}
                >
                  Revoke
                </Button>
              )}
            </Stack>
            <Typography sx={{ mt: 1, marginBottom: "30px" }}>
              Instantly Connect your Instagram and automate your Instagram
              Management from one place 💭
            </Typography>
            <Divider />
          </>
        ) : null}
        {(
          isArrayWithValues(filterIntegration)
            ? filterIntegration.includes("whatsapp")
            : true
        ) ? (
          <>
            <Stack
              direction="row"
              // justifyContent={"space-between"}
              alignItems="center"
              mt={6}
              sx={{ gap: "30px" }}
            >
              <Typography
                variant="h6"
                sx={{ display: "flex", alignItems: "center", gap: "8px" }}
              >
                <WhatsappSvg /> Whatsapp
              </Typography>
              <Switch
                onChange={(e) => {
                  setSelectedScope((pre) => ({ ...pre, whatsapp: e }));
                }}
                checked={
                  integratedApp.includes("whatsapp") || selectedScope?.whatsapp
                }
                disabled={
                  fetchingMetaIntegration || integratedApp.includes("whatsapp")
                }
                style={
                  selectedScope?.whatsapp
                    ? {
                        border: `1px solid ${backgroundColor}`,
                        backgroundColor,
                      }
                    : {}
                }
              />
              {integratedApp.includes("whatsapp") && (
                <Button
                  variant="outlined"
                  color="error"
                  size="small"
                  onClick={() => {
                    setRevokeApp("whatsapp");
                  }}
                >
                  Revoke
                </Button>
              )}
            </Stack>
            <Typography sx={{ mt: 1 }}>
              Increase your customer retention by keeping your customers engaged
              with your jewellery brand 📨
            </Typography>
            <Button
              startIcon={<YouTube />}
              // sx={{ marginBottom: "20px" }}
              onClick={() => {
                dispatch(
                  setGlobalTutorial({
                    show: true,
                    url: tutorialData?.config_whatsapp?.link,
                    title: tutorialData?.config_whatsapp?.title,
                  })
                );
              }}
            >
              Watch Video
            </Button>

            <Divider />
          </>
        ) : null}
      </div>
      {(
        isArrayWithValues(filterIntegration)
          ? filterIntegration.includes("commerce")
          : true
      ) ? (
        <>
          <Stack
            direction="row"
            // justifyContent={"space-between"}
            sx={{ gap: "30px" }}
            alignItems="center"
            mt={6}
          >
            <Typography
              variant="h6"
              sx={{ display: "flex", alignItems: "center", gap: "8px" }}
            >
              <ShoppingCart
                style={{
                  height: "22px",
                  width: "22px",
                  color: "#808080",
                }}
              />{" "}
              Commerce
            </Typography>
            <Switch
              onChange={(e) => {
                setSelectedScope((pre) => ({ ...pre, commerce: e }));
              }}
              checked={
                integratedApp.includes("commerce") || selectedScope?.commerce
              }
              disabled={
                fetchingMetaIntegration || integratedApp.includes("commerce")
              }
              style={
                selectedScope?.commerce
                  ? {
                      border: `1px solid ${backgroundColor}`,
                      backgroundColor,
                    }
                  : {}
              }
            />
            {integratedApp.includes("commerce") && (
              <Button
                variant="outlined"
                color="error"
                size="small"
                onClick={() => {
                  setRevokeApp("commerce");
                }}
              >
                Revoke
              </Button>
            )}
          </Stack>
          <Typography sx={{ mt: 1, marginBottom: "30px" }}>
            Market your items on Facebook. Attract potential customers to your
            products 🛒
          </Typography>
          <Divider sx={{ mb: 3 }} />
        </>
      ) : null}
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          position: "sticky",
          bottom: "12px",
          zIndex: 20,
          gap: 2,
        }}
      >
        {selectedScope?.whatsapp &&
          Object.values(selectedScope)?.filter(Boolean)?.length === 1 && (
            <Button
              size="large"
              onClick={() => onEmbedPress({ is_embed: true })}
              sx={{
                borderRadius: "20px",
                // backgroundColor: "#1877F2",
                // ":hover": { backgroundColor: "#1877F2" },
                mt: 3,
              }}
              // color="#00E676"
              startIcon={<WhatsappSvg />}
              variant="outlined"
              // className={
              //   Object.values(selectedScope).some(Boolean)
              //     ? "Meta-integrations-button btn--jump"
              //     : "Meta-integrations-button"
              // }
            >
              Embed flow
            </Button>
          )}
        <Button
          size="large"
          onClick={FacebookConfiguration}
          sx={{
            borderRadius: "20px",
            backgroundColor: "#1877F2",
            ":hover": { backgroundColor: "#1877F2" },
            mt: 3,
          }}
          startIcon={<FacebookTwoTone />}
          variant="contained"
          className={
            selectedScope && Object.values(selectedScope).some(Boolean)
              ? "Meta-integrations-button btn--jump"
              : "Meta-integrations-button"
          }
        >
          Continue with Meta
        </Button>
      </Box>
    </>
  );
  return (
    <Box>
      {(
        isArrayWithValues(filterIntegration)
          ? filterIntegration?.includes("facebook")
          : true
      ) ? (
        <>
          <Stack
            direction="row"
            // justifyContent={""}
            alignItems="center"
            mt={6}
            sx={{ gap: "30px" }}
          >
            <Typography
              variant="h6"
              sx={{ display: "flex", alignItems: "center", gap: "8px" }}
            >
              <Facebook />
              Facebook
            </Typography>
            {/* <Switch
              onChange={(e) => {
                setSelectedScope((pre) => ({ ...pre, facebook: e }));
              }}
              checked={
                integratedApp.includes("facebook") || selectedScope.facebook
              }
              disabled={
                fetchingMetaIntegration || integratedApp.includes("facebook")
              }
              // style={
              //   selectedScope.facebook
              //     ? {
              //         border: `1px solid ${backgroundColor}`,
              //         backgroundColor,
              //       }
              //     : {}
              // }
            /> */}
            {(integratedApp?.includes("facebook") || true) && (
              <Button
                variant="outlined"
                color="error"
                size="small"
                onClick={() => {
                  setRevokeApp("facebook");
                }}
              >
                Revoke
              </Button>
            )}
          </Stack>
          <Typography sx={{ mt: 1, marginBottom: "30px" }}>
            Enables your jewellery business to communicate with your customers
            through posts, messaging, etc 🌐
          </Typography>
          <Divider />
        </>
      ) : null}
    </Box>
  );
}

export default MetaIntegrationView;
