const getWebchatScript = ({ widget_id }) => {
  let script = `
    <script src="https://unpkg.com/react@18/umd/react.production.min.js"></script>
    <script src="https://unpkg.com/react-dom@18/umd/react-dom.production.min.js"></script>
    <script src="https://unpkg.com/react-chatbot-widget@latest/dist/chat-widget.js"></script>
    <script>
      
      const token = "'${widget_id}'";
      document.addEventListener("DOMContentLoaded", function () {
      // Create a new div element
      var chatbotContainer = document.createElement('div');
      // Assign the id 'chatbot-container' to the new div
      chatbotContainer.id = 'chatbot-container';
      // Append the new div to the body element
      document.body.appendChild(chatbotContainer);
      // Now you can initialize your ChatWidget or any other scripts that target this div
      ChatWidget?.default?.init("chatbot-container", {token});
    });
    </script>
    `;

  return script;
};

export { getWebchatScript };
