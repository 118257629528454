/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Paper,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Tabs,
  Tooltip,
  Typography,
  TablePagination,
  IconButton,
  Avatar,
  Dialog,
  Chip,
  Alert,
  Button,
  useMediaQuery,
  CircularProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  DialogContent,
  DialogActions,
  DialogTitle,
} from "@mui/material";
import { useTheme } from "@mui/system";
import React, { useEffect, useState } from "react";
import {
  dialogStyle,
  formatDate,
  formatServerValue,
  getComparator,
  getIsBackendMigrated,
  getNameFromPlatform,
  getWebsite,
  globalCardStyle,
  isArray,
  isArrayWithValues,
  isDev,
  isObjWithValues,
  isOurWebsite,
  putTourHistory,
  reduceLargeText,
  secondaryLabelSmall,
  sectionLabel,
  stableSort,
  StackRow,
  TabPanel,
  validateNumber,
} from "../../helper";
import {
  BellCircle,
  ChatsSvg,
  InstagramSvg,
  Messenger,
  NewMessageIcon,
  NoCalendar,
  Whatsapp,
} from "../../Svgs";

import {
  newContactHeadCells,
  NoTasks,
  platformColors,
  platformIcons,
  primaryLabel,
  secondaryLabel,
  totalConversationsOptions,
} from "./ChatHelper";
import { visuallyHidden } from "@mui/utils";
import { useDispatch, useSelector } from "react-redux";
import {
  EDIT_CHATS,
  getConversations,
  getUsersWithTasks,
  updateUserTask,
} from "../../redux/actions/chatActions";

import { default as ChatMessages } from "./ChatsSocial";
import TaskTable from "./TaskTable";
import ReminderTable from "./ReminderTable";
import CallTable from "./CallTable";
import ReactApexChart from "react-apexcharts";
import { CreateUserTask, DeleteUserTask } from "./UserTasks";
import AllTasksTable from "./AllTasksTable";
import {
  Add,
  ArrowRightAltOutlined,
  CheckBoxOutlineBlank,
  Close,
  HelpOutline,
  PeopleAltOutlined,
  PersonOutlineOutlined,
  YouTube,
} from "@mui/icons-material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
// import { DashboardTotalCard } from "../dashboards/Default";
import { useMemo } from "react";
import ChatsCustomer from "./components/ChatsCustomer";
import DataNotFound from "../components/DataNotFound";
import { useTour } from "@reactour/tour";
import { metaIntegrationSteps, tutorialData } from "../../App";
import {
  setGlobalTutorial,
  setHelperData,
} from "../../redux/actions/helperActions";
import PopperTooltip from "../components/PopperTooltip";
import { addTourHistory } from "../../redux/actions/userActions";
import { DashboardTotalCard } from "../dashboards/Default";
import displayState from "../../validateProduct";
import { migrateFunction } from "../chat_new/apiFunctions";

function Chat() {
  const [selectedTab, setSelectedTab] = useState("overview");

  const [formOpen, setFormOpen] = useState(false);
  const [editForm, setEditForm] = useState(false);
  const [deleteFormOpen, setDeleteFormOpen] = useState(false);
  const [paramChatId, setParamChatId] = useState(false);
  const [hideTabs, setHideTabs] = useState(false);
  const [isSocialMediaConnected, setIsSocialMediaConnected] = useState(false);

  const { setSteps, setCurrentStep, setIsOpen } = useTour();
  const openUpdateForm = (edit) => {
    setEditForm(edit);
    setFormOpen(true);
  };
  const openAddForm = (edit) => {
    setEditForm({});
    setFormOpen(true);
  };
  const openDeleteForm = (edit) => {
    setEditForm(edit);
    setDeleteFormOpen(true);
  };
  const closeForm = () => setFormOpen(false);
  const closeDeleteForm = () => setDeleteFormOpen(false);
  const conversations = useSelector((state) => state.chats.conversations);
  const showGoogleCalendarIntegrateWarning = useSelector(
    (state) => state.chats.showGoogleCalendarIntegrateWarning
  );
  const unreadCount = useSelector((state) => state.chats.unreadCount);
  const allUserTasks = useSelector((state) => state.chats.allUserTasks);
  // const { id: paramChatId } = useParams()

  const isAdmin = useSelector((state) => state.user.isAdmin);
  const chatsAccess = useSelector((state) => state.user.chatsAccess);
  const reduxGoogleIntegration = useSelector(
    (state) => state.settings.googleIntegration
  );
  const googleCalendarIntegerated = Object.values(reduxGoogleIntegration || {})
    ?.map((o) => (isArrayWithValues(o.connected) ? o.connected : []))
    ?.flat()
    ?.includes("google_calendar");

  const metaIntegration = useSelector(
    (state) => state.settings.metaIntegration
  );
  const conversationsFetched = useSelector(
    (state) => state.chats.conversationsFetched
  );
  const users_with_tasks_fetched = useSelector(
    (state) => state.chats.users_with_tasks_fetched
  );

  const tour_history = useSelector((state) => state.user.tour_history);

  const allSettingsFetched = useSelector(
    (state) => state.settings.allSettingsFetched
  );
  const onlyChats = useSelector((state) => state.helper.onlyChats);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id: paramsId } = useParams();
  const location = useLocation();
  const theme = useTheme();
  const isIpad = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    let isSocialMediaConnected = false;
    if (allSettingsFetched) {
      if (!metaIntegration) isSocialMediaConnected = false;
      else if (isObjWithValues(metaIntegration)) {
        let socialMedia = ["facebook", "instagram", "whatsapp"];
        if (
          Object.values(metaIntegration).find((i) =>
            i?.connected?.find((i) => socialMedia.includes(i))
          )
        )
          isSocialMediaConnected = false;
      } else isSocialMediaConnected = true;
      setIsSocialMediaConnected(isSocialMediaConnected);
    }
  }, [metaIntegration, allSettingsFetched]);
  useMemo(() => {
    // if (order.dateCreated) return;
    let pieces = location.pathname.split("/");
    let chatId = pieces[pieces.length - 1];

    setHideTabs(chatId && pieces[pieces.length - 2] === "chats" && isIpad);
    if (chatId !== paramChatId) {
      setParamChatId(chatId);
      if (selectedTab !== 1) setSelectedTab("");
      dispatch({ type: EDIT_CHATS, payload: { paramChatId: chatId } });
    }
  }, [paramChatId, location]);

  useEffect(() => {
    // if (!isDev() && !isOurWebsite()) return;
    // if (isAdmin && !getIsBackendMigrated("chats")) {
    //   migrateFunction();
    // }

    return () => {};
  }, [isAdmin]);

  // useMemo(() => {
  //    // if (order.dateCreated) return;
  //   let pieces = location.pathname.split("/");
  //   let chatId = pieces[pieces.length - 1];

  //   setHideTabs(paramChatId && isIpad);
  //   if (chatId !== paramChatId) {
  //     setParamChatId(chatId);
  //     if (selectedTab !== 1) setSelectedTab(1);
  //     dispatch({ type: EDIT_CHATS, payload: { paramChatId: chatId } });
  //   }
  // }, [paramChatId])

  useEffect(() => {
    if (isObjWithValues(reduxGoogleIntegration)) {
      let googleCalendarObj = Object.values({ ...reduxGoogleIntegration }).find(
        (i) => i.connected?.includes("google_calendar")
      );
      dispatch({
        type: EDIT_CHATS,
        payload: {
          showGoogleCalendarIntegrateWarning:
            !isObjWithValues(googleCalendarObj),
        },
      });
    } else
      dispatch({
        type: EDIT_CHATS,
        payload: {
          showGoogleCalendarIntegrateWarning: true,
        },
      });
  }, [reduxGoogleIntegration]);

  useEffect(() => {
    if (!users_with_tasks_fetched) dispatch(getUsersWithTasks());
  }, []);

  // useEffect(() => {
  //   if (!isArrayWithValues(conversations)) return;
  //   let channelTotal = {};
  //   for (let convo of conversations) {
  //     let { platform, conversation } = convo;
  //     if (!channelTotal[platform]) channelTotal[platform] = 0;
  //     channelTotal[platform] += validateNumber(conversation?.length);
  //   }
  //   setChannelTotal(
  //     Object.keys(channelTotal)
  //       .map((i) => ({
  //         name: i,
  //         value: channelTotal[i],
  //       }))
  //       .sort((a, b) => b.value - a.value)
  //   );
  // }, [conversations]);

  useEffect(() => {
    if (!conversationsFetched) dispatch(getConversations());
  }, [conversationsFetched]);

  const onTabChange = (e, value) => {
    setSelectedTab(value);
  };
  // const arr =
  //   getWebsite()?.includes && getWebsite()?.includes("tiarabytj.com")
  //     ? [
  //       {
  //         label: "All Messages",
  //         value: "all_messages",
  //         icon: "",
  //       },
  //       {
  //         label: "Whatsapp",
  //         value: "whatsapp",
  //         icon: <Whatsapp />,
  //       },
  //       {
  //         label: "Facebook",
  //         value: "facebook",
  //         icon: <Messenger />,
  //       },
  //       {
  //         label: "Instagram",
  //         value: "instagram",
  //         icon: <InstagramSvg />,
  //       },
  //     ]
  //     : [
  //       {
  //         label: "All Messages",
  //         value: "all_messages",
  //         icon: "",
  //       },
  //       {
  //         label: "Whatsapp",
  //         value: "whatsapp",
  //         icon: <Whatsapp />,
  //       },
  //       {
  //         label: "Facebook",
  //         value: "facebook",
  //         icon: <Messenger />,
  //       },
  //     ];

  const onMetaIntegrate = () => {
    setSteps(metaIntegrationSteps);
    setCurrentStep(0);
    dispatch(
      setHelperData({
        restrictedTour: [0, 1, 2, 3, 4],
        currentTour: "meta_integration",
      })
    );
    setIsOpen(true);
  };
  useMemo(() => {
    // if (isIpad) {
    // setHideTabs(true)
    let { hash } = location;
    setSelectedTab(hash?.replace("#", ""));

    // console.log(searchParams(location))
    // }
  }, [isIpad, location]);

  useMemo(() => {
    if (onlyChats && !tour_history?.chats_video) {
      dispatch(
        setGlobalTutorial({
          show: true,
          url: tutorialData?.chats?.link,
          title: tutorialData?.chats?.title,
        })
      );
      let historyObj = { ["chats_video"]: true };
      putTourHistory(historyObj);
      dispatch(addTourHistory(historyObj));
      // chats_video
    }
  }, [tour_history?.chats_video]);
  if (false)
    // if (!isSocialMediaConnected || true)
    return (
      <DataNotFound
        illustration={<img src="/gifs/chats.gif" alt="gifs" />}
        subtitle={
          // "To access chats dashboard you need to integrate atleast one social media and select page"
          "You have not integrated any channel to begin conversations with your contacts"
        }
        title={"Lets integrate your first Channel"}
        createButton={
          <Button
            variant="contained"
            onClick={onMetaIntegrate}
            // size={"small"}
            sx={{ mt: 3 }}
          >
            {isIpad ? "Integrate" : "Start integration"}
          </Button>
        }
      />
    );

  if (!chatsAccess && !isAdmin)
    return (
      <Box>
        <Stack direction={"row"} mt="20vh" justifyContent={"center"}>
          <ChatsSvg />
        </Stack>
        <Stack direction={"row"} justifyContent={"center"}>
          {/* <SettingsIllustration /> */}
          <Typography variant="h6" mt={3} sx={{ color: "text.secondary" }}>
            You don't have access to change or view Chats!
          </Typography>
        </Stack>
      </Box>
    );
  return (
    <Box>
      {!hideTabs && !isIpad ? (
        <Box sx={{ borderBottom: 1, borderColor: "divider", mb: 1 }}>
          <Stack
            direction={{ xs: "column", sm: "row" }}
            alignItems="center"
            justifyContent={"space-between"}
          >
            <Tabs
              value={selectedTab}
              onChange={onTabChange}
              aria-label="basic tabs example"
              sx={{ mb: isIpad ? 4 : 0 }}
            >
              <Tab label="Overview" value={"overview"} />
              <Tab label="Messages" value={""} />
              <Tab
                label={
                  "Activities"
                  // <Badge badgeContent={4} color="primary">
                  //   Activities
                  // </Badge>
                }
                value={"activities"}
              />
            </Tabs>
            <Button
              startIcon={<YouTube />}
              onClick={() => {
                dispatch(
                  setGlobalTutorial({
                    show: true,
                    url: tutorialData?.chats?.link,
                    title: tutorialData?.chats?.title,
                  })
                );
              }}
            >
              Watch Video
            </Button>
            {/* <IconButton
              sx={{ mr: 4 }}
              onClick={() =>
                dispatch(
                  setGlobalTutorial({
                    show: true,
                    url: tutorialData?.chats?.link,
                    title: tutorialData?.chats?.title,
                  })
                )
              }
            >
              <YouTube />
            </IconButton> */}
          </Stack>
        </Box>
      ) : null}
      <TabPanel value={selectedTab} index={"overview"}>
        <Box style={{ marginTop: 7 }}>
          {showGoogleCalendarIntegrateWarning ? (
            <Alert
              severity="error"
              sx={{ mb: 3 }}
              action={
                <Stack direction="row" alignItems={"center"} spacing={3}>
                  <Button
                    color="inherit"
                    size="small"
                    onClick={() => navigate("/integrations#Google")}
                  >
                    Integrate
                  </Button>
                  <IconButton
                    color="inherit"
                    size="small"
                    onClick={() =>
                      dispatch({
                        type: EDIT_CHATS,
                        payload: {
                          showGoogleCalendarIntegrateWarning: false,
                        },
                      })
                    }
                  >
                    <Close />
                  </IconButton>
                </Stack>
              }
            >
              Please integrate Google Calendar to receive notifications
            </Alert>
          ) : null}
          <TopCards />
          <Box sx={{ mt: 4 }}>
            <Grid container columnSpacing={3} rowSpacing={3}>
              <Grid item xs={12} md={6}>
                {/* <ChannelTotalGraph channelTotal={channelTotal} /> */}

                <RecentUserTasks openUpdateForm={openUpdateForm} />
              </Grid>
              <Grid item xs={12} md={6}>
                <TotalConversations />
              </Grid>
            </Grid>
          </Box>
          <Grid container spacing={3} rowSpacing={3}>
            <Grid item xs={12} md={8}>
              <RecentConversationsTable />
            </Grid>
            <Grid item xs={12} md={4} sx={{ paddingBottom: "24px" }}>
              <Broadcast conversations={conversations} />
            </Grid>
          </Grid>
          <AllTasksTable openDeleteForm={openDeleteForm} />
          <NewContactTable />
        </Box>
      </TabPanel>
      <TabPanel value={selectedTab} index={""}>
        {/* {isIpad && !paramsId && (
          <Stack
            direction="row"
            sx={
              // isIpad && isObjWithValues(customerView)
              //   ? { display: "none" }
              //   :
              {
                // backgroundColor: "Background",
                padding: "10px",
                paddingTop: 0,
                // borderBottomColor: "#e8e8e8",
                // borderBottomWidth: 0.2,
                // borderBottomStyle: "solid",
                overflow: "auto",
                // display: " "
                // display: "none",
              }
            }
            // sx={{
            //   backgroundColor: "Background",
            //   padding: "10px",
            //   borderBottomColor: "#e8e8e8",
            //   borderBottomWidth: 0.2,
            //   borderBottomStyle: "solid",
            // }}
            spacing={4}
          >
            {arr.map((i) => {
              return (
                <Button
                  variant={"text"}
                  startIcon={i.icon}
                  // variant={selectedTab !== i.value ? "outlined" : "contained"}
                  sx={{
                    // gap: "15px",
                    padding: "6px 20px",
                    minWidth: "auto",
                    whiteSpace: "nowrap ",
                    backgroundColor:
                      selectedTabChannel === i.value
                        ? "#e6e6e6"
                        : "transparent",
                    color: "#000",
                    // color: selectedTabChannel === i.value ? "primary.main" : "#000",
                    "&:hover": {
                      backgroundColor:
                        selectedTabChannel === i.value
                          ? "#e6e6e6"
                          : "transparent",
                    },
                  }}
                  onClick={() => setSelectedTabChannel(i.value)}
                >
                  <Typography
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "4px",
                    }}
                  >
                    {i.label}
                    {unreadCount?.[i.value] ? (
                      <Box
                        sx={{
                          color: "#fff",
                          backgroundColor: "primary.main",
                          borderRadius: "50%",
                          // p: 1,
                          height: "20px",
                          width: "20px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          fontSize: "10px",
                        }}
                      >
                        {unreadCount?.[i.value]}
                      </Box>
                    ) : null}
                  </Typography>
                </Button>
              );
            })}
          </Stack>
        )} */}
        <ChatMessages
          // selectedTab={selectedTabChannel}
          // setSelectedTab={setSelectedTabChannel}
          unreadCount={unreadCount}
        />
      </TabPanel>
      <TabPanel value={selectedTab} index={"activities"}>
        {isArrayWithValues(allUserTasks) ? (
          <>
            <Stack
              direction={"row"}
              alignItems="center"
              justifyContent={"flex-end"}
              my={3}
            >
              <Button
                variant="outlined"
                startIcon={<Add />}
                onClick={openAddForm}
              >
                Add Activity
              </Button>
            </Stack>
            <TaskTable openDeleteForm={openDeleteForm} />
            <ReminderTable
              openUpdateForm={openUpdateForm}
              openDeleteForm={openDeleteForm}
            />
            <CallTable
              openUpdateForm={openUpdateForm}
              openDeleteForm={openDeleteForm}
            />
            <FollowupTable />
          </>
        ) : (
          <Box sx={{ width: isIpad ? "80%" : "50%", margin: "auto" }}>
            <DataNotFound
              sx={{ mt: "30%" }}
              illustration={
                googleCalendarIntegerated ? (
                  <BellCircle
                    style={{ height: "70px", width: "70px", fill: "#c4c4c4" }}
                    fillCircle="#c4c4c4"
                  />
                ) : (
                  <NoCalendar height="100px" width="100px" />
                )
              }
              subtitle={
                googleCalendarIntegerated
                  ? ""
                  : "oh no, you are missing the action. Integrate Google Calendar to create your Tasks, Reminders, Calls and also get notifications and update of everything on your mobile. Make customer follow-ups a breeze!"
              }
              title={googleCalendarIntegerated ? "No Activities Found" : ""}
              createButton={
                !googleCalendarIntegerated ? (
                  <Button
                    variant="contained"
                    onClick={() => navigate("/integrations#Google")}
                    // size={"small"}
                    sx={{ mt: 3 }}
                  >
                    Integrate Calendar
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    onClick={openAddForm}
                    // onClick={() => navigate("/integrations#Google")}
                    // size={"small"}
                    sx={{ mt: 3 }}
                  >
                    Create Activity
                  </Button>
                )
              }
            />
          </Box>
        )}
      </TabPanel>
      <Dialog
        open={formOpen}
        keepMounted
        onClose={closeForm}
        aria-describedby="alert-dialog-slide-description"
        maxWidth="sm"
        fullWidth
        sx={{ ...dialogStyle }}
      >
        <CreateUserTask closeForm={closeForm} updateForm={editForm} />
      </Dialog>
      <Dialog
        open={deleteFormOpen}
        keepMounted
        onClose={closeDeleteForm}
        aria-describedby="alert-dialog-slide-description"
        maxWidth="sm"
        fullWidth
        sx={{ ...dialogStyle }}
      >
        <DeleteUserTask closeForm={closeDeleteForm} deleteForm={editForm} />
      </Dialog>
    </Box>
  );
}

export default Chat;

const Broadcast = ({ conversations }) => {
  const [allConversations, setAllConversations] = useState([]);
  // const [broadcastlist,] = useState([]);
  const [viewCustomer, setViewCustomer] = useState([]);
  const [groups, setGroups] = useState({});
  const [viewGroup, setViewGroup] = useState({});
  const broadcastList = useSelector((state) => state.chats.broadcastList);
  const theme = useTheme();
  const isIpad = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();
  useEffect(() => {
    if (isArrayWithValues(conversations)) setAllConversations(conversations);
  }, [conversations]);

  // useEffect(() => {
  //   if (isArrayWithValues(allConversations)) {
  //     let labels =
  //       allConversations
  //         .filter((d) => isArrayWithValues(d.label))
  //         .map((o) => o.label)
  //         .flat() || [];
  //     let labelIdObj = {};
  //     for (let index = 0; index < labels.length; index++) {
  //       const element = labels[index];
  //       let num = labelIdObj[element] || 0;
  //       labelIdObj[element] = num + 1;
  //     }
  //     setBroadcastlist(
  //       Object.keys(labelIdObj).map((id) => ({
  //         ...getGroupLabelformId(id),
  //         length: labelIdObj[id],
  //       }))
  //     );
  //     console.log(labelIdObj, "<< num");
  //   }
  // }, [crmGrouping, allConversations]);
  useEffect(() => {
    let groupObj = {};
    if (
      broadcastList &&
      isArrayWithValues(allConversations) &&
      isArrayWithValues(Object.values(broadcastList))
    ) {
      for (const customer of allConversations || []) {
        if (isArrayWithValues(customer?.label)) {
          for (const label of customer?.label) {
            let group = Object.values(broadcastList || {})?.find(
              (obj) => obj?.id === label
            );
            if (Boolean(group)) {
              groupObj = {
                ...groupObj,
                [group.id]: {
                  group_detail: group,
                  customers: isArray(groupObj?.[group.id]?.customers)
                    ? [...groupObj[group.id].customers, customer]
                    : [customer],
                },
              };
            }
          }
        }
      }
      setGroups(groupObj);
    }
  }, [broadcastList, allConversations]);

  // const getGroupLabelformId = (id) => {
  //   let arr =  crmGrouping || [];
  //   return arr.find((obj) => obj?.id === id) || {};
  // };
  return (
    <Box sx={{ width: "100%", height: "100%", mt: 4 }}>
      <Paper sx={{ width: "100%", height: "100%", py: 2, px: 2 }}>
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Typography
            sx={{
              ...sectionLabel,
              display: "flex",
              alignItems: "center",
              gap: 2,
            }}
          >
            <PeopleAltOutlined />
            Broadcasts list{" "}
            {isObjWithValues(broadcastList)
              ? `(${Object.keys(broadcastList)?.length})`
              : null}
            <PopperTooltip points={tooltipPoints?.["Broadcast List"] || []}>
              <HelpOutline
                fontSize="small"
                style={{ opacity: "0.5", height: "16px", width: "16px" }}
                color="text.secondary"
              />
            </PopperTooltip>
          </Typography>
          <Button
            endIcon={<ArrowRightAltOutlined />}
            onClick={() =>
              isIpad ? navigate(`/chats`) : navigate(`/chats?Broadcast`)
            }
          >
            Create BroadcastList
          </Button>
        </Stack>
        {!isObjWithValues(broadcastList) ? (
          <Stack>
            <NoTasks
              text={
                <Stack rowGap={2} justifyContent="center" alignItems={"center"}>
                  <Typography>No Broadcast created yet</Typography>
                  {
                    <Button
                      variant="outlined"
                      onClick={() =>
                        isIpad
                          ? navigate(`/chats`)
                          : navigate(`/chats?Broadcast`)
                      }
                    >
                      Create Broadcast list
                    </Button>
                  }
                </Stack>
              }
              icon={
                <PeopleAltOutlined
                  style={{ height: "70px", width: "70px", opacity: "0.8" }}
                  color="primary"
                />
                // <BellCircle

                //   fillCircle="#c4c4c4"
                // />
              }
            />
          </Stack>
        ) : null}
        <List>
          {Object.values(
            isObjWithValues(broadcastList) ? broadcastList : {}
          ).map((obj) => {
            return (
              <ListItem
                // onClick={() => onAssgin(obj)}
                key={obj?.id}
              >
                {/* <ListItemButton
                  role={undefined}
                  selected={deletingId === obj?.id}
                  onClick={() => onAssgin(obj)}
                  dense
                > */}
                <ListItemIcon>
                  <PeopleAltOutlined />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Stack direction={"row"} alignItems="center">
                      <Typography variant="h6">{obj?.label} </Typography>{" "}
                      <Typography variant="h6" sx={{ ml: 4 }}>
                        {groups?.[obj?.id]?.customers?.length
                          ? `(${groups?.[obj?.id]?.customers?.length})`
                          : null}
                      </Typography>
                    </Stack>
                  }
                />

                {isArrayWithValues(groups?.[obj?.id]?.customers) && (
                  <Button
                    variant="outlined"
                    onClick={(e) => {
                      e.stopPropagation();
                      setViewGroup(groups?.[obj?.id]);
                      setViewCustomer(groups?.[obj?.id]?.customers || []);
                    }}
                  >
                    {isArrayWithValues(groups?.[obj?.id]?.customers)
                      ? "View"
                      : "No customer"}
                  </Button>
                )}
                {/* </ListItemButton> */}
              </ListItem>
            );
          })}
        </List>
        {/* </DialogContent> */}

        <Dialog
          open={isArrayWithValues(viewCustomer)}
          onClose={() => {
            setViewGroup({});
            setViewCustomer([]);
          }}
          sx={{ ...dialogStyle }}
          fullWidth
          maxWidth="xs"
        >
          <DialogTitle
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
              justifyContent: "space-between",
            }}
          >
            {viewGroup?.group_detail?.label}{" "}
            <Typography variant="h6" fontSize={"20px"}>
              {viewGroup?.customers?.length
                ? `(${viewGroup?.customers?.length})`
                : ""}{" "}
              Contacts
            </Typography>
          </DialogTitle>
          <DialogContent>
            <ChatsCustomer selectedCustomer={viewCustomer} />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setViewGroup({});
                setViewCustomer([]);
              }}
              variant="contained"
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </Paper>
    </Box>
  );
};

const TopCards = () => {
  const conversationExtra = useSelector(
    (state) => state.chats.conversationExtra
  );
  const conversations = useSelector((state) => state.chats.conversations);
  const user_products = useSelector(
    (state) => state.user?.store_plan?.user_products
  );

  let mediaData = displayState({
    key: "overviewChannelCard",
    user_products,
  })
    ? [
        {
          title: (
            <Box>
              <Whatsapp />
            </Box>
          ),
          value: conversationExtra?.totalWhatsappConversationsMedia || 0,
          // prefix: currencySymbols[currency],
        },
        {
          title: (
            <Box>
              <Messenger />
            </Box>
          ),
          value: conversationExtra?.totalFacebookConversationsMedia || 0,
          // suffix: "g",
        },
        {
          title: (
            <Box>
              <InstagramSvg />
            </Box>
          ),
          value: conversationExtra?.totalInstagramConversationsMedia || 0,
          // suffix: "g",
        },
      ]
    : Object.keys(conversationExtra?.whatsappMedia || {})?.map((k) => ({
        title: formatServerValue(k),
        value: conversationExtra?.whatsappMedia?.[k] || 0,
      })) || [];

  return (
    <Box sx={{ mb: 3 }}>
      <Grid container spacing={3}>
        {displayState({
          key: "overviewChannelCard",
          user_products,
        }) && (
          <Grid item xs={6} sm={6} md={4} lg={3}>
            {" "}
            <DashboardTotalCard
              title={"Channel"}
              points={tooltipPoints?.channels}
              value={conversationExtra?.totalChannels || 0}
              values={[
                {
                  title: (
                    <Box>
                      <Whatsapp />
                    </Box>
                  ),
                  value: conversationExtra?.totalWhatsappConversations || 0,
                  // prefix: currencySymbols[currency],
                },
                {
                  title: (
                    <Box>
                      <Messenger />
                    </Box>
                  ),
                  value: conversationExtra?.totalFacebookConversations || 0,
                  // suffix: "g",
                },
                {
                  title: (
                    <Box>
                      <InstagramSvg />
                    </Box>
                  ),
                  value: conversationExtra?.totalInstagramConversations || 0,
                  // suffix: "g",
                },
              ]}
              graphData={conversationExtra?.conversationsByYear}
            />
          </Grid>
        )}

        <Grid item xs={6} sm={6} md={4} lg={3}>
          <DashboardTotalCard
            title="Contacts"
            points={tooltipPoints?.contacts}
            value={conversations?.length || 0}
            values={[
              {
                title: "Customer",
                value: conversationExtra?.totalTypeCustomers,
                // prefix: currencySymbols[currency],
              },
              {
                title: "Lead",
                value: conversationExtra?.totalTypeLeads,
                // suffix: "g",
              },
              {
                title: "Undefined",
                value: conversationExtra?.totalTypeUndefined,
                // suffix: "g",
              },
            ]}
            graphData={conversationExtra?.conversationsStartedInaYear}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={4} lg={3}>
          <DashboardTotalCard
            title="Activities"
            points={tooltipPoints?.activities}
            value={conversationExtra?.totalTasks}
            values={[
              {
                title: "Follow up",
                value: conversationExtra?.totalFollowUp || 0,
                // prefix: currencySymbols[currency],
              },
              {
                title: "Reminder",
                value: conversationExtra?.totalReminder || 0,
                // suffix: "g",
              },
              {
                title: "Tasks",
                value: conversationExtra?.totalTask || 0,
                // suffix: "g",
              },
              {
                title: "Calls",
                value: conversationExtra?.totalCall || 0,
                // suffix: "g",
              },
              // {
              //   title: "Unseen messages",
              //   value: conversationExtra?.totalUnseen || 0,
              //   // suffix: "g",
              // },
            ]}
            graphData={conversationExtra?.activitesByMonth}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={4} lg={3}>
          <DashboardTotalCard
            title="Media"
            points={tooltipPoints?.media}
            value={conversationExtra?.totalConversationsMedia || 0}
            values={mediaData}
            graphData={conversationExtra?.allMediaInYear}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export const RecentUserTasks = ({ openUpdateForm }) => {
  const allUserTasks = useSelector((state) => state.chats.allUserTasks);
  const allUserTasksFetched = useSelector(
    (state) => state.chats.allUserTasksFetched
  );
  const no_user_tasks = useSelector((state) => state.chats.no_user_tasks);
  const users_with_tasks_fetched = useSelector(
    (state) => state.chats.users_with_tasks_fetched
  );

  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [rows, setRows] = useState([]);

  const [formOpen, setFormOpen] = useState(false);
  const [editForm, setEditForm] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(6);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!users_with_tasks_fetched) dispatch(getUsersWithTasks());
  }, []);

  useEffect(() => {
    if (!isArrayWithValues(allUserTasks)) return;
    setRows(allUserTasks.filter((i) => i.status != "complete"));
  }, [allUserTasks]);
  const openAddForm = (edit) => {
    setEditForm({});
    setFormOpen(true);
  };
  const closeForm = () => setFormOpen(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  return (
    <>
      <Card sx={{ borderRadius: "15px", height: "100%" }}>
        <CardContent>
          <Typography
            sx={{
              ...sectionLabel,
              display: "flex",
              gap: 2,
              alignItems: "center",
            }}
          >
            Upcoming activities{" "}
            <PopperTooltip
              points={tooltipPoints?.["Upcoming Activities"] || []}
            >
              <HelpOutline
                fontSize="small"
                style={{ opacity: "0.5", height: "16px", width: "16px" }}
                color="text.secondary"
              />
            </PopperTooltip>
          </Typography>

          {(allUserTasksFetched || no_user_tasks) &&
          !isArrayWithValues(rows) ? (
            <Box sx={{ margin: "auto" }}>
              <NoTasks
                text={
                  <Stack
                    rowGap={2}
                    justifyContent="center"
                    alignItems={"center"}
                  >
                    <Typography>No Upcoming Activities</Typography>
                  </Stack>
                }
                icon={
                  <BellCircle
                    style={{ height: "70px", width: "70px", fill: "#c4c4c4" }}
                    fillCircle="#c4c4c4"
                  />
                }
              />
            </Box>
          ) : (
            stableSort(rows, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return <TaskRow row={row} openUpdateForm={openUpdateForm} />;
              })
          )}
          {rows.length > rowsPerPage && (
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </CardContent>
      </Card>
      <Dialog
        open={formOpen}
        keepMounted
        onClose={closeForm}
        aria-describedby="alert-dialog-slide-description"
        maxWidth="sm"
        fullWidth
        sx={{ ...dialogStyle }}
      >
        <CreateUserTask closeForm={closeForm} updateForm={editForm} />
      </Dialog>
    </>
  );
};

export const TaskRow = ({ row, openUpdateForm, showCustomerName = true }) => {
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const onCompleteUserTask = () => {
    let obj = { ...row };
    if (!obj.show_remind) {
      delete obj.remind_label;
      delete obj.remind_value;
    }
    obj.is_admin = obj.owner == "admin";
    obj.date = new Date(obj.date).getTime();
    obj.date_modified = new Date().getTime();
    obj.date_created = new Date(obj.date_created).getTime();
    obj.status = "complete";
    delete obj.userDetails;
    delete obj.teamMemberInfo;
    dispatch(updateUserTask(obj, setLoading));
  };
  return (
    <Stack
      direction="row"
      alignItems={"center"}
      spacing={1}
      sx={{ my: 1.5, flex: 1, cursor: "pointer" }}
      onClick={() => openUpdateForm && openUpdateForm(row)}
    >
      {/* {row.type == "task" ? (
    <BellCircle />
  ) : row.type == "call" ? (
    <CallCircle />
  ) : (
    <BellCircle />
  )} */}
      {loading ? (
        <IconButton>
          <CircularProgress
            style={{ height: "20px", width: "20px", margin: "0px 3px" }}
          />
        </IconButton>
      ) : (
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            onCompleteUserTask();
          }}
        >
          <CheckBoxOutlineBlank />
        </IconButton>
      )}
      <Stack
        direction="row"
        alignItems={"center"}
        justifyContent="space-between"
        flexWrap="wrap"
        sx={{ flex: 1 }}
      >
        <Tooltip
          title={`${formatServerValue(row.title)} to ${row?.userDetails?.name}`}
        >
          <Box>
            <Typography sx={{ ...primaryLabel }}>
              {reduceLargeText(
                `${formatServerValue(row.title)}${
                  showCustomerName
                    ? ` to ${row?.userDetails?.name}`
                    : ` by ${reduceLargeText(row?.teamMemberInfo?.name, 30)}`
                }`,
                42
              )}
            </Typography>
            {showCustomerName ? (
              <Typography sx={{ ...secondaryLabelSmall, mt: 0.5 }}>
                Assigned To: {reduceLargeText(row?.teamMemberInfo?.name, 30)}
              </Typography>
            ) : null}
          </Box>
        </Tooltip>
        <Typography sx={{ ...secondaryLabel, mt: 0.4 }}>
          {`${formatDate(new Date(row.date), "dd:mm:yyyy hh:mm am/pm")}`}
        </Typography>
      </Stack>
    </Stack>
  );
};

const TotalConversations = () => {
  const [seriesData, setSeriesData] = useState({});
  const conversationExtra = useSelector(
    (state) => state.chats.conversationExtra
  );
  const user_products = useSelector(
    (state) => state.user?.store_plan?.user_products
  );

  useEffect(() => {
    if (!isObjWithValues(conversationExtra)) return;
    let { conversationsByDate } = conversationExtra;
    let whatsappObj = {};
    let facebookObj = {};
    let instagramObj = {};
    if (isObjWithValues(conversationsByDate)) {
      for (let date in conversationsByDate) {
        let array = conversationsByDate[date];
        if (isArrayWithValues(array)) {
          for (let string of array) {
            let platform = string?.split("_")?.[0];
            if (platform === "whatsapp") {
              if (!whatsappObj[date]) whatsappObj[date] = 0;
              whatsappObj[date] += 1;
            }
            if (platform === "facebook") {
              if (!facebookObj[date]) facebookObj[date] = 0;
              facebookObj[date] += 1;
            }
            if (platform === "instagram") {
              if (!instagramObj[date]) instagramObj[date] = 0;
              instagramObj[date] += 1;
            }
          }
        }
      }
    }
    let seriesObj = {
      whatsapp: [],
      facebook: [],
      instagram: [],
    };
    if (isObjWithValues(whatsappObj)) {
      for (let key in whatsappObj) {
        let date = new Date(key).getTime();
        if (`${date}`.length < 11) date = date * 1000;
        if (date) seriesObj.whatsapp.push([date, whatsappObj[key]]);
      }
    }
    if (isObjWithValues(facebookObj)) {
      for (let key in facebookObj) {
        let date = new Date(key).getTime();
        if (`${date}`.length < 11) date = date * 1000;
        if (date) seriesObj.facebook.push([date, facebookObj[key]]);
      }
    }
    if (isObjWithValues(instagramObj)) {
      for (let key in instagramObj) {
        let date = new Date(key).getTime();
        if (`${date}`.length < 11) date = date * 1000;
        if (date) seriesObj.instagram.push([date, instagramObj[key]]);
      }
    }
    for (let platform in seriesObj) {
      seriesObj[platform] = seriesObj[platform].sort((a, b) => a[0] - b[0]);
    }
    setSeriesData(seriesObj);
  }, [conversationExtra]);
  return (
    <Card sx={{ borderRadius: "15px", height: "100%" }}>
      <CardContent>
        <Typography
          sx={{
            ...sectionLabel,
            display: "flex",
            alignItems: "center",
            gap: 2,
          }}
        >
          Total conversations
          <PopperTooltip points={tooltipPoints?.["Conversation Graph"] || []}>
            <HelpOutline
              fontSize="small"
              style={{ opacity: "0.5", height: "16px", width: "16px" }}
              color="text.secondary"
            />
          </PopperTooltip>
        </Typography>
        <ReactApexChart
          options={totalConversationsOptions}
          series={
            user_products?.includes("whatsapp")
              ? [
                  {
                    data: seriesData.whatsapp || [],
                    name: `Whatsapp`,
                  },
                ]
              : [
                  {
                    data: seriesData.whatsapp || [],
                    name: `Whatsapp`,
                  },
                  {
                    data: seriesData.facebook || [],
                    name: `Facebook`,
                  },
                  {
                    data: seriesData.instagram || [],
                    name: `Instagram`,
                  },
                ]
          }
          type="area"
          height={320}
        />
      </CardContent>
    </Card>
  );
};

export const ChannelTotalGraph = ({ channelTotal, loading }) => {
  const [maxTotal, setMaxTotal] = useState(0);

  let [hasChats, setHasChats] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (isArrayWithValues(channelTotal)) {
      setMaxTotal(channelTotal[0].value);

      // let colors = [];
      // channelTotal.map((i) =>
      //   colors.push(chatgGlobalColors[getRandomInt(chatgGlobalColors.length)])
      // );
      // setColors(colors);
    }
    setHasChats(isObjWithValues(channelTotal?.find((i) => i.value)));
  }, [channelTotal]);

  return (
    <>
      <Card sx={{ borderRadius: "15px", height: "100%" }}>
        <CardContent>
          <Stack direction={"row"} alignItems="center" spacing={2}>
            <Typography sx={{ ...sectionLabel }}>
              Channel wise contact
            </Typography>

            <PopperTooltip points={["Channel wise conversation"]}>
              <HelpOutline
                fontSize="small"
                style={{ opacity: "0.5", height: "16px", width: "16px" }}
                color="text.secondary"
              />
            </PopperTooltip>
          </Stack>
          {!loading && !hasChats ? (
            <Box sx={{ margin: "auto", height: "100%" }}>
              <NoTasks
                text={
                  <Box sx={{ textAlign: "center" }}>
                    <Typography>You have no new chats.</Typography>
                    <Typography sx={{ ...secondaryLabel, fontSize: "12px" }}>
                      Let's start your first chat.
                    </Typography>
                    <Button
                      variant="outlined"
                      sx={{ mt: 3 }}
                      size="small"
                      // startIcon={<Add />}
                      onClick={() => navigate("/chats")}
                    >
                      Go to chats
                    </Button>
                  </Box>
                }
                icon={
                  <NewMessageIcon style={{ height: "70px", width: "70px" }} />
                }
              />
            </Box>
          ) : null}
          <Box sx={{ marginTop: "auto", height: "100%" }}>
            {isArrayWithValues(channelTotal) &&
              channelTotal
                .filter((i) => i.value)
                .map((plan, index) => {
                  let { value, name } = plan;
                  return (
                    <StackRow>
                      <Box
                        sx={{
                          height: "45px",
                          width: `${(validateNumber(value) / maxTotal) * 100}%`,
                          borderRadius: "10px",
                          //   backgroundColor: "#25D366",
                          background: platformColors[name],
                          alignItems: "center",
                          display: "flex",
                          justifyContent: "center",
                          margin: "18px 0px",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "15px",
                            textAlign: "center",
                            verticalAlign: "center",
                            fontWeight: "600",
                            color: "#fff",
                            margin: "auto 0px",
                          }}
                        >
                          {(validateNumber(value) / maxTotal) * 100 > 30
                            ? `${name}: ${value}`
                            : (validateNumber(value) / maxTotal) * 100 < 10
                            ? ""
                            : (validateNumber(value) / maxTotal) * 100 < 30
                            ? value
                            : ""}
                        </Typography>
                      </Box>
                      <Typography sx={{ ...primaryLabel, ml: 2 }}>
                        {(validateNumber(value) / maxTotal) * 100 < 10
                          ? `${name}: ${value}`
                          : ""}
                      </Typography>
                    </StackRow>
                  );
                })}

            <Divider sx={{ my: 2 }} />
            <Stack
              direction="row"
              alignItems={"center"}
              justifyContent="space-around"
              sx={{ mt: 5 }}
            >
              {isArrayWithValues(channelTotal) &&
                channelTotal
                  .filter((i) => i.value)
                  .map((plan, index) => {
                    let { value, name } = plan;
                    return (
                      <Tooltip title={name}>
                        <Box sx={{ textAlign: "center" }}>
                          {platformIcons[name]}
                          {/* <Box
                            sx={{
                              height: "24px",
                              width: "24px",
                              borderRadius: "50%",
                              backgroundColor: colors[index],
                              //   backgroundColor: "#e8e8e8",
                              margin: "0px auto",
                            }}
                          /> */}
                          <Typography
                            sx={{ fontSize: "12px", fontWeight: "500", mt: 2 }}
                          >
                            {name && name.length > 10
                              ? name.substring(0, 10) + "..."
                              : name}
                          </Typography>
                        </Box>
                      </Tooltip>
                    );
                  })}
              {/* <Box sx={{ textAlign: "center" }}>
            <Whatsapp />
            <Typography sx={{ fontSize: "12px", fontWeight: "500", mt: 1 }}>
              whatsapp
            </Typography>
          </Box>
          <Box sx={{ textAlign: "center" }}>
            <Messenger />
            <Typography sx={{ fontSize: "12px", fontWeight: "500", mt: 1 }}>
              Facebook
            </Typography>
          </Box>
          <Box sx={{ textAlign: "center" }}>
            <InstagramSvg />
            <Typography sx={{ fontSize: "12px", fontWeight: "500", mt: 1 }}>
              Instagram
            </Typography>
          </Box> */}
            </Stack>
          </Box>
          {/* <ReactApexChart
      options={options}
      series={series}
      type="bar"
      height={300}
    /> */}
        </CardContent>
      </Card>
    </>
    // <Card sx={{ borderRadius: "15px" }}>
    //   <CardContent>
    //     <Stack
    //       direction={"row"}
    //       justifyContent="space-between"
    //       alignItems={"center"}
    //     >
    //       <Typography>Analytics</Typography>
    //       {/* <Button startIcon={<ArrowBackIos />} endIcon={<ArrowForwardIos />}>
    //                     Total
    //                 </Button> */}
    //     </Stack>
    //     <ReactApexChart
    //       options={options}
    //       series={series}
    //       type="bar"
    //       height={350}
    //     />
    //   </CardContent>
    // </Card>
  );
};

const NewContactTable = () => {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [rows, setRows] = useState([]);

  const newContacts = useSelector((state) => state.chats.newContacts);

  const navigate = useNavigate();

  useEffect(() => {
    if (!isArrayWithValues(newContacts)) return;
    setRows(newContacts);
  }, [newContacts]);

  // const handleRequestSort = (event, property) => {
  //   const isAsc = orderBy === property && order === "asc";
  //   setOrder(isAsc ? "desc" : "asc");
  //   setOrderBy(property);
  // };

  // const handleSelectAllClick = (event) => {
  //   if (event.target.checked) {
  //     const newSelected = rows.map((n) => n.name);
  //     setSelected(newSelected);
  //     return;
  //   }
  //   setSelected([]);
  // };

  // const handleClick = (event, name) => {
  //   const selectedIndex = selected.indexOf(name);
  //   let newSelected = [];

  //   if (selectedIndex === -1) {
  //     newSelected = newSelected.concat(selected, name);
  //   } else if (selectedIndex === 0) {
  //     newSelected = newSelected.concat(selected.slice(1));
  //   } else if (selectedIndex === selected.length - 1) {
  //     newSelected = newSelected.concat(selected.slice(0, -1));
  //   } else if (selectedIndex > 0) {
  //     newSelected = newSelected.concat(
  //       selected.slice(0, selectedIndex),
  //       selected.slice(selectedIndex + 1)
  //     );
  //   }

  //   setSelected(newSelected);
  // };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <Card sx={{ width: "100%", mt: 4, ...globalCardStyle }}>
      <CardContent>
        {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
        <Typography
          sx={{
            ...sectionLabel,
            my: 2,
            mx: 2,
            display: "flex",
            alignItems: "center",
            gap: 2,
          }}
        >
          Recent new contacts
          <PopperTooltip points={tooltipPoints?.["Recent New Contact"] || []}>
            <HelpOutline
              fontSize="small"
              style={{ opacity: "0.5", height: "16px", width: "16px" }}
              color="text.secondary"
            />
          </PopperTooltip>
        </Typography>
        {isArrayWithValues(rows) ? (
          <>
            <TableContainer>
              <Table
                aria-labelledby="tableTitle"
                size={dense ? "small" : "medium"}
              >
                <TableHead>
                  <TableRow>
                    {/* <TableCell padding="checkbox">
                <Checkbox
                  color="primary"
                  indeterminate={numSelected > 0 && numSelected < rowCount}
                  checked={rowCount > 0 && numSelected === rowCount}
                  onChange={onSelectAllClick}
                  inputProps={{
                    'aria-label': 'select all desserts',
                  }}
                />
              </TableCell> */}
                    {newContactHeadCells.map((headCell) => (
                      <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? "right" : "left"}
                        padding={headCell.disablePadding ? "none" : "normal"}
                        sortDirection={orderBy === headCell.id ? order : false}
                      >
                        <TableSortLabel
                          active={orderBy === headCell.id}
                          direction={orderBy === headCell.id ? order : "asc"}
                          // onClick={createSortHandler(headCell.id)}
                        >
                          {headCell.label}
                          {orderBy === headCell.id ? (
                            <Box component="span" sx={visuallyHidden}>
                              {order === "desc"
                                ? "sorted descending"
                                : "sorted ascending"}
                            </Box>
                          ) : null}
                        </TableSortLabel>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {/* if you don't need to support IE11, you can replace the `stableSort` call with:
             rows.sort(getComparator(order, orderBy)).slice() */}
                  {stableSort(rows, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const isItemSelected = isSelected(row.name);
                      const labelId = `enhanced-table-checkbox-${index}`;
                      let { platform } = row;
                      let lastMessage = "";
                      if (platform === "whatsapp") {
                        lastMessage = row?.message?.text?.body || "";
                      }
                      if (platform !== "whatsapp")
                        lastMessage = row?.message?.text || "";
                      return (
                        <TableRow
                          hover
                          onClick={(event) => navigate(`/chats/${row.id}`)}
                          // role="checkbox"
                          sx={{ py: 2, cursor: "pointer" }}
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.name}
                          selected={isItemSelected}
                        >
                          {/* <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            "aria-labelledby": labelId,
                          }}
                        />
                      </TableCell> */}
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            // padding="none"
                          >
                            <Stack
                              direction={"row"}
                              alignItems="center"
                              spacing={3}
                            >
                              <Avatar src={row.profile_pic} />
                              <Typography sx={{ ...primaryLabel }}>
                                {getNameFromPlatform(row) || ""}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell
                            align="right"
                            style={{ whiteSpace: "nowrap" }}
                          >
                            <Typography sx={{ ...primaryLabel }}>
                              {formatDate(
                                new Date(row.joining_date),
                                "dd-mm-yyyy"
                              )}
                            </Typography>
                            <Typography sx={{ ...secondaryLabel }}>
                              {formatDate(
                                new Date(row.joining_date),
                                "hh:mm am/pm"
                              )}
                            </Typography>
                          </TableCell>
                          <TableCell align="right">
                            {platformIcons[row.platform]}
                          </TableCell>
                          <TableCell align="right">
                            {reduceLargeText(
                              row?.lastmessageObj?.messageText,
                              20
                            )}
                          </TableCell>
                          {/* <TableCell align="right">
                        <IconButton>
                          <MoreVert />
                        </IconButton>
                      </TableCell> */}
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow
                      style={{
                        height: (dense ? 33 : 53) * emptyRows,
                      }}
                    >
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </>
        ) : (
          <NoTasks
            text={
              <Box sx={{ textAlign: "center" }}>
                <Typography>You have no Recent Contact.</Typography>

                {/* <Button
                  variant="outlined"
                  sx={{ mt: 3 }}
                  size="small"
                  // startIcon={<Add />}
                  onClick={() => navigate("/chats")}
                >
                  Go to chats
                </Button> */}
              </Box>
            }
            icon={
              <PersonOutlineOutlined
                style={{ height: "70px", width: "70px", opacity: "0.8" }}
                color="primary"
              />
            }
          />
        )}
      </CardContent>
    </Card>
  );
};
const RecentConversationsTable = () => {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [rows, setRows] = useState([]);
  const navigate = useNavigate();

  const recentConversations = useSelector(
    (state) => state.chats.recentConversations
  );

  useEffect(() => {
    if (!isArrayWithValues(recentConversations)) return;
    setRows(recentConversations);
  }, [recentConversations]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <Card sx={{ width: "100%", mt: 4, ...globalCardStyle }}>
      <CardContent>
        <Typography
          sx={{
            ...sectionLabel,
            display: "flex",
            alignItems: "center",
            gap: 2,
          }}
        >
          Recent new messages
          <PopperTooltip points={tooltipPoints?.["Recent New Messages"] || []}>
            <HelpOutline
              fontSize="small"
              style={{ opacity: "0.5", height: "16px", width: "16px" }}
              color="text.secondary"
            />
          </PopperTooltip>
        </Typography>
        {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
        {isArrayWithValues(recentConversations) ? (
          <>
            <TableContainer>
              <Table
                aria-labelledby="tableTitle"
                size={dense ? "small" : "medium"}
              >
                <TableHead>
                  <TableRow>
                    {/* <TableCell padding="checkbox">
                <Checkbox
                  color="primary"
                  indeterminate={numSelected > 0 && numSelected < rowCount}
                  checked={rowCount > 0 && numSelected === rowCount}
                  onChange={onSelectAllClick}
                  inputProps={{
                    'aria-label': 'select all desserts',
                  }}
                />
              </TableCell> */}
                    {newContactHeadCells.map((headCell) => (
                      <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? "right" : "left"}
                        padding={headCell.disablePadding ? "none" : "normal"}
                        sortDirection={orderBy === headCell.id ? order : false}
                      >
                        <TableSortLabel
                          active={orderBy === headCell.id}
                          direction={orderBy === headCell.id ? order : "asc"}
                          // onClick={createSortHandler(headCell.id)}
                        >
                          {headCell.label}
                          {orderBy === headCell.id ? (
                            <Box component="span" sx={visuallyHidden}>
                              {order === "desc"
                                ? "sorted descending"
                                : "sorted ascending"}
                            </Box>
                          ) : null}
                        </TableSortLabel>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {/* if you don't need to support IE11, you can replace the `stableSort` call with:
             rows.sort(getComparator(order, orderBy)).slice() */}
                  {stableSort(rows, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const isItemSelected = isSelected(row.name);
                      const labelId = `enhanced-table-checkbox-${index}`;
                      let { platform } = row;
                      return (
                        <TableRow
                          hover
                          onClick={(event) => navigate(`/chats/${row.id}`)}
                          // role="checkbox"
                          sx={{ py: 2, cursor: "pointer" }}
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.name}
                          selected={isItemSelected}
                        >
                          {/* <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            "aria-labelledby": labelId,
                          }}
                        />
                      </TableCell> */}
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            // padding="none"
                          >
                            <Stack
                              direction={"row"}
                              alignItems="center"
                              spacing={3}
                            >
                              <Avatar src={row.profile_pic} />
                              <Typography sx={{ ...primaryLabel }}>
                                {getNameFromPlatform(row) || ""}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell
                            align="right"
                            style={{ whiteSpace: "nowrap" }}
                          >
                            <Typography sx={{ ...primaryLabel }}>
                              {formatDate(
                                new Date(
                                  row?.conversation?.[
                                    row?.conversation?.length - 1
                                  ]?.time
                                ),

                                "dd-mm-yyyy"
                              )}
                            </Typography>
                            <Typography sx={{ ...secondaryLabel }}>
                              {formatDate(
                                new Date(
                                  row?.conversation?.[
                                    row?.conversation?.length - 1
                                  ]?.time
                                ),

                                "hh:mm am/pm"
                              )}
                            </Typography>
                          </TableCell>
                          <TableCell align="right">
                            {platformIcons[row.platform]}
                          </TableCell>
                          <TableCell align="right">
                            <Typography sx={{ ...primaryLabel }}>
                              {reduceLargeText(
                                row?.lastmessageObj?.messageText,
                                20
                              )}
                            </Typography>
                          </TableCell>
                          {/* <TableCell align="right">
                        <IconButton>
                          <MoreVert />
                        </IconButton>
                      </TableCell> */}
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow
                      style={{
                        height: (dense ? 33 : 53) * emptyRows,
                      }}
                    >
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </>
        ) : (
          <NoTasks
            text={
              <Box sx={{ textAlign: "center" }}>
                <Typography>You have no Recent Messages.</Typography>

                <Button
                  variant="outlined"
                  sx={{ mt: 3 }}
                  size="small"
                  // startIcon={<Add />}
                  onClick={() => navigate("/chats")}
                >
                  Go to chats
                </Button>
              </Box>
            }
            icon={<NewMessageIcon style={{ height: "70px", width: "70px" }} />}
          />
        )}
      </CardContent>
    </Card>
  );
};
const FollowupTable = () => {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [rows, setRows] = useState([]);

  const followupContacts = useSelector((state) => state.chats.followupContacts);

  const navigate = useNavigate();

  useEffect(() => {
    if (!isArrayWithValues(followupContacts)) return;
    setRows(followupContacts);
  }, [followupContacts]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <Card sx={{ width: "100%", mt: 4, ...globalCardStyle }}>
      <CardContent>
        <Typography sx={{ ...sectionLabel }}>Follow up contacts</Typography>
        {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
        {isArrayWithValues(rows) ? (
          <>
            <TableContainer>
              <Table
                aria-labelledby="tableTitle"
                size={dense ? "small" : "medium"}
              >
                <TableHead>
                  <TableRow>
                    {/* <TableCell padding="checkbox">
                <Checkbox
                  color="primary"
                  indeterminate={numSelected > 0 && numSelected < rowCount}
                  checked={rowCount > 0 && numSelected === rowCount}
                  onChange={onSelectAllClick}
                  inputProps={{
                    'aria-label': 'select all desserts',
                  }}
                />
              </TableCell> */}
                    {newContactHeadCells.map((headCell) => (
                      <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? "right" : "left"}
                        padding={headCell.disablePadding ? "none" : "normal"}
                        sortDirection={orderBy === headCell.id ? order : false}
                      >
                        <TableSortLabel
                          active={orderBy === headCell.id}
                          direction={orderBy === headCell.id ? order : "asc"}
                          // onClick={createSortHandler(headCell.id)}
                        >
                          {headCell.label}
                          {orderBy === headCell.id ? (
                            <Box component="span" sx={visuallyHidden}>
                              {order === "desc"
                                ? "sorted descending"
                                : "sorted ascending"}
                            </Box>
                          ) : null}
                        </TableSortLabel>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {/* if you don't need to support IE11, you can replace the `stableSort` call with:
             rows.sort(getComparator(order, orderBy)).slice() */}
                  {stableSort(rows, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const isItemSelected = isSelected(row.name);
                      const labelId = `enhanced-table-checkbox-${index}`;
                      let { platform } = row;
                      return (
                        <TableRow
                          hover
                          onClick={(event) => navigate(`/chats/${row.id}`)}
                          // role="checkbox"
                          sx={{ py: 2, cursor: "pointer" }}
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.name}
                          selected={isItemSelected}
                        >
                          {/* <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            "aria-labelledby": labelId,
                          }}
                        />
                      </TableCell> */}
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            // padding="none"
                          >
                            <Stack
                              direction={"row"}
                              alignItems="center"
                              spacing={3}
                            >
                              <Avatar src={row.profile_pic} />
                              <Typography sx={{ ...primaryLabel }}>
                                {getNameFromPlatform(row) || ""}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell align="right">
                            <Typography sx={{ ...primaryLabel }}>
                              {formatDate(
                                new Date(row?.conversation?.[0]?.time),
                                "dd-mm-yyyy"
                              )}
                            </Typography>
                            <Typography sx={{ ...secondaryLabel }}>
                              {formatDate(
                                new Date(row?.conversation?.[0]?.time),
                                "hh:mm am/pm"
                              )}
                            </Typography>
                          </TableCell>
                          <TableCell align="right">
                            {platformIcons[row.platform]}
                          </TableCell>
                          <TableCell align="right">
                            <Typography sx={{ ...primaryLabel }}>
                              {reduceLargeText(
                                row?.lastmessageObj?.messageText,
                                20
                              )}
                            </Typography>
                          </TableCell>
                          {/* <TableCell align="right">
                        <IconButton>
                          <MoreVert />
                        </IconButton>
                      </TableCell> */}
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow
                      style={{
                        height: (dense ? 33 : 53) * emptyRows,
                      }}
                    >
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            {rows.length > 5 ? (
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            ) : null}
          </>
        ) : (
          <NoTasks
            text={
              <Stack rowGap={2} justifyContent="center" alignItems={"center"}>
                <Typography>No contacts on follow up</Typography>
              </Stack>
            }
            icon={
              <PeopleAltOutlined
                style={{ height: "70px", width: "70px", opacity: "0.8" }}
                color="primary"
              />
              // <BellCircle

              //   fillCircle="#c4c4c4"
              // />
            }
          />
        )}
      </CardContent>
    </Card>
  );
};

export const ChatStatusChip = ({ status }) => {
  const theme = useTheme();
  const isDarkTheme = theme.palette && theme.palette.mode === "dark";
  return (
    <Chip
      size="small"
      label={status}
      // color="warning"
      sx={{
        color: statusFontColor(status),
        border: isDarkTheme ? "0.7px solid" : "0px solid",
        // borderColor: statusFontColor(status),
        backgroundColor: isDarkTheme
          ? "transparent"
          : getStatusBackgroundColor(status),
      }}
      variant="outlined"
    />
  );
};

export const getStatusBackgroundColor = (status) => {
  status = status ? status.toLowerCase() : "";
  switch (status) {
    case "complete":
      return "#e0f6ed";
    case "deferred":
      return "#f4f3e6";
    case "processing":
      return "#EAECFB";
    case "failed":
      return "#F8D1DA";
    case "refunded":
      return "#F8D1DA";
    case "cancelled":
      return "#f2e5e8";
    case "on-hold":
      return "#AAEDF9";
    default:
      return "#EAECFB";
    // case "complete":
    //   return "#f7e0d2";
    // case "deferred":
    //   return "#f7e0d2";
    // default:
    //   return "#e8e8e8";
  }
};

export const statusFontColor = (status) => {
  status = status ? status.toLowerCase() : "";
  switch (status) {
    case "complete" || "active":
      return "#1aae6f";
    case "deferred":
      return "#93ad05";
    case "processing":
      return "#5570bc";
    case "failed":
      return "#ED3833";
    case "refunded":
      return "#ED3833";
    case "cancelled":
      return "#910905";
    case "on-hold":
      return "#46ADCA";
    default:
      return "#043b69";
  }
};
export const tooltipPoints = {
  channels: ["Channels Connected", "Nos of chats count"],
  contacts: [
    "Nos of Contacts with whom conversation initiated or messages received",
    "Count of Customers & Leads",
  ],
  activities: ["Count of Activities Created", "Activities wise count "],
  media: [
    "Nos of chats in which media file is attached with",
    "Channel wise media files count",
  ],
  "Upcoming Activities": [
    "View schedule activities based on time on event time ",
    "Click on Check box to Mark as Complete",
  ],
  "Conversation Graph": [
    "Channel wise conversation for a particular duration",
    "Move cursur to a color coded channel to view specific graph of that channel",
  ],
  "Recent New Messages": [
    "Latest messages (irrespective of channel) will display on top",
  ],
  "Broadcast List": [
    "Groups created to send bulk marketing / promtion messages",
    "Count of contacts listed in a group ",
  ],
  Activities: [
    "All activities created for any contacts is displaying here.",
    "Latest Created activity will be displayed on top",
    "Edit, Delete or change the status of activity from here",
  ],
  "Recent New Contact": [
    "Name of the new contact from whom a first message received, will be listed here",
  ],
};
