import { Box, Card, Stack, Typography } from "@mui/material";
import React from "react";
import { PhoneSvg } from "../../../Svgs";
import { reduceLargeText } from "../../../helper";

const PhoneMessagePreview = ({ templateString, heading, cardContent, flow }) => {
    let flowSize = { width: 275, height: 550, viewBox: "0 0 422 850" }
    return (

        <Box sx={{ position: "relative", overflow: "hidden", maxWidth: "275px" }}>
            <PhoneSvg height={"60vh"} {...(flow ? flowSize : {})} />
            <Card
                sx={{
                    position: "absolute",
                    top: "30%",
                    width: "84%",
                    right: "8%",
                    padding: "10px",
                    boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                    borderRadius: "18px",
                }}
            >
                {cardContent || (
                    <>
                        <Stack direction={"row"} justifyContent="space-between">
                            <Typography
                                variant="h6"
                                sx={{ display: "flex", alignItems: "center", gap: 2 }}
                            >
                                {heading}
                            </Typography>
                            <Typography textAlign={"end"} sx={{ justifyItems: "flex-end" }}>
                                now
                            </Typography>
                        </Stack>
                        <Stack gap={1}>
                            <Typography>{reduceLargeText(templateString, 180)}</Typography>
                        </Stack>
                    </>
                )}
            </Card>
        </Box>
    );
};

export default PhoneMessagePreview;
