import { East, HelpOutline } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardContent,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { sectionLabel } from "../../../helper";
import { BellCircle, CheckCircle } from "../../../Svgs";
import { NoTasks, secondaryLabel } from "../../chat/ChatHelper";
import PopperTooltip from "../../components/PopperTooltip";

function UpcomingActivities() {
  const [onlyCRMProduct, setOnlyCRMProduct] = React.useState(false);
  const navigate = useNavigate();

  const user_products = useSelector(
    (state) => state.user?.store_plan?.user_products
  );

  React.useEffect(() => {
    setOnlyCRMProduct(
      user_products?.length === 1 && user_products.includes("crm")
    );
  }, [user_products]);

  return (
    <Card sx={{ borderRadius: "15px", height: "100%" }}>
      <CardContent>
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
          sx={{ flexWrap: "wrap" }}
        >
          <Stack direction={"row"} alignItems={"center"} spacing={1}>
            <Typography sx={{ ...sectionLabel }}>
              Upcoming activities
            </Typography>
            <PopperTooltip
              points={[
                "View schedule activities based on time on event time",
                "Click on Check box to Mark as Complete",
              ]}
            >
              <HelpOutline
                fontSize="small"
                style={{
                  opacity: "0.5",
                  height: "16px",
                  width: "16px",
                }}
                color="text.secondary"
              />
            </PopperTooltip>
          </Stack>
          <Stack
            direction={"row"}
            alignItems="center"
            sx={{ cursor: "pointer", justifySelf: "flex-end" }}
            onClick={() =>
              onlyCRMProduct
                ? navigate("/user_activities")
                : navigate("/chats#activities")
            }
          >
            <Typography sx={{ ...secondaryLabel, color: "primary" }}>
              Go to activites
            </Typography>
            <East
              style={{ height: "15px", width: "15px", marginLeft: "5px" }}
            />
          </Stack>
          {/* <Button onClick={() => navigate(`/chats#activity`)}>Go to activites</Button> */}
        </Stack>

        <Box sx={{ margin: "auto" }}>
          <NoTasks
            text="No tasks added yet"
            icon={
              <CheckCircle
                style={{ height: "70px", width: "70px", fill: "#c4c4c4" }}
                fillCircle="#c4c4c4"
              />
            }
          />
        </Box>
      </CardContent>
    </Card>
  );
}

export default UpcomingActivities;
