import { Download, Search, Sync } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  InputAdornment,
  MenuItem,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import Switch from "rc-switch";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UAParser } from "ua-parser-js";
import { getIp } from "../../../../App";
import {
  addApiCalls,
  createBatchProducts,
  createObjFromMapping,
  fetchIPInfo,
  formatDate,
  formatServerValue,
  getRandomString,
  getTimeInSeconds,
  getToken,
  getWebsite,
  isArrayWithValues,
  isObjWithValues,
  secondaryLabel,
  secondaryLabelSmall,
  settingsEndpoint,
  sleep,
  TabPanel,
} from "../../../../helper";
import { setGlobalToast } from "../../../../redux/actions/helperActions";
import {
  editSettings,
  EDIT_SETTINGS,
} from "../../../../redux/actions/settingActions";
import { syncTimes } from "../ornate";
import Activity from "../shopify/Activity";
import {
  getJemisysProducts,
  getJweroProductFromJemisys,
  _getJemisysProductsFromTanika,
} from "./hepler";
import JemisysOverview from "./Overview";
const tabs = [
  {
    label: "Overview",
    value: "overview",
  },
  // {
  //     label: "Mapping",
  //     value: "taxonomy",
  // },
  {
    label: "Products",
    value: "products",
  },
  { label: "Settings", value: "settings" },
  { label: "Activity", value: "history" },
];
const Settings = () => {
  const [selectedTab, setSelectedTab] = useState("products");
  const jemisysHistory = useSelector((state) => state.settings.jemisysHistory);

  const jemisysIntegration = useSelector(
    (state) => state.settings.jemisysIntegration
  );

  const dispatch = useDispatch();
  useEffect(async () => {
    if (!isObjWithValues(jemisysHistory)) {
      let history = await settingsEndpoint({
        endpoint: `jemisys_history_${jemisysIntegration?.inventory_ip_port}`,
      });
      dispatch(
        editSettings({
          jemisysHistory: history,
        })
      );
    }
  }, []);

  return (
    <Box>
      <Stack
        direction="row"
        sx={{
          backgroundColor: "background.paper",
          padding: "10px",
          borderBottomColor: "#e8e8e8",
          borderBottomWidth: 0.2,
          borderBottomStyle: "solid",
          overflow: "scroll",
        }}
        spacing={4}
        className="scrollbar-hidden"
      >
        {tabs.map((i) => {
          return (
            <Button
              variant={"text"}
              // variant={selectedTab !== i.value ? "outlined" : "contained"}
              sx={{
                backgroundColor:
                  selectedTab === i.value ? "#e6e6e6" : "transparent",
                color: "#000",
                // color: selectedTab === i.value ? "primary.main" : "#000",
                "&:hover": {
                  backgroundColor:
                    selectedTab === i.value ? "#e6e6e6" : "transparent",
                },
              }}
              onClick={() => setSelectedTab(i.value)}
            >
              {i.label}
            </Button>
          );
        })}
      </Stack>
      <TabPanel value={"overview"} index={selectedTab}>
        <JemisysOverview />
      </TabPanel>
      <TabPanel value={"products"} index={selectedTab}>
        <Products />
      </TabPanel>
      <TabPanel value={"history"} index={selectedTab}>
        <Activity historyObject={jemisysHistory} hideImport={true} />
      </TabPanel>
      <TabPanel value={"settings"} index={selectedTab}>
        <JemisysSettings />
      </TabPanel>
    </Box>
  );
};

export default Settings;

const Products = () => {
  const jemisysIntegration = useSelector(
    (state) => state.settings.jemisysIntegration
  );
  const jemisysProducts = useSelector(
    (state) => state.settings.jemisysProducts
  );

  const jemisysSettings = useSelector(
    (state) => state.settings.jemisysIntegration?.jemisys_settings
  );
  const previousImopretdProducts = useSelector(
    (state) => state.settings?.previousImopretdProducts
  );
  const [searchTerm, setSearchTerm] = useState("");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [fetchingProduct, setFetchingProduct] = useState(false);
  const [importingState, setImportingState] = useState({});
  const [syncing, setSyncing] = useState(false);
  const [rows, setRows] = useState([]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const takeOutMeta = (array) => {
    let arr = array?.map((obj) => {
      for (const metaObj of obj?.meta_data || []) {
        obj[metaObj?.key] = metaObj?.value;
      }
      return obj;
    });
    return arr;
  };

  useEffect(async () => {
    if (!isArrayWithValues(previousImopretdProducts)) {
      let website = getWebsite();
      let token = getToken();
      let previousProducts = await _getJemisysProductsFromTanika(
        website,
        token
      );

      dispatch(
        editSettings({
          previousImopretdProducts: takeOutMeta(previousProducts),
        })
      );
    }
  }, []);

  const dispatch = useDispatch();

  const setUpJemisysProducts = async () => {
    setFetchingProduct(true);
    let jemisysProductsRes = await getJemisysProducts({
      ...jemisysIntegration,
    });
    await addApiCalls({
      name: "jemisys",
      calls: 1,
      dispatch,
    });
    dispatch({
      type: EDIT_SETTINGS,
      payload: {
        jemisysProducts: isArrayWithValues(jemisysProductsRes)
          ? jemisysProductsRes
          : [],
      },
    });
    setFetchingProduct(false);
  };
  useEffect(() => {
    if (!isArrayWithValues(jemisysProducts)) {
      setUpJemisysProducts();
    }

    return () => { };
  }, []);
  useEffect(() => {
    setRows(isArrayWithValues(jemisysProducts) ? jemisysProducts : []);
  }, [jemisysProducts]);

  const handleClick = (event, product) => {
    setSelected((pre) => {
      let array = [...pre];
      if (pre?.find((o) => o?.productCode === product?.productCode)) {
        array = array?.filter((o) => o?.productCode !== product?.productCode);
      } else array.push(product);

      return array;
    });
  };
  const onSearch = (e) => {
    setSearchTerm(e.target.value || "");
  };

  useMemo(async () => {
    await sleep(300);

    if (searchTerm) {
      setPage(0);
      setRows(
        jemisysProducts?.filter((obj) =>
          Object.values(obj || {})
            ?.join("")
            ?.toLocaleLowerCase()
            ?.includes(searchTerm?.toLocaleLowerCase())
        )
      );
    } else {
      setRows(jemisysProducts || []);
    }
  }, [searchTerm]);
  const addHistory = async ({ selected, resArray, type }) => {
    let historyObj = {
      id: getRandomString(8),
      start: Math.floor(Date.now() / 1000),
      ip: await getIp(),
      ua: new UAParser().getUA(),
      import_info: {},
      // ip:
      // imported: 0,
      // products: [],
    };
    let location = await fetchIPInfo(historyObj.ip);
    if (isObjWithValues(location)) {
      let { city, region, postal, country } = location;
      historyObj.location = {
        city,
        state: region,
        postcode: postal,
        country,
      };
    }
    let import_info = {};

    for (let index = 0; index < selected?.length; index++) {
      const shopifyCustomer = selected?.[index];

      let jeroCustomer = resArray?.find(
        (obj) => obj?.jemisys_product?.id == shopifyCustomer?.productCode
      );

      if (jeroCustomer) {
        import_info.success = [
          ...(import_info.success || []),
          { id: shopifyCustomer?.productCode },
        ];
      }
      try {
        if (isObjWithValues(resArray?.[index]?.error)) {
          import_info[resArray?.[index]?.error?.code?.replaceAll("-", "_")] = [
            ...(import_info[
              resArray?.[index]?.error?.code?.replaceAll("-", "_")
            ] || []),
            { id: shopifyCustomer?.productCode },
          ];
        }
      } catch (error) {
        console.log(error);
      }
    }

    historyObj.import_info = import_info;

    let res = await settingsEndpoint({
      endpoint: `jemisys_history_${jemisysIntegration?.inventory_ip_port}`,
      method: "PUT",
      data: {
        [historyObj?.id]: {
          ...historyObj,
          end: getTimeInSeconds(),
          type,
        },
      },
    });

    dispatch(
      setGlobalToast({
        show: true,
        severity: "success",
        message: `${formatServerValue(type)} products successfully!`,
      })
    );
    dispatch(
      editSettings({
        jemisysHistory: res,
      })
    );
  };
  const onImporting = async () => {
    setImportingState((pre) => ({ ...pre, loading: true }));

    //
    let haveToImport = selected;

    // batch creating
    let countArray = new Array(Math.ceil(haveToImport?.length / 100))
      ?.fill("")
      ?.map((str, index) => index);
    let arr = [];
    for await (const index of countArray) {
      let batchArray = getJweroProductFromJemisys({
        products: haveToImport?.slice(index * 100, index * 100 + 100),
        make_live: jemisysSettings?.make_live_when_imported,
      });

      //  haveToImport?.slice(index * 100, index * 100 + 100)?.map(obj => {
      //     let jweroCustomer = createObjFromMapping({
      //         mapping: [...(shopifyMapping?.customers?.mapping || []), ...defaultShopifyCustomerMapping],
      //         payload: obj,
      //     });
      //     jweroCustomer.password = "password"
      //     jweroCustomer.meta_data = [...(obj.meta_data || []), {
      //         key: "shopify_customer",
      //         value: {
      //             shopify_id: obj?.id,
      //         },
      //     },]

      //     return jweroCustomer
      // })
      let createRes = await createBatchProducts({
        data: {
          create: batchArray,
        },
      });

      // console.log(batchArray);
      // let createRes = []
      let customerArr =
        createRes?.data?.create?.map((obj) => {
          for (const metaObj of obj?.meta_data || []) {
            obj[metaObj?.key] = metaObj?.value;
          }
          return obj;
        }) || [];
      arr = [...arr, ...(customerArr || [])];
    }
    // return
    await addHistory({
      selected: haveToImport,
      resArray: arr,
      // ...(reduxShopifyIntegration || {}),
      type: "import",
    });
    dispatch(
      editSettings({
        previousImopretdProducts: [
          ...(previousImopretdProducts || []),
          ...(arr || []),
        ],
      })
    );
    setSelected([]);
    setImportingState((pre) => ({ ...pre, loading: false }));

    // let createRes = await batchCreateCustomer(arr);
    // if (setPreviousImported)
  };
  const onSelectAllClick = (event) => {
    let importedIds = previousImopretdProducts?.map(
      (obj) => obj?.jemisys_product?.id
    );
    if (event.target.checked) {
      const newSelected = rows?.filter(
        (i) => !importedIds.includes(i.productCode)
      );
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const onSyncPress = async () => {
    setSyncing(true);
    let website = await getWebsite();
    let token = await getToken();
    // let oldOrnateData = jemisysProducts;
    // console.log(ornateIntegration);
    // let { ornate_settings } = ornateIntegration || {};

    // const ornateProductsInTanika = await _getOrnateProductsFromTanika(
    //   website,
    //   token
    // );
    let historyObj = {
      id: getRandomString(8),
      start: Math.floor(Date.now() / 1000),
      ip: await getIp(),
      ua: new UAParser().getUA(),
      // ip:
      imported: 0,
      products: [],
    };
    let location = await fetchIPInfo(historyObj.ip);
    if (isObjWithValues(location)) {
      let { city, region, postal, country } = location;
      historyObj.location = {
        city,
        state: region,
        postcode: postal,
        country,
      };
    }
    let jemisysArray = [];
    for (const jeweroProduct of previousImopretdProducts || []) {
      let jemisysObj = {};
      let jemisysProduct = jemisysProducts?.find(
        (obj) => obj?.productCode === jeweroProduct?.jemisys_product?.id
      );
      jemisysObj = jemisysProduct;
      if (isObjWithValues(jemisysObj)) {
        jemisysArray.push({ ...jemisysObj, id: jeweroProduct?.id });
      }
    }
    let updatedArray = getJweroProductFromJemisys({
      products: jemisysArray,
      setId: true,
    });
    // let ornateData = ornateProducts;

    let updateLength = Math.ceil(previousImopretdProducts?.length / 100);
    let countArray = new Array(updateLength)
      ?.fill("")
      ?.map((str, index) => index);
    let arr = [];
    for await (const index of countArray) {
      let batchArray = updatedArray?.slice(index * 100, index * 100 + 100);

      let createRes = await createBatchProducts({
        data: {
          update: batchArray,
        },
      });

      // console.log(batchArray);
      // let createRes = []
      let customerArr =
        createRes?.data?.update?.map((obj) => {
          for (const metaObj of obj?.meta_data || []) {
            obj[metaObj?.key] = metaObj?.value;
          }
          return obj;
        }) || [];
      arr = [...arr, ...(customerArr || [])];
    }
    // return
    await addHistory({
      selected: jemisysArray,
      resArray: arr,
      // ...(reduxShopifyIntegration || {}),
      type: "sync",
    });
    setSyncing(false);
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Toolbar
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box>
            {isArrayWithValues(selected) ? (
              <Typography variant="h6">
                Selected : {selected?.length}
              </Typography>
            ) : (
              <Typography sx={{ ...secondaryLabelSmall }}>
                {/* Last Sync: */}
              </Typography>
            )}
          </Box>
          <Stack direction={"row"} alignItems={"center"} gap={3}>
            <TextField
              variant="standard"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
              size="small"
              value={searchTerm}
              onChange={onSearch}
            />
            <LoadingButton
              loading={fetchingProduct}
              onClick={() => {
                setUpJemisysProducts();
              }}
            >
              Refresh
            </LoadingButton>
            {isArrayWithValues(jemisysProducts) && (
              <LoadingButton
                variant="outlined"
                startIcon={<Sync />}
                loading={syncing}
                size="small"
                onClick={() => {
                  onSyncPress();
                }}
              >
                Sync
              </LoadingButton>
            )}
            {isArrayWithValues(selected) ? (
              <LoadingButton
                variant="contained"
                startIcon={<Download />}
                size="small"
                loading={importingState?.loading}
                onClick={() => {
                  onImporting();
                }}
              >
                Import
              </LoadingButton>
            ) : null}
          </Stack>
        </Toolbar>
        <Table sx={{}} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  color="primary"
                  // indeterminate={numSelected > 0 && numSelected < rowCount}
                  // checked={rowCount > 0 && numSelected === rowCount}
                  onChange={onSelectAllClick}
                  inputProps={{
                    "aria-label": "select all desserts",
                  }}
                />
              </TableCell>
              <TableCell>Label No.</TableCell>
              <TableCell>Product</TableCell>
              <TableCell>Stock</TableCell>
              {/* <TableCell>Amount</TableCell> */}
              <TableCell>Last Synced</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              ?.map((row) => {
                let disabled = previousImopretdProducts?.find(
                  (obj) => obj?.jemisys_product?.id === row?.productCode
                );
                return (
                  <TableRow
                    key={row.productCode}
                    hover
                    onClick={(event) => !disabled && handleClick(event, row)}
                  //  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <Tooltip title={disabled ? "Already imported" : ""}>
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={Boolean(
                            selected.find(
                              (prod) => prod?.productCode === row?.productCode
                            )
                          )}
                          disabled={disabled}
                        // inputProps={{
                        //     'aria-labelledby': labelId,
                        // }}
                        />
                      </TableCell>
                    </Tooltip>
                    <TableCell component="th" scope="row">
                      {row?.productCode}
                    </TableCell>
                    <TableCell>{row.productName}</TableCell>
                    <TableCell>{row.availability}</TableCell>
                    {/* <TableCell>{row.carbs}</TableCell> */}
                    <TableCell>
                      {disabled?.date_modified && (
                        <>
                          {formatDate(
                            new Date(disabled?.date_modified),
                            "relative_date"
                          )}{" "}
                          {formatDate(
                            new Date(disabled?.date_modified),
                            "hh:mm am/pm"
                          )}
                        </>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={jemisysProducts?.length || 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};

const JemisysSettings = () => {
  const [updating, setUpdating] = useState(false);
  const [settingsForm, setSettingsForm] = useState({
    sync_time: "",
    maketoorder: false,
    make_live_when_imported: false,
  });
  // const [loading, setLoading] = useState(false)
  const dispatch = useDispatch();
  const theme = useTheme();
  const jemisysSettings = useSelector(
    (state) => state.settings.jemisysIntegration?.jemisys_settings
  );
  let backgroundColor = theme.palette.primary && theme.palette.primary.main;

  useEffect(async () => {
    // if (!isObjWithValues(jemisysSettings)) {
    //   setUpdating(true);
    //   let res = await settingsEndpoint({
    //     endpoint: "jemisys_settings",
    //   });
    //   dispatch(
    //     editSettings({
    //       jemisysSettings: res,
    //     })
    //   );
    //   setUpdating(false);
    // }
  }, []);

  const editForm = (payload) => {
    setSettingsForm((pre) => ({ ...pre, ...payload }));
  };
  useEffect(() => {
    if (isObjWithValues(jemisysSettings))
      setSettingsForm({ ...jemisysSettings });
  }, [jemisysSettings]);
  const onUpdate = async () => {
    setUpdating(true);
    let res = await settingsEndpoint({
      endpoint: "jemisys_integration",
      data: {
        jemisys_settings: { ...settingsForm },
      },
      method: "PUT",
    });
    dispatch(
      editSettings({
        jemisysIntegration: res,
      })
    );
    dispatch(
      setGlobalToast({
        show: true,
        message: "Settings updated successfully",
        severity: "success",
      })
    );
    setUpdating(false);
  };
  return (
    <Box sx={{ px: { xs: 0, md: 3 }, mt: 3 }}>
      <Stack direction="row" justifyContent={"flex-end"} mt={4}>
        <LoadingButton
          loading={updating}
          onClick={onUpdate}
          variant="contained"
          size="small"
        >
          Update Settings
        </LoadingButton>
      </Stack>
      <Stack
        direction={"row"}
        alignItems="center"
        justifyContent="space-between"
        sx={{ mt: 5 }}
      >
        <Typography variant="h5" fontSize="15px">
          Sync Time
        </Typography>
        <FormControl size="small" sx={{ width: "180px" }}>
          {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}

          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            // value={"tanika_every_half_hour"}
            value={settingsForm.sync_time}
            onChange={(e) => editForm({ sync_time: e.target.value })}
          >
            {syncTimes.map((i) => {
              return <MenuItem value={i.value}>{i.label}</MenuItem>;
            })}
          </Select>
        </FormControl>
      </Stack>
      <Divider sx={{ my: 3 }} />
      <Stack
        direction={"row"}
        alignItems="center"
        justifyContent="space-between"
        sx={{ mt: 5 }}
      >
        <Typography variant="h5" fontSize="15px">
          Make sold products to Make to Order
        </Typography>
        <Switch
          onChange={(e) => {
            editForm({ maketoorder: e });
          }}
          checked={settingsForm.maketoorder}
          style={{
            border: `1px solid ${settingsForm.maketoorder ? backgroundColor : "#e8e8e8"
              }`,
            backgroundColor: settingsForm.maketoorder
              ? backgroundColor
              : "#e8e8e8",
          }}
        />
      </Stack>

      <Typography
        variant="body2"
        sx={{ fontSize: "12px", color: "text.secondary", mt: 1 }}
      >
        Enabling this option to yes will make outofstock products automatically
        to make-to-order
      </Typography>
      <Divider sx={{ my: 3 }} />
      <Stack
        direction={"row"}
        alignItems="center"
        justifyContent="space-between"
        sx={{ mt: 5 }}
      >
        <Typography variant="h5" fontSize="15px">
          Add products to website when imported
        </Typography>
        <Switch
          onChange={(e) => {
            editForm({ make_live_when_imported: e });
          }}
          checked={settingsForm.make_live_when_imported}
          style={{
            border: `1px solid ${settingsForm.make_live_when_imported ? backgroundColor : "#e8e8e8"
              }`,
            backgroundColor: settingsForm.make_live_when_imported
              ? backgroundColor
              : "#e8e8e8",
          }}
        />
      </Stack>

      <Typography
        variant="body2"
        sx={{ fontSize: "12px", color: "text.secondary", mt: 1 }}
      >
        When products are imported they will automatically added to website
      </Typography>
      {/* <Typography
                variant="body2"
                sx={{ fontSize: "12px", color: "text.secondary", mt: 1 }}
            >
                Enabling this option to yes will make outofstock products automatically
                to make-to-order
            </Typography>
            <Divider sx={{ my: 3 }} />
            <Stack
                direction={"row"}
                alignItems="center"
                justifyContent="space-between"
                sx={{ mt: 5 }}
            >
                <Typography variant="h5" fontSize="15px">
                    Enable Hallmarked ?
                </Typography>
                <Switch
                    onChange={(e) => {
                        editForm({ hallmarked: e });
                    }}
                    checked={settingsForm.hallmarked}
                    style={{
                        border: `1px solid ${settingsForm.hallmarked ? backgroundColor : "#e8e8e8"
                            }`,
                        backgroundColor: settingsForm.hallmarked
                            ? backgroundColor
                            : "#e8e8e8",
                    }}
                />
            </Stack>
            <Typography
                variant="body2"
                sx={{ fontSize: "12px", color: "text.secondary", mt: 1 }}
            >
                Enabling this option to yes will make any product hallmarked as yes by
                default.
            </Typography>
            <Divider sx={{ my: 3 }} />
            <Stack
                direction={"row"}
                alignItems="center"
                justifyContent="space-between"
                sx={{ mt: 4 }}
            >
                <Box>
                    <Typography variant="h5" fontSize="15px">
                        Disbale Auto-Sync from Ornate NX ?
                    </Typography>
                    <Typography
                        variant="body2"
                        sx={{ fontSize: "12px", color: "text.secondary", mt: 1 }}
                    >
                        Enabling this option will not be auto-synced.
                    </Typography>
                </Box>
               
            </Stack> */}
    </Box>
  );
};
