import { EDIT_ORDER } from "../actions/orderActions";

const initialState = {};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case EDIT_ORDER:
      return { ...state, ...payload };
    default:
      return state;
  }
};

export default reducer;
