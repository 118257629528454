import { LoadingButton } from "@mui/lab";
import {
  Box,
  Breadcrumbs,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  InputLabel,
  Link,
  ListSubheader,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  dialCodes,
  dialogStyle,
  getAdminName,
  getAxiosError,
  getId,
  getRandomString,
  getShortRefLink,
  getStoreEmail,
  getStoreName,
  getToken,
  getWebsite,
  isArrayWithValues,
  secondaryLabel,
  validateEmail,
} from "../../helper";
import { updateCRMInvite } from "../../redux/actions/crmActions";
import { commonDialCodes } from "../crm/Add";
import { ButtonTab } from "../components/helper";
import { NavLink } from "react-router-dom";
import { Add } from "@mui/icons-material";
import SuppliersList from "./components/SuppliersList";

const AllSuppliers = () => {
  const theme = useTheme();
  const isIpad = useMediaQuery(theme.breakpoints.down("md"));
  const [showAddForm, setShowAddForm] = useState(false);
  const closeInviteForm = () => setShowAddForm(false);
  const openInviteForm = () => setShowAddForm(true);

  return (
    <Box padding={isIpad ? 0 : "20px"}>
      <Stack
        direction={"row"}
        justifyContent="space-between"
        alignItems={"center"}
      >
        <Box>
          <Typography variant="h3" gutterBottom display="inline">
            Suppliers
          </Typography>
          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/design-bank">
              Dashboard
            </Link>
            <Typography>Suppliers</Typography>
          </Breadcrumbs>
        </Box>
        <Button variant="outlined" onClick={openInviteForm} startIcon={<Add />}>
          Invite Supplier
        </Button>
      </Stack>

      <SuppliersList />
      <Dialog
        open={showAddForm}
        onClose={() => setShowAddForm(false)}
        fullWidth
        maxWidth="sm"
        sx={dialogStyle}
      >
        <DialogTitle>Add Supplier</DialogTitle>
        <InviteForm handleClose={() => setShowAddForm(false)} />
      </Dialog>
    </Box>
  );
};

export default AllSuppliers;

const InviteForm = ({ handleClose }) => {
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    company_name: "",
    email: "",
    whatsapp: "",
    whatsapp_dial: "91",
  });
  const [hasError, setHasError] = useState({});
  const [loading, setLoading] = useState(false);
  const store_id = useSelector((state) => state.user.store_id);
  const productSettings = useSelector(
    (state) => state.settings.productSettings
  );
  const dispatch = useDispatch();
  async function sendInvitationSupplier(id) {
    try {
      let website = await getWebsite();
      let token = await getToken();
      let res = await axios({
        url: `${website}/wp-json/store/v1/invite`,
        headers: {
          Authorization: `Basic ${token}`,
        },
        params: { suppliers: id },
        method: "POST",
      });

      return res.data;
    } catch (error) {
      console.log(getAxiosError(error));
    }
  }
  async function addSupplierInvite(obj) {
    try {
      let website = await getWebsite();
      const token = await getToken();
      if (website && token) {
        let res = await axios({
          url: `${website}/wp-json/store/v1/supplier/connect`,
          headers: {
            Authorization: `Basic ${token}`,
          },
          data: obj,
          method: "POST",
        });
        // console.log(res.data, 'supplier invite response ');
        return res.data;
      }
    } catch (error) {
      console.log(getAxiosError(error));
    }
  }
  // async function onSubmit() {
  //   // let { fetchAllSuppliers } = this.props;
  //   setLoading(true);
  //   let { first_name, last_name, email } = formData;
  //   if (!first_name) {
  //     setHasError({
  //       name: "first_name",
  //       text: "Please Enter first name",
  //     });
  //     setLoading(false);
  //     return;
  //   }
  //   if (!last_name) {
  //     setHasError({
  //       name: "last_name",
  //       text: "Please Enter last name",
  //     });
  //     setLoading(false);
  //     return;
  //   }
  //   if (!email) {
  //     setHasError({
  //       name: "email",
  //       text: "Please Enter email",
  //     });
  //     setLoading(false);
  //     return;
  //   }
  //   if (!validateEmail(email)) {
  //     setHasError({
  //       name: "email",
  //       text: "Please Enter valid email",
  //     });
  //     setLoading(false);
  //     return;
  //   }
  //   // this.setState({ loading: true });
  //   let supplier_obj = { ...formData };
  //   // delete supplier_obj.loading;
  //   let user_id = getId();
  //   let userEmail = getStoreEmail();
  //   let userName = getAdminName();
  //   let retailer_obj = {
  //     email: userEmail,
  //   };
  //   if (userName) {
  //     let names = userName.split(" ");
  //     if (isArrayWithValues(names) && names.length > 1) {
  //       retailer_obj.first_name = names[0];
  //       retailer_obj.last_name = names[names.length - 1];
  //     } else if (isArrayWithValues(names)) retailer_obj.first_name = names[0];
  //   }
  //   // console.log(retailer_obj);
  //   // return;

  //   // console.log(user_id, userEmail, userName);

  //   let uid = getRandomString(14);
  //   let supplier_uid = getRandomString(14);
  //   let refObj = {
  //     by: user_id,
  //     type: "retailer",
  //     uid,
  //     supplierUniqueID: supplier_uid,
  //     retailer_obj,
  //   };
  //   // await sleep(1000);
  //   let refLink = await getShortRefLinkSupplier(refObj);
  //   console.log(refLink);
  //   if (!refLink) {
  //     setLoading(false);
  //     setHasError({
  //       name: "link",
  //       text: "Can't able to generate referral link ! try it later",
  //     });
  //     setLoading(false);
  //     return;
  //   }
  //   let supplier_connect_obj = {
  //     status: "invited",
  //     referral_link: refLink,
  //     retailerUniqueID: uid,
  //     supplierUniqueID: supplier_uid,
  //     info: {
  //       ...supplier_obj,
  //     },
  //   };
  //   console.log(JSON.stringify(supplier_connect_obj, null, 2));
  //   return;
  //   let addInviteData = await addSupplierInvite(supplier_connect_obj);
  //   let inviteData = await sendInvitationSupplier(supplier_uid);
  //   // await fetchAllSuppliers();
  //   dispatch(
  //     setGlobalToast({
  //       show: true,
  //       message: "Supplier has been invited successfully",
  //       severity: "success",
  //     })
  //   );
  //   handleClose();
  //   // this.setState({ loading: false }, () => {
  //   //     Toast.show({
  //   //         type: 'success',
  //   //         text1: 'Success',
  //   //         text2: 'Supplier has been invited successfully',
  //   //     });
  //   //     this.props.navigation.navigate('SupplierInvite');
  //   // });

  //   console.log(addInviteData, "ADd supplier invite");
  //   if (addInviteData?.success && isArrayWithValues(addInviteData.data)) {
  //     dispatch({
  //       type: SUPPLIER_EDIT,
  //       payload: {
  //         // fetchingSuppliers: false,
  //         allSuppliers: addInviteData.data,
  //       },
  //     });
  //   }
  //   console.log(inviteData, "supplier invite res");
  //   // if(isArrayWithValues())
  //   setLoading(false);
  // }
  async function onSubmit() {
    // let { fetchAllSuppliers } = this.props;
    setLoading(true);
    let { first_name, last_name, email, whatsapp } = formData;
    if (!first_name) {
      setHasError({
        name: "first_name",
        text: "Please Enter first name",
      });
      setLoading(false);
      return;
    }
    if (!last_name) {
      setHasError({
        name: "last_name",
        text: "Please Enter last name",
      });
      setLoading(false);
      return;
    }
    if (!email && !whatsapp) {
      setHasError({
        name: "link",
        text: "Please Enter email or whatsapp",
      });
      setLoading(false);
      return;
    }
    if (email && !validateEmail(email)) {
      setHasError({
        name: "email",
        text: "Please Enter valid email",
      });
      setLoading(false);
      return;
    }
    // this.setState({ loading: true });
    // delete supplier_obj.loading;
    let user_id = getId();
    let userEmail = getStoreEmail();
    let userName = getAdminName();
    let refObj = {
      email: userEmail,
    };
    let company_name = getStoreName();
    if (userName) {
      let names = userName.split(" ");
      if (isArrayWithValues(names) && names.length > 1) {
        refObj.first_name = names[0];
        refObj.last_name = names[names.length - 1];
      } else if (isArrayWithValues(names)) refObj.first_name = names[0];
    }
    let id = `${new Date().getTime()}_${getRandomString(6)}`;
    let formId = id;

    // console.log(retailer_obj);
    // return;

    // console.log(user_id, userEmail, userName);

    let supplier_uid = getRandomString(14);
    refObj = {
      ...refObj,
      uid: formId,
      url: getWebsite(),
      company_name: getStoreName(),

      // ...formData,
    };
    // await sleep(1000);
    let refLink = await getShortRefLink(refObj);
    console.log(refLink);
    if (!refLink) {
      setLoading(false);
      setHasError({
        name: "link",
        text: "Can't able to generate referral link! try it later",
      });
      setLoading(false);
      return;
    }
    let retailer_obj = {
      status: "invited",
      referral_link: refLink,
      uid: formId,
      ...formData,
    };
    // console.log(JSON.stringify(retailer_obj, null, 2));
    console.log(refObj, retailer_obj);
    // return;
    dispatch(
      updateCRMInvite({
        obj: retailer_obj,
        setLoading,
        onSuccess: () => {
          handleClose();
        },
      })
    );
  }
  const onEditForm = (payload) => {
    setFormData((pre) => ({ ...pre, ...payload }));
  };

  return (
    <>
      <DialogContent>
        <Box>
          <Grid container spacing={3} rowSpacing={3}>
            <Grid item xs={6}>
              <FormLabel>First Name</FormLabel>
              <TextField
                fullWidth
                placeholder="First Name"
                value={formData?.first_name || ""}
                onChange={(e) => {
                  onEditForm({ first_name: e.target.value });
                  setHasError({});
                }}
                error={hasError?.name === "first_name"}
              />
              {hasError?.name === "first_name" && (
                <FormHelperText sx={{ color: "#FF0000" }}>
                  {hasError?.text}
                </FormHelperText>
              )}
            </Grid>
            <Grid item xs={6}>
              <FormLabel>Last Name</FormLabel>
              <TextField
                fullWidth
                placeholder="last Name"
                value={formData?.last_name || ""}
                onChange={(e) => {
                  onEditForm({ last_name: e.target.value });
                  setHasError({});
                }}
                error={hasError?.name === "last_name"}
              />
              {hasError?.name === "last_name" && (
                <FormHelperText sx={{ color: "#FF0000" }}>
                  {hasError?.text}
                </FormHelperText>
              )}
            </Grid>
            <Grid item xs={12}>
              <FormLabel>Company Name</FormLabel>
              <TextField
                fullWidth
                placeholder="Company name"
                value={formData?.company_name || ""}
                onChange={(e) => {
                  onEditForm({ company_name: e.target.value });
                }}
                // error={hasError?.name === "last_name"}
              />
              {/* {hasError?.name === "last_name" && <FormHelperText>{hasError?.text}</FormHelperText>} */}
            </Grid>
            <Grid item xs={12}>
              <FormLabel>Email</FormLabel>
              <TextField
                fullWidth
                placeholder="Email"
                value={formData?.email || ""}
                onChange={(e) => {
                  onEditForm({ email: e.target.value });
                  setHasError({});
                }}
                error={hasError?.name === "email"}
              />
              {hasError?.name === "email" && (
                <FormHelperText sx={{ color: "#FF0000" }}>
                  {hasError?.text}
                </FormHelperText>
              )}
            </Grid>
            <Grid item xs={12}>
              <FormLabel>Whatsapp</FormLabel>
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <Select
                    // size="small"
                    fullWidth
                    value={formData.whatsapp_dial}
                    onChange={(e) =>
                      onEditForm({ whatsapp_dial: e.target.value?.value })
                    }
                    renderValue={(value) => `${formData.whatsapp_dial}`}
                  >
                    <ListSubheader>Common Dial Codes</ListSubheader>
                    {commonDialCodes.map((i) => {
                      return (
                        <MenuItem key={i.value} value={i}>
                          {i.label}
                        </MenuItem>
                      );
                    })}
                    <ListSubheader>Dial Codes</ListSubheader>
                    {dialCodes.map((i) => (
                      <MenuItem value={i}>{i.label}</MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs>
                  <TextField
                    value={formData?.whatsapp || ""}
                    fullWidth
                    placeholder="Whatsapp"
                    onChange={(e) => onEditForm({ whatsapp: e.target.value })}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      {hasError?.name === "link" && (
        <Typography sx={{ color: "#FF0000", mt: 2, textAlign: "center" }}>
          {hasError?.text}
        </Typography>
      )}
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <LoadingButton loading={loading} onClick={onSubmit}>
          Add
        </LoadingButton>
      </DialogActions>
    </>
  );
};
