import { Popover, Box, Typography } from "@mui/material";
import { optionPopoverStyles } from "../styles/optionPopoverStyles";

const styles = optionPopoverStyles;

const ValidateResponsePopover = ({
  questionId,
  questions,
  setQuestions,
  optionsAnchorEl,
  setOptionsAnchorEl,
}) => {
  const open = optionsAnchorEl.openedPopoverId === questionId;
  const id = open ? "simple-popover" : undefined;

  const handleClosePopover = () => {
    setOptionsAnchorEl({ openedPopoverId: null, anchorEl: null });
  };

  const handleOptionClick = (type) => {
    setQuestions((prevQuestions) => {
      return prevQuestions.map((question) => {
        if (question.id === questionId) {
          const updatedQuestion = { ...question };
          updatedQuestion.validateEntity = type;
          return updatedQuestion;
        }
        return question;
      });
    });
    handleClosePopover();
  };

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={optionsAnchorEl.anchorEl}
      onClose={handleClosePopover}
      anchorOrigin={{
        vertical: "center",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "center",
        horizontal: "right",
      }}
    >
      <Box sx={styles.outerBox}>
        <Box
          sx={{
            ...styles.innerBox,
            "&:hover": {
              backgroundColor: "#fff",
            },
          }}
        >
          <Typography variant="h6" sx={styles.mainTitle}>
            SYSTEM ENTITIES
          </Typography>
        </Box>

        <Box sx={styles.innerBox} onClick={() => handleOptionClick("any")}>
          <Typography variant="span" sx={styles.innerBoxTitle}>
            Any
          </Typography>
          <Typography variant="span" sx={styles.innerBoxDesciption}>
            Matches anything that is non-empty
          </Typography>
        </Box>

        <Box
          sx={styles.innerBox}
          onClick={() => handleOptionClick("alphanumeric")}
        >
          <Typography variant="span" sx={styles.innerBoxTitle}>
            Alphanumeric
          </Typography>
          <Typography variant="span" sx={styles.innerBoxDesciption}>
            Matches both letters and numerals
          </Typography>
        </Box>

        <Box sx={styles.innerBox} onClick={() => handleOptionClick("email")}>
          <Typography variant="span" sx={styles.innerBoxTitle}>
            Email
          </Typography>
          <Typography variant="span" sx={styles.innerBoxDesciption}>
            Matches e-mail
          </Typography>
        </Box>

        <Box sx={styles.innerBox} onClick={() => handleOptionClick("integer")}>
          <Typography variant="span" sx={styles.innerBoxTitle}>
            Integer
          </Typography>
          <Typography variant="span" sx={styles.innerBoxDesciption}>
            Matches integer number
          </Typography>
        </Box>

        <Box
          sx={styles.innerBox}
          onClick={() => handleOptionClick("phone-number")}
        >
          <Typography variant="span" sx={styles.innerBoxTitle}>
            Phone number
          </Typography>
          <Typography variant="span" sx={styles.innerBoxDesciption}>
            Matches phone number
          </Typography>
        </Box>

        <Box sx={styles.innerBox} onClick={() => handleOptionClick("yes-no")}>
          <Typography variant="span" sx={styles.innerBoxTitle}>
            Yes - No
          </Typography>
          <Typography variant="span" sx={styles.innerBoxDesciption}>
            Matches yes and no synonyms
          </Typography>
        </Box>
      </Box>
    </Popover>
  );
};

export default ValidateResponsePopover;
