import { Box, Stack, Tab, Tabs, Typography } from "@mui/material";
import React, { useState } from "react";
import { TabPanel } from "../../../../helper";
import Integration from "./Integration";

import { PaysharpSvg } from "../../../../Svgs";
import Settings from "./Settings";
import { ListItem } from "../meta/MetaOverview";

const Paysharp = () => {
    const [selectedTab, setSelectedTab] = useState("overview");
    return (
        <Box sx={{ height: "100%", paddingRight: "15px" }}>
            <Stack
                sx={{
                    position: "sticky",
                    top: "0",
                    backgroundColor: "#fff",
                    zIndex: 13,
                    margin: 0,
                    padding: "12px",
                }}
            >
                <Stack
                    direction="row"
                    justifyContent={"space-between"}
                    alignItems="center"
                >
                    <Typography
                        variant="h3"
                        sx={{ display: "flex", alignItems: "center", gap: "10px" }}
                    >
                        <PaysharpSvg /> Paysharp
                    </Typography>
                </Stack>
                <Tabs
                    onChange={(e, newValue) => setSelectedTab(newValue)}
                    value={selectedTab}
                    sx={{ borderBottom: 1, borderColor: "divider" }}
                >
                    <Tab label="Overview" value="overview" />
                    <Tab label="How to Use" value="how_to_use" />
                    <Tab label="Integration" value="integration" />
                    <Tab label="Settings" value="settings" />
                </Tabs>
            </Stack>
            <TabPanel value={selectedTab} index={"overview"}>
                <Overview />
            </TabPanel>
            <TabPanel value={selectedTab} index={"how_to_use"}>
                <HowToUse />
            </TabPanel>
            <TabPanel value={selectedTab} index={"integration"}>
                <Integration />
            </TabPanel>

            <TabPanel value={selectedTab} index={"settings"}>
                <Settings />
            </TabPanel>
        </Box>
    );
};

export default Paysharp;

const Overview = () => {
    return (
        <>
            <Box p={"12px 26px"}>
                <img
                    src={"/static/img/onboarding/paysharpOverview.png"}
                    width={"100%"}
                    alt="info"
                />

                <Typography variant="subtitle2" sx={{ fontSize: "16px", mb: 7 }}>
                    Paysharp offers specialized payment solutions for jewellers, enabling
                    them to collect automated Digigold payments from customers using BBPS
                    apps. With Paysharp's virtual account solution, jewellers can easily
                    track payments by assigning unique account numbers to each customer.
                </Typography>
                <Typography variant="subtitle2" sx={{ fontSize: "16px", mb: 7 }}>
                    Their payment collection solutions streamline the payment process for
                    jewellers, allowing them to collect payments more efficiently and
                    automate the process, resulting in time and cost savings.
                </Typography>
                <Typography variant="subtitle2" sx={{ fontSize: "16px", mb: 7 }}>
                    By integrating Paysharp's payment with Jwero, jewellers can enable
                    automatic monthly Digigold payments directly through the customer's
                    preferred BBPS app. This integration offers several benefits,
                    including an automated payment setup, allowing customers to pay
                    directly from their chosen BBPS app and the inclusion of pending
                    payments in the upcoming payment cycle.
                </Typography>
            </Box>
        </>
    );
};

const HowToUse = () => {
    let contentArray = [
        "1. Sign Up for Paysharp's services >>> {enter paysharp referral link here}.",
        "2. Account Set Up with Paysharp",
        "3. Select the Customer Authentication Option with Paysharp.",
        "4. Apply and Complete Process for BBPS Approval process.",
        "5. Copy and share webhook Links from Jwero Integration from your registered email to pay sharp.",
        "6. Complete Integration with Jwero by Informing Jwero on care@jwero.ai.",
        "7. Inform Customers to Subscribe for your brand on the BBPS app for Digigold Scheme Payments",
        "8. Once Subscribed, customers will receive automatic Payment reminders for their Subscribed Digigold Plan",
        "9. On Payment Confirmation, Customers can login to your website/mobile app and access complete payment history instantly.",
        "10. Jewellers will notified and can access complete payment history of the customers in jwero",
        "11. Pending Month payment will be automatically carried forward to next month payment cycle for customers",
        "12. Once Plan is Closed Customers will no longer receive the payment Reminders​",
    ];
    return (
        <>
            <Box p={"12px 26px"}>
                <Typography variant="h6" sx={{ fontSize: "16px", mb: 6 }}>
                    How does it work?
                </Typography>
                <Typography variant="subtitle1" sx={{ fontSize: "16px", mb: 2 }}>
                    1)  The process of getting started with Paysharp and Jwero Integration is
                    simple.
                </Typography>
                <Typography variant="subtitle1" sx={{ fontSize: "16px", mb: 2 }}>
                    2)  Jewellers registers for Paysharp's Services directly via Paysharp.
                </Typography>
                <Typography variant="subtitle1" sx={{ fontSize: "16px", mb: 2 }}>
                    3)  Jewellers then submit their KYC documents with Paysharp for BBPS
                    Approval.
                </Typography>
                <Typography variant="subtitle1" sx={{ fontSize: "16px", mb: 2 }}>
                    4)  Jewellers must then share the Webhook link from Jwero Integrations tab
                    with Paysharp.
                </Typography>
                <Typography variant="subtitle1" sx={{ fontSize: "16px", mb: 6 }}>
                    5)  Once the process is complete jewellers can inform and educate their
                    customers for Automated payment option available.
                </Typography>
                <Typography variant="h6" sx={{ fontSize: "16px", mb: 6 }}>
                    You can get started using the below-mentioned steps:
                </Typography>
                <img
                    src={"/static/img/onboarding/paysharp.png"}
                    width={"100%"}
                    alt="info"
                />
                <ListItem arr={contentArray} />
            </Box>
        </>
    );
};
