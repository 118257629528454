import {
  Box,
  Button,
  CircularProgress,
  Divider,
  InputAdornment,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
// import Switch from 'rc-switch'
import "rc-switch/assets/index.css";
import axios from "axios";
import React from "react";
import { useState } from "react";
import {
  formatDate,
  getToken,
  getWebsite,
  isArrayWithValues,
  isObjWithValues,
  TabPanel,
} from "../../../../helper";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setGlobalToast } from "../../../../redux/actions/helperActions";
import { LoadingButton } from "@mui/lab";
import { EDIT_SETTINGS } from "../../../../redux/actions/settingActions";
import { Lock } from "@mui/icons-material";
import Overview from "./Overview";

const Camweara = () => {
  const [selectedTab, setSelectedTab] = useState("overview");
  const [keyInput, setKeyInput] = useState("");
  const [loading, setLoading] = useState(false);
  const [isIntegreated, setIsIntegreated] = useState(false);
  // const [istryb4uIntegrated, setTryB4uIntegrated] = useState(false);
  const [integratedDate, setIntegratedDate] = useState(false);
  const globalToast = useSelector((state) => state.helper.globalToast);
  const dispatch = useDispatch();
  const camwearaIntegration = useSelector(
    (state) => state.settings.camwearaIntegration
  );
  const virtualTryIntegrated = useSelector(
    (state) => state.helper.virtualTryIntegrated
  );
  useEffect(() => {
    if (isObjWithValues(camwearaIntegration)) {
      if (camwearaIntegration?.key) {
        setKeyInput(camwearaIntegration?.key || "");
        setIsIntegreated(true);
        setIntegratedDate(camwearaIntegration?.time || "");
      } else {
        setKeyInput("");
        setIsIntegreated(false);
        setIntegratedDate(false);
      }
    }
  }, [camwearaIntegration]);
  // const reduxTrybeforeyoubuyIntegration = useSelector(
  //   (state) => state.settings.trybeforeyoubuyIntegration
  // );
  // useEffect(() => {
  //   if (isObjWithValues(reduxTrybeforeyoubuyIntegration)) {
  //     if (reduxTrybeforeyoubuyIntegration?.key) {
  //       setTryB4uIntegrated(true);
  //     } else {
  //       // setKeyInput("")
  //       setTryB4uIntegrated(false);
  //     }
  //   }
  // }, [reduxTrybeforeyoubuyIntegration]);

  const verifykey = async () => {
    let website = getWebsite();
    let token = getToken();
    if (!website && !token) return false;
    setLoading(true);
    let body = new FormData();
    body.append("camweara_key", keyInput);
    let res = await axios({
      url: `https://cdn.camweara.com/camweara_jewelry_client/authenticate_customer.php`,
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: body,
    });
    console.log(res.data);

    if (res.data) {
      let time = Date.now();
      let IntegartionData = await axios({
        url: `${website}/wp-json/store/v1/settings/camweara_integration`,
        headers: {
          Authorization: `Basic ${token}`,
        },
        data: {
          type: "camweara_integration",
          data: {
            time,
            key: keyInput,
          },
        },
        method: "PUT",
      });
      dispatch(
        setGlobalToast({
          ...globalToast,
          show: true,
          message: "Vendor Authorised succesfully",
        })
      );
      dispatch({
        type: EDIT_SETTINGS,
        payload: {
          camwearaIntegration: {
            time,
            key: keyInput,
          },
        },
      });
    } else {
      dispatch(
        setGlobalToast({
          ...globalToast,
          show: true,
          message: "Vendor Authorised failed",
        })
      );
      dispatch({
        type: EDIT_SETTINGS,
        payload: {
          camwearaIntegration: {
            time: "",
            key: "",
          },
        },
      });
    }
    console.log(res.data);
    setLoading(false);
  };
  const revokeKey = async () => {
    let website = getWebsite();
    let token = getToken();
    if (!website && !token) return false;
    setLoading(true);
    let IntegartionData = await axios({
      url: `${website}/wp-json/store/v1/settings/camweara_integration`,
      headers: {
        Authorization: `Basic ${token}`,
      },
      data: {
        type: "camweara_integration",
        data: {
          time: "",
          key: "",
        },
      },
      method: "PUT",
    });
    console.log(IntegartionData);
    if (IntegartionData?.data?.success) {

      dispatch({
        type: EDIT_SETTINGS,
        payload: {
          camwearaIntegration: IntegartionData?.data?.data,
        },
      });
    }
    setLoading(false);
  };
  return (
    <Box sx={{ height: "100%", paddingRight: "15px" }}>
      <Stack
        sx={{
          position: "sticky",
          top: "0",
          backgroundColor: "#fff",
          zIndex: 13,
          margin: 0,
          padding: "12px",
        }}
      >
        <Stack
          direction="row"
          justifyContent={"space-between"}
          alignItems="center"
        >
          <Typography
            variant="h3"
            sx={{ display: "flex", alignItems: "center", gap: "10px" }}
          >
            {" "}
            <img
              src="/static/img/brands/camweara.png"
              width="20px"
              height="20px"
            />{" "}
            Camweara
          </Typography>
          {/* {fetchingMetaIntegration && <Stack direction={"row"} alignItems="center" sx={{ gap: "10px" }}>
                        <CircularProgress size={"15px"} /> <Typography>Fetching Integrations...</Typography>
                    </Stack>} */}
        </Stack>
        {/* <Divider sx={{ mt: 4 }} /> */}
        <Tabs
          onChange={(e, newValue) => setSelectedTab(newValue)}
          value={selectedTab}
          sx={{ borderBottom: 1, borderColor: "divider" }}
        >
          <Tab label="Overview" value="overview" />
          {/* <Tab label="How to do?" value="how_to_do" />
                    <Tab label="Details" value="details" /> */}
          <Tab label="Integration" value="integration" />
          {/* <Tab label="Settings" value="settings" /> */}
        </Tabs>
      </Stack>
      <TabPanel value={selectedTab} index={"overview"}>
        <Typography>
          <Overview />
        </Typography>
      </TabPanel>
      <TabPanel value={selectedTab} index={"how_to_do"}>
        <Typography>How to do</Typography>
      </TabPanel>
      <TabPanel value={selectedTab} index={"details"}>
        <Typography>Details</Typography>
      </TabPanel>
      <TabPanel value={selectedTab} index={"integration"}>
        <Box sx={{ padding: "12px 18px" }}>
          <Stack direction={"row"} alignItems={"center"} sx={{ gap: "20px" }}>
            <Typography
              variant="h6"
            // fontSize={"13px"}
            // mt={3}
            >
              Camweara Key
            </Typography>
          </Stack>
          <Stack
            direction={"row"}
            alignItems="center"
            sx={{ gap: "20px", mt: 2 }}
          >
            <TextField
              value={keyInput}
              onChange={(e) => setKeyInput(e.target.value)}
              // variant="outlined"
              size="small"
              sx={{ width: "350px" }}
              // fullWidth
              // sx={{ mt: 2 }}
              disabled={isIntegreated}
              InputProps={
                isIntegreated
                  ? {
                    endAdornment: (
                      <InputAdornment position="start">
                        <Lock />
                      </InputAdornment>
                    ),
                  }
                  : null
              }
            />

            {!isIntegreated ? (
              <LoadingButton
                size="small"
                loading={loading}
                disabled={virtualTryIntegrated}
                // disabled={istryb4uIntegrated}
                variant="contained"
                onClick={verifykey}
              >
                Integrate
              </LoadingButton>
            ) : null}
            {isIntegreated ? (
              <LoadingButton
                size="small"
                color="error"
                loading={loading}
                variant="contained"
                onClick={revokeKey}
              >
                Revoke
              </LoadingButton>
            ) : null}
          </Stack>
          {integratedDate ? (
            <Typography sx={{ mt: 2 }}>
              {" "}
              Integrated on{" "}
              {formatDate(new Date(integratedDate), "relative_date")}
            </Typography>
          ) : null}
          {/* <Typography variant="h6" fontSize={"13px"} mt={3}>
                        Gupshup App Name
                    </Typography>
                    <TextField
                        value={name}
                        onChange={(e) => onEditGupshupForm({ name: e.target.value })}
                        variant="outlined"
                        size="small"
                        sx={{ mt: 2 }}
                        fullWidth
                        disabled={fetchingIntegrations}
                    /> */}
        </Box>
      </TabPanel>
    </Box>
  );
};

export default Camweara;
