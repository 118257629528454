import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components/macro";
import * as Yup from "yup";
import { Formik } from "formik";
import { LoadingButton } from "@mui/lab";
import {
  Alert as MuiAlert,
  Button,
  Checkbox,
  Collapse,
  FormControlLabel,
  Stack,
  TextField as MuiTextField,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";

import useAuth from "../../hooks/useAuth";
import {
  isEmailValid,
  getAxiosError,
  validateNumber,
  Base64,
  mainWebsite,
} from "../../helper";
import axios from "axios";
import { useDispatch } from "react-redux";
import { SET_HELPER_DATA } from "../../redux/actions/helperActions";

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)(spacing);

const defaultErrors = {
  email: "",
  password: "",
  otp: "",
};

function SignUp() {
  const [showOtp, setShowOtp] = useState(false);
  const [values, setValues] = useState({
    email: "",
    password: "",
    otp: "",
  });
  const [resendTimer, setResendTimer] = useState(10);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(defaultErrors);
  const [showPassword, setShowPassword] = useState(false);
  const [signingUp, setSigningUp] = useState(false);
  const [alert, setAlert] = useState({ show: false });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { signUp } = useAuth();

  // useEffect(() => {
  //   const handleKeyDown = (event) => {
  //     if (event.keyCode === 13) {
  //       // Call your event handler here
  //       showOtp && !loading ? onSignUp() : onNextPress();
  //     }
  //   };
  //   document.addEventListener("keydown", handleKeyDown);

  //   return () => {
  //     document.removeEventListener("keydown", handleKeyDown);
  //   };
  // }, []);

  useEffect(() => {
    let { email, password, otp } = errors;
    if (email || password || otp) setErrors(defaultErrors);
  }, [values]);

  useEffect(() => {
    let resendTimeout;
    if (resendTimer !== 0) {
      resendTimeout = setTimeout(() => {
        setResendTimer((state) => state - 1);
      }, 1000);
      // resendTimeout = setInterval(() => {
      //   setResendTimer((state) => state - 1);
      // }, 1000);
    } else clearTimeout(resendTimeout);
    return () => clearTimeout(resendTimeout);
  }, [resendTimer]);

  const onNextPress = async () => {
    let { email, password } = values;
    if (!values.email)
      return setErrors({ ...defaultErrors, email: "Email is required" });
    if (!isEmailValid(values.email))
      return setErrors({ ...defaultErrors, email: "Must be a valid email" });
    if (!values.password)
      return setErrors({ ...defaultErrors, password: "Password is required" });
    if (values.password.length < 6)
      return setErrors({
        ...defaultErrors,
        password: "Password should be atleast 6 character",
      });

    try {
      setLoading(true);
      let res = await sendOtp(email, password);
      setLoading(false);
      if (res.success) {
        setShowOtp(true);
        setResendTimer(30);
        setAlert({
          show: true,
          message: "OTP sent to your email address",
          severity: "success",
        });
        setTimeout(() => {
          setAlert((state) => ({
            ...state,
            show: false,
          }));
        }, 1800);
        // resendTimeout = setInterval(() => {
        //   console.log(resendTimer);
        //   // if (resendTimer === 0) clearInterval(resendInterval);
        //   setResendTimer(resendTimer - 1);
        // }, 1000);
      }

      console.log(res);
    } catch (error) {
      setLoading(false);
      error = getAxiosError(error);
      console.log(error);
      // if (error.message)
      //   setAlert({
      //     show: true,
      //     message: error.message,
      //     severity: "error",
      //   });
      // else
      if (`${error.code}` === "400")
        return setAlert({
          show: true,
          message: "Email address already exist.",
          severity: "error",
        });
      setAlert({
        show: true,
        message: "Internal server error! Please Try Again Later",
        severity: "error",
      });
    }
    // setFieldError({ email: "invalid email" });
    // setTouched({ email: true }, true);
    // setFieldError("email", "invalid email", false);

    return;
  };

  const sendOtp = async (email, password) => {
    let res = await axios({
      method: "POST",
      // url: "https://app.tanika.tech/wp-json/store/v1/signup",
      url: `${mainWebsite}/wp-json/store/v1/signup`,
      data: { email, password },
    });
    return res.data;
  };

  const onSignUp = async () => {
    let data = { ...values };
    data.otp = validateNumber(data.otp);
    setSigningUp(true);
    axios({
      method: "POST",
      // url: "https://app.tanika.tech/wp-json/store/v1/signup",
      url: `${mainWebsite}/wp-json/store/v1/signup`,
      data: { ...data },
    })
      .then((res) => {
        let { data } = res;
        console.log(data, "<<<< sign up data");
        // console.log(JSON.stringify(data, null, 2));
        // if (data.ID && data.data) {
        //   let { email, password } = values;
        //   dispatch({
        //     type: "SET_USER",
        //     payload: {
        //       unregistered_user: {
        //         user_id: data.ID,
        //         user_email: email,
        //         token: `${Base64.btoa(`${email}:${password}`)}`,
        //       },
        //     },
        //   });
        //   setTimeout(() => {
        //     navigate("/auth/register");
        //   }, 400);
        // }
        if (data.data) {
          let { email, password } = values;
          dispatch({
            type: SET_HELPER_DATA,
            payload: {
              unregistered_user: {
                user_id: data?.data?.ID,
                user_email: email,
                token: `${Base64.btoa(`${email}:${password}`)}`,
              },
            },
          });
          setTimeout(() => {
            setSigningUp(false);
            navigate("/auth/register");
          }, 700);
        }
      })
      .catch((e) => {
        console.log(getAxiosError(e));
        setSigningUp(false);
        setErrors({ ...defaultErrors, otp: "Invalid OTP" });
      });
    // .finally(() => setSigningUp(false));
  };
  const handleSubmit = () => {};
  const handleChange = (e) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
      errors: defaultErrors,
    });
  };
  return (
    <form noValidate onSubmit={handleSubmit}>
      {/* {errors.submit && (
        <Alert mt={2} mb={1} severity="warning">
          {errors.submit}
        </Alert>
      )} */}
      <Collapse in={alert.show}>
        <Alert sx={{ mt: 2 }} severity={alert.severity}>
          {alert.message}
        </Alert>
      </Collapse>
      <Stack
        direction={"row"}
        alignItems="flex-end"
        justifyContent={"space-between"}
        mt={2}
      >
        <Typography ml={2} variant="h6">
          Email
        </Typography>
        {showOtp && (
          <Button
            onClick={() => setShowOtp(false)}
            style={{ padding: "0px 7px", marginTop: "auto" }}
          >
            Change
          </Button>
        )}
      </Stack>
      <TextField
        type="email"
        name="email"
        placeholder="example@gmail.com"
        // label="Email address"
        value={values.email}
        error={Boolean(errors.email)}
        fullWidth
        helperText={errors.email}
        // onBlur={handleBlur}
        onChange={handleChange}
        my={2}
        disabled={showOtp}
      />

      {showOtp ? (
        <Stack>
          <Typography mt={6} ml={2} variant="h6">
            One Time Password
          </Typography>
          <TextField
            type="number"
            name="otp"
            placeholder="Enter otp"
            value={values.otp}
            error={Boolean(errors.otp)}
            fullWidth
            helperText={errors.otp}
            // onBlur={handleBlur}
            onChange={handleChange}
            my={2}
          />
        </Stack>
      ) : (
        <>
          <Typography mt={6} ml={2} variant="h6" color="text.success">
            Create Password
          </Typography>
          <TextField
            type={showPassword ? "text" : "password"}
            name="password"
            placeholder="**********"
            value={values.password}
            color={"success"}
            error={Boolean(errors.password)}
            fullWidth
            helperText={errors.password}
            // onBlur={handleBlur}
            onChange={handleChange}
            my={2}
          />
        </>
      )}
      {showOtp ? (
        <Stack
          direction="row"
          alignItems={"center"}
          sx={{ mt: 1, mb: 2, marginLeft: "0px", alignItems: "center" }}
        >
          <Typography>Didn't receive otp?</Typography>
          <LoadingButton
            loading={loading}
            onClick={onNextPress}
            disabled={resendTimer}
          >
            Send again {`${resendTimer ? `(${resendTimer})` : ""}`}
          </LoadingButton>
        </Stack>
      ) : (
        <FormControlLabel
          sx={{ mt: 1, mb: 2, marginLeft: "0px", alignItems: "center" }}
          control={
            <Checkbox
              value="remember"
              color="primary"
              sx={{ mr: 1 }}
              checked={showPassword}
              onChange={(e, value) => setShowPassword(value)}
            />
          }
          label="Show Password"
        />
      )}
      {/* <TextField
            type="password"
            name="confirmPassword"
            label="Confirm password"
            value={values.confirmPassword}
            error={Boolean(touched.confirmPassword && errors.confirmPassword)}
            fullWidth
            helperText={touched.confirmPassword && errors.confirmPassword}
            onBlur={handleBlur}
            onChange={handleChange}
            my={3}
          /> */}
      {showOtp ? (
        <LoadingButton
          loading={signingUp}
          // type="submit"
          fullWidth
          variant="contained"
          color="primary"
          // disabled={isSubmitting}
          onClick={onSignUp}
        >
          Sign up
        </LoadingButton>
      ) : (
        <LoadingButton
          // type="submit"
          fullWidth
          variant="contained"
          color="primary"
          // disabled={!values.email }
          loading={loading}
          onClick={onNextPress}
        >
          Next
        </LoadingButton>
      )}
    </form>
  );
  return (
    <Formik
      initialValues={{
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        confirmPassword: "",
        otp: "",
        submit: false,
      }}
      validationSchema={Yup.object().shape({
        // firstName: Yup.string().max(255).required("First name is required"),
        // lastName: Yup.string().max(255).required("Last name is required"),
        email: Yup.string()
          .email("Must be a valid email")
          .max(255)
          .required("Email is required"),
        password: Yup.string()
          .min(8, "Must be at least 8 characters")
          .max(255)
          .required("Password required"),
        // confirmPassword: Yup.string().when("password", {
        //   is: (val) => (val && val.length > 0 ? true : false),
        //   then: Yup.string().oneOf(
        //     [Yup.ref("password")],
        //     "Both password need to be the same"
        //   ),
        // }),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          signUp(
            values.email,
            values.password,
            values.firstName,
            values.lastName
          );
          navigate("/register");
        } catch (error) {
          const message = error.message || "Something went wrong";
          setStatus({ success: false });
          setErrors({ submit: message });
          setSubmitting(false);
        }
      }}
      test={() => {
        return "yes";
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        setErrors,
        setFieldError,
        setTouched,
        test,
      }) => {
        // console.log(handleSubmit);
        return (
          <form noValidate onSubmit={handleSubmit}>
            {errors.submit && (
              <Alert mt={2} mb={1} severity="warning">
                {errors.submit}
              </Alert>
            )}
            {/* <TextField
            type="text"
            name="firstName"
            label="First name"
            value={values.firstName}
            error={Boolean(touched.firstName && errors.firstName)}
            fullWidth
            helperText={touched.firstName && errors.firstName}
            onBlur={handleBlur}
            onChange={handleChange}
            my={3}
          />
          <TextField
            type="text"
            name="lastName"
            label="Last name"
            value={values.lastName}
            error={Boolean(touched.lastName && errors.lastName)}
            fullWidth
            helperText={touched.lastName && errors.lastName}
            onBlur={handleBlur}
            onChange={handleChange}
            my={3}
          /> */}

            <Typography mt={6} ml={2} variant="h6">
              Email
            </Typography>
            <TextField
              type="email"
              name="email"
              placeholder="example@gmail.com"
              // label="Email address"
              value={values.email}
              error={Boolean(touched.email && errors.email)}
              fullWidth
              helperText={touched.email && errors.email}
              onBlur={handleBlur}
              onChange={handleChange}
              my={2}
            />

            {showOtp ? (
              <>
                <Typography mt={6} ml={2} variant="h6">
                  One Time Password
                </Typography>
                <TextField
                  // type="password"
                  // name="password"
                  placeholder="Enter otp"
                  value={values.otp}
                  // error={Boolean(touched.password && errors.password)}
                  fullWidth
                  // helperText={touched.password && errors.password}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  my={2}
                />
              </>
            ) : (
              <>
                <Typography mt={6} ml={2} variant="h6">
                  Create Password
                </Typography>
                <TextField
                  type="password"
                  name="password"
                  placeholder="**********"
                  value={values.password}
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  my={2}
                />
              </>
            )}
            <FormControlLabel
              sx={{ mt: 1, mb: 2, marginLeft: "0px", alignItems: "center" }}
              control={
                <Checkbox
                  value="remember"
                  color="primary"
                  sx={{ mr: 1 }}
                  // checked={showPassword}
                  // onChange={(e, value) => setShowPassword(value)}
                />
              }
              label="Show Password"
            />
            {/* <TextField
            type="password"
            name="confirmPassword"
            label="Confirm password"
            value={values.confirmPassword}
            error={Boolean(touched.confirmPassword && errors.confirmPassword)}
            fullWidth
            helperText={touched.confirmPassword && errors.confirmPassword}
            onBlur={handleBlur}
            onChange={handleChange}
            my={3}
          /> */}
            {showOtp ? (
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={isSubmitting}
              >
                Sign up
              </Button>
            ) : (
              <Button
                // type="submit"
                fullWidth
                variant="contained"
                color="primary"
                // disabled={!values.email }

                onClick={() => onNextPress(values, setTouched, setFieldError)}
              >
                Next
              </Button>
            )}
          </form>
        );
      }}
    </Formik>
  );
}

export default SignUp;
