import { Box, Link, Typography } from "@mui/material";
import React from "react";
import { ListItem } from "../meta/MetaOverview";

const feature = [
  "👉 Seamless inventory management: With Jwero's integration with WooCommerce, you can manage your inventory seamlessly. You can update your product information, pricing, and stock levels in real-time, and ensure that your online store is always up-to-date with your physical inventory.",
  "👉 Multi-channel sales: Jwero's integration with WooCommerce allows you to expand your sales channels and reach a wider audience. You can manage your products and orders across all your sales channels, including your own online store, marketplaces, and social media platforms",
  "👉 Increased efficiency: Jwero's integration with WooCommerce allows you to automate your order fulfillment process. Whenever a customer places an order on your WooCommerce store, Jwero automatically picks up the order and processes it, reducing the time and effort required to manually fulfill orders",
  "👉 Automatic price updates: With Jwero's integration with WooCommerce, you can easily update the prices of your products on your online store. Just make an update in Jwero, and the prices will be automatically updated in real-time on your WooCommerce store and all other sales channels",
  "👉 Track orders in one dashboard: With Jwero's integration with WooCommerce, you can track all your orders in one dashboard. You can view the order status, tracking information, and shipping details in one place, making it easy to manage your orders",
  "👉 Easily customize product listings: With Jwero's integration with WooCommerce, you can easily customize the product listings on your online store. You can add images, descriptions, and other details to your product listings, making them more attractive to your customers.",
];
const WooCommerceOverview = () => {
  return (
    <Box sx={{ p: 3, mb: 3 }}>
      <Typography variant="subtitle1" sx={{ fontSize: "16px", mb: 4 }}>
        Benefits of Integrating Shopify with Jwero
      </Typography>

      <Typography variant="subtitle2" sx={{ fontSize: "16px", mb: 4 }}>
        Features of WooCommerce
      </Typography>
      <ListItem arr={feature} />
      {/* <Typography sx={{ fontSize: "16px", mt: 3 }}>
        📨 Send your queries{" "}
        <Link
          href="https://razorpay.com/support/?utm_referrer=&referral_code=meetanshi7m92j"
          style={{ fontSize: "16px" }}
        >
          Contact RazorPay
        </Link>
      </Typography>
      <Typography sx={{ fontSize: "16px", mt: 3 }}>
        📞 Contact number:
        <Link href="tel:+18001231272" style={{ fontSize: "16px" }}>
          {" "}
          1800-123-1272
        </Link>
      </Typography> */}
    </Box>
  );
};

export default WooCommerceOverview;
