import { Box, Button, Stack, Tab, Tabs } from "@mui/material";
import React, { useState } from "react";

const tabs = [
  { label: "Products", value: "products" },
  { label: "Orders", value: "orders" },
];

function EbayMapping() {
  const [selectedTab, setSelectedTab] = useState(tabs[0].value);
  return (
    <Box>
      <Stack
        direction="row"
        sx={{
          backgroundColor: "Background.paper",
          padding: "10px",
          borderBottomColor: "#e8e8e8",
          borderBottomWidth: 0.2,
          borderBottomStyle: "solid",
          overflow: "scroll",
        }}
        spacing={4}
        className="scrollbar-hidden"
      >
        {tabs.map((i) => {
          return (
            <Button
              variant={"text"}
              sx={{
                backgroundColor:
                  selectedTab === i.value ? "#e6e6e6" : "transparent",
                color: "#000",
                "&:hover": {
                  backgroundColor:
                    selectedTab === i.value ? "#e6e6e6" : "transparent",
                },
              }}
              onClick={() => setSelectedTab(i.value)}
            >
              {i.label}
            </Button>
          );
        })}
      </Stack>

    </Box>
  );
}

export default EbayMapping;
