import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
import React, { forwardRef, useImperativeHandle, useState } from "react";
import { dialogStyle, primaryLabelLarge } from "../../helper";
import { SupplierSvg } from "../../Svgs";
import { LoadingButton } from "@mui/lab";

const SupplierBasicProfileDialog = forwardRef(({ onAccept, loading }, ref) => {
  const [open, setOpen] = React.useState(false);
  let [info, setInfo] = useState({
    first_name: "Akshay",
    last_name: "Catalog",
    uid: "1682056167450_hCscWt",
    store_id: 632,
    url: "https://akshaysjewellery.jwero.com",
    store_name: "Akshay&#039;s jewellery",
  });

  useImperativeHandle(ref, () => {
    return {
      show: (obj) => {
        setInfo(obj);
        setOpen(true);
      },
    };
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{ ...dialogStyle }}
      maxWidth="xs"
      fullWidth
    >
      <DialogContent>
        <Stack direction="row" justifyContent={"center"}>
          <SupplierSvg
            style={{ height: "100px", width: "100px", alignSelf: "center" }}
          />
        </Stack>
        <Typography sx={{ ...primaryLabelLarge, mt: 4 }}>
          {`${info?.first_name || ""} ${info?.last_name || ""}`} has sent you a
          connection request
        </Typography>

        <DialogContentText id="alert-dialog-description" sx={{ mt: 3 }}>
          Accepting the request will grant you access to{" "}
          {`${info?.first_name || ""} ${info?.last_name || ""}`}'s products in
          your design bank.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Ignore</Button>
        <LoadingButton
          onClick={() => onAccept && onAccept(info)}
          autoFocus
          variant="outlined"
          loading={loading}
        >
          Accept
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
});

export default SupplierBasicProfileDialog;
