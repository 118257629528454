import { Add } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardContent,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  a11yProps,
  isArrayWithValues,
  isObjWithValues,
  sectionLabel,
} from "../../../helper";
import { BellCircle, ProductsIcon } from "../../../Svgs";
import { NoTasks } from "../../chat/ChatHelper";

function ProductAnalyticsBar({}) {
  const [data, setData] = useState({
    series: [],
    labels: [],
  });
  const [taxonomies, setTaxonomies] = useState({});
  const [noProducts, setNoProducts] = useState(false);

  const dashboardDetails = useSelector(
    (state) => state.settings.dashboardDetails
  );
  const fetchingDashboardDetails = useSelector(
    (state) => state.settings.fetchingDashboardDetails
  );

  const isIpad = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const navigate = useNavigate();

  const [selectedTab, setSelectedTab] = React.useState(0);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  useEffect(() => {
    if (!isObjWithValues(dashboardDetails)) return;
    let { taxonomies, products } = dashboardDetails;
    setTaxonomies(taxonomies);
  }, [dashboardDetails]);

  useEffect(() => {
    if (!isObjWithValues(taxonomies)) return;
    let { products } = dashboardDetails;
    if (!products?.total) return setNoProducts(true);
    let categoryUsage = [];
    let collectionUsage = [];
    if (isArrayWithValues(taxonomies.categories))
      categoryUsage = taxonomies.categories.sort((a, b) => b.count - a.count);
    if (isArrayWithValues(taxonomies.collections))
      collectionUsage = taxonomies.collections.sort(
        (a, b) => b.count - a.count
      );

    let series = [];
    let labels = [];
    if (selectedTab === 0) {
      if (isArrayWithValues(categoryUsage))
        for (let category of categoryUsage) {
          let { count, label } = category;
          series.push(count);
          labels.push(label);
        }
    } else if (selectedTab === 1) {
      if (isArrayWithValues(collectionUsage))
        for (let collection of collectionUsage) {
          let { count, label } = collection;
          series.push(count);
          labels.push(label);
        }
    } else if (selectedTab === 2) {
      series = [
        products?.instock || 0,
        products?.onbackorder || 0,
        products?.outofstock || 0,
      ];
      labels = ["In stock", "Make to order", "Out of stock"];
    }
    setData({
      series: series.slice(0, isIpad ? 4 : 6),
      labels: labels?.slice(0, isIpad ? 4 : 6)?.map((i) => i?.split(" ")),
    });
  }, [taxonomies, selectedTab]);

  return (
    <Card sx={{ borderRadius: "15px", height: "100%" }}>
      <CardContent>
        <Typography sx={{ ...sectionLabel }}>Products</Typography>

        <Box sx={{ borderBottomWidth: 1, borderBottomColor: "divider" }}>
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            aria-label="basic tabs example"
            sx={{ mt: 2 }}
          >
            <Tab label="Category" {...a11yProps(0)} />
            <Tab label="Type" {...a11yProps(1)} />
            <Tab label="Stock status" {...a11yProps(2)} />
          </Tabs>
        </Box>
        {noProducts ? (
          <Box sx={{ margin: "auto", height: "100%" }}>
            <NoTasks
              text={
                <Box sx={{ textAlign: "center" }}>
                  <Typography>
                    You have no products added in your inventory
                  </Typography>
                  <Typography sx={{ mt: 1 }}>
                    Let's add your first product.
                  </Typography>
                  <Button
                    variant="outlined"
                    sx={{ mt: 3 }}
                    size="small"
                    startIcon={<Add />}
                    onClick={() => navigate("/products")}
                  >
                    Add Product
                  </Button>
                </Box>
              }
              icon={<ProductsIcon style={{ height: "70px", width: "70px" }} />}
            />
          </Box>
        ) : (
          <Box sx={{ mt: 2 }}>
            <ReactApexChart
              options={{
                ...options,
                xaxis: {
                  categories: data.labels,
                  labels: {
                    style: {
                      // colors: colors,
                      fontSize: "12px",
                    },
                  },
                },
              }}
              series={[{ data: data.series, name: "Products" }]}
              type="bar"
              // width={420}
            />
          </Box>
        )}
      </CardContent>
    </Card>
  );
}

export default ProductAnalyticsBar;

const options = {
  chart: {
    height: 320,
    type: "bar",
    events: {
      click: function (chart, w, e) {
        // console.log(chart, w, e)
      },
    },
  },
  colors: ["#616ADA", "#F6B11B", "#022687", "#E9E9E9"],
  plotOptions: {
    bar: {
      borderRadius: 6,
      columnWidth: "50%",
      columnWidth: "45%",
      distributed: true,
    },
  },
  dataLabels: {
    enabled: false,
  },
  legend: {
    show: false,
  },
};
