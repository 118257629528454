import { Box, Typography } from "@mui/material";
import React from "react";
import { primaryLabel, secondaryLabel } from "../../helper";

function DataNotFound({ illustration, subtitle, title, createButton, sx = {} }) {
  return (
    <Box
      sx={{
        margin: "auto",
        width: "100%",
        textAlign: "center",
        marginTop: "7%",
        ...sx
      }}
    >
      {illustration}
      <Typography
        sx={{
          ...secondaryLabel,
          mt: 3,
          fontSize: "14px",
        }}
      >
        {subtitle}
      </Typography>
      <Typography sx={{ ...primaryLabel, mt: 3, fontSize: "19px" }}>
        {title}
      </Typography>
      {createButton}
    </Box>
  );
}

export default DataNotFound;
