import { useState, useRef } from "react";
import {
  TextField,
  Box,
  Button,
  IconButton,
  InputAdornment,
} from "@mui/material";
import ButtonPopover from "../../components/ButtonPopover";
import AddIcon from "@mui/icons-material/Add";
import DeleteAndSwap from "../../components/DeleteAndSwap";
import DataObjectIcon from "@mui/icons-material/DataObject";
import InsertEmoticonIcon from "@mui/icons-material/InsertEmoticon";
import TextFieldAttributes from "../helper/TextFieldAttributes";
import EmojiPopover from "../../chatbot/helper/EmojiPopover";
import { quickReplyStyles } from "../styles/quickReplyStyles";
import PropTypes from "prop-types";
import { getRandomId } from "../../utils";

const styles = quickReplyStyles;

const BotQuickReplyField = ({ botData, setBotData, data, index }) => {
  const inputRef = useRef();
  const [anchorEl, setAnchorEl] = useState(null);
  const [attributeAnchorEl, setAttributeAnchorEl] = useState(null);

  const handleAttributePopover = (e) => {
    setAttributeAnchorEl(e.currentTarget);
  };

  const handleEmojiPicker = (e) => {
    setAnchorEl(inputRef.current);
  };

  const handleAttributeSelect = (value) => {
    const cursor = inputRef.current.querySelector("textarea").selectionStart;
    const newValue =
      data.description.slice(0, cursor) +
      ` {{${value}}} ` +
      data.description.slice(cursor);

    setBotData((currentField) => {
      return currentField.map((field) => {
        if (field?.id === data?.id) {
          return { ...field, description: newValue };
        }
        return field;
      });
    });
    setAttributeAnchorEl(null);
  };

  const handleEmojiSelect = (emoji) => {
    const emojiText = emoji.native;
    const cursor = inputRef.current.querySelector("textarea").selectionStart;
    const newValue =
      data.description.slice(0, cursor) +
      emojiText +
      data.description.slice(cursor);

    setBotData((currentField) => {
      return currentField.map((field) => {
        if (field?.id === data?.id) {
          return { ...field, description: newValue };
        }
        return field;
      });
    });
    setAnchorEl(false);
  };

  const handleQuickReplyTitle = (e, data) => {
    const description = e.target.value;
    const id = data.id;

    setBotData((quickReplyField) => {
      return quickReplyField.map((field) => {
        if (field.id === id) {
          return { ...field, description: description };
        }
        return field;
      });
    });
  };

  const handleAddButtonClick = (id, dataType) => {
    const newButton = {
      id: getRandomId(),
      title: "Button",
      type: "send_message",
    };
    setBotData((currentField) => {
      return currentField.map((field) => {
        if (field?.id === id && field?.type == dataType) {
          return { ...field, buttons: [...field.buttons, newButton] };
        }
        return field;
      });
    });
  };

  return (
    <Box key={data.id} sx={styles.outerBox}>
      <DeleteAndSwap index={index} setBotData={setBotData} botData={botData} />

      <Box sx={styles.innerBox}>
        <Box sx={styles.textFieldBox}>
          <TextField
            ref={inputRef}
            multiline
            sx={styles.textField}
            placeholder="Enter your message..."
            value={data.description}
            onChange={(e) => {
              handleQuickReplyTitle(e, data);
            }}
            inputProps={{
              maxLength: 256,
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    sx={styles.iconButton}
                    onClick={handleAttributePopover}
                  >
                    <DataObjectIcon sx={styles.icon} />
                  </IconButton>
                  <TextFieldAttributes
                    attributeAnchorEl={attributeAnchorEl}
                    setAttributeAnchorEl={setAttributeAnchorEl}
                    handleAttributeSelect={handleAttributeSelect}
                    data={data}
                  />

                  <IconButton
                    sx={styles.iconButton}
                    onClick={handleEmojiPicker}
                  >
                    <InsertEmoticonIcon sx={styles.icon} />
                  </IconButton>
                  <EmojiPopover
                    anchorEl={anchorEl}
                    setAnchorEl={setAnchorEl}
                    handleEmojiSelect={handleEmojiSelect}
                    data={data}
                  />
                </InputAdornment>
              ),
            }}
            fullWidth
          />
        </Box>
        <Box sx={styles.buttonsBox}>
          {data?.buttons.map((field) => (
            <ButtonPopover
              fieldId={data?.id}
              key={field?.id}
              buttonId={field?.id}
              button={field}
              fieldType={data?.type}
              title={field?.title}
              botData={botData}
              setBotData={setBotData}
            />
          ))}

          {data?.buttons.length < 10 && (
            <Button
              sx={styles.buttons}
              onClick={() => handleAddButtonClick(data?.id, data?.type)}
              startIcon={<AddIcon />}
            >
              Add Button
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
};

BotQuickReplyField.propTypes = {
  data: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  botData: PropTypes.array.isRequired,
  setBotData: PropTypes.func.isRequired,
  handleButtonClick: PropTypes.func.isRequired,
  fileInputRefs: PropTypes.object.isRequired,
};

export default BotQuickReplyField;
