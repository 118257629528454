import * as React from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import { visuallyHidden } from "@mui/utils";
import { useDispatch, useSelector } from "react-redux";
import { fetchAndSaveSingleSettings } from "../../../../../redux/actions/settingActions";
import {
  capitalizeFirstLetter,
  formatDate,
  isObjWithValues,
  primaryLabel,
  validateNumber,
} from "../../../../../helper";
import { Button, Chip } from "@mui/material";
import { exportAsExcel } from "../../../../digital_gold/Dashboard";
import { Sync } from "@material-ui/icons";
import { LoadingButton } from "@mui/lab";
import CustomChip from "../../../../components/CustomChip";
import { setGlobalToast } from "../../../../../redux/actions/helperActions";

function createData(name, calories, fat, carbs, protein) {
  return {
    name,
    calories,
    fat,
    carbs,
    protein,
  };
}

const rows = [
  createData("Cupcake", 305, 3.7, 67, 4.3),
  createData("Donut", 452, 25.0, 51, 4.9),
  createData("Eclair", 262, 16.0, 24, 6.0),
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
  createData("Gingerbread", 356, 16.0, 49, 3.9),
  createData("Honeycomb", 408, 3.2, 87, 6.5),
  createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
  createData("Jelly Bean", 375, 0.0, 94, 0.0),
  createData("KitKat", 518, 26.0, 65, 7.0),
  createData("Lollipop", 392, 0.2, 98, 0.0),
  createData("Marshmallow", 318, 0, 81, 2.0),
  createData("Nougat", 360, 19.0, 9, 37.0),
  createData("Oreo", 437, 18.0, 63, 4.0),
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "sr",
    numeric: false,
    disablePadding: false,
    label: "Sr No.",
  },
  {
    id: "activity",
    numeric: false,
    disablePadding: false,
    label: "Activity",
  },
  {
    id: "Status",
    numeric: false,
    disablePadding: false,
    label: "Status",
  },
  {
    id: "products",
    numeric: true,
    disablePadding: false,
    label: "Products",
  },
  {
    id: "carbs",
    numeric: true,
    disablePadding: false,
    label: "Date",
  },
  {
    id: "protein",
    numeric: true,
    disablePadding: false,
    label: "Actions",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected } = props;
  const dispatch = useDispatch();

  const amazonFeedHistoryFetching = useSelector(
    (state) => state.settings.amazonFeedHistoryFetching
  );

  const onRefresh = () => {
    dispatch(
      fetchAndSaveSingleSettings({
        endpoint: "amazon_feed_history",
        key: "amazonFeedHistory",
        onSuccess: () => {
          dispatch(
            setGlobalToast({
              show: true,
              message: "Amazon activities refreshed successfully",
              severity: "success",
            })
          );
        },
      })
    );
  };
  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Amazon Activity
        </Typography>
      )}
      <LoadingButton
        loading={amazonFeedHistoryFetching}
        onClick={onRefresh}
        startIcon={<Sync />}
        size="small"
        variant="outlined"
      >
        Refresh
      </LoadingButton>
      {/* {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )} */}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function Activities() {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [rows, setRows] = React.useState([]);
  const amazonFeedHistory = useSelector(
    (state) => state.settings.amazonFeedHistory
  );
  const amazonFeedHistoryFetched = useSelector(
    (state) => state.settings.amazonFeedHistoryFetched
  );
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (amazonFeedHistory)
      setRows(
        Object.values(amazonFeedHistory).sort((a, b) => b.start - a.start)
      );
  }, [amazonFeedHistory, amazonFeedHistoryFetched]);

  React.useEffect(() => {
    if (!amazonFeedHistoryFetched)
      dispatch(
        fetchAndSaveSingleSettings({
          endpoint: "amazon_feed_history",
          key: "amazonFeedHistory",
        })
      );
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = React.useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rowsPerPage, rows]
  );

  const onDownloadReport = (report) => {
    exportAsExcel({
      data: report,
      fileName: `Amazon report - ${formatDate(
        new Date(),
        "dd:mm:yyyy hh:mm am/pm"
      )}`,
      sheetName: `Customers`,
    });
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <EnhancedTableToolbar numSelected={selected.length} />
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {visibleRows.map((row, index) => {
                const isItemSelected = isSelected(row.name);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    // hover
                    // onClick={(event) => handleClick(event, row.name)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.name}
                    selected={isItemSelected}
                    // sx={{ cursor: "pointer" }}
                  >
                    <TableCell align="left">{index + 1}</TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      //   padding="none"
                    >
                      {row.type === "bulk_create"
                        ? "Product Create"
                        : "Product Update"}
                    </TableCell>
                    <TableCell align="left">
                      <CustomChip
                        label={capitalizeFirstLetter(row.status)}
                        type={row.status}
                      />
                    </TableCell>
                    <TableCell align="right">{row.products?.length}</TableCell>
                    <TableCell align="right">
                      {formatDate(
                        new Date(validateNumber(row.start)),
                        "dd:mm:yyyy hh:mm am/pm"
                      )}
                    </TableCell>
                    <TableCell align="right">
                      {!row.feedReport ? (
                        <Typography sx={{ ...primaryLabel }}>N/A</Typography>
                      ) : (
                        <Button
                          variant="outlined"
                          size="small"
                          onClick={() =>
                            onDownloadReport(
                              row.productErrors || row.feedReport
                            )
                          }
                          disabled={!row.feedReport}
                        >
                          Download report
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      {/* <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      /> */}
    </Box>
  );
}

function makeKeysReadable(objects) {
  const newObjects = [];

  for (let i = 0; i < objects.length; i++) {
    const object = objects[i];
    const newObject = {};

    // Map the old keys to new, more readable keys
    const keyMap = {
      sku: "SKU",
      "error-code": "Error Code",
      "error-type": "Error Type",
      "error-message": "Error Message",
    };

    // Iterate over the properties of the object
    for (const key in object) {
      if (keyMap.hasOwnProperty(key)) {
        // If the key exists in the keyMap, use the new key
        const newKey = keyMap[key];
        newObject[newKey] = object[key];
      } else {
        // Otherwise, use the original key
        newObject[key] = object[key];
      }
    }

    newObjects.push(newObject);
  }

  return newObjects;
}
