import { Add, Close, Delete, Done, Groups, PeopleAltOutlined } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { useMemo } from "react";
import { Fragment } from "react";
import { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  dialogStyle,
  getNameFromPlatform,
  getRandomString,
  getToken,
  getWebsite,
  isArray,
  isArrayWithValues,
  isObjWithValues,
} from "../../../helper";
import { setGlobalToast } from "../../../redux/actions/helperActions";
import {
  EDIT_CHATS,
  setUpConversations,
  updateChatObject,
} from "../../../redux/actions/chatActions";
import ChatsCustomer from "./ChatsCustomer";
import { LoadingButton } from "@mui/lab";

const BroadcastList = ({
  asBox,
  open,
  handleClose,
  selectedCustomers,
  hasBoradCast,
  setHasBoradCast,
  hideView,
  hideDelete,
}) => {
  const broadcastList = useSelector((state) => state.chats.broadcastList);
  const allReduxConversations = useSelector(
    (state) => state.chats.conversations
  );
  const [groups, setGroups] = useState({});
  const [viewGroup, setViewGroup] = useState({});

  const dispatch = useDispatch();
  const [showTextFeild, setShowTextFeild] = useState(false);
  const [deletingId, setDeletingId] = useState(false);
  const [viewCustomer, setViewCustomer] = useState([]);
  const [labelValue, setLabelValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [deleteObj, setDeleteObj] = useState({});

  useMemo(() => {
    let groupObj = {};
    if (
      isArrayWithValues(allReduxConversations) &&
      isArrayWithValues(Object.values(broadcastList))
    ) {
      for (const customer of allReduxConversations || []) {
        if (isArrayWithValues(customer?.label)) {
          for (const label of customer?.label) {
            let group = Object.values(broadcastList || {})?.find(
              (obj) => obj?.id === label
            );
            if (Boolean(group)) {
              groupObj = {
                ...groupObj,
                [group.id]: {
                  group_detail: group,
                  customers: isArray(groupObj?.[group.id]?.customers)
                    ? [...groupObj[group.id].customers, customer]
                    : [customer],
                },
              };
            }
          }
        }
      }
      setGroups(groupObj);
    }
  }, [broadcastList, allReduxConversations]);

  const onAdd = async (e) => {
    // console.log(e.target.value, e.key);

    // if (e.key === "Enter") {
    setLoading(true);
    console.log("enter");
    let id = `${Date.now()}_${getRandomString(4)}`;
    let website = getWebsite();
    let token = getToken();
    const res = await axios({
      url: `${website}/wp-json/store/v1/settings/broadcast_list`,
      headers: {
        Authorization: `Basic ${token}`,
      },
      method: "PUT",
      data: {
        type: "broadcast_list",
        data: {
          [id]: {
            id,
            label: labelValue,
          },
        },
      },
    });
    console.log(res);
    if (res?.data?.success) {
      await onAssgin({
        id,
        label: labelValue,
      });
      setShowTextFeild(false);
      setLabelValue("");
      dispatch({
        type: EDIT_CHATS,
        payload: { broadcastList: res?.data?.data },
      });
      dispatch(setUpConversations(allReduxConversations));
      handleClose && handleClose();
    }
    setLoading(false);
    // }
  };

  const onDelete = async (id) => {
    setLoading(true);
    console.log("enter");
    setDeletingId(id);
    let website = getWebsite();
    let token = getToken();
    const res = await axios({
      url: `${website}/wp-json/store/v1/settings/broadcast_list`,
      headers: {
        Authorization: `Basic ${token}`,
      },
      method: "DELETE",
      data: {
        id,
      },
    });
    console.log(res);
    if (res?.data?.success) {
      dispatch({
        type: EDIT_CHATS,
        payload: { broadcastList: res?.data?.data },
      });
      setShowTextFeild(false);
    }
    setDeleteObj({});
    setDeletingId(false);
    setLoading(false);
  };

  const onAssgin = async (obj, customer) => {
    setDeletingId(obj?.id);
    // if (selectedCustomers?.find(o => o.platform !== customer?.[0]?.platform)) {
    //   return dispatch(setGlobalToast({
    //     show: true,
    //     message: 'Cannot',

    //   }))
    // }
    setLoading(true);
    if (isArrayWithValues(selectedCustomers)) {
      // return
      for await (const iterator of selectedCustomers) {
        let arr = Array.isArray(iterator?.label) ? iterator?.label : [];
        // console.log(arr, arr.push(obj.id));
        arr.push(obj.id);

        // return
        await dispatch(
          updateChatObject(iterator, {
            payload: {
              label: [...new Set(arr)],
            },
          })
        );
        dispatch(setUpConversations(allReduxConversations));
        // onClose();
      }
    }
    setLoading(false);
    dispatch(
      setGlobalToast({
        show: true,
        message: `${selectedCustomers?.length == 1
          ? getNameFromPlatform(selectedCustomers?.[0] || {})
          : selectedCustomers?.length || ""
          } added in broadcast ${obj?.label || ""}`,
        severity: "success",
      })
    );
    handleClose && handleClose();
    setDeletingId(false);
  };

  return (
    <>
      <DialogTitle
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          gap: 2
        }}
      >
        <PeopleAltOutlined />
        Broadcast List{" "}
        {isObjWithValues(broadcastList)
          ? `(${Object.keys(broadcastList)?.length})`
          : null}
      </DialogTitle>
      <DialogContent>
        <List>
          <>
            {" "}
            {showTextFeild ? (
              <ListItem>
                <TextField
                  placeholder="BroadcastList Name"
                  size="small"
                  fullWidth
                  variant="standard"
                  focused
                  // type={"submit"}
                  // onSubmit={onAdd}
                  value={labelValue}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      onAdd();
                    }
                  }}
                  onChange={(e) => {
                    setLabelValue(e.target.value);
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Add />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment
                        sx={{ cursor: "pointer" }}
                        position="start"
                        onClick={() => setShowTextFeild(false)}
                      >
                        {loading ? (
                          <CircularProgress
                            style={{ height: "20px", width: "20px" }}
                          />
                        ) : (
                          <Close />
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
              </ListItem>
            ) : (
              <>
                <ListItemButton
                  onClick={() => {
                    setShowTextFeild(true);
                    setHasBoradCast && setHasBoradCast(false);
                  }}
                  className={hasBoradCast ? "btn--jump" : ""}
                // selected={selectedIndex === 0}
                // onClick={(event) => handleListItemClick(event, 0)}
                >
                  <ListItemIcon>
                    <Add />
                  </ListItemIcon>
                  <ListItemText primary={"Add new broadcastlist"} />
                </ListItemButton>
              </>
            )}
          </>

          {Object.values(
            isObjWithValues(broadcastList) ? broadcastList : {}
          ).map((obj) => {
            let alreadyAssign = false;
            if (
              selectedCustomers?.length === 1 &&
              isArrayWithValues(selectedCustomers?.[0]?.label) &&
              selectedCustomers?.[0]?.label?.includes(obj?.id)
            ) {
              alreadyAssign = true;
            }
            return (
              <ListItem
                // onClick={() => onAssgin(obj)}
                key={obj?.id}
                secondaryAction={
                  <>
                    {loading && deletingId === obj?.id ? (
                      <CircularProgress
                        style={{ height: "15px", width: "15px" }}
                      />
                    ) : hideDelete ? null : (
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={() => setDeleteObj(obj)}
                      >
                        <Delete />
                      </IconButton>
                    )}
                  </>
                }
              >
                <ListItemButton
                  role={undefined}
                  selected={deletingId === obj?.id}
                  onClick={() =>
                    !alreadyAssign &&
                    onAssgin(obj, groups?.[obj?.id]?.customers || [])
                  }
                  dense
                >
                  <ListItemIcon>
                    <PeopleAltOutlined />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Stack direction={"row"} alignItems="center">
                        <Typography variant="h6">{obj?.label} </Typography>{" "}
                        <Typography variant="h6" sx={{ ml: 4 }}>
                          {groups?.[obj?.id]?.customers?.length
                            ? `(${groups?.[obj?.id]?.customers?.length})`
                            : null}
                        </Typography>
                        {alreadyAssign && (
                          <Tooltip title={"Already Assign"}>
                            <Avatar
                              height={"20px"}
                              width={"20px"}
                              sx={{ ml: 3, height: "20px", width: "20px" }}
                              src={selectedCustomers?.[0]?.profile_pic || ""}
                            />
                          </Tooltip>
                        )}
                      </Stack>
                    }
                  />

                  {hideView ? null : (
                    <Button
                      variant="outlined"
                      onClick={(e) => {
                        e.stopPropagation();
                        setViewGroup(groups?.[obj?.id]);
                        setViewCustomer(groups?.[obj?.id]?.customers || []);
                      }}
                    >
                      View
                    </Button>
                  )}
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
      </DialogContent>

      <Dialog
        open={isArrayWithValues(viewCustomer)}
        onClose={() => {
          setViewGroup({});
          setViewCustomer([]);
        }}
        sx={{ ...dialogStyle }}
        fullWidth
        maxWidth={"xs"}
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 2,
            justifyContent: "space-between",
          }}
        >
          {viewGroup?.group_detail?.label}{" "}
          <Typography variant="h6" fontSize={"20px"}>
            {viewGroup?.customers?.length
              ? `(${viewGroup?.customers?.length})`
              : ""}{" "}
            Contacts
          </Typography>
        </DialogTitle>
        <DialogContent>
          {/* <Typography variant="h6"></Typography> */}
          <ChatsCustomer selectedCustomer={viewCustomer} />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setViewGroup({});
              setViewCustomer([]);
            }}
            variant="contained"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={isObjWithValues(deleteObj)}
        onClose={() => setDeleteObj({})}
        sx={{ ...dialogStyle }}
      >
        <DialogTitle>
          Are you sure You want to Delete BroadcastList {deleteObj?.label}
        </DialogTitle>
        <DialogContent></DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteObj({})}>Cancel</Button>
          <LoadingButton
            loading={loading}
            onClick={() => onDelete(deleteObj?.id)}
            variant="contained"
            color="error"
          >
            Delete
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default BroadcastList;
