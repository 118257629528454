import { isArrayWithValues } from "../../helper";
import {
  EDIT_CHATS,
  EDIT_CHATS_CHILDREN,
  SPREAD_PAYLOAD,
  EDIT_CHATS_CHILDREN_SPREAD,
} from "../actions/chatActions";

const initialState = {
  settingsUpUserTasks: true,
};
const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SPREAD_PAYLOAD:
      return { ...state, ...payload };
    case EDIT_CHATS:
      if (isArrayWithValues(payload?.conversations)) {
        // console.log(, "<<<<state");

        let unreadCount = {
          all_messages: 0,
          whatsapp: 0,
          facebook: 0,
          instagram: 0,
        };
        // let mergeObj = {};
        for (const customerChat of payload?.conversations || []) {
          // if (customerChat?.company_name) {
          //   mergeObj[customerChat?.company_name] = {
          //     id: customerChat?.company_name,
          //     name: customerChat?.company_name,
          //     ids: mergeObj[customerChat?.company_name]?.ids?.push(customerChat?.id),
          //     platform: "whatsapp"
          //   }
          // }
          if (
            customerChat?.conversation?.[customerChat?.conversation?.length - 1]
              ?.is_read === 0
          ) {
            unreadCount.all_messages += 1;
            unreadCount[customerChat?.platform] += 1;
          }
        }

        payload.unreadCount = unreadCount;
      }
      return { ...state, ...payload };
    case EDIT_CHATS_CHILDREN_SPREAD:
      return {
        ...state,
        [payload.key]: { ...state[payload.key], ...payload.payload },
      };
    case EDIT_CHATS_CHILDREN:
      return {
        ...state,
        [payload?.key]: {
          ...state?.[payload?.key],
          [payload?.children]: payload?.value,
        },
      };

    default:
      return state;
  }
};
export default reducer;
