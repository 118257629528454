import { math } from "polished";
import {
  capitalizeFirstLetter,
  getProductPrice,
  getRandomString,
  isArray,
  isArrayWithValues,
  isObjWithValues,
  setupJweroLegacyProduct,
  validateNumber,
} from "../../helper";
import { currencySymbols } from "../pricing/Gold";
import { getDiamondRate, getDiamondSizeLabel } from "../products/AddProduct";
import {
  platinumPurities,
  purities,
  silverPurities,
} from "../settings/MasterSettings";

const setupQuotationProduct = ({
  product,
  master_pricing,
  product_settings,
  diamond_groups,
  tax_rate,
  customization,
}) => {
  if (!isObjWithValues(product)) return;
  let { meta_data } = product;
  let updatedProduct = { ...product };
  if (isArrayWithValues(meta_data))
    for (let metaObj of meta_data)
      updatedProduct[metaObj?.key] = metaObj?.value;
  updatedProduct = setupJweroLegacyProduct(updatedProduct);
  let priceBreakup = getProductPrice(
    product,
    master_pricing,
    product_settings,
    diamond_groups,
    tax_rate
  );

  if (!updatedProduct?.customize_id)
    updatedProduct.customize_id = getRandomString(10);

  updatedProduct.priceBreakup = priceBreakup;
  let {
    details_rows,
    metal_breakup_rows,
    diamond_rows,
    gemstone_rows,
    other_rows,
  } = getDetailRows({
    product: updatedProduct,
    product_settings,
    priceBreakup,
    diamond_groups,
    master_pricing,
  });
  updatedProduct.details_rows = details_rows;
  updatedProduct.metal_breakup_rows = metal_breakup_rows;
  updatedProduct.diamond_rows = diamond_rows;
  updatedProduct.gemstone_rows = gemstone_rows;
  updatedProduct.other_rows = other_rows;

  // let { customization = [] } = updatedProduct;
  let totalQuantity = 0;
  let customizationArray = customization?.[product?.id] || [];
  let lineItem = [];
  for (let customize of customizationArray) {
    let { quantity = 1, metal_purity } = customize;
    totalQuantity += validateNumber(quantity);
    let customizedProduct = changeCustomizationInProduct({
      product: product,
      customizationObj: customize,
    });
    let priceBreakup = getProductPrice(
      customizedProduct,
      master_pricing,
      product_settings,
      diamond_groups,
      tax_rate
    );
    lineItem.push({
      product_id: product?.id,
      subtotal: `${priceBreakup.priceWithoutTax}`,
      subtotal_tax: `${priceBreakup.tax}`,
      total: `${priceBreakup?.price}`,
      total_tax: `${priceBreakup?.tax}`,
      price: `${priceBreakup?.price}`,
      quantity,
    });
  }

  updatedProduct.lineItem = lineItem;
  updatedProduct.totalQuantity = totalQuantity;
  updatedProduct.totalProductPrice = Math.round(
    validateNumber(updatedProduct?.priceBreakup?.price) * totalQuantity
  );

  return updatedProduct;
};

let getDetailRows = ({
  product,
  product_settings,
  priceBreakup,
  diamond_groups,
  master_pricing,
}) => {
  let default_currency = product_settings?.default_currency;
  let details_rows = [];
  let metal_breakup_rows = {};
  // product details tab info
  let {
    metal_types,
    diamond,
    diamond_from,
    colorstone_details,
    colorstone_from,
    wastage_percent,
    labourType,
    minimum_labour,
  } = product;
  let metalPurities = {
    gold: purities,
    silver: silverPurities,
    platinium: platinumPurities,
  };
  if (isArrayWithValues(metal_types)) {
    let purityKey = {
      gold: "gold_kt",
      silver: "silver_purity",
      platinium: "platinium_purity",
    };
    for (let metal of metal_types) {
      let purities = metalPurities[metal];
      let purity = purities.find((i) => i.value == product[purityKey[metal]]);
      details_rows.push({
        metal: capitalizeFirstLetter(metal),
        purity: purity?.label || "-",
        gross: product[`${metal}_gross`] || "-",
        gross_suffix: "gms",
        amount:
          priceBreakup?.metalBreakup?.[
            metal === "platinium" ? "platinum" : metal
          ] || "-",
        amount_prefix: currencySymbols[default_currency],
      });
      if (!isArray(metal_breakup_rows?.[metal])) metal_breakup_rows[metal] = [];

      metal_breakup_rows[metal] = {
        metal: capitalizeFirstLetter(metal),
        purity: purity?.label || "-",
        gross: product[`${metal}_gross`] || "-",
        gross_suffix: "gms",
        net: product[`${metal}_net`] || "-",
        net_suffix: "gms",
        rate: priceBreakup?.metalRates?.[
          metal === "platinium" ? "platinum" : metal
        ]?.[product?.[purityKey[metal]]],
        rate_prefix: currencySymbols[default_currency],
        amount:
          priceBreakup?.metalBreakup?.[
            metal === "platinium" ? "platinum" : metal
          ] || "-",
        amount_prefix: currencySymbols[default_currency],
      };
    }
    //   let diamond_details = isObjWithValues(diamond)
    //     ? Object.values(diamond)
    //     : isArrayWithValues(diamond)
    //     ? diamond
    //     : null;
    let firstDiamond = diamond?.[0] || diamond?.[1] || "";
    let firstGemstone =
      colorstone_details?.[0] || colorstone_details?.[1] || "";

    if (isObjWithValues(firstDiamond)) {
      details_rows.push({
        metal: "Diamond",
        purity: firstDiamond?.diamond_purity || "-",
        gross: firstDiamond?.diamond_weight || "-",
        gross_suffix: "cts",
        amount: priceBreakup?.[diamond_from]?.["diamond"] || "-",
        amount_prefix: currencySymbols[default_currency],
      });
    }
    if (isObjWithValues(firstGemstone)) {
      details_rows.push({
        metal: "Gemstone",
        purity: firstGemstone?.colorstone_purity || "-",
        gross: firstGemstone?.colorstone_weight || "-",
        gross_suffix: "cts",
        amount: priceBreakup?.[colorstone_from]?.["colorstone"] || "-",
        amount_prefix: currencySymbols[default_currency],
      });
    }
    details_rows.push({
      metal: "Labour:",
      purity: wastage_percent || "-",
      purity_suffix: "% Wastage",
      gross: minimum_labour || "-",
      gross_suffix: "/gram",
      amount: priceBreakup?.[labourType]?.["labour"] || "-",
      amount_prefix: currencySymbols[default_currency],
    });
    details_rows.push({
      metal: "Total",
      purity: "",
      gross: "",
      amount: priceBreakup?.["price"] || "-",
      amount_prefix: currencySymbols[default_currency],
    });
    metal_breakup_rows["Total"] = {
      metal: "Total",
      purity: Object.keys(metal_breakup_rows).length > 1 ? "" : "Total",
      gross: "",
      net: "",
      rate: "",
      amount: priceBreakup?.master?.["metal"] || "-",
      amount_prefix: currencySymbols[default_currency],
    };
  }
  let diamond_rows = getDiamondRows({
    product,
    default_currency,
    diamond_groups,
    master_pricing,
  });
  let gemstone_rows = getGemstoneRows({
    product,
    default_currency,
    diamond_groups,
    master_pricing,
  });
  let other_rows = getOtherRows({
    product,
    default_currency,
    diamond_groups,
    master_pricing,
  });
  return {
    details_rows,
    metal_breakup_rows,
    diamond_rows,
    gemstone_rows,
    other_rows,
  };
};

const getDiamondRows = ({
  product = {},
  diamond_groups,
  master_pricing,
  default_currency,
}) => {
  let { diamond, priceBreakup = {}, diamond_from } = product;
  let { diamondBreakup } = priceBreakup;
  let diamondPricings = master_pricing?.diamond_pricing?.[default_currency];
  let diamond_array = isObjWithValues(diamond)
    ? Object.values(diamond)
    : isArrayWithValues(diamond)
    ? diamond
    : null;
  let diamond_rows = [];
  if (!isArrayWithValues(diamond_array)) return [];
  let index = 1;
  for (let diamondObj of diamond_array) {
    let totalWeight = 0;
    let totalPieces = 0;
    let {
      diamond_quality,
      diamond_shape,
      // diamond_sieve,
      diamond_cut,
      diamond_weight,
      diamond_pieces,
      diamond_type,
    } = diamondObj;
    totalWeight += Number(diamond_weight);
    totalPieces += Number(diamond_pieces);

    let diamond_sieve;
    if (isObjWithValues(diamond_groups)) {
      let netWeight = Number(Number(diamond_weight) / Number(diamond_pieces));
      let diamondGroup = Object.values(diamond_groups);

      let obj = diamondGroup.find((i) => {
        return (
          i.shape === diamond_shape &&
          netWeight >= Number(i.from) &&
          netWeight <= Number(i.to)
        );
      });

      if (!obj)
        obj = diamondGroup.find((i) => {
          let netWeightFixed2 = Number(netWeight).toFixed(2);
          return (
            i.shape === diamond_shape &&
            netWeightFixed2 >= Number(i.from) &&
            netWeightFixed2 <= Number(i.to)
          );
        });

      diamond_sieve = obj?.id;
    }

    let sieveLabel = getDiamondSizeLabel(diamond_groups, diamond_sieve);
    let amount = diamondBreakup[index];
    index++;
    diamond_rows.push({
      purity: diamond_quality || "",
      shape: diamond_shape || "",
      size: sieveLabel || "",
      weight: diamond_weight
        ? `${diamond_weight} cts\n ${
            diamond_pieces ? `(${diamond_pieces} pcs)` : ""
          }`
        : "",
      // weight_prefix:
      amount,
      amount_prefix: currencySymbols[default_currency],
    });
  }
  diamond_rows.push({
    purity: "Total",
    shape: "",
    size: "",
    weight: "",
    amount: priceBreakup?.[diamond_from]?.diamond,
    amount_prefix: currencySymbols[default_currency],
  });
  return diamond_rows;
};
const getGemstoneRows = ({ product = {}, default_currency }) => {
  let gemstone_rows = [];

  let { colorstone_details, priceBreakup, colorstone_from } = product;
  let { gemstoneBreakup } = priceBreakup || {};
  if (!isObjWithValues(colorstone_details)) return [];

  let gemstone_array = Object.values(colorstone_details);
  let index = 1;
  for (let gemstoneObj of gemstone_array) {
    let {
      colorstone_quality,
      colorstone_type,
      colorstone_shape,
      colorstone_size,
      colorstone_pieces,
      colorstone_weight,
    } = gemstoneObj;
    let amount = gemstoneBreakup[index];
    index++;
    gemstone_rows.push({
      purity: colorstone_quality || "",
      shape: colorstone_shape || "",
      size: colorstone_size || "",
      weight: colorstone_weight
        ? `${colorstone_weight} cts\n ${
            colorstone_pieces ? `(${colorstone_pieces} pcs)` : ""
          }`
        : "",
      amount,
      amount_prefix: currencySymbols[default_currency],
    });
  }

  gemstone_rows.push({
    purity: "Total",
    shape: "",
    size: "",
    weight: "",
    amount: priceBreakup?.[colorstone_from]?.gemstone,
    amount_prefix: currencySymbols[default_currency],
  });
  return gemstone_rows;
};
const getOtherRows = ({ product = {}, default_currency }) => {
  let other_rows = [];
  let {
    priceBreakup,
    wastage_percent,
    minimum_labour,
    labourType,
    extra_charges,
  } = product;

  let amount = 0;
  other_rows.push({
    title: "Labour",
    // purity: wastage_percent || "-",
    // purity_suffix: "% Wastage",
    // gross: minimum_labour || "-",
    // gross_suffix: "/gram",
    amount: priceBreakup?.[labourType]?.["labour"] || "-",
    amount_prefix: currencySymbols[default_currency],
  });
  amount += validateNumber(priceBreakup?.[labourType]?.["labour"]);

  if (isObjWithValues(extra_charges)) {
    let charges_array = Object.values(extra_charges);
    for (let chargeObj of charges_array) {
      let { extra_charge_label, extra_charge_value } = chargeObj;

      other_rows.push({
        title: extra_charge_label,
        amount: extra_charge_value || "-",
        amount_prefix: currencySymbols[default_currency],
      });
      amount += validateNumber(extra_charge_value);
    }
  }

  other_rows.push({
    title: "Total",
    // purity: wastage_percent || "-",
    // purity_suffix: "% Wastage",
    // gross: minimum_labour || "-",
    // gross_suffix: "/gram",
    amount,
    amount_prefix: currencySymbols[default_currency],
  });

  return other_rows;
};

const setupProductsSummary = ({
  quotation,
  master_pricing,
  product_settings,
  diamond_groups,
  tax_rate,
}) => {
  let { productDetails, customization } = quotation;
  if (!isArrayWithValues(productDetails)) return;

  let summary = {
    metal: 0,
    diamond: 0,
    gemstone: 0,
    labour: 0,
    total: 0,
    avgPricePerProduct: 0,
    totalProducts: 0,
    metal_gross_weight: 0,
    total_metal_rate: 0,
    avg_rate_per_gram: 0,
    total_diamond_pieces: 0,
    total_diamond_weight: 0,
    avg_diamond_weight_per_pc: 0,
    total_gemstone_pieces: 0,
    total_gemstone_weight: 0,
    avg_gemstone_weight_per_pc: 0,
    labour_per_gram: 0,
  };

  for (let product of productDetails) {
    let {
      id,
      diamond_from,
      colorstone_from,
      labourType,
      gold_gross,
      silver_gross,
      platinium_gross,
      metal_types,
      diamond,
      colorstone_details,
    } = product;
    let productCustomizations = customization?.[id];

    if (!isArrayWithValues(productCustomizations)) continue;

    let diamondArray = [];
    if (isObjWithValues(diamond)) diamondArray = Object.values(diamond);
    else if (isArrayWithValues(diamond)) diamondArray = diamond;

    let gemstoneArray = [];
    if (isObjWithValues(colorstone_details))
      gemstoneArray = Object.values(colorstone_details);

    summary.totalProducts += productCustomizations?.length;
    for (let customization of productCustomizations) {
      let { metal_purity, quantity = 1 } = customization;

      if (isArrayWithValues(diamondArray)) {
        for (let diamond of diamondArray) {
          let { diamond_pieces, diamond_weight } = diamond;
          summary.total_diamond_weight +=
            validateNumber(diamond_weight) * quantity;
          summary.total_diamond_pieces +=
            validateNumber(diamond_pieces) * quantity;
          let avgWeightPerPc =
            validateNumber(validateNumber(diamond_weight) * quantity) /
            validateNumber(validateNumber(diamond_pieces) * quantity);

          summary.avg_diamond_weight_per_pc += validateNumber(avgWeightPerPc);
        }
      }
      if (isArrayWithValues(gemstoneArray)) {
        for (let gemstone of gemstoneArray) {
          let { colorstone_pieces, colorstone_weight } = gemstone;
          summary.total_gemstone_weight +=
            validateNumber(colorstone_weight) * quantity;
          summary.total_gemstone_pieces +=
            validateNumber(colorstone_pieces) * quantity;
          let avgWeightPerPc =
            validateNumber(validateNumber(colorstone_weight) * quantity) /
            validateNumber(validateNumber(colorstone_pieces) * quantity);

          summary.avg_gemstone_weight_per_pc += validateNumber(avgWeightPerPc);
        }
      }

      summary.metal_gross_weight +=
        validateNumber(gold_gross) +
        validateNumber(silver_gross) +
        validateNumber(platinium_gross);

      let customizedProduct = { ...product };
      let { meta_data } = product;
      if (metal_purity) {
        let goldKtIndex = meta_data?.findIndex((i) => i.key === "gold_kt");
        if (goldKtIndex)
          meta_data[goldKtIndex] = { key: "gold_kt", value: metal_purity };
        customizedProduct.gold_kt = metal_purity;
        customizedProduct.meta_data = meta_data;
      }

      let priceBreakupOfCustomizedProduct = getProductPrice(
        customizedProduct,
        master_pricing,
        product_settings,
        diamond_groups,
        tax_rate
      );
      let purityKey = {
        gold: "gold_kt",
        silver: "silver_purity",
        platinium: "platinium_purity",
      };
      let { metalRates } = priceBreakupOfCustomizedProduct;
      if (isArrayWithValues(metal_types))
        for (let metal of metal_types) {
          if (metalRates?.[metal]?.[product[purityKey[metal]]]) {
            let rate = validateNumber(
              metalRates?.[metal]?.[product[purityKey[metal]]]
            );
            summary.total_metal_rate += rate;
            let avgRate = Math.round(rate / product[`${metal}_gross`]);
            summary.avg_rate_per_gram += avgRate;
          }
        }

      summary.metal += validateNumber(
        Math.round(priceBreakupOfCustomizedProduct?.master?.metal * quantity)
      );
      summary.diamond += validateNumber(
        Math.round(
          priceBreakupOfCustomizedProduct?.[diamond_from]?.diamond * quantity
        )
      );
      summary.gemstone += validateNumber(
        Math.round(
          priceBreakupOfCustomizedProduct?.[colorstone_from]?.gemstone *
            quantity
        )
      );
      summary.labour += validateNumber(
        Math.round(
          priceBreakupOfCustomizedProduct?.[labourType]?.labour * quantity
        )
      );
      summary.total += validateNumber(
        Math.round(priceBreakupOfCustomizedProduct?.totalPrice * quantity)
      );
    }
  }
  summary.labour_per_gram = Math.round(
    summary.labour / Number(summary.metal_gross_weight)
  );
  summary.avg_gemstone_weight_per_pc = Number(
    summary.avg_gemstone_weight_per_pc
  ).toFixed(3);
  summary.avg_diamond_weight_per_pc = Number(
    summary.avg_diamond_weight_per_pc
  ).toFixed(3);
  summary.metal_gross_weight = Number(summary.metal_gross_weight).toFixed(3);
  summary.total_gemstone_weight = Number(summary.total_gemstone_weight).toFixed(
    3
  );
  summary.total_diamond_weight = Number(summary.total_diamond_weight).toFixed(
    3
  );
  summary.avgPricePerProduct += validateNumber(
    Math.round(summary?.total / summary?.totalProducts)
  );

  return summary;
};

const setupCategorySummary = ({ quotation }) => {
  let { customization, productDetails } = quotation;
  let categorySummary = {};
  let defaultObj = {
    quantity: 0,
    metal_gross_weight: 0,
    diamond_weight: 0,
    diamond_pieces: 0,
  };
  if (!isArrayWithValues(productDetails)) return {};
  for (let product of productDetails) {
    let { categories, id } = product;
    let productCustomizations = customization?.[id];
    let quantity = 0;
    productCustomizations?.map((i) => (quantity += i.quantity || 1));

    if (!isArrayWithValues(categories)) continue;
    let category = categories?.[0]?.slug || categories?.[0]?.value;
    let categoryLabel = categories?.[0]?.name || categories?.[0]?.label;
    if (!category) continue;
    if (!categorySummary.hasOwnProperty(category))
      categorySummary[category] = { ...defaultObj, label: categoryLabel };
    // if (!categorySummary?.[category]) categorySummary[category] = defaultObj;

    categorySummary[category].quantity = quantity;

    let { diamond, gold_gross } = product;
    categorySummary[category].metal_gross_weight += validateNumber(gold_gross);

    let diamondArray = [];
    if (isObjWithValues(diamond)) diamondArray = Object.values(diamond);
    else if (isArrayWithValues(diamond)) diamondArray = diamond;

    if (isArrayWithValues(diamondArray)) {
      for (let diamond of diamondArray) {
        let { diamond_weight, diamond_pieces } = diamond;
        categorySummary[category].diamond_weight += Number(
          validateNumber(diamond_weight)
        );
        categorySummary[category].diamond_pieces +=
          validateNumber(diamond_pieces);
      }
    }
    categorySummary[category].metal_gross_weight = Number(
      categorySummary[category].metal_gross_weight * quantity
    );
    categorySummary[category].diamond_weight = Number(
      Number(categorySummary[category].diamond_weight) * quantity
    );
    categorySummary[category].diamond_pieces = Math.round(
      categorySummary[category].diamond_pieces * quantity
    );
  }
  for (let category in categorySummary) {
    categorySummary[category].metal_gross_weight = Number(
      categorySummary[category].metal_gross_weight
    ).toFixed(3);
    categorySummary[category].diamond_weight = Number(
      Number(categorySummary[category].diamond_weight)
    ).toFixed(2);
  }
  return categorySummary;
};

const changeCustomizationInProduct = ({ product, customizationObj }) => {
  let { metal_color, metal_purity } = customizationObj;
  let { meta_data } = product;
  let goldKtIndex = meta_data?.findIndex((i) => i.key === "gold_kt");
  if (goldKtIndex)
    meta_data[goldKtIndex] = { key: "gold_kt", value: metal_purity };
  return { ...product, gold_kt: metal_purity, meta_data };
};
export {
  setupQuotationProduct,
  setupProductsSummary,
  setupCategorySummary,
  changeCustomizationInProduct,
};
