import React, { useContext, useState } from "react";
import { FlowStateContext } from "..";
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Label } from "../../customers/components/Info";
import { CampaignIconSvg, WhatsappSvg } from "../../../Svgs";
import {
  Add,
  DescriptionOutlined,
  EmailOutlined,
  NotificationsActiveOutlined,
  SmsOutlined,
} from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  EmptyState,
  dialogStyle,
  getAdminName,
  isObjWithValues,
} from "../../../helper";
import { getSegmentList } from "../../../redux/actions/crmActions";
import TempleteList from "../../components/TempleteList";
import PhoneMessagePreview from "../../customers/components/PhoneMessagePreview";
import SendSms from "../../customers/components/SendSms";
import SendNotification from "../../components/SendNotification";
import { EmailTemplateList } from "../../email/EmailList";
import { useReactFlow } from "reactflow";
import { Delete } from "@mui/icons-material";

export const CampaignInfo = () => {
  const { campaignState, setCampaignState, flowObject, setFlowObject } = useContext(FlowStateContext);
  const dispatch = useDispatch();
  const customerSegments = useSelector((state) => state.chats.customerSegments);
  useEffect(() => {
    if (!isObjWithValues(customerSegments)) dispatch(getSegmentList());
    return () => {
      // setCreateCampaignState({});
    };
  }, []);
  const onEditForm = (payload) => {
    setCampaignState((pre) => {
      let { campaign_info } = pre || {};
      pre = { ...pre, campaign_info: { ...campaign_info, ...payload } };
      return pre;
    });
  };

  return (
    <Box>
      <Box sx={{ padding: "12px" }}>
        <Stack>

          <Label>Name </Label>
          <TextField
            size="small"
            fullWidth
            value={flowObject?.name}
            onChange={(e) => {
              setFlowObject(pre => ({ ...pre, name: e.target.value }))
            }}
          />
        </Stack>
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Typography
            variant="subtitle2"
            sx={{ display: "flex", alignItems: "center", gap: 2 }}
          >
            Customers
          </Typography>
          <IconButton>
            <Add />
          </IconButton>
        </Stack>

        <Stack>
          <Label>Segment</Label>
          <Autocomplete
            multiple
            id="tags-standard"
            size="small"
            options={Object.values(customerSegments || {})}
            getOptionLabel={(option) => option?.filterForm?.segment_name}
            value={campaignState?.campaign_info?.segment_id || []}
            onChange={(event, newValue) => {
              onEditForm({ segment_id: newValue });
            }}
            // renderOption={(o) => <MenuItem >{o?.filterForm?.segment_name}</MenuItem>}
            // defaultValue={[top100Films[13]]}
            renderInput={(params) => <TextField {...params} />}
          />
        </Stack>
      </Box>
    </Box>
  );
};

export const WhatsappInfo = () => {
  const [openTemplate, setOpenTemplate] = useState(false);
  const {
    campaignState,
    setCampaignState,
    selectedNode: nodeData,
    onEditForm,
  } = useContext(FlowStateContext);
  console.log(nodeData);
  let [templatePreviewObj, setTemplatePreviewObj] = useState({});
  const setTemplate = (temp, o, extra) => {
    // console.log(temp, extra);
    setCampaignState((pre) => ({
      ...pre,
      [nodeData?.id]: {
        template: temp,
        extra: {
          content:
            extra?.components?.find((o) => o.type === "BODY")?.text || "",
          heading:
            extra?.components.find((o) => o.type === "HEADER")?.text || "",
        },
        hasError: false,
      },
    }));
    setTemplatePreviewObj({
      content: extra?.components?.find((o) => o.type === "BODY")?.text || "",
      heading: extra?.components.find((o) => o.type === "HEADER")?.text || "",
    });
    // console.log(temp);
  };

  useEffect(() => {
    // console.log(campaignState);
    if (campaignState?.[nodeData?.id]?.extra) {
      setTemplatePreviewObj(campaignState?.[nodeData?.id]?.extra || false);
    }

    return () => {
      setTemplatePreviewObj({});
    };
  }, [nodeData?.id]);

  return (
    <Box sx={{ p: 3 }}>
      {openTemplate && (
        <TempleteList
          open={openTemplate}
          hideRejected={true}
          setParentTemplate={setTemplate}
          handleClose={() => setOpenTemplate(false)}
          label={"Select"}
        />
      )}

      {campaignState?.[nodeData?.id]?.template ? (
        <Stack gap={3} justifyContent={"center"}>
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
            sx={{ width: "100%" }}
          // gap={3}
          >
            <Typography variant="h6">Select Template</Typography>

            <Button onClick={() => setOpenTemplate(true)}>
              Change Template
            </Button>
          </Stack>
          <PhoneMessagePreview
            flow
            templateString={templatePreviewObj?.content}
            heading={
              <>
                <WhatsappSvg height={18} width={18} />
                {templatePreviewObj?.heading}
              </>
            }
          />
        </Stack>
      ) : (
        <>
          <EmptyState
            button={
              <Button variant="contained" onClick={() => setOpenTemplate(true)}>
                Select Template
              </Button>
            }
            text={"Select Template lorem ... "}
            icon={
              <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"center"}
                gap={2}
              >
                <WhatsappSvg height={40} width={40} />{" "}
                <DescriptionOutlined
                  color={"action"}
                  style={{ height: "40px", width: "40px" }}
                />
              </Stack>
            }
          />
        </>
      )}
    </Box>
  );
};

export const SMSInfo = () => {
  const [openTemplate, setOpenTemplate] = useState(false);
  const { campaignState, setCampaignState, selectedNode, onEditForm } =
    useContext(FlowStateContext);
  // const onEditForm = (obj) => {
  //     // console.log(temp);
  //     setCampaignState((pre) => ({
  //         ...pre,
  //         [selectedNode?.id]: {
  //             ...obj,
  //         },
  //     }));
  // };
  const getPayload = (payload) => {
    onEditForm({
      payload: { ...payload },
      key: selectedNode?.id,
    });
  };
  return (
    <Box sx={{ p: 3 }}>
      {/* <Stack sx={{ mb: 2 }}>
                <Label>Name </Label>
                <TextField
                    size="small"
                    value={campaignState?.[selectedNode?.id]?.name || ""}
                    onChange={(e) => {
                        onEditForm({
                            payload: { name: e.target.value },
                            key: selectedNode?.id,
                        });
                    }}
                />
            </Stack> */}
      {openTemplate && (
        <SendSms
          heading={`Send SMS`}
          open={openTemplate}
          handleClose={() => setOpenTemplate(false)}
          returnPayload={true}
          onEditForm={getPayload}
        />
      )}
      {campaignState?.[selectedNode?.id]?.template_id ? (
        <Stack gap={3} justifyContent={"center"}>
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
            sx={{ width: "100%" }}
          // gap={3}
          >
            <Typography variant="h6">Select Template</Typography>

            <Button onClick={() => setOpenTemplate(true)}>
              Change Template
            </Button>
          </Stack>
          <PhoneMessagePreview
            flow
            templateString={campaignState?.[selectedNode?.id]?.templateString}
            heading={
              <>
                <SmsOutlined
                  color="action"
                  style={{
                    height: "18px",
                    width: "18px",
                  }}
                />
                {`JW-${campaignState?.[selectedNode?.id]?.sender_id}`}
              </>
            }
          />
        </Stack>
      ) : (
        <>
          <EmptyState
            button={
              <Button variant="contained" onClick={() => setOpenTemplate(true)}>
                Select Template
              </Button>
            }
            text={"Select Template lorem ... "}
            icon={
              <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"center"}
                gap={2}
              >
                <SmsOutlined
                  color={"action"}
                  style={{ height: "40px", width: "40px" }}
                />{" "}
                <DescriptionOutlined
                  color={"action"}
                  style={{ height: "40px", width: "40px" }}
                />
              </Stack>
            }
          />
        </>
      )}
      {/* <Button onClick={() => setOpenTemplate(true)}>Select template</Button> */}
    </Box>
  );
};

export const PushNotificationInfo = () => {
  const [openTemplate, setOpenTemplate] = useState(false);
  const { campaignState, setCampaignState, selectedNode, onEditForm } =
    useContext(FlowStateContext);
  // const onEditForm = (obj) => {
  //     // console.log(temp);
  //     setCampaignState((pre) => ({
  //         ...pre,
  //         [selectedNode?.id]: {
  //             payload: obj,
  //         },
  //     }));
  // };
  return (
    <Box sx={{ p: 3 }}>
      {/* <Stack sx={{ mb: 2 }}>
                <Label>Name</Label>
                <TextField
                    size="small"
                    value={campaignState?.[selectedNode?.id]?.name || ""}
                    onChange={(e) => {
                        onEditForm({
                            payload: { name: e.target.value },
                            key: selectedNode?.id,
                        });
                    }}
                />
            </Stack> */}
      {openTemplate && (
        <Dialog
          fullWidth
          maxWidth="md"
          sx={dialogStyle}
          // fullScreen={fullScreen}
          open={openTemplate}
          onClose={() => setOpenTemplate(false)}
        >
          <DialogTitle
            id="responsive-dialog-title"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            Push notifications
            {/* <Button variant="contained" onClick={handleClick}>
                                        Variables
                                      </Button> */}
          </DialogTitle>
          <DialogContent>
            <SendNotification
              buttonLabel={"Create Notification"}
              returnPayload={true}
              getPayload={
                // onEditForm
                (payload) => {
                  onEditForm({
                    payload: { payload, hasError: false },
                    key: selectedNode?.id,
                  });
                  // setCreateCampaignState((pre) => ({
                  //     ...pre,
                  //     payload: payload,
                  // }));}
                }
              }
              onClose={() => setOpenTemplate(false)}
            />
          </DialogContent>
        </Dialog>
      )}
      {campaignState?.[selectedNode?.id]?.payload ? (
        <Stack gap={3} justifyContent={"center"}>
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
            sx={{ width: "100%" }}
          // gap={3}
          >
            <Typography variant="h6">Create Notification</Typography>

            <Button onClick={() => setOpenTemplate(true)}>
              Change Notification
            </Button>
          </Stack>
          <PhoneMessagePreview
            flow
            cardContent={
              <>
                <Stack direction={"row"} justifyContent="space-between">
                  <Typography>
                    {campaignState?.[selectedNode?.id]?.payload.title}
                  </Typography>
                  <Typography>now</Typography>
                </Stack>
                <Stack>
                  {/* <Typography>{campaignState?.[selectedNode?.id]?.paylo}</Typography> */}
                  <Typography>
                    {campaignState?.[selectedNode?.id]?.payload?.contents?.en}
                  </Typography>
                </Stack>
                {campaignState?.[selectedNode?.id]?.payload.chrome_web_image ? (
                  <img
                    alt="notification "
                    src={
                      campaignState?.[selectedNode?.id]?.payload
                        .chrome_web_image
                    }
                    style={{
                      width: "100%",
                      borderRadius: "7px",
                    }}
                  />
                ) : null}
              </>
            }
          />
        </Stack>
      ) : (
        <>
          <EmptyState
            button={
              <Button variant="contained" onClick={() => setOpenTemplate(true)}>
                Create Notification
              </Button>
            }
            text={"Create Notification lorem ... "}
            icon={
              <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"center"}
                gap={2}
              >
                <NotificationsActiveOutlined
                  color={"action"}
                  style={{ height: "40px", width: "40px" }}
                />{" "}
                <DescriptionOutlined
                  color={"action"}
                  style={{ height: "40px", width: "40px" }}
                />
              </Stack>
            }
          />
        </>
      )}
    </Box>
  );
};

export const EmailInfo = () => {
  const [openTemplate, setOpenTemplate] = useState(false);
  const { campaignState, selectedNode, onEditForm } =
    useContext(FlowStateContext);
  // const smtpMail = useSelector((state) => state.settings.smtpMail);
  return (
    <Box sx={{ p: 2 }}>
      {/* <Stack sx={{ mb: 2 }}>
            <Label>Name</Label>
            <TextField
                size="small"
                value={campaignState?.[selectedNode?.id]?.name || ""}
                onChange={(e) => {
                    onEditForm({
                        payload: { name: e.target.value },
                        key: selectedNode?.id,
                    });
                }}
            />
        </Stack> */}
      {openTemplate && (
        <Dialog
          fullWidth
          maxWidth="md"
          sx={dialogStyle}
          // fullScreen={fullScreen}
          open={openTemplate}
          onClose={() => setOpenTemplate(false)}
        >
          <EmailTemplateList
            setOnSelectedtemplated={(payload) => {
              onEditForm({
                payload: {
                  template: payload,
                  template_id: payload?.id,
                },
                key: selectedNode?.id,
              });
              console.log(payload);
              setOpenTemplate(false);
            }}
            isCampaign={false}
            hidePredefined={true}
          // setSelectedPage={(payload) => {
          //     console.log(payload);
          //     setOpenTemplate(false);
          // }}
          />
        </Dialog>
      )}
      {campaignState?.[selectedNode?.id]?.template ? (
        <Stack gap={3} justifyContent={"center"}>
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
            sx={{ width: "100%" }}
          // gap={3}
          >
            <Typography variant="h6">Select Template</Typography>

            <Button onClick={() => setOpenTemplate(true)}>
              Change Template
            </Button>
          </Stack>
          <PhoneMessagePreview
            flow
            templateString={`Subject : ${campaignState?.[selectedNode?.id]?.template?.subject
              }`}
            heading={
              <>
                <EmailOutlined
                  color="action"
                  style={{
                    height: "18px",
                    width: "18px",
                  }}
                />
                {getAdminName()}
              </>
            }
          />
        </Stack>
      ) : (
        <>
          <EmptyState
            button={
              <Button variant="contained" onClick={() => setOpenTemplate(true)}>
                Select Template
              </Button>
            }
            text={"Select Template lorem ... "}
            icon={
              <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"center"}
                gap={2}
              >
                <EmailOutlined
                  color={"action"}
                  style={{ height: "40px", width: "40px" }}
                />{" "}
                <DescriptionOutlined
                  color={"action"}
                  style={{ height: "40px", width: "40px" }}
                />
              </Stack>
            }
          />
        </>
      )}
    </Box>
  );
};

export const DelayInfo = () => {
  let delayIn = [
    { label: "Seconds", value: "seconds" },
    { label: "Minutes", value: "minutes" },
    { label: "Hours", value: "hours" },
    { label: "Days", value: "days" },
  ];
  const { selectedEdge, setEdges, setSelectedEdge } =
    useContext(FlowStateContext);
  // const {  } = useReactFlow();

  const [delayState, setDelayState] = useState({});
  useEffect(() => {
    setDelayState(selectedEdge?.delay || {});
    return () => { };
  }, [selectedEdge]);

  const onEdit = (payload) => {
    setDelayState((pre) => ({ ...pre, ...payload }));
  };
  const onSave = () => {
    if (delayState?.value && delayState?.label)
      setEdges((pre) =>
        pre?.map((o) =>
          o?.id === selectedEdge?.id
            ? { ...o, delay: delayState, selected: false }
            : o
        )
      );
    setSelectedEdge(false);
  };
  const onDelete = () => {
    setEdges((pre) =>
      pre?.map((o) => {
        if (o?.id === selectedEdge?.id) delete o.delay;
        o.selected = false;
        return o;
      })
    );
    setSelectedEdge(false);
  };
  return (
    <Box sx={{ m: 3 }}>
      <Grid container spacing={3} rowSpacing={3} sx={{ m: 0 }}>
        <Grid item xs={6}>
          <Label>Delay for</Label>
          <TextField
            size="small"
            type="number"
            fullWidth
            value={delayState?.value}
            onChange={(e) => {
              onEdit({ value: e?.target?.value });
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <Label>In</Label>
          <TextField
            size="small"
            select
            fullWidth
            value={delayState?.label || ""}
            onChange={(e) => {
              onEdit({ label: e?.target?.value });
            }}
          >
            {delayIn?.map((o) => (
              <MenuItem key={o?.value} value={o?.value}>
                {o?.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"end"}
        gap={2}
        sx={{ mt: 2 }}
      >
        <IconButton onClick={onDelete} color="error">
          <Delete />
        </IconButton>
        {/* <Button variant="outlined" >Delete</Button> */}
        <Button variant="contained" onClick={onSave}>
          Save
        </Button>
      </Stack>
    </Box>
  );
};
