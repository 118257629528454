import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";

import {
  Avatar as MuiAvatar,
  Badge,
  Box,
  Button,
  CircularProgress,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Popover as MuiPopover,
  Tooltip,
  Typography,
} from "@mui/material";
import { MessageSquare } from "react-feather";
import { getToken, getWebsite, isArrayWithValues } from "../../helper";
import axios from "axios";
import { Whatsapp } from "../../Svgs";

const Popover = styled(MuiPopover)`
  .MuiPaper-root {
    width: 300px;
    ${(props) => props.theme.shadows[1]};
    border: 1px solid ${(props) => props.theme.palette.divider};
  }
`;

const Indicator = styled(Badge)`
  .MuiBadge-badge {
    background: ${(props) => props.theme.header.indicator.background};
    color: ${(props) => props.theme.palette.common.white};
  }
`;

const Avatar = styled(MuiAvatar)`
  background: ${(props) => props.theme.palette.primary.main};
`;

const MessageHeader = styled(Box)`
  text-align: center;
  border-bottom: 1px solid ${(props) => props.theme.palette.divider};
`;

function Message({
  title,
  description,
  customer_id,
  image,
  icon,
  status,
  handleClose,
}) {
  const navigate = useNavigate();
  const onClick = () => {
    handleClose();
    navigate(`/customers/${customer_id}#chat`);
  };
  return (
    <ListItem
      divider
      onClick={onClick}
      sx={{ cursor: "pointer" }}
      // component={Link}
      // to="#"
      disabled={status === 0 ? false : true}
    >
      {icon}
      {/* <ListItemAvatar>
        <Avatar src={image} alt="Avatar" />
      </ListItemAvatar> */}
      <ListItemText
        primary={title}
        primaryTypographyProps={{
          variant: "subtitle2",
          color: "textPrimary",
        }}
        secondary={description}
        secondaryTypographyProps={{ fontSize: "11px" }}
      />
    </ListItem>
  );
}

function NavbarMessagesDropdown(props) {
  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [unreadMessages, setUnreadMessages] = useState(0);
  const navigate = useNavigate();
  const handleOpen = () => {
    // setOpen(true);
    navigate("/chats");
  };

  const handleClose = () => {
    setOpen(false);
  };
  useEffect(async () => {
    if (isArrayWithValues(props.notifications)) {
      // let array = await getNotifications(setLoading);
      // console.log(array);
      // array.length = 10;
      // console.log(array);
      let array = [...props.notifications];
      let unreadMessages = 0;
      let messagesArray = [];
      if (isArrayWithValues(array)) {
        for (let i = array.length - 1; i >= 0; i--) {
          if (array[i].type === "whatsapp_msg_notify") {
            messagesArray.push(array[i]);
            if (array[i].status === 0) unreadMessages += 1;
          }
        }
        setMessages(messagesArray);
        setUnreadMessages(unreadMessages);
        // setMessages(array.filter((i) => i.type === "whatsapp_msg_notify"));
      }
    }
  }, [props.notifications]);
  return (
    <React.Fragment>
      <Tooltip title="Messages">
        <IconButton color="inherit" ref={ref} onClick={handleOpen} size="large">
          <Indicator badgeContent={0}>
            <MessageSquare />
          </Indicator>
        </IconButton>
      </Tooltip>
      <Popover
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
      >
        <MessageHeader p={2}>
          <Typography variant="subtitle1" color="textPrimary">
            {loading ? "New Messages" : "3 New Messages"}
          </Typography>
        </MessageHeader>
        <React.Fragment>
          <List disablePadding sx={{ maxHeight: "300px" }}>
            {loading && !isArrayWithValues(messages) ? (
              <Box sx={{ textAlign: "center", mt: 8, mb: 8 }}>
                <CircularProgress size="20px" />
              </Box>
            ) : null}
            {isArrayWithValues(messages) &&
              messages.map((message) => (
                <Message
                  key={message.time}
                  title={message.title}
                  description={message.message}
                  customer_id={message.id}
                  handleClose={handleClose}
                  status={message.status}
                  icon={
                    <Box
                      sx={{
                        width: "50px",
                        justifyContent: "center",
                        // textAlign: "center",
                      }}
                    >
                      <Whatsapp style={{ margin: "auto" }} />
                    </Box>
                  }
                  // image="/static/img/avatars/avatar-2.jpg"
                />
              ))}
            {/* <Message
              title="Lucy Lavender"
              description="Nam pretium turpis et arcu. Duis arcu tortor."
              image="/static/img/avatars/avatar-1.jpg"
            />
            <Message
              title="Remy Sharp"
              description="Curabitur ligula sapien euismod vitae."
              image="/static/img/avatars/avatar-2.jpg"
            />
            <Message
              title="Cassandra Mixon"
              description="Pellentesque auctor neque nec urna."
              image="/static/img/avatars/avatar-3.jpg"
            /> */}
          </List>
          {/* {!loading ? (
            <Box p={1} display="flex" justifyContent="center">
              <Button size="small" component={Link} to="#">
                Show all messages
              </Button>
            </Box>
          ) : null} */}
        </React.Fragment>
      </Popover>
    </React.Fragment>
  );
}

export default NavbarMessagesDropdown;

const getNotifications = async (setLoading) => {
  let token = getToken();
  let website = getWebsite();
  setLoading(true);
  let headersList = {
    Accept: "*/*",
    Authorization: `Basic ${token}`,
  };

  let res = await fetch(`${website}/wp-json/store/v1/notifications`, {
    method: "GET",
    headers: headersList,
  })
    .then(function (response) {
      return response.text();
    })
    .then(function (data) {
      return data;
    });
  setLoading(false);
  if (Array.isArray(JSON.parse(res))) return JSON.parse(res);
  // if (res.data) return res.data;

  // console.log(token);
  // let res = await axios({
  //   method: "GET",
  //   url: `${website}/wp-json/store/v1/notifications`,
  //   headers: {
  //     Authentication: `Basic ${token}`,
  //     // Accept: "*/*",
  //   },
  // });
  // setLoading(false);
  // if (res.data) return res.data;
};
