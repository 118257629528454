import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Card,
  CardContent,
  Typography,
  CardMedia,
  Button,
  Tabs,
} from "@mui/material";
import PropTypes from "prop-types";
import { galleryFieldStyles } from "../utils/chatbotFieldsStyles";
import * as actions from "../../../../../../redux/actions/flowsActions";
import "../public/css/tabs-border.css";
import { format } from "date-fns";
import { backendApiCall } from "../../../../../../helper";

const styles = galleryFieldStyles;

const BotImageMessage = ({ message, data }) => {
  const dispatch = useDispatch();
  const [value, setValue] = useState(0);
  const prevNode = useSelector((state) => state.flow?.chatbotMessageResponse);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const sendTime = (currTime) => {
    const formattedTime = format(currTime, "HH:mm");
    return formattedTime;
  };

  const normalizeURL = (url) => {
    if (url.startsWith("http://") || url.startsWith("https://")) {
      return url;
    } else {
      if (!url.startsWith("www.")) {
        return "http://www." + url;
      } else {
        return "http://" + url;
      }
    }
  };

  const handleClick = async (button) => {
    if (button.type === "send_message") {
      const newMessage = {
        id: Date.now(),
        text: button.title,
        isUser: true,
        time: sendTime(Date.now()),
      };
      dispatch({ type: actions.TEST_BOT_ADD_MESSAGES, payload: newMessage });

      const obj = {
        flow_data: data,
        user_response: button?.title,
        prev_node: prevNode,
      };

      await backendApiCall({
        endpoint: "api/test-chatbot",
        method: "POST",
        data: obj,
      });
    }

    if (button.type === "phone_number") {
      const newMessage = {
        id: Date.now(),
        text: `tel: ${button.number}`,
        isUser: true,
        time: sendTime(Date.now()),
      };
      dispatch({ type: actions.TEST_BOT_ADD_MESSAGES, payload: newMessage });

      const obj = {
        flow_data: data,
        user_response: button?.title,
        prev_node: prevNode,
      };

      await backendApiCall({
        endpoint: "api/test-chatbot",
        method: "POST",
        data: obj,
      });
    }

    if (button.type === "open_url") {
      const url = normalizeURL(button.url);
      window.open(url, "_blank");
    }

    if (button.type === "go-to-block") {
      const url = normalizeURL(button.url);
      window.open(url, "_blank");
    }
  };

  return (
    <div key={message.id} style={{ marginBottom: "10px", display: "flex" }}>
      <Tabs
        style={styles.tabStyles}
        className="no-border-tabs"
        value={value}
        indicatorColor={""}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="auto"
        aria-label="scrollable force tabs example"
      >
        {message.cards.map((card) => (
          <div key={card.id} style={styles.mainDiv}>
            <Card style={styles.divCard}>
              <CardContent style={styles.divCardContent}>
                <CardMedia
                  style={styles.cardMedia}
                  component="img"
                  sx={{ height: 150 }}
                  image={card.file}
                  alt="Selected Image"
                />
              </CardContent>
              <CardContent style={styles.galleryBottom}>
                <div style={styles.textFieldDiv}>
                  <Typography variant="h6" style={styles.galleryTextField}>
                    {card.title}
                  </Typography>
                  <div style={styles.galleryTextField}>{card.description}</div>
                </div>
                {card.buttons.map((button) => (
                  <Button
                    style={styles.button}
                    key={button.id}
                    variant="outlined"
                    onClick={() => handleClick(button)}
                  >
                    {button.title}
                  </Button>
                ))}
              </CardContent>
            </Card>
          </div>
        ))}
      </Tabs>
    </div>
  );
};

BotImageMessage.propTypes = {
  message: PropTypes.object.isRequired,
};

export default BotImageMessage;
