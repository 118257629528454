import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  isArrayWithValues,
  isObjWithValues,
  primaryLabel,
  primaryLabelSmall,
  secondaryLabelSmall,
  validateNumber,
} from "../../../helper";
import {
  Box,
  Button,
  ButtonGroup,
  FormControlLabel,
  IconButton,
  MenuItem,
  Radio,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Add, Remove } from "@mui/icons-material";
import { Cancel } from "@material-ui/icons";
import { purities } from "../../settings/MasterSettings";
import { LoadingButton } from "@mui/lab";

const ProductCustomization = ({
  editCustomizeForm,
  customizeForm,
  addCustomizationInQuotation,
  removeCustomizationFromQuotation,
  onCustomizationClick,
  product,
  quotation,
  updateQuotation,
  addingCustomization,
}) => {
  const allowed_customization = useSelector(
    (state) => state.settings.allowed_customization
  );
  const [metalPurities, setMetalPurities] = useState([]);
  const [metalColors, setMetalColors] = useState([]);
  const [allCustomizations, setAllCustomizations] = useState([]);

  useEffect(() => {
    if (isArrayWithValues(quotation?.customization?.[product?.id]))
      setAllCustomizations(quotation?.customization?.[product?.id]);
    else setAllCustomizations([]);
  }, [quotation?.customization, quotation?.customization?.[product?.id]]);

  useEffect(() => {
    if (!isObjWithValues(allowed_customization)) return;
    let { metalPurities, metalColors } = allowed_customization;
    setMetalPurities(metalPurities);
    setMetalColors(metalColors);
  }, [allowed_customization]);

  return (
    <Box sx={{ mt: 3 }}>
      <TableContainer sx={{ border: 0.5, borderRadius: "6px", mb: 3 }}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell
                sx={{ ...secondaryLabelSmall, pb: 1, pt: 1 }}
                align={"left"}
              ></TableCell>
              <TableCell
                sx={{ ...secondaryLabelSmall, pb: 1, pt: 1 }}
                align={"left"}
              >
                Metal
              </TableCell>
              <TableCell
                sx={{
                  ...secondaryLabelSmall,
                  pb: 1,
                  pt: 1,
                  whiteSpace: "nowrap",
                }}
                align="right"
              >
                Color
              </TableCell>
              <TableCell
                sx={{
                  ...secondaryLabelSmall,
                  pb: 1,
                  whiteSpace: "nowrap",
                  pt: 1,
                }}
                align="right"
              >
                Quantity
              </TableCell>
              <TableCell
                sx={{ ...secondaryLabelSmall, pb: 1, pt: 1 }}
                align="right"
              >
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody sx={{ rowGap: "4px", mt: 2 }}>
            {allCustomizations.map((row) => (
              <>
                <TableRow
                  // key={row.title}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    "& td": { border: 0, py: 2 },
                    "& th": { border: 0 },
                    ...primaryLabel,
                    cursor: "pointer",
                  }}
                  onClick={() => onCustomizationClick(product.id, row.id)}
                >
                  <TableCell
                    sx={{
                      ...primaryLabel,
                    }}
                    // scope="row"
                  >
                    <FormControlLabel
                      value={row.id}
                      control={
                        <Radio
                          checked={row.id === product?.selectedCustomization}
                        />
                      }
                      label=""
                    />
                  </TableCell>
                  <TableCell
                    sx={{
                      ...primaryLabel,
                    }}
                    scope="row"
                  >
                    {getPurityLabel(row.metal_purity) || ""}
                  </TableCell>
                  <TableCell
                    sx={{
                      ...primaryLabel,
                    }}
                    align="right"
                  >
                    {row.metal_color || ""}
                  </TableCell>
                  <TableCell
                    sx={{
                      ...primaryLabel,
                      whiteSpace: "nowrap",
                    }}
                    align="right"
                  >
                    {row?.quantity || ""}
                  </TableCell>
                  <TableCell
                    sx={{
                      ...primaryLabel,
                      whiteSpace: "nowrap",
                    }}
                    align="right"
                  >
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        removeCustomizationFromQuotation(row.id);
                      }}
                      size="small"
                      disabled={allCustomizations?.length === 1}
                    >
                      <Cancel />
                    </IconButton>
                  </TableCell>
                </TableRow>
              </>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Stack direction={"row"} alignItems={"center"} spacing={3}>
        <Box>
          <Typography sx={{ ...primaryLabelSmall }}>Metal Purity</Typography>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={customizeForm.metal_purity}
            onChange={(e) =>
              editCustomizeForm({ metal_purity: e.target.value })
            }
            size="small"
            sx={{ mt: 1 }}
          >
            {metalPurities?.map((i) => (
              <MenuItem value={i.value}>{i.label}</MenuItem>
            ))}
          </Select>
        </Box>

        <Box>
          <Typography sx={{ ...primaryLabelSmall }}>Color</Typography>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={customizeForm.metal_color}
            onChange={(e) => editCustomizeForm({ metal_color: e.target.value })}
            size="small"
            sx={{ mt: 1 }}
          >
            {metalColors?.map((i) => (
              <MenuItem value={i.value}>{i.label}</MenuItem>
            ))}
          </Select>
        </Box>
        <Box>
          <Typography sx={{ ...primaryLabelSmall }}>Quantity</Typography>
          <ButtonGroup
            size="medium"
            sx={{ mt: 1 }}
            fullWidth
            aria-label="small button group"
          >
            <Button
              sx={{
                maxWidth: "22px",
                border: "1px solid #8c8c8c",
                color: "#8c8c8c",
                height: "32px",
              }}
              onClick={() =>
                editCustomizeForm({
                  quantity: validateNumber(customizeForm?.quantity) - 1,
                })
              }
              disabled={customizeForm?.quantity <= 1}
            >
              <Remove />
            </Button>
            <Button
              sx={{
                height: "32px",
              }}
              disabled
              fullWidth
            >
              {customizeForm?.quantity}
            </Button>
            <Button
              sx={{
                maxWidth: "22px",
                border: "1px solid #8c8c8c",
                color: "#8c8c8c",
                height: "32px",
              }}
              onClick={() =>
                editCustomizeForm({
                  quantity: validateNumber(customizeForm?.quantity) + 1,
                })
              }
              disabled={customizeForm?.quantity >= 20}
            >
              <Add />
            </Button>
          </ButtonGroup>
        </Box>
      </Stack>

      <Stack direction="row" justifyContent="flex-end" mt={2}>
        <LoadingButton
          onClick={() => addCustomizationInQuotation()}
          variant="outlined"
          size="small"
          startIcon={<Add />}
          disabled={
            !customizeForm?.metal_purity ||
            !customizeForm?.metal_color ||
            !customizeForm?.quantity
          }
          loading={addingCustomization}
        >
          Add a variant
        </LoadingButton>
      </Stack>
    </Box>
  );
};

export default ProductCustomization;

const getPurityLabel = (purity) => {
  let obj = purities?.find((i) => i.value == purity);
  if (obj) return obj.label;
  return purity;
};
