import { ArrowForwardIos } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  Chip,
  CircularProgress,
  Collapse,
  IconButton,
  Stack,
  TableCell,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";
import Loader from "../../components/Loader";
import {
  formatServerValue,
  isArrayWithValues,
  isObjWithValues,
  ResponsiveStackRow,
} from "../../helper";
import CustomChip from "./CustomChip";

export { StackRow, FullSizeDialogTitle, FullScreenLoader };

const StackRow = (props) => {
  return (
    <Stack
      direction={"row"}
      gap={"12px"}
      justifyContent="flex-end"
      alignItems={"center"}
      sx={{ mt: 3 }}
      {...props}
    >
      {props?.children}
    </Stack>
  );
};

const FullSizeDialogTitle = (props) => {
  return (
    <Stack
      direction={"row"}
      justifyContent="space-between"
      alignItems={"center"}
      sx={{
        padding: "16px 24px",
        paddingBottom: 0,
        fontSize: "1rem",
        fontWeight: "500",
        flex: "0 0 auto",
      }}
      {...props}
    >
      {props.children}
    </Stack>
  );
};

const FullScreenLoader = () => {
  const theme = useTheme();
  const isIpad = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <>
      {isIpad ? (
        <Stack
          sx={
            isIpad
              ? {
                  position: "absolute",
                  zIndex: "15",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                }
              : {}
          }
        >
          <CircularProgress color="primary" disableShrink />
        </Stack>
      ) : (
        <Loader style={{ height: "70vh" }} />
      )}
    </>
  );
};

export const ButtonTab = ({ value, selectedTab, ...otherprops }) => {
  return (
    <Button
      {...otherprops}
      variant={"text"}
      sx={{
        backgroundColor: selectedTab === value ? "primary.main" : "transparent",
        color: selectedTab === value ? "#fff" : "#000",

        "&:hover": {
          backgroundColor:
            selectedTab === value ? "primary.main" : "transparent",
        },
      }}
      // style={{ minWidth: "auto" }}
    >
      {otherprops.children}
    </Button>
  );
};

export const ResponsiveTabaleRow = ({
  title,
  titleProps,
  subTitle,
  subTitle2,
  src,
  rowSecondary,
  chipLabel,
  chipProps,
  accordionObject,
  avatarProps,
  menu,
  right,
  rightProps,
  onClick,
}) => {
  const theme = useTheme();
  const [showQuickEdit, setShowQuickEdit] = useState(false);
  const isIpad = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <TableRow onClick={() => onClick && onClick()}>
      <TableCell style={{ width: "100%" }}>
        <Stack
          direction={"row"}
          //   alignItems="flex-start"
          justifyContent={"space-between"}
          flex={1}
        >
          <Stack
            direction={"row"}
            alignItems="center"
            gap={1}
            style={{ width: "100%" }}
          >
            <Stack direction={"row"}>
              {/* {isArrayWithValues(accordionObject) && (
                <Stack alignItems={"center"} justifyContent={"center"}>
                  <IconButton>
                    {" "}
                    <IconButton
                      size="small"
                      style={{ padding: 0 }}
                      onClick={(e) => {
                        e.stopPropagation();
                        setShowQuickEdit((pre) => !pre);
                      }}
                    >
                      <ArrowForwardIos
                        style={{
                          transform: showQuickEdit
                            ? "rotate(90deg)"
                            : "rotate(270deg)",
                          transition: "transform 300ms ease",
                          padding: 0,
                        }}
                      />
                    </IconButton>
                  </IconButton>
                </Stack>
              )} */}
              {src && (
                <Avatar
                  src={src}
                  style={{ width: "60px", height: "60px" }}
                  {...avatarProps}
                />
              )}
            </Stack>

            <Stack style={{ width: "100%" }}>
              <StackRow
                sx={{
                  justifyContent: "space-between",
                  flex: 1,
                  width: "100%",
                }}
              >
                <Stack direction={"row"} alignItems="center" gap={2}>
                  <Typography variant="h6" {...titleProps}>
                    {title}
                  </Typography>{" "}
                  {chipLabel && <CustomChip label={chipLabel} {...chipProps} />}
                </Stack>
                <Box
                  style={{ alignSelf: "flex-end", marginLeft: "auto" }}
                  {...rightProps}
                >
                  {menu || right}
                </Box>
              </StackRow>
              <Typography variant="subtitle1">{subTitle}</Typography>
              <Typography variant="subtitle1">{subTitle2}</Typography>
            </Stack>
          </Stack>
        </Stack>
        <Collapse in={showQuickEdit}>
          <Stack rowGap={1} sx={{ borderBottom: "divider" }}>
            {accordionObject?.map((obj) => {
              return (
                <Stack
                  direction={"row"}
                  alignItems="center"
                  justifyContent={"space-between"}
                >
                  {" "}
                  <Typography variant="subtitle2">{obj?.key}</Typography>{" "}
                  <Typography variant="subtitle1">{obj?.value}</Typography>
                </Stack>
              );
            })}
          </Stack>
        </Collapse>
      </TableCell>
    </TableRow>
  );
};
