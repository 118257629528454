export const botTextFieldStyles = {
  mainContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: "20px",
    width: "470px",
  },
  textFieldBox: {
    width: "100%",
    borderRadius: "10px",
    marginLeft: "10px",
    marginRight: "25px",
    color: "rgb(89, 104, 123)",
  },
  textField: {
    textAlign: "center",
    alignItems: "center",
    border: "none",
    borderRadius: "10px",
    // paddingRight: "20px",
    "& fieldset": { border: "none" },
    color: "rgb(89, 104, 123)",
    backgroundColor: "white",
  },
  iconButton: {
    height: "30px",
    width: "30px",
    backgroundColor: "#fff",
    color: "#647283",
    border: "0.3px solid #dcdcdc",
    marginRight: "6px",
    "&:hover": {
      color: "#fff",
      backgroundColor: "#008aff"
    }
  },
  icon: {
    fontSize: "19px",
    fontWeight: 600,
  }
};
