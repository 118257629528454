import { useTheme } from "@mui/system";
import Switch from "rc-switch";
import "rc-switch/assets/index.css";

const SmallSwitch = (props) => {
  let { onChange, checked, usePrimaryColor } = props;

  const theme = useTheme();
  let backgroundColor = theme.palette.primary && theme.palette.primary.main;
  return (
    <>
      <style>
        {`.small-switch::after {
                    content: '';
                  height: 12px;
                  width: 12px;
               
}`}
        {`.small-switch-checked::after {
   height: 12px;
 width: 12px;
    left: 14px;
}`}
      </style>
      <Switch
        {...props}
        onChange={onChange}
        checked={checked}
        className={
          checked ? "small-switch-checked small-switch" : "small-switch"
        }
        style={
          checked
            ? {
                ...(usePrimaryColor
                  ? {
                      border: `1px solid ${backgroundColor}`,
                      backgroundColor,
                    }
                  : {}),
                width: "30px",
                height: "16px",
              }
            : {
                width: "30px",
                height: "16px",
                backgroundColor: usePrimaryColor
                  ? "rgb(232, 232, 232)"
                  : "#F70000",
              }
        }
      />
    </>
  );
};

export default SmallSwitch;
