import { Box, Checkbox, FormHelperText, IconButton, InputAdornment, MenuItem, Select, Stack, TextField, Typography, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import { useState } from "react";
import axios from "axios";
import { getToken, getWebsite, isObjWithValues, } from "../../../../helper";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import Switch from 'rc-switch'
import "rc-switch/assets/index.css";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { EDIT_SETTINGS } from "../../../../redux/actions/settingActions";
import { FormLabel } from "../../Integrations";
import { setGlobalToast } from "../../../../redux/actions/helperActions";
const RazorpayForm = () => {

    const [formData, setFormData] = useState({
        enabled: "no",
        title: "",
        description: "",
        key_id: "",
        key_secret: "",
        payment_action: "",
        order_success_message: "",
        enable_webhook: "",
    });
    const dispatch = useDispatch()
    const [visibility, setVisibility] = useState({
        key_id: false,
        key_secret: false
    })
    const [loading, setLoading] = useState(false)
    const globalToast = useSelector((state) => state.helper.globalToast);
    const woocommerceRazorpaySettings = useSelector((state) => state.settings.woocommerceRazorpaySettings);
    const theme = useTheme();
    let backgroundColor = theme.palette.primary && theme.palette.primary.main;
    const isPhone = useMediaQuery(theme.breakpoints.down("sm"));
    console.log(woocommerceRazorpaySettings);
    useEffect(() => {
        if (isObjWithValues(woocommerceRazorpaySettings)) setFormData({ ...woocommerceRazorpaySettings })
    }, [woocommerceRazorpaySettings])

    const onFormEdit = (payload) => {
        setFormData((pre) => ({ ...pre, ...payload }));
    };
    const onUpdateForm = async () => {
        let website = getWebsite();
        let token = getToken();
        if (!website && !token) return false
        setLoading(true)
        let res = await axios(
            {
                url: `${website}/wp-json/store/v1/settings/woocommerce_razorpay_settings`,
                method: "PUT",
                headers: {
                    Authorization: `Basic ${token}`
                },
                data: {
                    "setting": "woocommerce_razorpay_settings",
                    data: formData
                }
            }
        )
        if (res.data.success) {
            dispatch(setGlobalToast({ ...globalToast, show: true, message: "Razorpay setting saved successfully", severity: "error" }))
            dispatch({
                type: EDIT_SETTINGS,
                payload: {
                    woocommerceRazorpaySettings: res.data.data,
                },
            });
        } else {
            dispatch(setGlobalToast({ ...globalToast, show: true, message: "Razorpay setting saved successfully", severity: "error" }))
        }
        setLoading(false)
    }
    return (
        <Box sx={{ paddingLeft: "12px", paddingBottom: "25px" }}>

            <Stack direction={"row"} alignItems="center" sx={{ gap: "14px", mb: 2 }} justifyContent="space-between">
                <Stack direction={"row"} alignItems="center" sx={{ gap: "14px" }}>
                    <FormLabel>Enable /Disabled</FormLabel>
                    <Switch
                        onChange={(e) => onFormEdit({ enabled: e ? "yes" : "no" })}
                        checked={formData.enabled === "yes"}

                        style={
                            formData.enabled === "yes"
                                ? {
                                    border: `1px solid ${backgroundColor}`,
                                    backgroundColor,
                                } : {}
                        }
                    />
                </Stack>
                <LoadingButton loading={loading} variant={"contained"} onClick={onUpdateForm} sx={{ mr: 4 }}>Update</LoadingButton>
            </Stack>
            <Stack spacing={7} sx={{ width: isPhone ? "100%" : "70%" }}>
                <Stack>
                    <FormLabel >Title</FormLabel>
                    <TextField
                        value={formData.title}
                        onChange={(e) => onFormEdit({ title: e.target.value })}
                        size="small"
                        placeholder="Title"
                        helperText=""
                    />
                    <FormHelperText>This controls the title which the user sees during checkout</FormHelperText>
                </Stack>
                <Stack>
                    <FormLabel >Description</FormLabel>
                    <TextField
                        value={formData.description}
                        onChange={(e) => onFormEdit({ description: e.target.value })}
                        size="small"
                        placeholder="Description"
                        // helperText=""
                        multiline
                        rows={3}
                    />
                    <FormHelperText>This controls the description which the user sees during checkout</FormHelperText>
                </Stack>
                <Stack>
                    <FormLabel >Key ID</FormLabel>
                    <TextField
                        type={visibility.key_id ? 'text' : 'password'}
                        value={formData.key_id}
                        onChange={(e) => onFormEdit({ key_id: e.target.value })}
                        size="small"
                        placeholder="Key ID"
                        helperText=""
                        InputProps={{
                            endAdornment: <InputAdornment position="end">
                                <IconButton
                                    // aria-label="toggle password visibility"
                                    onClick={() => setVisibility(pre => ({ ...pre, key_id: !visibility.key_id }))}
                                    // onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {visibility.key_id ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>,
                        }}

                    />
                    <FormHelperText>The key Id and key screct can be genrated from API Keys sections of Razorpay Dashboard. Use test or live for test or live mode</FormHelperText>
                </Stack>
                <Stack>
                    <FormLabel >Key Secret</FormLabel>
                    <TextField
                        type={visibility.key_secret ? 'text' : 'password'}
                        value={formData.key_secret}
                        onChange={(e) => onFormEdit({ key_secret: e.target.value })}
                        size="small"
                        placeholder="Key Secret"
                        helperText=""
                        InputProps={{
                            endAdornment: <InputAdornment position="end">
                                <IconButton
                                    // aria-label="toggle password visibility"
                                    onClick={() => setVisibility(pre => ({ ...pre, key_secret: !visibility.key_secret }))}
                                    // onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {visibility.key_secret ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>,
                        }}
                    />
                    <FormHelperText>The key Id and key screct can be genrated from API Keys sections of Razorpay Dashboard. Use test or live for test or live mode</FormHelperText>
                </Stack>
                <Stack>
                    <FormLabel >Payment Action</FormLabel>
                    <Select value={formData.payment_action} size='small' onChange={(e) => onFormEdit({ payment_action: e.target.value })}>
                        <MenuItem value="authorize">Authorize</MenuItem>
                        <MenuItem value="capture">Authorize and Capture</MenuItem>
                    </Select>
                </Stack>
                <Stack>
                    <FormLabel >Order Completion Message</FormLabel>
                    <TextField
                        value={formData.order_success_message}
                        onChange={(e) =>
                            onFormEdit({ order_success_message: e.target.value })
                        }
                        size="small"
                        placeholder="Key Secret"
                        helperText=""
                    />
                    <FormHelperText>Message display after a successful order.</FormHelperText>
                </Stack>
            </Stack>
        </Box>
    );
};

export default RazorpayForm;
