import {
    Box,
    Button,
    CircularProgress,
    Divider,
    InputAdornment,
    Stack,
    Tab,
    Tabs,
    TextField,
    Typography,
} from "@mui/material";
// import Switch from 'rc-switch'
import "rc-switch/assets/index.css";
import axios from "axios";
import React, { useEffect } from "react";
import { useState } from "react";
import {
    formatDate,
    getToken,
    getWebsite,
    isArrayWithValues,
    isObjWithValues,
    TabPanel,
} from "../../../../helper";
import { setGlobalToast } from "../../../../redux/actions/helperActions";
import { useDispatch, useSelector } from "react-redux";
import { EDIT_SETTINGS } from "../../../../redux/actions/settingActions";
import { LoadingButton } from "@mui/lab";
import { Lock } from "@mui/icons-material";

const Mirrar = () => {
    const [selectedTab, setSelectedTab] = useState("integration");
    const [keyInput, setKeyInput] = useState("");
    const [loading, setLoading] = useState(false);
    const [isIntegreated, setIsIntegreated] = useState(false);
    const [integratedDate, setIntegratedDate] = useState(false);
    // const [isCamweraIntegrated, setIsCamweraIntegrated] = useState(false);
    const globalToast = useSelector((state) => state.helper.globalToast);
    const dispatch = useDispatch();

    const virtualTryIntegrated = useSelector(
        (state) => state.helper.virtualTryIntegrated
    );

    const mirrARIntegration = useSelector(
        (state) => state.settings.mirrARIntegration
    );
    console.log(mirrARIntegration);
    useEffect(() => {
        console.log(mirrARIntegration);
        if (isObjWithValues(mirrARIntegration)) {
            if (mirrARIntegration?.key) {
                setKeyInput(mirrARIntegration?.key || "");
                setIsIntegreated(true);
                setIntegratedDate(mirrARIntegration?.time || "");
            } else {
                setKeyInput("");
                setIsIntegreated(false);
                setIntegratedDate(false);
            }
        }
    }, [mirrARIntegration]);

    const verifykey = async () => {
        let website = getWebsite();
        let token = getToken();
        if (!website && !token) return false;
        setLoading(true);

        let time = Date.now();
        let IntegartionData = await axios({
            url: `${website}/wp-json/store/v1/settings/mirrAR_integration`,
            headers: {
                Authorization: `Basic ${token}`,
            },
            data: {
                type: "mirrAR_integration",
                data: {
                    time,
                    key: keyInput,
                },
            },
            method: "PUT",
        });
        if (IntegartionData?.data?.success) {
            dispatch(
                setGlobalToast({
                    ...globalToast,
                    show: true,
                    message: "Vendor Authorised succesfully",
                })
            );
            dispatch({
                type: EDIT_SETTINGS,
                payload: {
                    mirrARIntegration: IntegartionData?.data?.data,
                },
            });
        }

        // dispatch({
        //     type: EDIT_SETTINGS,
        //     payload: {
        //         trybeforeyoubuyIntegration: {
        //             time,
        //             key: keyInput,
        //         },
        //     },
        // });

        // console.log(res.data);
        setLoading(false);
    };
    const revokeKey = async () => {
        let website = getWebsite();
        let token = getToken();
        if (!website && !token) return false;
        setLoading(true);
        let IntegartionData = await axios({
            url: `${website}/wp-json/store/v1/settings/mirrAR_integration`,
            headers: {
                Authorization: `Basic ${token}`,
            },
            data: {
                type: "mirrAR_integration",
                data: {
                    time: "",
                    key: "",
                },
            },
            method: "PUT",
        });
        console.log(IntegartionData);
        if (IntegartionData.data.success)
            dispatch({
                type: EDIT_SETTINGS,
                payload: {
                    trybeforeyoubuyIntegration: IntegartionData.data.data,
                },
            });
        setLoading(false);
    };
    return (
        <Box sx={{ height: "100%", paddingRight: "15px" }}>
            <Stack
                sx={{
                    position: "sticky",
                    top: "0",
                    backgroundColor: "#fff",
                    zIndex: 13,
                    margin: 0,
                    padding: "12px",
                }}
            >
                <Stack
                    direction="row"
                    justifyContent={"space-between"}
                    alignItems="center"
                >
                    <Typography
                        variant="h3"
                        sx={{ display: "flex", alignItems: "center", gap: "10px" }}
                    >
                        {" "}
                        <img
                            src="/static/img/brands/Logo_Mirrar_Normal.png"
                            width="auto"
                            height="24px"
                        // sx={{}}
                        />{" "}
                        Mirrar
                    </Typography>
                    {/* {fetchingMetaIntegration && <Stack direction={"row"} alignItems="center" sx={{ gap: "10px" }}>
                        <CircularProgress size={"15px"} /> <Typography>Fetching Integrations...</Typography>
                    </Stack>} */}
                </Stack>
                {/* <Divider sx={{ mt: 4 }} /> */}
                <Tabs
                    onChange={(e, newValue) => setSelectedTab(newValue)}
                    value={selectedTab}
                    sx={{ borderBottom: 1, borderColor: "divider" }}
                >
                    {/* <Tab label="Overview" value="overview" /> */}
                    {/* <Tab label="How to do?" value="how_to_do" />
                    <Tab label="Details" value="details" /> */}
                    <Tab label="Integration" value="integration" />
                    {/* <Tab label="Settings" value="settings" /> */}
                </Tabs>
            </Stack>
            <TabPanel value={selectedTab} index={"overview"}>
                {/* <Typography><Overview /></Typography> */}
            </TabPanel>
            <TabPanel value={selectedTab} index={"how_to_do"}>
                <Typography>How to do</Typography>
            </TabPanel>
            <TabPanel value={selectedTab} index={"details"}>
                <Typography>Details</Typography>
            </TabPanel>
            <TabPanel value={selectedTab} index={"integration"}>
                <Box sx={{ padding: "12px 18px" }}>
                    <Stack direction={"row"} alignItems={"center"} sx={{ gap: "30px" }}>
                        <Typography
                            variant="h6"
                        // fontSize={"13px"}
                        // mt={3}
                        >
                            Brand Key
                        </Typography>
                    </Stack>
                    <Stack
                        direction={"row"}
                        alignItems="center"
                        sx={{ gap: "20px", mt: 2 }}
                    >
                        <TextField
                            value={keyInput}
                            onChange={(e) => setKeyInput(e.target.value)}
                            // variant="outlined"
                            size="small"
                            sx={{ width: "350px" }}
                            // fullWidth
                            // sx={{ mt: 2 }}
                            disabled={isIntegreated}
                            InputProps={
                                isIntegreated
                                    ? {
                                        endAdornment: (
                                            <InputAdornment position="start">
                                                <Lock />
                                            </InputAdornment>
                                        ),
                                    }
                                    : null
                            }
                        />
                        {!isIntegreated ? (
                            <LoadingButton
                                size="small"
                                loading={loading}
                                disabled={virtualTryIntegrated}
                                variant="contained"
                                onClick={verifykey}
                            >
                                Integrate
                            </LoadingButton>
                        ) : null}
                        {isIntegreated ? (
                            <LoadingButton
                                size="small"
                                color="error"
                                loading={loading}
                                variant="contained"
                                onClick={revokeKey}
                            >
                                Revoke
                            </LoadingButton>
                        ) : null}
                    </Stack>

                    {integratedDate ? (
                        <Typography sx={{ mt: 2 }}>
                            {" "}
                            Integrated on{" "}
                            {formatDate(new Date(integratedDate), "relative_date")}
                        </Typography>
                    ) : null}
                </Box>
            </TabPanel>
        </Box>
    );
};

export default Mirrar;
