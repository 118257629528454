import React from "react";
import { currencySymbols } from "../../pricing/Gold";
import { Box, Divider, Stack, Typography } from "@mui/material";
import { SummaryLabelValue, SummaryTitle } from "../AddProduct";
import { validateNumber } from "../../../helper";

function ProductPriceBreakup({
  onMouseLeave,
  product,
  priceBreakup,
  defaultCurrency,
  currentTax,
  getTotalWeight,
  getTotalPieces,
  getExtraCharges,
  productTotalPrice,
}) {
  let {
    gold_gross,
    silver_gross,
    platinium_gross,
    metal_color,
    colorstone_details,
    diamond,
    colorstone_from,
    diamond_from,
  } = product;
  let labour = validateNumber(priceBreakup?.[product?.labourType]?.labour);
  let discount = priceBreakup?.discount || {};
  let { gemstone, metal, total } = priceBreakup?.discount || {};
  let totalDiscount = validateNumber(total);
  // validateNumber(priceBreakup?.discount?.diamond) +
  // validateNumber(gemstone) +
  // validateNumber(labour) +
  // validateNumber(metal) +
  // validateNumber(total);
  console.log(product?.custom_discounts);
  let discountStr = ({ discountObject, type }) => {
    let str = "";
    switch (type) {
      case "metal":
        if (discountObject?.on_sale_metal?.value)
          str = ` - ${
            discountObject?.on_sale_metal?.type === "by_amount"
              ? currencySymbols[defaultCurrency] || ""
              : ""
          } ${discountObject?.on_sale_metal?.value || ""}  ${
            discountObject?.on_sale_metal?.type === "by_percentage" ? "%" : ""
          }`;

        break;
      case "gemstone":
        if (discountObject?.on_sale_colorstone?.value)
          str = ` - ${
            discountObject?.on_sale_colorstone?.type === "by_amount"
              ? currencySymbols[defaultCurrency] || ""
              : ""
          } ${discountObject?.on_sale_colorstone?.value || ""}  ${
            discountObject?.on_sale_colorstone?.type === "by_percentage"
              ? "%"
              : ""
          }`;

        break;
      case "diamond":
        if (discountObject?.on_sale_diamond?.value)
          str = ` - ${
            discountObject?.on_sale_diamond?.type === "by_amount"
              ? currencySymbols[defaultCurrency] || ""
              : ""
          } ${discountObject?.on_sale_diamond?.value || ""}  ${
            discountObject?.on_sale_diamond?.type === "by_percentage" ? "%" : ""
          }`;

        break;
      case "labour":
        if (discountObject?.on_sale_making?.value)
          str = ` - ${
            discountObject?.on_sale_making?.type === "by_amount"
              ? currencySymbols[defaultCurrency] || ""
              : ""
          } ${discountObject?.on_sale_making?.value || ""}  ${
            discountObject?.on_sale_making?.type === "by_percentage" ? "%" : ""
          }`;

        break;

      default:
        break;
    }
    return str;
  };
  return (
    <Box
      onMouseLeave={onMouseLeave}
      sx={{
        width: { md: "400px" },
        px: 3,
        py: 5,
        boxShadow: "1px 1px 5px 0px rgba(0,0,0,0.75)",
        borderRadius: 3,
      }}
    >
      <Typography sx={{ p: 1, fontSize: "16px", fontWeight: "600" }}>
        Product Breakup
      </Typography>
      <Divider sx={{ mb: 3, mt: 1.5 }} />
      <SummaryTitle title="Metal" />

      <SummaryLabelValue
        title={"Metal Gross Weight"}
        value={Number(
          Number(gold_gross) + Number(silver_gross) + Number(platinium_gross)
        ).toFixed(3)}
      />
      <SummaryLabelValue title={"Metal Color"} value={metal_color} />
      {getTotalWeight(colorstone_details, "colorstone_weight") ||
      getTotalPieces(colorstone_details, "colorstone_pieces") ? (
        <SummaryTitle title="Gemstone Details" />
      ) : null}
      <SummaryLabelValue
        title={"Gemstone Weight (cts.)"}
        value={getTotalWeight(colorstone_details, "colorstone_weight")}
        valueSuffix={"ct."}
      />
      <SummaryLabelValue
        title={"Gemstone Pieces"}
        value={getTotalPieces(colorstone_details, "colorstone_pieces")}
        valueSuffix={"pcs."}
      />
      {getTotalWeight(diamond, "diamond_weight") ||
      getTotalPieces(diamond, "diamond_pieces") ? (
        <SummaryTitle title="Diamond Details" />
      ) : null}
      <SummaryLabelValue
        title={"Diamond Weight (cts.)"}
        value={getTotalWeight(diamond, "diamond_weight")}
        valueSuffix={"ct."}
      />
      <SummaryLabelValue
        title={"Diamond Pieces"}
        value={getTotalPieces(diamond, "diamond_pieces")}
        valueSuffix={"pcs."}
      />
      <SummaryTitle title="Price Breakup" />
      {!priceBreakup?.has_manual_price ? (
        <>
          <SummaryLabelValue
            title={
              <>
                Metal{"    "}
                <Typography sx={{ color: "green", display: "inline" }}>
                  {discountStr({
                    discountObject: product?.custom_discounts,
                    type: "metal",
                  })}
                </Typography>{" "}
              </>
            }
            // value={validateNumber(priceBreakup?.master?.metal)}
            value={
              <>
                {discount?.metal ? (
                  <>
                    {" "}
                    <Typography
                      sx={{ textDecoration: "line-through", display: "inline" }}
                    >
                      {Math.round(validateNumber(priceBreakup?.master?.metal))}
                    </Typography>{" "}
                    <Typography sx={{ display: "inline" }}>
                      {currencySymbols[defaultCurrency]}
                      {validateNumber(priceBreakup?.master?.metal) -
                        validateNumber(discount?.metal)}
                    </Typography>{" "}
                  </>
                ) : (
                  validateNumber(priceBreakup?.master?.metal)
                )}
              </>
            }
            valuePrefix={currencySymbols[defaultCurrency]}
          />
          <SummaryLabelValue
            title={
              <>
                Gemstone{"    "}
                <Typography sx={{ color: "green", display: "inline" }}>
                  {discountStr({
                    discountObject: product?.custom_discounts,
                    type: "gemstone",
                  })}
                </Typography>{" "}
              </>
            }
            subtitle={`${
              priceBreakup?.erpMargin?.gemstone?.margin
                ? `+${priceBreakup?.erpMargin?.gemstone?.margin}% Margin`
                : ""
            }`}
            // value={validateNumber(priceBreakup[colorstone_from]?.colorstone)}
            value={
              <>
                {discount?.gemstone ? (
                  <>
                    {" "}
                    <Typography
                      sx={{ textDecoration: "line-through", display: "inline" }}
                    >
                      {Math.round(
                        validateNumber(
                          priceBreakup[colorstone_from]?.colorstone
                        )
                      )}
                    </Typography>{" "}
                    <Typography sx={{ display: "inline" }}>
                      {currencySymbols[defaultCurrency]}
                      {validateNumber(
                        priceBreakup[colorstone_from]?.colorstone
                      ) - validateNumber(discount?.gemstone)}
                    </Typography>{" "}
                  </>
                ) : (
                  validateNumber(priceBreakup[colorstone_from]?.colorstone)
                )}
              </>
            }
            valuePrefix={currencySymbols[defaultCurrency]}
          />
          <SummaryLabelValue
            title={
              <>
                Diamond{"    "}
                <Typography sx={{ color: "green", display: "inline" }}>
                  {discountStr({
                    discountObject: product?.custom_discounts,
                    type: "diamond",
                  })}
                </Typography>{" "}
              </>
            }
            subtitle={`${
              priceBreakup?.erpMargin?.diamond?.margin
                ? `+${priceBreakup?.erpMargin?.diamond?.margin}% Margin`
                : ""
            }`}
            // value={validateNumber(priceBreakup[diamond_from]?.diamond)}
            value={
              <>
                {discount?.diamond ? (
                  <>
                    {" "}
                    <Typography
                      sx={{ textDecoration: "line-through", display: "inline" }}
                    >
                      {Math.round(
                        validateNumber(priceBreakup[diamond_from]?.diamond)
                      )}
                    </Typography>{" "}
                    <Typography sx={{ display: "inline" }}>
                      {currencySymbols[defaultCurrency]}
                      {validateNumber(priceBreakup[diamond_from]?.diamond) -
                        validateNumber(discount?.diamond)}
                    </Typography>{" "}
                  </>
                ) : (
                  validateNumber(priceBreakup[diamond_from]?.diamond)
                )}
              </>
            }
            valuePrefix={currencySymbols[defaultCurrency]}
          />
          <SummaryLabelValue
            title={
              <>
                Labour{"    "}
                <Typography sx={{ color: "green", display: "inline" }}>
                  {discountStr({
                    discountObject: product?.custom_discounts,
                    type: "labour",
                  })}
                </Typography>{" "}
              </>
            }
            subtitle={`${
              priceBreakup?.erpMargin?.labour?.margin
                ? `+${priceBreakup?.erpMargin?.labour?.margin}% Margin`
                : ""
            }`}
            value={
              <>
                {discount?.labour ? (
                  <>
                    {" "}
                    <Typography
                      sx={{ textDecoration: "line-through", display: "inline" }}
                    >
                      {Math.round(
                        validateNumber(
                          priceBreakup?.[product?.labourType]?.labour
                        )
                      )}
                    </Typography>{" "}
                    <Typography sx={{ display: "inline" }}>
                      {currencySymbols[defaultCurrency]}
                      {validateNumber(
                        priceBreakup?.[product?.labourType]?.labour
                      ) - validateNumber(discount?.labour)}
                    </Typography>{" "}
                  </>
                ) : (
                  validateNumber(priceBreakup?.[product?.labourType]?.labour)
                )}
              </>
            }
            valuePrefix={currencySymbols[defaultCurrency]}
          />
          <SummaryLabelValue
            title={"Extra charges"}
            value={getExtraCharges()}
            valuePrefix={currencySymbols[defaultCurrency]}
          />
        </>
      ) : (
        <>
          <SummaryLabelValue
            title={"Price"}
            value={validateNumber(priceBreakup?.totalPrice)}
            valuePrefix={currencySymbols[defaultCurrency]}
          />
        </>
      )}
      <SummaryLabelValue
        title={"GST/Tax"}
        value={validateNumber(priceBreakup?.tax)}
        valuePrefix={currencySymbols[defaultCurrency]}
      />
      {totalDiscount ? (
        <SummaryLabelValue
          title={"Discount"}
          value={Math.round(totalDiscount)}
          valuePrefix={`- ${currencySymbols[defaultCurrency] || ""}`}
        />
      ) : null}
      <Divider sx={{ my: 2.5 }} />
      <Stack
        direction="row"
        alignItems={"center"}
        justifyContent="space-between"
        sx={{ my: 2 }}
      >
        <Typography sx={{ fontSize: "13px", fontWeight: "600" }}>
          {"Total"}
        </Typography>
        <Typography sx={{ fontSize: "13px", fontWeight: "600" }}>
          {`${currencySymbols[defaultCurrency] || ""} ${validateNumber(
            priceBreakup?.price
          )}`}
        </Typography>
      </Stack>
    </Box>
  );
}

export default ProductPriceBreakup;
