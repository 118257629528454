/* eslint-disable import/no-anonymous-default-export */
import { EDIT_DATASHEET } from "../actions/datasheetActions";

const initialState = {};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case EDIT_DATASHEET:
      return { ...state, ...payload };
    default:
      return state;
  }
};
export default reducer;
