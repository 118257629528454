import { SET_MAIN_SOCKET } from "../actions/socketActions";

const initialState = {
  main_socket: null,
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_MAIN_SOCKET:
      return { ...state, main_socket: payload };

    default:
      return state;
  }
};
export default reducer;
