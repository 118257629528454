import { isEmailValid } from "../../../helper";

const validateStep1 = ({ form, setAlert }) => {
  let { email, password } = form;
  if (!email) {
    setAlert({
      show: true,
      message: "Email is required",
      severity: "error",
    });
    return true;
  }
  if (!isEmailValid(email)) {
    setAlert({
      show: true,
      message: "Must be a valid email",
      severity: "error",
    });
    return true;
  }
  if (!password) {
    setAlert({
      show: true,
      message: "Password is required",
      severity: "error",
    });
    return true;
  }
  if (password.length < 8) {
    setAlert({
      show: true,
      message: "Password should be atleast 8 character",
      severity: "error",
    });
    return true;
  }
};

export { validateStep1 };
