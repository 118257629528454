import { LocationOn, People } from "@material-ui/icons";
import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardContent,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useSelector } from "react-redux";
import {
  formatServerValue,
  getUserType,
  hasOnlyProduct,
  jweroColorPalette,
  mainSocketEvents,
  returnMostOccurring,
} from "../../../helper";
import {
  CrmPlan,
  CustomersIcon,
  LiveVisitor,
  Persons,
  SessionsAnalyticsSvg,
  UserInfoSvg,
} from "../../../Svgs";
import { chatgGlobalColors, NoTasks } from "../../chat/ChatHelper";
import { AnalyticsCard, VisitorList } from "./helper";

const options = {
  chart: {
    type: "bar",
    width: "100%", // height: "calc(100vh - 380px)",
    toolbar: {
      show: false, // Set to false to remove the option button,
      tools: {
        download: false, // <== line to add
      },
    },
  },
  fill: {
    colors: jweroColorPalette,
  },
  // colors: chatgGlobalColors,
  colors: jweroColorPalette,
  plotOptions: {
    bar: {
      borderRadius: 18,
      horizontal: true,
      distributed: true,
    },
  },

  dataLabels: {
    enabled: false,
  },
  xaxis: {
    categories: ["> 0 Min", "> 1 Min", "> 5 Min", "> 10 Min"],
  },
};

let chartOptionValue = {
  time_spent: {
    categories: ["> 0 Min", "> 1 Min", "> 5 Min", "> 10 Min"],
  },
  module: {
    categories:
      getUserType() === "supplier"
        ? ["Catalogs", "Website", "Quotation", "Instore"]
        : ["Catalogs", "Website", "Instore"],
  },
};

const LiveVisitorOverview = ({
  chartDataOption,
  allLiveVisitors,
  currentVisitors,
  isIpad,
  isPhone,
  setChartDataOption,
  visitordashboard,
}) => {
  const [chatOptions, setChatOptions] = useState(options);
  const [chartValue, setChartValue] = useState([0, 0, 0, 0]);
  const allOnlineVisitors = [];

  const user_products = useSelector(
    (state) => state.user?.store_plan?.user_products
  );
  //  useSelector(
  //     (state) => state.visitor.allOnlineVisitors
  // );

  let height = isIpad ? null : 250;
  useEffect(() => {
    if (chartDataOption === "time_spent")
      setChartValue([
        currentVisitors?.["0"]?.length || 0,
        currentVisitors?.["1"]?.length || 0,
        currentVisitors?.["5"]?.length || 0,
        currentVisitors?.["10"]?.length || 0,
      ]);

    if (chartDataOption === "module") {
      // console.log(allLiveVisitors);
      getUserType() === "supplier"
        ? setChartValue([
            allLiveVisitors?.filter((visi) =>
              visi?.events?.[0]?.type?.includes("catalog")
            )?.length || 0,
            allLiveVisitors?.filter((visi) =>
              visi?.events?.[0]?.type?.includes("website")
            )?.length || 0,
            allLiveVisitors?.filter((visi) =>
              visi?.events?.[0]?.type?.includes("quotation")
            )?.length || 0,
            allLiveVisitors?.filter((visi) =>
              visi?.events?.[0]?.type?.includes("Instore")
            )?.length || 0,
          ])
        : setChartValue([
            allLiveVisitors?.filter((visi) =>
              visi?.events?.[0]?.type?.includes("catalog")
            )?.length || 0,
            allLiveVisitors?.filter((visi) =>
              visi?.events?.[0]?.type?.includes("website")
            )?.length || 0,
            allLiveVisitors?.filter((visi) =>
              visi?.events?.[0]?.type?.includes("instore")
            )?.length || 0,
          ]);
    }
  }, [allLiveVisitors, chartDataOption, currentVisitors]);
  useMemo(() => {
    setChatOptions((pre) => ({
      ...pre,
      xaxis: chartOptionValue?.[chartDataOption],
    }));
  }, [chartDataOption]);
  // console.log(chartValue);
  return (
    <Box mt={2} sx={{ height: `calc(100vh - ${height}px)`, overflow: "auto" }}>
      <Grid container spacing={5}>
        <Grid item xs={12} lg={8}>
          <Card sx={{ borderRadius: "14px", border: "0.5px solid #e5eef8" }}>
            <CardContent
              sx={{
                padding: "12px",
                "&:last-child": { paddingBottom: "12px" },
              }}
            >
              <Stack
                direction={"row"}
                alignItems="center"
                justifyContent={"space-between"}
              >
                <Typography variant="h6" sx={{ ml: 3 }}>
                  {formatServerValue(chartDataOption || "")}
                </Typography>
                {isPhone && !hasOnlyProduct("catalog", user_products) && (
                  <ButtonGroup
                    variant="outlined"
                    aria-label="outlined primary button group"
                    size="small"
                  >
                    <Button
                      variant={
                        chartDataOption === "time_spent"
                          ? "contained"
                          : "outlined"
                      }
                      onClick={() => setChartDataOption("time_spent")}
                    >
                      Time spent
                    </Button>
                    <Button
                      variant={
                        chartDataOption === "module" ? "contained" : "outlined"
                      }
                      onClick={() => setChartDataOption("module")}
                    >
                      Module
                    </Button>
                  </ButtonGroup>
                )}
              </Stack>
              <Box
                sx={{
                  height: `calc(100vh - ${height + 180}px)`,
                  display: !chartValue?.find((num) => num >= 1)
                    ? "flex"
                    : "block",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {chartValue?.find((num) => num >= 1) ? (
                  <ReactApexChart
                    options={chatOptions}
                    series={[
                      {
                        data: chartValue,
                      },
                    ]}
                    type="bar"
                    height="100%"
                    // height={"300px"}
                  />
                ) : (
                  <NoTasks
                    // sx={{ height: "100%" }}
                    // sx={{ my: 25 }}
                    sx={{ my: 0 }}
                    text="No visitors Live at the moment"
                    icon={
                      // <Button variant="outlined">
                      //   Go to visitor page
                      // </Button>
                      <LiveVisitor
                        style={{
                          height: "70px",
                          width: "70px",
                          fill: "#c4c4c4",
                        }}
                        fillCircle="#c4c4c4"
                      />
                    }
                  />
                )}
              </Box>
            </CardContent>
          </Card>
          <Grid container spacing={4} mt={2}>
            {/* {console.log(allLiveVisitors)} */}
            <Grid item xs={6} md={3}>
              <AnalyticsCard
                title={"Live Customer"}
                icon={<UserInfoSvg />}
                value={
                  allLiveVisitors?.filter((arr) => arr?.[0]?.user_name?.trim())
                    ?.length || "-"
                }
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <AnalyticsCard
                title={"Live Leads"}
                icon={<People style={{ height: "26px" }} color="primary" />}
                value={
                  allLiveVisitors?.filter((arr) => !arr?.[0]?.user_name?.trim())
                    ?.length || "-"
                }
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <AnalyticsCard
                title={"Avg session"}
                icon={<SessionsAnalyticsSvg />}
                value={visitordashboard?.sessions || "-"}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <AnalyticsCard
                title={"Top city"}
                icon={<LocationOn style={{ height: "26px" }} color="primary" />}
                value={returnMostOccurring(
                  allLiveVisitors?.map((ob) => {
                    let locationStr =
                      ob?.events?.find((o) =>
                        mainSocketEvents?.includes(o.type)
                      )?.location?.city || "";
                    return locationStr;
                  })
                )}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={4}>
          {/* <Card
                        sx={{
                            height,
                            overflow: "auto",
                            borderRadius: "14px",
                            border: "0.5px solid #e5eef8",
                        }}
                        className="scrollbar-hidden"
                    > */}
          <VisitorList
            cardStyle={{
              height: `calc(100vh - ${height}px)`,
              overflow: "auto",
              borderRadius: "14px",
              border: "0.5px solid #e5eef8",
            }}
            visitors={allLiveVisitors}
            heading={`${allLiveVisitors?.length || ""} Visitors`}
            live={true}
          />
          {/* </Card> */}
        </Grid>
      </Grid>
    </Box>
  );
};

export default LiveVisitorOverview;
