import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  MenuItem,
  Select,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  getRandomString,
  getTimeInSeconds,
  isArrayWithValues,
  isObjWithValues,
  primaryLabel,
  primaryLabelSmall,
  secondaryLabelSmall,
} from "../../../helper";
import { MaketoOrder, Readystockoutline } from "../../../Svgs";
import { SwiperSlide, Swiper } from "swiper/react";
import { Controller, Navigation } from "swiper";
import "swiper/css"; // core Swiper
import ProductCardDetails from "./ProductCardDetails";
import ProductCardBreakup from "./ProductCardBreakup";
import { useSelector } from "react-redux";
import ProductCardComments from "./ProductCardComments";
import ProductCustomization from "./ProductCustomization";
import { changeCustomizationInProduct } from "../quotationHelper";

function QuotationProductCard({
  product,
  quotation,
  setQuotation,
  updateQuotation,
}) {
  const [customizeForm, setCustomizeForm] = useState({
    diamond_quality: "",
    metal_color: "",
    metal_purity: "",
    diamond_qualities: [],
    quantity: 1,
  });
  const [addingCustomization, setAddingCustomization] = useState(false);
  const [removingCustomization, setRemovingCustomization] = useState(false);

  const allowed_customization = useSelector(
    (state) => state.settings.allowed_customization
  );

  const addCustomizationInQuotation = async () => {
    let { customization } = { ...(quotation || {}) };
    if (!isObjWithValues(customization)) customization = {};
    if (!isArrayWithValues(customization[product.id]))
      customization[product.id] = [];
    let product_customizations = customization[product.id];
    product_customizations.push({
      ...customizeForm,
      id: `${getTimeInSeconds()}_${getRandomString(8)}`,
    });
    customization[product.id] = product_customizations;
    setAddingCustomization(true);
    await updateQuotation({
      payload: {
        ...quotation,
        customization,
        productsFetched: getRandomString(10),
      },
      success_message: "New variant has been added",
      error_message: "Couldn't add new variant",
    });
    setAddingCustomization(false);
    return;
    // setQuotation((state) => ({
    //   ...state,
    //   customization,
    //   productsFetched: getRandomString(10),
    // }));
  };
  const removeCustomizationFromQuotation = async (id) => {
    let { customization } = { ...(quotation || {}) };
    if (!isObjWithValues(customization)) customization = {};
    if (!isArrayWithValues(customization[product.id]))
      customization[product.id] = [];
    let product_customizations = customization[product.id];
    product_customizations = product_customizations?.filter((i) => i.id !== id);
    customization[product.id] = product_customizations;
    setRemovingCustomization(true);
    await updateQuotation({
      payload: {
        ...quotation,
        customization,
        productsFetched: getRandomString(10),
      },
      success_message: "Variant has been removed",
      error_message: "Couldn't remove variant, please try again",
    });
    setRemovingCustomization(false);
    // setQuotation((state) => ({
    //   ...state,
    //   customization,
    //   productsFetched: getRandomString(10),
    // }));
  };

  const onCustomizationClick = (product_id, id) => {
    let { productDetails } = quotation;
    let product = productDetails?.find((i) => i.id === product_id);
    if (!product) return;
    product.selectedCustomization = id;

    setQuotation((state) => ({
      ...state,
      productDetails: productDetails?.map((i) =>
        i.id === product_id ? product : i
      ),
    }));
  };

  const editCustomizeForm = (payload) =>
    setCustomizeForm((state) => ({ ...state, ...payload }));

  useEffect(() => {
    let { customization } = quotation;
    let productCustomization = customization?.[product.id];
    if (!isArrayWithValues(productCustomization)) return;
    let { selectedCustomization } = product;
    let customizationObj = productCustomization?.find(
      (i) => i.id === selectedCustomization
    );
    if (!customizationObj) return;

    let { metal_color, metal_purity } = customizationObj;
    let allProducts = quotation?.productDetails;
    if (!isArrayWithValues(allProducts)) return;
    allProducts = allProducts.map((i) => {
      if (i.id === product.id && metal_purity) {
        let updatedProduct = changeCustomizationInProduct({
          product: i,
          customizationObj,
        });
        return updatedProduct;
        // let goldKtIndex = meta_data?.findIndex((i) => i.key === "gold_kt");
        // if (goldKtIndex)
        //   meta_data[goldKtIndex] = { key: "gold_kt", value: metal_purity };
        // return { ...i, gold_kt: metal_purity, meta_data };
      } else return i;
    });
    setQuotation((state) => ({
      ...state,
      productDetails: allProducts,
      productsFetched: getRandomString(10),
    }));
  }, [product.selectedCustomization]);

  // useEffect(() => {
  //   let { metal_color, metal_purity } = customizeForm;
  //   let allProducts = quotation?.productDetails;
  //   if (!isArrayWithValues(allProducts)) return;
  //   allProducts = allProducts.map((i) => {
  //     let { meta_data } = i;
  //     if (i.customize_id === product.customize_id && metal_purity) {
  //       let goldKtIndex = meta_data?.findIndex((i) => i.key === "gold_kt");
  //       if (goldKtIndex)
  //         meta_data[goldKtIndex] = { key: "gold_kt", value: metal_purity };
  //       return { ...i, gold_kt: metal_purity, meta_data };
  //     } else return i;
  //   });
  //   setQuotation((state) => ({
  //     ...state,
  //     productDetails: allProducts,
  //     productsFetched: getRandomString(10),
  //   }));
  // }, [customizeForm]);

  useEffect(() => {
    if (isObjWithValues(product)) {
      let customizeForm = {};
      if (product.gold_kt) customizeForm.metal_purity = product.gold_kt;
      if (
        isArrayWithValues(allowed_customization?.diamond_qualities) &&
        isObjWithValues(product.diamond)
      ) {
        customizeForm.diamond_quality = Object.values(
          product.diamond
        )?.[0]?.diamond_quality;
      }
      if (product?.metal_color) customizeForm.metal_color = product.metal_color;

      if (
        allowed_customization?.metalColors?.[0]?.value &&
        product?.metal_color
      )
        customizeForm.metal_color =
          allowed_customization?.metalColors?.[0]?.value;
      editCustomizeForm(customizeForm);
    }
  }, [allowed_customization, product]);

  let { name, collections, categories, images } = product;

  return (
    <Card sx={{ borderRadius: "8px" }}>
      <CardContent>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent={"space-between"}
        >
          <Box>
            <Typography sx={{ ...primaryLabel }}>{name}</Typography>
            <Typography sx={{ ...secondaryLabelSmall }}>
              {collections?.[0]?.name || ""}{" "}
              {categories?.[0]?.name ? `> ${categories?.[0]?.name}` : ""}
            </Typography>
          </Box>
          <Stack direction="row" alignItems={"center"} spacing={2}>
            {product.stock_status === "instock" ? (
              <Readystockoutline />
            ) : product.stock_status === "maketoorder" ? (
              <MaketoOrder />
            ) : null}
            <Typography sx={{ ...primaryLabel }}>
              ₹ {product?.totalProductPrice}
            </Typography>
          </Stack>
        </Stack>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12} md={3}>
            <ProductImagesView images={images} />
          </Grid>
          <Grid item xs={12} md={9}>
            <ProductBreakup
              product={product}
              quotation={quotation}
              setQuotation={setQuotation}
              editCustomizeForm={editCustomizeForm}
              customizeForm={customizeForm}
              addCustomizationInQuotation={addCustomizationInQuotation}
              removeCustomizationFromQuotation={
                removeCustomizationFromQuotation
              }
              updateQuotation={updateQuotation}
              onCustomizationClick={onCustomizationClick}
              addingCustomization={addingCustomization}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default QuotationProductCard;

const ProductImagesView = ({ images }) => {
  const [selectedImage, setSelectedImage] = useState();
  const [allImages, setAllImages] = useState([]);
  const isIpad = useMediaQuery((theme) => theme.breakpoints.down("md"));
  useEffect(() => {
    if (isArrayWithValues(images)) {
      setSelectedImage(images[0]);
      setAllImages(images);
    } else {
      setSelectedImage({
        src: "/static/img/woocommerce_placeholder.png",
      });
      setAllImages([
        {
          src: "/static/img/woocommerce_placeholder.png",
        },
      ]);
    }
  }, [images]);
  return (
    <Box>
      <img
        src={selectedImage?.src}
        style={{ height: "100%", width: "100%", borderRadius: "6px" }}
      />
      <Swiper
        // spaceBetween={30}
        slidesPerView={3}
        // onSlideChange={() => console.log("slide change")}
        // onSwiper={(swiper) => console.log(swiper)}
        modules={[Navigation]}
        navigation={true}
        // navigation={
        //   {
        //       nextEl: ".swiper-button-next",
        //       prevEl: ".swiper-button-prev",
        //       disabledClass: "swiper-button-disabled",
        //   }
        // }
        style={{
          "--swiper-navigation-color": "#808080",
          "--swiper-navigation-size": "20px",
          "--swiper-pagination-color": "#808080",
          position: "relative",

          //   maxWidth: !isIpad ? `calc(100vw - ${sidebarExtraWidth}px)` : "100%",
          //   gap: "5px",
          marginTop: "8px",
        }}
        // slideNextClass="swiper-button-next"
        pagination={{
          type: "fraction",
        }}
        breakpoints={
          {
            // when window width is >= 320px
            //   320: {
            //     slidesPerView: 1,
            //     spaceBetween: 24,
            //   },
            //   // when window width is >= 480px
            //   480: {
            //     slidesPerView: 2,
            //     spaceBetween: 24,
            //   },
            //   // when window width is >= 640px
            //   640: {
            //     slidesPerView: 2,
            //     spaceBetween: 24,
            //   },
            //   1024: {
            //     slidesPerView: 3,
            //     spaceBetween: 32,
            //     // slidesPerGroup: 1,
            //   },
            //   1336: {
            //     slidesPerView: 3,
            //     spaceBetween: 32,
            //   },
          }
        }
      >
        {allImages.map((image) => (
          <SwiperSlide key={image.id}>
            <img
              src={image?.src}
              onClick={() => setSelectedImage(image)}
              style={{
                height: "40px",
                width: "40px",
                borderRadius: "6px",
                border:
                  image?.src === selectedImage?.src
                    ? "1px solid #000"
                    : "1px solid transparent",
              }}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  );
};

const tabs = [
  { label: "Details", value: "details" },
  { label: "Breakup", value: "breakup" },
  { label: "Comments", value: "comments" },
];

const ProductBreakup = ({
  product,
  quotation,
  setQuotation,
  editCustomizeForm,
  customizeForm,
  addCustomizationInQuotation,
  removeCustomizationFromQuotation,
  onCustomizationClick,
  updateQuotation,
  addingCustomization,
}) => {
  const [selectedTab, setSelectedTab] = useState("details");
  return (
    <Box>
      <Stack direction="row" alignItems={"center"} spacing={2}>
        {tabs.map((i) => {
          let selected = selectedTab === i.value;
          return (
            <Button
              sx={{
                color: selected ? "primary" : "text.secondary",
                transition: "all 0.5s",
              }}
              onClick={() => setSelectedTab(i.value)}
              //   size={selected ? "large" : "medium"}
              key={i.value}
            >
              {i.label}
            </Button>
          );
        })}
      </Stack>
      {selectedTab === "details" ? (
        <>
          <ProductCardDetails product={product} />
          <ProductCustomization
            product={product}
            quotation={quotation}
            customizeForm={customizeForm}
            editCustomizeForm={editCustomizeForm}
            addCustomizationInQuotation={addCustomizationInQuotation}
            removeCustomizationFromQuotation={removeCustomizationFromQuotation}
            onCustomizationClick={onCustomizationClick}
            updateQuotation={updateQuotation}
            addingCustomization={addingCustomization}
          />
        </>
      ) : selectedTab === "breakup" ? (
        <ProductCardBreakup product={product} />
      ) : selectedTab === "comments" ? (
        <ProductCardComments
          product={product}
          setQuotation={setQuotation}
          quotation={quotation}
        />
      ) : null}
    </Box>
  );
};
