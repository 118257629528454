import { Typography } from "@mui/material";
import { filterStyles } from "../styles/filterStyles";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";

const styles = filterStyles

const FilterAdd = ({ onAddFilter }) => {
    return ( 

        <Typography variant="span" onClick={onAddFilter}>
        <Typography variant="h2" sx={styles.footerTextBox}>
          <Typography variant="span" sx={styles.footerText}>
            <AddOutlinedIcon sx={styles.footerIcon} />
            Add filter group
          </Typography>
        </Typography>
      </Typography>
    );
}
 
export default FilterAdd;