import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/system";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  dialCodes,
  dialogStyle,
  getAdminName,
  getAxiosError,
  getId,
  getRandomString,
  getShortRefLink,
  getStoreEmail,
  getStoreName,
  getToken,
  getWebsite,
  isArrayWithValues,
  secondaryLabel,
  validateEmail,
} from "../../../helper";
import { updateCRMInvite } from "../../../redux/actions/crmActions";
import { getAllBusinesses } from "../../../redux/actions/supplierActions";
import { ButtonTab } from "../../components/helper";
import { commonDialCodes } from "../../crm/Add";
import { LoadingSupplierInfoCard, SupplierInfoCard } from "../Dashboard";

const tabs = [
  { label: "All", value: "" },
  { label: "Gold jewellery", value: "gold-jewellery" },
  { label: "Diamond jewellery", value: "diamond-jewellery" },
  { label: "Platinum jewellery", value: "platinium-jewellery" },
  { label: "Antique jewellery", value: "antique-jewellery" },
  { label: "Silver jewellery", value: "silver-jewellery" },
];

const SuppliersList = () => {
  const [selectedTab, setSelectedTab] = useState("");
  const [selected, setSelected] = useState("connected");
  const handleSelected = (e) => setSelected(e.target.value);
  // const crmInvites = useSelector((state) => state.customers.crmInvites);
  const allSuppliers = useSelector((state) => state.supplier.allSuppliers);
  const gettingAllBusinesses = useSelector((state) => state.supplier.gettingAllBusinesses);
  console.log(allSuppliers, "<<<<");
  const [suppliersByStatus, setSuppliersByStatus] = useState({});

  const dispatch = useDispatch();

  useEffect(() => {
    if (!isArrayWithValues(allSuppliers)) dispatch(getAllBusinesses());
  }, []);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    if (isArrayWithValues(allSuppliers)) {
      let obj = { new: [] };
      for (let i of allSuppliers) {
        let { status } = i;
        if (status) {
          if (!obj[status]) obj[status] = [];
          obj[status].push(i);
        } else obj.new.push(i);
      }
      setSuppliersByStatus(obj);
    }
  }, [allSuppliers]);

  useEffect(() => { }, []);

  return (
    <Box>
      <Box my={4}>
        <Tabs onChange={(e, newValue) => setSelectedTab(newValue)} value={selectedTab}>
          {tabs.map(obj => <Tab label={obj?.label} value={obj?.value} />)}
        </Tabs>
        {/* <Stack
          direction={"row"}
          alignItems="center"
          gap={"10px"}
          sx={{ overflow: "scroll" }}
          className="scrollbar-hidden"
        >
          {tabs.map((obj) => (
            <ButtonTab
              onClick={() => setSelectedTab(obj?.value)}
              selectedTab={selectedTab}
              value={obj?.value}
            >
              {obj?.label}
            </ButtonTab>
          ))}
        </Stack> */}
      </Box>
      <Stack
        direction="row"
        alignItems={"center"}
        justifyContent="space-between"
      >
        <Typography sx={{ ...secondaryLabel, my: 3 }}>
          Showing 12 supplier
        </Typography>
        <FormControl size="small">
          {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selected}
            // label="Age"
            onChange={handleSelected}
          >
            <MenuItem value={"new"}>New</MenuItem>
            <MenuItem value={"invited"}>Invited</MenuItem>
            <MenuItem value={"connected"}>Connected</MenuItem>
            <MenuItem value={"requested"}>Requested</MenuItem>
          </Select>
        </FormControl>
      </Stack>

      <Grid container spacing={isMobile ? 1 : 3}>

        {gettingAllBusinesses ? new Array(7).fill("")?.map(str => <LoadingSupplierInfoCard />) : null}
        {isArrayWithValues(suppliersByStatus[selected]) &&
          suppliersByStatus[selected]
            ?.filter((i) =>
              !selectedTab
                ? true
                : i?.deals_in?.collections?.find((j) => j.value === selectedTab)
            )
            .map((obj) => (
              <Grid item xs={6} sm={4} md={3} lg={2} xl={1.7142}>
                <SupplierInfoCard customer={obj} />
              </Grid>
            ))}
      </Grid>
    </Box>
  );
};

export default SuppliersList;
