import {
  Avatar,
  Box,
  Card,
  CardContent,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { getRandomAvatar } from "..";
import {
  getRandomInt,
  getRandomString,
  globalCardStyle,
  secondaryLabel,
} from "../../../helper";

const random = [
  "Diamond ray added by Ranjan Jwellers",
  "203 products has been added by ratnalaya jewellers",
  "New supplier AR-243 has joined",
  "AR-243 is now Out of Stock",
];
const RecentActivity = () => {
  return (
    <Card sx={{ ...globalCardStyle }}>
      <CardContent>
        <Typography variant="h6"> Recent activity</Typography>

        <Box>
          <Stack
            direction={"row"}
            justifyContent="space-between"
            alignItems={"center"}
            my={4}
          >
            <Typography sx={{ ...secondaryLabel }}>Today</Typography>
            <Typography sx={{ ...secondaryLabel }}>3 update</Typography>
          </Stack>
          <Stack rowGap={"8px"}>
            {new Array(12).fill("").map((obj) => {
              return (
                <Stack direction={"row"} alignItems="center" gap={"12px"}>
                  <Avatar
                    src={getRandomAvatar()}
                    sx={{ width: 50, height: 50 }}
                  />
                  <Typography variant="h6" fontWeight={"400"}>
                    {random[getRandomInt(random.length - 1)]}
                  </Typography>
                </Stack>
              );
            })}
          </Stack>
        </Box>
      </CardContent>
    </Card>
  );
};

export default RecentActivity;
