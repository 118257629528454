import { Box, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useDispatch, useSelector } from "react-redux";
import {
  formatDate,
  isArrayWithValues,
  isObjWithValues,
  settingsEndpoint,
  validateNumber,
} from "../../../../helper";
import {
  editSettings,
  EDIT_SETTINGS,
} from "../../../../redux/actions/settingActions";
import { AnalyticsCard } from "../ornate";
import { getJemisysProducts } from "./hepler";

const JemisysOverview = ({ reduxOrnateProducts, ornateApiCalls }) => {
  const [data, setData] = useState([]);
  const [tanikaInventory, setTanikaInventory] = useState("");
  const [jemisysInventory, setJemisysInventory] = useState(0);
  const [jemisysImported, setJemisysImported] = useState(0);
  const [jemisysCalls, setJemisysCalls] = useState(0);
  const userInfo = useSelector((state) => state.user);

  const dispatch = useDispatch();

  const reduxOrnateHistory = useSelector(
    (state) => state.settings.ornateHistory
  );
  const jemisysHistory = useSelector((state) => state.settings.jemisysHistory);

  const jemisysProducts = useSelector(
    (state) => state.settings.jemisysProducts
  );
  const jemisysIntegration = useSelector(
    (state) => state.settings.jemisysIntegration
  );
  const api_calls = useSelector((state) => state.settings.apiCalls);

  useEffect(() => {
    setJemisysCalls(api_calls?.[`total_jemisys`] || 0);
  }, [api_calls]);

  const setUpJemisysProducts = async () => {
    // setFetchingProduct(true);
    let jemisysProductsRes = await getJemisysProducts({
      ...jemisysIntegration,
    });

    dispatch({
      type: EDIT_SETTINGS,
      payload: {
        jemisysProducts: isArrayWithValues(jemisysProductsRes)
          ? jemisysProductsRes
          : [],
      },
    });
    // setFetchingProduct(false);
  };
  useEffect(() => {
    if (!isArrayWithValues(jemisysProducts)) {
      setUpJemisysProducts();
    }

    return () => {};
  }, []);
  useEffect(async () => {
    if (!isObjWithValues(jemisysHistory)) {
      let history = await settingsEndpoint({
        endpoint: `jemisys_history_${jemisysIntegration?.inventory_ip_port}`,
        // endpoint: `jemisys_history`,
      });
      dispatch(
        editSettings({
          jemisysHistory: history,
        })
      );
    }
  }, []);

  useEffect(() => {
    setJemisysInventory(jemisysProducts?.length || 0);
  }, [jemisysProducts]);
  //   console.log(jemisysProducts, "<<<<");
  useEffect(() => {
    if (isObjWithValues(ornateApiCalls)) {
      let total = 0;
      Object.values(ornateApiCalls).map((i) => (total += validateNumber(i)));
      setJemisysCalls(total);
    }
  }, [ornateApiCalls]);
  useEffect(() => {
    if (isObjWithValues(jemisysHistory)) {
      let array = [];
      let totalProducts = 0;
      for (let historyObj of Object.values(jemisysHistory)) {
        let { start, import_info, type } = historyObj;
        if (type === "import") {
          array.push([Number(start) * 1000, import_info?.success?.length || 0]);
          totalProducts += import_info?.success?.length || 0;
        }
        // if (isArrayWithValues(products)) {
        // }
      }
      setData(array);
      setJemisysImported(totalProducts);
    }
  }, [jemisysHistory]);

  useEffect(() => {
    if (isObjWithValues(userInfo)) {
      let { store_used, store_plan } = userInfo;
      let string = "";
      if (isObjWithValues(store_used)) string = `${store_used.products}`;
      //   if (isObjWithValues(store_plan))
      //     string = `${string}${store_plan.add_products || ""}`;
      setTanikaInventory(string);
    }
  }, [userInfo]);
  return (
    <Box sx={{ p: 3, pt: 4 }}>
      <Grid container spacing={4}>
        <Grid item xs={12} md={4} lg={3}>
          <AnalyticsCard title="Jwero Inventory" value={tanikaInventory} />
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
          <AnalyticsCard title="Jemisys Inventory" value={jemisysInventory} />
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
          <AnalyticsCard
            title="Imported from JEMiSys"
            value={jemisysImported}
          />
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
          <AnalyticsCard
            title="API Calls"
            value={jemisysCalls}
            subtitle={
              jemisysIntegration.last_sync_time
                ? formatDate(
                    new Date(jemisysIntegration.last_sync_time),
                    "dd:mm:yyyy hh:mm am/pm"
                  )
                : ""
            }
          />
        </Grid>
      </Grid>
      <Box sx={{ mt: 6 }}>
        <ReactApexChart
          options={{
            ...importOptions,
            selection: "all",
            xaxis: {
              type: "datetime",
              min: isArrayWithValues(data)
                ? new Date(data[0][0]).getTime()
                : new Date("01 Oct 2022").getTime(),
              tickAmount: 6,
            },
          }}
          series={[{ name: "Imports", data }]}
          type="area"
          height={350}
        />
      </Box>
    </Box>
  );
};

export default JemisysOverview;

const importOptions = {
  chart: {
    id: "area-datetime",
    type: "area",
    height: 350,
    zoom: {
      autoScaleYaxis: true,
    },
    toolbar: { show: false },
  },
  dataLabels: {
    enabled: false,
  },
  markers: {
    size: 0,
    style: "hollow",
  },

  tooltip: {
    x: {
      format: "dd MMM yyyy",
    },
  },
  fill: {
    type: "gradient",
    gradient: {
      shadeIntensity: 1,
      opacityFrom: 0.7,
      opacityTo: 0.9,
      stops: [0, 100],
    },
  },
};
