import { Box, Grid, Typography } from "@mui/material";
import { DashboardTotalCard } from "../../dashboards/Default";
import { currencySymbols } from "../../pricing/Gold";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { isObjWithValues, primaryLabelLarge } from "../../../helper";

const ProductsSummary = ({ quotation }) => {
  const [pricingSummary, setPricingSummary] = useState({
    metal: 0,
    diamond: 0,
    gemstone: 0,
    labour: 0,
    total: 0,
    avgPricePerProduct: 0,
    totalProducts: 0,
    metal_gross_weight: 0,
    total_metal_rate: 0,
    avg_rate_per_gram: 0,
    total_diamond_pieces: 0,
    total_diamond_weight: 0,
    avg_diamond_weight_per_pc: 0,
    total_gemstone_pieces: 0,
    total_gemstone_weight: 0,
    avg_gemstone_weight_per_pc: 0,
    labour_per_gram: 0,
  });
  const default_currency = useSelector(
    (state) => state.settings.productSettings
  )?.default_currency;

  useEffect(() => {
    let { productsSummary } = quotation;
    if (isObjWithValues(productsSummary)) setPricingSummary(productsSummary);
  }, [quotation.productsSummary]);

  let { customer } = quotation;
  return (
    <Box sx={{ mt: 3 }}>
      <Typography sx={{ ...primaryLabelLarge }}>Pricing Details</Typography>
      <Grid container spacing={3} sx={{ mt: 1 }}>
        <Grid item xs={6} sm={4} md={3} lg={2}>
          <DashboardTotalCard
            hideNavigate
            hideGraph
            title="Metal"
            value={pricingSummary.metal || 0}
            valuePrefix={currencySymbols[default_currency]}
            subtitle={`${pricingSummary.metal_gross_weight} gms`}
            subtitle2={`Avg rate per gm: ${currencySymbols[default_currency]} ${pricingSummary.avg_rate_per_gram}`}
            {...topCardsExtraProps}
          />
        </Grid>
        <Grid item xs={6} sm={4} md={3} lg={2}>
          <DashboardTotalCard
            hideNavigate
            hideGraph
            {...topCardsExtraProps}
            valuePrefix={currencySymbols[default_currency]}
            title="Diamond"
            value={pricingSummary.diamond || 0}
            subtitle={`${pricingSummary.total_diamond_weight} cts/ ${pricingSummary.total_diamond_pieces} pcs`}
            subtitle2={`Avg size per pc: ${pricingSummary.avg_diamond_weight_per_pc} cts`}
          />
        </Grid>
        <Grid item xs={6} sm={4} md={3} lg={2}>
          <DashboardTotalCard
            hideNavigate
            hideGraph
            {...topCardsExtraProps}
            valuePrefix={currencySymbols[default_currency]}
            title="Gemstone"
            value={pricingSummary.gemstone || 0}
            subtitle={`${pricingSummary.total_gemstone_weight} cts/ ${pricingSummary.total_gemstone_pieces} pcs`}
            subtitle2={`Avg size per pc: ${pricingSummary.avg_gemstone_weight_per_pc} cts`}
          />
        </Grid>
        <Grid item xs={6} sm={4} md={3} lg={2}>
          <DashboardTotalCard
            hideNavigate
            hideGraph
            {...topCardsExtraProps}
            valuePrefix={currencySymbols[default_currency]}
            title="Labour"
            subtitle={`Per gram: ${currencySymbols[default_currency]} ${pricingSummary.labour_per_gram}`}
            value={pricingSummary.labour || 0}
          />
        </Grid>
        <Grid item xs={6} sm={4} md={3} lg={2}>
          <DashboardTotalCard
            hideNavigate
            hideGraph
            {...topCardsExtraProps}
            valuePrefix={currencySymbols[default_currency]}
            title="Total"
            value={pricingSummary.total || 0}
          />
        </Grid>
        <Grid item xs={6} sm={4} md={3} lg={2}>
          <DashboardTotalCard
            hideNavigate
            hideGraph
            {...topCardsExtraProps}
            valuePrefix={currencySymbols[default_currency]}
            title="Avg price per product"
            value={pricingSummary.avgPricePerProduct || 0}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default ProductsSummary;

const topCardsExtraProps = {
  valueContainerStyle: { my: 1, mb: 0 },
  labelStyle: { fontSize: "15px" },
  valueProps: {
    sx: { fontSize: "16px", color: "text.primary", fontWeight: "500" },
  },
};
