import Switch from "rc-switch";
import React from "react";
import "rc-switch/assets/index.css";
import { useTheme } from "@mui/material";

const CustomSwitch = (props) => {
    let { onChange, checked } = props;
    const theme = useTheme();
    let backgroundColor = theme.palette.primary && theme.palette.primary.main;
    return (
        <>
            {/* <style>
                {`.rc-switch-checked::after {
                    content: '';
                  height: 13px;
                  width: 13px;
                  left: 14px;
}

`}{
                    `.rc-switch::after {
   height: 13px;
 width: 13px;
}`
                }
            </style> */}
            <Switch
                {...props}
                onChange={onChange}
                checked={checked}

                style={
                    checked
                        ? {
                            border: `1px solid ${backgroundColor}`,
                            backgroundColor,
                            // width: "30px",
                            // height: "16px",

                        }
                        : {
                            // width: "30px",
                            // height: "16px",


                        }
                }
            />
        </>
    );
};

export default CustomSwitch;
