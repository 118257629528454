import { Box, Button, Grid, Stack } from "@mui/material";
import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { isArrayWithValues } from "../../../../helper";
import { getConversations } from "../../../../redux/actions/chatActions";
import { Facebook, InstagramSvg, Messenger, Whatsapp } from "../../../../Svgs";
import { AnalyticsCard } from "../ornate";
const tabs = [
  { label: "Whatsapp", value: "whatsapp", icon: <Whatsapp /> },
  {
    label: "Facebook",
    value: "facebook",
    icon: <Messenger height={25} width={25} />,
  },
  { label: "Instagram", value: "instagram", icon: <InstagramSvg /> },
];
const MetaAnalytics = () => {
  const allReduxConversations =
    useSelector((state) => state.chats.conversations) || [];
  const dispatch = useDispatch();
  const [selectedTab, setSelectedTab] = useState("whatsapp");
  // useEffect(() => {
  //   if (!isArrayWithValues(allReduxConversations)) dispatch(getConversations());
  // }, []);
  const getPlatformUser = (platform) => {
    let arr = allReduxConversations.filter((obj) => obj.platform === platform);
    return arr.length;
  };
  console.log("facebook", getPlatformUser(selectedTab));
  const getPlatformMessages = (platform) => {
    let arr = allReduxConversations.filter((obj) => obj.platform === platform);
    let messageArray = [];
    for (const iterator of arr) {
      // console.log(iterator);
      messageArray = [...messageArray, ...iterator.conversation];
    }
    console.log(messageArray);
    return messageArray;
  };
  const getTemplateMessages = (platform) => {
    let arr = allReduxConversations.filter(
      (obj) => obj.platform === "whatsapp"
    );
    let messageArray = [];
    for (const iterator of arr) {
      // console.log(iterator);
      for (const obj of iterator?.conversation) {
        if (obj?.message?.type === "template") {
          messageArray = [...messageArray, obj];
        }
      }
    }
    console.log(messageArray);
    return messageArray;
  };
  const getSentMessage = (platform) => {
    let arr = allReduxConversations.filter((obj) => obj.platform === platform);
    let messageArray = [];
    for (const iterator of arr) {
      // console.log(iterator);
      for (const obj of iterator?.conversation) {
        if (obj?.is_sender) {
          messageArray = [...messageArray, obj];
        }
      }
    }
    console.log(messageArray);
    return messageArray;
  };
  // useEffect(() => {
  //   getPlatformMessages(selectedTab);
  // }, [selectedTab]);

  return (
    <Box>
      <Stack
        className="scrollbar-hidden"
        direction="row"
        sx={{
          backgroundColor: "Background.paper",
          padding: "10px",
          borderBottomColor: "#e8e8e8",
          borderBottomWidth: 0.2,
          borderBottomStyle: "solid",
          overflow: "scroll"
        }}
        gap={3}
      // spacing={4}
      >
        {tabs.map((i) => {
          return (
            <Button
              // variant={"text"}
              startIcon={i.icon}
              // variant={selectedTab !== i.value ? "outlined" : "contained"}
              sx={{
                px: "12px",
                minWidth: "auto",
                backgroundColor:
                  selectedTab === i.value ? "#e6e6e6" : "transparent",
                color: "#000",

                // color: selectedTab === i.value ? "primary.main" : "#000",
                "&:hover": {
                  backgroundColor:
                    selectedTab === i.value ? "#e6e6e6" : "transparent",
                },
              }}
              onClick={() => setSelectedTab(i.value)}
            >
              {i.label}
            </Button>
          );
        })}
      </Stack>
      <Box sx={{ p: 3, pt: 4 }}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={4} lg={3}>
            <AnalyticsCard
              title="Total Messages"
              value={getPlatformMessages(selectedTab).length}
            />
          </Grid>
          <Grid item xs={12} md={4} lg={3}>
            <AnalyticsCard
              title="Total Chats sent"
              value={getSentMessage(selectedTab).length}
            />
          </Grid>
          {selectedTab === "whatsapp" && (
            <Grid item xs={12} md={4} lg={3}>
              <AnalyticsCard
                title="Total Templates sent"
                value={getTemplateMessages().length}
              />
            </Grid>
          )}
          <Grid item xs={12} md={4} lg={3}>
            <AnalyticsCard
              title={`Total ${selectedTab} Users`}
              value={getPlatformUser(selectedTab)}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default MetaAnalytics;
