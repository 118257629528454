import { Paper, Popper } from "@material-ui/core";
import { HelpOutline } from "@mui/icons-material";
import {
  ClickAwayListener,
  IconButton,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";

const PopperTooltip = ({ points, children }) => {
  const theme = useTheme();
  const isIpad = useMediaQuery(theme.breakpoints.down("md"));
  const [tooltipProps, setToolTipsProps] = useState({});
  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    if (isIpad) {
      setToolTipsProps({
        open,
        onClick: () => setOpen((pre) => !pre),
      });
    }
  }, [isIpad, open]);

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <Tooltip
        arrow
        PopperProps={{
          disablePortal: true,
        }}
        onClose={handleTooltipClose}
        open={open}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        // onClose={handleTooltipClose}
        //   PopperComponent={Popper}
        //   PopperProps={}
        title={
          <div>
            <ul style={{ listStyle: "none" }}>
              {points.map((str) => (
                <li>
                  <Typography sx={{ fontSize: "13px" }}>{str}</Typography>
                </li>
              ))}
            </ul>
          </div>
        }
      >
        <IconButton
          onClick={() => setOpen((pre) => !pre)}
          onMouseEnter={() => setOpen(true)}
          onMouseLeave={() => setOpen(false)}
        >
          {children ? (
            children
          ) : (
            <HelpOutline
              onClick={() => tooltipProps?.onClick && tooltipProps?.onClick()}
              fontSize="small"
              style={{ opacity: "0.5", height: "16px", width: "16px" }}
              color="text.secondary"
            />
          )}
        </IconButton>
      </Tooltip>
    </ClickAwayListener>
  );
};

export default PopperTooltip;
