import { CalendarTodayOutlined } from "@material-ui/icons";
import {
  ArrowForward,
  CalendarToday,
  East,
  LocationOn,
  LocationOnOutlined,
  Store,
  Storefront,
  Visibility,
} from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  Skeleton,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getRandomAvatar } from "..";
import { sidebarExtraWidth } from "../../../App";
import {
  formatDate,
  getRandomRangeNumbers,
  globalCardStyle,
  isArrayWithValues,
  isObjWithValues,
  primaryLabel,
  reduceLargeText,
  secondaryLabel,
  sectionLabel,
} from "../../../helper";
import { drawerWidth } from "../../../layouts/Dashboard";
import { fetchRecentCustomers } from "../../../redux/actions/crmActions";
import { InstagramSvg, Messenger, Whatsapp } from "../../../Svgs";

function ConnectedSuppliersAvatars({}) {
  //   const recentCustomers =
  //     useSelector((state) => state.customers.recentCustomers) || [];
  const navigate = useNavigate();
  const [recentCustomers, setRecentCustomers] = useState([]);
  const fetchingRecentCustomers = useSelector(
    (state) => state.customers.fetchingRecentCustomers
  );
  const allSuppliers =
    useSelector((state) => state.supplier.allSuppliers) || [];
  const theme = useTheme();
  const isIpad = useMediaQuery(theme.breakpoints.down("md"));

  const dashboardDetails = useSelector(
    (state) => state.settings.dashboardDetails
  );
  const fetchingDashboardDetails = useSelector(
    (state) => state.settings.fetchingDashboardDetails
  );

  const dispatch = useDispatch();
  useEffect(() => {
    if (!isObjWithValues(dashboardDetails)) return;
    let { contacts, products } = dashboardDetails;
    console.log(products?.top_products);
    if (isArrayWithValues(products?.top_products))
      setRecentCustomers(products?.top_products);
  }, [dashboardDetails]);
  return (
    <Card sx={{ borderRadius: "15px", height: "100%", ...globalCardStyle }}>
      <CardContent>
        <Stack
          direction={"row"}
          alignItems="center"
          justifyContent={"space-between"}
          mb={3}
          flexWrap="wrap"
        >
          <Typography variant="h6">
            You are connected to{" "}
            <Typography sx={{ color: "info.main", display: "initial" }}>
              {allSuppliers?.filter((obj) => obj?.status === "connected")
                ?.length || 0}{" "}
              Suppliers
            </Typography>
          </Typography>
          <Button
            size="small"
            endIcon={<East />}
            onClick={() => navigate("/design-bank/suppliers")}
          >
            See all suppliers
          </Button>
        </Stack>
        {/* <Typography sx={{ ...secondaryLabel, mt: 1 }}>
            You have 7 new customers
          </Typography> */}
        {fetchingRecentCustomers ? (
          <>
            {new Array(5).fill("").map((str, index) => (
              <LoadingRecentCustomer key={index} />
            ))}
          </>
        ) : null}
        <Stack
          direction="row"
          overflow={"scroll"}
          className="scrollbar-hidden"
          //   spacing={3}
          sx={{
            maxWidth: !isIpad ? `calc(100vw - ${sidebarExtraWidth}px)` : "100%",
          }}
        >
          {allSuppliers
            ?.filter((obj) => obj?.status === "connected")
            .map((customer) => (
              <CustomerCard customer={customer} />
            ))}
        </Stack>
      </CardContent>
    </Card>
  );
}

export default ConnectedSuppliersAvatars;

const CustomerCard = ({ customer }) => {
  const [customerInfo, setCustomerInfo] = useState(customer);
  let fullName = `${customer?.first_name || ""} ${customer?.last_name || ""}`;
  const navigate = useNavigate();
  useEffect(() => {
    let { meta_data } = customer;
    let customerObj = { ...customer };

    const metaKeys = [
      "whatsapp",
      "profile_url",
      "whatsapp_id",
      "facebook_id",
      "instagram_id",
      "wc_last_active",
    ];
    if (isArrayWithValues(meta_data)) {
      for (let metaObj of meta_data) {
        if (metaKeys.includes(metaObj.key) && metaObj.value)
          customerObj[metaObj.key] = metaObj.value;
      }
    }
    setCustomerInfo(customerObj);
    // setCustomerInfoObj(customer)
  }, []);

  return (
    <Stack
      direction={"row"}
      sx={{
        mt: 5,
        mb: 5,
        mx: 3,
        minWidth: "120px",
        textAlign: "center",
        // alignItems: "center",
      }}
      // sx={{ textAlign: "center", justifyContent: "center" }}
      gap={"8px"}
    >
      <Box
        sx={{
          textAlign: "center",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Avatar
          src={customer?.details?.store_logo}
          //   sx={{ height: "150px", width: "150px" }}
          sx={{ width: 80, height: 80, margin: "auto", marginTop: 0 }}
        />
        <Typography sx={{ ...primaryLabel, mt: 2 }}>
          {/* {reduceLargeText(`${customer.name || ""}`, 12)} */}
          {customer?.contact_name}
        </Typography>
        {/* <Typography sx={{ ...secondaryLabel, mt: 1 }}>
          {reduceLargeText(`${customer.sku || ""}`, 12)}
        </Typography> */}
        <Stack direction="row" alignItems={"center"} justifyContent="center">
          <LocationOnOutlined
            style={{ fontSize: "13px", mr: 1, color: "info.main" }}
          />
          <Typography sx={{ ...secondaryLabel, ml: 1 }}>
            {customer?.address?.city}
          </Typography>
        </Stack>
        <Typography
          sx={{
            ...primaryLabel,
            mt: 1,
            color: "info.main",
            textAlign: "center",
            fontSize: "13px",
          }}
        >
          {/* {reduceLargeText(`${customer.name || ""}`, 12)} */}
          Gold Jewellery
        </Typography>
        <Stack direction="row" alignItems={"center"} justifyContent="center">
          <CalendarTodayOutlined
            style={{ fontSize: "13px", mr: 1, color: "info.main" }}
          />
          <Typography sx={{ ...secondaryLabel, ml: 1 }}>Jan 2023</Typography>
        </Stack>
      </Box>
      {/* <Stack sx={{ width: "-webkit-fill-available" }}>
            <Stack direction="row" alignItems={"center"}>
              <Typography fontSize={"16px"} fontWeight={"500"}>
                {fullName}
              </Typography>
              <Box sx={{ ml: 2 }}>
                {customerInfo?.facebook_id ? (
                  <Messenger height={17} width={17} />
                ) : customerInfo?.instagram_id ? (
                  <InstagramSvg height={17} width={17} />
                ) : customerInfo?.whatsapp_id ? (
                  <Whatsapp height={17} width={17} />
                ) : (
                  <Storefront style={{ height: "17px", width: "17px" }} />
                )}
              </Box>
            </Stack>
            <Stack
              direction={"row"}
              alignItems="center"
              justifyContent={"space-between"}
              sx={{ width: "80%" }}
            >
              <Typography sx={{ fontSize: "13px", color: "text.secondary" }}>
                Last active :{" "}
                {customerInfo?.wc_last_active
                  ? formatDate(
                      new Date(Number(customerInfo?.wc_last_active) * 1000),
                      "dd:mm:yyyy hh:mm"
                    )
                  : ""}
              </Typography>
            </Stack>
          </Stack>
          <IconButton onClick={() => navigate(`/crm/${customerInfo?.id}`)}>
            <ArrowForward />
          </IconButton> */}
    </Stack>
  );
};

const LoadingRecentCustomer = () => {
  return (
    <Stack
      direction={"row"}
      sx={{ mt: 5, mb: 5 }}
      // sx={{ textAlign: "center", justifyContent: "center" }}
      gap={"8px"}
    >
      <Skeleton variant="circular" width={40} height={40} />

      <Stack sx={{ width: "-webkit-fill-available" }}>
        <Skeleton variant="text" sx={{ fontSize: "1rem", width: "180px" }} />

        <Stack
          direction={"row"}
          alignItems="center"
          justifyContent={"space-between"}
          sx={{ width: "80%" }}
        >
          <Skeleton variant="text" sx={{ fontSize: "1rem", width: "100px" }} />
          <Skeleton variant="circular" width={25} height={25} />
        </Stack>
      </Stack>
    </Stack>
  );
};
