import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import {
  fetchQuotationProducts,
  getAllQuotation,
  paymentMethods,
  updateQuotation,
} from "../../redux/actions/productActions";
import { useDispatch, useSelector } from "react-redux";
import {
  TabPanel,
  createOrder,
  dialogStyle,
  formatDate,
  getAxiosError,
  getId,
  getRandomId,
  getRandomString,
  getToken,
  getWebsite,
  isArrayWithValues,
  isObjWithValues,
  primaryLabel,
  primaryLabelLarge,
  secondaryLabel,
  validateNumber,
} from "../../helper";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Breadcrumbs,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  Link,
  MenuItem,
  Select,
  Skeleton,
  Stack,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
} from "@mui/material";
import {
  Add,
  ArrowBackIos,
  OpenInNew,
  ShoppingCartOutlined,
} from "@mui/icons-material";
import { DashboardTotalCard } from "../dashboards/Default";
import QuotationProductCard from "./components/QuotationProductCard";
import {
  setupCategorySummary,
  setupProductsSummary,
  setupQuotationProduct,
} from "./quotationHelper";
import { getAllTaxes } from "../../redux/actions/settingActions";
import { currencySymbols } from "../pricing/Gold";
import ProductsSummary from "./components/ProductsSummary";
import CategorySummary from "./components/CategorySummary";
import SelectProducts from "../components/SelectProducts";
import axios from "axios";
import { LoadingButton } from "@mui/lab";
import { setGlobalToast } from "../../redux/actions/helperActions";
import QuotationComments from "./components/QuotationComments";

function QuotationViewNew() {
  const [quotation, setQuotation] = useState({});
  const [currentTaxRate, setCurrentTaxRate] = useState("");
  const allQuotations = useSelector((state) => state.product.quotations);
  const [selectedTab, setSelectedTab] = useState("summary");
  const [quotationLoading, setQuotationLoading] = useState(false);

  const [masterPricing, setMasterPricing] = useState({});
  const reduxMasterPricing = useSelector(
    (state) => state.settings.masterPricing
  );
  const customerMasterPricing = useSelector(
    (state) => state.settings.customerMasterPricing
  );

  const allDiamondGroups = useSelector(
    (state) => state.settings.allDiamondGroups
  );
  const productSettings = useSelector(
    (state) => state.settings.productSettings
  );
  const allTaxes = useSelector((state) => state.settings.allTaxes);
  const allSettingsFetched = useSelector(
    (state) => state.settings.allSettingsFetched
  );
  const isAdmin = useSelector((state) => state.user.isAdmin);
  const user = useSelector((state) => state.user);

  const createOrderRef = useRef();

  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    if (isObjWithValues(reduxMasterPricing)) {
      if (isObjWithValues(customerMasterPricing)) {
        setMasterPricing(
          convertMasterPricing({
            masterPricing: reduxMasterPricing,
            customerMasterPricing,
            customer: quotation?.customer,
          })
        );
      } else setMasterPricing(reduxMasterPricing);
    }
  }, [reduxMasterPricing, customerMasterPricing, quotation?.customer]);

  useEffect(() => {
    if (!isArrayWithValues(allTaxes)) dispatch(getAllTaxes());

    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!isArrayWithValues(allTaxes)) return;
    setCurrentTaxRate(Math.max(...allTaxes.map((o) => validateNumber(o.rate))));
  }, [allTaxes]);

  useEffect(() => {
    if (!quotation.productsFetched)
      dispatch(fetchQuotationProducts({ quotation }));
  }, [quotation]);

  useEffect(() => {
    if (
      masterPricing &&
      productSettings &&
      allSettingsFetched &&
      quotation?.productsFetched
    ) {
      let obj = { ...quotation };
      let { productDetails, customization } = obj;
      let updated = [];
      for (let i of productDetails) {
        let updatedProduct = setupQuotationProduct({
          product: i,
          master_pricing: masterPricing,
          diamond_groups: allDiamondGroups,
          product_settings: productSettings,
          tax_rate: currentTaxRate,
          customization,
        });
        let productCustomization = customization[i?.id];
        console.log(
          i.selectedCustomization,
          productCustomization,
          "<<<<<<asdf"
        );
        if (!i.selectedCustomization && isArrayWithValues(productCustomization))
          updatedProduct.selectedCustomization = productCustomization?.[0]?.id;
        updated.push(updatedProduct);
      }
      obj.productDetails = updated;
      obj.productsSummary = setupProductsSummary({
        quotation,
        master_pricing: masterPricing,
        diamond_groups: allDiamondGroups,
        product_settings: productSettings,
        tax_rate: currentTaxRate,
      });
      obj.categorySummary = setupCategorySummary({
        quotation,
      });
      setQuotation(obj);
      // console.log(obj.productsSummary, "<<<<< category summary");
      console.log("product details updated");
    }
  }, [
    quotation?.productsFetched,
    quotation?.customization,
    quotation?.products,
    allSettingsFetched,
    currentTaxRate,
    allQuotations,
  ]);
  useEffect(() => {
    if (!allQuotations) dispatch(getAllQuotation());
  }, []);
  useEffect(() => {
    let pieces = location.pathname.split("/");
    let quotationId = pieces[pieces.length - 1];
    if (isArrayWithValues(allQuotations)) {
      let obj = allQuotations.find((i) => i.id === Number(quotationId));
      // obj.products = [55593, 55600];
      // obj.customer.currency = "INR";
      obj.fetched = true;
      if (!obj.customize) {
        obj.customize = {};
        obj.customize.sizes = {};
      }
      console.log(
        obj && quotation?.id !== obj.id,
        obj,
        "<<<< allquotatin use effect"
      );
      if (obj && quotation?.id !== obj.id) {
        setQuotation(obj);
        // quotationBackupRef.current = Object.assign({ ...obj });
        // setQuotationBackup({ ...obj2 });
        // dispatch(
        //   setProductData({
        //     quotationBackup: Object.assign({ ...obj }),
        //   })
        // );
        // if (!isObjWithValues(quotationBackup)) {
        //   let obj2 = { ...obj };
        // }
      }
    }
    //eslint-disable-next-line
  }, [location.pathname, allQuotations]);

  const onCreateOrderPress = () => {
    createOrderRef.current?.open();
  };

  const updateQuotation = async ({
    payload = {},
    product_id,
    setAddingCustomization,
    success_message,
    error_message,
    activity,
  }) => {
    try {
      let {
        title,
        products,
        comments,
        product_comments,
        customer,
        customization,
        activities = [],
      } = payload || quotation;
      let obj = {};
      obj = {
        type: "edit",
        id: quotation.id,
        data: {
          title,
          products: products.join(),
          customer: customer.ID,
          comments,
          product_comments,
          customization,
        },
      };
      if (payload?.is_order_created)
        obj.data.is_order_created = payload?.is_order_created;
      if (isObjWithValues(activity)) {
        let added_by = "";
        let added_by_id = "";
        if (isAdmin) {
          added_by = "admin";
        } else {
          added_by = `${user?.first_name} ${user?.last_name}`;
        }
        added_by_id = getId();
        if (!isArrayWithValues(activities)) activities = [];
        activities.push({
          ...activity,
          user_name: added_by,
          user_id: added_by_id,
        });
        obj.data.activities = activities;
      }
      setQuotationLoading(true);
      let website = await getWebsite();
      let token = await getToken();
      let res = await axios({
        url: `${website}/wp-json/store/v1/quotations`,
        method: "POST",
        headers: {
          Authorization: `Basic ${token}`,
        },
        data: obj,
      });
      setQuotationLoading(false);
      if (res.data.success) {
        setQuotation((state) => ({ ...state, ...payload }));
        dispatch(
          setGlobalToast({
            show: true,
            message: success_message || "Quotation has been updated",
            severity: "success",
          })
        );
        return true;
      }
      throw new Error();
    } catch (e) {
      console.log(getAxiosError(e));
      setQuotationLoading(false);
      dispatch(
        setGlobalToast({
          show: true,
          message: error_message || "Couldn't update quotation",
          severity: "error",
        })
      );
      return false;
    }
  };

  return (
    <Box sx={{ px: 2 }}>
      <QuotationTitle
        quotation={quotation}
        onCreateOrderPress={onCreateOrderPress}
      />
      <QuotationOverview quotation={quotation} />
      <ProductsView
        quotation={quotation}
        setQuotation={setQuotation}
        updateQuotation={updateQuotation}
        quotationLoading={quotationLoading}
      />
      <ProductsSummary quotation={quotation} setQuotation={setQuotation} />
      <CreateOrderSummary
        quotation={quotation}
        ref={createOrderRef}
        updateQuotation={updateQuotation}
      />
      <Box sx={{ borderBottom: 1, mt: 3, borderColor: "divider" }}>
        <Tabs
          value={selectedTab}
          onChange={(a, e) => setSelectedTab(e)}
          aria-label="basic tabs example"
        >
          <Tab label="Category Summary" value="summary" />
          <Tab label="Quotation Comments" value="comments" />
        </Tabs>
      </Box>
      <TabPanel value={selectedTab} index={"summary"}>
        <CategorySummary quotation={quotation} />
      </TabPanel>
      <TabPanel value={selectedTab} index={"comments"}>
        <QuotationComments quotation={quotation} setQuotation={setQuotation} />
      </TabPanel>
    </Box>
  );
}

export default QuotationViewNew;

const CreateOrderSummary = forwardRef(
  ({ quotation = {}, updateQuotation }, ref) => {
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const default_currency = useSelector(
      (state) => state.settings.productSettings
    )?.default_currency;
    const paymentsMethods = useSelector((state) => state.product.PaymentMethod);
    const [form, setForm] = useState({
      payment_method: "",
      paid: false,
    });

    const editForm = (payload) =>
      setForm((state) => ({ ...state, ...payload }));

    const dispatch = useDispatch();

    useEffect(() => {
      if (!paymentsMethods) dispatch(paymentMethods());
    }, []);

    useImperativeHandle(ref, () => {
      return {
        open() {
          handleClickOpen();
        },
      };
    });

    const handleClickOpen = () => {
      setOpen(true);
    };

    const handleClose = () => {
      setOpen(false);
    };

    const onCreateOrder = async () => {
      let products = quotation?.productDetails;
      let { customization } = quotation;
      let { paid, payment_method } = form;
      let methodObj = paymentsMethods.find((i) => i.value === payment_method);
      let line_items = [];
      if (!isArrayWithValues(products)) return;
      if (!isObjWithValues(customization)) return;
      for (let product of products) {
        let { lineItem } = product;
        line_items = line_items?.concat(lineItem);
      }
      let orderObj = {
        customer_id: quotation?.customer?.ID,
        payment_method: methodObj?.value || "cod",
        payment_method_title: methodObj?.label || "",
        // date_paid: getDate(addForm.date_paid),
        status: paid ? "completed" : "pending",
        // line_items: products?.map((i) => ({
        //   product_id: i.id,
        //   quantity: 1,
        // })),
        line_items,
        // line_items: [
        //   {
        //     product_id: 163,
        //     quantity: 1,
        //   },
        //   {
        //     product_id: 163,
        //     quantity: 1,
        //     total: "3000",
        //     subtotal: "3000",
        //     price: "2980",
        //   },
        //   {
        //     product_id: 130,
        //     quantity: 1,
        //     total: "1000",
        //     subtotal: "1000",
        //     price: "980",
        //   },
        //   {
        //     product_id: 126,
        //     quantity: 1,
        //   },
        // ],
      };
      console.log(orderObj);
      setLoading(true);
      let res = await createOrder(orderObj);
      await updateQuotation({
        payload: {
          ...quotation,
          is_order_created: res?.id,
        },
      });
      if (isObjWithValues(res)) {
        console.log(res, "create order res");
        dispatch(
          setGlobalToast({
            show: true,
            message: "Order created successfully",
            severity: "success",
          })
        );
        handleClose();
      } else
        dispatch(
          setGlobalToast({
            show: true,
            message: "Couldn't create order! Try again",
            severity: "error",
          })
        );
      setLoading(false);
    };

    let { productDetails } = quotation;
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{ ...dialogStyle }}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="alert-dialog-title">{"Create order"}</DialogTitle>
        <DialogContent>
          <Typography sx={{ ...primaryLabel }}>
            Select payment method
          </Typography>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={form.payment_method}
            // label="payment_method of Payment"
            fullWidth
            onChange={(e) => editForm({ payment_method: e.target.value })}
          >
            {isArrayWithValues(paymentsMethods) &&
              paymentsMethods.map((i) => {
                return (
                  <MenuItem key={i.value} value={i.value}>
                    {i.label === "Cash on delivery" ? "Cash" : i.label}
                  </MenuItem>
                );
              })}
          </Select>
          <FormControlLabel
            required
            control={
              <Checkbox
                checked={form.paid}
                onChange={(e) => editForm({ paid: e.target.checked })}
              />
            }
            sx={{ mt: 1 }}
            label="Make payment as paid"
          />
          <Typography sx={{ ...primaryLabel, mt: 1 }}>Products</Typography>
          {productDetails?.map((i) => {
            return (
              <Stack direction="row" spacing={3} mt={2} alignItems="center">
                <img
                  src={
                    i.images?.[0]?.src ||
                    "/static/img/woocommerce_placeholder.png"
                  }
                  style={{ height: "50px", width: "50px" }}
                />
                <Box>
                  <Typography sx={{ ...primaryLabel }}>
                    {i.name || ""}
                  </Typography>
                  <Typography sx={{ ...secondaryLabel, mt: 1 }}>
                    {currencySymbols?.[default_currency]}{" "}
                    {i?.priceBreakup?.price} x {i.totalQuantity} ={" "}
                    <strong>
                      {currencySymbols?.[default_currency]}{" "}
                      {i?.totalProductPrice}
                    </strong>
                  </Typography>
                </Box>
              </Stack>
            );
          })}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <LoadingButton
            loading={loading}
            onClick={onCreateOrder}
            variant="outlined"
            autoFocus
          >
            Create Order
          </LoadingButton>
        </DialogActions>
      </Dialog>
    );
  }
);

const ProductsView = ({
  quotation,
  setQuotation,
  updateQuotation,
  quotationLoading,
}) => {
  const [showAddProduct, setShowAddProduct] = useState(false);
  const closeProductSelection = () => setShowAddProduct(false);
  const closeAddProducts = () => setShowAddProduct(false);
  const openAddProducts = () => setShowAddProduct(true);
  const [addingProducts, setAddingProducts] = useState(false);

  const onProductSelect = async (products) => {
    console.log(products);
    let new_customization = {};
    for (let product of products) {
      let obj = { ...product };
      let { meta_data } = product;
      if (isArrayWithValues(meta_data))
        for (let metaObj of meta_data) obj[metaObj.key] = metaObj.value;

      let { gold_kt, diamond, metal_color, id, subcatsetting } = obj;
      new_customization[id] = [
        {
          metal_purity: gold_kt,
          metal_color,
          id: getRandomId(),
          size: subcatsetting?.[0] || "",
          diamond_quality: diamond?.[1]?.["diamond_quality"],
          diamond_type: diamond?.[1]?.["diamond_type"],
          quantity: 1,
        },
      ];
    }
    setAddingProducts(true);
    closeAddProducts();
    console.log([
      ...(products?.map((i) => i.id) || []),
      ...quotation?.products,
    ]);
    await updateQuotation({
      payload: {
        ...quotation,
        productDetails: [...products, ...quotation?.productDetails],
        products: [
          ...(products?.map((i) => `${i.id}`) || []),
          ...quotation?.products,
        ],
        customization: { ...quotation?.customization, ...new_customization },
        productsFetched: getRandomString(10),
      },
      activity: {
        id: getRandomId(),
        products: products?.map((i) => i.id),
        type: "products_added",
      },
    });
    setAddingProducts(false);
    // setQuotation((state) => ({
    // ...state,
    // productDetails: [...products, ...state?.productDetails],
    // customization: { ...state.customization, ...new_customization },
    // productsFetched: getRandomString(10),
    // }));
  };

  let { productDetails, products, productsFetched } = quotation;

  useEffect(() => {}, [quotation]);
  console.log(
    productDetails?.map((i) => i.id),
    "<<<< showing productDetails"
  );
  return (
    <Box sx={{ mt: 5 }}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent={"space-between"}
      >
        <Box>
          <Typography sx={{ ...primaryLabelLarge }}>Products</Typography>
          <Typography sx={{ ...secondaryLabel, mt: 1 }}>
            Showing {quotation?.products?.length} products
          </Typography>
        </Box>
        <Stack
          direction="row"
          alignItems="center"
          // justifyContent={"space-between"}
          spacing={3}
        >
          <LoadingButton
            loading={quotationLoading}
            variant="contained"
            // startIcon={<Add />}
            onClick={updateQuotation}
          >
            Update Quotation
          </LoadingButton>
          <LoadingButton
            variant="outlined"
            startIcon={<Add />}
            onClick={openAddProducts}
            loading={addingProducts}
          >
            Add Product
          </LoadingButton>
        </Stack>
      </Stack>
      <Grid container spacing={2} sx={{ mt: 1 }}>
        {isArrayWithValues(productDetails) &&
          productDetails?.map((i) => {
            return (
              <Grid item xs={12} md={6} key={i.id}>
                <QuotationProductCard
                  product={i}
                  quotation={quotation}
                  setQuotation={setQuotation}
                  updateQuotation={updateQuotation}
                />
              </Grid>
            );
          })}
      </Grid>
      <Grid container spacing={2} sx={{ mt: 1 }}>
        {isArrayWithValues(products) &&
          !productsFetched &&
          products?.map((i) => {
            return (
              <Grid item xs={12} md={6} key={i}>
                <Skeleton
                  width={"100%"}
                  sx={{
                    borderRadius: "15px",
                    "-webkit-transform": "scale(1)",
                    transform: "scale(1)",
                    // mb: skeletonSpacing,
                  }}
                  height={"350px"}
                />
                {/* <QuotationProductCard
                  product={i}
                  quotation={quotation}
                  setQuotation={setQuotation}
                /> */}
              </Grid>
            );
          })}
      </Grid>

      <Dialog
        open={showAddProduct}
        onClose={closeAddProducts}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={dialogStyle}
      >
        <SelectProducts
          onClose={closeProductSelection}
          onSelect={onProductSelect}
          onlyImage
          title={"Add Products in Quotation"}
          disabledProducts={quotation?.productDetails?.map((i) => i.id)}
        />
      </Dialog>
    </Box>
  );
};

const QuotationTitle = ({ quotation, onCreateOrderPress }) => {
  const navigate = useNavigate();
  const isIpad = useMediaQuery((theme) => theme.breakpoints.down("md"));
  let { is_order_created } = quotation;
  const onCheckOrder = () => {
    navigate(`/orders/${is_order_created}`);
  };
  return (
    <Grid
      justifyContent="space-between"
      alignItems={"center"}
      container
      spacing={2}
      p={isIpad ? 3 : 6}
      px={isIpad ? 1 : 3}
    >
      <Grid item>
        <Stack direction="row" alignItems={"center"}>
          <IconButton size="small" onClick={() => navigate(-1)}>
            <ArrowBackIos />
          </IconButton>
          <Box>
            <Typography variant="h5" gutterBottom display="inline">
              {quotation.title}
            </Typography>
            <Breadcrumbs aria-label="Breadcrumb" mt={2}>
              <Link component={NavLink} to="/">
                Dashboard
              </Link>
              <Link component={NavLink} to="/quotations">
                Quotations
              </Link>
              <Typography>{quotation.title}</Typography>
            </Breadcrumbs>
          </Box>
        </Stack>
      </Grid>
      <Grid item sx={{ marginLeft: "auto" }}>
        {is_order_created ? (
          <Button
            onClick={() => onCheckOrder()}
            endIcon={<OpenInNew />}
            variant="outlined"
          >
            Check Order
          </Button>
        ) : (
          <Button
            onClick={() => onCreateOrderPress()}
            endIcon={<ShoppingCartOutlined />}
            variant="outlined"
          >
            create order
          </Button>
        )}
      </Grid>
    </Grid>
  );
};

const QuotationOverview = ({ quotation = {} }) => {
  let { customer } = quotation;
  return (
    <Box sx={{ mt: 3 }}>
      <Stack
        direction="row"
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Typography sx={{ ...primaryLabelLarge }}>Overview</Typography>
        <Stack direction="row" alignItems="center">
          <Typography sx={{ ...secondaryLabel }}>
            Created on:{" "}
            {formatDate(new Date(quotation?.created_at), "dd-mm-yyyy")}
          </Typography>
          <IconButton>
            <OpenInNew />
          </IconButton>
        </Stack>
      </Stack>
      <Grid container spacing={3} sx={{ mt: 1 }}>
        <Grid item xs={6} sm={4} md={3} lg={2}>
          <DashboardTotalCard
            hideNavigate
            hideGraph
            title="Sent to"
            value={`${customer?.first_name || ""} ${customer?.last_name || ""}`}
            subtitle2={`${
              customer?.company_name ? `${customer?.company_name},` : ""
            } ${customer?.city || customer?.billing?.city || ""}`}
            {...topCardsExtraProps}
          />
        </Grid>
        <Grid item xs={6} sm={4} md={3} lg={2}>
          <DashboardTotalCard
            hideNavigate
            hideGraph
            {...topCardsExtraProps}
            title="Status"
            value="Unopened"
          />
        </Grid>
        <Grid item xs={6} sm={4} md={3} lg={2}>
          <DashboardTotalCard
            hideNavigate
            hideGraph
            {...topCardsExtraProps}
            title="Products Sent"
            value={"4/2"}
          />
        </Grid>
        <Grid item xs={6} sm={4} md={3} lg={2}>
          <DashboardTotalCard
            hideNavigate
            hideGraph
            {...topCardsExtraProps}
            title="Response status"
          />
        </Grid>
        <Grid item xs={6} sm={4} md={3} lg={2}>
          <DashboardTotalCard
            hideNavigate
            hideGraph
            {...topCardsExtraProps}
            title="Time spent"
          />
        </Grid>
        <Grid item xs={6} sm={4} md={3} lg={2}>
          <DashboardTotalCard
            hideNavigate
            hideGraph
            {...topCardsExtraProps}
            title="Visits"
          />
        </Grid>
      </Grid>
    </Box>
  );
};

const topCardsExtraProps = {
  valueContainerStyle: { my: 1, mb: 0 },
  labelStyle: { fontSize: "15px" },
  valueProps: {
    sx: { fontSize: "16px", color: "text.primary", fontWeight: "500" },
  },
};

const convertMasterPricing = ({
  customerMasterPricing = {},
  masterPricing = {},
  customer = {},
}) => {
  let id = customer?.id || customer?.ID;
  let { customers } = customerMasterPricing;
  if (!customers?.[id]) return masterPricing;
  let { pricing_type } = customers?.[id];
  if (pricing_type !== "custom") return masterPricing;

  let singleCustomerPricing = customers?.[id];
  let newMasterPricing = { ...masterPricing };

  let { additional_pricing } = masterPricing;

  for (let currency in additional_pricing) {
    let masterRate = additional_pricing[currency];
    let customerRate = singleCustomerPricing?.additional_pricing?.[currency];
    if (customerRate)
      newMasterPricing.additional_pricing[currency] = customerRate;
    else newMasterPricing.additional_pricing[currency] = masterRate;
  }

  let pricingKeys = ["diamond_pricing", "gemstone_pricing", "labour_pricing"];
  for (let pricingKey of pricingKeys) {
    if (!newMasterPricing[pricingKey]) newMasterPricing[pricingKey] = {};
    let pricing = masterPricing[pricingKey];
    if (!isObjWithValues(pricing)) continue;
    for (let currency in pricing) {
      if (!newMasterPricing[pricingKey]?.[currency])
        newMasterPricing[pricingKey][currency] = [];

      let array = [...(pricing[currency] || [])];
      if (!isArrayWithValues(array)) continue;
      for (let combination of array) {
        let { id } = combination;
        let customerRate = singleCustomerPricing[pricingKey]?.[currency]?.find(
          (i) => i.id == id
        )?.rate;
        newMasterPricing[pricingKey][currency] = newMasterPricing[pricingKey][
          currency
        ]?.map((i) => {
          if (i.id == id && customerRate) return { ...i, rate: customerRate };
          else return i;
        });
      }
    }
  }
  return newMasterPricing;
};
