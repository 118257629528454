/* eslint-disable react-hooks/exhaustive-deps */
import {
    Avatar,
    Badge,
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Radio,
    Stack,
    Tab,
    Tabs,
    TextField,
} from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { memo } from "react";
import axios from "axios";
import { useMemo } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { InstagramSvg, Messenger, Whatsapp } from "../../../Svgs";
import {
    getRandomString,
    getToken,
    getWebsite,
    isObjWithValues,
} from "../../../helper";
import { LoadingButton } from "@mui/lab";
import {
    EDIT_CHATS,
    putMergeProfiles,
} from "../../../redux/actions/chatActions";
const svgObj = {
    whatsapp: <Whatsapp style={{ height: "15px", width: "15px" }} />,
    facebook: <Messenger style={{ height: "15px", width: "15px" }} />,
    instagram: <InstagramSvg style={{ height: "15px", width: "15px" }} />,
};

function getFullName(obj) {
    return `${obj?.first_name || ""} ${obj?.last_name || ""} ${obj?.name || ""}`;
}

let tabs = [
    { label: "Whatsapp", value: "whatsapp" },
    { label: "Facebook", value: "facebook" },
    { label: "Instagram", value: "instagram" },
];
const ConnectChannel = memo(({ customer, onClose, merge_profiles }) => {
    const allReduxConversations = useSelector(
        (state) => state.chats.conversations
    );
    const dispatch = useDispatch();
    const [selectedTab, setSelectedTab] = useState("Whatsapp");
    const [filteredTabs, setFilterTabs] = useState(tabs);
    const [conversation, setConversations] = useState([]);
    const [selectedCustomer, setSelectedCustomer] = useState({});
    const [searchValue, setSearchValue] = useState("");
    const [loading, setLoading] = useState(false);
    console.log(customer);
    useMemo(() => {
        setConversations(
            allReduxConversations?.filter((obj) => obj?.platform === selectedTab) ||
            []
        );
        console.log(
            allReduxConversations?.filter((obj) => obj?.platform === selectedTab)
        );
    }, [selectedTab]);
    useMemo(() => {
        if (searchValue) {
            setConversations(
                allReduxConversations
                    ?.filter((obj) => obj?.platform === selectedTab)
                    ?.filter((obj) =>
                        getFullName(obj)
                            ?.toLowerCase()
                            ?.includes(searchValue?.toLowerCase())
                    )
            );
        } else {
            setConversations(
                allReduxConversations?.filter((obj) => obj?.platform === selectedTab) ||
                []
            );
        }
    }, [searchValue]);
    useEffect(() => {
        setSelectedTab(customer?.platform === "whatsapp" ? "facebook" : "whatsapp");
    }, [customer]);

    const onAdd = async () => {
        // setLoading(true);
        let id = `${Date.now()}_${getRandomString(5)}`;

        let data = {
            [id]: {
                id,
                profiles: [
                    {
                        id: customer?.id,
                        platform: customer?.platform,
                    },
                    ...Object?.keys(selectedCustomer || {}).map((key) => ({
                        id: selectedCustomer?.[key],
                        platform: key,
                    })),
                ],
            },
        };
        // if (isObjWithValues(customer?.merge_profiles)) {

        //     data = {
        //         [customer?.merge_profiles?.id]: {
        //             ...customer?.merge_profiles,
        //             crm_id: selectedCustomer?.id,
        //         },
        //     };
        if (isObjWithValues(merge_profiles)) {
            data = {
                [merge_profiles?.id]: {
                    ...merge_profiles,
                    profiles: [
                        ...(merge_profiles.profiles || []),
                        ...Object?.keys(selectedCustomer || {}).map((key) => ({
                            id: selectedCustomer?.[key],
                            platform: key,
                        })),
                    ],
                },
            };
        }
        console.log(data);
        // return
        await dispatch(
            putMergeProfiles(data, setLoading, () => {
                console.log("sucess");
                onClose();
            })
        );
    };

    useEffect(() => {
        let filteredTabsNew = tabs?.filter(
            (o) =>
                o.value !== customer?.platform &&
                !merge_profiles?.profiles?.map((v) => v?.platform)?.includes(o.value)
        );
        setFilterTabs(filteredTabsNew);
    }, [merge_profiles]);

    useEffect(() => {
        setSelectedTab(filteredTabs?.[0]?.value);

        return () => { };
    }, [filteredTabs]);

    return (
        <>
            <DialogTitle id="alert-dialog-title">
                Merge profile ({getFullName(customer)})
            </DialogTitle>
            <DialogContent>
                <Tabs
                    value={selectedTab}
                    onChange={(e, newValue) => {
                        setSelectedTab(newValue);
                    }}
                >
                    {filteredTabs?.map((obj) => {
                        return <Tab label={obj?.label} value={obj?.value} />;
                    })}
                    {/* {customer?.platform === "facebook" && (
                        <Tab label={"Whatsapp"} value={"whatsapp"} />
                    )}
                    {customer?.platform === "whatsapp" && (
                        <Tab label={"facebook"} value={"facebook"} />
                    )} */}
                </Tabs>

                <TextField
                    size="small"
                    fullWidth
                    placeholder="Search"
                    sx={{ mt: 2 }}
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target?.value)}
                />
                <List>
                    {conversation?.map((obj) => {
                        return (
                            <ListItem>
                                <Radio
                                    checked={Boolean(selectedCustomer?.[selectedTab] === obj?.id)}
                                    onChange={(e) =>
                                        setSelectedCustomer((pre) => ({
                                            ...pre,
                                            [selectedTab]: obj?.id,
                                        }))
                                    }
                                    value={obj?.id}
                                    name="radio-buttons"
                                />
                                <ListItemAvatar>
                                    <Badge
                                        overlap="circular"
                                        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                                        badgeContent={svgObj[selectedTab]}
                                    >
                                        <Avatar></Avatar>
                                    </Badge>
                                </ListItemAvatar>
                                <ListItemText
                                    primary={`${obj?.first_name || ""} ${obj?.last_name || ""} ${obj?.name || ""
                                        } `}
                                    secondary={customer?.contact_type || ""}
                                />
                            </ListItem>
                        );
                    })}
                </List>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onClose()}>Cancel</Button>
                <LoadingButton
                    loading={loading}
                    onClick={onAdd}
                    variant="contained"
                    autoFocus
                >
                    Merge
                </LoadingButton>
            </DialogActions>
        </>
    );
});

export default ConnectChannel;
