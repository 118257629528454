import {
  EDIT_SETTINGS,
  SET_PRODUCT_GROUPS,
  SET_SIZE_MASTER,
  SET_SIZE_TAB,
} from "../actions/settingActions";

const inititalState = {
  sizeMasters: {},
  customerLabels: [
    { label: "New Customer", value: "New Customer", color: "#8FA940" },
    { label: "New Order", value: "New Order", color: "#C1A13F" },
    { label: "Pending payment", value: "Pending payment", color: "#A42B70" },
    { label: "Paid", value: "Paid", color: "#732438" },
    { label: "Order Complete", value: "Order Complete", color: "#EEB130" },
  ],
  teamMembers: [],
};

const reducer = (state = inititalState, { type, payload }) => {
  switch (type) {
    case EDIT_SETTINGS:
      return { ...state, ...payload };
    case SET_SIZE_MASTER:
      return { ...state, sizeMasters: payload };
    case SET_SIZE_TAB:
      return { ...state, sizeTabs: payload };
    case SET_PRODUCT_GROUPS:
      return { ...state, productGroups: payload };
    default:
      return state;
  }
};

export default reducer;
