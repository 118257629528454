import {  CardMedia,  } from "@mui/material";
import { imageFieldStyles } from "../utils/chatbotFieldsStyles";
import PropTypes from "prop-types";

const styles = imageFieldStyles;

const BotImageMessage = ({ message }) => {
  return (
      <CardMedia
        style={styles.imageComponent}
        component="img"
        sx={{ height: 260 }}
        image={message.file}
        alt="Selected Image"
      />
  );
};

BotImageMessage.propTypes = {
  message: PropTypes.object.isRequired,
};

export default BotImageMessage;
