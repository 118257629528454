import * as React from "react";
import styled, { useTheme } from "styled-components/macro";
import { Power } from "react-feather";
import { useNavigate } from "react-router-dom";

import {
  Tooltip,
  Menu,
  MenuItem,
  IconButton as MuiIconButton,
  useMediaQuery,
} from "@mui/material";

import useAuth from "../../hooks/useAuth";
import { PersonOutlineOutlined } from "@mui/icons-material";
import { getWebsite } from "../../helper";
import { useSelector } from "react-redux";

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

function NavbarUserDropdown({ showStoreIcon }) {
  const [anchorMenu, setAnchorMenu] = React.useState(null);
  const theme = useTheme();
  const isPhone = useMediaQuery(theme.breakpoints.down("sm"));
  const onlyChats = useSelector((state) => state.helper.onlyChats);
  const navigate = useNavigate();
  const { signOut } = useAuth();

  const toggleMenu = (event) => {
    setAnchorMenu(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorMenu(null);
  };

  const onGotoWebsite = () => {
    let website = getWebsite();
    if (website) {
      window.open(website);
    }
  };
  const handleSignOut = async () => {
    closeMenu();
    await signOut();
    navigate("/auth/sign-in");
  };
  const handleProfile = async () => {
    closeMenu();
    navigate("/profile");
  };

  return (
    <React.Fragment>
      <Tooltip title="Account">
        <IconButton
          aria-owns={Boolean(anchorMenu) ? "menu-appbar" : undefined}
          aria-haspopup="true"
          onClick={toggleMenu}
          color="inherit"
          size="large"
          sx={{ padding: "8px" }}
          className="profile_icon"
        >
          <PersonOutlineOutlined
            fontSize="large"
            style={{ height: "30px", width: "30px" }}
          />
        </IconButton>
      </Tooltip>
      <Menu
        id="menu-appbar"
        anchorEl={anchorMenu}
        open={Boolean(anchorMenu)}
        onClose={closeMenu}
      >
        <MenuItem onClick={handleProfile}>Profile</MenuItem>
        {showStoreIcon && <MenuItem onClick={onGotoWebsite}>Go to website</MenuItem>}
        {/* {} */}
        <MenuItem onClick={handleSignOut}>Sign out</MenuItem>
      </Menu>
    </React.Fragment>
  );
}

export default NavbarUserDropdown;
