import React, { useEffect, useState } from "react";
import { EbaySvg, LinkIcon } from "../../../../Svgs";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import {
  getAxiosError,
  getStoreId,
  getToken,
  getWebsite,
  isObjWithValues,
} from "../../../../helper";
import { useDispatch, useSelector } from "react-redux";
import ebayCreds from "./constants";
import { EDIT_SETTINGS } from "../../../../redux/actions/settingActions";
import axios from "axios";
import IntegrationView from "../../helper/IntegrationView";

function EbayIntegration() {
  const dispatch = useDispatch();

  const reduxEbayIntegration = useSelector(
    (state) => state.settings.ebayIntegration
  );
  const reduxIntegrationAttributes = useSelector(
    (state) => state.settings.integrationAttributes
  );

  const [mappedAttributes, setMappedAttributes] = useState({});

  const onPressAuthorise = () => {
    window.open(`${ebayCreds.oAuthURL}&state=ebay_${getStoreId()}`, "_self");
  };

  useEffect(() => {
    getEbayProductKeys();
  }, []);

  useEffect(() => {
    getEbayProductKeys();
  }, [reduxEbayIntegration]);

  const getEbayProductKeys = async () => {
    const website = getWebsite();
    if (
      !reduxIntegrationAttributes?.hasOwnProperty("ebay") &&
      reduxEbayIntegration?.integrated
    ) {
      try {
        const response = await axios({
          method: "POST",
          url: `${website}/wp-json/store/v1/channel/ebay?endpoint=/ws/api.dll`,
          data: {
            payload: {
              headers: {
                "Content-Type": "application/xml",
                "X-EBAY-API-CALL-NAME": "GetMyeBaySelling",
                "X-EBAY-API-SITEID": 0,
                "X-EBAY-API-COMPATIBILITY-LEVEL": 967,
              },
              body: `<?xml version="1.0" encoding="utf-8"?>
              <GetMyeBaySellingRequest xmlns="urn:ebay:apis:eBLBaseComponents">
                  <ErrorLanguage>en_US</ErrorLanguage>
                  <WarningLevel>High</WarningLevel>
                  <ActiveList>
                      <Sort>TimeLeft</Sort>
                      <Pagination>
                          <EntriesPerPage>1</EntriesPerPage>
                          <PageNumber>1</PageNumber>
                      </Pagination>
                  </ActiveList>
              </GetMyeBaySellingRequest>`,
            },
          },
        });

        console.debug(response?.data);
        const product = response.data?.ActiveList?.ItemArray?.Item;

        if (response.status == 200) {
          const attributes = Object.keys(product)
            // .filter((filterItem) => shopifyProductKeys.includes(filterItem))
            .map((item, index) => ({
              [item]: typeof product[item],
            }));
          dispatch({
            type: EDIT_SETTINGS,
            payload: {
              integrationAttributes: {
                ...reduxIntegrationAttributes,
                ebay: attributes,
              },
            },
          });
        }
      } catch (err) {
        console.debug(err);
      }
    }
  };

  const onPressRevoke = async () => {
    let website = getWebsite();
    let token = getToken();

    if (website && token) {
      dispatch({
        type: EDIT_SETTINGS,
        payload: {
          revoking_ebay: true,
        },
      });
      try {
        let updateRes = await axios({
          url: `${website}/wp-json/store/v1/settings/ebay_integration`,
          method: "POST",
          headers: {
            Authorization: `Basic ${token}`,
          },
          data: {
            setting: "ebay_integration",
            data: {
              ...reduxEbayIntegration,
              integrated: false,
            },
          },
        });

        console.debug("Revoke data", updateRes);

        if (updateRes.data.success) {
          dispatch({
            type: EDIT_SETTINGS,
            payload: {
              ebayIntegration: updateRes.data.data,
            },
          });
        }
      } catch (err) {
        console.log("err", getAxiosError(err));
      }

      dispatch({
        type: EDIT_SETTINGS,
        payload: {
          revoking_ebay: false,
        },
      });
    }
  };

  const handleChangeAttribute = (target, value) => {
    setMappedAttributes({
      ...mappedAttributes,
      [target]: value?.target?.value,
    });
  };

  const onPressApplyMapping = () => {};

  const _renderAttributes = () => {
    return (
      <div>
        <div
          style={{
            flexDirection: "row",
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h5">Ebay</Typography>
          <Typography variant="h5">Jwero</Typography>
        </div>

        {isObjWithValues(reduxIntegrationAttributes) &&
          reduxIntegrationAttributes.hasOwnProperty("ebay") &&
          reduxIntegrationAttributes?.ebay?.map((item, index) =>
            Object.keys(item).map((ebayKey, attributeIndex) => {
              return (
                <div style={{ flexDirection: "row", display: "flex" }}>
                  <FormControl
                    fullWidth
                    sx={{
                      marginTop: "14px",
                    }}
                  >
                    <InputLabel id={attributeIndex}>{ebayKey}</InputLabel>
                    <TextField
                      disabled
                      labelId={"ebay-attributes"}
                      id={`ebay-select-${attributeIndex}`}
                      value={ebayKey}
                      style={{ color: "#000000" }}
                    ></TextField>
                  </FormControl>
                  {/* --------- */}
                  <FormControl
                    fullWidth
                    sx={{
                      marginTop: "14px",
                      marginLeft: "14px",
                    }}
                  >
                    <InputLabel id={attributeIndex}>
                      {mappedAttributes[ebayKey]}
                    </InputLabel>
                    <Select
                      labelId={"jwero-attributes"}
                      id={attributeIndex}
                      value={mappedAttributes[ebayKey]}
                      label={mappedAttributes[ebayKey]}
                      onChange={(value) =>
                        handleChangeAttribute(ebayKey, value)
                      }
                      sx={{ fontStyle: { color: "#000000" } }}
                    >
                      {reduxIntegrationAttributes?.jwero &&
                        Object.keys(
                          reduxIntegrationAttributes?.jwero?.reduce(
                            (r, c) => Object.assign(r, c),
                            {}
                          )
                        )
                          .filter(
                            (item) =>
                              !Object.values(mappedAttributes).includes(item)
                          )
                          .map((otherAttributeKey, otherAttributeIndex) => (
                            <MenuItem value={otherAttributeKey}>
                              {otherAttributeKey}
                            </MenuItem>
                          ))}
                    </Select>
                  </FormControl>
                </div>
              );
            })
          )}
      </div>
    );
  };

  const _renderAuthorizedView = () => {
    return (
      <div>
        <div style={{ margin: "14px" }}>
          <div
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              display: "flex",
              marginBottom: "30px",
            }}
          >
            <Typography variant="h4">Map Product Attributes</Typography>
            <div
              style={{
                flexDirection: "row",
                justifyContent: "flex-end",
                display: "flex",
              }}
            >
              <Button
                size="large"
                onClick={onPressRevoke}
                sx={{
                  borderRadius: "30px",
                  backgroundColor: "#FF6263",
                  color: "#FFFFFF",
                }}
                variant="contained"
              >
                Revoke
              </Button>
              {reduxIntegrationAttributes?.hasOwnProperty("jwero") &&
                reduxIntegrationAttributes?.hasOwnProperty("ebay") && (
                  <Button
                    size="large"
                    onClick={onPressApplyMapping}
                    sx={{
                      marginLeft: "24px",
                      borderRadius: "30px",
                      backgroundColor: "#383CC1",
                      color: "#FFFFFF",
                    }}
                    variant="contained"
                  >
                    Apply Mapping
                  </Button>
                )}
            </div>
          </div>
          <div>{_renderAttributes()}</div>
        </div>
      </div>
    );
  };

  // const _renderNotAuthorizedView = () => {
  // };
  let fetchingTokenForEbay = useSelector(
    (state) => state.settings.fetchingTokenForEbay
  );
  let revoking_ebay = useSelector((state) => state.settings.revoking_ebay);
  return (
    <IntegrationView
      onIntegrate={onPressAuthorise}
      onRevoke={onPressRevoke}
      integrationTitle={
        <>
          <EbaySvg height={25} width={25} /> Ebay
        </>
      }
      subtitle={
        "Our eBay integration allows you to manage orders, products, and customers with ease, all from the convenience of your dashboard."
      }
      integrateButtonProps={{
        disabled: fetchingTokenForEbay,
        children: fetchingTokenForEbay ? "Integrating" : "Integrate",
      }}
      revokeButtonProps={{
        disabled: revoking_ebay,
        children: revoking_ebay ? "Revoking" : "Revoke",
      }}
      isIntegrated={reduxEbayIntegration?.integrated}
    />
  );
}

export default EbayIntegration;
