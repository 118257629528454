import { Box, Link, Typography } from "@mui/material";
import React from "react";
import { ListItem } from "../meta/MetaOverview";

const feature = [
    '👉No set-up cost',
    '👉High Security',
    '👉Easy Website Integration',
    '👉Instant Refund',
    '👉Easy to configure',
    '👉Allows Invoice generation',
    '👉Accepts International Payments and many more…'
]
const CashFreeOverview = () => {
    return (
        <Box sx={{ p: 3, mb: 3 }}>
            <Typography variant="subtitle1" sx={{ fontSize: "16px", mb: 4 }}>
                Cashfree is one of the popular payment gateways in India with an amazing
                onboarding experience ✅
            </Typography>
            <Typography variant="subtitle1" sx={{ fontSize: "16px", mb: 4 }}>
                They offer a fast payment Service and an instant refund facility for their customers.
                As a jeweller, one can integrate their Cashfree payment gateway on their website with almost no setup cost 🤯
            </Typography>
            <Typography variant="subtitle2" sx={{ fontSize: "16px", mb: 4 }}>Features of Cashfree</Typography>
            <ListItem arr={feature} />
            <Link href="https://www.cashfree.com/contact-us/?referrer=partner&refCode=CFPMI929789" style={{ marginTop: 2, fontSize: "16px" }} >Contact Cashfree</Link>
        </Box>
    );
};

export default CashFreeOverview;
