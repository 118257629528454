import { Add, ArrowBackIosNew, LocalOffer } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Label } from "../../customers/components/Info";
import { settingApi } from "../apiFunctions";
import {
  getRandomString,
  getTimeInMilliSeconds,
  isObjWithValues,
} from "../../../helper";
import { EDIT_CHATS } from "../../../redux/actions/chatActions";
import { setGlobalToast } from "../../../redux/actions/helperActions";

const colors = [
  "#FF0000", // Red
  "#008000", // Green
  "#0000FF", // Blue
  "#FFFF00", // Yellow
  "#800080", // Purple
  "#00FFFF", // Cyan
  "#FF00FF", // Magenta
  "#FFA500", // Orange
  "#A52A2A", // Brown
  "#FFC0CB", // Pink
];

const Tags = ({ asBox, onConfirm }) => {
  const Container = DialogContent;
  const dispatch = useDispatch();
  const reduxTags = useSelector((state) => state?.chats?.chat_tags);
  const [selected, setSelected] = useState({});
  const [create, setCreate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({});
  const [createForm, setCreateForm] = useState({});
  const editForm = (payload) => {
    setError({});
    setCreateForm((pre) => ({ ...pre, ...payload }));
  };
  const onSelect = (obj) => {
    if (selected?.[obj?.id]) {
      let tempObj = { ...selected };
      delete tempObj[obj?.id];
      setSelected(tempObj);
    } else {
      setSelected({ ...selected, [obj?.id]: obj });
    }
    //    setSelected((pre) => {
    //       if (pre?.[obj?.id]) delete pre[obj?.id];
    //       else pre[obj?.id] = obj;
    //       return pre;
    //     });
  };
  const submitHandler = async () => {
    setLoading(true);
    if (onConfirm) await onConfirm(selected);
    setLoading(false);
  };
  console.log(selected, "selected");

  const onCreate = async () => {
    if (!createForm?.title)
      return setError((pre) => ({ ...pre, title: "Title is required" }));
    if (!createForm?.color)
      return setError((pre) => ({ ...pre, color: "Color is required" }));
    setLoading(true);
    let id = getRandomString(10);
    let putObject = {
      [id]: {
        id,
        ...createForm,
        created_on: getTimeInMilliSeconds(),
        contact_ids: [],
      },
    };
    let res = await settingApi({
      data: { data: putObject },
      endpoint: "chat_tags",
      method: "PUT",
    });
    console.log(res);
    if (res?.success) {
      dispatch({
        type: EDIT_CHATS,
        payload: {
          chat_tags: res?.data,
        },
      });
      dispatch(
        setGlobalToast({
          show: true,
          message: "Tag Updated Successfully",
          severity: "success",
        })
      );
      setCreateForm({});
      onSelect(putObject?.[id]);
      setLoading(false);
      setCreate(false);
    }
  };

  return (
    <>
      {!create ? (
        <>
          <DialogTitle
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            Select Tag
            <LoadingButton
              loading={loading}
              variant="contained"
              disabled={!isObjWithValues(selected)}
              onClick={() => {
                submitHandler();
              }}
            >
              Confirm
            </LoadingButton>
          </DialogTitle>
          <Container>
            <List>
              {Object.values(reduxTags || {})?.map((obj) => {
                return (
                  <ListItem
                    onClick={() => onSelect(obj)}
                    sx={{ cursor: "pointer" }}
                  >
                    <ListItemIcon sx={{ mr: 2 }}>
                      <Stack
                        direction={"row"}
                        alignItems={"center"}
                        gap={1}
                        justifyContent={"center"}
                      >
                        <Checkbox
                          edge="start"
                          checked={Boolean(selected?.[obj?.id])}
                          //   checked={checked.indexOf(value) !== -1}
                          tabIndex={-1}
                          disableRipple
                          //   inputProps={{ 'aria-labelledby': labelId }}
                        />
                        <LocalOffer style={{ color: obj?.color }} />
                      </Stack>
                    </ListItemIcon>
                    <Stack>
                      <Typography variant="subtitle1">{obj?.title}</Typography>
                    </Stack>
                    {/* <ListItemText
                  primary={obj?.title}
                  sx={{}}
                  //   secondary={secondary ? 'Secondary text' : null}
                /> */}
                  </ListItem>
                );
              })}
            </List>
            <Stack justifyContent={"center"} direction={"row"}>
              <Button
                startIcon={<Add />}
                onClick={() => setCreate(true)}
                // variant="contained"
              >
                Create
              </Button>
            </Stack>
          </Container>
        </>
      ) : (
        <>
          <DialogTitle
            sx={{
              display: "flex",
              alignItems: "center",
              //   justifyContent: "space-between",
              gap: 2,
            }}
          >
            <IconButton
              onClick={() => {
                setCreateForm({});
                setCreate(false);
              }}
            >
              <ArrowBackIosNew />
            </IconButton>{" "}
            Create Tag
          </DialogTitle>
          <Container>
            <Stack sx={{ gap: 2 }}>
              <Stack>
                <Label>Enter tag title</Label>
                <TextField
                  size="small"
                  placeholder="Enter tag title"
                  value={createForm?.title}
                  error={error?.title}
                  helperText={error?.title}
                  onChange={(e) => editForm({ title: e.target.value })}
                />
              </Stack>
              <Stack>
                <Label sx={{ color: error?.color && "#FF0000" }}>
                  Select tag color
                </Label>
                <Stack direction="row" justifyContent={"flex-start"}>
                  {colors.map((i) => {
                    return (
                      <IconButton
                        sx={{ p: 1.5 }}
                        onClick={() => {
                          editForm({ color: i });
                        }}
                        //   onClick={(e) =>
                        //     !product?.pull_source &&
                        //     onEditProduct({
                        //       metal_color: metal_color === i.value ? "" : i.value,
                        //     })
                        //   }
                        // onClick={() => onColorClick(id, i.value)}
                      >
                        <Box
                          sx={{
                            background: i,
                            height: "25px",
                            width: "25px",
                            borderRadius: "50%",
                            border: `2px solid ${
                              i === createForm?.color ? "#000" : "transparent"
                            }`,
                          }}
                        />
                      </IconButton>
                    );
                  })}
                </Stack>
              </Stack>
            </Stack>
          </Container>
          <DialogActions>
            <Button
              onClick={() => {
                setCreateForm({});
                setCreate(false);
              }}
            >
              Cancel
            </Button>
            <LoadingButton
              variant="contained"
              onClick={() => {
                onCreate();
              }}
              loading={loading}
            >
              Create
            </LoadingButton>
          </DialogActions>
        </>
      )}
    </>
  );
};

export default Tags;
