import {
  Avatar,
  Box,
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  Link,
  Popover,
  Stack,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/system";
import React, { createRef, useEffect, useRef, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { connect, useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import {
  isArrayWithValues,
  isObjWithValues,
  jweroColorPalette,
  primaryLabel,
  secondaryLabel,
  sectionLabel,
  StyledBadge,
  validateNumber,
} from "../../helper";
import { addVisitor, removeVisitor } from "../../redux/actions/visitorActions";

import socketIOClient, { io } from "socket.io-client";
import { isValidDate } from "../../utils/dateFunctions";
import { East, VolumeOff, VolumeUp } from "@mui/icons-material";
import { chatgGlobalColors, NoTasks } from "../chat/ChatHelper";
import { setPersistData } from "../../redux/actions/persistActions";
import { LiveVisitor } from "../../Svgs";
const mainEvents = ["design_bank", "catalog", "quotation", "website"];

function VisitorsOverview(props) {
  const theme = useTheme();
  const navigate = useNavigate();
  const [primaryColor, setPrimaryColor] = useState("");
  const visitor_audio_mute = useSelector(
    (state) => state.persist.visitor_audio_mute
  );
  // const [options, setOptions] = useState({
  //   chart: {
  //     height: 450,
  //     type: "pie",
  //     events: {
  //       click: function (chart, w, e) {
  //         console.log(chart, w, e);
  //       },
  //     },
  //     toolbar: {
  //       show: false,
  //     },
  //     foreColor: theme.palette.text.primary,
  //   },
  //   colors: [theme.palette.primary.main],
  //   plotOptions: {
  //     bar: {
  //       columnWidth: "35%",
  //       distributed: true,
  //     },
  //   },
  //   dataLabels: {
  //     enabled: false,
  //   },
  //   legend: {
  //     show: false,
  //   },
  //   fill: {
  //     type: "gradient",
  //     gradient: {
  //       shade: "dark",
  //       type: "vertical",
  //       shadeIntensity: 1,
  //       opacityFrom: 0.7,
  //       opacityTo: 0.9,
  //       stops: [0, 90, 100],
  //     },
  //   },
  //   grid: { show: true, borderColor: "rgba(0,0,0,0.1)" },
  //   xaxis: {
  //     categories: [
  //       ["More than ", "0 minute"],
  //       ["More than ", "1 minutes"],
  //       ["More than ", "5 minutes"],
  //       ["More than ", "10 minutes"],
  //     ],
  //     labels: {
  //       style: {
  //         //   colors: ["red"],
  //         fontSize: "12px",
  //       },
  //       color: "red",
  //     },
  //   },
  // });
  const [options, setOptions] = useState({
    chart: {
      type: "bar",
      height: 350,
      toolbar: {
        show: false,
      },
    },
    fill: {
      colors: jweroColorPalette,
    },
    // colors: chatgGlobalColors,
    colors: jweroColorPalette,

    plotOptions: {
      bar: {
        borderRadius: 4,
        horizontal: true,
        barHeight: "50%",
        // colors :
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: [["> 0 min"], ["> 1 min"], ["> 5 min"], ["> 10 min"]],
    },
    yaxis: [
      // {
      //   labels: {
      //     formatter: function (val) {
      //       return val?.toFixed ? val?.toFixed(0) : 0;
      //     },
      //   },
      // },
    ],
  });
  const [series, setSeries] = useState([
    {
      data: [0, 0, 0, 0],
    },
  ]);
  let store_details = useSelector((state) => state.user.store_details);
  let [test, setTest] = useState([]);
  const dispatch = useDispatch();
  const [visitors, setVisitors] = useState({
    0: [],
    1: [],
    5: [],
    10: [],
  });
  const [allVisitors, setAllVisitors] = useState([]);
  const reduxAllVisitors = useSelector(
    (state) => state.visitor.allOnlineVisitors
  );
  const [intervalStarted, setIntervalStarted] = useState(false);
  let visitorsDataInterval;
  const protocol = window.location.protocol.includes("https") ? "wss" : "ws";
  const socketRef = createRef();
  let allVisitorsRef = useRef([]);

  useEffect(() => {
    // if (!process.env.NODE_ENV || process.env.NODE_ENV === "development")
    //   socketRef.current = io("ws://192.168.0.26:8080");
    // else
    // socketRef.current = io("wss://coral-app-q77rv.ondigitalocean.app/");

    // let websocket = new WebSocket("ws://192.168.0.31:8080");
    // websocket.onopen(() => {
    //   console.log("connected ws");
    // });

    // socketRef.current = io("ws://192.168.0.31:8080");

    // let io = socketIOClient(`ws://192.168.0.26:8080`);
    // socketRef.current.on("connect", () => {
    //   console.log("Successfully connected!Reactjs");
    // });

    // io.on("connect", (message) => {
    //   console.log("io connect in reactjs");
    // });
    // socketRef.current.emit("visitor", {
    //   hello: "asd",
    // });
    // socketRef.current.on("visitor", (message) => {
    //   console.log(JSON.stringify(message, null, 2));
    //   if (message.online) dispatch(addVisitor(message));
    //   else dispatch(removeVisitor(message.username));
    // });
    // socket.on("visitor", (message) => {
    //   console.log(message);
    // });

    return () => {
      // console.log("SOCKET DISCONNECTED");
      // return socketRef.current.disconnect();
      // return io.disconnect();
    };
  }, []);
  // socket.emit("message", { message: "Hello World!", by: "Supplier" });

  // socket.on("visitor", (message) => {
  //   console.log(message);
  //   if (message.online) dispatch(addVisitor(message));
  //   else dispatch(removeVisitor(message.username));
  // });

  // var ws = React.useRef(
  //   // new WebSocket(`${protocol}://coral-app-q77rv.ondigitalocean.app/`)
  //   new WebSocket(`ws://192.168.0.27:8080/${store_details.store_url}`)
  // ).current;
  // if (ws.onopen)
  //   ws.onopen((e) => {
  //     console.log(`socket open on ${store_details.store_url}`);
  //   });
  // // if (ws.onmessage)
  // ws.onmessage = (e) => {
  //   // setTest((state) => [...state, e.data]);
  //   // console.log(JSON.parse(e.data, null, 2), "WEBSOCKET MESSAGE");
  //   let message = JSON.parse(e.data);
  //   // console.log("GOT MESSAGE", message.username);
  //   if (message.online) dispatch(addVisitor(message));
  //   else dispatch(removeVisitor(message.username));
  // };
  // useEffect(() => {
  //   let ws = new WebSocket(
  //     `wss://coral-app-q77rv.ondigitalocean.app/${store_details.store_url}`
  //     // `ws://192.168.0.26:8080/${store_details.store_url}`
  //   );
  //   // var ws = React.useRef(
  //   //   // new WebSocket(`${protocol}://coral-app-q77rv.ondigitalocean.app/`)
  //   //   new WebSocket(`ws://192.168.0.27:8080/${store_details.store_url}`)
  //   // ).current;
  //   if (ws.onopen)
  //     ws.onopen((e) => {
  //       console.log(`socket open on ${store_details.store_url}`);
  //     });
  //   // if (ws.onmessage)
  //   ws.onmessage = (e) => {
  //     // setTest((state) => [...state, e.data]);
  //     // console.log(JSON.parse(e.data, null, 2), "WEBSOCKET MESSAGE");
  //     let message = JSON.parse(e.data);
  //     // console.log("GOT MESSAGE", message.username);
  //     if (message.online) dispatch(addVisitor(message));
  //     else dispatch(removeVisitor(message.username));
  //   };
  //   return () => {
  //     return ws.close();
  //   };
  // }, []);

  useEffect(() => {
    return () => {
      clearInterval(visitorsDataInterval);
    };
  }, []);

  useEffect(() => {
    let obj = { ...reduxAllVisitors };
    let date = new Date();
    let todayDate = date.toISOString().substring(0, 10);
    date = date.setDate(date.getDate() - 1);
    date = new Date(date);
    let yesterdayDate = date.toISOString().substring(0, 10);
    let visitorsArray = [];
    for (let id in obj) {
      let isOnline = false;
      let events = obj[id];

      // for (let i = events.length - 1; i >= 0; i--) {
      for (let i = 0; i < events.length; i++) {
        let { time, id, online, user_id, type, user_name } = events[i];
        let eventTime = new Date(validateNumber(time) * 1000);

        eventTime = isValidDate(eventTime)
          ? eventTime.toISOString().substring(0, 10)
          : "";
        // console.log(
        //   id,
        //   eventTime === todayDate || eventTime === yesterdayDate,
        //   mainEvents.includes(type),
        //   online || online === "true"
        // );
        if (
          (eventTime === todayDate || eventTime === yesterdayDate) &&
          mainEvents.includes(type)
        ) {
          if (online || online === "true") {
            isOnline = true;
            visitorsArray.push({
              time,
              user_id,
              user_name,
              events,
            });
            break;
          } else break;
        }
      }
    }

    setAllVisitors(visitorsArray);
    // if (isObjWithValues(obj)) {
    //   for (let id in obj) {
    //     if (isObjWithValues(obj[id])) {
    //       let isOnline = false;
    //       if (isArrayWithValues(obj[id][todayDate])) {
    //         let array = obj[id][todayDate];
    //         for (let i = array.length - 1; i >= 0; i--) {
    //           let event = array[i];
    //           if (
    //             mainEvents.includes(i.type) &&
    //             (event.online || event.online === "true")
    //           ) {
    //             isOnline = true;
    //             break;
    //           }
    //         }
    //       }
    //       if (!isOnline && isArrayWithValues(obj[id][yesterdayDate])) {
    //         let array = obj[id][yesterdayDate];
    //         for (let i = array.length - 1; i >= 0; i--) {
    //           let event = array[i];
    //           if (
    //             mainEvents.includes(i.type) &&
    //             (event.online || event.online === "true")
    //           ) {
    //             isOnline = true;
    //             break;
    //           }
    //         }
    //       }
    //     }
    //   }
    // }
  }, [reduxAllVisitors]);
  useEffect(() => {
    setVisitorsData(allVisitors);
    allVisitorsRef.current = [...allVisitors];
    if (isArrayWithValues(allVisitors)) {
      if (!intervalStarted) {
        setIntervalStarted(true);
        visitorsDataInterval = setInterval(() => {
          setVisitorsData(allVisitorsRef.current);
        }, 10000);
      }
    }
    if (!isArrayWithValues(allVisitors)) clearInterval(visitorsDataInterval);
    // return () => clearInterval(visitorsDataInterval);
  }, [allVisitors]);

  const setVisitorsData = (allVisitors) => {
    if (allVisitors.length === 0) {
      setSeries([
        {
          data: [0, 0, 0, 0],
        },
      ]);
      setVisitors({
        0: [],
        1: [],
        5: [],
        10: [],
      });
    } else {
      let currentVisitors = {
        0: [],
        1: [],
        5: [],
        10: [],
      };

      for (let i = 0; i < allVisitors.length; i++) {
        let visitor = allVisitors[i];
        let currentTime = parseInt(Date.now() / 1000);
        let minutesDiff = Math.round(
          (currentTime - validateNumber(visitor.time)) / 60
        );

        if (minutesDiff < 1) currentVisitors[0].push(visitor);
        else if (minutesDiff < 5) currentVisitors[1].push(visitor);
        else if (minutesDiff < 10) currentVisitors[5].push(visitor);
        else if (minutesDiff >= 10) currentVisitors[10].push(visitor);
      }
      if (
        currentVisitors[0].length === 0 &&
        currentVisitors[1].length === 0 &&
        currentVisitors[5].length === 0
      )
        clearInterval(visitorsDataInterval);
      setVisitors(currentVisitors);
      setSeries([
        {
          data: [
            currentVisitors[0].length,
            currentVisitors[1].length,
            currentVisitors[5].length,
            currentVisitors[10].length,
          ],
        },
      ]);
    }
  };

  const onVisitorVolumePress = () => {
    dispatch(
      setPersistData({
        visitor_audio_mute: !visitor_audio_mute,
      })
    );
  };
  // console.log(visitor_audio_mute, "MUTE");

  // console.log(JSON.stringify(reduxAllVisitors, null, 2));

  return (
    <Box sx={{ height: "100%" }}>
      {/* 
      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          Dashboard
        </Link>
        <Typography>Visitors Info</Typography>
      </Breadcrumbs> */}
      <Grid container spacing={4} flex={1} sx={{ height: "100%" }}>
        <Grid item xs={12} sx={{ height: "100%" }}>
          <Card sx={{ height: "100%" }}>
            <CardContent>
              <Stack
                direction={"row"}
                alignItems="center"
                justifyContent={"space-between"}
                sx={{ mb: 5 }}
              >
                <Stack direction={"row"} alignItems="center" spacing={2}>
                  <Typography sx={{ ...sectionLabel }}>
                    Live Visitors
                  </Typography>
                  {/* <IconButton onClick={onVisitorVolumePress}>
                    {!visitor_audio_mute ? <VolumeUp /> : <VolumeOff />}
                  </IconButton> */}
                </Stack>

                {series[0]?.data?.find((num) => num >= 1) ? (
                  <Stack
                    direction={"row"}
                    alignItems="center"
                    sx={{ cursor: "pointer" }}
                    onClick={() => navigate("/visitors_info/details")}
                  >
                    <Typography sx={{ ...secondaryLabel, color: "primary" }}>
                      View Details
                    </Typography>
                    <East
                      style={{
                        height: "15px",
                        width: "15px",
                        marginLeft: "5px",
                      }}
                    />
                  </Stack>
                ) : null}
              </Stack>
              {/* <ReactApexChart
                options={options}
                series={series}
                type="bar"
                height={350}
              /> */}
              {series[0]?.data?.find((num) => num >= 1) ? (
                <ReactApexChart
                  options={options}
                  series={series}
                  type="bar"
                  height={350}
                />
              ) : (
                <>
                  <NoTasks
                    sx={{ my: 25 }}
                    text={
                      <Stack
                        justifyContent={"center"}
                        alignItems="center"
                        rowGap={2}
                      >
                        No visitors Live at the moment
                        <Button
                          variant="outlined"
                          onClick={() => navigate("/visitors_info/details")}
                        >
                          Go to live Visitors{" "}
                        </Button>
                      </Stack>
                    }
                    icon={
                      // <Button variant="outlined">
                      //   Go to visitor page
                      // </Button>
                      <LiveVisitor
                        style={{
                          height: "70px",
                          width: "70px",
                          fill: "#c4c4c4",
                        }}
                        fillCircle="#c4c4c4"
                      />
                    }
                  />
                </>
              )}
              {/* <Stack direction={"row"}>
                {Object.values(visitors)
                  .flat()
                  .map((i) => {
                    return (
                      <LiveCustomerAvatar info={i} />
                    );
                  })}
              </Stack> */}
              {/* <Stack direction={"row"} justifyContent="flex-end">
                <Typography variant="h6" color="text.secondary">
                  Total Visitors: {allVisitors.length}
                </Typography>
              </Stack> */}
            </CardContent>
          </Card>
        </Grid>
        {/* <Grid
          item
          xs={12}
          md={6}
          style={{ height: "100%" }}
          height="100%"
          flex={1}
          sx={{}}
        >
          <Stack
            direction="row"
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Typography sx={{ ...sectionLabel }}>Time on sites</Typography>
            <Button
              onClick={() => navigate("/visitors_info/details")}
              sx={{ m: 0, py: 0 }}
            >
              Details
            </Button>
          </Stack>
          <Grid container spacing={4} sx={{ marginTop: "0px", height: "100%" }}>
            <Grid item xs={6} sx={{}}>
              <TimeCard
                title="More than 0 minute"
                visitors={visitors[0].length}
              />
            </Grid>
            <Grid item xs={6}>
              <TimeCard
                title="More than 1 minutes"
                visitors={visitors[1].length}
              />
            </Grid>
            <Grid item xs={6}>
              <TimeCard
                title="More than 5 minutes"
                visitors={visitors[5].length}
              />
            </Grid>
            <Grid item xs={6}>
              <TimeCard
                title="More than 10 minutes"
                visitors={visitors[10].length}
              />
            </Grid>
          </Grid>
        </Grid> */}
      </Grid>
    </Box>
  );
}

export default VisitorsOverview;

const LiveCustomerAvatar = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  return (
    <>
      <StyledBadge
        overlap="circular"
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        variant="dot"
      >
        <Avatar
          alt="Remy Sharp"
          src="/static/images/avatar/1.jpg"
          aria-owns={open ? "mouse-over-popover" : undefined}
          aria-haspopup="true"
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
        />
      </StyledBadge>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: "none",
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography sx={{ p: 1 }}>I use Popover.</Typography>
      </Popover>
    </>
  );
};

const TimeCard = ({ title, visitors }) => {
  let [visitorColor, setVisitorColor] = useState();
  useEffect(() => {
    if (visitors === 0) setVisitorColor("error.main");
    if (visitors > 0 && visitors < 8) setVisitorColor("info.main");
    if (visitors > 8) setVisitorColor("success.main");
  }, [visitors]);
  return (
    <Card
      sx={{ boxShadow: "0px 0px 3px 0px rgba(0,0,0,0.25)", height: "100%" }}
    >
      <CardContent
        sx={{
          "&:last-child": { paddingBottom: "16px" },
        }}
      >
        <Typography variant="body2"> {title}</Typography>
        <Stack textAlign={"center"} my={12}>
          <Stack
            direction={"row"}
            alignItems="flex-end"
            justifyContent="center"
          >
            <Typography
              variant="h6"
              fontSize={"30px"}
              sx={{ color: visitorColor }}
              mr={1}
            >
              {visitors}
            </Typography>
            <Typography variant="h6" mb={1}>
              Visitors
            </Typography>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
};
