import { Close, Looks } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Box,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  apiCallFromSocket,
  dialogStyle,
  getAxiosError,
  isObjWithValues,
  primaryLabel,
  primaryLabelLarge,
  settingsEndpoint,
} from "../../../../helper";
import { EDIT_SETTINGS } from "../../../../redux/actions/settingActions";
import { Label } from "../../../customers/components/Info";

///////////  JEMiSys api ///////
// export const jemisysSilverInventory_API = (ip_port, code) => `http://${ip_port}/JEMiSysecAPI/api/${code}/GetInventory_${code}`
// export const jemisysInventoryStone_API = (ip_port, code) => `http://${ip_port}/JEMiSysecAPI/api/${code}/GetInventoryStone_${code}`
// export const jemisysCheckStockOnline_API = (ip_port, code) => `http://${ip_port}/JEMiSysecAPI/api/${code}GetCheckStockOnline_${code}`
// export const jemisyseCUpdateJobOrder_API = (ip_port, code) => `http://${ip_port}/JEMiSysONEAPI/api/${code}eCUpdateJobOrder`
// export const jemisysgetGoldSellingRate_API = (ip_port, code) => `http://${ip_port}/JEMiSysONEAPI/api/GoldRate/GetGoldSellingRate`
////////// JEMiSys Silver api /////////
export const jemisysSilverInventory_API = (ip_port, code) =>
  `http://${ip_port}/JEMiSysSilverAPI/api/${code}/GetInventory_Silver`;
export const jemisysSilverCheckStockOnline_API = (ip_port, code) =>
  `http://${ip_port}/JEMiSysSilverAPI/api/${code}GetCheckStockOnline_${code}`;
export const jemisysSilvereCUpdateJobOrder_API = (ip_port, code) =>
  `http://${ip_port}/JEMiSysSilverAPI/api/${code}eCUpdateJobOrder`;
export const jemisysSilvergetGoldSellingRate_API = (ip_port, code) =>
  `http://${ip_port}/JEMiSysSilverAPI/api/GoldRate/GetGoldSellingRate`;
export const jemisysSilverCategory_API = (ip_port, code) =>
  `http://${ip_port}/JEMiSysSilverAPI/api/${code}/GetCategories`;
export const jemisysSilverInvoiceNumber_API = (ip_port, code) =>
  `http://${ip_port}/JEMiSysSilverAPI/api/${code}/GetInvoiceNumber`;

////////////////////////////////
const Integration = () => {
  const jemisysIntegration = useSelector(
    (state) => state.settings.jemisysIntegration
  );

  const [formOpen, setFormOpen] = useState(false);
  const [revokeOpen, setRevokeOpen] = useState(false);
  const closeForm = () => setFormOpen(false);
  const openForm = () => setFormOpen(true);
  const openRevoke = () => setRevokeOpen(true);
  const closeRevoke = () => setRevokeOpen(false);

  return (
    <Box sx={{ px: { xs: 0, md: 3 } }}>
      <>
        <Stack
          direction="row"
          justifyContent={"space-between"}
          alignItems="center"
          mt={6}
          sx={{ gap: "30px" }}
        >
          <Box>
            <Typography
              variant="h6"
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "8px",
                ...primaryLabelLarge,
              }}
            >
              {/* <img
                src="/static/img/brands/jemisysLogo.png"
                width="auto"
                height="20px"
              /> */}
              JEMiSys
            </Typography>
          </Box>
          <LoadingButton
            // loading={fetching}
            size="small"
            // onClick={() => (openForm())}
            onClick={() =>
              jemisysIntegration?.integrated ? openRevoke() : openForm()
            }
            variant={"contained"}
            color={jemisysIntegration?.integrated ? "error" : "primary"}
          >
            {jemisysIntegration?.integrated ? "Revoke" : "Integrate"}
          </LoadingButton>

          {/* {integratedApp.includes(obj.value) && (
            <LoadingButton
              loading={revoking}
              variant="outlined"
              color="error"
              size="small"
              onClick={() => onRevokeGoogle(obj.value)}
            >
              Revoke
            </LoadingButton>
          )} */}
        </Stack>
        {/* {isObjWithValues(connectedAccount) && ( */}
        {jemisysIntegration?.integrated && (
          <Typography variant="h6" sx={{ mt: 1, fontSize: "13px" }}>
            Connected Account:{" "}
            <strong>{jemisysIntegration?.inventory_ip_port}</strong>
            {/* {connectedAccount.ip_port} ( */}
            {/* {connectedAccount.username}) */}
          </Typography>
        )}
        {/* )} */}
        <Typography
          sx={{
            mt: 4,
            color: "text.secondary",
            fontSize: "13px",
            maxWidth: "800px",
          }}
        >
          Jemisys is a revolutionary Jewellery Store Management Software that
          takes your business to the next level. With Jemisys, you can easily
          manage your back-end and front-end business operations without the
          need for constant human involvement.
        </Typography>
      </>
      <IntegrateForm formOpen={formOpen} closeForm={closeForm} />
      <RevokeDialog closeRevoke={closeRevoke} revokeOpen={revokeOpen} />
    </Box>
  );
};

export default Integration;

const inventory_ip_port_error = "Please Enter IPAddress for Inventory";
const order_ip_port_error = "Please Enter IPAddress for Order Update";
const code_error = "Please Enter Company Code";
const APIKey_error = "Please Enter Api Key";
const IntegrateForm = ({ formOpen, closeForm }) => {
  const [loading, setLoading] = useState(false);
  const [addForm, setAddForm] = useState({});
  const [verificationFailed, setVerificationFailed] = useState(false);
  const [error, setError] = useState({});
  const dispatch = useDispatch();

  const editForm = (payload) => {
    setVerificationFailed(false);
    setError({});
    setAddForm((pre) => ({ ...pre, ...payload }));
  };
  // const []

  const onSave = async () => {
    let { inventory_ip_port, code, order_ip_port, APIKey } = addForm || {};

    if (!inventory_ip_port)
      return setError((pre) => ({
        ...pre,
        inventory_ip_port: inventory_ip_port_error,
      }));
    // if (!order_ip_port) return setError(pre => ({ ...pre, order_ip_port: order_ip_port_error }))
    if (!code) return setError((pre) => ({ ...pre, code: code_error }));
    if (!APIKey) return setError((pre) => ({ ...pre, APIKey: APIKey_error }));

    setLoading(true);

    let res;
    try {
      let inventoryRes = await apiCallFromSocket({
        url: jemisysSilverInventory_API(inventory_ip_port, code),
        headers: {
          APIKey: APIKey,
          "Content-Type": "application/json",
        },
        method: "POST",
      });
      res = inventoryRes;

      console.log(inventoryRes);
    } catch (error) {
      setVerificationFailed(true);
      console.log(getAxiosError(error));
    }

    // if (res?.statusText === "OK") {
    if (res?.data) {
      try {
        let jemisysIntegration = await settingsEndpoint({
          endpoint: "jemisys_integration",
          method: "POST",
          data: {
            ...addForm,
            last_sync_time: new Date().getTime(),
            integrated: true,
          },
        });
        if (isObjWithValues(jemisysIntegration)) {
          dispatch({
            type: EDIT_SETTINGS,
            payload: {
              jemisysIntegration: jemisysIntegration,
            },
          });

          console.log(jemisysIntegration);
          closeForm();
        }
      } catch (error) {
        console.log(getAxiosError(error));
      }
    }

    // try {
    //     let orderRes = await axios({
    //         url: jemisyseCUpdateJobOrder_API(inventory_ip_port, code),
    //         headers: {
    //             APIKey: addForm?.api_key
    //         },
    //         method: "POST"
    //     })
    //     console.log(orderRes);
    // } catch (error) {
    //     console.log(getAxiosError(error));
    // }
    setLoading(false);
  };

  return (
    <Dialog
      open={formOpen}
      onClose={closeForm}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="xs"
      keepMounted={false}
      sx={{ ...dialogStyle }}
    >
      <DialogTitle variant="h5" id="alert-dialog-title">
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems={"center"}
        >
          JEMiSys Integration
          <IconButton onClick={closeForm}>
            <Close />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={4} rowSpacing={4} sx={{ mt: 2 }}>
          <Grid item xs={12}>
            <Label>IPAddress for Inventory:Port</Label>
            <TextField
              value={addForm.inventory_ip_port}
              onChange={(e) => editForm({ inventory_ip_port: e.target.value })}
              fullWidth
              size="small"
              placeholder="For e.g. 103.10.234.244:100"
              error={error?.inventory_ip_port}
              helperText={error?.inventory_ip_port}
            />
          </Grid>
          {/* <Grid item xs={12}>
                    <Label>IPAddress for Order Update:Port</Label>
                    <TextField
                        value={addForm.order_ip_port}
                        onChange={(e) => editForm({ order_ip_port: e.target.value })}
                        fullWidth
                        size="small"
                        placeholder="For e.g. 103.10.234.244:100"
                        error={error?.order_ip_port}
                        helperText={error?.order_ip_port}
                    />
                </Grid> */}
          <Grid item xs={12}>
            <Label>Company Code</Label>
            <TextField
              value={addForm.code}
              onChange={(e) => editForm({ code: e.target.value })}
              fullWidth
              size="small"
              error={error?.code}
              helperText={error?.code}
              // placeholder="For e.g. 103.10.234.244:100"
            />
          </Grid>
          <Grid item xs={12}>
            <Label>Api Key</Label>
            <TextField
              value={addForm.APIKey}
              onChange={(e) => editForm({ APIKey: e.target.value })}
              fullWidth
              size="small"
              error={error?.APIKey}
              helperText={error?.APIKey}
            />
          </Grid>
        </Grid>
        {/* <Divider sx={{ mt: 3, mb: 1 }} /> */}
        {/* <Grid container spacing={2}>
                <Grid item xs={4}>
                    <Label>FTP Username</Label>
                    <TextField
                        value={addForm.ftp_username}
                        onChange={(e) => editForm({ ftp_username: e.target.value })}
                        fullWidth
                        size="small"
                    />
                </Grid>
                <Grid item xs={4}>
                    <Label>FTP Password</Label>
                    <TextField
                        value={addForm.ftp_password}
                        onChange={(e) => editForm({ ftp_password: e.target.value })}
                        fullWidth
                        size="small"
                        type={"password"}
                    />
                </Grid>
                <Grid item xs={4}>
                    <Label>FTP Port</Label>
                    <TextField
                        value={addForm.ftp_port}
                        onChange={(e) => editForm({ ftp_port: e.target.value })}
                        fullWidth
                        size="small"
                    />
                </Grid>
            </Grid> */}
        <Collapse
        // in={error}
        >
          <Alert
            severity="error"
            variant="standard"
            style={{
              backgroundColor: "transparent",
              padding: "0px",
              marginTop: "7px",
            }}
          >
            All Fields required
          </Alert>
        </Collapse>
        <Collapse in={verificationFailed}>
          <Alert
            severity="error"
            variant="standard"
            style={{
              backgroundColor: "transparent",
              padding: "0px",
              marginTop: "7px",
            }}
          >
            Credentials are invalid!
          </Alert>
        </Collapse>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeForm}>Cancel</Button>
        <LoadingButton loading={loading} onClick={onSave} autoFocus>
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
const RevokeDialog = ({ revokeOpen, closeRevoke }) => {
  const [revoking, setRevoking] = useState(false);
  const dispatch = useDispatch();
  const onRevoke = async () => {
    setRevoking(true);
    try {
      let jemisysIntegration = await settingsEndpoint({
        endpoint: "jemisys_integration",
        method: "Post",
        data: {
          integrated: false,
        },
      });
      if (isObjWithValues(jemisysIntegration)) {
        dispatch({
          type: EDIT_SETTINGS,
          payload: {
            jemisysIntegration: jemisysIntegration,
          },
        });

        console.log(jemisysIntegration);
        closeRevoke();
      }
    } catch (error) {
      console.log(getAxiosError(error));
    }
    setRevoking(false);
  };
  return (
    <Dialog
      open={revokeOpen}
      onClose={closeRevoke}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="xs"
      sx={{ ...dialogStyle }}
    >
      <DialogTitle variant="h5" id="alert-dialog-title">
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems={"center"}
        >
          Revoke Ornate Integration?
          <IconButton onClick={closeRevoke}>
            <Close />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>This action cannot be undone!</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeRevoke}>Cancel</Button>
        <LoadingButton onClick={onRevoke} loading={revoking} color="error">
          Revoke
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
