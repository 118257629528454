import { LoadingButton } from "@mui/lab";
import { Button, Stack, Typography } from "@mui/material";
import React from "react";
import { LinkIcon } from "../../../Svgs";

function IntegrationView({
  integrationTitle,
  subtitle,
  onIntegrate = () => {},
  integrateButtonProps = {},
  revokeButtonProps = {},
  isIntegrated,
  onRevoke = () => {},
}) {
  return (
    <>
      <Stack
        direction={"row"}
        sx={{ justifyContent: "space-between", py: 3, px: 4 }}
        alignItems="center"
      >
        <Stack
          direction="row"
          justifyContent={"space-between"}
          alignItems="center"
        >
          <Typography
            variant="h5"
            sx={{ display: "flex", alignItems: "center", gap: "10px" }}
          >
            {integrationTitle}
          </Typography>
        </Stack>
        {isIntegrated ? (
          <LoadingButton
            variant="contained"
            color="error"
            onClick={onRevoke}
            {...revokeButtonProps}
          >
            Revoke
          </LoadingButton>
        ) : (
          <LoadingButton
            variant="contained"
            onClick={onIntegrate}
            endIcon={<LinkIcon fill="#fff" />}
            {...integrateButtonProps}
          >
            {integrateButtonProps.children
              ? integrateButtonProps.children
              : "Integrate"}
          </LoadingButton>
        )}
        {/* <Button
        // startIcon={<EbaySvg height={40} width={40} />}
        onClick={onPressAuthorise}
        sx={{
          padding: "12px 16px",
          borderRadius: "29px",
          boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
          backgroundColor: "#fff",
        }}
      >
        Integrate
      </Button> */}
      </Stack>
      <Typography
        sx={{
          mt: 2,
          color: "text.secondary",
          fontSize: "15px",
          maxWidth: "80%",
        }}
      >
        {subtitle}
      </Typography>
    </>
  );
}

export default IntegrationView;
