import { Box, Button, Stack } from "@mui/material";
import React, { useState } from "react";
import { TabPanel, isDev } from "../../../../../helper";
import AllCustomer from "./AllCustomer";
import AllProducts from "./AllProducts";

const tabs = [
  // {
  //     label: "Overview",
  //     value: "overview",
  // },
  // {
  //     label: "Mapping",
  //     value: "mapping",
  // },
  {
    label: "Customers",
    value: "customers",
  },
  isDev() && { label: "Products", value: "products" },
  // { label: "Settings", value: "settings" },
  // { label: "Activity", value: "history" },
];
const Import = () => {
  const [selectedTab, setSelectedTab] = useState("customers");
  // return <AllCustomer />
  return (
    <Box>
      <Stack
        direction="row"
        sx={{
          backgroundColor: "Background.paper",
          padding: "10px",
          borderBottomColor: "#e8e8e8",
          borderBottomWidth: 0.2,
          borderBottomStyle: "solid",
          overflow: "scroll",
        }}
        spacing={4}
        className="scrollbar-hidden"
      >
        {tabs.map((i) => {
          return (
            <Button
              variant={"text"}
              // variant={selectedTab !== i.value ? "outlined" : "contained"}
              sx={{
                backgroundColor:
                  selectedTab === i.value ? "#e6e6e6" : "transparent",
                color: "#000",
                // color: selectedTab === i.value ? "primary.main" : "#000",
                "&:hover": {
                  backgroundColor:
                    selectedTab === i.value ? "#e6e6e6" : "transparent",
                },
              }}
              onClick={() => setSelectedTab(i.value)}
            >
              {i.label}
            </Button>
          );
        })}
      </Stack>
      <TabPanel index={selectedTab} value={"customers"}>
        <AllCustomer />
      </TabPanel>
      {isDev() && (
        <TabPanel index={selectedTab} value={"products"}>
          <AllProducts />
        </TabPanel>
      )}
    </Box>
  );
};

export default Import;
