export const quickReplyStyles = {
  outerBox: {
    marginBottom: "10px",
    display: "flex",
  },
  innerBox: {
    display: "flex",
    flexDirection: "column",
    marginRight: "25px",
  },
  textFieldBox: {
    width: "405px",
    borderRadius: "10px",
    marginLeft: "10px",
    marginRight: "25px",
    color: "rgb(89, 104, 123)",
    // "&:hover": {
    //   border: "1px solid #008aff",
    // },
  },
  textField: {
    backgroundColor: "white",
    borderRadius: "10px",
    textAlign: "center",
    alignItems: "center",
    border: "none",
    "& fieldset": { border: "none" },
    color: "rgb(89, 104, 123)",
  },
  buttonsBox: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "flexStart",
    marginTop: "10px",
    marginLeft: "10px",
  },
  buttons: {
    backgroundColor: "#DCDCDC",
    border: "1px dashed #4f4f4f",
    borderRadius: "20px",
    textTransform: "none",
    fontWeight: "800px",
    color: "black",
  },
  icon: {
    fontSize: "19px",
    fontWeight: 600,
  },

};
