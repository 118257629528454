/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";

import {
  Grid,
  Typography as MuiTypography,
  useMediaQuery,
  IconButton,
  Chip,
  Box,
  Stack,
  Tooltip,
  CardContent,
  Button,
  Card,
  AvatarGroup,
  Avatar,
} from "@mui/material";
import { spacing, useTheme } from "@mui/system";

import { useDispatch, useSelector } from "react-redux";

import {
  getStoreName,
  getToken,
  getWebsite,
  isArrayWithValues,
  isObjWithValues,
  secondaryLabel,
  secondaryLabelSmall,
} from "../../../helper";

import { useNavigate } from "react-router-dom";

import axios from "axios";

import { UAParser } from "ua-parser-js";

import PricingSetting from "./PricingSetting";

import {
  East,
  HelpOutline,
  NorthEast,
  NorthWest,
  SouthWest,
} from "@mui/icons-material";

import ReactApexChart from "react-apexcharts";

import { fetchDashboardDetails } from "../../../redux/actions/settingActions";
import RetailerDashboard from "./RetailerDashboard";

import PopperTooltip from "../../components/PopperTooltip";

const Typography = styled(MuiTypography)(spacing);

function Default() {
  // const [selectedFilter, setSelectedFilter] = useState("");
  // const [noTopProducts, setNoTopProducts] = useState(false);
  const [isIOS, setIsIOS] = useState(true);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  // const navigate = useNavigate();
  const theme = useTheme();
  const [userTotalData, setUserTotalData] = useState({
    products: 0,
    users: 0,
    catalogs: 0,
    quotations: 0,
  });
  const [allCatalogs, setAllCatalogs] = useState([]);
  const [reduxCatalogs, setReduxCatalog] = useState([]);
  // const [defaultParams, setDefaultParams] = useState({ page: 1, per_page: 20 });
  const reduxAllCatalogs = useSelector((state) => state.product.allCatalogs);
  // const allReduxCustomers = useSelector(
  //   (state) => state.customers.allCustomers
  // );
  const reduxQuotations = useSelector((state) => state.product.quotations);
  const user_products = useSelector(
    (state) => state.user?.store_plan?.user_products
  );
  const reduxTaxonomies = useSelector((state) => state.product.taxonomies);

  const fetchedAllCatalogs = useSelector(
    (state) => state.product.fetchedAllCatalogs
  );
  const dashboardDetails = useSelector(
    (state) => state.settings.dashboardDetails
  );

  const isPhone = useMediaQuery(theme.breakpoints.down("sm"));
  const productSettings = useSelector(
    (state) => state.settings.productSettings
  );

  useEffect(() => {
    // if (!fetchedAllOrders)
    //   dispatch(
    //     getAllOrders({
    //       per_page: 50,
    //       page: 1,
    //     })
    //   );
    if (!dashboardDetails) dispatch(fetchDashboardDetails());
  }, []);

  // const { user } = useAuth();

  const user = useSelector((state) => state.user);
  useEffect(() => {
    if (isObjWithValues(user)) {
      if (user.store_used) {
        let products, catalogs, users, orders;
        if (user.store_used.products) products = user.store_used.products;
        if (user.store_used.catalogs) catalogs = user.store_used.catalogs;
        if (user.store_used.users) users = user.store_used.users;
        if (user.store_used.orders) orders = user.store_used.orders;
        setUserTotalData({
          ...userTotalData,
          catalogs,
          products,
          users,
          orders,
        });
      }
    }
  }, [user]);

  useEffect(() => {
    let ua = new UAParser().getOS();
    if (ua.name && ua.name.toLowerCase() === "ios") setIsIOS(true);
    else setIsIOS(false);
    const temp = async () => {
      // if (!fetchedDashboardCatalogRequests)
      //   dispatch(fetchDashboardCatalogsRequests());
      // if (!isArrayWithValues(reduxAllCatalogs)) dispatch(fetchCatalogs());
      // if (!isArrayWithValues(reduxAllSku)) dispatch(getAllSku());
      // if (!isArrayWithValues(reduxQuotations)) dispatch(getAllQuotation());
      // if (!isObjWithValues(reduxTaxonomies)) dispatch(fetchTaxonomies());
      // if (!isArrayWithValues(allReduxCustomers)) {
      //   let customers = await getAllCustomers();
      //   if (isArrayWithValues(customers)) {
      //     dispatch({
      //       type: "SET_CRM_DATA",
      //       payload: { allCustomers: customers },
      //     });
      //   }
      // }
    };

    temp();
  }, []);

  useEffect(() => {
    if (isArrayWithValues(reduxAllCatalogs) && !isArrayWithValues(allCatalogs))
      setReduxCatalog([].concat(reduxAllCatalogs));
  }, [reduxAllCatalogs]);
  useEffect(() => {
    if (Array.isArray(reduxCatalogs)) setAllCatalogs([...reduxCatalogs]);
  }, [reduxCatalogs]);

  const handleScroll = (event) => {
    console.log("scrollTop: ", event.currentTarget.scrollTop);
    console.log("offsetHeight: ", event.currentTarget.offsetHeight);
  };

  return (
    <div onScroll={handleScroll}>
      <Box sx={{ px: 2 }}>
        <Helmet title="Default Dashboard" />
        <Grid
          justifyContent="space-between"
          direction={"row"}
          container
          spacing={6}
        >
          <Grid
            item
            direction={isPhone ? "column" : "row"}
            sx={{
              display: "flex",
              gap: isPhone ? "0px" : "20px",
              alignItems: isPhone ? "flex-start" : "center",
            }}
          >
            <Typography variant="h3" gutterBottom id="himanshu">
              Dashboard
            </Typography>
            <Typography variant="subtitle1">
              {t("Welcome back")},{" "}
              <span
                style={{ fontWeight: "700" }}
                dangerouslySetInnerHTML={{ __html: getStoreName() }}
              ></span>{" "}
              {/* {t("We've missed you")}.{" "}
              <span role="img" aria-label="Waving Hand Sign">
                👋
              </span> */}
            </Typography>
          </Grid>

          {/* <Grid item>
          <Actions />
        </Grid> */}
        </Grid>

        {/* <Divider my={6} /> */}
        {(user_products?.length === 1 && user_products?.[0] === "crm") ||
        productSettings?.hide_pricing ? (
          <Box sx={{ height: "40px" }} />
        ) : (
          <PricingSetting />
        )}
        {/* {user_type == "supplier" ? (
          <SupplierDashboard
            dashboardDetails={dashboardDetails}
            userTotalData={userTotalData}
            taxonomies={reduxTaxonomies}
            catalogs={allCatalogs}
            fetchedAllCatalogs={fetchedAllCatalogs}
            quotations={reduxQuotations}
          />
        ) : (
          )} */}
        <RetailerDashboard
          dashboardDetails={dashboardDetails}
          userTotalData={userTotalData}
          taxonomies={reduxTaxonomies}
          catalogs={allCatalogs}
          fetchedAllCatalogs={fetchedAllCatalogs}
          quotations={reduxQuotations}
        />
        {/* <Grid container spacing={4}>
          <Grid item xs={12} md={5.5}>
            <Grid container spacing={isMobile ? 3 : 6}>
              <Grid item xs={6} sm={6} md={6} lg={6}>
                <Stats
                  title="Total Products"
                  chipClick={() => navigate("/products")}
                  amount={userTotalData.products || 0}
                  chip="Today"
                  percentagetext="+0%"
                  percentagecolor={green[500]}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6}>
                <Stats
                  backgroundColor={"#DAECFF"}
                  title="Total Catalogs"
                  chipClick={() => navigate("/catalogs")}
                  amount={userTotalData.catalogs || 0}
                  chip="Today"
                  percentagetext="+0%"
                  percentagecolor={green[500]}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6}>
                <Stats
                  backgroundColor={"#FFFFCD"}
                  title="Total Orders"
                  amount={userTotalData.orders || "0"}
                  chipClick={() => navigate("/orders")}
                  chip="Today"
                  percentagetext="+0%"
                  percentagecolor={green[500]}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6}>
                <Stats
                  title="Total Customers"
                  chipClick={() => navigate("/crm")}
                  amount={userTotalData.users || 0}
                  chip="Today"
                  percentagetext="+0%"
                  percentagecolor={green[500]}
                  // illustration="/static/img/illustrations/waiting.png"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6.5}>
            <GoogleAnalyticsDashboard />
          </Grid>
        </Grid>

        <GoogleAnalyticsNumbers />

        <Grid container spacing={5} sx={{ mt: 4, transition: "all 0.3s" }}>
          <Grid item xs={showTopSellingProducts ? 8 : 12}>
            <Heading title={"Recent Orders"} navigateRoute={"/orders"} />

            <RecentOrders />
          </Grid>
          {showTopSellingProducts && (
            <Grid item xs={4}>
              <TopSellingProducts
                setShowTopSellingProducts={setShowTopSellingProducts}
              />
            </Grid>
          )}
        </Grid>
        <TaxonomyCards taxonomies={reduxTaxonomies} />
        <Grid container sx={{ mt: 3, transition: "all 0.3s" }} spacing={4}>
          <Grid item xs={12} lg={4}>
            <Heading title={"Recent Customers"} navigateRoute={"/crm"} />

            <RecentCustomers taxonomies={reduxTaxonomies} />
          </Grid>

          <Grid item xs={12} lg={8}>
            <TopProducts
              catalogs={allCatalogs}
              setNoTopProducts={setNoTopProducts}
              setShowTopProducts={setShowTopProducts}
            />
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          <DigitalGoldDashborad />
        </Grid>

        <Grid container spacing={6} mt={2}>
          <Grid item xs={12} lg={12}>
            <Heading
              title={"Recent catalog requests"}
              navigateRoute={"/catalogs"}
            />

            <Table
              catalogs={allCatalogs}
              fetchedAllCatalogs={fetchedAllCatalogs}
            />
          </Grid>
        </Grid>
        <ChatAnalytics /> */}
      </Box>
    </div>
  );
}

export default Default;

export const AnalyticsChip = ({ label, type, props, hideIcon }) => {
  return (
    <Chip
      sx={{
        height: "24px",
        backgroundColor: type === "success" ? "#EDFEE7" : "#FEE7E7",
        color: type === "success" ? "#63C242" : "#BE3737",
      }}
      label={label}
      icon={
        hideIcon ? (
          <></>
        ) : type === "success" ? (
          <NorthWest
            style={{
              height: "20px",
              width: "20px",
              color: type === "success" ? "#63C242" : "#BE3737",
            }}
          />
        ) : (
          <SouthWest
            style={{
              height: "20px",
              width: "20px",
              color: type === "success" ? "#63C242" : "#BE3737",
            }}
          />
        )
      }
      {...props}
    />
  );
};

export const Heading = ({ title, navigateRoute }) => {
  const navigate = useNavigate();
  return (
    <Typography variant="h3" fontWeight={"400"} sx={{ mb: 3, mt: 3 }}>
      {title}
      {navigateRoute && (
        <IconButton onClick={() => navigate(navigateRoute)}>
          <NorthEast />
        </IconButton>
      )}
    </Typography>
  );
};

export const DashboardTotalCard = ({
  title,
  value,
  valuePrefix,
  valueSuffix,
  values = [],
  suffix,
  backgroundColor,
  onClick,
  breakup,
  graphData,
  hideGraph,
  profiles,
  points,
  valueProps,
  hideNavigate = false,
  valueContainerStyle,
  labelStyle,
  subtitle = "",
  subtitle2 = "",
}) => {
  const [hover, setHover] = useState(false);
  return (
    <>
      <Card
        sx={{
          // border: "0.5px solid #e5eef8",
          // height: "100%",
          backgroundColor: backgroundColor || "background.paper",
          cursor: "pointer",
          height: "100%",
          borderRadius: "8px",
          // ...catalogCardStyle,
          // borderTopWidth: "4px",
          // borderTopColor: "primary.main",
          // borderTopStyle: "solid",
          position: "relative",
        }}
        className="light_shadow_card"
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <Box
          sx={{
            width: "100%",
            height: "3px",
            backgroundColor: "rgba(246, 177, 28, 0.85)",
          }}
        />
        <Box
          sx={{
            maxWidth: hover ? "0%" : "100%",
            height: "3px",
            backgroundColor: "primary.main",
            position: "relative",
            top: -3,
            right: 0,
            left: 0,
            transition: "all 0.5s",
          }}
        />
        <CardContent sx={{ "&:last-child": { paddingBottom: "20px" } }}>
          <Stack
            direction="row"
            alignItems={"center"}
            justifyContent="space-between"
          >
            <Typography
              fontSize="16px"
              fontWeight={"500"}
              sx={{
                color: "text.secondary",
                display: "flex",
                gap: 2,
                alignItems: "center",
                ...labelStyle,
              }}
            >
              {title}
              {isArrayWithValues(points) ? (
                <PopperTooltip points={points}>
                  <HelpOutline
                    fontSize="small"
                    style={{ opacity: "0.5", height: "16px", width: "16px" }}
                    color="text.secondary"
                  />
                </PopperTooltip>
              ) : null}
            </Typography>
            {!hideNavigate ? (
              <Button
                // variant="text"
                onClick={() => onClick && onClick()}
                // variant="contained"
                size="small"
                color="primary"
                sx={{ minWidth: "44px", height: "23px", borderRadius: "9px" }}
              >
                <East style={{ height: "17px", width: "17px" }} />
              </Button>
            ) : null}
          </Stack>

          <Stack
            sx={{ my: 3.5, py: 1, ...valueContainerStyle }}
            spacing={3}
            alignItems="center"
            direction="row"
            justifyContent={"space-between"}
          >
            <Box>
              <Typography
                variant="h5"
                fontSize="26px"
                fontWeight={"600"}
                {...valueProps}
              >
                {valuePrefix || ""} {value || 0} {valueSuffix || ""}
              </Typography>
              <Typography
                // variant="h5"
                sx={{ ...secondaryLabel, mt: 1 }}
              >
                {subtitle}
              </Typography>
              <Typography
                // variant="h5"
                sx={{ ...secondaryLabelSmall, mt: 1 }}
              >
                {subtitle2}
              </Typography>
            </Box>
            {!hideGraph ? (
              <Box
                sx={{
                  maxWidth: "130px",
                  display: hideGraph ? "none" : "initial",
                }}
              >
                <ReactApexChart
                  options={totalCardChartOptions}
                  series={[
                    {
                      data: isArrayWithValues(graphData)
                        ? graphData
                        : [
                            // getRandomInt(10),
                            // getRandomInt(10),
                            // getRandomInt(10),
                            // getRandomInt(10),
                            // getRandomInt(10),
                            // getRandomInt(10),
                            // getRandomInt(10),
                            // getRandomInt(10),
                            // getRandomInt(10),
                            // getRandomInt(10),
                            // getRandomInt(10),
                            // getRandomInt(10),
                            // getRandomInt(10),
                            // getRandomInt(10),
                            // getRandomInt(10),
                            // getRandomInt(10),
                            // getRandomInt(10),
                          ],
                    },
                  ]}
                  type="bar"
                  height={65}
                  width={"100%"}
                />
              </Box>
            ) : null}
          </Stack>
          {isArrayWithValues(profiles) ? (
            <Stack direction="row" alignItems={"center"}>
              <AvatarGroup max={5}>
                {profiles.map((i) => {
                  return (
                    <Avatar
                      alt={i.name}
                      src={i.url}
                      style={{ backgroundColor: "#eff0f2" }}
                    />
                  );
                })}
              </AvatarGroup>
            </Stack>
          ) : null}
          <Stack
            mt={hideGraph ? 5 : 2}
            alignItems="flex-start"
            direction={"row"}
            // justifyContent="space-around"
            // flexWrap="wrap"
            // sx={{ marginTop: "auto" }}
            sx={{ overflow: "scroll" }}
            className="scrollbar-hidden"
            spacing={6}
          >
            {values.map((obj) => {
              return (
                <Box>
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <Typography
                      sx={{ fontSize: "12px", color: "text.secondary" }}
                    >
                      {obj.title}
                    </Typography>
                    {isArrayWithValues(obj.info) ? (
                      <PopperTooltip points={obj.info}>
                        <HelpOutline
                          fontSize="small"
                          style={{
                            opacity: "0.5",
                            height: "16px",
                            width: "16px",
                          }}
                          color="text.secondary"
                        />
                      </PopperTooltip>
                    ) : null}
                  </Stack>
                  <Stack direction="row" alignItems={"center"} sx={{ mt: 1 }}>
                    {obj.prefix && (
                      <Typography
                        ml={1}
                        variant="h5"
                        fontSize="22px"
                        fontWeight={"400"}
                        mr={1}
                      >
                        {obj.prefix || ""}
                      </Typography>
                    )}
                    <Tooltip title={obj.tooltip || ""}>
                      <Typography
                        variant="h5"
                        fontSize="16px"
                        fontWeight={"600"}
                        style={{ ...(obj.valueStyle || {}) }}
                      >
                        {obj.value}
                      </Typography>
                    </Tooltip>
                    <Typography
                      ml={1}
                      variant="body2"
                      fontSize="14px"
                      color="text.secondary"
                    >
                      {obj.suffix || ""}
                    </Typography>
                  </Stack>
                </Box>
              );
            })}
          </Stack>
        </CardContent>
      </Card>
      {/* {isObjWithValues(breakup) && (
          <Card sx={{}}>
            <CardContent>
              {Object.keys(breakup).map((key) => {
                return (
                  <RenderLabelValue
                    value={breakup[key]}
                    label={formatServerValue(key)}
                  />
                );
              })}
            </CardContent>
          </Card>
        )} */}
    </>
  );
};

const totalCardChartOptions = {
  // chart: {
  //   type: "line",
  //   // width: 100,
  //   // height: 35,
  //   sparkline: {
  //     enabled: true,
  //   },
  // },
  // colors: ["#012687", "#0012b9", "#616ada", "#f6b11c"],
  // grid: {
  //   padding: {
  //     top: 5,
  //     bottom: 5,
  //   },
  // },
  // stroke: {
  //   show: true,
  //   curve: "smooth",
  //   lineCap: "butt",
  //   colors: undefined,
  //   width: 3,
  //   dashArray: 0,
  // },
  // tooltip: {
  //   fixed: {
  //     enabled: false,
  //   },
  //   x: {
  //     show: false,
  //   },
  //   y: {
  //     title: {
  //       formatter: function (seriesName) {
  //         return "";
  //       },
  //     },
  //   },
  //   marker: {
  //     show: false,
  //   },
  // },
  chart: {
    type: "bar",
    width: 100,
    height: 35,
    sparkline: {
      enabled: true,
    },
  },
  colors: ["#e8e8e8", "#0012b9", "#f6b11c"],
  plotOptions: {
    bar: { distributed: true, columnWidth: "80%" },
  },
  labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
  xaxis: {
    crosshairs: {
      width: 1,
    },
  },
  tooltip: {
    fixed: {
      enabled: false,
    },
    x: {
      show: false,
    },
    y: {
      title: {
        formatter: function (seriesName) {
          return "";
        },
      },
    },
    marker: {
      show: false,
    },
  },
};
