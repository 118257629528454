import {
  Alert,
  Box,
  Button,
  Chip,
  CircularProgress,
  Divider,
  FormControl,
  IconButton,
  Link,
  MenuItem,
  OutlinedInput,
  Select,
  Snackbar,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import React from "react";
import { useState } from "react";
import { Google as GoogleLogo } from "../../../../Svgs";
import GoogleIntegrations from "./GoogleIntegrations";
import axios from "axios";
import {
  backendApiCall,
  backendDoamin,
  formatServerValue,
  getAxiosError,
  getGlobalRedirectURL,
  getIsBackendMigrated,
  getToken,
  getWebsite,
  isArrayWithValues,
  isDev,
  isObjWithValues,
} from "../../../../helper";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  editSettings,
  EDIT_SETTINGS,
} from "../../../../redux/actions/settingActions";
import { LoadingButton } from "@mui/lab";
import { Cached, CheckCircle, OpenInNew, Refresh } from "@mui/icons-material";
import { setGlobalToast } from "../../../../redux/actions/helperActions";
import GoogleOverview from "./GoogleOverview";
import { backendEndpoints } from "../../../chat_new/apiFunctions";
export const googleScopes = {
  google_siteverification: `https://www.googleapis.com/auth/siteverification.verify_only https://www.googleapis.com/auth/userinfo.email`,
  google_sheets: `https://www.googleapis.com/auth/spreadsheets  https://www.googleapis.com/auth/userinfo.email`,
  google_shopping: `https://www.googleapis.com/auth/content https://www.googleapis.com/auth/userinfo.email`,
  google_business: `https://www.googleapis.com/auth/plus.business.manage https://www.googleapis.com/auth/business.manage https://www.googleapis.com/auth/userinfo.email`,
  google_analytics: `https://www.googleapis.com/auth/analytics https://www.googleapis.com/auth/analytics.edit https://www.googleapis.com/auth/analytics.readonly https://www.googleapis.com/auth/userinfo.email`,
  tag_manager: `https://www.googleapis.com/auth/tagmanager.readonly https://www.googleapis.com/auth/userinfo.email`,
  firebase: `https://www.googleapis.com/auth/firebase.readonly https://www.googleapis.com/auth/userinfo.email`,
  google_calendar:
    "https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/calendar.events https://www.googleapis.com/auth/userinfo.email",
};
const Google = () => {
  const [selectedTab, setSelectedTab] = useState("overview");
  const [googleIntegartion, setGoogleIntegartion] = useState({});
  const [selectedShoppingId, setSelectedShoppingId] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const fetchingAllSettings = useSelector(
    (state) => state.settings.fetchingAllSettings
  );
  const reduxGoogleIntegration = useSelector(
    (state) => state.settings.googleIntegration
  );
  const reduxGoogleSpreadsheet = useSelector(
    (state) => state.settings.googleSpreadsheet
  );

  const onlyChats = useSelector((state) => state.helper.onlyChats);
  const globalToast = useSelector((state) => state.helper.globalToast);
  let authUrl = "https://accounts.google.com/o/oauth2/v2/auth";
  const client_id =
    "448848454597-rbba0c3q0kbcn0f8l893813l98rgra7p.apps.googleusercontent.com";
  const redirect_url_global = getGlobalRedirectURL();
  const redirect_url = `${redirect_url_global}/integrations`;
  const client_secret = "28QrjjfHZljyTaBGE2sNHzZG";

  const [integrating, setIntegrating] = useState(false);
  const [merchantIds, setMerchantIds] = useState([]);
  const [updatingId, setUpdatingId] = useState(false);
  const [refreshingId, setRefreshingId] = useState(false);
  const [business_accounts, setBusiness_accounts] = useState([]);
  const [selected_business_account, setSelected_business_account] =
    useState("");
  const [integratedApp, setIntegartedApp] = useState([]);
  const [tagManagerAccounts, setTagManagerAccounts] = useState([]);
  const [selectedTagManagerAccount, setSelectedTagManagerAccount] =
    useState("");
  const [googleAnalyticsAccounts, setGoogleAnalyticsAccounts] = useState([]);
  const [selectedAnalyticsAccount, setSelectedAnalyticsAccount] = useState("");
  const [updatingApp, setUpdatingApp] = useState(false);
  const [refreshingApp, setRefreshingApp] = useState(false);
  const [googleShopedProductCount, setGoogleShopedProductCount] = useState(0);
  const [firebaseAccounts, setFirebaseAccounts] = useState([]);
  const [selectedFirebaseAccount, setSelectedFirebaseAccount] = useState([]);
  const [selectedSheetExcluded, setSelectedSheetExcluded] = useState([]);
  const getSpreadSheetData = async (access_token) => {
    console.log("access token", access_token);
    const res = await axios({
      url: `https://sheets.googleapis.com/v4/spreadsheets`,
      headers: {
        Authorization: `Bearer ${access_token}`,
        "Content-Type": "application/json",
      },
      data: { properties: { title: "Tanika - Product Sheet" } },
      method: "POST",
    });
    return res;
  };

  useEffect(() => {
    if (
      isObjWithValues(reduxGoogleSpreadsheet) &&
      isArrayWithValues(reduxGoogleSpreadsheet.excluded_sheet_columns)
    )
      setSelectedSheetExcluded(reduxGoogleSpreadsheet.excluded_sheet_columns);
  }, [reduxGoogleSpreadsheet]);
  useEffect(() => {
    if (isObjWithValues(reduxGoogleIntegration))
      setGoogleIntegartion(reduxGoogleIntegration);
  }, [reduxGoogleIntegration]);
  useEffect(() => {
    for (const key in googleIntegartion) {
      if (googleIntegartion[key].connected.includes("google_shopping")) {
        if (googleIntegartion[key]?.google_shopping) {
          let google_shopping = googleIntegartion[key]?.google_shopping || [];
          setMerchantIds(
            google_shopping.find((obj) => obj.merchant_ids)?.merchant_ids || []
          );
          setSelectedShoppingId(
            google_shopping.find((obj) => obj.selected_merchant_id)
              ?.selected_merchant_id || ""
          );
          let countObj = googleIntegartion[key]?.google_shopping.find(
            (obj) => obj.selected_merchant_products
          );
          setGoogleShopedProductCount(
            countObj?.selected_merchant_products || 0
          );
        }
      }
      if (googleIntegartion[key].connected.includes("google_business")) {
        let google_business = googleIntegartion[key]?.google_business || [];
        setBusiness_accounts(
          google_business.find((obj) => obj.business_accounts)
            ?.business_accounts || []
        );
        setSelected_business_account(
          google_business.find((obj) => obj.selected_business_id)
            ?.selected_business_id || ""
        );
      }
      if (googleIntegartion[key].connected.includes("firebase")) {
        let firebase = googleIntegartion[key]?.firebase || [];
        setFirebaseAccounts(
          firebase.find((obj) => obj.firebase_apps)?.firebase_apps || []
        );
        setSelectedFirebaseAccount(
          firebase.find((obj) => obj.selected_firebase_app)
            ?.selected_firebase_app?.displayName || ""
        );
        // setSelected_business_account(googleIntegartion[key]?.google_business.find(obj => obj.selected_business_id)?.selected_business_id || "")
      }
      if (googleIntegartion[key].connected.includes("tag_manager")) {
        if (googleIntegartion[key]?.tag_manager) {
          let tag_manager = googleIntegartion[key]?.tag_manager || [];
          setTagManagerAccounts(
            tag_manager.find((obj) => obj.tag_manager_accounts)
              ?.tag_manager_accounts || []
          );
          setSelectedTagManagerAccount(
            tag_manager.find((obj) => obj?.selected_tag_manager_id)
              ?.selected_tag_manager_id || ""
          );
        }
      }
      if (googleIntegartion[key].connected.includes("google_analytics")) {
        if (googleIntegartion[key]?.google_analytics) {
          let google_analytics = googleIntegartion[key]?.google_analytics || [];
          let analyticsArr =
            google_analytics.find((obj) => obj.analytics_accounts)
              ?.analytics_accounts || [];
          let arr = [];

          for (const iterator of analyticsArr) {
            if (iterator.propertySummaries)
              arr = [...arr, ...iterator.propertySummaries];
          }
          let val = google_analytics.find(
            (obj) => obj.selected_analytics_id
          )?.selected_analytics_id;
          let selectVal = arr.find((obj) => obj.property.split("/")[1] === val);
          setSelectedAnalyticsAccount(selectVal?.displayName);
          setGoogleAnalyticsAccounts(arr);
          console.log(arr);
        }
      }
    }
  }, [googleIntegartion]);

  useEffect(() => {
    let params_string = location.search;
    // let url = new URL(params_string);
    let code = new URLSearchParams(params_string).get("code");
    let app = new URLSearchParams(params_string).get("state");
    if (!code || !app) return;

    console.log(app);
    console.log(code);
    if (
      app?.includes("google") ||
      app?.includes("tag") ||
      app?.includes("firebase")
    ) {
      console.log("get code");
      getAccessTokenFromCode(code, app.split(","));
      setSelectedTab("integration");
    }
    // if (code)
    let { hash } = location;
    navigate("/integrations");
  }, []);
  const bacis_info_google = async (access_token) => {
    try {
      let info = await axios({
        url: "https://www.googleapis.com/oauth2/v3/userinfo",
        params: { access_token },
      });
      return info.data;
    } catch (error) {
      console.log(error);
    }
  };

  const getTagManagerContiner = async (objVal, objKey) => {
    let channel = "tag_manager";
    let website = getWebsite();
    let token = getToken();
    if (!website && !token) return false;
    setRefreshingApp(channel);
    let updateRes;
    let obj = {};
    let key = "";
    // let access_token = ""
    if (isObjWithValues(objVal)) {
      obj = objVal;
      key = objKey;
    } else {
      for (const name in reduxGoogleIntegration) {
        if (reduxGoogleIntegration[name].connected.includes(channel)) {
          obj = reduxGoogleIntegration[name];
          key = name;
          // access_token = reduxGoogleIntegration[name].access_token
        }
      }
    }
    let idsRes = await getChannelData(channel, "/accounts");
    if (idsRes?.account) {
      let ids = idsRes?.account.map((obj) => obj.accountId);
      let accountsArr = [];
      for (const iterator of ids) {
        let accounts = await getChannelData(
          channel,
          `/accounts/${iterator}/containers`
        );
        let containerArr = accounts?.container.map((obj) => {
          let { path, accountId, containerId, name, publicId, usageContext } =
            obj;
          return {
            path,
            accountId,
            containerId,
            name,
            publicId,
            usageContext,
          };
        });
        accountsArr = [...accountsArr, ...containerArr];
      }
      console.log(accountsArr);
      if (obj[channel]?.find((obj) => obj["tag_manager_accounts"])) {
        obj = {
          ...obj,
          [channel]: obj[channel].map((obj) =>
            obj["tag_manager_accounts"]
              ? { ["tag_manager_accounts"]: accountsArr }
              : obj
          ),
        };
      } else {
        let objArr = obj[channel];
        obj = {
          ...obj,
          [`${channel}`]: [{ [`${"tag_manager_accounts"}`]: accountsArr }],
        };
      }

      console.log(obj, "serverr obj");
      let website = getWebsite();
      let token = getToken();
      if (website && token) {
        updateRes = await axios({
          url: `${website}/wp-json/store/v1/settings/google_integration`,
          method: "PUT",
          headers: {
            Authorization: `Basic ${token}`,
          },
          data: {
            setting: "google_integration",
            data: {
              [key]: obj,
            },
          },
        });
        console.log({
          [key]: obj,
        });
        console.log(updateRes.data);
        if (updateRes.data.success) {
          if (objKey)
            dispatch(
              setGlobalToast({
                ...globalToast,
                show: true,
                message: "Refresh Tag Manager Account succesfully",
                severity: "success",
              })
            );
          dispatch({
            type: EDIT_SETTINGS,
            payload: {
              googleIntegration: updateRes.data.data,
            },
          });
        }
        setRefreshingApp(false);
        return updateRes.data.data;
      }
    }
  };

  const getFirebaseAccount = async (objVal, objKey) => {
    let website = getWebsite();
    let token = getToken();
    if (!website && !token) return false;
    let channel = "firebase";
    setRefreshingApp(channel);
    // let updateRes
    let obj = {};
    let key = "";
    // let access_token = ""
    if (isObjWithValues(objVal)) {
      obj = objVal;
      key = objKey;
    } else {
      for (const name in reduxGoogleIntegration) {
        if (reduxGoogleIntegration[name].connected.includes(channel)) {
          obj = reduxGoogleIntegration[name];
          key = name;
          console.log(obj);
          // access_token = reduxGoogleIntegration[name].access_token
        }
      }
    }
    let idsRes = await getChannelData(channel, "/projects");
    if (idsRes?.results) {
      let ids = idsRes?.results.map((obj) => obj.name);
      let accountsArr = [];
      for await (const iterator of ids) {
        console.log(`${iterator}/webApps`);
        let accounts = await getChannelData(channel, `/${iterator}/webApps`);

        if (accounts?.apps) accountsArr = [...accountsArr, ...accounts.apps];
        console.log(accounts);
      }
      console.log(accountsArr, obj);
      if (obj[channel]?.find((obj) => obj[channel])) {
        obj = {
          ...obj,
          [channel]: obj[channel].map((obj) =>
            obj[channel] ? { firebase_apps: accountsArr } : obj
          ),
        };
      } else {
        let objArr = obj[channel];
        if (objArr)
          obj = {
            ...obj,
            [`${channel}`]: [...objArr, { [`firebase_apps`]: accountsArr }],
          };
        if (!objArr)
          obj = {
            ...obj,
            [`${channel}`]: [{ [`firebase_apps`]: accountsArr }],
          };
      }
      console.log(obj);

      // console.log(obj, "serverr obj");
      let website = getWebsite();
      let token = getToken();
      let updateRes = await axios({
        url: `${website}/wp-json/store/v1/settings/google_integration`,
        method: "PUT",
        headers: {
          Authorization: `Basic ${token}`,
        },
        data: {
          setting: "google_integration",
          data: {
            [key]: obj,
          },
        },
      });
      console.log({
        [key]: obj,
      });
      console.log(updateRes.data);
      if (updateRes.data.success) {
        if (!objKey)
          dispatch(
            setGlobalToast({
              ...globalToast,
              show: true,
              message: "Refresh Firebase Account succesfully",
              severity: "success",
            })
          );
        dispatch({
          type: EDIT_SETTINGS,
          payload: {
            googleIntegration: updateRes.data.data,
          },
        });
      }
      setRefreshingApp(false);
      if (objKey) return updateRes.data.data;
    }
  };
  const getGoogleBusinessAccounts = async (accces_token) => {
    try {
      let googleBusinessRes = await axios({
        url: "https://shoppingcontent.googleapis.com/content/v2.1/accounts",
        headers: { Authorization: `Bearer ${accces_token}` },
      });
      console.log(googleBusinessRes);
      if (googleBusinessRes.data.accountIdentifiers) {
        return googleBusinessRes.data.accountIdentifiers;
      }
    } catch (error) {
      console.log(error);
    }
  };
  const siteVerification = async (accces_token) => {
    let website = getWebsite();
    let token = getToken();
    if (!website && !token) return false;
    try {
      let siteVerificationRes = axios({
        url: "https://www.googleapis.com/siteVerification/v1/token",
        headers: {
          Authorization: `Bearer ${accces_token}`,
          data: {
            site: {
              type: "SITE",
              identifier: website,
            },
            verificationMethod: "META",
          },
        },
      });
    } catch (error) {}
  };
  const getAccessTokenFromCode = async (code, app) => {
    let website = getWebsite();
    let token = getToken();
    if (!website && !token) return false;
    setIntegrating(true);
    let dataSend = {
      code,
      client_secret,
      client_id,
      redirect_uri: redirect_url,
      grant_type: "authorization_code",
    };
    try {
      let googleRes = await axios({
        url: "https://accounts.google.com/o/oauth2/token",
        method: "POST",
        data: dataSend,
      });
      let { data } = googleRes;
      if (data.access_token && data.refresh_token) {
        console.log(data.access_token, data.refresh_token);
        let time = Math.round(Date.now() / 1000);
        let { access_token, refresh_token } = data;
        let info = await bacis_info_google(access_token);
        let obj = {
          connected: app,
          access_token,
          refresh_token,
          scope: app.map((i) => googleScopes[i]).join(" "),
          info,
        };
        if (app.includes("google_shopping")) {
          let idsRes = await getMerchantAccount(access_token);
          console.log(idsRes);
          obj = { ...obj, google_shopping: [{ merchant_ids: idsRes }] };
        }

        console.log(obj);
        let googleIntegartionData;

        if (getIsBackendMigrated("chats") && false) {
          googleIntegartionData = await backendApiCall({
            endpoint: `${backendEndpoints.settings}/google_integration`,
            method: "PUT",
            data: {
              data: {
                [time]: {
                  ...obj,
                },
              },
            },
          });
          // googleIntegartionData = await axios({
          //   url: `${backendDoamin}/settings/google_integration`,
          //   headers: {
          //     Authorization: `Bearer ${token}`,
          //   },
          //   data: {
          //     type: "meta_integration",
          //     data: {
          //       [time]: {
          //         ...obj,
          //       },
          //     },
          //   },
          //   method: "PUT",
          // });
        } else
          googleIntegartionData = await axios({
            url: `${website}/wp-json/store/v1/settings/google_integration`,
            headers: {
              Authorization: `Basic ${token}`,
            },
            data: {
              type: "meta_integration",
              data: {
                [time]: {
                  ...obj,
                },
              },
            },
            method: "PUT",
          });

        console.log(googleIntegartionData, "<<<<< googleIntegartionData");
        if (
          googleIntegartionData.data.success ||
          googleIntegartionData?.success
        ) {
          if (app.includes("google_business")) {
            let idsRes = await refreshAccountsGoogle(
              "google_business",
              "/accounts",
              "business_accounts",
              "accounts",
              {
                ...obj,
              },
              time
            );
            console.log(obj, idsRes, "google_business");
            obj = idsRes[time];
          }
          if (app.includes("tag_manager")) {
            console.log("fetching tag manager", obj);
            let idsRes = await getTagManagerContiner(
              {
                ...obj,
              },
              time
            );
            obj = idsRes[time];
          }
          if (app.includes("firebase")) {
            let idsRes = await getFirebaseAccount(
              {
                ...obj,
              },
              time
            );
            obj = idsRes[time];
          }
          if (app.includes("google_analytics")) {
            let idsRes = await refreshAccountsGoogle(
              "google_analytics",
              "/accountSummaries",
              "analytics_accounts",
              "accountSummaries",
              {
                ...obj,
              },
              time
            );
            obj = idsRes[time];
          }
          if (
            !app.includes("google_business") &&
            !app.includes("tag_manager") &&
            !app.includes("google_analytics") &&
            !app.includes("firebase")
          ) {
            dispatch({
              type: EDIT_SETTINGS,
              payload: {
                googleIntegration:
                  googleIntegartionData.data.data ||
                  googleIntegartionData?.data,
                fetchingMetaIntegration: false,
              },
            });
          }
        }
      }
    } catch (e) {
      console.log(getAxiosError(e));
    }
    setIntegrating(false);
  };

  const refreshAccountsGoogle = async (
    channel,
    endpoint,
    keyName,
    resKey,
    objVal,
    objKey
  ) => {
    let website = getWebsite();
    let token = getToken();
    if (!website && !token) return false;
    setRefreshingApp(channel);
    let updateRes;
    let obj = {};
    let key = "";
    // let access_token = ""
    if (isObjWithValues(objVal)) {
      obj = objVal;
      key = objKey;
    } else {
      for (const name in reduxGoogleIntegration) {
        if (reduxGoogleIntegration[name].connected.includes(channel)) {
          obj = reduxGoogleIntegration[name];
          key = name;
          // access_token = reduxGoogleIntegration[name].access_token
        }
      }
    }
    let idsRes = await getChannelData(channel, endpoint);
    console.log(idsRes);
    // obj = { ...obj, google_shopping: idsRes }
    if (obj[channel]?.find((obj) => obj[keyName])) {
      obj = {
        ...obj,
        [channel]: obj[channel].map((obj) =>
          obj[keyName]
            ? {
                [keyName]:
                  channel === "google_analytics"
                    ? idsRes.accountSummaries.filter(
                        (obj) => obj.propertySummaries
                      )
                    : idsRes[resKey],
              }
            : obj
        ),
      };
    } else {
      let objArr = obj[channel] || [];
      obj = {
        ...obj,
        [`${channel}`]: [
          ...objArr,
          {
            [`${keyName}`]:
              channel === "google_analytics"
                ? idsRes.accountSummaries.filter((obj) => obj.propertySummaries)
                : idsRes[resKey],
          },
        ],
      };
    }

    console.log(obj, "serverr obj");
    updateRes = await axios({
      url: `${website}/wp-json/store/v1/settings/google_integration`,
      method: "PUT",
      headers: {
        Authorization: `Basic ${token}`,
      },
      data: {
        setting: "google_integration",
        data: {
          [key]: obj,
        },
      },
    });
    console.log({
      [key]: obj,
    });
    console.log(updateRes.data);
    if (updateRes.data.success) {
      if (!objKey)
        dispatch(
          setGlobalToast({
            ...globalToast,
            show: true,
            message: `Refresh ${channel} Account succesfully`,
            severity: "success",
          })
        );
      dispatch({
        type: EDIT_SETTINGS,
        payload: {
          googleIntegration: updateRes.data.data,
        },
      });
    }
    setRefreshingApp(false);
    if (objKey) return updateRes.data.data;
  };

  const updateMerchantAccount = async () => {
    let website = getWebsite();
    let token = getToken();
    if (!website && !token) return false;
    setUpdatingId(true);
    let obj = {};
    let key = "";
    for (const name in googleIntegartion) {
      if (googleIntegartion[name].connected.includes("google_shopping")) {
        obj = googleIntegartion[name];
        key = name;
      }
    }
    if (obj?.google_shopping?.find((obj) => obj.selected_merchant_id)) {
      obj = {
        ...obj,
        google_shopping: obj.google_shopping.map((obj) =>
          obj.selected_merchant_id
            ? { selected_merchant_id: selectedShoppingId }
            : obj
        ),
      };
    } else {
      obj = {
        ...obj,
        google_shopping: [
          ...obj.google_shopping,
          { selected_merchant_id: selectedShoppingId },
        ],
      };
    }
    // obj = { ...obj, google_shopping: obj.google_shopping.map(obj => obj.merchantId === selectedShoppingId ? { ...obj, enable: true } : { ...obj, enable: false }) }
    let updateRes = await axios({
      url: `${website}/wp-json/store/v1/settings/google_integration`,
      method: "PUT",
      headers: {
        Authorization: `Basic ${token}`,
      },
      data: {
        setting: "google_integration",
        data: {
          [key]: obj,
        },
      },
    });
    console.log({
      [key]: obj,
    });
    console.log(updateRes.data);
    if (updateRes.data.success) {
      dispatch(
        setGlobalToast({
          ...globalToast,
          show: true,
          message: "Update Merchant Account succesfully",
          severity: "success",
        })
      );
      dispatch({
        type: EDIT_SETTINGS,
        payload: {
          googleIntegration: updateRes.data.data,
        },
      });
    }
    setUpdatingId(false);
  };

  const getMerchantAccount = async (accces_token) => {
    try {
      let merchantIdRes = await axios({
        url: "https://shoppingcontent.googleapis.com/content/v2.1/accounts/authinfo",
        headers: { Authorization: `Bearer ${accces_token}` },
      });
      console.log(merchantIdRes);
      if (merchantIdRes.data.accountIdentifiers) {
        return merchantIdRes.data.accountIdentifiers;
      }
    } catch (error) {
      console.log(error);
    }
  };
  const onRefreshAccount = async () => {
    let website = getWebsite();
    let token = getToken();
    if (!website && !token) return false;
    setRefreshingId(true);
    let obj = {};
    let key = "";
    let access_token = "";
    for (const name in googleIntegartion) {
      if (googleIntegartion[name].connected.includes("google_shopping")) {
        obj = googleIntegartion[name];
        key = name;
        access_token = googleIntegartion[name].access_token;
      }
    }
    let idsRes = await getChannelData("google_shopping", "/accounts/authinfo");
    console.log(idsRes);
    // obj = { ...obj, google_shopping: idsRes }
    if (obj?.google_shopping?.find((obj) => obj.merchant_ids)) {
      obj = {
        ...obj,
        google_shopping: obj.google_shopping.map((obj) =>
          obj.merchant_ids ? { merchant_ids: idsRes.accountIdentifiers } : obj
        ),
      };
    } else {
      obj = {
        ...obj,
        google_shopping: [
          ...obj.google_shopping,
          { merchant_ids: idsRes.accountIdentifiers },
        ],
      };
    }
    // obj = { ...obj, google_shopping: obj.google_shopping.map(obj => obj.merchantId === selectedShoppingId ? { ...obj, enable: true } : { ...obj, enable: false }) }
    let updateRes = await axios({
      url: `${website}/wp-json/store/v1/settings/google_integration`,
      method: "PUT",
      headers: {
        Authorization: `Basic ${token}`,
      },
      data: {
        setting: "google_integration",
        data: {
          [key]: obj,
        },
      },
    });
    console.log({
      [key]: obj,
    });
    console.log(updateRes.data);
    if (updateRes.data.success) {
      dispatch(
        setGlobalToast({
          ...globalToast,
          show: true,
          message: "Refresh Merchant Account succesfully",
        })
      );
      dispatch({
        type: EDIT_SETTINGS,
        payload: {
          googleIntegration: updateRes.data.data,
        },
      });
    }
    setRefreshingId(false);
  };
  useEffect(() => {
    if (isObjWithValues(reduxGoogleIntegration))
      setGoogleIntegartion(reduxGoogleIntegration);
  }, [reduxGoogleIntegration]);
  useEffect(() => {
    if (!isObjWithValues(googleIntegartion)) {
      setIntegartedApp([]);
      return;
    }
    let integratedApp = [];
    for (const key in googleIntegartion) {
      let app = googleIntegartion[key].connected;
      integratedApp = [...integratedApp, ...app];
    }
    setIntegartedApp(integratedApp);
  }, [reduxGoogleIntegration, googleIntegartion]);

  const getChannelData = async (app, endpoint) => {
    let website = getWebsite();
    let token = getToken();
    if (!website && !token) return false;
    try {
      console.log(`${website}/wp-json/store/v1/channel/${app}`, endpoint);
      let res = await axios({
        url: `${website}/wp-json/store/v1/channel/${app}`,
        method: "GET",
        params: {
          endpoint: endpoint,
        },
      });
      console.log(res);
      return res.data;
    } catch (error) {
      getAxiosError(error);
    }
  };

  const updadateAccountGoogle = async (app, setKey, value) => {
    let website = getWebsite();
    let token = getToken();
    if (!website && !token) return false;
    setUpdatingApp(app);
    let obj = {};
    let key = "";
    for (const name in googleIntegartion) {
      if (googleIntegartion[name].connected.includes(app)) {
        obj = googleIntegartion[name];
        key = name;
        if (obj[app]?.find((obj) => obj[setKey])) {
          obj = {
            ...obj,
            [app]: obj[app].map((obj) =>
              obj[setKey] ? { [setKey]: value } : obj
            ),
          };
        } else {
          console.log(obj[app], app, obj);
          let appDataArray = obj[app] || [];
          appDataArray.push({ [setKey]: value });
          obj = { ...obj, [app]: appDataArray };
        }
      }
    }

    // obj = { ...obj, google_shopping: obj.google_shopping.map(obj => obj.merchantId === selectedShoppingId ? { ...obj, enable: true } : { ...obj, enable: false }) }
    let updateRes = await axios({
      url: `${website}/wp-json/store/v1/settings/google_integration`,
      method: "PUT",
      headers: {
        Authorization: `Basic ${token}`,
      },
      data: {
        setting: "google_integration",
        data: {
          [key]: obj,
        },
      },
    });
    console.log({
      [key]: obj,
    });
    console.log(updateRes.data);
    if (updateRes.data.success) {
      dispatch(
        setGlobalToast({
          ...globalToast,
          show: true,
          message: `Update ${app} Account succesfully`,
        })
      );
      dispatch({
        type: EDIT_SETTINGS,
        payload: {
          googleIntegration: updateRes.data.data,
        },
      });
    }
    setUpdatingApp(false);
  };

  const firebaseUpdateId = async () => {
    setUpdatingApp("firebase");
    let selectedObj = firebaseAccounts.find(
      (obj) => obj.displayName === selectedFirebaseAccount
    );
    let res = await getChannelData(
      "firebase",
      `/projects/${selectedObj.projectId}/webApps/${selectedObj.appId}/config`
    );
    console.log(res);
    updadateAccountGoogle("firebase", "selected_firebase_app", {
      ...res,
      displayName: selectedObj.displayName,
    });
    setUpdatingApp("firebase");
  };
  const googleSheetClick = () => {
    if (isObjWithValues(reduxGoogleSpreadsheet)) {
      let { spreadSheet_product_id } = reduxGoogleSpreadsheet;
      if (spreadSheet_product_id)
        window.open(
          `https://docs.google.com/spreadsheets/d/${spreadSheet_product_id}/edit?usp=sharing`
        );
    }
  };

  const handleSheetExcludedChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedSheetExcluded(
      typeof value === "string" ? value.split(",") : value
    );
  };

  const onSheetExcludeInclude = async () => {
    let website = await getWebsite();
    let token = await getToken();
    setRefreshingApp("google_sheet");
    let { data } = await axios({
      url: `${website}/wp-json/store/v1/settings/google_spreadsheet`,
      headers: {
        Authorization: `Basic ${token}`,
      },
      data: {
        type: "google_spreadsheet",
        data: {
          excluded_sheet_columns: selectedSheetExcluded,
        },
      },
      method: "PUT",
    });
    if (data.success && data.data) {
      dispatch(
        editSettings({
          googleSpreadsheet: data.data,
        })
      );
      dispatch(
        setGlobalToast({
          show: true,
          message: "Google Sheet Exclude values are updated",
          severity: "success",
        })
      );
    }
    setRefreshingApp(false);
  };
  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={integrating}
        // onClose={handleClose}
        message={
          <Stack direction="row" spacing={1.5} alignItems={"center"}>
            <CheckCircle color="success" />
            <Box>
              <Typography sx={{ fontSize: "14px", fontWeight: "500" }}>
                App Integrating please wait...
              </Typography>
              {/* <Button
                color={"success"}
                size="small"
                onClick={() => {
                  navigate(globalToast.link);
                  dispatch(setGlobalToast({ ...globalToast, show: false }));
                }}
                sx={{ mt: 1, marginLeft: "auto" }}
              >
                Open
              </Button> */}
            </Box>
          </Stack>
        }
        severity={"success"}
      >
        {/* <Alert severity={"success"} sx={{ width: "100%" }}>
          {"App Integrating please wait..."}
        </Alert> */}
      </Snackbar>

      <Box sx={{ padding: 0 }}>
        <Stack
          sx={{
            position: "sticky",
            backgroundColor: "#fff",
            zIndex: 13,
            margin: 0,
            padding: "12px",
            top: "0",
          }}
        >
          <Stack
            direction="row"
            justifyContent={"space-between"}
            alignItems="center"
          >
            <Typography
              variant="h3"
              sx={{ display: "flex", alignItems: "center", gap: "10px" }}
            >
              <GoogleLogo /> Google
            </Typography>

            {fetchingAllSettings && (
              <Stack direction={"row"} alignItems="center" sx={{ gap: "10px" }}>
                <CircularProgress size={"15px"} />{" "}
                <Typography>Fetching Integrations...</Typography>
              </Stack>
            )}
          </Stack>
          {/* <Divider sx={{ mt: 4 }} /> */}
          <Tabs
            onChange={(e, newValue) => setSelectedTab(newValue)}
            value={selectedTab}
            sx={{ borderBottom: 1, borderColor: "divider" }}
          >
            <Tab label="Overview" value="overview" />
            {/* <Tab label="How to do?" value="how_to_do" /> */}
            {/* <Tab label="Details" value="details" /> */}
            <Tab
              label="Integration"
              value="integration"
              className="Google-integration-tab"
            />
            {onlyChats ||
            !isArrayWithValues(
              integratedApp?.filter((i) =>
                [
                  "google_business",
                  "tag_manager",
                  "google_shopping",
                  "google_analytics",
                  "firebase",
                  "google_sheets",
                ].includes(i)
              )
            ) ? null : (
              <Tab label="Settings" value="settings" />
            )}
          </Tabs>
        </Stack>
        <TabPanel value={selectedTab} index={"overview"}>
          <GoogleOverview />
        </TabPanel>
        <TabPanel value={selectedTab} index={"how_to_do"}>
          <Typography>How to do</Typography>
        </TabPanel>
        <TabPanel value={selectedTab} index={"details"}>
          <Typography>Details</Typography>
        </TabPanel>
        <TabPanel value={selectedTab} index={"integration"}>
          <GoogleIntegrations
            client_id={client_id}
            redirect_url={redirect_url}
            authUrl={authUrl}
          />
        </TabPanel>
        <TabPanel value={selectedTab} index={"settings"}>
          <Box sx={{ maxWidth: "700px", ml: 2 }}>
            {integratedApp.includes("google_business") && (
              <>
                <Box sx={{ maxWidth: "500px" }}>
                  <Stack
                    direction={"row"}
                    alignItems="center"
                    justifyContent={"space-between"}
                    // sx={{ gap: "18px", maxWidth: "600px" }}
                  >
                    <Typography variant="h6">Google Business</Typography>
                    {/* <LoadingButton size="small" startIcon={<Refresh />}>
                  Refresh Account
                </LoadingButton> */}
                    {/* <LoadingButton
                  sx={{
                    fontSize: 0,
                    padding: 0,
                    borderRadius: "50%",
                    minWidth: 0,
                  }}
                  size="small"
                  loading={refreshingApp === "google_business"}
                >
                  <IconButton
                    sx={
                      refreshingApp === "google_business"
                        ? { display: "none" }
                        : { padding: 0 }
                    }
                    onClick={() =>
                      refreshAccountsGoogle(
                        "google_business",
                        "/accounts",
                        "business_accounts",
                        "accounts"
                      )
                    }
                    aria-label="delete"
                  >
                    <Refresh />
                  </IconButton>
                </LoadingButton> */}
                  </Stack>
                  <Stack
                    direction={"row"}
                    alignItems="center"
                    sx={{ gap: "18px", minHeight: "30px", mt: 2 }}
                  >
                    <Select
                      // sx={{ maxWidth: "500px" }}
                      fullWidth
                      value={selected_business_account || ""}
                      size="small"
                      onChange={(e) =>
                        setSelected_business_account(e.target.value)
                      }
                    >
                      {business_accounts.map((obj) => (
                        <MenuItem value={obj.name}>{obj.accountName}</MenuItem>
                      ))}
                      {!isArrayWithValues(business_accounts) ? (
                        <MenuItem disabled>
                          <em>No account found</em>
                        </MenuItem>
                      ) : null}
                    </Select>
                  </Stack>
                  <Stack
                    direction={"row"}
                    alignItems="center"
                    justifyContent={"flex-end"}
                    sx={{
                      // gap: "18px",
                      minHeight: "30px",
                      // maxWidth: "500px",
                      mt: 2,
                    }}
                  >
                    <LoadingButton
                      size="small"
                      startIcon={<Refresh />}
                      onClick={() =>
                        refreshAccountsGoogle(
                          "google_business",
                          "/accounts",
                          "business_accounts",
                          "accounts"
                        )
                      }
                    >
                      Refresh Account
                    </LoadingButton>
                    <LoadingButton
                      loading={updatingApp === "google_business"}
                      variant={"contained"}
                      disabled={!selected_business_account}
                      size="small"
                      onClick={() =>
                        updadateAccountGoogle(
                          "google_business",
                          "selected_business_id",
                          selected_business_account
                        )
                      }
                    >
                      Update
                    </LoadingButton>
                  </Stack>
                  {/* {selected_business_account && <Box sx={{ mt: 3 }}>

                        <Typography variant='subtitle2'  >Type : {business_accounts.find(obj => obj.name === selected_business_account).type}</Typography>
                        <Typography variant='subtitle2' >Verification State : {business_accounts.find(obj => obj.name === selected_business_account).verificationState}</Typography>

                    </Box>} */}
                </Box>
                <Divider sx={{ mt: 5, mb: 5 }} />
              </>
            )}
            {integratedApp.includes("tag_manager") && (
              <>
                <Box sx={{ maxWidth: "500px" }}>
                  <Stack
                    direction={"row"}
                    alignItems="center"
                    sx={{ gap: "18px", minHeight: "30px" }}
                  >
                    <Typography variant="h6">Tag Manager</Typography>
                    {/* <LoadingButton
                  sx={{
                    fontSize: 0,
                    padding: 0,
                    borderRadius: "50%",
                    minWidth: 0,
                  }}
                  size="small"
                  loading={refreshingApp === "tag_manager"}
                >
                  <IconButton
                    sx={
                      refreshingApp === "tag_manager"
                        ? { display: "none" }
                        : { padding: 0 }
                    }
                    onClick={() => getTagManagerContiner()}
                    aria-label="delete"
                  >
                    <Refresh />
                  </IconButton>
                </LoadingButton> */}
                  </Stack>
                  <Stack
                    direction={"row"}
                    alignItems="center"
                    sx={{ gap: "18px" }}
                  >
                    <Select
                      sx={{}}
                      value={selectedTagManagerAccount}
                      fullWidth
                      size="small"
                      onChange={(e) =>
                        setSelectedTagManagerAccount(e.target.value)
                      }
                    >
                      {tagManagerAccounts.map((obj) => (
                        <MenuItem value={obj.publicId}>
                          {obj.name} - {obj.publicId}
                        </MenuItem>
                      ))}
                      {!isArrayWithValues(tagManagerAccounts) ? (
                        <MenuItem disabled>
                          <em>No account found</em>
                        </MenuItem>
                      ) : null}
                    </Select>
                    {/* <LoadingButton
                        loading={refreshingApp === "tag_manager"}
                        onClick={() => getTagManagerContiner()}
                        startIcon={<Refresh />}
                        sx={{ mr: 3, ml: 3 }}>
                        Refresh
                    </LoadingButton> */}
                  </Stack>
                  <Stack
                    direction={"row"}
                    alignItems="center"
                    justifyContent={"flex-end"}
                    sx={{ mt: 3 }}
                  >
                    <LoadingButton
                      size="small"
                      loading={refreshingApp === "tag_manager"}
                      onClick={() => getTagManagerContiner()}
                      startIcon={<Refresh />}
                    >
                      Refresh Account
                    </LoadingButton>
                    <LoadingButton
                      loading={updatingApp === "tag_manager"}
                      variant={"contained"}
                      size="small"
                      disabled={!selectedTagManagerAccount}
                      onClick={() =>
                        updadateAccountGoogle(
                          "tag_manager",
                          "selected_tag_manager_id",
                          selectedTagManagerAccount
                        )
                      }
                    >
                      Update
                    </LoadingButton>
                  </Stack>
                </Box>
                <Divider sx={{ mt: 5, mb: 5 }} />
              </>
            )}

            {integratedApp.includes("google_shopping") && (
              <>
                <Box sx={{ maxWidth: "500px" }}>
                  <Stack
                    direction={"row"}
                    alignItems="center"
                    sx={{ gap: "18px", minHeight: "30px" }}
                  >
                    <Typography variant="h6">
                      Select Merchant Account
                    </Typography>
                    {/* <LoadingButton
                  sx={{
                    fontSize: 0,
                    padding: 0,
                    borderRadius: "50%",
                    minWidth: 0,
                  }}
                  size="small"
                  loading={refreshingId}
                >
                  <IconButton
                    sx={refreshingId ? { display: "none" } : { padding: 0 }}
                    onClick={onRefreshAccount}
                    aria-label="delete"
                  >
                    <Refresh />
                  </IconButton>
                </LoadingButton> */}
                  </Stack>
                  <Stack
                    direction={"row"}
                    alignItems="center"
                    sx={{ gap: "18px", minHeight: "30px" }}
                  >
                    <Select
                      sx={{}}
                      fullWidth
                      size="small"
                      value={selectedShoppingId}
                      onChange={(e) => setSelectedShoppingId(e.target.value)}
                    >
                      {merchantIds.map((obj) => (
                        <MenuItem value={obj.merchantId}>
                          {obj.merchantId}
                        </MenuItem>
                      ))}
                      {!isArrayWithValues(merchantIds) ? (
                        <MenuItem disabled>
                          <em>No account found</em>
                        </MenuItem>
                      ) : null}
                    </Select>
                    {/* <LoadingButton
                            loading={refreshingId}
                            onClick={onRefreshAccount}
                            startIcon={<Refresh />}
                            sx={{ mr: 3, ml: 3 }}>
                            Refresh
                        </LoadingButton> */}
                  </Stack>
                  <Stack
                    direction={"row"}
                    alignItems="center"
                    justifyContent={"flex-end"}
                    sx={{ mt: 3 }}
                  >
                    <LoadingButton
                      size="small"
                      loading={refreshingId}
                      onClick={onRefreshAccount}
                      startIcon={<Refresh />}
                    >
                      Refresh Account
                    </LoadingButton>
                    <LoadingButton
                      loading={updatingId}
                      variant={"contained"}
                      size="small"
                      disabled={!selectedShoppingId}
                      onClick={updateMerchantAccount}
                    >
                      Update
                    </LoadingButton>
                  </Stack>

                  {googleShopedProductCount ? (
                    <Typography variant="subtitle2">
                      Google Merchant Product: {googleShopedProductCount}
                    </Typography>
                  ) : null}
                </Box>
                <Divider sx={{ mt: 5, mb: 5 }} />
              </>
            )}
            {integratedApp.includes("google_analytics") && (
              <>
                <Box sx={{ maxWidth: "500px" }}>
                  <Stack
                    direction={"row"}
                    alignItems="center"
                    sx={{ gap: "18px", minHeight: "30px" }}
                  >
                    <Typography variant="h6">Analytics</Typography>
                    {/* <LoadingButton
    sx={{
      fontSize: 0,
      padding: 0,
      borderRadius: "50%",
      minWidth: 0,
    }}
    size="small"
    loading={refreshingApp === "google_analytics"}
  >
    <IconButton
      sx={
        refreshingApp === "google_analytics"
          ? { display: "none" }
          : { padding: 0 }
      }
      onClick={() =>
        refreshAccountsGoogle(
          "google_analytics",
          "/accountSummaries",
          "analytics_accounts",
          "accountSummaries"
        )
      }
      aria-label="delete"
    >
      <Refresh />
    </IconButton>
  </LoadingButton> */}
                  </Stack>
                  <Stack
                    direction={"row"}
                    alignItems="center"
                    sx={{ gap: "18px", minHeight: "30px" }}
                  >
                    <Select
                      MenuProps={{ sx: { maxHeight: "300px" } }}
                      value={selectedAnalyticsAccount}
                      size="small"
                      fullWidth
                      onChange={(e) =>
                        setSelectedAnalyticsAccount(e.target.value)
                      }
                    >
                      {googleAnalyticsAccounts.map((obj) => (
                        <MenuItem value={obj.displayName}>
                          {obj.displayName} -
                          <Typography
                            variant="subtitle2"
                            sx={{ display: "inline" }}
                          >
                            {" "}
                            {obj.property.split("/")[1]}
                          </Typography>
                        </MenuItem>
                      ))}
                      {!isArrayWithValues(googleAnalyticsAccounts) ? (
                        <MenuItem disabled>
                          <em>No account found</em>
                        </MenuItem>
                      ) : null}
                    </Select>
                    {/* <LoadingButton
              loading={refreshingApp === "google_analytics"}
              startIcon={<Refresh />} onClick={() => refreshAccountsGoogle("google_analytics", "/accountSummaries", "analytics_accounts", "accountSummaries")}
              sx={{ mr: 3, ml: 3 }}>
              Refresh
          </LoadingButton> */}
                  </Stack>
                  <Stack
                    direction={"row"}
                    alignItems="center"
                    justifyContent={"flex-end"}
                    sx={{ mt: 3 }}
                  >
                    <LoadingButton
                      size="small"
                      loading={refreshingApp === "google_analytics"}
                      onClick={() =>
                        refreshAccountsGoogle(
                          "google_analytics",
                          "/accountSummaries",
                          "analytics_accounts",
                          "accountSummaries"
                        )
                      }
                      startIcon={<Refresh />}
                    >
                      Refresh Account
                    </LoadingButton>
                    <LoadingButton
                      loading={updatingApp === "google_analytics"}
                      variant={"contained"}
                      disabled={!selectedAnalyticsAccount}
                      size="small"
                      onClick={() =>
                        updadateAccountGoogle(
                          "google_analytics",
                          "selected_analytics_id",
                          googleAnalyticsAccounts
                            .find(
                              (obj) =>
                                obj.displayName === selectedAnalyticsAccount
                            )
                            .property.split("/")[1]
                        )
                      }
                    >
                      Update
                    </LoadingButton>
                  </Stack>
                </Box>
                <Divider sx={{ mt: 5, mb: 5 }} />
              </>
            )}
            {integratedApp.includes("firebase") && (
              <>
                <Box sx={{ maxWidth: "500px" }}>
                  <Stack
                    direction={"row"}
                    alignItems="center"
                    sx={{ gap: "18px", minHeight: "30px" }}
                  >
                    <Typography variant="h6">Firebase</Typography>

                    {/* <LoadingButton
                  sx={{
                    fontSize: 0,
                    padding: 0,
                    borderRadius: "50%",
                    minWidth: 0,
                  }}
                  size="small"
                  loading={refreshingApp === "firebase"}
                >
                  <IconButton
                    sx={
                      refreshingApp === "firebase"
                        ? { display: "none" }
                        : { padding: 0 }
                    }
                    onClick={() => getFirebaseAccount()}
                    aria-label="delete"
                  >
                    <Refresh />
                  </IconButton>
                </LoadingButton> */}
                  </Stack>
                  <Stack direction={"row"} alignItems="center">
                    <Select
                      fullWidth
                      size="small"
                      MenuProps={{ sx: { maxHeight: "300px" } }}
                      value={selectedFirebaseAccount}
                      onChange={(e) =>
                        setSelectedFirebaseAccount(e.target.value)
                      }
                    >
                      {firebaseAccounts.map((obj) => (
                        <MenuItem value={obj.displayName}>
                          {obj.displayName}
                        </MenuItem>
                      ))}
                      {!isArrayWithValues(firebaseAccounts) ? (
                        <MenuItem disabled>
                          <em>No account found</em>
                        </MenuItem>
                      ) : null}
                    </Select>
                    {/* <LoadingButton
                            loading={refreshingApp === "firebase"}
                            startIcon={<Refresh />}
                            onClick={() => getFirebaseAccount()}
                            sx={{ mr: 3, ml: 3 }}>
                            Refresh
                        </LoadingButton> */}
                  </Stack>
                  <Stack
                    direction={"row"}
                    alignItems="center"
                    justifyContent={"flex-end"}
                    sx={{ mt: 3 }}
                  >
                    <LoadingButton
                      size="small"
                      loading={refreshingApp === "firebase"}
                      onClick={() => getFirebaseAccount()}
                      startIcon={<Refresh />}
                    >
                      Refresh Account
                    </LoadingButton>
                    <LoadingButton
                      loading={updatingApp === "firebase"}
                      variant={"contained"}
                      size="small"
                      disabled={!selectedFirebaseAccount}
                      onClick={() => firebaseUpdateId()}
                    >
                      Update
                    </LoadingButton>
                  </Stack>
                </Box>
                <Divider sx={{ mt: 5, mb: 5 }} />
              </>
            )}
            {integratedApp.includes("google_sheets") && !onlyChats && (
              <Box sx={{ maxWidth: "500px" }}>
                <Stack
                  direction="row"
                  alignItems={"center"}
                  justifyContent="space-between"
                  sx={{ mb: 3 }}
                >
                  <Typography variant="h6">Google Sheets</Typography>
                  {/* <Box sx={{ height: "5px" }}></Box> */}
                  <Link
                    underline="hover"
                    sx={{
                      fontSize: "13px",
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                    onClick={() => googleSheetClick("spreadSheet_product_id")}
                  >
                    Go to Product sheet
                    <OpenInNew style={{ height: "13px", width: "13px" }} />
                  </Link>
                </Stack>

                <Stack sx={{ mt: 4 }}>
                  <Typography
                    variant="subtitle2"
                    sx={{ mb: 1, fontSize: "12px" }}
                  >
                    Column to Exclude{" "}
                  </Typography>
                  <FormControl fullWidth size="small" sx={{}}>
                    {/* <InputLabel id="demo-multiple-chip-label">Chip</InputLabel> */}
                    <Select
                      labelId="demo-multiple-chip-label"
                      id="demo-multiple-chip"
                      multiple
                      value={selectedSheetExcluded}
                      onChange={handleSheetExcludedChange}
                      input={<OutlinedInput id="select-multiple-chip" />}
                      renderValue={(selected) => (
                        <Box
                          sx={{ display: "flex", flexWrap: "wrap", gap: 0.8 }}
                        >
                          {selected.map((value) => (
                            <Chip
                              size="small"
                              key={value}
                              label={sheetExcludeLabel[value]}
                            />
                          ))}
                        </Box>
                      )}
                      //   MenuProps={MenuProps}
                    >
                      {sheetExclude.map((obj) => (
                        <MenuItem
                          key={obj.value}
                          value={obj.value}
                          //   style={getStyles(obj, personobj, theme)}
                        >
                          {obj.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <Stack
                    direction="row"
                    alignItems={"center"}
                    justifyContent="flex-end"
                    sx={{ mt: 3 }}
                  >
                    <LoadingButton
                      loading={refreshingApp === "google_sheet"}
                      size="small"
                      onClick={onSheetExcludeInclude}
                      variant="contained"
                    >
                      Update
                    </LoadingButton>
                  </Stack>
                </Stack>
                {/* <Select
                  fullWidth
                  size="small"
                  MenuProps={{ sx: { maxHeight: "300px" } }}
                  // value={sheetExclude}
                  // onChange={(e) => setSelectedFirebaseAccount(e.target.value)}
                >
                  {sheetExclude.map((obj) => (
                    <MenuItem value={obj.value}>{obj.label}</MenuItem>
                  ))}
                </Select> */}
                {/* <Button endIcon={<OpenInNew />} onClick={() => googleSheetClick("spreadSheet_product_id")} sx={{ mt: 3 }}>Go to Product sheet</Button> */}
              </Box>
            )}
          </Box>
          {/* <Box sx={{ height: "100px" }} /> */}
        </TabPanel>
      </Box>
    </>
  );
};

export default Google;

const sheetExclude = [
  { label: "Metal ", value: "metal" },
  { label: "Diamond", value: "diamond" },
  { label: "Gemstone", value: "gemstone" },
  { label: "Labour ", value: "labour" },
  { label: "Discounts ", value: "discounts" },
  { label: "Measurements ", value: "measurements" },
  { label: "Matching Products", value: "matching" },
  { label: "SEO", value: "seo" },
  { label: "Images ", value: "images" },
  { label: "Video", value: "video" },
];
const sheetExcludeLabel = {
  metal: "Metal",
  diamond: "Diamond",
  gemstone: "Gemstone",
  labour: "Labour",
  discounts: "Discounts",
  measurements: "Measurements",
  matching: "Matching Product",
  seo: "SEO",
  images: "Images",
  video: "Video",
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {/* <Typography>{children}</Typography> */}
          {children}
        </Box>
      )}
    </div>
  );
}
