import {
  alpha,
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Paper,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Tabs,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
  TablePagination,
  IconButton,
  Dialog,
  Avatar,
  Menu,
  MenuItem,
  Button,
} from "@mui/material";
import { useTheme } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  a11yProps,
  dialogStyle,
  formatDate,
  formatServerValue,
  getComparator,
  getLastMessageFromObj,
  getNameFromPlatform,
  getRandomInt,
  globalCardStyle,
  isArrayWithValues,
  isObjWithValues,
  reduceLargeText,
  sortObjectByValues,
  stableSort,
  StyledTableRow,
  TabPanel,
  validateNumber,
} from "../../helper";
import {
  NoTasks,
  platformIcons,
  primaryLabel,
  secondaryLabel,
  sectionLabel,
  userTasksHeadCells,
  userTasksHeadCellsWithoutCustomer,
} from "./ChatHelper";
import { visuallyHidden } from "@mui/utils";
import { MoreVert } from "@material-ui/icons";
import { CreateUserTask } from "./UserTasks";
import { ChatStatusChip, tooltipPoints } from ".";
import PopperTooltip from "../components/PopperTooltip";
import { HelpOutline } from "@mui/icons-material";
import { CheckCircle } from "../../Svgs";

const AllTasksTable = ({ customer, openDeleteForm }) => {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [rows, setRows] = useState([]);
  // hideing customer column inside customer
  const [showCustomerColumn, setShowCustomerColumn] = useState(true);

  const [formOpen, setFormOpen] = useState(false);
  const [editForm, setEditForm] = useState(false);
  const openForm = (edit) => {
    setEditForm(edit);
    setFormOpen(true);
  };
  const closeForm = () => setFormOpen(false);

  const newContacts = useSelector((state) => state.chats.newContacts);
  const allUserTasks = useSelector((state) => state.chats.allUserTasks);
  const reduxGoogleIntegration = useSelector(
    (state) => state.settings.googleIntegration
  );
  const googleCalendarIntegerated = Object.values(reduxGoogleIntegration || {})
    ?.map((o) => (isArrayWithValues(o.connected) ? o.connected : []))
    ?.flat()
    ?.includes("google_calendar");
  useEffect(() => {
    setShowCustomerColumn(!isObjWithValues(customer));
  }, [customer]);
  useEffect(() => {
    if (!isArrayWithValues(allUserTasks)) return;
    if (isObjWithValues(customer)) {
      setRows(allUserTasks.filter((i) => i.user_id == customer.id));
    } else setRows(allUserTasks);
  }, [allUserTasks, customer]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <Card
      sx={{
        width: "100%",
        ...globalCardStyle,
        mt: 4,
      }}
    >
      <CardContent sx={{ padding: 0 }}>
        {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
        <Typography
          sx={{
            ...sectionLabel,
            display: "flex",
            alignItems: "center",
            gap: 2,
          }}
        >
          Activities
          <PopperTooltip points={tooltipPoints?.["Activities"] || []}>
            <HelpOutline
              fontSize="small"
              style={{ opacity: "0.5", height: "16px", width: "16px" }}
              color="text.secondary"
            />
          </PopperTooltip>
        </Typography>
        {isArrayWithValues(rows) ? (
          <>
            <TableContainer component={Card} sx={{ ...globalCardStyle }}>
              <Table
                aria-labelledby="tableTitle"
                size={dense ? "small" : "medium"}
              >
                <TableHead>
                  <TableRow
                  // sx={{
                  //   // backgroundColor: "#E7F3FF",
                  //  color: "text.secondary" }}
                  >
                    {/* <TableCell padding="checkbox">
                    <Checkbox
                      color="primary"
                      indeterminate={numSelected > 0 && numSelected < rowCount}
                      checked={rowCount > 0 && numSelected === rowCount}
                      onChange={onSelectAllClick}
                      inputProps={{
                        'aria-label': 'select all desserts',
                      }}
                    />
                  </TableCell> */}
                    {(showCustomerColumn
                      ? userTasksHeadCells
                      : userTasksHeadCellsWithoutCustomer
                    ).map((headCell) => (
                      <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? "right" : "left"}
                        // padding={headCell.disablePadding ? "none" : "normal"}
                        sortDirection={orderBy === headCell.id ? order : false}
                        // sx={{ color: "text.secondary" }}
                      >
                        <TableSortLabel
                          active={orderBy === headCell.id}
                          direction={orderBy === headCell.id ? order : "asc"}
                          // onClick={createSortHandler(headCell.id)}
                          // sx={{ color: "text.secondary" }}
                        >
                          {headCell.label}
                          {orderBy === headCell.id ? (
                            <Box
                              component="span"
                              sx={{
                                ...visuallyHidden,
                                color: "text.secondary",
                              }}
                            >
                              {order === "desc"
                                ? "sorted descending"
                                : "sorted ascending"}
                            </Box>
                          ) : null}
                        </TableSortLabel>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.sort(getComparator(order, orderBy)).slice() */}
                  {stableSort(rows, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      let { platform } = row;
                      let lastMessage = "";
                      if (platform === "whatsapp") {
                        lastMessage = row?.message?.text?.body || "";
                      }
                      if (platform !== "whatsapp")
                        lastMessage = row?.message?.text || "";
                      return (
                        <Row
                          showCustomerColumn={showCustomerColumn}
                          row={row}
                          index={index}
                          // openForm={() => openForm({})}
                          openForm={openForm}
                          openDeleteForm={openDeleteForm}
                        />
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow
                      style={{
                        height: (dense ? 33 : 53) * emptyRows,
                      }}
                    >
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            {rows.length > 5 ? (
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            ) : null}
          </>
        ) : (
          <NoTasks
            text={
              <Stack justifyContent={"center"} alignItems={"center"} gap={2}>
                No tasks added yet
                {googleCalendarIntegerated && (
                  <Button variant="outlined" onClick={openForm}>
                    Create your first task
                  </Button>
                )}
              </Stack>
            }
            icon={
              <CheckCircle
                style={{ height: "70px", width: "70px", fill: "#c4c4c4" }}
                fillCircle="#c4c4c4"
              />
            }
          />
        )}

        <Dialog
          open={formOpen}
          keepMounted
          onClose={closeForm}
          aria-describedby="alert-dialog-slide-description"
          maxWidth="sm"
          fullWidth
          sx={{ ...dialogStyle }}
        >
          <CreateUserTask
            customer={isArrayWithValues(rows) ? customer : false}
            // updateForm={customer}
            closeForm={closeForm}
            updateForm={isArrayWithValues(rows) ? editForm : false}
          />
        </Dialog>
      </CardContent>{" "}
    </Card>
  );
};
export default AllTasksTable;

const Row = ({ showCustomerColumn, row, index, openForm, openDeleteForm }) => {
  const labelId = `enhanced-table-checkbox-${index}`;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    // <TableRow
    <TableRow
      hover
      backgroundColor="#F7FBFF"
      order={"even"}
      // even
      // onClick={(event) => handleClick(event, row.name)}
      // role="checkbox"
      sx={{ py: 2 }}
      // aria-checked={isItemSelected}
      tabIndex={-1}
      key={row.name}
      // selected={isItemSelected}
    >
      {/* <TableCell padding="checkbox">
        <Checkbox
          color="primary"
          checked={isItemSelected}
          inputProps={{
            "aria-labelledby": labelId,
          }}
        />
      </TableCell> */}
      {showCustomerColumn ? (
        <TableCell
          component="th"
          // id={labelId}
          scope="row"
          // padding="none"
        >
          <Stack direction={"row"} alignItems="center" spacing={3}>
            <Avatar
              src={row?.userDetails?.profile_pic}
              // onError={({ currentTarget }) => {
              //   currentTarget.onerror = null; // prevents looping
              //   currentTarget.src =
              //     "/static/img/default_avatar.webp";
              // }}
              // style={{
              //   height: "40px",
              //   width: "40px",
              //   borderRadius: "50%",
              // }}
            />
            <Typography sx={{ ...primaryLabel }}>
              {row.userDetails?.name}
            </Typography>
          </Stack>
        </TableCell>
      ) : null}
      <TableCell align="left">
        <Typography sx={{ ...primaryLabel }}>
          {formatServerValue(row.title)}
        </Typography>
      </TableCell>
      <TableCell align="right" style={{ whiteSpace: "nowrap" }}>
        <Typography sx={{ ...primaryLabel }}>
          {formatDate(new Date(row.date_created), "dd-mm-yyyy")}
        </Typography>
        <Typography sx={{ ...secondaryLabel }}>
          {formatDate(new Date(row.date_created), "hh:mm am/pm")}
        </Typography>
      </TableCell>
      <TableCell align="right">
        {reduceLargeText(row.description, 20)}
      </TableCell>
      <TableCell align="right">
        <Typography sx={{ ...primaryLabel }}>
          {row?.teamMemberInfo?.name}
        </Typography>
        {/* <Typography sx={{ ...secondaryLabel }}>
        Sales executive
      </Typography> */}
      </TableCell>
      <TableCell align="right" style={{ whiteSpace: "nowrap" }}>
        <Typography sx={{ ...primaryLabel }}>
          {formatDate(new Date(row.date), "dd-mm-yyyy")}
        </Typography>
        <Typography sx={{ ...secondaryLabel }}>
          {formatDate(new Date(row.date), "hh:mm am/pm")}
        </Typography>
      </TableCell>
      <TableCell align="right">
        <Typography sx={{ ...primaryLabel }}>
          <ChatStatusChip status={formatServerValue(row.status)} />
        </Typography>
      </TableCell>
      <TableCell align="right">
        <IconButton onClick={handleClick}>
          <MoreVert />
        </IconButton>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem
            onClick={() => {
              handleClose();
              openForm(row);
            }}
          >
            Edit
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleClose();
              // setShowDelete(row);
              openDeleteForm(row);
            }}
          >
            Delete
          </MenuItem>
        </Menu>
      </TableCell>
    </TableRow>
  );
};
