import {
  ArrowBackIosNew,
  ArrowForwardIos,
  Check,
  Close,
  Email,
  Language,
  LocalPhone,
  LocationOnOutlined,
} from "@mui/icons-material";
import {
  Avatar,
  Box,
  Breadcrumbs,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  IconButton,
  Paper,
  Rating,
  Stack,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactApexChart from "react-apexcharts";
import { Link, NavLink, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { additionalBenefits, getRandomadditionalBenefits, getRandomAvatar } from ".";
import {
  a11yProps,
  dialogStyle,
  getRandomString,
  globalColors,
  isArrayWithValues,
  isObjWithValues,
  primaryLabel,
  secondaryLabel,
  TabPanel,
} from "../../helper";
import { Whatsapp } from "../../Svgs";
import { AddDetails, ProductCard, ProductDialog, ProductLoadingCard, SearchRow } from "./DesignBank";
import { getDesignBankProduct } from "../../redux/actions/productActions";
import { useEffect } from "react";
import { getAllBusinesses, getDesignBankActivities } from "../../redux/actions/supplierActions";

const ProfilePage = () => {
  const reduxFetchingSuppliers = useSelector(
    (state) => state.supplier.fetchingSuppliers
  );
  const reduxAllProductsMain = useSelector(
    (state) => state.product?.designbankSupplierProduct
  );
  const allSuppliers = useSelector(state => state.supplier.allSuppliers)
  const reduxFetchingDesignbankProduct = useSelector(
    (state) => state.product?.fetchingDesignbankProduct
  );
  const reduxDesignbankProductsLength = useSelector(
    (state) => state.product?.designbankProductsLength
  );
  const productSettings = useSelector(
    (state) => state.settings.productSettings
  );
  const allDiamondGroups = useSelector(
    (state) => state.settings.allDiamondGroups
  );
  const totalProductsPulledBySuppliers = useSelector(
    (state) =>
      state.supplier.designbankProductsAnalytics?.totalProductsPulledBySuppliers
  );
  const globalPopupStyle =
    useSelector((state) => state.helper.globalPopupStyle) || {};
  const masterPricing = useSelector((state) => state.settings.masterPricing);
  const designBankActivities = useSelector((state) => state.supplier.designBankActivities);
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const theme = useTheme();
  const isIpad = useMediaQuery(theme.breakpoints.down("md"));
  const [productDetail, setProductDetail] = useState({})
  const [showAddDetailsPopup, setShowAddDetailsPopup] = useState({});
  const [productMasterAdd, setProductMasterAdd] = useState({});
  const [supplier, setSupplier] = useState({})
  const [reduxAllProducts, setReduxAllProducts] = useState([])
  const { id } = useParams()
  useEffect(() => {
    setSupplier(allSuppliers?.find(obj => obj?.id === id))
  }, [id, allSuppliers])

  useEffect(() => {
    if (!isArrayWithValues(allSuppliers)) dispatch(getAllBusinesses())
    if (!isObjWithValues(designBankActivities)) dispatch(getDesignBankActivities())
  }, [])
  useEffect(() => {
    if (isArrayWithValues(reduxAllProductsMain)) {
      let meta_keys = [
        "retailer_likes",
        "labourType",
        "colorstone_from",
        "diamond_from",
        "colorstone_from",
        "diamond",
        "colorstone_details",
        "retailer_pull",
        "gold_kt",
      ];
      let productArr = [];
      for (let index = 0; index < reduxAllProductsMain.length; index++) {
        let productObj = reduxAllProductsMain[index];
        let { meta_data } = productObj || {};
        for (let index = 0; index < meta_keys.length; index++) {
          const element = meta_keys[index];
          let obj = meta_data.find((obj) => obj?.key === element);
          if (isObjWithValues(obj)) {
            productObj = { ...productObj, [obj?.key]: obj?.value };
          }
        }

        productArr.push(productObj);
      }
      setReduxAllProducts(productArr)
    }
  }, [reduxAllProductsMain])


  const allTaxes = useSelector((state) => state.settings.allTaxes) || [];
  const onPreviosProduct = () => {
    if (!isObjWithValues(productDetail) && isArrayWithValues(reduxAllProducts)) return;
    let index = reduxAllProducts?.findIndex((obj) => obj?.id === productDetail?.id);
    if (index === 0) return;
    setProductDetail(reduxAllProducts?.[index - 1]);
  };
  const onNextProduct = () => {
    if (!isObjWithValues(productDetail) && isArrayWithValues(reduxAllProducts)) return;
    let index = reduxAllProducts?.findIndex((obj) => obj?.id === productDetail?.id);
    if (index === reduxAllProducts?.length - 1) return;
    setProductDetail(reduxAllProducts?.[index + 1]);
  };
  const closeAddPopup = () => {
    setShowAddDetailsPopup({});
  };
  const closeProductDetail = () => {
    setProductDetail({});
  };
  return (
    <Box padding={isIpad ? 0 : "20px"}>
      <Stack direction={"row"} alignItems="center">
        <IconButton onClick={() => navigate(-1)}>
          <ArrowBackIosNew />
        </IconButton>
        <Typography variant="h3" display="inline">
          Supplier Profile
        </Typography>
      </Stack>
      {/* <Typography variant="h3" gutterBottom display="inline">
        Supplier Profile
      </Typography> */}
      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/design-bank">
          Dashboard
        </Link>
        <Link component={NavLink} to="/design-bank/suppliers">
          Suppliers
        </Link>
        <Typography>Suppliers Profile</Typography>
      </Breadcrumbs>

      <Box my={4}>
        <Paper>
          <SearchRow
            supplierProducts={supplier?.filters}
          />
          <Divider />
          <Profile />
        </Paper>
      </Box>
      <Typography variant="h3" mt={4}>
        Products
      </Typography>
      <Stack
        direction={"row"}
        justifyContent="space-between"
        alignItems={"flex-end"}
      >
        {/* <Tabs
          value={productTabValue}
          onChange={(e, value) => setProductTabValue(value)}
        >
          <Tab label="Gold Jewellery" value={"gold_jewellery"} />
          <Tab label="Silver Jewellery" value={"silver_jewellery"} />
        </Tabs> */}
        <Typography sx={{ ...secondaryLabel }}>
          Showing {reduxDesignbankProductsLength} prodcuts
        </Typography>
      </Stack>
      {reduxFetchingDesignbankProduct || reduxFetchingSuppliers ? (
        <Grid container mt={3} spacing={4}>
          {new Array(12).fill("").map((i) => {
            return (
              <Grid item xs={6} sm={4} md={4} lg={2.4}>
                <ProductLoadingCard />
              </Grid>
            );
          })}
        </Grid>
      ) : null}
      <Grid container mt={3} spacing={5}>
        {!reduxFetchingSuppliers && !reduxFetchingDesignbankProduct
          ? reduxAllProducts?.map((i) => {
            // if (isArrayWithValues(i.images))
            return (
              <Grid item xs={6} sm={4} md={4} lg={2.4}>
                <ProductCard
                  key={i.id}
                  setShowAddDetailsPopup={setShowAddDetailsPopup}
                  setProductMasterAdd={setProductMasterAdd}
                  masterPricing={masterPricing}
                  productSettings={productSettings}
                  // selectedProducts={selectedProducts}
                  allDiamondGroups={allDiamondGroups}
                  totalProductsPulledBySuppliers={
                    totalProductsPulledBySuppliers
                  }
                  product={i}
                  setProductDetail={setProductDetail}
                  productDetail={productDetail}
                  allTaxes={allTaxes}

                />
              </Grid>
            );
          })
          : null}
      </Grid>
      <Dialog
        open={isObjWithValues(productDetail)}
        // TransitionComponent={Transition}
        keepMounted
        onClose={closeProductDetail}
        aria-describedby="alert-dialog-slide-description"
        maxWidth="md"
        fullWidth
        sx={dialogStyle}
        {...globalPopupStyle}
      >
        <DialogContent>
          <Stack
            direction={"row"}
            alignItems="center"
            justifyContent={"space-between"}
          // sx={{ padding: "10px 20px" }}
          >
            <Typography variant="h3">Product Details</Typography>{" "}
            <Stack direction={"row"} alignItems="center" gap="10px">
              <Stack direction={"row"} alignItems="center">
                <IconButton onClick={onPreviosProduct}>
                  <ArrowBackIosNew />
                </IconButton>
                <IconButton onClick={onNextProduct}>
                  <ArrowForwardIos />
                </IconButton>
              </Stack>
              <IconButton onClick={closeProductDetail}>
                <Close />
              </IconButton>
            </Stack>
          </Stack>
          <ProductDialog
            productDetail={productDetail}
            // onSelectPress={onSelectPress}
            // selectedProducts={selectedProducts}
            productSettings={productSettings}
            allDiamondGroups={allDiamondGroups}
            onPreviosProduct={onPreviosProduct}
            onNextProduct={onNextProduct}
            masterPricing={masterPricing}
            allTaxes={allTaxes}
            totalProductsPulledBySuppliers={totalProductsPulledBySuppliers}
          />
          <DialogActions></DialogActions>
        </DialogContent>
      </Dialog>
      <AddDetails

        open={isObjWithValues(showAddDetailsPopup)}
        handleClose={closeAddPopup}
        data={showAddDetailsPopup}
        productMasterAdd={productMasterAdd}
      />
    </Box>
  );
};

export default ProfilePage;

export const Profile = () => {
  const allSuppliers = useSelector(state => state.supplier.allSuppliers)
  const { id } = useParams()
  const theme = useTheme();
  const isIpad = useMediaQuery(theme.breakpoints.down("mdd"));
  const [supplier, setSupplier] = useState({})

  useEffect(() => {
    if (id && isArrayWithValues(allSuppliers)) {
      setSupplier(allSuppliers?.find(obj => obj?.id === id) || {})
    }
  }, [id, allSuppliers])

  console.log(supplier);

  return (
    <>
      <Box>
        <Grid container>
          <Grid item xs={12} md={3}>
            <BasicInfo supplier={supplier} />
          </Grid>
          {!isIpad && <Divider orientation="vertical" flexItem />}
          <Grid item xs={12} md={6.5}>
            <ProfileGraph />
          </Grid>
          {!isIpad && <Divider orientation="vertical" flexItem />}
          <Grid item xs={12} md={2.3}>
            <Stack
              gap={"20px"}
              py={5}
              justifyContent="center"
              alignItems={"center"}
            >
              <Stack
                rowGap={"10px"}
                justifyContent="center"
                alignItems={"center"}
              >
                <Typography variant="h6" sx={{ color: "text.secondary" }}>
                  Supplier ratings
                </Typography>
                <Rating />
                <Typography sx={{ color: "text.secondary" }}>
                  (90 reviews)
                </Typography>
              </Stack>
              <Stack
                rowGap={"7px"}
                justifyContent="center"
                alignItems={"center"}
                textAlign="center"
              >
                <Typography variant="h6" sx={{ color: "text.secondary" }}>
                  Additional benefits
                </Typography>
                {new Array(2).fill("").map((obj) => (
                  <Stack direction={"row"} gap={"5px"}>
                    <Check fontSize="small" />
                    <Typography>{getRandomadditionalBenefits()}</Typography>{" "}

                  </Stack>
                ))}
              </Stack>
              <Stack>
                <Typography variant="h6" sx={{ color: "text.secondary" }}>
                  Awards
                </Typography>
                {new Array(1).fill("").map((obj) => (
                  <Stack direction={"row"} gap={"5px"}>
                    <Typography
                      variant="subtitle1"
                      sx={{ color: "text.secondary" }}
                    >
                      NJA 2022(gjc)
                    </Typography>{" "}
                  </Stack>
                ))}
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export const BasicInfo = ({ supplier }) => {
  return (
    <>
      <Stack
        justifyContent={"center"}
        alignItems="center"
        rowGap={"10px"}
        py={5}
        px={3}
      >
        <Avatar
          sx={{ height: 70, width: 70 }}
          src={supplier?.details?.store_logo}
        />
        <Typography variant="h4">{supplier?.name}</Typography>
        <Typography textAlign={"center"} sx={{ ...secondaryLabel }}>{supplier?.details?.store_description}</Typography>
        <Stack direction="row" alignItems={"center"} justifyContent="center">
          <LocationOnOutlined
            style={{ fontSize: "15px", mr: 1, color: "info.main" }}
          />
          <Typography
            sx={{
              ...secondaryLabel,
              ml: 1,
              fontSize: "16px",
              fontWeight: 500,
            }}
          >
            {supplier?.address?.city}
          </Typography>
        </Stack>
        <Stack
          direction="row"
          alignItems={"center"}
          justifyContent="center"
          gap={"10px"}
        >
          <IconButton>
            <LocalPhone />
          </IconButton>
          <IconButton>
            <Email />
          </IconButton>
          <IconButton>
            <Language />
          </IconButton>
          <IconButton>
            <Whatsapp />
          </IconButton>
        </Stack>
        <Stack
          direction={"row"}
          alignItems="center"
          justifyContent={"space-between"}
          sx={{ width: "100%", mt: 4 }}
        >
          <Stack alignItems={"center"} justifyContent="center">
            <Typography variant="subtitle1" sx={{ color: "text.secondary" }}>
              Category
            </Typography>
            <Typography variant="subtitle1">Gold , silver</Typography>
          </Stack>
          <Stack alignItems={"center"} justifyContent="center">
            <Typography variant="subtitle1" sx={{ color: "text.secondary" }}>
              Estd in
            </Typography>
            <Typography variant="subtitle1">2015</Typography>
          </Stack>
          <Stack alignItems={"center"} justifyContent="center">
            <Typography variant="subtitle1" sx={{ color: "text.secondary" }}>
              Business type
            </Typography>
            <Typography variant="subtitle1">Partnership</Typography>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

const ProfileGraph = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const handleTabChange = (a, e) => {
    setSelectedTab(e);
  };
  return (
    <Box sx={{ mt: 2 }}>
      {/* <Tabs value={selectedTab} onChange={handleTabChange}>
        <Tab label="Retailer connected" {...a11yProps(0)} />
        <Tab label="Category Wise Products" {...a11yProps(1)} />
      </Tabs>
      <TabPanel value={selectedTab} index={0}>
        <RetailersConnected />
      </TabPanel>
      <TabPanel value={selectedTab} index={1}>
      </TabPanel> */}
      <Typography sx={{ ...primaryLabel, ml: 2 }}>
        Category wise products
      </Typography>
      <SupplierProductsAvailablity />
    </Box>
  );
};

const SupplierProductsAvailablity = () => {
  return (
    <Box>
      <ReactApexChart
        options={options}
        series={[
          {
            name: "Instock",
            data: [27, 35, 22],
          },
          {
            name: "Out of Stock",
            data: [45, 85, 25],
          },
          {
            name: "Make to order",
            data: [13, 11, 6],
          },
        ]}
        type="bar"
        height={300}
      />
    </Box>
  );
};

const options = {
  chart: {
    type: "bar",
    height: 350,
  },
  colors: globalColors,
  plotOptions: {
    bar: {
      horizontal: false,
      columnWidth: "55%",
      endingShape: "rounded",
    },
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    show: true,
    width: 2,
    colors: ["transparent"],
  },
  xaxis: {
    categories: ["Gold Jewellery", "Silver Jewellery", "Diamond Jewellery"],
  },
  yaxis: {
    title: {
      text: "Quantity",
    },
  },
  fill: {
    opacity: 1,
  },
  tooltip: {
    // y: {
    //   formatter: function (val) {
    //     return "$ " + val + " thousands";
    //   },
    // },
  },
};

const RetailersConnected = () => {
  return (
    <div>
      <ReactApexChart
        options={retailersConnectedOptions}
        series={[
          {
            data: [
              [1327359600000, 30.95],
              [1327446000000, 31.34],
              [1327532400000, 31.18],
              [1327618800000, 31.05],
              [1327878000000, 31.0],
              [1327964400000, 30.95],
              [1328050800000, 31.24],
              [1328137200000, 31.29],
              [1328223600000, 31.85],
              [1328482800000, 31.86],
              [1328569200000, 32.28],
              [1328655600000, 32.1],
              [1328742000000, 32.65],
              [1328828400000, 32.21],
              [1329087600000, 32.35],
              [1329174000000, 32.44],
              [1329260400000, 32.46],
              [1329346800000, 32.86],
              [1329433200000, 32.75],
              [1329778800000, 32.54],
              [1329865200000, 32.33],
              [1329951600000, 32.97],
              [1330038000000, 33.41],
              [1330297200000, 33.27],
              [1330383600000, 33.27],
              [1330470000000, 32.89],
              [1330556400000, 33.1],
              [1330642800000, 33.73],
              [1330902000000, 33.22],
              [1330988400000, 31.99],
              [1331074800000, 32.41],
              [1331161200000, 33.05],
              [1331247600000, 33.64],
              [1331506800000, 33.56],
              [1331593200000, 34.22],
              [1331679600000, 33.77],
              [1331766000000, 34.17],
              [1331852400000, 33.82],
              [1332111600000, 34.51],
            ],
          },
        ]}
        type="area"
        height={300}
      />
    </div>
  );
};

const retailersConnectedOptions = {
  chart: {
    id: "area-datetime",
    type: "area",
    height: 350,
    zoom: {
      autoScaleYaxis: true,
    },
  },
  dataLabels: {
    enabled: false,
  },
  markers: {
    size: 0,
    style: "hollow",
  },
  xaxis: {
    type: "datetime",
    tickAmount: 6,
  },
  tooltip: {
    x: {
      format: "ddd MMM yyyy",
    },
  },
  fill: {
    type: "gradient",
    gradient: {
      shadeIntensity: 1,
      opacityFrom: 0.7,
      opacityTo: 0.9,
      stops: [0, 100],
    },
  },
};
