import {
  Autocomplete,
  Box,
  Button,
  Divider,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  formatServerValue,
  getShopifyCustomers,
  getToken,
  getWebsite,
  isObjWithValues,
  setupAttribute,
  TabPanel,
  getDropDownValueFromObj,
  getShopifyData,
  createObjFromMapping,
} from "../../../../../helper";
import { EDIT_SETTINGS } from "../../../../../redux/actions/settingActions";
import {
  CustomerMapping,
  GroupHeader,
  GroupItems,
  mappingFeilds,
} from "../../woocommerce/components/Mapping";
const tabs = [
  { label: "Customer", value: "customer" },
  { label: "Products", value: "products" },
];
let ignoreKeys = [
  "first_name",
  "last_name",
  "email",
  "phone",
  "state",
  "address_Line_1",
  "address_Line_2",
  "city",
  "postcode",
  "country",
  "total_purchase",
];

const ignoreKeysShopifyProducts = [
  "images",
  "name",
  "description",
  "categories",
  "tags",
];
const Mapping = () => {
  const [selectedTab, setSelectedTab] = useState("customer");
  const [updatingMap, setUpdatingMap] = useState(false);
  const [customerExample, setCustomerExample] = useState({});
  const [hideProductMapping, setHideProductMapping] = useState(false);
  const shopifyMapping = useSelector((state) => state.settings?.shopifyMapping);
  const reduxShopifyIntegration = useSelector(
    (state) => state.settings.shopifyIntegration
  );
  const user_products = useSelector(
    (state) => state.user?.store_plan?.user_products
  );
  useEffect(async () => {
    let customerRes = await getShopifyData({
      ...reduxShopifyIntegration,
      params: { limit: 1 },
    });
    setCustomerExample(customerRes?.data?.data?.customers?.[0] || {});
    console.log(customerRes);
  }, [reduxShopifyIntegration]);

  const dispatch = useDispatch();

  useEffect(() => {
    setHideProductMapping(
      user_products?.length === 1 && user_products.includes("crm")
    );
  }, [user_products]);

  const saveMap = async (attributeObj) => {
    console.log({
      customers: {
        attributes: attributeObj,
        mapping: setupAttribute(attributeObj),
      },
    });
    let website = getWebsite();
    let token = getToken();
    if (website && token) {
      setUpdatingMap(true);
      try {
        let res = await axios({
          url: `${website}/wp-json/store/v1/settings/shopify_mapping`,
          headers: { Authorization: `Basic ${token}` },

          method: "PUT",
          data: {
            setting: "shopify_mapping",
            data: {
              customers: {
                attributes: attributeObj,
                mapping: setupAttribute(attributeObj),
              },
            },
          },
        });
        if (res?.data?.success) {
          dispatch({
            type: EDIT_SETTINGS,
            payload: {
              shopifyMapping: res?.data?.data,
            },
          });
          setUpdatingMap(false);
        }
      } catch (error) {
        setUpdatingMap(false);
      }
      setUpdatingMap(false);
    }
  };
  return (
    <>
      <Stack
        direction="row"
        sx={{
          backgroundColor: "Background.paper",
          padding: "10px",
          borderBottomColor: "#e8e8e8",
          borderBottomWidth: 0.2,
          borderBottomStyle: "solid",
          overflow: "scroll",
          paddingTop: 0,
        }}
        spacing={4}
        className="scrollbar-hidden"
      >
        {tabs
          .filter((i) => {
            return i.value === "products" ? !hideProductMapping : true;
          })
          .map((i) => {
            return (
              <Button
                variant={"text"}
                // variant={selectedTab !== i.value ? "outlined" : "contained"}
                sx={{
                  backgroundColor:
                    selectedTab === i.value ? "#e6e6e6" : "transparent",
                  color: "#000",
                  // color: selectedTab === i.value ? "primary.main" : "#000",
                  "&:hover": {
                    backgroundColor:
                      selectedTab === i.value ? "#e6e6e6" : "transparent",
                  },
                }}
                onClick={() => setSelectedTab(i.value)}
              >
                {i.label}
              </Button>
            );
          })}
      </Stack>
      {/* <Tabs value={selectedTab} onChange={(e, newValue) => setSelectedTab(newValue)}>
            <Tab label="Customer" value={"customer"} />
            <Tab label="Products" value={"products"} />
        </Tabs> */}
      <TabPanel value={"customer"} index={selectedTab}>
        <CustomerMapping
          customer={customerExample}
          channel={"shopify"}
          attributes={shopifyMapping?.customers?.attributes || {}}
          updatingMap={updatingMap}
          onMap={saveMap}
          ignoreKeys={ignoreKeys}
        />
      </TabPanel>
      <TabPanel value={"products"} index={selectedTab}>
        <ProductMapping
          reduxShopifyIntegration={reduxShopifyIntegration}
          shopifyMapping={shopifyMapping}
          ignoreKeys={ignoreKeysShopifyProducts}
        />
      </TabPanel>
    </>
  );
};

export default Mapping;

const ProductMapping = ({ reduxShopifyIntegration, shopifyMapping }) => {
  const [productExample, setproductExample] = useState({});
  useEffect(async () => {
    let customerRes = await getShopifyData({
      ...reduxShopifyIntegration,
      params: { limit: 1 },
      endpoint: "products",
    });
    setproductExample(customerRes?.data?.data?.products?.[0] || {});
    console.log(customerRes);
  }, [reduxShopifyIntegration]);
  const saveMap = (obj) => {
    console.log(
      createObjFromMapping({
        mapping: setupAttribute(obj),
        payload: productExample,
      })
    );
  };
  return (
    <CustomerMapping
      customer={productExample}
      channel={"shopify"}
      attributes={shopifyMapping?.products?.attributes || {}}
      // updatingMap={updatingMap}
      onMap={saveMap}
      uiJson={productUiJson}
    />
  );
};

// const CustomerMapping = ({ }) => {
//     const [options, setOptions] = useState([]);
//     const [attribute, setAttribute] = useState({});

//     const onEditForm = (payload) => {
//         setAttribute((pre) => ({ ...pre, ...payload }));
//     };
//     useEffect(() => {
//         setOptions(
//             getDropDownValueFromObj({
//                 obj: shopifyCustomer,
//                 channel: "shopify",
//             })
//         );
//         console.log(getDropDownValueFromObj({
//             obj: shopifyCustomer,
//             channel: "shopify",
//         }));
//     }, []);
//     // console.log(attribute);

//     const setupAttribute = () => {
//         let arr = [];
//         for (const key of Object.keys(attribute)) {
//             if (isObjWithValues(attribute?.[key])) {
//                 let obj = {
//                     jwero_key: [],
//                     key: [],
//                 };
//                 if (attribute?.[key]?.jwero_from) {
//                     obj.jwero_key.push({ key: attribute?.[key]?.jwero_from, data_type: attribute?.[key]?.jwero_dataType })
//                 }
//                 obj.jwero_key.push({ key })
//                 if (attribute?.[key]?.from) {
//                     obj.key.push({ key: attribute?.[key]?.from, data_type: attribute?.[key]?.dataType })
//                 }
//                 obj?.key.push({ key })
//                 arr.push(obj)
//             }

//         }
//         console.log(arr);

//     };
//     return (
//         <Box p={4}>
//             <Stack
//                 direction={"row"}
//                 alignItems={"center"}
//                 sx={{ justifyContent: "flex-end" }}
//             >
//                 <Button
//                     variant="outlined"
//                     onClick={() => {
//                         console.log(
//                             setupAttribute()
//                         );
//                     }}
//                 >
//                     Map
//                 </Button>
//             </Stack>
//             <Grid container rowSpacing={2}>
//                 {Object.keys(mappingFeilds)?.map((key) => {
//                     return (
//                         <>
//                             <Grid item xs={12}>
//                                 <Typography variant="h6">{formatServerValue(key)}</Typography>
//                             </Grid>
//                             {mappingFeilds?.[key]?.keys?.map((obj) => {
//                                 return (
//                                     <Grid item md={12} lg={6} spacing={2} rowSpacing={2}>
//                                         <Grid container>
//                                             <Grid item xs={6}>
//                                                 {" "}
//                                                 <Typography>{obj?.label}</Typography>{" "}
//                                             </Grid>
//                                             <Grid item xs={6}>
//                                                 <Autocomplete
//                                                     value={attribute?.[obj?.key] || null}
//                                                     onChange={(e, newValue) => {

//                                                         onEditForm({
//                                                             [obj?.key]: {
//                                                                 ...(newValue || {}),
//                                                                 jwero_from: obj?.from,
//                                                                 jwero_dataType: obj?.dataType,
//                                                             },
//                                                         });
//                                                         // console.log(obj);
//                                                     }}
//                                                     options={options}
//                                                     groupBy={(option) => option?.from}
//                                                     getOptionLabel={(option) => option?.key}
//                                                     sx={{ width: 160 }}
//                                                     renderInput={(params) => (
//                                                         <TextField {...params} size="small" />
//                                                     )}
//                                                     renderGroup={(params) => (
//                                                         <li key={params.key}>
//                                                             {params.group && (
//                                                                 <GroupHeader>{params.group}</GroupHeader>
//                                                             )}
//                                                             <GroupItems>{params.children}</GroupItems>
//                                                         </li>
//                                                     )}
//                                                 />
//                                                 {/* <TextField size="small" /> */}
//                                             </Grid>
//                                         </Grid>
//                                     </Grid>
//                                 );
//                             })}

//                             <Grid item xs={12} my={2}>
//                                 <Divider />
//                             </Grid>
//                         </>
//                     );
//                 })}
//             </Grid>
//         </Box>
//     );
// };
let meta_dataKey = {
  key: "meta_data",
  data_type: "array",
  array_obj_key: "key",
  array_obj_value: "value",
};
export const productUiJson = {
  primary_details: {
    keys: [
      {
        label: "Title",
        key: "name",
      },
      {
        label: "Images",
        key: "images",
      },
      {
        label: "Video link",
        key: "_woodmart_product_video",
        jwero_key: [meta_dataKey],
      },
      {
        label: "Product categories",
        key: "categories",
        jwero_key: [meta_dataKey],
      },
    ],
  },
  product_details: {
    keys: [
      {
        label: "Stock status",
        key: "stock_status",
      },
      {
        label: "Stock Quantity",
        key: "stock_quantity",
      },
      {
        label: "SKU",
        key: "sku",
      },
      {
        label: "Bar Code",
        key: "barcode",
        jwero_key: [meta_dataKey],
      },
      {
        label: "HUID",
        key: "huid",
        jwero_key: [meta_dataKey],
      },
      {
        label: "Product height",
        key: "product_height",
        jwero_key: [meta_dataKey],
      },
      {
        label: "Product width",
        key: "product_width",
        jwero_key: [meta_dataKey],
      },
      {
        label: "Product Thickness",
        key: "product_thickness",
        jwero_key: [meta_dataKey],
      },
      {
        label: "Product tags",
        key: "tag",
        // jwero_key: [meta_dataKey],
      },
    ],
  },
  product_description: {
    keys: [
      {
        label: "Product description",
        key: "description",
      },
    ],
  },
  //   account_info: {
  //     keys: [
  //       {
  //         key: "first_name",
  //         label: "First Name",
  //         type: "string",
  //       },
  //       { key: "last_name", label: "Last Name" },
  //       { key: "email", label: "Email" },
  //       {
  //         key: "dialcode_mobile",
  //         label: "Mobile Dailcode",
  //         jwero_key: [meta_dataKey],
  //         // from: "meta_data",
  //         // dataType: "array",
  //       },
  //       {
  //         key: "phone",
  //         label: "Mobile",
  //         from: "meta_data",
  //         jwero_key: [meta_dataKey],
  //       },
  //       {
  //         key: "dialcode_whatsapp",
  //         label: "Whatsapp Dailcode",
  //         from: "meta_data",
  //         jwero_key: [meta_dataKey],
  //       },
  //       {
  //         key: "whatsapp",
  //         label: "Whatsapp",
  //         from: "meta_data",
  //         jwero_key: [meta_dataKey],
  //       },
  //       {
  //         key: "contact_type",
  //         label: "Contact type",
  //         from: "meta_data",
  //         jwero_key: [meta_dataKey],
  //       },
  //     ],
  //   },
  //   personal_info: {
  //     keys: [
  //       {
  //         key: "user_birthday",
  //         label: "Birthday",
  //         from: "meta_data",
  //         jwero_key: [meta_dataKey],
  //       },
  //       {
  //         key: "gender",
  //         label: "Gender",
  //         from: "meta_data",
  //         jwero_key: [meta_dataKey],
  //       },
  //       {
  //         key: "user_anniversary",
  //         label: "Anniversary",
  //         from: "meta_data",
  //         jwero_key: [meta_dataKey],
  //       },
  //       {
  //         key: "profession",
  //         label: "Profession",
  //         from: "meta_data",
  //         jwero_key: [meta_dataKey],
  //       },
  //       {
  //         key: "annual_income",
  //         label: "Annual Income",
  //         from: "meta_data",
  //         jwero_key: [meta_dataKey],
  //       },
  //       {
  //         key: "ethincity",
  //         label: "Ethnicity",
  //         from: "meta_data",
  //         jwero_key: [meta_dataKey],
  //       },
  //       {
  //         key: "language",
  //         label: "Language",
  //         from: "meta_data",
  //         jwero_key: [meta_dataKey],
  //       },
  //       {
  //         key: "religion",
  //         label: "Religion",
  //         from: "meta_data",
  //         jwero_key: [meta_dataKey],
  //       },
  //     ],
  //   },
  //   address_Info: {
  //     keys: [
  //       {
  //         key: "address_Line_1",
  //         label: "Address Line 1",
  //         from: "billing",
  //         jwero_key: [
  //           {
  //             key: "billing",
  //             data_type: "object",
  //           },
  //         ],
  //       },
  //       {
  //         key: "address_Line_2",
  //         label: "Address Line 2",
  //         from: "billing",
  //         jwero_key: [
  //           {
  //             key: "billing",
  //             data_type: "object",
  //           },
  //         ],
  //       },
  //       {
  //         key: "city",
  //         label: "City",
  //         from: "billing",
  //         jwero_key: [
  //           {
  //             key: "billing",
  //             data_type: "object",
  //           },
  //         ],
  //       },
  //       {
  //         key: "postcode",
  //         label: "Post Code",
  //         from: "billing",
  //         jwero_key: [
  //           {
  //             key: "billing",
  //             data_type: "object",
  //           },
  //         ],
  //       },
  //       {
  //         key: "country",
  //         label: "Country",
  //         from: "billing",
  //         jwero_key: [
  //           {
  //             key: "billing",
  //             data_type: "object",
  //           },
  //         ],
  //       },
  //       {
  //         key: "state",
  //         label: "State",
  //         from: "billing",
  //         jwero_key: [
  //           {
  //             key: "billing",
  //             data_type: "object",
  //           },
  //         ],
  //       },
  //     ],
  //   },
  //   others_info: {
  //     keys: [
  //       {
  //         key: "lead_source",
  //         label: "Lead Source",
  //         from: "meta_data",
  //         jwero_key: [meta_dataKey],
  //       },
  //       {
  //         key: "customer_since",
  //         label: "Customer Since",
  //         from: "meta_data",
  //         jwero_key: [meta_dataKey],
  //       },
  //       {
  //         key: "first_purchase",
  //         label: "First Purchase",
  //         from: "meta_data",
  //         jwero_key: [meta_dataKey],
  //       },
  //       {
  //         key: "total_purchase",
  //         label: "Total Purchase",
  //         from: "meta_data",
  //         jwero_key: [meta_dataKey],
  //       },
  //       {
  //         key: "visits_in_showroom",
  //         label: "Showroom Visits",
  //         from: "meta_data",
  //         jwero_key: [meta_dataKey],
  //       },
  //       {
  //         key: "last_visit_in_showrrom",
  //         label: "Last Visited",
  //         from: "meta_data",
  //         jwero_key: [meta_dataKey],
  //       },
  //       {
  //         key: "visits_on_website",
  //         label: "Website Visits",
  //         from: "meta_data",
  //         jwero_key: [meta_dataKey],
  //       },
  //       {
  //         key: "last_visit_on_website",
  //         label: "Last Visited",
  //         from: "meta_data",
  //         jwero_key: [meta_dataKey],
  //       },
  //       {
  //         key: "branch_from",
  //         label: "Branch",
  //         from: "meta_data",
  //         jwero_key: [meta_dataKey],
  //       },
  //     ],
  //   },
  //   social_info: {
  //     keys: [
  //       {
  //         key: "customer_facebook",
  //         label: "Facebook",
  //         from: "meta_data",
  //         jwero_key: [meta_dataKey],
  //       },
  //       {
  //         key: "customer_instagram",
  //         label: "Instagram",
  //         from: "meta_data",
  //         jwero_key: [meta_dataKey],
  //       },
  //       {
  //         key: "customer_instagram",
  //         label: "Instagram",
  //         from: "meta_data",
  //         jwero_key: [meta_dataKey],
  //       },
  //       {
  //         key: "customer_twitter",
  //         label: "Twitter",
  //         from: "meta_data",
  //         jwero_key: [meta_dataKey],
  //       },
  //       {
  //         key: "customer_linkedin",
  //         label: "Linkedin",
  //         from: "meta_data",
  //         jwero_key: [meta_dataKey],
  //       },
  //       {
  //         key: "customer_pinterest",
  //         label: "Pinterest",
  //         from: "meta_data",
  //         jwero_key: [meta_dataKey],
  //       },
  //     ],
  //   },
};

const shopifyCustomer = {
  id: 1073339465,
  email: "steve.lastnameson@example.com",
  accepts_marketing: false,
  created_at: "2023-02-02T09:39:23-05:00",
  updated_at: "2023-02-02T09:39:23-05:00",
  first_name: "Steve",
  last_name: "Lastnameson",
  orders_count: 0,
  state: "disabled",
  total_spent: "0.00",
  last_order_id: null,
  note: null,
  verified_email: true,
  multipass_identifier: null,
  tax_exempt: false,
  tags: "",
  last_order_name: null,
  currency: "USD",
  phone: "+15142546011",
  addresses: [
    {
      id: 1053317299,
      customer_id: 1073339465,
      first_name: "Mother",
      last_name: "Lastnameson",
      company: null,
      address1: "123 Oak St",
      address2: null,
      city: "Ottawa",
      province: "Ontario",
      country: "Canada",
      zip: "123 ABC",
      phone: "555-1212",
      name: "Mother Lastnameson",
      province_code: "ON",
      country_code: "CA",
      country_name: "Canada",
      default: true,
    },
  ],
  accepts_marketing_updated_at: "2023-02-02T09:39:23-05:00",
  marketing_opt_in_level: null,
  tax_exemptions: [],
  admin_graphql_api_id: "gid://shopify/Customer/1073339465",
  default_address: {
    id: 1053317299,
    customer_id: 1073339465,
    first_name: "Mother",
    last_name: "Lastnameson",
    company: null,
    address1: "123 Oak St",
    address2: null,
    city: "Ottawa",
    province: "Ontario",
    country: "Canada",
    zip: "123 ABC",
    phone: "555-1212",
    name: "Mother Lastnameson",
    province_code: "ON",
    country_code: "CA",
    country_name: "Canada",
    default: true,
  },
  metafield: {
    id: 1069228951,
    namespace: "my_fields",
    key: "sponsor",
    value: "Shopify",
    description: null,
    owner_id: 382285388,
    created_at: "2023-03-09T18:29:52-05:00",
    updated_at: "2023-03-09T18:29:52-05:00",
    owner_resource: "blog",
    type: "single_line_text_field",
    admin_graphql_api_id: "gid://shopify/Metafield/1069228951",
  },
};
