export const messageSectionStyles = {
  mainContainer: {
    height: "400px",
    display: "flex",
    padding: "15px",
    flexDirection: "column",
    backgroundColor: "rgb(234, 238, 243)",
  },
  innerContainer: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "13px",
  },
};
