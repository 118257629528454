import { Add } from "@material-ui/icons";
import { IconButton, Typography } from "@mui/material";
import React, { useCallback, useContext, useEffect } from "react";
import { Handle, Position, addEdge, useReactFlow } from "reactflow";
import { useTheme } from "styled-components";
import { ChannelNames } from "../components/menu";
import { FlowStateContext } from "..";
import { unformatServerValue } from "../../../helper";
import { extractPrefix } from "../hepler";

const CustomHandle = ({
  style = {},
  label,
  showChannel,
  id,
  xPos,
  parent,
  subTitle,
  ...otherProps
}) => {
  const theme = useTheme();
  const primary = theme.palette.primary && theme.palette.primary.main;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { isEditable, historyObject, isReport } = useContext(FlowStateContext);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const { setEdges } = useReactFlow();

  const onConnect = useCallback(
    (params) => {
      setEdges((eds) => {
        let updatedParams = { ...params, type: "buttonedge" };
        updatedParams.action = unformatServerValue(
          params?.sourceHandle?.replace(params?.source, "")
        );
        updatedParams.channel = extractPrefix(params?.target);
        // console.log(updatedParams);
        return addEdge(updatedParams, eds);
      });
    },
    [setEdges]
  );
  return (
    <>
      <ChannelNames
        anchorEl={anchorEl}
        handleClose={handleClose}
        open={open}
        parent={parent}
        id={id}
        xPos={xPos}
        action={unformatServerValue(label)}
      />
      <Handle
        type="source"
        position={Position.Bottom}
        id={id}
        // isConnectable={}
        {...otherProps}
        style={{
          left: 40,
          bottom: "-10px",
          minHeight: "50px",
          width: "70px",
          // visibility: "hidden",
          // width: "25px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          // rowGap: 4,
          gap: "6px",
          cursor: "pointer",
          // color: "#fff",
          backgroundColor: "transparent",
          border: 0,

          ...(style || {}),
        }}
        onClick={isEditable && handleClick}
        onConnect={onConnect}
      >
        {isReport && (
          <Typography
            variant="h6"
            sx={{ position: "absolute", bottom: "60px" }}
          >
            {subTitle ||
              [
                ...new Set(
                  historyObject?.[parent?.id]?.[
                    unformatServerValue(label)
                  ]?.map((o) => o?.id) || []
                ),
              ]?.length}
          </Typography>
        )}
        <Typography variant="subtitle2" sx={{ pointerEvents: "none" }}>
          {label}
        </Typography>
        <IconButton
          // onDrag={}

          sx={{
            backgroundColor: "primary.main",
            color: "background.paper",
            padding: "2px",
            height: "20px",
            width: "20px",
            // position: "absolute",
            // top: "50%",
            // left: "50%",
            // transform: "translate(-50% , 50%)",
            "&:hover": {
              backgroundColor: "primary.main",
              color: "background.paper",
            },
            pointerEvents: "none",
          }}
          // onMouseDown={(event) => event.stopPropagation()} // Prevents node dragging when clicking on the handle
          // onMouseUp={(event) => event.stopPropagation()} // Prevents node dragging when releasing the handle
        >
          <Add fontSize="small" style={{ pointerEvents: "none" }} />
        </IconButton>
      </Handle>
    </>
  );
};

export default CustomHandle;
