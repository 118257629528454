import { Cancel, DragIndicator, InsertLink } from "@material-ui/icons";
import { FileUpload } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  LinearProgress,
  Link,
  Stack,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import axios from "axios";

import React, { useEffect, useState } from "react";
import SortableList, { SortableItem } from "react-easy-sort";
import { useDispatch, useSelector } from "react-redux";
import {
  arrayMove,
  getMedia,
  getToken,
  getWebsite,
  isObjWithValues,
} from "../../../helper";
import { setGlobalToast } from "../../../redux/actions/helperActions";
import {
  EDIT_PAGES,
  getRandomBanner,
} from "../../../redux/actions/pagesActions";
import ProductMediaLibrary from "../../components/ProductMediaLibrary";

const RandomBanners = () => {
  const reduxbannerShop = useSelector((state) => state.pages.bannerShop);
  const reduxfetchingBannerShop = useSelector(
    (state) => state.pages.fetchingBannerShop
  );
  const dispatch = useDispatch();
  const [bannerShop, setBannerShop] = useState({});
  const [updating, setUpdating] = useState(false);
  useEffect(() => {
    if (!isObjWithValues(reduxbannerShop)) dispatch(getRandomBanner());
  }, []);
  useEffect(() => {
    if (isObjWithValues(reduxbannerShop)) setBannerShop(reduxbannerShop);
  }, [reduxbannerShop]);

  const [items, setItems] = useState([]);
  const onSettingChnage = (index, obj) => {
    setBannerShop((pre) => ({ ...pre, [index]: obj }));
  };
  useEffect(() => {
    let arr = [];
    for (const key in bannerShop) {
      if (key !== "after")
        arr.push(
          <BannerCard
            obj={bannerShop[key]}
            title={`Banner #${key}`}
            imageId={bannerShop[key].id}
            index={key}
            onSettingChnage={onSettingChnage}
            bannerShop={bannerShop}
            setItems={setItems}
            setBannerShop={setBannerShop}
          />
        );
      // arr.push({

      //     title: `Banner #${key}`,
      //     imageId: bannerShop[key].id,
      //     index: key,
      //     onSettingChnage: onSettingChnage,
      //     bannerShop: bannerShop,
      //     setItems: setItems,
      //     setBannerShop: setBannerShop,
      // }

      // );
    }
    setItems(arr);
  }, [bannerShop]);
  const onSortEnd = (oldIndex, newIndex) => {
    let newArr = arrayMove(items, oldIndex, newIndex);
    let setArray = newArr.map((data, index) => {
      onSettingChnage(index + 1, data?.props.obj);
      return <BannerCard {...data?.props} index={index + 1} />;
    });

    setItems(arrayMove(setArray, oldIndex, newIndex));
  };

  const addBanner = () => {
    setItems((pre) => [
      ...pre,
      <BannerCard
        title={`Banner #${items.length + 1}`}
        imageId={""}
        index={items.length + 1}
        onSettingChnage={onSettingChnage}
        bannerShop={bannerShop}
        setItems={setItems}
        setBannerShop={setBannerShop}
      />,
    ]);
    onSettingChnage(items.length + 1, { id: "", link: "" });
  };

  const updateBanner = async () => {
    let website = getWebsite();
    let token = getToken();
    setUpdating(true);
    let res = await axios({
      url: `${website}/wp-json/store/v1/settings/banner_shop`,
      headers: {
        Authorization: `Basic ${token}`,
      },
      method: "POST",
      data: {
        setting: "banner_shop",
        data: {
          ...bannerShop,
        },
      },
    });

    if (res?.data?.type === "success") {
      dispatch({
        type: EDIT_PAGES,
        payload: {
          bannerShop: res?.data?.data,
        },
      });
      dispatch(
        setGlobalToast({
          show: true,
          message: "Update Banners Successfully",
          severity: "success",
        })
      );
    }
    setUpdating(false);
  };
  return (
    <Box sx={{ padding: 0, marginBottom: "60px" }}>
      <Stack
        direction={"row"}
        justifyContent="space-between"
        alignItems={"center"}
        sx={{
          position: "sticky",
          top: "0",
          marginTop: "0",
          padding: "12px",
          backgroundColor: "#fff",
          zIndex: 11,
        }}
      >
        <Typography variant="h3">Shop Random Banner</Typography>
        <Stack direction={"row"} sx={{ gap: "10px" }}>
          <LoadingButton
            variant="contained"
            loading={updating}
            onClick={updateBanner}
          >
            Update
          </LoadingButton>
        </Stack>
      </Stack>
      {reduxfetchingBannerShop ? (
        <Stack
          sx={{ height: "calc(100vh - 200px)", width: "100%" }}
          justifyContent="center"
          alignItems={"center"}
        >
          <Typography variant="h6" sx={{ mb: 2 }}>
            Fetching Banners ...
          </Typography>
          <LinearProgress sx={{ width: "50%" }} />
        </Stack>
      ) : null}
      {!reduxfetchingBannerShop ? (
        <>
          {" "}
          <Stack
            direction={"row"}
            sx={{ gap: "10px", padding: "12px" }}
            alignItems="center"
          >
            <Typography variant="subtitle2" sx={{ fontSize: "14px" }}>
              SHOW BANNER AFTER EVERY X PRODUCTS :
            </Typography>{" "}
            <TextField
              type={"number"}
              size="small"
              placeholder="Number"
              value={Number(bannerShop?.after)}
              onChange={(e) =>
                setBannerShop((pre) => ({ ...pre, after: e.target.value }))
              }
            />
          </Stack>
          <Box>
            <Stack spacing={6}>
              <SortableList
                onSortEnd={onSortEnd}
                className="list"
                draggedItemClassName="dragged"
              >
                {items.map((item, index) => (
                  <SortableItem key={item}>
                    <div className="item" style={{ margin: "10px" }}>
                      {item}
                      {/* < BannerCard {...item} title={`Banner #${index + 1}`} index={index + 1} /> */}
                    </div>
                  </SortableItem>
                ))}
              </SortableList>
            </Stack>
          </Box>
          <Stack
            direction={"row"}
            justifyContent={"flex-end"}
            sx={{ margin: "20px" }}
          >
            <Button variant="contained" onClick={addBanner}>
              Add Banner
            </Button>
          </Stack>
        </>
      ) : null}
    </Box>
  );
};

export default RandomBanners;

const BannerCard = ({
  obj,
  title,
  imageId,
  onSettingChnage,
  index,
  bannerShop,
  setItems,
  setBannerShop,
  currentIndex,
}) => {
  const theme = useTheme();
  // const isLg = useMediaQuery(theme.breakpoints.down("lg"));
  // const isIpad = useMediaQuery(theme.breakpoints.down("md"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  // const [image, setImage] = useState("");
  const [mediaLibraryOpen, toggleMediaLibrary] = useState(false);
  const [loadingImage, setLoadingImage] = useState(false);
  const [imageObj, setImageObj] = useState({});
  const [linkText, setLinkText] = useState("");
  const setBannerImages = (arr) => {
    setImageObj(arr[0]);
    onSettingChnage(index, { id: arr[0]?.id, link: "" });
    toggleMediaLibrary(false);
  };
  useEffect(async () => {
    if (obj?.id) {
      setLoadingImage(true);
      let img = await getMedia(imageId);
      setImageObj(img);
      setLoadingImage(false);
    }
  }, [obj?.id]);

  useEffect(() => {
    let obj = {
      id: imageObj?.id ? imageId : imageId,
      link: linkText,
    };
    onSettingChnage(index, obj);
  }, [linkText]);

  const onDeleteBanner = () => {
    let data = bannerShop;
    delete data[index];
    let arr = [];
    let setObj = { after: data.after };
    for (const key in data) {
      if (key !== "after") {
        arr.push(
          <BannerCard
            title={`Banner #${arr.length + 1}`}
            imageId={bannerShop[key].id}
            index={arr.length + 1}
            onSettingChnage={onSettingChnage}
            bannerShop={bannerShop}
            setItems={setItems}
          />
        );
        setObj[arr.length] = data[key];
      }
    }
    setItems(arr);
    setBannerShop(setObj);
  };
  return (
    <Card className="shadow_card" sx={{ padding: "20px", cursor: "pointer" }}>
      <Stack
        sx={{ mb: 4 }}
        direction="row"
        justifyContent={"space-between"}
        alignItems="center"
      >
        <Stack direction={"row"} sx={{ gap: "8px" }}>
          <Tooltip title="Drag">
            <DragIndicator />
          </Tooltip>
          <Typography variant="h6">{`Banner #${index}`}</Typography>
        </Stack>
        <Stack direction={"row"} sx={{ gap: "8px" }}>
          <Button
            variant="outlined"
            color="error"
            size="small"
            onClick={onDeleteBanner}
          >
            Remove
          </Button>
        </Stack>
      </Stack>
      <Grid container spacing={isMobile ? 4 : 8} sx={{ width: "100%" }}>
        <Grid item xs={12} sm={6}>
          {isObjWithValues(imageObj) ? (
            <Box sx={{ position: "relative" }}>
              <img
                src={imageObj?.source_url}
                style={{
                  maxHeight: "160px",
                  width: "100%",
                  objectFit: "contain",
                  textAlign: "center",
                  borderRadius: "8px",
                  border: "0.5px solid #ccc",
                }}
              />
              <IconButton
                sx={{
                  position: "absolute",
                  right: "-12px",
                  top: "-12px",
                  zIndex: 2,
                  overflow: "hidden",
                }}
                onClick={() => setImageObj({})}
                aria-label="delete"
              >
                <Cancel
                  size="small"
                  color="primary"
                  sx={{
                    backgroundColor: "#fff",
                    borderRadius: "50%",
                  }}
                />
              </IconButton>
            </Box>
          ) : (
            <Card
              onClick={() => toggleMediaLibrary(true)}
              sx={{
                cursor: "pointer",
                backgroundColor: "rgba(0,0,0,0.1)",
                "&:hover": {
                  backgroundColor: "rgba(0,0,0,0.1)",
                },
              }}
            >
              <CardContent
                fullWidth
                id="outlined"
                style={{
                  // borderWidth: 2,
                  // borderStyle: "dashed",
                  // borderColor: "#bababa",
                  height: "160px",
                  alignItems: "center",
                  display: "flex",
                }}
                hoverTitle=""
              >
                <Stack
                  justifyContent={"center"}
                  alignItems="center"
                  sx={{ width: "100%" }}
                >
                  {loadingImage ? (
                    <CircularProgress />
                  ) : (
                    <>
                      {" "}
                      <FileUpload
                        color="rgba(0,0,0,0.3)"
                        style={{
                          height: "40px",
                          width: "40px",
                          // backgroundColor: "rgba(0,0,0,0.3)",
                          // position: "absolute",
                          // top: "50%",
                          // left: "50%",
                          // transform: "translate(-50%, -50%)",
                        }}
                      />
                      <Typography>Image (700px X 400px)</Typography>
                    </>
                  )}
                </Stack>
                {/* <Box
                                    sx={{
                                        height: "80px",
                                        width: " 100%",
                                        // backgroundColor: "primary.main",
                                        borderRadius: "50%",

                                        position: "relative",
                                    }}
                                >
                                    <Typography>Image (700px X 400px)</Typography>
                                </Box> */}
                {/* <div style={{ marginLeft: 5 }}>
                            <h3 style={{ margin: "0px" }}>Drop or select file</h3>
                            <span style={{ margin: "0px" }}>
                                drop files here or drag through your machine
                            </span>
                        </div> */}
              </CardContent>
            </Card>
          )}
          {/* <Typography variant='subtitle2' sx={{ textAlign: "center" }}>{obj?.label}</Typography> */}
          <ProductMediaLibrary
            open={mediaLibraryOpen}
            onChange={toggleMediaLibrary}
            setParentImages={setBannerImages}
            // id={"mobile_1"}
            multiple={false}
            aspect={{ width: 700, height: 400 }}
          // selectedImages={images}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="subtitle2" sx={{ fontSize: "14px" }}>
            Add Link
          </Typography>
          <TextField
            placeholder="Link"
            size="small"
            fullWidth
            onChange={(e) => setLinkText(e.target.value)}
            value={linkText}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <InsertLink />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>
    </Card>
  );
};
