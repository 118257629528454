import { Close } from "@mui/icons-material";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { dialogStyle } from "../../helper";

const TutorialVideo = () => {
    const globalTutorial = useSelector((state) => state.helper.globalTutorial);
    const [open, setOpen] = useState(false);
    useEffect(() => {
        setOpen(globalTutorial?.show);
    }, [globalTutorial]);

    const onClose = () => {
        setOpen(false);
    };
    const globalPopupStyle = useSelector(state => state.helper.globalPopupStyle) || {}
    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="responsive-dialog-title"
            fullWidth
            maxWidth="md"
            PaperProps={{ sx: { minHeight: "80vh", overflow: "hidden" } }}
            // classes={{ "paper": { dialogPaper: { minHeight: "80vh" } } }}
            sx={{ ...dialogStyle }}
            {...globalPopupStyle}
        >
            <DialogTitle
                id="responsive-dialog-title"
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "7px 24px"
                }}
            >
                <Typography variant="h6"> {globalTutorial?.title || ""}</Typography>{" "}
                <IconButton onClick={onClose}>
                    <Close />
                </IconButton>
            </DialogTitle>
            {/* <DialogContent> */}
            <div
                style={{
                    position: "absolute",
                    // paddingBottom: "56.25%",
                    // height: "100%",
                    borderRadius: "10px",
                    top: "60px",
                    bottom: 0,
                    left: 0,
                    right: 0
                    // boxShadow: "0 15px 40px rgba(63,58,79,.3)",
                    // overflow: "hidden",
                    // minWidth: "320px",
                }}
            >
                <iframe
                    // src={globalTutorial?.url}
                    src={globalTutorial?.url}
                    frameborder="0"
                    webkitallowfullscreen
                    mozallowfullscreen
                    allowfullscreen
                    allow="autoplay; fullscreen"
                    style={{
                        width: "100%",
                        height: "100%",
                        // position: "absolute",
                        // top: "0",
                        // left: "0",
                        // width: "100%",
                        // height: "100%",
                    }}
                ></iframe>
            </div>
            {/* <iframe
                    src={globalTutorial?.url}
                    height="100%"
                    width="100%"
                    title="description"
                ></iframe> */}
            {/* </DialogContent> */}
        </Dialog>
    );
};

export default TutorialVideo;
