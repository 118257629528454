import { CardMedia, Typography } from "@mui/material";
import PropTypes from "prop-types";
import botImg from "../assets/jwero-bg-removed.png";
import BotButtonsMessage from "./BotButtonsMessage";
import BotImageMessage from "./BotImageMessage";
import BotTextMessage from "./BotTextMessage";
import BotQuickReply from "./BotQuickReply";
import BotGalleryMessage from "./BotGalleryMessage";
import BotVideoMessage from "./BotVideoMessage";
import BotDocumentMessage from "./BotDocumentMessage";
import BotWhatsappTemplate from "./BotWhatsappTemplate";
import { botMessageStyles } from "../utils/botMessageStyles";
import defaultBotIcon from "../assets/default_bot_image.png";

const styles = botMessageStyles;

const BotMessages = ({ message, data }) => {
  return (
    <>
      <div key={Date.now()} style={styles.chatHeader}>
        <CardMedia
          component="img"
          sx={styles.chatHeaderLogo}
          image={defaultBotIcon || botImg}
          alt="Live from space album cover"
        />
        <Typography style={styles.chatHeaderText}>Bot</Typography>
      </div>

      {message?.type === "text" && <BotTextMessage text={message.response} />}
      {message?.type === "buttons" && (
        <BotButtonsMessage message={message} data={data} />
      )}
      {message?.type === "product-catalog" && (
        <BotButtonsMessage message={message} data={data} />
      )}

      {message?.type === "image" && <BotImageMessage message={message} />}

      {message?.type === "quick-reply" && (
        <BotQuickReply message={message} data={data} />
      )}

      {message?.type === "gallery" && <BotGalleryMessage message={message} data={data}/>}

      {message?.type === "video" && <BotVideoMessage message={message} />}

      {message?.type === "document" && <BotDocumentMessage message={message} />}

      {message?.type === "whatsapp-template" && (
        <BotWhatsappTemplate message={message} />
      )}
        <div style={styles.chatFooter}>
          <Typography style={styles.chatFooterTime} variant="span">
            {message.time}
          </Typography>
        </div>
    </>
  );
};

BotMessages.propTypes = {
  message: PropTypes.object.isRequired,
};

export default BotMessages;
