import React from "react";
import { memo } from "react";
import { useSelector } from "react-redux";
import { ChatListRow } from "../ChatsSocial";

const ChatsCustomer = memo(({ selectedCustomer }) => {
  const allReduxConversations = useSelector(
    (state) => state.chats.conversations
  );
  return (
    <>
      {selectedCustomer?.map((obj) => (
        <ChatListRow customer={obj} />
      ))}
    </>
  );
});

export default ChatsCustomer;
