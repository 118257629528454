import { findMostOpenedTimePeriod } from "../../helper";
import {
  isObjWithValues,
  settingsEndpoint,
  formatServerValue,
  getRandomString,
  fetchJweroCustomers,
  validateNumber,
} from "../../helper";
import { extractPrefix } from "../../pages/flowBuilder/hepler";

export const EDIT_CAMPAIGN = "EDIT_CAMPAIGN";

export const getFlows = () => {
  return async (dispatch) => {
    dispatch({
      type: EDIT_CAMPAIGN,
      payload: {
        fetchingFLow: true,
      },
    });
    let flows = await settingsEndpoint({
      endpoint: "campaign_flow",
    });

    if (isObjWithValues(flows)) {
      dispatch({
        type: EDIT_CAMPAIGN,
        payload: {
          fetchingFLow: false,
          campaignFlow: Object.values(flows || {}),
        },
      });
    }
    dispatch({
      type: EDIT_CAMPAIGN,
      payload: {
        fetchingFLow: false,
      },
    });
  };
};

export const getFlowsHistories = () => {
  return async (dispatch) => {
    dispatch({
      type: EDIT_CAMPAIGN,
      payload: {
        fetchingFLowHistories: true,
      },
    });
    let flows = await settingsEndpoint({
      endpoint: "flow_history",
    });

    if (isObjWithValues(flows)) {
      dispatch(setUpFlowHistory({ data: flows }));
      dispatch({
        type: EDIT_CAMPAIGN,
        payload: {
          fetchingFLowHistories: false,
          campaignFlowHistory: flows,
        },
      });
    }
    dispatch({
      type: EDIT_CAMPAIGN,
      payload: {
        fetchingFLowHistories: false,
      },
    });
  };
};

function addNestedValue(obj, keys, value) {
  const newObj = { ...obj }; // Create a new object to avoid modifying the original

  let currentObj = newObj;

  for (let i = 0; i < keys.length - 1; i++) {
    const key = keys[i];

    if (!currentObj[key]) {
      currentObj[key] = {};
    }

    currentObj = currentObj[key];
  }

  const lastKey = keys[keys.length - 1];

  if (!currentObj[lastKey]) {
    currentObj[lastKey] = []; // Create an array if it doesn't exist
  }

  currentObj[lastKey] = currentObj[lastKey].concat(value); // Concatenate the arrays

  return newObj;
}

const channelColor = {
  email: "#1D84DE",
  whatsapp: "#46C256",
  sms: "#faacab",
  push_notification: "#C8ACE8",
};
export const setUpFlowHistory = ({ data, campaignHistory }) => {
  return async (dispatch, getState) => {
    let state = getState();
    let campaignFlowHistory = state?.campaign?.campaignFlowHistory || {};
    let campaignHistoryState = state?.chats?.campaignHistory || {};
    data = data || campaignFlowHistory || {};
    campaignHistory = campaignHistory || campaignHistoryState || {};
    let topChannels = {};
    let topChannelsIgnoreKey = ["error", "success", "sent"];

    for (let campaignKey of Object.keys(campaignHistory || {})) {
      console.log("setUpFlowHistory 1");
      let keysInHistory = ["opened", "reopened"];
      //like open reopen add in sent_info
      let extraObj = {};
      if (!campaignHistory?.[campaignKey]?.is_campaign) continue;
      for (let key of keysInHistory) {
        console.log("setUpFlowHistory 2");
        if (isObjWithValues(campaignHistory?.[campaignKey]?.[key])) {
          extraObj = addNestedValue(
            extraObj,
            [key],
            Object?.keys(campaignHistory?.[campaignKey]?.[key])?.map((k) => ({
              id: key,
            }))
          );
        }
      }
      // console.log(extraObj, "<<<< asf");
      data = {
        ...data,
        [campaignKey]: {
          sent_info: {
            [`${
              campaignHistory?.[campaignKey]?.campaign_info?.campaign_type
            }_${getRandomString(4)}`]: {
              reached: campaignHistory?.[campaignKey]?.sent_info?.success || [],
              ...extraObj,
            },
          },
        },
      };
    }
    let emailCustomers = [];
    let openCustomer = [];
    let reopenedCustomer = [];
    let timeArray = [];
    for (let historyObject of Object.values(data || {})) {
      console.log("setUpFlowHistory 3");
      for (let sentInfoKey of Object.keys(historyObject?.sent_info || {})) {
        console.log("setUpFlowHistory 4");
        let key = extractPrefix(sentInfoKey);
        for (let statusKey of Object.keys(
          historyObject?.sent_info?.[sentInfoKey] || {}
        )) {
          console.log("setUpFlowHistory 5");
          if (!topChannelsIgnoreKey?.includes(statusKey)) {
            topChannels = addNestedValue(
              topChannels,
              [key],
              (historyObject?.sent_info?.[sentInfoKey]?.[statusKey] || [])?.map(
                (o) => o?.id?.toString()
              )
            );
          }
          // if (key === "email") {

          emailCustomers = [
            ...emailCustomers,
            ...(
              historyObject?.sent_info?.[sentInfoKey]?.[statusKey] || []
            )?.map((o) => o?.id?.toString()),
          ];

          if (statusKey === "opened" || statusKey === "read") {
            openCustomer = [
              ...openCustomer,
              ...(
                historyObject?.sent_info?.[sentInfoKey]?.[statusKey] || []
              )?.map((o) => o?.id?.toString()),
            ];

            for (let o of historyObject?.sent_info?.[sentInfoKey]?.[
              statusKey
            ] || []) {
              console.log("setUpFlowHistory 6");
              if (o?.time) timeArray = [...timeArray, validateNumber(o?.time)];
            }
          }

          if (statusKey === "reopened")
            reopenedCustomer = [
              ...reopenedCustomer,
              ...(
                historyObject?.sent_info?.[sentInfoKey]?.[statusKey] || []
              )?.map((o) => o?.id?.toString()),
            ];

          // }
        }
      }
    }

    let topChannelsData = {
      data: [],
      categories: [],
      color: [],
    };
    let totalAudience = {};

    for (let channel of Object.keys(topChannels || {})) {
      console.log("setUpFlowHistory 7");
      if (channel) {
        topChannelsData = addNestedValue(
          topChannelsData,
          ["data"],
          [(topChannels?.[channel] || [])?.length]
        );

        topChannelsData = addNestedValue(
          topChannelsData,
          ["categories"],
          [formatServerValue(channel || "")?.split(" ")]
        );
        topChannelsData = addNestedValue(
          topChannelsData,
          ["color"],
          [channelColor?.[channel]]
        );

        ///total
        totalAudience = addNestedValue(
          totalAudience,
          ["total"],
          topChannels?.[channel] || []
        );
        totalAudience = addNestedValue(
          totalAudience,
          [channel],
          topChannels?.[channel] || []
        );
      }
    }
    let totalMessages = {};
    for (let key of Object.keys(totalAudience || {})) {
      console.log("setUpFlowHistory 8");
      totalMessages[key] = (totalAudience?.[key] || [])?.length;
      totalAudience[key] = [...new Set(totalAudience?.[key] || [])]?.length;
    }

    dispatch({
      type: EDIT_CAMPAIGN,
      payload: {
        topChannelsData,
        totalAudience,
        totalMessages,
        openRateEmail: {
          avgOpenRate: Math.round(
            (openCustomer?.length / emailCustomers?.length) * 100
          ),
          avgReopenedRate: Math.round(
            (reopenedCustomer?.length / emailCustomers?.length) * 100
          ),
          avgUnopenedRate:
            100 -
            Math.round((openCustomer?.length / emailCustomers?.length) * 100),
        },
        topIdealTime: findMostOpenedTimePeriod(timeArray)?.sort(
          (a, b) => b?.percentage - a?.percentage
        ),
      },
    });

    ///disptach to customer

    try {
      let customersData = Object.values(topChannels || {})?.flat();
      const uniqueValues = [...new Set(customersData)];

      let result = {};
      uniqueValues?.forEach((value, index) => {
        const count = customersData.filter((item) => item === value).length;
        result[index + 1] = { id: value, val: count };
      });

      let customers = await fetchJweroCustomers({
        params: {
          include: Object.values(result)
            ?.slice(0, 10)
            ?.map((o) => o?.id)
            ?.join(","),
          _fields: "first_name,last_name,id",
        },
      });

      let topCustomerData = {
        data: [],
        categories: [],
      };

      const sortedData = Object.entries(result)
        ?.slice(0, 10)
        .sort(([, a], [, b]) => b?.val - a.val)
        .reduce((acc, [key, value], index) => {
          acc[index + 1] = value;
          return acc;
        }, {});

      // const sortedArray = Object.entries(result)
      //     .sort(([, a], [, b]) => a.val - b.val)
      //     .map(([key, value]) => ({ [key]: value }));

      // const sortedObj = Object.assign({}, ...sortedArray);
      console.log(sortedData);

      for (let numPlace of Object.keys(sortedData || {})?.slice(0, 10)) {
        let customer = customers?.find(
          (o) =>
            validateNumber(o?.id) === validateNumber(sortedData?.[numPlace]?.id)
        );
        topCustomerData = addNestedValue(
          topCustomerData,
          ["data"],
          [validateNumber(sortedData?.[numPlace]?.val)]
        );
        topCustomerData = addNestedValue(
          topCustomerData,
          ["categories"],
          [[[customer?.first_name], [customer?.last_name]]]
        );
      }
      dispatch({
        type: EDIT_CAMPAIGN,
        payload: {
          topCustomerData,
        },
      });
    } catch (error) {
      console.log(error);
    }

    // console.log(topChannelsData, "topChannels");
  };
};
