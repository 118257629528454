import { CardContent, CardMedia } from "@mui/material";
import ReactPlayer from "react-player";

const FilePreview = ({ component }) => {
  const type = component?.format;
  const fileURL =
    component?.file_url?.url || component?.example?.header_handle?.[0];
  return (
    <CardContent
      style={{
        padding: 0,
        height: "160px",
        borderRadius: "20px 20px 0 0",
        overflow: "hidden",
      }}
    >
      {type === "VIDEO" ? (
        <ReactPlayer url={fileURL} width="640" height="560" controls />
      ) : type === "IMAGE" ? (
        <CardMedia
          style={{
            borderTopLeftRadius: "10px",
            borderTopRightRadius: "10px",
          }}
          component="img"
          sx={{ height: 200 }}
          image={fileURL}
          alt="Selected Image"
        />
      ) : type === "DOCUMENT" ? (
        <iframe
          src={`https://docs.google.com/gview?url=${encodeURIComponent(
            fileURL
          )}&embedded=true`}
          type="application/pdf, application/doc, application/docx, application/xml, application/csv"
          style={{ border: "none", width: "300px" }}
        />
      ) : null}
    </CardContent>
  );
};

export default FilePreview;
