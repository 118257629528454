import { Box, Stack, Tab, Tabs, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/system";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isArray, isArrayWithValues, isObjWithValues, TabPanel } from "../../helper";
import { getAllBusinesses, getDesignBankActivities } from "../../redux/actions/supplierActions";
import ProductSelectHeader from "./components/ProductSelectHeader";
import DesignBankDashboard from "./Dashboard";
import DesignbankProducts from "./DesignBank";
// import { DesignBank as DesignBankProducts } from './DesignBank'

const avatars = [
  "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRaL28QJo190Rnojd10zxRtJ3FDATVqA5WUHNNRmxpIAQ&s",
  "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSW_nNY5vopHqz344uCyMs71re14m4Ak-fXRzTi5A8X-A&s",
  "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS0qEdRu5qkvzd2e1V9PYVAiaeSeeMkmCMaHFLGyQkm6g&s",
  "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTq7DPor6Ehe0p1cz8uzHExiv9S4g6m8A7mQ64CThIGcQ&s",
  "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRivN-4VMb1QcIOjPbcpMA99jVaqWrQP_q3pUqfYyx41A&s",
];


export const additionalBenefits = [
  "Simple on approval",
  "Factory visits",
  "Real time meetings",
]

export const designBnakColor = [
  "#EA7369", "#19AADE", "#EE9A3A", "#5388D8"
]

export const getRandomAvatar = () => {
  return avatars[Math.floor(Math.random() * 4)];
};
export const getRandomadditionalBenefits = () => {
  return additionalBenefits[Math.floor(Math.random() * 2)]
};
const DesignBank = () => {
  const [selectedTab, setSelectedTab] = useState("dashboard");
  const allSuppliers = useSelector((state) => state.supplier.allSuppliers);
  const designBankActivities = useSelector((state) => state.supplier.designBankActivities);
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  // console.log(allBusinesses, "<<<all");
  useEffect(() => {
    if (!isArrayWithValues(allSuppliers)) dispatch(getAllBusinesses());
    if (!isObjWithValues(designBankActivities)) dispatch(getDesignBankActivities())
  }, []);

  return (
    <Box>
      <Stack
        direction={"row"}
        justifyContent="space-between"
        alignItems={"center"}
        flexWrap="wrap"
      >
        <Tabs
          onChange={(e, value) => setSelectedTab(value)}
          value={selectedTab}
        >
          <Tab label="Dashboard" value="dashboard" />
          <Tab label="Design bank" value={"design_bank"} />
          <Tab />
        </Tabs>
        {!isMobile && <ProductSelectHeader />}
      </Stack>
      {isMobile && (
        <Stack direction="row" justifyContent={"flex-end"} mt={2}>
          <ProductSelectHeader />
        </Stack>
      )}
      <TabPanel index="design_bank" value={selectedTab}>
        <DesignbankProducts />
      </TabPanel>
      <TabPanel index="dashboard" value={selectedTab}>
        <DesignBankDashboard setSelectedTab={setSelectedTab} />
      </TabPanel>
    </Box>
  );
};

export default DesignBank;
