import { Popover, Box, Typography } from "@mui/material";
import { productPopoverStyles } from "../styles/productPopoverStyles";

const styles = productPopoverStyles;

const ProductPopover = ({
  openProductPopover,
  handleClose,
  catalogs,
  catalog,
}) => {
  const open = Boolean(openProductPopover);
  const id = open ? "simple-popover" : undefined;
  const products = catalogs?.[catalog.value]

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={openProductPopover}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "center",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "center",
        horizontal: "right",
      }}
    >
      <Box sx={styles.container}>
        {products && products.length > 0 && products.map((product) => (
            <Typography key={product.id} variant="span" sx={styles.productsLabel}>
              {product.label}
            </Typography>
          ))}
      </Box>
    </Popover>
  );
};

export default ProductPopover;
