import { Box, Link, Typography } from '@mui/material'
import React from 'react'
import { ListItem } from '../meta/MetaOverview'

const feature = [
    '👉Easy to integrate',
    '👉Seamless, secure and fast payments',
    '👉Credt/Debit Card,UPI,net banking and 100+ options',
    '👉Better conversion rate',
    '👉Major International cards accepted',
    '👉Zero Setup Cost',

]
const Overview = () => {
    return (
        <Box sx={{ p: 3, mb: 3 }}>
            <Typography variant="subtitle1" sx={{ fontSize: "16px", mb: 4 }}>
                Razorpay is one of the leading payment gateway options available in India.
                Jewellers can accept payments directly from their customers from their website 💎

            </Typography>
            <Typography variant="subtitle1" sx={{ fontSize: "16px", mb: 4 }}>
                Encapsulated with many features Razorpay allows you to focus on your business seamlessly with 100+ payment methods and instant settlements from day 💳
            </Typography>
            <Typography variant="subtitle1" sx={{ fontSize: "16px", mb: 4 }}>
                It also provides in-depth reporting and insights for its customers with industry-leading success rates.
            </Typography>
            <Typography variant="subtitle2" sx={{ fontSize: "16px", mb: 4 }}>Features of RazorPay</Typography>
            <ListItem arr={feature} />
            <Typography sx={{ fontSize: "16px", mt: 3 }}>
                📨 Send your queries <Link href="https://razorpay.com/support/?utm_referrer=&referral_code=meetanshi7m92j" style={{ fontSize: "16px" }} >Contact RazorPay</Link>
            </Typography>
            <Typography sx={{ fontSize: "16px", mt: 3 }}>
                📞 Contact number:
                <Link href="tel:+18001231272" style={{ fontSize: "16px" }} > 1800-123-1272</Link>
            </Typography>

        </Box>
    )
}

export default Overview