import {
  FiberManualRecord,
  Inventory2,
  MoreVert,
  Widgets,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Grid,
  Menu,
  MenuItem,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  EmptyState,
  formatDate,
  getComparator,
  getRelativeTimeDifference,
  isArrayWithValues,
  isObjWithValues,
  primaryLabel,
  primaryLabelLarge,
  reduceLargeText,
  secondaryLabel,
  stableSort,
  validateNumber,
} from "../../helper";
import {
  catalogCardStyle,
  catalogDateFormat,
  CatalogSentRequestChartOptions,
  catalogsSentHeadCells,
  IconValue,
  recentRequestHeadCells,
  SectionLabel,
  TableLabel,
  TableSecondaryLabel,
  TotalCard,
} from "./CatalogHelper";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import { visuallyHidden } from "@mui/utils";
import { fetchCatalogs } from "../../redux/actions/productActions";
import {
  ArrowBackIos,
  Favorite,
  FavoriteBorder,
  Share,
  ShareOutlined,
  Visibility,
  VisibilityOutlined,
} from "@material-ui/icons";
import { useNavigate } from "react-router-dom";
import { setGlobalToast } from "../../redux/actions/helperActions";
import ReactApexChart from "react-apexcharts";
import { sidebarExtraWidth } from "../../App";
import { ResponsiveTabaleRow } from "../components/helper";

function CatalogsRequests() {
  const reduxCatalogs = useSelector((state) => state.product.allCatalogs);
  const fetchedAllCatalogs = useSelector(
    (state) => state.product.fetchedAllCatalogs
  );
  const fetchingCatalogs = useSelector(
    (state) => state.product.fetchingCatalogs
  );
  const catalogExtra = useSelector((state) => state.product.catalogExtra);

  const access = useSelector((state) => state.user.access);
  const catalogsAccess = useSelector((state) => state.user.catalogsAccess);
  const [productAccess, setProductAccess] = useState([]);
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const primaryColor = theme?.palette?.primary?.main;

  console.log(catalogExtra);

  useEffect(() => {
    if (isArrayWithValues(access?.product)) setProductAccess(access.product);
  }, [access]);

  useEffect(() => {
    if (!fetchedAllCatalogs) dispatch(fetchCatalogs());
  }, []);
  return (
    <Box sx={{ columnGap: "14px" }}>
      <Stack
        direction={"row"}
        alignItems="center"
        justifyContent={"space-between"}
        mb={6}
        flexWrap="wrap"
        gap="10px"
        display="none"
      >
        <Button
          size="large"
          startIcon={<ArrowBackIos />}
          onClick={() => navigate(-1)}
        >
          Catalogs Requests
        </Button>
        <Stack
          direction={"row"}
          alignItems="center"
          justifyContent={"space-between"}
          spacing={3}
        >
          <Button variant="outlined" onClick={() => navigate("/catalogs/sent")}>
            View Sent catalogs
          </Button>
          {/* <Button variant="outlined">View request catalogs</Button> */}
          <Button variant="contained" onClick={() => navigate("/catalogs")}>
            Go to dashboard
          </Button>
        </Stack>
      </Stack>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={6}>
              <TotalCard
                title="Catalog sent"
                value={reduxCatalogs?.length}
                hideGraph
                values={[
                  {
                    title: "Sent to contacts",
                    value: reduxCatalogs?.length || 0,
                    // prefix: currencySymbols[currency],
                  },
                  {
                    title: "Contacts responded",
                    value: catalogExtra?.totalRequests || 0,
                    // suffix: "g",
                  },
                ]}
                hideNavigate
                // graphData={topGraphs?.plans}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <TotalCard
                title="Request received"
                value={catalogExtra?.totalRequests}
                hideGraph
                values={[
                  {
                    title: "Customers",
                    value: catalogExtra?.totalRequests,
                    // prefix: currencySymbols[currency],
                  },
                  {
                    title: "Leads",
                    value: 0,
                    // suffix: "g",
                  },
                ]}
                hideNavigate
                // graphData={topGraphs?.plans}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <TotalCard
                title="Products sent"
                value={catalogExtra?.totalProductsSentUnique || 0}
                hideGraph
                values={[
                  {
                    title: "Products selected",
                    value: catalogExtra?.totalUniqueProductsRequested || 0,
                    // prefix: currencySymbols[currency],
                  },
                  {
                    title: "No response products",
                    value:
                      catalogExtra?.totalProductsSentUnique -
                        catalogExtra?.totalUniqueProductsRequested >
                      0
                        ? catalogExtra?.totalProductsSentUnique -
                          catalogExtra?.totalUniqueProductsRequested
                        : 0,
                    // suffix: "g",
                  },
                ]}
                hideNavigate
                // graphData={topGraphs?.plans}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <TotalCard
                title="Customer never responded"
                value={validateNumber(
                  catalogExtra?.totalSentToCustomers -
                    catalogExtra?.totalRequests >
                    0
                    ? catalogExtra?.totalSentToCustomers -
                        catalogExtra?.totalRequests
                    : 0
                )}
                hideGraph
                values={[
                  {
                    title: "Customer",
                    value: 0,
                    // prefix: currencySymbols[currency],
                  },
                  {
                    title: "Leads",
                    value:
                      catalogExtra?.totalSentToCustomers -
                        catalogExtra?.totalRequests >
                      0
                        ? catalogExtra?.totalSentToCustomers -
                          catalogExtra?.totalRequests
                        : 0,
                    // suffix: "g",
                  },
                ]}
                hideNavigate
                // graphData={topGraphs?.plans}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <ReactApexChart
                options={CatalogSentRequestChartOptions}
                series={[
                  {
                    data: catalogExtra?.sentArrayGraph || [],
                    name: `Catalogs Sent`,
                  },
                  {
                    data: catalogExtra?.requestArrayGraph || [],
                    name: `Catalogs received`,
                  },
                ]}
                type="line"
                height={320}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <CatalogsRequestsTable />
    </Box>
  );
}

export default CatalogsRequests;

export function CatalogsRequestsTable({ customer }) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [rows, setRows] = useState([]);
  const catalog_requests = useSelector(
    (state) => state.product.catalog_requests
  );

  const theme = useTheme();
  const isIpad = useMediaQuery(theme.breakpoints.down("md"));
  const lgUp = useMediaQuery(theme.breakpoints.up("lg"));

  useEffect(() => {
    if (!isArrayWithValues(catalog_requests)) return;
    // console.log(
    //   catalog_requests
    //     .filter(
    //       (i) =>
    //         i.products &&
    //         (isObjWithValues(customer)
    //           ? i?.customer_id == customer?.customer_id
    //           : true)
    //     )
    //     .sort((a, b) => {
    //       return (
    //         new Date(
    //           validateNumber(b.catalogue_request_timing) * 1000
    //         ).getTime() -
    //         new Date(
    //           validateNumber(a.catalogue_request_timing) * 1000
    //         ).getTime()
    //       );
    //     })
    // );
    setRows(
      catalog_requests
        .filter(
          (i) =>
            i.products &&
            (isObjWithValues(customer)
              ? i?.customer_id == customer?.customer_id
              : true)
        )
        .sort((a, b) => {
          let aTime =
            `${a.catalogue_request_timing}`.length <= 10
              ? validateNumber(a.catalogue_request_timing) * 1000
              : a.catalogue_request_timing;
          let bTime =
            `${b.catalogue_request_timing}`.length <= 10
              ? validateNumber(b.catalogue_request_timing) * 1000
              : b.catalogue_request_timing;
          return validateNumber(bTime) - validateNumber(aTime);
        })
    );
  }, [catalog_requests]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;
  return (
    <Box sx={{ width: "100%", mt: 5 }}>
      <Paper sx={{ width: "100%", mb: 2, mt: 2, ...catalogCardStyle }}>
        <Stack sx={{ px: 2, pt: 3, pb: 2 }}>
          <SectionLabel style={{}}>Catalog requests</SectionLabel>
        </Stack>
        {/* <EmptyState icon={<Inventory2 />} /> */}
        {/* <Toolbar
          sx={{
            pl: { sm: 2 },
            pr: { xs: 1, sm: 1 },
            ...(selected.length > 0 && {
              bgcolor: (theme) =>
                alpha(
                  theme.palette.primary.main,
                  theme.palette.action.activatedOpacity
                ),
            }),
            display: "none",
          }}
        >
          {selected.length > 0 ? (
            <Typography
              sx={{ flex: "1 1 100%" }}
              color="inherit"
              variant="subtitle1"
              component="div"
            >
              {selected.length} selected
            </Typography>
          ) : (
            <Typography
              sx={{ flex: "1 1 100%" }}
              variant="h6"
              id="tableTitle"
              component="div"
            >
              Recent catalog request received
            </Typography>
          )}

          {selected.length > 0 ? (
            <Tooltip title="Delete">
              <IconButton>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title="Filter list">
              <IconButton>
                <FilterListIcon />
              </IconButton>
            </Tooltip>
          )}
        </Toolbar> */}
        <TableContainer
          sx={{
            maxWidth: !isIpad
              ? lgUp
                ? "100%"
                : `calc(100vw - ${sidebarExtraWidth}px)`
              : "100%",
          }}
        >
          <Table aria-labelledby="tableTitle" size={dense ? "small" : "medium"}>
            <TableHead style={{}}>
              {!isIpad && (
                <TableRow>
                  {/* <TableCell padding="checkbox">
                    <Checkbox
                      color="primary"
                      indeterminate={
                        selected.length > 0 && selected.length < rows.length
                      }
                      checked={rows.length > 0 && selected.length === rows.length}
                      onChange={handleSelectAllClick}
                      inputProps={{
                        "aria-label": "select all desserts",
                      }}
                    />
                  </TableCell> */}
                  {recentRequestHeadCells.map((headCell) => (
                    <TableCell
                      key={headCell.id}
                      align={headCell.numeric ? "right" : "left"}
                      // padding={headCell.disablePadding ? "none" : "normal"}
                      padding={"normal"}
                      sortDirection={orderBy === headCell.id ? order : false}
                    >
                      <TableSortLabel
                        active={orderBy === headCell.id}
                        direction={orderBy === headCell.id ? order : "asc"}
                        onClick={createSortHandler(headCell.id)}
                      >
                        {headCell.label}
                        {orderBy === headCell.id ? (
                          <Box component="span" sx={visuallyHidden}>
                            {order === "desc"
                              ? "sorted descending"
                              : "sorted ascending"}
                          </Box>
                        ) : null}
                      </TableSortLabel>
                      {headCell.hasSubheader && (
                        <Stack
                          direction={"row"}
                          alignItems="center"
                          justifyContent={"space-between"}
                        >
                          <Typography sx={{ fontSize: "10px" }}>
                            Contacts responded
                          </Typography>
                          <Typography sx={{ fontSize: "10px" }}>
                            {" "}
                            Unique products
                          </Typography>
                        </Stack>
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              )}
              {/* <TableRow sx={{}}>
                  <TableCell
                    align={"left"}
                    // padding={headCell.disablePadding ? "none" : "normal"}
                    padding={"normal"}
                    //   sortDirection={false}
                    colSpan={5}
                  ></TableCell>
                  <TableCell
                    align={"left"}
                    sx={{ fontSize: "11px", color: "text.secondary" }}
                    // padding={headCell.disablePadding ? "none" : "normal"}
                    padding={"normal"}
                    //   sortDirection={false}
                    colSpan={0.5}
                  >
                    Contacts responded
                  </TableCell>
                  <TableCell
                    sx={{ fontSize: "11px", color: "text.secondary" }}
                    align={"left"}
                    // padding={headCell.disablePadding ? "none" : "normal"}
                    padding={"normal"}
                    //   sortDirection={false}
                    colSpan={0.5}
                  >
                    Unique products
                  </TableCell>
                  <TableCell
                    align={"left"}
                    // padding={headCell.disablePadding ? "none" : "normal"}
                    padding={"normal"}
                    colSpan={1}
  
                    //   sortDirection={false}
                  ></TableCell>
                </TableRow> */}
            </TableHead>
            {/* <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
              /> */}
            <TableBody>
              {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                     rows.sort(getComparator(order, orderBy)).slice() */}
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return <CustomerRow row={row} index={index} />;
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {rows.length > 5 && (
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </Paper>
    </Box>
  );
}

const CustomerRow = ({ row, index, isSelected }) => {
  // const isItemSelected = isSelected(row.name);
  const labelId = `enhanced-table-checkbox-${index}`;
  const catalogs = useSelector((state) => state.product.catalogs);
  const [catalog, setCatalog] = useState({});
  const [customer, setCustomer] = useState({});
  const [responseTime, setResponseTime] = useState("");
  const [info, setInfo] = useState({});
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [imageUrl, setImageUrl] = useState("");
  const open = Boolean(anchorEl);
  const catalog_live_ids = useSelector(
    (state) => state.product.catalog_live_ids
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const isIpad = useMediaQuery(theme.breakpoints.down("md"));
  const isDarkTheme = theme.palette && theme.palette.mode === "dark";

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (!isArrayWithValues(catalogs)) return;
    let catalog = catalogs.find((i) => i.id == row.post_id);
    setCatalog(catalog || {});
  }, [catalogs, row]);

  // useEffect(() => {
  //   if (isObjWithValues(catalog)) {
  //     let;
  //   }
  // }, [catalog]);

  useEffect(() => {
    if (!isObjWithValues(catalog)) return;
    let { productDetails } = catalog;
    if (isArrayWithValues(productDetails)) {
      for (let product of productDetails) {
        let { images } = product;
        if (isArrayWithValues(images)) {
          setImageUrl(images[0]?.src);
          break;
        }
      }
    }
  }, [catalog]);
  useEffect(() => {
    let { customerDetail, catalogue_request_timing, products } = row || {};
    let { created_at } = catalog;
    let obj = {};

    if (typeof products === "string")
      obj.totalSelected = products?.split(",")?.filter(Boolean)?.length;
    else obj.totalSelected = products?.length || 0;
    if (catalogue_request_timing) {
      let obj = getRelativeTimeDifference(
        new Date(created_at),
        new Date(
          `${catalogue_request_timing}`.length > 10
            ? catalogue_request_timing
            : catalogue_request_timing * 1000
        )
      );
      setResponseTime(`${obj.value} ${obj.label}`);
    }
    setInfo((state) => ({ ...state, ...obj }));
    if (customerDetail) setCustomer(customerDetail);
  }, [row, catalog]);

  const onCopyLink = () => {
    navigator?.clipboard?.writeText(catalog.link);
    if (navigator?.clipboard?.writeText)
      dispatch(
        setGlobalToast({
          show: true,
          message: "Catalog link copied to clipboard",
          severity: "success",
        })
      );
    else
      dispatch(
        setGlobalToast({
          show: true,
          message: "Couldn't copy!",
          severity: "error",
        })
      );
    handleClose();
  };
  return (
    <>
      {isIpad ? (
        <ResponsiveTabaleRow
          // title={reduceLargeText(catalog.title, 20)}
          title={`${customer.first_name || ""} ${customer.last_name || ""}`}
          titleProps={{
            onClick: () => navigate(`/catalogs/requests/${row.request_id}`),
          }}
          subTitle={
            <>
              <Typography sx={{ ...primaryLabel, color: "primary.main" }}>
                Catalog: {reduceLargeText(catalog.title, 20)}
              </Typography>
            </>
          }
          subTitle2={
            <>
              <TableSecondaryLabel sx={{ mt: 1 }}>
                {row.catalogue_request_timing
                  ? formatDate(
                      new Date(
                        `${row.catalogue_request_timing}`.length > 10
                          ? row.catalogue_request_timing
                          : row.catalogue_request_timing * 1000
                      ),
                      catalogDateFormat
                    )
                  : ""}
                {"  "}
                {row.catalogue_request_timing
                  ? formatDate(
                      new Date(
                        `${row.catalogue_request_timing}`.length > 10
                          ? row.catalogue_request_timing
                          : row.catalogue_request_timing * 1000
                      ),
                      "hh:mm am/pm"
                    )
                  : ""}
              </TableSecondaryLabel>
            </>
          }
          src={imageUrl || "/static/img/woocommerce_placeholder.png"}
          menu={
            <IconButton onClick={handleClick}>
              <MoreVert />
            </IconButton>
          }
          chipLabel={
            <Typography>
              {info.totalSelected}/
              <TableSecondaryLabel variant="caption">
                {catalog?.products || 0}
              </TableSecondaryLabel>
            </Typography>
          }
          chipProps={{
            size: "small",
            mr: 1,
            mb: 1,

            sx: {
              // color: statusFontColor(row.status),
              color: !row.is_public ? "#5570bc" : "#1aae6f",
              border: isDarkTheme ? "0.7px solid" : "0px solid",
              // borderColor: statusFontColor(row.status),
              backgroundColor: isDarkTheme
                ? "transparent"
                : !row.is_public
                ? "#EAECFB"
                : "#e0f6ed",
            },
            variant: "outlined",
          }}
        />
      ) : (
        <TableRow
          hover
          onClick={() => navigate(`/catalogs/requests/${row.request_id}`)}
          sx={{
            cursor: "pointer",
          }}
          // onClick={(event) => handleClick(event, row.name)}
          //   role="checkbox"
          // aria-checked={isItemSelected}
          tabIndex={-1}
          key={row.title}
          // selected={isItemSelected}
        >
          {/* <TableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            checked={isItemSelected}
                            inputProps={{
                              "aria-labelledby": labelId,
                            }}
                          />
                        </TableCell> */}
          <TableCell
            component="th"
            id={labelId}
            scope="row"
            // padding="none"
          >
            <Stack direction="row" alignItems={"center"}>
              <img
                src={imageUrl || "/static/img/woocommerce_placeholder.png"}
                style={{
                  height: "50px",
                  width: "50px",
                  borderRadius: "50%",
                }}
              />
              <Box sx={{ ml: 1.5 }}>
                <Typography
                  sx={{
                    fontSize: "13px",
                    fontWeight: "600",
                    cursor: "pointer",
                  }}
                  // onClick={() =>
                  //   navigate(`/catalogs/requests/${row.request_id}`)
                  // }
                >
                  {reduceLargeText(catalog.title, 20)}
                </Typography>
                <Stack direction="row" alignItems={"center"} spacing={3} mt={1}>
                  {/* <IconValue
                icon={
                  <Favorite
                    style={{
                      height: "13px",
                      width: "13px",
                      fill: "#bb6666",
                    }}
                  />
                }
                text={"0"}
              /> */}
                  <IconValue
                    icon={
                      <Visibility
                        style={{
                          height: "15px",
                          width: "15px",
                          fill: "#818181",
                        }}
                      />
                    }
                    text={catalog.totalViews || 0}
                    tooltip="Catalog visits"
                  />
                  {/* <IconValue
                icon={
                  <Share
                    style={{
                      height: "13px",
                      width: "13px",
                      fill: "#35557f",
                    }}
                  />
                }
                text={"0"}
              /> */}
                </Stack>
              </Box>
            </Stack>
          </TableCell>
          <TableCell align="left">
            <Box>
              <TableLabel>
                {catalog_live_ids?.includes(`${customer.id}`) ? (
                  <div
                    style={{
                      backgroundColor: "#5fa451",
                      height: "11px",
                      width: "11px",
                      borderRadius: "50%",
                      alignItems: "center",
                    }}
                  />
                ) : null}
                {customer.first_name || ""} {customer.last_name || ""}
              </TableLabel>
              <TableSecondaryLabel>{customer.email || ""}</TableSecondaryLabel>
              <TableSecondaryLabel>
                {customer?.billing?.phone}
              </TableSecondaryLabel>
            </Box>
          </TableCell>
          <TableCell align="left">
            {row.catalogue_request_timing
              ? formatDate(
                  new Date(
                    `${row.catalogue_request_timing}`.length > 10
                      ? row.catalogue_request_timing
                      : row.catalogue_request_timing * 1000
                  ),
                  catalogDateFormat
                )
              : ""}
            <TableSecondaryLabel>
              {row.catalogue_request_timing
                ? formatDate(
                    new Date(
                      `${row.catalogue_request_timing}`.length > 10
                        ? row.catalogue_request_timing
                        : row.catalogue_request_timing * 1000
                    ),
                    "hh:mm am/pm"
                  )
                : ""}
            </TableSecondaryLabel>
            <TableSecondaryLabel>
              Responded in
              <br /> {responseTime}
            </TableSecondaryLabel>
          </TableCell>
          <TableCell align="right">
            <TableLabel>
              {info.totalSelected}/
              <TableSecondaryLabel variant="caption">
                {catalog?.products || 0}
              </TableSecondaryLabel>
            </TableLabel>
            <TableSecondaryLabel>
              {validateNumber(
                Math.round(
                  (validateNumber(info.totalSelected) /
                    validateNumber(catalog?.products)) *
                    100
                )
              )}
              %
            </TableSecondaryLabel>
          </TableCell>
          <TableCell align="left">
            <TableSecondaryLabel>
              In stock: {row.totalInStock}
            </TableSecondaryLabel>
            <TableSecondaryLabel>
              Make to order: {row.totalMakeToOrder}
            </TableSecondaryLabel>
          </TableCell>
          <TableCell align="left">
            <TableLabel>{catalog.totalRequests}</TableLabel>
            <TableSecondaryLabel>(0%)</TableSecondaryLabel>
          </TableCell>
          <TableCell align="left">
            <Tooltip
              title={
                <>
                  <div>
                    Unique products:{" "}
                    <strong>{catalog.uniqueProductsRequested}</strong>
                  </div>
                  <div>
                    Avg per contact:{" "}
                    <strong>{catalog.totalProductRequestPerContact}</strong>
                  </div>
                </>
              }
            >
              <TableLabel>{catalog.totalProductRequest}</TableLabel>
            </Tooltip>
          </TableCell>
          {/* <TableCell align="right">
        <TableSecondaryLabel>In process</TableSecondaryLabel>
      </TableCell> */}
          <TableCell align="right">
            <IconButton onClick={handleClick}>
              <MoreVert />
            </IconButton>
          </TableCell>
        </TableRow>
      )}
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          onClick={() => navigate(`/catalogs/requests/${row.request_id}`)}
        >
          View
        </MenuItem>
        {/* <MenuItem onClick={handleClose}>Delete</MenuItem> */}
        {/* <MenuItem onClick={handleClose}>Recreate</MenuItem> */}
        <MenuItem onClick={onCopyLink}>Copy Link</MenuItem>
      </Menu>
    </>
  );
};

const MostViewedProducts = () => {
  return (
    <Box>
      <SectionLabel>Most viewed products</SectionLabel>

      <Stack
        direction={"row"}
        sx={{
          maxWidth: "100%",
          overflow: "auto",
          display: "flex",
          gap: "12px",
          mt: 3,
        }}
      >
        {/* {map(range(10), (_) => (
          <Container />
        ))} */}
        {Array.from(new Array(4)).map((i) => {
          return <CatalogProductCard />;
        })}
      </Stack>
    </Box>
  );
};

const CatalogProductCard = () => {
  return (
    <Card sx={{ maxWidth: "250px", p: 0, ...catalogCardStyle }}>
      <CardContent sx={{ p: 0 }}>
        <img
          src="https://tiarabytj.com/wp-content/uploads/sites/27/2022/11/1615371830359.jpg"
          style={{
            height: "100%",
            width: "100%",
            //   height: "50px",
            //   width: "50px",
            //   borderRadius: "50%",
          }}
        />
        <Box sx={{ px: 1.5 }}>
          <Stack
            direction="row"
            alignItems={"center"}
            justifyContent="space-between"
            //   spacing={1}
            px={1.2}
            mt={1}
          >
            <IconValue
              icon={
                <Favorite
                  style={{
                    height: "16px",
                    width: "16px",
                    fill: "#bb6666",
                  }}
                />
              }
              text={"0"}
              textStyle={{ fontSize: "15px" }}
            />
            <IconValue
              icon={
                <Visibility
                  style={{
                    height: "17px",
                    width: "17px",
                    fill: "#818181",
                  }}
                />
              }
              text={"0"}
              textStyle={{ fontSize: "15px" }}
            />
            {/* <IconValue
              icon={
                <Share
                  style={{
                    height: "16px",
                    width: "16px",
                    fill: "#35557f",
                  }}
                />
              }
              text={"13"}
              textStyle={{ fontSize: "15px" }}
            /> */}
          </Stack>
          <Typography sx={{ fontSize: "14px", fontWeight: "500", mt: 2 }}>
            Tanika product DC-23-VR
          </Typography>
          <TableLabel>Diamond, Necklace</TableLabel>
          <Grid container style={{ marginTop: "6px" }}>
            <Grid item xs={6}>
              <Box>
                <TableLabel>Sent</TableLabel>
                <TableSecondaryLabel>40</TableSecondaryLabel>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box>
                <TableLabel>Request</TableLabel>
                <TableSecondaryLabel>50</TableSecondaryLabel>
              </Box>
            </Grid>
          </Grid>
          {/* <Stack
            direction={"row"}
            alignItems="flex-start"
            justifyContent={"space-evenly"}
          >
            <Box>
              <TableLabel>Sent</TableLabel>
              <TableSecondaryLabel>40</TableSecondaryLabel>
              {/* <TableLabel>40</TableLabel> 
            </Box>
            <Box>
              <TableLabel>Request</TableLabel>
              <TableSecondaryLabel>50</TableSecondaryLabel>
            </Box>
          </Stack> */}
        </Box>
      </CardContent>
    </Card>
  );
};
